import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import moment from 'moment'
import {CONVERTER} from '../../utils/converter'
import PropTypes from "prop-types";
import {dateTimeUtils} from "../../utils/dateTimeUtils";

export class IssuePanel extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
    }

    /**
     *
     * @param {*} issue
     * @returns the name of the health issue
     */
    getNameCell = (issue) => {
        return <div key={++this.index} className="health-panel-name">{issue.name}</div>;
    }

    /**
     *
     * @param {*} issue
     * @returns the type of the health issue
     */
    getType = (issue) => {
        if (issue.allergy_condition_id) {
            return <div key={++this.index}
                        className='type-dashboard issue-allergy-condition-cells'>{!issue.allergyCondition || issue.allergyCondition.type === undefined ? $$("other_allergy") : CONVERTER.getAllergyNameForDisplay(issue.allergyCondition.type)}</div>
        } else if (issue.chronic_condition_id) {
            return <div key={++this.index}
                        className='type-dashboard issue-allergy-condition-cells'>{$$("chronic_condition")}</div>;
        } else {
            return <div className="type-dashboard hide-on-small" key={++this.index}/>;
        }
    }

    /**
     *
     * @param {*} issue
     * @returns the date cell
     */
    getDateTime = (issue) => {
        return <div className="date-time-dashboard" key={++this.index}>
            <div className='issue-time-cell'>
                <div className="d-flex">
                    <span className="medrec-grey-2">
                        <i className='fa fa-calendar-alt'/>
                    </span>
                    <span className="ml-2 mr-3">
                        {dateTimeUtils.getFormattedDate(issue.date_created)}
                    </span>
                </div>
                <div className="d-flex">
                    <span className="medrec-grey-2 ml-2">
                        <i className='fa fa-clock'/>
                    </span>
                    <span className="ml-2">
                        {CONVERTER.getEntryDateTimeFormat(issue.date_created)}
                    </span>
                </div>
            </div>
        </div>;
    }

    getRow = (issue, idx, issuesCount) => {
        let cells = [];
        if (issue !== undefined) {
            cells.push(this.getNameCell(issue))
            cells.push(this.getType(issue))
            cells.push(this.getDateTime(issue))
        }
        if (idx !== issuesCount - 1) cells.push(<hr key={++this.index}
                                                    style={{borderTop: "1px solid #DCEBFF", width: "90%"}}/>)
        return <div className="issue-pannel-row" key={idx}>{cells}</div>
    }

    getBody = () => {
        let sortedIssues = this.props.healthIssues.sort((a, b) => (a.date_created < b.date_created) ? 1 : -1)
        let allRows = [];
        let issuesCount = sortedIssues.length <= 2 ? sortedIssues.length : 2;
        for (let i = 0; i < issuesCount; ++i) {
            allRows.push(this.getRow(sortedIssues[i], i, issuesCount));
        }
        return [].concat.apply([], allRows);
    }


    render() {
        return (
            <>
                {this.props.healthIssues && this.props.healthIssues.length > 0 &&
                <>
                    {this.getBody()}
                </>
                }
            </>
        )
    }
}

IssuePanel.propTypes = {
    dashboardCharts: PropTypes.object,
    fetchSelectedUserLatestMeasurements: PropTypes.func,
    healthIssues:  PropTypes.array,
    history: PropTypes.object,
    i18n:PropTypes.object,
    latestUserMeasurements: PropTypes.array,
    location:PropTypes.object,
    match:PropTypes.object,
    medicationPlan: PropTypes.array,
    providers:  PropTypes.array,
    selectedUser: PropTypes.object,
    userAppointments:  PropTypes.array,
    userInfo: PropTypes.object
}

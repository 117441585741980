import React from "react";
import AnswerType from "../../../models/answer-type/AnswerType";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import BaseAnswer from "../BaseAnswer";
import FormCheck from "../../input/FormCheck";
import '../Answer.css'

class DateAnswer extends BaseAnswer {
    render() {
        const answer = this.props.answer;
        return (
            <span>
                <div className={`custom-control custom-${answer.selectionType}`}>
                <FormCheck ref={this.inputRef} answer={answer} handleChange={this.onInputChange}/>
                <label onClick={() => this.inputRef.current.click()}
                      className={'cursor-pointer mr-2 custom-control-label'}>
                    {`${answer.label[this.language]} ${this.getSuffix(answer)}`}
                </label>
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        className={'invisible'}
                        value={this.state.date}
                        open={this.state.open}
                        onClose={() => this.onCancelButtonPressed()}
                        onChange={date => this.setState({date})}
                        onAccept={date => this.onAccept(date)}
                    />
                </MuiPickersUtilsProvider>
            </span>
        )
    };

    onAccept = (date) => {
        this.props.answer.result = date.toString();
        this.selectAnswer();
    }
}

class DateAnswerFactory {
    static get type() {
        return AnswerType.DATE;
    }

    static create(answer, updateAnswer, language) {
        return <DateAnswer answer={answer} updateAnswer={updateAnswer} language={language}/>
    }
}

export default DateAnswerFactory

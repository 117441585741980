import React, {Component} from 'react'
import {$$} from '../../../helpers/localization'
import PropTypes from "prop-types";

export default class OxygenSaturationForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.oxygen_saturation ? $$('oxygen_saturation_label') : null}<br/>
                <div className="form-group">
                    <div className="input-group mb-3">
                        <input type="number" step="0.01"
                               className={this.props.errors.oxygen_saturation ? "custom-error form-control" : "form-control"}
                               value={this.props.oxygen_saturation}
                               placeholder={$$('oxygen_saturation_label')} name="oxygen_saturation"
                               onChange={this.props.onInputChange} required/>
                        <div className="input-group-append">
                            <span className="input-group-text" style={{"height": "auto"}} id="basic-addon2">%</span>
                        </div>
                    </div>
                    <div
                        className={this.props.errors.oxygen_saturation ? "custom-invalid-feedback" : "invalid-feedback"}>
                        {this.props.errors.oxygen_saturation ? $$(this.props.errors.oxygen_saturation) : $$('oxygen_saturation_required_message')}
                    </div>
                </div>
            </div>
        )
    }
}

OxygenSaturationForm.propTypes = {
    clearSelectedEntry: PropTypes.func,
    createEntry: PropTypes.func,
    errors: PropTypes.any,
    oxygen_saturation: PropTypes.any,
    fetchSelectedUserHealthIssues: PropTypes.func,
    formDisabled: PropTypes.object,
    getHealthIssueList: PropTypes.func,
    healthIssues: PropTypes.object,
    logbookEntries: PropTypes.array,
    medicalProfile: PropTypes.object,
    onHide: PropTypes.func,
    onInputChange: PropTypes.func,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    show: PropTypes.bool,
    type: PropTypes.string,
    updateEntry: PropTypes.func,
    updateUserMedicalProfile: PropTypes.func
}
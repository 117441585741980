import React, {forwardRef} from 'react';

const FormCheck = forwardRef((props, ref) => {
    const emptyFunction = () => {
        return true;
    }
    const answer = props.answer;
    return (
        <input
            className={'mr-2 custom-control-input'}
            ref={ref}
            type={answer.selectionType}
            name={answer.group}
            onClick={props.onClick || emptyFunction}
            checked={answer.checked || ''}
            onChange={props.handleChange}
        />
    )
});


export default FormCheck;

import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {connect} from 'react-redux'
import ReactDOM from 'react-dom';
import CenteredModal from '../shared/CenteredModal'
import no_data from '../../resources/images/no_data.png'
import {infoUtils} from "../../utils/infoUtils";
import HealthIssuesListItem from './HealthIssuesListItem'
import HealthIssueForm from './HealthIssueForm'
import moment from 'moment'
import {
    createHealthIssue,
    deleteHealthIssue,
    fetchSelectedUserHealthIssues,
    updateHealthIssue
} from '../../actions/health-issues_actions'
import HealthIssuesHeader from './HealthIssuesHeader'
import {getResolvedOptions, HEALTH_ISSUE_OPTIONS} from '../../constants/select_options'
import PropTypes from "prop-types";

export class IssueContainer extends Component {
    state = {
        start: null,
        end: null,
        searchValue: '',
        selectedType: 2,
        requestSent: false,
        selectedHealthIssueId: '',
        selectedHealthIssue: null,
        addUpdateModal: {
            show: false
        },
        selectedEntry: null,
        formclass: '',
        errors: {},
        descriptionOpened: false,
        deletedConfirmationModal: {
            show: false
        },
        selectedEntryToDelete: null,
        loaded: false,
        filteredHealthIssues: {}
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.selectedUser.id) {
            this.props.fetchSelectedUserHealthIssues(this.props.selectedUser.id, null).then(() => {
                this.setState({loaded: true})
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps && prevProps.healthIssues.entries !== this.props.healthIssues.entries && !this.state.requestSent) {
            this.setState({
                requestSent: true
            });
        }

        if (prevProps && prevProps.healthIssues.entries !== this.props.healthIssues.entries) {
            this.filterSortAndSelectFirst(true)
        }

        if (this.state.searchValue !== prevState.searchValue) {
            this.filterSortAndSelectFirst()
        }

        if (this.state.selectedType !== prevState.selectedType) {
            this.filterSortAndSelectFirst()

        }

        if (this.state.loaded && !prevState.loaded) {
            this.filterSortAndSelectFirst()
        }
    }

    filterSortAndSelectFirst = (preserveSelection) => {
        let filteredIssues = this.getFilteredIssues()

        let allergiesAndConditionsWithStartDate = [];
        let allergiesAndConditionsWithoutStartDate = [];
        let othersDateEnded = [];
        let othersNoDateEnded = [];

        filteredIssues.map((issue) => {
                if (issue.allergy_condition_id != null || issue.chronic_condition_id != null) {
                    if (issue.date_started) {
                        allergiesAndConditionsWithStartDate.push(issue);
                    } else allergiesAndConditionsWithoutStartDate.push(issue)
                } else {
                    if (issue.date_ended != null) {
                        othersDateEnded.push(issue)
                    } else {
                        othersNoDateEnded.push(issue);
                    }
                }
            }
        )
        let orderedIssues = allergiesAndConditionsWithStartDate.sort(
            (a, b) => new Date(b.date_started).getTime() - new Date(a.date_started).getTime()
        );
        orderedIssues.push(...allergiesAndConditionsWithoutStartDate.sort(
            (a, b) => new Date(b.date_created).getTime() - new Date(a.date_created).getTime()
        ))
        orderedIssues.push(...othersNoDateEnded.sort(
            (a, b) => new Date(b.date_started).getTime() - new Date(a.date_started).getTime()
        ))
        orderedIssues.push(...othersDateEnded.sort(
            (a, b) => new Date(b.date_ended).getTime() - new Date(a.date_ended).getTime()
        ))

        this.setState({filteredHealthIssues: orderedIssues})
        if (!preserveSelection) {
            this.selectFirst(orderedIssues[0])
        }
    }

    /**
     * Filters the health issues by name
     * @param {*} searchValue
     */
    filterIssuesByName = (searchValue) => {
        this.setState({searchValue: searchValue});
    }

    /**
     * Filters the health issues based on select value
     * @param {*} evt
     */
    onSelectChange = (evt) => {
        this.setState({selectedType: evt.value})
    }

    /**
     * Confirm deletion and remove entry from list
     */
    onDeleteConfirmModal = () => {
        let deletedEntry = this.state.selectedEntryToDelete;
        deletedEntry.deleted = true;
        deletedEntry.updated_timestamp = moment().valueOf();
        this.props.deleteHealthIssue(deletedEntry.id, this.props.selectedUser.id);

        this.setState({
            deletedConfirmationModal: {show: false},
            selectedEntryToDelete: '',
        });

    }
    /**
     * Show confirmation modal when trying to delete an entry
     *
     * @param {entry} key The entry to delete
     */
    showDeletedConfirmationModal = (key) => {
        this.setState({
            deletedConfirmationModal: {show: true},
            selectedEntryToDelete: key
        })
    }


    /**
     * Show add/update modal
     *
     * @param {entry}  entry - entry to modify or null
     */
    showAddUpdateModal = (entry) => {
        if (entry) {
            this.setState({
                selectedEntry: entry,
                addUpdateModal: {show: true}
            })
        } else {
            this.setState({
                selectedEntry: null,
                addUpdateModal: {show: true}
            })
        }
    }

    /**
     * Hide the add/update modal
     */
    onHideAddUpdateModal = () => {
        this.setState({
            addUpdateModal: {
                show: false
            },
            selectedEntry: null,
        });
    }

    /**
     * Clear selected entry from state
     */
    clearSelectedEntry = () => {
        this.setState({
            selectedEntry: null
        })
    }

    /**
     * Fetch selected user health issues
     */
    onLoadMore = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.props.selectedUser.id && (this.props.healthIssues.request.finished === undefined || this.props.healthIssues.request.finished)) {
            this.props.fetchSelectedUserHealthIssues(this.props.selectedUser.id, null);
        }
    }

    /**
     * Selects the first health issue just not to be null , when compared with the other health issues
     * @param {object} healthIssue
     */
    selectFirst = (healthIssue) => {
        this.setState({
            selectedHealthIssue: healthIssue,
        })
    }

    /**
     * An event handler triggered when a new health issue is choosen
     * from the list of health
     *
     * @param {object} healthIssue - the healthIssue object
     */
    onHealthIssueChange = (healthIssue) => {
        let selectedHealthIssueId = healthIssue.id;
        this.setState({
            selectedHealthIssueId: selectedHealthIssueId,
            selectedHealthIssue: healthIssue
        });
    }


    /**
     * Filters the health issues based on search values
     * @returns
     */
    getFilteredIssues = () => {
        let filteredByName = this.props.healthIssues.entries ? this.props.healthIssues.entries.filter(u => {
            if (!this.state.searchValue) {
                return true;
            }
            return u.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1;
        }) : [];

        return filteredByName.filter((issue) => {
            if (this.state.selectedType === "1") {
                return issue.date_ended ? moment(issue.date_ended).valueOf() < moment().valueOf() : false
            } else if (this.state.selectedType === "0") {
                return issue.date_ended ? moment(issue.date_ended).valueOf() >= moment().valueOf() : true
            } else return true;
        })
    }


    /**
     * Get the health issues grouped by date time
     *
     * @returns {Array} of grouped health issues
     */
    getHealthIssues = () => {
        let noHealthIssuesObj = {
            imgClass: 'no-entry-image',
            objClass: 'no-data-object',
            primaryLabelClass: 'no-health-issues-primary-label',
            secondaryLabelClass: 'no-health-issues-secondary-label',
            src: no_data,
            primaryLabel: $$('health_issues_no_data_primary_label'),
        }

        if (this.state.filteredHealthIssues && this.state.filteredHealthIssues.length > 0) {
            return this.state.filteredHealthIssues.map((h, i) => {
                return <HealthIssuesListItem
                    key={i}
                    selectedUserId={this.props.selectedUser.id}
                    healthIssue={h}
                    selectedHealthIssue={this.state.selectedHealthIssue}
                    showAddUpdateModal={this.showAddUpdateModal}
                    showDeletedConfirmationModal={this.showDeletedConfirmationModal}
                    onHealthIssueChange={this.onHealthIssueChange}
                    i18n={this.props.i18n}/>
            })
        } else {
            return infoUtils.noData(noHealthIssuesObj)
        }
    }

    render() {
        let domNode = document.getElementById('new-symptom-btn');
        return (
            <div className="wrapping-div">
                <HealthIssuesHeader
                    filterIssuesByName={this.filterIssuesByName}
                    healthIssueOptions={getResolvedOptions(HEALTH_ISSUE_OPTIONS.TYPE)}
                    pickedOption={this.state.selectedType}
                    onSelectChange={this.onSelectChange}
                />
                {!this.state.addUpdateModal.show && this.props.healthIssues &&
                    <div>
                        {!this.state.loaded && <div className={"med-search-loader"}/>}
                        {this.state.loaded && this.getHealthIssues()}
                    </div>}
                <CenteredModal title={$$('mark_health_issue_entry_deleted_modal_title')}
                               dialogClassName='doc-delete-modal'
                               show={this.state.deletedConfirmationModal.show}
                               onHide={() => this.setState({deletedConfirmationModal: {show: false}})}
                               cancelBtnLabel={$$('cancel_btn')}
                               onConfirm={this.onDeleteConfirmModal}
                               id="delete-modal-title"
                               className="center-delete-modal"
                               idFooter="footer-delete-modal"
                               confirmBtnLabel={$$('delete_label')}
                               confirmBtnClass="danger"
                               idBtnPrimary="btn-danger"
                               idBtnSecondary="btn-secondary">
                    {$$('mark_health_issue_entry_deleted_modal_message')}
                    <div className="bin-align">
                        <i className="fas fa-trash-alt fa-3x"/>
                    </div>
                </CenteredModal>
                {this.state.addUpdateModal.show &&
                    <HealthIssueForm
                        show={this.state.addUpdateModal.show}
                        i18n={this.props.i18n}
                        onHide={this.onHideAddUpdateModal}
                        selectedUser={this.props.selectedUser}
                        selectedEntry={this.state.selectedEntry}
                        createEntry={this.props.createHealthIssue}
                        updateEntry={this.props.updateHealthIssue}
                        clearSelectedEntry={this.clearSelectedEntry}
                        formDisabled={this.props.formDisabled}
                        settings={this.props.settings}
                    />}
                {!this.state.addUpdateModal.show && ReactDOM.createPortal(
                    <div onClick={() => {
                        this.showAddUpdateModal(null)
                    }} className="blue-button-container">
                        <i className="add-new-button fa fa-plus"
                           style={{"display": "table-cell", "verticalAlign": "middle"}}/>
                    </div>,
                    domNode
                )}
            </div>
        )
    }
}

IssueContainer.propTypes = {
    createHealthIssue: PropTypes.func,
    deleteHealthIssue: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    formDisabled: PropTypes.object,
    healthIssues: PropTypes.object,
    i18n: PropTypes.object,
    settings: PropTypes.any,
    selectedUser: PropTypes.object,
    updateHealthIssue: PropTypes.func
}

function mapStateToProps(state) {
    return {
        selectedUser: state.selectedUser.data,
        healthIssues: state.healthIssues,
        formDisabled: state.formInteractions
    }
}

export default connect(mapStateToProps, {
    fetchSelectedUserHealthIssues,
    createHealthIssue,
    updateHealthIssue,
    deleteHealthIssue
})(IssueContainer);

import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {CONVERTER} from '../../utils/converter'
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";
import PropTypes from "prop-types";

export class LogbookCholesterol extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
    }


    getSmallColumn = (name, value, color) => {
        return <div className="blood-pressure-column">
            <div className="row vitals-mini-name">{$$(name).replace("_", " ").toUpperCase()}</div>
            <div className="row">
                {name === "pulse_label" && <i className="fa fa-heart pulse-icon"/>}
                <div className="vitals-value" style={color ? {"color": color} : null}>{value}</div>
            </div>
        </div>
    }

    render() {

        return <div className="logbook-entry low-shadow-container condition-card">
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <div className="d-flex">
                        <div className="medrec-grey-2 hide-on-big">
                            <i className="fa fa-clock"/>
                            <span
                                className="time-for-symptoms medrec-blue-1"> {CONVERTER.getEntryDateTimeFormat(this.props.entry.date_time)}</span>
                        </div>
                    </div>
                    <div className="logbook-entry-title">
                        {$$(this.props.entry.entry_type.toLowerCase())}
                    </div>
                    <div className="d-flex" style={{flexWrap: "wrap"}}>
                        {this.getSmallColumn("total_label", CONVERTER.cholesterolPerUnit(this.props.entry.total_cholesterol))}
                        {this.getSmallColumn("ldl_label", this.props.entry.ldl)}
                        {this.getSmallColumn("hdl_label", this.props.entry.hdl)}
                        {this.getSmallColumn("triglycerides", this.props.entry.triglycerides)}
                    </div>
                    {this.props.entry.notes && !this.props.entry.hideNotes ?
                        <div className="mt-2">{$$("notes")}: {this.props.entry.notes}</div> : ""}
                </div>
                <div className="col-md-6 col-sm-12">{this.props.getHealthIssueList(this.props.entry)}</div>
            </div>
            <div className="row" style={{justifyContent: "flex-end", paddingRight: "10px"}}>
                <DownloadEditDeleteButtons
                    handleEditClick={() => this.props.onShowEditModal(this.props.entry)}
                    handleDeleteClick={() => this.props.onShowModal(this.props.entry)}
                />
            </div>
        </div>
    }
}

LogbookCholesterol.propTypes = {
    entry: PropTypes.object,
    getHealthIssueList: PropTypes.func,
    onShowEditModal: PropTypes.func,
    onShowModal: PropTypes.func,
    unit: PropTypes.string
};

export default LogbookCholesterol;

import {
    CHANGE_DOCUMENTS_FILTERS,
    CLEAR_DOC, CLEAR_USER_DOCUMENTS,
    CREATE_DOCUMENT_ENTRY_ERROR,
    CREATE_DOCUMENT_ENTRY_REQUEST_SENT,
    CREATE_DOCUMENT_ENTRY_SUCCESS,
    DELETE_DOCUMENT_ENTRY_ERROR,
    DELETE_DOCUMENT_ENTRY_REQUEST_SENT,
    DELETE_DOCUMENT_ENTRY_SUCCESS,
    DOCUMENT,
    DUMMY,
    FETCH_LOGGED_USER_DOCUMENTS_ERROR,
    FETCH_LOGGED_USER_DOCUMENTS_REQUEST_SENT,
    FETCH_LOGGED_USER_DOCUMENTS_SUCCESS,
    FETCH_SELECTED_USER_DOCUMENTS_ERROR,
    FETCH_SELECTED_USER_DOCUMENTS_REQUEST_SENT,
    FETCH_SELECTED_USER_DOCUMENTS_SUCCESS,
    NO_MORE_LOGGED_USER_DOCUMENTS,
    NO_MORE_SELECTED_USER_DOCUMENTS,
    RESET_LOGGED_USER_DOCUMENTS_SUCCESS,
    RESET_SELECTED_USER_DOCUMENTS_SUCCESS,
    SET_FORM_TO_DISABLED,
    SET_FORM_TO_ENABLED,
    UPDATE_DOCUMENT_ENTRY_ERROR,
    UPDATE_DOCUMENT_ENTRY_REQUEST_SENT,
    UPDATE_DOCUMENT_ENTRY_SUCCESS
} from './actions';
import moment from 'moment';
import {documentsService} from '../service/docments_service';

/**
 * Fetch the uploaded documents of the selected user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @param {boolean} resetList - flag, if set to true reset the documents list
 * @returns {function} dispatch function
 */
export function fetchSelectedUserDocuments(userId, params, resetList) {
    return (dispatch, getState) => {
        if (!params) {
            const beforeDateTime = getState().documents.filters.before_date_time;
            params = {
                before_date_time: beforeDateTime ? beforeDateTime : moment().valueOf(),
                size: 500
            };
        }
        dispatch({type: FETCH_SELECTED_USER_DOCUMENTS_REQUEST_SENT});
        return documentsService.fetchSelectedUserDocuments(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res && res.length > 0) {
                if (resetList) {
                    dispatch({type: RESET_SELECTED_USER_DOCUMENTS_SUCCESS, result: res});
                } else {
                    dispatch({type: FETCH_SELECTED_USER_DOCUMENTS_SUCCESS, result: res});
                }
            } else {
                dispatch({type: NO_MORE_SELECTED_USER_DOCUMENTS});
                if (resetList) {
                    dispatch({type: RESET_SELECTED_USER_DOCUMENTS_SUCCESS, result: res});
                }
            }
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_USER_DOCUMENTS_ERROR, result: err});
        })
    }
}

/**
 * Fetch the uploaded documents of the currently logged user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the logged user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchLoggedUserDocuments(userId, params, resetList) {
    return (dispatch, getState) => {
        if (!params) {
            const beforeDateTime = getState().documents.filters.before_date_time;
            params = {
                before_date_time: beforeDateTime ? beforeDateTime : moment().valueOf(),
                size: 500
            };
        }
        dispatch({type: FETCH_LOGGED_USER_DOCUMENTS_REQUEST_SENT});
        const userId = userId || getState().userInfo && getState().userInfo.data ? getState().userInfo.data.id : '';
        return documentsService.fetchSelectedUserDocuments(userId, params).then((res) => {
            if (res && res.length > 0) {
                if (resetList) {
                    dispatch({type: RESET_LOGGED_USER_DOCUMENTS_SUCCESS, result: res});
                } else {
                    dispatch({type: FETCH_LOGGED_USER_DOCUMENTS_SUCCESS, result: res});
                }
            } else {
                dispatch({type: NO_MORE_LOGGED_USER_DOCUMENTS});
                if (resetList) {
                    dispatch({type: RESET_LOGGED_USER_DOCUMENTS_SUCCESS, result: res});
                }
            }
        }).catch((err) => {
            dispatch({type: FETCH_LOGGED_USER_DOCUMENTS_ERROR, result: err});
        })
    }
}

/**
 * Change the filters to apply to chart requests.
 *
 * @param {object} filters - the new set of filters
 * @returns {function} dispatch function
 */
export function changeDocumentsFilters(filters) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_DOCUMENTS_FILTERS,
            documentType: filters.type,
            category: filters.category,
            beforeDateTime: filters.beforeDateTime,
            afterDateTime: filters.afterDateTime
        });
    }
}

/**
 *  Create a document entry
 *
 * @param {object} entry The entry to be created
 */
export function createDocument(entry, userId) {
    return (dispatch, getState) => {
        dispatch({type: SET_FORM_TO_DISABLED, formName: DOCUMENT});
        dispatch({type: CREATE_DOCUMENT_ENTRY_REQUEST_SENT});
        return documentsService.createDocument(entry).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: SET_FORM_TO_ENABLED});
            dispatch({type: CREATE_DOCUMENT_ENTRY_SUCCESS, result: res});
            dispatch(fetchSelectedUserDocuments(userId, null, true));
        }).catch((err) => {
            dispatch({type: SET_FORM_TO_ENABLED});
            dispatch({type: CREATE_DOCUMENT_ENTRY_ERROR, result: err});
        });
    }
}

export function clearUserDocuments() {
    return (dispatch) => {
        dispatch({type: CLEAR_USER_DOCUMENTS});
    }
}


/**
 *  Update a document entry
 *
 * @param {object} entry The entry to be updated
 */
export function updateDocument(entry, userId) {
    return (dispatch, getState) => {
        dispatch({type: SET_FORM_TO_DISABLED, formName: DOCUMENT});
        dispatch({type: UPDATE_DOCUMENT_ENTRY_REQUEST_SENT});
        return documentsService.updateDocument(entry).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: SET_FORM_TO_ENABLED});
            dispatch({type: UPDATE_DOCUMENT_ENTRY_SUCCESS, result: res});
            dispatch(fetchSelectedUserDocuments(userId, null, true));
        }).catch((err) => {
            dispatch({type: SET_FORM_TO_ENABLED});
            dispatch({type: UPDATE_DOCUMENT_ENTRY_ERROR, result: err});
        });
    }
}

/**
 *  Delete a document entry
 *
 * @param {number} documentId The entry to be deleted
 */
export function deleteDocument(documentId, userId) {
    return (dispatch, getState) => {
        dispatch({type: DELETE_DOCUMENT_ENTRY_REQUEST_SENT});
        return documentsService.deleteDocument(documentId).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: DELETE_DOCUMENT_ENTRY_SUCCESS, result: res});
            dispatch(fetchSelectedUserDocuments(userId, null, true));
        }).catch((err) => {
            dispatch({type: DELETE_DOCUMENT_ENTRY_ERROR, result: err});
        });
    }
}

/**
 * Clear the documents action.
 *
 * @returns {function} dispatch function
 */
export function clearDoc() {
    return (dispatch) => {
        dispatch({type: CLEAR_DOC});
    }
}
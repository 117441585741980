import React, {Component} from 'react'
import DisplayHospitals from './DisplayHospitals';
import HospitalCliniciansView from './HospitalCliniciansView';
import PropTypes from "prop-types";
import no_appointments from "../../resources/images/no_appointments.png";
import {$$} from "../../helpers/localization";
import {infoUtils} from "../../utils/infoUtils";

class HospitalsListView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showClinicians: false,
            selectedHospital: null,
            hospitals:{}
        }
    }

    componentDidMount() {
        let hospitals = this.getHospitals();
        this.setState({hospitals: hospitals})

        const queryParams = new URLSearchParams(this.props.locationSearch)
        if (queryParams.has('org')) {
            let clinicId = queryParams.get('org');

            for(let i in hospitals){
                if(hospitals[i].hospital.id === clinicId){
                    this.showClinicians(hospitals[i])
                }
            }
        }
    }


    /**
     * Filters the clients based on the search value
     *
     * @returns {Array} the list of filtered clients
     */
    getFilteredClinicians = () => {
        return this.props.providers ? this.props.providers.filter(u => {
            let shouldReturn = true;
            if (!this.props.searchValue) {
                return true;
            }
            shouldReturn = u.fullname.toLowerCase().indexOf(this.props.searchValue.toLowerCase()) !== -1;
            u.specialties.map((specialty) => {
                if (specialty.replace("_", " ").toLowerCase().indexOf(this.props.searchValue.toLowerCase()) !== -1) shouldReturn = true
            })

            return shouldReturn
        }) : [];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.providers !== prevProps.providers || this.props.searchValue !== prevProps.searchValue){
            let hospitals = this.getHospitals();
            this.setState({hospitals: hospitals})

            if(this.state.selectedHospital){
                for(let i in hospitals){
                    if(hospitals[i].hospital.id ===  this.state.selectedHospital.hospital.id){
                        this.setSelectedHospital(hospitals[i]);
                    }

                }
            }
        }
    }



    getHospitals = () => {
        let clinicians = this.getFilteredClinicians();
        let hospitals = this.props.hospitals.hospitals;
        let hospitalsUpdated = []

        hospitals?.map((hospital) => {
            hospitalsUpdated.push({hospital, clinicians: []})
        })

        hospitalsUpdated.map((hospital) => {
            clinicians.map((clinician) => {
                clinician.organizations.map((organization) => {
                    if (organization.id === hospital.hospital.id) {
                        hospital.clinicians.push(clinician);
                    }
                })
            })
        })

        let filteredHospitals = []

        if(this.props.searchValue || !(this.props.specialties === "" || this.props.specialties === "ALL" )){
            for(let i in hospitalsUpdated){
                if(hospitalsUpdated[i].clinicians.length > 0){
                    filteredHospitals.push(hospitalsUpdated[i]);
                }
            }
        } else {
            filteredHospitals = hospitalsUpdated;
        }

        return filteredHospitals;
    }

    showClinicians = (hospital) => {
        this.setSelectedHospital(hospital)
        this.setState({showClinicians: true})
    }

    hideClinicians = () => {
        this.setState({showClinicians: false})
    }

    setSelectedHospital = (hospital) => {
        this.setState({selectedHospital: hospital})
    }

    render() {
        let noData = {
            imgClass: 'no-entry-image',
            objClass: 'no-data-object',
            primaryLabelClass: 'no-clinicians-primary-label',
            secondaryLabelClass: 'no-clinicians-secondary-label',
            src: no_appointments,
            primaryLabel: $$('no_results_found'),
        }
        return (
            <>
                {this.props.loading &&  <div className={"med-search-loader"} />}
                {!this.props.loading && this.state.hospitals.length === 0 && infoUtils.noData(noData)}
                {(!this.props.loading && this.state.hospitals.length > 0) && (this.state.showClinicians ?
                    <HospitalCliniciansView
                        hospital={this.state.selectedHospital}
                        hideClinicians={this.hideClinicians}
                        onClinicianClick={this.props.onClinicianClick}
                    />
                    :
                    <div className="clinicians-container">
                        {
                            this.state.hospitals.map((hospital) =>
                                <div className="high-shadow-container clinician-container pointer"
                                     key={hospital.hospital.id} onClick={() => this.showClinicians(hospital)}>
                                    <DisplayHospitals
                                        hospital={hospital}
                                        i18n={this.props.i18n}
                                        showClinicians={this.showClinicians}
                                    />
                                </div>
                            )
                        }
                    </div>)
                }
            </>
        )
    }
}

HospitalsListView.propTypes = {
    hospitals: PropTypes.object,
    i18n: PropTypes.object,
    loading: PropTypes.bool,
    onClinicianClick: PropTypes.func,
    providers: PropTypes.array,
    searchValue: PropTypes.any,
    specialties: PropTypes.any,
    locationSearch: PropTypes.any,
}

export default HospitalsListView;
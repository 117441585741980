import {fetchHelper} from '../helpers/request_helper';
import {
    ALL_USERS_URL,
    CLINICIAN_PUBLIC_PHOTO_URL,
    DELETE_CHILD_IMAGE_URL,
    DELETE_USER_IMAGE_URL,
    DELETE_USER_URL,
    GET_ENCOUNTER_OF_THE_APPOINTMENT,
    GET_USER_INFO_URL,
    LATEST_MEASUREMENTS_FOR_USER,
    MEDICAL_PROFILE_FOR_USER,
    UPDATE_ENCOUNTER_DOCUMENTS_URL,
    UPDATE_USER_INFO_URL,
    UPDATE_USER_PASSWORD_URL,
    UPDATE_USER_PHONE_AND_NAMES_URL,
    UPGRADE_CHILD_TO_STANDALONE,
    UPLOAD_CHILD_IMAGE_URL,
    UPLOAD_USER_IMAGE_URL,
    USER_CHART_DATA_URL,
    USER_PICTURE_URL
} from '../constants/api_paths';

export const usersService = {
    getAllUsers,
    fetchSelectedUserChartsData,
    fetchSelectedUserLatestMeasurements,
    fetchSelectedUserMedicalProfile,
    updateUserMedicalProfile,
    fetchAppointmentEncounter,
    getUserImage,
    getUserInfo,
    updateUserInfo,
    updateUserPhoneAndNames,
    updateUserPassword,
    upgradeChildToFullUser,
    deleteUser,
    updateEncounterDocuments
};


/**
 * Get all chat users method
 *
 * @returns {object} promise object
 */
export function getAllUsers() {
    return fetchHelper.callGet(ALL_USERS_URL);
}


/**
 * Get logged user info
 *
 * @returns {object} promise object
 */
export function getUserInfo() {
    return fetchHelper.callGet(GET_USER_INFO_URL);
}

/**
 * Update logged user info
 *
 * @param {object} data - {phone,fullname} object to save
 * @returns {object} promise object
 */
export function updateUserPhoneAndNames(data) {
    return fetchHelper.callPut(UPDATE_USER_PHONE_AND_NAMES_URL, data);
}

/**
 * Update logged user info
 *
 * @param {object} data the user info to save
 * @returns {object} promise object
 */
export function updateUserInfo(data) {
    return fetchHelper.callPut(UPDATE_USER_INFO_URL, data);
}

/**
 * Upgrade child user to standalone
 *
 * @param {object} data the user info to convert
 * @returns {object} promise object
 */
export function upgradeChildToFullUser(data) {
    return fetchHelper.callPut(UPGRADE_CHILD_TO_STANDALONE, data);
}

/**
 * Update logged user password
 *
 * @param {object} data the user password to save
 * @returns {object} promise object
 */
export function updateUserPassword(data) {
    return fetchHelper.callPut(UPDATE_USER_PASSWORD_URL, data);
}

/**
 * Get user image
 *
 * @param {string} userId Id of the user to get image for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function getUserImage(userId, params) {
    return fetchHelper.getBlob(USER_PICTURE_URL.replace('{userId}', userId), params);
}

/**
 * Get clinician photo
 *
 * @param {string} userId Id of the clinician
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function getClinicianPhoto(userId, params) {
    return fetchHelper.getBlob(CLINICIAN_PUBLIC_PHOTO_URL.replace('{userId}', userId), params);
}

/**
 * Upload user image
 *
 * @param {object} file the image to be uploaded
 * @param {object} user the user
 * @param {string} parentID the id of the parent
 * @returns {object} promise object
 */
export function uploadImage(file, user, parentID) {
    if (user.id === parentID) {
        return fetchHelper.uploadFile(UPLOAD_USER_IMAGE_URL, file);
    } else {
        return fetchHelper.uploadFile(UPLOAD_CHILD_IMAGE_URL.replace('{childId}', user.id), file);
    }
}

/**
 * Delete user image
 *
 * @returns {object} promise object
 */
export function deleteImage(user, parentID) {
    if (user.id === parentID) {
        return fetchHelper.callPost(DELETE_USER_IMAGE_URL);
    } else {
        return fetchHelper.callPost(DELETE_CHILD_IMAGE_URL.replace('{childId}', user.id));
    }
}

/**
 * Fetch the selected user chart data
 *
 * @param {number} userId id of the user to fetch the chart data
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserChartsData(userId, params) {
    return fetchHelper.callGet(USER_CHART_DATA_URL.replace('{userId}', userId), params);
}

/**
 * Fetch the latest logged measurements of the given user
 *
 * @param {number} userId id of the user to fetch entries for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserLatestMeasurements(userId, params) {
    return fetchHelper.callGet(LATEST_MEASUREMENTS_FOR_USER.replace('{userId}', userId), params);
}

/**
 * Fetch the medical profile of the given user
 *
 * @param {number} userId id of the user to fetch the profile for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserMedicalProfile(userId, params) {
    return fetchHelper.callGet(MEDICAL_PROFILE_FOR_USER.replace('{userId}', userId), params);
}

/**
 * Update the medical profile of the given user
 *
 * @param {number} userId id of the user to update the profile for
 * @param {object} body body of the request
 * @returns {object} promise object
 */
export function updateUserMedicalProfile(userId, body) {
    return fetchHelper.callPut(MEDICAL_PROFILE_FOR_USER.replace('{userId}', ""), body);
}


/**
 * Fetch the encounter of the given appointment
 *
 * @param {number} encounterId id of the encounter
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchAppointmentEncounter(encounterId, params) {
    return fetchHelper.callGet(GET_ENCOUNTER_OF_THE_APPOINTMENT.replace('{encounterId}', encounterId), params);
}


export function deleteUser() {
    return fetchHelper.callDelete(DELETE_USER_URL, {});
}

export function updateEncounterDocuments(userId, encounterId, document_ids) {
    return fetchHelper.callPut(UPDATE_ENCOUNTER_DOCUMENTS_URL.replace("{encounterId}", encounterId), document_ids, {userId: userId});
}
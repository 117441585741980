import {medicationsService} from '../service/medications_service'
import {
    CLEAR_EVENTS,
    CLEAR_MED, CLEAR_MEDICATION_PLAN,
    CREATE_MEDICATION_DATA_ERROR,
    CREATE_MEDICATION_DATA_REQUEST_SENT,
    CREATE_MEDICATION_DATA_SUCCESS,
    CREATE_MEDICATION_EVENT_ERROR,
    CREATE_MEDICATION_EVENT_REQUEST_SENT,
    CREATE_MEDICATION_EVENT_SUCCESS,
    DELETE_MED_EVENTS_ENTRIES_ERROR,
    DELETE_MED_EVENTS_ENTRIES_REQUEST_SENT,
    DELETE_MED_EVENTS_ENTRIES_SUCCESS,
    DELETE_MEDICATION_EVENTS_ERROR,
    DELETE_MEDICATION_EVENTS_REQUEST_SENT,
    DELETE_MEDICATION_EVENTS_SUCCESS,
    DELETE_MEDICATION_PLAN_ERROR,
    DELETE_MEDICATION_PLAN_REQUEST_SENT,
    DELETE_MEDICATION_PLAN_SUCCESS,
    DUMMY,
    FETCH_CHILD_MED_ENTRIES_FOR_MED_EVENTS_ERROR,
    FETCH_CHILD_MED_ENTRIES_FOR_MED_EVENTS_SUCCESS,
    FETCH_MED_ENTRIES_FOR_MED_EVENTS_ERROR,
    FETCH_MED_ENTRIES_FOR_MED_EVENTS_SUCCESS,
    FETCH_SELECTED_CHILD_MEDICATIONS_ERROR,
    FETCH_SELECTED_CHILD_MEDICATIONS_SUCCESS,
    FETCH_SELECTED_USER_MEDICATIONS_ERROR,
    FETCH_SELECTED_USER_MEDICATIONS_SUCCESS,
    GET_CHILD_MEDICATION_EVENTS_ERROR,
    GET_CHILD_MEDICATION_EVENTS_SUCCESS,
    GET_MEDICATION_EVENTS_ERROR,
    GET_MEDICATION_EVENTS_SUCCESS,
    GET_SELECTED_MEDICAL_PLAN_STATS,
    MEDICATIONS,
    SET_FORM_TO_DISABLED,
    SET_FORM_TO_ENABLED,
    UPDATE_MEDICATION_DATA_ERROR,
    UPDATE_MEDICATION_DATA_REQUEST_SENT,
    UPDATE_MEDICATION_DATA_SUCCESS,
    UPDATE_MEDICATION_ENTRY_ERROR,
    UPDATE_MEDICATION_ENTRY_REQUEST_SENT,
    UPDATE_MEDICATION_ENTRY_SUCCESS,
    UPDATE_MEDICATION_EVENT_ERROR,
    UPDATE_MEDICATION_EVENT_REQUEST_SENT,
    UPDATE_MEDICATION_EVENT_SUCCESS
} from './actions';

/**
 * Fetch selected user medication plan
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchSelectedUserMedicationPlan(userId, params) {
    return (dispatch, getState) => {
        return medicationsService.fetchSelectedUserMedicationPlan(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_SELECTED_USER_MEDICATIONS_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_USER_MEDICATIONS_ERROR, result: err});
        })
    }
}

/**
 * Search for a medication
 * @param {object} params - params to send with the request
 */
export function searchForMedication(params){
    return medicationsService.searchForMedication(params)
}

export function clearMedicationPlan() {
    return (dispatch) => {
        dispatch({type: CLEAR_MEDICATION_PLAN });
    }
}

/**
 * Fetch selected child medication plan
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchSelectedChildMedicationPlan(userId, params) {
    return (dispatch, getState) => {
        return medicationsService.fetchSelectedUserMedicationPlan(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_SELECTED_CHILD_MEDICATIONS_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_CHILD_MEDICATIONS_ERROR, result: err});
        })
    }
}

/**
 *  Delete a medication plan
 *
 * @param {number} planId The medication plan to be deleted
 */
export function deleteMedicationPlan(planId, userId) {
    return (dispatch, getState) => {
        dispatch({type: DELETE_MEDICATION_PLAN_REQUEST_SENT});
        return medicationsService.deleteMedicationPlan(planId, userId).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: DELETE_MEDICATION_PLAN_SUCCESS, result: res});
            dispatch(fetchSelectedUserMedicationPlan(userId, null));
        }).catch((err) => {
            dispatch({type: DELETE_MEDICATION_PLAN_ERROR, result: err});
        });
    }
}

/**
 * Update a medication
 *
 * @param {object} entry The entry to be updated
 */
export function updateMedication(entry, userId) {
    return (dispatch, getState) => {
        dispatch({type: SET_FORM_TO_DISABLED, formName: MEDICATIONS});
        dispatch({type: UPDATE_MEDICATION_DATA_REQUEST_SENT});
        return medicationsService.updateMedication(entry).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: SET_FORM_TO_ENABLED});
            dispatch({type: UPDATE_MEDICATION_DATA_SUCCESS, result: res});
            dispatch(fetchSelectedUserMedicationPlan(userId, null));

        }).catch((err) => {
            dispatch({type: SET_FORM_TO_ENABLED});
            dispatch({type: UPDATE_MEDICATION_DATA_ERROR, result: err});
        });
    }
}

/**
 *  Create a medication entry
 *
 * @param {object} entry The entry to be created
 */
export function createMedication(entry, userId) {
    return (dispatch, getState) => {
        dispatch({type: SET_FORM_TO_DISABLED, formName: MEDICATIONS});
        dispatch({type: CREATE_MEDICATION_DATA_REQUEST_SENT});
        return medicationsService.createMedication(entry).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: CREATE_MEDICATION_DATA_SUCCESS, result: res});
            dispatch(fetchSelectedUserMedicationPlan(userId, null));
        }).catch((err) => {
            dispatch({type: CREATE_MEDICATION_DATA_ERROR, result: err});
        });
    }
}

/**
 *  get plan statistics
 * */
export function getPlanStats(userId, planId) {
    return (dispatch, getState) => {
        return medicationsService.getPlanStats(userId, planId).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: GET_SELECTED_MEDICAL_PLAN_STATS, result: res})
            }
        }).catch((err) => {
            dispatch({type: GET_SELECTED_MEDICAL_PLAN_STATS, result: err});
        })
    }
}

/**
 * Get medication events
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function getMedicationEvents(userId, params) {
    return (dispatch, getState) => {
        return medicationsService.getMedicationEvents(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: GET_MEDICATION_EVENTS_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: GET_MEDICATION_EVENTS_ERROR, result: err});
        })
    }
}

/**
 * Get medication events for connected profiles
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function getMedicationEventsForChildren(userId, params) {
    return (dispatch, getState) => {
        return medicationsService.getMedicationEvents(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: GET_CHILD_MEDICATION_EVENTS_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: GET_CHILD_MEDICATION_EVENTS_ERROR, result: err});
        })
    }
}

/**
 * Fetches Medication entries for Medication events
 * @param {*} userId - id of the user to fetch the medication entries/event for
 * @param {*} body - other params sent together with the request
 * @returns
 */
export function getMedEntriesForMedEvents(userId, body) {
    return (dispatch, getState) => {
        return medicationsService.getMedEntriesForMedEvents(userId, body).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_MED_ENTRIES_FOR_MED_EVENTS_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: FETCH_MED_ENTRIES_FOR_MED_EVENTS_ERROR, result: err});
        })
    }
}

/**
 * Fetches Medication entries for Medication events for children
 * @param {*} userId - id of the user to fetch the medication entries/event for
 * @param {*} body - other params sent together with the request
 * @returns
 */
export function getMedEntriesForMedEventsChild(userId, body) {
    return (dispatch, getState) => {
        return medicationsService.getMedEntriesForMedEvents(userId, body).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_CHILD_MED_ENTRIES_FOR_MED_EVENTS_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: FETCH_CHILD_MED_ENTRIES_FOR_MED_EVENTS_ERROR, result: err});
        })
    }
}

export function clearMed() {
    return (dispatch) => {
        dispatch({type: CLEAR_MED});
    }
}

export function clearMedEvents() {
    return (dispatch) => {
        dispatch({type: CLEAR_EVENTS});
    }
}

/**
 *  Delete medication events with the given plan id
 *
 * @param {number} planId The med planId whose medication events are to be deleted
 */
export function deleteMedicationEvents(planId, userId) {
    return (dispatch, getState) => {
        dispatch({type: DELETE_MEDICATION_EVENTS_REQUEST_SENT});
        return medicationsService.deleteMedicationEvents(planId, userId).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: DELETE_MEDICATION_EVENTS_SUCCESS, result: res});
        }).catch((err) => {
            dispatch({type: DELETE_MEDICATION_EVENTS_ERROR, result: err});
        });
    }
}

/**
 *  Delete medication events' entries with the given plan id
 *
 * @param {number} planId The med planId whose medication events' entries are to be deleted
 */
export function deleteMedEventsEntries(planId, userId) {
    return (dispatch, getState) => {
        dispatch({type: DELETE_MED_EVENTS_ENTRIES_REQUEST_SENT});
        return medicationsService.deleteMedEventsEntries(planId, userId).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: DELETE_MED_EVENTS_ENTRIES_SUCCESS, result: res});
        }).catch((err) => {
            dispatch({type: DELETE_MED_EVENTS_ENTRIES_ERROR, result: err});
        });
    }
}

/**
 *  Create a medication event
 *
 * @param {object} event The event to be created
 */
export function createMedicationEvent(event, userId) {
    return (dispatch, getState) => {
        dispatch({type: CREATE_MEDICATION_EVENT_REQUEST_SENT});
        return medicationsService.createMedicationEvent(event).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: CREATE_MEDICATION_EVENT_SUCCESS, result: res});
        }).catch((err) => {
            dispatch({type: CREATE_MEDICATION_EVENT_ERROR, result: err});
        });
    }
}

/**
 *  Update a medication event
 *
 * @param {object} event The event to be updated
 */
export function updateMedicationEvent(event, userId) {
    return (dispatch, getState) => {
        dispatch({type: UPDATE_MEDICATION_EVENT_REQUEST_SENT});
        return medicationsService.updateMedicationEvent(event).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: UPDATE_MEDICATION_EVENT_SUCCESS, result: res});
        }).catch((err) => {
            dispatch({type: UPDATE_MEDICATION_EVENT_ERROR, result: err});
        });
    }
}

/**
 *  Update a medication entry
 *
 * @param {object} entry The entry to be updated
 */
export function updateMedicationEntry(entry, userId) {
    return (dispatch, getState) => {
        dispatch({type: UPDATE_MEDICATION_ENTRY_REQUEST_SENT});
        return medicationsService.updateMedicationEntry(entry).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: UPDATE_MEDICATION_ENTRY_SUCCESS, result: res});
        }).catch((err) => {
            dispatch({type: UPDATE_MEDICATION_ENTRY_ERROR, result: err});
        });
    }
}
import React, {Component} from 'react'
import PropTypes from "prop-types";
import {getcountry_labelName} from "../../constants/countries";
import ClinicInfoModal from "./ClinicInfoModal";
import {isLoggedIn} from "../../helpers/auth_helper";
import {ClinicianFilters} from "./ClinicianFilters";


class Hospital extends Component {

    constructor(props) {
        super(props)
        this.state =
            {
                hospital: ""
            }
    }

    componentDidMount() {
        this.setState({hospital: this.props.hospital.hospital})
    }

    render() {
        const {hospital} = this.state;
        return (
            <div style={{width:"100%"}}>
                <div className='flex-space-between flex-wrap w-100'>
                    <div>
                        <h4 style={{"fontSize": "1.8rem"}}>{hospital.name} <span onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.setState({show_info: true})
                        }}><i className="fa fa-info-circle"/></span></h4>
                        <h6 style={{"color": "#9BABB6"}}>{hospital.city}, {getcountry_labelName(this.props.i18n.selected.lang, hospital.country || "")}, {hospital.address}</h6>
                        {
                            hospital.phone_numbers && hospital.phone_numbers.map((number, id) =>
                                <h6 key={id}>
                                    {number}
                                </h6>
                            )
                        }
                        <a href={hospital.website} target="_blank" rel="noreferrer">{hospital.website}</a>
                        {this.state.show_info && <div onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}><ClinicInfoModal show={true} hospital={this.props.hospital.hospital} onHide={() => {
                            this.setState({show_info: false})
                        }}/></div>}
                    </div>
                    {!isLoggedIn() && <>
                        <div className="flex-wrap" style={{'marginLeft': '2rem'}}>
                            <ClinicianFilters
                                specialtiesOptions = {this.props.specialtiesOptions}
                                specialties = {this.props.selectedSpecialtyFilter}
                                onSelectChange = {this.props.onSelectChange}
                                filterCliniciansByName = {this.props.filterCliniciansByName}
                                i18n = {this.props.i18n}
                                marginTop = {"1.5rem"}
                            />
                        </div>
                    </>}

                </div>
            </div>
        )
    }
}

Hospital.propTypes = {
    hospital: PropTypes.object,
    specialtiesOptions: PropTypes.array,
    selectedSpecialtyFilter: PropTypes.any,
    onSelectChange: PropTypes.func,
    filterCliniciansByName: PropTypes.func,
    i18n: PropTypes.object
}

export default Hospital;
export const downloadUtils = {
    download
}

/**
 * Download file.
 *
 * @param {object} objectUrl the url of the object to attach to the link
 * @param {string} fileName name of the file to download
 * @returns {object} promise object
 */
function download(objectUrl, fileName) {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    const url = objectUrl;
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}
import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {
    clearAppointmentCreationStatus,
    fetchSelectedUserAppointments,
    updateAppointmentInList,
    updateAppointmentStatus
} from '../../actions/appointments_actions'
import {connect} from 'react-redux'
import no_appointments from '../../resources/images/no_appointments.png'
import {infoUtils} from "../../utils/infoUtils";
import AppointmentsListItem from './AppointmentsListItem'
import NotesTab from "../notes/NotesTab"
import PropTypes from "prop-types";
import history from "../../helpers/history";
import {CHANGE_APPOINTMENT_IN_APPOINTMENTS_LIST} from "../../actions/actions";
import {appointmentsService as clinicService, updateAppointment} from "../../service/appointments_service";
import store from '../../store'
import {dateTimeUtils} from "../../utils/dateTimeUtils";
import AppointmentsHeader from "./AppointmentsHeader";
import {APPOINTMENT_FILTER_OPTIONS, getResolvedOptions} from "../../constants/select_options";
import PaginationComponent from "../shared/Pagination";

const PAGE_SIZE = 20;

export class AppointmentsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAppointment: null,
            showNotes: false,
            selectedType: 0,
            page: 0
        }
        const queryParams = new URLSearchParams(props.location.search)
        if (queryParams.has('status')) {
            let status = queryParams.get('status');
            let appId = queryParams.get('id');
            this.state.status = status;
            this.state.paymentAppId = appId;
        }
    }


    clearPaymentStatus = () => {
        this.setState({status: undefined, paymentAppId: undefined});
        if (this.refetch) {
            clearInterval(this.refetch);
            this.refetch = null;
        }
        const queryParams = new URLSearchParams(this.props.location.search)
        queryParams.delete('status')
        queryParams.delete('id')
        history.replace({
            search: queryParams.toString(),
        })
    }

    componentDidMount() {
        if (this.props.selectedUser.id) {
            this.props.fetchSelectedUserAppointments(this.props.selectedUser.id, 0, (Date.now() + dateTimeUtils.YEAR_IN_MS))
                .then(res => {
                    if (res && res.length > 0) {
                        this.setState({selectedAppointment: res[0]})
                    }
                });
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((this.state.status === 'OK' || this.state.status === 'WAITING') && !this.refetch) {
            this.refetch = setInterval(() => {
                clinicService.fetchAppointment(this.state.paymentAppId).then((res) => {
                    if (res && res.payment_status === 'COMPLETED') {
                        store.dispatch({type: CHANGE_APPOINTMENT_IN_APPOINTMENTS_LIST, result: res});
                        clearInterval(this.refetch);
                    }
                })
            }, 5000)
        }

        let newParams = new URLSearchParams(this.props.location.search);
        let oldParams = new URLSearchParams(prevProps.location.search);

        if (newParams.toString() !== oldParams.toString() && newParams.has('status')) {
            let status = newParams.get('status');
            let appId = newParams.get('id');
            this.setState({status: status, paymentAppId: appId});
        }

        if (this.state.selectedType !== prevState.selectedType) {
            let filteredAppointments = this.getFilteredAppointments()
            this.setState({
                selectedAppointment: filteredAppointments && filteredAppointments[0] ? filteredAppointments[0]: null,
            })
        }
    }


    /**
     * An event handler triggered when a new appointment is chosen
     * from the list of appointments
     *
     * @param {object} appointment - the appointment object
     */
    onAppointmentChange = (appointment) => {
        this.setState({
            selectedAppointment: appointment,
        });
    }

    getFilteredAppointments = () => {
        return this.props.userAppointments.entries.filter((appointment) => {
            switch (this.state.selectedType) {
                case "0":
                    return true;

                case "1":
                    return appointment.status === "COMPLETED";

                case "2":
                    return !appointment.text_only && appointment.status !== "COMPLETED" && !appointment.status.includes("CANCELED");

                case "3":
                    return !!(appointment.text_only && appointment.status !== "COMPLETED" && !appointment.status.includes("CANCELED"));

                default:
                    return true;

            }
        })
    }


    /**
     * Get the appointments grouped by date time
     *
     * @returns {Array} of grouped appointments
     */
    getAppointments = () => {
        let noAppointmentsObj = {
            imgClass: 'no-entry-image',
            objClass: 'no-data-object',
            primaryLabelClass: 'no_appointments_primary_label',
            secondaryLabelClass: '',
            src: no_appointments,
            primaryLabel: $$('appointments_no_data_primary_label'),
            secondaryLabel: ''
        }

        let filteredAppointments = this.getFilteredAppointments()

        if (filteredAppointments && filteredAppointments.length > 0 /*&& this.state.selectedAppointment*/) {
            return filteredAppointments.map((h, i) => {
                return <AppointmentsListItem
                    key={h.id}
                    appointment={h}
                    selectedUser={this.props.selectedUser}
                    selectedUserId={this.props.selectedUser.id}
                    selectedAppointment={this.state.selectedAppointment}
                    onAppointmentChange={this.onAppointmentChange}
                    showNotes={this.showNotes}
                    updateAppointmentStatus={this.props.updateAppointmentStatus}
                    fetchSelectedUserAppointments={this.props.fetchSelectedUserAppointments}
                    paymentStatus={this.state.status}
                    paymentAppId={this.state.paymentAppId}
                    clearPaymentStatus={this.clearPaymentStatus}
                    i18n={this.props.i18n}/>
            })
                .slice((this.state.page * PAGE_SIZE), ((this.state.page * PAGE_SIZE) + PAGE_SIZE))
        } else if (this.props.userAppointments.request.finished || this.props.userAppointments.entries.length === 0) {
            return infoUtils.noData(noAppointmentsObj);
        }

        return <div className={"med-search-loader"}/>
    }

    showNotes = (appointment) => {
        this.setState({showNotes: true, selectedAppointment: appointment})
    }

    createNote = async (selectedAppointment, note) => {
        let newNoteArray = selectedAppointment.notes;
        newNoteArray.unshift(note);
        let form = {
            ...selectedAppointment,
            notes: newNoteArray,
        }
        await updateAppointment(form, this.props.selectedUser.id).then(app => {
            this.props.updateAppointmentInList(app)
            this.setState({selectedAppointment: app})
        });
        await this.props.clearAppointmentCreationStatus()
    }

    updateNote = async (userId, newNote, selectedAppointment) => {
        let newNoteArray = selectedAppointment.notes;
        let filtered = newNoteArray.filter((note) => note.id !== newNote.id)
        filtered.push(newNote);
        let form = {
            ...selectedAppointment,
            notes: filtered,
        }
        await updateAppointment(form, this.props.selectedUser.id).then(app => {
            this.props.updateAppointmentInList(app)
            this.setState({selectedAppointment: app})
        });
        await this.props.clearAppointmentCreationStatus()
    }

    deleteNote = async (noteId, selectedAppointment) => {
        let newNoteArray = selectedAppointment.notes;
        let filtered = newNoteArray.filter((note) => note.id !== noteId)
        let form = {
            ...selectedAppointment,
            notes: filtered,
        }
        await updateAppointment(form, this.props.selectedUser.id).then(app => {
            this.props.updateAppointmentInList(app)
            this.setState({selectedAppointment: app})
        });
        await this.props.clearAppointmentCreationStatus()
    }

    onSelectChange = (evt) => {
        this.setState({selectedType: evt.value, page: 0})
    }

    render() {
        let appointments = this.getAppointments();
        let filteredAppointments = this.getFilteredAppointments();
        return (
            <div className='wrapping-div'>
                <div className={`${this.state.showNotes ? "d-none" : ""}`}>
                    <AppointmentsHeader
                        filterOptions={getResolvedOptions(APPOINTMENT_FILTER_OPTIONS.TYPE)}
                        pickedOption={this.state.selectedType}
                        onSelectChange={this.onSelectChange}
                    />
                    <div className="list-group appointments-list">
                        {appointments}
                    </div>
                    {filteredAppointments.length > 0 &&
                        <div className="pt-3">
                            <PaginationComponent alwaysShown={false} itemsCount={filteredAppointments.length}
                                                 itemsPerPage={PAGE_SIZE}
                                                 currentPage={this.state.page + 1}
                                                 setCurrentPage={(p) => {
                                                     this.setState({page: p - 1, selectedAppointment: null});
                                                 }}/>
                        </div>}
                </div>
                {this.state.showNotes && <div>
                    <div className="mt-5 ml-3 back-to-clinicians pointer" onClick={() => {
                        this.setState({showNotes: false})
                    }}>
                        <span><i className="fa fa-arrow-left"/></span>
                        <span className="ml-2">{$$('back_to_appointments_label')}</span>
                    </div>
                    <div className="mt-5 mb-3 ml-3 mr-3">
                        <NotesTab userId={this.props.selectedUser.id}
                                  selectedAppointment={this.state.selectedAppointment}
                                  selectedUserId={this.props.selectedUser.id}
                                  selectedUser={this.props.selectedUser}
                                  notes={this.state.selectedAppointment.notes}
                                  createNote={this.createNote}
                                  updateNote={this.updateNote}
                                  deleteNote={this.deleteNote}
                                  i18n={this.props.i18n.selected}
                                  loggedUser={this.props.loggedUser}
                        />
                    </div>
                </div>}
            </div>
        )
    }
}

AppointmentsContainer.propTypes = {
    appointment: PropTypes.object,
    location: PropTypes.any,
    loggedUser: PropTypes.any,
    auth: PropTypes.object,
    fetchAppointment: PropTypes.func,
    fetchSelectedUserAppointments: PropTypes.func,
    i18n: PropTypes.object,
    selectedUser: PropTypes.object,
    updateAppointment: PropTypes.func,
    updateAppointmentInList: PropTypes.func,
    clearAppointmentCreationStatus: PropTypes.func,
    updateAppointmentStatus: PropTypes.func,
    userAppointments: PropTypes.object
}

function mapStateToProps(state) {
    return {
        selectedUser: state.selectedUser.data,
        auth: state.authentication,
        userAppointments: state.userAppointments,
        appointment: state.userAppointment.entry,
        loggedUser: state.userInfo.data
    }
}

const mapDispatchToProps = {
    fetchSelectedUserAppointments,
    updateAppointmentStatus,
    updateAppointmentInList,
    clearAppointmentCreationStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsContainer);
import React, {Component} from 'react'
import DateRangePicker from '../shared/DateRangePicker'
import Select from '../shared/Select'
import {$$} from '../../helpers/localization'
import moment from 'moment'
import PropTypes from "prop-types";


export class LabResultsFilters extends Component {

    state = {
        laboratory: this.props.filters.laboratory,
        beforeDateTime: this.props.filters.before_date_time,
        afterDateTime: this.props.filters.after_date_time
    }

    constructor(props) {
        super(props);
    }

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields, function () {
            this.props.changeLabResultsFilters(this.state);
        });
    };

    /**
     * Change the lab results filters state.
     *
     * @param {object} range - the custom range selected
     */
    onRangeSelected = (range) => {
        this.setState({
            beforeDateTime: range.endDate ? moment(range.endDate).endOf('day').valueOf() : null,
            afterDateTime: range.startDate ? moment(range.startDate).startOf('day').valueOf() : null
        }, function () {
            this.props.changeLabResultsFilters(this.state);
        });
    }

    render() {
        return (
            <div className="form-inline flex-space-between" style={{"paddingTop": "10px", "backgroundColor": "white"}}>
                <div>
                    <h1 style={{'marginRight': '2rem'}}>
                        {$$('Lab_Results')}
                    </h1>
                </div>

                <div className="row lab-results-filters">
                    <div style={{'display': 'flex', 'marginRight': '1.5rem'}}>
                        <Select
                            name="laboratory"
                            options={this.props.laboratoryOptions}
                            value={this.state.laboratory}
                            onChange={this.onSelectChange}
                            placeHolder={$$('laboratory_label')}
                            style={{'maxWidth': '200px', 'zIndex': '1', 'opacity': '70%'}}/>
                    </div>
                    <div className={"date-filter-small-screen"} style={{"position": "relative"}}>
                        <DateRangePicker
                            i18n={this.props.i18n}
                            onRangeSelected={this.onRangeSelected}
                            startDate={this.props.filters.after_date_time}
                            endDate={this.props.filters.before_date_time}/>
                    </div>
                </div>
            </div>
        )
    }
}

LabResultsFilters.propTypes = {
    changeLabResultsFilters: PropTypes.func,
    filters: PropTypes.object,
    i18n: PropTypes.object,
    labResults: PropTypes.object,
    laboratoryOptions: PropTypes.array
}

export default LabResultsFilters

import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {login, loginWithApple, loginWithFacebook, loginWithGoogle} from '../../actions/auth_actions'
import LoginForm from './LoginForm'
import {useLocation} from 'react-router-dom'
import PropTypes from "prop-types";
import {isMobile} from "../video/utils";
import CenteredModal from "../shared/CenteredModal";
import {$$} from "../../helpers/localization";

function iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const LoginPage = (props) => {
    let query = new URLSearchParams(useLocation().search);
    const [pickMobileModal, setPickMobileModal] = useState(false);
    const [ios, setIos] = useState(false);
    useEffect(()=>{
        setPickMobileModal(isMobile && !props.preselectedAppointment.selectedPrice);
        setIos(iOS());
    }, [])
    return (
        <div>
            {pickMobileModal && <CenteredModal show={pickMobileModal} onHide={()=>{setPickMobileModal(false)}} primary>
                <div className={"pb-3"}>
                    {$$("choose_mobile_app_text")}
                </div>
                <div className="text-center">
                {ios ? <a href="https://apps.apple.com/app/id1617858478" className="btn btn-primary" target="_blank">{$$("get_for_ios")}</a> :
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.sirma.medrec.m" className="btn btn-primary">{$$("get_for_android")}</a>}
                </div>
            </CenteredModal>}
        <LoginForm
            onLogin={props.login}
            onLoginWithGoogle={props.loginWithGoogle}
            onLoginWithFacebook={props.loginWithFacebook}
            onLoginWithApple={props.loginWithApple}
            auth_data={props.auth_data}
            email={query.get('username')}
            password={query.get('password')}
            i18n={props.i18n}/>
        </div>
    )
}

LoginPage.propTypes = {
    login: PropTypes.func,
    loginWithGoogle: PropTypes.func,
    loginWithFacebook: PropTypes.func,
    loginWithApple: PropTypes.func,
    auth_data: PropTypes.any,
    i18n: PropTypes.object
}

function mapStateToProps(state) {
    return {
        auth_data: state.authentication,
        i18n: state.language,
        preselectedAppointment: state.preselectedAppointment
    }
}

const mapDispatchToProps = {
    login,
    loginWithGoogle,
    loginWithFacebook,
    loginWithApple
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)


import React, {Component} from 'react'
import {Button} from "react-bootstrap";
import {$$} from "../../helpers/localization";
export default class TestsResultDisplay extends Component {

    getValue = (field) => {
        let value = field.value;
        if (!value) {
            return "";
        }
        switch (field.type) {
            case 'calculate':
            case 'number':
                return value.f_value !== null ? value.f_value : "";
            case 'boolean':
                return value.b_value !== null ? value.b_value : "";
            case 'date':
                return value.date_value ? value.date_value : "";
            case 'string':
                return value.s_value ? value.s_value : "";
            default: return "";
        }
    }

    fillRest = (l1, l2) => {
        let r = [];
        for (; l2 < l1; l2 ++) {
            r.push(<td key={`l_${l2}`}></td>)
        }
        return r;
    }

    renderValue = (f) => {
        switch (f.type) {
            case "calculate":
            case "number":
            case "string":
            case "date": return <span>{this.getValue(f)}</span>
            case "boolean":
                let value = this.getValue(f);
                if (value === "") {
                    return "";
                }
                return <input type="checkbox" readOnly={true} size={4} id={f.id} checked={this.getValue(f)}/>
        }
    }

    testHasValue = (test) => {
        return test.groups.find(g => {
            return this.groupHasValue(g)
        });
    }

    groupHasValue = (group) => {
        return group.rows.find(r => {
            return this.rowHasValue(r)
        });
    }

    rowHasValue(row) {
        return row.fields.find(f => {
            return this.getValue(f) !== ""
        });
    }

    render() {
        return <div className={"tests_result_edit"} >
            <h4>{this.props.result.name}</h4>
            <table className={"tre_outer_table table table-bordered"}>
                <tbody>
                {
                    this.props.result.tests.map((test, tindex) => {
                        if (!this.testHasValue(test)) {
                            return null;
                        }
                        return <tr key={test.id}><td className={"td-wrap"}>
                            {test.name && <div className={"test-name"}>{test.name}</div>}
                            <table>
                                <tbody>
                                {test.groups.map((g,gindex) => {
                                    if (!this.groupHasValue(g)) {
                                        return null;
                                    }
                                    return <React.Fragment key={gindex}>{gindex === 0 && <tr className={"headers"}>
                                                {test.description_headers.map((dh, i) => {
                                                    return <td key={i}>{dh}</td>
                                                })}
                                                {test.field_headers.map((fh, i) => {
                                                    return <td key={i}>{fh}</td>
                                                })}
                                            </tr>}
                                            {!g.hide_name && <tr className="tr-group-name"><td></td><td className={"group-name"}>{g.name}</td><td></td><td colSpan={test.description_headers.length + test.field_headers.length - 3}></td></tr>}
                                            {g.rows.map(r => {
                                                if (!this.rowHasValue(r)) {
                                                    return null;
                                                }
                                                return <tr key={r.id}>
                                                    {r.description?.values.map((value, i) => {
                                                        return <td key={i}>{value}</td>
                                                    })}
                                                    {r.fields.map(f => {
                                                        return <td className="field-value" key={f.id}>{this.renderValue(f)}</td>
                                                    })}
                                                    {this.fillRest(test.description_headers.length + test.field_headers.length, r.description.values.length + r.fields.length)}
                                                </tr>
                                            })}
                                    </React.Fragment>
                                })}
                                </tbody>
                            </table>
                        </td></tr>
                    })
                }
                </tbody>
            </table>
            <div className={"mt-3"}>
                <Button type={"button"} variant={"secondary"} className="ml-3" onClick={this.props.onCancel} >{$$("close_btn_label")}</Button>
            </div>
        </div>
    }
}
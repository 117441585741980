import React, {Component} from 'react'
import Hospital from './Hospital'
import PropTypes from "prop-types";
import {SERVER_URL} from "../../constants/api_paths";

class DisplayHospitals extends Component {
    constructor(props) {
        super(props)
        this.state =
            {
                hospital: "",
            }
    }

    componentDidMount() {
        this.setState({
            hospital: this.props.hospital,
        })
    }

    triggerShowClinicians = (hospital) => {
        this.props.showClinicians(hospital)
    }

    render() {
        const {hospital} = this.state;
        return (
            <div className="d-flex" onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.triggerShowClinicians(hospital)}}>
                <div className="pointer"
                     style={{"marginLeft": "1rem", "marginRight": "1rem", "marginTop": "0.5rem"}}>
                    {
                        hospital !== "" && !this.state.show_placeholder &&
                        <img style={{width:"60px"}} src={`${SERVER_URL}/public/provider/hospital/logo/${hospital.hospital.id}`}
                            onError={() => {
                                this.setState({show_placeholder: true})
                            }} />
                    }
                    {this.state.show_placeholder && <i className="flaticon2-hospital"/>}
                </div>
                {
                    hospital !== "" &&
                    <Hospital
                        i18n = {this.props.i18n}
                        hospital={hospital}
                    />
                }
            </div>
        )
    }
}

DisplayHospitals.propTypes = {
    hospital: PropTypes.object,
    i18n: PropTypes.object,
    showClinicians:  PropTypes.func
}

export default DisplayHospitals;
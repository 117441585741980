import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    fetchHospitals,
    fetchProviders,
    fetchSpecialties,
    fetchSpecialtiesCount
} from '../../actions/provider_actions'
import {$$} from '../../helpers/localization'
import CliniciansListView from './CliniciansListView'
import CliniciansHeader from './CliniciansHeader'
import {cliniciansProfileHelper} from '../../helpers/clinician_profile_helper'
import HospitalsListView from './HospitalsListView'
import PropTypes from "prop-types";


class CliniciansPage extends Component {
    state = {
        specialties: null,
        searchValue: '',
        showClinicians: true,
        showHospitals: false,
        cliniciansLoading: true,
        hospitalsLoading: true
    }

    constructor(props) {
        super(props);
    }

    componentDidMount = async () => {
        const queryParams = new URLSearchParams(this.props.location.search)
        if (queryParams.has('org')) {
            let clinicId = queryParams.get('org');

            this.setState({
                    showHospitals: true,
                    showClinicians: false
            })
        }

        await this.props.fetchHospitals().then(() => {
            this.setState({hospitalsLoading: false})
        })

        this.setState({
            specialties: "",
        })
        await this.props.fetchProviders(null, this.props.userInfo.country).then(() => {
            this.setState({cliniciansLoading: false})
        })
        await this.props.fetchSpecialtiesCount()

    }

    /**
     * Sets the state based on what is entered by the user
     *
     * @param {string} searchValue - the value entered by the user, used for filtering purposes
     */
    filterCliniciansByName = (searchValue) => {
        this.setState({searchValue});
    }

    /**
     * Return select options with the text resolved to the selected language
     *
     * @param {Array} options - the list of options
     */
    getSpecialtiesOptions(options) {
        return options && options.map(o => {
            if (o.count > 0)
                return {value: o.specialty, text: $$(o.specialty.toLowerCase())}
        });
    }

    getSpecialitiesPlusAll(options) {
        const optionArray = this.getSpecialtiesOptions(options);
        optionArray.unshift({value: "ALL", text: $$("all_clinicians")})
        return optionArray;
    }


    onSelectChange = (evt) => {
        this.setState({cliniciansLoading: true})
        evt.value === "ALL" ? this.props.fetchProviders(null, this.props.userInfo.country).then(() => {
            this.setState({cliniciansLoading: false})
        }) : this.props.fetchProviders(evt.value, this.props.userInfo.country).then(() => {
            this.setState({cliniciansLoading: false})
        });
        this.setState({specialties: evt.value})
    }


    prepareClinicianData = (clinician) => {

        cliniciansProfileHelper.prepareData(clinician);
    }

    render() {
        return (
            <div className='wrapping-div'>
                <CliniciansHeader
                    filterCliniciansByName={this.filterCliniciansByName}
                    specialtiesOptions={this.getSpecialitiesPlusAll(this.props.specialties)}
                    specialties={this.state.specialties}
                    onSelectChange={this.onSelectChange}
                    showFilters={this.state.showClinicians}
                />
                <div className="d-flex my-3" style={{minHeight: "30px"}}>
                            <span onClick={() => {
                                this.setState({showClinicians: true, showHospitals: false})
                            }}
                                  className={this.state.showClinicians ? 'clinician-tabs-active' : 'clinicians-tab'}>{$$('clinicians_label')}</span>
                    <span onClick={() => {
                        this.setState({showClinicians: false, showHospitals: true})
                    }}
                          className={!this.state.showClinicians ? 'clinician-tabs-active ml-4' : 'clinicians-tab ml-4'}>{$$('hospitals_label')}</span>
                </div>
                {this.state.showClinicians && <CliniciansListView
                    providers={this.props.providers}
                    searchValue={this.state.searchValue}
                    i18n={this.props.i18n}
                    loading = {this.state.cliniciansLoading}
                    onClinicianClick={this.prepareClinicianData}/>
                }
                {
                    this.state.showHospitals && <HospitalsListView
                        hospitals={this.props.hospitals}
                        providers={this.props.providers}
                        locationSearch = {this.props.location.search}
                        searchValue={this.state.searchValue}
                        specialties ={this.state.specialties}
                        loading = {this.state.hospitalsLoading}
                        i18n={this.props.i18n}
                        onClinicianClick={this.prepareClinicianData}
                    />
                }
            </div>
        )
    }
}

CliniciansPage.propTypes = {
    fetchHospitals: PropTypes.func,
    fetchProviders: PropTypes.func,
    fetchSpecialties: PropTypes.func,
    fetchSpecialtiesCount: PropTypes.func,
    history: PropTypes.object,
    hospitals: PropTypes.object,
    i18n: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    providers: PropTypes.array,
    settings: PropTypes.object,
    specialties: PropTypes.array,
    userInfo: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        userInfo: state.userInfo.data,
        settings: state.settings,
        providers: state.providers.entries,
        i18n: state.language,
        specialties: state.specialties.specialties,
        hospitals: state.hospitals
    }
}

const mapDispatchToProps = {
    fetchHospitals,
    fetchProviders,
    fetchSpecialtiesCount,
    fetchSpecialties,
}
export default connect(mapStateToProps, mapDispatchToProps)(CliniciansPage)

import React, {Component} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faMicrophone,
    faMicrophoneSlash,
    faPhoneSlash,
    faSyncAlt,
    faVideo,
    faVideoSlash
} from "@fortawesome/free-solid-svg-icons";
import {VideoManager} from "./video_manager";
import {CameraDevices} from './CameraDevices'
import PropTypes from "prop-types";
import {VideoContext} from "../video/VideoProvider";


export class VideoControlsOverlay extends Component {
    static contextType = VideoContext;
    state = {
        cameraNo: 0
    }

    onHangupCall = () => {
        this.context.room.disconnect();
        if(this.props.onHangup) {
            console.log("onStopCall")
            this.props.onHangup();
        }
        //VideoManager.hangup();
    }

    /**
     * On mic btn press. Mute or unmute
     */
    onMicBtnClick = () => {
        if (this.props.audioMuted) {
            this.getAudioTrack().enable();
        } else {
            this.getAudioTrack().disable();
        }
    }

    /**
     * On video btn press. Hold or continue video
     */
    onVideoBtnClick = () => {
        if (this.props.videoMuted) {
            this.getVideoTrack().enable();
        } else {
            this.getVideoTrack().disable();
        }
    }

    /**
     * The case with 2 cameras
     */
    onSwitchCamera = () => {
        if (this.state.cameraNo === 0) {
            VideoManager.getVideoCameras(0);
            this.setState({cameraNo: 1})
        } else if (this.state.cameraNo === 1) {
            VideoManager.getVideoCameras(1);
            this.setState({cameraNo: 0})
        }
    }

    /**
     * The case with more cameras
     */
    selectCamera = () => {
        if (this.state.cameraNo == 0) {
            VideoManager.onSwitchVideoCamera(0, this.props.devices);
            this.setState({cameraNo: 1})
        } else if (this.state.cameraNo == 1) {
            VideoManager.onSwitchVideoCamera(1, this.props.devices);
            this.setState({cameraNo: 0})
        }
    }


    getAudioTrack = () => {
        return this.context.localTracks.find(track => track.kind === 'audio')
    }

    getVideoTrack = () => {
        return this.context.localTracks.find(track => track.kind === 'video')
    }


    onVisibilityChanged = () => {
        VideoManager.setState({modalShow: !this.props.modalShow})
    }

    render() {
        return <div
            className={this.props.modal ? "buttons video-controls" : (this.props.topbar ? "buttons video-controls1" : "buttons video-controls2")}>
            <button className={this.props.modal ? "video-control-btn touch-btn" : "video-control-btn1 touch-btn"}
                    onClick={this.onMicBtnClick}>
                <FontAwesomeIcon className={this.props.modal ? "touch-safari-mic-btn" : "touch-safari-mic-btn-heading"}
                                 icon={this.props.audioMuted ? faMicrophoneSlash : faMicrophone}/>
            </button>
            <button className={this.props.modal ? "video-hangup-btn touch-btn" : "video-hangup-btn1 touch-btn"}
                    data-target="video" onClick={this.onHangupCall}>
                <FontAwesomeIcon
                    className={this.props.modal ? "touch-safari-hangup-btn" : "touch-safari-hangup-btn-heading"}
                    icon={faPhoneSlash}/>
            </button>
            <button className={this.props.modal ? "video-control-btn touch-btn" : "video-control-btn1 touch-btn"}
                    onClick={this.onVideoBtnClick}>
                <FontAwesomeIcon
                    className={this.props.modal ? "touch-safari-video-btn" : "touch-safari-video-btn-heading"}
                    icon={this.props.videoMuted ? faVideoSlash : faVideo}/>
            </button>
            <button className={this.props.modal ? "video-control-btn touch-btn" : "video-control-btn1 touch-btn"}
                    onClick={this.onVisibilityChanged}>
                {this.props.modalShow ? <span
    className={this.props.modal ? "fa fa-compress-alt touch-safari-shrink-btn" : "touch-safari-shrink-btn-heading"}/> :
                    <span
    className={this.props.modal ? "fa fa-expand touch-safari-shrink-btn" : "fa fa-expand touch-safari-shrink-btn-heading"}/>}
            </button>
            {this.props.cameraDevices && this.props.cameraDevices.length == 2 &&
            <button className={this.props.modal ? "video-control-btn touch-btn" : "video-control-btn1 touch-btn"}
                    onClick={this.onSwitchCamera}>
                <FontAwesomeIcon
                    className={this.props.modal ? "touch-safari-video-btn" : "touch-safari-video-btn-heading"}
                    icon={this.props.facingMode ? faSyncAlt : faSyncAlt}/>
            </button>}
            {this.props.cameraDevices && this.props.cameraDevices.length > 2 &&
            <CameraDevices deviceId={this.props.deviceId} modal={this.props.modal}
                           cameraDevices={this.props.cameraDevices}/>}

        </div>
    }
}
VideoControlsOverlay.propTypes = {
    audioMuted: PropTypes.bool,
    cameraDevices:  PropTypes.array,
    topbar:  PropTypes.any,
    devices:  PropTypes.any,
    deviceId:  PropTypes.string,
    facingMode:  PropTypes.string,
    modal:  PropTypes.bool,
    modalShow:  PropTypes.bool,
    videoMuted:  PropTypes.bool
};
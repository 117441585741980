import React, {Component, useEffect, useState} from 'react'
import {connect} from "react-redux";
import {CHAT_WEBSOCKET_URL} from "../../constants/api_paths";
import {fetchHelper} from "../../helpers/request_helper";
import {VideoManager} from "./video_manager";
import {
    IDLE, RINGING,
    STARTING_CALL
} from "../../constants/call_status";
import {
    attachNewMessage,
    newMessageViaSocket,
    updateMessage
} from "../../actions/chat_actions";
import PropTypes from "prop-types";
import PreJoinScreens from "../video/PreJoinScreens/PreJoinScreens";
import {VideoProvider} from "../video/VideoProvider";
import useConnectionOptions from "../video/utils/useConnectionOptions/useConnectionOptions";
import VideoPickUpModal from "./VideoPickUpModal";
import useIsTrackEnabled from "../video/hooks/useIsTrackEnabled/useIsTrackEnabled";
import useIsTrackSwitchedOff from "../video/hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import useTrack from "../video/hooks/useTrack/useTrack";
import VideoTrack from "../video/VideoTrack";
import AudioTrack from "../video/AudioTrack";
import AvatarIcon from "../video/icons/AvatarIcon";
import AudioLevelIndicator from "../video/AudioLevelIndicator";
import usePublications from "../video/hooks/usePublications/usePublications";
import {$$} from "../../helpers/localization";
import {makeStyles} from "@material-ui/core";
import useParticipants from "../video/hooks/useParticipants/useParticipants";
import {VideoControlsOverlay} from "./VideoControlsOverlay";
import Stopwatch from "../video/Stopwatch";
import useRoomState from "../video/hooks/useRoomState/useRoomState";
import useVideoContext from "../video/hooks/useVideoContext/useVideoContext";
import Modal from "react-bootstrap/Modal";
import ReactDOM from "react-dom";
import useLocalTracks from "../video/VideoProvider/useLocalTracks/useLocalTracks";
import {video} from "../../reducers/video_reducer";
import {FETCH_LATEST_VIDEO_MESSAGE_SUCCESS} from "../../actions/actions";
import store from "../../store"
import useIsRoomForGroupEvent from "../video/hooks/useIsRoomForGroupEvent/useIsRoomForGroupEvent";
import useMainParticipant from "../video/hooks/useMainParticipant/useMainParticipant";
import useSelectedParticipant from "../video/VideoProvider/useSelectedParticipant/useSelectedParticipant";
import {faArrowAltCircleLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const rootVideoContainer = document.getElementById("root-video-container")

class VideoMessageCallListener extends Component {

    constructor(props) {
        super(props);
        this.stopRetry = false;
        this.retryCount = 0;
        this.state = {
            facingMode: 'user',
            callStatus: IDLE
        }
    }

    componentDidMount() {
        this.connectSocket();
    }

    componentWillUnmount() {
        this.disconnectSocket();
    }

    connectSocket = () => {
        let protocol, webSocketProtocol;
        if (location.protocol.indexOf('https') !== -1) {
            protocol = 'https';
            webSocketProtocol = 'wss';
        } else if (location.protocol.indexOf('http') !== -1) {
            protocol = 'http';
            webSocketProtocol = 'ws';
        }
        const socket = new WebSocket(CHAT_WEBSOCKET_URL.replace(protocol, webSocketProtocol));
        // eslint-disable-next-line no-undef
        this.stompClient = Stomp.over(socket);
        this.stompClient.debug = f => f;
        this.stompClient.heartbeat.outgoing = 25000;
        this.stompClient.heartbeat.incoming = 25000;
        this.stompClient.reconnect_delay = 5000;
        this.stompClient.connect(fetchHelper.getTokenHeader(), function () {
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
            this.stompClient.subscribe('/user/queue/messages', function (messageOutput) {
                const message = messageOutput && messageOutput.body ? JSON.parse(messageOutput.body) : null;
                if (message == null) {
                    return;
                }
                if (message.type === 'VIDEO') {
                    if (message.video_status === "initiated" && !message.seen && message.date_time > Date.now() - 30000) {
                        this.setState({message: message}, () => VideoManager.updateVideoData({callStatus: STARTING_CALL}));
                        store.dispatch({type: FETCH_LATEST_VIDEO_MESSAGE_SUCCESS, result: message})
                    } else {
                        store.dispatch({type: FETCH_LATEST_VIDEO_MESSAGE_SUCCESS, result: {}})
                    }
                }
                this.props.newMessageViaSocket(message)
            }.bind(this));
        }.bind(this), function () {
            this.reConnectSocket();
        }.bind(this));
    }

    componentDidUpdate(prevProps) {
        if (this.props.video.callStatus == IDLE && prevProps.video.callStatus != IDLE && this.state.message) {
            this.setState({message: null});
        }

        if (this.props.video.modalShow !== this.state.modalShow) {
            this.setState({modalShow: this.props.video.modalShow});
        }

        if (this.props.video.error) {
            VideoManager.setState({callStatus: IDLE, modalShow: false, error:undefined});
        }

        if (!this.state.message && this.props.video.video_message && this.props.video.callStatus === STARTING_CALL) {
            this.setState({message: this.props.video.video_message, callStatus: 'PRE_JOIN'},
                () => {
                    VideoManager.setState({callStatus: 'PRE_JOIN', modalShow: true, error: undefined})
                });
        }
    }

    /**
     * Re-connect socket function, try to reconnect for several times before stopping
     */
    reConnectSocket = () => {
        if (!this.stopRetry && this.retryCount < 10) {
            const factor = this.retryCount ? this.retryCount : 0;
            setTimeout(() => this.connectSocket(), 3000 * factor);
            this.retryCount++;
        } else if (this.retryCount >= 10) {
            this.stopRetry = true;
        }
    }

    /**
     * Disconnect the stomp client over the socket
     */
    disconnectSocket = () => {
        if (this.stompClient != null) {
            try {
                this.stompClient.disconnect();
            } catch (e) {
                //can throw exception in rare cases.
            }
        }
    }

    render() {
        return <div>
            <RemoteAudio />
            {this.state.message &&
            <VideoPickUpModal show={this.props.video.callStatus === STARTING_CALL}
                              clinician={{fullname: this.state.message.from, id: this.state.message.from_user_id}}
                              onPickUp={() => {
                                  this.setState({callStatus: 'PRE_JOIN'}, () => {
                                      VideoManager.setState({callStatus: 'PRE_JOIN', modalShow: true, error: undefined})
                                  })
                              }}
                              onDecline={() => {
                                  //VideoManager.hangup();
                                  this.setState({callStatus: IDLE}, () => {
                                      VideoManager.setState({callStatus: IDLE, modalShow: false, error: undefined});
                                  })
                              }}
            />
            }
            {
                this.state.callStatus === 'PRE_JOIN'
                    && this.state.modalShow
                    && !this.props.video.error
                && <Video
                    onHangup={() => {
                        //VideoManager.hangup();
                        this.setState({callStatus: IDLE}, () => {
                            VideoManager.setState({callStatus: IDLE, modalShow: false, error:undefined});
                        })
                    }}
                    showModal={this.props.video.modalShow}
                    name={getIdentity(this.props.loggedUser)}
                    roomName={getRoomId(this.state.message)}
                    displayName={this.props.loggedUser.fullname}
                    message={this.state.message}
                />}

        </div>

    }
}


function RemoteAudio() {
    const roomState = useRoomState();
    if (roomState == 'connected') {
        return <ParticipantsAudios />
    }
    return null;
}

function ParticipantsAudios() {
    const participants = useParticipants();
    return <span>
            {participants.map(p=>{
                return <PAudio key={p.sid} participant={p} />
            })
            }
        </span>
}

function PAudio({participant}) {
    const publications = usePublications(participant);
    const audioPublication = publications.find(p => p.kind === 'audio');
    const audioTrack = useTrack(audioPublication);
    if (audioTrack) {
        return <AudioTrack track={audioTrack} />
    }
    return null;
}

function getRoomId(message) {
    if (message) {
        if (message.appointment_id) {
            return message.appointment_id + ":appointment"
        } else if (message.event_id) {
            return message.event_id + ":event";
        }
    }
}

function getIdentity(user) {
    return user.id + ":" + user.fullname
}

function mapStateToProps(state) {
    return {
        i18n: state.language.selected,
        selectedUser: state.selectedUser.data,
        loggedUser: state.userInfo.data,
        chat: state.chat,
        auth: state.authentication,
        video: state.video.data,
        cameraDevices: state.cameraDevices.list,
    }
}

const mapDispatchToProps = {
    updateMessage,
    attachNewMessage,
    newMessageViaSocket
}

VideoMessageCallListener.propTypes = {
    i18n: PropTypes.object,
    selectedUser: PropTypes.object,
    video: PropTypes.any,
    loggedUser: PropTypes.object,
    chat: PropTypes.object,
    cameraDevices: PropTypes.array,
    updateMessage: PropTypes.func,
    attachNewMessage: PropTypes.func,
    newMessageViaSocket: PropTypes.func
};


function ModalListener({show, children}) {

    useEffect(() => {
        if (show) {
            setOverflowToBody();
        } else {
            clearOverflowToBody();
        }
        return function cleanup() {
            clearOverflowToBody();
        }
    }, [show]);
    return children;
}

function setOverflowToBody() {
    let body = document.getElementsByTagName("body")[0];
    if (body) {
        document.scrollingElement.scrollTo(0, 0);
        body.style.overflow = "hidden";
    }
}

function clearOverflowToBody() {
    let body = document.getElementsByTagName("body")[0];
    if (body) {
        body.style.overflow = "visible";
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoMessageCallListener)

function Video({name, roomName, displayName, onHangup, message}) {
    const roomState = useRoomState();
    return roomState !== 'connected' && roomState !== 'reconnecting' && roomState !== 'reconnected' ?
        <Modal show={true} size="lg">
            <Modal.Body>
                <PreJoinScreens name={name} roomName={roomName} displayName={displayName} onCancel={onHangup}/>
            </Modal.Body>
        </Modal>
        :
        <ModalListener show={true}>
            {ReactDOM.createPortal(
                <div className="video-container" key="key-video-container">
                    <Room onHangup={onHangup} message={message}/>
                </div>
                , rootVideoContainer)}
        </ModalListener>
}

function Room({onHangup, message}) {
    const context = useVideoContext();
    const localParticipant = (context.room && context.room.localParticipant) || undefined;
    const roomState = useRoomState();
    let audioTrack = context.localTracks.find(track => track.kind === 'audio');
    let isAudioTrackEnabled = useIsTrackEnabled(audioTrack);
    let isAudioTrackSwitchedOff = useIsTrackSwitchedOff(audioTrack);
    let audioMuted = !isAudioTrackEnabled || isAudioTrackSwitchedOff;
    let videoTrack = context.localTracks.find(track => track.kind === 'video');
    let isVideoTrackEnabled = useIsTrackEnabled(videoTrack);
    let isVideoTrackSwitchedOff = useIsTrackSwitchedOff(videoTrack);
    let videoMuted = !isVideoTrackEnabled || isVideoTrackSwitchedOff;
    const isRoomForGroupEvent = useIsRoomForGroupEvent();
    const mainParticipant = useMainParticipant();

    useEffect(() => {
        context.setVideoMessage(message)
    }, [message])

    useEffect(() => {
        if (context.room) {
            context.room.message = message
        }
    }, [message])


    if (isRoomForGroupEvent) {
        return <>
            <div className="videocall-contianer group-event">
                <div className="frames__main">
                    <div className={"main-participant"}>
                        <Participant
                            participant={mainParticipant}
                            isLocalParticipant={localParticipant === mainParticipant} />
                        <VideoControlsOverlay modal={true} videoMuted={videoMuted} audioMuted={audioMuted} onHangup={onHangup}
                                              modalShow={true}/>
                    </div>
                    {roomState === 'connected' &&
                        <div className={"participants-list"}>
                            <div className={"participants-list-inner"}>
                                <ParticipantsList localParticipant={localParticipant} mainParticipant={mainParticipant} isRoomForGroupEvent={isRoomForGroupEvent}/>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {roomState === 'connected' && <Stopwatch/>}
        </>
    }

    return <>
        <div className="videocall-contianer">
            <div className="frames__main">
                {roomState === 'connected' && <ParticipantsList/>}
                {localParticipant && <Participant participant={localParticipant} isLocalParticipant={true}/>}
            </div>
            <VideoControlsOverlay modal={true} videoMuted={videoMuted} audioMuted={audioMuted} onHangup={onHangup}
                                  modalShow={true}/>
        </div>
        {roomState === 'connected' && <Stopwatch/>}
    </>
}

function ParticipantsList({localParticipant, mainParticipant, isRoomForGroupEvent}) {
    const participants = useParticipants();
    return <>
        {localParticipant && <Participant isLocalParticipant={true} participant={localParticipant} avatarOnly={localParticipant === mainParticipant && participants && participants.length > 0 && isRoomForGroupEvent}/> }
        {
            participants.map((participant, key) => {
                return <Participant participant={participant} key={key} avatarOnly={participant === mainParticipant && isRoomForGroupEvent}/>
            })
        }
    </>
}


const usePStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    identity: {
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '0.1em 0.3em 0.1em 0',
        display: 'inline',
        '& svg': {
            marginLeft: '0.3em',
        },
        marginRight: '0.4em',
        alignItems: 'center',
        borderBottomLeftRadius: '8px',
        position: 'absolute',
        bottom: '0',
        left: '0',
        zIndex: '10',
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    infoContainer: {
        position: 'absolute',
        zIndex: 2,
        height: '100%',
        width: '100%',
    },
    reconnectingContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(40, 42, 43, 0.75)',
        zIndex: 1,
    },
    fullWidth: {
        gridArea: '1 / 1 / 2 / 3',
        [theme.breakpoints.down('sm')]: {
            gridArea: '1 / 1 / 3 / 3',
        },
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'black',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        '& svg': {
            transform: 'scale(2)',
        },
    },
    recordingIndicator: {
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '0.1em 0.3em 0.1em 0',
        fontSize: '1.2rem',
        height: '28px',
        [theme.breakpoints.down('sm')]: {
            bottom: 'auto',
            right: 0,
            top: 0,
        },
    },
    circle: {
        height: '12px',
        width: '12px',
        background: 'red',
        borderRadius: '100%',
        margin: '0 0.6em',
        animation: `1.25s $pulsate ease-out infinite`,
    },
    '@keyframes pulsate': {
        '0%': {
            background: `#A90000`,
        },
        '50%': {
            background: '#f00',
        },
        '100%': {
            background: '#A90000',
        },
    },
}));


function Participant({participant, isLocalParticipant, avatarOnly}) {
    const classes = usePStyles();
    const publications = usePublications(participant);
    const audioPublication = publications.find(p => p.kind === 'audio');
    const audioTrack = useTrack(audioPublication);
    const pName = useParticipantName({identity: participant.identity})
    const name = isLocalParticipant ? `(${$$("You")})` : pName;
    const videoPublication = publications.find(p => p.kind === 'video');
    const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
    const isRoomForGroupEvent = useIsRoomForGroupEvent();



    let className = `${classes.identity} p-names`;
    return <div className={isLocalParticipant ? "local-video p-wrap" : "qb-video p-wrap"} onClick={()=>{
        if (isRoomForGroupEvent) {
            setSelectedParticipant(participant);
        }
    }}>
        <div className={className}>
            <AudioLevelIndicator audioTrack={audioTrack}/>
            {name}
        </div>
        {(!videoPublication || avatarOnly) &&
            <div className="d-flex w-100 h-100 justify-content-center align-items-center avatar-wrap">
                {avatarOnly ? <FontAwesomeIcon icon={faArrowAltCircleLeft} size={"1x"} /> : <AvatarIcon/>}
            </div>}
        {!avatarOnly && publications.map(p => {
            return <Publication publication={p} key={p.kind} isLocal={isLocalParticipant}/>
        })}
    </div>
}

function useParticipantName({identity}) {
    let idName = identity.split(":");
    if (idName.length > 1) {
        return idName[1];
    } else {
        return "Unknown";
    }
}

function Publication({publication, isLocal}) {
    let track = useTrack(publication);
    const isTrackEnabled = useIsTrackEnabled(track);
    const isTrackSwitchedOff = useIsTrackSwitchedOff(track);
    if (!track) return null;
    switch (track.kind) {
        case 'video':

            if (!isTrackEnabled || isTrackSwitchedOff) {
                return <div className="d-flex w-100 h-100 justify-content-center align-items-center"><AvatarIcon/></div>
            }

            return isLocal ? <VideoTrack track={track} isLocal={isLocal} classes="qb-video_source"/> :
                <div className="w-100 h-100">
                    <VideoTrack track={track} isLocal={isLocal} classes={"main_video"}/>
                </div>
        //return <VideoTrack track={track} isLocal={isLocal} classes={isLocal ? "qb-video_source" : "main_video"}/>;
       /* case 'audio':
            return <AudioTrack track={track}/>;*/
        default:
            return null;
    }
}
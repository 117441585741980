import React, {Component} from 'react'
import {$$, _$$} from '../../helpers/localization'
import CenteredModal from '../shared/CenteredModal';
import CustomMultiselect from '../shared/CustomMultiSelect'
import {getHealthIssueOptions, getResolvedOptions, UNIT_TYPES} from '../../constants/select_options'
import Select from '../shared/Select'
import moment from 'moment'
import {
    CYCLE,
    DAILY,
    DURATION_FOR_X_DAYS,
    DURATION_ONGOING,
    DURATION_UNTIL_DATE,
    EVERY_X_DAYS,
    SPECIFIC_DAYS_OF_WEEK,
    WHEN_NEEDED,
} from '../../constants/medications_constants';
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SelectedPill from "./SelectedPill";
import PillPicker from "./PillPicker";
import {CONVERTER} from '../../utils/converter'
import {dateTimeUtils} from '../../utils/dateTimeUtils'
import NotWhenNeededForms from './NotWhenNeededForms'
import {MEDICATIONS} from "../../actions/actions";
import FormWithLoading from "../shared/FormWithLoading";
import PropTypes from "prop-types";
import bg from 'date-fns/locale/bg';
import sq from 'date-fns/locale/sq';
import enGB from 'date-fns/locale/en-GB';
import Search from "../shared/Search";
import {searchForMedication} from "../../actions/medications_actions";
import _ from "underscore";
import no_data from "../../resources/images/no_data.png";
import {infoUtils} from "../../utils/infoUtils";
import {medicationUtils} from "../../utils/medicationsUtils";


export class MedicationForm extends Component {

    constructor(props) {
        super(props);
        if (this.props.importMedication !== undefined) {
            this.state = {
                existingHealthIssues: [],
                selectedHealthIssues: [],
                loadingComplete: false,
                showMedSearch: false,
                medSearchValue: "",
                medicationSearchResults: [],
                name: this.props.importMedication.name,
                dateStarted: this.props.importMedication.starts,
                notes: this.props.importMedication.notes,
                healthIssueIds: this.props.importMedication.health_issues_ids,
                formclass: "",
                errors: {},
                id: null,
                unit: this.props.importMedication.unit,
                dose: this.props.importMedication.dose,
                scheduleType: this.props.importMedication.scheduleType,
                durationType: this.props.importMedication.durationType ? this.props.importMedication.durationType : DURATION_ONGOING,
                ends: this.props.importMedication.ends,
                howToTake: this.props.importMedication.howToTake,
                pillShape: 0,
                pillPrimaryColour: "#ffffff",
                pillSecondaryColour: "#ffffff",
                previousPrimaryColour: "#ffffff",
                previousSecondaryColour: "#ffffff",
                scheduleTimesPerDay: this.props.importMedication.scheduleTimesPerDay,
                scheduleDaysInactive: this.props.importMedication.scheduleType === EVERY_X_DAYS ? this.props.importMedication.scheduleDaysInactive + 1 : this.props.importMedication.scheduleDaysInactive,
                scheduleDaysActive: this.props.importMedication.scheduleDaysActive,
                scheduleDaysCountStartsAt: this.props.importMedication.scheduleDaysCountStartsAt,
                scheduleWeekdays: this.props.importMedication.scheduleWeekdays,
                durationInDays: this.props.importMedication.durationInDays ? this.props.importMedication.durationInDays : 5,
                scheduleHours: this.props.importMedication.scheduleHours,
                weekdays: this.props.importMedication.schedule && this.props.importMedication.schedule.weekdays ? this.props.importMedication.schedule.weekdays : [1, 2, 3, 4, 5, 6, 7],
                weekDays: this.getWeekdays(),
                weekEnd: this.getWeekend(),
                times: this.props.importMedication.schedule && this.props.importMedication.schedule.times ? this.props.importMedication.schedule.times : [],
                weekendTimes: this.props.importMedication.schedule && this.props.importMedication.schedule.weekendTimes ? this.props.importMedication.schedule.weekendTimes : [],
            }
        } else {
            let existingHealthIssues = this.props.selectedEntry ? this.getHealthIssueList(this.props.selectedEntry) : [];
            let selectedIssuesArray = this.props.selectedEntry && this.props.selectedEntry.health_issues_ids ? getHealthIssueOptions(existingHealthIssues) : [];

            this.state = {
                name: '',
                dose: 1,
                existingHealthIssues: this.props.selectedEntry ? existingHealthIssues : [],
                selectedHealthIssues: this.props.selectedEntry ? selectedIssuesArray : [],
                scheduleDaysInactive: this.props.selectedEntry && this.props.selectedEntry.scheduleDaysInactive ? (this.props.selectedEntry.scheduleType === EVERY_X_DAYS ? this.props.selectedEntry.scheduleDaysInactive + 1 : this.props.selectedEntry.scheduleDaysInactive) : 2,
                weekDays: this.getWeekdays(),
                weekEnd: this.getWeekend(),
                times: this.props.selectedEntry && this.props.selectedEntry.schedule && this.props.selectedEntry.schedule.times ? this.props.selectedEntry.schedule.times : [],
                weekendTimes: this.props.selectedEntry && this.props.selectedEntry.schedule && this.props.selectedEntry.schedule.weekendTimes ? this.props.selectedEntry.schedule.weekendTimes : [],
            }
        }


        this.onSubmit = this.onSubmit.bind(this);
        this.onSaveAddUpdateModal = this.onSaveAddUpdateModal.bind(this);
    }

    componentDidMount() {
        this.setState({
            pillShape: 9001
        }, () => {
            if (!this.props.importMedication) {
                this.setState({
                    pillShape: this.props.selectedEntry && this.props.selectedEntry.shapeIndex ? this.props.selectedEntry.shapeIndex : 0,
                    pillPrimaryColour: this.props.selectedEntry && this.props.selectedEntry.shapePrimaryColor ? CONVERTER.decimalHexTwosComplement(this.props.selectedEntry.shapePrimaryColor) : "#ffffff",
                    pillSecondaryColour: this.props.selectedEntry && this.props.selectedEntry.shapeSecondaryColor ? CONVERTER.decimalHexTwosComplement(this.props.selectedEntry.shapeSecondaryColor) : "#ffffff",
                    previousPrimaryColour: this.props.selectedEntry && this.props.selectedEntry.shapePrimaryColor ? CONVERTER.decimalHexTwosComplement(this.props.selectedEntry.shapePrimaryColor) : "#ffffff",
                    previousSecondaryColour: this.props.selectedEntry && this.props.selectedEntry.shapePrimaryColor ? CONVERTER.decimalHexTwosComplement(this.props.selectedEntry.shapeSecondaryColor) : "#ffffff",
                    showPillPicker: false,
                    scheduleDaysInactive: this.props.selectedEntry && this.props.selectedEntry.scheduleDaysInactive ? (this.props.selectedEntry.scheduleType === EVERY_X_DAYS ? this.props.selectedEntry.scheduleDaysInactive + 1 : this.props.selectedEntry.scheduleDaysInactive) : 2,
                    weekdays: this.props.selectedEntry && this.props.selectedEntry.schedule && this.props.selectedEntry.schedule.weekdays ? this.props.selectedEntry.schedule.weekdays : [1, 2, 3, 4, 5, 6, 7],
                    weekDays: this.getWeekdays(),
                    weekEnd: this.getWeekend(),
                    times: this.props.selectedEntry && this.props.selectedEntry.schedule && this.props.selectedEntry.schedule.times ? this.props.selectedEntry.schedule.times : [],
                    weekendTimes: this.props.selectedEntry && this.props.selectedEntry.schedule && this.props.selectedEntry.schedule.weekendTimes ? this.props.selectedEntry.schedule.weekendTimes : [],
                });
                this.setSchedule();
            } else {
                this.setState({pillShape: 0})
            }
        })
    }

    setSchedule = () => {
        this.setState({
            name: this.props.selectedEntry && this.props.selectedEntry.name ? this.props.selectedEntry.name : '',
            dateStarted: this.props.selectedEntry && this.props.selectedEntry.starts ? this.props.selectedEntry.starts : moment().valueOf(),
            notes: this.props.selectedEntry && this.props.selectedEntry.notes ? this.props.selectedEntry.notes : "",
            healthIssueIds: this.props.selectedEntry && this.props.selectedEntry.health_issues_ids ? this.props.selectedEntry.health_issues_ids : [],
            formclass: "",
            errors: {},
            id: this.props.selectedEntry && this.props.selectedEntry.id ? this.props.selectedEntry.id : null,
            unit: this.props.selectedEntry && this.props.selectedEntry.unit ? this.props.selectedEntry.unit : 'TABLET',
            dose: this.props.selectedEntry && this.props.selectedEntry.dose ? this.props.selectedEntry.dose : 1,
            scheduleType: this.props.selectedEntry && this.props.selectedEntry.scheduleType ? this.props.selectedEntry.scheduleType : WHEN_NEEDED,
            durationType: this.props.selectedEntry && this.props.selectedEntry.durationType ? this.props.selectedEntry.durationType : DURATION_ONGOING,
            ends: this.props.selectedEntry && this.props.selectedEntry.ends ? this.props.selectedEntry.ends : 0,
            howToTake: this.props.selectedEntry && this.props.selectedEntry.howToTake ? this.props.selectedEntry.howToTake : 'TAKE_X_TIMES',
            scheduleTimesPerDay: this.props.selectedEntry && this.props.selectedEntry.scheduleTimesPerDay ? this.props.selectedEntry.scheduleTimesPerDay : 1,
            scheduleDaysActive: this.props.selectedEntry && this.props.selectedEntry.scheduleDaysActive ? this.props.selectedEntry.scheduleDaysActive : 21,
            scheduleDaysCountStartsAt: this.props.selectedEntry && this.props.selectedEntry.scheduleDaysCountStartsAt ? this.props.selectedEntry.scheduleDaysCountStartsAt : 1,
            scheduleWeekdays: this.props.selectedEntry && this.props.selectedEntry.scheduleWeekdays ? this.props.selectedEntry.scheduleWeekdays : 127,
            durationInDays: this.props.selectedEntry && this.props.selectedEntry.durationInDays ? this.props.selectedEntry.durationInDays : 5,
            scheduleHours: this.props.selectedEntry && this.props.selectedEntry.scheduleHours ? this.props.selectedEntry.scheduleHours : 8,
            weekdays: this.props.selectedEntry && this.props.selectedEntry.schedule && this.props.selectedEntry.schedule.weekdays ? this.props.selectedEntry.schedule.weekdays : [1, 2, 3, 4, 5, 6, 7],
            showWeekend: this.props.selectedEntry && this.props.selectedEntry.schedule.weekendTimes && !this.props.selectedEntry.schedule.times.every((val, idx) => val === this.props.selectedEntry.schedule.weekendTimes[idx])
        });
    }

    /**
     * On component update change the view if selected entry has changed
     *
     */
    componentDidUpdate = () => {
        if (this.props.selectedEntry && this.state.id !== this.props.selectedEntry.id) {
            this.setState({
                scheduleDaysInactive: this.props.selectedEntry && this.props.selectedEntry.scheduleDaysInactive ? this.props.selectedEntry.scheduleDaysInactive : 2,
            });
            this.setSchedule();
        }
    }

    getLocale = () => {
        switch (this.props.i18n.selected.lang) {
            case "en":
                return enGB;
            case "bg":
                return bg;
            case "sq":
                return sq;
            default:
                return enGB;
        }
    }

    isCheckedWeekday = (id) => {
        return this.props.selectedEntry && this.props.selectedEntry.schedule && this.props.selectedEntry.schedule.weekdays ? this.props.selectedEntry.schedule.weekdays.some(day => day === id) : true
    }

    isCheckedWeekend = (id) => {
        return this.props.selectedEntry && this.props.selectedEntry.schedule && this.props.selectedEntry.schedule.weekends ? this.props.selectedEntry.schedule.weekends.some(day => day === id) : true
    }

    getWeekdays = () => {
        let weekDays = [];
        for (let i = 1; i <= 7; i++) {
            weekDays.push({
                id: i,
                value: dateTimeUtils.getDayNames(i, this.props.i18n.selected.lang),
                isChecked: this.isCheckedWeekday(i)
            })
        }
        return weekDays
    }

    getWeekend = () => {
        return [{
            id: 6,
            value: dateTimeUtils.getDayNames(6, this.props.i18n.selected.lang),
            isChecked: this.isCheckedWeekend(6)
        }, {
            id: 7,
            value: dateTimeUtils.getDayNames(7, this.props.i18n.selected.lang),
            isChecked: this.isCheckedWeekend(7)
        }]
    }

    /**
     * This will return all the checkboxes for the week days
     */
    getCheckBoxes = () => {
        return (<div className='ml-2'>
            {this.state.weekDays.map((day) => {
                return (this.getCheckbox(day, false))
            })}
        </div>)
    }

    /**
     * This will return all the checkboxes for the weekend
     */
    getCheckBoxesWeekend = () => {
        return (<div className='ml-2 hidden'>
            {this.state.weekEnd.map((day) => {
                return (this.getCheckbox(day, true))
            })}
        </div>)
    }

    /**
     * This will return one checkbox
     * @param {object} day
     * @param {boolean} isWeekend
     */
    getCheckbox = (day, isWeekend) => {
        return (<label key={day.id} className="weekdays-checkbox custom-control custom-checkbox">
            <input className="custom-control-input" style={{display: "none"}}
                   onChange={isWeekend ? this.handleCheckWeekendElement : this.handleCheckDaysElement}
                   type="checkbox" checked={day.isChecked} value={day.value}/><span
            className="ml-1 custom-control-label">{day.value}</span>
        </label>)
    }

    /**
     * This will check or uncheck the checkbox once we click on it
     * @param {*} evt
     */
    handleCheckDaysElement = (evt) => {
        let days = this.state.weekDays;
        days.forEach(day => {
            if (day.value === evt.target.value) day.isChecked = evt.target.checked;
        })
        this.setState({weekDays: days});
        this.returnWeekDays();
    }

    /**
     * This will check or uncheck the checkbox once we click on it for the weekends
     * @param {*} evt
     */
    handleCheckWeekendElement = (evt) => {
        let days = this.state.weekEnd;
        days.forEach(day => {
            if (day.value === evt.target.value) day.isChecked = evt.target.checked;
        })
        this.setState({weekEnd: days});
        this.returnWeekEnds();
    }

    /**
     * This will get from the state only the checked days and will return an array to be sent with the form as weekdays[].
     * Also it will calculate based on the binary representation , the value to be sent in scheduleWeekdays prop.
     */
    returnWeekDays = () => {
        let weekdayss = [];
        this.state.weekDays.map(day => {
            if (day.isChecked) weekdayss.push(day.id)
        });
        let binaryWeekdays = 0;
        weekdayss.forEach(e => {
            binaryWeekdays += 2 ** (e - 1);
        })
        this.setState({scheduleWeekdays: binaryWeekdays})
        return weekdayss;
    }

    /**
     * Returns the weekend days checked
     */
    returnWeekEnds = () => {
        let weekendss = [];
        this.state.weekEnd.map(day => {
            if (day.isChecked) weekendss.push(day.id)
        });
        return weekendss;
    }

    /**
     * This returns the number of hours a medication has to be taken, based on how many times per day
     */
    getTimesArrayXTimesADay = (nrTimes) => {
        return medicationUtils.getTimesArrayXTimesADay(nrTimes, this.props.settings)
    }

    /**
     * This returns the number of hours a medication has to be taken, based on every X hours . The one to be displayed, because it uses getTimeCell
     */
    getTimesArrayEveryXHours = (nrHrs) => {
        return medicationUtils.getTimesArrayEveryXHours(nrHrs, this.props.settings)
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        if (evt.target) {
            fields[evt.target.name] = evt.target.value;
        } else {
            fields[evt.name] = evt.value;
        }
        this.setState(fields);
    }

    /**
     * Sets the value when the schedule type is selected
     * @param {event} e
     */
    onScheduleTypeChange = (e) => {
        if (e.value === WHEN_NEEDED) {
            this.setState({
                scheduleType: e.value, times: [], weekendTimes: []
            })
        } else {
            this.setState({
                scheduleType: e.value,
            })
        }
    }

    /**
     * This sets the state based on the object it comes from <HowToTake />
     * @param {*} obj - it can be an object created based on which input changed : the one determining X times a day , the one determining every X hours , or the howToTake select element
     */
    onChange = (obj) => {
        this.setState({
            howToTake: obj.howToTake ? obj.howToTake : this.state.howToTake, scheduleHours: obj.scheduleHours,  //default value,
            scheduleTimesPerDay: obj.scheduleTimesPerDay, //clean the other input since its not in use
            times: obj.times, weekendTimes: obj.weekendTimes
        })
    }

    /**
     * Set the state to the latest selected date.
     *
     * @param {object} date - The event handler argument
     */
    onStartDateChange = (date) => {
        this.setState({dateStarted: moment(date).valueOf()})
    };

    /**
     * Set the state to the latest selected date. It is set when we choose duration : For X days
     *
     * @param {object} date - The event handler argument
     */
    onEndDateChange = (date) => {
        this.setState({ends: moment(date).valueOf()})
    };

    /**
     * Hide the modal
     */
    onHideAddUpdateModal = (event) => {
        this.setState({
            dateStarted: 0,
            name: '',
            notes: '',
            formclass: "",
            errors: {},
            healthIssueIds: [],
            unit: 'TABLET',
            dose: 1,
            scheduleType: WHEN_NEEDED,
            durationType: DURATION_ONGOING,
            ends: 0,
            howToTake: '',
            scheduleTimesPerDay: 1,
            scheduleDaysInactive: 2,
            scheduleDaysActive: 21,
            scheduleWeekdays: 127,
            durationInDays: 5,
            scheduleHours: 8,
            scheduleDaysCountStartsAt: 1,
            temphide: false
        });
        this.props.onHide(event);
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        this.setState({errors: ''});
        if (!this.state.dateStarted) {
            errors.dateStarted = 'datetime_required_message';
        }
        if (!this.state.name) {
            errors.name = 'health_issue_title_required_message';
        }
        if (this.state.scheduleType === SPECIFIC_DAYS_OF_WEEK && this.returnWeekDays().length === 0) {
            errors.weekdays = 'health_issue_title_required_message';
        }
        this.setState({errors});
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }
        return Object.keys(errors).length === 0;
    }

    /**
     * Creates an array with only the health issues that the entry is part of.
     * @param {*} entry - document entry
     */
    getHealthIssueList = (entry) => {
        let healthIss = [];
        if (entry.health_issues_ids) {
            if (this.props.healthIssues && this.props.healthIssues.length > 0) {
                entry.health_issues_ids.map((ids) => {
                    this.props.healthIssues.map((h) => {
                        if (ids === h.id) {
                            healthIss.push(h)
                        }
                    });
                });
                return healthIss;
            }
        }
    }

    cleanHealthIssueList = (entry) => {
        let healthIssClean = [];
        // eslint-disable-next-line no-unused-vars
        entry.map((ids, idz) => {
            // eslint-disable-next-line no-unused-vars
            this.props.healthIssues.map((h, idx) => {
                if (ids === h.id) {
                    healthIssClean.push(h.id)
                }
            });
        });
        return healthIssClean;
    }

    /**
     * It is used to add the selected health issue in the health_issues_ids
     * @param {} selectedItem
     */
    onSelect = (selectedItem) => {
        let selectedIssuesArray = this.state.selectedHealthIssues;
        let selectedIssuesArrayIds = this.state.healthIssueIds;
        selectedIssuesArray.push({value: selectedItem.value, text: selectedItem.value.name});
        selectedIssuesArrayIds.push(selectedItem.value.id);
        this.setState({
            selectedHealthIssues: selectedIssuesArray, healthIssueIds: selectedIssuesArrayIds
        })
    }

    /**
     * It is used to remove the health issue from the array
     * @param {*} removedItem
     */
    onRemove = (removedItem) => {
        let selectedIssuesArray = this.state.selectedHealthIssues.filter((issue) => {
            if (issue.value) {
                return issue.value.id !== removedItem.value.id;
            } else return issue.id !== removedItem.value.id;
        });
        let selectedIssuesArrayIds = this.state.healthIssueIds.filter((id) => {
            return (removedItem.value.id !== id);
        });
        this.setState({
            selectedHealthIssues: selectedIssuesArray, healthIssueIds: selectedIssuesArrayIds
        })
    }

    /**
     * Form submit handler, validate data and set error in state if any. Call create health issue entry action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();
    }

    /**
     * Handle form creation or update based on the selected entry
     * @param {Object} form - request object to be sent to the server
     */
    handleCreateOrUpdate = async (form) => {
        if (this.props.selectedEntry) {
            await this.props.updateMedication(form, this.props.selectedUser.id);
            this.props.clearSelectedEntry();
        } else {
            await this.props.createMedication(form, this.props.selectedUser.id);
        }
        this.onHideAddUpdateModal();
    }


    /**
     * Create new entry or update existing entry from list
     */
    onSaveAddUpdateModal = async () => {

        let formElement  = document.getElementById("medicationForm");

        if (!formElement.checkValidity()) {
            if (this.state.formclass !== "was-validated") {
                this.setState({formclass: "was-validated"});
            }
            return;
        }

        if (this.validate()) {
            let form = {};
            if (this.props.selectedEntry) {
                form = {...this.props.selectedEntry}
                form.schedule = {...this.props.selectedEntry.schedule};
                form.schedule.cycle = {...this.props.selectedEntry.schedule.cycle};

                if (this.props.selectedEntry.schedule.id) {
                    let shouldBeDeactivated = await this.props.getPlanStats(this.props.selectedUser.id, form.id).then(() => {
                        if (this.props.medication.medicationPlanStats.statistics.skipped !== 0 || this.props.medication.medicationPlanStats.statistics.confirmed !== 0 || this.props.medication.medicationPlanStats.statistics.deleted !== 0 || this.props.medication.medicationPlanStats.statistics.unknown !== 0) {
                            return true
                        }
                    })
                    if (shouldBeDeactivated) {
                        let entryToMakeInactive = this.props.selectedEntry;
                        entryToMakeInactive.inactive = true;
                        this.handleCreateOrUpdate(entryToMakeInactive)
                        delete form.id
                        this.props.clearSelectedEntry()
                    }
                }
            }
            if (!this.props.selectedEntry) {
                form.schedule = {}
                form.schedule.cycle = {}
                form = {
                    ...form,
                    user_id: this.props.selectedUser.id,
                    starts: this.state.dateStarted,
                    notes: this.state.notes,
                    deleted: false,
                    created_datetime: moment().valueOf(),
                    updated_datetime: moment().valueOf(),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    name: this.state.name,
                    health_issues_ids: this.cleanHealthIssueList(this.state.healthIssueIds),
                    unit: this.state.unit,
                    dose: this.state.dose,
                    scheduleType: this.state.scheduleType,
                    durationType: this.state.durationType,
                    ends: this.state.ends,
                    durationInDays: this.state.durationInDays,
                    howToTake: this.state.howToTake,
                    scheduleTimesPerDay: this.state.scheduleTimesPerDay,
                    scheduleHours: this.state.scheduleHours,
                    scheduleDaysActive: this.state.scheduleDaysActive,
                    scheduleDaysInactive: this.state.scheduleDaysInactive,
                    scheduleDaysCountStartsAt: this.state.scheduleDaysCountStartsAt,
                    scheduleWeekdays: this.state.scheduleWeekdays,
                    inactive: false,
                    shapeIndex: this.state.pillShape,
                    shapePrimaryColor: CONVERTER.hexTwosComplementDecimal(this.state.pillPrimaryColour),
                    shapeSecondaryColor: CONVERTER.hexTwosComplementDecimal(this.state.pillSecondaryColour),
                };
                form.schedule.cycle.daysInactive = this.state.scheduleDaysInactive;
                form.schedule.cycle.daysActive = this.state.scheduleDaysActive;
                form.schedule.cycle.countStartsAt = this.state.scheduleDaysCountStartsAt;
                form.schedule.weekdays = this.returnWeekDays();
                form.schedule.times = this.state.times;
                form.schedule.weekends = this.returnWeekEnds();
                form.schedule.weekendTimes = this.state.weekendTimes;
            } else {
                form = {
                    ...form,
                    user_id: this.props.selectedUser.id,
                    starts: this.state.dateStarted,
                    notes: this.state.notes,
                    deleted: false,
                    created_datetime: moment().valueOf(),
                    updated_datetime: moment().valueOf(),
                    name: this.state.name,
                    health_issues_ids: this.cleanHealthIssueList(this.state.healthIssueIds),
                    unit: this.state.unit,
                    dose: this.state.dose,
                    scheduleType: this.state.scheduleType,
                    durationType: this.state.durationType,
                    ends: this.state.ends,
                    durationInDays: this.state.durationInDays,
                    howToTake: this.state.howToTake,
                    scheduleTimesPerDay: this.state.scheduleTimesPerDay,
                    scheduleHours: this.state.scheduleHours,
                    scheduleDaysActive: this.state.scheduleDaysActive,
                    scheduleDaysInactive: this.state.scheduleDaysInactive,
                    scheduleDaysCountStartsAt: this.state.scheduleDaysCountStartsAt,
                    scheduleWeekdays: this.state.scheduleWeekdays,
                    inactive: false,
                    shapeIndex: this.state.pillShape,
                    shapePrimaryColor: CONVERTER.hexTwosComplementDecimal(this.state.pillPrimaryColour),
                    shapeSecondaryColor: CONVERTER.hexTwosComplementDecimal(this.state.pillSecondaryColour),
                };
                form.schedule.cycle.daysInactive = this.state.scheduleDaysInactive;
                form.schedule.cycle.daysActive = this.state.scheduleDaysActive;
                form.schedule.cycle.countStartsAt = this.state.scheduleDaysCountStartsAt;
                form.schedule.weekdays = this.returnWeekDays();
                form.schedule.times = this.state.times;
                form.schedule.weekends = this.returnWeekEnds();
                form.schedule.weekendTimes = this.state.weekendTimes;
            }

            if (form.scheduleType === EVERY_X_DAYS) {
                form.scheduleDaysInactive = Number(form.scheduleDaysInactive) - 1;
                form.schedule.cycle.daysInactive = form.scheduleDaysInactive;
                form.schedule.cycle.daysActive = 1;
                form.scheduleDaysActive = 1;
                form.schedule.cycle.countStartsAt = 1;
            }

            if (form.durationType === DURATION_FOR_X_DAYS) {
                form.ends = moment(form.starts).add(form.durationInDays, 'd').valueOf();
            }

            if (form.durationType === DURATION_ONGOING) {
                form.ends = 0;
            }

            let array1 = form.schedule.weekendTimes;
            let array2 = form.schedule.times;
            if (array1?.length === 0 || !this.state.showWeekend || (array1 && array2 && array1.length === array2?.length && array1.every(function (value, index) {
                return value === array2[index]
            }))) {
                form.schedule.weekendTimes = null;
            }

            this.handleCreateOrUpdate(form)
            this.props.needsUpdate();
        }
    }

    /**
     * Get the formatted medication duration string
     *
     * @returns {string} medication plan duration
     */
    getMedicationDurationString = () => {
        const durationTypes = [{text: $$('ongoing'), value: DURATION_ONGOING}];
        durationTypes.push({
            text: _$$('for_x_days_until'/*, CONVERTER.formatDate(this.state.ends)*/), value: DURATION_FOR_X_DAYS
        })
        durationTypes.push({
            text: _$$('until_date'/*, CONVERTER.formatDate(this.state.ends, true)*/), value: DURATION_UNTIL_DATE
        })
        return durationTypes;
    }


    /**
     * Get the formatted medication schedule string
     *
     * @returns {string} medication plan schedule
     */
    getMedicationScheduleString = () => {
        const scheduleTypes = [{text: $$('when_needed'), value: WHEN_NEEDED}];
        scheduleTypes.push({text: $$('daily'), value: DAILY})
        scheduleTypes.push({text: _$$('every_x_days'), value: EVERY_X_DAYS})
        let scheduleWeekdays = this.state.scheduleWeekdays;
        let days = [];
        (scheduleWeekdays >>> 0).toString(2).split('').forEach((bit, idx) => {
            if (bit === '1') {
                days.push(moment().locale(this.props.i18n.selected.lang).day(idx + 1).format('ddd'));
            }
        });

        scheduleTypes.push({text: _$$('specific_days_of_week'), value: SPECIFIC_DAYS_OF_WEEK})
        scheduleTypes.push({text: _$$('take_x_rest_x'), value: CYCLE})
        return scheduleTypes;
    }

    /**
     * Get the formatted medication frequency string
     *
     * @returns {string} medication plan frequency
     */
    getMedicationFrequencyString = () => {
        const howToTakes = [{text: _$$('frequency_times_day'), value: 'TAKE_X_TIMES'}];
        howToTakes.push({text: _$$('frequency_every_hours'), value: 'TAKE_EVERY_X_HOURS'});
        return howToTakes;

    }

    onHidePillPicker = (selection, updating) => {
        this.setState({
            showPillPicker: false, tempHide: false, pillShape: selection && selection.pillShape,
        })

        if (!updating) {
            this.setState({
                pillPrimaryColour: this.state.previousPrimaryColour,
                pillSecondaryColour: this.state.previousSecondaryColour
            })
        }

        if (selection && selection.pillShape === 0) {
            this.setState({
                pillPrimaryColour: "#FFFFFF", pillSecondaryColour: "#FFFFFF"
            })
        }

        if (selection && selection.pillShape > 4) {
            this.setState({
                pillSecondaryColour: "#FFFFFF"
            })
        }
    }

    setPrimaryColour = (colour) => {
        this.setState({pillPrimaryColour: colour})
    }

    setPreviousPrimaryColour = (colour) => {
        this.setState({previousPrimaryColour: colour})
    }

    setPreviousSecondaryColour = (colour) => {
        this.setState({previousSecondaryColour: colour})
    }

    setSecondaryColour = (colour) => {
        this.setState({pillSecondaryColour: colour})
    }

    setPillSelection = (shape) => {
        this.setState({
            pillShape: 9001
        }, () => {
            this.setState({pillShape: shape})
        });
    }

    resetPillToDefault = () => {
        this.setState({
            pillShape: 0, pillPrimaryColour: "#ffffff", pillSecondaryColour: "#ffffff"
        })
    }

    setWeekend = () => {
        let state = {showWeekend: !this.state.showWeekend};
        state.weekendTimes = [...this.state.times];
        this.setState(state)
    }

    getMedicationSource = () => {
        if (this.props.selectedUser.country?.toLowerCase() === "bg" ||
            this.props.i18n.selected.lang === "bg") {
            return "ncpr"
        } else {
            return "nih"
        }
    }
    setMedicationFromSearch = (medication) => {
        this.setState({
            name: medication.name + (medication.strength && medication.strength.trim() !== "" ? ` ${medication.strength}` : ""),
            unit: medication.unit,
            showMedSearch: false,
            medicationSearchResults: [],
            medSearchValue: ""
        })
    }

    medSearchResults = () => {
        let results = []
        for (let i in this.state.medicationSearchResults) {
            results.push(<div onClick={() => {
                this.setMedicationFromSearch(this.state.medicationSearchResults[i])
            }} key={i} className={"med-search-result"}>
                <SelectedPill primaryColour={"#ffffff"}
                              secondaryColour={"#ffffff"}
                              pillSelection={0}
                />
                <span className={"medication-result"} onClick={() => {
                    this.setMedicationFromSearch(this.state.medicationSearchResults[i])
                }} key={i}> {this.state.medicationSearchResults[i].name}
                    <br/> {this.state.medicationSearchResults[i].form ?? this.state.medicationSearchResults[i].form} {this.state.medicationSearchResults[i].strength}
                    <br/>
                    <span
                        className={this.state.medicationSearchResults[i].needsPrescription ? "text-danger" : "text-success"}>{this.state.medicationSearchResults[i].needsPrescription ? $$('needs_prescription') : $$('doesnt_need_prescription')} </span>
                </span>
            </div>)
        }
        return results;
    }

    searchForMedication = async (searchTerm) => {
        let res = await searchForMedication({searchText: searchTerm, source: this.getMedicationSource()})
        this.setState({
            loadingComplete: true, medicationSearchResults: res
        })
    }

    searchForMedications = async (searchTerm) => {
        this.setState({medSearchValue: searchTerm})
        if (searchTerm.length > 2) {
            this.setState({showMedSearch: true, loadingComplete: false, medicationSearchResults: []})
            _.throttle(this.searchForMedication(searchTerm), 100);
        } else {
            this.setState({showMedSearch: false, loadingComplete: false, medicationSearchResults: []})
        }
    }

    render() {
        let noMedications = {
            imgClass: 'no-entry-image-landing',
            objClass: 'no-data-object-landing',
            primaryLabelClass: 'no-medications-landing-label',
            src: no_data,
            primaryLabel: $$('no_results_found'),
            nobreak: true
        }
        return (<>
            <CenteredModal
                title={this.props.selectedEntry ? $$('update_medication') : $$('add_medication')}
                dialogClassName='add-doc-modal'
                show={this.props.show && !this.state.tempHide}
                onHide={this.state.showMedSearch ? () => {
                    this.setState({showMedSearch: false, loadingComplete: false, medicationSearchResults: []})
                } : this.onHideAddUpdateModal}
                confirmBtnLabel={$$('save_btn_label')}
                cancelBtnLabel={$$('cancel_btn')}
                onConfirm={this.onSaveAddUpdateModal}
            >
                <div className="d-flex form-group flex-space-between">
                    <Search
                        handleSearchChange={this.searchForMedications}
                        searchValue={this.state.medSearchValue}
                        externalSearchValue={true}
                        placeholder={$$('search_for_medications')}
                    />
                </div>

                <FormWithLoading
                    formDisabled={this.props.formDisabled}
                    currentForm={MEDICATIONS}
                    marginTop="30%"
                    marginLeft="calc(50% - 70px)"
                    id="medicationForm"
                    onSubmit={this.onSubmit}
                    className={this.state.formclass}>
                    <div className="mt-3">
                        {this.state.showMedSearch && <>
                            <div className={"med-search-title mb-3"}>
                                {$$("medications_found")}
                                <a style={{paddingTop: "5px"}} className={"la la-close"} onClick={() => {
                                    this.setState({
                                        showMedSearch: false, medicationSearchResults: []
                                    })
                                }}/>
                            </div>

                            {!this.state.loadingComplete && <div className="med-search-loader"/>}

                            {this.medSearchResults()}
                            {this.state.medicationSearchResults.length === 0 && this.state.loadingComplete && infoUtils.noData(noMedications)}
                        </>}

                        {!this.state.showMedSearch && <>
                            <div className="row form-group">
                                <label>{$$("medication_name_label")}</label>
                                <input type="text" className="form-control" value={this.state.name}
                                       placeholder={$$('medication_name_label')} name="name"
                                       onChange={this.onInputChange} required/>
                            </div>
                            <div className="row form-group">
                                <div className="col-sm-6 form-group">
                                    <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                        <KeyboardDateTimePicker
                                            variant="inline"
                                            format={CONVERTER.getDateFNSDateTimeFormat(false)}
                                            margin="normal"
                                            id="date-picker-inline"
                                            label={$$("start_date_label")}
                                            value={this.state.dateStarted}
                                            onChange={this.onStartDateChange}
                                            KeyboardButtonProps={{'aria-label': 'change date',}}
                                            required
                                            invalidDateMessage={$$('invalid_date_format')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div className="d-flex form-group flex-space-between">
                                <div>
                                    <label>{$$('dose_label')}</label>
                                    <div className="d-flex dose-unit-med-form">
                                        <div className="dose-med-form"><input
                                            className={this.state.errors?.dose ? "custom-error form-control" : "form-control"}
                                            type="number"
                                            min="0.000001"
                                            step="any"
                                            name="dose"
                                            value={this.state.dose}
                                            required
                                            onChange={this.onInputChange}/>
                                        </div>

                                        <div className="ml-3">
                                            <Select
                                                name="unit"
                                                options={getResolvedOptions(UNIT_TYPES.TYPE)}
                                                value={this.state.unit}
                                                onChange={this.onInputChange}
                                                placeHolder={$$('unit_label')}
                                                hasNoLabel={true}
                                                style={{
                                                    'minWidth': '100%',
                                                    'maxWidth': '100%',
                                                    'zIndex': '1',
                                                    'opacity': '70%'
                                                }}/>

                                        </div>
                                    </div>

                                </div>
                                <div className="pill-picker-pill-component mx-4 mt-3"
                                     onClick={() => this.setState({
                                         showPillPicker: true, tempHide: true
                                     })}>
                                    <SelectedPill primaryColour={this.state.pillPrimaryColour}
                                                  secondaryColour={this.state.pillSecondaryColour}
                                                  pillSelection={this.state.pillShape}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <Select
                                    label={$$("schedule")}
                                    name="scheduleType"
                                    options={this.getMedicationScheduleString()}
                                    value={this.state.scheduleType}
                                    onChange={this.onScheduleTypeChange}
                                    placeHolder={$$('schedule')}
                                    style={{
                                        'minWidth': '100%', 'maxWidth': '100%', 'zIndex': '1', 'opacity': '70%'
                                    }}/>
                            </div>
                            {this.state.scheduleType && this.state.scheduleType !== WHEN_NEEDED &&
                                <NotWhenNeededForms scheduleType={this.state.scheduleType}
                                                    i18n={this.props.i18n}
                                                    scheduleDaysInactive={this.state.scheduleDaysInactive}
                                                    onInputChange={this.onInputChange}
                                                    getCheckBoxes={this.getCheckBoxes}
                                                    scheduleDaysActive={this.state.scheduleDaysActive}
                                                    scheduleDaysCountStartsAt={this.state.scheduleDaysCountStartsAt}
                                                    getMedicationFrequencyString={this.getMedicationFrequencyString}
                                                    howToTake={this.state.howToTake}
                                                    scheduleTimesPerDay={this.state.scheduleTimesPerDay}
                                                    scheduleHours={this.state.scheduleHours}
                                                    getTimesArrayEveryXHours={this.getTimesArrayEveryXHours}
                                                    getTimesArrayXTimesADay={this.getTimesArrayXTimesADay}
                                                    onChange={this.onChange}
                                                    setWeekend={this.setWeekend}
                                                    showWeekend={this.state.showWeekend ? this.state.showWeekend : false}
                                                    getCheckBoxesWeekend={this.getCheckBoxesWeekend}
                                                    getMedicationDurationString={this.getMedicationDurationString}
                                                    durationType={this.state.durationType}
                                                    ends={this.state.ends}
                                                    onEndDateChange={this.onEndDateChange}
                                                    durationInDays={this.state.durationInDays}
                                                    times={this.state.times}
                                                    weekendTimes={this.state.weekendTimes}
                                                    updateState={(o) => this.setState(o)}
                                                    ampm={!this.props.settings.time24hour}
                                />}
                            <div className="form-group">
                                <CustomMultiselect
                                    options={getHealthIssueOptions(this.props.healthIssues)}
                                    selectedValues={this.state.selectedHealthIssues}
                                    onSelect={this.onSelect}
                                    onRemove={this.onRemove}
                                    displayValue="text"
                                    placeholder={$$('select_health_issue_label')}
                                    closeIcon='cancel'
                                    avoidHighlightFirstOption={true}
                                    formClass={this.state.formclass}/>
                            </div>
                            <div className="form-group">
                                {this.state.notes && <label>{$$('notes')}</label>}
                                <textarea className="form-control"
                                          value={this.state.notes || ''}
                                          maxLength="500"
                                          placeholder={$$('notes')}
                                          name="notes"
                                          onChange={this.onInputChange}/>
                            </div>
                        </>}
                    </div>
                </FormWithLoading>
            </CenteredModal>
            <PillPicker showPillPicker={this.state.showPillPicker}
                        onHidePillPicker={this.onHidePillPicker}
                        primaryColour={this.state.pillPrimaryColour}
                        secondaryColour={this.state.pillSecondaryColour}
                        pillSelection={this.state.pillShape}
                        setPrimaryColour={this.setPrimaryColour}
                        setSecondaryColour={this.setSecondaryColour}
                        setPreviousPrimaryColour={this.setPreviousPrimaryColour}
                        setPreviousSecondaryColour={this.setPreviousSecondaryColour}
                        resetPillToDefault={this.resetPillToDefault}
                        setPillSelection={this.setPillSelection}
                        selectedEntry={this.props.selectedEntry}
            />
        </>)
    }
}

MedicationForm.propTypes = {
    clearSelectedEntry: PropTypes.func,
    createMedication: PropTypes.func,
    fetchSelectedUserMedicationPlan: PropTypes.func,
    importMedication: PropTypes.any,
    formDisabled: PropTypes.object,
    getPlanStats: PropTypes.func,
    healthIssues: PropTypes.array,
    i18n: PropTypes.object,
    medication: PropTypes.object,
    needsUpdate: PropTypes.func,
    onHide: PropTypes.func,
    selectedEntry: PropTypes.object,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    show: PropTypes.bool,
    updateMedication: PropTypes.func
}

export default MedicationForm
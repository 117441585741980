import React, {Component} from 'react'
import {$$} from '../../../helpers/localization'
import {CONVERTER} from '../../../utils/converter'
import PropTypes from "prop-types";

export default class WeightForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.weight ? $$('weight_label') : null}<br/>
                <div className="form-group">
                    <div className="input-group mb-3">
                        <input type="number" step="0.01"
                               className={this.props.errors.weight ? "custom-error form-control" : "form-control"}
                               value={this.props.weight} placeholder={$$('weight_label')}
                               name="weight" onChange={this.props.onInputChange} required/>
                        <div className="input-group-append">
                            <span className="input-group-text" style={{"height": "auto"}}
                                  id="basic-addon2">{CONVERTER.getUnitByType('WEIGHT')}</span>
                        </div>
                    </div>
                    <div
                        className={this.props.errors.weight ? "custom-invalid-feedback" : "invalid-feedback"}>
                        {this.props.errors.weight ? $$(this.props.errors.weight) : $$('weight_required_message')}
                    </div>
                </div>
            </div>
        )
    }
}

WeightForm.propTypes = {
    clearSelectedEntry: PropTypes.func,
    createEntry: PropTypes.func,
    onInputChange: PropTypes.func,
    errors: PropTypes.any,
    weight: PropTypes.any,
    fetchSelectedUserHealthIssues: PropTypes.func,
    formDisabled: PropTypes.object,
    getHealthIssueList: PropTypes.func,
    healthIssues: PropTypes.object,
    logbookEntries: PropTypes.array,
    medicalProfile: PropTypes.object,
    onHide: PropTypes.func,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    show: PropTypes.bool,
    type: PropTypes.string,
    updateEntry: PropTypes.func,
    updateUserMedicalProfile: PropTypes.func
}
import React, {Component} from 'react'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import PropTypes from "prop-types";

export default class SymptomsTreeView extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        console.log(this.props)
    }

    /**
     * Renders the TreeItem label and registers onClick event listener
     *
     * @param {object} symptom - the symptom in the list
     */
    renderLabel = (symptom) => {
        return <span
            onClick={() => {
                this.props.onSymptomSelected(symptom);
            }}
        >
            {symptom.description}
        </span>
    }

    /**
     * Manipulates the symptoms retrieved as prop based on their nomenclature field and
     * prepares an array with TreeItem objects corresponding to symptoms
     *
     * @returns {Array} array with TreeItem objects corresponding to symptoms
     */
    getTreeItems = () => {
        let symptoms = this.props.symptoms;
        let treeItems = [];

        treeItems.push(symptoms.filter(symptom => symptom.nomenclature.indexOf('.') === -1).map((symptom, idx) => {
            let children = symptoms.filter(s => s.nomenclature !== symptom.nomenclature &&
                s.nomenclature.split('.')[0] === symptom.nomenclature.split('.')[0] &&
                s.nomenclature.split('.').length === 2);

            let childrenTreeItems = children.map((ch, idx) => {
                let grandChildTreeItems = symptoms.filter(s =>
                    s.nomenclature !== ch.nomenclature &&
                    s.nomenclature.split('.')[0] === ch.nomenclature.split('.')[0] &&
                    s.nomenclature.split('.')[1] === ch.nomenclature.split('.')[1] &&
                    s.nomenclature.split('.').length === 3).map((s, idx) => {
                    return <TreeItem key={idx} nodeId={s.nomenclature.toString()} label={this.renderLabel(s)}/>;
                });
                return grandChildTreeItems.length > 0 ?
                    <TreeItem key={idx} nodeId={ch.nomenclature.toString()} label={ch.description}
                              type={ch.type}>{grandChildTreeItems}</TreeItem>
                    : <TreeItem key={idx} nodeId={ch.nomenclature.toString()} label={this.renderLabel(ch)}/>
            });

            let mainTreeItem = <TreeItem key={idx} nodeId={symptom.nomenclature.toString()}
                                         label={symptom.description}>{childrenTreeItems}</TreeItem>
            treeItems.push(mainTreeItem);
        }));

        return treeItems;
    }

    render() {
        return (
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon/>}
                defaultExpandIcon={<ChevronRightIcon/>}
            >
                {this.getTreeItems()}
            </TreeView>
        );
    }
}

SymptomsTreeView.propTypes = {
    onSymptomSelected: PropTypes.func,
    symptoms: PropTypes.array
};

import React, {Component} from "react";
import {$$} from '../../helpers/localization'
import CenteredModal from "../shared/CenteredModal";
import {
    KeyboardDatePicker,
    KeyboardTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
import PropTypes from "prop-types";
import bg from 'date-fns/locale/bg';
import sq from 'date-fns/locale/sq';
import enGB from 'date-fns/locale/en-GB';
import {dateTimeUtils} from "../../utils/dateTimeUtils";


class TakeRescheduleModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: this.props.date ? new Date(this.props.date) : new Date(),
            selectedTime: this.props.time ? this.props.time : dateTimeUtils.currentTimestampWithoutSeconds(),
            plannedDate: this.props.time && this.props.date ? this.dateAndHourToTimeStamp(this.props.date, this.props.time) : dateTimeUtils.currentTimestampWithoutSeconds()
        }

    }

    getLocale = () => {
        switch(this.props.i18n.selected.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            case "sq":
                return sq
            default:
                return enGB
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.date !== this.props.date || this.props.time !== prevProps.time) {
            this.setState({
                selectedDate: this.props.date ? new Date(this.props.date) : new Date(),
                selectedTime: this.props.time ? this.props.time : dateTimeUtils.currentTimestampWithoutSeconds(),
                plannedDate: this.props.time && this.props.date ? this.dateAndHourToTimeStamp(this.props.date, this.props.time) : dateTimeUtils.currentTimestampWithoutSeconds()
            })
        }
    }

    onDateChange = (date) => {

        this.setState({selectedDate: moment(date).valueOf()})

    };

    onTimeChange = (time) => {

        this.setState({selectedTime: time})
    }

    dateAndHourToTimeStamp = (date, time) => {
        let dateToReturn = new Date(date);
        let hour = moment(time).format('HH:mm').split(':')[0];
        let minute = moment(time).format('HH:mm').split(':')[1];
        dateToReturn.setHours(hour);
        dateToReturn.setMinutes(minute, 0, 0);
        return moment(dateToReturn).valueOf();
    }

    takeAsPlanned = () => {
        this.props.timePicked('TAKE_AS_PLANNED', this.state.plannedDate)
    }
    takeNow = () => {
        this.props.timePicked('TAKE_NOW', dateTimeUtils.currentTimestampWithoutSeconds(), this.props.whenNeeded)
    }
    takeAtSelectedTime = () => {
        this.props.timePicked('TAKE_AT_SELECTED_TIME', this.dateAndHourToTimeStamp(this.state.selectedDate, this.state.selectedTime), this.props.whenNeeded)
    }
    reschedule = () => {
        this.props.timePicked('RESCHEDULE', this.dateAndHourToTimeStamp(this.state.selectedDate, this.state.selectedTime))
    }

    render() {
        return (
            <CenteredModal
                title={$$('pick_date_and_time')}
                dialogClassName='add-doc-modal'
                show={this.props.show}
                onHide={this.props.onHide}
                confirmBtnLabel={$$('reschedule_label')}
                cancelBtnLabel={$$('cancel_btn')}
                onConfirm={this.reschedule}
                primary={this.props.type === 'TAKE'}>
                <MuiPickersUtilsProvider locale={this.getLocale()}
                                         utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        variant="inline"
                        format={this.props.settings.data.dateFormat}
                        margin="normal"
                        id="date-picker-inline"
                        label={$$("log_symptoms_date_label")}
                        value={this.state.selectedDate}
                        onChange={this.onDateChange}
                        KeyboardButtonProps={{'aria-label': 'change date',}}
                        required
                        invalidDateMessage={$$('invalid_date_format')}
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                    <KeyboardTimePicker
                        ampm={!this.props.settings.data.time24hour}
                        margin="normal"
                        id="time-picker"
                        okLabel={$$("OK")}
                        cancelLabel={$$("cancel_btn")}
                        label={$$("log_symptoms_time_label")}
                        value={this.state.selectedTime}
                        KeyboardButtonProps={{'aria-label': 'change time',}}
                        onChange={this.onTimeChange}
                        required
                        invalidDateMessage={$$('invalid_time_format')}
                    />
                </MuiPickersUtilsProvider>
                {this.props.type === 'TAKE' && <div className="mt-2">
                    {!this.props.whenNeeded && <button type="button" className="btn btn-primary btn-block"
                                                       onClick={this.takeAsPlanned}>
                        {$$("take_as_planned_label").replace('{time}', moment(this.state.plannedDate).format('HH:mm'))}
                    </button>}
                    <button type="button" className="btn btn-primary btn-block"
                            onClick={this.takeNow}>
                        {$$("take_now_label").replace('{time}', moment().format('HH:mm'))}
                    </button>
                    <button type="button" className="btn btn-primary btn-block"
                            onClick={this.takeAtSelectedTime}>
                        {$$("take_at_selected_time_label")}
                    </button>
                </div>}
            </CenteredModal>
        )
    }
}

TakeRescheduleModal.propTypes = {
    date: PropTypes.any,
    onHide:  PropTypes.func,
    settings: PropTypes.object,
    type: PropTypes.any,
    i18n: PropTypes.any,
    show: PropTypes.bool,
    time: PropTypes.number,
    timePicked:  PropTypes.func,
    whenNeeded: PropTypes.bool
}

export default TakeRescheduleModal
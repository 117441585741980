import {fetchHelper} from '../helpers/request_helper'
import {
    ALL_HEALTH_ISSUES_FOR_USER,
    CREATE_UPDATE_HEALTH_ISSUE,
    DELETE_HEALTH_ISSUE
} from '../constants/api_paths'

export const healthIssuesService = {
    fetchSelectedUserHealthIssues,
    createHealthIssue,
    updateHealthIssue,
    deleteHealthIssue
};

/**
 * Fetch the health issues method
 *
 * @param {number} userId id of the user to fetch health issues for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserHealthIssues(userId, params) {
    return fetchHelper.callGet(ALL_HEALTH_ISSUES_FOR_USER.replace('{userId}', userId), params);
}

/**
 * Creates entry
 * @param {formData} entry The entry to be created
 */
export function createHealthIssue(entry) {
    return fetchHelper.callPost(CREATE_UPDATE_HEALTH_ISSUE, entry, false);
}

/**
 * Creates/Updates health issue
 * @param {object} entry The entry to be created/updated
 */
export function updateHealthIssue(entry) {
    return fetchHelper.callPut(CREATE_UPDATE_HEALTH_ISSUE, entry, null, false)
}

/**
 * Deletes health issue with the given id.
 *
 * @param {number} healthIssueId id of the health issue to delete
 * @returns {object} promise object
 */
export function deleteHealthIssue(healthIssueId) {
    return fetchHelper.callDelete(DELETE_HEALTH_ISSUE.replace('{healthIssueId}', healthIssueId));
}
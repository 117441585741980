import React, {Component} from 'react'
import HospitalCliniciansView from './HospitalCliniciansView';
import PropTypes from "prop-types";
import {fetchHospitals, fetchProviders, fetchSpecialties, fetchSpecialtiesCount} from "../../actions/provider_actions";
import {connect} from "react-redux";
import {Routes} from "../../constants/routes";
import history from "../../helpers/history";
import {$$} from "../../helpers/localization";

class PublicHospitalPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            specialties: null,
            selectedSpecialtyFilter: null,
            searchValue: '',
            showClinicians: true,
            selectedHospital: null,
            hospitals: {}
        }
    }


    /**
     * Return select options with the text resolved to the selected language
     *
     * @param {Array} options - the list of options
     */
    getSpecialtiesOptions(options) {
        return options && options.map(o => {
            if (o.count > 0)
                return {value: o.specialty, text: $$(o.specialty.toLowerCase())}
        });
    }

    getSpecialitiesPlusAll(options) {
        const optionArray = this.getSpecialtiesOptions(options);
        optionArray.unshift({value: "ALL", text: $$("all_clinicians")})
        return optionArray;
    }

    /**
     * Sets the state based on what is entered by the user
     *
     * @param {string} searchValue - the value entered by the user, used for filtering purposes
     */
    filterCliniciansByName = (searchValue) => {
        this.setState({searchValue});
    }

    onSelectChange = async (evt) => {
        this.setState({cliniciansLoading: true})
        evt.value === "ALL" ? this.props.fetchProviders(null, null).then(() => {
            this.setState({cliniciansLoading: false})
        }) : this.props.fetchProviders(evt.value, null).then(() => {
            this.setState({cliniciansLoading: false})
        });
        this.setState({selectedSpecialtyFilter: evt.value})
    }

    componentDidMount = async () => {
        await this.props.fetchHospitals().then(() => {
            this.setState({hospitalsLoading: false})
        })

        this.setState({
            specialties: "",
        })
        await this.props.fetchProviders(null, null).then(() => {
            this.setState({cliniciansLoading: false})
        })
        await this.props.fetchSpecialtiesCount()

        let hospital = this.getHospital();
        this.setState({hospitals: hospital})

        if(hospital.length === 0){
            history.push(Routes.LOGIN);
        }

        this.showClinicians(hospital[0])
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if((!this.state.cliniciansLoading && prevState.cliniciansLoading) || (this.state.searchValue !== prevState.searchValue)){
            let hospital = this.getHospital();
            this.setState({hospitals: hospital})

            if (hospital.length === 0) {
                history.push(Routes.LOGIN);
            }

            this.showClinicians(hospital[0])
        }
    }


    /**
     * Filters the clients based on the search value
     *
     * @returns {Array} the list of filtered clients
     */
    getFilteredClinicians = () => {
        return this.props.providers ? this.props.providers.filter(u => {
            let shouldReturn = true;
            if (!this.state.searchValue) {
                return true;
            }
            shouldReturn = u.fullname.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1;
            u.specialties.map((specialty) => {
                if (specialty.replace("_", " ").toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1) shouldReturn = true
            })

            return shouldReturn
        }) : [];
    }


    getClinicFromSearchParams = () => {
        let orgId = "";
        const queryParams = new URLSearchParams(this.props.location.search)
        if (queryParams.has('org')) {
            orgId = queryParams.get('org');
        }
        return orgId
    }

    getHospital = () => {
        let clinicians = this.getFilteredClinicians();
        let hospitals = this.props.hospitals.hospitals;
        let hospitalsUpdated = []
        let selectedHospitalId = this.getClinicFromSearchParams()

        hospitals?.map((hospital) => {
            if (hospital.id === selectedHospitalId) {
                hospitalsUpdated.push({hospital, clinicians: []})
            }
        })

        hospitalsUpdated.map((hospital) => {
            clinicians.map((clinician) => {
                clinician.organizations.map((organization) => {
                    if (organization.name === hospital.hospital.name) {
                        hospital.clinicians.push(clinician);
                    }
                })
            })
        })
        return hospitalsUpdated;
    }

    showClinicians = (hospital) => {
        this.setSelectedHospital(hospital)
        this.setState({showClinicians: true})
    }

    hideClinicians = () => {
        this.setState({showClinicians: false})
    }

    setSelectedHospital = (hospital) => {
        this.setState({selectedHospital: hospital})
    }

    render() {
        return (
            <>
                {this.props.loading && <div className={"med-search-loader"}/>}
                {!this.props.loading && this.state.selectedHospital &&
                    <HospitalCliniciansView
                        location={this.props.location}
                        hospital={this.state.selectedHospital}
                        hideClinicians={this.hideClinicians}
                        specialtiesOptions={this.getSpecialitiesPlusAll(this.props.specialties)}
                        specialties={this.state.specialties}
                        selectedSpecialtyFilter={this.state.selectedSpecialtyFilter}
                        onSelectChange={this.onSelectChange}
                        filterCliniciansByName={this.filterCliniciansByName}
                        onClinicianClick={this.props.onClinicianClick}
                    />
                }
            </>
        )
    }
}

PublicHospitalPage.propTypes = {
    hospitals: PropTypes.object,
    specialties: PropTypes.array,
    location: PropTypes.object,
    i18n: PropTypes.object,
    loading: PropTypes.bool,
    onClinicianClick: PropTypes.func,
    fetchHospitals: PropTypes.func,
    fetchProviders: PropTypes.func,
    fetchSpecialtiesCount: PropTypes.func,
    providers: PropTypes.array,
    searchValue: PropTypes.any,
}

function mapStateToProps(state) {
    return {
        userInfo: state.userInfo.data,
        settings: state.settings,
        providers: state.providers.entries,
        i18n: state.language,
        specialties: state.specialties.specialties,
        hospitals: state.hospitals
    }
}

const mapDispatchToProps = {
    fetchHospitals,
    fetchProviders,
    fetchSpecialtiesCount,
    fetchSpecialties,
}
export default connect(mapStateToProps, mapDispatchToProps)(PublicHospitalPage)

import {
    CLEAR_SYMPTOMS_LOG,
    FETCH_LATEST_SYMPTOMS_TYPES_ERROR,
    FETCH_LATEST_SYMPTOMS_TYPES_SUCCESS,
    FETCH_SELECTED_USER_SYMPTOMS_ERROR,
    FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT,
    FETCH_SELECTED_USER_SYMPTOMS_SUCCESS,
    GET_NOMENCLATURE_ERROR,
    GET_NOMENCLATURE_REQUEST_SENT,
    GET_NOMENCLATURE_SUCCESS,
    LOG_SELECTED_USER_SYMPTOMS_ERROR,
    LOG_SELECTED_USER_SYMPTOMS_REQUEST_SENT,
    LOG_SELECTED_USER_SYMPTOMS_SUCCESS,
    LOGOUT,
    NO_MORE_SYMPTOMS,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    RESET_SELECTED_USER_SYMPTOMPS_SUCCESS,
    SELECT_USER
} from "../actions/actions";
import {requestStatus} from './requests_reducers';

const initialState = {data: {}, request: requestStatus(undefined, {})}

/**
 * Nomenclature reducer. Reduce state based on action type.
 *
 * @param {object} state the state of nomenclature
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
export function nomenclature(state = initialState, action) {
    switch (action.type) {
        case GET_NOMENCLATURE_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})};
        }
        case GET_NOMENCLATURE_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})};
        }
        case GET_NOMENCLATURE_ERROR:
            return {
                ...state, ...initialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            };
        case LOGOUT: {
            return {...state, ...initialState};
        }
        default: {
            return state
        }
    }
}

/**
 * Symptoms log reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the symptoms logs
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const symptomsInitialState = {entries: [], isLastPage: false, request: requestStatus(undefined, {})}

export function symptoms(state = symptomsInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case FETCH_SELECTED_USER_SYMPTOMS_SUCCESS: {

            return {
                ...state,
                entries: [...state.entries, ...action.result],
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case RESET_SELECTED_USER_SYMPTOMPS_SUCCESS: {
            return {...state, entries: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_SELECTED_USER_SYMPTOMS_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case NO_MORE_SYMPTOMS: {
            return {...state, isLastPage: true}
        }
        case SELECT_USER:
        case LOGOUT: {
            return {...state, ...symptomsInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Symptoms log reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the symptoms logs
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const logSymptomsInitialState = {request: requestStatus(undefined, {})}

export function logSymptoms(state = logSymptomsInitialState, action) {
    switch (action.type) {
        case LOG_SELECTED_USER_SYMPTOMS_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case LOG_SELECTED_USER_SYMPTOMS_SUCCESS: {

            return {...state, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case LOG_SELECTED_USER_SYMPTOMS_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, ...rest} = state;
            return {...rest, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case CLEAR_SYMPTOMS_LOG:
        case SELECT_USER:
        case LOGOUT: {
            return {...state, ...logSymptomsInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Reducer for the latest used symptoms types for a given user
 */
const getLatestSymptomsInitialState = {entries: [], request: requestStatus(undefined, {})}

export function latestSymptomsTypes(state = getLatestSymptomsInitialState, action) {
    switch (action.type) {
        case FETCH_LATEST_SYMPTOMS_TYPES_SUCCESS: {

            return {...state, entries: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_LATEST_SYMPTOMS_TYPES_ERROR: {
            return {
                ...state,
                entries: getLatestSymptomsInitialState.entries,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case SELECT_USER:
        case LOGOUT: {
            return {...state, ...getLatestSymptomsInitialState};
        }
        default: {
            return state;
        }
    }
}
import {fetchHelper} from '../helpers/request_helper';
import {
    APPROVE_PERMISSION_URL,
    DENY_PERMISSION_URL,
    GET_ALL_AUTHORIZING_PERMISSIONS_URL,
    GET_ALL_PERMISSIONS_URL,
    SHARE_DATA_URL,
    USER_ACCESS_LINK_URL
} from '../constants/api_paths';

export const permissionsService = {
    getPermissions,
    approvePermission,
    denyPermission,
    shareData,
    getAuthorizingPermissions,
    createTemporaryAccessLink
};

/**
 * Get list of permission
 *
 * @returns {object} promise object
 */
export function getPermissions() {
    return fetchHelper.callGet(GET_ALL_PERMISSIONS_URL);
}

/**
 * Get list of permissions for the user.
 *
 * @returns {object} promise object
 */
export function getAuthorizingPermissions() {
    return fetchHelper.callGet(GET_ALL_AUTHORIZING_PERMISSIONS_URL);
}

/**
 * Approve the given permission
 *
 * @param {string} permissionId id of the permission to approve
 * @returns {object} promise object
 */
export function approvePermission(permissionId) {
    return fetchHelper.callPost(APPROVE_PERMISSION_URL.replace('{permissionId}', permissionId));
}

/**
 * Deny the given permission
 *
 * @param {string} permissionId id of the permission to cancel
 * @returns {object} promise object
 */
export function denyPermission(permissionId) {
    return fetchHelper.callPost(DENY_PERMISSION_URL.replace('{permissionId}', permissionId));
}

/**
 * Shares data with a given user.
 *
 * @param {string} email of the user to share data with
 * @returns {object} promise object
 */
export function shareData(email) {
    return fetchHelper.callPost(SHARE_DATA_URL.replace('{email}', email));
}


/**
 * Create temporary access link for the Medrec-M monitor which can be shared with another user.
 *
 * @param {number} duration the validity duration
 * @returns {object} promise object
 */
export function createTemporaryAccessLink(duration) {
    return fetchHelper.callPost(USER_ACCESS_LINK_URL.replace('{duration}', duration));
}
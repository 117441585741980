import React, {Component} from 'react'
import {connect} from "react-redux";
import UsersWithSearch from "./users_list_with_search/UsersWithSearch";
import {chatService} from "../../service/chat_service";
import {getAllUsers} from "../../actions/users_actions";
import PropTypes from "prop-types";
import ChatBox from "./chatBoxWithSelectedUser/ChatBox";
import MessagesHeader from "./MessagesHeader";
import phoneHand from '../../../public/media/chat/phone_hand.png'
import {$$} from "../../helpers/localization";

class MessagesPage extends Component {
    state = {
        searchValue: "",
        loadedAllUsers: false,
        UsersWithLatestMessage: [],
        userToChatWith: {},
        loading:true
    }

    constructor(props) {
        super(props);
        this._ismounted = true;
    }

    async componentDidMount() {
        window.addEventListener('resize', this.handleResize)
        this.props.getAllUsers();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
        this._ismounted = false;
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.users !== prevProps.users || this.props.chat !== prevProps.chat) {
            this.getLastMessages();
            this.setState({loadedAllUsers: false})
        }
    }


    /**
     * Get last messages for the logged user.
     */
    getLastMessages = async () => {
        await chatService.getLatestMessages().then((res) => {
            if (res && this._ismounted) {
                this.addUsersWithNoChat(res);
                this.setState({
                    UsersWithLatestMessage: res.filter(m => m.to_user_id !== this.props.loggedUser.id || m.from_user_id !== this.props.loggedUser.id), loading: false
                });
            }
        });
    }

    /**
     * Add users with no prior char history to chat list
     */
    addUsersWithNoChat = (res) => {
        let UsersWithLatestMessage = res;

        for (let i in this.props.users) {
            let HasSpokenWithUser = false
            for (let j in res) {
                if (res[j].to_user_id === this.props.users[i].id || res[j].from_user_id === this.props.users[i].id) {
                    HasSpokenWithUser = true;
                }
            }
            if (!HasSpokenWithUser) {
                let charUser = {
                    id: 1,
                    from: this.props.users[i].fullname,
                    fromEmail: this.props.users[i].email,
                    from_user_id: this.props.users[i].id,
                    to: this.props.loggedUser.fullname,
                    toEmail: this.props.loggedUser.email,
                    to_user_id: this.props.loggedUser.email,
                    date_time: 0
                };

                UsersWithLatestMessage.push(charUser);
            }
        }
        UsersWithLatestMessage = UsersWithLatestMessage.sort((a, b) => parseFloat(a.date_time) - parseFloat(b.date_time));
        UsersWithLatestMessage = UsersWithLatestMessage.reverse();

        return UsersWithLatestMessage
    }

    handleResize = () => {
        this.setState({width: window.innerWidth})
    }

    deSelectUser = () => {
        this.setState({userToChatWith: null})
    }

    updateSearchValue = (searchTerm) => {
        this.setState({searchValue: searchTerm})
    }

    selectNewUser = (selectedUser) => {
        this.setState({userToChatWith: selectedUser})
    }

    render() {
        return (
            <>
                <MessagesHeader/>
                {this.state.loading &&  <div className={"med-search-loader"} />}
                {this.state.UsersWithLatestMessage?.length === 0 && !this.state.loading &&
                    <div className={"full-width full-height recent-message-box"}>
                        <div className={"centered-text"}>
                            <img src={phoneHand} alt="phone"/>
                            <h2>{$$('no_messages_to_show')}</h2>
                            <p className="medrec-blue-1">{$$('messages_will_appear_here')}</p>
                        </div>
                    </div>}
                {this.state.UsersWithLatestMessage?.length > 0 && !this.state.loading && <div className={"chat-card-box"}>
                    <div className="chat-card">
                        <UsersWithSearch
                            UsersWithLatestMessage={this.state.UsersWithLatestMessage}
                            selectNewUser={this.selectNewUser}
                            searchValue={this.state.searchValue}
                            updateSearchValue={this.updateSearchValue}
                            smallMessages={this.state.width <= 737}
                            userToChatId={this.state.userToChatWith?.id}
                        />
                        {this.state.userToChatWith?.id && <ChatBox
                            patient={this.state.userToChatWith}
                            deSelectUser={this.deSelectUser}
                            smallMessages={this.state.width <= 737}
                        />}
                    </div>
                </div>}
            </>)
    }
}

MessagesPage.propTypes = {
    loggedUser: PropTypes.object,
    getAllUsers: PropTypes.func,
    users: PropTypes.array,
    chat: PropTypes.object,
};

const mapDispatchToProps = {
    getAllUsers
}

const mapStateToProps = (state) => ({
    i18n: state.language.selected,
    users: state.users.list,
    selectedUser: state.selectedUser.data,
    loggedUser: state.userInfo.data,
    chat: state.chat
})

export default connect(mapStateToProps, mapDispatchToProps)(MessagesPage)

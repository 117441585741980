import React from 'react'
import Navbar from './NavBar'
import PropTypes from "prop-types";

export const DefaultContainer = (props) => {
    return (
        <>
            <Navbar/>
            {props.children}
        </>
    )
}


DefaultContainer.propTypes = {
    children:  PropTypes.any
}
import React from 'react'
import {$$} from '../../helpers/localization'
import {URL_UTILS} from '../../utils/urlUtils'
import classnames from 'classnames'
import PropTypes from "prop-types";
import {dateTimeUtils} from "../../utils/dateTimeUtils";

export default function PermissionItem(props) {

    const isUrl = URL_UTILS.isUrl(props.permission.authorizedUserFullName);

    const userDataClasses = classnames("col-xs-10 col-md-10", {
        "first-li-child": isUrl && props.permission.isFirst
    });
    const denyBtnClasses = classnames("btn btn-primary sharing-btn", {
        "deny-btn": isUrl
    });

    const handleFocus = (evt) => evt.target.select();

    return (
        <li className="list-group-item" style={{"border": "none"}}>
            <div className="row">
                <div className={userDataClasses}>
                    <div
                        style={{"marginTop": props.permission.authorizedUserEmail && props.permission.authorizedUserEmail.includes('@') ? "" : "10px"}}>
                        {isUrl &&
                        <input type="text"
                               value={props.permission.authorizedUserFullName}
                               name="authorizedUserFullName"
                               onClick={handleFocus}
                               className="form-control  form-control-sm full-width"
                               readOnly/>
                        }
                        {!isUrl && <strong>{props.permission.authorizedUserFullName}</strong>}
                    </div>
                    <div style={{"marginTop": "10px"}}>
                        {props.permission.authorizedUserEmail && props.permission.authorizedUserEmail.includes('@') ? props.permission.authorizedUserEmail : ''}
                    </div>
                    {isUrl &&
                    <div>
                        <b className="ml-2">{$$('valid_to_label')}&nbsp;{dateTimeUtils.getFormattedDate( new Date(props.permission.validTo).toISOString())}</b>
                    </div>
                    }
                </div>
                <div className="col-xs-1 col-md-1">
                    {!props.permission.userApproved ?
                        (
                            <button className="btn btn-primary sharing-btn approve-btn"
                                    onClick={() => props.onApprove(props.permission.id)}>
                                {$$('approve_permission_label')}
                            </button>
                        ) : ''}
                </div>
                <div className="col-xs-1 col-md-1">
                    <button className={denyBtnClasses} onClick={() => props.onDeny(props.permission.id)}>
                        {props.cancelBtnLabel ? props.cancelBtnLabel : $$('deny_permission_label')}
                    </button>
                </div>
            </div>
        </li>
    )
}

PermissionItem.propTypes = {
    onApprove: PropTypes.func,
    cancelBtnLabel: PropTypes.any,
    onDeny: PropTypes.func,
    permission: PropTypes.object
};

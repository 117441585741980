import React, {Component} from 'react'
import PermissionItem from './PermissionItem';
import {connect} from 'react-redux';
import PropTypes from "prop-types";

export class PermissionList extends Component {

    render() {
        if (!this.props.permissions || (this.props.permissions && this.props.permissions.length === 0)) {
            return '';
        }
        const permissions = this.props.permissions.filter(p => {
            return !((p.authorizingUser === this.props.userInfo.id) && (p.authorizedUser === this.props.userInfo.id));
        }).map((p, idx) => {
            return <PermissionItem
                key={idx}
                permission={p}
                onApprove={this.props.onApprove}
                onDeny={this.props.onDeny}
                cancelBtnLabel={this.props.cancelBtnLabel}/>
        });
        return (
            <ul className="list-group full-width">
                {permissions}
            </ul>
        )
    }
}

PermissionList.propTypes = {
    dispatch: PropTypes.func,
    onApprove: PropTypes.func,
    cancelBtnLabel: PropTypes.any,
    onDeny: PropTypes.func,
    permissions: PropTypes.array,
    userInfo: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo.data
    }
}

export default connect(mapStateToProps)(PermissionList)

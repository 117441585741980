import React, {Component} from "react";
import {Tooltip} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import classNames from 'classnames'
import PropTypes from "prop-types";

export class SpeechButton extends Component {
    constructor(props, context) {
        super(props, context);
    }

    renderTooltip = props => (
        <Tooltip {...props} show={props.show.toString()}>{this.props.title}</Tooltip>
    );

    render() {
        // eslint-disable-next-line no-unused-vars
        const {recording, ...otherProps} = this.props;
        return <OverlayTrigger
            placement={"top"}
            overlay={this.renderTooltip}
        >
            <div {...otherProps}
                 className={classNames({
                     'btn btn-outline-primary btn-icon btn-icon-sm btn-circle btn-sm fas': true,
                     'fa-microphone-alt': !this.props.recording,
                     'fa-microphone-alt-slash': this.props.recording
                 })}>
                <i className='center-text'/>
            </div>
        </OverlayTrigger>
    }
}

SpeechButton.propTypes = {
    onClick: PropTypes.func,
    recording: PropTypes.bool,
    title: PropTypes.string
};


import React, {Component} from 'react'
import PropTypes from "prop-types";
import {$$} from "../../helpers/localization";
import InfoModal from "./InfoModal";

class AdditionalInfo extends Component {

    constructor(props) {
        super(props)
        this.state =
            {
                showInfoModal: false,
            }
    }

    closeInfoModal = () => {
        this.setState({
            showInfoModal: false
        })
    }

    render() {
        return (
            <>
                {(this.props.examination.description || this.props.examination.disclaimer) &&
                    <span className={"btn btn-sm btn-danger"} onClick={() => {
                        this.setState({showInfoModal: true})
                    }}>
                    ⓘ {$$('show_info_modal')}
                </span>}

                <InfoModal
                    showModal={this.state.showInfoModal}
                    closeModal={this.closeInfoModal}
                    clinician={this.props.clinician}
                    i18n={this.props.i18n}
                    examination={this.props.examination}/>
            </>
        )
    }
}

AdditionalInfo.propTypes = {
    i18n: PropTypes.object,
    clinician: PropTypes.object,
    examination: PropTypes.object,
    showModal: PropTypes.bool,
    closeModal: PropTypes.func,

}

export default AdditionalInfo;

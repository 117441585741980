import React, {Component} from 'react'
import {connect} from 'react-redux'
import {selectUser} from '../../actions/users_actions'
import AppointmentsContainer from './AppointmentsContainer';
import PropTypes from "prop-types";

class Appointments extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <AppointmentsContainer i18n={this.props.i18n} location={this.props.location}/>
        )
    }
}

Appointments.propTypes = {
    auth_data: PropTypes.object,
    history: PropTypes.object,
    i18n: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    selectUser: PropTypes.func,
    selectedUser: PropTypes.object,
    userInfo: PropTypes.object
}

function mapStateToProps(state) {
    return {
        userInfo: state.userInfo.data,
        auth_data: state.authentication.data,
        selectedUser: state.selectedUser.data,
        i18n: state.language,
    }
}

const mapDispatchToProps = {
    selectUser
}


export default connect(mapStateToProps, mapDispatchToProps)(Appointments);

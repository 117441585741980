import {fetchHelper} from '../helpers/request_helper';
import {
    GET_USER_SETTINGS_URL,
    UPDATE_USER_SETTINGS_URL
} from '../constants/api_paths';

export const settingsService = {
    getUserSettings,
    updateUserSettings
};

/**
 * Get user settings
 *
 * @param {object} user the selected user
 * @returns {object} promise object
 */
export function getUserSettings(user) {
    return fetchHelper.callGet(GET_USER_SETTINGS_URL.replace('{targetUserId}', user.id));
}

/**
 * Update user settings
 *
 * @param {object} body the new settings to save
 * @param {object} user the selected user
 * @returns {object} promise object
 */
export function updateUserSettings(body, user) {
    return fetchHelper.callPut(UPDATE_USER_SETTINGS_URL.replace('{targetUserId}', user.id), body);
}



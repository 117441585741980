import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import moment from 'moment'
import {
    KeyboardTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types";
import bg from 'date-fns/locale/bg';
import sq from 'date-fns/locale/sq';
import enGB from 'date-fns/locale/en-GB';
import {CONVERTER} from "../../utils/converter";


export class MedicationTimesPerDay extends Component {

    getLocale = () => {
        switch (this.props.i18n.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            case "sq":
                return sq
            default:
                return enGB
        }
    }


    render() {
        return (
            <span className="patient-age2 p-2" style={{'margin': '2%', 'height': 'min-content'}}>
            <div style={{
                'display': 'inline-flex',
                'alignItems': 'baseline',
                'marginTop': '4px',
                "width": "25%",
                "paddingLeft": "10px"
            }}>
                <i className='flaticon2-time medrec-grey-2'/>
                <span className="pl-1 text-nowrap">{CONVERTER.getEntryDateTimeFormat(toTimeEntity(this.props.time), false)}</span>
                <MuiPickersUtilsProvider locale={this.getLocale()}
                                         utils={DateFnsUtils}>
                    <KeyboardTimePicker
                        ampm={this.props.ampm}
                        margin="normal"
                        okLabel={$$("OK")}
                        cancelLabel={$$("cancel_btn")}
                        id="time-picker-evening"
                        value={toTimeEntity(this.props.time)}
                        onChange={(o) => this.props.onChange(toStringTime(o))}
                        KeyboardButtonProps={{'aria-label': 'change time',}}
                        required
                        className="form-control"
                        invalidDateMessage={$$('invalid_time_format')}
                        style={{"height": "0"}}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </span>
        )
    }

}


function toTimeEntity(time) {
    let date = new Date(0);
    let comp = time.split(":");
    date.setHours(parseInt(comp[0]), parseInt(comp[1]), 0, 0);
    return date;
}

function toStringTime(time) {
    return moment(time).format('HH:mm')
}

MedicationTimesPerDay.propTypes = {
    onChange: PropTypes.func,
    time: PropTypes.string,
    ampm: PropTypes.bool,
    i18n: PropTypes.object,
}

export default MedicationTimesPerDay
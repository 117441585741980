import React from 'react'

export const infoUtils = {
    noData, noDataLargerObj
}

/**
 * Returns the message to be displayed when there's no data
 *
 * @param {object} obj - the object containing all the information needed for
 * rendering the view when there's no data to display
 *
 * @returns {HTMLElement} the appropriate message when there's no data wrapped in a html div
 */
function noData(obj) {
    let nobreak = obj.nobreak || false
    return <div className={obj.objClass} style={{'textAlign': 'center'}}>
        <img className={obj.imgClass} src={obj.src}/>
        <br/> {!nobreak && <br/>}
        <span className={obj.primaryLabelClass}>{obj.primaryLabel}</span>
        <br/>
        <span className={obj.secondaryLabelClass}>{obj.secondaryLabel}</span>
    </div>
}

/**
 * Returns the message to be displayed when there's no data
 *
 * @param {object} obj - the object containing all the information needed for
 * rendering the view when there's no data to display
 *
 * @returns {HTMLElement} the appropriate message when there's no data wrapped in a html div
 */
function noDataLargerObj(obj) {
    return <div style={{'textAlign': 'center'}}>
        <img className={obj.imgClass} src={obj.src} style={{'width': '420px', 'height': '420px'}} alt={"No Data"}/>
        <br/> <br/>
        <span className={obj.primaryLabelClass} style={{'fontSize': '180%'}}>{obj.primaryLabel}</span>
        <br/>
        <span className={obj.secondaryLabelClass}>{obj.secondaryLabel}</span>
    </div>
}
import {fetchHelper} from '../helpers/request_helper';
import {
    IMPORT_GP_CAPTURE_URL,
    IMPORT_GP_URL,
    USER_GENERAL_PRACTITIONERS_CREATE_URL,
    USER_GENERAL_PRACTITIONERS_URL
} from '../constants/api_paths';

export const generalPractitionerService = {
    fetchSelectedUserGeneralPractitioner,
    createUserGeneralPractitioner,
    updateUserGeneralPractitioner,
    deleteUserGeneralPractitioner,
    getGPImportCapture,
    importGeneralPractitioner
};

/**
 * Get the General Practitioner for the selected user
 *
 * @param {string} userId id of the user to fetch the General Practitioner for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserGeneralPractitioner(userId, params) {
    return fetchHelper.callGet(USER_GENERAL_PRACTITIONERS_URL.replace('{userId}', userId), params);
}

export function createUserGeneralPractitioner(GP) {
    return fetchHelper.callPost(USER_GENERAL_PRACTITIONERS_CREATE_URL, GP);
}

export function updateUserGeneralPractitioner(GP) {
    return fetchHelper.callPut(USER_GENERAL_PRACTITIONERS_CREATE_URL, GP);
}

export function deleteUserGeneralPractitioner() {
    return fetchHelper.callDelete(USER_GENERAL_PRACTITIONERS_CREATE_URL);
}

export function getGPImportCapture() {
    return fetchHelper.callGet(IMPORT_GP_CAPTURE_URL);
}

export function importGeneralPractitioner(form) {
    return fetchHelper.callGet(IMPORT_GP_URL, form);
}

import {settingsService} from '../service/settings_service'
import {
    CHANGE_USER_SETTINGS_ERROR,
    CHANGE_USER_SETTINGS_SUCCESS,
    GET_USER_SETTINGS,
    GET_USER_SETTINGS_ERROR,
    SET_FORM_TO_DISABLED,
    SET_FORM_TO_ENABLED,
    SETTINGS
} from './actions';

/**
 * Get the user settings action, fetch the settings and dispatch action
 *
 * @returns {function} dispatch function
 */
export function getUserSettings(user) {
    return (dispatch) => {
        settingsService.getUserSettings(user).then((res) => {
            if (res) {
                dispatch({type: GET_USER_SETTINGS, result: res});
            }
        }).catch((err) => {
            dispatch({type: GET_USER_SETTINGS_ERROR, result: err});
        });
    }
}

/**
 * Update the settings action, call the service and dipatch the correct action
 *
 * @param {object} settings - the settings to save
 * @returns {function} dispatch function
 */
export function updateUserSettings(settings, user) {
    return (dispatch) => {
        dispatch({type: SET_FORM_TO_DISABLED, formName: SETTINGS});
        settingsService.updateUserSettings(settings, user).then((res) => {
            if (res) {
                dispatch({type: SET_FORM_TO_ENABLED});
                dispatch({type: CHANGE_USER_SETTINGS_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: SET_FORM_TO_ENABLED});
            dispatch({type: CHANGE_USER_SETTINGS_ERROR, result: err});
        });
    }
}

import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import Select from '../shared/Select'
import PropTypes from "prop-types";

export class HowToTake extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.scheduleTimesPerDay && this.props.howToTake === 'TAKE_X_TIMES' && this.props.times.length === 0) {

            let times = this.props.scheduleTimesPerDay;
            let XhoursTimes = {
                howToTake: "TAKE_X_TIMES",
                scheduleHours: 0,
                scheduleTimesPerDay: times,
                times: this.props.getTimesArrayXTimesADay(times),
                weekendTimes: this.props.getTimesArrayXTimesADay(times),
            }
            this.props.onChange(XhoursTimes)
        }
        if (this.props.scheduleHours && this.props.howToTake === 'TAKE_EVERY_X_HOURS' && this.props.times.length === 0) {
            let times = this.props.scheduleHours;
            let XHours = {
                howToTake: "TAKE_EVERY_X_HOURS",
                scheduleHours: times,
                scheduleTimesPerDay: 0, //this might not be necessary tbh,
                times: this.props.getTimesArrayEveryXHours(times),
                weekendTimes: this.props.getTimesArrayEveryXHours(times),
            }
            this.props.onChange(XHours)
        }


    }

    onFrequencyChange = (e) => {
        let XhoursTimes;
        if (e.value === "TAKE_EVERY_X_HOURS") {
            XhoursTimes = {
                howToTake: "TAKE_EVERY_X_HOURS",
                scheduleHours: 8,  //default value,
                scheduleTimesPerDay: 0, //clean the other input since its not in use
                times: this.props.getTimesArrayEveryXHours(8),
                weekendTimes: this.props.getTimesArrayEveryXHours(8),
            }
        } else { //this is for the other TAKE_X_TIMES
            XhoursTimes = {
                howToTake: "TAKE_X_TIMES",
                scheduleHours: 0,
                scheduleTimesPerDay: 1,
                times: this.props.getTimesArrayXTimesADay(1),
                weekendTimes: this.props.getTimesArrayXTimesADay(1),
            }
        }
        this.props.onChange(XhoursTimes)
    }


    onXTimesChange = (e) => {

        let XTimes = {
            scheduleHours: 0,
            scheduleTimesPerDay: e.target.value, //this might not be necessary tbh,
            times: this.props.getTimesArrayXTimesADay(e.target.value),
            weekendTimes: this.props.getTimesArrayXTimesADay(e.target.value),
        }
        this.props.onChange(XTimes)
    }

    onXHoursChange = (e) => {
        let XHours = {
            scheduleHours: e.value,
            scheduleTimesPerDay: 0, //this might not be necessary tbh,
            times: this.props.getTimesArrayEveryXHours(e.value),
            weekendTimes: this.props.getTimesArrayEveryXHours(e.value),
        }
        this.props.onChange(XHours)
    }

    getFrequencyString = () => {
        return [
            {text: $$("select_label"), value:"", disabled:true },
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 6, value: 6},
            {text: 8, value: 8},
            {text: 12, value: 12}]
    }

    getValidValue = () => {
        const valid = [1,2,3,4,6,8,12];
        return valid.includes(+this.props.scheduleHours) ? this.props.scheduleHours : "";
    }

    render() {
        return (
            <div>
                <div className="row form-group">
                    <div className="col-sm-6">
                        <Select
                            label={$$("frequency")}
                            name="howToTake"
                            options={this.props.getMedicationFrequencyString()}
                            value={this.props.howToTake}
                            onChange={this.onFrequencyChange}
                            placeHolder={$$('schedule')}
                            style={{
                                'minWidth': '100%',
                                'maxWidth': '100%',
                                'zIndex': '1',
                                'opacity': '70%'
                            }}/>
                    </div>
                    {this.props.howToTake && this.props.howToTake === 'TAKE_X_TIMES' &&
                    <div className="col-sm-6">
                        <div>
                            <label>{$$("number_times_per_day")}</label>
                            <input type="number" required min={1} className="form-control" value={this.props.scheduleTimesPerDay}
                                   placeholder={$$('frequency_times_day')} name="scheduleTimesPerDay"
                                   onChange={this.onXTimesChange}/>
                        </div>
                    </div>}
                    {this.props.howToTake && this.props.howToTake === 'TAKE_EVERY_X_HOURS' &&
                    <div className="col-sm-6">
                        <Select
                            label={$$("frequency_hours")}
                            name="scheduleHours"
                            required={true}
                            options={this.getFrequencyString()}
                            value={this.getValidValue()}
                            onChange={this.onXHoursChange}
                            placeHolder={$$('frequency_every_hours')}
                            style={{
                                'minWidth': '100%',
                                'maxWidth': '100%',
                                'zIndex': '1',
                                'opacity': '70%'
                            }}/>
                    </div>}
                </div>
            </div>
        )
    }
}

HowToTake.propTypes = {
    getTimesArrayEveryXHours: PropTypes.func,
    getMedicationFrequencyString: PropTypes.func,
    getTimesArrayXTimesADay: PropTypes.func,
    howToTake: PropTypes.string,
    onChange: PropTypes.func,
    scheduleHours: PropTypes.number,
    scheduleTimesPerDay: PropTypes.number
}

export default HowToTake
import {$$} from "../../helpers/localization";
import CenteredModal from "../shared/CenteredModal";
import React, {useCallback, useEffect, useState} from "react";
import CheckboxList from "../shared/CheckboxList";
import MedicationForm from "../medications/MedicationForm";
import {getPrescriptionInstructions, isNonMedicinalProduct} from "../../utils/medicationUtils";
import moment from "moment";
import {medicationUtils} from "../../utils/medicationsUtils";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {CONVERTER} from "../../utils/converter";
import enGB from "date-fns/locale/en-GB";
import bg from "date-fns/locale/bg";
import sq from "date-fns/locale/sq";
import {DURATION_FOR_X_DAYS} from "../../constants/medications_constants";

function prepareMedication(selectedPrescription, encounter, settings, selectedUser) {
    let instructions = getPrescriptionInstructions(selectedPrescription);
    let notesWithInstructions = selectedPrescription.notes;
    if (instructions) {
        instructions = $$("intake_instructions_note_label") + ": " + instructions;
        if (selectedPrescription) {
            notesWithInstructions = instructions + "\n" + notesWithInstructions;
        } else {
            notesWithInstructions = instructions;
        }
    }
    let form = {
        created_datetime: moment().valueOf(),
        deleted: false,
        dose: selectedPrescription.dose,
        durationInDays: selectedPrescription.durationInDays,
        durationType: selectedPrescription.durationType,
        ends: 0,
        health_issues_ids: encounter.health_issue_ids,
        howToTake: selectedPrescription.howToTake,
        inactive: false,
        name: selectedPrescription.name,
        notes: notesWithInstructions,
        schedule: {
            cycle: {
                daysInactive: selectedPrescription.scheduleDaysInactive,
                daysActive: selectedPrescription.scheduleDaysActive,
                countStartsAt: 1
            },
            weekdays: [1, 2, 3, 4, 5, 6, 7],
            times: getTimes(selectedPrescription, settings),
            weekends: [6, 7],
            weekendTimes: getTimes(selectedPrescription, settings)
        },
        scheduleDaysActive: selectedPrescription.scheduleDaysActive,
        scheduleDaysCountStartsAt: 1,
        scheduleDaysInactive: selectedPrescription.scheduleDaysInactive,
        scheduleHours: selectedPrescription.scheduleHours,
        scheduleTimesPerDay: selectedPrescription.scheduleTimesPerDay,
        scheduleType: selectedPrescription.scheduleType,
        scheduleWeekdays: 127,
        shapeIndex: selectedPrescription.shapeIndex,
        shapePrimaryColor: selectedPrescription.shapePrimaryColor,
        shapeSecondaryColor: selectedPrescription.shapeSecondaryColor,
        starts: moment().valueOf(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        unit: selectedPrescription.unit,
        updated_datetime: moment().valueOf(),
        user_id: selectedUser.id
    }
    return form;
}

export default function ImportMedications({prescriptions, openMedPage, onClose, show, i18n, settings, formDisabled, selectedUser, encounter, createMedication}) {
    const [showPicker, setShowPicker] = useState(show);
    const [showMedForm, setShowMedForm] = useState(false);
    const [selectedPrescriptions, setSelectedPrescriptions] = useState([]);
    const [filteredPrescriptions, setFilteredPrescriptions] = useState([]);
    const [selectedMedication, setSelectedMedication] = useState(null);
    const [showTimePickerForm, setShowTimePickerForm] = useState(false);


    useEffect(() => {
        if (showPicker !== show) {
            setShowPicker(show);
        }
    }, [show]);

    useEffect(() => {
        setFilteredPrescriptions(prescriptions.filter(p => !isNonMedicinalProduct(p) &&
            (!p.nhis_prescription_nrn || p.nhis_prescription_dosage_instruction_sequence === 1)));
    }, [prescriptions]);

    const isPrescriptionSelected = useCallback((prescription) => {
        for (const item of selectedPrescriptions) {
            if (item.id === prescription.id) {
                return true
            }
        }
        return false
    }, [selectedPrescriptions])

    const onCheckBoxChange = useCallback((prescription) => {
        let needToAdd = true;
        for (const item of selectedPrescriptions) {
            if (item.id === prescription.id) {
                let newSelectedPrescriptions = selectedPrescriptions.filter(function (value, index, arr) {
                    return value.id !== prescription.id;
                });

                setSelectedPrescriptions(newSelectedPrescriptions)
                needToAdd = false;
            }
        }

        if (needToAdd) {
            let newSelectedPrescriptions = [...selectedPrescriptions]
            newSelectedPrescriptions.push(prescription)
            setSelectedPrescriptions(newSelectedPrescriptions);
        }
    }, [selectedPrescriptions, setSelectedPrescriptions])

    const getPrescriptionCheckList = useCallback(() => {
        let prescriptionToDisplay = [];

        prescriptionToDisplay.push(filteredPrescriptions.map((prescription, idx) => {
            return <div key={idx}>
                <CheckboxList
                    index={idx}
                    item={prescription}
                    other_intakes={prescriptions
                        .filter(pr =>
                            !isNonMedicinalProduct(pr) &&
                            pr.nhis_prescription_nrn
                            && prescription.nhis_prescription_nrn === pr.nhis_prescription_nrn
                            && pr.nhis_prescription_medication_sequence_id === prescription.nhis_prescription_medication_sequence_id
                            && prescription.nhis_prescription_dosage_instruction_sequence !== pr.nhis_prescription_dosage_instruction_sequence)
                    }
                    onCheckBoxChange={onCheckBoxChange}
                    itemLabel={prescription.name}
                    selected={isPrescriptionSelected(prescription)}
                />
            </div>
        }));

        return prescriptionToDisplay
    }, [filteredPrescriptions, selectedPrescriptions, isPrescriptionSelected, prescriptions]);

    const onProcessSelectedPrescriptions = (selectedPrescriptions) => {
        if (selectedPrescriptions.length > 1) {
            setShowPicker(false);
            setShowTimePickerForm(true);
        } else {
            setShowPicker(false);
            let selectedPrescription = selectedPrescriptions[0];
            if (!selectedPrescription.nhis_prescription_nrn) {
                let form = prepareMedication(selectedPrescription, encounter, settings, selectedUser);
                setSelectedMedication(form);
                setShowMedForm(true);
            } else {
                setShowTimePickerForm(true);
            }
        }
    }

    if (showMedForm && selectedMedication) {
        return <MedicationForm
            i18n={i18n}
            settings={settings}
            importMedication={selectedMedication}
            formDisabled={formDisabled}
            show={showMedForm}
            selectedUser={selectedUser}
            needsUpdate={() => {
            }}
            createMedication={(form, userId) => {
                createMedication(form, userId);
                openMedPage();
            }}
            onHide={(event) => {
                setShowMedForm(false);
                setSelectedMedication(null);
                if (!event) {
                    setSelectedPrescriptions([]);
                }
                setShowPicker(!!event);
            }}
        />
    }

    if (showTimePickerForm) {
        return <TimePickerModal onCancel={() => {
            setShowTimePickerForm(false);
            setShowPicker(true);
        }} i18n={i18n} onDateTimePicked={async (date) => {
            for (const prescription of selectedPrescriptions) {
                let med = prepareMedication(prescription, encounter, settings, selectedUser);
                med.starts = date;
                if (med.durationType === DURATION_FOR_X_DAYS) {
                    med.ends = moment(med.starts).add(med.durationInDays, 'd').valueOf();
                }
                await createMedication(med, selectedUser.id);
                if (prescription.nhis_prescription_nrn) { //find others and save them too!
                    const others = [];
                    prescriptions.filter((p) => !isNonMedicinalProduct(p) && p.nhis_prescription_nrn === prescription.nhis_prescription_nrn
                        && p.nhis_prescription_medication_sequence_id === prescription.nhis_prescription_medication_sequence_id
                        && prescription.nhis_prescription_dosage_instruction_sequence !== p.nhis_prescription_dosage_instruction_sequence)
                        .forEach(p => others[p.nhis_prescription_dosage_instruction_sequence - 2] = p);
                    let prevMed = med;
                    for (const nextMedIntake of others) {
                        let newMed = prepareMedication(nextMedIntake, encounter, settings, selectedUser);
                        if (prevMed.ends > 0) {
                            newMed.starts = prevMed.ends;
                        }
                        if (med.durationType === DURATION_FOR_X_DAYS) {
                            newMed.ends = moment(newMed.starts).add(newMed.durationInDays, 'd').valueOf();
                        }
                        prevMed = newMed;
                        await createMedication(newMed, selectedUser.id);
                    }
                }
            }
            openMedPage();
        }}/>
    }

    return <CenteredModal title={$$('import')}
                          show={showPicker}
                          onHide={() => {
                              onClose();
                          }}
                          onCancel={() => {
                              setSelectedPrescriptions([]);
                              onClose();
                          }}
                          extraBtnLabel={$$('import_all')}
                          confirmBtnLabel={$$('import_selected')}
                          idBtnExtra="importAll"
                          extraBtnClick={() => {
                              setSelectedPrescriptions(filteredPrescriptions);
                              onProcessSelectedPrescriptions(filteredPrescriptions);
                          }}
                          primary={!selectedPrescriptions?.length > 0}
                          onConfirm={()=>onProcessSelectedPrescriptions(selectedPrescriptions)}>
        {getPrescriptionCheckList()}
    </CenteredModal>
}


const getTimes = (prescription, settings) => {
    if (prescription.howToTake === 'TAKE_X_TIMES') {
        return medicationUtils.getTimesArrayXTimesADay(prescription.scheduleTimesPerDay, settings);
    } else if (prescription.howToTake === 'TAKE_EVERY_X_HOURS') {
        return medicationUtils.getTimesArrayEveryXHours(prescription.scheduleHours, settings);
    }
    return []
}

function TimePickerModal({i18n, onCancel, onDateTimePicked}) {
    const [formClass, setFormClass] = useState("");
    const [dateStarted, setDateStarted] = useState(moment().valueOf());
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async (e) => {
        setSubmitting(true)
        e.preventDefault();
        if (!e.target.checkValidity()) {
            setFormClass("was-validated");
            setSubmitting(false)
            return;
        }
        try {
            await onDateTimePicked(dateStarted);
        } finally {
            setSubmitting(false)
        }
    }

    const getLocale = () => {
        switch (i18n.selected.lang) {
            case "en":
                return enGB;
            case "bg":
                return bg;
            case "sq":
                return sq;
            default:
                return enGB;
        }
    }

    const onStartDateChange = (date) => {
        setDateStarted(moment(date).valueOf());
    }

    return <Modal show={true} backdrop={"static"}>
        <form noValidate={true} className={formClass} onSubmit={onSubmit}>
            <Modal.Body>
                <FormGroup>
                    <MuiPickersUtilsProvider locale={getLocale()} utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                            variant="inline"
                            format={CONVERTER.getDateFNSDateTimeFormat(false)}
                            margin="normal"
                            id="date-picker-inline"
                            label={$$("start_date_label")}
                            value={dateStarted}
                            onChange={onStartDateChange}
                            KeyboardButtonProps={{'aria-label': 'change date',}}
                            required
                            invalidDateMessage={$$('invalid_date_format')}
                        />
                    </MuiPickersUtilsProvider>
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"primary"} type={"submit"} disabled={submitting}>{$$("import")}</Button>
                <Button variant={"primary"} type={"button"} onClick={onCancel}>{$$("cancel_btn")}</Button>
            </Modal.Footer>
        </form>
    </Modal>
}
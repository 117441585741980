import {requestStatus} from "./requests_reducers";
import {
    ACCEPT_CHILD,
    ACCEPT_CHILD_ERROR,
    ADD_PARENT,
    ADD_PARENT_ERROR,
    CLEAR_CONNECTED_PROFILE_RESPONSE,
    CLEAR_PARENT_COUNT,
    CREATE_CHILD_ERROR,
    CREATE_CHILD_SUCCESS,
    DENY_CHILD,
    DENY_CHILD_ERROR,
    GET_CHILDREN,
    GET_PARENT_COUNT,
    GET_PARENT_LIST,
    LOGOUT
} from "../actions/actions";

/**
 * Children profiles reducer. Keep the child profiles in the store
 *
 * @param {object} state the state of the authentication
 * @param {action} action the action to execute on the state
 */
const childrenProfilesInitialState = {entries: [], request: requestStatus(undefined, {}),childParentCount:[], connectProfileResponse: null, parents: []}

export function children_profiles(state = childrenProfilesInitialState, action) {
    switch (action.type) {
        case GET_CHILDREN: {
            return {...state, entries: action.payload}
        }
        case GET_PARENT_COUNT: {
            return {...state, childParentCount: [...state.childParentCount, {"childID":action.childId , "count":action.payload.filter(f=>f.child_accepted).length},]}
        }
        case CLEAR_PARENT_COUNT:{
            return {...state, childParentCount: []}
        }
        case GET_PARENT_LIST: {
            return {...state, parents: action.payload}
        }
        case CREATE_CHILD_SUCCESS:
        case CREATE_CHILD_ERROR: {
            return {...state, response: action.response};
        }
        case DENY_CHILD:
        case DENY_CHILD_ERROR:
        {
            return {...state, connectProfileResponse: action.response};
        }
        case ACCEPT_CHILD:
        case ACCEPT_CHILD_ERROR: {
            return {...state, connectProfileResponse: action.response};
        }
        case ADD_PARENT:
        case ADD_PARENT_ERROR:
            return {...state, connectProfileResponse: action.action.status};

        case CLEAR_CONNECTED_PROFILE_RESPONSE : {
            return {...state, connectProfileResponse: null};
        }
        case LOGOUT: {
            return {...state, ...childrenProfilesInitialState};
        }
        default: {
            return state;
        }

    }
}
import {symptomsService} from '../service/symptoms_service'
import {
    CLEAR_SYMPTOMS_LOG,
    DUMMY,
    FETCH_LATEST_SYMPTOMS_TYPES_ERROR,
    FETCH_LATEST_SYMPTOMS_TYPES_SUCCESS,
    FETCH_SELECTED_USER_SYMPTOMS_ERROR,
    FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT,
    FETCH_SELECTED_USER_SYMPTOMS_SUCCESS,
    GET_NOMENCLATURE_ERROR,
    GET_NOMENCLATURE_REQUEST_SENT,
    GET_NOMENCLATURE_SUCCESS,
    LOG_SELECTED_USER_SYMPTOMS_ERROR,
    LOG_SELECTED_USER_SYMPTOMS_REQUEST_SENT,
    LOG_SELECTED_USER_SYMPTOMS_SUCCESS,
    NO_MORE_SYMPTOMS,
    RESET_SELECTED_USER_SYMPTOMPS_SUCCESS,
    SET_FORM_TO_DISABLED,
    SET_FORM_TO_ENABLED,
    SYMPTOMS
} from './actions';
import moment from 'moment'

/**
 * Get nomencalture action.
 *
 * @returns {function} dispatch function
 */
export function getNomencalture() {
    return (dispatch) => {
        dispatch({type: GET_NOMENCLATURE_REQUEST_SENT});
        symptomsService.getNomencalture().then((res) => {
            if (res) {
                dispatch({type: GET_NOMENCLATURE_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: GET_NOMENCLATURE_ERROR, result: err});
        });
    }
}

/**
 * Fetch symptoms logs for a given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchSelectedUserSymptomsLog(userId, params, resetList, afterChange) {

    return (dispatch, getState) => {
        if (!params) {
            params = {
                before_date_time: moment().valueOf(),
            };
        }
        params.size = 20;
        dispatch({type: FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT});
        return symptomsService.fetchSelectedUserSymptoms(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res && (res.length > 0 || afterChange)) {
                if (resetList) {
                    dispatch({type: RESET_SELECTED_USER_SYMPTOMPS_SUCCESS, result: res});
                } else {
                    dispatch({type: FETCH_SELECTED_USER_SYMPTOMS_SUCCESS, result: res})
                }
            }
            if (res && (res.length < params.size)) {
                dispatch({type: NO_MORE_SYMPTOMS})
            }
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_USER_SYMPTOMS_ERROR, result: err});
        })
    }
}

export function clearSymptomsLog() {
    return (dispatch) => {
        dispatch({type: CLEAR_SYMPTOMS_LOG});
    }
}

/**
 * Log symptoms for a given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {Array} body - array of recently created symptoms
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function logSelectedUserSymptoms(userId, body, params) {

    return (dispatch, getState) => {
        if (!params) {
            params = {
                modified_after: 0,
            };
        }
        dispatch({type: SET_FORM_TO_DISABLED, formName: SYMPTOMS});
        dispatch({type: LOG_SELECTED_USER_SYMPTOMS_REQUEST_SENT});
        return symptomsService.logSelectedUserSymptoms(userId, body, params).then((res) => {

            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }

            dispatch({type: SET_FORM_TO_ENABLED});
            dispatch({type: LOG_SELECTED_USER_SYMPTOMS_SUCCESS, result: res});
            dispatch(fetchSelectedUserSymptomsLog(userId, null, true, true));

        }).catch((err) => {
            dispatch({type: SET_FORM_TO_ENABLED});
            dispatch({type: LOG_SELECTED_USER_SYMPTOMS_ERROR, result: err});
        });
    }
}

/**
 * It will return the latest used symptoms types for a given user
 * @param {*} userId - id of the user
 * @param {*} params  - size as a required parameter
 * @returns
 */
export function getLatestSymptomsTypes(userId, params) {
    return (dispatch, getState) => {
        return symptomsService.getLatestSymptomsTypes(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_LATEST_SYMPTOMS_TYPES_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: FETCH_LATEST_SYMPTOMS_TYPES_ERROR, result: err});
        })
    }
}
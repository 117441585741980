import React, {Component} from 'react'
import FormBox from './FormBox';
import {getSymptomName} from '../../utils/symptomUtils';
import {$$} from '../../helpers/localization';
import PropTypes from "prop-types";


export class SymptomStrengthPairs extends Component {
    state = {
        resetSelection: false
    };

    constructor(props) {
        super(props);
    }

    /**
     * A handler that is triggered by the child component when a symptom is selected and
     * takes appropriate action
     *
     * @param {object} symptom - the selected symptom
     */
    onChange = (symptom) => {
        if (symptom.type === 'NO_SYMPTOMS') {
            this.setState({resetSelection: true}, function () {
                this.props.onChange(symptom);
            });
        } else {
            this.setState({resetSelection: false}, function () {
                this.props.onChange(symptom);
            });
        }
    }


    /**
     * Prepares a CheckboxRadioPair for each symptom in order to display them as a list where we can select symptoms
     *
     * @returns {Array} - array of CheckboxRadioPair containing the checkbox, label (symptom name) and a set of three radio buttons
     */
    showCheckboxRadioPairs = () => {
        let symptomsToDisplay = [];
        let symptomsExist = false;
        this.props.symptoms && this.props.symptoms.map(s => {
            if (s.selected == true) {
                symptomsExist = true;
            }
        })
        //this is the case when there is only one symptom (NO_SYMPTOMS) logged
        !(this.props.symptoms && this.props.symptoms[0] && this.props.symptoms[0].type == 'NO_SYMPTOMS') && symptomsToDisplay.push(
            <div key='no_symptoms'>
                <FormBox
                    key='NO_SYMPTOMS'
                    i18n={this.props.i18n}
                    index="no_symptoms"
                    type={'NO_SYMPTOMS'}
                    selected={!symptomsExist}
                    description={$$('no_symptoms_label')}
                    strength={1}
                    onChange={this.onChange}
                />
            </div>)
        symptomsToDisplay.push(this.props.symptoms.map((symptom, idx) => {
            let symptomName = getSymptomName(symptom, this.props.nomenclature, this.props.i18n.lang);
            return <div key={idx}>
                <FormBox
                    key={symptom.type}
                    index={idx}
                    description={symptomName}
                    i18n={this.props.i18n}
                    shouldResetSelection={symptom.shouldResetSelection}
                    type={symptom.type}
                    selected={symptom.selected}
                    strength={symptom.strength}
                    resetSelection={this.state.resetSelection}
                    onChange={this.onChange}
                />
            </div>
        }));


        return symptomsToDisplay;
    }

    render() {
        return (
            <div className={"symptom-strength-pairs"}>
                {this.showCheckboxRadioPairs()}
            </div>
        )
    }
}

SymptomStrengthPairs.propTypes = {
    i18n: PropTypes.object,
    nomenclature: PropTypes.object,
    onChange: PropTypes.func,
    selectedSymptoms: PropTypes.array,
    symptoms: PropTypes.array,
};


export default SymptomStrengthPairs

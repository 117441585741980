import React, {Component} from 'react'
import {connect} from "react-redux";
import {$$} from "../../../helpers/localization";
import Search from "../../shared/Search";
import PropTypes from "prop-types";
import UsersList from "./UsersList";
import UserFilterOptions from "./UserFilterOptions";

class UsersWithSearch extends Component {

    state = {
        filteredUsers: this.props.UsersWithLatestMessage,
        defaultSort: true,
        onlyNewMessages: false
    }

    constructor(props) {
        super(props);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.filteredUsers !== prevState.filteredUsers && this.props.searchValue !== prevProps.searchValue) {
            this.handleFilters()
        }
    }

    /**
     * Filter chat users.
     */
    filterUsers = (searchTerm) => {
        if (searchTerm && searchTerm !== "") {
            this.setState({
                filteredUsers: this.props.UsersWithLatestMessage.filter((m) => {
                    return m.from.toLowerCase().includes(searchTerm.toLowerCase().trim()) ||
                        m.to.toLowerCase().includes(searchTerm.toLowerCase().trim()) ||
                        m.fromEmail.toLowerCase().includes(searchTerm.toLowerCase().trim()) ||
                        m.toEmail.toLowerCase().includes(searchTerm.toLowerCase().trim())
                })
            });
        } else {
            this.setState({
                filteredUsers: this.props.UsersWithLatestMessage
            })
        }
        this.props.updateSearchValue(searchTerm)
    }

    handleFilters = () => {

        if (this.state.defaultSort) {
            this.sortByMostRecent()
        } else {
            this.sortByName()
        }

        if (this.state.onlyNewMessages) {
            let filteredUsers = this.onlyUnread();

            this.setState({filteredUsers: []}, () => {
                this.setState({filteredUsers: filteredUsers})
            })
        }
    }


    handleSearchChange = (searchTerm) => {
        this.filterUsers(searchTerm)
    }


    sortByMostRecent = () => {
        let filteredByNewest = this.state.filteredUsers.sort((a, b) => parseFloat(a.date_time) - parseFloat(b.date_time));
        filteredByNewest = filteredByNewest.reverse();

        this.setState({filteredUsers: [], defaultSort: true}, () => {
            this.setState({filteredUsers: filteredByNewest})
        })
    }

    sortByName = () => {
        let filteredByName = this.state.filteredUsers

        for (let i in filteredByName) {
            let name = filteredByName[i].from
            if (filteredByName[i].from === this.props.loggedUser.fullname) {
                name = filteredByName[i].to
            }
            filteredByName[i].name = name
        }

        filteredByName = filteredByName.sort(function (a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        })

        this.setState({filteredUsers: [], defaultSort: false}, () => {
            this.setState({filteredUsers: filteredByName})
        })
    }

    onlyUnread = () => {
        let filteredUsers = this.state.filteredUsers

        let filteredByUnread = []

        for (let i in filteredUsers) {
            for (let j in this.props.chat.unreadMessages?.list) {
                if (filteredUsers[i].from_user_id === this.props.chat.unreadMessages?.list[j].sender && this.props.chat.unreadMessages?.list[j].sender !== this.props.loggedUser.id) {
                    filteredByUnread.push(filteredUsers[i])
                }
            }
        }
        filteredUsers = filteredByUnread

        return filteredUsers
    }

    onlyNewClicked = () => {
        if (!this.state.onlyNewMessages) {
            let filteredUsers = this.onlyUnread();

            this.setState({filteredUsers: [], onlyNewMessages: !this.state.onlyNewMessages}, () => {
                this.setState({filteredUsers: filteredUsers})
            })
        } else {
            this.filterUsers(this.props.searchValue)
            this.setState({onlyNewMessages: !this.state.onlyNewMessages})
        }
    }

    updateUserListOnNewMessage = (LoggedInUserId, newMessage) => {
        let userList = this.state.filteredUsers;
        for (let i in userList) {
            if (((newMessage.from_user_id === userList[i].from_user_id || newMessage.from_user_id === userList[i].to_user_id) &&
                (newMessage.to_user_id === userList[i].from_user_id || newMessage.to_user_id === userList[i].to_user_id))) {
                userList[i] = newMessage
            }
        }

        this.setState({filteredUsers: userList})
    }

    render() {
        return (<>
            {(this.props.smallMessages && !this.props.userToChatId || !this.props.smallMessages) &&
                <div className="chat-card-left">
                    <div style={{"maxWidth": "99%"}}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "baseline"
                        }}>
                            <div style={{width: "80%"}}>
                                <Search handleSearchChange={this.handleSearchChange}
                                        searchValue={this.props.searchValue}
                                        placeholder={$$('search_in_messages_label')}/>
                            </div>
                            <div style={{width: "20%"}}>
                                <UserFilterOptions
                                    defaultSort={this.state.defaultSort}
                                    onlyNewMessages={this.state.onlyNewMessages}
                                    sortByName={this.sortByName}
                                    onlyNewClicked={this.onlyNewClicked}
                                    sortByMostRecent={this.sortByMostRecent}
                                />
                            </div>
                        </div>
                        <UsersList
                            usersList={this.state.filteredUsers}
                            smallMessages={this.props.smallMessages}
                            selectNewUser={this.props.selectNewUser}
                            onlyNewMessages={this.state.onlyNewMessages}
                            updateUserListOnNewMessage={this.updateUserListOnNewMessage}
                            userToChatId={this.props.userToChatId}
                        />
                    </div>
                </div>} </>)
    }
}

UsersWithSearch.propTypes = {
    UsersWithLatestMessage: PropTypes.array,
    loggedUser: PropTypes.object,
    chat: PropTypes.object,
    smallMessages: PropTypes.bool,
    userToChatId: PropTypes.string,
    searchValue: PropTypes.string,
    updateSearchValue: PropTypes.func,
    selectNewUser: PropTypes.func
};

const mapStateToProps = (state) => ({
    i18n: state.language.selected,
    loggedUser: state.userInfo.data,
    chat: state.chat
})

export default connect(mapStateToProps, null)(UsersWithSearch)

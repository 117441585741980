import React, {Component} from 'react'
import {Routes} from '../../constants/routes'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {logout} from '../../actions/auth_actions'
import {changeLanguage} from '../../actions/language_actions'
import {$$} from '../../helpers/localization'
import NavLinks from './NavLinks'
import {authHelper} from '../../helpers/auth_helper'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUserCog} from '@fortawesome/free-solid-svg-icons'
import PropTypes from "prop-types";

class NavBar extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let logoutBtn = '';
        if (authHelper.isLoggedIn()) {
            logoutBtn =
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
                       aria-haspopup="true" aria-expanded="false">
                        <FontAwesomeIcon icon={faUserCog}/>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <Link className="nav-link" to={Routes.SETTINGS}>{$$('settings_label')}</Link>
                        <Link className="nav-link" to={Routes.LOGIN}
                              onClick={() => this.props.logout()}>{$$('logout_label')}</Link>
                    </div>
                </li>
        }
        return (
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <NavLinks isAuthenticated={authHelper.isLoggedIn()}/>
                <div className="my-2 my-lg-0 full-width">
                    <ul className="navbar-nav mr-auto float-right">
                        {logoutBtn}
                    </ul>
                </div>
            </div>
        )
    }
}


NavBar.propTypes = {
    logout:  PropTypes.func
}

function mapStateToProps(state) {
    return {
        auth_data: state.authentication.data,
        i18n: state.language
    }
}

export default connect(mapStateToProps, {logout, changeLanguage})(NavBar);

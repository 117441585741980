import {
    ACCESS_LINK_REQUEST_ERROR,
    ACCESS_LINK_REQUEST_SENT,
    AUTHORIZING_PERMISSIONS_REQUEST_ERROR,
    AUTHORIZING_PERMISSIONS_REQUEST_SENT,
    AUTHORIZING_PERMISSIONS_REQUEST_SUCCESS,
    PERMISSION_APPROVE_REQUEST_ERROR,
    PERMISSION_APPROVE_REQUEST_SUCCESS,
    PERMISSION_DENY_REQUEST_ERROR,
    PERMISSION_DENY_REQUEST_SUCCESS,
    SHARE_DATA_ERROR,
    SHARE_DATA_SUCCESS
} from './actions';
import {permissionsService} from '../service/permissions_service';

/**
 * Get list of permissions for the user.
 *
 * @returns {function} dispatch function
 */
export function getAuthorizingPermissions() {
    return (dispatch) => {
        dispatch({type: AUTHORIZING_PERMISSIONS_REQUEST_SENT});
        permissionsService.getAuthorizingPermissions().then((res) => {
            if (res) {
                dispatch({type: AUTHORIZING_PERMISSIONS_REQUEST_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: AUTHORIZING_PERMISSIONS_REQUEST_ERROR, result: err});
        });
    }
}

/**
 * Approve pending permission. Select the approved user after refreshing the list.
 *
 * @param {number} permissionId - id of the pending permission
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function approvePermission(permissionId, params) {
    return (dispatch) => {
        return permissionsService.approvePermission(permissionId, params).then(() => {
            dispatch({type: PERMISSION_APPROVE_REQUEST_SUCCESS});
            dispatch(getAuthorizingPermissions());
        }).catch((err) => {
            dispatch({type: PERMISSION_APPROVE_REQUEST_ERROR, result: err});
        })
    }
}

/**
 * Deny the permission for an user. Deselect the user if it is the selected one.
 *
 * @param {number} permissionId - id of the permission to deny
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function denyPermission(permissionId, params) {
    return (dispatch) => {
        return permissionsService.denyPermission(permissionId, params).then(() => {
            dispatch({type: PERMISSION_DENY_REQUEST_SUCCESS});
            dispatch(getAuthorizingPermissions());
        }).catch((err) => {
            dispatch({type: PERMISSION_DENY_REQUEST_ERROR, result: err});
        })
    }
}

/**
 * Shares data with a given user.
 *
 * @param {string} email - email of the user to share data with
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function shareData(email, params) {
    return (dispatch) => {
        return permissionsService.shareData(email, params).then((res) => {
            dispatch({type: SHARE_DATA_SUCCESS, result: res});
            dispatch(getAuthorizingPermissions());
        }).catch((err) => {
            dispatch({type: SHARE_DATA_ERROR, result: err});
        })
    }
}

/**
 * Create temporary access link for the Medrec-M monitor which can be shared with another user.
 *
 * @returns {function} dispatch function
 */
export function createTemporaryAccessLink(duration) {
    return (dispatch) => {
        dispatch({type: ACCESS_LINK_REQUEST_SENT});
        permissionsService.createTemporaryAccessLink(duration).then((res) => {
            if (res) {
                dispatch(getAuthorizingPermissions());
            }
        }).catch((err) => {
            dispatch({type: ACCESS_LINK_REQUEST_ERROR, result: err});
        });
    }
}
import {
    CLEAR_ENTRIES,
    CREATE_LOGBOOK_ENTRY_ERROR,
    CREATE_LOGBOOK_ENTRY_REQUEST_SENT,
    FETCH_SELECTED_USER_ENTRIES_ERROR,
    FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT,
    FETCH_SELECTED_USER_ENTRIES_SUCCESS,
    LOGOUT,
    NO_MORE_ENTRIES,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    RESET_SELECTED_USER_ENTRIES_SUCCESS,
    SELECT_USER,
    UPDATE_LOGBOOK_ENTRY_ERROR,
    UPDATE_LOGBOOK_ENTRY_REQUEST_SENT
} from "../actions/actions";
import {requestStatus} from './requests_reducers';

/**
 * Logbook entries reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const logBookEntriesInitialState = {entries: [], isLastPage: false, request: requestStatus(undefined, {})}

export function logBook(state = logBookEntriesInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case FETCH_SELECTED_USER_ENTRIES_SUCCESS: {
            return {
                ...state,
                entries: [...state.entries, ...action.result],
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case RESET_SELECTED_USER_ENTRIES_SUCCESS: {
            return {...state, entries: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_SELECTED_USER_ENTRIES_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case CREATE_LOGBOOK_ENTRY_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT}), type: "CREATE"}
        }
        case CREATE_LOGBOOK_ENTRY_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, isLastPage, ...rest} = state;
            return {
                ...rest,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result}),
                type: "CREATE"
            }
        }
        case UPDATE_LOGBOOK_ENTRY_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT}), type: "UPDATE"}
        }
        case UPDATE_LOGBOOK_ENTRY_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, isLastPage, ...rest} = state;
            return {
                ...rest,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result}),
                type: "UPDATE"
            }
        }
        case NO_MORE_ENTRIES: {
            return {...state, isLastPage: true}
        }
        case CLEAR_ENTRIES:
        case SELECT_USER:
        case LOGOUT: {
            return {...state, ...logBookEntriesInitialState};
        }
        default: {
            return state;
        }
    }
}
import React, {Component} from 'react'
import {$$} from '../../../helpers/localization'
import {getResolvedOptions, MEASURING_SITE_TYPES, POSITION_TYPES,} from '../../../constants/select_options'
import Select from '../../shared/Select'
import PropTypes from "prop-types";

export default class BloodPressureForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4"
                         style={{"margin": 0}}>
                        <div
                            style={{height: '1.6rem'}}>{this.props.systolic ? $$('systolic_label') : null}</div>
                        <input type="number" step="0.01"
                               className={this.props.errors.bloodPressure ? "custom-error form-control" : "form-control"}
                               value={this.props.systolic} placeholder={$$('systolic_label')}
                               name="systolic" onChange={this.props.onInputChange}/>
                    </div>
                    <div className="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4"
                         style={{"margin": 0}}>
                        <div
                            style={{height: '1.6rem'}}>{this.props.diastolic ? $$('diastolic_label') : null}</div>
                        <input type="number" step="0.01"
                               className={this.props.errors.bloodPressure ? "custom-error form-control" : "form-control"}
                               value={this.props.diastolic} placeholder={$$('diastolic_label')}
                               name="diastolic" onChange={this.props.onInputChange}/>
                    </div>
                    <div className="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4"
                         style={{"margin": 0}}>
                        <div
                            style={{height: '1.6rem'}}>{this.props.pulse ? $$('pulse_label') : null}</div>
                        <input type="number" step="0.01"
                               className={this.props.errors.bloodPressure ? "custom-error form-control" : "form-control"}
                               value={this.props.pulse} placeholder={$$('pulse_label')} name="pulse"
                               onChange={this.props.onInputChange}/>
                    </div>
                    <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12"
                         style={{"margin": 0}}>
                        <div
                            className={this.props.errors.bloodPressure ? "custom-invalid-feedback" : "invalid-feedback"}>
                            {this.props.errors.bloodPressure ? $$(this.props.errors.bloodPressure) : $$('blood_presure_required_message')}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4"
                         style={{"margin": 0, "display": "flex", "alignItems": "center"}}>
                        {$$('position_label')}
                    </div>
                    <div className="form-group col-xs-8 col-sm-8 col-md-8 col-lg-8"
                         style={{"margin": 0}}>
                        <Select
                            name="measuring_position"
                            options={getResolvedOptions(POSITION_TYPES.TYPE)}
                            value={this.props.measuring_position}
                            onChange={this.props.onInputChange}
                            placeHolder={$$('position_label')}
                            style={{
                                'minWidth': '100%',
                                'minHeight': '47px',
                                'maxWidth': '100%',
                                'zIndex': '1',
                                'opacity': '70%'
                            }}/>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4"
                         style={{"margin": 0, "display": "flex", "alignItems": "center"}}>
                        {$$('measuring_site_label')}
                    </div>
                    <div className="form-group col-xs-8 col-sm-8 col-md-8 col-lg-8"
                         style={{"margin": 0}}>
                        <Select
                            name="measuring_site"
                            options={getResolvedOptions(MEASURING_SITE_TYPES.TYPE)}
                            value={this.props.measuring_site}
                            onChange={this.props.onInputChange}
                            placeHolder={$$('measuring_site_label')} style={{
                            'minWidth': '100%',
                            'minHeight': '47px',
                            'maxWidth': '100%',
                            'zIndex': '1',
                            'opacity': '70%'
                        }}/>
                    </div>
                </div>

                <div className="checkbox-container d-flex">
                    <input onChange={this.props.toggleCheckboxChange} name="arrhythmia" checked={!!this.props.arrhythmia} id="arrhythmia"
                           className="symptom-checkbox" type="checkbox"/>
                    <label className="checkbox-label" htmlFor="arrhythmia">
                        <i className="fa fa-check wht" aria-hidden="true"/>
                    </label>
                    <label htmlFor="arrhythmia" className="peak-flow-checkbox-label">
                        {$$("arrhythmia_detected_label")}
                    </label>
                </div>
            </div>
        )
    }
}

BloodPressureForm.propTypes = {
    clearSelectedEntry: PropTypes.func,
    createEntry: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    formDisabled: PropTypes.object,
    getHealthIssueList: PropTypes.func,
    healthIssues: PropTypes.object,
    logbookEntries: PropTypes.array,
    medicalProfile: PropTypes.object,
    onHide: PropTypes.func,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    show: PropTypes.bool,
    type: PropTypes.string,
    updateEntry: PropTypes.func,
    arrhythmia: PropTypes.any,
    toggleCheckboxChange: PropTypes.any,
    measuring_site: PropTypes.any,
    pulse: PropTypes.any,
    diastolic: PropTypes.any,
    systolic: PropTypes.any,
    errors: PropTypes.any,
    measuring_position: PropTypes.any,
    onInputChange: PropTypes.func,
    updateUserMedicalProfile: PropTypes.func
}
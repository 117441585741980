import {
    CHANGE_LAB_RESULTS_FILTERS,
    CLEAR_LAB_DATES, CLEAR_LAB_RESULTS,
    CLEAR_LABS,
    CREATE_LAB_RESULTS_DOC_ERROR,
    CREATE_LAB_RESULTS_DOC_REQUEST_SENT,
    CREATE_LAB_RESULTS_DOC_SUCCESS,
    DELETE_LAB_RESULT_ERROR,
    DELETE_LAB_RESULT_REQUEST_SENT,
    DELETE_LAB_RESULT_SUCCESS,
    DUMMY,
    FETCH_LAB_DATES_ERROR,
    FETCH_LAB_DATES_REQUEST_SENT,
    FETCH_LAB_DATES_SUCCESS,
    FETCH_LAB_RESULTS_ERROR,
    FETCH_LAB_RESULTS_REQUEST_SENT,
    FETCH_LAB_RESULTS_SUCCESS,
    FETCH_LABORATORIES_ERROR,
    FETCH_LABORATORIES_REQUEST_SENT,
    FETCH_LABORATORIES_SUCCESS,
    FETCH_SELECTED_USER_LAB_RESULTS_ERROR,
    FETCH_SELECTED_USER_LAB_RESULTS_REQUEST_SENT,
    FETCH_SELECTED_USER_LAB_RESULTS_SUCCESS,
    NO_MORE_SELECTED_USER_LAB_RESULTS,
    RESET_SELECTED_USER_LAB_RESULTS_SUCCESS,
    UPDATE_LAB_RESULTS_ERROR,
    UPDATE_LAB_RESULTS_REQUEST_SENT
} from './actions';
import moment from 'moment';
import {labResultsService} from '../service/lab-results_service';
import {updateDocument} from "./documents_actions";


/**
 * Fetch all the available laboratories
 *
 * @param {object} params params sent together with the request
 * @returns {object} dispatch function
 */
export function fetchLaboratories(params) {
    return (dispatch) => {
        dispatch({type: FETCH_LABORATORIES_REQUEST_SENT});
        return labResultsService.fetchLaboratories(params).then((res) => {
            if (res && res.length > 0) {
                dispatch({type: FETCH_LABORATORIES_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_LABORATORIES_ERROR, result: err});
        })
    }
}

/**
 * Fetch the lab results of the selected user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @param {boolean} resetList - flag, if set to true reset the lab results list
 * @returns {function} dispatch function
 */
export function fetchSelectedUserLabResults(userId, params, resetList) {
    return (dispatch, getState) => {
        if (!params) {
            const beforeDateTime = getState().labResults.filters.before_date_time;
            params = {
                before_date_time: beforeDateTime ? beforeDateTime : moment().valueOf(),
                size: 500
            };
        }
        dispatch({type: FETCH_SELECTED_USER_LAB_RESULTS_REQUEST_SENT});
        return labResultsService.fetchSelectedUserLabResults(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res && res.length > 0) {
                if (resetList) {
                    dispatch({type: RESET_SELECTED_USER_LAB_RESULTS_SUCCESS, result: res});
                } else {
                    dispatch({type: FETCH_SELECTED_USER_LAB_RESULTS_SUCCESS, result: res});
                }
            } else {
                dispatch({type: NO_MORE_SELECTED_USER_LAB_RESULTS});
                if (resetList) {
                    dispatch({type: RESET_SELECTED_USER_LAB_RESULTS_SUCCESS, result: res});
                }
            }
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_USER_LAB_RESULTS_ERROR, result: err});
        })
    }
}

/**
 * Change the filters to apply to lab results requests.
 *
 * @param {object} filters - the new set of filters
 * @returns {function} dispatch function
 */
export function changeLabResultsFilters(filters) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_LAB_RESULTS_FILTERS,
            laboratory: filters.laboratory,
            beforeDateTime: filters.beforeDateTime,
            afterDateTime: filters.afterDateTime
        });
    }
}

/**
 * Fetch laboratory results dates
 * @param {object} params - parameters sent with the request
 */
export function fetchLabResultsDates(params) {
    return (dispatch) => {
        dispatch({type: FETCH_LAB_DATES_REQUEST_SENT});
        return labResultsService.fetchLabResultsDates(params).then((res) => {
            if (res && res.length > 0) {
                dispatch({type: FETCH_LAB_DATES_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_LAB_DATES_ERROR, result: err});
        })
    }
}

/**
 * Fetch laboratory results
 * @param {object} params - parameters sent with the request
 */
export function fetchLabResults(params) {
    return (dispatch) => {
        dispatch({type: FETCH_LAB_RESULTS_REQUEST_SENT});
        return labResultsService.fetchLabResults(params).then((res) => {
            if (res) {
                dispatch({type: FETCH_LAB_RESULTS_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_LAB_RESULTS_ERROR, result: err});
        })
    }
}

/**
 * Clear the get dates form action.
 *
 * @returns {function} dispatch function
 */
export function clearLabDates() {
    return (dispatch) => {
        dispatch({type: CLEAR_LAB_DATES});
    }
}

/**
 * Clear the selected date action.
 *
 * @returns {function} dispatch function
 */
export function clearLab() {
    return (dispatch) => {
        dispatch({type: CLEAR_LABS});
    }
}

export function clearUserLabResults() {
    return (dispatch) => {
        dispatch({type: CLEAR_LAB_RESULTS});
    }
}

/**
 * Update lab results with a newly saved one
 *
 * @param {object} entry The entry to be created
 */
export function updateLabResults(labResult, params, userId) {
    return (dispatch, getState) => {
        dispatch({type: UPDATE_LAB_RESULTS_REQUEST_SENT});
        return labResultsService.updateLabResults(labResult, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch(fetchSelectedUserLabResults(userId, null, true));
            return res.id
        }).catch((err) => {
            dispatch({type: UPDATE_LAB_RESULTS_ERROR, result: err});
        });
    }
}

/**
 *  Create a lab result document entry
 *
 * @param {object} entry The entry to be created
 */
export function createLabResultsDoc(entry, userId, healthIssuesIds ) {
    return (dispatch, getState) => {
        dispatch({type: CREATE_LAB_RESULTS_DOC_REQUEST_SENT});
        return labResultsService.createLabResultsDoc(entry).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
                return;
            }
            if(healthIssuesIds && healthIssuesIds.length > 0){
                res.health_issues_ids = healthIssuesIds
                const formData = new FormData();
                formData.append('document', new Blob([JSON.stringify(res)], {type: "application/json"}));
                dispatch(updateDocument(formData, userId)).then( ()=>{
                        updateReducers(res, userId)
                    }
                )
            } else {
                updateReducers(res, userId)
            }
        }).catch((err) => {
            dispatch({type: CREATE_LAB_RESULTS_DOC_ERROR, result: err});
        });
    }
}

function updateReducers (res, userId) {
    return (dispatch, getState) => {
        if (getState().selectedUser.data.id !== userId) {
            dispatch({type: DUMMY});
            return;
        }
        dispatch({type: CREATE_LAB_RESULTS_DOC_SUCCESS, result: res});
        dispatch(fetchSelectedUserLabResults(userId, null, true));
    }
}

/**
 *  Delete a laboratory result entry
 *
 * @param {number} labResultId The entry to be deleted
 */
export function deleteLabResult(labResultId, userId) {
    return (dispatch, getState) => {
        dispatch({type: DELETE_LAB_RESULT_REQUEST_SENT});
        return labResultsService.deleteLabResult(labResultId).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: DELETE_LAB_RESULT_SUCCESS, result: res});
            dispatch(fetchSelectedUserLabResults(userId, null, true));
        }).catch((err) => {
            dispatch({type: DELETE_LAB_RESULT_ERROR, result: err});
        });
    }
}

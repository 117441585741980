import React, {Component} from 'react'
import moment from 'moment';
import {Routes} from '../../constants/routes';
import history from '../../helpers/history'
import PropTypes from "prop-types";
import {CONVERTER} from "../../utils/converter";
import {appointmentsService} from "../../service/appointments_service";
import {appointmentUtils} from "../../utils/appointmentUtils";


class FreeSlotItem extends Component {
    state = {}

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <button
                onClick={
                    async () => {
                        history.push(
                            {
                                pathname: Routes.APPOINTMENT,
                                state: {
                                    selectedPrice: this.props.selectedPrice,
                                    clinician: this.props.clinician,
                                    selectedSlot: this.props.slot,
                                    userWasntLoggedIn: this.props.selectedUser.id === undefined
                                }
                            }
                        );
                    }}
                className="btn slot-item"
                disabled={this.props.slot.status === "TAKEN"}
            >
                <div className="d-flex">
                    <span>
                        <i className="far fa-clock" style={{color: "#BBCDD9"}}/>
                    </span>
                    <span>
                            {CONVERTER.getEntryDateTimeFormat(moment(this.props.slot.start), false)}
                    </span>
                </div>
            </button>
        )
    }
}

FreeSlotItem.propTypes = {
    clinician:  PropTypes.object,
    selectedPrice:  PropTypes.object,
    slot:  PropTypes.object
}

export default FreeSlotItem;

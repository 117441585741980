import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import Select from "../shared/Select";
import {countryOptions} from "../../constants/countries";
import moment from "moment";
import {
    GENDER,
    getResolvedOptions
} from '../../constants/select_options';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {dateTimeUtils} from '../../utils/dateTimeUtils'
import {CHILD} from "../../actions/actions";
import FormWithLoading from "../shared/FormWithLoading";
import PropTypes from "prop-types";
import bg from 'date-fns/locale/bg';
import sq from 'date-fns/locale/sq';
import enGB from 'date-fns/locale/en-GB';


export class CreateChildForm extends Component {
    state = {
        fullname: '',
        city: '',
        country: 'bg',
        gender: '',
        birthday: dateTimeUtils.getUtcOffset(0).valueOf(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        formClass: '',
        errors: {}
    }

    constructor(props) {
        super(props);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.childrenProfiles.response && props.childrenProfiles.response.status === 403) {
            return {
                errors: {email: 'register_form_email_exists_message'}
            }
        }
        return state;
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields);
    };

    /**
     * Set the state to the latest selected date.
     *
     * @param {object} date - The event handler argument
     */
    onDateChange = (date) => {
        const fields = Object.assign({}, this.state);
        fields["birthday"] = moment(date).valueOf();
        this.setState(fields);
    };

    /**
     * Form submit handler, validate data and set error in state if any. Call register action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();
        if (this.state.formClass !== "was-validated") {
            this.setState({formClass: "was-validated"});
        }

        if (evt.target.checkValidity() === true) {
            // eslint-disable-next-line no-unused-vars
            const {errors, formClass, ...data} = this.state;
            this.props.createChild(data);
        }
    }

    getLocale = () => {
        switch(this.props.i18n.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            case "sq":
                return sq
            default:
                return enGB
        }
    }

    render() {

        return (
            <div className="centered-form margin-top-connected">
                <h2 className="text-center card-title">{$$('child_label')}</h2>
                <div className="card-body">
                    <FormWithLoading
                        formDisabled={this.props.formDisabled}
                        currentForm={CHILD}
                        onSubmit={this.onSubmit}
                        className={this.state.formClass}
                        noValidate={true}>

                        <div className="form-group register-control">
                            <label>{$$('fullname_label')}</label>
                            <input type="text"
                                   className="form-control"
                                   value={this.state.fullname}
                                   placeholder={$$('fullname_label')}
                                   name="fullname"
                                   onChange={this.onInputChange}
                                   required
                                   style={{display: "flex", 'minHeight': '47px', 'zIndex': '1', 'opacity': '70%', width : "100%"}}/>
                            <div className="invalid-feedback">
                                {$$('register_form_full_name_required')}
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className="form-group register-control col-sm-6 col-md-6">
                                <Select
                                    name="gender"
                                    label={$$('gender_label')}
                                    options={getResolvedOptions(GENDER.OPTIONS)}
                                    value={this.state.gender}
                                    onChange={this.onSelectChange}
                                    settingsPage = {true}
                                    connectedProfile = {true}
                                    placeHolder={$$('gender_label')}
                                    style={{display: "flex", 'minHeight': '47px', 'zIndex': '1', 'opacity': '70%', width : "100%"}}/>
                            </div>

                            <div className="form-group register-control col-sm-6 col-md-6">
                                <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        variant="inline"
                                        format={this.props.settings.dateFormat}
                                        margin="normal"
                                        id="date-picker-inline"
                                        label={$$("birthday_label")}
                                        value={this.state.birthday}
                                        onChange={this.onDateChange}
                                        required
                                        KeyboardButtonProps={{'aria-label': 'change date',}}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className="form-group register-control col-sm-6 col-md-6">
                                <Select
                                    name="country"
                                    label={$$('country_label')}
                                    options={countryOptions(this.props.i18n.lang)}
                                    value={this.state.country}
                                    onChange={this.onSelectChange}
                                    settingsPage = {true}
                                    connectedProfile = {true}
                                    placeHolder={$$('country_label')}
                                    style={{display: "flex", 'minHeight': '47px', 'zIndex': '1', 'opacity': '70%'}}/>
                            </div>

                            <div className="form-group register-control col-sm-6 col-md-6">
                                <label>{$$('city_label')}</label>
                                <input type="text"
                                       className="form-control"
                                       value={this.state.city}
                                       placeholder={$$('city_label')}
                                       name="city"
                                       onChange={this.onInputChange}
                                       style={{display: "flex", 'minHeight': '47px', 'zIndex': '1', 'opacity': '70%', width : "100%"}}/>
                            </div>
                        </div>

                        <div className="form-group register-control"
                             style={{"marginTop": "20px"}}>
                            <div className="col-xs-7 col-md-7 mt-4 mx-auto">
                                <button type="submit"
                                        style={{"marginTop": "2rem"}}
                                        className="btn btn-primary btn-block">{$$('child_label')}</button>
                            </div>
                        </div>
                    </FormWithLoading>
                </div>
            </div>
        )
    }
}

CreateChildForm.propTypes = {
    childrenProfiles: PropTypes.object,
    createChild:  PropTypes.func,
    formDisabled: PropTypes.object,
    i18n: PropTypes.any,
    selectedUser:  PropTypes.object,
    settings:  PropTypes.any,
    userInfo:  PropTypes.object
}
import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import Select from '../shared/Select'
import {
    getResolvedOptions,
    SETTING_OPTIONS
} from '../../constants/select_options'
import classnames from 'classnames'
import moment from 'moment'
import {
    KeyboardTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {SETTINGS} from "../../actions/actions";
import FormWithLoading from "../shared/FormWithLoading";
import PropTypes from "prop-types";
import bg from 'date-fns/locale/bg';
import sq from 'date-fns/locale/sq';
import enGB from 'date-fns/locale/en-GB';

export class SettingsForm extends Component {
    state = {
        unitsOfMeasurement: this.props.settings.unitsOfMeasurement,
        cholesterolUnits: this.props.settings.cholesterolUnits,
        classificationMethod: this.props.settings.classificationMethod,
        dateFormat: this.props.settings.dateFormat,
        time24hour: this.props.settings.time24hour,
        glucoseUnits: this.props.settings.glucoseUnits,
        ketonesUnits: this.props.settings.ketonesUnits,
        hba1cUnits: this.props.settings.hba1cUnits,
        morningTime: this.props.settings.morningTime,
        noonTime: this.props.settings.noonTime,
        eveningTime: this.props.settings.eveningTime,
        bedTime: this.props.settings.bedTime,
        formclass: '',
        errors: {}
    };

    constructor(props) {
        super(props);
    }

    getLocale = () => {
        switch (this.props.i18n.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            case "sq":
                return sq
            default:
                return enGB
        }
    }


    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields);
    };


    /**
     * For units of measurement only
     * @param {object} evt
     */
    onSelectMeasurementUnit = (evt) => {
        this.setState({unitsOfMeasurement: evt.value})
    };


    /**
     * On checkbox checked handler, set the correct value to state
     *
     * @param {object} evt - The event handler argument
     */
    onCheckboxChanged = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.checked;
        this.setState(fields);
    };

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     * @param {string} name - name
     */
    onInputChange = (evt, name) => {
        var newTime = moment(evt, 'HH:mm:ss.sss').format('HH:mm:ss.sss');
        const fields = Object.assign({}, this.state);
        fields[name] = newTime;
        this.setState(fields);
    };

    /**
     * On label clicked handler, set the correct value to state
     *
     * @param {object} evt - The event handler argument
     */
    onLabelClick = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.htmlFor] = !fields[evt.target.htmlFor];
        this.setState(fields);
    }

    /**
     * Form submit handler, update the user settings.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        const formErrors = this.validate();
        this.setState({errors: formErrors});
        evt.preventDefault();

        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }

        if (Object.keys(formErrors).length) {
            return;
        }

        if (evt.target.checkValidity() === true) {
            this.props.updateUserSettings(this.state, this.props.selectedUser);
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            this.setState({formclass: ""});
        }
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        if (!this.state.morningTime) {
            errors.morningTime = 'morning_time_required_message';
        }
        if (!this.state.noonTime) {
            errors.noonTime = 'noon_time_required_message';
        }
        if (!this.state.eveningTime) {
            errors.eveningTime = 'evening_time_required_message';
        }
        if (!this.state.bedTime) {
            errors.bedTime = 'bed_time_required_message';
        }
        return errors;
    }

    /**
     * Reset previous settings.
     */
    resetSettings = () => {

        this.setState({
            unitsOfMeasurement: this.props.settings.unitsOfMeasurement,
            cholesterolUnits: this.props.settings.cholesterolUnits,
            classificationMethod: this.props.settings.classificationMethod,
            dateFormat: this.props.settings.dateFormat,
            time24hour: this.props.settings.time24hour,
            glucoseUnits: this.props.settings.glucoseUnits,
            ketonesUnits: this.props.settings.ketonesUnits,
            hba1cUnits: this.props.settings.hba1cUnits,
            morningTime: this.props.settings.morningTime,
            noonTime: this.props.settings.noonTime,
            eveningTime: this.props.settings.eveningTime,
            bedTime: this.props.settings.bedTime,
            formclass: '',
            errors: {}
        });
    }


    render() {

        let alert = '';
        if (this.props.showSuccessfulAlert) {
            alert = (
                <div className="alert alert-success">
                    {$$('settings_updated_message')}
                </div>
            );
        }

        return (
            <div className="centered-form settings-on-big-screen">
                <h2 className="text-center card-title">{$$("settings_label")}</h2>
                <div>
                    {alert}
                    <FormWithLoading
                        formDisabled={this.props.formDisabled}
                        currentForm={SETTINGS}
                        marginTop="30%"
                        marginLeft="20%"
                        onSubmit={(evt) => this.onSubmit(evt)}
                        className={classnames(this.state.formclass)}
                        noValidate={true}>
                        <div className="form-group settings-form">
                            <div className="settings-dropdown d-flex">
                                <Select
                                    name="issues-type"
                                    label={$$('unit_of_measurement_label')}
                                    options={getResolvedOptions(SETTING_OPTIONS.UNITS_OF_MEASUREMENTS)}
                                    value={this.state.unitsOfMeasurement}
                                    settingsPage={true}
                                    onChange={this.onSelectMeasurementUnit}
                                    placeHolder={$$('unit_of_measurement_label')}/>
                            </div>
                        </div>
                        <div className="form-group settings-form">
                            <div className="settings-dropdown d-flex">
                                <Select
                                    label={$$('cholesterol_units_label')}
                                    name="cholesterolUnits"
                                    options={getResolvedOptions(SETTING_OPTIONS.CHOLESTEROL_UNITS)}
                                    value={this.state.cholesterolUnits}
                                    settingsPage={true}
                                    onChange={this.onSelectChange}
                                    placeHolder={$$('cholesterol_units_label')}/>
                            </div>
                        </div>
                        <div className="form-group settings-form">
                            <div className="settings-dropdown  d-flex">
                                <Select
                                    label={$$('classification_method_label')}
                                    name="classificationMethod"
                                    options={SETTING_OPTIONS.CLASSIFICATION_METHODS}
                                    value={this.state.classificationMethod}
                                    settingsPage={true}
                                    onChange={this.onSelectChange}
                                    placeHolder={$$('classification_method_label')}/>
                            </div>
                        </div>
                        <div className="form-group settings-form">
                            <div className="settings-dropdown  d-flex">
                                <Select
                                    label={$$('glucose_units')}
                                    name="glucoseUnits"
                                    options={getResolvedOptions(SETTING_OPTIONS.GLUCOSE_UNITS)}
                                    value={this.state.glucoseUnits}
                                    settingsPage={true}
                                    onChange={this.onSelectChange}
                                    placeHolder={$$('glucose_units')}/>
                            </div>
                        </div>
                        <div className="form-group settings-form">
                            <div className="settings-dropdown  d-flex">
                                <Select
                                    label={$$('ketones_units')}
                                    name="ketonesUnits"
                                    options={getResolvedOptions(SETTING_OPTIONS.KETONES_UNITS)}
                                    value={this.state.ketonesUnits}
                                    settingsPage={true}
                                    onChange={this.onSelectChange}
                                    placeHolder={$$('ketones_units')}/>
                            </div>
                        </div>
                        <div className="form-group settings-form">
                            <div className="settings-dropdown  d-flex">
                                <Select
                                    label={$$('hba1c_units')}
                                    name="hba1cUnits"
                                    options={getResolvedOptions(SETTING_OPTIONS.HBA1C_UNITS)}
                                    value={this.state.hba1cUnits}
                                    settingsPage={true}
                                    onChange={this.onSelectChange}
                                    placeHolder={$$('hba1c_units')}/>
                            </div>
                        </div>
                        <div className="form-group settings-form">
                            <div className="settings-dropdown  d-flex">
                                <Select
                                    label={$$('date_format_label')}
                                    name="dateFormat"
                                    settingsPage={true}
                                    options={SETTING_OPTIONS.DATE_FORMATS}
                                    value={this.state.dateFormat}
                                    onChange={this.onSelectChange}
                                    placeHolder={$$('date_format_label')}/>
                            </div>
                        </div>
                        <div className="form-group settings-form">
                            <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    ampm={false}
                                    margin="normal"
                                    okLabel={$$("OK")}
                                    cancelLabel={$$("cancel_btn")}
                                    id="time-picker-morning"
                                    label={$$('morning_time')}
                                    value={moment(this.state.morningTime, moment.HTML5_FMT.TIME_MS).toDate()}
                                    onChange={(value) => this.onInputChange(value, "morningTime")}
                                    KeyboardButtonProps={{'aria-label': 'change time',}}
                                    required
                                    className="form-control"
                                    invalidDateMessage={$$('invalid_time_format')}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="form-group settings-form">
                            <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    ampm={false}
                                    margin="normal"
                                    okLabel={$$("OK")}
                                    cancelLabel={$$("cancel_btn")}
                                    id="time-picker-noon"
                                    label={$$('noon_time')}
                                    value={moment(this.state.noonTime, moment.HTML5_FMT.TIME_MS).toDate()}
                                    onChange={(value) => this.onInputChange(value, "noonTime")}
                                    KeyboardButtonProps={{'aria-label': 'change time',}}
                                    required
                                    className="form-control"
                                    invalidDateMessage={$$('invalid_time_format')}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="form-group settings-form">
                            <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    ampm={false}
                                    margin="normal"
                                    okLabel={$$("OK")}
                                    cancelLabel={$$("cancel_btn")}
                                    id="time-picker-evening"
                                    label={$$('evening_time')}
                                    value={moment(this.state.eveningTime, moment.HTML5_FMT.TIME_MS).toDate()}
                                    onChange={(value) => this.onInputChange(value, "eveningTime")}
                                    KeyboardButtonProps={{'aria-label': 'change time',}}
                                    required
                                    className="form-control"
                                    invalidDateMessage={$$('invalid_time_format')}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="form-group settings-form">
                            <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    ampm={false}
                                    margin="normal"
                                    okLabel={$$("OK")}
                                    cancelLabel={$$("cancel_btn")}
                                    id="time-picker-evening"
                                    label={$$('bed_time')}
                                    value={moment(this.state.bedTime, moment.HTML5_FMT.TIME_MS).toDate()}
                                    onChange={(value) => this.onInputChange(value, "bedTime")}
                                    KeyboardButtonProps={{'aria-label': 'change time',}}
                                    required
                                    className="form-control"
                                    invalidDateMessage={$$('invalid_time_format')}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="form-check mt-4">
                            <input className="form-check-input" type="checkbox" name="time24hour"
                                   checked={this.state.time24hour}
                                   onChange={this.onCheckboxChanged}
                            />
                            <label className="form-check-label" htmlFor="time24hour" onClick={this.onLabelClick}>
                                {$$('time_24hour_label')}
                            </label>
                        </div>
                        <div className="form-group settings-buttons">
                            <div className="row settings-buttons-row">
                                <button type="button" onClick={this.resetSettings}
                                        className="btn btn-secondary">{$$("reset_btn_label")}</button>
                                <button type="submit" className="btn btn-primary settings-save">
                                    {$$("save_btn_label")}
                                </button>
                            </div>
                        </div>
                    </FormWithLoading>
                </div>
            </div>
        )
    }
}

SettingsForm.propTypes = {
    formDisabled: PropTypes.object,
    i18n: PropTypes.object,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    showSuccessfulAlert: PropTypes.any,
    updateUserSettings: PropTypes.func,
};

export default SettingsForm

import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import Search from '../shared/Search';
import Select from '../shared/Select'
import PropTypes from "prop-types";


class HealthIssuesHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="form-inline" style={{'backgroundColor': 'white', "marginBottom": "2.5rem"}}>
                <div className='flex-space-between flex-wrap w-100'>
                    <h2>
                        {$$('health_issues_label')}
                    </h2>
                    <div className="" style={{'display': 'flex', 'flexWrap': 'wrap'}}>
                        <div className="" style={{'display': 'flex', 'marginRight': '2rem'}}>
                            <Select
                                name="issues-type"
                                options={this.props.healthIssueOptions}
                                value={this.props.pickedOption ? this.props.pickedOption : 0}
                                onChange={this.props.onSelectChange}
                                placeHolder={$$('health_issues_label')}
                                style={{
                                    'minWidth': '-10.188rem',
                                    'minHeight': '2.938rem',
                                    'maxWidth': '18.75rem',
                                    'zIndex': '1',
                                    'opacity': '70%'
                                }}/>
                        </div>
                        <div className='search-box2' style={{'marginRight': '2rem'}}>
                            <Search
                                filterUsers={this.props.filterIssuesByName}
                                placeholder={$$('search_placeholder_label')}
                                style={{'width': '17.5rem'}}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

HealthIssuesHeader.propTypes = {
    filterIssuesByName: PropTypes.func,
    healthIssueOptions: PropTypes.array,
    onSelectChange: PropTypes.func,
    pickedOption: PropTypes.any
}

export default HealthIssuesHeader

import React, {useEffect, useMemo, useState} from "react";
import {ListGroup} from "react-bootstrap";
import {dateTimeUtils} from "../../utils/dateTimeUtils";
import {$$} from "../../helpers/localization";


function EreferralListItem({ereferral}) {
    if (!ereferral.nrn) {
        return false;
    }

    return <div className="list-group-item">
        <EreferralBody nrn={ereferral.nrn} eReferral={ereferral.content} />
        <hr />
    </div>
}

function EreferralBody({eReferral, nrn}) {
    const component = useMemo(function getBody() {
        switch (eReferral.contents.category) {
            case "R1":
                return LabView({eReferral})
            case "R2":
                return ConsultationView({eReferral})
            case "R3":
                return SpecializedActivitiesView({eReferral})
            case "R4":
                return HospitalizationView({eReferral})
            case "R5":
                return MedicalExpertiseView({eReferral})
            case "R8":
                return WorkIncapacityView({eReferral})
            default:
                return null;
        }
    }, [eReferral])

    return <table className="table table-sm table-borderless base-font-color">
        <tbody>
        <TableRow name={$$("nhis.ereferral.nrn_number")} value={nrn || ""}/>
        <TableRow name={$$("nhis.ereferral.category")} value={eReferral.viewModel.category}/>
        <TableRow name={$$("nhis.ereferral.authoredOn")} value={localizedDate(eReferral.contents.authoredOn)}/>
        {component}
        </tbody>
    </table>
}

function LabView({eReferral}) {
    return (
        <TableRow name={$$("nhis.ereferral.activity")} value={eReferral.viewModel.laboratory}/>
    )
}

function ConsultationView({eReferral}) {
    return (
        <TableRow name={$$("nhis.ereferral.qualificationTarget")} value={eReferral.viewModel.consultation}/>
    )
}

function SpecializedActivitiesView({eReferral}) {
    return (<>
        <TableRow name={$$("nhis.ereferral.qualificationTarget")}
                  value={eReferral.viewModel.specializedActivities.qualification}/>
        <TableRow name={$$("nhis.ereferral.specializedActivityCode")}
                  value={eReferral.viewModel.specializedActivities.code}/>
    </>)
}

function HospitalizationView({eReferral}) {
    return <>
        <TableRow name={$$("nhis.ereferral.admissionType")} value={eReferral.viewModel.hospitalization.admissionType}/>
        <TableRow name={$$("nhis.ereferral.directedBy")} value={eReferral.viewModel.hospitalization.directedBy}/>
        <TableRow name={$$("nhis.ereferral.clinicalPathway")}
                  value={eReferral.viewModel.hospitalization.clinicalPathway}/>
        <TableRow name={$$("nhis.ereferral.outpatientProcedure")}
                  value={eReferral.viewModel.hospitalization.outpatientProcedure}/>
    </>
}

function MedicalExpertiseView({eReferral}) {
    return <>
        <TableRow name={$$("nhis.ereferral.qualificationTargets")}
                  value={eReferral.viewModel.medicalExpertise.qualification}/>
        <TableRow name={$$("nhis.ereferral.examType")} value={eReferral.viewModel.medicalExpertise.examType}/>
    </>
}

function WorkIncapacityView({eReferral}) {
    return <>
        <TableRow name={$$("nhis.ereferral.workIncapacityReason")} value={eReferral.viewModel.workIncapacity.reason}/>
    </>
}

function TableRow({name, value}) {
    if (!value || (Array.isArray(value) && value.length === 0)) {
        return null;
    }

    let isArray = Array.isArray(value);
    return <tr>
        <td className="medrec-grey-2 text-right medical-notice-item-label">{name}:</td>
        <td className={"whitespace-pre-line w-100 align-bottom"}>{isArray ? value.join("; ") : value}</td>
    </tr>
}

function localizedDate(date) {
    if (!date) {
        return ""
    }
    return dateTimeUtils.getFormattedDate(new Date(date));
}

export function EReferralList({encounter}) {
    const [eReferrals, setEReferrals] = useState([]);

    useEffect(() => {
        setEReferrals(encounter.ereferrals.map(e => {
            return {nrn: e.nrn, lrn: e.lrn, content: JSON.parse(e.content_json)}
        }))
    }, [encounter.ereferrals])

    console.log(eReferrals)

    return <ListGroup className={"medical-notice-list"}>
        {eReferrals.map(n => {
            return <EreferralListItem key={n.lrn} ereferral={n} />
        })}
    </ListGroup>
}
import {$$} from '../helpers/localization'

export const SETTING_OPTIONS = {
    UNITS_OF_MEASUREMENTS: [
        {
            value: "US",
            text: "US"
        },
        {
            value: "METRIC",
            text: "metric"
        }
    ],
    CHOLESTEROL_UNITS: [
        {
            value: "MG_DL",
            text: "milligram_per_decilitre"
        },
        {
            value: "MMOL_L",
            text: "millimol_per_litre"
        }
    ],
    CLASSIFICATION_METHODS: [
        {
            value: "ACC_AHA",
            text: "ACC/AHA"
        },
        {
            value: "ESC_ESH",
            text: "ESC/ESH"
        },
        {
            value: "JNC",
            text: "JNC8"
        },
        {
            value: "NICE",
            text: "NICE 2019"
        },
        {
            value: "CANADA",
            text: "Hypertension Canada"
        }
    ],
    DATE_FORMATS: [
        {
            value: "dd.MM.yyyy",
            text: "dd.MM.yyyy"
        },
        {
            value: "dd/MM/yyyy",
            text: "dd/MM/yyyy"
        },
        {
            value: "dd-MM-yyyy",
            text: "dd-MM-yyyy"
        },
        {
            value: "MM/dd/yyyy",
            text: "MM/dd/yyyy"
        },
        {
            value: "yyyy-MM-dd",
            text: "yyyy-MM-dd"
        },
        {
            value: "yyyy.MM.dd",
            text: "yyyy.MM.dd"
        },
        {
            value: "yyyy/MM/dd",
            text: "yyyy/MM/dd"
        }
    ],
    GLUCOSE_UNITS: [
        {
            value: "MG_DL",
            text: "milligram_per_decilitre"
        },
        {
            value: "MMOL_L",
            text: "millimol_per_litre"
        }
    ],
    KETONES_UNITS: [
        {
            value: "MG_DL",
            text: "milligram_per_decilitre"
        },
        {
            value: "MMOL_L",
            text: "millimol_per_litre"
        }
    ],
    HBA1C_UNITS: [
        {
            value: "PERCENT",
            text: "percent"
        },
        {
            value: "MMOL_MOL",
            text: "millimol_per_mol"
        }
    ],
}

export const CHART_FILTERS = {
    PERIOD_OPTIONS: [
        {
            value: "WEEK",
            text: "week_label"
        },
        {
            value: "FORTNIGHT",
            text: "fortnight_label"
        },
        {
            value: "MONTH",
            text: "month_label"
        },
        {
            value: "QUARTER",
            text: "quarter_label"
        },
        {
            value: "CUSTOM",
            text: "custom_label"
        }
    ]
}

export const GENDER = {
    OPTIONS: [
        {
            value: "",
            text: "select_gender"
        },
        {
            value: "MALE",
            text: "male"
        },
        {
            value: "FEMALE",
            text: "female"
        }
    ]
}

export const DOCUMENTS_FILTER = {
    TYPE: [
        {
            value: "",
            text: "all_document_types_label"
        },
        {
            value: "EPICRISIS",
            text: "document_type_epicrisis"
        },
        {
            value: "PRESCRIPTION",
            text: "document_type_prescription"
        },
        {
            value: "LAB_RESULTS",
            text: "document_type_lab_results"
        },
        {
            value: "AMBULANCE_SHEET",
            text: "document_type_ambulance_sheet"
        },
        {
            value: "REFERRAL",
            text: "document_type_referral"
        },
        {
            value: "OTHER",
            text: "document_type_other"
        },
    ]
}
export const DOCUMENTS = {
    TYPE: [
        {
            value: "DIAGNOSTIC_IMAGING",
            text: "document_type_diagnostic_imaging"
        },
        {
            value: "EPICRISIS",
            text: "document_type_epicrisis"
        },
        {
            value: "PRESCRIPTION",
            text: "document_type_prescription"
        },
        {
            value: "LAB_RESULTS",
            text: "document_type_lab_results"
        },
        {
            value: "AMBULANCE_SHEET",
            text: "document_type_ambulance_sheet"
        },
        {
            value: "REFERRAL",
            text: "document_type_referral"
        },
        {
            value: "OTHER",
            text: "document_type_other"
        },
    ]
}

export const MENSTRUATION = {
    FLOW: [
        {
            value: 0,
            text: "not_entered"
        },
        {
            value: 1,
            text: "light"
        },
        {
            value: 2,
            text: "medium"
        },
        {
            value: 3,
            text: "heavy"
        },
        {
            value: 4,
            text: "unexpected"
        }
    ],
    COLOR: [
        {
            value: 0,
            color: "",
            text: "not_entered"
        },
        {
            value: 1,
            color: "#000000",
            text: "black"
        },
        {
            value: 2,
            color: "#b33c00",
            text: "brown"
        },
        {
            value: 3,
            color: "#cc0000",
            text: "dark_red"
        },
        {
            value: 4,
            color: "#FF0000",
            text: "bright_red"
        },
        {
            value: 5,
            color: "#ff1a75",
            text: "pink"
        },
        {
            value: 6,
            color: "#FFA500",
            text: "orange"
        },
        {
            value: 7,
            color: "#808080",
            text: "gray"
        }
    ],
    CONSISTENCY: [
        {
            value: 0,
            text: "not_entered"
        },
        {
            value: 1,
            text: "watery"
        },
        {
            value: 2,
            text: "clots"
        }
    ],
}

export const LOGBOOK_TYPES = {
    TYPE: [
        {
            value: "",
            text: "entry_types"
        },
        {
            value: "TEMPERATURE",
            text: "entry_type_temperature"
        },
        {
            value: "BLOOD_PRESSURE",
            text: "entry_type_blood_pressure"
        },
        {
            value: "BLOOD_SUGAR",
            text: "entry_type_blood_sugar"
        },
        {
            value: "HBA1C",
            text: "entry_type_hba1c"
        },
        {
            value: "KETONES",
            text: "entry_type_ketones"
        },
        {
            value: "URINE_PH",
            text: "entry_type_urine_ph"
        },
        {
            value: "WEIGHT",
            text: "entry_type_weight"
        },
        {
            value: "HEIGHT",
            text: "entry_type_height"
        },
        {
            value: "CHOLESTEROL",
            text: "entry_type_cholesterol"
        },
        {
            value: "SATURATION",
            text: "entry_type_oxygen_saturation"
        },
        {
            value: "RESPIRATORY_RATE",
            text: "entry_type_respiratory_rate"
        },
        {
            value: "HYDRATION",
            text: "entry_type_hydration"
        },
        {
            value: "MENSTRUATION",
            text: "entry_type_menstruation"
        },
        {
            value: "MEDICATION",
            text: "entry_type_medication"
        },
        {
            value: "PEAK_FLOW",
            text: "entry_type_peak_flow"
        }
    ]
}

export const UNIT_TYPES = {
    TYPE: [
        {
            value: "AMPULE",
            text: "unit_type_ampule"
        },
        {
            value: "APPLICATION",
            text: "unit_type_application"
        },
        {
            value: "CAPSULE",
            text: "unit_type_capsule"
        },
        {
            value: "DROP",
            text: "unit_type_drop"
        },
        {
            value: "GRAM",
            text: "unit_type_gram"
        },
        {
            value: "INJECTION",
            text: "unit_type_injection"
        },
        {
            value: "MG",
            text: "unit_type_mg"
        },
        {
            value: "ML",
            text: "unit_type_ml"
        },
        {
            value: "PACKET",
            text: "unit_type_packet"
        },
        {
            value: "PATCH",
            text: "unit_type_patch"
        },
        {
            value: "PIECE",
            text: "unit_type_piece"
        },
        {
            value: "TABLET",
            text: "unit_type_tablet"
        },
        {
            value: "PUFF",
            text: "unit_type_puff"
        },
        {
            value: "SPRAY",
            text: "unit_type_spray"
        },
        {
            value: "SUPPOSITORY",
            text: "unit_type_suppository"
        },
        {
            value: "TBSP",
            text: "unit_type_tbsp"
        },
        {
            value: "TSP",
            text: "unit_type_tsp"
        },
        {
            value: "UNIT",
            text: "unit_type_unit"
        }
    ]
}

export const POSITION_TYPES = {
    TYPE: [
        {
            value: "0",
            text: "position_type_seated"
        },
        {
            value: "1",
            text: "position_type_lying"
        },
        {
            value: "2",
            text: "position_type_standing"
        }
    ]
}


export const MEASURING_SITE_TYPES = {
    TYPE: [
        {
            value: "0",
            text: "measuring_site_type_left_arm"
        },
        {
            value: "1",
            text: "measuring_site_type_right_arm"
        },
        {
            value: "2",
            text: "measuring_site_type_left_wrist"
        },
        {
            value: "3",
            text: "measuring_site_type_right_wrist"
        },
        {
            value: "4",
            text: "measuring_site_type_left_thigh"
        },
        {
            value: "5",
            text: "measuring_site_type_right_thigh"
        },
        {
            value: "6",
            text: "measuring_site_type_left_calf"
        },
        {
            value: "7",
            text: "measuring_site_type_right_calf"
        },
        {
            value: "8",
            text: "measuring_site_type_left_ankle"
        },
        {
            value: "9",
            text: "measuring_site_type_right_ankle"
        }
    ]
}

export const MENSTRUATION_FLOW_TYPES = {
    TYPE: [
        {
            value: "0",
            text: "not_entered"
        },
        {
            value: "1",
            text: "light"
        },
        {
            value: "2",
            text: "medium"
        },
        {
            value: "3",
            text: "heavy"
        },
        {
            value: "4",
            text: "unexpected"
        }
    ]
}

export const MENSTRUATION_COLOR_TYPES = {
    TYPE: [
        {
            value: "0",
            text: "not_entered"
        },
        {
            value: "1",
            text: "black"
        },
        {
            value: "2",
            text: "brown"
        },
        {
            value: "3",
            text: "dark_red"
        },
        {
            value: "4",
            text: "bright_red"
        },
        {
            value: "5",
            text: "pink"
        },
        {
            value: "6",
            text: "orange"
        },
        {
            value: "7",
            text: "gray"
        }
    ]
}

export const MENSTRUATION_CONSISTENCY_TYPES = {
    TYPE: [
        {
            value: "0",
            text: "not_entered"
        },
        {
            value: "1",
            text: "watery"
        },
        {
            value: "2",
            text: "clots"
        }
    ]
}

export const APPOINTMENTS_FILTER = {
    TYPE: [
        {
            value: "",
            text: "all_label"
        },
        {
            value: "WAITING_FOR_DOCTOR_APPROVAL",
            text: "waiting_for_doctor_approval"
        },
        {
            value: "WAITING_FOR_PATIENT_APPROVAL",
            text: "waiting_for_patient_approval"
        },
        {
            value: "NEED_MORE_INFO",
            text: "need_more_info"
        },
        {
            value: "ACCEPTED",
            text: "accepted"
        },
        {
            value: "COMPLETED",
            text: "completed"
        },
        {
            value: "REJECTED_BY_PATIENT",
            text: "rejected_by_patient"
        },
        {
            value: "CANCELED_BY_PATIENT",
            text: "canceled_by_patient"
        },
        {
            value: "CANCELED_BY_PROVIDER",
            text: "canceled_by_provider"
        },
        {
            value: "REJECTED_BY_PATIENT",
            text: "rejected_by_patient"
        },
        {
            value: "REJECTED_BY_PROVIDER",
            text: "rejected_by_provider"
        },
        {
            value: "CANCELED_NOT_PAID",
            text: "canceled_not_paid"
        },
        {
            value: "CANCELED_RESPONSE_OVERDUE",
            text: "canceled_response_overdue"
        },
    ],
    COLOR: [
        {
            value: "WAITING_FOR_DOCTOR_APPROVAL",
            color: "#FFC300",
            text: "waiting_for_doctor_approval"
        },
        {
            value: "WAITING_FOR_PATIENT_APPROVAL",
            color: "#ff8100",
            text: "waiting_for_patient_approval"
        },
        {
            value: "NEED_MORE_INFO",
            color: "#9A9D9E",
            text: "need_more_info"
        },
        {
            value: "ACCEPTED",
            color: "#02555B",
            text: "accepted"
        },
        {
            value: "COMPLETED",
            color: "#3E9979",
            text: "completed"
        },
        {
            value: "REJECTED_BY_PATIENT",
            color: "#C70039",
            text: "rejected_by_patient"
        },
        {
            value: "CANCELED_BY_PATIENT",
            color: "#C70039",
            text: "canceled_by_patient"
        },
        {
            value: "CANCELED_BY_PROVIDER",
            color: "#C70039",
            text: "canceled_by_provider"
        },
        {
            value: "REJECTED_BY_PATIENT",
            color: "#C70039",
            text: "rejected_by_patient"
        },
        {
            value: "REJECTED_BY_PROVIDER",
            color: "#C70039",
            text: "rejected_by_provider"
        },
        {
            value: "CANCELED_NOT_PAID",
            color: "#C70039",
            text: "canceled_not_paid"
        },
        {
            value: "CANCELED_RESPONSE_OVERDUE",
            color: "#C70039",
            text: "canceled_response_overdue"
        },
        {
            value: "OVERDUE",
            color: "#C70039",
            text: "overdue"
        }
    ]
}
export const BLOOD_TYPE = {
    TYPE: [
        {
            value: "NONE",
            text: "None"
        },
        {
            value: "A_RhD_P",
            text: "A+"
        },
        {
            value: "A_RhD_N",
            text: "A-"
        },
        {
            value: "B_RhD_P",
            text: "B+"
        },
        {
            value: "B_RhD_N",
            text: "B-"
        },
        {
            value: "O_RhD_P",
            text: "0+"
        },
        {
            value: "O_RhD_N",
            text: "0-"
        },
        {
            value: "AB_RhD_P",
            text: "AB+"
        },
        {
            value: "AB_RhD_N",
            text: "AB-"
        },

    ]
}

export const ALLERGY_TYPE = {
    TYPE: [
        {
            value: "NONE",
            text: "None"
        },
        {
            value: "DUST_ALLERGY",
            text: "dust_allergy"
        },
        {
            value: "FOOD_ALLERGY",
            text: "food_allergy"
        },
        {
            value: "INSECT_ALLERGY",
            text: "insect_allergy"
        },
        {
            value: "LATEX_ALLERGY",
            text: "latex_allergy"
        },
        {
            value: "MOLD_ALLERGY",
            text: "mold_allergy"
        },
        {
            value: "PET_ALLERGY",
            text: "pet_allergy"
        },
        {
            value: "POLLEN_ALLERGY",
            text: "pollen_allergy"
        },
        {
            value: "DRUG_ALLERGY",
            text: "drug_allergy"
        },
        {
            value: "OTHER_ALLERGY",
            text: "other_allergy"
        },

    ]
}

export const ALLERGY_SEVERITY = {
    TYPE: [
        {
            value: 0,
            text: "mild"
        },
        {
            value: 1,
            text: "mild_to_moderate"
        },
        {
            value: 2,
            text: "moderate"
        },
        {
            value: 3,
            text: "moderate_to_severe"
        },
        {
            value: 4,
            text: "severe"
        },
        {
            value: 5,
            text: "life_threatening"
        },

    ]
}


export const HEALTH_ISSUE_OPTIONS = {
    TYPE: [
        {
            value: 0,
            text: "current_issues"
        },
        {
            value: 1,
            text: "past_issues"
        },
        {
            value: 2,
            text: "all_issues"
        }
    ]
}


export const APPOINTMENT_FILTER_OPTIONS = {
    TYPE: [
        {
            value: 0,
            text: "appointment_filter_all"
        },
        {
            value: 1,
            text: "appointment_filter_completed"
        },
        {
            value: 2,
            text: "appointment_filter_pending"
        },
        {
            value: 3,
            text: "appointment_filter_active_consults"
        }
    ]
}

/**
 * Return select options with the text resolved to the selected language
 *
 * @param {Array} options - the list of options
 */
export function getResolvedOptions(options) {
    return options && options.map(o => {
        return {value: o.value, text: !$$(o.text).startsWith("$") ? $$(o.text) : o.text}
    });
}

export function getHealthIssueOptions(options) {
    return options && options.map(o => {
        return {value: o, text: o.name.toLowerCase()}
    });
}

export function getHealthIssueOptionsNoLowerCase(options) {
    return options && options.map(o => {
        return {value: o.id, text: o.name}
    });
}

/**
 * Return text for the selected option
 *
 * @param {Array} options - the list of options
 * @param {string} value - the selected value
 */
export function getResolvedText(options, value) {
    if (options && options.length > 0 && value !== null && value !== undefined) {
        const option = options.find(o => o.value === value);
        if (option) {
            return $$(option.text);
        }
    }
    return '';
}

/**
 * Return color for the selected option
 *
 * @param {Array} options - the list of options
 * @param {string} value - the selected value
 */
export function getColorForOption(options, value) {
    if (options && options.length > 0 && value !== null && value !== undefined) {
        const option = options.find(o => o.value === value);
        if (option) {
            return option.color;
        }
    }
    return '';
}

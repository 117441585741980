import React from "react";
import AnswerType from "../../../models/answer-type/AnswerType";
import Modal from "react-bootstrap/Modal";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import BaseAnswer from "../BaseAnswer";
import "../Answer.css"
import FormCheck from "../../input/FormCheck";
import {$$} from "../../../../helpers/localization";

class TextAnswer extends BaseAnswer {
    answerLabel;

    constructor(props) {
        super(props);
        this.answerLabel = props.answer.label[this.language];
    }

    render() {
        const answer = this.props.answer;

        return (
            <span>
                <div className={`custom-control custom-${answer.selectionType}`}>
                    <FormCheck ref={this.inputRef} answer={answer} handleChange={this.onInputChange}/>
                    <label onClick={() => this.inputRef.current.click()}
                          className={'cursor-pointer mr-2 custom-control-label'}>
                        {`${answer.label[this.language]} ${this.getSuffix(answer)}`}
                    </label>
                </div>
                <Modal
                    show={this.state.open}
                    centered={true}
                    onHide={() => this.onCancelButtonPressed()}
                    dialogClassName="modal-20w picker-modal">
                    <Modal.Header className="height-auto pb-0">{this.answerLabel}</Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Control
                                value={this.state.result}
                                onChange={event => this.setState({result: event.target.value})}
                                as={'textarea'}/>
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.onCancelButtonPressed()}>{$$("cancel_btn")}</Button>
                        <Button disabled={!this.state.result} variant="primary"
                                onClick={this.onOkButtonPressed}>{$$("OK")}</Button>
                    </Modal.Footer>
                </Modal>
            </span>
        )
    };

    onOkButtonPressed = () => {
        this.props.answer.result = this.state.result;
        this.selectAnswer();
        this.setState({open: false});
    };
}

class TextAnswerFactory {
    static get type() {
        return AnswerType.TEXT;
    }

    static create(answer, updateAnswer, language) {
        return <TextAnswer answer={answer} updateAnswer={updateAnswer} language={language}/>
    }
}

export default TextAnswerFactory

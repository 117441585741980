import {
    CLEAR_HEALTH_ISSUES,
    CREATE_HEALTH_ISSUE_ENTRY_ERROR,
    CREATE_HEALTH_ISSUE_ENTRY_REQUEST_SENT,
    CREATE_HEALTH_ISSUE_ENTRY_SUCCESS,
    FETCH_HEALTH_ISSUES_DATA_ERROR,
    FETCH_HEALTH_ISSUES_DATA_REQUEST_SENT,
    FETCH_HEALTH_ISSUES_DATA_SUCCESS,
    LOGOUT,
    NO_MORE_ENTRIES,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    RESET_HEALTH_ISSUES_DATA_SUCCESS,
    SELECT_USER,
    UPDATE_HEALTH_ISSUE_DATA_ERROR,
    UPDATE_HEALTH_ISSUE_DATA_REQUEST_SENT
} from '../actions/actions'
import {requestStatus} from './requests_reducers';

/**
 * Health issues reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const healthIssuesInitialState = {entries: [], isLastPage: false, request: requestStatus(undefined, {})}

export function healthIssues(state = healthIssuesInitialState, action) {
    switch (action.type) {
        case FETCH_HEALTH_ISSUES_DATA_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case FETCH_HEALTH_ISSUES_DATA_SUCCESS: {
            return {
                ...state,
                entries: [...state.entries, ...action.result],
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case RESET_HEALTH_ISSUES_DATA_SUCCESS: {
            return {...state, entries: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_HEALTH_ISSUES_DATA_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, isLastPage, ...rest} = state;
            return {
                ...rest,
                entries: [],
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case CREATE_HEALTH_ISSUE_ENTRY_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT}), type: "CREATE"}
        }
        case CREATE_HEALTH_ISSUE_ENTRY_SUCCESS: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SUCCESS, response: action.result})}
        }
        case CREATE_HEALTH_ISSUE_ENTRY_ERROR: {
            const {...rest} = state;
            return {
                ...rest,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result}),
                type: "CREATE"
            }
        }
        case UPDATE_HEALTH_ISSUE_DATA_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT}), type: "UPDATE"}
        }
        case UPDATE_HEALTH_ISSUE_DATA_ERROR: {
            const {...rest} = state;
            return {
                ...rest,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result}),
                type: "UPDATE"
            }
        }
        case NO_MORE_ENTRIES: {
            return {...state, isLastPage: true}
        }
        case CLEAR_HEALTH_ISSUES:
        case SELECT_USER:
        case LOGOUT: {
            return {...healthIssuesInitialState};
        }
        default: {
            return state;
        }
    }
}
import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import UserImage from '../shared/UserImage'
import {infoUtils} from '../../utils/infoUtils'
import no_appointments from '../../resources/images/no_appointments.png'
import ClinicianName from './ClinicianName'
import PropTypes from "prop-types";
import {isLoggedIn} from "../../helpers/auth_helper";
import history from "../../helpers/history";
import {Routes} from "../../constants/routes";


class CliniciansListView extends Component {
    state = {
        chosenDate: new Date(),
        triggeredFromBtn: false,
    }

    constructor(props) {
        super(props);
    }

    getNewBody = () => {
        let clinicians = this.getFilteredClinicians().map((provider) => {
            return this.getClinician(provider)
        })
        return <>
            {clinicians}
        </>
    }


    getSpecialtiesForPanel = (clinician) => {
        return clinician.specialties.map((specialty, index) => {
            if (index === 0) return $$(specialty.toLowerCase()) ? $$(specialty.toLowerCase()) : specialty.replace("_", " ").toLowerCase()
            else return ", " + ($$(specialty.toLowerCase()) ? $$(specialty.toLowerCase()) : specialty.replace("_", " ").toLowerCase())
        })
    }

    getOrganizationsForPanel = (clinician) => {
        return clinician.organizations.map((organization, index) => {
            if (index === 0) return organization.name;
            else return "/ " + organization.name;
        })
    }

    getClinicFromSearchParams = () => {
        let orgId = "";
        const queryParams = new URLSearchParams(this.props.location.search)
        if (queryParams.has('org')) {
            orgId = queryParams.get('org');
        }
        return orgId
    }

    getClinician = (clinician) => {
        if (clinician) {
            return <div key={clinician.id} className='high-shadow-container clinician-container pointer'
                        onClick={() => {
                            if (!isLoggedIn()) {
                                history.push(Routes.CLINICIAN_NOT_LOGGED_IN + "?clinician=" + clinician.id + "&org=" + this.getClinicFromSearchParams())
                            } else {
                                this.props.onClinicianClick(clinician)
                            }
                        }}>
                <div className="flex-space-between items-center">
                    <div className='clinician-img-new-container pointer'
                         onClick={() => {
                             if (!isLoggedIn()) {
                                 history.push(Routes.CLINICIAN_NOT_LOGGED_IN + "?clinician=" + clinician.id + this.getClinicFromSearchParams())
                             } else {
                                 this.props.onClinicianClick(clinician)
                             }
                         }}>
                        <UserImage
                            clinician={true}
                            userID={clinician.id}
                            classnames={"clinician-img-new"}/>
                    </div>
                    <div className="pl-2 clinician-panel-data-container">
                        <ClinicianName
                            className={"clinician-name"}
                            clinician={clinician}
                            i18n={this.props.i18n}
                            onNameClick={() => {
                                if (!isLoggedIn()) {
                                    history.push(Routes.CLINICIAN_NOT_LOGGED_IN + "?clinician=" + clinician.id + this.getClinicFromSearchParams())
                                } else {
                                    this.props.onClinicianClick(clinician)
                                }
                            }}
                        />
                        <div className="clinician-panel-specialty mt-2">{this.getSpecialtiesForPanel(clinician)}</div>
                        <div className="clinician-panel-hospital mt-2">{this.getOrganizationsForPanel(clinician)}</div>
                    </div>
                </div>
            </div>
        }
    }

    /**
     * Filters the clients based on the search value
     *
     * @returns {Array} the list of filtered clients
     */
    getFilteredClinicians = () => {
        return this.props.providers ? this.props.providers.filter(u => {
            let shouldReturn = true;
            if (!this.props.searchValue) {
                return true;
            }
            shouldReturn = u.fullname.toLowerCase().indexOf(this.props.searchValue.toLowerCase()) !== -1;
            u.specialties.map((specialty) => {
                if (specialty.replace("_", " ").toLowerCase().indexOf(this.props.searchValue.toLowerCase()) !== -1) shouldReturn = true
            })

            return shouldReturn
        }) : [];
    }

    /**
     * An event handler triggered when a new date is choosen
     * from the datepicker
     *
     * @param {Date} date - the new choosen date
     * @param  triggeredFromBtn - triggeredFromBtn
     */
    onSelectDate = (date, triggeredFromBtn) => {
        this.setState({chosenDate: date, triggeredFromBtn});
    };

    /**
     * An event handler triggered when 'Today' button is pressed
     *
     */
    selectToday = () => {
        this.onSelectDate(new Date(), true);
    }


    render() {
        let noClinicians = {
            imgClass: 'no-entry-image',
            objClass: 'no-data-object',
            primaryLabelClass: 'no-clinicians-primary-label',
            secondaryLabelClass: 'no-clinicians-secondary-label',
            src: no_appointments,
            primaryLabel: $$('no_clinicians_primary_label'),
            secondaryLabel: $$('no_clinicians_secondary_label'),
        }
        return (
            <>
                {this.props.loading && <div className={"med-search-loader"}/>}
                {!this.props.loading && this.getFilteredClinicians().length > 0 &&
                    <div className="clinicians-container">
                        {this.getNewBody()}
                    </div>
                }
                {!this.props.loading && this.getFilteredClinicians().length === 0 && infoUtils.noData(noClinicians)}
            </>
        )
    }
}

CliniciansListView.propTypes = {
    i18n: PropTypes.object,
    onClinicianClick: PropTypes.func,
    loading: PropTypes.bool,
    providers: PropTypes.array,
    searchValue: PropTypes.string
}

export default CliniciansListView;

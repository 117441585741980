import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import Select from '../shared/Select'
import {
    CYCLE,
    DURATION_FOR_X_DAYS,
    DURATION_UNTIL_DATE,
    EVERY_X_DAYS,
    SPECIFIC_DAYS_OF_WEEK
} from '../../constants/medications_constants';
import HowToTake from './HowToTake';
import {
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types";
import bg from 'date-fns/locale/bg';
import sq from 'date-fns/locale/sq';
import enGB from 'date-fns/locale/en-GB';
import MedicationTimesPerDay from "./MedicationTimesPerDay";
import {CONVERTER} from "../../utils/converter";

export default class NotWhenNeededForms extends Component {
    constructor(props) {
        super(props);
    }

    getLocale = () => {
        switch (this.props.i18n.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            case "sq":
                return sq
            default:
                return enGB
        }
    }

    render() {
        return (
            <div className="form-group">
                <div className="row form-group">
                    <div className="col-sm-12">
                        {this.props.scheduleType === EVERY_X_DAYS &&
                            <div className="form-group d-flex align-items-center">
                                <label
                                    className="form-control-label flex-grow-0 flex-shrink-0 pr-2 mb-0">{$$("every")}</label>
                                <div className={"flex-grow-1 flex-shrink-0"}>
                                    <input type="number" className="form-control"
                                           min="1"
                                           required
                                           value={this.props.scheduleDaysInactive} placeholder={$$('days')}
                                           name="scheduleDaysInactive" onChange={this.props.onInputChange}/>
                                </div>
                                <div className="flex-grow-0 flex-shrink-0 pl-2">
                                    {$$("days")}
                                </div>
                            </div>}
                        {this.props.scheduleType === SPECIFIC_DAYS_OF_WEEK &&
                            <>
                                <label>{$$("specific_days_of_week")}</label>
                                {this.props.getCheckBoxes()}
                            </>}
                        {this.props.scheduleType === CYCLE &&
                            <>
                                <label>{$$("take")}</label>
                                {<input type="number" className="form-control" min={1}
                                        required
                                        value={this.props.scheduleDaysActive} placeholder={$$('take')}
                                        name="scheduleDaysActive" onChange={this.props.onInputChange}/>}
                                <label>{$$("rest")}</label>
                                {<input type="number" className="form-control" min={1}
                                        required
                                        value={this.props.scheduleDaysInactive} placeholder={$$('rest')}
                                        name="scheduleDaysInactive" onChange={this.props.onInputChange}/>}
                                <label>{$$("cycle_day")}</label>
                                {<input type="number" className="form-control" min={1}
                                        required
                                        value={this.props.scheduleDaysCountStartsAt}
                                        placeholder={$$('cycle_day')} name="scheduleDaysCountStartsAt"
                                        onChange={this.props.onInputChange}/>}
                            </>}
                    </div>
                </div>
                <HowToTake
                    getMedicationFrequencyString={this.props.getMedicationFrequencyString}
                    howToTake={this.props.howToTake}
                    scheduleTimesPerDay={this.props.scheduleTimesPerDay}
                    scheduleHours={this.props.scheduleHours}
                    getTimesArrayEveryXHours={this.props.getTimesArrayEveryXHours}
                    getTimesArrayXTimesADay={this.props.getTimesArrayXTimesADay}
                    onChange={this.props.onChange}
                    times={this.props.times}
                    weekendTimes={this.props.weekendTimes}
                />
                <div className="row form-group">
                    <div style={{'width': '100%'}}>
                        <div className='row form-group' style={{'flexWrap': 'wrap', 'width': '99%'}}>
                            {/*{this.props.output}*/}
                            {this.props.times?.map((a, i) =>
                                <MedicationTimesPerDay key={i} time={a} onChange={(t) => {
                                    let times = [...this.props.times];
                                    times[i] = t;
                                    this.props.updateState({times: times})
                                }} i18n={this.props.i18n} ampm={this.props.ampm}/>
                            )}
                        </div>
                        <div className="col-sm-12">
                            <input
                                onChange={this.props.setWeekend}
                                type="checkbox" name="showWeekend" checked={this.props.showWeekend}
                                value={this.props.showWeekend}/> {$$("weekend_schedule")}
                            {this.props.showWeekend && <div>
                                {this.props.getCheckBoxesWeekend()}
                                <div className='row form-group' style={{'marginLeft': '10px'}}>
                                    {this.props.weekendTimes?.map((a, i) =>
                                        <MedicationTimesPerDay key={i} time={a} onChange={(t) => {
                                            let times = [...this.props.weekendTimes];
                                            times[i] = t;
                                            this.props.updateState({weekendTimes: times})
                                        }} i18n={this.props.i18n} ampm={this.props.ampm}/>)
                                    }
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <Select
                            label={$$("duration")}
                            name="durationType"
                            options={this.props.getMedicationDurationString()}
                            value={this.props.durationType}
                            onChange={this.props.onInputChange}
                            placeHolder={$$('duration')}
                            style={{
                                'minWidth': '100%',
                                'maxWidth': '100%',
                                'zIndex': '1',
                                'opacity': '70%'
                            }}/>

                    </div>
                    {this.props.durationType && this.props.durationType === DURATION_UNTIL_DATE &&
                        <div className="col-sm-6 form-group">
                            <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    variant="inline"
                                    format={CONVERTER.getDateFNSDateTimeFormat(false)}
                                    margin="normal"
                                    id="date-picker-inline"
                                    label={$$("end_date_label")}
                                    value={this.props.ends}
                                    onChange={this.props.onEndDateChange}
                                    KeyboardButtonProps={{'aria-label': 'change date',}}
                                    invalidDateMessage={$$('invalid_date_format')}
                                    required
                                />
                            </MuiPickersUtilsProvider>
                        </div>}
                    {this.props.durationType && this.props.durationType === DURATION_FOR_X_DAYS &&
                        <div className="col-sm-6">
                            <label>{$$("number_of_days")}</label>
                            <input type="number" className="form-control"
                                   min={1}
                                   required
                                   value={this.props.durationInDays}
                                   placeholder={$$('for_x_days_until')} name="durationInDays"
                                   onChange={this.props.onInputChange}/>
                        </div>}
                </div>
            </div>
        )
    }
}

NotWhenNeededForms.propTypes = {
    durationInDays: PropTypes.any,
    weekendTimes: PropTypes.any,
    times: PropTypes.any,
    updateState: PropTypes.func,
    ampm: PropTypes.any,
    durationType: PropTypes.string,
    ends: PropTypes.any,
    getCheckBoxes: PropTypes.func,
    getCheckBoxesWeekend: PropTypes.func,
    getMedicationDurationString: PropTypes.func,
    getMedicationFrequencyString: PropTypes.func,
    getTimesArrayEveryXHours: PropTypes.func,
    getTimesArrayXTimesADay: PropTypes.func,
    howToTake: PropTypes.string,
    onChange: PropTypes.func,
    onEndDateChange: PropTypes.func,
    onInputChange: PropTypes.func,
    scheduleDaysActive: PropTypes.number,
    scheduleDaysCountStartsAt: PropTypes.number,
    scheduleDaysInactive: PropTypes.number,
    scheduleHours: PropTypes.number,
    i18n: PropTypes.any,
    scheduleTimesPerDay: PropTypes.number,
    scheduleType: PropTypes.string,
    setWeekend: PropTypes.func,
    showWeekend: PropTypes.bool
}
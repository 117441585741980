import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import * as PropTypes from "prop-types";
import {
    ALLERGY_SEVERITY,
    ALLERGY_TYPE,
    getResolvedOptions
} from '../../constants/select_options'
import Select from '../shared/Select'
import classnames from 'classnames'
import moment from 'moment';
import {updateUserMedicalProfile} from '../../actions/users_actions'
import {connect} from 'react-redux'
import {dateTimeUtils} from '../../utils/dateTimeUtils'
import CenteredModal from '../shared/CenteredModal';
import {
    createHealthIssue,
    deleteHealthIssue,
    fetchSelectedUserHealthIssues,
    updateHealthIssue
} from '../../actions/health-issues_actions'
import {clearIcd, searchIcd} from '../../actions/icd_actions'
import CustomMultiSelect from '../shared/CustomMultiSelect';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {infoUtils} from "../../utils/infoUtils";
import no_data from "../../resources/images/no_data.png";
import {CHRONIC_CONDITIONS} from "../../actions/actions";
import FormWithLoading from "../shared/FormWithLoading";
import bg from 'date-fns/locale/bg';
import sq from 'date-fns/locale/sq';
import enGB from 'date-fns/locale/en-GB';

export class Allergies extends Component {
    state = {
        createAllergy: false,
        selectedAllergyType: ALLERGY_TYPE[0],
        allergenName: "",
        diagnosedDatetime: null,
        description: "",
        medications: "",
        selectedSeverity: ALLERGY_SEVERITY[0],
        errors: {},
        selectedCode: "",
        codeArray: null,
    }

    constructor(props) {
        super(props);
    }

    clearState = () => {

        this.setState({
            createAllergy: false,
            selectedAllergyType: ALLERGY_TYPE[0],
            allergenName: "",
            diagnosedDatetime: null,
            description: "",
            medications: "",
            selectedSeverity: ALLERGY_SEVERITY[0],
            errors: {},
            selectedCode: "",
            codeArray: null,
        })
        this.props.clearIcd()
    }

    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        if (evt.target) {
            fields[evt.target.name] = evt.target.value;
        } else {
            fields[evt.name] = evt.value;
        }
        this.setState(fields);
    }

    getLocale = () => {
        switch(this.props.i18n.selected.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            case "sq":
                return sq
            default:
                return enGB
        }
    }

    /**
     * Set the state to the latest selected date.
     *
     * @param {object} date - The event handler argument
     */
    onDateChange = (date) => {
        this.setState({diagnosedDatetime: moment(date).valueOf()})
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.selectedAllergy != null && prevProps.selectedAllergy !== this.props.selectedAllergy) {
            let codeArray = null
            if (this.props.selectedAllergy.disease_code)
                codeArray = [{
                    value: this.props.selectedAllergy.disease_code,
                    text: this.props.selectedAllergy.description
                }]
            this.setState({
                    selectedAllergyType: this.props.selectedAllergy.type ? this.props.selectedAllergy.type : ALLERGY_TYPE[0],
                    allergenName: this.props.selectedAllergy.name ? this.props.selectedAllergy.name : "",
                    diagnosedDatetime: this.props.selectedAllergy.date_diagnosed ? this.props.selectedAllergy.date_diagnosed : null,
                    description: this.props.selectedAllergy.description ? this.props.selectedAllergy.description : "",
                    medications: this.props.selectedAllergy.medications ? this.props.selectedAllergy.medications : "",
                    selectedSeverity: this.props.selectedAllergy.severity ? this.props.selectedAllergy.severity : ALLERGY_SEVERITY[0],
                    selectedCode: this.props.selectedAllergy.disease_code ? this.props.selectedAllergy.disease_code : "",
                    codeArray: codeArray
                },
            )
        }
    }


    getIcdOptions(options) {
        return options && options.map(o => {
            if (o.label) {
                let n = o.label.search(/[A-Z][0-9]{2}[.]/);
                let name = o.label.substring(0, n)
                let text = o.label.substring(n)
                return {value: o.id, text: text, name: name}
            } else return {value: o.id, text: o.label ? o.label : "", name: o.label ? o.label : ""}
        });
    }

    onAllergyCreate = (state) => {
        this.setState({
            createAllergy: state,
        })

    };

    onTypeChange = (e) => {
        this.setState({
            selectedAllergyType: e.value
        });

    }

    onSeverityChange = (e) => {
        this.setState({
            selectedSeverity: e.value
        });
    }


    validate = () => {
        let d = this.state.diagnosedDatetime ? new Date(this.state.diagnosedDatetime): null;

        const errors = {};
        this.setState({errors: ''});
        if (d && d > moment().valueOf()) {
            errors.datetime = 'datetime_max_value_message';
        }
        if (!this.state.allergenName) {
            errors.allergenName = 'allergen_name_required_message';
        }
        this.setState({errors});
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }
        return Object.keys(errors).length === 0;

    }

    onSave = async () => {
        if (this.validate()) {
            this.onAllergyCreate(false)

            let allergies = this.props.medicalProfile.data.allergies
            let filteredAllergies = allergies;

            let allergy = {
                date_created: dateTimeUtils.toOffsetDateTime(moment().utc()),
                date_diagnosed: this.state.diagnosedDatetime ? moment(this.state.diagnosedDatetime).valueOf(): null,
                date_modified: dateTimeUtils.toOffsetDateTime(moment().utc()),
                description: this.state.description,
                medications: this.state.medications,
                name: this.state.allergenName,
                disease_code: this.state.selectedCode ? this.state.selectedCode : "",
                severity: this.state.selectedSeverity,
                type: this.state.selectedAllergyType
            }

            if (this.props.selectedAllergy == null) {
                filteredAllergies.push(allergy)
            } else {
                allergy.id = this.props.selectedAllergy.id
                filteredAllergies = allergies.filter((allergy) => {
                    return allergy.id !== this.props.selectedAllergy.id
                })
                filteredAllergies.push(allergy)
            }

            let form = {
                ...this.props.medicalProfile.data,
                allergies: filteredAllergies,
            }
            let keepSelectedAllergy = this.props.selectedAllergy
            await this.props.updateUserMedicalProfile(this.props.selectedUser.id, form)
            let allergyFromResponse = this.props.createAllergyResponse.allergies[this.props.createAllergyResponse.allergies.length - 1]
            if (keepSelectedAllergy == null) {
                let healthIssue = {
                    allergy_condition_id: allergyFromResponse.id,
                    allergy_condition: allergyFromResponse,
                    user_id: this.props.selectedUser.id,
                    date_started: allergyFromResponse.date_diagnosed ? dateTimeUtils.toOffsetDateTime(moment(allergyFromResponse.date_diagnosed).utc()) : "",
                    date_ended: "",
                    notes: "",
                    deleted: false,
                    date_created: dateTimeUtils.toOffsetDateTime(new Date()),
                    date_modified: dateTimeUtils.toOffsetDateTime(new Date()),
                    name: this.state.allergenName,
                }
                this.props.createHealthIssue(healthIssue, this.props.selectedUser.id);
            } else {
                let healthIssues = this.props.healthIssues
                let healthIssueToBeEdited = null
                await healthIssues.map((h) => {
                    if (h.allergy_condition_id === keepSelectedAllergy.id) {
                        healthIssueToBeEdited = h
                        healthIssueToBeEdited.date_started = this.state.diagnosedDatetime ? dateTimeUtils.toOffsetDateTime(moment(this.state.diagnosedDatetime).utc()) : ""
                        healthIssueToBeEdited.date_modified = dateTimeUtils.toOffsetDateTime(new Date())
                        healthIssueToBeEdited.name = this.state.allergenName
                    }
                })
                if (healthIssueToBeEdited) this.props.updateHealthIssue(healthIssueToBeEdited, this.props.selectedUser.id)
            }
            this.clearState()
            this.props.clear()
        }

    }


    /**
     * Confirm deletion and remove entry from list
     */
    onDeleteConfirmModal = async () => {
        let filteredAllergies = this.props.medicalProfile.data.allergies.filter((allergy) => {
            return allergy.id !== this.props.selectedEntryToDelete.id
        })
        this.setState({
            selectedEntryToDelete: null,
        });
        let form = {
            ...this.props.medicalProfile.data,
            allergies: filteredAllergies
        }
        await this.props.updateUserMedicalProfile(this.props.selectedUser.id, form);

        let healthIssues = this.props.healthIssues
        let healthIssueToBeDeleted = null
        healthIssues.map((h) => {
            if (h.allergy_condition_id === this.props.selectedEntryToDelete.id) {
                healthIssueToBeDeleted = h
            }
        })
        if (healthIssueToBeDeleted) this.props.deleteHealthIssue(healthIssueToBeDeleted.id, this.props.selectedUser.id);

        this.props.clear()
        this.clearState()

    }

    onIcdChange = async (e) => {
        await (this.props.selectedUser ? this.props.searchIcd(this.props.i18n.selected.lang, e) : null)
    }

    onSelectIcd = (selectedItem) => {
        this.setState(
            {
                selectedCode: selectedItem.value,
                description: selectedItem.text
            })

    }

    onRemoveIcd = () => {
        this.setState({selectedCode: ""})
        this.props.clearIcd()

    }

    render() {
        let noAllergies = {
            imgClass: 'no-entry-image-medical-record',
            objClass: 'no-data-object-landing',
            primaryLabelClass: 'no-entry-medical-record-label',
            src: no_data,
            primaryLabel: $$('no_allergies'),
            nobreak: true
        }

        classnames('my-1 mr-sm-2', {
            'custom-error form-control': this.state.errors.datetime,
            'form-control': !this.state.errors.datetime,
        });
        return <div className={"allergies-cont"}>
            <div className="new-header row space-between">
                <span className="add-new-condition">{$$("allergies")}</span>
                <div className="pointer" onClick={() => this.onAllergyCreate(true)}>
                    <span className="fa fa-plus add-condition"/>
                    <span className="add-new-condition">{$$("add_entry")}</span>
                </div>
            </div>

            <div className="d-flex condition-container">
                {this.props.medicalProfile.data.allergies && this.props.medicalProfile.data.allergies.length === 0 &&
                <div className="low-shadow-container flex-center no-data-count">
                    <div className="card-body light-green flex-center">
                        {infoUtils.noData(noAllergies)}
                    </div>
                </div>}
                {this.props.medicalProfile.data.allergies ?
                    this.props.medicalProfile.data.allergies.map(
                        this.props.callbackfn
                    ) : ""}
            </div>

            {(this.state.createAllergy || this.props.selectedAllergy != null) &&
            <CenteredModal title={this.props.selectedAllergy ? $$('edit_allergy') : $$('add_allergy')}
                           show={this.state.createAllergy || this.props.selectedAllergy != null}
                           size={"Default"}
                           onHide={() => {
                               this.props.selectedAllergy ? this.props.onAllergyEdit(true) : null
                               this.clearState()
                               this.props.clear()
                           }}
                           onConfirm={() => {
                               this.onSave()
                           }}>
                <FormWithLoading
                    formDisabled={this.props.formDisabled}
                    currentForm={CHRONIC_CONDITIONS}
                    marginTop="30%"
                    marginLeft="calc(50% - 70px)"
                >
                    <br/>
                    <div className="form-group">
                        <Select
                            name="type"
                            label={$$("allergy_type_label")}
                            options={getResolvedOptions(ALLERGY_TYPE.TYPE)}
                            value={this.state.selectedAllergyType}
                            onChange={this.onTypeChange}
                            placeHolder={$$('type_label')}
                            selectDisabled={false}
                            customClass = "settings-dropdown"
                        />

                    </div>
                    <div className="form-group">
                        {this.state.allergenName && <label>{$$("allergen_name_label")}</label>}
                        <input type="text" className="form-control"
                               value={(this.state.allergenName === "undefined") ? '' : this.state.allergenName}
                               placeholder={$$('allergen_name_label')} name="allergenName" onChange={this.onInputChange}
                               required/>
                        <div className={this.state.errors.allergenName ? "custom-invalid-feedback last-center-text" : "invalid-feedback last-center-text"}>
                            {this.state.errors.allergenName ? $$(this.state.errors.allergenName) : $$("allergen_name_required_message")}
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="d-flex">
                            <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    format={this.props.settings.dateFormat}
                                    margin="normal"
                                    id="date-picker-inline"
                                    label={$$("log_symptoms_date_label")}
                                    value={this.state.diagnosedDatetime}
                                    onChange={this.onDateChange}
                                    invalidDateMessage={$$('invalid_date_format')}
                                    KeyboardButtonProps={{'aria-label': 'change date',}}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={this.state.errors.datetime ? "custom-invalid-feedback last-center-text" : "invalid-feedback last-center-text"}>
                            {this.state.errors.datetime ? $$(this.state.errors.datetime) : ""}
                        </div>
                    </div>
                    <div className="form-group pre-wrap">
                        {this.state.selectedCode && <label>{$$("disease")}</label>}
                        <CustomMultiSelect
                            options={this.getIcdOptions(this.props.icdEntries)}
                            onSearch={this.onIcdChange}
                            selectedValues={this.state.codeArray ? this.state.codeArray : []}
                            displayValue="text"
                            placeholder={$$("disease")}
                            groupBy="name"
                            emptyRecordMsg={$$("no_results_found")}
                            loading={!this.props.icdEntries}
                            loadingMessage={$$("loading_data_label")}
                            selectionLimit={1}
                            avoidHighlightFirstOption={true}
                            onSelect={this.onSelectIcd}
                            onRemove={this.onRemoveIcd}
                            hidePlaceholder={this.state.selectedCode !== ""
                            }
                        />
                    </div>
                    {!this.state.selectedCode && <div className="form-group">
                        {this.state.description && <label>{$$("description")}</label>}
                        <input type="text" className="form-control" value={this.state.description || ''}
                               placeholder={$$('description')} name="description" onChange={this.onInputChange}/>
                    </div>}
                    <div className="form-group">
                        {this.state.medications && <label>{$$("medications")}</label>}
                        <input type="text" className="form-control" value={this.state.medications || ''}
                               placeholder={$$('medications')} name="medications" onChange={this.onInputChange}/>
                    </div>
                    <div className="form-group">
                        <Select
                            customClass = "settings-dropdown"
                            name="severity"
                            label={$$("allergy_severity_label")}
                            options={getResolvedOptions(ALLERGY_SEVERITY.TYPE)}
                            value={this.state.selectedSeverity}
                            onChange={this.onSeverityChange}
                            placeHolder={$$('allergy_severity_label')}
                            selectDisabled={false}
                        />
                    </div>
                </FormWithLoading>
            </CenteredModal>
            }
            {this.props.selectedEntryToDelete &&
            <CenteredModal title={$$('delete_log_entry_modal_header')}
                           dialogClassName='doc-delete-modal'
                           show={this.props.selectedEntryToDelete != null}
                           onHide={this.props.clear}
                           cancelBtnLabel={$$('cancel_btn')}
                           onConfirm={this.onDeleteConfirmModal}
                           id="delete-modal-title"
                           className="center-delete-modal"
                           idFooter="footer-delete-modal"
                           confirmBtnLabel={$$('delete_label')}
                           confirmBtnClass="danger"
                           idBtnPrimary="btn-danger"
                           idBtnSecondary="btn-secondary">
                {$$('mark_allergy_entry_deleted_modal_title')}
                <div className="bin-align">
                    <i className="fas fa-trash-alt fa-3x"/>
                </div>
            </CenteredModal>
            }
        </div>;
    }
}

Allergies.propTypes = {
    callbackfn: PropTypes.func,
    clear: PropTypes.func,
    clearIcd: PropTypes.func,
    createAllergyResponse: PropTypes.object,
    createHealthIssue: PropTypes.func,
    deleteHealthIssue: PropTypes.func,
    enableUpdateButton: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    formDisabled: PropTypes.object,
    healthIssues: PropTypes.array,
    i18n: PropTypes.object,
    selectedAllergy: PropTypes.any,
    selectedEntryToDelete: PropTypes.any,
    icdEntries: PropTypes.array,
    medicalProfile: PropTypes.object,
    onAllergyEdit: PropTypes.func,
    searchIcd: PropTypes.func,
    selectedUser: PropTypes.object,
    updateHealthIssue: PropTypes.func,
    settings: PropTypes.any,
    updateUserMedicalProfile: PropTypes.func
}

const mapStateToProps = (state) => ({
    healthIssues: state.healthIssues.entries,
    createAllergyResponse: state.medicalProfile.data,
    icdEntries: state.icd.entries,
    settings: state.settings.data,
    formDisabled: state.formInteractions
})

const mapDispatchToProps = {
    updateUserMedicalProfile,
    fetchSelectedUserHealthIssues,
    createHealthIssue,
    updateHealthIssue,
    deleteHealthIssue,
    searchIcd,
    clearIcd
}


export default connect(mapStateToProps, mapDispatchToProps)(Allergies)

import React, {Component} from 'react'
import CenteredModal from '../shared/CenteredModal'
import {$$} from '../../helpers/localization'
import moment from 'moment';
import {logSelectedUserSymptoms} from '../../actions/symptoms_actions'
import {connect} from 'react-redux'
import SymptomBox from './SymptomBox';
import HealthIssueTag from '../health/HealthIssueTag';
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";
import {getSymptomName} from '../../utils/symptomUtils';
import PropTypes from "prop-types";
import {dateTimeUtils} from "../../utils/dateTimeUtils";
import {CONVERTER} from "../../utils/converter";

export class Symptoms extends Component {

    state = {
        showModal: false,
        groupActive: false,
        filteredLogbookLength: this.props.groupLength
    }

    constructor(props) {
        super(props);
    }

    /**
     * Set state for the selected symptom entry when showing the modal
     */
    showModal = () => {
        this.setState({showModal: true});
    }

    /**
     * On confirm modal, mark the symptom entry as deleted.
     */
    onConfirmModal = () => {
        this.markEntryAsDeleted();
        this.setState({showModal: false});
    }

    /**
     * Prepares the body of the update request, marks the selected symptom entry as deleted
     */
    markEntryAsDeleted = () => {
        const body = [];
        let data = {
            ...this.props.entry,
            updated_timestamp: moment().valueOf(),
            deleted: true,
        };
        body.push(data);

        this.props.logSelectedUserSymptoms(data.user_id, body);
    }

    /**
     * Iterate the syptoms and return the formatted symptoms components
     *
     * @returns the html to render
     */
    getFormatedSymptoms = () => {
        return this.props.entry.symptoms.map((s, idx) => {

            let strength = s.strength;
            if (s.type === 'NO_SYMPTOMS') {
                strength = -1;
            }
            if (strength !== 0) {
                let symptomName = getSymptomName(s, this.props.nomenclature, this.props.i18n.lang);
                if (s.type === "NO_SYMPTOMS")
                    symptomName = "NO_SYMPTOMS"
                return (
                    <div key={idx} className="symptom-list-item no-border">
                        <SymptomBox
                            idx={idx}
                            symptomName={symptomName}
                            strength={strength}
                            i18n={this.props.i18n}
                        />
                    </div>
                );
            } else {
                return '';
            }
        })
    }

    showSymptomsList = (e) => {
        this.props.showSymptomsList(e, this.props.entry);
    }

    /**
     * Creates the health issues list
     *
     * @param {object} entry - the symptoms object
     * @returns {ReactElement}
     */
    getHealthIssueList = (entry) => {
        let healthIss = [];
        if (entry.health_issues_ids) {
            if (this.props.healthIssues && this.props.healthIssues.entries && this.props.healthIssues.entries.length > 0) {
                entry.health_issues_ids.map((ids) => {
                    this.props.healthIssues.entries.map((h) => {
                        if (ids === h.id) {
                            healthIss.push(h.name)
                        }
                    });
                });
            }
        }
        if (healthIss.length > 0) {
            let healthIssueList2 = healthIss.map((p, idy) => {

                return <HealthIssueTag
                    key={idy}
                    className='issue-allergy-condition-cells'
                    styles={{}}
                    healthIssue={p}
                />
            });
            return <span className='health-issue-list symptoms-hwalth-issue-lst'>
                {healthIssueList2}
            </span>
        }

    }

    getNotes = () => {
        if (this.props.entry.notes) {
            return <div className="symptom-notes margin-left-for-symptom-group">
                {this.props.entry.notes}
            </div>
        } else return <div className="symptom-notes-dummy margin-left-for-symptom-group"/>
    }

    getGroupContent = () => {
        return <>
            <div className="group-of-symptoms margin-left-for-symptom-group">
                {this.getFormatedSymptoms()}
            </div>
            {this.getNotes()}
        </>
    }

    getTimeOfGroup = () => {
        return <div className="time-of-group hide-on-sm-time-symptoms">
            <span className="medrec-grey-2 ml-2">
                <i className='fa fa-clock'/>
            </span>
            <span className="time-for-symptoms">{CONVERTER.getEntryDateTimeFormat(this.props.entry.date_time)}</span>
        </div>
    }
    getTimeOfGroupForSmall = () => {
        return <span className="time-of-group-sm hide-on-big">
            <span className="medrec-grey-2 ml-2">
                <i className='fa fa-clock'/>
            </span>
            <span className="time-for-symptoms">{CONVERTER.getEntryDateTimeFormat(this.props.entry.date_time)}</span>
        </span>
    }


    compressButton = () => {
        return <div key={Math.random()} className="text-left pb-3">
            <div className="d-flex">
                <div className="expand-compress-logbook">
                    <i className={this.props.allGroup ? "fa fa-chevron-up" : "fa fa-chevron-down"}
                       onClick={() => {
                           this.props.toggleGroup()
                       }}/>
                </div>
                {!this.props.allGroup && <span className="more-text more-text-symptoms pointer" onClick={() => {
                    this.props.toggleGroup()
                }}>+{this.props.groupLength - 1} more</span>}
            </div>
        </div>
    }

    render() {
        let isToday = moment(this.props.entry.date_time).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD");
        return (
            <>
                {this.props.index === 0 &&
                <li className={isToday ? "logbook-li-active" : "logbook-li"}>
                    {this.getTimeOfGroup()}
                    <span className="d-flex flex-space-between" style={{marginTop: "-2.5rem", flexWrap: "wrap"}}>
                            <span className="d-flex" style={{alignItems: "center"}}>
                                <span style={{marginLeft: "5rem"}}
                                      className={isToday ? "logbook-date-active symptoms-date" : "logbook-date symptoms-date"}>
                                    {dateTimeUtils.getFormattedDayWithDate(this.props.entry.date_time)}
                                </span>
                                <DownloadEditDeleteButtons
                                    handleEditClick={this.showSymptomsList}
                                    handleDeleteClick={this.showModal}
                                />
                            </span>
                            <span className="flex-space-between w-100 flx-wrap">
                                {this.getTimeOfGroupForSmall()}
                                {this.getHealthIssueList(this.props.entry)}
                            </span>
                        </span>
                    <div>
                        {this.getGroupContent()}
                    </div>
                    {this.props.groupLength > 1 && this.compressButton()}
                </li>}

                {this.props.allGroup && this.props.index !== 0 && <li className="logbook-li">
                    {this.getTimeOfGroup()}
                    <span className="d-flex flex-space-between" style={{marginTop: "-2.5rem", flexWrap: "wrap"}}>
                            <span className="buttons-symptoms-small" style={{marginLeft: "5rem", marginTop: "0.6rem"}}>
                            <DownloadEditDeleteButtons
                                handleEditClick={this.showSymptomsList}
                                handleDeleteClick={this.showModal}
                            />
                            </span>  
                            <span className="flex-space-between w-100 flx-wrap">
                                {this.getTimeOfGroupForSmall()}
                                {this.getHealthIssueList(this.props.entry)}
                            </span>
                        </span>
                    <div>
                        {this.getGroupContent()}
                    </div>
                </li>}
                <CenteredModal title={$$('mark_symptom_entry_deleted_modal_title')}
                               dialogClassName='doc-delete-modal'
                               show={this.state.showModal}
                               onHide={() => this.setState({showModal: false})}
                               cancelBtnLabel={$$('cancel_btn')}
                               onConfirm={this.onConfirmModal}
                               id="delete-modal-title"
                               className="center-delete-modal"
                               idFooter="footer-delete-modal"
                               confirmBtnLabel={$$('delete_label')}
                               confirmBtnClass="danger"
                               idBtnPrimary="btn-danger"
                               idBtnSecondary="btn-secondary">
                    {$$('mark_symptom_entry_deleted_modal_message')}
                    <div className="bin-align">
                        <i className="fas fa-trash-alt fa-3x"/>
                    </div>
                </CenteredModal>
            </>
        )
    }
}

Symptoms.propTypes = {
    allGroup: PropTypes.bool,
    changeGroup: PropTypes.func,
    changeGroupActive: PropTypes.func,
    entry: PropTypes.object,
    fetchSelectedUserHealthIssues: PropTypes.func,
    groupActive: PropTypes.bool,
    groupLength: PropTypes.number,
    healthIssues: PropTypes.object,
    i18n: PropTypes.object,
    index: PropTypes.number,
    indexOfGroup: PropTypes.number,
    logSelectedUserSymptoms: PropTypes.func,
    nomenclature: PropTypes.object,
    showSymptomsList: PropTypes.func,
    toggleGroup: PropTypes.func,
};

export default connect(null, {logSelectedUserSymptoms})(Symptoms)

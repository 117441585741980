import {fetchHelper} from '../helpers/request_helper';
import {
    CREATE_UPDATE_LAB_RESULT,
    DELETE_LAB_RESULT,
    GET_LAB_RESULTS,
    GET_LAB_RESULTS_DATES,
    GET_LABORATORIES_URL,
    GET_USER_LAB_RESULTS_URL,
    SAVE_LAB_RESULT_DOCUMENT
} from '../constants/api_paths';

export const labResultsService = {
    fetchLaboratories,
    fetchSelectedUserLabResults,
    fetchLabResultsDates,
    fetchLabResults,
    updateLabResults,
    createLabResultsDoc,
    deleteLabResult
};

/**
 * Fetch all the available laboratories
 *
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchLaboratories(params) {
    return fetchHelper.callGet(GET_LABORATORIES_URL, params);
}

/**
 * Fetch the selected user laboratories results
 *
 * @param {string} userId id of the user to fetch lab results for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserLabResults(userId, params) {
    return fetchHelper.callGet(GET_USER_LAB_RESULTS_URL.replace('{userId}', userId), params);
}

/**
 * Fetch laboratory result dates
 * @param {object} params - parameters sent together with the request
 */
export function fetchLabResultsDates(params) {
    return fetchHelper.callGet(GET_LAB_RESULTS_DATES, params);
}

/**
 * Fetch laboratory results
 * @param {object} params - parameters sent together with the request
 */
export function fetchLabResults(params) {
    return fetchHelper.callGet(GET_LAB_RESULTS, params);
}

/**
 * Creates/Updates lab results entries
 * @param {object} params The entry to be created/updated
 */
export function updateLabResults(labResult, params) {
    return fetchHelper.callPut(CREATE_UPDATE_LAB_RESULT, labResult, params)
}

/**
 * Creates lab result document
 * @param {formData} entry The entry to be created
 */
export function createLabResultsDoc(entry) {
    return fetchHelper.callPost(SAVE_LAB_RESULT_DOCUMENT, entry, false);
}

/**
 * Deletes the lab result with the given id.
 *
 * @param {number} labResultId id of the laboratory result to delete
 * @returns {object} promise object
 */
export function deleteLabResult(labResultId) {
    return fetchHelper.callDelete(DELETE_LAB_RESULT.replace('{lab-result-id}', labResultId));
}
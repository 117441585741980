import React, {Component} from "react";
import CenteredModal from '../shared/CenteredModal';
import moment from "moment";
import {$$} from '../../helpers/localization';
import {CONVERTER, getUnitValuefromKey} from '../../utils/converter';
import {Modal} from "react-bootstrap";
import {
    ASLEEP,
    DOSE_NOT_NEEDED,
    EXPENSIVE,
    FORGOT_TO_TAKE,
    MED_NOT_NEAR_ME,
    NONE,
    OTHER,
    OUT_OF_MED,
    SIDE_EFFECTS,
    TOO_BUSY
} from '../../constants/medications_constants';
import {
    createMedicationEvent,
    updateMedicationEntry,
    updateMedicationEvent
} from '../../actions/medications_actions';
import {connect} from 'react-redux';
import {v4 as uuid} from 'uuid'
import PropTypes from "prop-types";


export class TakeMedication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dose: this.props.medicationEntry ? this.props.medicationEntry.dose : this.props.medicationPlan.dose,
            notes: this.getInitialValueForNotes(),
            skipMedicationModal: {
                show: false
            },
            skipReason: NONE,
            otherReasonsModal: {
                show: false
            },
            dateTimePlanned: this.props.dateTimePlanned ? moment(this.props.dateTimePlanned).valueOf() : 0,
            medicationPlan: this.props.medicationPlan ? this.props.medicationPlan : null,
            user_id: this.props.selectedUser ? this.props.selectedUser.id : '',
            showDeleteLogEntryModal: {
                show: false
            },
        }
        this.onSubmit = this.onSubmit.bind(this)
    }


    getInitialValueForNotes = () => {
        if (this.props.medicationEntry && this.props.medicationEntry.notes) {
            return this.props.medicationEntry.notes;
        }

        if (this.props.medicationEvent && this.props.medicationEvent.notes) {
            return this.props.medicationEvent.notes;
        }

        return "";
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.medicationPlan !== this.props.medicationPlan) {
            this.setState({
                dose: this.props.medicationPlan && this.props.medicationPlan.dose,
                notes: this.getInitialValueForNotes(),
                skipMedicationModal: {
                    show: false
                },
                skipReason: NONE,
                otherReasonsModal: {
                    show: false
                },
                dateTimePlanned: this.props.dateTimePlanned ? moment(this.props.dateTimePlanned).valueOf() : 0,
                medicationPlan: this.props.medicationPlan ? this.props.medicationPlan : null,
                user_id: this.props.selectedUser ? this.props.selectedUser.id : '',
            })
        }
    }

    /**
     * This function is called when we click on reset button
     */
    resetMedication = async () => {
        if (this.props.medicationEvent && this.props.medicationEvent.status && this.props.medicationEvent.status === "SKIPPED") {
            let form = {
                ...this.props.medicationEvent,
                status: "UNKNOWN",
                skipReason: "",
                updated_timestamp: moment().valueOf(),
            }
            await this.props.updateMedicationEvent(form, this.props.selectedUser.id);
            this.props.refetchMedicationEvents();
        } else if (this.props.medicationEvent && this.props.medicationEvent.status && this.props.medicationEvent.status === "CONFIRMED") {
            this.setState({
                showDeleteLogEntryModal: {
                    show: true
                },
            })
        }
        this.props.hideTakeMedication();
    }

    /**
     * Here it will be confirmed if we want to delete the log entry along with reseting the medication event (case when it is 'CONFIRMED')
     */
    onDeleteLogEntryConfirmModal = async () => {
        let form = {
            ...this.props.medicationEvent,
            status: "UNKNOWN",
            updated_timestamp: moment().valueOf(),
        }
        await this.props.updateMedicationEvent(form, this.props.selectedUser.id);
        let medEntryForm = {
            ...this.props.medicationEntry,
            deleted: true,
            updated_timestamp: moment().valueOf(),
        }
        await this.props.updateMedicationEntry(medEntryForm, this.props.selectedUser.id);
        this.props.refetchMedicationEvents();
        this.setState({
            showDeleteLogEntryModal: {
                show: false
            },
        })
    }

    /**
     * Sets the reason chosen to be sent in the server
     * @param {*} reason
     */
    setSkipReason = async (reason) => {
        if (reason !== OTHER && reason !== SIDE_EFFECTS) {
            if (this.props.medicationEvent) {

                let form = {
                    ...this.props.medicationEvent,
                    skipReason: reason,
                    status: "SKIPPED",
                    notes: this.state.notes,
                    updated_timestamp: moment().valueOf(),
                }
                await this.props.updateMedicationEvent(form, this.props.selectedUser.id);
                this.props.refetchMedicationEvents();

            } else {

                let form = {
                    created_timestamp: moment().valueOf(),
                    dateTimePlanned: this.state.dateTimePlanned,
                    dateTimeScheduled: 0,
                    id: uuid(),
                    medicationPlanId: this.state.medicationPlan.id,
                    notes: this.state.notes,
                    planName: this.state.medicationPlan.name,
                    skipReason: reason,
                    status: "SKIPPED",
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    updated_timestamp: moment().valueOf(),
                    user_id: this.state.user_id,
                }
                await this.props.createMedicationEvent(form, this.props.selectedUser.id);
                this.props.refetchMedicationEvents();

            }
            this.props.hideTakeMedication();
        } else {
            this.setState({
                otherReasonsModal: {
                    show: true
                },
            })
        }
        this.setState({
            skipReason: reason,
            skipMedicationModal: {
                show: false
            },
        })
    }

    /**
     * Hides the other reason modal and shows the main med intake modal
     */
    hideOtherReasonsModal = () => {
        this.setState({
            otherReasonsModal: {
                show: false
            }
        });
        this.props.showTakeMedication();
    }

    /**
     * We close the other reason modal and also the main med intake modal and send the other reason to the server
     */
    onConfirmOtherReasons = async () => {
        if (this.props.medicationEvent) {
            let form = {
                ...this.props.medicationEvent,
                notes: this.state.notes,
                skipReason: this.state.skipReason,
                status: "SKIPPED",
                updated_timestamp: moment().valueOf(),
            }
            await this.props.updateMedicationEvent(form, this.props.selectedUser.id);
            this.props.refetchMedicationEvents();

        } else {
            let form = {
                created_timestamp: moment().valueOf(),
                dateTimePlanned: this.state.dateTimePlanned,
                dateTimeScheduled: 0,
                id: uuid(),
                medicationPlanId: this.state.medicationPlan.id,
                notes: this.state.notes,
                planName: this.state.medicationPlan.name,
                skipReason: this.state.skipReason,
                status: "SKIPPED",
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                updated_timestamp: moment().valueOf(),
                user_id: this.state.user_id,
            }
            await this.props.createMedicationEvent(form, this.props.selectedUser.id);
            this.props.refetchMedicationEvents();

        }
        this.setState({
            otherReasonsModal: {
                show: false
            },
        });
        this.props.hideTakeMedication();
    }

    /**
     * Opens the modal with the skip reasons for medications and hides the main intake med modal
     */
    showSkipMedication = () => {
        this.setState({
            skipMedicationModal: {
                show: true
            },
        });
        this.props.hideTakeMedication();
    }

    /**
     * Hides the medication modal that has the skip reasons for medications and shows the main med intake modal
     */
    hideSkipMedication = () => {
        this.setState({
            skipMedicationModal: {
                show: false
            },
        });
        this.props.showTakeMedication();
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        if (evt.target) {
            fields[evt.target.name] = evt.target.value;
        } else {
            fields[evt.name] = evt.value;
        }
        this.setState(fields);
    }


    takeMedication = () => {
        let medicationToTake = {
            dateTimePlanned: this.state.dateTimePlanned,
            id: this.state.medicationPlan.id,
            notes: this.state.notes,
            name: this.state.medicationPlan.name,
            dose: this.state.dose,
            unit: this.props.medicationPlan.unit,
            health_issues_ids: this.props.medicationPlan.health_issues_ids,
            selectedEvent: this.props.medicationEvent ? this.props.medicationEvent : null,
            selectedEntry: this.props.medicationEntry ? this.props.medicationEntry : null,
        }
        this.props.takeMedication(medicationToTake);
    }
    rescheduleMedication = () => {
        let medicationToTake = {
            dateTimePlanned: this.state.dateTimePlanned,
            id: this.state.medicationPlan.id,
            notes: this.state.notes,
            name: this.state.medicationPlan.name,
            dose: this.state.dose,
            unit: this.props.medicationPlan.unit,
            health_issues_ids: this.props.medicationPlan.health_issues_ids,
            selectedEvent: this.props.medicationEvent ? this.props.medicationEvent : null,
        }
        this.props.rescheduleMedication(medicationToTake);
    }

    /**
     * This function deletes the med event for the WHEN_NEEDED case
     */
    deleteWhenNeeded = async () => {
        let form = {
            ...this.props.medicationEvent,
            deleted: true,
            updated_timestamp: moment().valueOf(),
        }
        await this.props.updateMedicationEvent(form, this.props.selectedUser.id);
        let medEntryForm = {
            ...this.props.medicationEntry,
            deleted: true,
            updated_timestamp: moment().valueOf(),
        }
        await this.props.updateMedicationEntry(medEntryForm, this.props.selectedUser.id);
        this.props.refetchMedicationEvents();
        this.props.hideTakeMedication();
    }

    /**
     * Form submit handler, validate data and set error in state if any. Call create health issue entry action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();
    }

    getModalHeader = () => {
        return <div className="med-intake-hour-ext">
            <div>{this.props.time ? CONVERTER.getEntryDateTimeFormat(this.props.time)  : CONVERTER.getEntryDateTimeFormat(new Date()) }  </div>
            <div>{this.props.planName}</div>
            {!this.props.whenNeeded && <div className="medication-planned" style={{'color': '#595d6ee8'}}>
                {$$('planned_for_information')}, {moment(this.props.medicationEvent ? this.props.medicationEvent.dateTimePlanned : this.props.dateTimePlanned).format("MMM DD HH:mm")}
            </div>}
        </div>
    }

    render() {
        return (
            <div>
                <form id="documentForm" onSubmit={this.onSubmit}>
                    {this.props.showTakeMed &&
                    <CenteredModal
                        dialogClassName='medication-modal'
                        show={this.props.show}
                        onHide={this.props.onHide}
                        titleClassName="hour-name"
                        bodyClassName="medication-modal-body"
                        primary={true}
                        secondary={true}
                        idFooter="take-med-footer"
                        medicationModal={true}
                        headerClassName="header-medication-form"
                        header={this.getModalHeader()}
                    >
                        {this.props.medicationEvent && this.props.medicationEvent.dateTimeScheduled !== 0 &&
                        <div className="medication-rescheduled-for">
                            {$$('rescheduled_for_label')}, {moment(this.props.medicationEvent.dateTimeScheduled).format("MMM DD HH:mm")}
                        </div>}
                        {this.props.medicationPlan && this.props.medicationPlan.notes && <div className="medication-planned white-space-pre-line" style={{'color': '##02555b'}}>
                            <em>{this.props.medicationPlan.notes}</em>
                        </div>}
                        <div className="take-med-form">
                            <div className="take-med-dose d-flex">
                                {$$('take')} <input className="form-control take-medication-dose-input" type='text'
                                                    name="dose" defaultValue={this.state.dose}
                                                    onChange={this.onInputChange}/>
                                {getUnitValuefromKey(this.props.medicationPlan.unit, this.state.dose === 1 ? this.state.dose : 2)}

                            </div>
                            <label>{$$('notes')}</label>
                            <textarea className="form-control"
                                      value={this.state.notes || ''}
                                      placeholder={$$('notes')}
                                      name="notes"
                                      maxLength="500"
                                      onChange={this.onInputChange}/>
                        </div>
                        <Modal.Footer id="take-med-footer">
                            {!this.props.whenNeeded &&
                            <div onClick={this.showSkipMedication} className="btn btn-danger">
                                {$$('skip_label')}
                            </div>}
                            <div onClick={this.takeMedication} className="btn btn-primary">
                                {$$('take')}
                            </div>
                            {this.props.whenNeeded && this.props.medicationEvent != null &&
                            <div onClick={this.deleteWhenNeeded} className="btn btn-danger">
                                {$$('delete_label')}
                            </div>}
                            {!this.props.whenNeeded && this.props.medicationEvent && this.props.medicationEvent.status && (this.props.medicationEvent.status === "SKIPPED" || this.props.medicationEvent.status === "CONFIRMED") ?
                                <div onClick={this.resetMedication} className="btn btn-secondary">
                                    {$$('reset_btn_label')}
                                </div>
                                : !this.props.whenNeeded &&
                                <div onClick={this.rescheduleMedication} className="btn btn-secondary">
                                    {$$('reschedule_label')}
                                </div>}
                        </Modal.Footer>
                    </CenteredModal>}
                    {this.state.skipMedicationModal.show &&
                    <CenteredModal
                        show={this.state.skipMedicationModal.show}
                        dialogClassName='add-doc-modal'
                        onHide={this.hideSkipMedication}
                        title={$$('skipping_reason')}
                        titleClassName="skip-reason-title"
                        primary={true}
                        cancelBtnLabel={$$('cancel_btn')}
                    >
                        <div style={{marginTop: '1rem'}}>
                            <div className='pointer skip-reasons'
                                 onClick={() => this.setSkipReason(NONE)}>{$$('skip_reason_none')}</div>
                            <div className='pointer skip-reasons'
                                 onClick={() => this.setSkipReason(FORGOT_TO_TAKE)}>{$$('skip_reason_forgot_to_take')}</div>
                            <div className='pointer skip-reasons'
                                 onClick={() => this.setSkipReason(MED_NOT_NEAR_ME)}>{$$('skip_reason_med_not_near_me')}</div>
                            <div className='pointer skip-reasons'
                                 onClick={() => this.setSkipReason(TOO_BUSY)}>{$$('skip_reason_too_busy')}</div>
                            <div className='pointer skip-reasons'
                                 onClick={() => this.setSkipReason(ASLEEP)}>{$$('skip_reason_asleep')}</div>
                            <div className='pointer skip-reasons'
                                 onClick={() => this.setSkipReason(OUT_OF_MED)}>{$$('skip_reason_out_of_med')}</div>
                            <div className='pointer skip-reasons'
                                 onClick={() => this.setSkipReason(DOSE_NOT_NEEDED)}>{$$('skip_reason_dose_not_needed')}</div>
                            <div className='pointer skip-reasons'
                                 onClick={() => this.setSkipReason(EXPENSIVE)}>{$$('skip_reason_expensive')}</div>
                            <div className='pointer skip-reasons'
                                 onClick={() => this.setSkipReason(SIDE_EFFECTS)}>{$$('skip_reason_side_effects')}</div>
                            <div className='pointer skip-reasons'
                                 onClick={() => this.setSkipReason(OTHER)}>{$$('skip_reason_other')}</div>
                        </div>
                    </CenteredModal>}
                    {this.state.otherReasonsModal.show &&
                    <CenteredModal
                        show={this.state.otherReasonsModal.show}
                        onHide={this.hideOtherReasonsModal}
                        dialogClassName='add-doc-modal'
                        onConfirm={this.onConfirmOtherReasons}
                        confirmBtnLabel={$$('OK')}
                        cancelBtnLabel={$$('cancel_btn')}
                        title={$$('reason_notes')}
                        titleClassName="skip-reason-title"
                    >
                        <div>
                                <textarea className="form-control"
                                          value={this.state.notes || ''}
                                          placeholder={$$('notes')}
                                          name="notes"
                                          maxLength="500"
                                          onChange={this.onInputChange}/>
                        </div>
                    </CenteredModal>}
                    {this.state.showDeleteLogEntryModal.show &&
                    <CenteredModal
                        title={$$('reset_medication_label')}
                        body={$$('reset_medication_msg')}
                        dialogClassName='not-doc-delete-modal'
                        show={this.state.showDeleteLogEntryModal.show}
                        onHide={() => this.setState({showDeleteLogEntryModal: {show: false}})}
                        cancelBtnLabel={$$('cancel_btn')}
                        onConfirm={this.onDeleteLogEntryConfirmModal}
                        id="delete-modal-title"
                        className="center-delete-modal"
                        idFooter="footer-delete-modal"
                        confirmBtnLabel={$$('delete_label')}
                        confirmBtnClass="danger"
                        idBtnPrimary="btn-danger"
                        idBtnSecondary="btn-secondary">
                        {this.props.medicationEntry ? this.props.medicationEntry.name : ''}<br/>
                        <div className="bin-align">
                            <i className="fas fa-trash-alt fa-3x"/>
                        </div>
                    </CenteredModal>}
                </form>
            </div>
        )
    }
}

TakeMedication.propTypes = {
    createMedicationEvent: PropTypes.func,
    dateTimePlanned: PropTypes.object,
    hideTakeMedication: PropTypes.func,
    medicationPlan: PropTypes.object,
    onHide: PropTypes.func,
    planName: PropTypes.string,
    refetchMedicationEvents: PropTypes.func,
    rescheduleMedication: PropTypes.func,
    selectedUser: PropTypes.object,
    medicationEntry: PropTypes.any,
    medicationEvent: PropTypes.any,
    show: PropTypes.bool,
    showTakeMed: PropTypes.bool,
    showTakeMedication: PropTypes.func,
    takeMedication: PropTypes.func,
    time: PropTypes.number,
    updateMedicationEntry: PropTypes.func,
    updateMedicationEvent: PropTypes.func,
    whenNeeded: PropTypes.bool
}

const mapDispatchToProps = {
    createMedicationEvent,
    updateMedicationEvent,
    updateMedicationEntry
}

export default connect(null, mapDispatchToProps)(TakeMedication);
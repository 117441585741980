import React, {Component} from 'react'
import DateRangePicker from '../shared/DateRangePicker'
import Select from '../shared/Select'
import {
    CHART_FILTERS,
    getResolvedOptions
} from '../../constants/select_options'
import classnames from 'classnames'
import {$$} from '../../helpers/localization'
import moment from 'moment'
import PropTypes from "prop-types";


export class ChartFilters extends Component {

    state = {
        period: this.props.filters.period,
        showCustomRange: this.props.filters.period === 'CUSTOM'
    }

    constructor(props) {
        super(props);
    }

    /**
     * On custom period change. Set the new state and change the charts filters state.
     *
     * @param {object} - Name of the input and the new value
     */
    onPeriodChanged = ({value}) => {
        this.setState({
            period: value,
            showCustomRange: value === 'CUSTOM'
        }, function () {
            if (this.state.period !== 'CUSTOM') {
                const filters = {
                    period: this.state.period,
                    before_date_time: null,
                    after_date_time: null
                }
                this.props.changeChartsFilters(filters);
            }
        });

    };

    /**
     * Change the charts filters state.
     *
     * @param {object} range - the custom range selected
     */
    onRangeSelected = (range) => {
        if (range.endDate && range.startDate) {
            const filters = {
                period: this.state.period,
                beforeDateTime: moment(range.endDate).valueOf(),
                afterDateTime: moment(range.startDate).valueOf()
            }
            this.props.changeChartsFilters(filters);
        }
    }

    render() {
        const datePickerClasses = classnames({
            "hidden": !this.state.showCustomRange
        });
        return (
            <div className="form-inline" style={{'backgroundColor': 'white', 'paddingTop': '10px'}}>
                <div style={{'display': 'flex', 'flexWrap': 'wrap', 'width': '100%'}}>
                    <h2 style={{'marginRight': 'auto'}}>
                        {$$('Charts')}
                    </h2>
                    <div className="" style={{'display': 'flex', 'flexWrap': 'wrap'}}>
                        <div className="" style={{'display': 'flex', 'marginRight': '2rem'}}>
                            <Select
                                name="period"
                                options={getResolvedOptions(CHART_FILTERS.PERIOD_OPTIONS)}
                                value={this.state.period}
                                onChange={this.onPeriodChanged}
                                style={{'maxWidth': '300px', 'zIndex': '1', 'opacity': '70%'}}/>
                            <div className={datePickerClasses} style={{"position": "relative"}}>
                                <DateRangePicker className={datePickerClasses}
                                                 onRangeSelected={this.onRangeSelected}
                                                 i18n={this.props.i18n}
                                                 startDate={this.props.filters.after_date_time}
                                                 endDate={this.props.filters.before_date_time}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

ChartFilters.propTypes = {
    changeChartsFilters: PropTypes.func,
    filters: PropTypes.object,
    i18n:PropTypes.object,
    selectedUser: PropTypes.object
}

export default ChartFilters

import {fetchHelper} from '../helpers/request_helper';
import {
    ALL_LOGBOOK_ENTIRES_FOR_USER,
    CREATE_UPDATE_LOGBOOK_ENTRY,
    DOWNLOAD_REPORT_URL,
    GET_MENSTRUATION_STATS
} from '../constants/api_paths';

export const logbookService = {
    fetchSelectedUserLogbookEntries,
    createLogbookEntry,
    updateLogbookEntry,
    getMenstruationStats
};

/**
 * Fetch the logbook entries method
 *
 * @param {number} userId id of the user to fetch entries for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserLogbookEntries(userId, params) {
    return fetchHelper.callGet(ALL_LOGBOOK_ENTIRES_FOR_USER.replace('{userId}', userId), params);
}

/**
 * Creates/Updates logbook entry
 * @param {object} params The entry to be created/updated
 */
export function createLogbookEntry(params) {
    return fetchHelper.callPost(CREATE_UPDATE_LOGBOOK_ENTRY, params)
}

/**
 * Creates/Updates logbook entry
 * @param {object} params The entry to be created/updated
 */
export function updateLogbookEntry(params) {
    return fetchHelper.callPut(CREATE_UPDATE_LOGBOOK_ENTRY, params)
}


/**
 * Get Menstruation report statistics
 *
 * @param {number} userId - id of the selected user
 * @param {object} params -
 * */
export function getMenstruationStats(userId, params) {
    return fetchHelper.callGet(GET_MENSTRUATION_STATS.replace('{userId}', userId), params);
}

/**
 * Download Menstruation Report
 *
 * @param lang
 * @param reportData
 * @return {object} promise
 */
export function downloadMenstruationReport(lang, reportData) {
    return fetchHelper.getBlobWithPost(DOWNLOAD_REPORT_URL.replace("{reportType}", "MenstruationReport").replace("{lang}", lang), reportData)
}


import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import CenteredModal from '../shared/CenteredModal';
import moment from 'moment';
import CustomMultiselect from '../shared/CustomMultiSelect'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHamburger, faHeartbeat, faTemperatureLow, faTint, faWeight, faWind} from "@fortawesome/free-solid-svg-icons";
import {ReactComponent as UrinepH} from "../../../public/media/icons/urine.svg";
import {ReactComponent as BloodSugarIcon} from "../../../public/media/icons/blood_sugar.svg";
import {ReactComponent as HeightIcon} from "../../../public/media/icons/height.svg";
import {ReactComponent as KetonesIcon} from "../../../public/media/icons/ketones.svg";
import {ReactComponent as HBA1CIcon} from "../../../public/media/icons/hba1c.svg";
import {ReactComponent as MenstrautionIcon} from '../../../public/media/icons/menstruation.svg'
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import info from '../../resources/images/info.png'
import goback from '../../resources/images/goback_arrow.png'
import {CONVERTER} from '../../utils/converter'
import {HEIGHT, HYDRATION, TEMPERATURE, WEIGHT} from '../../constants/entry_types';
import {getHealthIssueOptions} from '../../constants/select_options'
import TemperatureForm from './entry-forms/TemperatureForm'
import BloodPressureForm from './entry-forms/BloodPressureForm';
import BloodSugarForm from './entry-forms/BloodSugarForm';
import Hba1cForm from './entry-forms/Hba1cForm';
import KetonesForm from './entry-forms/KetonesForm';
import UrinePhForm from './entry-forms/UrinePhForm';
import WeightForm from './entry-forms/WeightForm';
import HeightForm from './entry-forms/HeightForm';
import CholesterolForm from './entry-forms/CholesterolForm';
import OxygenSaturationForm from './entry-forms/OxygenSaturationForm';
import RespiratoryRateForm from './entry-forms/RespiratoryRateForm';
import HydrationForm from './entry-forms/HydrationForm';
import MenstruationForm from './entry-forms/MenstruationForm';
import MedicationForm from './entry-forms/MedicationForm';
import {CHRONIC_CONDITIONS} from "../../actions/actions";
import FormWithLoading from "../shared/FormWithLoading";
import PropTypes from "prop-types";
import bg from 'date-fns/locale/bg';
import sq from 'date-fns/locale/sq';
import enGB from 'date-fns/locale/en-GB';
import PeakFlowForm from "./entry-forms/PeakFlowForm";
import {PeakFlowIcon} from "./PeakFlowIcon";
import {dateTimeUtils} from "../../utils/dateTimeUtils";


export class LogbookForm extends Component {
    state = {
        isLogbookTypeSelected: true,
        showTemperatureForm: false,
        showBloodPressureForm: false,
        showBloodSugarForm: false,
        showHba1cForm: false,
        showKetonesForm: false,
        showUrinePhForm: false,
        showWeightForm: false,
        showHeightForm: false,
        showCholesterolForm: false,
        showOxygenSaturationForm: false,
        showRespiratoryRateForm: false,
        showHydrationForm: false,
        showMenstruationForm: false,
        showMedicationForm: false,
        showPeakFlowForm: false,
        selectedLogbookType: "",
        notes: this.props.selectedEntry && this.props.selectedEntry.notes ? this.props.selectedEntry.notes : '',
        lon: this.props.selectedEntry && this.props.selectedEntry.lon ? this.props.selectedEntry.lon : 0,
        lat: this.props.selectedEntry && this.props.selectedEntry.lat ? this.props.selectedEntry.lat : 0,
        location_timestamp: this.props.selectedEntry && this.props.selectedEntry.location_timestamp ? this.props.selectedEntry.location_timestamp : 0,
        health_issues_ids: this.props.selectedEntry && this.props.selectedEntry.health_issues_ids ? this.props.selectedEntry.health_issues_ids : [],
        selectedHealthIssues: this.props.selectedEntry && this.props.selectedEntry.health_issues_ids ? getHealthIssueOptions(this.props.getHealthIssueList(this.props.selectedEntry)) : [],
        temperature: '',
        name: '',
        unit: '',
        dose: '',
        systolic: '',
        diastolic: '',
        pulse: '',
        measuring_position: '0',
        measuring_site: '0',
        arrhythmia: false,
        blood_sugar: '',
        hba1c: '',
        ketones: '',
        urine_ph: '',
        weight: '',
        height: '',
        total_cholesterol: '',
        ldl: '',
        hdl: '',
        triglycerides: '',
        oxygen_saturation: '',
        respiratory_rate: '',
        hydration: '',
        menstruationFlow: '0',
        menstruationColor: '0',
        menstruationConsistency: '0',
        peak_flow: '',
        peak_flow_symptoms: '',
        formclass: '',
        errors: {},
        descriptionOpened: false,
        selectDisabled: false,
        dateMissing: false,
        timeMissing: false,
        howToDoItIcon: '',
        howToDoItSymptom: '',
        date: this.props.selectedEntry && this.props.selectedEntry.date_time ? this.props.selectedEntry.date_time : moment().valueOf(),
        time: this.props.selectedEntry && this.props.selectedEntry.date_time ? this.props.selectedEntry.date_time : moment().valueOf(),
    }

    constructor(props) {
        super(props);
    }

    getDateTime = () => {
        let dateTime = new Date(this.state.date);
        let t = new Date(this.state.time);
        dateTime.setHours(t.getHours());
        dateTime.setMinutes(t.getMinutes());
        return dateTime.getTime()
    }

    getLocale = () => {
        switch (this.props.i18n.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            case "sq":
                return sq
            default:
                return enGB
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.selectedEntry && prevProps.selectedEntry !== this.props.selectedEntry) {
            this.setState({
                showTemperatureForm: false,
                showBloodPressureForm: false,
                showBloodSugarForm: false,
                showHba1cForm: false,
                showKetonesForm: false,
                showUrinePhForm: false,
                showWeightForm: false,
                showHeightForm: false,
                showCholesterolForm: false,
                showOxygenSaturationForm: false,
                showRespiratoryRateForm: false,
                showHydrationForm: false,
                showMenstruationForm: false,
                showMedicationForm: false,
                showPeakFlowForm: false,
                isLogbookTypeSelected: true,
                notes: this.props.selectedEntry && this.props.selectedEntry.notes ? this.props.selectedEntry.notes : '',
                lon: this.props.selectedEntry && this.props.selectedEntry.lon ? this.props.selectedEntry.lon : 0,
                lat: this.props.selectedEntry && this.props.selectedEntry.lat ? this.props.selectedEntry.lat : 0,
                location_timestamp: this.props.selectedEntry && this.props.selectedEntry.location_timestamp ? this.props.selectedEntry.location_timestamp : 0,
                health_issues_ids: this.props.selectedEntry && this.props.selectedEntry.health_issues_ids ? this.props.selectedEntry.health_issues_ids : [],
                selectedHealthIssues: this.props.selectedEntry && this.props.selectedEntry.health_issues_ids ? getHealthIssueOptions(this.props.getHealthIssueList(this.props.selectedEntry)) : [],
                name: '',
                unit: '',
                dose: '',
                systolic: '',
                diastolic: '',
                pulse: '',
                measuring_position: '0',
                measuring_site: '0',
                arrhythmia: false,
                blood_sugar: '',
                hba1c: '',
                ketones: '',
                urine_ph: '',
                weight: '',
                height: '',
                total_cholesterol: '',
                ldl: '',
                hdl: '',
                triglycerides: '',
                oxygen_saturation: '',
                respiratory_rate: '',
                hydration: '',
                menstruationFlow: '0',
                menstruationColor: '0',
                menstruationConsistency: '0',
                peak_flow: '',
                peak_flow_symptoms: '',
                formclass: '',
                errors: {},
                descriptionOpened: false,
                selectDisabled: true,
                date: this.props.selectedEntry && this.props.selectedEntry.date_time ? this.props.selectedEntry.date_time : moment().valueOf(),
                time: this.props.selectedEntry && this.props.selectedEntry.date_time ? this.props.selectedEntry.date_time : moment().valueOf(),
            });
            switch (this.props.selectedEntry.entry_type) {
                case "TEMPERATURE":
                    this.setState({
                        showTemperatureForm: true,
                        temperature: CONVERTER.convertByUnit(TEMPERATURE, this.props.selectedEntry.temperature)
                    });
                    break;
                case "BLOOD_PRESSURE":
                    this.setState({
                        showBloodPressureForm: true,
                        systolic: this.props.selectedEntry.systolic,
                        diastolic: this.props.selectedEntry.diastolic,
                        pulse: this.props.selectedEntry.pulse,
                        measuring_position: this.props.selectedEntry.measuring_position,
                        measuring_site: this.props.selectedEntry.measuring_site,
                        arrhythmia: this.props.selectedEntry.arrhythmia
                    });
                    break;
                case "BLOOD_SUGAR":
                    this.setState({
                        showBloodSugarForm: true,
                        blood_sugar: CONVERTER.bloodSugarPerUnit(this.props.selectedEntry.blood_sugar)
                    });
                    break;
                case "HBA1C":
                    this.setState({
                        showHba1cForm: true,
                        hba1c: CONVERTER.hba1cPerUnit(this.props.selectedEntry.hba1c)
                    });
                    break;
                case "KETONES":
                    this.setState({
                        showKetonesForm: true,
                        ketones: CONVERTER.ketonesPerUnit(this.props.selectedEntry.ketones)
                    });
                    break;
                case "URINE_PH":
                    this.setState({
                        showUrinePhForm: true,
                        urine_ph: this.props.selectedEntry.urine_ph
                    });
                    break;
                case "WEIGHT":
                    this.setState({
                        showWeightForm: true,
                        weight: CONVERTER.convertByUnit(WEIGHT, this.props.selectedEntry.weight)
                    });
                    break;
                case "HEIGHT":
                    this.setState({
                        showHeightForm: true,
                        height: CONVERTER.convertByUnit(HEIGHT, this.props.selectedEntry.height)
                    });
                    break;
                case "CHOLESTEROL":
                    this.setState({
                        showCholesterolForm: true,
                        total_cholesterol: CONVERTER.cholesterolPerUnit(this.props.selectedEntry.total_cholesterol),
                        ldl: this.props.selectedEntry.ldl,
                        hdl: this.props.selectedEntry.hdl,
                        triglycerides: this.props.selectedEntry.triglycerides
                    });
                    break;
                case "SATURATION":
                    this.setState({
                        showOxygenSaturationForm: true,
                        oxygen_saturation: this.props.selectedEntry.oxygen_saturation
                    });
                    break;
                case "RESPIRATORY_RATE":
                    this.setState({
                        showRespiratoryRateForm: true,
                        respiratory_rate: this.props.selectedEntry.respiratory_rate
                    });
                    break;
                case "HYDRATION":
                    this.setState({
                        showHydrationForm: true,
                        hydration: CONVERTER.convertByUnit(HYDRATION, this.props.selectedEntry.hydration)
                    });
                    break;
                case "MENSTRUATION":
                    this.setState({
                        showMenstruationForm: true,
                        menstruationFlow: this.props.selectedEntry.menstruationFlow,
                        menstruationColor: this.props.selectedEntry.menstruationColor,
                        menstruationConsistency: this.props.selectedEntry.menstruationConsistency
                    });
                    break;
                case "MEDICATION":
                    this.setState({
                        showMedicationForm: true,
                        name: this.props.selectedEntry.name,
                        unit: this.props.selectedEntry.unit,
                        dose: this.props.selectedEntry.dose
                    });
                    break;
                case "PEAK_FLOW":
                    this.setState({
                        showPeakFlowForm: true,
                        peak_flow: this.props.selectedEntry.peak_flow,
                        peak_flow_symptoms: this.props.selectedEntry.peak_flow_symptoms
                    });
                    break;
            }
            this.initIconsAndTitle(this.props.selectedEntry.entry_type);
        }
    }

    onSelect = (selectedItem) => {
        let selectedIssuesArray = this.state.selectedHealthIssues;
        let selectedIssuesArrayIds = this.state.health_issues_ids;
        selectedIssuesArray.push(selectedItem.value);
        selectedIssuesArrayIds.push(selectedItem.value.id);
        this.setState({
            selectedHealthIssues: selectedIssuesArray,
            health_issues_ids: selectedIssuesArrayIds
        })
    }

    onRemove = (removedItem) => {
        let selectedIssuesArray = this.state.selectedHealthIssues;
        let selectedIssuesArrayIds = this.state.health_issues_ids;
        selectedIssuesArray.pop(removedItem);
        selectedIssuesArrayIds.pop(removedItem.id);
        this.setState({
            selectedHealthIssues: selectedIssuesArray,
            health_issues_ids: selectedIssuesArrayIds
        })
    }


    onHideAddUpdateModal = () => {
        this.setState({
            selectedLogbookType: "",
            isLogbookTypeSelected: true,
            showTemperatureForm: false,
            showBloodPressureForm: false,
            showBloodSugarForm: false,
            showHba1cForm: false,
            showKetonesForm: false,
            showUrinePhForm: false,
            showWeightForm: false,
            showHeightForm: false,
            showCholesterolForm: false,
            showOxygenSaturationForm: false,
            showRespiratoryRateForm: false,
            showHydrationForm: false,
            showMenstruationForm: false,
            showMedicationForm: false,
            showPeakFlowForm: false,
            health_issues_ids: [],
            selectedHealthIssues: [],
            notes: '',
            lon: 0,
            lat: 0,
            location_timestamp: 0,
            temperature: '',
            name: '',
            unit: '',
            dose: '',
            systolic: '',
            diastolic: '',
            pulse: '',
            measuring_position: '0',
            measuring_site: '0',
            arrhythmia: false,
            blood_sugar: '',
            hba1c: '',
            ketones: '',
            urine_ph: '',
            weight: '',
            height: '',
            total_cholesterol: '',
            ldl: '',
            hdl: '',
            triglycerides: '',
            oxygen_saturation: '',
            respiratory_rate: '',
            hydration: '',
            menstruationFlow: '0',
            menstruationColor: '0',
            menstruationConsistency: '0',
            peak_flow: '',
            peak_flow_symptoms: '',
            formclass: '',
            errors: {},
            selectDisabled: false,
            date: '',
            time: '',
        });
        this.props.onHide();
    }

    /**
     * Create new entry or update existing entry from list
     */
    onSaveAddUpdateModal = () => {
        if (this.state.selectedLogbookType === "") {
            this.setState({
                isLogbookTypeSelected: false
            });
            return;
        }
        if (this.validate()) {
            let form = {};
            if (this.props.selectedEntry) {
                form = {...this.props.selectedEntry}
            }
            switch (this.state.selectedLogbookType) {
                case "TEMPERATURE":
                    if (this.props.selectedEntry) {
                        form.temperature = CONVERTER.convertToMetric(TEMPERATURE, this.state.temperature);
                    } else {
                        form = {temperature: CONVERTER.convertToMetric(TEMPERATURE, this.state.temperature)};
                    }
                    break;
                case "BLOOD_PRESSURE":
                    if (this.props.selectedEntry) {
                        form.systolic = this.state.systolic;
                        form.diastolic = this.state.diastolic;
                        form.pulse = this.state.pulse;
                        form.measuring_position = this.state.measuring_position;
                        form.arrhythmia = this.state.arrhythmia;
                        form.measuring_site = this.state.measuring_site;
                    } else {
                        form = {
                            systolic: this.state.systolic,
                            diastolic: this.state.diastolic,
                            pulse: this.state.pulse,
                            measuring_position: this.state.measuring_position,
                            measuring_site: this.state.measuring_site,
                            arrhythmia: this.state.arrhythmia
                        };
                    }
                    break;
                case "BLOOD_SUGAR":
                    if (this.props.selectedEntry) {
                        form.blood_sugar = CONVERTER.bloodSugarInDefault(this.state.blood_sugar);
                    } else {
                        form = {blood_sugar: CONVERTER.bloodSugarInDefault(this.state.blood_sugar)};
                    }
                    break;
                case "HBA1C":
                    if (this.props.selectedEntry) {
                        form.hba1c = CONVERTER.hba1cToDefault(this.state.hba1c);
                    } else {
                        form = {hba1c: CONVERTER.hba1cToDefault(this.state.hba1c)};
                    }
                    break;
                case "KETONES":
                    if (this.props.selectedEntry) {
                        form.ketones = CONVERTER.ketonesInDefault(this.state.ketones);
                    } else {
                        form = {ketones: CONVERTER.ketonesInDefault(this.state.ketones)};
                    }
                    break;
                case "URINE_PH":
                    if (this.props.selectedEntry) {
                        form.urine_ph = this.state.urine_ph;
                    } else {
                        form = {urine_ph: this.state.urine_ph};
                    }
                    break;
                case "WEIGHT":
                    if (this.props.selectedEntry) {
                        form.weight = CONVERTER.convertToMetric(WEIGHT, this.state.weight);
                    } else {
                        form = {weight: CONVERTER.convertToMetric(WEIGHT, this.state.weight)};
                    }
                    break;
                case "HEIGHT":
                    if (this.props.selectedEntry) {
                        form.height = CONVERTER.convertToMetric(HEIGHT, this.state.height);
                    } else {
                        form = {height: CONVERTER.convertToMetric(HEIGHT, this.state.height)};
                    }
                    break;
                case "CHOLESTEROL":
                    if (this.props.selectedEntry) {
                        form.total_cholesterol = CONVERTER.cholesterolInDefault(this.state.total_cholesterol);
                        form.ldl = this.state.ldl;
                        form.hdl = this.state.hdl;
                        form.triglycerides = this.state.triglycerides;
                    } else {
                        form = {
                            total_cholesterol: CONVERTER.cholesterolInDefault(this.state.total_cholesterol),
                            ldl: this.state.ldl,
                            hdl: this.state.hdl,
                            triglycerides: this.state.triglycerides
                        };
                    }
                    break;
                case "SATURATION":
                    if (this.props.selectedEntry) {
                        form.oxygen_saturation = this.state.oxygen_saturation;
                    } else {
                        form = {oxygen_saturation: this.state.oxygen_saturation};
                    }
                    break;
                case "RESPIRATORY_RATE":
                    if (this.props.selectedEntry) {
                        form.respiratory_rate = this.state.respiratory_rate;
                    } else {
                        form = {respiratory_rate: this.state.respiratory_rate};
                    }
                    break;
                case "HYDRATION":
                    if (this.props.selectedEntry) {
                        form.hydration = CONVERTER.convertToMetric(HYDRATION, this.state.hydration);
                    } else {
                        form = {hydration: CONVERTER.convertToMetric(HYDRATION, this.state.hydration)};
                    }
                    break;
                case "MENSTRUATION":
                    if (this.props.selectedEntry) {
                        form.menstruationFlow = this.state.menstruationFlow;
                        form.menstruationColor = this.state.menstruationColor;
                        form.menstruationConsistency = this.state.menstruationConsistency;
                    } else {
                        form = {
                            menstruationFlow: this.state.menstruationFlow,
                            menstruationColor: this.state.menstruationColor,
                            menstruationConsistency: this.state.menstruationConsistency
                        };
                    }
                    break;
                case "MEDICATION":
                    if (this.props.selectedEntry) {
                        form.name = this.state.name;
                        form.unit = this.state.unit;
                        form.dose = this.state.dose;
                    } else {
                        form = {name: this.state.name, unit: this.state.unit, dose: this.state.dose};
                    }
                    break;
                case "PEAK_FLOW":
                    let pf = parseInt(this.state.peak_flow);
                    if (this.props.selectedEntry) {
                        form.peak_flow = pf;
                        form.peak_flow_norm = this.props.medicalProfile?.peak_flow_norm > 0 ? this.props.medicalProfile.peak_flow_norm : ""
                        form.peak_flow_symptoms = this.state.peak_flow_symptoms;
                    } else {
                        form = {
                            peak_flow: pf,
                            peak_flow_norm: this.props.medicalProfile?.peak_flow_norm > 0 ? this.props.medicalProfile.peak_flow_norm : "",
                            peak_flow_symptoms: this.state.peak_flow_symptoms
                        };
                    }
            }
            let date_time = this.getDateTime()
            if (!this.props.selectedEntry) {
                form = {
                    ...form,
                    entry_type: this.state.selectedLogbookType,
                    user_id: this.props.selectedUser.id,
                    deleted: false,
                    date_time: date_time,
                    notes: this.state.notes,
                    lon: this.state.lon,
                    lat: this.state.lat,
                    health_issues_ids: this.state.health_issues_ids,
                    location_timestamp: this.state.location_timestamp,
                    created_timestamp: moment().valueOf(),
                    updated_timestamp: moment().valueOf(),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                };
            } else {
                form = {
                    ...form,
                    date_time: date_time,
                    notes: this.state.notes,
                    updated_timestamp: moment().valueOf(),
                    health_issues_ids: this.state.health_issues_ids,
                };
            }
            this.handleCreateOrUpdate(form);
        }

        /* Update the medical profile on height/weight change */

        let isThisTheNewest = true;
        if (this.state.selectedLogbookType === WEIGHT || this.state.selectedLogbookType === HEIGHT) {
            let date_time = this.getDateTime()

            this.props.logbookEntries.map((entry) => {
                if (entry.entry_type === HEIGHT && this.state.selectedLogbookType === HEIGHT) {
                    if (entry.date_time > date_time) {
                        isThisTheNewest = false;
                    }
                } else if (entry.entry_type === WEIGHT && this.state.selectedLogbookType === WEIGHT) {
                    if (entry.date_time > date_time) {
                        isThisTheNewest = false;
                    }
                }

            })
            if (isThisTheNewest && (moment(this.props.medicalProfile.date_modified).valueOf() <= date_time
                || this.state.selectedLogbookType === WEIGHT && this.props.medicalProfile.weight === ""
                || this.state.selectedLogbookType === HEIGHT && this.props.medicalProfile.height === "")) {
                this.updateMedicalProfile();
            }
        }
    }

    /**
     * Handle form creation or update based on the selected entry
     * @param {Object} form - request object to be sent to the server
     */
    handleCreateOrUpdate = (form) => {
        if (this.props.selectedEntry) {
            this.props.updateEntry(form, this.props.selectedUser.id, this.props.type);
        } else {
            this.props.createEntry(form, this.props.selectedUser.id, this.props.type);
        }
        this.onHideAddUpdateModal();
    }


    onSelectChange = (value) => {
        this.setState({
            showTemperatureForm: false,
            showBloodPressureForm: false,
            showBloodSugarForm: false,
            showHba1cForm: false,
            showKetonesForm: false,
            showUrinePhForm: false,
            showWeightForm: false,
            showHeightForm: false,
            showCholesterolForm: false,
            showOxygenSaturationForm: false,
            showRespiratoryRateForm: false,
            showHydrationForm: false,
            showMenstruationForm: false,
            showMedicationForm: false,
            showPeakFlowForm: false,
            isLogbookTypeSelected: true,
            date: moment().valueOf(),
            time: moment().valueOf(),
            notes: '',
            temperature: '',
            name: '',
            unit: '',
            dose: '',
            systolic: '',
            diastolic: '',
            pulse: '',
            measuring_position: '0',
            measuring_site: '0',
            arrhythmia: false,
            blood_sugar: '',
            hba1c: '',
            ketones: '',
            urine_ph: '',
            weight: '',
            height: '',
            total_cholesterol: '',
            ldl: '',
            hdl: '',
            triglycerides: '',
            oxygen_saturation: '',
            respiratory_rate: '',
            hydration: '',
            menstruationFlow: '0',
            menstruationColor: '0',
            menstruationConsistency: '0',
            peak_flow: '',
            peak_flow_symptoms: '',
            formclass: '',
            errors: {},
            descriptionOpened: false
        });
        this.initIconsAndTitle(value);
    }

    initIconsAndTitle = (value) => {
        switch (value) {
            case "TEMPERATURE":
                this.setState({
                    showTemperatureForm: true,
                    selectedLogbookType: "TEMPERATURE",
                    howToDoItIcon: <FontAwesomeIcon icon={faHeartbeat} className="logbook-entry-icon-v2"/>,
                    howToDoItSymptom: $$('temperature_label')
                });
                break;
            case "BLOOD_PRESSURE":
                this.setState({
                    showBloodPressureForm: true,
                    selectedLogbookType: "BLOOD_PRESSURE",
                    howToDoItIcon: <FontAwesomeIcon icon={faHeartbeat} className="logbook-entry-icon-v2"/>,
                    howToDoItSymptom: $$('entry_type_blood_pressure')
                });
                break;
            case "BLOOD_SUGAR":
                this.setState({
                    showBloodSugarForm: true,
                    selectedLogbookType: "BLOOD_SUGAR",
                    howToDoItIcon: <BloodSugarIcon fill="#646c9a" className="logbook-entry-icon-v2"/>,
                    howToDoItSymptom: $$('blood_sugar_label')
                });
                break;
            case "HBA1C":
                this.setState({
                    showHba1cForm: true,
                    selectedLogbookType: "HBA1C",
                    howToDoItIcon: <HBA1CIcon fill="#646c9a" className="logbook-entry-icon-v2"/>,
                    howToDoItSymptom: $$('hba1c')
                });
                break;
            case "KETONES":
                this.setState({
                    showKetonesForm: true,
                    selectedLogbookType: "KETONES",
                    howToDoItIcon: <KetonesIcon fill="#646c9a" className="logbook-entry-icon-v2"/>,
                    howToDoItSymptom: $$('ketones')
                });
                break;
            case "URINE_PH":
                this.setState({
                    showUrinePhForm: true,
                    selectedLogbookType: "URINE_PH",
                    howToDoItIcon:
                        <span className="logbook-entry-icon-v2">
                                <UrinepH className="svg-icon" fill="#646c9a"/></span>,
                    howToDoItSymptom: $$('urine_ph_label')
                });
                break;
            case "WEIGHT":
                this.setState({
                    showWeightForm: true,
                    selectedLogbookType: "WEIGHT",
                    howToDoItIcon: <FontAwesomeIcon icon={faWeight} className="logbook-entry-icon-v2"/>,
                    howToDoItSymptom: $$('weight_label')
                });
                break;
            case "HEIGHT":
                this.setState({
                    showHeightForm: true,
                    selectedLogbookType: "HEIGHT",
                    howToDoItIcon: <HeightIcon fill="#646c9a"/>,
                    howToDoItSymptom: $$('height_label')
                });
                break;
            case "CHOLESTEROL":
                this.setState({
                    showCholesterolForm: true,
                    selectedLogbookType: "CHOLESTEROL",
                    howToDoItIcon: <FontAwesomeIcon icon={faHamburger} className="logbook-entry-icon-v2"/>,
                    howToDoItSymptom: $$('cholesterol')
                });
                break;
            case "SATURATION":
                this.setState({
                    showOxygenSaturationForm: true,
                    selectedLogbookType: "SATURATION",
                    howToDoItIcon: <span
                        className="logbook-entry-icon-o2 logbook-entry-icon-v2"><b>O<sub>2</sub></b></span>,
                    howToDoItSymptom: $$('entry_type_oxygen_saturation')
                });
                break;
            case "RESPIRATORY_RATE":
                this.setState({
                    showRespiratoryRateForm: true,
                    selectedLogbookType: "RESPIRATORY_RATE",
                    howToDoItIcon: <FontAwesomeIcon icon={faWind} className="logbook-entry-icon-v2"/>,
                    howToDoItSymptom: $$('respiratory_rate')
                });
                break;
            case "HYDRATION":
                this.setState({
                    showHydrationForm: true,
                    selectedLogbookType: "HYDRATION",
                    howToDoItIcon: <FontAwesomeIcon icon={faTint} className="logbook-entry-icon-v2"/>,
                    howToDoItSymptom: $$('hydration_label')
                });
                break;
            case "MENSTRUATION":
                this.setState({
                    showMenstruationForm: true,
                    selectedLogbookType: "MENSTRUATION",
                    howToDoItIcon: <MenstrautionIcon fill="#646c9a" className="logbook-entry-icon-v2"/>,
                    howToDoItSymptom: $$('menstruation')
                });
                break;
            case "MEDICATION":
                this.setState({
                    showMedicationForm: true,
                    selectedLogbookType: "MEDICATION",
                    howToDoItIcon: <i className="logbook-entry-icon-v2 flaticon2-contrast"/>,
                    howToDoItSymptom: $$("edit_label")
                });
                break;
            case "PEAK_FLOW":
                this.setState({
                    showPeakFlowForm: true,
                    selectedLogbookType: "PEAK_FLOW",
                    howToDoItIcon: <PeakFlowIcon className="logbook-entry-icon-v2"/>,
                    howToDoItSymptom: $$('peak_flow')
                });
                break;
            case "":
                this.setState({selectedLogbookType: ""});
                break;
        }
    }

    /**
     * Form submit handler, validate data and set error in state if any. Call create logbook entry action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        if (this.state.selectedLogbookType === "") {
            this.setState({
                isLogbookTypeSelected: false
            });
            return;
        }

        let d = new Date(this.getDateTime());

        const errors = {};
        this.setState({errors: ''});

        if (!this.state.date || this.state.dateMissing) {
            errors.date = 'invalid_date_format';
        }

        if (!this.state.time || this.state.timeMissing) {
            errors.time = 'invalid_time_format';
        }

        if (d > moment().valueOf()) {
            errors.datetime = 'datetime_max_value_message';
        }

        switch (this.state.selectedLogbookType) {
            case "TEMPERATURE":
                if (!this.state.temperature) {
                    errors.temperature = 'temperature_required_message';
                } else {
                    if (isNaN(parseFloat(this.state.temperature))) {
                        errors.temperature = 'temperature_wrong_format_message';
                    } else if ((this.props.settings.data.unitsOfMeasurement === "METRIC" &&
                            // eslint-disable-next-line no-undef
                            (this.state.temperature < parseInt(process.env.TEMPERATURE_MIN_METRIC) || this.state.temperature > parseInt(process.env.TEMPERATURE_MAX_METRIC))) ||
                        (this.props.settings.data.unitsOfMeasurement === "US" &&
                            // eslint-disable-next-line no-undef
                            (this.state.temperature < parseInt(process.env.TEMPERATURE_MIN_US) || this.state.temperature > parseInt(process.env.TEMPERATURE_MAX_US)))) {
                        errors.temperature = 'temperature_out_of_range_message';
                    }
                }
                break;
            case "BLOOD_PRESSURE":
                if ((!this.state.systolic && this.state.diastolic) || (this.state.systolic && !this.state.diastolic) || (!this.state.pulse && !this.state.systolic && !this.state.diastolic)) {
                    errors.bloodPressure = 'blood_pressure_required_message';
                } else {
                    if (isNaN(parseFloat(this.state.systolic)) || isNaN(parseFloat(this.state.diastolic)) || (this.state.pulse && isNaN(parseFloat(this.state.pulse)))) {
                        errors.bloodPressure = 'blood_pressure_wrong_format_message';
                        // eslint-disable-next-line no-undef
                    } else if ((this.state.systolic && (this.state.systolic < parseInt(process.env.BLOOD_PRESSURE_SYSTOLIC_MIN) || this.state.systolic > parseInt(process.env.BLOOD_PRESSURE_SYSTOLIC_MAX))) ||
                        // eslint-disable-next-line no-undef
                        (this.state.diastolic && (this.state.diastolic < parseInt(process.env.BLOOD_PRESSURE_DIASTOLIC_MIN) || this.state.diastolic > parseInt(process.env.BLOOD_PRESSURE_DIASTOLIC_MAX)))) {
                        errors.bloodPressure = 'blood_pressure_out_of_range_message';
                    }
                }
                break;
            case "BLOOD_SUGAR":
                if (!this.state.blood_sugar) {
                    errors.blood_sugar = 'blood_sugar_required_message';
                } else {
                    if (isNaN(parseFloat(this.state.blood_sugar))) {
                        errors.blood_sugar = 'blood_sugar_wrong_format_message';
                        // eslint-disable-next-line no-undef
                    } else if ((CONVERTER.bloodSugarInDefault(this.state.blood_sugar) > parseInt(process.env.BLOOD_SUGAR_MAX_METRIC))) {
                        errors.blood_sugar = 'blood_sugar_out_of_range_message';
                    }
                }
                break;
            case "HBA1C":
                if (!this.state.hba1c) {
                    errors.hba1c = 'hba1c_required_message';
                } else {
                    if (isNaN(parseFloat(this.state.hba1c))) {
                        errors.hba1c = 'hba1c_wrong_format_message';
                        // eslint-disable-next-line no-undef
                    } else if ((CONVERTER.hba1cToDefault(this.state.hba1c) > parseInt(process.env.HBA1C_MAX_METRIC))) {
                        errors.hba1c = 'hba1c_out_of_range_message';
                    }
                }
                break;
            case "KETONES":
                if (!this.state.ketones) {
                    errors.ketones = 'ketones_required_message';
                } else {
                    if (isNaN(parseFloat(this.state.ketones))) {
                        errors.ketones = 'ketones_wrong_format_message';
                        // eslint-disable-next-line no-undef
                    } else if (CONVERTER.ketonesInDefault(this.state.ketones) > parseInt(process.env.KETONES_MAX_US)) {
                        errors.ketones = 'ketones_out_of_range_message';
                    }
                }
                break;
            case "URINE_PH":
                if (!this.state.urine_ph) {
                    errors.urine_ph = 'urine_ph_required_message';
                } else {
                    if (isNaN(parseFloat(this.state.urine_ph))) {
                        errors.urine_ph = 'urine_ph_wrong_format_message';
                    } else if (parseFloat(this.state.urine_ph) > 14 || parseFloat(this.state.urine_ph) < 0) {
                        errors.urine_ph = 'urine_ph_out_of_range_message';
                    }
                }
                break;
            case "WEIGHT":
                if (!this.state.weight) {
                    errors.weight = 'weight_required_message';
                } else {
                    if (isNaN(parseFloat(this.state.weight))) {
                        errors.weight = 'weight_wrong_format_message';
                    }
                }
                break;
            case "HEIGHT":
                if (!this.state.height) {
                    errors.height = 'height_required_message';
                } else {
                    if (isNaN(parseFloat(this.state.height))) {
                        errors.height = 'height_wrong_format_message';
                    }
                }
                break;
            case "CHOLESTEROL":
                if ((!this.state.total_cholesterol && !this.state.ldl && !this.state.hdl && !this.state.triglycerides)) {
                    errors.cholesterol = 'cholesterol_required_message';
                }
                if (this.state.total_cholesterol) {
                    if (isNaN(parseFloat(this.state.total_cholesterol))) {
                        errors.total_cholesterol = 'total_cholesterol_wrong_format_message';
                        // eslint-disable-next-line no-undef
                    } else if (CONVERTER.cholesterolInDefault(this.state.total_cholesterol) > parseInt(process.env.CHOLESTEROL_MAX)) {
                        errors.total_cholesterol = 'total_cholesterol_out_of_range_message';
                    }
                }
                if (this.state.ldl) {
                    if (isNaN(parseFloat(this.state.ldl))) {
                        errors.ldl = 'ldl_wrong_format_message';
                        // eslint-disable-next-line no-undef
                    } else if (this.state.ldl > parseInt(process.env.CHOLESTEROL_MAX)) {
                        errors.ldl = 'ldl_out_of_range_message';
                    }
                }
                if (this.state.hdl) {
                    if (isNaN(parseFloat(this.state.hdl))) {
                        errors.hdl = 'hdl_wrong_format_message';
                        // eslint-disable-next-line no-undef
                    } else if (this.state.hdl > parseInt(process.env.CHOLESTEROL_MAX)) {
                        errors.hdl = 'hdl_out_of_range_message';
                    }
                }
                if (this.state.triglycerides) {
                    if (isNaN(parseFloat(this.state.triglycerides))) {
                        errors.triglycerides = 'triglycerides_wrong_format_message';
                        // eslint-disable-next-line no-undef
                    } else if (this.state.triglycerides > parseInt(process.env.CHOLESTEROL_MAX)) {
                        errors.triglycerides = 'triglycerides_out_of_range_message';
                    }
                }
                break;
            case "SATURATION":
                if (!this.state.oxygen_saturation) {
                    errors.oxygen_saturation = 'oxygen_saturation_required_message';
                } else {
                    if (isNaN(parseFloat(this.state.oxygen_saturation))) {
                        errors.oxygen_saturation = 'oxygen_saturation_wrong_format_message';
                        // eslint-disable-next-line no-undef
                    } else if (this.state.oxygen_saturation < parseInt(process.env.OXYGEN_SATURATION_MIN) || this.state.oxygen_saturation > parseInt(process.env.OXYGEN_SATURATION_MAX)) {
                        errors.oxygen_saturation = 'oxygen_saturation_out_of_range_message';
                    }
                }
                break;
            case "RESPIRATORY_RATE":
                if (!this.state.respiratory_rate) {
                    errors.respiratory_rate = 'respiratory_rate_required_message';
                } else {
                    if (isNaN(parseFloat(this.state.respiratory_rate))) {
                        errors.respiratory_rate = 'respiratory_rate_wrong_format_message';
                        // eslint-disable-next-line no-undef
                    } else if (this.state.respiratory_rate < parseInt(process.env.RESPIRATORY_RATE_MIN) || this.state.respiratory_rate > parseInt(process.env.RESPIRATORY_RATE_MAX)) {
                        errors.respiratory_rate = 'respiratory_rate_out_of_range_message';
                    }
                }
                break;
            case "HYDRATION":
                if (!this.state.hydration) {
                    errors.hydration = 'hydration_required_message';
                } else {
                    if (isNaN(parseFloat(this.state.hydration))) {
                        errors.hydration = 'hydration_wrong_format_message';
                        // eslint-disable-next-line no-undef
                    } else if (this.state.hydration > parseInt(process.env.HYDRATION_MAX)) {
                        errors.hydration = 'hydration_out_of_range_message';
                    }
                }
                break;
            case "MEDICATION":
                if ((!this.state.name)) {
                    errors.name = 'medication_name_required_message';
                }
                if (!this.state.unit) {
                    errors.unit = 'unit_required_message';
                }
                if (!this.state.dose) {
                    errors.dose = 'dose_required_message';
                } else {
                    if (isNaN(parseFloat(this.state.dose))) {
                        errors.dose = 'dose_wrong_format_message';
                        // eslint-disable-next-line no-undef
                    } else if (this.state.dose <= parseInt(process.env.DOSE_MIN)) {
                        errors.dose = 'dose_out_of_range_message';
                    }
                }
                break;
            case "PEAK_FLOW":
                if (!this.state.peak_flow) {
                    errors.peak_flow = 'peak_flow_required_message';
                } else {
                    if (isNaN(parseInt(this.state.peak_flow)) || parseInt(this.state.peak_flow) != this.state.peak_flow) {
                        errors.peak_flow = 'peak_flow_wrong_format_message';
                        // eslint-disable-next-line no-undef
                    } else if (this.state.peak_flow < parseInt(process.env.PEAK_FLOW_MIN) || this.state.peak_flow > parseInt(process.env.PEAK_FLOW_MAX)) {
                        errors.peak_flow = 'peak_flow_out_of_range_message';
                    }
                }
                break;
        }
        if (this.state.dateTimeMissing) {
            errors.datetime = 'datetime_required_message';
        }
        this.setState({errors});
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }

        if (this.state.dateTimeMissing) {
            return false;
        }

        return Object.keys(errors).length === 0;

    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        if (evt.target) {
            fields[evt.target.name] = evt.target.value;
        } else {
            fields[evt.name] = evt.value;
        }
        this.setState(fields);
    }


    /**
     * Set the state to the latest selected date.
     *
     * @param {object} date - The event handler argument
     */
    onDateChange = (date) => {
        if (date instanceof Date && !isNaN(date)) {
            const fields = Object.assign({}, this.state);
            let d = new Date(date);
            let t = new Date(this.state.time);
            d.setHours(t.getHours());
            d.setMinutes(t.getMinutes());
            fields["date"] = moment(d).valueOf();
            this.setState(fields);

            this.setState({
                errors: {datetime: null, date: null},
                dateTimeMissing: false,
                dateMissing: false
            });

        } else {
            this.setState({
                errors: {date: 'invalid_date_format'},
                dateTimeMissing: true,
                dateMissing: true
            });
        }
    };

    /**
     * Set the state to the latest selected date.
     *
     * @param {object} time - The event handler argument
     */
    onTimeChange = (time) => {
        if (time instanceof Date && !isNaN(time)) {
            const fields = Object.assign({}, this.state);
            let d = new Date(this.state.date);
            d.setHours(time.getHours());
            d.setMinutes(time.getMinutes(), 0, 0);
            fields["time"] = moment(d).valueOf();
            this.setState(fields);
            this.setState({
                errors: {datetime: null, time: null},
                dateTimeMissing: false,
                timeMissing: false

            });


        } else {
            this.setState({
                errors: {time: 'invalid_time_format'},
                dateTimeMissing: true,
                timeMissing: true
            });
        }
    };


    /**
     * Set the state to the latest change in the checkbox value.
     */
    toggleCheckboxChange = () => {
        this.setState(({arrhythmia}) => (
            {
                arrhythmia: !arrhythmia,
            }
        ));
    }

    toggleDescriptionOpened = () => {
        this.setState(({descriptionOpened}) => (
            {
                descriptionOpened: !descriptionOpened
            }
        ))
    }

    updateMedicalProfile = () => {
        let form = {};
        switch (this.state.selectedLogbookType) {
            case "WEIGHT":
                form = {
                    ...this.props.medicalProfile,
                    weight: CONVERTER.convertToMetric(WEIGHT, this.state.weight),
                    date_modified: dateTimeUtils.toOffsetDateTime(this.getDateTime())
                }
                break;
            case "HEIGHT":
                form = {
                    ...this.props.medicalProfile,
                    height: CONVERTER.convertToMetric(HEIGHT, this.state.height),
                    date_modified: dateTimeUtils.toOffsetDateTime(this.getDateTime())
                }
                break;

        }
        this.props.updateUserMedicalProfile(this.props.selectedUser.id, form);
    }

    hasHowToDoIt = () => {
        return !this.state.showCholesterolForm && !this.state.showHba1cForm && !this.state.showMedicationForm;
    }

    render() {

        let howToDoItTitle = <div className="row">
            <img
                style={{
                    "marginLeft": "1.8rem",
                    "marginRight": "2rem",
                    "marginTop": "0.7rem",
                    "height": "2rem",
                    "cursor": "pointer"
                }}
                src={goback}
                onClick={this.toggleDescriptionOpened} alt={"Go Back"}/>
            <div>{$$('logentry_instructions_title') + "?"}</div>
        </div>

        let errorMsg = '';
        if (!this.state.isLogbookTypeSelected) {
            errorMsg = (
                <div className="alert alert-danger">
                    {$$('select_entry_type_error')}
                </div>
            );
        }
        let title = !this.props.selectedEntry && this.state.selectedLogbookType === "" ? $$("add_entry") : <div>
            <div className="row">
                <div
                    className="logbook-entry-logo pointer"
                    style={{"marginTop": "1rem", "marginLeft": "1rem"}}
                >
                    {this.state.howToDoItIcon}
                </div>
                <div
                    className="add-condition-text"
                    style={{"marginTop": "0.3rem", "marginLeft": "6rem"}}>
                    {this.state.howToDoItSymptom}
                </div>
                {this.hasHowToDoIt() && <a
                    className="nav-link active"
                    style={{"marginTop": "0.5rem"}}
                    onClick={() => {
                        this.setState({descriptionOpened: !this.state.descriptionOpened})
                    }}
                >
                    <img src={info} alt={"Info"}/>
                </a>}
            </div>
        </div>


        return (
            <div>
                {
                    !this.state.descriptionOpened ?
                        <CenteredModal title={title}
                                       show={this.props.show}
                                       onHide={this.onHideAddUpdateModal}
                                       onConfirm={this.onSaveAddUpdateModal}
                                       confirmBtnLabel={$$('save_btn_label')}
                        >
                            <FormWithLoading
                                formDisabled={this.props.formDisabled}
                                currentForm={CHRONIC_CONDITIONS}
                                marginTop="30%"
                                marginLeft="calc(50% - 70px)"
                                id="logbookForm"
                                onSubmit={this.onSubmit}
                                className={this.state.formclass}
                                noValidate={true}
                                style={{"marginTop": "2rem"}}
                            >
                                {errorMsg}
                                {!this.props.selectedEntry && this.state.selectedLogbookType === "" &&
                                    <div className="form-group">
                                        <div className="logbook-form-type-container">
                                            <div className="logbook-entry-type" onClick={() => {
                                                this.onSelectChange("BLOOD_PRESSURE")
                                            }}>
                                                <div className="logbook-entry-logo-new  pointer">
                                                    <FontAwesomeIcon icon={faHeartbeat}
                                                                     className="logbook-entry-icon-v2"/>
                                                </div>
                                                <p className="add-condition-text">{$$('entry_type_blood_pressure')}</p>
                                            </div>
                                            <div className="logbook-entry-type" onClick={() => {
                                                this.onSelectChange("URINE_PH")
                                            }}>
                                                <div className="logbook-entry-logo-new pointer">
                                                    <span className="logbook-entry-icon-v2">
                                                        <UrinepH className="svg-icon" fill="#646c9a"/></span>
                                                </div>
                                                <p className="add-condition-text">{$$('urine_ph_label')}</p>
                                            </div>
                                            <div className="logbook-entry-type" onClick={() => {
                                                this.onSelectChange("TEMPERATURE")
                                            }}>
                                                <div className="logbook-entry-logo-new pointer">
                                                    <FontAwesomeIcon icon={faTemperatureLow}
                                                                     className="logbook-entry-icon-v2"/>
                                                </div>
                                                <p className="add-condition-text">{$$('temperature_label')}</p>
                                            </div>
                                            <div className="logbook-entry-type" onClick={() => {
                                                this.onSelectChange("BLOOD_SUGAR")
                                            }}>
                                                <div className="logbook-entry-logo-new pointer">
                                                    <BloodSugarIcon fill="#646c9a" className="logbook-entry-icon-v2"/>
                                                </div>
                                                <p className="add-condition-text">{$$('blood_sugar_label')}</p>
                                            </div>
                                            <div className="logbook-entry-type" onClick={() => {
                                                this.onSelectChange("WEIGHT")
                                            }}>
                                                <div className="logbook-entry-logo-new pointer">
                                                    <FontAwesomeIcon icon={faWeight} className="logbook-entry-icon-v2"/>
                                                </div>
                                                <p className="add-condition-text">{$$('weight_label')}</p>
                                            </div>
                                            <div className="logbook-entry-type" onClick={() => {
                                                this.onSelectChange("HEIGHT")
                                            }}>
                                                <div className="logbook-entry-logo-new pointer">
                                                    <HeightIcon fill="#646c9a" className="height-icon"/>
                                                </div>
                                                <p className="add-condition-text">{$$('height_label')}</p>
                                            </div>
                                            <div className="logbook-entry-type" onClick={() => {
                                                this.onSelectChange("HYDRATION")
                                            }}>
                                                <div className="logbook-entry-logo-new pointer">
                                                    <FontAwesomeIcon icon={faTint} className="logbook-entry-icon-v2"/>
                                                </div>
                                                <p className="add-condition-text">{$$('hydration_label')}</p>
                                            </div>
                                            <div className="logbook-entry-type" onClick={() => {
                                                this.onSelectChange("KETONES")
                                            }}>
                                                <div className="logbook-entry-logo-new pointer">
                                                    <KetonesIcon fill="#646c9a" className="logbook-entry-icon-v2"/>
                                                </div>
                                                <p className="add-condition-text">{$$('ketones')}</p>
                                            </div>
                                            <div className="logbook-entry-type" onClick={() => {
                                                this.onSelectChange("CHOLESTEROL")
                                            }}>
                                                <div className="logbook-entry-logo-new pointer">
                                                    <FontAwesomeIcon icon={faHamburger}
                                                                     className="logbook-entry-icon-v2"/>
                                                </div>
                                                <p className="add-condition-text">{$$('cholesterol')}</p>
                                            </div>
                                            <div className="logbook-entry-type" onClick={() => {
                                                this.onSelectChange("RESPIRATORY_RATE")
                                            }}>
                                                <div className="logbook-entry-logo-new pointer">
                                                    <FontAwesomeIcon icon={faWind} className="logbook-entry-icon-v2"/>
                                                </div>
                                                <p className="add-condition-text">{$$('respiratory_rate')}</p>
                                            </div>
                                            <div className="logbook-entry-type" onClick={() => {
                                                this.onSelectChange("SATURATION")
                                            }}>
                                                <div className="logbook-entry-logo-new pointer">
                                                    <span
                                                        className="logbook-entry-icon-o2 logbook-entry-icon-v2 "><b>O<sub>2</sub></b></span>
                                                </div>
                                                <p className="add-condition-text">{$$('entry_type_oxygen_saturation')}</p>
                                            </div>
                                            <div className="logbook-entry-type" onClick={() => {
                                                this.onSelectChange("HBA1C")
                                            }}>
                                                <div className="logbook-entry-logo-new pointer">
                                                    <HBA1CIcon fill="#646c9a" className="logbook-entry-icon-v2"/>
                                                </div>
                                                <p className="add-condition-text">{$$('hba1c')}</p>
                                            </div>
                                            {this.props.selectedUser.gender !== "MALE" &&
                                                <div className="logbook-entry-type" onClick={() => {
                                                    this.onSelectChange("MENSTRUATION")
                                                }}>
                                                    <div className="logbook-entry-logo-new pointer">
                                                        <MenstrautionIcon fill="#646c9a"
                                                                          className="logbook-entry-icon-v2"/>
                                                    </div>
                                                    <p className="add-condition-text">{$$('menstruation')}</p>
                                                </div>}
                                            <div className="logbook-entry-type" onClick={() => {
                                                this.onSelectChange("PEAK_FLOW")
                                            }}>
                                                <div className="logbook-entry-logo-new pointer">
                                                    <PeakFlowIcon className="logbook-entry-icon-v2"/>
                                                </div>
                                                <p className="add-condition-text">{$$('peak_flow')}</p>
                                            </div>
                                        </div>
                                    </div>}
                                {this.state.selectedLogbookType !== "" &&
                                    <div className="form-group row">
                                        <div className="col-sm-6">
                                            <MuiPickersUtilsProvider locale={this.getLocale()}
                                                                     utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    variant="inline"
                                                    format={this.props.settings.data.dateFormat}
                                                    margin="normal"
                                                    id="date-picker-inline"
                                                    label={$$("log_symptoms_date_label")}
                                                    value={this.state.date}
                                                    onChange={this.onDateChange}
                                                    KeyboardButtonProps={{'aria-label': 'change date',}}
                                                    required
                                                />
                                            </MuiPickersUtilsProvider>
                                            <div
                                                className={this.state.errors.date ? 'custom-invalid-feedback' : 'invalid-feedback'}
                                                style={{'textAlignLast': 'center'}}>
                                                {this.state.errors.date ? $$(this.state.errors.date) : $$('log_symptoms_form_date_time_required_message')}
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <MuiPickersUtilsProvider locale={this.getLocale()}
                                                                     utils={DateFnsUtils}>
                                                <KeyboardTimePicker
                                                    ampm={!this.props.settings.data.time24hour}
                                                    margin="normal"
                                                    okLabel={$$("OK")}
                                                    cancelLabel={$$("cancel_btn")}
                                                    id="time-picker"
                                                    label={$$("log_symptoms_time_label")}
                                                    value={this.state.time}
                                                    onChange={this.onTimeChange}
                                                    KeyboardButtonProps={{'aria-label': 'change time',}}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <div
                                                className={this.state.errors.time ? 'custom-invalid-feedback' : 'invalid-feedback'}
                                                style={{'textAlignLast': 'center'}}>
                                                {this.state.errors.time ? $$(this.state.errors.time) : $$('log_symptoms_form_date_time_required_message')}
                                            </div>
                                        </div>

                                        <div
                                            className={this.state.errors.datetime ? 'custom-invalid-feedback' : 'invalid-feedback'}
                                            style={{'textAlignLast': 'center'}}>
                                            {this.state.errors.datetime ? $$(this.state.errors.datetime) : $$('log_symptoms_form_date_time_required_message')}
                                        </div>
                                    </div>}
                                {this.state.showTemperatureForm &&
                                    <TemperatureForm temperature={this.state.temperature}
                                                     errors={this.state.errors}
                                                     onInputChange={this.onInputChange}
                                    />}
                                {this.state.showBloodPressureForm &&
                                    <BloodPressureForm systolic={this.state.systolic}
                                                       errors={this.state.errors}
                                                       onInputChange={this.onInputChange}
                                                       diastolic={this.state.diastolic}
                                                       pulse={this.state.pulse}
                                                       measuring_position={this.state.measuring_position}
                                                       measuring_site={this.state.measuring_site}
                                                       arrhythmia={this.state.arrhythmia}
                                                       toggleCheckboxChange={this.toggleCheckboxChange}
                                    />}
                                {this.state.showBloodSugarForm &&
                                    <BloodSugarForm blood_sugar={this.state.blood_sugar}
                                                    errors={this.state.errors}
                                                    onInputChange={this.onInputChange}
                                    />}
                                {this.state.showHba1cForm &&
                                    <Hba1cForm hba1c={this.state.hba1c}
                                               errors={this.state.errors}
                                               onInputChange={this.onInputChange}
                                    />}
                                {this.state.showKetonesForm &&
                                    <KetonesForm ketones={this.state.ketones}
                                                 errors={this.state.errors}
                                                 onInputChange={this.onInputChange}
                                    />}
                                {this.state.showUrinePhForm &&
                                    <UrinePhForm urine_ph={this.state.urine_ph}
                                                 errors={this.state.errors}
                                                 onInputChange={this.onInputChange}
                                    />}
                                {this.state.showWeightForm &&
                                    <WeightForm weight={this.state.weight}
                                                errors={this.state.errors}
                                                onInputChange={this.onInputChange}
                                    />}
                                {this.state.showHeightForm &&
                                    <HeightForm height={this.state.height}
                                                errors={this.state.errors}
                                                onInputChange={this.onInputChange}
                                    />}
                                {this.state.showCholesterolForm &&
                                    <CholesterolForm total_cholesterol={this.state.total_cholesterol}
                                                     errors={this.state.errors}
                                                     onInputChange={this.onInputChange}
                                                     ldl={this.state.ldl}
                                                     hdl={this.state.hdl}
                                                     triglycerides={this.state.triglycerides}
                                                     cholesterol={this.state.cholesterol}
                                    />}
                                {this.state.showOxygenSaturationForm &&
                                    <OxygenSaturationForm oxygen_saturation={this.state.oxygen_saturation}
                                                          errors={this.state.errors}
                                                          onInputChange={this.onInputChange}
                                    />}
                                {this.state.showRespiratoryRateForm &&
                                    <RespiratoryRateForm respiratory_rate={this.state.respiratory_rate}
                                                         errors={this.state.errors}
                                                         onInputChange={this.onInputChange}
                                    />}
                                {this.state.showHydrationForm &&
                                    <HydrationForm hydration={this.state.hydration}
                                                   errors={this.state.errors}
                                                   onInputChange={this.onInputChange}
                                    />}
                                {this.state.showMenstruationForm &&
                                    <MenstruationForm menstruationFlow={this.state.menstruationFlow}
                                                      onInputChange={this.onInputChange}
                                                      menstruationColor={this.state.menstruationColor}
                                                      menstruationConsistency={this.state.menstruationConsistency}
                                    />}
                                {this.state.showMedicationForm &&
                                    <MedicationForm errors={this.state.errors}
                                                    name={this.state.name}
                                                    formDisabled={this.props.formDisabled}
                                                    onInputChange={this.onInputChange}
                                                    unit={this.state.unit}
                                                    dose={this.state.dose}
                                    />}
                                {this.state.showPeakFlowForm &&
                                    <PeakFlowForm errors={this.state.errors}
                                                  formDisabled={this.props.formDisabled}
                                                  onInputChange={this.onInputChange}
                                                  peak_flow={this.state.peak_flow}
                                                  peak_flow_symptoms={this.state.peak_flow_symptoms}
                                    />}
                                {this.state.selectedLogbookType !== "" &&
                                    <div className="form-group" style={{'marginBottom': '50px'}}>
                                        {this.state.notes ? $$('log_symptoms_notes_label') : null}<br/>
                                        <textarea className="form-control" value={this.state.notes}
                                                  placeholder={$$('log_symptoms_notes_label')} name="notes"
                                                  maxLength="500"
                                                  onChange={this.onInputChange}
                                                  style={{'marginBottom': '30px'}}/>
                                        <div className="invalid-feedback">
                                            {$$('notes_required_message')}
                                        </div>
                                        <CustomMultiselect
                                            options={getHealthIssueOptions(this.props.healthIssues.entries)}
                                            selectedValues={this.state.selectedHealthIssues}
                                            onSelect={this.onSelect}
                                            onRemove={this.onRemove}
                                            displayValue="text"
                                            placeholder={$$('select_health_issue_label')}
                                            closeIcon='cancel'
                                            avoidHighlightFirstOption={true}
                                            isFieldValid={this.state.validLanguagesField}
                                            formClass={this.state.formclass}/>
                                    </div>
                                }
                            </FormWithLoading>
                        </CenteredModal>
                        :
                        <CenteredModal
                            title={howToDoItTitle}
                            show={this.state.descriptionOpened}
                            onHide={this.toggleDescriptionOpened}
                            confirmBtnClass={" "}
                            confirmBtnLabel={" "}
                        >
                            <div className="card-body" style={{"marginLeft": "-1.2rem", "height": "425px"}}>
                                {this.state.showBloodPressureForm &&
                                    <div style={{"height": "425px"}}>
                                        <ul>
                                            <li>{$$('logentry_blood_pressure_instructions1')}</li>
                                            <li>{$$('logentry_blood_pressure_instructions2')}</li>
                                            <li>{$$('logentry_blood_pressure_instructions3')}</li>
                                            <li>{$$('logentry_blood_pressure_instructions4')}</li>
                                            <li>{$$('logentry_blood_pressure_instructions5')}</li>
                                            <li>{$$('logentry_blood_pressure_instructions6')}</li>
                                        </ul>
                                    </div>
                                }
                                {this.state.showRespiratoryRateForm &&
                                    <div style={{"height": "425px"}}>
                                        <ul>
                                            <li>{$$('logentry_respiratory_rate_instructions0')}</li>
                                            <li>{$$('logentry_respiratory_rate_instructions1')}</li>
                                            <li>{$$('logentry_respiratory_rate_instructions2')}</li>
                                            <li>{$$('logentry_respiratory_rate_instructions3')}</li>
                                        </ul>
                                    </div>
                                }
                                {this.state.showOxygenSaturationForm &&
                                    <div style={{"height": "425px"}}>
                                        <ul>
                                            <li>{$$('logentry_oxygen_saturation_instructions0')}</li>
                                            <li>{$$('logentry_oxygen_saturation_instructions1')}</li>
                                            <li>{$$('logentry_oxygen_saturation_instructions2')}</li>
                                            <li>{$$('logentry_oxygen_saturation_instructions3')}</li>
                                        </ul>
                                    </div>
                                }
                                {this.state.showTemperatureForm &&
                                    <div style={{"height": "425px"}}>
                                        <h6>{$$('logentry_temperature_instructions_title1')}</h6>
                                        <ul>
                                            <li>{$$('logentry_temperature_instructions11')}</li>
                                            <li>{$$('logentry_temperature_instructions12')}</li>
                                            <li>{$$('logentry_temperature_instructions13')}</li>
                                            <li>{$$('logentry_temperature_instructions14')}</li>
                                            <li>{$$('logentry_temperature_instructions15')}</li>
                                            <li>{$$('logentry_temperature_instructions16')}</li>
                                            <li>{$$('logentry_temperature_instructions17')}</li>
                                            <li>{$$('logentry_temperature_instructions18')}</li>
                                        </ul>
                                        <h6>{$$('logentry_temperature_instructions_title2')}</h6>
                                        <ul>
                                            <li>{$$('logentry_temperature_instructions21')}</li>
                                            <li>{$$('logentry_temperature_instructions22')}</li>
                                            <li>{$$('logentry_temperature_instructions23')}</li>
                                            <li>{$$('logentry_temperature_instructions24')}</li>
                                            <li>{$$('logentry_temperature_instructions25')}</li>
                                        </ul>
                                        <h6>{$$('logentry_temperature_instructions_title3')}</h6>
                                        <ul>
                                            <li>{$$('logentry_temperature_instructions31')}</li>
                                            <li>{$$('logentry_temperature_instructions32')}</li>
                                            <li>{$$('logentry_temperature_instructions33')}</li>
                                            <li>{$$('logentry_temperature_instructions34')}</li>
                                            <li>{$$('logentry_temperature_instructions35')}</li>
                                        </ul>
                                        <p>{$$('logentry_temperature_instructions_note26')}</p>
                                    </div>
                                }
                                {this.state.showHydrationForm &&
                                    <div style={{"height": "425px"}}>
                                        <h5>{$$("logentry_hydration_instructions_title")}</h5>
                                        <h6>{$$('logentry_hydration_instructions_title1')}</h6>
                                        <ul>
                                            <li>{$$('logentry_hydration_instructions11')}</li>
                                            <li>{$$('logentry_hydration_instructions12')}</li>
                                        </ul>
                                        <h6>{$$('logentry_hydration_instructions_title2')}</h6>
                                        <ul>
                                            <li>{$$('logentry_hydration_instructions21')}</li>
                                            <li>{$$('logentry_hydration_instructions22')}</li>
                                            <li>{$$('logentry_hydration_instructions23')}</li>
                                        </ul>
                                        <h6>{$$('logentry_hydration_instructions_title3')}</h6>
                                        <ul>
                                            <li>{$$('logentry_hydration_instructions31')}</li>
                                            <li>{$$('logentry_hydration_instructions32')}</li>
                                            <li>{$$('logentry_hydration_instructions33')}</li>
                                            <li>{$$('logentry_hydration_instructions34')}</li>
                                        </ul>
                                    </div>
                                }
                                {this.state.showWeightForm &&
                                    <div style={{"height": "425px"}}>
                                        <ul>
                                            <li>{$$('logentry_weight_instructions0')}</li>
                                            <li>{$$('logentry_weight_instructions1')}</li>
                                            <li>{$$('logentry_weight_instructions2')}</li>
                                            <li>{$$('logentry_weight_instructions3')}</li>
                                        </ul>
                                    </div>
                                }
                                {this.state.showHeightForm &&
                                    <div style={{"height": "425px"}}>
                                        <ul>
                                            <li>{$$('logentry_height_instructions0')}</li>
                                            <li>{$$('logentry_height_instructions1')}</li>
                                            <li>{$$('logentry_height_instructions2')}</li>
                                            <li>{$$('logentry_height_instructions3')}</li>
                                            <li>{$$('logentry_height_instructions4')}</li>
                                            <li>{$$('logentry_height_instructions5')}</li>
                                            <li>{$$('logentry_height_instructions6')}</li>
                                            <li>{$$('logentry_height_instructions7')}</li>
                                            <li>{$$('logentry_height_instructions8')}</li>
                                        </ul>
                                    </div>
                                }
                                {this.state.showBloodSugarForm &&
                                    <div style={{"height": "425px"}}>
                                        <ul>
                                            <li>{$$('logentry_glucose_instructions0')}</li>
                                            <li>{$$('logentry_glucose_instructions1')}</li>
                                            <li>{$$('logentry_glucose_instructions2')}</li>
                                            <li>{$$('logentry_glucose_instructions3')}</li>
                                            <li>{$$('logentry_glucose_instructions4')}</li>
                                            <li>{$$('logentry_glucose_instructions5')}</li>
                                        </ul>
                                    </div>
                                }
                                {this.state.showKetonesForm &&
                                    <div style={{"height": "425px"}}>
                                        <ul>
                                            <li>{$$('logentry_ketones_instructions0')}</li>
                                            <li>{$$('logentry_ketones_instructions1')}</li>
                                            <li>{$$('logentry_ketones_instructions2')}</li>
                                            <li>{$$('logentry_ketones_instructions3')}</li>
                                            <li>{$$('logentry_ketones_instructions4')}</li>
                                            <li>{$$('logentry_ketones_instructions5')}</li>
                                        </ul>
                                    </div>
                                }
                                {this.state.showUrinePhForm &&
                                    <div style={{"height": "425px"}}>
                                        <ul>
                                            <li>{$$('logentry_urine_ph_instructions0')}</li>
                                            <li>{$$('logentry_urine_ph_instructions1')}</li>
                                            <li>{$$('logentry_urine_ph_instructions2')}</li>
                                            <li>{$$('logentry_urine_ph_instructions3')}</li>
                                        </ul>
                                    </div>
                                }
                                {this.state.showMenstruationForm &&
                                    <div style={{"height": "425px"}}>
                                        <ul>
                                            <li>{$$('logentry_menstruation_instructions0')}</li>
                                            <li>{$$('logentry_menstruation_instructions1')}</li>
                                            <li>{$$('logentry_menstruation_instructions2')}</li>
                                        </ul>
                                        <h6>{$$('logentry_menstruation_instructions_title1')}</h6>
                                        <ul>
                                            <li>{$$('logentry_menstruation_instructions3')}</li>
                                            <li>{$$('logentry_menstruation_instructions4')}</li>
                                            <li>{$$('logentry_menstruation_instructions5')}</li>
                                            <li>{$$('logentry_menstruation_instructions6')}</li>
                                        </ul>
                                    </div>
                                }
                                {this.state.showPeakFlowForm &&
                                    <div style={{"height": "425px"}}>
                                        <ul>
                                            <li>{$$('logentry_peak_flow_instructions0')}</li>
                                            <li>{$$('logentry_peak_flow_instructions1')}</li>
                                            <li>{$$('logentry_peak_flow_instructions2')}</li>
                                            <li>{$$('logentry_peak_flow_instructions3')}</li>
                                            <li className={"text-danger bold"}>{$$('logentry_peak_flow_instructions4')}</li>
                                            <li>{$$('logentry_peak_flow_instructions5')}</li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </CenteredModal>
                }
            </div>
        )
    }
}

LogbookForm.propTypes = {
    clearSelectedEntry: PropTypes.func,
    createEntry: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    formDisabled: PropTypes.object,
    getHealthIssueList: PropTypes.func,
    parseFloat: PropTypes.func,
    healthIssues: PropTypes.object,
    logbookEntries: PropTypes.array,
    medicalProfile: PropTypes.object,
    onHide: PropTypes.func,
    selectedEntry: PropTypes.any,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    show: PropTypes.bool,
    i18n: PropTypes.any,
    type: PropTypes.string,
    updateEntry: PropTypes.func,
    updateUserMedicalProfile: PropTypes.func
}



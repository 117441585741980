import React, {Component} from 'react'
import {$$} from '../../../helpers/localization'
import {
    getResolvedOptions,
    MENSTRUATION_COLOR_TYPES,
    MENSTRUATION_CONSISTENCY_TYPES,
    MENSTRUATION_FLOW_TYPES
} from '../../../constants/select_options'
import Select from '../../shared/Select'
import PropTypes from "prop-types";


export default class OxygenSaturationForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="form-group">
                <div className="row">
                    <div className="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4"
                         style={{"margin": 0, "display": "flex", "alignItems": "center"}}>
                        {$$('flow_label')}
                    </div>
                    <div className="form-group col-xs-8 col-sm-8 col-md-8 col-lg-8"
                         style={{"margin": 0}}>
                        <Select
                            name="menstruationFlow"
                            options={getResolvedOptions(MENSTRUATION_FLOW_TYPES.TYPE)}
                            value={this.props.menstruationFlow}
                            onChange={this.props.onInputChange}
                            placeHolder={$$('flow_label')}/>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4"
                         style={{"margin": 0, "display": "flex", "alignItems": "center"}}>
                        {$$('color_label')}
                    </div>
                    <div className="form-group col-xs-8 col-sm-8 col-md-8 col-lg-8"
                         style={{"margin": 0}}>
                        <Select
                            name="menstruationColor"
                            options={getResolvedOptions(MENSTRUATION_COLOR_TYPES.TYPE)}
                            value={this.props.menstruationColor}
                            onChange={this.props.onInputChange}
                            placeHolder={$$('color_label')}/>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-xs-4 col-sm-4 col-md-4 col-lg-4"
                         style={{"margin": 0, "display": "flex", "alignItems": "center"}}>
                        {$$('consistency_label')}
                    </div>
                    <div className="form-group col-xs-8 col-sm-8 col-md-8 col-lg-8"
                         style={{"margin": 0}}>
                        <Select
                            name="menstruationConsistency"
                            options={getResolvedOptions(MENSTRUATION_CONSISTENCY_TYPES.TYPE)}
                            value={this.props.menstruationConsistency}
                            onChange={this.props.onInputChange}
                            placeHolder={$$('consistency_label')}/>
                    </div>
                </div>
            </div>
        )
    }
}

OxygenSaturationForm.propTypes = {
    clearSelectedEntry: PropTypes.func,
    createEntry: PropTypes.func,
    onInputChange: PropTypes.func,
    menstruationFlow: PropTypes.any,
    menstruationColor: PropTypes.any,
    menstruationConsistency: PropTypes.any,
    fetchSelectedUserHealthIssues: PropTypes.func,
    formDisabled: PropTypes.object,
    getHealthIssueList: PropTypes.func,
    healthIssues: PropTypes.object,
    logbookEntries: PropTypes.array,
    medicalProfile: PropTypes.object,
    onHide: PropTypes.func,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    show: PropTypes.bool,
    type: PropTypes.string,
    updateEntry: PropTypes.func,
    updateUserMedicalProfile: PropTypes.func
}
import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {getTreeViewSymptoms} from '../../utils/symptomUtils';
import PropTypes from "prop-types";

class SymptomBox extends Component {
    constructor(props) {
        super(props);
    }

    getStrength = () => {
        let i;
        let strength = []
        for (i = 0; i <= this.props.strength - 1; i++) {
            let strengths = ["symptom-strength-cell-low","symptom-strength-cell-medium","symptom-strength-cell-high"]
            strength.push(<span key={i} className={"symptom-strength-cell-full " + strengths[i]}/>)
        }
        for (i = 0; i <= 2 - this.props.strength; i++) {
            strength.push(<span key={3 + i} className="symptom-strength-cell-empty"/>)
        }
        return strength;
    }


    render() {
        let symptomsTree = getTreeViewSymptoms(this.props.i18n.lang);
        let nomenclature = "";
        symptomsTree.map((symptom) => {
            if (this.props.symptomName === symptom.description.substring(0, symptom.description.length)) {
                nomenclature = symptom.nomenclature
            }
        })

        let type = "";
        symptomsTree.map((symptom) => {
            if (symptom.nomenclature === (nomenclature[0])) {
                type = symptom.description
            }
        })
        return (
            <div className="symptom-box">
                <div className="symptom-item-type">
                    {type === "" ? '\u00A0' : type.replace("_", " ").replace("_", " ")}
                </div>
                <div
                    className="row symptom-item-name">{this.props.symptomName === "N/A" || this.props.symptomName === "NO_SYMPTOMS" || !this.props.symptomName ? $$("no_symptoms_label") : this.props.symptomName}</div>
                {this.props.strength !== -1 && <div className="row">
                    {this.getStrength()}
                </div>}
                <div className="symptom-description-box"/>
            </div>
        )
    }
}

SymptomBox.propTypes = {
    i18n: PropTypes.object,
    idx: PropTypes.number,
    strength: PropTypes.number,
    symptomName: PropTypes.string
};

export default SymptomBox

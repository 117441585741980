import React, {Component} from 'react'
import {$$} from '../../helpers/localization';
import {usersService} from "../../service/users_service"
import {Collapse} from 'react-collapse'
import moment from 'moment'
import {APPOINTMENTS_FILTER, getColorForOption} from '../../constants/select_options'
import UserImage from '../shared/UserImage'
import {formatUtils} from '../../utils/formatUtils'
import {
    COMPLETED,
    IN_PROGRESS,
    INITIAL,
    REJECTED} from '../../constants/appointment_payment_status'
import {appointmentUtils} from '../../utils/appointmentUtils'
import {
    fetchSelectedUserAppointments,
    updateAppointmentStatus
} from '../../actions/appointments_actions'
import {connect} from 'react-redux'
import HealthIssueTag from '../health/HealthIssueTag';
import PropTypes from "prop-types";
import {dateTimeUtils} from "../../utils/dateTimeUtils";
import {CONVERTER, formatDate} from "../../utils/converter";
import {documentsService} from "../../service/docments_service";
import EncounterDetails from "./EncounterDetails";
import {getPrescriptionDetailsString} from "../../utils/medicationUtils";

class AppointmentDetails extends Component {
    state = {
        cancelAppointmentModalOpened: false,
        appointmentToCancel: null,
        descriptionOpened: false,
        isEditMode: false,
        value: '',
        appointment: '',
        formClass: '',
        errors: {},
        changingAppointment: false,
        encounter: null,
    }

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        this.getEncounter();
    }

    /**
     * An event handler triggered when an appointment is clicked
     */
    getEncounter = () => {
        if (this.props.appointment.encounter_id && this.state.encounter == null) {
            usersService.fetchAppointmentEncounter(this.props.appointment.encounter_id, {userId:this.props.selectedUserId}).then((encounter) => {
                if (encounter) {
                    this.setState({encounter});
                    documentsService.fetchSelectedUserDocuments(this.props.selectedUserId, {
                            before_date_time: moment().valueOf(),
                            size: 500
                        }).then((r)=>{
                            this.setState({all_user_documents:r})
                    })
                }
            });
        }
    }

    /**
     * Creates the time cell of the table
     *
     * @param {object} appointment - the appointment object
     * @returns {ReactElement} the created table cell
     */
    getTimeCell = (appointment) => {
        let isThisYear = moment(appointment.starts_at).format("YYYY") === moment().format("YYYY")
        return <div className='d-flex clinician-time-cell-appointment'>
            <div className="text-right">
                <span className='font-weight-bold patient-fullname'>
                    {dateTimeUtils.getLongFormattedDate(appointment.starts_at)}
                </span>
                <br/>
                <span className="patient-age">
                    <i className='flaticon2-time medrec-grey-2'/>
                    &nbsp; {CONVERTER.getEntryDateTimeFormat(appointment.starts_at)}
                </span>
            </div>
        </div>
    }

    /**
     * Creates the name cell of the table
     *
     * @param {object} appointment - the appointment object
     * @returns {ReactElement} the created table cell
     */
    getNameCell = (appointment) => {
        let doctorImageClass = 'patient-img';
        let doctor;
        for (let i = 0; i < appointment.participants.length; i++) {
            if (appointment.participants[i].participant_role === "PROVIDER") {
                doctor = appointment.participants[i];
            }
        }

        return <div className="d-flex clinician-name-cell-appointment">
            <div className='justify-content-center patient-img-container-list'>
                <UserImage userID={doctor.user_id}
                           classnames={doctorImageClass}/>
            </div>
            <div style={{"alignSelf": "left"}}>
                <span className='patient-fullname'>{doctor.fullname}</span>
                <br/>
                <span className="medrec-grey-2"><span className="medrec-grey-2 patient-age">{appointmentUtils.appointmentType(appointment.appointment_price)}&nbsp;</span>, <span
                className="text-lowercase medrec-grey-2">{appointment.text_only ? $$("text_consultation") : appointmentUtils.displayLocation(appointment)}</span>
                </span>
            </div>
        </div>
    }

    /**
     * Creates the price cell of the table
     *
     * @returns {ReactElement} the created table cell
     */
    getPriceCell = (appointment) => {
        let s = appointment.text_only ? $$('free_label_consultation') : $$('free_label');
        return <div className="clinician-payment-status-cell-appointment ml-3">
            {appointment.appointment_price.price_cents === 0 ? s : formatUtils.currencyFormat(appointment.appointment_price.price_cents, appointment.appointment_price.currency)}
        </div>;
    }

    /**
     * Creates the payment status cell of the table
     *
     * @returns {ReactElement} the created table cell
     */
    getPaymentStatus = (appointment) => {
        const consultation_key_suffix = appointment.text_only ? "_consultation" : "";
        let label = "";
        if (appointment.appointment_price.price_cents === 0) {
            label = "";
        } else if (appointment.payment_status === COMPLETED) {
            label = $$('payment_completed' + consultation_key_suffix);
        } else if (appointment.payment_status === INITIAL) {
            label = $$('payment_initial' + consultation_key_suffix);
        } else if (appointment.payment_status === IN_PROGRESS) {
            label = $$('payment_in_progress' + consultation_key_suffix);
        } else if (appointment.payment_status === REJECTED) {
            label = $$('payment_rejected' + consultation_key_suffix);
        }

        /*return <div className='payment-cell-appointment ml-3'>
            {label}
        </div>*/

        let overdue = appointmentUtils.isOverdue(appointment) && appointment.appointment_price.location_type !== 'ON_SITE';
        let missedPayment = overdue && appointment.payment_status !== COMPLETED && appointment.payment_status !== IN_PROGRESS && appointment.appointment_price.price_cents > 0;
        label = missedPayment ? $$('missed_payment' + consultation_key_suffix) : label;

        let canPay = ((appointment.payment_status === INITIAL || appointment.payment_status === REJECTED)
            && (appointment.status === 'COMPLETED' || appointment.status === 'ACCEPTED') && appointment.appointment_price.price_cents > 0
            && appointment.appointment_price.currency === 'BGN')
            && !overdue;

        return <div className='d-flex'>
            <div className="payment-item">
                <span className={missedPayment ? "text-danger" : ""}>{label} {missedPayment &&
                <p className="small">({formatDate(appointmentUtils.getAdjustedPayBefore(appointment), false)})</p>}</span>
                {canPay && appointment.appointment_price.location_type !== 'ON_SITE' &&
                <p className="small">{$$("pay_before")}: {formatDate(appointmentUtils.getAdjustedPayBefore(appointment), false)}</p>}
            </div>
        </div>

    }

    /**
     * Creates the health issues list cell of the table
     *
     * @param {object} appointment - the appointment object
     * @returns {ReactElement} the created table cell
     */
    getHealthIssueListCell = (appointment) => {
        if (appointment.health_issues) {
            let healthIssueList = appointment.health_issues.map((h, idx) => {
                return <HealthIssueTag
                    key={idx}
                    className='patient-health-issue-list health-issue-cell-appointments'
                    styles={{}}
                    healthIssue={h.name}
                />
            });

            return <div className="health-issue-cell-appointment pt-2">{healthIssueList}</div>
        }
    }


    /**
     * Creates the status cell of the table
     *
     * @param {object} appointment - the appointment object
     * @returns {ReactElement} the created table cell
     */
    getStatusCell = (appointment) => {
        return <div className="status-cell-appointment">
            <span className="patient-age"
                  style={{"color": getColorForOption(APPOINTMENTS_FILTER.COLOR, appointment.status)}}>
                {this.getStatusString(appointment)}
            </span>
        </div>
    }

    getStatusString = (appointment) => {
        return appointment.text_only ? $$(`${appointment.status.toLowerCase()}_consultation`) : $$(appointment.status.toLowerCase())
    }

    getPrescriptions = () => {
        let prescriptions = "";
        for (let i = 0; i < this.state.encounter.prescriptions.length; i++) {
            if (i === 0) {
                prescriptions += this.state.encounter.prescriptions[i].name + " - " + $$(this.state.encounter.prescriptions[i].scheduleType.toLowerCase())
            } else {
                prescriptions += "    |    " + this.state.encounter.prescriptions[i].name + " - " + getPrescriptionDetailsString(this.state.encounter.prescriptions[i])
            }
        }
        return prescriptions;
    }

    getMore = () => {
        let hasDisclaimer = this.props.selectedAppointment?.appointment_price.disclaimer;
        let hasDescription = this.props.selectedAppointment?.appointment_price.description;
        return <div>
            {(hasDisclaimer || hasDescription) &&
            <hr className="hr-text" data-content={$$("description")}/>}
            {hasDescription &&
            <div className={"pl-4"}>
                <p className="white-space-pre-line">{this.props.selectedAppointment?.appointment_price.description}</p>
            </div>
            }
            {hasDisclaimer &&
            <div className={"pl-4"}>
                <p className="white-space-pre-line">{this.props.selectedAppointment?.appointment_price.disclaimer}</p>
            </div>
            }
        </div>
    }

    render() {
        return (
            <div>
                <div className='high-shadow-container'>
                    <div className="d-flex flex-wrap flex-column">
                        <div className="d-flex justify-content-between">
                            {this.getNameCell(this.props.appointment)}
                            {this.getTimeCell(this.props.appointment)}
                        </div>
                        {this.getHealthIssueListCell(this.props.appointment)}
                        <div className="d-flex pt-3 mt-3 border-top-light">
                            {this.getStatusCell(this.props.appointment)}
                            {this.getPriceCell(this.props.appointment)}
                            {this.getPaymentStatus(this.props.appointment)}
                        </div>
                    </div>
                    <div className="text-center p-3 show-more">
                        <a onClick={() => {
                            this.setState({descriptionOpened: !this.state.descriptionOpened})
                        }} >
                            {!this.state.descriptionOpened &&
                            <span className="mr-2">{$$("more")}</span>
                            }
                            {!this.state.descriptionOpened &&
                            <i className="kt-nav__link-icon fas fa-chevron-down medrec-blue-1 icon-thin"
                            />
                            }
                            {this.state.descriptionOpened &&
                            <span className="mr-2">{$$("less")}</span>
                            }
                            {this.state.descriptionOpened &&
                            <i className="kt-nav__link-icon fas fa-chevron-up medrec-blue-1 icon-thin"
                            />
                            }

                        </a>
                    </div>
                </div>
                {this.state.encounter != null && this.props.selectedAppointment.encounter_id && true && this.props.selectedAppointment && this.props.selectedAppointment.status === 'COMPLETED' &&
                <Collapse isOpened={this.state.descriptionOpened}>
                    {this.getMore()}
                    <EncounterDetails encounter={this.state.encounter}
                                      all_user_documents={this.state.all_user_documents}
                                      text_only={this.props.selectedAppointment.text_only}
                                      selectedUser={this.props.selectedUser}
                    >
                        {this.state.encounter.prescriptions.length > 0 && <div>
                            <hr/>
                            <div className="row full-width">
                                <div className='medrec-grey-2' style={{
                                    "width": "20%",
                                    "textAlign": "right",
                                    "padding": "20px"
                                }}>{$$("prescriptions")}</div>
                                <div style={{
                                    "width": "80%",
                                    "textAlign": "left",
                                    "whiteSpace": "pre-wrap",
                                    "padding": "20px",
                                    "paddingLeft": "40px"
                                }}>
                                    {this.getPrescriptions()}
                                </div>
                            </div>
                        </div>}
                    </EncounterDetails>
                </Collapse>}
                {(this.state.encounter == null || this.props.selectedAppointment.status !== 'COMPLETED') &&
                <Collapse isOpened={this.state.descriptionOpened}>
                    {this.getMore()}
                    <hr className="hr-text" data-content={$$(this.props.selectedAppointment.text_only ? "consultation_results_data_separator":"eccounter_data_separator")}/>
                    <br/>
                    <div className='medrec-grey-2 center-text center-text'
                         >{$$(this.props.selectedAppointment.text_only ?  "no_encounter_found_label_consultation" : "no_encounter_found_label")}</div>
                    <br/>
                </Collapse>}
            </div>
        )
    }
}


AppointmentDetails.propTypes = {
    appointment: PropTypes.object,
    clinician: PropTypes.object,
    fetchSelectedUserAppointments: PropTypes.func,
    selectedAppointment: PropTypes.object,
    updateAppointmentStatus:PropTypes.func
}

const mapStateToProps = (state) => ({
    clinician: state.provider.data,
})

const mapDispatchToProps = {
    fetchSelectedUserAppointments,
    updateAppointmentStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetails);

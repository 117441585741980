import {
    LOGIN_REQUEST_SUCCESS,
    REGISTER_CLEAR,
    REGISTER_ERROR,
    REGISTER_SUCCESS
} from "./actions";
import {authService} from '../service/auth_service'
import {uploadImage} from '../service/users_service'

/**
 * Register user, call the service and dispatch the appropiate reducer method
 *
 * @param {object} user - The user data to persist
 * @returns {function} dispatch function
 */
export function register(user) {

    return (dispatch, getState) => {
        authService.register(user)
            .then(res => {
                if (res.id) {
                    dispatch({type: REGISTER_SUCCESS, response: res});

                    let imageUrl = getState().manageProfileImage.imageUrl

                    if (imageUrl) {

                        authService.login(user.email, user.password).then(result => {
                            dispatch({type: LOGIN_REQUEST_SUCCESS, response: result});
                            authService.createDeviceInfo();
                            async function createFile() {

                                const response = await fetch(imageUrl)
                                    .then("SUCCESS")
                                    .catch(err => {
                                        console.log(err)
                                    });

                                const data = await response.blob();


                                const metadata = {
                                    type: 'image/jpeg'
                                };
                                const file = new File([data], "test.jpg", metadata);


                                uploadImage(file, res, res.id)

                            }

                            createFile();
                        })
                    }


                }
            })
            .catch((error) => {
                dispatch({type: REGISTER_ERROR, response: error});
            });
    }
}

/**
 * Register user and connected profile, call the service and dispatch the appropriate reducer method
 *
 * @param {object} user - The user data to persist
 * @returns {function} dispatch function
 */
export function registerWithConnectedProfile(user) {
    return (dispatch, getState) => {
        return authService.registerWithConnectedProfile(user)
            .then(res => {
                if (res.userInfo.id) {
                    dispatch({type: REGISTER_SUCCESS, response: res.userInfo});

                    let imageUrl = getState().manageProfileImage.imageUrl

                    if (imageUrl) {

                        authService.login(user.email, user.password).then(result => {
                            dispatch({type: LOGIN_REQUEST_SUCCESS, response: result});
                            authService.createDeviceInfo();
                            async function createFile() {
                                const response = await fetch(imageUrl)
                                    .then("SUCCESS")
                                    .catch(err => {
                                        console.log(err)
                                    });

                                const data = await response.blob();


                                const metadata = {
                                    type: 'image/jpeg'
                                };
                                const file = new File([data], "test.jpg", metadata);


                                uploadImage(file, res.connectedProfile, res.userInfo.id)

                            }
                            createFile();
                        })
                    }
                    return res;
                }
            })
            .catch((error) => {
                dispatch({type: REGISTER_ERROR, response: error});
            });
    }
}


/**
 * Clear registration data action
 *
 * @returns {function} dispatch function
 */
export function clearRegistrationData() {
    return (dispatch) => {
        dispatch({type: REGISTER_CLEAR});
    }
}


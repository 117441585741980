export const Routes = {
    DASHBOARD: "/dashboard",
    LOGIN: "/login",
    REGISTER: "/register",
    MAIN_PAGE: "/main",
    SETTINGS: "/settings",
    SHARING: "/sharing",
    CLINICIANS: "/clinicians",
    CLINICIAN: "/clinician-page",
    CLINICIAN_NOT_LOGGED_IN: "/clinician",
    CLINIC_NOT_LOGGED_IN: "/public/clinic",
    MESSAGES: "/messages",
    PROFILE: "/profile",
    MY_DOCUMENTS: "/documents",
    COMMUNICATION: "/communication",
    CHILD_MEDICAL_RECORD: "/medical-record",
    CHANGE_PASSWORD: "/change-password",
    HEALTH_ISSUES: "/health-issues",
    APPOINTMENTS: "/appointments",
    APPOINTMENT: "/appointment",
    CHILD_RECORD: "/create-child",
    MEDICATIONS: "/medications",
};

import React, {Component} from "react";

import SingleImagePill from "./pills/SingleImagePill";
import DoubleImageSolidPill from "./pills/DoubleImageSolidPill";
import DoubleImageSemiPill from "./pills/DoubleImageSemiPill";
import DoubleImageTranPill from "./pills/DoubleImageTranPill";
import GelPill from "./pills/GelPill";
import MedCapsuleHSolidF from "../../resources/images/pill_shapes/pngs/med_capsule_H_solid_01_f.png";
import MedCapsuleHSolidFMask from "../../resources/images/pill_shapes/masks/med_capsule_H_solid_01_f_mask.png";
import MedCapsuleHSolidM from "../../resources/images/pill_shapes/pngs/med_capsule_H_solid_01_m.png";
import MedCapsuleHSolidMMask from "../../resources/images/pill_shapes/masks/med_capsule_H_solid_01_m_mask.png";
import MedCapsuleVSolidF from "../../resources/images/pill_shapes/pngs/med_capsule_V_solid_01_f.png";
import MedCapsuleVSemiF from "../../resources/images/pill_shapes/pngs/med_capsule_V_solid_01_f.png";
import MedCapsuleVSolidFMask from "../../resources/images/pill_shapes/masks/med_capsule_V_solid_01_f_mask.png";
import MedCapsuleVSemiFMask from "../../resources/images/pill_shapes/masks/med_capsule_V_solid_01_f_mask.png";
import MedCapsuleVSolidM from "../../resources/images/pill_shapes/pngs/med_capsule_V_solid_01_m.png";
import MedCapsuleVSolidMMask from "../../resources/images/pill_shapes/masks/med_capsule_V_solid_01_m_mask.png";
import MedCapsuleVSemiM from "../../resources/images/pill_shapes/pngs/med_capsule_V_semi_01_m.png";
import MedCapsuleVSemiMMask from "../../resources/images/pill_shapes/masks/med_capsule_V_semi_01_m_mask.png";
import MedCapsuleVSemiB from "../../resources/images/pill_shapes/pngs/med_capsule_V_semi_01_b.png";
import MedCapsuleVSemiBMask from "../../resources/images/pill_shapes/masks/med_capsule_V_semi_01_b_mask.png";
import MedCapsuleVTranF from "../../resources/images/pill_shapes/pngs/med_capsule_V_tran_01_f.png";
import MedCapsuleVTranFMask from "../../resources/images/pill_shapes/masks/med_capsule_V_tran_01_f_mask.png";
import MedCapsuleVTranM from "../../resources/images/pill_shapes/pngs/med_capsule_V_tran_01_m.png";
import MedCapsuleVTranMMask from "../../resources/images/pill_shapes/masks/med_capsule_V_tran_01_m_mask.png";
import MedCapsuleVTranB from "../../resources/images/pill_shapes/pngs/med_capsule_V_tran_01_b.png";
import MedCapsuleVTranBMask from "../../resources/images/pill_shapes/masks/med_capsule_V_tran_01_b_mask.png";
import MedGelSolidOneF from "../../resources/images/pill_shapes/pngs/med_gel_solid_01_f.png";
import MedGelSolidOneFMask from "../../resources/images/pill_shapes/masks/med_gel_solid_01_f_mask.png";
import MedGelSolidOneB from "../../resources/images/pill_shapes/pngs/med_gel_solid_01_b.png";
import MedGelSolidOneBMask from "../../resources/images/pill_shapes/masks/med_gel_solid_01_b_mask.png";
import MedGelSolidTwoF from "../../resources/images/pill_shapes/pngs/med_gel_solid_02_f.png";
import MedGelSolidTwoFMask from "../../resources/images/pill_shapes/masks/med_gel_solid_02_f_mask.png";
import MedGelSolidTwoB from "../../resources/images/pill_shapes/pngs/med_gel_solid_02_b.png";
import MedGelSolidTwoBMask from "../../resources/images/pill_shapes/masks/med_gel_solid_02_b_mask.png";
import MedGelSolidThreeF from "../../resources/images/pill_shapes/pngs/med_gel_solid_03_f.png";
import MedGelSolidThreeFMask from "../../resources/images/pill_shapes/masks/med_gel_solid_03_f_mask.png";
import MedGelSolidThreeB from "../../resources/images/pill_shapes/pngs/med_gel_solid_03_b.png";
import MedGelSolidThreeBMask from "../../resources/images/pill_shapes/masks/med_gel_solid_03_b_mask.png";
import MedGelSolidFourF from "../../resources/images/pill_shapes/pngs/med_gel_solid_04_f.png";
import MedGelSolidFourFMask from "../../resources/images/pill_shapes/masks/med_gel_solid_04_f_mask.png";
import MedGelSolidFourB from "../../resources/images/pill_shapes/pngs/med_gel_solid_04_b.png";
import MedGelSolidFourBMask from "../../resources/images/pill_shapes/masks/med_gel_solid_04_b_mask.png";
import MedSphericSolidOneF from "../../resources/images/pill_shapes/pngs/med_spheric_solid_01.png";
import MedSphericSolidOneFMask from "../../resources/images/pill_shapes/masks/med_spheric_solid_01_mask.png";
import MedSphericSolidTwoF from "../../resources/images/pill_shapes/pngs/med_spheric_solid_02.png";
import MedSphericSolidTwoFMask from "../../resources/images/pill_shapes/masks/med_spheric_solid_02_mask.png";
import MedSphericSolidThreeF from "../../resources/images/pill_shapes/pngs/med_spheric_solid_03.png";
import MedSphericSolidThreeFMask from "../../resources/images/pill_shapes/masks/med_spheric_solid_03_mask.png";
import MedPillRoundFlatLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_flat_L0_solid_x_01_f.png";
import MedPillRoundFlatLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_flat_L0_solid_x_01_f_mask.png";
import MedPillRoundFlatLZeroSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_flat_L0_solid_l_01_f.png";
import MedPillRoundFlatLZeroSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_flat_L0_solid_l_01_f_mask.png";
import MedPillRoundFlatLZeroSolidMF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_flat_L0_solid_m_01_f.png";
import MedPillRoundFlatLZeroSolidMFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_flat_L0_solid_m_01_f_mask.png";
import MedPillRoundFlatLZeroSolidSF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_flat_L0_solid_s_01_f.png";
import MedPillRoundFlatLZeroSolidSFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_flat_L0_solid_s_01_f_mask.png";
import MedPillRoundFlatLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_flat_L1_solid_x_01_f.png";
import MedPillRoundFlatLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_flat_L1_solid_x_01_f_mask.png";
import MedPillRoundFlatLOneSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_flat_L1_solid_l_01_f.png";
import MedPillRoundFlatLOneSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_flat_L1_solid_l_01_f_mask.png";
import MedPillRoundFlatLTwoSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_flat_L2_solid_x_01_f.png";
import MedPillRoundFlatLTwoSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_flat_L2_solid_x_01_f_mask.png";
import MedPillRoundFlatLTwoSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_flat_L2_solid_l_01_f.png";
import MedPillRoundFlatLTwoSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_flat_L2_solid_l_01_f_mask.png";
import MedPillRoundInLZeroSolidXF from "../../resources/images/pill_shapes/pngs/med_pill_round_in_L0_solid_x_01_f.png";
import MedPillRoundInLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_in_L0_solid_x_01_f_mask.png";
import MedPillRoundInLZeroSolidLF from "../../resources/images/pill_shapes/pngs/med_pill_round_in_L0_solid_l_01_f.png";
import MedPillRoundInLZeroSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_in_L0_solid_l_01_f_mask.png";
import MedPillRoundInLZeroSolidMF from "../../resources/images/pill_shapes/pngs/med_pill_round_in_L0_solid_m_01_f.png";
import MedPillRoundInLZeroSolidMFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_in_L0_solid_m_01_f_mask.png";
import MedPillRoundInLZeroSolidSF from "../../resources/images/pill_shapes/pngs/med_pill_round_in_L0_solid_s_01_f.png";
import MedPillRoundInLZeroSolidSFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_in_L0_solid_s_01_f_mask.png";
import MedPillRoundInLOneSolidXF from "../../resources/images/pill_shapes/pngs/med_pill_round_in_L1_solid_x_01_f.png";
import MedPillRoundInLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_in_L1_solid_x_01_f_mask.png";
import MedPillRoundInLOneSolidLF from "../../resources/images/pill_shapes/pngs/med_pill_round_in_L1_solid_l_01_f.png";
import MedPillRoundInLOneSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_in_L1_solid_l_01_f_mask.png";
import MedPillRoundInLTwoSolidXF from "../../resources/images/pill_shapes/pngs/med_pill_round_in_L2_solid_x_01_f.png";
import MedPillRoundInLTwoSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_in_L2_solid_x_01_f_mask.png";
import MedPillRoundInLTwoSolidLF from "../../resources/images/pill_shapes/pngs/med_pill_round_in_L2_solid_l_01_f.png";
import MedPillRoundInLTwoSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_in_L2_solid_l_01_f_mask.png";
import MedPillRoundOutLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_out_L0_solid_x_01_f.png";
import MedPillRoundOutLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_out_L0_solid_x_01_f_mask.png";
import MedPillRoundOutLZeroSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_out_L0_solid_l_01_f.png";
import MedPillRoundOutLZeroSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_out_L0_solid_l_01_f_mask.png";
import MedPillRoundOutLZeroSolidMF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_out_L0_solid_m_01_f.png";
import MedPillRoundOutLZeroSolidMFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_out_L0_solid_m_01_f_mask.png";
import MedPillRoundOutLZeroSolidSF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_out_L0_solid_s_01_f.png";
import MedPillRoundOutLZeroSolidSFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_out_L0_solid_s_01_f_mask.png";
import MedPillRoundOutLOneSolidXF from "../../resources/images/pill_shapes/pngs/med_pill_round_out_L1_solid_x_01_f.png";
import MedPillRoundOutLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_out_L1_solid_x_01_f_mask.png";
import MedPillRoundOutLOneSolidLF from "../../resources/images/pill_shapes/pngs/med_pill_round_out_L1_solid_l_01_f.png";
import MedPillRoundOutLOneSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_out_L1_solid_l_01_f_mask.png";
import MedPillRoundOutLTwoSolidXF from "../../resources/images/pill_shapes/pngs/med_pill_round_out_L2_solid_x_01_f.png";
import MedPillRoundOutLTwoSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_out_L2_solid_x_01_f_mask.png";
import MedPillRoundOutLTwoSolidLF from "../../resources/images/pill_shapes/pngs/med_pill_round_out_L2_solid_l_01_f.png";
import MedPillRoundOutLTwoSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_out_L2_solid_l_01_f_mask.png";
import MedPillElipseFlatLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_elipse_flat_L0_solid_x_01_f.png";
import MedPillElipseFlatLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_elipse_flat_L0_solid_x_01_f_mask.png";
import MedPillElipseFlatLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_elipse_flat_L1_solid_x_01_f.png";
import MedPillElipseFlatLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_elipse_flat_L1_solid_x_01_f_mask.png";
import MedPillElipseInLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_elipse_in_L0_solid_x_01_f.png";
import MedPillElipseInLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_elipse_in_L0_solid_x_01_f_mask.png";
import MedPillElipseInLOneSolidXF from "../../resources/images/pill_shapes/pngs/med_pill_elipse_in_L1_solid_x_01_f.png";
import MedPillElipseInLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_elipse_in_L1_solid_x_01_f_mask.png";
import MedPillElipseOutLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_elipse_out_L0_solid_x_01_f.png";
import MedPillElipseOutLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_elipse_out_L0_solid_x_01_f_mask.png";
import MedPillElipseOutLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_elipse_out_L1_solid_x_01_f.png";
import MedPillElipseOutLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_elipse_out_L1_solid_x_01_f_mask.png";
import MedPillRectangleFlatLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_rectangle_flat_L0_solid_x_01_f.png";
import MedPillRectangleFlatLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_rectangle_flat_L0_solid_x_01_f_mask.png";
import MedPillRectangleFlatLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_rectangle_flat_L1_solid_x_01_f.png";
import MedPillRectangleFlatLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_rectangle_flat_L1_solid_x_01_f_mask.png";
import MedPillRectangleInLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_rectangle_in_L0_solid_x_01_f.png";
import MedPillRectangleInLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_rectangle_in_L0_solid_x_01_f_mask.png";
import MedPillRectangleInLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_rectangle_in_L1_solid_x_01_f.png";
import MedPillRectangleInLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_rectangle_in_L1_solid_x_01_f_mask.png";
import MedPillRectangleOutLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_rectangle_out_L0_solid_x_01_f.png";
import MedPillRectangleOutLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_rectangle_out_L0_solid_x_01_f_mask.png";
import MedPillRectangleOutLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_rectangle_out_L1_solid_x_01_f.png";
import MedPillRectangleOutLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_rectangle_out_L1_solid_x_01_f_mask.png";
import MedPillSquareFlatLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_flat_L0_solid_x_01_f.png";
import MedPillSquareFlatLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_flat_L0_solid_x_01_f_mask.png";
import MedPillSquareFlatLZeroSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_flat_L0_solid_l_01_f.png";
import MedPillSquareFlatLZeroSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_flat_L0_solid_l_01_f_mask.png";
import MedPillSquareFlatLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_flat_L1_solid_x_01_f.png";
import MedPillSquareFlatLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_flat_L1_solid_x_01_f_mask.png";
import MedPillSquareFlatLOneSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_flat_L1_solid_l_01_f.png";
import MedPillSquareFlatLOneSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_flat_L1_solid_l_01_f_mask.png";
import MedPillSquareFlatLTwoSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_flat_L2_solid_x_01_f.png";
import MedPillSquareFlatLTwoSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_flat_L2_solid_x_01_f_mask.png";
import MedPillSquareFlatLTwoSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_flat_L2_solid_l_01_f.png";
import MedPillSquareFlatLTwoSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_flat_L2_solid_l_01_f_mask.png";
import MedPillSquareInLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_in_L0_solid_x_01_f.png";
import MedPillSquareInLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_in_L0_solid_x_01_f_mask.png";
import MedPillSquareInLZeroSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_in_L0_solid_l_01_f.png";
import MedPillSquareInLZeroSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_in_L0_solid_l_01_f_mask.png";
import MedPillSquareInLOneSolidXF from "../../resources/images/pill_shapes/pngs/med_pill_square_in_L1_solid_x_01_f.png";
import MedPillSquareInLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_in_L1_solid_x_01_f_mask.png";
import MedPillSquareInLOneSolidLF from "../../resources/images/pill_shapes/pngs/med_pill_square_in_L1_solid_l_01_f.png";
import MedPillSquareInLOneSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_in_L1_solid_l_01_f_mask.png";
import MedPillSquareInLTwoSolidXF from "../../resources/images/pill_shapes/pngs/med_pill_square_in_L2_solid_x_01_f.png";
import MedPillSquareInLTwoSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_in_L2_solid_x_01_f_mask.png";
import MedPillSquareInLTwoSolidLF from "../../resources/images/pill_shapes/pngs/med_pill_square_in_L2_solid_l_01_f.png";
import MedPillSquareInLTwoSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_in_L2_solid_l_01_f_mask.png";
import MedPillSquareOutLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_out_L0_solid_x_01_f.png";
import MedPillSquareOutLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_out_L0_solid_x_01_f_mask.png";
import MedPillSquareOutLZeroSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_out_L0_solid_l_01_f.png";
import MedPillSquareOutLZeroSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_out_L0_solid_l_01_f_mask.png";
import MedPillSquareOutLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_out_L1_solid_x_01_f.png";
import MedPillSquareOutLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_out_L1_solid_x_01_f_mask.png";
import MedPillSquareOutLOneSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_out_L1_solid_l_01_f.png";
import MedPillSquareOutLOneSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_out_L1_solid_l_01_f_mask.png";
import MedPillSquareOutLTwoSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_out_L2_solid_x_01_f.png";
import MedPillSquareOutLTwoSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_out_L2_solid_x_01_f_mask.png";
import MedPillSquareOutLTwoSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_out_L2_solid_l_01_f.png";
import MedPillSquareOutLTwoSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_out_L2_solid_l_01_f_mask.png";
import MedPillTriangleFlatLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_triangle_flat_L0_solid_x_01_f.png";
import MedPillTriangleFlatLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_triangle_flat_L0_solid_x_01_f_mask.png";
import MedPillTriangleFlatLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_triangle_flat_L1_solid_x_01_f.png";
import MedPillTriangleFlatLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_triangle_flat_L1_solid_x_01_f_mask.png";
import MedPillTriangleInLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_triangle_in_L0_solid_x_01_f.png";
import MedPillTriangleInLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_triangle_in_L0_solid_x_01_f_mask.png";
import MedPillTriangleInLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_triangle_in_L1_solid_x_01_f.png";
import MedPillTriangleInLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_triangle_in_L1_solid_x_01_f_mask.png";
import MedPillTriangleOutLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_triangle_out_L0_solid_x_01_f.png";
import MedPillTriangleOutLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_triangle_out_L0_solid_x_01_f_mask.png";
import MedPillTriangleOutLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_triangle_out_L1_solid_x_01_f.png";
import MedPillTriangleOutLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_triangle_out_L1_solid_x_01_f_mask.png";
import MedXAmpouleOneF from "../../resources/images/pill_shapes/pngs/med_x_ampoule_1.png";
import MedXAmpouleOneFMask from "../../resources/images/pill_shapes/masks/med_x_ampoule_1_mask.png";
import MedXAmpouleTwoF from "../../resources/images/pill_shapes/pngs/med_x_ampoule_2.png";
import MedXAmpouleTwoFMask from "../../resources/images/pill_shapes/masks/med_x_ampoule_2_mask.png";
import MedXVialF from "../../resources/images/pill_shapes/pngs/med_x_vial.png";
import MedXVialFMask from "../../resources/images/pill_shapes/masks/med_x_vial_mask.png";
import MedXSprayF from "../../resources/images/pill_shapes/pngs/med_x_spray.png";
import MedXSprayFMask from "../../resources/images/pill_shapes/masks/med_x_spray_mask.png";
import MedXSprayNasalF from "../../resources/images/pill_shapes/pngs/med_x_spray_nasal.png";
import MedXSprayNasalFMask from "../../resources/images/pill_shapes/masks/med_x_spray_nasal_mask.png";
import MedXSprayOralF from "../../resources/images/pill_shapes/pngs/med_x_spray_oral.png";
import MedXSprayOralFMask from "../../resources/images/pill_shapes/masks/med_x_spray_oral_mask.png";
import MedXSyringeF from "../../resources/images/pill_shapes/pngs/med_x_syringe.png";
import MedXSyringeFMask from "../../resources/images/pill_shapes/masks/med_x_syringe_mask.png";
import MedXInsulinPenF from "../../resources/images/pill_shapes/pngs/med_x_insulin_pen.png";
import MedXInsulinPenFMask from "../../resources/images/pill_shapes/masks/med_x_insulin_pen_mask.png";
import MedXSpoonF from "../../resources/images/pill_shapes/pngs/med_x_spoon.png";
import MedXSpoonFMask from "../../resources/images/pill_shapes/masks/med_x_spoon_mask.png";
import MedXInhalerF from "../../resources/images/pill_shapes/pngs/med_x_inhaler.png";
import MedXInhalerFMask from "../../resources/images/pill_shapes/masks/med_x_inhaler_mask.png";
import MedXOintmentF from "../../resources/images/pill_shapes/pngs/med_x_ointment.png";
import MedXOintmentFMask from "../../resources/images/pill_shapes/masks/med_x_ointment_mask.png";
import MedXEyeDropsF from "../../resources/images/pill_shapes/pngs/med_x_eye_drops.png";
import MedXEyeDropsFMask from "../../resources/images/pill_shapes/masks/med_x_eye_drops_mask.png";
import MedXPipetteF from "../../resources/images/pill_shapes/pngs/med_x_pipette.png";
import MedXPipetteFMask from "../../resources/images/pill_shapes/masks/med_x_pipette_mask.png";
import MedXPowderOneF from "../../resources/images/pill_shapes/pngs/med_x_powder_1.png";
import MedXPowderOneFMask from "../../resources/images/pill_shapes/masks/med_x_powder_1_mask.png";
import MedXPowderTwoF from "../../resources/images/pill_shapes/pngs/med_x_powder_2.png";
import MedXPowderTwoFMask from "../../resources/images/pill_shapes/masks/med_x_powder_2_mask.png";
import NoPill from "./pills/NoPill";
import PropTypes from "prop-types";


export default class SelectedPill extends Component {

    getSelectedPill = () => {
        switch (this.props.pillSelection) {

            case 0:
                return (<NoPill selected={true}/>);
            case 1:
                return (<DoubleImageSolidPill MedF={MedCapsuleHSolidF} MedFMask={MedCapsuleHSolidFMask}
                                              MedM={MedCapsuleHSolidM} MedMMask={MedCapsuleHSolidMMask} selected={true}
                                              primaryColour={this.props.primaryColour}
                                              secondaryColour={this.props.secondaryColour}/>)
            case 2:
                return (<DoubleImageSolidPill MedF={MedCapsuleVSolidF} MedFMask={MedCapsuleVSolidFMask}
                                              MedM={MedCapsuleVSolidM} MedMMask={MedCapsuleVSolidMMask} selected={true}
                                              primaryColour={this.props.primaryColour}
                                              secondaryColour={this.props.secondaryColour}/>)
            case 3:
                return (
                    <DoubleImageSemiPill MedF={MedCapsuleVSemiF} MedFMask={MedCapsuleVSemiFMask} MedM={MedCapsuleVSemiM}
                                         MedMMask={MedCapsuleVSemiMMask} MedB={MedCapsuleVSemiB}
                                         MedBMask={MedCapsuleVSemiBMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 4:
                return (
                    <DoubleImageTranPill MedF={MedCapsuleVTranF} MedFMask={MedCapsuleVTranFMask} MedM={MedCapsuleVTranM}
                                         MedMMask={MedCapsuleVTranMMask} MedB={MedCapsuleVTranB}
                                         MedBMask={MedCapsuleVTranBMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 5:
                return (<GelPill MedF={MedGelSolidOneF} MedFMask={MedGelSolidOneFMask} MedB={MedGelSolidOneB}
                                 MedBMask={MedGelSolidOneBMask} selected={true} primaryColour={this.props.primaryColour}
                                 secondaryColour={this.props.secondaryColour}/>)
            case 6:
                return (<GelPill MedF={MedGelSolidTwoF} MedFMask={MedGelSolidTwoFMask} MedB={MedGelSolidTwoB}
                                 MedBMask={MedGelSolidTwoBMask} selected={true} primaryColour={this.props.primaryColour}
                                 secondaryColour={this.props.secondaryColour}/>)
            case 7:
                return (<GelPill MedF={MedGelSolidThreeF} MedFMask={MedGelSolidThreeFMask} MedB={MedGelSolidThreeB}
                                 MedBMask={MedGelSolidThreeBMask} selected={true}
                                 primaryColour={this.props.primaryColour}
                                 secondaryColour={this.props.secondaryColour}/>)
            case 8:
                return (<GelPill MedF={MedGelSolidFourF} MedFMask={MedGelSolidFourFMask} MedB={MedGelSolidFourB}
                                 MedBMask={MedGelSolidFourBMask} selected={true}
                                 primaryColour={this.props.primaryColour}
                                 secondaryColour={this.props.secondaryColour}/>)
            case 9:
                return (<SingleImagePill MedF={MedSphericSolidOneF} MedFMask={MedSphericSolidOneFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 10:
                return (<SingleImagePill MedF={MedSphericSolidTwoF} MedFMask={MedSphericSolidTwoFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 11:
                return (
                    <SingleImagePill MedF={MedSphericSolidThreeF} MedFMask={MedSphericSolidThreeFMask} selected={true}
                                     primaryColour={this.props.primaryColour}
                                     secondaryColour={this.props.secondaryColour}/>)
            case 12:
                return (<SingleImagePill MedF={MedPillRoundFlatLZeroSolidXF} MedFMask={MedPillRoundFlatLZeroSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 13:
                return (<SingleImagePill MedF={MedPillRoundFlatLZeroSolidLF} MedFMask={MedPillRoundFlatLZeroSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 14:
                return (<SingleImagePill MedF={MedPillRoundFlatLZeroSolidMF} MedFMask={MedPillRoundFlatLZeroSolidMFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 15:
                return (<SingleImagePill MedF={MedPillRoundFlatLZeroSolidSF} MedFMask={MedPillRoundFlatLZeroSolidSFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 16:
                return (<SingleImagePill MedF={MedPillRoundFlatLOneSolidXF} MedFMask={MedPillRoundFlatLOneSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 17:
                return (<SingleImagePill MedF={MedPillRoundFlatLOneSolidLF} MedFMask={MedPillRoundFlatLOneSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 18:
                return (<SingleImagePill MedF={MedPillRoundFlatLTwoSolidXF} MedFMask={MedPillRoundFlatLTwoSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 19:
                return (<SingleImagePill MedF={MedPillRoundFlatLTwoSolidLF} MedFMask={MedPillRoundFlatLTwoSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 20:
                return (<SingleImagePill MedF={MedPillRoundInLZeroSolidXF} MedFMask={MedPillRoundInLZeroSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 21:
                return (<SingleImagePill MedF={MedPillRoundInLZeroSolidLF} MedFMask={MedPillRoundInLZeroSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 22:
                return (<SingleImagePill MedF={MedPillRoundInLZeroSolidMF} MedFMask={MedPillRoundInLZeroSolidMFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 23:
                return (<SingleImagePill MedF={MedPillRoundInLZeroSolidSF} MedFMask={MedPillRoundInLZeroSolidSFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 24:
                return (<SingleImagePill MedF={MedPillRoundInLOneSolidXF} MedFMask={MedPillRoundInLOneSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 25:
                return (<SingleImagePill MedF={MedPillRoundInLOneSolidLF} MedFMask={MedPillRoundInLOneSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 26:
                return (<SingleImagePill MedF={MedPillRoundInLTwoSolidXF} MedFMask={MedPillRoundInLTwoSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 27:
                return (<SingleImagePill MedF={MedPillRoundInLTwoSolidLF} MedFMask={MedPillRoundInLTwoSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 28:
                return (<SingleImagePill MedF={MedPillRoundOutLZeroSolidXF} MedFMask={MedPillRoundOutLZeroSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 29:
                return (<SingleImagePill MedF={MedPillRoundOutLZeroSolidLF} MedFMask={MedPillRoundOutLZeroSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 30:
                return (<SingleImagePill MedF={MedPillRoundOutLZeroSolidMF} MedFMask={MedPillRoundOutLZeroSolidMFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 31:
                return (<SingleImagePill MedF={MedPillRoundOutLZeroSolidSF} MedFMask={MedPillRoundOutLZeroSolidSFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 32:
                return (<SingleImagePill MedF={MedPillRoundOutLOneSolidXF} MedFMask={MedPillRoundOutLOneSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 33:
                return (<SingleImagePill MedF={MedPillRoundOutLOneSolidLF} MedFMask={MedPillRoundOutLOneSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 34:
                return (<SingleImagePill MedF={MedPillRoundOutLTwoSolidXF} MedFMask={MedPillRoundOutLTwoSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 35:
                return (<SingleImagePill MedF={MedPillRoundOutLTwoSolidLF} MedFMask={MedPillRoundOutLTwoSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 36:
                return (
                    <SingleImagePill MedF={MedPillElipseFlatLZeroSolidXF} MedFMask={MedPillElipseFlatLZeroSolidXFMask}
                                     selected={true} primaryColour={this.props.primaryColour}
                                     secondaryColour={this.props.secondaryColour}/>)
            case 37:
                return (<SingleImagePill MedF={MedPillElipseFlatLOneSolidXF} MedFMask={MedPillElipseFlatLOneSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 38:
                return (<SingleImagePill MedF={MedPillElipseInLZeroSolidXF} MedFMask={MedPillElipseInLZeroSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 39:
                return (<SingleImagePill MedF={MedPillElipseInLOneSolidXF} MedFMask={MedPillElipseInLOneSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 40:
                return (<SingleImagePill MedF={MedPillElipseOutLZeroSolidXF} MedFMask={MedPillElipseOutLZeroSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 41:
                return (<SingleImagePill MedF={MedPillElipseOutLOneSolidXF} MedFMask={MedPillElipseOutLOneSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 42:
                return (<SingleImagePill MedF={MedPillRectangleFlatLZeroSolidXF}
                                         MedFMask={MedPillRectangleFlatLZeroSolidXFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 43:
                return (<SingleImagePill MedF={MedPillRectangleFlatLOneSolidXF}
                                         MedFMask={MedPillRectangleFlatLOneSolidXFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 44:
                return (
                    <SingleImagePill MedF={MedPillRectangleInLZeroSolidXF} MedFMask={MedPillRectangleInLZeroSolidXFMask}
                                     selected={true} primaryColour={this.props.primaryColour}
                                     secondaryColour={this.props.secondaryColour}/>)
            case 45:
                return (
                    <SingleImagePill MedF={MedPillRectangleInLOneSolidXF} MedFMask={MedPillRectangleInLOneSolidXFMask}
                                     selected={true} primaryColour={this.props.primaryColour}
                                     secondaryColour={this.props.secondaryColour}/>)
            case 46:
                return (<SingleImagePill MedF={MedPillRectangleOutLZeroSolidXF}
                                         MedFMask={MedPillRectangleOutLZeroSolidXFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 47:
                return (
                    <SingleImagePill MedF={MedPillRectangleOutLOneSolidXF} MedFMask={MedPillRectangleOutLOneSolidXFMask}
                                     selected={true} primaryColour={this.props.primaryColour}
                                     secondaryColour={this.props.secondaryColour}/>)
            case 48:
                return (
                    <SingleImagePill MedF={MedPillSquareFlatLZeroSolidXF} MedFMask={MedPillSquareFlatLZeroSolidXFMask}
                                     selected={true} primaryColour={this.props.primaryColour}
                                     secondaryColour={this.props.secondaryColour}/>)
            case 49:
                return (
                    <SingleImagePill MedF={MedPillSquareFlatLZeroSolidLF} MedFMask={MedPillSquareFlatLZeroSolidLFMask}
                                     selected={true} primaryColour={this.props.primaryColour}
                                     secondaryColour={this.props.secondaryColour}/>)
            case 50:
                return (<SingleImagePill MedF={MedPillSquareFlatLOneSolidXF} MedFMask={MedPillSquareFlatLOneSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 51:
                return (<SingleImagePill MedF={MedPillSquareFlatLOneSolidLF} MedFMask={MedPillSquareFlatLOneSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 52:
                return (<SingleImagePill MedF={MedPillSquareFlatLTwoSolidXF} MedFMask={MedPillSquareFlatLTwoSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 53:
                return (<SingleImagePill MedF={MedPillSquareFlatLTwoSolidLF} MedFMask={MedPillSquareFlatLTwoSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 54:
                return (<SingleImagePill MedF={MedPillSquareInLZeroSolidXF} MedFMask={MedPillSquareInLZeroSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 55:
                return (<SingleImagePill MedF={MedPillSquareInLZeroSolidLF} MedFMask={MedPillSquareInLZeroSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 56:
                return (<SingleImagePill MedF={MedPillSquareInLOneSolidXF} MedFMask={MedPillSquareInLOneSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 57:
                return (<SingleImagePill MedF={MedPillSquareInLOneSolidLF} MedFMask={MedPillSquareInLOneSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 58:
                return (<SingleImagePill MedF={MedPillSquareInLTwoSolidXF} MedFMask={MedPillSquareInLTwoSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 59:
                return (<SingleImagePill MedF={MedPillSquareInLTwoSolidLF} MedFMask={MedPillSquareInLTwoSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 60:
                return (<SingleImagePill MedF={MedPillSquareOutLZeroSolidXF} MedFMask={MedPillSquareOutLZeroSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 61:
                return (<SingleImagePill MedF={MedPillSquareOutLZeroSolidLF} MedFMask={MedPillSquareOutLZeroSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 62:
                return (<SingleImagePill MedF={MedPillSquareOutLOneSolidXF} MedFMask={MedPillSquareOutLOneSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 63:
                return (<SingleImagePill MedF={MedPillSquareOutLOneSolidLF} MedFMask={MedPillSquareOutLOneSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 64:
                return (<SingleImagePill MedF={MedPillSquareOutLTwoSolidXF} MedFMask={MedPillSquareOutLTwoSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 65:
                return (<SingleImagePill MedF={MedPillSquareOutLTwoSolidLF} MedFMask={MedPillSquareOutLTwoSolidLFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 66:
                return (<SingleImagePill MedF={MedPillTriangleFlatLZeroSolidXF}
                                         MedFMask={MedPillTriangleFlatLZeroSolidXFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 67:
                return (
                    <SingleImagePill MedF={MedPillTriangleFlatLOneSolidXF} MedFMask={MedPillTriangleFlatLOneSolidXFMask}
                                     selected={true} primaryColour={this.props.primaryColour}
                                     secondaryColour={this.props.secondaryColour}/>)
            case 68:
                return (
                    <SingleImagePill MedF={MedPillTriangleInLZeroSolidXF} MedFMask={MedPillTriangleInLZeroSolidXFMask}
                                     selected={true} primaryColour={this.props.primaryColour}
                                     secondaryColour={this.props.secondaryColour}/>)
            case 69:
                return (<SingleImagePill MedF={MedPillTriangleInLOneSolidXF} MedFMask={MedPillTriangleInLOneSolidXFMask}
                                         selected={true} primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 70:
                return (
                    <SingleImagePill MedF={MedPillTriangleOutLZeroSolidXF} MedFMask={MedPillTriangleOutLZeroSolidXFMask}
                                     selected={true} primaryColour={this.props.primaryColour}
                                     secondaryColour={this.props.secondaryColour}/>)
            case 71:
                return (
                    <SingleImagePill MedF={MedPillTriangleOutLOneSolidXF} MedFMask={MedPillTriangleOutLOneSolidXFMask}
                                     selected={true} primaryColour={this.props.primaryColour}
                                     secondaryColour={this.props.secondaryColour}/>)
            case 100:
                return (<SingleImagePill MedF={MedXAmpouleOneF} MedFMask={MedXAmpouleOneFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 101:
                return (<SingleImagePill MedF={MedXAmpouleTwoF} MedFMask={MedXAmpouleTwoFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 102:
                return (<SingleImagePill MedF={MedXVialF} MedFMask={MedXVialFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 103:
                return (<SingleImagePill MedF={MedXSprayF} MedFMask={MedXSprayFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 104:
                return (<SingleImagePill MedF={MedXSprayNasalF} MedFMask={MedXSprayNasalFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 105:
                return (<SingleImagePill MedF={MedXSprayOralF} MedFMask={MedXSprayOralFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 106:
                return (<SingleImagePill MedF={MedXSyringeF} MedFMask={MedXSyringeFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 107:
                return (<SingleImagePill MedF={MedXInsulinPenF} MedFMask={MedXInsulinPenFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 108:
                return (<SingleImagePill MedF={MedXSpoonF} MedFMask={MedXSpoonFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 109:
                return (<SingleImagePill MedF={MedXInhalerF} MedFMask={MedXInhalerFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 110:
                return (<SingleImagePill MedF={MedXOintmentF} MedFMask={MedXOintmentFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 111:
                return (<SingleImagePill MedF={MedXEyeDropsF} MedFMask={MedXEyeDropsFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 112:
                return (<SingleImagePill MedF={MedXPipetteF} MedFMask={MedXPipetteFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 113:
                return (<SingleImagePill MedF={MedXPowderOneF} MedFMask={MedXPowderOneFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 114:
                return (<SingleImagePill MedF={MedXPowderTwoF} MedFMask={MedXPowderTwoFMask} selected={true}
                                         primaryColour={this.props.primaryColour}
                                         secondaryColour={this.props.secondaryColour}/>)
            case 9001:
                return (<div className="pill-picker-selected"/>)
            default:
                return (<NoPill selected={true}/>)
        }
    }

    render() {
        return (this.getSelectedPill())
    }
}

SelectedPill.propTypes = {
    primaryColour: PropTypes.string,
    secondaryColour: PropTypes.string,
    pillSelection: PropTypes.number
}

export default {
  header: {
    self: {},
    items: []
  },
  aside: {
    self: {},
    items: [
      {
        title: "DashboardDashboard",
        root: true,
        icon: "flaticon-imac",
        page: "dashboard",
        translate: "dashboard",
        bullet: "dot"
      },
      {
        title: "Main",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "medical-record",
        translate: "main_page_label",
        bullet: "dot"
      },
      {
        title: "Health Issues",
        root: true,
        icon: "flaticon-list-2",
        page: "health-issues",
        translate: "health_issues_menu_label",
      },
      {
        title: "Medications",
        root: true,
        icon: "flaticon2-contrast",
        page: "medications",
        translate: "Medications",
      },
      {
        title: "Clinicians",
        root: true,
        icon: "flaticon-users",
        page: "clinicians",
        translate: "clinicians_label",
        bullet: "dot"
      },
      {
        title: "Messages",
        root: true,
        icon: "flaticon-comment",
        page: "messages",
        translate: "messages_label",
        bullet: "dot"
      },
      {
        title: "Appointments",
        root: true,
        icon: "flaticon-calendar-2",
        page: "appointments",
        translate: "my_appointments_label",
        bullet: "dot"
      },
/*      {
        title: "Sharing",
        root: true,
        icon: "flaticon-share",
        page: "sharing",
        translate: "sharing_label",
        bullet: "dot"
      }, */
      {
        title: "Settings",
        root: true,
        icon: "flaticon-settings",
        page: "settings",
        translate: "settings_label",
        bullet: "dot"
      },
      {
        title: "Aptechko",
        root: true,
        aptechko: true
      }
    ]
  }
};

import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import Search from '../shared/Search';
import Select from '../shared/Select'
import PropTypes from "prop-types";
import DateRangePicker from "../shared/DateRangePicker";

class SymptomsHeader extends Component {
    state = {
        symptomDate: new Date()
    }

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="form-inline" style={{'backgroundColor': 'white', 'paddingTop': '10px'}}>
                <div style={{'display': 'flex', 'flexWrap': 'wrap', 'width': '100%'}}>
                    <h1 style={{'marginRight': 'auto'}}>
                        {$$('symptoms_history')}
                    </h1>
                    <div className="" style={{'display': 'flex', 'flexWrap': 'wrap'}}>
                        <div className="" style={{'display': 'flex', 'marginRight': '2rem'}}>
                            <Select
                                name="issues-type"
                                options={this.props.symptomsOptions}
                                value={this.props.pickedOption ? this.props.pickedOption : 0}
                                onChange={this.props.onSelectChange}
                                style={{
                                    'minWidth': '-10.188rem',
                                    'minHeight': '2.938rem',
                                    'maxWidth': '18.75rem',
                                    'zIndex': '1',
                                    'opacity': '70%'
                                }}
                                placeHolder={$$('health_issues_label')}/>
                        </div>
                        <div className='search-box2' style={{'marginRight': '2rem'}}>
                            <Search
                                filterUsers={this.props.onSearchChange}
                                placeholder={$$('search_placeholder_label')}/>
                        </div>

                        {/*<div className='search-box2' style={{'marginRight': '2rem'}}>

                            <DateRangePicker
                                i18n = {this.props.i18n}
                                onRangeSelected={this.props.onRangeSelected}
                            />
                        </div>*/}



                    </div>
                </div>
            </div>
        )
    }
}
SymptomsHeader.propTypes = {
    onSearchChange: PropTypes.func,
    onSelectChange: PropTypes.func,
    onRangeSelected: PropTypes.func,
    pickedOption: PropTypes.any,
    i18n: PropTypes.any,
    symptomsOptions: PropTypes.array
};

export default SymptomsHeader

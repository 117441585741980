import React, {Component} from 'react'
import PropTypes from "prop-types";
import {getClinicianNameWithTitle} from "../../utils/getClinicianNameWithTitle";

export default class ClinicianName extends Component {

    render() {
        return (
            <span
                className={this.props.className ? this.props.className : 'clinician-fullname'}
                onClick={() => {
                    (this.props.onNameClick) ? this.props.onNameClick(this.props.clinician) : null
                }}>
                {getClinicianNameWithTitle(this.props.clinician)}
            </span>
        );
    }
}

ClinicianName.propTypes = {
    className: PropTypes.string,
    clinician: PropTypes.object,
    i18n: PropTypes.object,
    fromCreateAppointment: PropTypes.any,
    onNameClick: PropTypes.func
}

import React, {useEffect, useState} from "react";
import CenteredModal from "../shared/CenteredModal";
import {$$} from "../../helpers/localization";
import UserItem from "./UserItem";

export function AppointmentUserPicker({selected, loggedInUser, users, onSelected, onCancel}) {
    const [selectedUser, setSelectedUser] = useState(selected);

    useEffect(()=>{
        if (selectedUser !== selected) {
            setSelectedUser(selected)
        }
    }, [selected])

    if (!users || users.length === 0) {
        return null;
    }

    return <CenteredModal
        show={true}
        onHide={onCancel}
        onConfirm={()=>{onSelected(selectedUser)}}
        secondary={true}
        title={$$("select_user_for_appointment")}
    >
        <p>{$$("select_user_for_appointment_modal_body")}</p>
        <h6>{$$("main_user")}</h6>
        <div className="list-group">
            <UserItem key={loggedInUser.id} name={loggedInUser.fullname}
                      user={loggedInUser} id={loggedInUser.id}
                      selected={selectedUser.id === loggedInUser.id}
                      onItemClick={(id, name, user)=>{
                          setSelectedUser(user);
                      }} />

        </div>
        <br/>

        <h6>{$$("connected_profiles")}</h6>
        <div className="list-group">
            {users.map(u => {
                return <UserItem key={u.id} name={u.fullname}
                                 user={u} id={u.id}
                                 selected={selectedUser.id === u.id}
                                 onItemClick={(id, name, user)=>{
                                     setSelectedUser(user);
                                 }} />
            })}
        </div>
    </CenteredModal>
}
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {selectUser, upgradeChildToFullUser} from '../../actions/users_actions'
import UserProfileForm from './UserProfileForm';
import moment from 'moment'
import PropTypes from "prop-types";

class UserProfile extends Component {
    state = {
        successfulUpdate: false
    }

    constructor(props) {
        super(props);
    }

    /**
     * Check if update opeartin is done, if so display successful update message.
     *
     * @param {object} prevProps - the previous props
     */
    componentDidUpdate(prevProps) {
        const prevUserInfo = prevProps.userInfo;
        const currentUserInfo = this.props.userInfo
        if (prevUserInfo) {
            if (moment(prevUserInfo.date_modified).valueOf() < moment(currentUserInfo.date_modified).valueOf()) {
                this.setState({successfulUpdate: true});
            }
        }
    }

    render() {
        return (
            <UserProfileForm
                showSuccessfulAlert={this.state.successfulUpdate}
                userInfo={this.props.userInfo}
                parentId={this.props.parentId}
                parent={this.props.parent}
                selectedUser={this.props.selectUser}
                updateUserInfo={this.props.updateUserInfo}
                upgradeChildToFullUser={this.props.upgradeChildToFullUser}
                medicalProfile={this.props.medicalProfile}
                i18n={this.props.i18n}
                upgrade={this.props.upgrade}

            />
        )
    }
}


const mapStateToProps = (state) => ({
    medicalProfile: state.medicalProfile,
    parentId: state.userInfo.data.id,
    parent: state.userInfo.data,
    userInfo: state.selectedUser.data,
    i18n: state.language,
    upgrade: state.childToStandalone
})

const mapDispatchToProps = {
    upgradeChildToFullUser,
    selectUser
}

UserProfile.propTypes = {
    i18n: PropTypes.object,
    medicalProfile: PropTypes.object,
    parent: PropTypes.object,
    parentId: PropTypes.string,
    selectUser: PropTypes.func,
    tabIndex: PropTypes.number,
    updateUserInfo: PropTypes.func,
    upgrade: PropTypes.object,
    upgradeChildToFullUser: PropTypes.func,
    userInfo: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);

import React, {Component} from 'react'
import SubHeader from './SubHeader';
import PropTypes from "prop-types";

export class Tabs extends Component {

    state = {
        activeTabIndex: this.props.defaultActiveTabIndex,
        activeSubTabIndex: null
    }

    constructor(props) {
        super(props);
    }

    /**
     * Handle tab click, set active tab index to state
     *
     * @param {number} tabIndex the index of the tab to be set to active
     */
    handleTabClick = (tabIndex) => {
        this.setState({
            activeTabIndex: tabIndex ? tabIndex : 0,
            activeSubTabIndex: null
        });
    }

    /**
     * Handle sub tab click, set active sub tab index to state
     *
     * @param {number} subTabIndex the index of the sub tab to be set to active
     */
    handleSubTabClick = (subTabIndex) => {
        this.setState({
            activeSubTabIndex: subTabIndex ? subTabIndex : this.props.defaultActiveSubTabIndex,
        });
    }

    /**
     * Render tabs, add properties
     *
     * @returns {Array} array of React elements
     */
    renderTabs() {
        return React.Children.map(this.props.children, (child, index) => {
            if (child) {
                return React.cloneElement(child, {
                    onClick: this.handleTabClick,
                    tabIndex: index,
                    isActive: index === this.state.activeTabIndex,
                });
            }
            return '';
        });
    }

    /**
     * Render children content
     *
     * @returns {Array} array of React elements
     */
    renderChildrenContent(children) {
        // eslint-disable-next-line no-unused-vars
        return React.Children.map(children, (child, index) => {
            if (child) {
                return React.cloneElement(child, {
                    tabIndex: this.state.activeTabIndex
                });
            }
            return '';
        });
    }

    /**
     * Render sub header. If sub header contains tabs,
     * pass the necesary properties to the sub header component(activeSubHeaderIndex is mandatory in the Tabs component).
     * If not filter only SubHeader elements.
     *
     * @returns {Array} array of React elements
     */
    renderSubHeader() {
        // eslint-disable-next-line react/prop-types
        const {children} = this.props;
        const {activeTabIndex} = this.state;
        const activeChild = Array.isArray(children) ? children[activeTabIndex] : children;
        const activeSubTabIndex = this.getActiveSubTabIndex(children, activeTabIndex);
        if (activeChild.props && activeChild.props.children) {
            const subChild = activeSubTabIndex !== null && activeSubTabIndex !== undefined ? activeChild.props.children : null;
            if (subChild && subChild.props.children && subChild.props.children[activeSubTabIndex]) {
                return React.cloneElement(subChild, {
                    onClick: this.handleSubTabClick,
                    activeSubTabIndex: activeSubTabIndex,
                });
            } else if (activeChild.props.children.length > 0 && activeChild.props.children.some(c => c.type === SubHeader)) {
                return activeChild.props.children.filter(c => c.type === SubHeader);
            }
        }
    }

    /**
     * Render the active tab content. If tab has subtabs render active subtab content.
     *
     * @returns {Array} array of React elements
     */
    renderTabContent() {
        // eslint-disable-next-line react/prop-types
        const {children} = this.props;
        const {activeTabIndex} = this.state;
        const activeChild = Array.isArray(children) ? children[activeTabIndex] : children;
        const activeSubTabIndex = this.getActiveSubTabIndex(children, activeTabIndex);
        if (activeChild && activeChild.props.children) {
            if (activeSubTabIndex !== null && activeSubTabIndex !== undefined) {
                return activeChild.props.children.props.children[activeSubTabIndex].props.children;
            } else if (Array.isArray(activeChild.props.children) && activeChild.props.children.length > 0) {
                return activeChild.props.children.filter(c => c.type !== SubHeader);
            } else if (activeChild.props.children.type !== SubHeader) {
                return this.renderChildrenContent(activeChild.props.children);
            }
        }
    }

    /**
     * Get the active sub tab index if there is any
     *
     * @param {object} children - children of the tabs component
     * @param {number} activeTabIndex - index of the active tab
     * @returns {Array} array of React elements
     */
    getActiveSubTabIndex(children, activeTabIndex) {
        let activeSubTabIndex = null;
        if (this.state.activeSubTabIndex) {
            activeSubTabIndex = this.state.activeSubTabIndex;
        } else if (children[activeTabIndex] && children[activeTabIndex].props) {
            activeSubTabIndex = children[activeTabIndex].props.defaultActiveSubTabIndex;
        }
        return activeSubTabIndex;
    }

    render() {
        return (
            <div style={{minWidth: "100px"}}>
                <ul className="nav nav-tabs nav-tabs-line position-relative" role="group">
                    {this.renderTabs()}
                </ul>
                <div>
                    {this.renderSubHeader()}
                </div>
                <div className="card-body main-card">
                    {this.renderTabContent()}
                </div>
            </div>

        )
    }
}

Tabs.propTypes = {
    defaultActiveTabIndex: PropTypes.number,
    defaultActiveSubTabIndex: PropTypes.number,
    children: PropTypes.any
}

export default Tabs

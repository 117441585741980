import React, {Component} from 'react'
import Symptoms from './Symptoms';
import moment from 'moment';
import {getSymptomName} from '../../utils/symptomUtils';
import PropTypes from "prop-types";
import {removeUndefineds} from "../video/utils";

export class GroupedSymptoms extends Component {
    state = {
        allGroup: false,
        groupActive: 0,
        symptoms: {},
        filteredSymptoms: this.props.symptoms,
        groupLength: this.props.groupLength,
    }

    constructor(props) {
        super(props);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.searchValue !== this.props.searchValue) || (prevProps.symptoms !== this.props.symptoms) ||  (prevProps.pickedSelectOption !== this.props.pickedSelectOption)) {
            this.filterSymptoms();
        }
    }

    componentDidMount() {
        this.filterSymptoms();
    }

    toggleGroup = () => {
        this.setState({allGroup: !this.state.allGroup})
    }
    changeGroup = () => {
        this.props.onDateTimeChange(this.props.date_time)
    }
    changeGroupActive = (idx) => {
        this.setState({
            groupActive: idx
        })
    }


    filterBySymptom = (arrayToFilter) => {
        return arrayToFilter.filter(u => {
            if (this.props.searchValue === "") {
                return true;
            }
            let hasSymptom = false;
            u.symptoms.map((symptom) => {
                if (!symptom.description) hasSymptom = false
                else if (getSymptomName(symptom, this.props.nomenclature, this.props.i18n.lang).toLowerCase().indexOf(this.props.searchValue.toLowerCase()) !== -1) {
                    hasSymptom = true;
                }
            })

            return hasSymptom;
        })
    }

    filterByDateTime = (symptoms) => {
        return symptoms.filter(u => {
            return u.date_time >= this.props.startDate && u.date_time <= this.props.endDate;
        })
    }


    filterSymptoms = () => {
        let filteredBySelection = this.filterBySelection(this.props.symptoms);
        let filteredBySymptom = this.filterBySymptom(filteredBySelection);
        let filteredByTime = this.filterByDateTime(filteredBySymptom)

        let allFilteredBySelection = this.filterBySelection(this.props.allEntries);
        let allFilteredBySymptom = this.filterBySymptom(allFilteredBySelection);
        let allFilteredByTime = this.filterByDateTime(allFilteredBySymptom)

        this.setState({
            filteredSymptoms: filteredByTime,
            groupLength: filteredByTime.length
        })
        if (filteredByTime.length > 0) {
            if (this.props.hasContent !== true)
                this.props.onHasContent(true);
        }

        if(allFilteredByTime.length === 0){
            this.props.onHasContent(false);
        }
    }

    filterBySelection = (symptoms) => {
        return symptoms.filter((entry) => {
            let hasHealthIssue = false;
            if (this.props.pickedSelectOption === 0)
                return true;
            if (entry.health_issues_ids != null) {
                entry.health_issues_ids.map((h) => {
                    if (this.props.pickedSelectOption === 0)
                        hasHealthIssue = true;
                    if (h === this.props.pickedSelectOption) {
                        hasHealthIssue = true;
                    }
                })
            }
            return hasHealthIssue;
        })
    }

    /**
     * Get the symptoms to show
     *
     * @returns {Array} of symptoms
     */
    getGroupedSymptoms = () => {
        if (this.props.symptoms && this.props.symptoms.length > 0) {
            if (this.state.filteredSymptoms.length > 0) {
                return this.state.filteredSymptoms.map((entry, idx) => {
                    return <Symptoms
                        groupActive={this.props.selectedDateTime === moment(entry.date_time).format("YYYY-MM-DD")}
                        groupLength={this.state.groupLength}
                        changeGroup={this.changeGroup}
                        toggleGroup={this.toggleGroup}
                        changeGroupActive={this.changeGroupActive}
                        allGroup={this.state.allGroup}
                        key={idx}
                        indexOfGroup={this.props.indexOfGroup}
                        index={idx}
                        entry={entry}
                        nomenclature={this.props.nomenclature.data.nomenclature}
                        showSymptomsList={this.props.showSymptomsList}
                        i18n={this.props.i18n}
                        fetchSelectedUserHealthIssues={this.props.fetchSelectedUserHealthIssues}
                        healthIssues={this.props.healthIssues}/>
                });

            }
        } else {
            return "";
        }
    }

    render() {
        if (this.state.filteredSymptoms.length > 0) {
            return <div className="margin-on-big">
                <ol className="logbook-ol symptoms-ol">
                    {this.getGroupedSymptoms()}
                </ol>
            </div>;
        } else {
            return null
        }
    }
}

GroupedSymptoms.propTypes = {
    date_time: PropTypes.string,
    fetchSelectedUserHealthIssues: PropTypes.func,
    groupLength: PropTypes.any,
    hasContent: PropTypes.bool,
    healthIssues: PropTypes.object,
    i18n: PropTypes.object,
    indexOfGroup: PropTypes.number,
    nomenclature: PropTypes.object,
    onDateTimeChange: PropTypes.func,
    onHasContent: PropTypes.func,
    pickedSelectOption: PropTypes.any,
    searchValue: PropTypes.string,
    selectedDateTime: PropTypes.string,
    allEntries: PropTypes.any,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    settings: PropTypes.object,
    showSymptomsList: PropTypes.func,
    symptoms: PropTypes.array
};

export default GroupedSymptoms

import React, {Component} from 'react'
import {
    Bar,
    Chart
} from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import 'chartjs-plugin-datalabels'
import $ from 'jquery';
import PropTypes from "prop-types";

export class BarChart extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * Get array of box annotations for the background rectangles.
     *
     * @returns {array} array of box annotation objects
     */
    getBoxAnnotations = () => {
        return this.props.chartData.regions.map((r, i) => {
            return {
                type: 'box',
                drawTime: 'beforeDatasetsDraw',
                id: 'a-box-' + i,
                xScaleID: 'x-axis-0',
                yScaleID: 'y-axis-0',
                yMax: r.ymax,
                yMin: r.ymin,
                borderWidth: 0,
                borderColor: 'rgba(0, 0, 0, 0)',
                backgroundColor: r.color,
            }
        });
    }

    /**
     * Get array for the x-axis properties.
     *
     * @returns {array} array of x-axis properties
     */
    getXAxesOptions = () => {
        return this.props.chartData.xAxis.map(a => {
            return {
                beginAtZero: true,
                scaleLabel: {
                    display: true,
                    labelString: a.label
                },
                gridLines: {
                    borderDash: [8, 5]
                }
            }
        });
    }

    /**
     * Get array for the y-axis properties.
     *
     * @returns {array} array of y-axis properties
     */
    getYAxesOptions = () => {
        return this.props.chartData.yAxis.map(a => {
            return {
                ticks: {
                    beginAtZero: false,
                    max: this.props.yAxesRange ? this.props.yAxesRange.max : 210,
                    min: this.props.yAxesRange ? this.props.yAxesRange.min : 30,
                    stepSize: this.props.yAxesRange ? this.props.yAxesRange.step : 30,
                },
                scaleLabel: {
                    display: true,
                    labelString: a.label
                },
                gridLines: {
                    borderDash: [8, 5]
                }
            }
        });
    }

    render() {
        if (!this.props.chartData.data) {
            return '';
        }

        const options = {
            scales: {
                yAxes: this.getYAxesOptions(),
                xAxes: this.getXAxesOptions()
            },
            responsive: true,
            maintainAspectRatio: false,
            showDataPoints: true,
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        const dataset = data.datasets[tooltipItem.datasetIndex];
                        return `${dataset.label}: [${dataset.data[tooltipItem.index][1]}, ${dataset.data[tooltipItem.index][0]}]`
                    }
                }
            },
            plugins: {
                datalabels: {
                    align: 'start',
                    anchor: 'start',
                    // eslint-disable-next-line no-unused-vars
                    formatter: function (value, context) {
                        const num = parseFloat(value);
                        if (!isNaN(num) && !this.props.hideLowDataLabel) {
                            return Math.round(num);
                        }
                        return '';
                    }.bind(this),
                    font: function (context) {
                        const height = context.chart.height;
                        const width = context.chart.width;
                        if (width < 300) {
                            return {
                                size: height * 3 / 100,
                            };
                        } else {
                            return {
                                size: Chart.defaults.global.defaultFontSize
                            };
                        }
                    }
                }
            },
            annotation: {
                annotations: this.getBoxAnnotations()
            }
        }

        const chartData = $.extend(true, {}, this.props.chartData.data);

        return (
            <div className={this.props.small ? "card-body-small chart-card " : "card-body chart-card"}>
                <div className="card-title chart-title">
                    {this.props.chartData.label}
                </div>
                <div className={this.props.small ? "chart-container-small" : "chart-container"}>
                    <Bar data={chartData} options={this.props.options ? this.props.options : options}/>
                </div>
            </div>
        )
    }
}

BarChart.propTypes = {
    chartData: PropTypes.object,
    hideLowDataLabel: PropTypes.bool,
    settings: PropTypes.object,
    small: PropTypes.bool,
    yAxesRange: PropTypes.object,
    options: PropTypes.any
}

export default BarChart

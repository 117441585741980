import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {$$} from '../../helpers/localization'
import {API_APPLE_REDIRECT_URI, FORGOT_PASSWORD_URL} from '../../constants/api_paths'
import classnames from 'classnames'
import {connect} from 'react-redux'
import {logout} from '../../actions/auth_actions'
import PropTypes from "prop-types";


export class LoginForm extends Component {
    state = {
        email: '',
        password: '',
        formclass: '',
        errors: {},
    };

    constructor(props) {
        super(props);
    }

    static getDerivedStateFromProps(props) {
        if (props.email && props.password) {
            return {email: props.email, password: props.password};
        }
        return null;
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.i18n !== this.props.i18n) {
            this.renderGAPIButton()
        }
    }

    renderGAPIButton = () => {
        window.google.accounts.id.renderButton(document.getElementById("gapi-button"),
            {   theme: 'filled_blue',
                width: 245,
                shape:'rectangular',
                locale: this.props.i18n.selected.lang,
                size: 'large'})
    }
    initialiseGoogleSignIn() {
        window.google.accounts.id.initialize({
            // eslint-disable-next-line no-undef
            client_id: process.env.GOOGLE_SIGIN_CLIENTID,
            callback: (response) => {
                this.props.onLoginWithGoogle(response.credential, true);
            }
        });

        this.renderGAPIButton()
        //window.google.accounts.id.prompt();

    }

    componentDidMount() {
        this.initialiseGoogleSignIn();
        if (this.props.email && this.props.password) {
            this.props.onLogin(this.props.email, this.props.password, true);
        }
        var self = this;
        // eslint-disable-next-line no-undef
        /*FB.Event.subscribe('auth.authResponseChange', function (response) {
            if (response.status === "connected") {
                self.props.onLoginWithFacebook(response.authResponse.accessToken, true);
            }
        });
        // eslint-disable-next-line no-undef
        FB.XFBML.parse(); */

        let clientId = 'com.sirma.medrecm.web';
        // eslint-disable-next-line no-undef
        AppleID.auth.init({
            clientId: clientId,
            scope: 'name email',
            redirectURI: API_APPLE_REDIRECT_URI,
            state: new String(Math.random()).substr(2, 100),
            nonce: new String(Math.random()).substr(2, 100),
            usePopup: true
        });

        // Listen for authorization success.
        this.listener = (event) => {
            // Handle successful response.
            self.props.onLoginWithApple(event.detail.authorization.id_token, clientId, event.detail.user);
        };
        document.addEventListener('AppleIDSignInOnSuccess', this.listener);

        // Listen for authorization failures.
        this.listener1 = (event) => {
            // Handle error.
            console.log(event.detail);
        };
        document.addEventListener('AppleIDSignInOnFailure', this.listener1);
    }

    componentWillUnmount() {
        try {
            document.removeEventListener('AppleIDSignInOnSuccess', this.listener);
            document.removeEventListener('AppleIDSignInOnFailure', this.listener1);
        } catch (e) {
            //nop
        }
    }


    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };
    /**
     * On label clicked handler, set the correct value to state
     *
     * @param {object} evt - The event handler argument
     */
    onLabelClick = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.htmlFor] = !fields[evt.target.htmlFor];
        this.setState(fields);
    }

    /**
     * Form submit handler, validate data and set error in state if any. Call login action and clear state.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();

        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }

        if (evt.target.checkValidity() === true) {
            const email = this.state.email;
            const password = this.state.password;
            this.props.onLogin(email, password, null);
            this.setState({
                email: '',
                password: '',
                formclass: ''
            })
        }
    }

    /**
     * Open new tab with the forgot password url
     */
    onForgotPasswordClick = () => {
        window.open(FORGOT_PASSWORD_URL.replace("{lang}", this.props.i18n.selected.lang), "_blank");
    }

    /**
     * Get error message to display based on error code
     *
     * @returns {string} error message to display
     */
    getErrorMessage() {
        if (this.props.auth_data.request && this.props.auth_data.request?.error) {
            switch (this.props.auth_data.request?.error?.status) {
                case 401:
                    if (this.props.auth_data.request?.error?.message === 'TEMP_LOCKED_DOWN') {
                        return $$('login_form_temp_locked_down_message');
                    }
                    if (this.props.auth_data.request?.error?.message === 'MUST_RESET_PASSWORD') {
                        return $$('login_form_must_reset_password_message');
                    }
                    return $$('login_form_invalid_credentials_message');
                default:
                    return this.props.auth_data.request?.error?.message
            }
        } else if (this.props.auth_data.refreshRequest?.error) {
            if (this.props.auth_data.refreshRequest.error.message === 'AUTO_LOGOUT') {
                return $$('auto_logout');
            }
            switch (this.props.auth_data.refreshRequest?.error?.status) {
                case 401:
                    return $$('refresh_token_expired_message');
                default:
                    return this.props.auth_data.refreshRequest?.error?.message
            }
        }
    }


    render() {
        let errorMsg = '';
        if ((this.props.auth_data.request && this.props.auth_data.request?.error) || this.props.auth_data.refreshRequest?.error) {
            errorMsg = (
                <div className="alert alert-danger">
                    {this.getErrorMessage()}
                </div>
            );
        }


        const spinnerClass = classnames({
            "spinner-border": true,
            "hidden": !(this.props.email && this.props.password)
        });

        return (
            <div className="kt-login__body d-flex justify-content-center">
                <div className="kt-login__form">
                    <div className="kt-login__title">
                        <h2 className="text-center">{$$('login_form_title')}</h2>
                    </div>
                    <form onSubmit={this.onSubmit} className={this.state.formclass} noValidate={true}>
                        {errorMsg}
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control"
                                value={this.state.email}
                                placeholder={$$('placeholder_email_message')}
                                name="email"
                                onChange={this.onInputChange}
                                required
                            />
                            <div className="invalid-feedback">
                                {$$('email_required_message')}
                            </div>
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                value={this.state.password}
                                placeholder={$$('placeholder_password_message')}
                                name="password"
                                onChange={this.onInputChange}
                                required
                            />
                            <div className="invalid-feedback">
                                {$$('password_required_message')}
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-xs-12 col-md-6 mx-auto mt-2">
                                <Link className="btn btn-secondary btn-block" to="/register">
                                    {$$("register_label")}
                                </Link>
                            </div>
                            <div className="col-xs-12 col-md-6 mx-auto mt-2">
                                <button type="submit" className="btn btn-success btn-block">
                                    {$$("login_label")}
                                    <div className={spinnerClass + " loading-margin"} role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <hr className="hr-text" data-content={$$("social_media_login_separator")}/>
                        <div className="form-group d-flex social-buttons">
                                <div
                                    id="gapi-button"
                                />
                            {/*
                                <div className="fb-login-btn-position">
                                    <div
                                        className="fb-login-button"
                                        data-size="large"
                                        data-button-type="continue_with"
                                        data-layout="default"
                                        data-auto-logout-link="false"
                                        data-use-continue-as="false"
                                    />
                                </div>
                            */}
                        </div>
                        <div className="d-flex justify-content-center">
                            <div id="appleid-signin"
                                 className="apple-signin-button"
                                 data-color="black"
                                 data-border="true"
                                 data-type="sign in"
                            />
                        </div>
                        <div className="form-group text-center small font-weight-lighter pt-5">
                            <a href="#"
                               className="forgot-password kt-footer__menu-link kt-link"
                               onClick={this.onForgotPasswordClick}>{$$('forgotten_password')}</a>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

LoginForm.propTypes = {
    auth_data: PropTypes.object,
    email: PropTypes.string,
    i18n: PropTypes.object,
    logout: PropTypes.func,
    onLogin: PropTypes.func,
    onLoginWithFacebook: PropTypes.func,
    onLoginWithGoogle: PropTypes.func,
    onLoginWithApple: PropTypes.func,
    password: PropTypes.string,
}

const mapDispatchToProps = {
    logout
}


export default connect(null, mapDispatchToProps)(LoginForm);

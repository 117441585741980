export const SYMPTOMS = {
    "bg": {
        "All": [{
            "description": "Общи симптоми",
            "nomenclature": "0",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SYMPTOMS_CAT"
        }, {
            "description": "Втрисане",
            "nomenclature": "0.1",
            "selectable": false,
            "strength": 0,
            "type": "CHILLS"
        }, {
            "description": "Треска",
            "nomenclature": "0.2",
            "selectable": false,
            "strength": 0,
            "type": "FEVER_CAT"
        }, {
            "description": "Треска",
            "nomenclature": "0.2.1",
            "selectable": false,
            "strength": 0,
            "type": "FEVER"
        }, {
            "description": "Висока температура",
            "nomenclature": "0.2.2",
            "selectable": false,
            "strength": 0,
            "type": "FEVER_HIGH_TEMPERATURE"
        }, {
            "description": "Умора",
            "nomenclature": "0.3",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_CAT"
        }, {
            "description": "Умора",
            "nomenclature": "0.3.0",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE"
        }, {
            "description": "Изтощение",
            "nomenclature": "0.3.1",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_EXHAUSTED"
        }, {
            "description": "Обща слабост",
            "nomenclature": "0.4.2",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_GENERAL_WEAKNESS"
        }, {
            "description": "Изцеден",
            "nomenclature": "0.3.3",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_POOPED"
        }, {
            "description": "Повален",
            "nomenclature": "0.3.4",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_RUNDOWN"
        }, {
            "description": "Изморен",
            "nomenclature": "0.3.5",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_TIRED"
        }, {
            "description": "Износен",
            "nomenclature": "0.3.6",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_WORN_OUT"
        }, {
            "description": "Общо неразположение",
            "nomenclature": "0.4",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_FEELING_CAT"
        }, {
            "description": "Не се чувствам добре",
            "nomenclature": "0.4.1",
            "selectable": false,
            "strength": 0,
            "type": "NOT_FEELING_WELL"
        }, {
            "description": "Дисбаланс на течностите",
            "nomenclature": "0.5",
            "selectable": false,
            "strength": 0,
            "type": "FLUID_IMBALANCE_CAT"
        }, {
            "description": "Дехидратация",
            "nomenclature": "0.5.1",
            "selectable": false,
            "strength": 0,
            "type": "DEHYDRATION"
        }, {
            "description": "Прекомерно изпотяване",
            "nomenclature": "0.5.2",
            "selectable": false,
            "strength": 0,
            "type": "FLUID_IMBALANCE_EXCESSIVE_SWEATING"
        }, {
            "description": "Прекомерна жажда",
            "nomenclature": "0.5.3",
            "selectable": false,
            "strength": 0,
            "type": "FLUID_IMBALANCE_EXCESSIVE_THIRST"
        }, {
            "description": "Задържане на течности",
            "nomenclature": "0.5.4",
            "selectable": false,
            "strength": 0,
            "type": "FLUID_IMBALANCE_RETENTION_OF_FLUID"
        }, {
            "description": "Забавено физиологическо развитие",
            "nomenclature": "0.6",
            "selectable": false,
            "strength": 0,
            "type": "LACK_PHYS_DEVELOPMENT_CAT"
        }, {
            "description": "Забавен растеж",
            "nomenclature": "0.6.1",
            "selectable": false,
            "strength": 0,
            "type": "LACK_PHYS_DEVELOPMENT_LACK_GROWTH"
        }, {
            "description": "Качване на тегло",
            "nomenclature": "0.7",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_GAIN_CAT"
        }, {
            "description": "Наднормено тегло",
            "nomenclature": "0.7.1",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_GAIN_OVERWEIGHT"
        }, {
            "description": "Затлъстяване",
            "nomenclature": "0.7.2",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_GAIN_OBESITY"
        }, {
            "description": "Загуба на тегло",
            "nomenclature": "0.8",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_LOSS_CAT"
        }, {
            "description": "Скорошна загуба на тегло",
            "nomenclature": "0.8.1",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_LOSS_RECENT_WEIGHT_LOSS"
        }, {
            "description": "Поднормено тегло",
            "nomenclature": "0.8.2",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_LOSS_UNDERWEIGHT"
        }, {
            "description": "Болка, генерализирана, място неуточнено",
            "nomenclature": "0.9",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_CAT"
        }, {
            "description": "Болезненост",
            "nomenclature": "0.9.1",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_ACHE"
        }, {
            "description": "Болки навсякъде",
            "nomenclature": "0.9.2",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_ACHES_OVER"
        }, {
            "description": "Схващане",
            "nomenclature": "0.9.3",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_CRAMP"
        }, {
            "description": "Болка",
            "nomenclature": "0.9.4",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_HURT"
        }, {
            "description": "Скованост",
            "nomenclature": "0.9.5",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_STIFFNESS"
        }, {
            "description": "Подуване или маса, място неопределено",
            "nomenclature": "0.10",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_CAT"
        }, {
            "description": "Изпъкналост",
            "nomenclature": "0.10.1",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_BULGE"
        }, {
            "description": "Подутина",
            "nomenclature": "0.10.2",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_BUMP"
        }, {
            "description": "Възел",
            "nomenclature": "0.10.3",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_KNOT"
        }, {
            "description": "Буца",
            "nomenclature": "0.10.4",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_LUMP"
        }, {
            "description": "Общи симптоми на кърмачета и деца",
            "nomenclature": "0.11",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_CAT"
        }, {
            "description": "Плаче прекалено много",
            "nomenclature": "0.11.1",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_CRYING_TOO_MUCH"
        }, {
            "description": "Нервност",
            "nomenclature": "0.11.2",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_FIDGETY"
        }, {
            "description": "Тръшкане",
            "nomenclature": "0.11.3",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_FUSSY"
        }, {
            "description": "Хиперактивен",
            "nomenclature": "0.11.4",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_HYPERACTIVE"
        }, {
            "description": "Раздразнителен",
            "nomenclature": "0.11.5",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_IRRITABLE"
        }, {
            "description": "Намалена активност",
            "nomenclature": "0.11.6",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_UNDERACTIVE"
        }, {
            "description": "Нервна система",
            "nomenclature": "1",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUS_SYSTEM_CAT"
        }, {
            "description": "Ненормални неволни движения",
            "nomenclature": "1.1",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_CAT"
        }, {
            "description": "Kлатене",
            "nomenclature": "1.1.1",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_SHAKING"
        }, {
            "description": "Тик",
            "nomenclature": "1.1.2",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_TIC"
        }, {
            "description": "Треперене",
            "nomenclature": "1.1.3",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_TREMOR"
        }, {
            "description": "Конвулсивни движения",
            "nomenclature": "1.1.4",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_TWITCH"
        }, {
            "description": "Кома и ступор",
            "nomenclature": "1.2",
            "selectable": false,
            "strength": 0,
            "type": "COMA_AND_STUPOR"
        }, {
            "description": "Oбъркване",
            "nomenclature": "1.3",
            "selectable": false,
            "strength": 0,
            "type": "CONFUSION"
        }, {
            "description": "Конвулсии",
            "nomenclature": "1.4",
            "selectable": false,
            "strength": 0,
            "type": "CONVULSIONS_CAT"
        }, {
            "description": "Пристъпи",
            "nomenclature": "1.4.1",
            "selectable": false,
            "strength": 0,
            "type": "CONVULSIONS_FITS"
        }, {
            "description": "Припадъци",
            "nomenclature": "1.4.2",
            "selectable": false,
            "strength": 0,
            "type": "CONVULSIONS_SEIZURES"
        }, {
            "description": "Замайване",
            "nomenclature": "1.4.3",
            "selectable": false,
            "strength": 0,
            "type": "CONVULSIONS_SPELLS"
        }, {
            "description": "Главоболие",
            "nomenclature": "1.5",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE_CAT"
        }, {
            "description": "Главоболие",
            "nomenclature": "1.5.0",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE"
        }, {
            "description": "Мигрена",
            "nomenclature": "1.5.1",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE_MIGRAINE"
        }, {
            "description": "Нервност",
            "nomenclature": "1.5.2",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE_NERVOUS"
        }, {
            "description": "Напрежение",
            "nomenclature": "1.5.3",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE_TENSION"
        }, {
            "description": "Памет, смущения",
            "nomenclature": "1.6",
            "selectable": false,
            "strength": 0,
            "type": "MEMORY_DISTURBANCE_CAT"
        }, {
            "description": "Амнезия",
            "nomenclature": "1.6.1",
            "selectable": false,
            "strength": 0,
            "type": "MEMORY_DISTURBANCE_AMNESIA"
        }, {
            "description": "Липса или загуба на памет",
            "nomenclature": "1.6.2",
            "selectable": false,
            "strength": 0,
            "type": "MEMORY_DISTURBANCE_LOSS"
        }, {
            "description": "Временна загуба на памет",
            "nomenclature": "1.6.3",
            "selectable": false,
            "strength": 0,
            "type": "MEMORY_DISTURBANCE_TEMPORARY_LOSS"
        }, {
            "description": "Други нарушения на усещането",
            "nomenclature": "1.7",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_CAT"
        }, {
            "description": "Анестезия",
            "nomenclature": "1.7.1",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_ANESTHESIA"
        }, {
            "description": "Парене",
            "nomenclature": "1.7.2",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_BURNING"
        }, {
            "description": "Хиперестезия",
            "nomenclature": "1.7.3",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_HYPERESTHESIA"
        }, {
            "description": "Загуба на миризма",
            "nomenclature": "1.7.4",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_LOSS_OF_SMELL"
        }, {
            "description": "Загуба на вкус",
            "nomenclature": "1.7.5",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_LOSS_OF_TASTE"
        }, {
            "description": "Загуба на допир",
            "nomenclature": "1.7.6",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_LOSS_OF_TOUCH"
        }, {
            "description": "Чудовищно чувство",
            "nomenclature": "1.7.7",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_PRICKLY_FEELING"
        }, {
            "description": "Изтръпване",
            "nomenclature": "1.7.8",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_TINGLING"
        }, {
            "description": "Нарушения на съня",
            "nomenclature": "1.8",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_CAT"
        }, {
            "description": "Сънливост",
            "nomenclature": "1.8.1",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_DROWSINESS"
        }, {
            "description": "Хиперсомния",
            "nomenclature": "1.8.2",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_HYPERSOMNIA"
        }, {
            "description": "Безсъние",
            "nomenclature": "1.8.3",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_INSOMNIA"
        }, {
            "description": "Проблеми със съня",
            "nomenclature": "1.8.4",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_TROUBLE_SLEEPING"
        }, {
            "description": "Не мога да спя",
            "nomenclature": "1.8.5",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_CANT_SLEEP"
        }, {
            "description": "Кошмари",
            "nomenclature": "1.8.6",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_NIGHTMARES"
        }, {
            "description": "Постоянно ми се спи",
            "nomenclature": "1.8.7",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_SLEEPINESS"
        }, {
            "description": "Сомнамбулизъм",
            "nomenclature": "1.8.8",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_SLEEP_WALKING"
        }, {
            "description": "Синдром на часовата зона, джетлаг",
            "nomenclature": "1.8.9",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_TIMEZONE_SYNDROME"
        }, {
            "description": "Сенилност от старост",
            "nomenclature": "1.9",
            "selectable": false,
            "strength": 0,
            "type": "OLD_AGE_SENILITY"
        }, {
            "description": "заекване или пелтечене",
            "nomenclature": "1.10",
            "selectable": false,
            "strength": 0,
            "type": "STAMMERING_OR_STUTTERING"
        }, {
            "description": "Вертиго - световъртеж",
            "nomenclature": "1.11",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_CAT"
        }, {
            "description": "Падащи усещания",
            "nomenclature": "1.11.1",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_FALLING_SENSATIONS"
        }, {
            "description": "Виене на свят",
            "nomenclature": "1.11.2",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_GIDDINESS"
        }, {
            "description": "Замаяност",
            "nomenclature": "1.11.3",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_LIGHTHEADEDNESS"
        }, {
            "description": "Загуба на чувство за равновесие или баланс",
            "nomenclature": "1.11.4",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_LOSS_SENSE_EQUILIBRIUM_BALANCE"
        }, {
            "description": "Други симптоми, отнасящи се до нервната система",
            "nomenclature": "1.12",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_SYMPTOMS_NERVOUS_SYSTEM"
        }, {
            "description": "Кожа, нокти и коса",
            "nomenclature": "2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_NAILS_HAIR_CAT"
        }, {
            "description": "Акне или пъпки",
            "nomenclature": "2.1",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_CAT"
        }, {
            "description": "Лош тен",
            "nomenclature": "2.1.1",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_BAD_COMPLEXION"
        }, {
            "description": "Ергенски пъпки",
            "nomenclature": "2.1.2",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_BLACKHEADS"
        }, {
            "description": "Петна",
            "nomenclature": "2.1.3",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_BLEMISHES"
        }, {
            "description": "Гнойни пъпки",
            "nomenclature": "2.1.4",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_BREAKING_OUT"
        }, {
            "description": "Пъпки с бял връх",
            "nomenclature": "2.1.5",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_WHITEHEADS"
        }, {
            "description": "Обезцветяване или пигментация",
            "nomenclature": "2.2",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_CAT"
        }, {
            "description": "Изчервяване",
            "nomenclature": "2.2.1",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_BLUSHING"
        }, {
            "description": "Промяна в цвета",
            "nomenclature": "2.2.2",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_CHANGE_IN_COLOR"
        }, {
            "description": "Почервеняване",
            "nomenclature": "2.2.3",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_FLUSHING"
        }, {
            "description": "Лунички",
            "nomenclature": "2.2.4",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_FRECKLES"
        }, {
            "description": "Зачервяване",
            "nomenclature": "2.2.5",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_RED"
        }, {
            "description": "Петна",
            "nomenclature": "2.2.6",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_SPOTS"
        }, {
            "description": "Инфекциозни разстройства",
            "nomenclature": "2.3",
            "selectable": false,
            "strength": 0,
            "type": "INFECTIOUS_DISORDERS_CAT"
        }, {
            "description": "Атлетично стъпало (гъбички)",
            "nomenclature": "2.3.1",
            "selectable": false,
            "strength": 0,
            "type": "INFECTIOUS_DISORDERS_ATHLETES_FOOT"
        }, {
            "description": "Циреи",
            "nomenclature": "2.3.2",
            "selectable": false,
            "strength": 0,
            "type": "INFECTIOUS_DISORDERS_BOILS"
        }, {
            "description": "Tения",
            "nomenclature": "2.3.3",
            "selectable": false,
            "strength": 0,
            "type": "INFECTIOUS_DISORDERS_RINGWORM"
        }, {
            "description": "Калуси или мазоли",
            "nomenclature": "2.4",
            "selectable": false,
            "strength": 0,
            "type": "CALLUSES_OR_CORNS"
        }, {
            "description": "Кожни бенки",
            "nomenclature": "2.5",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_MOLES"
        }, {
            "description": "Бръчки",
            "nomenclature": "2.6",
            "selectable": false,
            "strength": 0,
            "type": "WRINKLES"
        }, {
            "description": "Брадавици",
            "nomenclature": "2.7",
            "selectable": false,
            "strength": 0,
            "type": "WARTS"
        }, {
            "description": "Алергични кожни реакции",
            "nomenclature": "2.8",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_CAT"
        }, {
            "description": "Обрив",
            "nomenclature": "2.8.0",
            "selectable": false,
            "strength": 0,
            "type": "RASH"
        }, {
            "description": "Копривна треска",
            "nomenclature": "2.8.1",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_HIVES"
        }, {
            "description": "Фоточувствителност",
            "nomenclature": "2.8.2",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_PHOTOSENSITIVITY"
        }, {
            "description": "Отровен бръшлян, отровен дъб и др.",
            "nomenclature": "2.8.3",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_POISON_IVY_OAK"
        }, {
            "description": "Обрив, подсичане",
            "nomenclature": "2.8.4",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_RASH_DIAPER"
        }, {
            "description": "Раздразнения на кожата",
            "nomenclature": "2.9",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_CAT"
        }, {
            "description": "Кожна инфекция",
            "nomenclature": "2.9.1",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_INFLAMMATION"
        }, {
            "description": "Сърбеж",
            "nomenclature": "2.9.2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_ITCHING"
        }, {
            "description": "Болка по кожата",
            "nomenclature": "2.9.3",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_PAIN"
        }, {
            "description": "Язва на кожата",
            "nomenclature": "2.9.4",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_ULCER"
        }, {
            "description": "Възпалено място на кожата",
            "nomenclature": "2.9.5",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_SORE"
        }, {
            "description": "Кожа: подуване или кожна маса",
            "nomenclature": "2.10",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_CAT"
        }, {
            "description": "Кожни подутини",
            "nomenclature": "2.10.1",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_BUMPS"
        }, {
            "description": "Кожни лезии",
            "nomenclature": "2.10.2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_LESION"
        }, {
            "description": "Кожни възли",
            "nomenclature": "2.10.3",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_NODULES"
        }, {
            "description": "Кожни подутини с кантове, копривна треска",
            "nomenclature": "2.10.4",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_WELTS"
        }, {
            "description": "Рани по кожата",
            "nomenclature": "2.11",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_CAT"
        }, {
            "description": "Ухапвания по кожата",
            "nomenclature": "2.11.1",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_BITES"
        }, {
            "description": "Мехури по кожата, неалергични",
            "nomenclature": "2.11.2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_BLISTERS_NON_ALLERGIC"
        }, {
            "description": "Синини по кожата",
            "nomenclature": "2.11.3",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_BRUISES"
        }, {
            "description": "Изгаряния по кожата(химически, слънце, вятър и пара)",
            "nomenclature": "2.11.4",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_BURNS"
        }, {
            "description": "Порязвания по кожата",
            "nomenclature": "2.11.5",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_CUTS"
        }, {
            "description": "Драскотини по кожата",
            "nomenclature": "2.11.6",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_SCRATCHES"
        }, {
            "description": "Други симптоми, отнасящи се до кожата",
            "nomenclature": "2.12",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_CAT"
        }, {
            "description": "Сухота на кожата",
            "nomenclature": "2.12.1",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_DRYNESS"
        }, {
            "description": "Омазняване на кожата",
            "nomenclature": "2.12.2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_OILINESS"
        }, {
            "description": "Белене на кожата",
            "nomenclature": "2.12.3",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_PEELING"
        }, {
            "description": "Люспеста кожа",
            "nomenclature": "2.12.4",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_SCALINES"
        }, {
            "description": "Промяна в текстурата на кожата",
            "nomenclature": "2.12.5",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_CHANGE_IN_TEXTURE"
        }, {
            "description": "Симптоми, отнасящи се до ноктите",
            "nomenclature": "2.13",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_CAT"
        }, {
            "description": "Чупене на ноктите",
            "nomenclature": "2.13.1",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_BREAKING"
        }, {
            "description": "Крехкост на ноктите",
            "nomenclature": "2.13.2",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_BRITTLE"
        }, {
            "description": "Промяна в цвета на ноктите",
            "nomenclature": "2.13.3",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_CHANGE_IN_COLOR"
        }, {
            "description": "Напукани нокти",
            "nomenclature": "2.13.4",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_CRACKED"
        }, {
            "description": "Растящи навътре нокти",
            "nomenclature": "2.13.5",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_INGROWN"
        }, {
            "description": "Хребети по ноктите",
            "nomenclature": "2.13.6",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_RIDGES"
        }, {
            "description": "Нацепени нокти",
            "nomenclature": "2.13.7",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_SPLITTING"
        }, {
            "description": "Симптоми, отнасящи се до косата",
            "nomenclature": "2.14",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_CAT"
        }, {
            "description": "Плешивост",
            "nomenclature": "2.14.1",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_BALDNESS"
        }, {
            "description": "Крехкост на косата",
            "nomenclature": "2.14.2",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_BRITTLE"
        }, {
            "description": "Сухота на косата",
            "nomenclature": "2.14.3",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_DRYNESS"
        }, {
            "description": "Косопад",
            "nomenclature": "2.14.4",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_FALLING_OUT"
        }, {
            "description": "Омазняване на косата",
            "nomenclature": "2.14.5",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_OILNESS"
        }, {
            "description": "Отдръпваща се линия на косата",
            "nomenclature": "2.14.6",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_RECEDING_HAIR_LINE"
        }, {
            "description": "Симптоми на пъпа",
            "nomenclature": "2.15",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_CAT"
        }, {
            "description": "Секретиране от пъпа",
            "nomenclature": "2.15.1",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_DISCHARGE"
        }, {
            "description": "Изтичане на течност от пъпа",
            "nomenclature": "2.15.2",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_DRAINING"
        }, {
            "description": "Незарастващ пъп",
            "nomenclature": "2.15.3",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_NOT_HEALING"
        }, {
            "description": "Болезнен пъп",
            "nomenclature": "2.15.4",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_PAINFUL"
        }, {
            "description": "Зачервен пъп",
            "nomenclature": "2.15.5",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_RED"
        }, {
            "description": "Сърдечно-съдова и лимфна системи",
            "nomenclature": "3",
            "selectable": false,
            "strength": 0,
            "type": "CARDIOVASCULAR_LYMPHATIC_SYMPTOMS_CAT"
        }, {
            "description": "Неравномерен ритъм и сърцебиене",
            "nomenclature": "3.1",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_CAT"
        }, {
            "description": "Забавен сърдечен ритъм",
            "nomenclature": "3.1.1",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_DECREASED"
        }, {
            "description": "Трептене в гърдите",
            "nomenclature": "3.1.2",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_FLUTTERING"
        }, {
            "description": "Учестен сърдечен ритъм",
            "nomenclature": "3.1.3",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_INCREASED"
        }, {
            "description": "Твърде учестен пулс",
            "nomenclature": "3.1.4",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_PULSE_TOO_FAST"
        }, {
            "description": "Твърде забавен пулс",
            "nomenclature": "3.1.5",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_PULSE_TOO_SLOW"
        }, {
            "description": "Аритмия",
            "nomenclature": "3.1.6",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_IRREGULAR_BEATS"
        }, {
            "description": "Чува се бързо сърцебиене",
            "nomenclature": "3.1.7",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_RAPID_HEARD"
        }, {
            "description": "Сърцето прескача удари",
            "nomenclature": "3.1.8",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_SKIPPED_BEAT"
        }, {
            "description": "Неравномерно сърцебиене",
            "nomenclature": "3.1.9",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_UNEQUAL"
        }, {
            "description": "Сърдечен шум",
            "nomenclature": "3.2",
            "selectable": false,
            "strength": 0,
            "type": "HEART_MURMUR"
        }, {
            "description": "Ненормално високо кръвно налягане",
            "nomenclature": "3.3",
            "selectable": false,
            "strength": 0,
            "type": "HIGH_BLOOD_PRESSURE_CAT"
        }, {
            "description": "Повишено кръвно налягане",
            "nomenclature": "3.3.1",
            "selectable": false,
            "strength": 0,
            "type": "HIGH_BLOOD_PRESSURE_ELEVATED_BP"
        }, {
            "description": "Високо кръвно налягане",
            "nomenclature": "3.3.2",
            "selectable": false,
            "strength": 0,
            "type": "HIGH_BLOOD_PRESSURE_HIGH_BP"
        }, {
            "description": "Хипертония",
            "nomenclature": "3.3.3",
            "selectable": false,
            "strength": 0,
            "type": "HIGH_BLOOD_PRESSURE_HYPERTENSION"
        }, {
            "description": "Ненормално ниско кръвно налягане",
            "nomenclature": "3.4",
            "selectable": false,
            "strength": 0,
            "type": "LOW_BLOOD_PRESSURE_CAT"
        }, {
            "description": "Намалено кръвно налягане",
            "nomenclature": "3.4.1",
            "selectable": false,
            "strength": 0,
            "type": "LOW_BLOOD_PRESSURE_DECREASED_BP"
        }, {
            "description": "Хипотония",
            "nomenclature": "3.4.2",
            "selectable": false,
            "strength": 0,
            "type": "LOW_BLOOD_PRESSURE_HYPOTENSION"
        }, {
            "description": "Ниско кръвно налягане",
            "nomenclature": "3.4.3",
            "selectable": false,
            "strength": 0,
            "type": "LOW_BLOOD_PRESSURE_LOW_BP"
        }, {
            "description": "Симптоми, отнасящи се до кръвта",
            "nomenclature": "3.5",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_CAT"
        }, {
            "description": "Лошо кръвообращение",
            "nomenclature": "3.5.1",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_POOR"
        }, {
            "description": "Разредена кръв",
            "nomenclature": "3.5.2",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_THIN"
        }, {
            "description": "Анемия, чувство на умора",
            "nomenclature": "3.5.3",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_TIRED"
        }, {
            "description": "Анемия, слабост",
            "nomenclature": "3.5.4",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_WEAK"
        }, {
            "description": "Бледност и цианоза",
            "nomenclature": "3.6",
            "selectable": false,
            "strength": 0,
            "type": "PALLOR_AND_CYANOSIS_CAT"
        }, {
            "description": "Пепеляв цвят",
            "nomenclature": "3.6.1",
            "selectable": false,
            "strength": 0,
            "type": "PALLOR_AND_CYANOSIS_ASHEN_COLOR"
        }, {
            "description": "Сини пръсти на краката",
            "nomenclature": "3.6.2",
            "selectable": false,
            "strength": 0,
            "type": "PALLOR_AND_CYANOSIS_BLUENESS_OF_FINGERS"
        }, {
            "description": "Бледост",
            "nomenclature": "3.6.3",
            "selectable": false,
            "strength": 0,
            "type": "PALLOR_AND_CYANOSIS_PALENESS"
        }, {
            "description": "Синкоп или колапс",
            "nomenclature": "3.7",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_CAT"
        }, {
            "description": "Причерняване",
            "nomenclature": "3.7.1",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_BLACKED_OUT"
        }, {
            "description": "Припадък",
            "nomenclature": "3.7.2",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_FAINTING"
        }, {
            "description": "Загуба на съзнание",
            "nomenclature": "3.7.3",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_PASSED_OUT"
        }, {
            "description": "Замайване",
            "nomenclature": "3.7.4",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_SPELLS"
        }, {
            "description": "Други симптоми на слуха",
            "nomenclature": "3.8",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_HEARD_CAT"
        }, {
            "description": "Лошо чуване",
            "nomenclature": "3.8.1",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_HEARD_BAD_HEARD"
        }, {
            "description": "Недобро чуване",
            "nomenclature": "3.8.2",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_HEARD_POOR_HEARD"
        }, {
            "description": "Слабо чуване",
            "nomenclature": "3.8.3",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_HEARD_WEAK_HEARD"
        }, {
            "description": "Оток и водянка",
            "nomenclature": "3.9",
            "selectable": false,
            "strength": 0,
            "type": "EDEMA_AND_DROPSY"
        }, {
            "description": "Лимфни жлези",
            "nomenclature": "3.10",
            "selectable": false,
            "strength": 0,
            "type": "LYMPH_GLANDS_CAT"
        }, {
            "description": "Увеличени лимфни възли",
            "nomenclature": "3.10.1",
            "selectable": false,
            "strength": 0,
            "type": "LYMPH_GLANDS_ENLARGED_LYMPH_NODES"
        }, {
            "description": "Възпалени лимфни възли",
            "nomenclature": "3.10.2",
            "selectable": false,
            "strength": 0,
            "type": "LYMPH_GLANDS_SORE_GLANDS"
        }, {
            "description": "Подути лимфни възли",
            "nomenclature": "3.10.3",
            "selectable": false,
            "strength": 0,
            "type": "LYMPH_GLANDS_SWOLLEN_GLANDS"
        }, {
            "description": "Дихателна система",
            "nomenclature": "4",
            "selectable": false,
            "strength": 0,
            "type": "RESPIRATORY_SYMPTOMS_CAT"
        }, {
            "description": "Кръв от носа",
            "nomenclature": "4.1",
            "selectable": false,
            "strength": 0,
            "type": "NOSE_BLEED_CAT"
        }, {
            "description": "Кървене от носа",
            "nomenclature": "4.1.1",
            "selectable": false,
            "strength": 0,
            "type": "NOSE_BLEED_BLEEDING"
        }, {
            "description": "Кръвоизлив от носа",
            "nomenclature": "4.1.2",
            "selectable": false,
            "strength": 0,
            "type": "NOSE_BLEED_HEMORRHAGE"
        }, {
            "description": "Запушване на носа",
            "nomenclature": "4.2",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_CAT"
        }, {
            "description": "Течащ нос",
            "nomenclature": "4.2.1",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_DRIPPY_NOSE"
        }, {
            "description": "Постназално секретиране (стичане на секрет към гърлото)",
            "nomenclature": "4.2.2",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_POSTNASAL_DRIP"
        }, {
            "description": "Зачервен нос",
            "nomenclature": "4.2.3",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_RED_NOSE"
        }, {
            "description": "Хрема",
            "nomenclature": "4.2.4",
            "selectable": false,
            "strength": 0,
            "type": "RUNNY_NOSE"
        }, {
            "description": "Подсмърчане",
            "nomenclature": "4.2.5",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_SNIFFLES"
        }, {
            "description": "Запушен нос",
            "nomenclature": "4.2.6",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_STUFFY_NOSE"
        }, {
            "description": "Проблеми със синусите",
            "nomenclature": "4.3",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_CAT"
        }, {
            "description": "Задръстване на синусите",
            "nomenclature": "4.3.1",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_CONGESTION"
        }, {
            "description": "Увредени синуси",
            "nomenclature": "4.3.2",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_IMPACTED"
        }, {
            "description": "Инфектирани синуси",
            "nomenclature": "4.3.3",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_INFECTED"
        }, {
            "description": "Чувство на лекота в синусите",
            "nomenclature": "4.3.4",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_LIGHTNESS"
        }, {
            "description": "Болка в синусите",
            "nomenclature": "4.3.5",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_PAIN"
        }, {
            "description": "Налягане в синусите",
            "nomenclature": "4.3.6",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_PRESSURE"
        }, {
            "description": "Дишане",
            "nomenclature": "4.4",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_CAT"
        }, {
            "description": "Недостиг на въздух",
            "nomenclature": "4.4.0",
            "selectable": false,
            "strength": 0,
            "type": "BREATH_SHORTNESS"
        }, {
            "description": "Задух",
            "nomenclature": "4.4.1",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_BREATHLESSNESS"
        }, {
            "description": "Затруднено дишане",
            "nomenclature": "4.4.2",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_DYSPNES"
        }, {
            "description": "Усещане за задушаване",
            "nomenclature": "4.4.3",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_SENSATION_OF_SUFFOCATION"
        }, {
            "description": "Проблемно дишане",
            "nomenclature": "4.4.4",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_TROUBLE_BREATHING"
        }, {
            "description": "Други нарушения на дихателния ритъм и звук",
            "nomenclature": "4.5",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_CAT"
        }, {
            "description": "Ненормални звуци при дишане",
            "nomenclature": "4.5.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_ABNORMAL_BREATHING_SOUNDS"
        }, {
            "description": "Хипервентилация",
            "nomenclature": "4.5.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_HYPERVENTILATION"
        }, {
            "description": "Хрипове",
            "nomenclature": "4.5.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_RALES"
        }, {
            "description": "Бързо дишане",
            "nomenclature": "4.5.4",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_RAPID_BREATHING"
        }, {
            "description": "Въздишащо дишане",
            "nomenclature": "4.5.5",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_SIGHING_RESPIRATION"
        }, {
            "description": "Свирене в гърдите",
            "nomenclature": "4.5.6",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_WHEEZING"
        }, {
            "description": "Кихане",
            "nomenclature": "4.6",
            "selectable": false,
            "strength": 0,
            "type": "SNEEZING"
        }, {
            "description": "Кашлица",
            "nomenclature": "4.7",
            "selectable": false,
            "strength": 0,
            "type": "COUGH"
        }, {
            "description": "Настинка, простуда",
            "nomenclature": "4.8",
            "selectable": false,
            "strength": 0,
            "type": "COLD"
        }, {
            "description": "Грип",
            "nomenclature": "4.9",
            "selectable": false,
            "strength": 0,
            "type": "FLU"
        }, {
            "description": "Грип",
            "nomenclature": "4.9.1",
            "selectable": false,
            "strength": 0,
            "type": "GRIP"
        }, {
            "description": "Инфлуенца",
            "nomenclature": "4.9.2",
            "selectable": false,
            "strength": 0,
            "type": "INFLUENZA"
        }, {
            "description": "Круп",
            "nomenclature": "4.10",
            "selectable": false,
            "strength": 0,
            "type": "CROUP"
        }, {
            "description": "Слюнка или храчки",
            "nomenclature": "4.11",
            "selectable": false,
            "strength": 0,
            "type": "SPUTUM_OR_PHLEGM_CAT"
        }, {
            "description": "Кървави храчки",
            "nomenclature": "4.11.1",
            "selectable": false,
            "strength": 0,
            "type": "SPUTUM_OR_PHLEGM_BLOODY"
        }, {
            "description": "Прекомерни храчки",
            "nomenclature": "4.11.2",
            "selectable": false,
            "strength": 0,
            "type": "SPUTUM_OR_PHLEGM_EXCESSIVE"
        }, {
            "description": "Гнойни храчки",
            "nomenclature": "4.11.3",
            "selectable": false,
            "strength": 0,
            "type": "SPUTUM_OR_PHLEGM_PURULENT"
        }, {
            "description": "Конгестия на гърдите",
            "nomenclature": "4.12",
            "selectable": false,
            "strength": 0,
            "type": "CONGESTION_IN_CHEST_CAT"
        }, {
            "description": "Конгестия на белите дробове",
            "nomenclature": "4.12.1",
            "selectable": false,
            "strength": 0,
            "type": "CONGESTION_IN_CHEST_LUNG_CONGESTION"
        }, {
            "description": "Болка в гърдите",
            "nomenclature": "4.13",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_CAT"
        }, {
            "description": "Изгарящо усещане",
            "nomenclature": "4.13.1",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_BURNING_SENSATION"
        }, {
            "description": "Стягане в гърдите",
            "nomenclature": "4.13.2",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_CHEST_TIGHTNESS"
        }, {
            "description": "Болка в белия дроб",
            "nomenclature": "4.13.3",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_PAIN_IN_LUNG"
        }, {
            "description": "Болка над сърцето",
            "nomenclature": "4.13.4",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_PAIN_OVER_HEART"
        }, {
            "description": "Дихателна болка в областта на гръдната кост",
            "nomenclature": "4.13.5",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_RIB"
        }, {
            "description": "Натиск в/върху гърдите",
            "nomenclature": "4.13.6",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_PRESSURE_IN_ON_CHEST"
        }, {
            "description": "Нарушения на гласа",
            "nomenclature": "4.14",
            "selectable": false,
            "strength": 0,
            "type": "DISORDERS_OF_VOICE_CAT"
        }, {
            "description": "Дрезгавина на гласа",
            "nomenclature": "4.14.1",
            "selectable": false,
            "strength": 0,
            "type": "DISORDERS_OF_VOICE_HOARSENSES"
        }, {
            "description": "Носово говорене",
            "nomenclature": "4.14.2",
            "selectable": false,
            "strength": 0,
            "type": "DISORDERS_OF_VOICE_HYPERNASALITY"
        }, {
            "description": "Мускулно-скелетна система",
            "nomenclature": "5",
            "selectable": false,
            "strength": 0,
            "type": "MUSKULOSKELETAL_SYSTEM_SYMPTOMS_CAT"
        }, {
            "description": "Болка, подуване, нараняване на долните крайници",
            "nomenclature": "5.1",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_CAT"
        }, {
            "description": "Болка в крака",
            "nomenclature": "5.1.1",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_ACHE"
        }, {
            "description": "Мускулен спазъм на крака",
            "nomenclature": "5.1.2",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_CHARLEYHORSE"
        }, {
            "description": "Контракция на мускул на крака",
            "nomenclature": "5.1.3",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_CONTRACTURE"
        }, {
            "description": "Схващане на крака",
            "nomenclature": "5.1.4",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_CRAMP"
        }, {
            "description": "Смяна на усещане за топло-студено на крака",
            "nomenclature": "5.1.5",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_HOT_COLD"
        }, {
            "description": "Боли ме кракът",
            "nomenclature": "5.1.6",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_HURT"
        }, {
            "description": "Разтегнат мускул на крака",
            "nomenclature": "5.1.7",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_PULLED_MUSCLE"
        }, {
            "description": "Болезненост в крака",
            "nomenclature": "5.1.8",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_SORENESS"
        }, {
            "description": "Спазъм на крака",
            "nomenclature": "5.1.9",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_SPASM"
        }, {
            "description": "Скованост на крака",
            "nomenclature": "5.1.10",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STIFFNESS"
        }, {
            "description": "Болка в глезена, стъпалото, тазобедрената става, коляното",
            "nomenclature": "5.1.11",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_ANKLE_FOOT_HIP_KNEE"
        }, {
            "description": "Болка в стъпалото",
            "nomenclature": "5.1.12",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_FOOT"
        }, {
            "description": "Болка в тазобедрената става",
            "nomenclature": "5.1.13",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_HIP"
        }, {
            "description": "Болка в коляното",
            "nomenclature": "5.1.14",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_KNEE"
        }, {
            "description": "Болка в крака или бедрото",
            "nomenclature": "5.1.15",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_LEG_OR_THIGH"
        }, {
            "description": "Болка в долния крайник",
            "nomenclature": "5.1.16",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_GLOBAL"
        }, {
            "description": "Болка в пръстите на краката",
            "nomenclature": "5.1.17",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_TOE"
        }, {
            "description": "Болка, подуване, нараняване на горния крайник (ръка, ръце)",
            "nomenclature": "5.2",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_CAT"
        }, {
            "description": "Болка в ръката",
            "nomenclature": "5.2.1",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_ACHE"
        }, {
            "description": "Контракция на мускул на ръката",
            "nomenclature": "5.2.1",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_CONTRACTURE"
        }, {
            "description": "Смяна на усещане за топло-студено на ръката",
            "nomenclature": "5.2.3",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_HOT_COLD"
        }, {
            "description": "Боли ме ръката",
            "nomenclature": "5.2.4",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_HURT"
        }, {
            "description": "Разтегнат мускул на ръката",
            "nomenclature": "5.2.5",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_PULLED_MUSCLE"
        }, {
            "description": "Болезненост в ръката",
            "nomenclature": "5.2.6",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_SORENESS"
        }, {
            "description": "Спазъм в ръката",
            "nomenclature": "5.2.7",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_SPASM"
        }, {
            "description": "Скованост в ръката",
            "nomenclature": "5.2.8",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STIFFNESS"
        }, {
            "description": "Болка в ръката",
            "nomenclature": "5.2.9",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_ARM"
        }, {
            "description": "Болка в лакътя",
            "nomenclature": "5.2.10",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_ELBOW"
        }, {
            "description": "Болка в пръстите",
            "nomenclature": "5.2.11",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_FINGERS"
        }, {
            "description": "Болка в предмишницата",
            "nomenclature": "5.2.12",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_FOREARM"
        }, {
            "description": "Болка в ръката",
            "nomenclature": "5.2.13",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_HAND"
        }, {
            "description": "Болка в рамото",
            "nomenclature": "5.2.14",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_SHOULDER"
        }, {
            "description": "Болка в палеца",
            "nomenclature": "5.2.15",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_THUMB"
        }, {
            "description": "Болка в горната част на ръката",
            "nomenclature": "5.2.16",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_UPPER_ARM"
        }, {
            "description": "Болка в горния крайник",
            "nomenclature": "5.2.17",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_UPPER_EXTREMITY"
        }, {
            "description": "Болка в китката",
            "nomenclature": "5.2.18",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_WRIST"
        }, {
            "description": "Болка, подуване, нараняване на лицето и шията",
            "nomenclature": "5.3",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_CAT"
        }, {
            "description": "Болка във врата",
            "nomenclature": "5.3.0",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_ACHE_NECK"
        }, {
            "description": "Болка в лицето",
            "nomenclature": "5.3.1",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_ACHE_FACE"
        }, {
            "description": "Контракция на лицето",
            "nomenclature": "5.3.2",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_CONTRACTURE"
        }, {
            "description": "Схващане на шията",
            "nomenclature": "5.3.3",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_CRAMP_NECK"
        }, {
            "description": "Схващане на врата",
            "nomenclature": "5.3.30",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_CRAMP_FACE"
        }, {
            "description": "Боли ме шията",
            "nomenclature": "5.3.4",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_HURT_NECK"
        }, {
            "description": "Боли ме лицето",
            "nomenclature": "5.3.40",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_HURT"
        }, {
            "description": "Разтегнат мускул на шията",
            "nomenclature": "5.3.5",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_PULLED_MUSCLE"
        }, {
            "description": "Болезненост на лицето",
            "nomenclature": "5.3.6",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_SORENESS"
        }, {
            "description": "Спазъм на лицето",
            "nomenclature": "5.3.7",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_SPASM"
        }, {
            "description": "Скованост на шията",
            "nomenclature": "5.3.8",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STIFFNESS"
        }, {
            "description": "Болка в задната част на главата",
            "nomenclature": "5.3.9",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_BACK_HEAD"
        }, {
            "description": "Болка в шийния отдел на гръбначния стълб",
            "nomenclature": "5.3.10",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_CERVICAL_SPINE"
        }, {
            "description": "Болка в лицето",
            "nomenclature": "5.3.11",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_FACE"
        }, {
            "description": "Болка в челюстта",
            "nomenclature": "5.3.12",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_JAW"
        }, {
            "description": "Болка в шията",
            "nomenclature": "5.3.13",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_NECK"
        }, {
            "description": "Болка в горната част на гръбнака",
            "nomenclature": "5.3.14",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_UPPER_SPINE"
        }, {
            "description": "Болка, подуване, нараняване на областта на гърба",
            "nomenclature": "5.4",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_CAT"
        }, {
            "description": "Болка в гърба",
            "nomenclature": "5.4.1",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_ACHE"
        }, {
            "description": "Контракция на гърба",
            "nomenclature": "5.4.2",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_CONTRACTURE"
        }, {
            "description": "Схващане на гърба",
            "nomenclature": "5.4.3",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_CRAMP"
        }, {
            "description": "Боли ме гърба",
            "nomenclature": "5.4.4",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_HURT"
        }, {
            "description": "Разтегнат мускул на гърба",
            "nomenclature": "5.4.5",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_PULLED_MUSCLE"
        }, {
            "description": "Болезненост на гърба",
            "nomenclature": "5.4.6",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_SORENESS"
        }, {
            "description": "Спазъм на гърба",
            "nomenclature": "5.4.7",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_SPASM"
        }, {
            "description": "Скованост на гърба",
            "nomenclature": "5.4.8",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STIFFNESS"
        }, {
            "description": "Болка в гърба",
            "nomenclature": "5.4.9",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_BACK"
        }, {
            "description": "Болка в гърба, горната, долната част",
            "nomenclature": "5.4.10",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_BACK_LOWER_UPPER"
        }, {
            "description": "Болка в лумбалната област (в кръста)",
            "nomenclature": "5.4.11",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_LUMBAR"
        }, {
            "description": "Болки в лумбосакралната област  (долната част на гърба)",
            "nomenclature": "5.4.12",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_LUMBOSACRAL"
        }, {
            "description": "Болка в сакролиака",
            "nomenclature": "5.4.13",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_SACROILIAC"
        }, {
            "description": "Болка в гръбнака",
            "nomenclature": "5.4.14",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_SPINE"
        }, {
            "description": "Болка в торакалната част на гръбнака (горната част на гърба)",
            "nomenclature": "5.4.15",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_THORACIC_SPINE"
        }, {
            "description": "Атрофия или загуба на крайници",
            "nomenclature": "5.5",
            "selectable": false,
            "strength": 0,
            "type": "ATROPHY_OR_WATING_EXTREMITIES_CAT"
        }, {
            "description": "Скованост на крайниците",
            "nomenclature": "5.5.1",
            "selectable": false,
            "strength": 0,
            "type": "ATROPHY_OR_WATING_EXTREMITIES_NYMBNESS"
        }, {
            "description": "Парализа, частична или пълна",
            "nomenclature": "5.5.2",
            "selectable": false,
            "strength": 0,
            "type": "ATROPHY_OR_WATING_EXTREMITIES_PARALYSIS"
        }, {
            "description": "Слабост в крайниците",
            "nomenclature": "5.5.3",
            "selectable": false,
            "strength": 0,
            "type": "ATROPHY_OR_WATING_EXTREMITIES_WEAKNESS"
        }, {
            "description": "Трудност при ходене, ненормалност на походката",
            "nomenclature": "5.6",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_CAT"
        }, {
            "description": "Тромавост",
            "nomenclature": "5.6.1",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_CLUMSINESS"
        }, {
            "description": "Падане",
            "nomenclature": "5.6.2",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_FALLING"
        }, {
            "description": "Невъзможност за стоене или ходене",
            "nomenclature": "5.6.3",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_INABILITY_TO_STAND_AND_WALK"
        }, {
            "description": "Куцане",
            "nomenclature": "5.6.4",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_LIMPING"
        }, {
            "description": "Олюляване",
            "nomenclature": "5.6.5",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_STAGGERING"
        }, {
            "description": "Други симптоми, отнасящи се до крайници и стави",
            "nomenclature": "5.7",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_LIMB_JOINT_SYMPTOMS_CAT"
        }, {
            "description": "Болки в мускулите",
            "nomenclature": "5.7.0",
            "selectable": false,
            "strength": 0,
            "type": "MUSCLE_PAIN"
        }, {
            "description": "Болки в ставите",
            "nomenclature": "5.7.4",
            "selectable": false,
            "strength": 0,
            "type": "JOINT_PAIN"
        }, {
            "description": "Увисване на стъпалото",
            "nomenclature": "5.7.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_LIMB_JOINT_SYMPTOMS_FOOT_DROP"
        }, {
            "description": "Проблеми с позата",
            "nomenclature": "5.7.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_LIMB_JOINT_SYMPTOMS_POSTURE_PROBLEMS"
        }, {
            "description": "Увисване на китката",
            "nomenclature": "5.7.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_LIMB_JOINT_SYMPTOMS_WRIST_DROP"
        }, {
            "description": "Буниони (кокалчета)",
            "nomenclature": "5.8",
            "selectable": false,
            "strength": 0,
            "type": "BUNION"
        }, {
            "description": "Храносмилателната система",
            "nomenclature": "6",
            "selectable": false,
            "strength": 0,
            "type": "DIGESTIVE_SYSTEM_CAT"
        }, {
            "description": "Трудност при дъвчене",
            "nomenclature": "6.1",
            "selectable": false,
            "strength": 0,
            "type": "CHEWING_DIFFICULTY"
        }, {
            "description": "Кървене, венци",
            "nomenclature": "6.2",
            "selectable": false,
            "strength": 0,
            "type": "BLEEDING_GUMS"
        }, {
            "description": "Лош дъх на устата",
            "nomenclature": "6.3",
            "selectable": false,
            "strength": 0,
            "type": "HALITOSIS_CAT"
        }, {
            "description": "Лош дъх",
            "nomenclature": "6.3.1",
            "selectable": false,
            "strength": 0,
            "type": "HALITOSIS_BAD_BREATH"
        }, {
            "description": "Симптоми, отнасящи се до устните",
            "nomenclature": "6.4",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_CAT"
        }, {
            "description": "Ненормален цвят на устните",
            "nomenclature": "6.4.1",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_ABNORMAL_COLOR"
        }, {
            "description": "Кървене на устните",
            "nomenclature": "6.4.2",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_BLEEDING"
        }, {
            "description": "Напукани устни",
            "nomenclature": "6.4.3",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_CRACKED"
        }, {
            "description": "Сухи устни",
            "nomenclature": "6.4.4",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_DRY"
        }, {
            "description": "Болка на устните",
            "nomenclature": "6.4.5",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_PAIN"
        }, {
            "description": "Подуване на устните",
            "nomenclature": "6.4.6",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_SWELLING"
        }, {
            "description": "Симптоми, отнасящи се до устата",
            "nomenclature": "6.5",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_CAT"
        }, {
            "description": "Лош вкус",
            "nomenclature": "6.5.1",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_BAD_TASTE"
        }, {
            "description": "Изгаряне в устата",
            "nomenclature": "6.5.2",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_BURN"
        }, {
            "description": "Сухост в устата",
            "nomenclature": "6.5.3",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_DRYNESS"
        }, {
            "description": "Възпаление в устата",
            "nomenclature": "6.5.4",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_INFLAMMATION"
        }, {
            "description": "Болка в устата",
            "nomenclature": "6.5.5",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_PAIN"
        }, {
            "description": "Подуване в устата",
            "nomenclature": "6.5.6",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_SWELLING"
        }, {
            "description": "Язва в устата",
            "nomenclature": "6.5.7",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_ULCER"
        }, {
            "description": "Слюнка",
            "nomenclature": "6.6",
            "selectable": false,
            "strength": 0,
            "type": "SALIVA_CAT"
        }, {
            "description": "Твърде много слюнка",
            "nomenclature": "6.6.0",
            "selectable": false,
            "strength": 0,
            "type": "SALIVA_EXCESS"
        }, {
            "description": "Липса на слюнка",
            "nomenclature": "6.6.2",
            "selectable": false,
            "strength": 0,
            "type": "SALIVA_LACK"
        }, {
            "description": "Капеща слюнка",
            "nomenclature": "6.6.1",
            "selectable": false,
            "strength": 0,
            "type": "SALIVA_DROOLING"
        }, {
            "description": "Зъбобол",
            "nomenclature": "6.7",
            "selectable": false,
            "strength": 0,
            "type": "TOOTHACHE"
        }, {
            "description": "Болезненост в гърлото",
            "nomenclature": "6.8",
            "selectable": false,
            "strength": 0,
            "type": "THROAT_CAT"
        }, {
            "description": "Болезнено гърло",
            "nomenclature": "6.8.1",
            "selectable": false,
            "strength": 0,
            "type": "THROAT_PAINFUL"
        }, {
            "description": "Драскащо гърло",
            "nomenclature": "6.8.2",
            "selectable": false,
            "strength": 0,
            "type": "THROAT_SCRATCHY"
        }, {
            "description": "Възпалено гърло",
            "nomenclature": "6.8.3",
            "selectable": false,
            "strength": 0,
            "type": "SORE_THROAT"
        }, {
            "description": "Симптоми, отнасящи се до езика",
            "nomenclature": "6.9",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_CAT"
        }, {
            "description": "Ненормален цвят на езика",
            "nomenclature": "6.9.1",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_ABNORMAL_COLOR"
        }, {
            "description": "Кървенена езика",
            "nomenclature": "6.9.2",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_BLEEDING"
        }, {
            "description": "Мехури по езика",
            "nomenclature": "6.9.3",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_BLISTERS"
        }, {
            "description": "Изгорен език",
            "nomenclature": "6.9.4",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_BURNED"
        }, {
            "description": "Болка на езика",
            "nomenclature": "6.9.5",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_PAIN"
        }, {
            "description": "Хребети (изпъкнали гребени) по езика",
            "nomenclature": "6.9.6",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_RIDGES"
        }, {
            "description": "Гладък език",
            "nomenclature": "6.9.7",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_SMOOTH"
        }, {
            "description": "Подуване или маса на езика",
            "nomenclature": "6.9.8",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_SWELLING_OR_MASS"
        }, {
            "description": "Язва на езика",
            "nomenclature": "6.9.9",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_ULCER"
        }, {
            "description": "Симптоми, отнасящи се до сливиците",
            "nomenclature": "6.10",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_CAT"
        }, {
            "description": "Кървене (постоперативно) на сливиците",
            "nomenclature": "6.10.1",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_BLEEDING_POSTOPERATIVE"
        }, {
            "description": "Секретиране от сливиците",
            "nomenclature": "6.10.2",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_DISCHARGE"
        }, {
            "description": "Възпаление на сливиците",
            "nomenclature": "6.10.3",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_INFLAMMATION"
        }, {
            "description": "Подуване на сливиците",
            "nomenclature": "6.10.4",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_SWELLING"
        }, {
            "description": "Преглъщане",
            "nomenclature": "6.11",
            "selectable": false,
            "strength": 0,
            "type": "SWALLOWING_CAT"
        }, {
            "description": "Трудност при преглъщането",
            "nomenclature": "6.11.0",
            "selectable": false,
            "strength": 0,
            "type": "SWALLOWING_DIFFICULTY"
        }, {
            "description": "Задавяне",
            "nomenclature": "6.11.1",
            "selectable": false,
            "strength": 0,
            "type": "SWALLOWING_CHOKING"
        }, {
            "description": "Болка в корема",
            "nomenclature": "6.12",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_CAT"
        }, {
            "description": "Колики, чревни (с изключение на кърмачета)",
            "nomenclature": "6.12.1",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_COLIC"
        }, {
            "description": "Болка в епигастриума",
            "nomenclature": "6.12.2",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_EPIGASTRIUM"
        }, {
            "description": "Болка в илиака",
            "nomenclature": "6.12.3",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_ILIAC"
        }, {
            "description": "Болка в Inguinal (слабините)",
            "nomenclature": "6.12.4",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_INGUINAL"
        }, {
            "description": "Болка в десния/левия, горния/долния квадрант",
            "nomenclature": "6.12.5",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_QUADRANT"
        }, {
            "description": "Болка в стомаха (включва спазми)",
            "nomenclature": "6.12.6",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_STOMACH"
        }, {
            "description": "Болка в пъпната област",
            "nomenclature": "6.12.7",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_UMBILICAL"
        }, {
            "description": "Колики, детски",
            "nomenclature": "6.13",
            "selectable": false,
            "strength": 0,
            "type": "COLIC_INFANTILE"
        }, {
            "description": "Подуване на корема или маса",
            "nomenclature": "6.14",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_SWELLING_MASS_CAT"
        }, {
            "description": "Подуване на корема",
            "nomenclature": "6.14.0",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_SWELLING_MASS_DISTENTION"
        }, {
            "description": "Тежест в корема",
            "nomenclature": "6.14.1",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_SWELLING_MASS_FULLNESS"
        }, {
            "description": "Метеоризъм",
            "nomenclature": "6.15",
            "selectable": false,
            "strength": 0,
            "type": "FLATUENCE_CAT"
        }, {
            "description": "Подуване, газове",
            "nomenclature": "6.15.1",
            "selectable": false,
            "strength": 0,
            "type": "FLATUENCE_BLOATING_GAS"
        }, {
            "description": "Подуване поради газове",
            "nomenclature": "6.15.2",
            "selectable": false,
            "strength": 0,
            "type": "FLATUENCE_DISTENSION"
        }, {
            "description": "Прекомерни газове",
            "nomenclature": "6.15.3",
            "selectable": false,
            "strength": 0,
            "type": "FLATUENCE_GAS_EXCESSIVE"
        }, {
            "description": "Апетит, ненормален",
            "nomenclature": "6.16",
            "selectable": false,
            "strength": 0,
            "type": "APPETITE_CAT"
        }, {
            "description": "Намален апетит",
            "nomenclature": "6.16.1",
            "selectable": false,
            "strength": 0,
            "type": "APPETITE_DECREASE"
        }, {
            "description": "Прекомерен апетит",
            "nomenclature": "6.16.2",
            "selectable": false,
            "strength": 0,
            "type": "APPETITE_EXCESSIVE"
        }, {
            "description": "Загуба на апетит",
            "nomenclature": "6.16.3",
            "selectable": false,
            "strength": 0,
            "type": "APPETITE_LOSS"
        }, {
            "description": "Проблеми с храненето",
            "nomenclature": "6.17",
            "selectable": false,
            "strength": 0,
            "type": "FEEDING_PROBLEMS"
        }, {
            "description": "Кървене, стомашно-чревно",
            "nomenclature": "6.18",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_CAT"
        }, {
            "description": "Кръв в изпражненията (мелена)",
            "nomenclature": "6.18.1",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_STOOLS"
        }, {
            "description": "Хематемеза (повръщане на кръв)",
            "nomenclature": "6.18.2",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_HEMATEMESIS"
        }, {
            "description": "Стомашно-чревен кръвоизлив",
            "nomenclature": "6.18.3",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_HEMORRHAGE"
        }, {
            "description": "Повръщане на кръв",
            "nomenclature": "6.18.4",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_BLOOD_VOMITING"
        }, {
            "description": "Запек",
            "nomenclature": "6.19",
            "selectable": false,
            "strength": 0,
            "type": "CONSTIPATION"
        }, {
            "description": "Диария",
            "nomenclature": "6.20",
            "selectable": false,
            "strength": 0,
            "type": "DIARRHEA_CAT"
        }, {
            "description": "Диария",
            "nomenclature": "6.20.0",
            "selectable": false,
            "strength": 0,
            "type": "DIARRHEA"
        }, {
            "description": "Диарични изпражнения",
            "nomenclature": "6.20.1",
            "selectable": false,
            "strength": 0,
            "type": "DIARRHEA_LOOSE_STOOLS"
        }, {
            "description": "Други симптоми или промени във функцията на червата",
            "nomenclature": "6.21",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_CAT"
        }, {
            "description": "Обемисти изпражнения",
            "nomenclature": "6.21.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_BULKY_STOOLS"
        }, {
            "description": "Тъмни изпражнения",
            "nomenclature": "6.21.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_DARK_STOOLS"
        }, {
            "description": "Мазни изпражнения",
            "nomenclature": "6.21.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_FATTY_STOOLS"
        }, {
            "description": "Слузести изпражнения",
            "nomenclature": "6.21.4",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_MUCOUS_STOOLS"
        }, {
            "description": "Гнойни изпражнения",
            "nomenclature": "6.21.5",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_PUS_STOOLS"
        }, {
            "description": "Необичаен цвят на изпражненията",
            "nomenclature": "6.21.6",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_UNUSUAL_COLOR"
        }, {
            "description": "Необичайна миризма на изпражненията",
            "nomenclature": "6.21.7",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_UNUSUAL_ODOR"
        }, {
            "description": "Симптоми, отнасящи се до анус-ректума",
            "nomenclature": "6.22",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_CAT"
        }, {
            "description": "Ректално кървене",
            "nomenclature": "6.22.1",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_BLEEDING"
        }, {
            "description": "Сърбеж при ануса",
            "nomenclature": "6.22.2",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_ITCHING"
        }, {
            "description": "Маса при ануса",
            "nomenclature": "6.22.3",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_MASS"
        }, {
            "description": "Ректална болка",
            "nomenclature": "6.22.4",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_RECTAL_PAIN"
        }, {
            "description": "Подуване на ануса",
            "nomenclature": "6.22.5",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_SWELLING"
        }, {
            "description": "Киселини или разстроен стомах",
            "nomenclature": "6.23",
            "selectable": false,
            "strength": 0,
            "type": "UPSET_STOMACH_CAT"
        }, {
            "description": "Оригване",
            "nomenclature": "6.23.1",
            "selectable": false,
            "strength": 0,
            "type": "UPSET_STOMACH_BELCHING"
        }, {
            "description": "Стомашно разстройство",
            "nomenclature": "6.23.2",
            "selectable": false,
            "strength": 0,
            "type": "UPSET_STOMACH_INDIGESTION"
        }, {
            "description": "Гадене и повръщане",
            "nomenclature": "6.24",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA_AND_VOMITING_CAT"
        }, {
            "description": "Гадене",
            "nomenclature": "6.24.0",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA"
        }, {
            "description": "Повдигане",
            "nomenclature": "6.24.1",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA_AND_VOMITING_RETCHING"
        }, {
            "description": "Лошо ми е на стомаха",
            "nomenclature": "6.24.2",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA_AND_VOMITING_SICK_TO_STOMACH"
        }, {
            "description": "Повръщане",
            "nomenclature": "6.24.3",
            "selectable": false,
            "strength": 0,
            "type": "VOMITING"
        }, {
            "description": "Повръщане на кръв",
            "nomenclature": "6.24.4",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA_AND_VOMITING_THROWING_UP"
        }, {
            "description": "Регургитация или плюене",
            "nomenclature": "6.25",
            "selectable": false,
            "strength": 0,
            "type": "REGURGITATION_OR_SPITTING_UP"
        }, {
            "description": "Хълцане",
            "nomenclature": "6.26",
            "selectable": false,
            "strength": 0,
            "type": "HICCOUGH"
        }, {
            "description": "Жълтеница",
            "nomenclature": "6.27",
            "selectable": false,
            "strength": 0,
            "type": "JAUNDICE"
        }, {
            "description": "Черен дроб и жлъчен мехур",
            "nomenclature": "6.28",
            "selectable": false,
            "strength": 0,
            "type": "LIVER_GALLBLADDER_CAT"
        }, {
            "description": "Жлъчни колики",
            "nomenclature": "6.28.1",
            "selectable": false,
            "strength": 0,
            "type": "LIVER_GALLBLADDER_BILIARY_COLIC"
        }, {
            "description": "Камъни в жлъчката",
            "nomenclature": "6.28.2",
            "selectable": false,
            "strength": 0,
            "type": "LIVER_GALLBLADDER_GALLSTONES"
        }, {
            "description": "Уринарен тракт",
            "nomenclature": "7",
            "selectable": false,
            "strength": 0,
            "type": "URINARY_TRACT"
        }, {
            "description": "Аномалии на урината",
            "nomenclature": "7.1",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_CAT"
        }, {
            "description": "Кръв в урината",
            "nomenclature": "7.1.1",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_BLOOD"
        }, {
            "description": "Гной в урината",
            "nomenclature": "7.1.2",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_PUS"
        }, {
            "description": "Необичаен цвят на урината",
            "nomenclature": "7.1.3",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_UNUSUAL_COLOR"
        }, {
            "description": "Необичайна миризма на урината",
            "nomenclature": "7.1.4",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_UNUSUAL_ODOR"
        }, {
            "description": "Честота и ноктурия (често уриниране нощем)",
            "nomenclature": "7.2",
            "selectable": false,
            "strength": 0,
            "type": "FREQUENCY_AND_NOCTURIA_CAT"
        }, {
            "description": "Намокряне в леглото",
            "nomenclature": "7.2.1",
            "selectable": false,
            "strength": 0,
            "type": "FREQUENCY_AND_NOCTURIA_BED_WETTING"
        }, {
            "description": "Нощно изпускане на урина",
            "nomenclature": "7.2.2",
            "selectable": false,
            "strength": 0,
            "type": "FREQUENCY_AND_NOCTURIA_NIGHT_DISCHARGE"
        }, {
            "description": "Инконтиненция (неволно изпускане) на урина",
            "nomenclature": "7.3",
            "selectable": false,
            "strength": 0,
            "type": "INCONTINENCE_OF_URINE_CAT"
        }, {
            "description": "Дрибъл (капене след уриниране)",
            "nomenclature": "7.3.1",
            "selectable": false,
            "strength": 0,
            "type": "INCONTINENCE_OF_URINE_DRIBBLING"
        }, {
            "description": "Неволно уриниране",
            "nomenclature": "7.3.2",
            "selectable": false,
            "strength": 0,
            "type": "INCONTINENCE_OF_URINE_INVOLUNTARY_URINATION"
        }, {
            "description": "Задържане на урина",
            "nomenclature": "7.4",
            "selectable": false,
            "strength": 0,
            "type": "RETENTION_OF_URINE_CAT"
        }, {
            "description": "Не може да се изпразни пикочния мехур",
            "nomenclature": "7.4.1",
            "selectable": false,
            "strength": 0,
            "type": "RETENTION_OF_URINE_CANNOT_EMPTY_BLADDER"
        }, {
            "description": "Невъзможност за уриниране",
            "nomenclature": "7.4.2",
            "selectable": false,
            "strength": 0,
            "type": "RETENTION_OF_URINE_INABILITY_TO_URINATE"
        }, {
            "description": "Болезнено уриниране",
            "nomenclature": "7.5",
            "selectable": false,
            "strength": 0,
            "type": "PAINFUL_URINATION_CAT"
        }, {
            "description": "Болезнено уриниране",
            "nomenclature": "7.5.0",
            "selectable": false,
            "strength": 0,
            "type": "PAINFUL_URINATION"
        }, {
            "description": "Парене при уриниране",
            "nomenclature": "7.5.1",
            "selectable": false,
            "strength": 0,
            "type": "PAINFUL_URINATION_BURNING"
        }, {
            "description": "Други симптоми на пикочните пътища",
            "nomenclature": "7.6",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_URINARY_SYMPTOMS_CAT"
        }, {
            "description": "Проблеми с пикочния мехур",
            "nomenclature": "7.6.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_URINARY_SYMPTOMS_BLADDER_TROUBLE"
        }, {
            "description": "Преминаващи камъни/песъчинки при уриниране",
            "nomenclature": "7.6.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_URINARY_SYMPTOMS_PASSED_STONES"
        }, {
            "description": "Мъжка репродуктивна система",
            "nomenclature": "8",
            "selectable": false,
            "strength": 0,
            "type": "MALE_REPRODUCTIVE_SYSTEM_CAT"
        }, {
            "description": "Безплодие  –  Мъжe",
            "nomenclature": "8.1",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_MALE_CAT"
        }, {
            "description": "Нисък брой сперматозоиди",
            "nomenclature": "8.1.1",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_MALE_LOW_SPERM_COUNT"
        }, {
            "description": "Стерилитет",
            "nomenclature": "8.1.2",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_MALE_STERILITY"
        }, {
            "description": "Болка, подуване или маса в мъжката полова система",
            "nomenclature": "8.2",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_CAT"
        }, {
            "description": "Болка в пениса",
            "nomenclature": "8.2.1",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_PAIN_PENIS"
        }, {
            "description": "Болка в скротума",
            "nomenclature": "8.2.2",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_PAIN_SCROTUM"
        }, {
            "description": "Болка в тестисите",
            "nomenclature": "8.2.3",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_PAIN_TESTES"
        }, {
            "description": "Подуване или маса (бучка) в пениса",
            "nomenclature": "8.2.4",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_SWELLING_PENIS"
        }, {
            "description": "Подуване или маса (бучка) в скротума",
            "nomenclature": "8.2.5",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_SWELLING_SCROTUM"
        }, {
            "description": "Подуване или маса (бучка) в тестисите",
            "nomenclature": "8.2.6",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_SWELLING_TESTES"
        }, {
            "description": "Други симптоми на мъжката репродуктивна система",
            "nomenclature": "8.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_MALE_REPRODUCTIVE_SYSTEM_CAT"
        }, {
            "description": "Психосексуални проблеми",
            "nomenclature": "8.3.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_MALE_REPRODUCTIVE_SYSTEM_PSYCHOSEXUAL_PROBLEMS"
        }, {
            "description": "Женска репродуктивна система",
            "nomenclature": "9",
            "selectable": false,
            "strength": 0,
            "type": "FEMALE_REPRODUCTIVE_PROBLEMS_CAT"
        }, {
            "description": "Симптоми в менопаузата",
            "nomenclature": "9.1",
            "selectable": false,
            "strength": 0,
            "type": "MENOPAUSE_CAT"
        }, {
            "description": "Горещи вълни",
            "nomenclature": "9.1.1",
            "selectable": false,
            "strength": 0,
            "type": "MENOPAUSE_HOT_FLASHES"
        }, {
            "description": "Предменструално напрежение",
            "nomenclature": "9.2",
            "selectable": false,
            "strength": 0,
            "type": "PREMENSTRUAL_TENSION"
        }, {
            "description": "Менструални крампи",
            "nomenclature": "9.3",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_CRAMPS"
        }, {
            "description": "Менструални нарушения",
            "nomenclature": "9.4",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_CAT"
        }, {
            "description": "Отсъствие на менструация (аменорея)",
            "nomenclature": "9.4.1",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_ABSENCE_AMENORRHEA"
        }, {
            "description": "Нетипичен менструален материал (секрет)",
            "nomenclature": "9.4.2",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_ATYPICAL_MATERIAL"
        }, {
            "description": "Менструални кръвни съсиреци",
            "nomenclature": "9.4.3",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_BLOOD_CLOTS"
        }, {
            "description": "Прекомерно менструално течение",
            "nomenclature": "9.4.4",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_EXCESSIVE"
        }, {
            "description": "Честа менструация",
            "nomenclature": "9.4.5",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_FREQUENT"
        }, {
            "description": "Рядка менструация",
            "nomenclature": "9.4.6",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_INFREQUENT"
        }, {
            "description": "Нередовна менструация (метрорагия)",
            "nomenclature": "9.4.7",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_IRREGULAR_METRORRHAGIA"
        }, {
            "description": "Обилно менструално течение",
            "nomenclature": "9.4.8",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_LARGE_FLOW"
        }, {
            "description": "Забавено начало на менструален цикъл",
            "nomenclature": "9.4.9",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_ONSET_DELAYED"
        }, {
            "description": "Оскъдно менструално течение",
            "nomenclature": "9.4.10",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_SCANTY"
        }, {
            "description": "Слабо менструално течение",
            "nomenclature": "9.4.11",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_SMALL_FLOW"
        }, {
            "description": "Овулационна болка",
            "nomenclature": "9.5",
            "selectable": false,
            "strength": 0,
            "type": "OVULATION_PAIN"
        }, {
            "description": "Тазови симптоми",
            "nomenclature": "9.6",
            "selectable": false,
            "strength": 0,
            "type": "PELVIC_CAT"
        }, {
            "description": "Болка в таза",
            "nomenclature": "9.6.1",
            "selectable": false,
            "strength": 0,
            "type": "PELVIC_PAIN"
        }, {
            "description": "Усещане за натиск или падане в таза",
            "nomenclature": "9.6.2",
            "selectable": false,
            "strength": 0,
            "type": "PELVIC_PRESSURE"
        }, {
            "description": "Подуване или бучка на таза",
            "nomenclature": "9.6.3",
            "selectable": false,
            "strength": 0,
            "type": "PELVIC_SWELLING_OR_MASS"
        }, {
            "description": "Вагинални нарушения",
            "nomenclature": "9.7",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISORDERS_CAT"
        }, {
            "description": "Вагинална болка",
            "nomenclature": "9.7.1",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISORDERS_PAIN"
        }, {
            "description": "Вагинално подуване или бучка",
            "nomenclature": "9.7.2",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISORDERS_SWELLING_OR_MASS"
        }, {
            "description": "Вагинално течение",
            "nomenclature": "9.8",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISCHARGE_CAT"
        }, {
            "description": "Атипично вагинално течение (левкорея, бяло течение)",
            "nomenclature": "9.8.1",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISCHARGE_ATYPICAL"
        }, {
            "description": "Кърваво вагинално течение",
            "nomenclature": "9.8.2",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISCHARGE_BLOODY"
        }, {
            "description": "Кафяво вагинално течение",
            "nomenclature": "9.8.3",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISCHARGE_BROWN"
        }, {
            "description": "Симптоми, свързани с вулвата (външните полови органи)",
            "nomenclature": "9.9",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_CAT"
        }, {
            "description": "Сърбеж на вулвата",
            "nomenclature": "9.9.1",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_ITCHING"
        }, {
            "description": "Болка при вулвата",
            "nomenclature": "9.9.2",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_PAIN"
        }, {
            "description": "Подуване или маса на перинеума",
            "nomenclature": "9.9.3",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_SWELLING_OR_MASS"
        }, {
            "description": "Язва на вулвата",
            "nomenclature": "9.9.4",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_ULCER"
        }, {
            "description": "Безплодие – Жени",
            "nomenclature": "9.10",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_FEMALE_CAT"
        }, {
            "description": "Стерилитет",
            "nomenclature": "9.10.1",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_FEMALE_STERILITY"
        }, {
            "description": "Проблеми на бременността",
            "nomenclature": "9.11",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_CAT"
        }, {
            "description": "Изтичане на околоплодна течност",
            "nomenclature": "9.11.1",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_LEAKING_AMNIOTIC_FLUID"
        }, {
            "description": "Възможно начало на раждане",
            "nomenclature": "9.11.2",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_POSSIBLE_LABOR"
        }, {
            "description": "Изхвърляне на плода (спонтанен аборт)",
            "nomenclature": "9.11.3",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_CONCEPTION_PRODUCT_PASSED"
        }, {
            "description": "Леко кървене (зацапване) при бременност",
            "nomenclature": "9.11.4",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_SPOTTING"
        }, {
            "description": "Други симптоми на женската репродуктивна система",
            "nomenclature": "9.12",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_FEMALE_REPRODUCTIVE_SYSTEM_SYMPTOMS"
        }, {
            "description": "Бучка или маса на гърдата",
            "nomenclature": "9.14",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_CAT"
        }, {
            "description": "Подутина на гърдата",
            "nomenclature": "9.14.1",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_BUMP"
        }, {
            "description": "Твърдо място (петно) на гърдата",
            "nomenclature": "9.14.2",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_HARD_SPOT"
        }, {
            "description": "Възел в гърдата",
            "nomenclature": "9.14.3",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_KNOT"
        }, {
            "description": "Локален оток на гърдата",
            "nomenclature": "9.14.4",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_LOCAL_SWELLING"
        }, {
            "description": "Възелче на гърдата",
            "nomenclature": "9.14.5",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_NODULE"
        }, {
            "description": "Болка или болезненост на гърдата",
            "nomenclature": "9.15",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_PAIN_CAT"
        }, {
            "description": "Зачервяване на гърдата",
            "nomenclature": "9.15.1",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_PAIN_REDNESS"
        }, {
            "description": "Оток на гърдата, общ",
            "nomenclature": "9.15.2",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_PAIN_SWELLING_GENERALIZED"
        }, {
            "description": "Чувствителност на гърдата",
            "nomenclature": "9.15.3",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_PAIN_TENDERNESS"
        }, {
            "description": "Симптоми на зърната",
            "nomenclature": "9.16",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_CAT"
        }, {
            "description": "Кървене на зърната",
            "nomenclature": "9.16.1",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_BLEEDING"
        }, {
            "description": "Промяна в цвета на зърната",
            "nomenclature": "9.16.2",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_CHANGE_IN_COLOR"
        }, {
            "description": "Секретиране от зърната",
            "nomenclature": "9.16.3",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_DISCHARGE"
        }, {
            "description": "Възпаление на зърната",
            "nomenclature": "9.16.4",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_INFLAMMATION"
        }, {
            "description": "Хлътване, прибиране на зърната",
            "nomenclature": "9.16.5",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_INVERSION"
        }, {
            "description": "Следродилни проблеми на гърдата",
            "nomenclature": "9.17",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_CAT"
        }, {
            "description": "Ненормално секретиране от гърдата",
            "nomenclature": "9.17.1",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_ABNORMAL_SECRETION"
        }, {
            "description": "Липса на кърма",
            "nomenclature": "9.17.2",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_ABSENCE_OF_MILK"
        }, {
            "description": "Трудност или невъзможност за кърмене",
            "nomenclature": "9.17.3",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_NURSING"
        }, {
            "description": "Препълване на гърдата",
            "nomenclature": "9.17.4",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_ENGORGEMENT"
        }, {
            "description": "Прекомерно мляко",
            "nomenclature": "9.17.5",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_EXCESSIVE_MILK"
        }, {
            "description": "Неправилна лактация",
            "nomenclature": "9.17.6",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_IMPROPER_LACTATION"
        }, {
            "description": "Други симптоми на гърдата",
            "nomenclature": "9.18",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BREAST_SYMPTOMS_CAT"
        }, {
            "description": "Увисване на гърдите",
            "nomenclature": "9.18.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BREAST_SYMPTOMS_SAGGING"
        }, {
            "description": "Прекалено големи гърди",
            "nomenclature": "9.18.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BREAST_SYMPTOMS_TOO_LARGE"
        }, {
            "description": "Твърде малки гърди",
            "nomenclature": "9.18.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BREAST_SYMPTOMS_TOO_SMALL"
        }, {
            "description": "Очи и уши",
            "nomenclature": "10",
            "selectable": false,
            "strength": 0,
            "type": "EYES_AND_EARS_CAT"
        }, {
            "description": "Пълна слепота",
            "nomenclature": "10.1",
            "selectable": false,
            "strength": 0,
            "type": "BLINDNESS_COMPLETE"
        }, {
            "description": "Друга дисфункция на зрението",
            "nomenclature": "10.2",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_CAT"
        }, {
            "description": "Неясно зрение",
            "nomenclature": "10.2.1",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_BLURRED"
        }, {
            "description": "Облачно зрение",
            "nomenclature": "10.2.2",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_CLOUDY_VISION"
        }, {
            "description": "Намалено зрение",
            "nomenclature": "10.2.3",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_DIMINISHED"
        }, {
            "description": "Затъмнено зрение",
            "nomenclature": "10.2.4",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_DULL_VISION"
        }, {
            "description": "Плаващи мътнини (мушици) в окото",
            "nomenclature": "10.2.5",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_FLOATERS"
        }, {
            "description": "Хемианопсия (отпадане на половината зрително поле)",
            "nomenclature": "10.2.6",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_HALF_VISION"
        }, {
            "description": "Мъгляво зрение",
            "nomenclature": "10.2.7",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_HAZY_VISION"
        }, {
            "description": "Фотофобия",
            "nomenclature": "10.2.8",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_PHOTOPHOBIA"
        }, {
            "description": "Петна в окото",
            "nomenclature": "10.2.9",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_SPOTS"
        }, {
            "description": "Секретиране на очите",
            "nomenclature": "10.3",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_CAT"
        }, {
            "description": "Кръв от очите",
            "nomenclature": "10.3.1",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_BLOOD"
        }, {
            "description": "Прекомерно сълзене",
            "nomenclature": "10.3.2",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_EXCESSIVE_TEARING"
        }, {
            "description": "Гной от очите",
            "nomenclature": "10.3.3",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_PUS"
        }, {
            "description": "Воднисти очи",
            "nomenclature": "10.3.4",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_WATERING"
        }, {
            "description": "Очна болка и дразнене",
            "nomenclature": "10.4",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_CAT"
        }, {
            "description": "Парене на очите",
            "nomenclature": "10.4.1",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_BURNING"
        }, {
            "description": "Възпалени очи",
            "nomenclature": "10.4.2",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_INFLAMED"
        }, {
            "description": "Раздразнение на очите",
            "nomenclature": "10.4.3",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_IRRITATION"
        }, {
            "description": "Сърбеж в очите",
            "nomenclature": "10.4.4",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_ITCHING"
        }, {
            "description": "Подуване или бучка в окото",
            "nomenclature": "10.4.5",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_SWELLING_OR_MASS"
        }, {
            "description": "Ненормални движения на очите",
            "nomenclature": "10.5",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_CAT"
        }, {
            "description": "Ненормално хлътване на очните ябълки",
            "nomenclature": "10.5.1",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_RETRACTION"
        }, {
            "description": "Кръстосване на очите",
            "nomenclature": "10.5.2",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_CROSS_EYED"
        }, {
            "description": "Различни по размер зеници",
            "nomenclature": "10.5.3",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_UNEQUAL_PUPIL"
        }, {
            "description": "Спазми",
            "nomenclature": "10.5.4",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_SPASMS"
        }, {
            "description": "Кривогледство",
            "nomenclature": "10.5.5",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_SQUINTING"
        }, {
            "description": "Потрепване",
            "nomenclature": "10.5.6",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_TWITCHING"
        }, {
            "description": "Симптоми на клепачите",
            "nomenclature": "10.6",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_CAT"
        }, {
            "description": "Падащ клепач",
            "nomenclature": "10.6.1",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_DROOPING"
        }, {
            "description": "Възпаление на клепачите",
            "nomenclature": "10.6.2",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_INFLAMMATION"
        }, {
            "description": "Сърбеж на клепачите",
            "nomenclature": "10.6.3",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_ITCHING"
        }, {
            "description": "Подуване или бучка на клепачите",
            "nomenclature": "10.6.4",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_SWELLING_OR_MASS"
        }, {
            "description": "Ечемик на окото",
            "nomenclature": "10.7",
            "selectable": false,
            "strength": 0,
            "type": "STY"
        }, {
            "description": "Розово око",
            "nomenclature": "10.8",
            "selectable": false,
            "strength": 0,
            "type": "PINK_EYE_CAT"
        }, {
            "description": "Розово око",
            "nomenclature": "10.8.0",
            "selectable": false,
            "strength": 0,
            "type": "PINK_EYE"
        }, {
            "description": "Конюктивит",
            "nomenclature": "10.8.1",
            "selectable": false,
            "strength": 0,
            "type": "PINK_EYE_CONJUNCTIVITIS"
        }, {
            "description": "Чуждо тяло в очите",
            "nomenclature": "10.9",
            "selectable": false,
            "strength": 0,
            "type": "FOREIGN_BODY_IN_EYES"
        }, {
            "description": "Очни наранявания",
            "nomenclature": "10.10",
            "selectable": false,
            "strength": 0,
            "type": "EYE_INJURIES_CAT"
        }, {
            "description": "Черно око",
            "nomenclature": "10.10.1",
            "selectable": false,
            "strength": 0,
            "type": "EYE_INJURIES_BLACK_EYE"
        }, {
            "description": "Изгаряния на окото",
            "nomenclature": "10.10.2",
            "selectable": false,
            "strength": 0,
            "type": "EYE_INJURIES_BURNS"
        }, {
            "description": "Драскотини по окото",
            "nomenclature": "10.10.3",
            "selectable": false,
            "strength": 0,
            "type": "EYE_INJURIES_SCRATCHES"
        }, {
            "description": "Ненормален външен вид на очите",
            "nomenclature": "10.11",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_CAT"
        }, {
            "description": "Ненормална изпъкналост на очите",
            "nomenclature": "10.11.1",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_PROTRUSION"
        }, {
            "description": "Кръвясало око",
            "nomenclature": "10.11.2",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_BLOODSHOT"
        }, {
            "description": "Мътни очи",
            "nomenclature": "10.11.3",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_CLOUDY"
        }, {
            "description": "Замъглени очи",
            "nomenclature": "10.11.4",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_DULL"
        }, {
            "description": "Перде на очите",
            "nomenclature": "10.11.5",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_HAZY"
        }, {
            "description": "Глухота",
            "nomenclature": "10.13",
            "selectable": false,
            "strength": 0,
            "type": "DEAFNESS"
        }, {
            "description": "Други слухови дисфункции",
            "nomenclature": "10.14",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_CAT"
        }, {
            "description": "Загуба на остър слух",
            "nomenclature": "10.14.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_ACUTE_HEARING"
        }, {
            "description": "Намален слух",
            "nomenclature": "10.14.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_DIMINISHED_HEARING"
        }, {
            "description": "Шум в ушите",
            "nomenclature": "10.14.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_NOICES"
        }, {
            "description": "Звън в ушите",
            "nomenclature": "10.14.4",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_RINGING_IN_EARS"
        }, {
            "description": "Проблеми със слуха",
            "nomenclature": "10.14.5",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_TROUBLE_HEARING"
        }, {
            "description": "Секретиране на ушите",
            "nomenclature": "10.15",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EAR_CAT"
        }, {
            "description": "Гной от ушите",
            "nomenclature": "10.15.0",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EAR_PUS"
        }, {
            "description": "Кървене от ушите",
            "nomenclature": "10.15.1",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EAR_BLEEDING"
        }, {
            "description": "Болка в ухото",
            "nomenclature": "10.16",
            "selectable": false,
            "strength": 0,
            "type": "EARACHE_CAT"
        }, {
            "description": "Болка в ухото",
            "nomenclature": "10.16.1",
            "selectable": false,
            "strength": 0,
            "type": "EARACHE"
        }, {
            "description": "Усещане за запушване на ушите",
            "nomenclature": "10.17",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_CAT"
        }, {
            "description": "Заглъхване на ушите",
            "nomenclature": "10.17.1",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_BLOCKED"
        }, {
            "description": "Пукане в ушите",
            "nomenclature": "10.17.2",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_CRACKING"
        }, {
            "description": "Пропуквания в ушите",
            "nomenclature": "10.17.3",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_POPPING"
        }, {
            "description": "Запушване на ушите",
            "nomenclature": "10.17.4",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_STOPPED_UP"
        }, {
            "description": "Излишък на ушна кал",
            "nomenclature": "10.18",
            "selectable": false,
            "strength": 0,
            "type": "EXCESS_WAX_IN_EAR"
        }, {
            "description": "Ненормален размер на ухото",
            "nomenclature": "10.19",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EAR_SIZE"
        }, {
            "description": "Други симптоми, отнасящи се до ушите",
            "nomenclature": "10.20",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_EAR_SYMPTOMS_CAT"
        }, {
            "description": "Чуждо тяло в ухото",
            "nomenclature": "10.20.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_EAR_SYMPTOMS_FOREIGN_BODY"
        }, {
            "description": "Сърбеж в ушите",
            "nomenclature": "10.20.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_EAR_SYMPTOMS_ITCHING"
        }, {
            "description": "Подуване или бучка в ушите",
            "nomenclature": "10.20.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_EAR_SYMPTOMS_SWELLING_OR_MASS"
        }, {
            "description": "Психическо здраве",
            "nomenclature": "11",
            "selectable": false,
            "strength": 0,
            "type": "MENTAL_HEALTH_CAT"
        }, {
            "description": "Безпокойство",
            "nomenclature": "11.1",
            "selectable": false,
            "strength": 0,
            "type": "ANXIETY_CAT"
        }, {
            "description": "Опасение, безпокойство",
            "nomenclature": "11.1.1",
            "selectable": false,
            "strength": 0,
            "type": "ANXIETY_APPREHENSION"
        }, {
            "description": "Страхове и фобии",
            "nomenclature": "11.3",
            "selectable": false,
            "strength": 0,
            "type": "FEARS_AND_PHOBIAS_CAT"
        }, {
            "description": "Безпокойство",
            "nomenclature": "11.3.0",
            "selectable": false,
            "strength": 0,
            "type": "FEARS_AND_PHOBIAS_RESTLESSNESS"
        }, {
            "description": "Хиперактивност",
            "nomenclature": "11.3.1",
            "selectable": false,
            "strength": 0,
            "type": "FEARS_AND_PHOBIAS_HYPERACTIVITY"
        }, {
            "description": "Свръхактивност",
            "nomenclature": "11.3.2",
            "selectable": false,
            "strength": 0,
            "type": "FEARS_AND_PHOBIAS_OVERACTIVITY"
        }, {
            "description": "Самота",
            "nomenclature": "11.4",
            "selectable": false,
            "strength": 0,
            "type": "LONELINESS"
        }, {
            "description": "Депресия",
            "nomenclature": "11.5",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_CAT"
        }, {
            "description": "Депресия, горчивина",
            "nomenclature": "11.5.1",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_BITTERNESS"
        }, {
            "description": "Депресия, прекомерен плач",
            "nomenclature": "11.5.2",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_CRYING_EXCESSIVELY"
        }, {
            "description": "Депресия, мрачен",
            "nomenclature": "11.5.3",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_DEJECTED"
        }, {
            "description": "Депресия, недоволство",
            "nomenclature": "11.5.4",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_DISCONTENTED"
        }, {
            "description": "Депресия, чувство на изгубеност",
            "nomenclature": "11.5.5",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_FEELING_LOST"
        }, {
            "description": "Депресия, подтиснатост",
            "nomenclature": "11.5.6",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_FEELING_LOW"
        }, {
            "description": "Депресия, отхвърленост",
            "nomenclature": "11.5.7",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_FEELING_REJECTED"
        }, {
            "description": "Депресия, безнадеждност",
            "nomenclature": "11.5.8",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_HOPELESSNESS"
        }, {
            "description": "Депресия, нещастен",
            "nomenclature": "11.5.9",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_UNHAPPY"
        }, {
            "description": "Депресия, тревожност",
            "nomenclature": "11.5.10",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_WORRYING"
        }, {
            "description": "Нервност",
            "nomenclature": "11.6",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_CAT"
        }, {
            "description": "“Пеперуди в стомаха”",
            "nomenclature": "11.6.1",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_BUTTERFLIES"
        }, {
            "description": "Нерви",
            "nomenclature": "11.6.2",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_NERVES"
        }, {
            "description": "Нервно напрежение",
            "nomenclature": "11.6.3",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_TENSION"
        }, {
            "description": "Нервно разстройство",
            "nomenclature": "11.6.4",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_UPSET"
        }, {
            "description": "Поведенчески смущения",
            "nomenclature": "11.7",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_CAT"
        }, {
            "description": "Антисоциално поведение",
            "nomenclature": "11.7.1",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_ANTISOCIAL_BEHAVIOR"
        }, {
            "description": "Поведенчески проблеми",
            "nomenclature": "11.7.2",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_BEHAVORIAL_PROBLEMS"
        }, {
            "description": "Раздразнителност",
            "nomenclature": "11.7.3",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_IRRITABILITY"
        }, {
            "description": "Свадливост",
            "nomenclature": "11.7.4",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_QUARRELSOME"
        }, {
            "description": "Изблици на раздразнение",
            "nomenclature": "11.7.5",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_TEMPER_TANTRUMS"
        }, {
            "description": "Прекомерно пушене",
            "nomenclature": "11.8",
            "selectable": false,
            "strength": 0,
            "type": "EXCESSIVE_SMOKING"
        }, {
            "description": "Проблеми, свързани с алкохола",
            "nomenclature": "11.9",
            "selectable": false,
            "strength": 0,
            "type": "ALCOHOL_RELATED_PROBLEMS_CAT"
        }, {
            "description": "Алкохолизъм",
            "nomenclature": "11.9.1",
            "selectable": false,
            "strength": 0,
            "type": "ALCOHOL_RELATED_PROBLEMS_ALCOHOLISM"
        }, {
            "description": "Пие твърде много",
            "nomenclature": "11.9.2",
            "selectable": false,
            "strength": 0,
            "type": "ALCOHOL_RELATED_PROBLEMS_DRINKS_TOO_MUCH"
        }, {
            "description": "Използване на наркотици",
            "nomenclature": "11.10",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_DRUG_USAGE_CAT"
        }, {
            "description": "Прекомерната употреба на стимуланти или антидепресанти",
            "nomenclature": "11.10.1",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_DRUG_USAGE_STIMULANTS_DEPRESSANTS"
        }, {
            "description": "Злоупотреба с лекарства или наркотици",
            "nomenclature": "11.10.2",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_DRUG_USAGE_MISUSE"
        }, {
            "description": "Илюзии или халюцинации",
            "nomenclature": "11.11",
            "selectable": false,
            "strength": 0,
            "type": "DELUSIONS_OR_HALLUCINATIONS"
        }, {
            "description": "Лоши навици",
            "nomenclature": "11.12",
            "selectable": false,
            "strength": 0,
            "type": "BAD_HABITS_CAT"
        }, {
            "description": "Дъвчене на косата",
            "nomenclature": "11.12.1",
            "selectable": false,
            "strength": 0,
            "type": "BAD_HABITS_CHEWING_ON_HAIR"
        }, {
            "description": "Гризане на ноктите",
            "nomenclature": "11.12.2",
            "selectable": false,
            "strength": 0,
            "type": "BAD_HABITS_NAIL_BITING"
        }, {
            "description": "Смучене на пръст (палец)",
            "nomenclature": "11.12.3",
            "selectable": false,
            "strength": 0,
            "type": "BAD_HABITS_THUMB_SUCKING"
        }, {
            "description": "Обсесия или принуда",
            "nomenclature": "11.13",
            "selectable": false,
            "strength": 0,
            "type": "OBSESSIONS_OR_COMPULSIONS"
        }, {
            "description": "Психосексуални разстройства",
            "nomenclature": "11.14",
            "selectable": false,
            "strength": 0,
            "type": "PSYCHOSEXUAL_DISORDERS_CAT"
        }, {
            "description": "Фригидност",
            "nomenclature": "11.14.1",
            "selectable": false,
            "strength": 0,
            "type": "PSYCHOSEXUAL_DISORDERS_FRIGIDITY"
        }, {
            "description": "Импотентност",
            "nomenclature": "11.14.2",
            "selectable": false,
            "strength": 0,
            "type": "PSYCHOSEXUAL_DISORDERS_IMPOTENCE"
        }],
        "Medrec-M": [{
            "description": "Общи симптоми",
            "nomenclature": "0",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SYMPTOMS_CAT"
        }, {
            "description": "Втрисане",
            "nomenclature": "0.1",
            "selectable": false,
            "strength": 0,
            "type": "CHILLS"
        }, {
            "description": "Треска",
            "nomenclature": "0.2",
            "selectable": false,
            "strength": 0,
            "type": "FEVER_CAT"
        }, {
            "description": "Треска",
            "nomenclature": "0.2.1",
            "selectable": false,
            "strength": 0,
            "type": "FEVER"
        }, {
            "description": "Висока температура",
            "nomenclature": "0.2.2",
            "selectable": false,
            "strength": 0,
            "type": "FEVER_HIGH_TEMPERATURE"
        }, {
            "description": "Умора",
            "nomenclature": "0.3",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_CAT"
        }, {
            "description": "Умора",
            "nomenclature": "0.3.0",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE"
        }, {
            "description": "Изтощение",
            "nomenclature": "0.3.1",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_EXHAUSTED"
        }, {
            "description": "Обща слабост",
            "nomenclature": "0.4.2",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_GENERAL_WEAKNESS"
        }, {
            "description": "Изцеден",
            "nomenclature": "0.3.3",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_POOPED"
        }, {
            "description": "Повален",
            "nomenclature": "0.3.4",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_RUNDOWN"
        }, {
            "description": "Изморен",
            "nomenclature": "0.3.5",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_TIRED"
        }, {
            "description": "Износен",
            "nomenclature": "0.3.6",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_WORN_OUT"
        }, {
            "description": "Общо неразположение",
            "nomenclature": "0.4",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_FEELING_CAT"
        }, {
            "description": "Не се чувствам добре",
            "nomenclature": "0.4.1",
            "selectable": false,
            "strength": 0,
            "type": "NOT_FEELING_WELL"
        }, {
            "description": "Дисбаланс на течностите",
            "nomenclature": "0.5",
            "selectable": false,
            "strength": 0,
            "type": "FLUID_IMBALANCE_CAT"
        }, {
            "description": "Дехидратация",
            "nomenclature": "0.5.1",
            "selectable": false,
            "strength": 0,
            "type": "DEHYDRATION"
        }, {
            "description": "Прекомерно изпотяване",
            "nomenclature": "0.5.2",
            "selectable": false,
            "strength": 0,
            "type": "FLUID_IMBALANCE_EXCESSIVE_SWEATING"
        }, {
            "description": "Прекомерна жажда",
            "nomenclature": "0.5.3",
            "selectable": false,
            "strength": 0,
            "type": "FLUID_IMBALANCE_EXCESSIVE_THIRST"
        }, {
            "description": "Задържане на течности",
            "nomenclature": "0.5.4",
            "selectable": false,
            "strength": 0,
            "type": "FLUID_IMBALANCE_RETENTION_OF_FLUID"
        }, {
            "description": "Забавено физиологическо развитие",
            "nomenclature": "0.6",
            "selectable": false,
            "strength": 0,
            "type": "LACK_PHYS_DEVELOPMENT_CAT"
        }, {
            "description": "Забавен растеж",
            "nomenclature": "0.6.1",
            "selectable": false,
            "strength": 0,
            "type": "LACK_PHYS_DEVELOPMENT_LACK_GROWTH"
        }, {
            "description": "Качване на тегло",
            "nomenclature": "0.7",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_GAIN_CAT"
        }, {
            "description": "Наднормено тегло",
            "nomenclature": "0.7.1",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_GAIN_OVERWEIGHT"
        }, {
            "description": "Затлъстяване",
            "nomenclature": "0.7.2",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_GAIN_OBESITY"
        }, {
            "description": "Загуба на тегло",
            "nomenclature": "0.8",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_LOSS_CAT"
        }, {
            "description": "Скорошна загуба на тегло",
            "nomenclature": "0.8.1",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_LOSS_RECENT_WEIGHT_LOSS"
        }, {
            "description": "Поднормено тегло",
            "nomenclature": "0.8.2",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_LOSS_UNDERWEIGHT"
        }, {
            "description": "Болка, генерализирана, място неуточнено",
            "nomenclature": "0.9",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_CAT"
        }, {
            "description": "Болезненост",
            "nomenclature": "0.9.1",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_ACHE"
        }, {
            "description": "Болки навсякъде",
            "nomenclature": "0.9.2",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_ACHES_OVER"
        }, {
            "description": "Схващане",
            "nomenclature": "0.9.3",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_CRAMP"
        }, {
            "description": "Болка",
            "nomenclature": "0.9.4",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_HURT"
        }, {
            "description": "Скованост",
            "nomenclature": "0.9.5",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_STIFFNESS"
        }, {
            "description": "Подуване или маса, място неопределено",
            "nomenclature": "0.10",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_CAT"
        }, {
            "description": "Изпъкналост",
            "nomenclature": "0.10.1",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_BULGE"
        }, {
            "description": "Подутина",
            "nomenclature": "0.10.2",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_BUMP"
        }, {
            "description": "Възел",
            "nomenclature": "0.10.3",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_KNOT"
        }, {
            "description": "Буца",
            "nomenclature": "0.10.4",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_LUMP"
        }, {
            "description": "Общи симптоми на кърмачета и деца",
            "nomenclature": "0.11",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_CAT"
        }, {
            "description": "Плаче прекалено много",
            "nomenclature": "0.11.1",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_CRYING_TOO_MUCH"
        }, {
            "description": "Нервност",
            "nomenclature": "0.11.2",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_FIDGETY"
        }, {
            "description": "Тръшкане",
            "nomenclature": "0.11.3",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_FUSSY"
        }, {
            "description": "Хиперактивен",
            "nomenclature": "0.11.4",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_HYPERACTIVE"
        }, {
            "description": "Раздразнителен",
            "nomenclature": "0.11.5",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_IRRITABLE"
        }, {
            "description": "Намалена активност",
            "nomenclature": "0.11.6",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_UNDERACTIVE"
        }, {
            "description": "Нервна система",
            "nomenclature": "1",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUS_SYSTEM_CAT"
        }, {
            "description": "Ненормални неволни движения",
            "nomenclature": "1.1",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_CAT"
        }, {
            "description": "Kлатене",
            "nomenclature": "1.1.1",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_SHAKING"
        }, {
            "description": "Тик",
            "nomenclature": "1.1.2",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_TIC"
        }, {
            "description": "Треперене",
            "nomenclature": "1.1.3",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_TREMOR"
        }, {
            "description": "Конвулсивни движения",
            "nomenclature": "1.1.4",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_TWITCH"
        }, {
            "description": "Кома и ступор",
            "nomenclature": "1.2",
            "selectable": false,
            "strength": 0,
            "type": "COMA_AND_STUPOR"
        }, {
            "description": "Oбъркване",
            "nomenclature": "1.3",
            "selectable": false,
            "strength": 0,
            "type": "CONFUSION"
        }, {
            "description": "Конвулсии",
            "nomenclature": "1.4",
            "selectable": false,
            "strength": 0,
            "type": "CONVULSIONS_CAT"
        }, {
            "description": "Пристъпи",
            "nomenclature": "1.4.1",
            "selectable": false,
            "strength": 0,
            "type": "CONVULSIONS_FITS"
        }, {
            "description": "Припадъци",
            "nomenclature": "1.4.2",
            "selectable": false,
            "strength": 0,
            "type": "CONVULSIONS_SEIZURES"
        }, {
            "description": "Замайване",
            "nomenclature": "1.4.3",
            "selectable": false,
            "strength": 0,
            "type": "CONVULSIONS_SPELLS"
        }, {
            "description": "Главоболие",
            "nomenclature": "1.5",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE_CAT"
        }, {
            "description": "Главоболие",
            "nomenclature": "1.5.0",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE"
        }, {
            "description": "Мигрена",
            "nomenclature": "1.5.1",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE_MIGRAINE"
        }, {
            "description": "Нервност",
            "nomenclature": "1.5.2",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE_NERVOUS"
        }, {
            "description": "Напрежение",
            "nomenclature": "1.5.3",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE_TENSION"
        }, {
            "description": "Памет, смущения",
            "nomenclature": "1.6",
            "selectable": false,
            "strength": 0,
            "type": "MEMORY_DISTURBANCE_CAT"
        }, {
            "description": "Амнезия",
            "nomenclature": "1.6.1",
            "selectable": false,
            "strength": 0,
            "type": "MEMORY_DISTURBANCE_AMNESIA"
        }, {
            "description": "Липса или загуба на памет",
            "nomenclature": "1.6.2",
            "selectable": false,
            "strength": 0,
            "type": "MEMORY_DISTURBANCE_LOSS"
        }, {
            "description": "Временна загуба на памет",
            "nomenclature": "1.6.3",
            "selectable": false,
            "strength": 0,
            "type": "MEMORY_DISTURBANCE_TEMPORARY_LOSS"
        }, {
            "description": "Други нарушения на усещането",
            "nomenclature": "1.7",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_CAT"
        }, {
            "description": "Анестезия",
            "nomenclature": "1.7.1",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_ANESTHESIA"
        }, {
            "description": "Парене",
            "nomenclature": "1.7.2",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_BURNING"
        }, {
            "description": "Хиперестезия",
            "nomenclature": "1.7.3",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_HYPERESTHESIA"
        }, {
            "description": "Загуба на миризма",
            "nomenclature": "1.7.4",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_LOSS_OF_SMELL"
        }, {
            "description": "Загуба на вкус",
            "nomenclature": "1.7.5",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_LOSS_OF_TASTE"
        }, {
            "description": "Загуба на допир",
            "nomenclature": "1.7.6",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_LOSS_OF_TOUCH"
        }, {
            "description": "Чудовищно чувство",
            "nomenclature": "1.7.7",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_PRICKLY_FEELING"
        }, {
            "description": "Изтръпване",
            "nomenclature": "1.7.8",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_TINGLING"
        }, {
            "description": "Нарушения на съня",
            "nomenclature": "1.8",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_CAT"
        }, {
            "description": "Сънливост",
            "nomenclature": "1.8.1",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_DROWSINESS"
        }, {
            "description": "Хиперсомния",
            "nomenclature": "1.8.2",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_HYPERSOMNIA"
        }, {
            "description": "Безсъние",
            "nomenclature": "1.8.3",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_INSOMNIA"
        }, {
            "description": "Проблеми със съня",
            "nomenclature": "1.8.4",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_TROUBLE_SLEEPING"
        }, {
            "description": "Не мога да спя",
            "nomenclature": "1.8.5",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_CANT_SLEEP"
        }, {
            "description": "Кошмари",
            "nomenclature": "1.8.6",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_NIGHTMARES"
        }, {
            "description": "Постоянно ми се спи",
            "nomenclature": "1.8.7",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_SLEEPINESS"
        }, {
            "description": "Сомнамбулизъм",
            "nomenclature": "1.8.8",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_SLEEP_WALKING"
        }, {
            "description": "Синдром на часовата зона, джетлаг",
            "nomenclature": "1.8.9",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_TIMEZONE_SYNDROME"
        }, {
            "description": "Сенилност от старост",
            "nomenclature": "1.9",
            "selectable": false,
            "strength": 0,
            "type": "OLD_AGE_SENILITY"
        }, {
            "description": "заекване или пелтечене",
            "nomenclature": "1.10",
            "selectable": false,
            "strength": 0,
            "type": "STAMMERING_OR_STUTTERING"
        }, {
            "description": "Вертиго - световъртеж",
            "nomenclature": "1.11",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_CAT"
        }, {
            "description": "Падащи усещания",
            "nomenclature": "1.11.1",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_FALLING_SENSATIONS"
        }, {
            "description": "Виене на свят",
            "nomenclature": "1.11.2",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_GIDDINESS"
        }, {
            "description": "Замаяност",
            "nomenclature": "1.11.3",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_LIGHTHEADEDNESS"
        }, {
            "description": "Загуба на чувство за равновесие или баланс",
            "nomenclature": "1.11.4",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_LOSS_SENSE_EQUILIBRIUM_BALANCE"
        }, {
            "description": "Други симптоми, отнасящи се до нервната система",
            "nomenclature": "1.12",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_SYMPTOMS_NERVOUS_SYSTEM"
        }, {
            "description": "Кожа, нокти и коса",
            "nomenclature": "2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_NAILS_HAIR_CAT"
        }, {
            "description": "Акне или пъпки",
            "nomenclature": "2.1",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_CAT"
        }, {
            "description": "Лош тен",
            "nomenclature": "2.1.1",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_BAD_COMPLEXION"
        }, {
            "description": "Ергенски пъпки",
            "nomenclature": "2.1.2",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_BLACKHEADS"
        }, {
            "description": "Петна",
            "nomenclature": "2.1.3",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_BLEMISHES"
        }, {
            "description": "Гнойни пъпки",
            "nomenclature": "2.1.4",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_BREAKING_OUT"
        }, {
            "description": "Пъпки с бял връх",
            "nomenclature": "2.1.5",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_WHITEHEADS"
        }, {
            "description": "Обезцветяване или пигментация",
            "nomenclature": "2.2",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_CAT"
        }, {
            "description": "Изчервяване",
            "nomenclature": "2.2.1",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_BLUSHING"
        }, {
            "description": "Промяна в цвета",
            "nomenclature": "2.2.2",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_CHANGE_IN_COLOR"
        }, {
            "description": "Почервеняване",
            "nomenclature": "2.2.3",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_FLUSHING"
        }, {
            "description": "Лунички",
            "nomenclature": "2.2.4",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_FRECKLES"
        }, {
            "description": "Зачервяване",
            "nomenclature": "2.2.5",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_RED"
        }, {
            "description": "Петна",
            "nomenclature": "2.2.6",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_SPOTS"
        }, {
            "description": "Инфекциозни разстройства",
            "nomenclature": "2.3",
            "selectable": false,
            "strength": 0,
            "type": "INFECTIOUS_DISORDERS_CAT"
        }, {
            "description": "Атлетично стъпало (гъбички)",
            "nomenclature": "2.3.1",
            "selectable": false,
            "strength": 0,
            "type": "INFECTIOUS_DISORDERS_ATHLETES_FOOT"
        }, {
            "description": "Циреи",
            "nomenclature": "2.3.2",
            "selectable": false,
            "strength": 0,
            "type": "INFECTIOUS_DISORDERS_BOILS"
        }, {
            "description": "Tения",
            "nomenclature": "2.3.3",
            "selectable": false,
            "strength": 0,
            "type": "INFECTIOUS_DISORDERS_RINGWORM"
        }, {
            "description": "Калуси или мазоли",
            "nomenclature": "2.4",
            "selectable": false,
            "strength": 0,
            "type": "CALLUSES_OR_CORNS"
        }, {
            "description": "Кожни бенки",
            "nomenclature": "2.5",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_MOLES"
        }, {
            "description": "Бръчки",
            "nomenclature": "2.6",
            "selectable": false,
            "strength": 0,
            "type": "WRINKLES"
        }, {
            "description": "Брадавици",
            "nomenclature": "2.7",
            "selectable": false,
            "strength": 0,
            "type": "WARTS"
        }, {
            "description": "Алергични кожни реакции",
            "nomenclature": "2.8",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_CAT"
        }, {
            "description": "Обрив",
            "nomenclature": "2.8.0",
            "selectable": false,
            "strength": 0,
            "type": "RASH"
        }, {
            "description": "Копривна треска",
            "nomenclature": "2.8.1",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_HIVES"
        }, {
            "description": "Фоточувствителност",
            "nomenclature": "2.8.2",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_PHOTOSENSITIVITY"
        }, {
            "description": "Отровен бръшлян, отровен дъб и др.",
            "nomenclature": "2.8.3",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_POISON_IVY_OAK"
        }, {
            "description": "Обрив, подсичане",
            "nomenclature": "2.8.4",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_RASH_DIAPER"
        }, {
            "description": "Раздразнения на кожата",
            "nomenclature": "2.9",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_CAT"
        }, {
            "description": "Кожна инфекция",
            "nomenclature": "2.9.1",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_INFLAMMATION"
        }, {
            "description": "Сърбеж",
            "nomenclature": "2.9.2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_ITCHING"
        }, {
            "description": "Болка по кожата",
            "nomenclature": "2.9.3",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_PAIN"
        }, {
            "description": "Язва на кожата",
            "nomenclature": "2.9.4",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_ULCER"
        }, {
            "description": "Възпалено място на кожата",
            "nomenclature": "2.9.5",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_SORE"
        }, {
            "description": "Кожа: подуване или кожна маса",
            "nomenclature": "2.10",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_CAT"
        }, {
            "description": "Кожни подутини",
            "nomenclature": "2.10.1",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_BUMPS"
        }, {
            "description": "Кожни лезии",
            "nomenclature": "2.10.2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_LESION"
        }, {
            "description": "Кожни възли",
            "nomenclature": "2.10.3",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_NODULES"
        }, {
            "description": "Кожни подутини с кантове, копривна треска",
            "nomenclature": "2.10.4",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_WELTS"
        }, {
            "description": "Рани по кожата",
            "nomenclature": "2.11",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_CAT"
        }, {
            "description": "Ухапвания по кожата",
            "nomenclature": "2.11.1",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_BITES"
        }, {
            "description": "Мехури по кожата, неалергични",
            "nomenclature": "2.11.2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_BLISTERS_NON_ALLERGIC"
        }, {
            "description": "Синини по кожата",
            "nomenclature": "2.11.3",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_BRUISES"
        }, {
            "description": "Изгаряния по кожата(химически, слънце, вятър и пара)",
            "nomenclature": "2.11.4",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_BURNS"
        }, {
            "description": "Порязвания по кожата",
            "nomenclature": "2.11.5",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_CUTS"
        }, {
            "description": "Драскотини по кожата",
            "nomenclature": "2.11.6",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_SCRATCHES"
        }, {
            "description": "Други симптоми, отнасящи се до кожата",
            "nomenclature": "2.12",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_CAT"
        }, {
            "description": "Сухота на кожата",
            "nomenclature": "2.12.1",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_DRYNESS"
        }, {
            "description": "Омазняване на кожата",
            "nomenclature": "2.12.2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_OILINESS"
        }, {
            "description": "Белене на кожата",
            "nomenclature": "2.12.3",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_PEELING"
        }, {
            "description": "Люспеста кожа",
            "nomenclature": "2.12.4",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_SCALINES"
        }, {
            "description": "Промяна в текстурата на кожата",
            "nomenclature": "2.12.5",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_CHANGE_IN_TEXTURE"
        }, {
            "description": "Симптоми, отнасящи се до ноктите",
            "nomenclature": "2.13",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_CAT"
        }, {
            "description": "Чупене на ноктите",
            "nomenclature": "2.13.1",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_BREAKING"
        }, {
            "description": "Крехкост на ноктите",
            "nomenclature": "2.13.2",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_BRITTLE"
        }, {
            "description": "Промяна в цвета на ноктите",
            "nomenclature": "2.13.3",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_CHANGE_IN_COLOR"
        }, {
            "description": "Напукани нокти",
            "nomenclature": "2.13.4",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_CRACKED"
        }, {
            "description": "Растящи навътре нокти",
            "nomenclature": "2.13.5",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_INGROWN"
        }, {
            "description": "Хребети по ноктите",
            "nomenclature": "2.13.6",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_RIDGES"
        }, {
            "description": "Нацепени нокти",
            "nomenclature": "2.13.7",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_SPLITTING"
        }, {
            "description": "Симптоми, отнасящи се до косата",
            "nomenclature": "2.14",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_CAT"
        }, {
            "description": "Плешивост",
            "nomenclature": "2.14.1",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_BALDNESS"
        }, {
            "description": "Крехкост на косата",
            "nomenclature": "2.14.2",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_BRITTLE"
        }, {
            "description": "Сухота на косата",
            "nomenclature": "2.14.3",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_DRYNESS"
        }, {
            "description": "Косопад",
            "nomenclature": "2.14.4",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_FALLING_OUT"
        }, {
            "description": "Омазняване на косата",
            "nomenclature": "2.14.5",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_OILNESS"
        }, {
            "description": "Отдръпваща се линия на косата",
            "nomenclature": "2.14.6",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_RECEDING_HAIR_LINE"
        }, {
            "description": "Симптоми на пъпа",
            "nomenclature": "2.15",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_CAT"
        }, {
            "description": "Секретиране от пъпа",
            "nomenclature": "2.15.1",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_DISCHARGE"
        }, {
            "description": "Изтичане на течност от пъпа",
            "nomenclature": "2.15.2",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_DRAINING"
        }, {
            "description": "Незарастващ пъп",
            "nomenclature": "2.15.3",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_NOT_HEALING"
        }, {
            "description": "Болезнен пъп",
            "nomenclature": "2.15.4",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_PAINFUL"
        }, {
            "description": "Зачервен пъп",
            "nomenclature": "2.15.5",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_RED"
        }, {
            "description": "Сърдечно-съдова и лимфна системи",
            "nomenclature": "3",
            "selectable": false,
            "strength": 0,
            "type": "CARDIOVASCULAR_LYMPHATIC_SYMPTOMS_CAT"
        }, {
            "description": "Неравномерен ритъм и сърцебиене",
            "nomenclature": "3.1",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_CAT"
        }, {
            "description": "Забавен сърдечен ритъм",
            "nomenclature": "3.1.1",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_DECREASED"
        }, {
            "description": "Трептене в гърдите",
            "nomenclature": "3.1.2",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_FLUTTERING"
        }, {
            "description": "Учестен сърдечен ритъм",
            "nomenclature": "3.1.3",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_INCREASED"
        }, {
            "description": "Твърде учестен пулс",
            "nomenclature": "3.1.4",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_PULSE_TOO_FAST"
        }, {
            "description": "Твърде забавен пулс",
            "nomenclature": "3.1.5",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_PULSE_TOO_SLOW"
        }, {
            "description": "Аритмия",
            "nomenclature": "3.1.6",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_IRREGULAR_BEATS"
        }, {
            "description": "Чува се бързо сърцебиене",
            "nomenclature": "3.1.7",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_RAPID_HEARD"
        }, {
            "description": "Сърцето прескача удари",
            "nomenclature": "3.1.8",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_SKIPPED_BEAT"
        }, {
            "description": "Неравномерно сърцебиене",
            "nomenclature": "3.1.9",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_UNEQUAL"
        }, {
            "description": "Сърдечен шум",
            "nomenclature": "3.2",
            "selectable": false,
            "strength": 0,
            "type": "HEART_MURMUR"
        }, {
            "description": "Ненормално високо кръвно налягане",
            "nomenclature": "3.3",
            "selectable": false,
            "strength": 0,
            "type": "HIGH_BLOOD_PRESSURE_CAT"
        }, {
            "description": "Повишено кръвно налягане",
            "nomenclature": "3.3.1",
            "selectable": false,
            "strength": 0,
            "type": "HIGH_BLOOD_PRESSURE_ELEVATED_BP"
        }, {
            "description": "Високо кръвно налягане",
            "nomenclature": "3.3.2",
            "selectable": false,
            "strength": 0,
            "type": "HIGH_BLOOD_PRESSURE_HIGH_BP"
        }, {
            "description": "Хипертония",
            "nomenclature": "3.3.3",
            "selectable": false,
            "strength": 0,
            "type": "HIGH_BLOOD_PRESSURE_HYPERTENSION"
        }, {
            "description": "Ненормално ниско кръвно налягане",
            "nomenclature": "3.4",
            "selectable": false,
            "strength": 0,
            "type": "LOW_BLOOD_PRESSURE_CAT"
        }, {
            "description": "Намалено кръвно налягане",
            "nomenclature": "3.4.1",
            "selectable": false,
            "strength": 0,
            "type": "LOW_BLOOD_PRESSURE_DECREASED_BP"
        }, {
            "description": "Хипотония",
            "nomenclature": "3.4.2",
            "selectable": false,
            "strength": 0,
            "type": "LOW_BLOOD_PRESSURE_HYPOTENSION"
        }, {
            "description": "Ниско кръвно налягане",
            "nomenclature": "3.4.3",
            "selectable": false,
            "strength": 0,
            "type": "LOW_BLOOD_PRESSURE_LOW_BP"
        }, {
            "description": "Симптоми, отнасящи се до кръвта",
            "nomenclature": "3.5",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_CAT"
        }, {
            "description": "Лошо кръвообращение",
            "nomenclature": "3.5.1",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_POOR"
        }, {
            "description": "Разредена кръв",
            "nomenclature": "3.5.2",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_THIN"
        }, {
            "description": "Анемия, чувство на умора",
            "nomenclature": "3.5.3",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_TIRED"
        }, {
            "description": "Анемия, слабост",
            "nomenclature": "3.5.4",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_WEAK"
        }, {
            "description": "Бледност и цианоза",
            "nomenclature": "3.6",
            "selectable": false,
            "strength": 0,
            "type": "PALLOR_AND_CYANOSIS_CAT"
        }, {
            "description": "Пепеляв цвят",
            "nomenclature": "3.6.1",
            "selectable": false,
            "strength": 0,
            "type": "PALLOR_AND_CYANOSIS_ASHEN_COLOR"
        }, {
            "description": "Сини пръсти на краката",
            "nomenclature": "3.6.2",
            "selectable": false,
            "strength": 0,
            "type": "PALLOR_AND_CYANOSIS_BLUENESS_OF_FINGERS"
        }, {
            "description": "Бледост",
            "nomenclature": "3.6.3",
            "selectable": false,
            "strength": 0,
            "type": "PALLOR_AND_CYANOSIS_PALENESS"
        }, {
            "description": "Синкоп или колапс",
            "nomenclature": "3.7",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_CAT"
        }, {
            "description": "Причерняване",
            "nomenclature": "3.7.1",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_BLACKED_OUT"
        }, {
            "description": "Припадък",
            "nomenclature": "3.7.2",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_FAINTING"
        }, {
            "description": "Загуба на съзнание",
            "nomenclature": "3.7.3",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_PASSED_OUT"
        }, {
            "description": "Замайване",
            "nomenclature": "3.7.4",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_SPELLS"
        }, {
            "description": "Други симптоми на слуха",
            "nomenclature": "3.8",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_HEARD_CAT"
        }, {
            "description": "Лошо чуване",
            "nomenclature": "3.8.1",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_HEARD_BAD_HEARD"
        }, {
            "description": "Недобро чуване",
            "nomenclature": "3.8.2",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_HEARD_POOR_HEARD"
        }, {
            "description": "Слабо чуване",
            "nomenclature": "3.8.3",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_HEARD_WEAK_HEARD"
        }, {
            "description": "Оток и водянка",
            "nomenclature": "3.9",
            "selectable": false,
            "strength": 0,
            "type": "EDEMA_AND_DROPSY"
        }, {
            "description": "Лимфни жлези",
            "nomenclature": "3.10",
            "selectable": false,
            "strength": 0,
            "type": "LYMPH_GLANDS_CAT"
        }, {
            "description": "Увеличени лимфни възли",
            "nomenclature": "3.10.1",
            "selectable": false,
            "strength": 0,
            "type": "LYMPH_GLANDS_ENLARGED_LYMPH_NODES"
        }, {
            "description": "Възпалени лимфни възли",
            "nomenclature": "3.10.2",
            "selectable": false,
            "strength": 0,
            "type": "LYMPH_GLANDS_SORE_GLANDS"
        }, {
            "description": "Подути лимфни възли",
            "nomenclature": "3.10.3",
            "selectable": false,
            "strength": 0,
            "type": "LYMPH_GLANDS_SWOLLEN_GLANDS"
        }, {
            "description": "Дихателна система",
            "nomenclature": "4",
            "selectable": false,
            "strength": 0,
            "type": "RESPIRATORY_SYMPTOMS_CAT"
        }, {
            "description": "Кръв от носа",
            "nomenclature": "4.1",
            "selectable": false,
            "strength": 0,
            "type": "NOSE_BLEED_CAT"
        }, {
            "description": "Кървене от носа",
            "nomenclature": "4.1.1",
            "selectable": false,
            "strength": 0,
            "type": "NOSE_BLEED_BLEEDING"
        }, {
            "description": "Кръвоизлив от носа",
            "nomenclature": "4.1.2",
            "selectable": false,
            "strength": 0,
            "type": "NOSE_BLEED_HEMORRHAGE"
        }, {
            "description": "Запушване на носа",
            "nomenclature": "4.2",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_CAT"
        }, {
            "description": "Течащ нос",
            "nomenclature": "4.2.1",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_DRIPPY_NOSE"
        }, {
            "description": "Постназално секретиране (стичане на секрет към гърлото)",
            "nomenclature": "4.2.2",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_POSTNASAL_DRIP"
        }, {
            "description": "Зачервен нос",
            "nomenclature": "4.2.3",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_RED_NOSE"
        }, {
            "description": "Хрема",
            "nomenclature": "4.2.4",
            "selectable": false,
            "strength": 0,
            "type": "RUNNY_NOSE"
        }, {
            "description": "Подсмърчане",
            "nomenclature": "4.2.5",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_SNIFFLES"
        }, {
            "description": "Запушен нос",
            "nomenclature": "4.2.6",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_STUFFY_NOSE"
        }, {
            "description": "Проблеми със синусите",
            "nomenclature": "4.3",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_CAT"
        }, {
            "description": "Задръстване на синусите",
            "nomenclature": "4.3.1",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_CONGESTION"
        }, {
            "description": "Увредени синуси",
            "nomenclature": "4.3.2",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_IMPACTED"
        }, {
            "description": "Инфектирани синуси",
            "nomenclature": "4.3.3",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_INFECTED"
        }, {
            "description": "Чувство на лекота в синусите",
            "nomenclature": "4.3.4",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_LIGHTNESS"
        }, {
            "description": "Болка в синусите",
            "nomenclature": "4.3.5",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_PAIN"
        }, {
            "description": "Налягане в синусите",
            "nomenclature": "4.3.6",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_PRESSURE"
        }, {
            "description": "Дишане",
            "nomenclature": "4.4",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_CAT"
        }, {
            "description": "Недостиг на въздух",
            "nomenclature": "4.4.0",
            "selectable": false,
            "strength": 0,
            "type": "BREATH_SHORTNESS"
        }, {
            "description": "Задух",
            "nomenclature": "4.4.1",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_BREATHLESSNESS"
        }, {
            "description": "Затруднено дишане",
            "nomenclature": "4.4.2",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_DYSPNES"
        }, {
            "description": "Усещане за задушаване",
            "nomenclature": "4.4.3",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_SENSATION_OF_SUFFOCATION"
        }, {
            "description": "Проблемно дишане",
            "nomenclature": "4.4.4",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_TROUBLE_BREATHING"
        }, {
            "description": "Други нарушения на дихателния ритъм и звук",
            "nomenclature": "4.5",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_CAT"
        }, {
            "description": "Ненормални звуци при дишане",
            "nomenclature": "4.5.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_ABNORMAL_BREATHING_SOUNDS"
        }, {
            "description": "Хипервентилация",
            "nomenclature": "4.5.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_HYPERVENTILATION"
        }, {
            "description": "Хрипове",
            "nomenclature": "4.5.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_RALES"
        }, {
            "description": "Бързо дишане",
            "nomenclature": "4.5.4",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_RAPID_BREATHING"
        }, {
            "description": "Въздишащо дишане",
            "nomenclature": "4.5.5",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_SIGHING_RESPIRATION"
        }, {
            "description": "Свирене в гърдите",
            "nomenclature": "4.5.6",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_WHEEZING"
        }, {
            "description": "Кихане",
            "nomenclature": "4.6",
            "selectable": false,
            "strength": 0,
            "type": "SNEEZING"
        }, {
            "description": "Кашлица",
            "nomenclature": "4.7",
            "selectable": false,
            "strength": 0,
            "type": "COUGH"
        }, {
            "description": "Настинка, простуда",
            "nomenclature": "4.8",
            "selectable": false,
            "strength": 0,
            "type": "COLD"
        }, {
            "description": "Грип",
            "nomenclature": "4.9",
            "selectable": false,
            "strength": 0,
            "type": "FLU"
        }, {
            "description": "Грип",
            "nomenclature": "4.9.1",
            "selectable": false,
            "strength": 0,
            "type": "GRIP"
        }, {
            "description": "Инфлуенца",
            "nomenclature": "4.9.2",
            "selectable": false,
            "strength": 0,
            "type": "INFLUENZA"
        }, {
            "description": "Круп",
            "nomenclature": "4.10",
            "selectable": false,
            "strength": 0,
            "type": "CROUP"
        }, {
            "description": "Слюнка или храчки",
            "nomenclature": "4.11",
            "selectable": false,
            "strength": 0,
            "type": "SPUTUM_OR_PHLEGM_CAT"
        }, {
            "description": "Кървави храчки",
            "nomenclature": "4.11.1",
            "selectable": false,
            "strength": 0,
            "type": "SPUTUM_OR_PHLEGM_BLOODY"
        }, {
            "description": "Прекомерни храчки",
            "nomenclature": "4.11.2",
            "selectable": false,
            "strength": 0,
            "type": "SPUTUM_OR_PHLEGM_EXCESSIVE"
        }, {
            "description": "Гнойни храчки",
            "nomenclature": "4.11.3",
            "selectable": false,
            "strength": 0,
            "type": "SPUTUM_OR_PHLEGM_PURULENT"
        }, {
            "description": "Конгестия на гърдите",
            "nomenclature": "4.12",
            "selectable": false,
            "strength": 0,
            "type": "CONGESTION_IN_CHEST_CAT"
        }, {
            "description": "Конгестия на белите дробове",
            "nomenclature": "4.12.1",
            "selectable": false,
            "strength": 0,
            "type": "CONGESTION_IN_CHEST_LUNG_CONGESTION"
        }, {
            "description": "Болка в гърдите",
            "nomenclature": "4.13",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_CAT"
        }, {
            "description": "Изгарящо усещане",
            "nomenclature": "4.13.1",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_BURNING_SENSATION"
        }, {
            "description": "Стягане в гърдите",
            "nomenclature": "4.13.2",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_CHEST_TIGHTNESS"
        }, {
            "description": "Болка в белия дроб",
            "nomenclature": "4.13.3",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_PAIN_IN_LUNG"
        }, {
            "description": "Болка над сърцето",
            "nomenclature": "4.13.4",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_PAIN_OVER_HEART"
        }, {
            "description": "Дихателна болка в областта на гръдната кост",
            "nomenclature": "4.13.5",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_RIB"
        }, {
            "description": "Натиск в/върху гърдите",
            "nomenclature": "4.13.6",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_PRESSURE_IN_ON_CHEST"
        }, {
            "description": "Нарушения на гласа",
            "nomenclature": "4.14",
            "selectable": false,
            "strength": 0,
            "type": "DISORDERS_OF_VOICE_CAT"
        }, {
            "description": "Дрезгавина на гласа",
            "nomenclature": "4.14.1",
            "selectable": false,
            "strength": 0,
            "type": "DISORDERS_OF_VOICE_HOARSENSES"
        }, {
            "description": "Носово говорене",
            "nomenclature": "4.14.2",
            "selectable": false,
            "strength": 0,
            "type": "DISORDERS_OF_VOICE_HYPERNASALITY"
        }, {
            "description": "Мускулно-скелетна система",
            "nomenclature": "5",
            "selectable": false,
            "strength": 0,
            "type": "MUSKULOSKELETAL_SYSTEM_SYMPTOMS_CAT"
        }, {
            "description": "Болка, подуване, нараняване на долните крайници",
            "nomenclature": "5.1",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_CAT"
        }, {
            "description": "Болка в крака",
            "nomenclature": "5.1.1",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_ACHE"
        }, {
            "description": "Мускулен спазъм на крака",
            "nomenclature": "5.1.2",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_CHARLEYHORSE"
        }, {
            "description": "Контракция на мускул на крака",
            "nomenclature": "5.1.3",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_CONTRACTURE"
        }, {
            "description": "Схващане на крака",
            "nomenclature": "5.1.4",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_CRAMP"
        }, {
            "description": "Смяна на усещане за топло-студено на крака",
            "nomenclature": "5.1.5",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_HOT_COLD"
        }, {
            "description": "Боли ме кракът",
            "nomenclature": "5.1.6",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_HURT"
        }, {
            "description": "Разтегнат мускул на крака",
            "nomenclature": "5.1.7",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_PULLED_MUSCLE"
        }, {
            "description": "Болезненост в крака",
            "nomenclature": "5.1.8",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_SORENESS"
        }, {
            "description": "Спазъм на крака",
            "nomenclature": "5.1.9",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_SPASM"
        }, {
            "description": "Скованост на крака",
            "nomenclature": "5.1.10",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STIFFNESS"
        }, {
            "description": "Болка в глезена, стъпалото, тазобедрената става, коляното",
            "nomenclature": "5.1.11",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_ANKLE_FOOT_HIP_KNEE"
        }, {
            "description": "Болка в стъпалото",
            "nomenclature": "5.1.12",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_FOOT"
        }, {
            "description": "Болка в тазобедрената става",
            "nomenclature": "5.1.13",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_HIP"
        }, {
            "description": "Болка в коляното",
            "nomenclature": "5.1.14",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_KNEE"
        }, {
            "description": "Болка в крака или бедрото",
            "nomenclature": "5.1.15",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_LEG_OR_THIGH"
        }, {
            "description": "Болка в долния крайник",
            "nomenclature": "5.1.16",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_GLOBAL"
        }, {
            "description": "Болка в пръстите на краката",
            "nomenclature": "5.1.17",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_TOE"
        }, {
            "description": "Болка, подуване, нараняване на горния крайник (ръка, ръце)",
            "nomenclature": "5.2",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_CAT"
        }, {
            "description": "Болка в ръката",
            "nomenclature": "5.2.1",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_ACHE"
        }, {
            "description": "Контракция на мускул на ръката",
            "nomenclature": "5.2.1",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_CONTRACTURE"
        }, {
            "description": "Смяна на усещане за топло-студено на ръката",
            "nomenclature": "5.2.3",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_HOT_COLD"
        }, {
            "description": "Боли ме ръката",
            "nomenclature": "5.2.4",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_HURT"
        }, {
            "description": "Разтегнат мускул на ръката",
            "nomenclature": "5.2.5",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_PULLED_MUSCLE"
        }, {
            "description": "Болезненост в ръката",
            "nomenclature": "5.2.6",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_SORENESS"
        }, {
            "description": "Спазъм в ръката",
            "nomenclature": "5.2.7",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_SPASM"
        }, {
            "description": "Скованост в ръката",
            "nomenclature": "5.2.8",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STIFFNESS"
        }, {
            "description": "Болка в ръката",
            "nomenclature": "5.2.9",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_ARM"
        }, {
            "description": "Болка в лакътя",
            "nomenclature": "5.2.10",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_ELBOW"
        }, {
            "description": "Болка в пръстите",
            "nomenclature": "5.2.11",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_FINGERS"
        }, {
            "description": "Болка в предмишницата",
            "nomenclature": "5.2.12",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_FOREARM"
        }, {
            "description": "Болка в ръката",
            "nomenclature": "5.2.13",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_HAND"
        }, {
            "description": "Болка в рамото",
            "nomenclature": "5.2.14",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_SHOULDER"
        }, {
            "description": "Болка в палеца",
            "nomenclature": "5.2.15",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_THUMB"
        }, {
            "description": "Болка в горната част на ръката",
            "nomenclature": "5.2.16",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_UPPER_ARM"
        }, {
            "description": "Болка в горния крайник",
            "nomenclature": "5.2.17",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_UPPER_EXTREMITY"
        }, {
            "description": "Болка в китката",
            "nomenclature": "5.2.18",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_WRIST"
        }, {
            "description": "Болка, подуване, нараняване на лицето и шията",
            "nomenclature": "5.3",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_CAT"
        }, {
            "description": "Болка във врата",
            "nomenclature": "5.3.0",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_ACHE_NECK"
        }, {
            "description": "Болка в лицето",
            "nomenclature": "5.3.1",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_ACHE_FACE"
        }, {
            "description": "Контракция на лицето",
            "nomenclature": "5.3.2",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_CONTRACTURE"
        }, {
            "description": "Схващане на шията",
            "nomenclature": "5.3.3",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_CRAMP_NECK"
        }, {
            "description": "Схващане на врата",
            "nomenclature": "5.3.30",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_CRAMP_FACE"
        }, {
            "description": "Боли ме шията",
            "nomenclature": "5.3.4",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_HURT_NECK"
        }, {
            "description": "Боли ме лицето",
            "nomenclature": "5.3.40",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_HURT"
        }, {
            "description": "Разтегнат мускул на шията",
            "nomenclature": "5.3.5",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_PULLED_MUSCLE"
        }, {
            "description": "Болезненост на лицето",
            "nomenclature": "5.3.6",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_SORENESS"
        }, {
            "description": "Спазъм на лицето",
            "nomenclature": "5.3.7",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_SPASM"
        }, {
            "description": "Скованост на шията",
            "nomenclature": "5.3.8",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STIFFNESS"
        }, {
            "description": "Болка в задната част на главата",
            "nomenclature": "5.3.9",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_BACK_HEAD"
        }, {
            "description": "Болка в шийния отдел на гръбначния стълб",
            "nomenclature": "5.3.10",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_CERVICAL_SPINE"
        }, {
            "description": "Болка в лицето",
            "nomenclature": "5.3.11",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_FACE"
        }, {
            "description": "Болка в челюстта",
            "nomenclature": "5.3.12",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_JAW"
        }, {
            "description": "Болка в шията",
            "nomenclature": "5.3.13",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_NECK"
        }, {
            "description": "Болка в горната част на гръбнака",
            "nomenclature": "5.3.14",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_UPPER_SPINE"
        }, {
            "description": "Болка, подуване, нараняване на областта на гърба",
            "nomenclature": "5.4",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_CAT"
        }, {
            "description": "Болка в гърба",
            "nomenclature": "5.4.1",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_ACHE"
        }, {
            "description": "Контракция на гърба",
            "nomenclature": "5.4.2",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_CONTRACTURE"
        }, {
            "description": "Схващане на гърба",
            "nomenclature": "5.4.3",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_CRAMP"
        }, {
            "description": "Боли ме гърба",
            "nomenclature": "5.4.4",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_HURT"
        }, {
            "description": "Разтегнат мускул на гърба",
            "nomenclature": "5.4.5",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_PULLED_MUSCLE"
        }, {
            "description": "Болезненост на гърба",
            "nomenclature": "5.4.6",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_SORENESS"
        }, {
            "description": "Спазъм на гърба",
            "nomenclature": "5.4.7",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_SPASM"
        }, {
            "description": "Скованост на гърба",
            "nomenclature": "5.4.8",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STIFFNESS"
        }, {
            "description": "Болка в гърба",
            "nomenclature": "5.4.9",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_BACK"
        }, {
            "description": "Болка в гърба, горната, долната част",
            "nomenclature": "5.4.10",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_BACK_LOWER_UPPER"
        }, {
            "description": "Болка в лумбалната област (в кръста)",
            "nomenclature": "5.4.11",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_LUMBAR"
        }, {
            "description": "Болки в лумбосакралната област  (долната част на гърба)",
            "nomenclature": "5.4.12",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_LUMBOSACRAL"
        }, {
            "description": "Болка в сакролиака",
            "nomenclature": "5.4.13",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_SACROILIAC"
        }, {
            "description": "Болка в гръбнака",
            "nomenclature": "5.4.14",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_SPINE"
        }, {
            "description": "Болка в торакалната част на гръбнака (горната част на гърба)",
            "nomenclature": "5.4.15",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_THORACIC_SPINE"
        }, {
            "description": "Атрофия или загуба на крайници",
            "nomenclature": "5.5",
            "selectable": false,
            "strength": 0,
            "type": "ATROPHY_OR_WATING_EXTREMITIES_CAT"
        }, {
            "description": "Скованост на крайниците",
            "nomenclature": "5.5.1",
            "selectable": false,
            "strength": 0,
            "type": "ATROPHY_OR_WATING_EXTREMITIES_NYMBNESS"
        }, {
            "description": "Парализа, частична или пълна",
            "nomenclature": "5.5.2",
            "selectable": false,
            "strength": 0,
            "type": "ATROPHY_OR_WATING_EXTREMITIES_PARALYSIS"
        }, {
            "description": "Слабост в крайниците",
            "nomenclature": "5.5.3",
            "selectable": false,
            "strength": 0,
            "type": "ATROPHY_OR_WATING_EXTREMITIES_WEAKNESS"
        }, {
            "description": "Трудност при ходене, ненормалност на походката",
            "nomenclature": "5.6",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_CAT"
        }, {
            "description": "Тромавост",
            "nomenclature": "5.6.1",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_CLUMSINESS"
        }, {
            "description": "Падане",
            "nomenclature": "5.6.2",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_FALLING"
        }, {
            "description": "Невъзможност за стоене или ходене",
            "nomenclature": "5.6.3",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_INABILITY_TO_STAND_AND_WALK"
        }, {
            "description": "Куцане",
            "nomenclature": "5.6.4",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_LIMPING"
        }, {
            "description": "Олюляване",
            "nomenclature": "5.6.5",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_STAGGERING"
        }, {
            "description": "Други симптоми, отнасящи се до крайници и стави",
            "nomenclature": "5.7",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_LIMB_JOINT_SYMPTOMS_CAT"
        }, {
            "description": "Болки в мускулите",
            "nomenclature": "5.7.0",
            "selectable": false,
            "strength": 0,
            "type": "MUSCLE_PAIN"
        }, {
            "description": "Болки в ставите",
            "nomenclature": "5.7.4",
            "selectable": false,
            "strength": 0,
            "type": "JOINT_PAIN"
        }, {
            "description": "Увисване на стъпалото",
            "nomenclature": "5.7.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_LIMB_JOINT_SYMPTOMS_FOOT_DROP"
        }, {
            "description": "Проблеми с позата",
            "nomenclature": "5.7.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_LIMB_JOINT_SYMPTOMS_POSTURE_PROBLEMS"
        }, {
            "description": "Увисване на китката",
            "nomenclature": "5.7.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_LIMB_JOINT_SYMPTOMS_WRIST_DROP"
        }, {
            "description": "Буниони (кокалчета)",
            "nomenclature": "5.8",
            "selectable": false,
            "strength": 0,
            "type": "BUNION"
        }, {
            "description": "Храносмилателната система",
            "nomenclature": "6",
            "selectable": false,
            "strength": 0,
            "type": "DIGESTIVE_SYSTEM_CAT"
        }, {
            "description": "Трудност при дъвчене",
            "nomenclature": "6.1",
            "selectable": false,
            "strength": 0,
            "type": "CHEWING_DIFFICULTY"
        }, {
            "description": "Кървене, венци",
            "nomenclature": "6.2",
            "selectable": false,
            "strength": 0,
            "type": "BLEEDING_GUMS"
        }, {
            "description": "Лош дъх на устата",
            "nomenclature": "6.3",
            "selectable": false,
            "strength": 0,
            "type": "HALITOSIS_CAT"
        }, {
            "description": "Лош дъх",
            "nomenclature": "6.3.1",
            "selectable": false,
            "strength": 0,
            "type": "HALITOSIS_BAD_BREATH"
        }, {
            "description": "Симптоми, отнасящи се до устните",
            "nomenclature": "6.4",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_CAT"
        }, {
            "description": "Ненормален цвят на устните",
            "nomenclature": "6.4.1",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_ABNORMAL_COLOR"
        }, {
            "description": "Кървене на устните",
            "nomenclature": "6.4.2",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_BLEEDING"
        }, {
            "description": "Напукани устни",
            "nomenclature": "6.4.3",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_CRACKED"
        }, {
            "description": "Сухи устни",
            "nomenclature": "6.4.4",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_DRY"
        }, {
            "description": "Болка на устните",
            "nomenclature": "6.4.5",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_PAIN"
        }, {
            "description": "Подуване на устните",
            "nomenclature": "6.4.6",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_SWELLING"
        }, {
            "description": "Симптоми, отнасящи се до устата",
            "nomenclature": "6.5",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_CAT"
        }, {
            "description": "Лош вкус",
            "nomenclature": "6.5.1",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_BAD_TASTE"
        }, {
            "description": "Изгаряне в устата",
            "nomenclature": "6.5.2",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_BURN"
        }, {
            "description": "Сухост в устата",
            "nomenclature": "6.5.3",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_DRYNESS"
        }, {
            "description": "Възпаление в устата",
            "nomenclature": "6.5.4",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_INFLAMMATION"
        }, {
            "description": "Болка в устата",
            "nomenclature": "6.5.5",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_PAIN"
        }, {
            "description": "Подуване в устата",
            "nomenclature": "6.5.6",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_SWELLING"
        }, {
            "description": "Язва в устата",
            "nomenclature": "6.5.7",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_ULCER"
        }, {
            "description": "Слюнка",
            "nomenclature": "6.6",
            "selectable": false,
            "strength": 0,
            "type": "SALIVA_CAT"
        }, {
            "description": "Твърде много слюнка",
            "nomenclature": "6.6.0",
            "selectable": false,
            "strength": 0,
            "type": "SALIVA_EXCESS"
        }, {
            "description": "Липса на слюнка",
            "nomenclature": "6.6.2",
            "selectable": false,
            "strength": 0,
            "type": "SALIVA_LACK"
        }, {
            "description": "Капеща слюнка",
            "nomenclature": "6.6.1",
            "selectable": false,
            "strength": 0,
            "type": "SALIVA_DROOLING"
        }, {
            "description": "Зъбобол",
            "nomenclature": "6.7",
            "selectable": false,
            "strength": 0,
            "type": "TOOTHACHE"
        }, {
            "description": "Болезненост в гърлото",
            "nomenclature": "6.8",
            "selectable": false,
            "strength": 0,
            "type": "THROAT_CAT"
        }, {
            "description": "Болезнено гърло",
            "nomenclature": "6.8.1",
            "selectable": false,
            "strength": 0,
            "type": "THROAT_PAINFUL"
        }, {
            "description": "Драскащо гърло",
            "nomenclature": "6.8.2",
            "selectable": false,
            "strength": 0,
            "type": "THROAT_SCRATCHY"
        }, {
            "description": "Възпалено гърло",
            "nomenclature": "6.8.3",
            "selectable": false,
            "strength": 0,
            "type": "SORE_THROAT"
        }, {
            "description": "Симптоми, отнасящи се до езика",
            "nomenclature": "6.9",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_CAT"
        }, {
            "description": "Ненормален цвят на езика",
            "nomenclature": "6.9.1",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_ABNORMAL_COLOR"
        }, {
            "description": "Кървенена езика",
            "nomenclature": "6.9.2",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_BLEEDING"
        }, {
            "description": "Мехури по езика",
            "nomenclature": "6.9.3",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_BLISTERS"
        }, {
            "description": "Изгорен език",
            "nomenclature": "6.9.4",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_BURNED"
        }, {
            "description": "Болка на езика",
            "nomenclature": "6.9.5",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_PAIN"
        }, {
            "description": "Хребети (изпъкнали гребени) по езика",
            "nomenclature": "6.9.6",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_RIDGES"
        }, {
            "description": "Гладък език",
            "nomenclature": "6.9.7",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_SMOOTH"
        }, {
            "description": "Подуване или маса на езика",
            "nomenclature": "6.9.8",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_SWELLING_OR_MASS"
        }, {
            "description": "Язва на езика",
            "nomenclature": "6.9.9",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_ULCER"
        }, {
            "description": "Симптоми, отнасящи се до сливиците",
            "nomenclature": "6.10",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_CAT"
        }, {
            "description": "Кървене (постоперативно) на сливиците",
            "nomenclature": "6.10.1",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_BLEEDING_POSTOPERATIVE"
        }, {
            "description": "Секретиране от сливиците",
            "nomenclature": "6.10.2",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_DISCHARGE"
        }, {
            "description": "Възпаление на сливиците",
            "nomenclature": "6.10.3",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_INFLAMMATION"
        }, {
            "description": "Подуване на сливиците",
            "nomenclature": "6.10.4",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_SWELLING"
        }, {
            "description": "Преглъщане",
            "nomenclature": "6.11",
            "selectable": false,
            "strength": 0,
            "type": "SWALLOWING_CAT"
        }, {
            "description": "Трудност при преглъщането",
            "nomenclature": "6.11.0",
            "selectable": false,
            "strength": 0,
            "type": "SWALLOWING_DIFFICULTY"
        }, {
            "description": "Задавяне",
            "nomenclature": "6.11.1",
            "selectable": false,
            "strength": 0,
            "type": "SWALLOWING_CHOKING"
        }, {
            "description": "Болка в корема",
            "nomenclature": "6.12",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_CAT"
        }, {
            "description": "Колики, чревни (с изключение на кърмачета)",
            "nomenclature": "6.12.1",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_COLIC"
        }, {
            "description": "Болка в епигастриума",
            "nomenclature": "6.12.2",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_EPIGASTRIUM"
        }, {
            "description": "Болка в илиака",
            "nomenclature": "6.12.3",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_ILIAC"
        }, {
            "description": "Болка в Inguinal (слабините)",
            "nomenclature": "6.12.4",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_INGUINAL"
        }, {
            "description": "Болка в десния/левия, горния/долния квадрант",
            "nomenclature": "6.12.5",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_QUADRANT"
        }, {
            "description": "Болка в стомаха (включва спазми)",
            "nomenclature": "6.12.6",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_STOMACH"
        }, {
            "description": "Болка в пъпната област",
            "nomenclature": "6.12.7",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_UMBILICAL"
        }, {
            "description": "Колики, детски",
            "nomenclature": "6.13",
            "selectable": false,
            "strength": 0,
            "type": "COLIC_INFANTILE"
        }, {
            "description": "Подуване на корема или маса",
            "nomenclature": "6.14",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_SWELLING_MASS_CAT"
        }, {
            "description": "Подуване на корема",
            "nomenclature": "6.14.0",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_SWELLING_MASS_DISTENTION"
        }, {
            "description": "Тежест в корема",
            "nomenclature": "6.14.1",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_SWELLING_MASS_FULLNESS"
        }, {
            "description": "Метеоризъм",
            "nomenclature": "6.15",
            "selectable": false,
            "strength": 0,
            "type": "FLATUENCE_CAT"
        }, {
            "description": "Подуване, газове",
            "nomenclature": "6.15.1",
            "selectable": false,
            "strength": 0,
            "type": "FLATUENCE_BLOATING_GAS"
        }, {
            "description": "Подуване поради газове",
            "nomenclature": "6.15.2",
            "selectable": false,
            "strength": 0,
            "type": "FLATUENCE_DISTENSION"
        }, {
            "description": "Прекомерни газове",
            "nomenclature": "6.15.3",
            "selectable": false,
            "strength": 0,
            "type": "FLATUENCE_GAS_EXCESSIVE"
        }, {
            "description": "Апетит, ненормален",
            "nomenclature": "6.16",
            "selectable": false,
            "strength": 0,
            "type": "APPETITE_CAT"
        }, {
            "description": "Намален апетит",
            "nomenclature": "6.16.1",
            "selectable": false,
            "strength": 0,
            "type": "APPETITE_DECREASE"
        }, {
            "description": "Прекомерен апетит",
            "nomenclature": "6.16.2",
            "selectable": false,
            "strength": 0,
            "type": "APPETITE_EXCESSIVE"
        }, {
            "description": "Загуба на апетит",
            "nomenclature": "6.16.3",
            "selectable": false,
            "strength": 0,
            "type": "APPETITE_LOSS"
        }, {
            "description": "Проблеми с храненето",
            "nomenclature": "6.17",
            "selectable": false,
            "strength": 0,
            "type": "FEEDING_PROBLEMS"
        }, {
            "description": "Кървене, стомашно-чревно",
            "nomenclature": "6.18",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_CAT"
        }, {
            "description": "Кръв в изпражненията (мелена)",
            "nomenclature": "6.18.1",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_STOOLS"
        }, {
            "description": "Хематемеза (повръщане на кръв)",
            "nomenclature": "6.18.2",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_HEMATEMESIS"
        }, {
            "description": "Стомашно-чревен кръвоизлив",
            "nomenclature": "6.18.3",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_HEMORRHAGE"
        }, {
            "description": "Повръщане на кръв",
            "nomenclature": "6.18.4",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_BLOOD_VOMITING"
        }, {
            "description": "Запек",
            "nomenclature": "6.19",
            "selectable": false,
            "strength": 0,
            "type": "CONSTIPATION"
        }, {
            "description": "Диария",
            "nomenclature": "6.20",
            "selectable": false,
            "strength": 0,
            "type": "DIARRHEA_CAT"
        }, {
            "description": "Диария",
            "nomenclature": "6.20.0",
            "selectable": false,
            "strength": 0,
            "type": "DIARRHEA"
        }, {
            "description": "Диарични изпражнения",
            "nomenclature": "6.20.1",
            "selectable": false,
            "strength": 0,
            "type": "DIARRHEA_LOOSE_STOOLS"
        }, {
            "description": "Други симптоми или промени във функцията на червата",
            "nomenclature": "6.21",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_CAT"
        }, {
            "description": "Обемисти изпражнения",
            "nomenclature": "6.21.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_BULKY_STOOLS"
        }, {
            "description": "Тъмни изпражнения",
            "nomenclature": "6.21.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_DARK_STOOLS"
        }, {
            "description": "Мазни изпражнения",
            "nomenclature": "6.21.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_FATTY_STOOLS"
        }, {
            "description": "Слузести изпражнения",
            "nomenclature": "6.21.4",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_MUCOUS_STOOLS"
        }, {
            "description": "Гнойни изпражнения",
            "nomenclature": "6.21.5",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_PUS_STOOLS"
        }, {
            "description": "Необичаен цвят на изпражненията",
            "nomenclature": "6.21.6",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_UNUSUAL_COLOR"
        }, {
            "description": "Необичайна миризма на изпражненията",
            "nomenclature": "6.21.7",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_UNUSUAL_ODOR"
        }, {
            "description": "Симптоми, отнасящи се до анус-ректума",
            "nomenclature": "6.22",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_CAT"
        }, {
            "description": "Ректално кървене",
            "nomenclature": "6.22.1",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_BLEEDING"
        }, {
            "description": "Сърбеж при ануса",
            "nomenclature": "6.22.2",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_ITCHING"
        }, {
            "description": "Маса при ануса",
            "nomenclature": "6.22.3",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_MASS"
        }, {
            "description": "Ректална болка",
            "nomenclature": "6.22.4",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_RECTAL_PAIN"
        }, {
            "description": "Подуване на ануса",
            "nomenclature": "6.22.5",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_SWELLING"
        }, {
            "description": "Киселини или разстроен стомах",
            "nomenclature": "6.23",
            "selectable": false,
            "strength": 0,
            "type": "UPSET_STOMACH_CAT"
        }, {
            "description": "Оригване",
            "nomenclature": "6.23.1",
            "selectable": false,
            "strength": 0,
            "type": "UPSET_STOMACH_BELCHING"
        }, {
            "description": "Стомашно разстройство",
            "nomenclature": "6.23.2",
            "selectable": false,
            "strength": 0,
            "type": "UPSET_STOMACH_INDIGESTION"
        }, {
            "description": "Гадене и повръщане",
            "nomenclature": "6.24",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA_AND_VOMITING_CAT"
        }, {
            "description": "Гадене",
            "nomenclature": "6.24.0",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA"
        }, {
            "description": "Повдигане",
            "nomenclature": "6.24.1",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA_AND_VOMITING_RETCHING"
        }, {
            "description": "Лошо ми е на стомаха",
            "nomenclature": "6.24.2",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA_AND_VOMITING_SICK_TO_STOMACH"
        }, {
            "description": "Повръщане",
            "nomenclature": "6.24.3",
            "selectable": false,
            "strength": 0,
            "type": "VOMITING"
        }, {
            "description": "Повръщане на кръв",
            "nomenclature": "6.24.4",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA_AND_VOMITING_THROWING_UP"
        }, {
            "description": "Регургитация или плюене",
            "nomenclature": "6.25",
            "selectable": false,
            "strength": 0,
            "type": "REGURGITATION_OR_SPITTING_UP"
        }, {
            "description": "Хълцане",
            "nomenclature": "6.26",
            "selectable": false,
            "strength": 0,
            "type": "HICCOUGH"
        }, {
            "description": "Жълтеница",
            "nomenclature": "6.27",
            "selectable": false,
            "strength": 0,
            "type": "JAUNDICE"
        }, {
            "description": "Черен дроб и жлъчен мехур",
            "nomenclature": "6.28",
            "selectable": false,
            "strength": 0,
            "type": "LIVER_GALLBLADDER_CAT"
        }, {
            "description": "Жлъчни колики",
            "nomenclature": "6.28.1",
            "selectable": false,
            "strength": 0,
            "type": "LIVER_GALLBLADDER_BILIARY_COLIC"
        }, {
            "description": "Камъни в жлъчката",
            "nomenclature": "6.28.2",
            "selectable": false,
            "strength": 0,
            "type": "LIVER_GALLBLADDER_GALLSTONES"
        }, {
            "description": "Уринарен тракт",
            "nomenclature": "7",
            "selectable": false,
            "strength": 0,
            "type": "URINARY_TRACT"
        }, {
            "description": "Аномалии на урината",
            "nomenclature": "7.1",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_CAT"
        }, {
            "description": "Кръв в урината",
            "nomenclature": "7.1.1",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_BLOOD"
        }, {
            "description": "Гной в урината",
            "nomenclature": "7.1.2",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_PUS"
        }, {
            "description": "Необичаен цвят на урината",
            "nomenclature": "7.1.3",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_UNUSUAL_COLOR"
        }, {
            "description": "Необичайна миризма на урината",
            "nomenclature": "7.1.4",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_UNUSUAL_ODOR"
        }, {
            "description": "Честота и ноктурия (често уриниране нощем)",
            "nomenclature": "7.2",
            "selectable": false,
            "strength": 0,
            "type": "FREQUENCY_AND_NOCTURIA_CAT"
        }, {
            "description": "Намокряне в леглото",
            "nomenclature": "7.2.1",
            "selectable": false,
            "strength": 0,
            "type": "FREQUENCY_AND_NOCTURIA_BED_WETTING"
        }, {
            "description": "Нощно изпускане на урина",
            "nomenclature": "7.2.2",
            "selectable": false,
            "strength": 0,
            "type": "FREQUENCY_AND_NOCTURIA_NIGHT_DISCHARGE"
        }, {
            "description": "Инконтиненция (неволно изпускане) на урина",
            "nomenclature": "7.3",
            "selectable": false,
            "strength": 0,
            "type": "INCONTINENCE_OF_URINE_CAT"
        }, {
            "description": "Дрибъл (капене след уриниране)",
            "nomenclature": "7.3.1",
            "selectable": false,
            "strength": 0,
            "type": "INCONTINENCE_OF_URINE_DRIBBLING"
        }, {
            "description": "Неволно уриниране",
            "nomenclature": "7.3.2",
            "selectable": false,
            "strength": 0,
            "type": "INCONTINENCE_OF_URINE_INVOLUNTARY_URINATION"
        }, {
            "description": "Задържане на урина",
            "nomenclature": "7.4",
            "selectable": false,
            "strength": 0,
            "type": "RETENTION_OF_URINE_CAT"
        }, {
            "description": "Не може да се изпразни пикочния мехур",
            "nomenclature": "7.4.1",
            "selectable": false,
            "strength": 0,
            "type": "RETENTION_OF_URINE_CANNOT_EMPTY_BLADDER"
        }, {
            "description": "Невъзможност за уриниране",
            "nomenclature": "7.4.2",
            "selectable": false,
            "strength": 0,
            "type": "RETENTION_OF_URINE_INABILITY_TO_URINATE"
        }, {
            "description": "Болезнено уриниране",
            "nomenclature": "7.5",
            "selectable": false,
            "strength": 0,
            "type": "PAINFUL_URINATION_CAT"
        }, {
            "description": "Болезнено уриниране",
            "nomenclature": "7.5.0",
            "selectable": false,
            "strength": 0,
            "type": "PAINFUL_URINATION"
        }, {
            "description": "Парене при уриниране",
            "nomenclature": "7.5.1",
            "selectable": false,
            "strength": 0,
            "type": "PAINFUL_URINATION_BURNING"
        }, {
            "description": "Други симптоми на пикочните пътища",
            "nomenclature": "7.6",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_URINARY_SYMPTOMS_CAT"
        }, {
            "description": "Проблеми с пикочния мехур",
            "nomenclature": "7.6.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_URINARY_SYMPTOMS_BLADDER_TROUBLE"
        }, {
            "description": "Преминаващи камъни/песъчинки при уриниране",
            "nomenclature": "7.6.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_URINARY_SYMPTOMS_PASSED_STONES"
        }, {
            "description": "Мъжка репродуктивна система",
            "nomenclature": "8",
            "selectable": false,
            "strength": 0,
            "type": "MALE_REPRODUCTIVE_SYSTEM_CAT"
        }, {
            "description": "Безплодие  –  Мъжe",
            "nomenclature": "8.1",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_MALE_CAT"
        }, {
            "description": "Нисък брой сперматозоиди",
            "nomenclature": "8.1.1",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_MALE_LOW_SPERM_COUNT"
        }, {
            "description": "Стерилитет",
            "nomenclature": "8.1.2",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_MALE_STERILITY"
        }, {
            "description": "Болка, подуване или маса в мъжката полова система",
            "nomenclature": "8.2",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_CAT"
        }, {
            "description": "Болка в пениса",
            "nomenclature": "8.2.1",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_PAIN_PENIS"
        }, {
            "description": "Болка в скротума",
            "nomenclature": "8.2.2",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_PAIN_SCROTUM"
        }, {
            "description": "Болка в тестисите",
            "nomenclature": "8.2.3",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_PAIN_TESTES"
        }, {
            "description": "Подуване или маса (бучка) в пениса",
            "nomenclature": "8.2.4",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_SWELLING_PENIS"
        }, {
            "description": "Подуване или маса (бучка) в скротума",
            "nomenclature": "8.2.5",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_SWELLING_SCROTUM"
        }, {
            "description": "Подуване или маса (бучка) в тестисите",
            "nomenclature": "8.2.6",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_SWELLING_TESTES"
        }, {
            "description": "Други симптоми на мъжката репродуктивна система",
            "nomenclature": "8.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_MALE_REPRODUCTIVE_SYSTEM_CAT"
        }, {
            "description": "Психосексуални проблеми",
            "nomenclature": "8.3.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_MALE_REPRODUCTIVE_SYSTEM_PSYCHOSEXUAL_PROBLEMS"
        }, {
            "description": "Женска репродуктивна система",
            "nomenclature": "9",
            "selectable": false,
            "strength": 0,
            "type": "FEMALE_REPRODUCTIVE_PROBLEMS_CAT"
        }, {
            "description": "Симптоми в менопаузата",
            "nomenclature": "9.1",
            "selectable": false,
            "strength": 0,
            "type": "MENOPAUSE_CAT"
        }, {
            "description": "Горещи вълни",
            "nomenclature": "9.1.1",
            "selectable": false,
            "strength": 0,
            "type": "MENOPAUSE_HOT_FLASHES"
        }, {
            "description": "Предменструално напрежение",
            "nomenclature": "9.2",
            "selectable": false,
            "strength": 0,
            "type": "PREMENSTRUAL_TENSION"
        }, {
            "description": "Менструални крампи",
            "nomenclature": "9.3",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_CRAMPS"
        }, {
            "description": "Менструални нарушения",
            "nomenclature": "9.4",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_CAT"
        }, {
            "description": "Отсъствие на менструация (аменорея)",
            "nomenclature": "9.4.1",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_ABSENCE_AMENORRHEA"
        }, {
            "description": "Нетипичен менструален материал (секрет)",
            "nomenclature": "9.4.2",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_ATYPICAL_MATERIAL"
        }, {
            "description": "Менструални кръвни съсиреци",
            "nomenclature": "9.4.3",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_BLOOD_CLOTS"
        }, {
            "description": "Прекомерно менструално течение",
            "nomenclature": "9.4.4",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_EXCESSIVE"
        }, {
            "description": "Честа менструация",
            "nomenclature": "9.4.5",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_FREQUENT"
        }, {
            "description": "Рядка менструация",
            "nomenclature": "9.4.6",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_INFREQUENT"
        }, {
            "description": "Нередовна менструация (метрорагия)",
            "nomenclature": "9.4.7",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_IRREGULAR_METRORRHAGIA"
        }, {
            "description": "Обилно менструално течение",
            "nomenclature": "9.4.8",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_LARGE_FLOW"
        }, {
            "description": "Забавено начало на менструален цикъл",
            "nomenclature": "9.4.9",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_ONSET_DELAYED"
        }, {
            "description": "Оскъдно менструално течение",
            "nomenclature": "9.4.10",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_SCANTY"
        }, {
            "description": "Слабо менструално течение",
            "nomenclature": "9.4.11",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_SMALL_FLOW"
        }, {
            "description": "Овулационна болка",
            "nomenclature": "9.5",
            "selectable": false,
            "strength": 0,
            "type": "OVULATION_PAIN"
        }, {
            "description": "Тазови симптоми",
            "nomenclature": "9.6",
            "selectable": false,
            "strength": 0,
            "type": "PELVIC_CAT"
        }, {
            "description": "Болка в таза",
            "nomenclature": "9.6.1",
            "selectable": false,
            "strength": 0,
            "type": "PELVIC_PAIN"
        }, {
            "description": "Усещане за натиск или падане в таза",
            "nomenclature": "9.6.2",
            "selectable": false,
            "strength": 0,
            "type": "PELVIC_PRESSURE"
        }, {
            "description": "Подуване или бучка на таза",
            "nomenclature": "9.6.3",
            "selectable": false,
            "strength": 0,
            "type": "PELVIC_SWELLING_OR_MASS"
        }, {
            "description": "Вагинални нарушения",
            "nomenclature": "9.7",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISORDERS_CAT"
        }, {
            "description": "Вагинална болка",
            "nomenclature": "9.7.1",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISORDERS_PAIN"
        }, {
            "description": "Вагинално подуване или бучка",
            "nomenclature": "9.7.2",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISORDERS_SWELLING_OR_MASS"
        }, {
            "description": "Вагинално течение",
            "nomenclature": "9.8",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISCHARGE_CAT"
        }, {
            "description": "Атипично вагинално течение (левкорея, бяло течение)",
            "nomenclature": "9.8.1",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISCHARGE_ATYPICAL"
        }, {
            "description": "Кърваво вагинално течение",
            "nomenclature": "9.8.2",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISCHARGE_BLOODY"
        }, {
            "description": "Кафяво вагинално течение",
            "nomenclature": "9.8.3",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISCHARGE_BROWN"
        }, {
            "description": "Симптоми, свързани с вулвата (външните полови органи)",
            "nomenclature": "9.9",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_CAT"
        }, {
            "description": "Сърбеж на вулвата",
            "nomenclature": "9.9.1",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_ITCHING"
        }, {
            "description": "Болка при вулвата",
            "nomenclature": "9.9.2",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_PAIN"
        }, {
            "description": "Подуване или маса на перинеума",
            "nomenclature": "9.9.3",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_SWELLING_OR_MASS"
        }, {
            "description": "Язва на вулвата",
            "nomenclature": "9.9.4",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_ULCER"
        }, {
            "description": "Безплодие – Жени",
            "nomenclature": "9.10",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_FEMALE_CAT"
        }, {
            "description": "Стерилитет",
            "nomenclature": "9.10.1",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_FEMALE_STERILITY"
        }, {
            "description": "Проблеми на бременността",
            "nomenclature": "9.11",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_CAT"
        }, {
            "description": "Изтичане на околоплодна течност",
            "nomenclature": "9.11.1",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_LEAKING_AMNIOTIC_FLUID"
        }, {
            "description": "Възможно начало на раждане",
            "nomenclature": "9.11.2",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_POSSIBLE_LABOR"
        }, {
            "description": "Изхвърляне на плода (спонтанен аборт)",
            "nomenclature": "9.11.3",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_CONCEPTION_PRODUCT_PASSED"
        }, {
            "description": "Леко кървене (зацапване) при бременност",
            "nomenclature": "9.11.4",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_SPOTTING"
        }, {
            "description": "Други симптоми на женската репродуктивна система",
            "nomenclature": "9.12",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_FEMALE_REPRODUCTIVE_SYSTEM_SYMPTOMS"
        }, {
            "description": "Бучка или маса на гърдата",
            "nomenclature": "9.14",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_CAT"
        }, {
            "description": "Подутина на гърдата",
            "nomenclature": "9.14.1",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_BUMP"
        }, {
            "description": "Твърдо място (петно) на гърдата",
            "nomenclature": "9.14.2",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_HARD_SPOT"
        }, {
            "description": "Възел в гърдата",
            "nomenclature": "9.14.3",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_KNOT"
        }, {
            "description": "Локален оток на гърдата",
            "nomenclature": "9.14.4",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_LOCAL_SWELLING"
        }, {
            "description": "Възелче на гърдата",
            "nomenclature": "9.14.5",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_NODULE"
        }, {
            "description": "Болка или болезненост на гърдата",
            "nomenclature": "9.15",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_PAIN_CAT"
        }, {
            "description": "Зачервяване на гърдата",
            "nomenclature": "9.15.1",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_PAIN_REDNESS"
        }, {
            "description": "Оток на гърдата, общ",
            "nomenclature": "9.15.2",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_PAIN_SWELLING_GENERALIZED"
        }, {
            "description": "Чувствителност на гърдата",
            "nomenclature": "9.15.3",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_PAIN_TENDERNESS"
        }, {
            "description": "Симптоми на зърната",
            "nomenclature": "9.16",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_CAT"
        }, {
            "description": "Кървене на зърната",
            "nomenclature": "9.16.1",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_BLEEDING"
        }, {
            "description": "Промяна в цвета на зърната",
            "nomenclature": "9.16.2",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_CHANGE_IN_COLOR"
        }, {
            "description": "Секретиране от зърната",
            "nomenclature": "9.16.3",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_DISCHARGE"
        }, {
            "description": "Възпаление на зърната",
            "nomenclature": "9.16.4",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_INFLAMMATION"
        }, {
            "description": "Хлътване, прибиране на зърната",
            "nomenclature": "9.16.5",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_INVERSION"
        }, {
            "description": "Следродилни проблеми на гърдата",
            "nomenclature": "9.17",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_CAT"
        }, {
            "description": "Ненормално секретиране от гърдата",
            "nomenclature": "9.17.1",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_ABNORMAL_SECRETION"
        }, {
            "description": "Липса на кърма",
            "nomenclature": "9.17.2",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_ABSENCE_OF_MILK"
        }, {
            "description": "Трудност или невъзможност за кърмене",
            "nomenclature": "9.17.3",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_NURSING"
        }, {
            "description": "Препълване на гърдата",
            "nomenclature": "9.17.4",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_ENGORGEMENT"
        }, {
            "description": "Прекомерно мляко",
            "nomenclature": "9.17.5",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_EXCESSIVE_MILK"
        }, {
            "description": "Неправилна лактация",
            "nomenclature": "9.17.6",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_IMPROPER_LACTATION"
        }, {
            "description": "Други симптоми на гърдата",
            "nomenclature": "9.18",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BREAST_SYMPTOMS_CAT"
        }, {
            "description": "Увисване на гърдите",
            "nomenclature": "9.18.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BREAST_SYMPTOMS_SAGGING"
        }, {
            "description": "Прекалено големи гърди",
            "nomenclature": "9.18.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BREAST_SYMPTOMS_TOO_LARGE"
        }, {
            "description": "Твърде малки гърди",
            "nomenclature": "9.18.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BREAST_SYMPTOMS_TOO_SMALL"
        }, {
            "description": "Очи и уши",
            "nomenclature": "10",
            "selectable": false,
            "strength": 0,
            "type": "EYES_AND_EARS_CAT"
        }, {
            "description": "Пълна слепота",
            "nomenclature": "10.1",
            "selectable": false,
            "strength": 0,
            "type": "BLINDNESS_COMPLETE"
        }, {
            "description": "Друга дисфункция на зрението",
            "nomenclature": "10.2",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_CAT"
        }, {
            "description": "Неясно зрение",
            "nomenclature": "10.2.1",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_BLURRED"
        }, {
            "description": "Облачно зрение",
            "nomenclature": "10.2.2",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_CLOUDY_VISION"
        }, {
            "description": "Намалено зрение",
            "nomenclature": "10.2.3",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_DIMINISHED"
        }, {
            "description": "Затъмнено зрение",
            "nomenclature": "10.2.4",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_DULL_VISION"
        }, {
            "description": "Плаващи мътнини (мушици) в окото",
            "nomenclature": "10.2.5",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_FLOATERS"
        }, {
            "description": "Хемианопсия (отпадане на половината зрително поле)",
            "nomenclature": "10.2.6",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_HALF_VISION"
        }, {
            "description": "Мъгляво зрение",
            "nomenclature": "10.2.7",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_HAZY_VISION"
        }, {
            "description": "Фотофобия",
            "nomenclature": "10.2.8",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_PHOTOPHOBIA"
        }, {
            "description": "Петна в окото",
            "nomenclature": "10.2.9",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_SPOTS"
        }, {
            "description": "Секретиране на очите",
            "nomenclature": "10.3",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_CAT"
        }, {
            "description": "Кръв от очите",
            "nomenclature": "10.3.1",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_BLOOD"
        }, {
            "description": "Прекомерно сълзене",
            "nomenclature": "10.3.2",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_EXCESSIVE_TEARING"
        }, {
            "description": "Гной от очите",
            "nomenclature": "10.3.3",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_PUS"
        }, {
            "description": "Воднисти очи",
            "nomenclature": "10.3.4",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_WATERING"
        }, {
            "description": "Очна болка и дразнене",
            "nomenclature": "10.4",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_CAT"
        }, {
            "description": "Парене на очите",
            "nomenclature": "10.4.1",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_BURNING"
        }, {
            "description": "Възпалени очи",
            "nomenclature": "10.4.2",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_INFLAMED"
        }, {
            "description": "Раздразнение на очите",
            "nomenclature": "10.4.3",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_IRRITATION"
        }, {
            "description": "Сърбеж в очите",
            "nomenclature": "10.4.4",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_ITCHING"
        }, {
            "description": "Подуване или бучка в окото",
            "nomenclature": "10.4.5",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_SWELLING_OR_MASS"
        }, {
            "description": "Ненормални движения на очите",
            "nomenclature": "10.5",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_CAT"
        }, {
            "description": "Ненормално хлътване на очните ябълки",
            "nomenclature": "10.5.1",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_RETRACTION"
        }, {
            "description": "Кръстосване на очите",
            "nomenclature": "10.5.2",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_CROSS_EYED"
        }, {
            "description": "Различни по размер зеници",
            "nomenclature": "10.5.3",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_UNEQUAL_PUPIL"
        }, {
            "description": "Спазми",
            "nomenclature": "10.5.4",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_SPASMS"
        }, {
            "description": "Кривогледство",
            "nomenclature": "10.5.5",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_SQUINTING"
        }, {
            "description": "Потрепване",
            "nomenclature": "10.5.6",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_TWITCHING"
        }, {
            "description": "Симптоми на клепачите",
            "nomenclature": "10.6",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_CAT"
        }, {
            "description": "Падащ клепач",
            "nomenclature": "10.6.1",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_DROOPING"
        }, {
            "description": "Възпаление на клепачите",
            "nomenclature": "10.6.2",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_INFLAMMATION"
        }, {
            "description": "Сърбеж на клепачите",
            "nomenclature": "10.6.3",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_ITCHING"
        }, {
            "description": "Подуване или бучка на клепачите",
            "nomenclature": "10.6.4",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_SWELLING_OR_MASS"
        }, {
            "description": "Ечемик на окото",
            "nomenclature": "10.7",
            "selectable": false,
            "strength": 0,
            "type": "STY"
        }, {
            "description": "Розово око",
            "nomenclature": "10.8",
            "selectable": false,
            "strength": 0,
            "type": "PINK_EYE_CAT"
        }, {
            "description": "Розово око",
            "nomenclature": "10.8.0",
            "selectable": false,
            "strength": 0,
            "type": "PINK_EYE"
        }, {
            "description": "Конюктивит",
            "nomenclature": "10.8.1",
            "selectable": false,
            "strength": 0,
            "type": "PINK_EYE_CONJUNCTIVITIS"
        }, {
            "description": "Чуждо тяло в очите",
            "nomenclature": "10.9",
            "selectable": false,
            "strength": 0,
            "type": "FOREIGN_BODY_IN_EYES"
        }, {
            "description": "Очни наранявания",
            "nomenclature": "10.10",
            "selectable": false,
            "strength": 0,
            "type": "EYE_INJURIES_CAT"
        }, {
            "description": "Черно око",
            "nomenclature": "10.10.1",
            "selectable": false,
            "strength": 0,
            "type": "EYE_INJURIES_BLACK_EYE"
        }, {
            "description": "Изгаряния на окото",
            "nomenclature": "10.10.2",
            "selectable": false,
            "strength": 0,
            "type": "EYE_INJURIES_BURNS"
        }, {
            "description": "Драскотини по окото",
            "nomenclature": "10.10.3",
            "selectable": false,
            "strength": 0,
            "type": "EYE_INJURIES_SCRATCHES"
        }, {
            "description": "Ненормален външен вид на очите",
            "nomenclature": "10.11",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_CAT"
        }, {
            "description": "Ненормална изпъкналост на очите",
            "nomenclature": "10.11.1",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_PROTRUSION"
        }, {
            "description": "Кръвясало око",
            "nomenclature": "10.11.2",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_BLOODSHOT"
        }, {
            "description": "Мътни очи",
            "nomenclature": "10.11.3",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_CLOUDY"
        }, {
            "description": "Замъглени очи",
            "nomenclature": "10.11.4",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_DULL"
        }, {
            "description": "Перде на очите",
            "nomenclature": "10.11.5",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_HAZY"
        }, {
            "description": "Глухота",
            "nomenclature": "10.13",
            "selectable": false,
            "strength": 0,
            "type": "DEAFNESS"
        }, {
            "description": "Други слухови дисфункции",
            "nomenclature": "10.14",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_CAT"
        }, {
            "description": "Загуба на остър слух",
            "nomenclature": "10.14.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_ACUTE_HEARING"
        }, {
            "description": "Намален слух",
            "nomenclature": "10.14.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_DIMINISHED_HEARING"
        }, {
            "description": "Шум в ушите",
            "nomenclature": "10.14.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_NOICES"
        }, {
            "description": "Звън в ушите",
            "nomenclature": "10.14.4",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_RINGING_IN_EARS"
        }, {
            "description": "Проблеми със слуха",
            "nomenclature": "10.14.5",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_TROUBLE_HEARING"
        }, {
            "description": "Секретиране на ушите",
            "nomenclature": "10.15",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EAR_CAT"
        }, {
            "description": "Гной от ушите",
            "nomenclature": "10.15.0",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EAR_PUS"
        }, {
            "description": "Кървене от ушите",
            "nomenclature": "10.15.1",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EAR_BLEEDING"
        }, {
            "description": "Болка в ухото",
            "nomenclature": "10.16",
            "selectable": false,
            "strength": 0,
            "type": "EARACHE_CAT"
        }, {
            "description": "Болка в ухото",
            "nomenclature": "10.16.1",
            "selectable": false,
            "strength": 0,
            "type": "EARACHE"
        }, {
            "description": "Усещане за запушване на ушите",
            "nomenclature": "10.17",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_CAT"
        }, {
            "description": "Заглъхване на ушите",
            "nomenclature": "10.17.1",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_BLOCKED"
        }, {
            "description": "Пукане в ушите",
            "nomenclature": "10.17.2",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_CRACKING"
        }, {
            "description": "Пропуквания в ушите",
            "nomenclature": "10.17.3",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_POPPING"
        }, {
            "description": "Запушване на ушите",
            "nomenclature": "10.17.4",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_STOPPED_UP"
        }, {
            "description": "Излишък на ушна кал",
            "nomenclature": "10.18",
            "selectable": false,
            "strength": 0,
            "type": "EXCESS_WAX_IN_EAR"
        }, {
            "description": "Ненормален размер на ухото",
            "nomenclature": "10.19",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EAR_SIZE"
        }, {
            "description": "Други симптоми, отнасящи се до ушите",
            "nomenclature": "10.20",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_EAR_SYMPTOMS_CAT"
        }, {
            "description": "Чуждо тяло в ухото",
            "nomenclature": "10.20.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_EAR_SYMPTOMS_FOREIGN_BODY"
        }, {
            "description": "Сърбеж в ушите",
            "nomenclature": "10.20.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_EAR_SYMPTOMS_ITCHING"
        }, {
            "description": "Подуване или бучка в ушите",
            "nomenclature": "10.20.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_EAR_SYMPTOMS_SWELLING_OR_MASS"
        }, {
            "description": "Психическо здраве",
            "nomenclature": "11",
            "selectable": false,
            "strength": 0,
            "type": "MENTAL_HEALTH_CAT"
        }, {
            "description": "Безпокойство",
            "nomenclature": "11.1",
            "selectable": false,
            "strength": 0,
            "type": "ANXIETY_CAT"
        }, {
            "description": "Опасение, безпокойство",
            "nomenclature": "11.1.1",
            "selectable": false,
            "strength": 0,
            "type": "ANXIETY_APPREHENSION"
        }, {
            "description": "Страхове и фобии",
            "nomenclature": "11.3",
            "selectable": false,
            "strength": 0,
            "type": "FEARS_AND_PHOBIAS_CAT"
        }, {
            "description": "Безпокойство",
            "nomenclature": "11.3.0",
            "selectable": false,
            "strength": 0,
            "type": "FEARS_AND_PHOBIAS_RESTLESSNESS"
        }, {
            "description": "Хиперактивност",
            "nomenclature": "11.3.1",
            "selectable": false,
            "strength": 0,
            "type": "FEARS_AND_PHOBIAS_HYPERACTIVITY"
        }, {
            "description": "Свръхактивност",
            "nomenclature": "11.3.2",
            "selectable": false,
            "strength": 0,
            "type": "FEARS_AND_PHOBIAS_OVERACTIVITY"
        }, {
            "description": "Самота",
            "nomenclature": "11.4",
            "selectable": false,
            "strength": 0,
            "type": "LONELINESS"
        }, {
            "description": "Депресия",
            "nomenclature": "11.5",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_CAT"
        }, {
            "description": "Депресия, горчивина",
            "nomenclature": "11.5.1",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_BITTERNESS"
        }, {
            "description": "Депресия, прекомерен плач",
            "nomenclature": "11.5.2",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_CRYING_EXCESSIVELY"
        }, {
            "description": "Депресия, мрачен",
            "nomenclature": "11.5.3",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_DEJECTED"
        }, {
            "description": "Депресия, недоволство",
            "nomenclature": "11.5.4",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_DISCONTENTED"
        }, {
            "description": "Депресия, чувство на изгубеност",
            "nomenclature": "11.5.5",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_FEELING_LOST"
        }, {
            "description": "Депресия, подтиснатост",
            "nomenclature": "11.5.6",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_FEELING_LOW"
        }, {
            "description": "Депресия, отхвърленост",
            "nomenclature": "11.5.7",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_FEELING_REJECTED"
        }, {
            "description": "Депресия, безнадеждност",
            "nomenclature": "11.5.8",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_HOPELESSNESS"
        }, {
            "description": "Депресия, нещастен",
            "nomenclature": "11.5.9",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_UNHAPPY"
        }, {
            "description": "Депресия, тревожност",
            "nomenclature": "11.5.10",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_WORRYING"
        }, {
            "description": "Нервност",
            "nomenclature": "11.6",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_CAT"
        }, {
            "description": "“Пеперуди в стомаха”",
            "nomenclature": "11.6.1",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_BUTTERFLIES"
        }, {
            "description": "Нерви",
            "nomenclature": "11.6.2",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_NERVES"
        }, {
            "description": "Нервно напрежение",
            "nomenclature": "11.6.3",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_TENSION"
        }, {
            "description": "Нервно разстройство",
            "nomenclature": "11.6.4",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_UPSET"
        }, {
            "description": "Поведенчески смущения",
            "nomenclature": "11.7",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_CAT"
        }, {
            "description": "Антисоциално поведение",
            "nomenclature": "11.7.1",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_ANTISOCIAL_BEHAVIOR"
        }, {
            "description": "Поведенчески проблеми",
            "nomenclature": "11.7.2",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_BEHAVORIAL_PROBLEMS"
        }, {
            "description": "Раздразнителност",
            "nomenclature": "11.7.3",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_IRRITABILITY"
        }, {
            "description": "Свадливост",
            "nomenclature": "11.7.4",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_QUARRELSOME"
        }, {
            "description": "Изблици на раздразнение",
            "nomenclature": "11.7.5",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_TEMPER_TANTRUMS"
        }, {
            "description": "Прекомерно пушене",
            "nomenclature": "11.8",
            "selectable": false,
            "strength": 0,
            "type": "EXCESSIVE_SMOKING"
        }, {
            "description": "Проблеми, свързани с алкохола",
            "nomenclature": "11.9",
            "selectable": false,
            "strength": 0,
            "type": "ALCOHOL_RELATED_PROBLEMS_CAT"
        }, {
            "description": "Алкохолизъм",
            "nomenclature": "11.9.1",
            "selectable": false,
            "strength": 0,
            "type": "ALCOHOL_RELATED_PROBLEMS_ALCOHOLISM"
        }, {
            "description": "Пие твърде много",
            "nomenclature": "11.9.2",
            "selectable": false,
            "strength": 0,
            "type": "ALCOHOL_RELATED_PROBLEMS_DRINKS_TOO_MUCH"
        }, {
            "description": "Използване на наркотици",
            "nomenclature": "11.10",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_DRUG_USAGE_CAT"
        }, {
            "description": "Прекомерната употреба на стимуланти или антидепресанти",
            "nomenclature": "11.10.1",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_DRUG_USAGE_STIMULANTS_DEPRESSANTS"
        }, {
            "description": "Злоупотреба с лекарства или наркотици",
            "nomenclature": "11.10.2",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_DRUG_USAGE_MISUSE"
        }, {
            "description": "Илюзии или халюцинации",
            "nomenclature": "11.11",
            "selectable": false,
            "strength": 0,
            "type": "DELUSIONS_OR_HALLUCINATIONS"
        }, {
            "description": "Лоши навици",
            "nomenclature": "11.12",
            "selectable": false,
            "strength": 0,
            "type": "BAD_HABITS_CAT"
        }, {
            "description": "Дъвчене на косата",
            "nomenclature": "11.12.1",
            "selectable": false,
            "strength": 0,
            "type": "BAD_HABITS_CHEWING_ON_HAIR"
        }, {
            "description": "Гризане на ноктите",
            "nomenclature": "11.12.2",
            "selectable": false,
            "strength": 0,
            "type": "BAD_HABITS_NAIL_BITING"
        }, {
            "description": "Смучене на пръст (палец)",
            "nomenclature": "11.12.3",
            "selectable": false,
            "strength": 0,
            "type": "BAD_HABITS_THUMB_SUCKING"
        }, {
            "description": "Обсесия или принуда",
            "nomenclature": "11.13",
            "selectable": false,
            "strength": 0,
            "type": "OBSESSIONS_OR_COMPULSIONS"
        }, {
            "description": "Психосексуални разстройства",
            "nomenclature": "11.14",
            "selectable": false,
            "strength": 0,
            "type": "PSYCHOSEXUAL_DISORDERS_CAT"
        }, {
            "description": "Фригидност",
            "nomenclature": "11.14.1",
            "selectable": false,
            "strength": 0,
            "type": "PSYCHOSEXUAL_DISORDERS_FRIGIDITY"
        }, {
            "description": "Импотентност",
            "nomenclature": "11.14.2",
            "selectable": false,
            "strength": 0,
            "type": "PSYCHOSEXUAL_DISORDERS_IMPOTENCE"
        }]
    },
    "en": {
        "All": [{
            "description": "General Symptoms",
            "nomenclature": "0",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SYMPTOMS_CAT"
        }, {
            "description": "Chills",
            "nomenclature": "0.1",
            "selectable": false,
            "strength": 0,
            "type": "CHILLS"
        }, {
            "description": "Fever",
            "nomenclature": "0.2",
            "selectable": false,
            "strength": 0,
            "type": "FEVER_CAT"
        }, {
            "description": "Fever",
            "nomenclature": "0.2.1",
            "selectable": false,
            "strength": 0,
            "type": "FEVER"
        }, {
            "description": "High temperature",
            "nomenclature": "0.2.2",
            "selectable": false,
            "strength": 0,
            "type": "FEVER_HIGH_TEMPERATURE"
        }, {
            "description": "Fatigue",
            "nomenclature": "0.3",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_CAT"
        }, {
            "description": "Fatigue",
            "nomenclature": "0.3.0",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE"
        }, {
            "description": "Exhausted",
            "nomenclature": "0.3.1",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_EXHAUSTED"
        }, {
            "description": "General weakness",
            "nomenclature": "0.4.2",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_GENERAL_WEAKNESS"
        }, {
            "description": "Pooped",
            "nomenclature": "0.3.3",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_POOPED"
        }, {
            "description": "Rundown",
            "nomenclature": "0.3.4",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_RUNDOWN"
        }, {
            "description": "Tired",
            "nomenclature": "0.3.5",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_TIRED"
        }, {
            "description": "Worn out",
            "nomenclature": "0.3.6",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_WORN_OUT"
        }, {
            "description": "General ill-feeling",
            "nomenclature": "0.4",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_FEELING_CAT"
        }, {
            "description": "Not feeling well",
            "nomenclature": "0.4.1",
            "selectable": false,
            "strength": 0,
            "type": "NOT_FEELING_WELL"
        }, {
            "description": "Fluid imbalance",
            "nomenclature": "0.5",
            "selectable": false,
            "strength": 0,
            "type": "FLUID_IMBALANCE_CAT"
        }, {
            "description": "Dehydration",
            "nomenclature": "0.5.1",
            "selectable": false,
            "strength": 0,
            "type": "DEHYDRATION"
        }, {
            "description": "Excessive sweating",
            "nomenclature": "0.5.2",
            "selectable": false,
            "strength": 0,
            "type": "FLUID_IMBALANCE_EXCESSIVE_SWEATING"
        }, {
            "description": "Excessive thirst",
            "nomenclature": "0.5.3",
            "selectable": false,
            "strength": 0,
            "type": "FLUID_IMBALANCE_EXCESSIVE_THIRST"
        }, {
            "description": "Retention of fluid",
            "nomenclature": "0.5.4",
            "selectable": false,
            "strength": 0,
            "type": "FLUID_IMBALANCE_RETENTION_OF_FLUID"
        }, {
            "description": "Lack of physiological development",
            "nomenclature": "0.6",
            "selectable": false,
            "strength": 0,
            "type": "LACK_PHYS_DEVELOPMENT_CAT"
        }, {
            "description": "Lack of growth",
            "nomenclature": "0.6.1",
            "selectable": false,
            "strength": 0,
            "type": "LACK_PHYS_DEVELOPMENT_LACK_GROWTH"
        }, {
            "description": "Weight gain",
            "nomenclature": "0.7",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_GAIN_CAT"
        }, {
            "description": "Overweight",
            "nomenclature": "0.7.1",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_GAIN_OVERWEIGHT"
        }, {
            "description": "Obesity",
            "nomenclature": "0.7.2",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_GAIN_OBESITY"
        }, {
            "description": "Weight loss",
            "nomenclature": "0.8",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_LOSS_CAT"
        }, {
            "description": "Recent weight loss",
            "nomenclature": "0.8.1",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_LOSS_RECENT_WEIGHT_LOSS"
        }, {
            "description": "Underweight",
            "nomenclature": "0.8.2",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_LOSS_UNDERWEIGHT"
        }, {
            "description": "Pain, generalized, site unspecified",
            "nomenclature": "0.9",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_CAT"
        }, {
            "description": "Ache",
            "nomenclature": "0.9.1",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_ACHE"
        }, {
            "description": "Aches all over",
            "nomenclature": "0.9.2",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_ACHES_OVER"
        }, {
            "description": "Cramp",
            "nomenclature": "0.9.3",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_CRAMP"
        }, {
            "description": "Hurt",
            "nomenclature": "0.9.4",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_HURT"
        }, {
            "description": "Stiffness",
            "nomenclature": "0.9.5",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_STIFFNESS"
        }, {
            "description": "Swelling or mass, site unspecified",
            "nomenclature": "0.10",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_CAT"
        }, {
            "description": "Bulge",
            "nomenclature": "0.10.1",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_BULGE"
        }, {
            "description": "Bump",
            "nomenclature": "0.10.2",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_BUMP"
        }, {
            "description": "Knot",
            "nomenclature": "0.10.3",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_KNOT"
        }, {
            "description": "Lump",
            "nomenclature": "0.10.4",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_LUMP"
        }, {
            "description": "General symptoms of infants and children",
            "nomenclature": "0.11",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_CAT"
        }, {
            "description": "Crying too much",
            "nomenclature": "0.11.1",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_CRYING_TOO_MUCH"
        }, {
            "description": "Fidgety",
            "nomenclature": "0.11.2",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_FIDGETY"
        }, {
            "description": "Fussy",
            "nomenclature": "0.11.3",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_FUSSY"
        }, {
            "description": "Hyperactive",
            "nomenclature": "0.11.4",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_HYPERACTIVE"
        }, {
            "description": "Irritable",
            "nomenclature": "0.11.5",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_IRRITABLE"
        }, {
            "description": "Underactive",
            "nomenclature": "0.11.6",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_UNDERACTIVE"
        }, {
            "description": "Nervous System",
            "nomenclature": "1",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUS_SYSTEM_CAT"
        }, {
            "description": "Abnormal involuntary movements",
            "nomenclature": "1.1",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_CAT"
        }, {
            "description": "Shaking",
            "nomenclature": "1.1.1",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_SHAKING"
        }, {
            "description": "Tic",
            "nomenclature": "1.1.2",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_TIC"
        }, {
            "description": "Tremor",
            "nomenclature": "1.1.3",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_TREMOR"
        }, {
            "description": "Twitch",
            "nomenclature": "1.1.4",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_TWITCH"
        }, {
            "description": "Coma and stupor",
            "nomenclature": "1.2",
            "selectable": false,
            "strength": 0,
            "type": "COMA_AND_STUPOR"
        }, {
            "description": "Confusion",
            "nomenclature": "1.3",
            "selectable": false,
            "strength": 0,
            "type": "CONFUSION"
        }, {
            "description": "Convulsions",
            "nomenclature": "1.4",
            "selectable": false,
            "strength": 0,
            "type": "CONVULSIONS_CAT"
        }, {
            "description": "Fits",
            "nomenclature": "1.4.1",
            "selectable": false,
            "strength": 0,
            "type": "CONVULSIONS_FITS"
        }, {
            "description": "Seizures",
            "nomenclature": "1.4.2",
            "selectable": false,
            "strength": 0,
            "type": "CONVULSIONS_SEIZURES"
        }, {
            "description": "Spells",
            "nomenclature": "1.4.3",
            "selectable": false,
            "strength": 0,
            "type": "CONVULSIONS_SPELLS"
        }, {
            "description": "Headache",
            "nomenclature": "1.5",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE_CAT"
        }, {
            "description": "Headache",
            "nomenclature": "1.5.0",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE"
        }, {
            "description": "Migraine",
            "nomenclature": "1.5.1",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE_MIGRAINE"
        }, {
            "description": "Nervous",
            "nomenclature": "1.5.2",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE_NERVOUS"
        }, {
            "description": "Tension",
            "nomenclature": "1.5.3",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE_TENSION"
        }, {
            "description": "Memory, disturbances of",
            "nomenclature": "1.6",
            "selectable": false,
            "strength": 0,
            "type": "MEMORY_DISTURBANCE_CAT"
        }, {
            "description": "Amnesia",
            "nomenclature": "1.6.1",
            "selectable": false,
            "strength": 0,
            "type": "MEMORY_DISTURBANCE_AMNESIA"
        }, {
            "description": "Lack or loss memory",
            "nomenclature": "1.6.2",
            "selectable": false,
            "strength": 0,
            "type": "MEMORY_DISTURBANCE_LOSS"
        }, {
            "description": "Temporary loss of memory",
            "nomenclature": "1.6.3",
            "selectable": false,
            "strength": 0,
            "type": "MEMORY_DISTURBANCE_TEMPORARY_LOSS"
        }, {
            "description": "Other disturbances of sensation",
            "nomenclature": "1.7",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_CAT"
        }, {
            "description": "Anesthesia",
            "nomenclature": "1.7.1",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_ANESTHESIA"
        }, {
            "description": "Burning",
            "nomenclature": "1.7.2",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_BURNING"
        }, {
            "description": "Hyperesthesia",
            "nomenclature": "1.7.3",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_HYPERESTHESIA"
        }, {
            "description": "Loss of smell",
            "nomenclature": "1.7.4",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_LOSS_OF_SMELL"
        }, {
            "description": "Loss of taste",
            "nomenclature": "1.7.5",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_LOSS_OF_TASTE"
        }, {
            "description": "Loss of touch",
            "nomenclature": "1.7.6",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_LOSS_OF_TOUCH"
        }, {
            "description": "Prickly feeling",
            "nomenclature": "1.7.7",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_PRICKLY_FEELING"
        }, {
            "description": "Tingling",
            "nomenclature": "1.7.8",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_TINGLING"
        }, {
            "description": "Disturbances of sleep",
            "nomenclature": "1.8",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_CAT"
        }, {
            "description": "Drowsiness",
            "nomenclature": "1.8.1",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_DROWSINESS"
        }, {
            "description": "Hypersomnia",
            "nomenclature": "1.8.2",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_HYPERSOMNIA"
        }, {
            "description": "Insomnia",
            "nomenclature": "1.8.3",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_INSOMNIA"
        }, {
            "description": "Trouble sleeping",
            "nomenclature": "1.8.4",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_TROUBLE_SLEEPING"
        }, {
            "description": "Can’t sleep",
            "nomenclature": "1.8.5",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_CANT_SLEEP"
        }, {
            "description": "Nightmares",
            "nomenclature": "1.8.6",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_NIGHTMARES"
        }, {
            "description": "Sleepiness",
            "nomenclature": "1.8.7",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_SLEEPINESS"
        }, {
            "description": "Sleep walking",
            "nomenclature": "1.8.8",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_SLEEP_WALKING"
        }, {
            "description": "Time-zone syndrome",
            "nomenclature": "1.8.9",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_TIMEZONE_SYNDROME"
        }, {
            "description": "Senility-Old Age",
            "nomenclature": "1.9",
            "selectable": false,
            "strength": 0,
            "type": "OLD_AGE_SENILITY"
        }, {
            "description": "Stammering or stuttering",
            "nomenclature": "1.10",
            "selectable": false,
            "strength": 0,
            "type": "STAMMERING_OR_STUTTERING"
        }, {
            "description": "Vertigo-dizziness",
            "nomenclature": "1.11",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_CAT"
        }, {
            "description": "Falling sensations",
            "nomenclature": "1.11.1",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_FALLING_SENSATIONS"
        }, {
            "description": "Giddiness",
            "nomenclature": "1.11.2",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_GIDDINESS"
        }, {
            "description": "Lightheadedness",
            "nomenclature": "1.11.3",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_LIGHTHEADEDNESS"
        }, {
            "description": "Loss of sense of equilibrium or balance",
            "nomenclature": "1.11.4",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_LOSS_SENSE_EQUILIBRIUM_BALANCE"
        }, {
            "description": "Other symptoms referable to the nervous system",
            "nomenclature": "1.12",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_SYMPTOMS_NERVOUS_SYSTEM"
        }, {
            "description": "Skin, Nails and Hair",
            "nomenclature": "2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_NAILS_HAIR_CAT"
        }, {
            "description": "Acne or pimples",
            "nomenclature": "2.1",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_CAT"
        }, {
            "description": "Bad complexion",
            "nomenclature": "2.1.1",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_BAD_COMPLEXION"
        }, {
            "description": "Blackheads",
            "nomenclature": "2.1.2",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_BLACKHEADS"
        }, {
            "description": "Blemishes",
            "nomenclature": "2.1.3",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_BLEMISHES"
        }, {
            "description": "Breaking out",
            "nomenclature": "2.1.4",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_BREAKING_OUT"
        }, {
            "description": "Whiteheads",
            "nomenclature": "2.1.5",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_WHITEHEADS"
        }, {
            "description": "Discoloration or pigmentation",
            "nomenclature": "2.2",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_CAT"
        }, {
            "description": "Blushing",
            "nomenclature": "2.2.1",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_BLUSHING"
        }, {
            "description": "Change in color",
            "nomenclature": "2.2.2",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_CHANGE_IN_COLOR"
        }, {
            "description": "Flushing",
            "nomenclature": "2.2.3",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_FLUSHING"
        }, {
            "description": "Freckles",
            "nomenclature": "2.2.4",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_FRECKLES"
        }, {
            "description": "Red",
            "nomenclature": "2.2.5",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_RED"
        }, {
            "description": "Spots",
            "nomenclature": "2.2.6",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_SPOTS"
        }, {
            "description": "Infectious disorders",
            "nomenclature": "2.3",
            "selectable": false,
            "strength": 0,
            "type": "INFECTIOUS_DISORDERS_CAT"
        }, {
            "description": "Athlete’s foot",
            "nomenclature": "2.3.1",
            "selectable": false,
            "strength": 0,
            "type": "INFECTIOUS_DISORDERS_ATHLETES_FOOT"
        }, {
            "description": "Boils",
            "nomenclature": "2.3.2",
            "selectable": false,
            "strength": 0,
            "type": "INFECTIOUS_DISORDERS_BOILS"
        }, {
            "description": "Ringworm",
            "nomenclature": "2.3.3",
            "selectable": false,
            "strength": 0,
            "type": "INFECTIOUS_DISORDERS_RINGWORM"
        }, {
            "description": "Calluses or corns",
            "nomenclature": "2.4",
            "selectable": false,
            "strength": 0,
            "type": "CALLUSES_OR_CORNS"
        }, {
            "description": "Skin moles",
            "nomenclature": "2.5",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_MOLES"
        }, {
            "description": "Wrinkles",
            "nomenclature": "2.6",
            "selectable": false,
            "strength": 0,
            "type": "WRINKLES"
        }, {
            "description": "Warts",
            "nomenclature": "2.7",
            "selectable": false,
            "strength": 0,
            "type": "WARTS"
        }, {
            "description": "Allergic skin reactions",
            "nomenclature": "2.8",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_CAT"
        }, {
            "description": "Rash",
            "nomenclature": "2.8.0",
            "selectable": false,
            "strength": 0,
            "type": "RASH"
        }, {
            "description": "Hives",
            "nomenclature": "2.8.1",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_HIVES"
        }, {
            "description": "Photosensitivity",
            "nomenclature": "2.8.2",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_PHOTOSENSITIVITY"
        }, {
            "description": "Poison ivy, poison oak, etc.",
            "nomenclature": "2.8.3",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_POISON_IVY_OAK"
        }, {
            "description": "Rash, diaper",
            "nomenclature": "2.8.4",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_RASH_DIAPER"
        }, {
            "description": "Skin irritations",
            "nomenclature": "2.9",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_CAT"
        }, {
            "description": "Skin inflammation",
            "nomenclature": "2.9.1",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_INFLAMMATION"
        }, {
            "description": "Itching",
            "nomenclature": "2.9.2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_ITCHING"
        }, {
            "description": "Painful skin",
            "nomenclature": "2.9.3",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_PAIN"
        }, {
            "description": "Skin ulcer",
            "nomenclature": "2.9.4",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_ULCER"
        }, {
            "description": "Sore skin",
            "nomenclature": "2.9.5",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_SORE"
        }, {
            "description": "Swelling or mass of skin",
            "nomenclature": "2.10",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_CAT"
        }, {
            "description": "Skin bumps",
            "nomenclature": "2.10.1",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_BUMPS"
        }, {
            "description": "Skin lesion",
            "nomenclature": "2.10.2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_LESION"
        }, {
            "description": "Skin nodules",
            "nomenclature": "2.10.3",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_NODULES"
        }, {
            "description": "Skin welts",
            "nomenclature": "2.10.4",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_WELTS"
        }, {
            "description": "Wounds of skin",
            "nomenclature": "2.11",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_CAT"
        }, {
            "description": "Bites on skin",
            "nomenclature": "2.11.1",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_BITES"
        }, {
            "description": "Blisters on skin, non-allergic",
            "nomenclature": "2.11.2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_BLISTERS_NON_ALLERGIC"
        }, {
            "description": "Skin bruises",
            "nomenclature": "2.11.3",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_BRUISES"
        }, {
            "description": "Skin burns(chemical, sun, wind and steam)",
            "nomenclature": "2.11.4",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_BURNS"
        }, {
            "description": "Skin cuts",
            "nomenclature": "2.11.5",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_CUTS"
        }, {
            "description": "Skin scratches",
            "nomenclature": "2.11.6",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_SCRATCHES"
        }, {
            "description": "Other symptoms referable to skin",
            "nomenclature": "2.12",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_CAT"
        }, {
            "description": "Skin dryness",
            "nomenclature": "2.12.1",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_DRYNESS"
        }, {
            "description": "Skin oiliness",
            "nomenclature": "2.12.2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_OILINESS"
        }, {
            "description": "Skin peeling",
            "nomenclature": "2.12.3",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_PEELING"
        }, {
            "description": "Skin scalines",
            "nomenclature": "2.12.4",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_SCALINES"
        }, {
            "description": "Change in skin texture",
            "nomenclature": "2.12.5",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_CHANGE_IN_TEXTURE"
        }, {
            "description": "Symtoms referable to nails",
            "nomenclature": "2.13",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_CAT"
        }, {
            "description": "Breaking of nails",
            "nomenclature": "2.13.1",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_BREAKING"
        }, {
            "description": "Brittle nails",
            "nomenclature": "2.13.2",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_BRITTLE"
        }, {
            "description": "Change in nails color",
            "nomenclature": "2.13.3",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_CHANGE_IN_COLOR"
        }, {
            "description": "Cracked nails",
            "nomenclature": "2.13.4",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_CRACKED"
        }, {
            "description": "Ingrown nails",
            "nomenclature": "2.13.5",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_INGROWN"
        }, {
            "description": "Ridges on nails",
            "nomenclature": "2.13.6",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_RIDGES"
        }, {
            "description": "Nails splitting",
            "nomenclature": "2.13.7",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_SPLITTING"
        }, {
            "description": "Symptoms referable to hair",
            "nomenclature": "2.14",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_CAT"
        }, {
            "description": "Baldness",
            "nomenclature": "2.14.1",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_BALDNESS"
        }, {
            "description": "Brittle hair",
            "nomenclature": "2.14.2",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_BRITTLE"
        }, {
            "description": "Hair dryness",
            "nomenclature": "2.14.3",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_DRYNESS"
        }, {
            "description": "Falling out hair",
            "nomenclature": "2.14.4",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_FALLING_OUT"
        }, {
            "description": "Hair oilness",
            "nomenclature": "2.14.5",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_OILNESS"
        }, {
            "description": "Receding hair line",
            "nomenclature": "2.14.6",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_RECEDING_HAIR_LINE"
        }, {
            "description": "Symptoms of umbilicus",
            "nomenclature": "2.15",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_CAT"
        }, {
            "description": "Umbilical discharge",
            "nomenclature": "2.15.1",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_DISCHARGE"
        }, {
            "description": "Umbilical drainage",
            "nomenclature": "2.15.2",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_DRAINING"
        }, {
            "description": "Belly button not healing",
            "nomenclature": "2.15.3",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_NOT_HEALING"
        }, {
            "description": "Belly button pain",
            "nomenclature": "2.15.4",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_PAINFUL"
        }, {
            "description": "Red belly button",
            "nomenclature": "2.15.5",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_RED"
        }, {
            "description": "Cardiovascular and Lymphatic systems",
            "nomenclature": "3",
            "selectable": false,
            "strength": 0,
            "type": "CARDIOVASCULAR_LYMPHATIC_SYMPTOMS_CAT"
        }, {
            "description": "Irregular pulsations and palpitations",
            "nomenclature": "3.1",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_CAT"
        }, {
            "description": "Decreased heart beats",
            "nomenclature": "3.1.1",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_DECREASED"
        }, {
            "description": "Fluttering heart",
            "nomenclature": "3.1.2",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_FLUTTERING"
        }, {
            "description": "Increased heart beats",
            "nomenclature": "3.1.3",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_INCREASED"
        }, {
            "description": "Pulse too fast",
            "nomenclature": "3.1.4",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_PULSE_TOO_FAST"
        }, {
            "description": "Pulse too slow",
            "nomenclature": "3.1.5",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_PULSE_TOO_SLOW"
        }, {
            "description": "Irregular heart beats",
            "nomenclature": "3.1.6",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_IRREGULAR_BEATS"
        }, {
            "description": "Rapid heart beats heard",
            "nomenclature": "3.1.7",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_RAPID_HEARD"
        }, {
            "description": "Skipped beat",
            "nomenclature": "3.1.8",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_SKIPPED_BEAT"
        }, {
            "description": "Unequal heart beats",
            "nomenclature": "3.1.9",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_UNEQUAL"
        }, {
            "description": "Heart murmur",
            "nomenclature": "3.2",
            "selectable": false,
            "strength": 0,
            "type": "HEART_MURMUR"
        }, {
            "description": "Abnormally high blood pressure",
            "nomenclature": "3.3",
            "selectable": false,
            "strength": 0,
            "type": "HIGH_BLOOD_PRESSURE_CAT"
        }, {
            "description": "Elevated blood pressure",
            "nomenclature": "3.3.1",
            "selectable": false,
            "strength": 0,
            "type": "HIGH_BLOOD_PRESSURE_ELEVATED_BP"
        }, {
            "description": "High blood pressure",
            "nomenclature": "3.3.2",
            "selectable": false,
            "strength": 0,
            "type": "HIGH_BLOOD_PRESSURE_HIGH_BP"
        }, {
            "description": "Hypertension",
            "nomenclature": "3.3.3",
            "selectable": false,
            "strength": 0,
            "type": "HIGH_BLOOD_PRESSURE_HYPERTENSION"
        }, {
            "description": "Abnormally low blood pressure",
            "nomenclature": "3.4",
            "selectable": false,
            "strength": 0,
            "type": "LOW_BLOOD_PRESSURE_CAT"
        }, {
            "description": "Decreased blood pressure",
            "nomenclature": "3.4.1",
            "selectable": false,
            "strength": 0,
            "type": "LOW_BLOOD_PRESSURE_DECREASED_BP"
        }, {
            "description": "Hypotension",
            "nomenclature": "3.4.2",
            "selectable": false,
            "strength": 0,
            "type": "LOW_BLOOD_PRESSURE_HYPOTENSION"
        }, {
            "description": "Low blood pressure",
            "nomenclature": "3.4.3",
            "selectable": false,
            "strength": 0,
            "type": "LOW_BLOOD_PRESSURE_LOW_BP"
        }, {
            "description": "Symptoms referable to blood",
            "nomenclature": "3.5",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_CAT"
        }, {
            "description": "Poor blood",
            "nomenclature": "3.5.1",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_POOR"
        }, {
            "description": "Thin blood",
            "nomenclature": "3.5.2",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_THIN"
        }, {
            "description": "Tired blood",
            "nomenclature": "3.5.3",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_TIRED"
        }, {
            "description": "Weak blood",
            "nomenclature": "3.5.4",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_WEAK"
        }, {
            "description": "Pallor and cyanosis",
            "nomenclature": "3.6",
            "selectable": false,
            "strength": 0,
            "type": "PALLOR_AND_CYANOSIS_CAT"
        }, {
            "description": "Ashen color",
            "nomenclature": "3.6.1",
            "selectable": false,
            "strength": 0,
            "type": "PALLOR_AND_CYANOSIS_ASHEN_COLOR"
        }, {
            "description": "Blueness of fingers-toes",
            "nomenclature": "3.6.2",
            "selectable": false,
            "strength": 0,
            "type": "PALLOR_AND_CYANOSIS_BLUENESS_OF_FINGERS"
        }, {
            "description": "Paleness",
            "nomenclature": "3.6.3",
            "selectable": false,
            "strength": 0,
            "type": "PALLOR_AND_CYANOSIS_PALENESS"
        }, {
            "description": "Syncope or collapse",
            "nomenclature": "3.7",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_CAT"
        }, {
            "description": "Blacked out",
            "nomenclature": "3.7.1",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_BLACKED_OUT"
        }, {
            "description": "Fainting",
            "nomenclature": "3.7.2",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_FAINTING"
        }, {
            "description": "Passed out",
            "nomenclature": "3.7.3",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_PASSED_OUT"
        }, {
            "description": "Spells",
            "nomenclature": "3.7.4",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_SPELLS"
        }, {
            "description": "Symptoms of heard",
            "nomenclature": "3.8",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_HEARD_CAT"
        }, {
            "description": "Bad heard",
            "nomenclature": "3.8.1",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_HEARD_BAD_HEARD"
        }, {
            "description": "Poor heard",
            "nomenclature": "3.8.2",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_HEARD_POOR_HEARD"
        }, {
            "description": "Weak heard",
            "nomenclature": "3.8.3",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_HEARD_WEAK_HEARD"
        }, {
            "description": "Edema and dropsy",
            "nomenclature": "3.9",
            "selectable": false,
            "strength": 0,
            "type": "EDEMA_AND_DROPSY"
        }, {
            "description": "Lymph glands",
            "nomenclature": "3.10",
            "selectable": false,
            "strength": 0,
            "type": "LYMPH_GLANDS_CAT"
        }, {
            "description": "Enlarged lymph nodes",
            "nomenclature": "3.10.1",
            "selectable": false,
            "strength": 0,
            "type": "LYMPH_GLANDS_ENLARGED_LYMPH_NODES"
        }, {
            "description": "Sore glands",
            "nomenclature": "3.10.2",
            "selectable": false,
            "strength": 0,
            "type": "LYMPH_GLANDS_SORE_GLANDS"
        }, {
            "description": "Swollen glands",
            "nomenclature": "3.10.3",
            "selectable": false,
            "strength": 0,
            "type": "LYMPH_GLANDS_SWOLLEN_GLANDS"
        }, {
            "description": "Respiratory System",
            "nomenclature": "4",
            "selectable": false,
            "strength": 0,
            "type": "RESPIRATORY_SYMPTOMS_CAT"
        }, {
            "description": "Nose bleed",
            "nomenclature": "4.1",
            "selectable": false,
            "strength": 0,
            "type": "NOSE_BLEED_CAT"
        }, {
            "description": "Bleeding from nose",
            "nomenclature": "4.1.1",
            "selectable": false,
            "strength": 0,
            "type": "NOSE_BLEED_BLEEDING"
        }, {
            "description": "Hemorrhage from nose",
            "nomenclature": "4.1.2",
            "selectable": false,
            "strength": 0,
            "type": "NOSE_BLEED_HEMORRHAGE"
        }, {
            "description": "Nasal congestion",
            "nomenclature": "4.2",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_CAT"
        }, {
            "description": "Drippy nose",
            "nomenclature": "4.2.1",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_DRIPPY_NOSE"
        }, {
            "description": "Postnasal drip",
            "nomenclature": "4.2.2",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_POSTNASAL_DRIP"
        }, {
            "description": "Red nose",
            "nomenclature": "4.2.3",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_RED_NOSE"
        }, {
            "description": "Runny nose",
            "nomenclature": "4.2.4",
            "selectable": false,
            "strength": 0,
            "type": "RUNNY_NOSE"
        }, {
            "description": "Sniffles",
            "nomenclature": "4.2.5",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_SNIFFLES"
        }, {
            "description": "Stuffy nose",
            "nomenclature": "4.2.6",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_STUFFY_NOSE"
        }, {
            "description": "Sinus problems",
            "nomenclature": "4.3",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_CAT"
        }, {
            "description": "Sinus congestion",
            "nomenclature": "4.3.1",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_CONGESTION"
        }, {
            "description": "Impacted sinuses",
            "nomenclature": "4.3.2",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_IMPACTED"
        }, {
            "description": "Infected sinuses",
            "nomenclature": "4.3.3",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_INFECTED"
        }, {
            "description": "Lightness in sinuses",
            "nomenclature": "4.3.4",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_LIGHTNESS"
        }, {
            "description": "Pain in sinuses",
            "nomenclature": "4.3.5",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_PAIN"
        }, {
            "description": "Pressure in sinuses",
            "nomenclature": "4.3.6",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_PRESSURE"
        }, {
            "description": "Breathing",
            "nomenclature": "4.4",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_CAT"
        }, {
            "description": "Shortness of breath",
            "nomenclature": "4.4.0",
            "selectable": false,
            "strength": 0,
            "type": "BREATH_SHORTNESS"
        }, {
            "description": "Breathlessness",
            "nomenclature": "4.4.1",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_BREATHLESSNESS"
        }, {
            "description": "Dyspnes",
            "nomenclature": "4.4.2",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_DYSPNES"
        }, {
            "description": "Sensation of suffocation",
            "nomenclature": "4.4.3",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_SENSATION_OF_SUFFOCATION"
        }, {
            "description": "Trouble breathing",
            "nomenclature": "4.4.4",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_TROUBLE_BREATHING"
        }, {
            "description": "Other disorders of respiratory rhythm and sound",
            "nomenclature": "4.5",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_CAT"
        }, {
            "description": "Abnormal breathing sounds",
            "nomenclature": "4.5.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_ABNORMAL_BREATHING_SOUNDS"
        }, {
            "description": "Hyperventilation",
            "nomenclature": "4.5.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_HYPERVENTILATION"
        }, {
            "description": "Rales",
            "nomenclature": "4.5.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_RALES"
        }, {
            "description": "Rapid breathing",
            "nomenclature": "4.5.4",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_RAPID_BREATHING"
        }, {
            "description": "Sighing respiration",
            "nomenclature": "4.5.5",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_SIGHING_RESPIRATION"
        }, {
            "description": "Wheezing",
            "nomenclature": "4.5.6",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_WHEEZING"
        }, {
            "description": "Sneezing",
            "nomenclature": "4.6",
            "selectable": false,
            "strength": 0,
            "type": "SNEEZING"
        }, {
            "description": "Cough",
            "nomenclature": "4.7",
            "selectable": false,
            "strength": 0,
            "type": "COUGH"
        }, {
            "description": "Cold",
            "nomenclature": "4.8",
            "selectable": false,
            "strength": 0,
            "type": "COLD"
        }, {
            "description": "Flu",
            "nomenclature": "4.9",
            "selectable": false,
            "strength": 0,
            "type": "FLU"
        }, {
            "description": "Grip",
            "nomenclature": "4.9.1",
            "selectable": false,
            "strength": 0,
            "type": "GRIP"
        }, {
            "description": "Influenza",
            "nomenclature": "4.9.2",
            "selectable": false,
            "strength": 0,
            "type": "INFLUENZA"
        }, {
            "description": "Croup",
            "nomenclature": "4.10",
            "selectable": false,
            "strength": 0,
            "type": "CROUP"
        }, {
            "description": "Sputum or phlegm",
            "nomenclature": "4.11",
            "selectable": false,
            "strength": 0,
            "type": "SPUTUM_OR_PHLEGM_CAT"
        }, {
            "description": "Bloody sputum",
            "nomenclature": "4.11.1",
            "selectable": false,
            "strength": 0,
            "type": "SPUTUM_OR_PHLEGM_BLOODY"
        }, {
            "description": "Excessive sputum",
            "nomenclature": "4.11.2",
            "selectable": false,
            "strength": 0,
            "type": "SPUTUM_OR_PHLEGM_EXCESSIVE"
        }, {
            "description": "Purulent sputum",
            "nomenclature": "4.11.3",
            "selectable": false,
            "strength": 0,
            "type": "SPUTUM_OR_PHLEGM_PURULENT"
        }, {
            "description": "Congestion in chest",
            "nomenclature": "4.12",
            "selectable": false,
            "strength": 0,
            "type": "CONGESTION_IN_CHEST_CAT"
        }, {
            "description": "Lung congestion",
            "nomenclature": "4.12.1",
            "selectable": false,
            "strength": 0,
            "type": "CONGESTION_IN_CHEST_LUNG_CONGESTION"
        }, {
            "description": "Pain in chest",
            "nomenclature": "4.13",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_CAT"
        }, {
            "description": "Burning sensation in chest",
            "nomenclature": "4.13.1",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_BURNING_SENSATION"
        }, {
            "description": "Chest tightness",
            "nomenclature": "4.13.2",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_CHEST_TIGHTNESS"
        }, {
            "description": "Pain in lung",
            "nomenclature": "4.13.3",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_PAIN_IN_LUNG"
        }, {
            "description": "Pain over heart",
            "nomenclature": "4.13.4",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_PAIN_OVER_HEART"
        }, {
            "description": "Respiratory pain (rib, retrosternal, sternal)",
            "nomenclature": "4.13.5",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_RIB"
        }, {
            "description": "Pressure in/on chest",
            "nomenclature": "4.13.6",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_PRESSURE_IN_ON_CHEST"
        }, {
            "description": "Disorders of voice",
            "nomenclature": "4.14",
            "selectable": false,
            "strength": 0,
            "type": "DISORDERS_OF_VOICE_CAT"
        }, {
            "description": "Voice hoarsenses",
            "nomenclature": "4.14.1",
            "selectable": false,
            "strength": 0,
            "type": "DISORDERS_OF_VOICE_HOARSENSES"
        }, {
            "description": "Hypernasality",
            "nomenclature": "4.14.2",
            "selectable": false,
            "strength": 0,
            "type": "DISORDERS_OF_VOICE_HYPERNASALITY"
        }, {
            "description": "Musculoskeletal System",
            "nomenclature": "5",
            "selectable": false,
            "strength": 0,
            "type": "MUSKULOSKELETAL_SYSTEM_SYMPTOMS_CAT"
        }, {
            "description": "Pain, swelling, injury of lower extremity",
            "nomenclature": "5.1",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_CAT"
        }, {
            "description": "Ache in leg",
            "nomenclature": "5.1.1",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_ACHE"
        }, {
            "description": "Charley horse in leg",
            "nomenclature": "5.1.2",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_CHARLEYHORSE"
        }, {
            "description": "Leg contracture",
            "nomenclature": "5.1.3",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_CONTRACTURE"
        }, {
            "description": "Leg cramp",
            "nomenclature": "5.1.4",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_CRAMP"
        }, {
            "description": "Hot-cold leg feeling",
            "nomenclature": "5.1.5",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_HOT_COLD"
        }, {
            "description": "Leg hurt",
            "nomenclature": "5.1.6",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_HURT"
        }, {
            "description": "Pulled leg muscle",
            "nomenclature": "5.1.7",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_PULLED_MUSCLE"
        }, {
            "description": "Soreness in leg",
            "nomenclature": "5.1.8",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_SORENESS"
        }, {
            "description": "Spasm in leg",
            "nomenclature": "5.1.9",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_SPASM"
        }, {
            "description": "Stiffness in leg",
            "nomenclature": "5.1.10",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STIFFNESS"
        }, {
            "description": "Strain of ankle, foot, hip, knee",
            "nomenclature": "5.1.11",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_ANKLE_FOOT_HIP_KNEE"
        }, {
            "description": "Strain of foot",
            "nomenclature": "5.1.12",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_FOOT"
        }, {
            "description": "Strain of hip",
            "nomenclature": "5.1.13",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_HIP"
        }, {
            "description": "Strain of knee",
            "nomenclature": "5.1.14",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_KNEE"
        }, {
            "description": "Strain of leg or thigh",
            "nomenclature": "5.1.15",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_LEG_OR_THIGH"
        }, {
            "description": "Strain of lower extremity",
            "nomenclature": "5.1.16",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_GLOBAL"
        }, {
            "description": "Strain of toe",
            "nomenclature": "5.1.17",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_TOE"
        }, {
            "description": "Pain, swelling, injury of upper extremity",
            "nomenclature": "5.2",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_CAT"
        }, {
            "description": "Ache in arm",
            "nomenclature": "5.2.1",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_ACHE"
        }, {
            "description": "Arm contracture",
            "nomenclature": "5.2.1",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_CONTRACTURE"
        }, {
            "description": "Hot-cold arm feeling",
            "nomenclature": "5.2.3",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_HOT_COLD"
        }, {
            "description": "Arm hurt",
            "nomenclature": "5.2.4",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_HURT"
        }, {
            "description": "Pulled arm muscle",
            "nomenclature": "5.2.5",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_PULLED_MUSCLE"
        }, {
            "description": "Soreness in arm",
            "nomenclature": "5.2.6",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_SORENESS"
        }, {
            "description": "Spasm in arm",
            "nomenclature": "5.2.7",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_SPASM"
        }, {
            "description": "Stiffness in arm",
            "nomenclature": "5.2.8",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STIFFNESS"
        }, {
            "description": "Strain of arm",
            "nomenclature": "5.2.9",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_ARM"
        }, {
            "description": "Strain of elbow",
            "nomenclature": "5.2.10",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_ELBOW"
        }, {
            "description": "Strain of fingers",
            "nomenclature": "5.2.11",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_FINGERS"
        }, {
            "description": "Strain of forearm",
            "nomenclature": "5.2.12",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_FOREARM"
        }, {
            "description": "Strain of hand",
            "nomenclature": "5.2.13",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_HAND"
        }, {
            "description": "Strain of shoulder",
            "nomenclature": "5.2.14",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_SHOULDER"
        }, {
            "description": "Strain of thumb",
            "nomenclature": "5.2.15",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_THUMB"
        }, {
            "description": "Strain of upper arm",
            "nomenclature": "5.2.16",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_UPPER_ARM"
        }, {
            "description": "Strain of upper extremity",
            "nomenclature": "5.2.17",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_UPPER_EXTREMITY"
        }, {
            "description": "Strain of wrist",
            "nomenclature": "5.2.18",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_WRIST"
        }, {
            "description": "Pain, swelling, injury of face and neck",
            "nomenclature": "5.3",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_CAT"
        }, {
            "description": "Ache in neck",
            "nomenclature": "5.3.0",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_ACHE_NECK"
        }, {
            "description": "Ache in face",
            "nomenclature": "5.3.1",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_ACHE_FACE"
        }, {
            "description": "Face contracture",
            "nomenclature": "5.3.2",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_CONTRACTURE"
        }, {
            "description": "Neck cramp",
            "nomenclature": "5.3.3",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_CRAMP_NECK"
        }, {
            "description": "Face cramp",
            "nomenclature": "5.3.30",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_CRAMP_FACE"
        }, {
            "description": "Neck hurt",
            "nomenclature": "5.3.4",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_HURT_NECK"
        }, {
            "description": "Face hurt",
            "nomenclature": "5.3.40",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_HURT"
        }, {
            "description": "Pulled muscle of neck",
            "nomenclature": "5.3.5",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_PULLED_MUSCLE"
        }, {
            "description": "Soreness of face",
            "nomenclature": "5.3.6",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_SORENESS"
        }, {
            "description": "Face spasm",
            "nomenclature": "5.3.7",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_SPASM"
        }, {
            "description": "Stiffness of neck",
            "nomenclature": "5.3.8",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STIFFNESS"
        }, {
            "description": "Strain of back of head",
            "nomenclature": "5.3.9",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_BACK_HEAD"
        }, {
            "description": "Strain of cervical spine",
            "nomenclature": "5.3.10",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_CERVICAL_SPINE"
        }, {
            "description": "Strain of face",
            "nomenclature": "5.3.11",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_FACE"
        }, {
            "description": "Strain of jaw",
            "nomenclature": "5.3.12",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_JAW"
        }, {
            "description": "Strain of neck",
            "nomenclature": "5.3.13",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_NECK"
        }, {
            "description": "Strain of upper spine",
            "nomenclature": "5.3.14",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_UPPER_SPINE"
        }, {
            "description": "Pain, swelling, injury of back region",
            "nomenclature": "5.4",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_CAT"
        }, {
            "description": "Back ache",
            "nomenclature": "5.4.1",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_ACHE"
        }, {
            "description": "Contracture",
            "nomenclature": "5.4.2",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_CONTRACTURE"
        }, {
            "description": "Back cramp",
            "nomenclature": "5.4.3",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_CRAMP"
        }, {
            "description": "Back hurt",
            "nomenclature": "5.4.4",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_HURT"
        }, {
            "description": "Pulled back muscle",
            "nomenclature": "5.4.5",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_PULLED_MUSCLE"
        }, {
            "description": "Back soreness",
            "nomenclature": "5.4.6",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_SORENESS"
        }, {
            "description": "Back spasm",
            "nomenclature": "5.4.7",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_SPASM"
        }, {
            "description": "Back stiffness",
            "nomenclature": "5.4.8",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STIFFNESS"
        }, {
            "description": "Strain of back",
            "nomenclature": "5.4.9",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_BACK"
        }, {
            "description": "Strain of back, upper, lower",
            "nomenclature": "5.4.10",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_BACK_LOWER_UPPER"
        }, {
            "description": "Strain of lumbar",
            "nomenclature": "5.4.11",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_LUMBAR"
        }, {
            "description": "Strain of lumbosacral",
            "nomenclature": "5.4.12",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_LUMBOSACRAL"
        }, {
            "description": "Strain of sacroiliac",
            "nomenclature": "5.4.13",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_SACROILIAC"
        }, {
            "description": "Strain of spine",
            "nomenclature": "5.4.14",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_SPINE"
        }, {
            "description": "Strain of thoracic spine",
            "nomenclature": "5.4.15",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_THORACIC_SPINE"
        }, {
            "description": "Atrophy or wasting of extremities",
            "nomenclature": "5.5",
            "selectable": false,
            "strength": 0,
            "type": "ATROPHY_OR_WATING_EXTREMITIES_CAT"
        }, {
            "description": "Limb numbness",
            "nomenclature": "5.5.1",
            "selectable": false,
            "strength": 0,
            "type": "ATROPHY_OR_WATING_EXTREMITIES_NYMBNESS"
        }, {
            "description": "Paralysis, partial or complete",
            "nomenclature": "5.5.2",
            "selectable": false,
            "strength": 0,
            "type": "ATROPHY_OR_WATING_EXTREMITIES_PARALYSIS"
        }, {
            "description": "Limb weakness",
            "nomenclature": "5.5.3",
            "selectable": false,
            "strength": 0,
            "type": "ATROPHY_OR_WATING_EXTREMITIES_WEAKNESS"
        }, {
            "description": "Difficulty in walking, abnormality of gait",
            "nomenclature": "5.6",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_CAT"
        }, {
            "description": "Clumsiness when walking",
            "nomenclature": "5.6.1",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_CLUMSINESS"
        }, {
            "description": "Falling when walking",
            "nomenclature": "5.6.2",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_FALLING"
        }, {
            "description": "Inability to stand or walk",
            "nomenclature": "5.6.3",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_INABILITY_TO_STAND_AND_WALK"
        }, {
            "description": "Limping",
            "nomenclature": "5.6.4",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_LIMPING"
        }, {
            "description": "Staggering",
            "nomenclature": "5.6.5",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_STAGGERING"
        }, {
            "description": "Other limb and joint symptoms",
            "nomenclature": "5.7",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_LIMB_JOINT_SYMPTOMS_CAT"
        }, {
            "description": "Muscle pain",
            "nomenclature": "5.7.0",
            "selectable": false,
            "strength": 0,
            "type": "MUSCLE_PAIN"
        }, {
            "description": "Joint pain",
            "nomenclature": "5.7.4",
            "selectable": false,
            "strength": 0,
            "type": "JOINT_PAIN"
        }, {
            "description": "Foot drop",
            "nomenclature": "5.7.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_LIMB_JOINT_SYMPTOMS_FOOT_DROP"
        }, {
            "description": "Posture problems",
            "nomenclature": "5.7.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_LIMB_JOINT_SYMPTOMS_POSTURE_PROBLEMS"
        }, {
            "description": "Wrist drop",
            "nomenclature": "5.7.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_LIMB_JOINT_SYMPTOMS_WRIST_DROP"
        }, {
            "description": "Bunion",
            "nomenclature": "5.8",
            "selectable": false,
            "strength": 0,
            "type": "BUNION"
        }, {
            "description": "Digestive System",
            "nomenclature": "6",
            "selectable": false,
            "strength": 0,
            "type": "DIGESTIVE_SYSTEM_CAT"
        }, {
            "description": "Chewing difficulty",
            "nomenclature": "6.1",
            "selectable": false,
            "strength": 0,
            "type": "CHEWING_DIFFICULTY"
        }, {
            "description": "Bleeding, gums (gingival)",
            "nomenclature": "6.2",
            "selectable": false,
            "strength": 0,
            "type": "BLEEDING_GUMS"
        }, {
            "description": "Halitosis",
            "nomenclature": "6.3",
            "selectable": false,
            "strength": 0,
            "type": "HALITOSIS_CAT"
        }, {
            "description": "Bad breath",
            "nomenclature": "6.3.1",
            "selectable": false,
            "strength": 0,
            "type": "HALITOSIS_BAD_BREATH"
        }, {
            "description": "Symptoms referable to lips",
            "nomenclature": "6.4",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_CAT"
        }, {
            "description": "Abnormal lips color",
            "nomenclature": "6.4.1",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_ABNORMAL_COLOR"
        }, {
            "description": "Bleeding lips",
            "nomenclature": "6.4.2",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_BLEEDING"
        }, {
            "description": "Cracked lips",
            "nomenclature": "6.4.3",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_CRACKED"
        }, {
            "description": "Dry lips",
            "nomenclature": "6.4.4",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_DRY"
        }, {
            "description": "Lips pain",
            "nomenclature": "6.4.5",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_PAIN"
        }, {
            "description": "Lips swelling",
            "nomenclature": "6.4.6",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_SWELLING"
        }, {
            "description": "Symptoms referable to mouth",
            "nomenclature": "6.5",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_CAT"
        }, {
            "description": "Bad taste",
            "nomenclature": "6.5.1",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_BAD_TASTE"
        }, {
            "description": "Mouth burn",
            "nomenclature": "6.5.2",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_BURN"
        }, {
            "description": "Mouth dryness",
            "nomenclature": "6.5.3",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_DRYNESS"
        }, {
            "description": "Mouth inflammation",
            "nomenclature": "6.5.4",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_INFLAMMATION"
        }, {
            "description": "Mouth pain",
            "nomenclature": "6.5.5",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_PAIN"
        }, {
            "description": "Mouth swelling",
            "nomenclature": "6.5.6",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_SWELLING"
        }, {
            "description": "Mouth ulcer",
            "nomenclature": "6.5.7",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_ULCER"
        }, {
            "description": "Saliva",
            "nomenclature": "6.6",
            "selectable": false,
            "strength": 0,
            "type": "SALIVA_CAT"
        }, {
            "description": "Too much saliva",
            "nomenclature": "6.6.0",
            "selectable": false,
            "strength": 0,
            "type": "SALIVA_EXCESS"
        }, {
            "description": "Lack of saliva",
            "nomenclature": "6.6.2",
            "selectable": false,
            "strength": 0,
            "type": "SALIVA_LACK"
        }, {
            "description": "Drooling saliva",
            "nomenclature": "6.6.1",
            "selectable": false,
            "strength": 0,
            "type": "SALIVA_DROOLING"
        }, {
            "description": "Toothache",
            "nomenclature": "6.7",
            "selectable": false,
            "strength": 0,
            "type": "TOOTHACHE"
        }, {
            "description": "Throat soreness",
            "nomenclature": "6.8",
            "selectable": false,
            "strength": 0,
            "type": "THROAT_CAT"
        }, {
            "description": "Painful throat",
            "nomenclature": "6.8.1",
            "selectable": false,
            "strength": 0,
            "type": "THROAT_PAINFUL"
        }, {
            "description": "Scratchy throat",
            "nomenclature": "6.8.2",
            "selectable": false,
            "strength": 0,
            "type": "THROAT_SCRATCHY"
        }, {
            "description": "Sore throat",
            "nomenclature": "6.8.3",
            "selectable": false,
            "strength": 0,
            "type": "SORE_THROAT"
        }, {
            "description": "Symptoms referable to tongue",
            "nomenclature": "6.9",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_CAT"
        }, {
            "description": "Abnormal tongue color",
            "nomenclature": "6.9.1",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_ABNORMAL_COLOR"
        }, {
            "description": "Bleeding tongue",
            "nomenclature": "6.9.2",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_BLEEDING"
        }, {
            "description": "Tongue blisters",
            "nomenclature": "6.9.3",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_BLISTERS"
        }, {
            "description": "Burned tongue",
            "nomenclature": "6.9.4",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_BURNED"
        }, {
            "description": "Tongue pain",
            "nomenclature": "6.9.5",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_PAIN"
        }, {
            "description": "Tongue ridges",
            "nomenclature": "6.9.6",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_RIDGES"
        }, {
            "description": "Smooth tongue",
            "nomenclature": "6.9.7",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_SMOOTH"
        }, {
            "description": "Swelling or mass in tongue",
            "nomenclature": "6.9.8",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_SWELLING_OR_MASS"
        }, {
            "description": "Tongue ulcer",
            "nomenclature": "6.9.9",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_ULCER"
        }, {
            "description": "Symptoms referable to tonsils",
            "nomenclature": "6.10",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_CAT"
        }, {
            "description": "Bleeding (postoperative) tonsils",
            "nomenclature": "6.10.1",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_BLEEDING_POSTOPERATIVE"
        }, {
            "description": "Tonsils discharge",
            "nomenclature": "6.10.2",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_DISCHARGE"
        }, {
            "description": "Tonsisls inflammation",
            "nomenclature": "6.10.3",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_INFLAMMATION"
        }, {
            "description": "Tonsils swelling",
            "nomenclature": "6.10.4",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_SWELLING"
        }, {
            "description": "Swallowing",
            "nomenclature": "6.11",
            "selectable": false,
            "strength": 0,
            "type": "SWALLOWING_CAT"
        }, {
            "description": "Difficulty in swallowing",
            "nomenclature": "6.11.0",
            "selectable": false,
            "strength": 0,
            "type": "SWALLOWING_DIFFICULTY"
        }, {
            "description": "Choking",
            "nomenclature": "6.11.1",
            "selectable": false,
            "strength": 0,
            "type": "SWALLOWING_CHOKING"
        }, {
            "description": "Abdominal pain",
            "nomenclature": "6.12",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_CAT"
        }, {
            "description": "Colic, intestinal (except infants)",
            "nomenclature": "6.12.1",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_COLIC"
        }, {
            "description": "Pain in Epigastrium",
            "nomenclature": "6.12.2",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_EPIGASTRIUM"
        }, {
            "description": "Pain in Iliac",
            "nomenclature": "6.12.3",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_ILIAC"
        }, {
            "description": "Pain in Inguinal (groin)",
            "nomenclature": "6.12.4",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_INGUINAL"
        }, {
            "description": "Pain in Right/Left, Upper/Lower quadrant",
            "nomenclature": "6.12.5",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_QUADRANT"
        }, {
            "description": "Pain in Stomach (includes cramps)",
            "nomenclature": "6.12.6",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_STOMACH"
        }, {
            "description": "Pain in Umbilical region",
            "nomenclature": "6.12.7",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_UMBILICAL"
        }, {
            "description": "Colic, infantile",
            "nomenclature": "6.13",
            "selectable": false,
            "strength": 0,
            "type": "COLIC_INFANTILE"
        }, {
            "description": "Abdominal swelling or mass",
            "nomenclature": "6.14",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_SWELLING_MASS_CAT"
        }, {
            "description": "Abdominal distension",
            "nomenclature": "6.14.0",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_SWELLING_MASS_DISTENTION"
        }, {
            "description": "Abdominal fullness",
            "nomenclature": "6.14.1",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_SWELLING_MASS_FULLNESS"
        }, {
            "description": "Flatulence",
            "nomenclature": "6.15",
            "selectable": false,
            "strength": 0,
            "type": "FLATUENCE_CAT"
        }, {
            "description": "Bloating, gas",
            "nomenclature": "6.15.1",
            "selectable": false,
            "strength": 0,
            "type": "FLATUENCE_BLOATING_GAS"
        }, {
            "description": "Distension due to gas",
            "nomenclature": "6.15.2",
            "selectable": false,
            "strength": 0,
            "type": "FLATUENCE_DISTENSION"
        }, {
            "description": "Gas, excessive",
            "nomenclature": "6.15.3",
            "selectable": false,
            "strength": 0,
            "type": "FLATUENCE_GAS_EXCESSIVE"
        }, {
            "description": "Appetite, abnormal",
            "nomenclature": "6.16",
            "selectable": false,
            "strength": 0,
            "type": "APPETITE_CAT"
        }, {
            "description": "Decreased appetite",
            "nomenclature": "6.16.1",
            "selectable": false,
            "strength": 0,
            "type": "APPETITE_DECREASE"
        }, {
            "description": "Excessive appetite",
            "nomenclature": "6.16.2",
            "selectable": false,
            "strength": 0,
            "type": "APPETITE_EXCESSIVE"
        }, {
            "description": "Loss of appetite",
            "nomenclature": "6.16.3",
            "selectable": false,
            "strength": 0,
            "type": "APPETITE_LOSS"
        }, {
            "description": "Feeding problems",
            "nomenclature": "6.17",
            "selectable": false,
            "strength": 0,
            "type": "FEEDING_PROBLEMS"
        }, {
            "description": "Bleeding, gastrointestinal",
            "nomenclature": "6.18",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_CAT"
        }, {
            "description": "Blood in stools (melena)",
            "nomenclature": "6.18.1",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_STOOLS"
        }, {
            "description": "Hematemesis",
            "nomenclature": "6.18.2",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_HEMATEMESIS"
        }, {
            "description": "Hemorrhage, cause unknown",
            "nomenclature": "6.18.3",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_HEMORRHAGE"
        }, {
            "description": "Vomiting blood",
            "nomenclature": "6.18.4",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_BLOOD_VOMITING"
        }, {
            "description": "Constipation",
            "nomenclature": "6.19",
            "selectable": false,
            "strength": 0,
            "type": "CONSTIPATION"
        }, {
            "description": "Diarrhea",
            "nomenclature": "6.20",
            "selectable": false,
            "strength": 0,
            "type": "DIARRHEA_CAT"
        }, {
            "description": "Diarrhea",
            "nomenclature": "6.20.0",
            "selectable": false,
            "strength": 0,
            "type": "DIARRHEA"
        }, {
            "description": "Loose stools",
            "nomenclature": "6.20.1",
            "selectable": false,
            "strength": 0,
            "type": "DIARRHEA_LOOSE_STOOLS"
        }, {
            "description": "Other symptoms or changes in bowel function",
            "nomenclature": "6.21",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_CAT"
        }, {
            "description": "Bulky stools",
            "nomenclature": "6.21.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_BULKY_STOOLS"
        }, {
            "description": "Dark stools",
            "nomenclature": "6.21.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_DARK_STOOLS"
        }, {
            "description": "Fatty stools",
            "nomenclature": "6.21.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_FATTY_STOOLS"
        }, {
            "description": "Mucous stools",
            "nomenclature": "6.21.4",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_MUCOUS_STOOLS"
        }, {
            "description": "Pus stools",
            "nomenclature": "6.21.5",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_PUS_STOOLS"
        }, {
            "description": "Unusual color",
            "nomenclature": "6.21.6",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_UNUSUAL_COLOR"
        }, {
            "description": "Unusual odor",
            "nomenclature": "6.21.7",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_UNUSUAL_ODOR"
        }, {
            "description": "Symptoms referable to anus-rectum",
            "nomenclature": "6.22",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_CAT"
        }, {
            "description": "Rectal bleeding",
            "nomenclature": "6.22.1",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_BLEEDING"
        }, {
            "description": "Anal itching",
            "nomenclature": "6.22.2",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_ITCHING"
        }, {
            "description": "Anal mass",
            "nomenclature": "6.22.3",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_MASS"
        }, {
            "description": "Rectal pain",
            "nomenclature": "6.22.4",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_RECTAL_PAIN"
        }, {
            "description": "Anal swelling",
            "nomenclature": "6.22.5",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_SWELLING"
        }, {
            "description": "Heartburn or upset stomach",
            "nomenclature": "6.23",
            "selectable": false,
            "strength": 0,
            "type": "UPSET_STOMACH_CAT"
        }, {
            "description": "Belching",
            "nomenclature": "6.23.1",
            "selectable": false,
            "strength": 0,
            "type": "UPSET_STOMACH_BELCHING"
        }, {
            "description": "Indigestion",
            "nomenclature": "6.23.2",
            "selectable": false,
            "strength": 0,
            "type": "UPSET_STOMACH_INDIGESTION"
        }, {
            "description": "Nausea and vomiting",
            "nomenclature": "6.24",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA_AND_VOMITING_CAT"
        }, {
            "description": "Nausea",
            "nomenclature": "6.24.0",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA"
        }, {
            "description": "Retching",
            "nomenclature": "6.24.1",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA_AND_VOMITING_RETCHING"
        }, {
            "description": "Sick to stomach",
            "nomenclature": "6.24.2",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA_AND_VOMITING_SICK_TO_STOMACH"
        }, {
            "description": "Vomiting",
            "nomenclature": "6.24.3",
            "selectable": false,
            "strength": 0,
            "type": "VOMITING"
        }, {
            "description": "Throwing up",
            "nomenclature": "6.24.4",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA_AND_VOMITING_THROWING_UP"
        }, {
            "description": "Regurgitation or spitting-up",
            "nomenclature": "6.25",
            "selectable": false,
            "strength": 0,
            "type": "REGURGITATION_OR_SPITTING_UP"
        }, {
            "description": "Hiccough",
            "nomenclature": "6.26",
            "selectable": false,
            "strength": 0,
            "type": "HICCOUGH"
        }, {
            "description": "Jaundice",
            "nomenclature": "6.27",
            "selectable": false,
            "strength": 0,
            "type": "JAUNDICE"
        }, {
            "description": "Liver and gallbladder",
            "nomenclature": "6.28",
            "selectable": false,
            "strength": 0,
            "type": "LIVER_GALLBLADDER_CAT"
        }, {
            "description": "Biliary colic",
            "nomenclature": "6.28.1",
            "selectable": false,
            "strength": 0,
            "type": "LIVER_GALLBLADDER_BILIARY_COLIC"
        }, {
            "description": "Gallstones",
            "nomenclature": "6.28.2",
            "selectable": false,
            "strength": 0,
            "type": "LIVER_GALLBLADDER_GALLSTONES"
        }, {
            "description": "Urinary Tract",
            "nomenclature": "7",
            "selectable": false,
            "strength": 0,
            "type": "URINARY_TRACT"
        }, {
            "description": "Urine abnormalities",
            "nomenclature": "7.1",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_CAT"
        }, {
            "description": "Blood in urine",
            "nomenclature": "7.1.1",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_BLOOD"
        }, {
            "description": "Pus in urine",
            "nomenclature": "7.1.2",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_PUS"
        }, {
            "description": "Unusual urine color",
            "nomenclature": "7.1.3",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_UNUSUAL_COLOR"
        }, {
            "description": "Unusual urine odor",
            "nomenclature": "7.1.4",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_UNUSUAL_ODOR"
        }, {
            "description": "Frequency and nocturia",
            "nomenclature": "7.2",
            "selectable": false,
            "strength": 0,
            "type": "FREQUENCY_AND_NOCTURIA_CAT"
        }, {
            "description": "Bed wetting",
            "nomenclature": "7.2.1",
            "selectable": false,
            "strength": 0,
            "type": "FREQUENCY_AND_NOCTURIA_BED_WETTING"
        }, {
            "description": "Night discharge",
            "nomenclature": "7.2.2",
            "selectable": false,
            "strength": 0,
            "type": "FREQUENCY_AND_NOCTURIA_NIGHT_DISCHARGE"
        }, {
            "description": "Incontinence of urine",
            "nomenclature": "7.3",
            "selectable": false,
            "strength": 0,
            "type": "INCONTINENCE_OF_URINE_CAT"
        }, {
            "description": "Urinary dribbling",
            "nomenclature": "7.3.1",
            "selectable": false,
            "strength": 0,
            "type": "INCONTINENCE_OF_URINE_DRIBBLING"
        }, {
            "description": "Involuntary urination",
            "nomenclature": "7.3.2",
            "selectable": false,
            "strength": 0,
            "type": "INCONTINENCE_OF_URINE_INVOLUNTARY_URINATION"
        }, {
            "description": "Retention of urine",
            "nomenclature": "7.4",
            "selectable": false,
            "strength": 0,
            "type": "RETENTION_OF_URINE_CAT"
        }, {
            "description": "Cannot empty bladder",
            "nomenclature": "7.4.1",
            "selectable": false,
            "strength": 0,
            "type": "RETENTION_OF_URINE_CANNOT_EMPTY_BLADDER"
        }, {
            "description": "Inability to urinate",
            "nomenclature": "7.4.2",
            "selectable": false,
            "strength": 0,
            "type": "RETENTION_OF_URINE_INABILITY_TO_URINATE"
        }, {
            "description": "Painful urination",
            "nomenclature": "7.5",
            "selectable": false,
            "strength": 0,
            "type": "PAINFUL_URINATION_CAT"
        }, {
            "description": "Painful urination",
            "nomenclature": "7.5.0",
            "selectable": false,
            "strength": 0,
            "type": "PAINFUL_URINATION"
        }, {
            "description": "Burning",
            "nomenclature": "7.5.1",
            "selectable": false,
            "strength": 0,
            "type": "PAINFUL_URINATION_BURNING"
        }, {
            "description": "Other urinary tract symptoms",
            "nomenclature": "7.6",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_URINARY_SYMPTOMS_CAT"
        }, {
            "description": "Bladder trouble",
            "nomenclature": "7.6.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_URINARY_SYMPTOMS_BLADDER_TROUBLE"
        }, {
            "description": "Passed kidney stones",
            "nomenclature": "7.6.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_URINARY_SYMPTOMS_PASSED_STONES"
        }, {
            "description": "Male Reproductive System",
            "nomenclature": "8",
            "selectable": false,
            "strength": 0,
            "type": "MALE_REPRODUCTIVE_SYSTEM_CAT"
        }, {
            "description": "Infertility – Male",
            "nomenclature": "8.1",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_MALE_CAT"
        }, {
            "description": "Low sperm count",
            "nomenclature": "8.1.1",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_MALE_LOW_SPERM_COUNT"
        }, {
            "description": "Sterility",
            "nomenclature": "8.1.2",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_MALE_STERILITY"
        }, {
            "description": "Pain, swelling, or mass of male genital system",
            "nomenclature": "8.2",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_CAT"
        }, {
            "description": "Pain in penis",
            "nomenclature": "8.2.1",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_PAIN_PENIS"
        }, {
            "description": "Pain in scrotum",
            "nomenclature": "8.2.2",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_PAIN_SCROTUM"
        }, {
            "description": "Pain in testes",
            "nomenclature": "8.2.3",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_PAIN_TESTES"
        }, {
            "description": "Swelling, or mass in penis",
            "nomenclature": "8.2.4",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_SWELLING_PENIS"
        }, {
            "description": "Swelling, or mass in scrotum",
            "nomenclature": "8.2.5",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_SWELLING_SCROTUM"
        }, {
            "description": "Swelling, or mass in testes",
            "nomenclature": "8.2.6",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_SWELLING_TESTES"
        }, {
            "description": "Other male reproductive system symptoms",
            "nomenclature": "8.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_MALE_REPRODUCTIVE_SYSTEM_CAT"
        }, {
            "description": "Psychosexual problems",
            "nomenclature": "8.3.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_MALE_REPRODUCTIVE_SYSTEM_PSYCHOSEXUAL_PROBLEMS"
        }, {
            "description": "Female Reproductive System",
            "nomenclature": "9",
            "selectable": false,
            "strength": 0,
            "type": "FEMALE_REPRODUCTIVE_PROBLEMS_CAT"
        }, {
            "description": "Menopause symptoms",
            "nomenclature": "9.1",
            "selectable": false,
            "strength": 0,
            "type": "MENOPAUSE_CAT"
        }, {
            "description": "Hot flashes",
            "nomenclature": "9.1.1",
            "selectable": false,
            "strength": 0,
            "type": "MENOPAUSE_HOT_FLASHES"
        }, {
            "description": "Premenstrual tension",
            "nomenclature": "9.2",
            "selectable": false,
            "strength": 0,
            "type": "PREMENSTRUAL_TENSION"
        }, {
            "description": "Menstrual cramps",
            "nomenclature": "9.3",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_CRAMPS"
        }, {
            "description": "Menstrual disorders",
            "nomenclature": "9.4",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_CAT"
        }, {
            "description": "Menstruation absence (amenorrhea)",
            "nomenclature": "9.4.1",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_ABSENCE_AMENORRHEA"
        }, {
            "description": "Atypical menstrual flow material",
            "nomenclature": "9.4.2",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_ATYPICAL_MATERIAL"
        }, {
            "description": "Menstrual blood clots",
            "nomenclature": "9.4.3",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_BLOOD_CLOTS"
        }, {
            "description": "Excessive menstrual flow",
            "nomenclature": "9.4.4",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_EXCESSIVE"
        }, {
            "description": "Frequent menstruation",
            "nomenclature": "9.4.5",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_FREQUENT"
        }, {
            "description": "Infrequent menstruation",
            "nomenclature": "9.4.6",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_INFREQUENT"
        }, {
            "description": "Irregular menstruation (metrorrhagia)",
            "nomenclature": "9.4.7",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_IRREGULAR_METRORRHAGIA"
        }, {
            "description": "Large menstrual flow",
            "nomenclature": "9.4.8",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_LARGE_FLOW"
        }, {
            "description": "Menstruation onset delayed",
            "nomenclature": "9.4.9",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_ONSET_DELAYED"
        }, {
            "description": "Scanty menstrual flow",
            "nomenclature": "9.4.10",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_SCANTY"
        }, {
            "description": "Small menstrual flow",
            "nomenclature": "9.4.11",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_SMALL_FLOW"
        }, {
            "description": "Ovulation pain",
            "nomenclature": "9.5",
            "selectable": false,
            "strength": 0,
            "type": "OVULATION_PAIN"
        }, {
            "description": "Pelvic symptoms",
            "nomenclature": "9.6",
            "selectable": false,
            "strength": 0,
            "type": "PELVIC_CAT"
        }, {
            "description": "Pelvic pain",
            "nomenclature": "9.6.1",
            "selectable": false,
            "strength": 0,
            "type": "PELVIC_PAIN"
        }, {
            "description": "Pelvic pressure or dropping sensation",
            "nomenclature": "9.6.2",
            "selectable": false,
            "strength": 0,
            "type": "PELVIC_PRESSURE"
        }, {
            "description": "Pelvic swelling or mass",
            "nomenclature": "9.6.3",
            "selectable": false,
            "strength": 0,
            "type": "PELVIC_SWELLING_OR_MASS"
        }, {
            "description": "Vaginal disorders",
            "nomenclature": "9.7",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISORDERS_CAT"
        }, {
            "description": "Vaginal pain",
            "nomenclature": "9.7.1",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISORDERS_PAIN"
        }, {
            "description": "Vaginal swelling or mass",
            "nomenclature": "9.7.2",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISORDERS_SWELLING_OR_MASS"
        }, {
            "description": "Vaginal discharge",
            "nomenclature": "9.8",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISCHARGE_CAT"
        }, {
            "description": "Atypical vaginal discharge (Ieukorrhea)",
            "nomenclature": "9.8.1",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISCHARGE_ATYPICAL"
        }, {
            "description": "Bloody vaginal discharge",
            "nomenclature": "9.8.2",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISCHARGE_BLOODY"
        }, {
            "description": "Brown vaginal discharge",
            "nomenclature": "9.8.3",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISCHARGE_BROWN"
        }, {
            "description": "Vulvar disorders",
            "nomenclature": "9.9",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_CAT"
        }, {
            "description": "Vulvar itching",
            "nomenclature": "9.9.1",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_ITCHING"
        }, {
            "description": "Vulvar pain",
            "nomenclature": "9.9.2",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_PAIN"
        }, {
            "description": "Perineil swelling or mass",
            "nomenclature": "9.9.3",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_SWELLING_OR_MASS"
        }, {
            "description": "Vulvar ulcer",
            "nomenclature": "9.9.4",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_ULCER"
        }, {
            "description": "Infertility – Female",
            "nomenclature": "9.10",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_FEMALE_CAT"
        }, {
            "description": "Sterility",
            "nomenclature": "9.10.1",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_FEMALE_STERILITY"
        }, {
            "description": "Problems of pregnancy",
            "nomenclature": "9.11",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_CAT"
        }, {
            "description": "Leaking amniotic fluid",
            "nomenclature": "9.11.1",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_LEAKING_AMNIOTIC_FLUID"
        }, {
            "description": "Possible labor",
            "nomenclature": "9.11.2",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_POSSIBLE_LABOR"
        }, {
            "description": "Products of conception passed",
            "nomenclature": "9.11.3",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_CONCEPTION_PRODUCT_PASSED"
        }, {
            "description": "Spotting (pregnancy)",
            "nomenclature": "9.11.4",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_SPOTTING"
        }, {
            "description": "Other female reproductive system symptoms",
            "nomenclature": "9.12",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_FEMALE_REPRODUCTIVE_SYSTEM_SYMPTOMS"
        }, {
            "description": "Lump or mass of breast",
            "nomenclature": "9.14",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_CAT"
        }, {
            "description": "Breast bump",
            "nomenclature": "9.14.1",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_BUMP"
        }, {
            "description": "Breast hard spot",
            "nomenclature": "9.14.2",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_HARD_SPOT"
        }, {
            "description": "Breast knot",
            "nomenclature": "9.14.3",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_KNOT"
        }, {
            "description": "Local breast swelling",
            "nomenclature": "9.14.4",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_LOCAL_SWELLING"
        }, {
            "description": "Breast nodule",
            "nomenclature": "9.14.5",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_NODULE"
        }, {
            "description": "Pain or soreness of breast",
            "nomenclature": "9.15",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_PAIN_CAT"
        }, {
            "description": "Breast redness",
            "nomenclature": "9.15.1",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_PAIN_REDNESS"
        }, {
            "description": "Breast swelling, generalized",
            "nomenclature": "9.15.2",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_PAIN_SWELLING_GENERALIZED"
        }, {
            "description": "Breast tenderness",
            "nomenclature": "9.15.3",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_PAIN_TENDERNESS"
        }, {
            "description": "Symptoms of nipple",
            "nomenclature": "9.16",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_CAT"
        }, {
            "description": "Nipple bleeding",
            "nomenclature": "9.16.1",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_BLEEDING"
        }, {
            "description": "Nipple change in color",
            "nomenclature": "9.16.2",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_CHANGE_IN_COLOR"
        }, {
            "description": "Nipple discharge",
            "nomenclature": "9.16.3",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_DISCHARGE"
        }, {
            "description": "Nipple inflammation",
            "nomenclature": "9.16.4",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_INFLAMMATION"
        }, {
            "description": "Nipple inversion",
            "nomenclature": "9.16.5",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_INVERSION"
        }, {
            "description": "Postpartum problems of breast",
            "nomenclature": "9.17",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_CAT"
        }, {
            "description": "Abnormal breast secretion",
            "nomenclature": "9.17.1",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_ABNORMAL_SECRETION"
        }, {
            "description": "Absence of milk",
            "nomenclature": "9.17.2",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_ABSENCE_OF_MILK"
        }, {
            "description": "Difficulty or inability in nursing",
            "nomenclature": "9.17.3",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_NURSING"
        }, {
            "description": "Breast engorgement",
            "nomenclature": "9.17.4",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_ENGORGEMENT"
        }, {
            "description": "Excessive milk",
            "nomenclature": "9.17.5",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_EXCESSIVE_MILK"
        }, {
            "description": "Improper lactation",
            "nomenclature": "9.17.6",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_IMPROPER_LACTATION"
        }, {
            "description": "Other breast symptoms",
            "nomenclature": "9.18",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BREAST_SYMPTOMS_CAT"
        }, {
            "description": "Breast sagging",
            "nomenclature": "9.18.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BREAST_SYMPTOMS_SAGGING"
        }, {
            "description": "Too large breasts",
            "nomenclature": "9.18.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BREAST_SYMPTOMS_TOO_LARGE"
        }, {
            "description": "Too small breasts",
            "nomenclature": "9.18.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BREAST_SYMPTOMS_TOO_SMALL"
        }, {
            "description": "Eyes and Ears",
            "nomenclature": "10",
            "selectable": false,
            "strength": 0,
            "type": "EYES_AND_EARS_CAT"
        }, {
            "description": "Complete blindness",
            "nomenclature": "10.1",
            "selectable": false,
            "strength": 0,
            "type": "BLINDNESS_COMPLETE"
        }, {
            "description": "Other vision disfunction",
            "nomenclature": "10.2",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_CAT"
        }, {
            "description": "Blurred vision",
            "nomenclature": "10.2.1",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_BLURRED"
        }, {
            "description": "Cloudy vision",
            "nomenclature": "10.2.2",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_CLOUDY_VISION"
        }, {
            "description": "Diminished vision",
            "nomenclature": "10.2.3",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_DIMINISHED"
        }, {
            "description": "Dull vision",
            "nomenclature": "10.2.4",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_DULL_VISION"
        }, {
            "description": "Eye floaters",
            "nomenclature": "10.2.5",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_FLOATERS"
        }, {
            "description": "Half vision",
            "nomenclature": "10.2.6",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_HALF_VISION"
        }, {
            "description": "Hazy vision",
            "nomenclature": "10.2.7",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_HAZY_VISION"
        }, {
            "description": "Photophobia",
            "nomenclature": "10.2.8",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_PHOTOPHOBIA"
        }, {
            "description": "Eye spots",
            "nomenclature": "10.2.9",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_SPOTS"
        }, {
            "description": "Discharge from eye",
            "nomenclature": "10.3",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_CAT"
        }, {
            "description": "Blood eye discharge",
            "nomenclature": "10.3.1",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_BLOOD"
        }, {
            "description": "Excessive tearing from eye",
            "nomenclature": "10.3.2",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_EXCESSIVE_TEARING"
        }, {
            "description": "Pus from eye",
            "nomenclature": "10.3.3",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_PUS"
        }, {
            "description": "Watering eye",
            "nomenclature": "10.3.4",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_WATERING"
        }, {
            "description": "Eye pain and irritation",
            "nomenclature": "10.4",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_CAT"
        }, {
            "description": "Burning eye",
            "nomenclature": "10.4.1",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_BURNING"
        }, {
            "description": "Eye inflamation",
            "nomenclature": "10.4.2",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_INFLAMED"
        }, {
            "description": "Eye irritation",
            "nomenclature": "10.4.3",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_IRRITATION"
        }, {
            "description": "Itchy eye",
            "nomenclature": "10.4.4",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_ITCHING"
        }, {
            "description": "Eye swelling or mass",
            "nomenclature": "10.4.5",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_SWELLING_OR_MASS"
        }, {
            "description": "Abnormal eye movements",
            "nomenclature": "10.5",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_CAT"
        }, {
            "description": "Abnormal eye retraction",
            "nomenclature": "10.5.1",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_RETRACTION"
        }, {
            "description": "Cross-eyed",
            "nomenclature": "10.5.2",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_CROSS_EYED"
        }, {
            "description": "Pupil unequal",
            "nomenclature": "10.5.3",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_UNEQUAL_PUPIL"
        }, {
            "description": "Eye spasms",
            "nomenclature": "10.5.4",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_SPASMS"
        }, {
            "description": "Eye squinting",
            "nomenclature": "10.5.5",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_SQUINTING"
        }, {
            "description": "Eye twitching",
            "nomenclature": "10.5.6",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_TWITCHING"
        }, {
            "description": "Symptoms of eyelids",
            "nomenclature": "10.6",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_CAT"
        }, {
            "description": "Drooping eyelid",
            "nomenclature": "10.6.1",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_DROOPING"
        }, {
            "description": "Eyelid inflammation",
            "nomenclature": "10.6.2",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_INFLAMMATION"
        }, {
            "description": "Itchy eyelid",
            "nomenclature": "10.6.3",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_ITCHING"
        }, {
            "description": "Eyelid swelling or mass",
            "nomenclature": "10.6.4",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_SWELLING_OR_MASS"
        }, {
            "description": "Sty",
            "nomenclature": "10.7",
            "selectable": false,
            "strength": 0,
            "type": "STY"
        }, {
            "description": "Pink-eye",
            "nomenclature": "10.8",
            "selectable": false,
            "strength": 0,
            "type": "PINK_EYE_CAT"
        }, {
            "description": "Pink-eye",
            "nomenclature": "10.8.0",
            "selectable": false,
            "strength": 0,
            "type": "PINK_EYE"
        }, {
            "description": "Conjunctivitis",
            "nomenclature": "10.8.1",
            "selectable": false,
            "strength": 0,
            "type": "PINK_EYE_CONJUNCTIVITIS"
        }, {
            "description": "Foreign body in eye",
            "nomenclature": "10.9",
            "selectable": false,
            "strength": 0,
            "type": "FOREIGN_BODY_IN_EYES"
        }, {
            "description": "Eye injuries",
            "nomenclature": "10.10",
            "selectable": false,
            "strength": 0,
            "type": "EYE_INJURIES_CAT"
        }, {
            "description": "Black eye",
            "nomenclature": "10.10.1",
            "selectable": false,
            "strength": 0,
            "type": "EYE_INJURIES_BLACK_EYE"
        }, {
            "description": "Eye burns",
            "nomenclature": "10.10.2",
            "selectable": false,
            "strength": 0,
            "type": "EYE_INJURIES_BURNS"
        }, {
            "description": "Scratches",
            "nomenclature": "10.10.3",
            "selectable": false,
            "strength": 0,
            "type": "EYE_INJURIES_SCRATCHES"
        }, {
            "description": "Abnormal appearance of eyes",
            "nomenclature": "10.11",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_CAT"
        }, {
            "description": "Abnormal eye protrusion",
            "nomenclature": "10.11.1",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_PROTRUSION"
        }, {
            "description": "Bloodshot eyes",
            "nomenclature": "10.11.2",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_BLOODSHOT"
        }, {
            "description": "Cloudy eyes",
            "nomenclature": "10.11.3",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_CLOUDY"
        }, {
            "description": "Dull eyes",
            "nomenclature": "10.11.4",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_DULL"
        }, {
            "description": "Hazy eyes",
            "nomenclature": "10.11.5",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_HAZY"
        }, {
            "description": "Deafness",
            "nomenclature": "10.13",
            "selectable": false,
            "strength": 0,
            "type": "DEAFNESS"
        }, {
            "description": "Other hearing dysfunctions",
            "nomenclature": "10.14",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_CAT"
        }, {
            "description": "Acute hearing",
            "nomenclature": "10.14.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_ACUTE_HEARING"
        }, {
            "description": "Diminished hearing",
            "nomenclature": "10.14.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_DIMINISHED_HEARING"
        }, {
            "description": "Extraneous noises in ears",
            "nomenclature": "10.14.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_NOICES"
        }, {
            "description": "Ringing in ears",
            "nomenclature": "10.14.4",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_RINGING_IN_EARS"
        }, {
            "description": "Trouble hearing",
            "nomenclature": "10.14.5",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_TROUBLE_HEARING"
        }, {
            "description": "Discharge from ear",
            "nomenclature": "10.15",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EAR_CAT"
        }, {
            "description": "Pus from ear",
            "nomenclature": "10.15.0",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EAR_PUS"
        }, {
            "description": "Ear bleeding",
            "nomenclature": "10.15.1",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EAR_BLEEDING"
        }, {
            "description": "Earache",
            "nomenclature": "10.16",
            "selectable": false,
            "strength": 0,
            "type": "EARACHE_CAT"
        }, {
            "description": "Pain in ear",
            "nomenclature": "10.16.1",
            "selectable": false,
            "strength": 0,
            "type": "EARACHE"
        }, {
            "description": "Plugged feeling in ear",
            "nomenclature": "10.17",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_CAT"
        }, {
            "description": "Blocked ears",
            "nomenclature": "10.17.1",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_BLOCKED"
        }, {
            "description": "Cracking ears",
            "nomenclature": "10.17.2",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_CRACKING"
        }, {
            "description": "Popping ears",
            "nomenclature": "10.17.3",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_POPPING"
        }, {
            "description": "Stopped up ears",
            "nomenclature": "10.17.4",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_STOPPED_UP"
        }, {
            "description": "Excess wax in ear",
            "nomenclature": "10.18",
            "selectable": false,
            "strength": 0,
            "type": "EXCESS_WAX_IN_EAR"
        }, {
            "description": "Abnormal ear size",
            "nomenclature": "10.19",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EAR_SIZE"
        }, {
            "description": "Other symptoms referable to the ears",
            "nomenclature": "10.20",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_EAR_SYMPTOMS_CAT"
        }, {
            "description": "Foreign body in ear",
            "nomenclature": "10.20.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_EAR_SYMPTOMS_FOREIGN_BODY"
        }, {
            "description": "Itchy ear",
            "nomenclature": "10.20.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_EAR_SYMPTOMS_ITCHING"
        }, {
            "description": "Ear swelling or mass",
            "nomenclature": "10.20.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_EAR_SYMPTOMS_SWELLING_OR_MASS"
        }, {
            "description": "Mental Health",
            "nomenclature": "11",
            "selectable": false,
            "strength": 0,
            "type": "MENTAL_HEALTH_CAT"
        }, {
            "description": "Anxiety",
            "nomenclature": "11.1",
            "selectable": false,
            "strength": 0,
            "type": "ANXIETY_CAT"
        }, {
            "description": "Apprehension",
            "nomenclature": "11.1.1",
            "selectable": false,
            "strength": 0,
            "type": "ANXIETY_APPREHENSION"
        }, {
            "description": "Fears and phobias",
            "nomenclature": "11.3",
            "selectable": false,
            "strength": 0,
            "type": "FEARS_AND_PHOBIAS_CAT"
        }, {
            "description": "Restlessness",
            "nomenclature": "11.3.0",
            "selectable": false,
            "strength": 0,
            "type": "FEARS_AND_PHOBIAS_RESTLESSNESS"
        }, {
            "description": "Hyperactivity",
            "nomenclature": "11.3.1",
            "selectable": false,
            "strength": 0,
            "type": "FEARS_AND_PHOBIAS_HYPERACTIVITY"
        }, {
            "description": "Overactivity",
            "nomenclature": "11.3.2",
            "selectable": false,
            "strength": 0,
            "type": "FEARS_AND_PHOBIAS_OVERACTIVITY"
        }, {
            "description": "Loneliness",
            "nomenclature": "11.4",
            "selectable": false,
            "strength": 0,
            "type": "LONELINESS"
        }, {
            "description": "Depression",
            "nomenclature": "11.5",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_CAT"
        }, {
            "description": "Deppression, Bitterness",
            "nomenclature": "11.5.1",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_BITTERNESS"
        }, {
            "description": "Deppression, Crying excessively",
            "nomenclature": "11.5.2",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_CRYING_EXCESSIVELY"
        }, {
            "description": "Deppression, Dejected",
            "nomenclature": "11.5.3",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_DEJECTED"
        }, {
            "description": "Deppression, Discontented",
            "nomenclature": "11.5.4",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_DISCONTENTED"
        }, {
            "description": "Deppression, Feeling lost",
            "nomenclature": "11.5.5",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_FEELING_LOST"
        }, {
            "description": "Deppression, Feeling low",
            "nomenclature": "11.5.6",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_FEELING_LOW"
        }, {
            "description": "Deppression, Feeling rejected",
            "nomenclature": "11.5.7",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_FEELING_REJECTED"
        }, {
            "description": "Deppression, Hopelessness",
            "nomenclature": "11.5.8",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_HOPELESSNESS"
        }, {
            "description": "Deppression, Unhappy",
            "nomenclature": "11.5.9",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_UNHAPPY"
        }, {
            "description": "Deppression, Worrying",
            "nomenclature": "11.5.10",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_WORRYING"
        }, {
            "description": "Nervousness",
            "nomenclature": "11.6",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_CAT"
        }, {
            "description": "Butterflies",
            "nomenclature": "11.6.1",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_BUTTERFLIES"
        }, {
            "description": "Nerves",
            "nomenclature": "11.6.2",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_NERVES"
        }, {
            "description": "Nervousness, Tension",
            "nomenclature": "11.6.3",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_TENSION"
        }, {
            "description": "Nervousness, Upset",
            "nomenclature": "11.6.4",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_UPSET"
        }, {
            "description": "Behavorial disturbance",
            "nomenclature": "11.7",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_CAT"
        }, {
            "description": "Antisocial behavior",
            "nomenclature": "11.7.1",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_ANTISOCIAL_BEHAVIOR"
        }, {
            "description": "Behavorial problems",
            "nomenclature": "11.7.2",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_BEHAVORIAL_PROBLEMS"
        }, {
            "description": "Irritability",
            "nomenclature": "11.7.3",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_IRRITABILITY"
        }, {
            "description": "Quarrelsome",
            "nomenclature": "11.7.4",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_QUARRELSOME"
        }, {
            "description": "Temper tantrums",
            "nomenclature": "11.7.5",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_TEMPER_TANTRUMS"
        }, {
            "description": "Excessive smoking",
            "nomenclature": "11.8",
            "selectable": false,
            "strength": 0,
            "type": "EXCESSIVE_SMOKING"
        }, {
            "description": "Alcohol related problems",
            "nomenclature": "11.9",
            "selectable": false,
            "strength": 0,
            "type": "ALCOHOL_RELATED_PROBLEMS_CAT"
        }, {
            "description": "Alcoholism",
            "nomenclature": "11.9.1",
            "selectable": false,
            "strength": 0,
            "type": "ALCOHOL_RELATED_PROBLEMS_ALCOHOLISM"
        }, {
            "description": "Drinks too much",
            "nomenclature": "11.9.2",
            "selectable": false,
            "strength": 0,
            "type": "ALCOHOL_RELATED_PROBLEMS_DRINKS_TOO_MUCH"
        }, {
            "description": "Abnormal drug usage",
            "nomenclature": "11.10",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_DRUG_USAGE_CAT"
        }, {
            "description": "Excessive use of stimulants or depressants",
            "nomenclature": "11.10.1",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_DRUG_USAGE_STIMULANTS_DEPRESSANTS"
        }, {
            "description": "Misuse of medications or drugs",
            "nomenclature": "11.10.2",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_DRUG_USAGE_MISUSE"
        }, {
            "description": "Delusions or hallucinations",
            "nomenclature": "11.11",
            "selectable": false,
            "strength": 0,
            "type": "DELUSIONS_OR_HALLUCINATIONS"
        }, {
            "description": "Bad habits",
            "nomenclature": "11.12",
            "selectable": false,
            "strength": 0,
            "type": "BAD_HABITS_CAT"
        }, {
            "description": "Chewing on hair",
            "nomenclature": "11.12.1",
            "selectable": false,
            "strength": 0,
            "type": "BAD_HABITS_CHEWING_ON_HAIR"
        }, {
            "description": "Nail biting",
            "nomenclature": "11.12.2",
            "selectable": false,
            "strength": 0,
            "type": "BAD_HABITS_NAIL_BITING"
        }, {
            "description": "Thumb sucking",
            "nomenclature": "11.12.3",
            "selectable": false,
            "strength": 0,
            "type": "BAD_HABITS_THUMB_SUCKING"
        }, {
            "description": "Obsessions or compulsions",
            "nomenclature": "11.13",
            "selectable": false,
            "strength": 0,
            "type": "OBSESSIONS_OR_COMPULSIONS"
        }, {
            "description": "Psychosexual disorders",
            "nomenclature": "11.14",
            "selectable": false,
            "strength": 0,
            "type": "PSYCHOSEXUAL_DISORDERS_CAT"
        }, {
            "description": "Frigidity",
            "nomenclature": "11.14.1",
            "selectable": false,
            "strength": 0,
            "type": "PSYCHOSEXUAL_DISORDERS_FRIGIDITY"
        }, {
            "description": "Impotence",
            "nomenclature": "11.14.2",
            "selectable": false,
            "strength": 0,
            "type": "PSYCHOSEXUAL_DISORDERS_IMPOTENCE"
        }],
        "Medrec-M": [{
            "description": "General Symptoms",
            "nomenclature": "0",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SYMPTOMS_CAT"
        }, {
            "description": "Chills",
            "nomenclature": "0.1",
            "selectable": false,
            "strength": 0,
            "type": "CHILLS"
        }, {
            "description": "Fever",
            "nomenclature": "0.2",
            "selectable": false,
            "strength": 0,
            "type": "FEVER_CAT"
        }, {
            "description": "Fever",
            "nomenclature": "0.2.1",
            "selectable": false,
            "strength": 0,
            "type": "FEVER"
        }, {
            "description": "High temperature",
            "nomenclature": "0.2.2",
            "selectable": false,
            "strength": 0,
            "type": "FEVER_HIGH_TEMPERATURE"
        }, {
            "description": "Fatigue",
            "nomenclature": "0.3",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_CAT"
        }, {
            "description": "Fatigue",
            "nomenclature": "0.3.0",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE"
        }, {
            "description": "Exhausted",
            "nomenclature": "0.3.1",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_EXHAUSTED"
        }, {
            "description": "General weakness",
            "nomenclature": "0.4.2",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_GENERAL_WEAKNESS"
        }, {
            "description": "Pooped",
            "nomenclature": "0.3.3",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_POOPED"
        }, {
            "description": "Rundown",
            "nomenclature": "0.3.4",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_RUNDOWN"
        }, {
            "description": "Tired",
            "nomenclature": "0.3.5",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_TIRED"
        }, {
            "description": "Worn out",
            "nomenclature": "0.3.6",
            "selectable": false,
            "strength": 0,
            "type": "FATIGUE_WORN_OUT"
        }, {
            "description": "General ill-feeling",
            "nomenclature": "0.4",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_FEELING_CAT"
        }, {
            "description": "Not feeling well",
            "nomenclature": "0.4.1",
            "selectable": false,
            "strength": 0,
            "type": "NOT_FEELING_WELL"
        }, {
            "description": "Fluid imbalance",
            "nomenclature": "0.5",
            "selectable": false,
            "strength": 0,
            "type": "FLUID_IMBALANCE_CAT"
        }, {
            "description": "Dehydration",
            "nomenclature": "0.5.1",
            "selectable": false,
            "strength": 0,
            "type": "DEHYDRATION"
        }, {
            "description": "Excessive sweating",
            "nomenclature": "0.5.2",
            "selectable": false,
            "strength": 0,
            "type": "FLUID_IMBALANCE_EXCESSIVE_SWEATING"
        }, {
            "description": "Excessive thirst",
            "nomenclature": "0.5.3",
            "selectable": false,
            "strength": 0,
            "type": "FLUID_IMBALANCE_EXCESSIVE_THIRST"
        }, {
            "description": "Retention of fluid",
            "nomenclature": "0.5.4",
            "selectable": false,
            "strength": 0,
            "type": "FLUID_IMBALANCE_RETENTION_OF_FLUID"
        }, {
            "description": "Lack of physiological development",
            "nomenclature": "0.6",
            "selectable": false,
            "strength": 0,
            "type": "LACK_PHYS_DEVELOPMENT_CAT"
        }, {
            "description": "Lack of growth",
            "nomenclature": "0.6.1",
            "selectable": false,
            "strength": 0,
            "type": "LACK_PHYS_DEVELOPMENT_LACK_GROWTH"
        }, {
            "description": "Weight gain",
            "nomenclature": "0.7",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_GAIN_CAT"
        }, {
            "description": "Overweight",
            "nomenclature": "0.7.1",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_GAIN_OVERWEIGHT"
        }, {
            "description": "Obesity",
            "nomenclature": "0.7.2",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_GAIN_OBESITY"
        }, {
            "description": "Weight loss",
            "nomenclature": "0.8",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_LOSS_CAT"
        }, {
            "description": "Recent weight loss",
            "nomenclature": "0.8.1",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_LOSS_RECENT_WEIGHT_LOSS"
        }, {
            "description": "Underweight",
            "nomenclature": "0.8.2",
            "selectable": false,
            "strength": 0,
            "type": "WEIGHT_LOSS_UNDERWEIGHT"
        }, {
            "description": "Pain, generalized, site unspecified",
            "nomenclature": "0.9",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_CAT"
        }, {
            "description": "Ache",
            "nomenclature": "0.9.1",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_ACHE"
        }, {
            "description": "Aches all over",
            "nomenclature": "0.9.2",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_ACHES_OVER"
        }, {
            "description": "Cramp",
            "nomenclature": "0.9.3",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_CRAMP"
        }, {
            "description": "Hurt",
            "nomenclature": "0.9.4",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_HURT"
        }, {
            "description": "Stiffness",
            "nomenclature": "0.9.5",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_PAIN_STIFFNESS"
        }, {
            "description": "Swelling or mass, site unspecified",
            "nomenclature": "0.10",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_CAT"
        }, {
            "description": "Bulge",
            "nomenclature": "0.10.1",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_BULGE"
        }, {
            "description": "Bump",
            "nomenclature": "0.10.2",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_BUMP"
        }, {
            "description": "Knot",
            "nomenclature": "0.10.3",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_KNOT"
        }, {
            "description": "Lump",
            "nomenclature": "0.10.4",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_SWELLING_LUMP"
        }, {
            "description": "General symptoms of infants and children",
            "nomenclature": "0.11",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_CAT"
        }, {
            "description": "Crying too much",
            "nomenclature": "0.11.1",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_CRYING_TOO_MUCH"
        }, {
            "description": "Fidgety",
            "nomenclature": "0.11.2",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_FIDGETY"
        }, {
            "description": "Fussy",
            "nomenclature": "0.11.3",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_FUSSY"
        }, {
            "description": "Hyperactive",
            "nomenclature": "0.11.4",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_HYPERACTIVE"
        }, {
            "description": "Irritable",
            "nomenclature": "0.11.5",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_IRRITABLE"
        }, {
            "description": "Underactive",
            "nomenclature": "0.11.6",
            "selectable": false,
            "strength": 0,
            "type": "GENERAL_INFANTS_UNDERACTIVE"
        }, {
            "description": "Nervous System",
            "nomenclature": "1",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUS_SYSTEM_CAT"
        }, {
            "description": "Abnormal involuntary movements",
            "nomenclature": "1.1",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_CAT"
        }, {
            "description": "Shaking",
            "nomenclature": "1.1.1",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_SHAKING"
        }, {
            "description": "Tic",
            "nomenclature": "1.1.2",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_TIC"
        }, {
            "description": "Tremor",
            "nomenclature": "1.1.3",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_TREMOR"
        }, {
            "description": "Twitch",
            "nomenclature": "1.1.4",
            "selectable": false,
            "strength": 0,
            "type": "INVOLUTARY_MOVEMENTS_TWITCH"
        }, {
            "description": "Coma and stupor",
            "nomenclature": "1.2",
            "selectable": false,
            "strength": 0,
            "type": "COMA_AND_STUPOR"
        }, {
            "description": "Confusion",
            "nomenclature": "1.3",
            "selectable": false,
            "strength": 0,
            "type": "CONFUSION"
        }, {
            "description": "Convulsions",
            "nomenclature": "1.4",
            "selectable": false,
            "strength": 0,
            "type": "CONVULSIONS_CAT"
        }, {
            "description": "Fits",
            "nomenclature": "1.4.1",
            "selectable": false,
            "strength": 0,
            "type": "CONVULSIONS_FITS"
        }, {
            "description": "Seizures",
            "nomenclature": "1.4.2",
            "selectable": false,
            "strength": 0,
            "type": "CONVULSIONS_SEIZURES"
        }, {
            "description": "Spells",
            "nomenclature": "1.4.3",
            "selectable": false,
            "strength": 0,
            "type": "CONVULSIONS_SPELLS"
        }, {
            "description": "Headache",
            "nomenclature": "1.5",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE_CAT"
        }, {
            "description": "Headache",
            "nomenclature": "1.5.0",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE"
        }, {
            "description": "Migraine",
            "nomenclature": "1.5.1",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE_MIGRAINE"
        }, {
            "description": "Nervous",
            "nomenclature": "1.5.2",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE_NERVOUS"
        }, {
            "description": "Tension",
            "nomenclature": "1.5.3",
            "selectable": false,
            "strength": 0,
            "type": "HEADACHE_TENSION"
        }, {
            "description": "Memory, disturbances of",
            "nomenclature": "1.6",
            "selectable": false,
            "strength": 0,
            "type": "MEMORY_DISTURBANCE_CAT"
        }, {
            "description": "Amnesia",
            "nomenclature": "1.6.1",
            "selectable": false,
            "strength": 0,
            "type": "MEMORY_DISTURBANCE_AMNESIA"
        }, {
            "description": "Lack or loss memory",
            "nomenclature": "1.6.2",
            "selectable": false,
            "strength": 0,
            "type": "MEMORY_DISTURBANCE_LOSS"
        }, {
            "description": "Temporary loss of memory",
            "nomenclature": "1.6.3",
            "selectable": false,
            "strength": 0,
            "type": "MEMORY_DISTURBANCE_TEMPORARY_LOSS"
        }, {
            "description": "Other disturbances of sensation",
            "nomenclature": "1.7",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_CAT"
        }, {
            "description": "Anesthesia",
            "nomenclature": "1.7.1",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_ANESTHESIA"
        }, {
            "description": "Burning",
            "nomenclature": "1.7.2",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_BURNING"
        }, {
            "description": "Hyperesthesia",
            "nomenclature": "1.7.3",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_HYPERESTHESIA"
        }, {
            "description": "Loss of smell",
            "nomenclature": "1.7.4",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_LOSS_OF_SMELL"
        }, {
            "description": "Loss of taste",
            "nomenclature": "1.7.5",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_LOSS_OF_TASTE"
        }, {
            "description": "Loss of touch",
            "nomenclature": "1.7.6",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_LOSS_OF_TOUCH"
        }, {
            "description": "Prickly feeling",
            "nomenclature": "1.7.7",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_PRICKLY_FEELING"
        }, {
            "description": "Tingling",
            "nomenclature": "1.7.8",
            "selectable": false,
            "strength": 0,
            "type": "SENSATION_DISTURBANCES_TINGLING"
        }, {
            "description": "Disturbances of sleep",
            "nomenclature": "1.8",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_CAT"
        }, {
            "description": "Drowsiness",
            "nomenclature": "1.8.1",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_DROWSINESS"
        }, {
            "description": "Hypersomnia",
            "nomenclature": "1.8.2",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_HYPERSOMNIA"
        }, {
            "description": "Insomnia",
            "nomenclature": "1.8.3",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_INSOMNIA"
        }, {
            "description": "Trouble sleeping",
            "nomenclature": "1.8.4",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_TROUBLE_SLEEPING"
        }, {
            "description": "Can’t sleep",
            "nomenclature": "1.8.5",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_CANT_SLEEP"
        }, {
            "description": "Nightmares",
            "nomenclature": "1.8.6",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_NIGHTMARES"
        }, {
            "description": "Sleepiness",
            "nomenclature": "1.8.7",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_SLEEPINESS"
        }, {
            "description": "Sleep walking",
            "nomenclature": "1.8.8",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_SLEEP_WALKING"
        }, {
            "description": "Time-zone syndrome",
            "nomenclature": "1.8.9",
            "selectable": false,
            "strength": 0,
            "type": "DISTURBANCES_OF_SLEEP_TIMEZONE_SYNDROME"
        }, {
            "description": "Senility-Old Age",
            "nomenclature": "1.9",
            "selectable": false,
            "strength": 0,
            "type": "OLD_AGE_SENILITY"
        }, {
            "description": "Stammering or stuttering",
            "nomenclature": "1.10",
            "selectable": false,
            "strength": 0,
            "type": "STAMMERING_OR_STUTTERING"
        }, {
            "description": "Vertigo-dizziness",
            "nomenclature": "1.11",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_CAT"
        }, {
            "description": "Falling sensations",
            "nomenclature": "1.11.1",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_FALLING_SENSATIONS"
        }, {
            "description": "Giddiness",
            "nomenclature": "1.11.2",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_GIDDINESS"
        }, {
            "description": "Lightheadedness",
            "nomenclature": "1.11.3",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_LIGHTHEADEDNESS"
        }, {
            "description": "Loss of sense of equilibrium or balance",
            "nomenclature": "1.11.4",
            "selectable": false,
            "strength": 0,
            "type": "VERTIGO_DIZZINESS_LOSS_SENSE_EQUILIBRIUM_BALANCE"
        }, {
            "description": "Other symptoms referable to the nervous system",
            "nomenclature": "1.12",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_SYMPTOMS_NERVOUS_SYSTEM"
        }, {
            "description": "Skin, Nails and Hair",
            "nomenclature": "2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_NAILS_HAIR_CAT"
        }, {
            "description": "Acne or pimples",
            "nomenclature": "2.1",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_CAT"
        }, {
            "description": "Bad complexion",
            "nomenclature": "2.1.1",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_BAD_COMPLEXION"
        }, {
            "description": "Blackheads",
            "nomenclature": "2.1.2",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_BLACKHEADS"
        }, {
            "description": "Blemishes",
            "nomenclature": "2.1.3",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_BLEMISHES"
        }, {
            "description": "Breaking out",
            "nomenclature": "2.1.4",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_BREAKING_OUT"
        }, {
            "description": "Whiteheads",
            "nomenclature": "2.1.5",
            "selectable": false,
            "strength": 0,
            "type": "ACNE_OR_PIMPLES_WHITEHEADS"
        }, {
            "description": "Discoloration or pigmentation",
            "nomenclature": "2.2",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_CAT"
        }, {
            "description": "Blushing",
            "nomenclature": "2.2.1",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_BLUSHING"
        }, {
            "description": "Change in color",
            "nomenclature": "2.2.2",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_CHANGE_IN_COLOR"
        }, {
            "description": "Flushing",
            "nomenclature": "2.2.3",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_FLUSHING"
        }, {
            "description": "Freckles",
            "nomenclature": "2.2.4",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_FRECKLES"
        }, {
            "description": "Red",
            "nomenclature": "2.2.5",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_RED"
        }, {
            "description": "Spots",
            "nomenclature": "2.2.6",
            "selectable": false,
            "strength": 0,
            "type": "DISCOLORATION_OR_PIGMENTATION_SPOTS"
        }, {
            "description": "Infectious disorders",
            "nomenclature": "2.3",
            "selectable": false,
            "strength": 0,
            "type": "INFECTIOUS_DISORDERS_CAT"
        }, {
            "description": "Athlete’s foot",
            "nomenclature": "2.3.1",
            "selectable": false,
            "strength": 0,
            "type": "INFECTIOUS_DISORDERS_ATHLETES_FOOT"
        }, {
            "description": "Boils",
            "nomenclature": "2.3.2",
            "selectable": false,
            "strength": 0,
            "type": "INFECTIOUS_DISORDERS_BOILS"
        }, {
            "description": "Ringworm",
            "nomenclature": "2.3.3",
            "selectable": false,
            "strength": 0,
            "type": "INFECTIOUS_DISORDERS_RINGWORM"
        }, {
            "description": "Calluses or corns",
            "nomenclature": "2.4",
            "selectable": false,
            "strength": 0,
            "type": "CALLUSES_OR_CORNS"
        }, {
            "description": "Skin moles",
            "nomenclature": "2.5",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_MOLES"
        }, {
            "description": "Wrinkles",
            "nomenclature": "2.6",
            "selectable": false,
            "strength": 0,
            "type": "WRINKLES"
        }, {
            "description": "Warts",
            "nomenclature": "2.7",
            "selectable": false,
            "strength": 0,
            "type": "WARTS"
        }, {
            "description": "Allergic skin reactions",
            "nomenclature": "2.8",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_CAT"
        }, {
            "description": "Rash",
            "nomenclature": "2.8.0",
            "selectable": false,
            "strength": 0,
            "type": "RASH"
        }, {
            "description": "Hives",
            "nomenclature": "2.8.1",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_HIVES"
        }, {
            "description": "Photosensitivity",
            "nomenclature": "2.8.2",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_PHOTOSENSITIVITY"
        }, {
            "description": "Poison ivy, poison oak, etc.",
            "nomenclature": "2.8.3",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_POISON_IVY_OAK"
        }, {
            "description": "Rash, diaper",
            "nomenclature": "2.8.4",
            "selectable": false,
            "strength": 0,
            "type": "ALLERGIC_SKIN_REACTIONS_RASH_DIAPER"
        }, {
            "description": "Skin irritations",
            "nomenclature": "2.9",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_CAT"
        }, {
            "description": "Skin inflammation",
            "nomenclature": "2.9.1",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_INFLAMMATION"
        }, {
            "description": "Itching",
            "nomenclature": "2.9.2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_ITCHING"
        }, {
            "description": "Painful skin",
            "nomenclature": "2.9.3",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_PAIN"
        }, {
            "description": "Skin ulcer",
            "nomenclature": "2.9.4",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_ULCER"
        }, {
            "description": "Sore skin",
            "nomenclature": "2.9.5",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_IRRITATIONS_SORE"
        }, {
            "description": "Swelling or mass of skin",
            "nomenclature": "2.10",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_CAT"
        }, {
            "description": "Skin bumps",
            "nomenclature": "2.10.1",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_BUMPS"
        }, {
            "description": "Skin lesion",
            "nomenclature": "2.10.2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_LESION"
        }, {
            "description": "Skin nodules",
            "nomenclature": "2.10.3",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_NODULES"
        }, {
            "description": "Skin welts",
            "nomenclature": "2.10.4",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SWELLING_OR_MASS_WELTS"
        }, {
            "description": "Wounds of skin",
            "nomenclature": "2.11",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_CAT"
        }, {
            "description": "Bites on skin",
            "nomenclature": "2.11.1",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_BITES"
        }, {
            "description": "Blisters on skin, non-allergic",
            "nomenclature": "2.11.2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_BLISTERS_NON_ALLERGIC"
        }, {
            "description": "Skin bruises",
            "nomenclature": "2.11.3",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_BRUISES"
        }, {
            "description": "Skin burns(chemical, sun, wind and steam)",
            "nomenclature": "2.11.4",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_BURNS"
        }, {
            "description": "Skin cuts",
            "nomenclature": "2.11.5",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_CUTS"
        }, {
            "description": "Skin scratches",
            "nomenclature": "2.11.6",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_WOUNDS_SCRATCHES"
        }, {
            "description": "Other symptoms referable to skin",
            "nomenclature": "2.12",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_CAT"
        }, {
            "description": "Skin dryness",
            "nomenclature": "2.12.1",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_DRYNESS"
        }, {
            "description": "Skin oiliness",
            "nomenclature": "2.12.2",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_OILINESS"
        }, {
            "description": "Skin peeling",
            "nomenclature": "2.12.3",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_PEELING"
        }, {
            "description": "Skin scalines",
            "nomenclature": "2.12.4",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_SCALINES"
        }, {
            "description": "Change in skin texture",
            "nomenclature": "2.12.5",
            "selectable": false,
            "strength": 0,
            "type": "SKIN_SYMPTOMS_CHANGE_IN_TEXTURE"
        }, {
            "description": "Symtoms referable to nails",
            "nomenclature": "2.13",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_CAT"
        }, {
            "description": "Breaking of nails",
            "nomenclature": "2.13.1",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_BREAKING"
        }, {
            "description": "Brittle nails",
            "nomenclature": "2.13.2",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_BRITTLE"
        }, {
            "description": "Change in nails color",
            "nomenclature": "2.13.3",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_CHANGE_IN_COLOR"
        }, {
            "description": "Cracked nails",
            "nomenclature": "2.13.4",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_CRACKED"
        }, {
            "description": "Ingrown nails",
            "nomenclature": "2.13.5",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_INGROWN"
        }, {
            "description": "Ridges on nails",
            "nomenclature": "2.13.6",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_RIDGES"
        }, {
            "description": "Nails splitting",
            "nomenclature": "2.13.7",
            "selectable": false,
            "strength": 0,
            "type": "NAIL_SYMPTOMS_SPLITTING"
        }, {
            "description": "Symptoms referable to hair",
            "nomenclature": "2.14",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_CAT"
        }, {
            "description": "Baldness",
            "nomenclature": "2.14.1",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_BALDNESS"
        }, {
            "description": "Brittle hair",
            "nomenclature": "2.14.2",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_BRITTLE"
        }, {
            "description": "Hair dryness",
            "nomenclature": "2.14.3",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_DRYNESS"
        }, {
            "description": "Falling out hair",
            "nomenclature": "2.14.4",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_FALLING_OUT"
        }, {
            "description": "Hair oilness",
            "nomenclature": "2.14.5",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_OILNESS"
        }, {
            "description": "Receding hair line",
            "nomenclature": "2.14.6",
            "selectable": false,
            "strength": 0,
            "type": "HAIR_SYMPTOMS_RECEDING_HAIR_LINE"
        }, {
            "description": "Symptoms of umbilicus",
            "nomenclature": "2.15",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_CAT"
        }, {
            "description": "Umbilical discharge",
            "nomenclature": "2.15.1",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_DISCHARGE"
        }, {
            "description": "Umbilical drainage",
            "nomenclature": "2.15.2",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_DRAINING"
        }, {
            "description": "Belly button not healing",
            "nomenclature": "2.15.3",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_NOT_HEALING"
        }, {
            "description": "Belly button pain",
            "nomenclature": "2.15.4",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_PAINFUL"
        }, {
            "description": "Red belly button",
            "nomenclature": "2.15.5",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_UMBILICUS_RED"
        }, {
            "description": "Cardiovascular and Lymphatic systems",
            "nomenclature": "3",
            "selectable": false,
            "strength": 0,
            "type": "CARDIOVASCULAR_LYMPHATIC_SYMPTOMS_CAT"
        }, {
            "description": "Irregular pulsations and palpitations",
            "nomenclature": "3.1",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_CAT"
        }, {
            "description": "Decreased heart beats",
            "nomenclature": "3.1.1",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_DECREASED"
        }, {
            "description": "Fluttering heart",
            "nomenclature": "3.1.2",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_FLUTTERING"
        }, {
            "description": "Increased heart beats",
            "nomenclature": "3.1.3",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_INCREASED"
        }, {
            "description": "Pulse too fast",
            "nomenclature": "3.1.4",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_PULSE_TOO_FAST"
        }, {
            "description": "Pulse too slow",
            "nomenclature": "3.1.5",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_PULSE_TOO_SLOW"
        }, {
            "description": "Irregular heart beats",
            "nomenclature": "3.1.6",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_IRREGULAR_BEATS"
        }, {
            "description": "Rapid heart beats heard",
            "nomenclature": "3.1.7",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_RAPID_HEARD"
        }, {
            "description": "Skipped beat",
            "nomenclature": "3.1.8",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_SKIPPED_BEAT"
        }, {
            "description": "Unequal heart beats",
            "nomenclature": "3.1.9",
            "selectable": false,
            "strength": 0,
            "type": "IRREGULAR_HEARTBEAT_UNEQUAL"
        }, {
            "description": "Heart murmur",
            "nomenclature": "3.2",
            "selectable": false,
            "strength": 0,
            "type": "HEART_MURMUR"
        }, {
            "description": "Abnormally high blood pressure",
            "nomenclature": "3.3",
            "selectable": false,
            "strength": 0,
            "type": "HIGH_BLOOD_PRESSURE_CAT"
        }, {
            "description": "Elevated blood pressure",
            "nomenclature": "3.3.1",
            "selectable": false,
            "strength": 0,
            "type": "HIGH_BLOOD_PRESSURE_ELEVATED_BP"
        }, {
            "description": "High blood pressure",
            "nomenclature": "3.3.2",
            "selectable": false,
            "strength": 0,
            "type": "HIGH_BLOOD_PRESSURE_HIGH_BP"
        }, {
            "description": "Hypertension",
            "nomenclature": "3.3.3",
            "selectable": false,
            "strength": 0,
            "type": "HIGH_BLOOD_PRESSURE_HYPERTENSION"
        }, {
            "description": "Abnormally low blood pressure",
            "nomenclature": "3.4",
            "selectable": false,
            "strength": 0,
            "type": "LOW_BLOOD_PRESSURE_CAT"
        }, {
            "description": "Decreased blood pressure",
            "nomenclature": "3.4.1",
            "selectable": false,
            "strength": 0,
            "type": "LOW_BLOOD_PRESSURE_DECREASED_BP"
        }, {
            "description": "Hypotension",
            "nomenclature": "3.4.2",
            "selectable": false,
            "strength": 0,
            "type": "LOW_BLOOD_PRESSURE_HYPOTENSION"
        }, {
            "description": "Low blood pressure",
            "nomenclature": "3.4.3",
            "selectable": false,
            "strength": 0,
            "type": "LOW_BLOOD_PRESSURE_LOW_BP"
        }, {
            "description": "Symptoms referable to blood",
            "nomenclature": "3.5",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_CAT"
        }, {
            "description": "Poor blood",
            "nomenclature": "3.5.1",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_POOR"
        }, {
            "description": "Thin blood",
            "nomenclature": "3.5.2",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_THIN"
        }, {
            "description": "Tired blood",
            "nomenclature": "3.5.3",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_TIRED"
        }, {
            "description": "Weak blood",
            "nomenclature": "3.5.4",
            "selectable": false,
            "strength": 0,
            "type": "BLOOD_SYMPTOMS_WEAK"
        }, {
            "description": "Pallor and cyanosis",
            "nomenclature": "3.6",
            "selectable": false,
            "strength": 0,
            "type": "PALLOR_AND_CYANOSIS_CAT"
        }, {
            "description": "Ashen color",
            "nomenclature": "3.6.1",
            "selectable": false,
            "strength": 0,
            "type": "PALLOR_AND_CYANOSIS_ASHEN_COLOR"
        }, {
            "description": "Blueness of fingers-toes",
            "nomenclature": "3.6.2",
            "selectable": false,
            "strength": 0,
            "type": "PALLOR_AND_CYANOSIS_BLUENESS_OF_FINGERS"
        }, {
            "description": "Paleness",
            "nomenclature": "3.6.3",
            "selectable": false,
            "strength": 0,
            "type": "PALLOR_AND_CYANOSIS_PALENESS"
        }, {
            "description": "Syncope or collapse",
            "nomenclature": "3.7",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_CAT"
        }, {
            "description": "Blacked out",
            "nomenclature": "3.7.1",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_BLACKED_OUT"
        }, {
            "description": "Fainting",
            "nomenclature": "3.7.2",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_FAINTING"
        }, {
            "description": "Passed out",
            "nomenclature": "3.7.3",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_PASSED_OUT"
        }, {
            "description": "Spells",
            "nomenclature": "3.7.4",
            "selectable": false,
            "strength": 0,
            "type": "SYNCOPE_OR_COLLAPSE_SPELLS"
        }, {
            "description": "Symptoms of heard",
            "nomenclature": "3.8",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_HEARD_CAT"
        }, {
            "description": "Bad heard",
            "nomenclature": "3.8.1",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_HEARD_BAD_HEARD"
        }, {
            "description": "Poor heard",
            "nomenclature": "3.8.2",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_HEARD_POOR_HEARD"
        }, {
            "description": "Weak heard",
            "nomenclature": "3.8.3",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_HEARD_WEAK_HEARD"
        }, {
            "description": "Edema and dropsy",
            "nomenclature": "3.9",
            "selectable": false,
            "strength": 0,
            "type": "EDEMA_AND_DROPSY"
        }, {
            "description": "Lymph glands",
            "nomenclature": "3.10",
            "selectable": false,
            "strength": 0,
            "type": "LYMPH_GLANDS_CAT"
        }, {
            "description": "Enlarged lymph nodes",
            "nomenclature": "3.10.1",
            "selectable": false,
            "strength": 0,
            "type": "LYMPH_GLANDS_ENLARGED_LYMPH_NODES"
        }, {
            "description": "Sore glands",
            "nomenclature": "3.10.2",
            "selectable": false,
            "strength": 0,
            "type": "LYMPH_GLANDS_SORE_GLANDS"
        }, {
            "description": "Swollen glands",
            "nomenclature": "3.10.3",
            "selectable": false,
            "strength": 0,
            "type": "LYMPH_GLANDS_SWOLLEN_GLANDS"
        }, {
            "description": "Respiratory System",
            "nomenclature": "4",
            "selectable": false,
            "strength": 0,
            "type": "RESPIRATORY_SYMPTOMS_CAT"
        }, {
            "description": "Nose bleed",
            "nomenclature": "4.1",
            "selectable": false,
            "strength": 0,
            "type": "NOSE_BLEED_CAT"
        }, {
            "description": "Bleeding from nose",
            "nomenclature": "4.1.1",
            "selectable": false,
            "strength": 0,
            "type": "NOSE_BLEED_BLEEDING"
        }, {
            "description": "Hemorrhage from nose",
            "nomenclature": "4.1.2",
            "selectable": false,
            "strength": 0,
            "type": "NOSE_BLEED_HEMORRHAGE"
        }, {
            "description": "Nasal congestion",
            "nomenclature": "4.2",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_CAT"
        }, {
            "description": "Drippy nose",
            "nomenclature": "4.2.1",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_DRIPPY_NOSE"
        }, {
            "description": "Postnasal drip",
            "nomenclature": "4.2.2",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_POSTNASAL_DRIP"
        }, {
            "description": "Red nose",
            "nomenclature": "4.2.3",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_RED_NOSE"
        }, {
            "description": "Runny nose",
            "nomenclature": "4.2.4",
            "selectable": false,
            "strength": 0,
            "type": "RUNNY_NOSE"
        }, {
            "description": "Sniffles",
            "nomenclature": "4.2.5",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_SNIFFLES"
        }, {
            "description": "Stuffy nose",
            "nomenclature": "4.2.6",
            "selectable": false,
            "strength": 0,
            "type": "NASAL_CONGESTION_STUFFY_NOSE"
        }, {
            "description": "Sinus problems",
            "nomenclature": "4.3",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_CAT"
        }, {
            "description": "Sinus congestion",
            "nomenclature": "4.3.1",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_CONGESTION"
        }, {
            "description": "Impacted sinuses",
            "nomenclature": "4.3.2",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_IMPACTED"
        }, {
            "description": "Infected sinuses",
            "nomenclature": "4.3.3",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_INFECTED"
        }, {
            "description": "Lightness in sinuses",
            "nomenclature": "4.3.4",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_LIGHTNESS"
        }, {
            "description": "Pain in sinuses",
            "nomenclature": "4.3.5",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_PAIN"
        }, {
            "description": "Pressure in sinuses",
            "nomenclature": "4.3.6",
            "selectable": false,
            "strength": 0,
            "type": "SINUS_PROBLEMS_PRESSURE"
        }, {
            "description": "Breathing",
            "nomenclature": "4.4",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_CAT"
        }, {
            "description": "Shortness of breath",
            "nomenclature": "4.4.0",
            "selectable": false,
            "strength": 0,
            "type": "BREATH_SHORTNESS"
        }, {
            "description": "Breathlessness",
            "nomenclature": "4.4.1",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_BREATHLESSNESS"
        }, {
            "description": "Dyspnes",
            "nomenclature": "4.4.2",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_DYSPNES"
        }, {
            "description": "Sensation of suffocation",
            "nomenclature": "4.4.3",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_SENSATION_OF_SUFFOCATION"
        }, {
            "description": "Trouble breathing",
            "nomenclature": "4.4.4",
            "selectable": false,
            "strength": 0,
            "type": "SHORTNESS_OF_BREATH_TROUBLE_BREATHING"
        }, {
            "description": "Other disorders of respiratory rhythm and sound",
            "nomenclature": "4.5",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_CAT"
        }, {
            "description": "Abnormal breathing sounds",
            "nomenclature": "4.5.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_ABNORMAL_BREATHING_SOUNDS"
        }, {
            "description": "Hyperventilation",
            "nomenclature": "4.5.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_HYPERVENTILATION"
        }, {
            "description": "Rales",
            "nomenclature": "4.5.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_RALES"
        }, {
            "description": "Rapid breathing",
            "nomenclature": "4.5.4",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_RAPID_BREATHING"
        }, {
            "description": "Sighing respiration",
            "nomenclature": "4.5.5",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_SIGHING_RESPIRATION"
        }, {
            "description": "Wheezing",
            "nomenclature": "4.5.6",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_RESPIRATORY_DISORDERS_WHEEZING"
        }, {
            "description": "Sneezing",
            "nomenclature": "4.6",
            "selectable": false,
            "strength": 0,
            "type": "SNEEZING"
        }, {
            "description": "Cough",
            "nomenclature": "4.7",
            "selectable": false,
            "strength": 0,
            "type": "COUGH"
        }, {
            "description": "Cold",
            "nomenclature": "4.8",
            "selectable": false,
            "strength": 0,
            "type": "COLD"
        }, {
            "description": "Flu",
            "nomenclature": "4.9",
            "selectable": false,
            "strength": 0,
            "type": "FLU"
        }, {
            "description": "Grip",
            "nomenclature": "4.9.1",
            "selectable": false,
            "strength": 0,
            "type": "GRIP"
        }, {
            "description": "Influenza",
            "nomenclature": "4.9.2",
            "selectable": false,
            "strength": 0,
            "type": "INFLUENZA"
        }, {
            "description": "Croup",
            "nomenclature": "4.10",
            "selectable": false,
            "strength": 0,
            "type": "CROUP"
        }, {
            "description": "Sputum or phlegm",
            "nomenclature": "4.11",
            "selectable": false,
            "strength": 0,
            "type": "SPUTUM_OR_PHLEGM_CAT"
        }, {
            "description": "Bloody sputum",
            "nomenclature": "4.11.1",
            "selectable": false,
            "strength": 0,
            "type": "SPUTUM_OR_PHLEGM_BLOODY"
        }, {
            "description": "Excessive sputum",
            "nomenclature": "4.11.2",
            "selectable": false,
            "strength": 0,
            "type": "SPUTUM_OR_PHLEGM_EXCESSIVE"
        }, {
            "description": "Purulent sputum",
            "nomenclature": "4.11.3",
            "selectable": false,
            "strength": 0,
            "type": "SPUTUM_OR_PHLEGM_PURULENT"
        }, {
            "description": "Congestion in chest",
            "nomenclature": "4.12",
            "selectable": false,
            "strength": 0,
            "type": "CONGESTION_IN_CHEST_CAT"
        }, {
            "description": "Lung congestion",
            "nomenclature": "4.12.1",
            "selectable": false,
            "strength": 0,
            "type": "CONGESTION_IN_CHEST_LUNG_CONGESTION"
        }, {
            "description": "Pain in chest",
            "nomenclature": "4.13",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_CAT"
        }, {
            "description": "Burning sensation in chest",
            "nomenclature": "4.13.1",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_BURNING_SENSATION"
        }, {
            "description": "Chest tightness",
            "nomenclature": "4.13.2",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_CHEST_TIGHTNESS"
        }, {
            "description": "Pain in lung",
            "nomenclature": "4.13.3",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_PAIN_IN_LUNG"
        }, {
            "description": "Pain over heart",
            "nomenclature": "4.13.4",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_PAIN_OVER_HEART"
        }, {
            "description": "Respiratory pain (rib, retrosternal, sternal)",
            "nomenclature": "4.13.5",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_RIB"
        }, {
            "description": "Pressure in/on chest",
            "nomenclature": "4.13.6",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_IN_CHEST_PRESSURE_IN_ON_CHEST"
        }, {
            "description": "Disorders of voice",
            "nomenclature": "4.14",
            "selectable": false,
            "strength": 0,
            "type": "DISORDERS_OF_VOICE_CAT"
        }, {
            "description": "Voice hoarsenses",
            "nomenclature": "4.14.1",
            "selectable": false,
            "strength": 0,
            "type": "DISORDERS_OF_VOICE_HOARSENSES"
        }, {
            "description": "Hypernasality",
            "nomenclature": "4.14.2",
            "selectable": false,
            "strength": 0,
            "type": "DISORDERS_OF_VOICE_HYPERNASALITY"
        }, {
            "description": "Musculoskeletal System",
            "nomenclature": "5",
            "selectable": false,
            "strength": 0,
            "type": "MUSKULOSKELETAL_SYSTEM_SYMPTOMS_CAT"
        }, {
            "description": "Pain, swelling, injury of lower extremity",
            "nomenclature": "5.1",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_CAT"
        }, {
            "description": "Ache in leg",
            "nomenclature": "5.1.1",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_ACHE"
        }, {
            "description": "Charley horse in leg",
            "nomenclature": "5.1.2",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_CHARLEYHORSE"
        }, {
            "description": "Leg contracture",
            "nomenclature": "5.1.3",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_CONTRACTURE"
        }, {
            "description": "Leg cramp",
            "nomenclature": "5.1.4",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_CRAMP"
        }, {
            "description": "Hot-cold leg feeling",
            "nomenclature": "5.1.5",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_HOT_COLD"
        }, {
            "description": "Leg hurt",
            "nomenclature": "5.1.6",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_HURT"
        }, {
            "description": "Pulled leg muscle",
            "nomenclature": "5.1.7",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_PULLED_MUSCLE"
        }, {
            "description": "Soreness in leg",
            "nomenclature": "5.1.8",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_SORENESS"
        }, {
            "description": "Spasm in leg",
            "nomenclature": "5.1.9",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_SPASM"
        }, {
            "description": "Stiffness in leg",
            "nomenclature": "5.1.10",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STIFFNESS"
        }, {
            "description": "Strain of ankle, foot, hip, knee",
            "nomenclature": "5.1.11",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_ANKLE_FOOT_HIP_KNEE"
        }, {
            "description": "Strain of foot",
            "nomenclature": "5.1.12",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_FOOT"
        }, {
            "description": "Strain of hip",
            "nomenclature": "5.1.13",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_HIP"
        }, {
            "description": "Strain of knee",
            "nomenclature": "5.1.14",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_KNEE"
        }, {
            "description": "Strain of leg or thigh",
            "nomenclature": "5.1.15",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_LEG_OR_THIGH"
        }, {
            "description": "Strain of lower extremity",
            "nomenclature": "5.1.16",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_GLOBAL"
        }, {
            "description": "Strain of toe",
            "nomenclature": "5.1.17",
            "selectable": false,
            "strength": 0,
            "type": "LOWER_EXTREMITY_PAIN_STRAIN_OF_TOE"
        }, {
            "description": "Pain, swelling, injury of upper extremity",
            "nomenclature": "5.2",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_CAT"
        }, {
            "description": "Ache in arm",
            "nomenclature": "5.2.1",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_ACHE"
        }, {
            "description": "Arm contracture",
            "nomenclature": "5.2.1",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_CONTRACTURE"
        }, {
            "description": "Hot-cold arm feeling",
            "nomenclature": "5.2.3",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_HOT_COLD"
        }, {
            "description": "Arm hurt",
            "nomenclature": "5.2.4",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_HURT"
        }, {
            "description": "Pulled arm muscle",
            "nomenclature": "5.2.5",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_PULLED_MUSCLE"
        }, {
            "description": "Soreness in arm",
            "nomenclature": "5.2.6",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_SORENESS"
        }, {
            "description": "Spasm in arm",
            "nomenclature": "5.2.7",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_SPASM"
        }, {
            "description": "Stiffness in arm",
            "nomenclature": "5.2.8",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STIFFNESS"
        }, {
            "description": "Strain of arm",
            "nomenclature": "5.2.9",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_ARM"
        }, {
            "description": "Strain of elbow",
            "nomenclature": "5.2.10",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_ELBOW"
        }, {
            "description": "Strain of fingers",
            "nomenclature": "5.2.11",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_FINGERS"
        }, {
            "description": "Strain of forearm",
            "nomenclature": "5.2.12",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_FOREARM"
        }, {
            "description": "Strain of hand",
            "nomenclature": "5.2.13",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_HAND"
        }, {
            "description": "Strain of shoulder",
            "nomenclature": "5.2.14",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_SHOULDER"
        }, {
            "description": "Strain of thumb",
            "nomenclature": "5.2.15",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_THUMB"
        }, {
            "description": "Strain of upper arm",
            "nomenclature": "5.2.16",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_UPPER_ARM"
        }, {
            "description": "Strain of upper extremity",
            "nomenclature": "5.2.17",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_UPPER_EXTREMITY"
        }, {
            "description": "Strain of wrist",
            "nomenclature": "5.2.18",
            "selectable": false,
            "strength": 0,
            "type": "UPPER_EXTREMITY_PAIN_STRAIN_OF_WRIST"
        }, {
            "description": "Pain, swelling, injury of face and neck",
            "nomenclature": "5.3",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_CAT"
        }, {
            "description": "Ache in neck",
            "nomenclature": "5.3.0",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_ACHE_NECK"
        }, {
            "description": "Ache in face",
            "nomenclature": "5.3.1",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_ACHE_FACE"
        }, {
            "description": "Face contracture",
            "nomenclature": "5.3.2",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_CONTRACTURE"
        }, {
            "description": "Neck cramp",
            "nomenclature": "5.3.3",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_CRAMP_NECK"
        }, {
            "description": "Face cramp",
            "nomenclature": "5.3.30",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_CRAMP_FACE"
        }, {
            "description": "Neck hurt",
            "nomenclature": "5.3.4",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_HURT_NECK"
        }, {
            "description": "Face hurt",
            "nomenclature": "5.3.40",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_HURT"
        }, {
            "description": "Pulled muscle of neck",
            "nomenclature": "5.3.5",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_PULLED_MUSCLE"
        }, {
            "description": "Soreness of face",
            "nomenclature": "5.3.6",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_SORENESS"
        }, {
            "description": "Face spasm",
            "nomenclature": "5.3.7",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_SPASM"
        }, {
            "description": "Stiffness of neck",
            "nomenclature": "5.3.8",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STIFFNESS"
        }, {
            "description": "Strain of back of head",
            "nomenclature": "5.3.9",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_BACK_HEAD"
        }, {
            "description": "Strain of cervical spine",
            "nomenclature": "5.3.10",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_CERVICAL_SPINE"
        }, {
            "description": "Strain of face",
            "nomenclature": "5.3.11",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_FACE"
        }, {
            "description": "Strain of jaw",
            "nomenclature": "5.3.12",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_JAW"
        }, {
            "description": "Strain of neck",
            "nomenclature": "5.3.13",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_NECK"
        }, {
            "description": "Strain of upper spine",
            "nomenclature": "5.3.14",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_FACE_AND_NECK_STRAIN_OF_UPPER_SPINE"
        }, {
            "description": "Pain, swelling, injury of back region",
            "nomenclature": "5.4",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_CAT"
        }, {
            "description": "Back ache",
            "nomenclature": "5.4.1",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_ACHE"
        }, {
            "description": "Contracture",
            "nomenclature": "5.4.2",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_CONTRACTURE"
        }, {
            "description": "Back cramp",
            "nomenclature": "5.4.3",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_CRAMP"
        }, {
            "description": "Back hurt",
            "nomenclature": "5.4.4",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_HURT"
        }, {
            "description": "Pulled back muscle",
            "nomenclature": "5.4.5",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_PULLED_MUSCLE"
        }, {
            "description": "Back soreness",
            "nomenclature": "5.4.6",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_SORENESS"
        }, {
            "description": "Back spasm",
            "nomenclature": "5.4.7",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_SPASM"
        }, {
            "description": "Back stiffness",
            "nomenclature": "5.4.8",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STIFFNESS"
        }, {
            "description": "Strain of back",
            "nomenclature": "5.4.9",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_BACK"
        }, {
            "description": "Strain of back, upper, lower",
            "nomenclature": "5.4.10",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_BACK_LOWER_UPPER"
        }, {
            "description": "Strain of lumbar",
            "nomenclature": "5.4.11",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_LUMBAR"
        }, {
            "description": "Strain of lumbosacral",
            "nomenclature": "5.4.12",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_LUMBOSACRAL"
        }, {
            "description": "Strain of sacroiliac",
            "nomenclature": "5.4.13",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_SACROILIAC"
        }, {
            "description": "Strain of spine",
            "nomenclature": "5.4.14",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_SPINE"
        }, {
            "description": "Strain of thoracic spine",
            "nomenclature": "5.4.15",
            "selectable": false,
            "strength": 0,
            "type": "BACK_PAIN_STRAIN_OF_THORACIC_SPINE"
        }, {
            "description": "Atrophy or wasting of extremities",
            "nomenclature": "5.5",
            "selectable": false,
            "strength": 0,
            "type": "ATROPHY_OR_WATING_EXTREMITIES_CAT"
        }, {
            "description": "Limb numbness",
            "nomenclature": "5.5.1",
            "selectable": false,
            "strength": 0,
            "type": "ATROPHY_OR_WATING_EXTREMITIES_NYMBNESS"
        }, {
            "description": "Paralysis, partial or complete",
            "nomenclature": "5.5.2",
            "selectable": false,
            "strength": 0,
            "type": "ATROPHY_OR_WATING_EXTREMITIES_PARALYSIS"
        }, {
            "description": "Limb weakness",
            "nomenclature": "5.5.3",
            "selectable": false,
            "strength": 0,
            "type": "ATROPHY_OR_WATING_EXTREMITIES_WEAKNESS"
        }, {
            "description": "Difficulty in walking, abnormality of gait",
            "nomenclature": "5.6",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_CAT"
        }, {
            "description": "Clumsiness when walking",
            "nomenclature": "5.6.1",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_CLUMSINESS"
        }, {
            "description": "Falling when walking",
            "nomenclature": "5.6.2",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_FALLING"
        }, {
            "description": "Inability to stand or walk",
            "nomenclature": "5.6.3",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_INABILITY_TO_STAND_AND_WALK"
        }, {
            "description": "Limping",
            "nomenclature": "5.6.4",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_LIMPING"
        }, {
            "description": "Staggering",
            "nomenclature": "5.6.5",
            "selectable": false,
            "strength": 0,
            "type": "WALKING_DIFFICULTIES_STAGGERING"
        }, {
            "description": "Other limb and joint symptoms",
            "nomenclature": "5.7",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_LIMB_JOINT_SYMPTOMS_CAT"
        }, {
            "description": "Muscle pain",
            "nomenclature": "5.7.0",
            "selectable": false,
            "strength": 0,
            "type": "MUSCLE_PAIN"
        }, {
            "description": "Joint pain",
            "nomenclature": "5.7.4",
            "selectable": false,
            "strength": 0,
            "type": "JOINT_PAIN"
        }, {
            "description": "Foot drop",
            "nomenclature": "5.7.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_LIMB_JOINT_SYMPTOMS_FOOT_DROP"
        }, {
            "description": "Posture problems",
            "nomenclature": "5.7.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_LIMB_JOINT_SYMPTOMS_POSTURE_PROBLEMS"
        }, {
            "description": "Wrist drop",
            "nomenclature": "5.7.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_LIMB_JOINT_SYMPTOMS_WRIST_DROP"
        }, {
            "description": "Bunion",
            "nomenclature": "5.8",
            "selectable": false,
            "strength": 0,
            "type": "BUNION"
        }, {
            "description": "Digestive System",
            "nomenclature": "6",
            "selectable": false,
            "strength": 0,
            "type": "DIGESTIVE_SYSTEM_CAT"
        }, {
            "description": "Chewing difficulty",
            "nomenclature": "6.1",
            "selectable": false,
            "strength": 0,
            "type": "CHEWING_DIFFICULTY"
        }, {
            "description": "Bleeding, gums (gingival)",
            "nomenclature": "6.2",
            "selectable": false,
            "strength": 0,
            "type": "BLEEDING_GUMS"
        }, {
            "description": "Halitosis",
            "nomenclature": "6.3",
            "selectable": false,
            "strength": 0,
            "type": "HALITOSIS_CAT"
        }, {
            "description": "Bad breath",
            "nomenclature": "6.3.1",
            "selectable": false,
            "strength": 0,
            "type": "HALITOSIS_BAD_BREATH"
        }, {
            "description": "Symptoms referable to lips",
            "nomenclature": "6.4",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_CAT"
        }, {
            "description": "Abnormal lips color",
            "nomenclature": "6.4.1",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_ABNORMAL_COLOR"
        }, {
            "description": "Bleeding lips",
            "nomenclature": "6.4.2",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_BLEEDING"
        }, {
            "description": "Cracked lips",
            "nomenclature": "6.4.3",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_CRACKED"
        }, {
            "description": "Dry lips",
            "nomenclature": "6.4.4",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_DRY"
        }, {
            "description": "Lips pain",
            "nomenclature": "6.4.5",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_PAIN"
        }, {
            "description": "Lips swelling",
            "nomenclature": "6.4.6",
            "selectable": false,
            "strength": 0,
            "type": "LIPS_SYMPTOMS_SWELLING"
        }, {
            "description": "Symptoms referable to mouth",
            "nomenclature": "6.5",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_CAT"
        }, {
            "description": "Bad taste",
            "nomenclature": "6.5.1",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_BAD_TASTE"
        }, {
            "description": "Mouth burn",
            "nomenclature": "6.5.2",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_BURN"
        }, {
            "description": "Mouth dryness",
            "nomenclature": "6.5.3",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_DRYNESS"
        }, {
            "description": "Mouth inflammation",
            "nomenclature": "6.5.4",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_INFLAMMATION"
        }, {
            "description": "Mouth pain",
            "nomenclature": "6.5.5",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_PAIN"
        }, {
            "description": "Mouth swelling",
            "nomenclature": "6.5.6",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_SWELLING"
        }, {
            "description": "Mouth ulcer",
            "nomenclature": "6.5.7",
            "selectable": false,
            "strength": 0,
            "type": "MOUTH_SYMPTOMS_ULCER"
        }, {
            "description": "Saliva",
            "nomenclature": "6.6",
            "selectable": false,
            "strength": 0,
            "type": "SALIVA_CAT"
        }, {
            "description": "Too much saliva",
            "nomenclature": "6.6.0",
            "selectable": false,
            "strength": 0,
            "type": "SALIVA_EXCESS"
        }, {
            "description": "Lack of saliva",
            "nomenclature": "6.6.2",
            "selectable": false,
            "strength": 0,
            "type": "SALIVA_LACK"
        }, {
            "description": "Drooling saliva",
            "nomenclature": "6.6.1",
            "selectable": false,
            "strength": 0,
            "type": "SALIVA_DROOLING"
        }, {
            "description": "Toothache",
            "nomenclature": "6.7",
            "selectable": false,
            "strength": 0,
            "type": "TOOTHACHE"
        }, {
            "description": "Throat soreness",
            "nomenclature": "6.8",
            "selectable": false,
            "strength": 0,
            "type": "THROAT_CAT"
        }, {
            "description": "Painful throat",
            "nomenclature": "6.8.1",
            "selectable": false,
            "strength": 0,
            "type": "THROAT_PAINFUL"
        }, {
            "description": "Scratchy throat",
            "nomenclature": "6.8.2",
            "selectable": false,
            "strength": 0,
            "type": "THROAT_SCRATCHY"
        }, {
            "description": "Sore throat",
            "nomenclature": "6.8.3",
            "selectable": false,
            "strength": 0,
            "type": "SORE_THROAT"
        }, {
            "description": "Symptoms referable to tongue",
            "nomenclature": "6.9",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_CAT"
        }, {
            "description": "Abnormal tongue color",
            "nomenclature": "6.9.1",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_ABNORMAL_COLOR"
        }, {
            "description": "Bleeding tongue",
            "nomenclature": "6.9.2",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_BLEEDING"
        }, {
            "description": "Tongue blisters",
            "nomenclature": "6.9.3",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_BLISTERS"
        }, {
            "description": "Burned tongue",
            "nomenclature": "6.9.4",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_BURNED"
        }, {
            "description": "Tongue pain",
            "nomenclature": "6.9.5",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_PAIN"
        }, {
            "description": "Tongue ridges",
            "nomenclature": "6.9.6",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_RIDGES"
        }, {
            "description": "Smooth tongue",
            "nomenclature": "6.9.7",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_SMOOTH"
        }, {
            "description": "Swelling or mass in tongue",
            "nomenclature": "6.9.8",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_SWELLING_OR_MASS"
        }, {
            "description": "Tongue ulcer",
            "nomenclature": "6.9.9",
            "selectable": false,
            "strength": 0,
            "type": "TONGUE_ULCER"
        }, {
            "description": "Symptoms referable to tonsils",
            "nomenclature": "6.10",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_CAT"
        }, {
            "description": "Bleeding (postoperative) tonsils",
            "nomenclature": "6.10.1",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_BLEEDING_POSTOPERATIVE"
        }, {
            "description": "Tonsils discharge",
            "nomenclature": "6.10.2",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_DISCHARGE"
        }, {
            "description": "Tonsisls inflammation",
            "nomenclature": "6.10.3",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_INFLAMMATION"
        }, {
            "description": "Tonsils swelling",
            "nomenclature": "6.10.4",
            "selectable": false,
            "strength": 0,
            "type": "TONSILS_SWELLING"
        }, {
            "description": "Swallowing",
            "nomenclature": "6.11",
            "selectable": false,
            "strength": 0,
            "type": "SWALLOWING_CAT"
        }, {
            "description": "Difficulty in swallowing",
            "nomenclature": "6.11.0",
            "selectable": false,
            "strength": 0,
            "type": "SWALLOWING_DIFFICULTY"
        }, {
            "description": "Choking",
            "nomenclature": "6.11.1",
            "selectable": false,
            "strength": 0,
            "type": "SWALLOWING_CHOKING"
        }, {
            "description": "Abdominal pain",
            "nomenclature": "6.12",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_CAT"
        }, {
            "description": "Colic, intestinal (except infants)",
            "nomenclature": "6.12.1",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_COLIC"
        }, {
            "description": "Pain in Epigastrium",
            "nomenclature": "6.12.2",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_EPIGASTRIUM"
        }, {
            "description": "Pain in Iliac",
            "nomenclature": "6.12.3",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_ILIAC"
        }, {
            "description": "Pain in Inguinal (groin)",
            "nomenclature": "6.12.4",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_INGUINAL"
        }, {
            "description": "Pain in Right/Left, Upper/Lower quadrant",
            "nomenclature": "6.12.5",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_QUADRANT"
        }, {
            "description": "Pain in Stomach (includes cramps)",
            "nomenclature": "6.12.6",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_STOMACH"
        }, {
            "description": "Pain in Umbilical region",
            "nomenclature": "6.12.7",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_PAIN_UMBILICAL"
        }, {
            "description": "Colic, infantile",
            "nomenclature": "6.13",
            "selectable": false,
            "strength": 0,
            "type": "COLIC_INFANTILE"
        }, {
            "description": "Abdominal swelling or mass",
            "nomenclature": "6.14",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_SWELLING_MASS_CAT"
        }, {
            "description": "Abdominal distension",
            "nomenclature": "6.14.0",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_SWELLING_MASS_DISTENTION"
        }, {
            "description": "Abdominal fullness",
            "nomenclature": "6.14.1",
            "selectable": false,
            "strength": 0,
            "type": "ABDOMINAL_SWELLING_MASS_FULLNESS"
        }, {
            "description": "Flatulence",
            "nomenclature": "6.15",
            "selectable": false,
            "strength": 0,
            "type": "FLATUENCE_CAT"
        }, {
            "description": "Bloating, gas",
            "nomenclature": "6.15.1",
            "selectable": false,
            "strength": 0,
            "type": "FLATUENCE_BLOATING_GAS"
        }, {
            "description": "Distension due to gas",
            "nomenclature": "6.15.2",
            "selectable": false,
            "strength": 0,
            "type": "FLATUENCE_DISTENSION"
        }, {
            "description": "Gas, excessive",
            "nomenclature": "6.15.3",
            "selectable": false,
            "strength": 0,
            "type": "FLATUENCE_GAS_EXCESSIVE"
        }, {
            "description": "Appetite, abnormal",
            "nomenclature": "6.16",
            "selectable": false,
            "strength": 0,
            "type": "APPETITE_CAT"
        }, {
            "description": "Decreased appetite",
            "nomenclature": "6.16.1",
            "selectable": false,
            "strength": 0,
            "type": "APPETITE_DECREASE"
        }, {
            "description": "Excessive appetite",
            "nomenclature": "6.16.2",
            "selectable": false,
            "strength": 0,
            "type": "APPETITE_EXCESSIVE"
        }, {
            "description": "Loss of appetite",
            "nomenclature": "6.16.3",
            "selectable": false,
            "strength": 0,
            "type": "APPETITE_LOSS"
        }, {
            "description": "Feeding problems",
            "nomenclature": "6.17",
            "selectable": false,
            "strength": 0,
            "type": "FEEDING_PROBLEMS"
        }, {
            "description": "Bleeding, gastrointestinal",
            "nomenclature": "6.18",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_CAT"
        }, {
            "description": "Blood in stools (melena)",
            "nomenclature": "6.18.1",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_STOOLS"
        }, {
            "description": "Hematemesis",
            "nomenclature": "6.18.2",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_HEMATEMESIS"
        }, {
            "description": "Hemorrhage, cause unknown",
            "nomenclature": "6.18.3",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_HEMORRHAGE"
        }, {
            "description": "Vomiting blood",
            "nomenclature": "6.18.4",
            "selectable": false,
            "strength": 0,
            "type": "GASTROINTESTINAL_BLEEDING_BLOOD_VOMITING"
        }, {
            "description": "Constipation",
            "nomenclature": "6.19",
            "selectable": false,
            "strength": 0,
            "type": "CONSTIPATION"
        }, {
            "description": "Diarrhea",
            "nomenclature": "6.20",
            "selectable": false,
            "strength": 0,
            "type": "DIARRHEA_CAT"
        }, {
            "description": "Diarrhea",
            "nomenclature": "6.20.0",
            "selectable": false,
            "strength": 0,
            "type": "DIARRHEA"
        }, {
            "description": "Loose stools",
            "nomenclature": "6.20.1",
            "selectable": false,
            "strength": 0,
            "type": "DIARRHEA_LOOSE_STOOLS"
        }, {
            "description": "Other symptoms or changes in bowel function",
            "nomenclature": "6.21",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_CAT"
        }, {
            "description": "Bulky stools",
            "nomenclature": "6.21.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_BULKY_STOOLS"
        }, {
            "description": "Dark stools",
            "nomenclature": "6.21.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_DARK_STOOLS"
        }, {
            "description": "Fatty stools",
            "nomenclature": "6.21.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_FATTY_STOOLS"
        }, {
            "description": "Mucous stools",
            "nomenclature": "6.21.4",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_MUCOUS_STOOLS"
        }, {
            "description": "Pus stools",
            "nomenclature": "6.21.5",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_PUS_STOOLS"
        }, {
            "description": "Unusual color",
            "nomenclature": "6.21.6",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_UNUSUAL_COLOR"
        }, {
            "description": "Unusual odor",
            "nomenclature": "6.21.7",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BOWEL_SYMPTOMS_UNUSUAL_ODOR"
        }, {
            "description": "Symptoms referable to anus-rectum",
            "nomenclature": "6.22",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_CAT"
        }, {
            "description": "Rectal bleeding",
            "nomenclature": "6.22.1",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_BLEEDING"
        }, {
            "description": "Anal itching",
            "nomenclature": "6.22.2",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_ITCHING"
        }, {
            "description": "Anal mass",
            "nomenclature": "6.22.3",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_MASS"
        }, {
            "description": "Rectal pain",
            "nomenclature": "6.22.4",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_RECTAL_PAIN"
        }, {
            "description": "Anal swelling",
            "nomenclature": "6.22.5",
            "selectable": false,
            "strength": 0,
            "type": "ANUS_RECTUM_SWELLING"
        }, {
            "description": "Heartburn or upset stomach",
            "nomenclature": "6.23",
            "selectable": false,
            "strength": 0,
            "type": "UPSET_STOMACH_CAT"
        }, {
            "description": "Belching",
            "nomenclature": "6.23.1",
            "selectable": false,
            "strength": 0,
            "type": "UPSET_STOMACH_BELCHING"
        }, {
            "description": "Indigestion",
            "nomenclature": "6.23.2",
            "selectable": false,
            "strength": 0,
            "type": "UPSET_STOMACH_INDIGESTION"
        }, {
            "description": "Nausea and vomiting",
            "nomenclature": "6.24",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA_AND_VOMITING_CAT"
        }, {
            "description": "Nausea",
            "nomenclature": "6.24.0",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA"
        }, {
            "description": "Retching",
            "nomenclature": "6.24.1",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA_AND_VOMITING_RETCHING"
        }, {
            "description": "Sick to stomach",
            "nomenclature": "6.24.2",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA_AND_VOMITING_SICK_TO_STOMACH"
        }, {
            "description": "Vomiting",
            "nomenclature": "6.24.3",
            "selectable": false,
            "strength": 0,
            "type": "VOMITING"
        }, {
            "description": "Throwing up",
            "nomenclature": "6.24.4",
            "selectable": false,
            "strength": 0,
            "type": "NAUSEA_AND_VOMITING_THROWING_UP"
        }, {
            "description": "Regurgitation or spitting-up",
            "nomenclature": "6.25",
            "selectable": false,
            "strength": 0,
            "type": "REGURGITATION_OR_SPITTING_UP"
        }, {
            "description": "Hiccough",
            "nomenclature": "6.26",
            "selectable": false,
            "strength": 0,
            "type": "HICCOUGH"
        }, {
            "description": "Jaundice",
            "nomenclature": "6.27",
            "selectable": false,
            "strength": 0,
            "type": "JAUNDICE"
        }, {
            "description": "Liver and gallbladder",
            "nomenclature": "6.28",
            "selectable": false,
            "strength": 0,
            "type": "LIVER_GALLBLADDER_CAT"
        }, {
            "description": "Biliary colic",
            "nomenclature": "6.28.1",
            "selectable": false,
            "strength": 0,
            "type": "LIVER_GALLBLADDER_BILIARY_COLIC"
        }, {
            "description": "Gallstones",
            "nomenclature": "6.28.2",
            "selectable": false,
            "strength": 0,
            "type": "LIVER_GALLBLADDER_GALLSTONES"
        }, {
            "description": "Urinary Tract",
            "nomenclature": "7",
            "selectable": false,
            "strength": 0,
            "type": "URINARY_TRACT"
        }, {
            "description": "Urine abnormalities",
            "nomenclature": "7.1",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_CAT"
        }, {
            "description": "Blood in urine",
            "nomenclature": "7.1.1",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_BLOOD"
        }, {
            "description": "Pus in urine",
            "nomenclature": "7.1.2",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_PUS"
        }, {
            "description": "Unusual urine color",
            "nomenclature": "7.1.3",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_UNUSUAL_COLOR"
        }, {
            "description": "Unusual urine odor",
            "nomenclature": "7.1.4",
            "selectable": false,
            "strength": 0,
            "type": "URINE_ABNORMALITIES_UNUSUAL_ODOR"
        }, {
            "description": "Frequency and nocturia",
            "nomenclature": "7.2",
            "selectable": false,
            "strength": 0,
            "type": "FREQUENCY_AND_NOCTURIA_CAT"
        }, {
            "description": "Bed wetting",
            "nomenclature": "7.2.1",
            "selectable": false,
            "strength": 0,
            "type": "FREQUENCY_AND_NOCTURIA_BED_WETTING"
        }, {
            "description": "Night discharge",
            "nomenclature": "7.2.2",
            "selectable": false,
            "strength": 0,
            "type": "FREQUENCY_AND_NOCTURIA_NIGHT_DISCHARGE"
        }, {
            "description": "Incontinence of urine",
            "nomenclature": "7.3",
            "selectable": false,
            "strength": 0,
            "type": "INCONTINENCE_OF_URINE_CAT"
        }, {
            "description": "Urinary dribbling",
            "nomenclature": "7.3.1",
            "selectable": false,
            "strength": 0,
            "type": "INCONTINENCE_OF_URINE_DRIBBLING"
        }, {
            "description": "Involuntary urination",
            "nomenclature": "7.3.2",
            "selectable": false,
            "strength": 0,
            "type": "INCONTINENCE_OF_URINE_INVOLUNTARY_URINATION"
        }, {
            "description": "Retention of urine",
            "nomenclature": "7.4",
            "selectable": false,
            "strength": 0,
            "type": "RETENTION_OF_URINE_CAT"
        }, {
            "description": "Cannot empty bladder",
            "nomenclature": "7.4.1",
            "selectable": false,
            "strength": 0,
            "type": "RETENTION_OF_URINE_CANNOT_EMPTY_BLADDER"
        }, {
            "description": "Inability to urinate",
            "nomenclature": "7.4.2",
            "selectable": false,
            "strength": 0,
            "type": "RETENTION_OF_URINE_INABILITY_TO_URINATE"
        }, {
            "description": "Painful urination",
            "nomenclature": "7.5",
            "selectable": false,
            "strength": 0,
            "type": "PAINFUL_URINATION_CAT"
        }, {
            "description": "Painful urination",
            "nomenclature": "7.5.0",
            "selectable": false,
            "strength": 0,
            "type": "PAINFUL_URINATION"
        }, {
            "description": "Burning",
            "nomenclature": "7.5.1",
            "selectable": false,
            "strength": 0,
            "type": "PAINFUL_URINATION_BURNING"
        }, {
            "description": "Other urinary tract symptoms",
            "nomenclature": "7.6",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_URINARY_SYMPTOMS_CAT"
        }, {
            "description": "Bladder trouble",
            "nomenclature": "7.6.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_URINARY_SYMPTOMS_BLADDER_TROUBLE"
        }, {
            "description": "Passed kidney stones",
            "nomenclature": "7.6.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_URINARY_SYMPTOMS_PASSED_STONES"
        }, {
            "description": "Male Reproductive System",
            "nomenclature": "8",
            "selectable": false,
            "strength": 0,
            "type": "MALE_REPRODUCTIVE_SYSTEM_CAT"
        }, {
            "description": "Infertility – Male",
            "nomenclature": "8.1",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_MALE_CAT"
        }, {
            "description": "Low sperm count",
            "nomenclature": "8.1.1",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_MALE_LOW_SPERM_COUNT"
        }, {
            "description": "Sterility",
            "nomenclature": "8.1.2",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_MALE_STERILITY"
        }, {
            "description": "Pain, swelling, or mass of male genital system",
            "nomenclature": "8.2",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_CAT"
        }, {
            "description": "Pain in penis",
            "nomenclature": "8.2.1",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_PAIN_PENIS"
        }, {
            "description": "Pain in scrotum",
            "nomenclature": "8.2.2",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_PAIN_SCROTUM"
        }, {
            "description": "Pain in testes",
            "nomenclature": "8.2.3",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_PAIN_TESTES"
        }, {
            "description": "Swelling, or mass in penis",
            "nomenclature": "8.2.4",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_SWELLING_PENIS"
        }, {
            "description": "Swelling, or mass in scrotum",
            "nomenclature": "8.2.5",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_SWELLING_SCROTUM"
        }, {
            "description": "Swelling, or mass in testes",
            "nomenclature": "8.2.6",
            "selectable": false,
            "strength": 0,
            "type": "PAIN_SWELLING_MASS_MALE_GENITALS_SWELLING_TESTES"
        }, {
            "description": "Other male reproductive system symptoms",
            "nomenclature": "8.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_MALE_REPRODUCTIVE_SYSTEM_CAT"
        }, {
            "description": "Psychosexual problems",
            "nomenclature": "8.3.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_MALE_REPRODUCTIVE_SYSTEM_PSYCHOSEXUAL_PROBLEMS"
        }, {
            "description": "Female Reproductive System",
            "nomenclature": "9",
            "selectable": false,
            "strength": 0,
            "type": "FEMALE_REPRODUCTIVE_PROBLEMS_CAT"
        }, {
            "description": "Menopause symptoms",
            "nomenclature": "9.1",
            "selectable": false,
            "strength": 0,
            "type": "MENOPAUSE_CAT"
        }, {
            "description": "Hot flashes",
            "nomenclature": "9.1.1",
            "selectable": false,
            "strength": 0,
            "type": "MENOPAUSE_HOT_FLASHES"
        }, {
            "description": "Premenstrual tension",
            "nomenclature": "9.2",
            "selectable": false,
            "strength": 0,
            "type": "PREMENSTRUAL_TENSION"
        }, {
            "description": "Menstrual cramps",
            "nomenclature": "9.3",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_CRAMPS"
        }, {
            "description": "Menstrual disorders",
            "nomenclature": "9.4",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_CAT"
        }, {
            "description": "Menstruation absence (amenorrhea)",
            "nomenclature": "9.4.1",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_ABSENCE_AMENORRHEA"
        }, {
            "description": "Atypical menstrual flow material",
            "nomenclature": "9.4.2",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_ATYPICAL_MATERIAL"
        }, {
            "description": "Menstrual blood clots",
            "nomenclature": "9.4.3",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_BLOOD_CLOTS"
        }, {
            "description": "Excessive menstrual flow",
            "nomenclature": "9.4.4",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_EXCESSIVE"
        }, {
            "description": "Frequent menstruation",
            "nomenclature": "9.4.5",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_FREQUENT"
        }, {
            "description": "Infrequent menstruation",
            "nomenclature": "9.4.6",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_INFREQUENT"
        }, {
            "description": "Irregular menstruation (metrorrhagia)",
            "nomenclature": "9.4.7",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_IRREGULAR_METRORRHAGIA"
        }, {
            "description": "Large menstrual flow",
            "nomenclature": "9.4.8",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_LARGE_FLOW"
        }, {
            "description": "Menstruation onset delayed",
            "nomenclature": "9.4.9",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_ONSET_DELAYED"
        }, {
            "description": "Scanty menstrual flow",
            "nomenclature": "9.4.10",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_SCANTY"
        }, {
            "description": "Small menstrual flow",
            "nomenclature": "9.4.11",
            "selectable": false,
            "strength": 0,
            "type": "MENSTRUAL_DISORDERS_SMALL_FLOW"
        }, {
            "description": "Ovulation pain",
            "nomenclature": "9.5",
            "selectable": false,
            "strength": 0,
            "type": "OVULATION_PAIN"
        }, {
            "description": "Pelvic symptoms",
            "nomenclature": "9.6",
            "selectable": false,
            "strength": 0,
            "type": "PELVIC_CAT"
        }, {
            "description": "Pelvic pain",
            "nomenclature": "9.6.1",
            "selectable": false,
            "strength": 0,
            "type": "PELVIC_PAIN"
        }, {
            "description": "Pelvic pressure or dropping sensation",
            "nomenclature": "9.6.2",
            "selectable": false,
            "strength": 0,
            "type": "PELVIC_PRESSURE"
        }, {
            "description": "Pelvic swelling or mass",
            "nomenclature": "9.6.3",
            "selectable": false,
            "strength": 0,
            "type": "PELVIC_SWELLING_OR_MASS"
        }, {
            "description": "Vaginal disorders",
            "nomenclature": "9.7",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISORDERS_CAT"
        }, {
            "description": "Vaginal pain",
            "nomenclature": "9.7.1",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISORDERS_PAIN"
        }, {
            "description": "Vaginal swelling or mass",
            "nomenclature": "9.7.2",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISORDERS_SWELLING_OR_MASS"
        }, {
            "description": "Vaginal discharge",
            "nomenclature": "9.8",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISCHARGE_CAT"
        }, {
            "description": "Atypical vaginal discharge (Ieukorrhea)",
            "nomenclature": "9.8.1",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISCHARGE_ATYPICAL"
        }, {
            "description": "Bloody vaginal discharge",
            "nomenclature": "9.8.2",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISCHARGE_BLOODY"
        }, {
            "description": "Brown vaginal discharge",
            "nomenclature": "9.8.3",
            "selectable": false,
            "strength": 0,
            "type": "VAGINAL_DISCHARGE_BROWN"
        }, {
            "description": "Vulvar disorders",
            "nomenclature": "9.9",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_CAT"
        }, {
            "description": "Vulvar itching",
            "nomenclature": "9.9.1",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_ITCHING"
        }, {
            "description": "Vulvar pain",
            "nomenclature": "9.9.2",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_PAIN"
        }, {
            "description": "Perineil swelling or mass",
            "nomenclature": "9.9.3",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_SWELLING_OR_MASS"
        }, {
            "description": "Vulvar ulcer",
            "nomenclature": "9.9.4",
            "selectable": false,
            "strength": 0,
            "type": "VULVAR_DISORDERS_ULCER"
        }, {
            "description": "Infertility – Female",
            "nomenclature": "9.10",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_FEMALE_CAT"
        }, {
            "description": "Sterility",
            "nomenclature": "9.10.1",
            "selectable": false,
            "strength": 0,
            "type": "INFERTILITY_FEMALE_STERILITY"
        }, {
            "description": "Problems of pregnancy",
            "nomenclature": "9.11",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_CAT"
        }, {
            "description": "Leaking amniotic fluid",
            "nomenclature": "9.11.1",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_LEAKING_AMNIOTIC_FLUID"
        }, {
            "description": "Possible labor",
            "nomenclature": "9.11.2",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_POSSIBLE_LABOR"
        }, {
            "description": "Products of conception passed",
            "nomenclature": "9.11.3",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_CONCEPTION_PRODUCT_PASSED"
        }, {
            "description": "Spotting (pregnancy)",
            "nomenclature": "9.11.4",
            "selectable": false,
            "strength": 0,
            "type": "PREGNANCY_SPOTTING"
        }, {
            "description": "Other female reproductive system symptoms",
            "nomenclature": "9.12",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_FEMALE_REPRODUCTIVE_SYSTEM_SYMPTOMS"
        }, {
            "description": "Lump or mass of breast",
            "nomenclature": "9.14",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_CAT"
        }, {
            "description": "Breast bump",
            "nomenclature": "9.14.1",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_BUMP"
        }, {
            "description": "Breast hard spot",
            "nomenclature": "9.14.2",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_HARD_SPOT"
        }, {
            "description": "Breast knot",
            "nomenclature": "9.14.3",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_KNOT"
        }, {
            "description": "Local breast swelling",
            "nomenclature": "9.14.4",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_LOCAL_SWELLING"
        }, {
            "description": "Breast nodule",
            "nomenclature": "9.14.5",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_LUMP_OR_MASS_NODULE"
        }, {
            "description": "Pain or soreness of breast",
            "nomenclature": "9.15",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_PAIN_CAT"
        }, {
            "description": "Breast redness",
            "nomenclature": "9.15.1",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_PAIN_REDNESS"
        }, {
            "description": "Breast swelling, generalized",
            "nomenclature": "9.15.2",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_PAIN_SWELLING_GENERALIZED"
        }, {
            "description": "Breast tenderness",
            "nomenclature": "9.15.3",
            "selectable": false,
            "strength": 0,
            "type": "BREAST_PAIN_TENDERNESS"
        }, {
            "description": "Symptoms of nipple",
            "nomenclature": "9.16",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_CAT"
        }, {
            "description": "Nipple bleeding",
            "nomenclature": "9.16.1",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_BLEEDING"
        }, {
            "description": "Nipple change in color",
            "nomenclature": "9.16.2",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_CHANGE_IN_COLOR"
        }, {
            "description": "Nipple discharge",
            "nomenclature": "9.16.3",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_DISCHARGE"
        }, {
            "description": "Nipple inflammation",
            "nomenclature": "9.16.4",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_INFLAMMATION"
        }, {
            "description": "Nipple inversion",
            "nomenclature": "9.16.5",
            "selectable": false,
            "strength": 0,
            "type": "NIPPLE_SYMPTOMS_INVERSION"
        }, {
            "description": "Postpartum problems of breast",
            "nomenclature": "9.17",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_CAT"
        }, {
            "description": "Abnormal breast secretion",
            "nomenclature": "9.17.1",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_ABNORMAL_SECRETION"
        }, {
            "description": "Absence of milk",
            "nomenclature": "9.17.2",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_ABSENCE_OF_MILK"
        }, {
            "description": "Difficulty or inability in nursing",
            "nomenclature": "9.17.3",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_NURSING"
        }, {
            "description": "Breast engorgement",
            "nomenclature": "9.17.4",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_ENGORGEMENT"
        }, {
            "description": "Excessive milk",
            "nomenclature": "9.17.5",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_EXCESSIVE_MILK"
        }, {
            "description": "Improper lactation",
            "nomenclature": "9.17.6",
            "selectable": false,
            "strength": 0,
            "type": "POSTPARTUM_BREAST_PROBLEMS_IMPROPER_LACTATION"
        }, {
            "description": "Other breast symptoms",
            "nomenclature": "9.18",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BREAST_SYMPTOMS_CAT"
        }, {
            "description": "Breast sagging",
            "nomenclature": "9.18.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BREAST_SYMPTOMS_SAGGING"
        }, {
            "description": "Too large breasts",
            "nomenclature": "9.18.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BREAST_SYMPTOMS_TOO_LARGE"
        }, {
            "description": "Too small breasts",
            "nomenclature": "9.18.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_BREAST_SYMPTOMS_TOO_SMALL"
        }, {
            "description": "Eyes and Ears",
            "nomenclature": "10",
            "selectable": false,
            "strength": 0,
            "type": "EYES_AND_EARS_CAT"
        }, {
            "description": "Complete blindness",
            "nomenclature": "10.1",
            "selectable": false,
            "strength": 0,
            "type": "BLINDNESS_COMPLETE"
        }, {
            "description": "Other vision disfunction",
            "nomenclature": "10.2",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_CAT"
        }, {
            "description": "Blurred vision",
            "nomenclature": "10.2.1",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_BLURRED"
        }, {
            "description": "Cloudy vision",
            "nomenclature": "10.2.2",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_CLOUDY_VISION"
        }, {
            "description": "Diminished vision",
            "nomenclature": "10.2.3",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_DIMINISHED"
        }, {
            "description": "Dull vision",
            "nomenclature": "10.2.4",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_DULL_VISION"
        }, {
            "description": "Eye floaters",
            "nomenclature": "10.2.5",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_FLOATERS"
        }, {
            "description": "Half vision",
            "nomenclature": "10.2.6",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_HALF_VISION"
        }, {
            "description": "Hazy vision",
            "nomenclature": "10.2.7",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_HAZY_VISION"
        }, {
            "description": "Photophobia",
            "nomenclature": "10.2.8",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_PHOTOPHOBIA"
        }, {
            "description": "Eye spots",
            "nomenclature": "10.2.9",
            "selectable": false,
            "strength": 0,
            "type": "VISION_DISFUNCTION_SPOTS"
        }, {
            "description": "Discharge from eye",
            "nomenclature": "10.3",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_CAT"
        }, {
            "description": "Blood eye discharge",
            "nomenclature": "10.3.1",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_BLOOD"
        }, {
            "description": "Excessive tearing from eye",
            "nomenclature": "10.3.2",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_EXCESSIVE_TEARING"
        }, {
            "description": "Pus from eye",
            "nomenclature": "10.3.3",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_PUS"
        }, {
            "description": "Watering eye",
            "nomenclature": "10.3.4",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EYE_WATERING"
        }, {
            "description": "Eye pain and irritation",
            "nomenclature": "10.4",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_CAT"
        }, {
            "description": "Burning eye",
            "nomenclature": "10.4.1",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_BURNING"
        }, {
            "description": "Eye inflamation",
            "nomenclature": "10.4.2",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_INFLAMED"
        }, {
            "description": "Eye irritation",
            "nomenclature": "10.4.3",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_IRRITATION"
        }, {
            "description": "Itchy eye",
            "nomenclature": "10.4.4",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_ITCHING"
        }, {
            "description": "Eye swelling or mass",
            "nomenclature": "10.4.5",
            "selectable": false,
            "strength": 0,
            "type": "EYE_PAIN_SWELLING_OR_MASS"
        }, {
            "description": "Abnormal eye movements",
            "nomenclature": "10.5",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_CAT"
        }, {
            "description": "Abnormal eye retraction",
            "nomenclature": "10.5.1",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_RETRACTION"
        }, {
            "description": "Cross-eyed",
            "nomenclature": "10.5.2",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_CROSS_EYED"
        }, {
            "description": "Pupil unequal",
            "nomenclature": "10.5.3",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_UNEQUAL_PUPIL"
        }, {
            "description": "Eye spasms",
            "nomenclature": "10.5.4",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_SPASMS"
        }, {
            "description": "Eye squinting",
            "nomenclature": "10.5.5",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_SQUINTING"
        }, {
            "description": "Eye twitching",
            "nomenclature": "10.5.6",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYE_MOVEMENTS_TWITCHING"
        }, {
            "description": "Symptoms of eyelids",
            "nomenclature": "10.6",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_CAT"
        }, {
            "description": "Drooping eyelid",
            "nomenclature": "10.6.1",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_DROOPING"
        }, {
            "description": "Eyelid inflammation",
            "nomenclature": "10.6.2",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_INFLAMMATION"
        }, {
            "description": "Itchy eyelid",
            "nomenclature": "10.6.3",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_ITCHING"
        }, {
            "description": "Eyelid swelling or mass",
            "nomenclature": "10.6.4",
            "selectable": false,
            "strength": 0,
            "type": "SYMPTOMS_OF_EYELIDS_SWELLING_OR_MASS"
        }, {
            "description": "Sty",
            "nomenclature": "10.7",
            "selectable": false,
            "strength": 0,
            "type": "STY"
        }, {
            "description": "Pink-eye",
            "nomenclature": "10.8",
            "selectable": false,
            "strength": 0,
            "type": "PINK_EYE_CAT"
        }, {
            "description": "Pink-eye",
            "nomenclature": "10.8.0",
            "selectable": false,
            "strength": 0,
            "type": "PINK_EYE"
        }, {
            "description": "Conjunctivitis",
            "nomenclature": "10.8.1",
            "selectable": false,
            "strength": 0,
            "type": "PINK_EYE_CONJUNCTIVITIS"
        }, {
            "description": "Foreign body in eye",
            "nomenclature": "10.9",
            "selectable": false,
            "strength": 0,
            "type": "FOREIGN_BODY_IN_EYES"
        }, {
            "description": "Eye injuries",
            "nomenclature": "10.10",
            "selectable": false,
            "strength": 0,
            "type": "EYE_INJURIES_CAT"
        }, {
            "description": "Black eye",
            "nomenclature": "10.10.1",
            "selectable": false,
            "strength": 0,
            "type": "EYE_INJURIES_BLACK_EYE"
        }, {
            "description": "Eye burns",
            "nomenclature": "10.10.2",
            "selectable": false,
            "strength": 0,
            "type": "EYE_INJURIES_BURNS"
        }, {
            "description": "Scratches",
            "nomenclature": "10.10.3",
            "selectable": false,
            "strength": 0,
            "type": "EYE_INJURIES_SCRATCHES"
        }, {
            "description": "Abnormal appearance of eyes",
            "nomenclature": "10.11",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_CAT"
        }, {
            "description": "Abnormal eye protrusion",
            "nomenclature": "10.11.1",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_PROTRUSION"
        }, {
            "description": "Bloodshot eyes",
            "nomenclature": "10.11.2",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_BLOODSHOT"
        }, {
            "description": "Cloudy eyes",
            "nomenclature": "10.11.3",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_CLOUDY"
        }, {
            "description": "Dull eyes",
            "nomenclature": "10.11.4",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_DULL"
        }, {
            "description": "Hazy eyes",
            "nomenclature": "10.11.5",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EYES_APPEARANCE_HAZY"
        }, {
            "description": "Deafness",
            "nomenclature": "10.13",
            "selectable": false,
            "strength": 0,
            "type": "DEAFNESS"
        }, {
            "description": "Other hearing dysfunctions",
            "nomenclature": "10.14",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_CAT"
        }, {
            "description": "Acute hearing",
            "nomenclature": "10.14.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_ACUTE_HEARING"
        }, {
            "description": "Diminished hearing",
            "nomenclature": "10.14.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_DIMINISHED_HEARING"
        }, {
            "description": "Extraneous noises in ears",
            "nomenclature": "10.14.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_NOICES"
        }, {
            "description": "Ringing in ears",
            "nomenclature": "10.14.4",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_RINGING_IN_EARS"
        }, {
            "description": "Trouble hearing",
            "nomenclature": "10.14.5",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_HEARING_DYSFUNCTIONS_TROUBLE_HEARING"
        }, {
            "description": "Discharge from ear",
            "nomenclature": "10.15",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EAR_CAT"
        }, {
            "description": "Pus from ear",
            "nomenclature": "10.15.0",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EAR_PUS"
        }, {
            "description": "Ear bleeding",
            "nomenclature": "10.15.1",
            "selectable": false,
            "strength": 0,
            "type": "DISCHARGE_FROM_EAR_BLEEDING"
        }, {
            "description": "Earache",
            "nomenclature": "10.16",
            "selectable": false,
            "strength": 0,
            "type": "EARACHE_CAT"
        }, {
            "description": "Pain in ear",
            "nomenclature": "10.16.1",
            "selectable": false,
            "strength": 0,
            "type": "EARACHE"
        }, {
            "description": "Plugged feeling in ear",
            "nomenclature": "10.17",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_CAT"
        }, {
            "description": "Blocked ears",
            "nomenclature": "10.17.1",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_BLOCKED"
        }, {
            "description": "Cracking ears",
            "nomenclature": "10.17.2",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_CRACKING"
        }, {
            "description": "Popping ears",
            "nomenclature": "10.17.3",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_POPPING"
        }, {
            "description": "Stopped up ears",
            "nomenclature": "10.17.4",
            "selectable": false,
            "strength": 0,
            "type": "EAR_PLUGGED_FEELING_STOPPED_UP"
        }, {
            "description": "Excess wax in ear",
            "nomenclature": "10.18",
            "selectable": false,
            "strength": 0,
            "type": "EXCESS_WAX_IN_EAR"
        }, {
            "description": "Abnormal ear size",
            "nomenclature": "10.19",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_EAR_SIZE"
        }, {
            "description": "Other symptoms referable to the ears",
            "nomenclature": "10.20",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_EAR_SYMPTOMS_CAT"
        }, {
            "description": "Foreign body in ear",
            "nomenclature": "10.20.1",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_EAR_SYMPTOMS_FOREIGN_BODY"
        }, {
            "description": "Itchy ear",
            "nomenclature": "10.20.2",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_EAR_SYMPTOMS_ITCHING"
        }, {
            "description": "Ear swelling or mass",
            "nomenclature": "10.20.3",
            "selectable": false,
            "strength": 0,
            "type": "OTHER_EAR_SYMPTOMS_SWELLING_OR_MASS"
        }, {
            "description": "Mental Health",
            "nomenclature": "11",
            "selectable": false,
            "strength": 0,
            "type": "MENTAL_HEALTH_CAT"
        }, {
            "description": "Anxiety",
            "nomenclature": "11.1",
            "selectable": false,
            "strength": 0,
            "type": "ANXIETY_CAT"
        }, {
            "description": "Apprehension",
            "nomenclature": "11.1.1",
            "selectable": false,
            "strength": 0,
            "type": "ANXIETY_APPREHENSION"
        }, {
            "description": "Fears and phobias",
            "nomenclature": "11.3",
            "selectable": false,
            "strength": 0,
            "type": "FEARS_AND_PHOBIAS_CAT"
        }, {
            "description": "Restlessness",
            "nomenclature": "11.3.0",
            "selectable": false,
            "strength": 0,
            "type": "FEARS_AND_PHOBIAS_RESTLESSNESS"
        }, {
            "description": "Hyperactivity",
            "nomenclature": "11.3.1",
            "selectable": false,
            "strength": 0,
            "type": "FEARS_AND_PHOBIAS_HYPERACTIVITY"
        }, {
            "description": "Overactivity",
            "nomenclature": "11.3.2",
            "selectable": false,
            "strength": 0,
            "type": "FEARS_AND_PHOBIAS_OVERACTIVITY"
        }, {
            "description": "Loneliness",
            "nomenclature": "11.4",
            "selectable": false,
            "strength": 0,
            "type": "LONELINESS"
        }, {
            "description": "Depression",
            "nomenclature": "11.5",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_CAT"
        }, {
            "description": "Deppression, Bitterness",
            "nomenclature": "11.5.1",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_BITTERNESS"
        }, {
            "description": "Deppression, Crying excessively",
            "nomenclature": "11.5.2",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_CRYING_EXCESSIVELY"
        }, {
            "description": "Deppression, Dejected",
            "nomenclature": "11.5.3",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_DEJECTED"
        }, {
            "description": "Deppression, Discontented",
            "nomenclature": "11.5.4",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_DISCONTENTED"
        }, {
            "description": "Deppression, Feeling lost",
            "nomenclature": "11.5.5",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_FEELING_LOST"
        }, {
            "description": "Deppression, Feeling low",
            "nomenclature": "11.5.6",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_FEELING_LOW"
        }, {
            "description": "Deppression, Feeling rejected",
            "nomenclature": "11.5.7",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_FEELING_REJECTED"
        }, {
            "description": "Deppression, Hopelessness",
            "nomenclature": "11.5.8",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_HOPELESSNESS"
        }, {
            "description": "Deppression, Unhappy",
            "nomenclature": "11.5.9",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_UNHAPPY"
        }, {
            "description": "Deppression, Worrying",
            "nomenclature": "11.5.10",
            "selectable": false,
            "strength": 0,
            "type": "DEPRESSION_WORRYING"
        }, {
            "description": "Nervousness",
            "nomenclature": "11.6",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_CAT"
        }, {
            "description": "“Butterflies”",
            "nomenclature": "11.6.1",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_BUTTERFLIES"
        }, {
            "description": "Nerves",
            "nomenclature": "11.6.2",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_NERVES"
        }, {
            "description": "Nervousness, Tension",
            "nomenclature": "11.6.3",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_TENSION"
        }, {
            "description": "Nervousness, Upset",
            "nomenclature": "11.6.4",
            "selectable": false,
            "strength": 0,
            "type": "NERVOUSNESS_UPSET"
        }, {
            "description": "Behavorial disturbance",
            "nomenclature": "11.7",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_CAT"
        }, {
            "description": "Antisocial behavior",
            "nomenclature": "11.7.1",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_ANTISOCIAL_BEHAVIOR"
        }, {
            "description": "Behavorial problems",
            "nomenclature": "11.7.2",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_BEHAVORIAL_PROBLEMS"
        }, {
            "description": "Irritability",
            "nomenclature": "11.7.3",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_IRRITABILITY"
        }, {
            "description": "Quarrelsome",
            "nomenclature": "11.7.4",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_QUARRELSOME"
        }, {
            "description": "Temper tantrums",
            "nomenclature": "11.7.5",
            "selectable": false,
            "strength": 0,
            "type": "BEHAVORIAL_DISTURBANCE_TEMPER_TANTRUMS"
        }, {
            "description": "Excessive smoking",
            "nomenclature": "11.8",
            "selectable": false,
            "strength": 0,
            "type": "EXCESSIVE_SMOKING"
        }, {
            "description": "Alcohol related problems",
            "nomenclature": "11.9",
            "selectable": false,
            "strength": 0,
            "type": "ALCOHOL_RELATED_PROBLEMS_CAT"
        }, {
            "description": "Alcoholism",
            "nomenclature": "11.9.1",
            "selectable": false,
            "strength": 0,
            "type": "ALCOHOL_RELATED_PROBLEMS_ALCOHOLISM"
        }, {
            "description": "Drinks too much",
            "nomenclature": "11.9.2",
            "selectable": false,
            "strength": 0,
            "type": "ALCOHOL_RELATED_PROBLEMS_DRINKS_TOO_MUCH"
        }, {
            "description": "Abnormal drug usage",
            "nomenclature": "11.10",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_DRUG_USAGE_CAT"
        }, {
            "description": "Excessive use of stimulants or depressants",
            "nomenclature": "11.10.1",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_DRUG_USAGE_STIMULANTS_DEPRESSANTS"
        }, {
            "description": "Misuse of medications or drugs",
            "nomenclature": "11.10.2",
            "selectable": false,
            "strength": 0,
            "type": "ABNORMAL_DRUG_USAGE_MISUSE"
        }, {
            "description": "Delusions or hallucinations",
            "nomenclature": "11.11",
            "selectable": false,
            "strength": 0,
            "type": "DELUSIONS_OR_HALLUCINATIONS"
        }, {
            "description": "Bad habits",
            "nomenclature": "11.12",
            "selectable": false,
            "strength": 0,
            "type": "BAD_HABITS_CAT"
        }, {
            "description": "Chewing on hair",
            "nomenclature": "11.12.1",
            "selectable": false,
            "strength": 0,
            "type": "BAD_HABITS_CHEWING_ON_HAIR"
        }, {
            "description": "Nail biting",
            "nomenclature": "11.12.2",
            "selectable": false,
            "strength": 0,
            "type": "BAD_HABITS_NAIL_BITING"
        }, {
            "description": "Thumb sucking",
            "nomenclature": "11.12.3",
            "selectable": false,
            "strength": 0,
            "type": "BAD_HABITS_THUMB_SUCKING"
        }, {
            "description": "Obsessions or compulsions",
            "nomenclature": "11.13",
            "selectable": false,
            "strength": 0,
            "type": "OBSESSIONS_OR_COMPULSIONS"
        }, {
            "description": "Psychosexual disorders",
            "nomenclature": "11.14",
            "selectable": false,
            "strength": 0,
            "type": "PSYCHOSEXUAL_DISORDERS_CAT"
        }, {
            "description": "Frigidity",
            "nomenclature": "11.14.1",
            "selectable": false,
            "strength": 0,
            "type": "PSYCHOSEXUAL_DISORDERS_FRIGIDITY"
        }, {
            "description": "Impotence",
            "nomenclature": "11.14.2",
            "selectable": false,
            "strength": 0,
            "type": "PSYCHOSEXUAL_DISORDERS_IMPOTENCE"
        }]
    }
}
import {
    CLEAR_GENERAL_PRACTITIONER,
    FETCH_SELECTED_USER_GENERAL_PRACTITIONER_ERROR,
    FETCH_SELECTED_USER_GENERAL_PRACTITIONER_SUCCESS, GET_CAPTURE_SUCCESS, IMPORT_GP_SUCCESS,
    LOGOUT,
    REQUEST_ERROR,
    REQUEST_SUCCESS,
    SELECT_USER
} from "../actions/actions";
import {requestStatus} from './requests_reducers';

/**
 * General Practitioner reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the symptoms logs
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const generalPractitionerInitialState = {generalPractitioner: {request: requestStatus(undefined, {})}}

export function practitioner(state = generalPractitionerInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_GENERAL_PRACTITIONER_SUCCESS: {
            return {
                ...state,
                generalPractitioner: {
                    value: action.result,
                    request: requestStatus(state.request, {type: REQUEST_SUCCESS})
                }
            }
        }
        case FETCH_SELECTED_USER_GENERAL_PRACTITIONER_ERROR: {
            return {
                ...state,
                generalPractitioner: {
                    request: requestStatus(state.request, {
                        type: REQUEST_ERROR,
                        response: action.response
                    })
                }
            }
        }
        case CLEAR_GENERAL_PRACTITIONER:
        case SELECT_USER:
        case LOGOUT: {
            return {...state, ...generalPractitionerInitialState};
        }
        default: {
            return state;
        }
    }
}


export function practitionerCapture(state = {}, action) {
    switch (action.type) {
        case GET_CAPTURE_SUCCESS: {
            return {
                ...state,
                value: action.result
            }
        }
        case SELECT_USER:
        case LOGOUT: {
            return {...state, ...generalPractitionerInitialState};
        }
        default: {
            return state;
        }
    }
}

export function importedPractitioner(state = {}, action) {
    switch (action.type) {
        case IMPORT_GP_SUCCESS: {
            return {
                ...state,
                value: action.result
            }
        }
        case SELECT_USER:
        case LOGOUT: {
            return {...state, ...generalPractitionerInitialState};
        }
        default: {
            return state;
        }
    }
}

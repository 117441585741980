import React, {Component} from 'react'
import {$$} from '../../../helpers/localization'
import {CONVERTER} from '../../../utils/converter'
import {HEIGHT, WEIGHT} from "../../../constants/entry_types";
import {BLOOD_TYPE, getResolvedOptions} from '../../../constants/select_options'
import Select from '../../shared/Select';
import PropTypes from "prop-types";


export class WeightHeightandBlood extends Component {
    state = {
        weight: this.props.medicalProfile.data.weight && CONVERTER.convertByUnit(WEIGHT, this.props.medicalProfile.data.weight).toFixed(1) > 0 ? CONVERTER.convertByUnit(WEIGHT, this.props.medicalProfile.data.weight).toFixed(1) : 0,
        height: this.props.medicalProfile.data.height && CONVERTER.convertByUnit(HEIGHT, this.props.medicalProfile.data.height).toFixed(1) > 0 ? CONVERTER.convertByUnit(HEIGHT, this.props.medicalProfile.data.height).toFixed(1) : 0,
        blood_type: this.props.blood_type ? this.props.blood_type : ""

    }

    constructor(props) {
        super(props);

    }

    componentDidMount() {
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.weightHeightEdit !== this.props.weightHeightEdit)
            this.setState({
                weight: this.props.medicalProfile.data.weight ? CONVERTER.convertByUnit(WEIGHT, this.props.medicalProfile.data.weight).toFixed(1) : "",
                height: this.props.medicalProfile.data.height ? CONVERTER.convertByUnit(HEIGHT, this.props.medicalProfile.data.height).toFixed(1) : ""
            })
    }

    isANumber = (evt) => {

        let value;

        if (!isNaN(parseInt(evt.target.value))) {
            if (evt.target.value >= 0) {
                value = evt.target.value
                value = value.replace(/^0+/, '')
            } else {
                value = 0
            }
        } else {
            value = 0
        }

        return value;
    }

    /**
     * Event handler when weight input changes
     * @param {*} evt
     */
    onWeightChange = (evt) => {
        let weight = this.isANumber(evt)
        this.setState({
            weight: weight
        });
        this.props.enableUpdateButton();

        let form = {
            ...this.props.medicalProfile.data,
            weight: CONVERTER.convertToMetric(WEIGHT, evt.target.value),
            height: CONVERTER.convertToMetric(HEIGHT, this.state.height),
            blood_type: this.state.blood_type
        }
        this.props.changeWeightHeightAndBlood(form);

    };

    /**
     * Event handler when we choose an option of blood type select
     * @param {*} e
     */
    onSelectChange = (e) => {
        this.setState({blood_type: e.value});
        this.props.enableUpdateButton();
        let form;
        form = {
            ...this.props.medicalProfile.data,
            weight: CONVERTER.convertToMetric(WEIGHT, this.state.weight),
            height: CONVERTER.convertToMetric(HEIGHT, this.state.height),
            blood_type: e.value
        }
        this.props.changeWeightHeightAndBlood(form);
    }

    /**
     * Event handler when height input changes
     * @param {*} evt
     */
    onHeightChange = (evt) => {
        let height = this.isANumber(evt)

        this.setState({
            height: height
        });
        this.props.enableUpdateButton();

        let form = {
            ...this.props.medicalProfile.data,
            weight: CONVERTER.convertToMetric(WEIGHT, this.state.weight),
            height: CONVERTER.convertToMetric(HEIGHT, evt.target.value),
            blood_type: this.state.blood_type
        }
        this.props.changeWeightHeightAndBlood(form);
    };


    render() {
        return <div className="d-flex weight-blood-height-container">
            <div className="weight-blood-height col-md-4 col-sm-12">
                <div className="row new-header">
                    <i className='flaticon2-blood-group'/> {$$('blood_group')}
                </div>
                <Select
                    name="blood_type"
                    options={getResolvedOptions(BLOOD_TYPE.TYPE)}
                    value={this.state.blood_type}
                    onChange={this.onSelectChange}
                    placeHolder={$$('type_label')}
                    selectDisabled={false}
                    selectDivClass="blood-type-select mr--2"
                    customClass="weight-height-blood-select"
                />
            </div>
            <div className="weight-blood-height col-md-4 col-sm-12">
                <div className="row new-header">
                    <i className='flaticon2-blood-group'/> {$$('height_label') + " (" + CONVERTER.getUnitByType(HEIGHT) + ")"}
                </div>

                <input className="form-control weight-blood-height-input"
                       type="number"
                       min="0"
                       step="0.01"
                       value={this.state.height}
                       onChange={this.onHeightChange}/>
            </div>
            <div className="weight-blood-height col-md-4 col-sm-12">
                <div className="row new-header">
                    <i className='flaticon2-blood-group'/> {$$('weight_label') + " (" + CONVERTER.getUnitByType(WEIGHT) + ")"}
                </div>
                <input className="form-control weight-blood-height-input"
                       type="number"
                       min="0"
                       step="0.01"
                       value={this.state.weight}
                       onChange={this.onWeightChange}/>
            </div>
        </div>
            ;
    }
}

WeightHeightandBlood.propTypes = {
    bloodGroupEdit: PropTypes.bool,
    blood_type: PropTypes.string,
    changeWeightHeightAndBlood: PropTypes.func,
    enableUpdateButton: PropTypes.func,
    i18n: PropTypes.object,
    medicalProfile: PropTypes.shape({
        data: PropTypes.shape({
            weight: PropTypes.number,
            height: PropTypes.number
        })
    }),
    onBloodGroupEdit: PropTypes.func,
    onWeightHeightEdit: PropTypes.func,
    selectedUser: PropTypes.object,
    weightHeightEdit: PropTypes.bool
};
import {
    CHANGE_APPOINTMENT_IN_APPOINTMENTS_LIST,
    CLEAR_APPOINTMENT_CREATION_STATUS,
    CREATE_APPOINTMENT_ERROR,
    CREATE_APPOINTMENT_SUCCESS,
    FETCH_APPOINTMENT_ERROR,
    FETCH_APPOINTMENT_REQUEST_SENT,
    FETCH_APPOINTMENT_SUCCESS,
    FETCH_APPOINTMENTS_ERROR,
    FETCH_APPOINTMENTS_REQUEST_SENT,
    FETCH_APPOINTMENTS_SUCCESS,
    LOGOUT,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS, RESET_PRESELECTED_APPOINTMENT, SET_PRESELECTED_APPOINTMENT
} from '../actions/actions'
import {requestStatus} from './requests_reducers';

/**
 * User appointments reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const userAppointmentsInitialState = {entry: {}, entries: [], request: requestStatus(undefined, {})}

export function userAppointments(state = userAppointmentsInitialState, action) {
    switch (action.type) {
        case FETCH_APPOINTMENTS_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case FETCH_APPOINTMENTS_SUCCESS: {
            return {
                ...state,
                entries: [...action.result],
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case FETCH_APPOINTMENTS_ERROR: {
            return {
                ...state, ...userAppointmentsInitialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case CHANGE_APPOINTMENT_IN_APPOINTMENTS_LIST: {
            let appointments = state.entries;
            for (let i = 0; i < appointments.length; i++) {
                if (appointments[i].id === action.result.id) {
                    appointments[i] = action.result;
                }
            }
            return {...state, entries: appointments, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case LOGOUT: {
            return {...state, ...userAppointmentsInitialState};
        }
        default: {
            return state;
        }
    }
}

const userAppointmentInitialState = {entry: {}, request: requestStatus(undefined, {}), appointmentCreation:{creationSuccess: null, request : requestStatus(undefined, {}) }}

export function userAppointment(state = userAppointmentInitialState, action) {
    switch (action.type) {
        case FETCH_APPOINTMENT_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case CREATE_APPOINTMENT_SUCCESS: {
            return {...state, entry: {...action.result}, appointmentCreation: {creationSuccess: true , request: action.result}}
        }
        case CREATE_APPOINTMENT_ERROR: {
            return {...state, appointmentCreation: {creationSuccess: false , request: action.result}}
        }

        case CLEAR_APPOINTMENT_CREATION_STATUS: {
            return {...state, appointmentCreation: userAppointmentInitialState.appointmentCreation}
        }
        case FETCH_APPOINTMENT_SUCCESS: {
            return {...state, entry: {...action.result}, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_APPOINTMENT_ERROR: {
            return {
                ...state, ...userAppointmentInitialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case LOGOUT: {
            return {...state, ...userAppointmentInitialState};
        }
        default: {
            return state;
        }
    }
}

export function preselectedAppointment(state = {}, action) {
    switch (action.type) {
        case SET_PRESELECTED_APPOINTMENT:
            return {...action.state}

        case CLEAR_APPOINTMENT_CREATION_STATUS:
        case RESET_PRESELECTED_APPOINTMENT:
        case LOGOUT: {
            return {};
        }
        default: {
            return state;
        }
    }
}
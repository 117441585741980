import React, {Component} from 'react'
import {$$} from '../../../helpers/localization'
import PropTypes from "prop-types";

export default class PeakFlowForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.peak_flow ? $$('peak_flow_label') : null}<br/>
                <div className="form-group">
                    <div className="input-group mb-3">
                        <input type="text" pattern="^\d+$"
                               className={this.props.errors.peak_flow ? "custom-error form-control" : "form-control"}
                               value={this.props.peak_flow} placeholder={$$('peak_flow_label')}
                               name="peak_flow" onChange={this.props.onInputChange} required/>
                        <div className="input-group-append">
                            <span className="input-group-text" style={{"height": "auto"}}
                                  id="basic-addon2">{$$("liters_per_minute")}</span>
                        </div>
                    </div>
                    <div
                        className={this.props.errors.peak_flow ? "custom-invalid-feedback" : "invalid-feedback"}>
                        {this.props.errors.peak_flow ? $$(this.props.errors.peak_flow) : $$('peak_flow_required_message')}
                    </div>
                </div>
                <div className="form-group">
                    {$$('peak_flow_symptoms_label')}
                    <PeakFormSymptoms onChange={(value)=>this.props.onInputChange({name:"peak_flow_symptoms", value:value})} peak_flow_symptoms={this.props.peak_flow_symptoms}/>
                </div>
            </div>
        )
    }
}

PeakFlowForm.propTypes = {
    peak_flow: PropTypes.any,
    onInputChange: PropTypes.func,
    peak_flow_symptoms: PropTypes.string,
    errors: PropTypes.object
}

class PeakFormSymptoms extends Component {

    PEAK_FLOW_SYMPTOM_INHALER_IS_USED = "INHALER_IS_USED";
    PEAK_FLOW_SYMPTOM_HAD_ASTHMA_SYMPTOMS = "HAD_ASTHMA_SYMPTOMS";
    PEAK_FLOW_SYMPTOM_HAD_NIGHT_ASTHMA_SYMPTOMS = "HAD_NIGHT_ASTHMA_SYMPTOMS";
    PEAK_FLOW_SYMPTOM_CANNOT_KEEP_DAILY_ACTIVITIES = "CANNOT_KEEP_DAILY_ACTIVITIES";

    onCheckBoxChange = (e) => {
        let res = this.props.peak_flow_symptoms || "";
        let name = e.target.name;
        console.log(name);
        if (res.indexOf(name) > -1) {
            res = res.split(",")
            res = res.filter((f)=>f.trim()!==name)
            res = res.join(",");
        } else {
            res = res.split(",").filter(f=>f !== '')
            res.push(name)
            res = res.join(",")
        }
        this.props.onChange(res);
    }

    isChecked(value) {
        return this.props.peak_flow_symptoms && this.props.peak_flow_symptoms.indexOf(value) > -1
    }

    render() {
        return <div>
            <div className="col-1 checkbox-container">
                <input onChange={this.onCheckBoxChange} name={this.PEAK_FLOW_SYMPTOM_INHALER_IS_USED} checked={this.isChecked(this.PEAK_FLOW_SYMPTOM_INHALER_IS_USED)} id={1}
                       className="symptom-checkbox" type="checkbox"/>
                <label className="checkbox-label" htmlFor={1} >
                    <i className="fa fa-check wht" aria-hidden="true"/>
                </label>
                <label htmlFor={1} className="peak-flow-checkbox-label">
                    {$$("peak_flow_inhaler_label")}
                </label>
            </div>
            <div className="col-1 checkbox-container">
                <input onChange={this.onCheckBoxChange} name={this.PEAK_FLOW_SYMPTOM_HAD_ASTHMA_SYMPTOMS} checked={this.isChecked(this.PEAK_FLOW_SYMPTOM_HAD_ASTHMA_SYMPTOMS)} id={2}
                       className="symptom-checkbox" type="checkbox"/>
                <label className="checkbox-label" htmlFor={2}>
                    <i className="fa fa-check wht" aria-hidden="true"/>
                </label>
                <label htmlFor={2} className="peak-flow-checkbox-label">
                    {$$("peak_flow_asthma_symptoms_label")}
                </label>
            </div>
            <div className="col-1 checkbox-container">
                <input onChange={this.onCheckBoxChange} name={this.PEAK_FLOW_SYMPTOM_HAD_NIGHT_ASTHMA_SYMPTOMS} checked={this.isChecked(this.PEAK_FLOW_SYMPTOM_HAD_NIGHT_ASTHMA_SYMPTOMS)} id={3}
                       className="symptom-checkbox" type="checkbox"/>
                <label className="checkbox-label" htmlFor={3}>
                    <i className="fa fa-check wht" aria-hidden="true"/>
                </label>
                <label htmlFor={3} className="peak-flow-checkbox-label">
                    {$$("peak_flow_night_asthma_symptoms_label")}
                </label>
            </div>
            <div className="col-1 checkbox-container">
                <input onChange={this.onCheckBoxChange} name={this.PEAK_FLOW_SYMPTOM_CANNOT_KEEP_DAILY_ACTIVITIES} checked={this.isChecked(this.PEAK_FLOW_SYMPTOM_CANNOT_KEEP_DAILY_ACTIVITIES)} id={4}
                       className="symptom-checkbox" type="checkbox"/>
                <label className="checkbox-label" htmlFor={4}>
                    <i className="fa fa-check wht" aria-hidden="true"/>
                </label>
                <label htmlFor={4} className="peak-flow-checkbox-label">
                    {$$("peak_flow_daily_activity_label")}
                </label>
            </div>
        </div>
    }
}

PeakFormSymptoms.propTypes = {
    peak_flow_symptoms: PropTypes.any,
    onChange: PropTypes.func,
}
import React, {Component} from 'react'
import PropTypes from "prop-types";

class HealthIssueTag extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = {...this.props}
        return <div key={props.key} className={props.className} style={props.styles}>{props.healthIssue}</div>
    }
}

HealthIssueTag.propTypes = {
    className: PropTypes.string,
    healthIssue: PropTypes.string,
    styles: PropTypes.object
}

export default HealthIssueTag;

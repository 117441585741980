import {
    CLEAR_PROVIDERS,
    FETCH_FIRST_AVAILABLE_ERROR,
    FETCH_FIRST_AVAILABLE_REQUEST_SENT,
    FETCH_FIRST_AVAILABLE_SUCCESS,
    FETCH_HOSPITALS_ERROR,
    FETCH_HOSPITALS_REQUEST_SENT,
    FETCH_HOSPITALS_SUCCESS,
    FETCH_PROVIDER_ERROR,
    FETCH_PROVIDER_REQUEST_SENT,
    FETCH_PROVIDER_SUCCESS,
    FETCH_PROVIDER_TIMETABLE_ERROR,
    FETCH_PROVIDER_TIMETABLE_REQUEST_SENT,
    FETCH_PROVIDER_TIMETABLE_SUCCESS,
    FETCH_PROVIDERS_ERROR,
    FETCH_PROVIDERS_REQUEST_SENT,
    FETCH_PROVIDERS_SUCCESS,
    FETCH_SPECIALTIES_COUNT_ERROR,
    FETCH_SPECIALTIES_COUNT_REQUEST_SENT,
    FETCH_SPECIALTIES_COUNT_SUCCESS,
    FETCH_SPECIALTIES_ERROR,
    FETCH_SPECIALTIES_REQUEST_SENT,
    FETCH_SPECIALTIES_SUCCESS
} from './actions';
import moment from 'moment';
import {providerService} from '../service/provider_service';

export function fetchHospitals() {
    return (dispatch) => {

        dispatch({type: FETCH_HOSPITALS_REQUEST_SENT});
        return providerService.fetchHospitals().then((res) => {
            if (res) {
                dispatch({type: FETCH_HOSPITALS_SUCCESS, result: res})
            }

        }).catch((err) => {
            dispatch({type: FETCH_HOSPITALS_ERROR, result: err})
        })
    }
}


/**
 * Fetch the providers.
 */
export function fetchProviders(specialty, country) {
    return (dispatch) => {

        dispatch({type: FETCH_PROVIDERS_REQUEST_SENT});
        return providerService.fetchProviders(specialty, country).then((res) => {
            if (res) {
                dispatch({type: FETCH_PROVIDERS_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: FETCH_PROVIDERS_ERROR, result: err});
        })
    }
}

export function clearProviders(){
    return(dispatch) => {
        dispatch({type: CLEAR_PROVIDERS})
    }
}

/**
 * Fetch all the available specialties
 *
 * @param {object} params params sent together with the request
 * @returns {object} dispatch function
 */
export function fetchSpecialties(params) {
    return (dispatch) => {
        dispatch({type: FETCH_SPECIALTIES_REQUEST_SENT});
        return providerService.fetchSpecialties(params).then((res) => {
            if (res && res.length > 0) {
                dispatch({type: FETCH_SPECIALTIES_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_SPECIALTIES_ERROR, result: err});
        })
    }
}

/**
 * Fetch specialties with counts
 *
 * @param {object} params params sent together with the request
 * @returns {object} dispatch function
 */
export function fetchSpecialtiesCount(params) {
    return (dispatch) => {
        dispatch({type: FETCH_SPECIALTIES_COUNT_REQUEST_SENT});
        return providerService.fetchSpecialtiesCount(params).then((res) => {
            if (res && res.length > 0) {
                dispatch({type: FETCH_SPECIALTIES_COUNT_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_SPECIALTIES_COUNT_ERROR, result: err});
        })
    }
}

/**
 * Fetch one provider
 */
export function fetchProvider(providerId) {
    return (dispatch) => {
        dispatch({type: FETCH_PROVIDER_REQUEST_SENT});
        return providerService.fetchProvider(providerId).then((res) => {
            if (res) {
                dispatch({type: FETCH_PROVIDER_SUCCESS, result: res})
            }
            return res;
        }).catch((err) => {
            dispatch({type: FETCH_PROVIDER_ERROR, result: err});
        })
    }
}


/**
 * Fetch first available hour
 */
export function fetchFirstHour(providerId, location, organizationId, priceId) {
    let startTimestamp = moment().add(15, 'minutes').valueOf();
    let endTimestamp = moment().add(365, 'days').valueOf();
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (dispatch) => {
        dispatch({type: FETCH_FIRST_AVAILABLE_REQUEST_SENT});
        return providerService.fetchFirstHour(providerId, startTimestamp, endTimestamp, timezone, location, organizationId, priceId).then((res) => {
            if (res) {
                dispatch({type: FETCH_FIRST_AVAILABLE_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: FETCH_FIRST_AVAILABLE_ERROR, result: err});
        })
    }
}

/**
 * Fetch timetable
 */
export function fetchTimetable(providerId, location, organizationId, priceId) {
    let startTimestamp = moment().add(15, 'minutes').valueOf();
    let endTimestamp = moment().add(365, 'days').valueOf();
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (dispatch) => {
        dispatch({type: FETCH_PROVIDER_TIMETABLE_REQUEST_SENT});
        return providerService.fetchTimetable(providerId, startTimestamp, endTimestamp, timezone, location, organizationId, priceId).then((res) => {
            if (res) {
                dispatch({type: FETCH_PROVIDER_TIMETABLE_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: FETCH_PROVIDER_TIMETABLE_ERROR, result: err});
        })
    }
}
import React, {Component} from 'react'
import {connect} from 'react-redux'
import IssueContainer from './IssueContainer';
import PropTypes from "prop-types";

class HealthIssues extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <IssueContainer i18n={this.props.i18n}/>
            </div>
        )
    }
}

HealthIssues.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
    i18n:PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object
}

function mapStateToProps(state) {
    return {
        i18n: state.language,
    }
}

export default connect(mapStateToProps, null)(HealthIssues);

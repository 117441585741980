import React, {Component} from "react";
import CenteredModal from "../../shared/CenteredModal";
import {$$} from "../../../helpers/localization";
import {Button} from "react-bootstrap";
import PropTypes from "prop-types";

export default class ImportGP extends Component {

    state = {
        showModal: false,
        captcha: null,
        viewState: null,
        cookies: null,
        userCaptcha: "",
        EGN: "",
        formClass: ""
    }

    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    getCapture = async () => {

        await this.props.getGPImportCapture().then(() => {
                this.setState({
                    showModal: true,
                    captcha: this.props.generalPractitionerCapture.captcha,
                    viewState: this.props.generalPractitionerCapture.viewState,
                    cookies: this.props.generalPractitionerCapture.cookies
                })

                this.props.toggleEditGPModal();

            }
        )
    }

    toggleModal = () => {
        this.setState({showModal: false})
        this.props.toggleEditGPModal();

    }

    getDetails = async (evt) => {
        evt.preventDefault();
        if (this.state.formClass !== "was-validated") {
            this.setState({formClass: "was-validated"});
        }

        if (this.state.userCaptcha !== "" && this.state.EGN !== "") {
            let form = {
                "EGN": this.state.EGN.replace(/\s/g, ""),
                "captcha": this.state.userCaptcha.replace(/\s/g, ""),
                "cookies": this.state.cookies.replace(/\s/g, ""),
                "viewState": this.state.viewState.replace(/\s/g, "")
            }

            await this.props.importGP(form).then(() => {
                let GP = {
                    name: this.props.importedPractitioner.name,
                    practiceName: this.props.importedPractitioner.practiceName,
                    medicalPracticeAddress: this.props.importedPractitioner.medicalPracticeAddress,
                    practicePhoneNumber: this.props.importedPractitioner.practicePhoneNumber,
                    regionalHealthCenterCode: this.props.importedPractitioner.regionalHealthCenterCode,
                    uin: this.props.importedPractitioner.uin
                }
                this.props.setFormUsingImportedGP(GP)
                this.toggleModal();
            })
        }

    }

    render() {
        return (
            <>
                <Button className={"left-align-modal-button"}
                        onClick={this.getCapture}>
                    {$$('import')}
                </Button>
                <CenteredModal title={$$("import") + ' ' + $$("general_practitioner")}
                               dialogClassName = {'custom-dialog'}
                               show={this.state.showModal}
                               onHide={this.toggleModal}
                               onConfirm={this.getDetails}
                               confirmBtnLabel={$$('continue')}
                               cancelBtnLabel={$$('cancel_btn')}>


                    <img className={"recaptcha-img"} src={this.state.captcha} alt={"captcha"}/>
                    <form
                        className={this.state.formClass + " import-form"}
                    >
                        <br/>
                        <label>{$$('Captcha')}: </label>
                        <input type="text"
                               className="form-control"
                               value={this.state.userCaptcha}
                               placeholder={$$('Captcha')}
                               name="userCaptcha"
                               onChange={this.onInputChange}
                               required/>
                        <br/>
                        <label>{$$('EGN')}: </label>
                        <input type="text"
                               className="form-control"
                               value={this.state.EGN}
                               placeholder={$$('EGN')}
                               name="EGN"
                               onChange={this.onInputChange}
                               required/>
                    </form>
                </CenteredModal>
            </>
        )
    }
}

ImportGP.propTypes = {
    generalPractitionerCapture: PropTypes.shape({
        cookies : PropTypes.string,
        viewState : PropTypes.string,
        captcha : PropTypes.string
    }),
    getGPImportCapture: PropTypes.func,
    importGP:  PropTypes.func,
    importedPractitioner:  PropTypes.object,
    setFormUsingImportedGP:  PropTypes.func,
    toggleEditGPModal:  PropTypes.func,
};
import store from '../store';

/**
 * Get label for a given key for the current applied language
 *
 * @param {string} key the label key to be resolved
 * @returns {string} the desired label
 */
export function $$(key) {
    let state = store.getState();
    let selecteLang = state.language.selected;
    var lang = window.strings[selecteLang.lang];
    if (!lang || !lang.text) {
        return "$$L:" + key;
    }
    if (key.indexOf(".") !== -1) {
        let path = key.split('.');
        let current = lang.text;
        while (path.length) {
            if (typeof current !== 'object') {
                return "$_:" + key;
            }
            current = current[path.shift()];
        }
        return current;
    }
    let val = lang.text[key];
    if (!val) {
        return "$_:" + key;
    }
    return val;
}

/**
 * Get label for a given key for the current applied language and replace placeholders with params
 *
 * @param {string} key the label key to be resolved
 * @param {any} props parameters to
 * @returns {string} the desired label
 */
export function _$$(key, ...props) {
    let val = $$(key);
    props.map((prop, idx) => {
        let regexp = new RegExp("%" + (idx + 1), "g");
        val = val.replace(regexp, prop);
    });
    return val;
}
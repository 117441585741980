import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {
    DOCUMENTS,
    getHealthIssueOptions,
    getResolvedOptions
} from '../../constants/select_options'
import Select from '../shared/Select'
import classnames from 'classnames'
import moment from 'moment';
import CustomMultiselect from '../shared/CustomMultiSelect'
import CenteredModal from '../shared/CenteredModal';
import {
    KeyboardDatePicker,
    KeyboardTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {DOCUMENT} from "../../actions/actions";
import FormWithLoading from "../shared/FormWithLoading";
import PropTypes from "prop-types";
import bg from 'date-fns/locale/bg';
import sq from 'date-fns/locale/sq';
import enGB from 'date-fns/locale/en-GB';


export class DocumentForm extends Component {
    constructor(props) {
        super(props);

        let existingHealthIssues = this.props.selectedEntry ? this.getHealthIssueList(this.props.selectedEntry) : [];
        let selectedIssuesArray = this.props.selectedEntry && this.props.selectedEntry.health_issues_ids ? getHealthIssueOptions(existingHealthIssues) : [];
        this.state = {
            date: this.props.selectedEntry && this.props.selectedEntry.date_time ? this.props.selectedEntry.date_time : moment().valueOf(),
            time: this.props.selectedEntry && this.props.selectedEntry.date_time ? this.props.selectedEntry.date_time : moment().valueOf(),
            showOtherForm: this.props.selectedEntry && this.props.selectedEntry.type === "OTHER",
            selectedDocumentType: this.props.selectedEntry && this.props.selectedEntry.type ? this.props.selectedEntry.type : DOCUMENTS.TYPE[3].value,
            category: this.props.selectedEntry && this.props.selectedEntry.category ? this.props.selectedEntry.category : "",
            notes: this.props.selectedEntry && this.props.selectedEntry.notes ? this.props.selectedEntry.notes : "",
            deleted: this.props.selectedEntry && this.props.selectedEntry.deleted ? this.props.selectedEntry.deleted : false,
            filename: this.props.selectedEntry && this.props.selectedEntry.filename ? this.props.selectedEntry.filename : "",
            mime_type: this.props.selectedEntry && this.props.selectedEntry.mime_type ? this.props.selectedEntry.mime_type : "",
            shared: this.props.selectedEntry && this.props.selectedEntry.shared ? this.props.selectedEntry.shared : false,
            size: this.props.selectedEntry && this.props.selectedEntry.size ? this.props.selectedEntry.size : 0,
            title: this.props.selectedEntry && this.props.selectedEntry.title ? this.props.selectedEntry.title : "",
            typeDescription: this.props.selectedEntry && this.props.selectedEntry.typeDescription ? this.props.selectedEntry.typeDescription : "",
            formclass: "",
            changedOnSelect: false,
            file: null,
            errors: {},
            showProcessingModal: false,
            existingHealthIssues: this.props.selectedEntry ? existingHealthIssues : [],
            selectedHealthIssues: this.props.selectedEntry ? selectedIssuesArray : [],
            healthIssueIds: this.props.selectedEntry && this.props.selectedEntry.health_issues_ids ? this.props.selectedEntry.health_issues_ids : [],
        }
        this.onFileChange = this.onFileChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onSaveAddUpdateModal = this.onSaveAddUpdateModal.bind(this)


    }


    /**
     * Creates an array with only the health issues that the entry is part of.
     * @param {*} entry - document entry
     */
    getHealthIssueList = (entry) => {
        let healthIss = [];
        if (entry.health_issues_ids) {
            if (this.props.healthIssues && this.props.healthIssues.entries && this.props.healthIssues.entries.length > 0) {
                entry.health_issues_ids.map((ids) => {
                    this.props.healthIssues.entries.map((h) => {
                        if (ids === h.id) {
                            healthIss.push(h)
                        }
                    });
                });
                return healthIss;
            }
        }
    }


    onSelect = (selectedItem) => {
        let selectedIssuesArray = this.state.selectedHealthIssues;
        let selectedIssuesArrayIds = this.state.healthIssueIds;
        selectedIssuesArray.push(selectedItem.value);
        selectedIssuesArrayIds.push(selectedItem.value.id);
        this.setState({
            selectedHealthIssues: selectedIssuesArray,
            healthIssueIds: selectedIssuesArrayIds
        })
    }

    onRemove = (removedItem) => {
        let selectedIssuesArray = this.state.selectedHealthIssues;
        let selectedIssuesArrayIds = this.state.healthIssueIds;
        selectedIssuesArray.pop(removedItem);
        selectedIssuesArrayIds.pop(removedItem.id);
        this.setState({
            selectedHealthIssues: selectedIssuesArray,
            healthIssueIds: selectedIssuesArrayIds
        })
    }

    /**
     * Set the state to the latest selected date.
     *
     * @param {object} evt - The event handler argument
     */
    onDateChange = (date) => {

        this.setState({date: moment(date).valueOf()})

    };

    getLocale = () => {
        switch (this.props.i18n.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            case "sq":
                return sq
            default:
                return enGB
        }
    }

    onTimeChange = (time) => {

        this.setState({time: moment(time).valueOf()})
    }


    /**
     * Handle form creation or update based on the selected entry
     * @param {Object} form - request object to be sent to the server
     */
    handleCreateOrUpdate = async (form) => {
        this.setState({showProcessingModal: true})
        if (this.props.selectedEntry) {
            await this.props.updateEntry(form, this.props.selectedUser.id);
            this.props.clearSelectedEntry();
        } else {
            await this.props.createEntry(form, this.props.selectedUser.id);
        }
        this.setState({showProcessingModal: false})
        this.onHideAddUpdateModal();
    }


    /**
     * Set the state to the latest change in the select.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = (e) => {
        this.setState({
            selectedDocumentType: e.value,
            showOtherForm: false,
            errors: {},
            formclass: "",
            changedOnSelect: true,
        });
        if (e.value === "OTHER")
            this.setState({showOtherForm: true});
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        if (evt.target) {
            fields[evt.target.name] = evt.target.value;
        } else {
            fields[evt.name] = evt.value;
        }
        this.setState(fields);
    }


    /**
     * Hide the modal
     */
    onHideAddUpdateModal = () => {
        this.setState({
            selectedDocumentType: '',
            date: '',
            time: '',
            errors: {},
            formclass: '',
            category: '',
            deleted: false,
            filename: '',
            mime_type: '',
            notes: '',
            shared: false,
            size: 0,
            title: '',
            typeDescription: '',
            healthIssueIds: [],
            showOtherForm: false,
        });
        this.props.onHide();
    }

    /**
     * Set the state to the latest change in the checkbox value.
     */
    toggleCheckboxChange = () => {
        this.setState({
            shared: !this.state.shared,
        });
    }

    onFileChange(e) {
        this.setState({
            fileChanged: true,
            file: e.target.files[0],
            filename: e.target.files[0].name,
            mime_type: e.target.files[0].type,
            size: e.target.files[0].size,
        })
    }

    /**
     * Form submit handler, validate data and set error in state if any. Call create logbook entry action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();
    }


    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        let d = new Date(this.state.date);

        const errors = {};
        this.setState({errors: ''});
        if (!this.state.date || !this.state.time) {
            errors.datetime = 'datetime_required_message';
        } else if (d > moment().valueOf() || this.state.time > moment().valueOf()) {
            errors.datetime = 'datetime_max_value_message';
        }
        if (!this.state.filename) {
            errors.file = 'file_required_message';
        }
        if (!this.state.typeDescription && this.state.showOtherForm) {
            errors.typeDescription = 'type_description_required_message';
        }
        this.setState({errors});
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }
        return Object.keys(errors).length === 0;

    }


    /**
     * Create new entry or update existing entry from list
     */
    onSaveAddUpdateModal = () => {
        if (this.validate()) {
            let d = new Date(this.state.date);
            let t = new Date(this.state.time)
            d.setHours(t.getHours());
            d.setMinutes(t.getMinutes());
            let datetime = moment(d).valueOf();
            let form = {};
            if (this.props.selectedEntry) {
                form = {...this.props.selectedEntry}
            }
            if (!this.props.selectedEntry) {
                form = {
                    ...form,
                    type: this.state.selectedDocumentType,
                    typeDescription: this.state.typeDescription,
                    user_id: this.props.selectedUser.id,
                    deleted: false,
                    shared: this.state.shared,
                    date_time: datetime,
                    notes: this.state.notes,
                    created_timestamp: moment().valueOf(),
                    updated_timestamp: moment().valueOf(),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    category: this.state.category,
                    title: this.state.title,
                    size: this.state.size,
                    health_issues_ids: this.state.healthIssueIds,
                    mime_type: this.state.mime_type,
                    filename: this.state.filename,
                    attachment_updated_timestamp: moment().valueOf(),
                };

            } else {
                form = {
                    ...form,
                    date_time: datetime,
                    notes: this.state.notes,
                    updated_timestamp: moment().valueOf(),
                    type: this.state.selectedDocumentType,
                    typeDescription: this.state.typeDescription,
                    shared: this.state.shared,
                    category: this.state.category,
                    title: this.state.title,
                    size: this.state.size,
                    mime_type: this.state.mime_type,
                    filename: this.state.filename,
                    attachment_updated_timestamp: moment().valueOf(),
                    health_issues_ids: this.state.healthIssueIds,
                };
                if (this.state.typeDescription !== "")
                    form = {...form, typeDescription: this.state.typeDescription}
            }
            const formData = new FormData();
            formData.append('document', new Blob([JSON.stringify(form)], {type: "application/json"}));
            if (this.state.file != null) {
                formData.append("file", this.state.file);
            }
            this.handleCreateOrUpdate(formData)
        }
    }


    render() {
        let errorMsg = '';
        classnames('my-1 mr-sm-2', {
            'custom-error form-control': this.state.errors.datetime,
            'form-control': !this.state.errors.datetime,
        });
        return (
            <CenteredModal
                title={this.props.selectedEntry ? $$('update_entry') : $$('document_label')}
                dialogClassName='add-doc-modal'
                show={this.props.show}
                onHide={this.props.onHide}
                confirmBtnLabel={$$('save_btn_label')}
                cancelBtnLabel={$$('cancel_btn')}
                onConfirm={this.onSaveAddUpdateModal}
            >
                <FormWithLoading
                    formDisabled={this.props.formDisabled}
                    currentForm={DOCUMENT}
                    marginTop="30%"
                    marginLeft="calc(50% - 70px)"
                    id="documentForm"
                    onSubmit={this.onSubmit}
                    className={this.state.formclass}
                    noValidate={true}>
                    {errorMsg}
                    <div className="row" style={{'marginTop': '4%'}}>
                        <div className="col-sm-12 col-md-12">
                            <div className="row form-group">
                                <div className="col-sm-6">
                                    {<label>{$$("document_title")}</label>}
                                    <input type="text" className="form-control"
                                           value={this.state.title || ''}
                                           maxLength="80"
                                           placeholder={$$('document_title')}
                                           name="title"
                                           onChange={this.onInputChange}
                                           disabled={this.props.selectedEntry && this.props.selectedEntry.created_by_provider}
                                    />
                                    <div
                                        className={this.state.errors.title ? "custom-invalid-feedback" : "invalid-feedback"}
                                        style={{"textAlignLast": "center"}}>
                                        {this.state.errors.title ? $$(this.state.errors.title) : $$("document_title_required_message")}
                                    </div>
                                </div>
                                <div className='col-sm-3'>
                                    <MuiPickersUtilsProvider locale={this.getLocale()}
                                                             utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            variant="inline"
                                            format={this.props.settings.data.dateFormat}
                                            margin="normal"
                                            id="date-picker-inline"
                                            label={$$("log_symptoms_date_label")}
                                            value={this.state.date}
                                            onChange={this.onDateChange}
                                            KeyboardButtonProps={{'aria-label': 'change date',}}
                                            required
                                            disabled={this.props.selectedEntry && this.props.selectedEntry.created_by_provider}
                                            invalidDateMessage={$$('invalid_date_format')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className='col-sm-3'>
                                    <MuiPickersUtilsProvider locale={this.getLocale()}
                                                             utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            ampm={!this.props.settings.data.time24hour}
                                            margin="normal"
                                            id="time-picker"
                                            okLabel={$$("OK")}
                                            cancelLabel={$$("cancel_btn")}
                                            label={$$("log_symptoms_time_label")}
                                            value={this.state.time}
                                            KeyboardButtonProps={{'aria-label': 'change time',}}
                                            onChange={this.onTimeChange}
                                            required
                                            disabled={this.props.selectedEntry && this.props.selectedEntry.created_by_provider}
                                            invalidDateMessage={$$('invalid_time_format')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div
                                    className={this.state.errors.datetime ? "custom-invalid-feedback" : "invalid-feedback"}
                                    style={{"textAlignLast": "center"}}>
                                    {this.state.errors.datetime ? $$(this.state.errors.datetime) : $$("datetime_required_message")}
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-sm-6">
                                    <Select
                                        name="type"
                                        label={$$("document_type_label")}
                                        labelClass=""
                                        options={getResolvedOptions(DOCUMENTS.TYPE)}
                                        value={this.state.selectedDocumentType}
                                        onChange={this.onSelectChange}
                                        placeHolder={$$('type_label')}
                                        selectDisabled={this.state.selectDisabled || (this.props.selectedEntry && this.props.selectedEntry.created_by_provider)}/>
                                </div>
                            </div>
                            {this.state.showOtherForm && <div className="form-group">
                                {this.state.typeDescription && <label>{$$("type_description_label")}</label>}
                                <input type="text" className="form-control"
                                       value={(this.state.typeDescription === "undefined") ? '' : this.state.typeDescription}
                                       maxLength="40"
                                       placeholder={$$('type_description_label')} name="typeDescription"
                                       disabled={this.props.selectedEntry && this.props.selectedEntry.created_by_provider}
                                       onChange={this.onInputChange} required/>
                                <div
                                    className={this.state.errors.typeDescription ? "custom-invalid-feedback" : "invalid-feedback"}
                                    style={{"textAlignLast": "center"}}>
                                    {this.state.errors.typeDescription ? $$(this.state.errors.typeDescription) : $$("type_description_required_message")}
                                </div>
                            </div>}
                            <div className="form-group">
                                {<label>{$$("health_issues_label")}</label>}
                                <CustomMultiselect
                                    options={getHealthIssueOptions(this.props.healthIssues.entries)}
                                    selectedValues={this.state.selectedHealthIssues}
                                    onSelect={this.onSelect}
                                    onRemove={this.onRemove}
                                    displayValue="text"
                                    placeholder={$$('select_health_issue_label')}
                                    closeIcon='cancel'
                                    avoidHighlightFirstOption={true}
                                    isFieldValid={this.state.validLanguagesField}
                                    formClass={this.state.formclass}/>
                            </div>

                            <div className="form-group">
                                <textarea className="form-control"
                                          value={this.state.notes || ''}
                                          maxLength="500"
                                          placeholder={$$('notes')}
                                          name="notes"
                                          onChange={this.onInputChange}/>
                            </div>
                            <br/>
                            {!(this.props.selectedEntry && this.props.selectedEntry.created_by_provider) &&
                                <div className="form-group row">
                                    <label htmlFor="file" className="col-sm-6 col-lg-6 col-xl-4 mb-3 mx-auto">
                                        <div className="form-control btn-primary text-nowrap w-100 pointer"
                                        ><span className="flaticon-add"></span>&nbsp;&nbsp;&nbsp;{$$("upload_document")}
                                        </div>
                                    </label>
                                    <input type="file" name="file" id="file" className="dropzone-file-input"
                                           onChange={this.onFileChange} required={!this.props.selectedEntry}/>
                                    <div className="mx-3 col-sm-6 col-lg-6 col-xl-8 mb-3 mx-auto">
                                        <div className="form-control" style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}>{this.state.filename ? this.state.filename : $$("no_file_chosen")}</div>
                                    </div>
                                    {!this.props.selectedEntry &&
                                        <div
                                            className={this.state.errors.file ? "custom-invalid-feedback" : "invalid-feedback"}
                                            style={{"textAlignLast": "center"}}>
                                            {this.state.errors.file ? $$(this.state.errors.file) : $$("file_required_message")}
                                        </div>
                                    }
                                </div>}
                        </div>
                    </div>
                </FormWithLoading>
            </CenteredModal>
        )
    }
}

DocumentForm.propTypes = {
    clearSelectedEntry: PropTypes.func,
    createEntry: PropTypes.func,
    selectedEntry: PropTypes.any,
    fetchSelectedUserHealthIssues: PropTypes.func,
    formDisabled: PropTypes.object,
    healthIssues: PropTypes.object,
    onHide: PropTypes.func,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    i18n: PropTypes.any,
    show: PropTypes.bool,
    updateEntry: PropTypes.func
}

export default DocumentForm;

import {fetchHelper} from '../helpers/request_helper'
import {
    ALL_APPOINTMENTS_FOR_USER,
    CREATE_APPOINTMENT, CREATE_PAYMENT,
    GET_APPOINTMENT,
    UPDATE_APPOINTMENT,
    UPDATE_APPOINTMENT_STATUS_URL,
    GET_QUESTIONNAIRE_BY_ENCOUNTER_ID_URL,
    GET_NEXT_APPOINTMENT,
    GET_ACTIVE_CONSULTATIONS_APPOINTMENT,
    GET_HAS_ACTIVE_FUTURE_APPOINTMENT
} from '../constants/api_paths'

export const appointmentsService = {
    fetchSelectedUserAppointments,
    updateAppointmentStatus,
    createAppointment,
    updateAppointment,
    fetchAppointment,
    createPayment,
    fetchNextAppointment,
    fetchActiveConsultations,
    hasFutureAppointment
};

/**
 * Fetch the appointments method
 *
 * @param {number} userId id of the user to fetch appointments for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserAppointments(userId, params) {
    return fetchHelper.callGet(ALL_APPOINTMENTS_FOR_USER.replace('{userId}', userId), params);
}

export function fetchAppointment(appointmentId) {
    return fetchHelper.callGet(GET_APPOINTMENT.replace('{appointmentId}', appointmentId));
}

export function fetchNextAppointment(userId) {
    return fetchHelper.callGet(GET_NEXT_APPOINTMENT, {userId:userId});
}

export function fetchActiveConsultations(userId) {
    return fetchHelper.callGet(GET_ACTIVE_CONSULTATIONS_APPOINTMENT, {userId:userId});
}

export function hasFutureAppointment(userId, providerId) {
    return fetchHelper.callGet(GET_HAS_ACTIVE_FUTURE_APPOINTMENT, {userId:userId, providerId:providerId})
}

/**
 * Update status for selected appointment
 *
 * @param {object} appointmentId - id of the selected appointment
 * @param {object} status - new status for selected appointment
 * @param {object} note - note to be sent with the appointment
 * @param {string} userId - User ID
 * @returns {object} promise object
 */
export function updateAppointmentStatus(appointmentId, status, note, userId) {
    return fetchHelper.callPut(UPDATE_APPOINTMENT_STATUS_URL.replace('{appointmentId}', appointmentId).replace('{status}', status).replace('{userId}', userId), note);
}


export function createAppointment(appointment, userId) {
    return fetchHelper.callPost(CREATE_APPOINTMENT.replace('{userId}', userId), appointment, false)
}

export function updateAppointment(appointment) {
    return fetchHelper.callPut(UPDATE_APPOINTMENT, appointment, false)
}

export function createPayment(body) {
    return  fetchHelper.callPost(CREATE_PAYMENT, body, false)
}

export function fetchQuestionnaireIdFromPrice(encounterPriceId) {
    return fetchHelper.callGet(GET_QUESTIONNAIRE_BY_ENCOUNTER_ID_URL.replace("{encounterPriceId}", encounterPriceId));
}
import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import PropTypes from "prop-types";
import {isPasswordValid} from "../../utils/validatePassword";
import PasswordValidity from "../shared/PasswordValidity";

export class PasswordForm extends Component {

    state = {
        userPasswordData: this.props.userPassword,
        password: '',
        rePassword: '',
        errors: {}
    }

    constructor(props) {
        super(props);
    }

    /**
     * Check if update opeartion is done, if so display successful update message.
     * Display error message if the request has failed.
     *
     * @param {object} prevProps - the previous props
     */
    componentDidUpdate(prevProps) {
        if (prevProps.userPassword && prevProps !== this.props && !this.state.successfulUpdate &&
            !this.state.requestError && this.props.userPassword.request.finished) {
            if (this.props.userPassword.request.success) {
                this.setState({successfulUpdate: true});
            } else {
                this.setState({requestError: true});
            }
        }

        if (!this.state.successfulUpdate && !this.state.requestError && prevProps !== this.props &&
            this.props.userPassword.request.sent && !this.props.userPassword.request.finished) {
            this.setState({
                userPasswordData: this.props.userPassword,
                password: '',
                rePassword: '',
                formclass: '',
                successfulUpdate: false,
                requestError: false,
                errors: {}
            });
        }
    }

    componentWillUnmount() {
        this.props.clearUserPassword();
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    }

    /**
     * Form submit handler, validate data and set error in state if any. Call updateUserPassword action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        const formErrors = this.validate();
        this.setState({errors: formErrors});
        evt.preventDefault();
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }

        if (Object.keys(formErrors).length) {
            return;
        }
        if (evt.target.checkValidity() === true) {
            this.props.updateUserPassword({password:this.state.password});
            this.setState({
                userPasswordData: this.props.userPassword,
                password: '',
                rePassword: '',
                formclass: '',
                errors: {}
            });
        }
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        if (this.state.password !== this.state.rePassword) {
            errors.password = 'register_form_passwords_not_match_message';
        }
        if (this.state.password &&  !isPasswordValid(this.state.password)) {
            errors.password = 'password_is_not_valid';
        }
        if (this.state.password.trim().length !== this.state.password.length) {
            errors.password = 'register_form_password_whitespace_message';
        }
        return errors;
    }


    render() {
        let alert = '';
        if (this.state.successfulUpdate) {
            alert = (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {$$('user_password_updated_message')}
                </div>
            );
        }

        if (this.state.requestError) {
            alert = (
                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    {$$('user_password_update_error_message')}
                </div>
            );
        }

        return (
            <div className="centered-form">
                <div>
                    <h2 className="text-center card-title">{$$("change_password_label")}</h2>
                    <br/>
                </div>
                <div>
                    <div className="row">
                        <div className="col-xs-12 col-md-12">
                            <div className="row">
                                <div className="text-center password-form-content">
                                    <form onSubmit={this.onSubmit} className={this.state.formclass} noValidate={true}>
                                        {alert}
                                        <div className="form-group">
                                            <input type="password"
                                                   className={this.state.errors.password ? "custom-error form-control" : "form-control"}
                                                   value={this.state.password} placeholder={$$('new_password_label')}
                                                   name="password" onChange={this.onInputChange} required
                                                   minLength={8}/>
                                            <div
                                                className={this.state.errors.password ? "custom-invalid-feedback" : "invalid-feedback"}>
                                                {this.state.errors.password ? $$(this.state.errors.password) : $$('password_required_message')}
                                            </div>
                                            <PasswordValidity password = {this.state.password}/>
                                        </div>
                                        <div className="form-group">
                                            <input type="password" className="form-control"
                                                   value={this.state.rePassword}
                                                   placeholder={$$('repeat_password_label')} name="rePassword"
                                                   onChange={this.onInputChange} required/>
                                            <div className="invalid-feedback">
                                                {$$('register_form_password_confirm_required_message')}
                                            </div>
                                        </div>
                                        <div className="form-group password-form-buttons">
                                            <div className="col-xs-11 col-md-11 mx-auto">
                                                <button type="submit" className="btn btn-primary btn-block"
                                                        disabled={this.props.userPassword.request.sent && !this.props.userPassword.request.finished}>{$$('change_password_label')}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

PasswordForm.propTypes = {
    clearUserPassword: PropTypes.func,
    updateUserPassword: PropTypes.func,
    userPassword: PropTypes.object
};

export default PasswordForm

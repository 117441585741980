import React, {Component} from 'react';
import StepProgressBar from "./components/step-progress-bar/StepProgressBar";
import Question from "./components/question/Question";
import Button from "react-bootstrap/Button";
import QuestionnaireSummary from "./components/questionnaire-summary/QuestionnaireSummary";
import SelectionType from "./models/selection-type/SelectionType";
import QuestionnaireUtil from "./util/QuestionnaireUtil";
import {$$} from "../helpers/localization";

class Questionnaire extends Component {
    title;
    totalSteps = 0;
    questionIdToIndex = new Map();
    questionHistory = [];
    currentIndex = 0;
    questions;
    language;

    constructor(props) {
        super(props);
        this.initComponent();
        this.state = {
            currentStep: 0,
            question: this.questions[0]
        };
    }

    render() {
        return (
            <div className="d-flex flex-column align-items-center questionnaire">
                <h3>{this.title}</h3>
                <StepProgressBar currentStep={this.state.currentStep} totalSteps={this.totalSteps}/>
                <div className="question-container">
                    <Question key={this.state.currentStep}
                              question={this.state.question}
                              language={this.language}
                              updateAnswer={() => this.forceUpdate()}/>
                    {
                        this.state.currentStep + 1 === this.totalSteps &&
                        this.state.summary &&
                        <QuestionnaireSummary summary={this.state.summary}/>
                    }
                </div>
                <div className="d-flex flex-row mt-3 pb-3">
                    {this.state.currentStep > 0 && <Button onClick={this.handlePreviousButton} variant="secondary mr-5">{$$("back_label")}</Button>}
                    {
                        this.state.currentStep + 1 < this.totalSteps &&
                        <Button onClick={this.handleNextButton}
                                disabled={this.isNextButtonDisabled()}
                                variant="primary">{$$("continue")}</Button>
                    }
                    {   false &&
                        this.state.currentStep + 1 === this.totalSteps &&
                        <Button onClick={this.handleFinishButton}
                                variant="primary">{$$("complete_btn")}</Button>
                    }
                </div>
            </div>
        );
    }

    handleFinishButton = () => {
        this.props.onComplete(this.state.summary);
    };

    handleNextButton = () => {
        if (this.state.currentStep >= this.totalSteps) {
            return;
        }
        this.questionHistory.push(this.state.question);
        const nextQuestion = this.getNextQuestion();
        nextQuestion.answers?.forEach(a => a.topic_text = null)
        if (nextQuestion.id === 'finish') {
            this.buildSummary();
        }
        this.setState(() => ({
            currentStep: this.currentIndex,
            question: nextQuestion
        }));
    };

    handlePreviousButton = () => {
        if (this.state.currentStep < 1) {
            return;
        }
        const previousQuestion = this.questionHistory.pop();
        previousQuestion.answers?.forEach(a => a.topic_text = null)
        this.currentIndex = this.questionIdToIndex.get(previousQuestion.id);
        this.setState(() => ({
            currentStep: this.currentIndex,
            question: previousQuestion
        }));
    };

    initComponent = () => {
        const questionnaire = this.props.questionnaire;
        this.questions = questionnaire.questions;
        this.language = this.getLanguage();
        this.title = questionnaire.title[this.language];
        this.totalSteps = questionnaire.questions.length;
        this.normalizeQuestions(questionnaire.questions);
    };

    normalizeQuestions = (questions) => {
        questions.forEach((question, index) => {
            if (question.id == null) {
                question.id = index;
            }
            this.questionIdToIndex.set(question.id, index);
        });
        questions.forEach((question, index) => this.normalizeAnswers(question, index));
    };

    getLanguage = () => {
        return QuestionnaireUtil.resolveLanguage(Object.keys(this.props.questionnaire.title));
    };

    normalizeAnswers = (question, currentQuestionIndex) => {
        question.answers?.forEach((answer, index) => {
            //answer.checked = false;
            answer.group = question.id;
            if (answer.id == null) {
                answer.id = index;
            }
            if (answer.nextQuestionId == null) {
                answer.nextQuestionId = this.questions[currentQuestionIndex + 1].id;
            }
        });
    };

    getNextQuestion = () => {
        const selectedAnswer = this.state
            .question
            ?.answers
            ?.find(answer => answer.checked);

        this.currentIndex = selectedAnswer ? this.questionIdToIndex.get(selectedAnswer.nextQuestionId) : this.currentIndex + 1;

        const nextQuestion = this.questions[this.currentIndex];

        if (nextQuestion.action == null) {
            return nextQuestion;
        }

        this.currentIndex = this.questionIdToIndex.get(extractId(nextQuestion.action));
        return this.questions[this.currentIndex];
    };

    isNextButtonDisabled = () => {
        return this.atLeastOneAnswerRequired() || this.allAnswersRequired();
    };

    allAnswersRequired = () => {
      return this.state.question.selectionType === SelectionType.ALL &&
          this.state.question.answers?.some(answer => !answer.checked);
    };

    atLeastOneAnswerRequired = () => {
        return this.state.question.answerRequired &&
            this.state.question.answers?.every(answer => !answer.checked);
    };

    buildSummary = () => {
        const questionnaire = this.props.questionnaire;
        const summary = {};
        summary.questionnaireId = questionnaire.id;
        summary.type = questionnaire.type;
        summary.title = questionnaire?.title[this.language];
        let currentTopic = null;
        summary.results = this.questionHistory.reduce((acc, question) => {
            const checkedAnswers = question.answers?.filter(answer => answer.checked)

            checkedAnswers?.forEach(answer => {
                if (answer.topic) {
                    answer.topic_text = answer.topic[this.language];
                    currentTopic = answer.topic_text;
                } else {
                    let topic = null;
                    for (const currentAnswer of question.answers) {
                        if (currentAnswer.topic) {
                            topic = currentAnswer.topic[this.language];
                        }
                        if (answer.id === currentAnswer.id && topic && topic !== currentTopic) {
                            answer.topic_text = topic;
                            currentTopic = topic;
                        }
                    }
                }
            });

            if (checkedAnswers?.length) {
                acc.push(this.toQuestionDto(question, checkedAnswers));
            }

            return acc
        }, []);

        this.setState({summary});
        this.props.onSummaryReady(summary)
    };

    toQuestionDto = (question, checkedAnswers) => {
        return {
            question_id: question.id,
            question_text: question?.question[this.language],
            answers_results: checkedAnswers.map(answer => this.toAnswerDto(answer))
        }
    };

    toAnswerDto = (answer) => {
        return {
            answer_id: answer.id,
            answer_text: answer.label[this.language],
            //result_text: answer.display ? answer.display.replace("{}", answer.result) : answer.result,
            result_text: answer.result,
            result_tag: answer.tag,
            display: answer.display,
            topic: answer.topic_text
        };
    }
}

export default Questionnaire


function extractId(action) {
    if (action.indexOf("id:") > -1) {
        return action.replace("id:", '');
    }
    return action;
}
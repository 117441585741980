import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import moment from "moment";
import ChronicConditions from '../medical-profile/ChronicConditions'
import Allergies from '../medical-profile/Allergies'
import {WeightHeightandBlood} from "./medicalProfile/WeightHeightandBlood";
import {ReactComponent as DeleteIcon} from "../../../public/media/icons/svg/Design/Delete.svg";
import {ReactComponent as EditIcon} from "../../../public/media/icons/svg/Design/Edit.svg";
import PropTypes from "prop-types";
import {dateTimeUtils} from "../../utils/dateTimeUtils";
import classNames from 'classnames'


export class MedicalProfile extends Component {
    state = {
        bloodGroupEdit: true,
        weightHeightEdit: true,
        editAllergy: false,
        editChronicCondition: false,
        selectedAllergy: null,
        selectedChronicCondition: null,
        selectedAllergyToDelete: null,
        selectedConditionToDelete: null,
        loaded: false,
        additionalInfoSectionOpened: false,
        peak_flow_norm: ""
    }

    componentDidMount() {
        this.props.fetchSelectedUserMedicalProfile(this.props.selectedUser.id).then(() => {
            this.setState({loaded:true})
            if (this.props.medicalProfile?.data?.peak_flow_norm) {
                this.setState({additionalInfoSectionOpened: true, peak_flow_norm: this.props.medicalProfile.data.peak_flow_norm})
            }
        })
    }

    constructor(props) {
        super(props);
    }

    onBloodGroupEdit = (state) => {
        this.setState({
            bloodGroupEdit: state,
        })
    };

    onAllergyEdit = (state, allergy) => {

        this.setState({
            editAllergy: state,
            selectedAllergy: allergy,
        })
    };
    onChronicConditionEdit = (state, chronicCondition) => {
        this.setState({
            editChronicCondition: state,
            selectedChronicCondition: chronicCondition,
        })
    };
    onWeightHeightEdit = (state) => {
        this.setState({
            weightHeightEdit: state,
        })
    };


    showAllergyDeletedConfirmationModal = (allergyToDelete) => {

        this.setState({
            selectedAllergyToDelete: allergyToDelete
        })
    }

    showConditionConfirmationDeletedModal = (key) => {
        this.setState({
            selectedConditionToDelete: key
        })
    }

    clearAllergy = () => {

        this.setState({
            selectedAllergy: null,
            selectedAllergyToDelete: null
        })
    }
    clearChronicCondition = () => {
        this.setState({
            selectedChronicCondition: null,
            selectedConditionToDelete: null
        })
    }

    render() {
        return <div>
            {this.state.loaded && <WeightHeightandBlood
                {...this.props}
                weightHeightEdit={this.state.weightHeightEdit}
                onWeightHeightEdit={this.onWeightHeightEdit}
                blood_type={this.props.medicalProfile.data.blood_type}
                bloodGroupEdit={this.state.bloodGroupEdit}
                onBloodGroupEdit={this.onBloodGroupEdit}
                changeWeightHeightAndBlood={this.props.changeWeightHeightAndBlood}
                enableUpdateButton={this.props.enableUpdateButton}
            />}

            {this.state.loaded && <div className="">
                <div className={"new-header row justify-content-start"}  >
                    <div className={"add-new-condition btn-link pointer font-weight-bold"}
                         onClick={()=>{this.setState({additionalInfoSectionOpened: !this.state.additionalInfoSectionOpened})}}>{$$("additional_info")}
                         <span className={classNames("fas pl-2", {"fa-chevron-down":!this.state.additionalInfoSectionOpened, "fa-chevron-up":this.state.additionalInfoSectionOpened})} />
                    </div>
                </div>
                { this.state.additionalInfoSectionOpened && <div className={""}>
                    <div className={"col-md-4 col-sm-12"}>
                        <div className="font-weight-bold p-2">{$$("peak_flow_norm")}</div>
                        <div>
                            <input type="number" className={"form-control"}
                                   value={this.state.peak_flow_norm} onChange={(e) => {
                                       this.setState({peak_flow_norm: e.target.value.trim()})
                                       this.props.onPeakFlowNormChanged(e.target.value);
                            }}/>
                        </div>
                    </div>
                </div>
                }
            </div>}

            <ChronicConditions
                {...this.props}
                onChronicConditionEdit={this.onChronicConditionEdit}
                clear={this.clearChronicCondition}
                selectedCondition={this.state.editChronicCondition ? this.state.selectedChronicCondition : null}
                selectedEntryToDelete={this.state.selectedConditionToDelete}
                condition={(condition, idx) => {
                    let body = <div>
                        <div className="word-break">
                            <p className="small text-danger">{$$("chronic_condition")} </p>
                            <h6 className="card-title">{condition.name}</h6>
                            <p className="small"><span
                                className=" wizard-title">{$$("health_issue_description")}:</span> {condition.description ? <>  {condition.description}</> : ""}
                            </p>
                            <p className="small"><span className=" wizard-title">{$$("diagnosed_in")}:</span>
                                {condition.date_diagnosed > 0 ? " " + dateTimeUtils.getFormattedMonthYear(condition.date_diagnosed) : " --"}
                            </p>
                        </div>
                        <span/>

                        <span onClick={() => this.showConditionConfirmationDeletedModal(condition)}
                              className="pointer condition-delete">
                            <DeleteIcon className="svg-icon"
                                        height="17px"
                                        width="17px"
                                        fill = '#02555bcf'/>
                        </span>

                        <span onClick={() => this.onChronicConditionEdit(true, condition)}
                              className="pointer condition-edit">
                            <EditIcon className="svg-icon"
                                      height="17px"
                                      width="17px"
                                      fill = '#02555bcf' />
                        </span>

                    </div>
                    return <div className="col-md-6 col-xs-12" key={"cc" + idx}>
                        <div className="low-shadow-container condition-card-1 flex-start">
                            {body}
                        </div>
                    </div>
                }}/>

            <Allergies
                {...this.props}
                onAllergyEdit={this.onAllergyEdit}
                clear={this.clearAllergy}
                selectedAllergy={this.state.editAllergy ? this.state.selectedAllergy : null}
                selectedEntryToDelete={this.state.selectedAllergyToDelete}
                callbackfn={
                    (allergy, idx) => {
                        let body = allergy.id !== undefined ?
                            <div>
                                <div className="word-break">
                                    <p className="small text-danger">{$$("allergy_condition")} </p>
                                    <h6 className="card-title">{allergy.name}</h6>
                                    <p className="small"><span
                                        className=" wizard-title">{$$("Medications")}:</span> {allergy.medications ?
                                        <>{allergy.medications}</> : ""} </p>
                                    <p className="small"><span className=" wizard-title">{$$("diagnosed_in")}:</span>
                                        {allergy.date_diagnosed > 0 ? " " + dateTimeUtils.getFormattedMonthYear(allergy.date_diagnosed) : " --"}
                                    </p>
                                </div>
                                <span onClick={() => this.showAllergyDeletedConfirmationModal(allergy)}
                                      className="pointer condition-delete">
                                    <DeleteIcon className="svg-icon"
                                                height="17px"
                                                width="17px"
                                                fill = '#02555bcf'/>
                                </span>

                                <span onClick={() => this.onAllergyEdit(true, allergy)}
                                      className="pointer condition-edit">
                                    <EditIcon className="svg-icon"
                                              height="17px"
                                              width="17px"
                                              fill = '#02555bcf'/>
                                </span>

                            </div> :
                            <div className="card-body light-green">
                                <h6 className="card-title">{$$("no_allergies")}</h6>
                            </div>;

                        return <div className="col-md-6 col-xs-12" key={"cc" + idx}>
                            <div className="low-shadow-container condition-card-1 flex-start">
                                {body}
                            </div>
                        </div>

                    }}/>
        </div>
    }
}

MedicalProfile.propTypes = {
    changeWeightHeightAndBlood: PropTypes.func,
    enableUpdateButton: PropTypes.func,
    i18n : PropTypes.object,
    medicalProfile : PropTypes.object,
    selectedUser : PropTypes.object,
    fetchSelectedUserMedicalProfile : PropTypes.func,
    clearMedicalProfile : PropTypes.func
};

import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    clearUserPassword,
    updateUserPassword
} from '../../actions/users_actions'
import PasswordForm from './PasswordForm';
import PropTypes from "prop-types";

class Password extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <PasswordForm
                    userPassword={this.props.userPassword}
                    updateUserPassword={this.props.updateUserPassword}
                    clearUserPassword={this.props.clearUserPassword}
                />
            </div>

        )
    }
}

Password.propTypes = {
    clearUserPassword: PropTypes.func,
    history: PropTypes.object,
    i18n: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    updateUserPassword: PropTypes.func,
    userPassword: PropTypes.object
};


const mapStateToProps = (state) => ({
    userPassword: state.userPassword,
    i18n: state.language.selected
})

const mapDispatchToProps = {
    updateUserPassword,
    clearUserPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(Password)

import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import Select from '../shared/Select'
import {
    getResolvedOptions,
    LOGBOOK_TYPES
} from '../../constants/select_options'
import PropTypes from "prop-types";
import DateRangePicker from "../shared/DateRangePicker";

class LogbookHeader extends Component {
    state = {
        symptomDate: new Date(),
    }

    constructor(props) {
        super(props);
    }

    handleReset = () => {
        let date = new Date();
        this.setState({symptomDate: date});
    }


    render() {
        return (
            <div className="form-inline" style={{'backgroundColor': 'white', 'paddingTop': '10px'}}>
                <div style={{'display': 'flex', 'flexWrap': 'wrap', 'width': '100%'}}>
                    <h1 style={{'marginRight': 'auto'}}>
                        {$$('Logbook')}
                    </h1>
                    <div className="" style={{'display': 'flex', 'flexWrap': 'wrap'}}>
                        <div className="" style={{'display': 'flex', 'marginRight': '2rem'}}>
                            <Select
                                name="issues-type"
                                options={this.props.options}
                                value={this.props.pickedOption ? this.props.pickedOption : 0}
                                onChange={this.props.onSelectChange}
                                style={{
                                    'minWidth': '-10.188rem',
                                    'minHeight': '2.938rem',
                                    'maxWidth': '18.75rem',
                                    'zIndex': '1',
                                    'opacity': '70%'
                                }}
                                placeHolder={$$('all_issues')}/>
                        </div>
                        <div className="" style={{'display': 'flex', 'marginRight': '2rem', 'marginBottom': '1.5rem'}}>
                            <Select
                                name="type"
                                options={getResolvedOptions(LOGBOOK_TYPES.TYPE)}
                                value={this.props.type}
                                onChange={this.props.onSelectType}
                                style={{
                                    'minWidth': '-10.188rem',
                                    'minHeight': '2.938rem',
                                    'maxWidth': '18.75rem',
                                    'zIndex': '1',
                                    'opacity': '70%'
                                }}
                                placeHolder={$$('logbook_type_label')}/>
                        </div>

                        <div className={"date-filter-small-screen"} style={{"position": "relative"}}>
                            <DateRangePicker
                                i18n = {this.props.i18n}
                                onRangeSelected={this.props.onRangeSelected}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

LogbookHeader.propTypes = {
    onSelectChange: PropTypes.func,
    onSelectType:  PropTypes.func,
    onRangeSelected:  PropTypes.func,
    dateFilters:  PropTypes.object,
    options: PropTypes.array,
    pickedOption:  PropTypes.any,
    i18n:  PropTypes.any,
    type:  PropTypes.string,
};

export default LogbookHeader

import moment from "moment";
import {$$} from '../helpers/localization'
import store from "../store";
import {CONVERTER} from "./converter";

export const dateTimeUtils = {
    toOffsetDateTime,
    getUtcOffset,
    getDayNames,
    getFormattedDate,
    getFormattedDateWithDay,
    getFormattedDayWithDate,
    getFormattedMonthYear,
    getLongFormattedDate,
    getFormattedDateWithLongMonth,
    getFormattedTime,
    currentTimestampWithoutSeconds,
    YEAR_IN_MS: 31557600000
}

function getFormattedDate(date){
    return(moment(date).format(CONVERTER.getSelectedDateTimeFormat(true)))
}

function getFormattedTime(date){
    return CONVERTER.formatTime(date);
}

function getFormattedDateWithLongMonth(date){
    let language = store.getState().language.selected
    return(moment(date).locale(language.lang).format("DD MMMM, YYYY"))
}

function getFormattedDateWithDay(date){
    let language = store.getState().language.selected
    return((moment(date).format(CONVERTER.getSelectedDateTimeFormat(true)) + ", " + moment(date).locale(language.lang).format('dddd')))
}

function getFormattedDayWithDate(date){
    let language = store.getState().language.selected

    let FormattedDate = moment(date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") ? $$("select_today_label") + moment(date).locale(language.lang).format(", DD MMM") : moment(date).locale(language.lang).format("ddd, DD MMM")

    let year = moment(date).format("YYYY") !== moment().format("YYYY") ? " " + moment(date).locale(language.lang).format("YYYY") : ""

    if(year !== ""){
        return FormattedDate + " " + year
    }

    return FormattedDate

}

function getLongFormattedDate(date){
    let language = store.getState().language.selected

    let FormattedDate = moment(date).locale(language.lang).format(" DD MMM")

    let year = moment(date).format("YYYY") !== moment().format("YYYY") ? " " + moment(date).locale(language.lang).format("YYYY") : ""

    if(year !== ""){
        return FormattedDate + " " + year
    }

    return FormattedDate

}

function getFormattedMonthYear(date){
    let language = store.getState().language.selected
    return moment(date).locale(language.lang).format('MMMM YYYY')
}

function toOffsetDateTime(date) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ssZZ');
}

function getUtcOffset(date) {
    return moment(date)
      .subtract(
        moment(date).utcOffset(), 
        'minutes')
      .utc()
  }

function getDayNames(day) {
  
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

  return $$(days[day-1]);
}


function currentTimestampWithoutSeconds() {
    return moment().seconds(0).milliseconds(0).valueOf()
}
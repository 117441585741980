import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import classnames from 'classnames';
import classNames from 'classnames';
import {formatUtils} from '../../utils/formatUtils'
import HealthIssueTag from '../health/HealthIssueTag';
import {ReactComponent as FileIcon} from "../../../public/media/icons/svg/Design/File.svg";
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";
import PropTypes from "prop-types";
import {convertDocumentTypeToText} from "../../utils/documentUtils";


export class DocumentEntry extends Component {
    constructor(props) {
        super(props);
    }


    /**
     * Confirm the registration success modal, navigate to login
     *
     * @param {object} e the event object
     */
    onFileNameClick = (e) => {
        e.preventDefault();
        this.props.showModal(this.props.entry.id, this.props.entry.filename);
    }

    /**
     * Creates the health issues list
     *
     * @param {object} entry - the document object
     * @returns {ReactElement}
     */
    getHealthIssueList = (entry) => {
        let healthIss = [];
        if (entry.health_issues_ids) {
            if (this.props.healthIssues && this.props.healthIssues.entries && this.props.healthIssues.entries.length > 0) {
                entry.health_issues_ids.map((ids) => {
                    this.props.healthIssues.entries.map((h) => {
                        if (ids === h.id) {
                            healthIss.push(h.name)
                        }
                    });
                });
            }
        }
        let healthIssueList2 = healthIss.map((p, idy) => {
            return <HealthIssueTag
                key={idy}
                className='patient-health-issue-list'
                styles={{'marginLeft': '0rem', 'marginTop': "0.2rem"}}
                healthIssue={p}
            />
        });
        return <div className='text-center'>
            <div className='row' style={{'justifyContent': 'left'}}>{healthIssueList2}</div>
        </div>;
    }

    /**
     * An event handler triggered when a document is clicked
     */
    onDocumentClick = () => {
        this.props.onDocumentChange(this.props.entry);
    }

    getSelectedDocumentId = () => {
        if(this.props.selectedDocument !== null){
            return this.props.selectedDocument.id
        } else {
            return ""
        }
    }

    render() {
        classnames('card', {
            'entry-card': this.props.index !== 0
        });
        const activeClass = classNames('appointment-containter', {
            'appointment-active': (this.getSelectedDocumentId() === this.props.entry.id)
        });
        return (
            <div className={activeClass} onClick={this.onDocumentClick}
                 style={{'marginBottom': '3px', 'border': 'none'}}>

                <div className="container-fluid">
                    <div className={classnames("row",{"medrec-clinic-text-color":this.props.entry.created_by_provider})} >
                        <div className="col-sm-12 col-md-12" style={{'margin': 'none'}}>
                            <div className="row" style={{'margin': 'none'}}>
                                <div className="col-sm-12 col-md-2" style={{'marginTop': '3%', 'display': 'flex'}}>
                                    <div style={{'paddingTop': '5px', 'marginRight': '15px'}}>
                                        <FileIcon className="svg-icon" height="21px" width="29px"
                                                  style={{'fill': '#B4C5D0'}}/>
                                    </div>
                                    <div className="menu-tab2">
                                        {this.props.entry.title}
                                        <div style={{
                                            'fontSize': '13px',
                                            'color': '#9BABB6',
                                            'paddingTop': '3%'
                                        }}>{this.props.entry.filename}</div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-2" style={{'marginTop': '3%'}}>
                                    <div className="menu-tab2">
                                        {convertDocumentTypeToText(this.props.entry)}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-1" style={{'marginTop': '3%'}}>
                                    <div className="menu-tab2">{formatUtils.bytesToSize(this.props.entry.size)}</div>
                                </div>
                                <div className="col-sm-12 col-md-2" style={{'marginTop': '3%'}}>
                                    <div className="menu-tab2">{this.getHealthIssueList(this.props.entry)}</div>
                                </div>
                                <div className="col-sm-12 col-md-2" style={{'marginTop': '3%'}}>
                                    <div className="menu-tab2">{this.props.dateTime}</div>
                                </div>
                                <div className="col-sm-12 col-md-3" style={{'marginTop': '3%'}}>
                                    {this.props.tabIndex !== 0 &&
                                    <div style={{'alignSelf': 'center', 'display': 'flex'}}>

                                        <DownloadEditDeleteButtons
                                            handleDownloadClick={this.onFileNameClick}
                                            handleEditClick={() => this.props.onShowEditModal(this.props.entry)}
                                            handleDeleteClick={!this.props.entry.created_by_provider ? () => this.props.onShowDeletedModal(this.props.entry): null}
                                        />
                                    </div>}
                                </div>
                            </div>
                            {this.props.entry.created_by_provider && <div className="small font-italic text-right">
                                {this.props.entry.provider_names}
                            </div>}
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    {(this.getSelectedDocumentId() === this.props.entry.id) && this.props.entry.notes &&
                                    <div style={{
                                        'fontSize': '16px',
                                        'color': '#9BABB6',
                                        'paddingTop': '3%',
                                        'marginLeft': '3%'
                                    }}>
                                        {this.props.entry.notes}
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style={{'borderTop': '0.2px solid #F2F2F2'}}/>
            </div>
        )
    }
}


DocumentEntry.propTypes = {
    dateTime: PropTypes.string,
    entry: PropTypes.object,
    tabIndex: PropTypes.number,
    fetchSelectedUserHealthIssues:PropTypes.func,
    healthIssues:PropTypes.object,
    index:PropTypes.number,
    onDocumentChange: PropTypes.func,
    onShowDeletedModal: PropTypes.func,
    onShowEditModal: PropTypes.func,
    selectedDocument: PropTypes.object,
    selectedUser: PropTypes.object,
    showModal: PropTypes.func
}
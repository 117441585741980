import React, {Component} from 'react'
import {
    $$
} from '../../helpers/localization';
import {
    CONVERTER,
    getUnitValuefromKey
} from '../../utils/converter';
import {WHEN_NEEDED
} from '../../constants/medications_constants';
import HealthIssueTag from '../health/HealthIssueTag';
import SelectedPill from "./SelectedPill";
import history from "../../helpers/history";
import {Routes} from "../../constants/routes";
import {infoUtils} from '../../utils/infoUtils'
import no_data from '../../resources/images/no_data.png'
import PropTypes from "prop-types";
import moment from "moment";
import {Modal} from "react-bootstrap";
import {isBulgarian} from "../../utils/userUtils";
import {getMedString} from "../../utils/medicationUtils";



class MedicationsPanel extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
    }

    getLeft = (medication) => {
        let alarms = medication.schedule && medication.scheduleType !== WHEN_NEEDED && medication.schedule.times.map((time, index) => {

            let timeStr = CONVERTER.getEntryDateTimeFormat(moment(time, 'HH:mm'), false);

            return <div key={index} className="row mr-4 ml-1">
                <i className="fa fa-bell mt-1" style={{color: "#FF6475", fontSize: "0.813rem"}}/>
                <div className="medication-time ml-1">{timeStr}</div>
            </div>
        })

        return <div className="name-alarm-cell flex-1" key={++this.index}>
            <div className="medication-row" style={{"marginLeft": "20px", "paddingBottom":"0.2rem"}}>
                <div className="medications-panel-name row">
                    <div className="mr-2" style={{
                        'fontSize': '1.25rem',
                        'fontHeight': '1.75rem',
                        'marginTop': '-0.188rem'
                    }}>{medication.name}</div>
                    <div className="row ml-2 alarm-row">{alarms}</div>
                </div>
                {(medication.remaining_meds <= medication.refillAlarmThreshold && medication.remaining_meds !== -1) &&
                <div className="row">
                    <div
                        className="medication-reminder">{medication.remaining_meds} {getUnitValuefromKey(medication.unit)} left
                        !
                    </div>
                </div>}
                <div className="row medication-notes mt-1">{getMedString(medication)}</div>
                {medication.notes && <div className="row medication-plan-notes">
                    {medication.notes}
                </div>}
            </div>
            {isBulgarian(this.props.selectedUser.country) && <AptechkoSearch name={medication.name}/>}
        </div>;
    }

    getHealthIssues = (medication) => {
        if (medication.health_issues_ids) {
            let healthIssues = medication.health_issues_ids.map((h, idx) => {
                let healthIssueName;
                this.props.healthIssues && this.props.healthIssues.map((healthIssue) => {
                    if (healthIssue.id === h) {
                        healthIssueName = healthIssue.name
                    }
                })
                if (healthIssueName) {
                    return <HealthIssueTag
                        key={idx}
                        className='clinicians-specialties-list'
                        styles={{}}
                        healthIssue={healthIssueName ? healthIssueName : ""}
                    />
                }
            });

            return <div key={++this.index} className='d-flex text-center clinicians-list-cell'
                        style={{flexWrap: 'wrap'}}>
                {healthIssues}
            </div>;
        } else
            return <div key={++this.index} className='d-flex text-center clinicians-list-cell'>
            </div>;
    }
    getArrow = () => {
        return <div key={++this.index} className="text-center pointer" style={{'position': 'absolute', 'right': '0rem'}}
                    onClick={() => {
                        history.push(Routes.MEDICATIONS)
                    }}>
            <i className="fa fa-chevron-right"/>
        </div>
    }

    getHealthIssuesList = (medication) => {
        let cells = [];
        if (medication.health_issues_ids && medication.health_issues_ids.length !== 0) cells.push(this.getHealthIssues(medication))
        return <div key={++this.index} className="medication-issues-sm-sc text-center clinicians-list-cell" style={{maxWidth: "300px"}}>{cells}</div>
    }

    getRow = (medication, idx) => {
        let cells = [];
        cells.push(
            <div key={++this.index} className="flex-space-start flex-wrap flex-1">
                <SelectedPill primaryColour={CONVERTER.decimalHexTwosComplement(medication.shapePrimaryColor)}
                              secondaryColour={CONVERTER.decimalHexTwosComplement(medication.shapeSecondaryColor)}
                              pillSelection={medication.shapeIndex}/>
                {this.getLeft(medication)}
            </div>)
        cells.push(this.getHealthIssuesList(medication))
        cells.push(<div key={++this.index}>{this.getArrow()}</div>)
        return <div key={++this.index}>
            <div className="d-flex flex-space-between align-items-center medication-panel-row">{cells}</div>
            {idx === 0 && <hr className="ruler"/>}
        </div>
    }

    getBody = () => {
        let sortedMedications = this.props.medicationPlan.filter(medPlan => medPlan.user_id === this.props.selectedUser.id)
            .filter(plan => (plan.ends === 0 && !plan.inactive) || plan.ends >= new Date().getTime())
            .sort((a, b) => (a.created_datetime < b.created_datetime) ? 1 : -1)
        let allRows = [];

        for (let i = 0; (i < sortedMedications.length); ++i) {
            allRows.push(this.getRow(sortedMedications[i], i));
            if (i === 1) break;
        }

        return [].concat.apply([], allRows);
    }

    render() {
        let noMedications = {
            imgClass: 'no-entry-image-landing',
            objClass: 'no-data-object-landing',
            primaryLabelClass: 'no-medications-landing-label',
            src: no_data,
            primaryLabel: $$('no_medications'),
            nobreak: true
        }
        let hasNoMedication = this.props.medicationPlan.filter(medPlan => medPlan.user_id === this.props.selectedUser.id)
            .filter(plan => (plan.ends === 0 && !plan.inactive) || plan.ends >= new Date().getTime()).length === 0;
        return (
            <div className="py-3">
                {this.getBody()}
                {hasNoMedication && infoUtils.noData(noMedications)}
            </div>
        )
    }
}

MedicationsPanel.propTypes = {
    dashboardCharts: PropTypes.object,
    fetchSelectedUserLatestMeasurements: PropTypes.func,
    healthIssues: PropTypes.array,
    history: PropTypes.object,
    i18n: PropTypes.object,
    latestUserMeasurements: PropTypes.array,
    location: PropTypes.object,
    match: PropTypes.object,
    medicationPlan: PropTypes.array,
    providers:  PropTypes.array,
    selectedUser: PropTypes.object,
    userAppointments:  PropTypes.array,
    userInfo: PropTypes.object
}

export default MedicationsPanel

const regExp = new RegExp('[\\s-:,]+');

function parseNameToSearch(name) {
    let allTerms = (name??'').split(regExp);
    let terms = [];
    for (let i = 0; i < allTerms.length; i++) {
        let val = allTerms[i];
        if (hasSymbol(val)) {
            break;
        }
        terms.push(val);
    }
    return terms.join(' ');
}

const symbolRegex = new RegExp('[0-9]+|[\\\\.\\-!@#%^&*()+=]+');

function hasSymbol(s) {
    return symbolRegex.test(s);
}

export class AptechkoSearch extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {open:false, search: parseNameToSearch(props.name)}
    }

    render() {
        const allowIframe = `${process.env.APTECHKO_RESULT_URL}${this.state.search}`;
        return (
            <>
                <div className="pointer pt-1" style={{fontSize:"0.875rem", marginLeft:"10px"}} onClick={(e) => {
                    e.preventDefault(); e.stopPropagation();
                    if (!window.aptechko) {
                        this.setState({open: true})
                    } else {
                        top.window.location.href = `${process.env.APTECHKO_RESULT_NO_REF_URL}${this.state.search}`;
                        //post message to parent - aptechko.bg
                        //window.parent.postMessage(JSON.stringify({url:`${process.env.APTECHKO_RESULT_NO_REF_URL}${this.props.name}`}), process.env.APTECHKO_HOST);
                    }
                }}>
                    {$$("search_with")} <img style={{height:"1rem", verticalAlign:"text-top"}} src="https://aptechko.bg/static/images/logo.png" alt="Aptechko"/>
                </div>
                {this.state.open && <Modal onHide={(e)=>{
                    this.setState({open:false})
                }} show={this.state.open} size={"xl"}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        {<iframe src={`${process.env.APTECHKO_RESULT_URL}${this.state.search}`} allow={allowIframe} width="100%" style={{minHeight:"70vh"}} frameBorder="0" />}
                    </Modal.Body>
                </Modal>}
            </>
        )
    }

}
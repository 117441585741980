import {fetchHelper} from '../helpers/request_helper';
import {
    CREATE_UPDATE_MEDICATION,
    CREATE_UPDATE_MEDICATION_ENTRY,
    CREATE_UPDATE_MEDICATION_EVENT,
    DELETE_MEDICATION_EVENTS,
    DELETE_MEDICATION_EVENTS_ENTRIES,
    DELETE_MEDICATION_PLAN,
    GET_MED_ENTRIES_FOR_MED_EVENTS,
    GET_MEDICATION_EVENTS,
    GET_PLAN_STATS,
    USER_MEDICATIONS_URL,
    SEARCH_FOR_MEDICATIONS_URL
} from '../constants/api_paths';

export const medicationsService = {
    fetchSelectedUserMedicationPlan,
    deleteMedicationPlan,
    updateMedication,
    createMedication,
    getPlanStats,
    getMedicationEvents,
    getMedEntriesForMedEvents,
    deleteMedicationEvents,
    deleteMedEventsEntries,
    createMedicationEvent,
    updateMedicationEvent,
    updateMedicationEntry,
    searchForMedication
};

/**
 * Get the medication plan for the selected user
 *
 * @param {string} userId id of the user to fetch the medication plan for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserMedicationPlan(userId, params) {
    return fetchHelper.callGet(USER_MEDICATIONS_URL.replace('{userId}', userId), params);
}
/**
 * Get for medication information
 *
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function searchForMedication(params) {
    return fetchHelper.callGet(SEARCH_FOR_MEDICATIONS_URL, params);
}

/**
 * Get statistics for a selected medical plan
 *
 * @param {string} userId id of the user
 * @param {string} planId id of the plan to select
 * @returns {object} promise object
 */
export function getPlanStats(userId, planId) {
    return fetchHelper.callGet(GET_PLAN_STATS.replace('{userId}', userId).replace('{planId}', planId));
}


/**
 * Deletes medication plan with the given id.
 *
 * @param {string} planId id of the medication plan to delete
 * @param {string} userId id of the user owner of the plan
 * @returns {object} promise object
 */
export function deleteMedicationPlan(planId, userId) {
    return fetchHelper.callDelete(DELETE_MEDICATION_PLAN.replace('{planId}', planId), {userId:userId});
}

/**
 * Creates/Updates medication
 * @param {object} plan The entry to be created/updated
 */
export function updateMedication(plan) {
    return fetchHelper.callPut(CREATE_UPDATE_MEDICATION, plan, null, false);
}

/**
 * Creates medication plan entry
 * @param {formData} plan The entry to be created
 */
export function createMedication(plan) {
    return fetchHelper.callPost(CREATE_UPDATE_MEDICATION, plan, false);
}

/**
 * Fetches medication events (medication intakes)
 * @param {*} userId - id of the user to fetch the medication event for
 * @param {object} params params sent together with the request
 * @returns
 */
export function getMedicationEvents(userId, params) {
    return fetchHelper.callGet(GET_MEDICATION_EVENTS.replace('{userId}', userId), params);
}

/**
 * Fetches Medication entries for Medication events
 * @param {*} userId - id of the user to fetch the medication entries/event for
 * @param {*} body - other params sent together with the request
 * @returns
 */
export function getMedEntriesForMedEvents(userId, body) {
    return fetchHelper.callPut(GET_MED_ENTRIES_FOR_MED_EVENTS.replace('{userId}', userId), body);
}

/**
 * Deletes medication events with the given plan id.
 *
 * @param {number} planId id of the medication events to delete
 * @returns {object} promise object
 */
export function deleteMedicationEvents(planId, userId) {
    return fetchHelper.callDelete(DELETE_MEDICATION_EVENTS.replace('{planId}', planId), {userId:userId});
}

/**
 * Deletes medication events' entries with the given plan id.
 *
 * @param {number} planId id of the medication events' entries to delete
 * @returns {object} promise object
 */
export function deleteMedEventsEntries(planId, userId) {
    return fetchHelper.callDelete(DELETE_MEDICATION_EVENTS_ENTRIES.replace('{planId}', planId), {userId:userId});
}

/**
 * Creates medication event
 * @param {formData} event The event to be created
 */
export function createMedicationEvent(event) {
    return fetchHelper.callPost(CREATE_UPDATE_MEDICATION_EVENT, event, false);
}

/**
 * Updates medication events
 * @param {formData} event The event to be updated
 * @returns
 */
export function updateMedicationEvent(event) {
    return fetchHelper.callPut(CREATE_UPDATE_MEDICATION_EVENT, event);
}

/**
 * Updates medication entry
 * @param {formData} entry The entry to be updated
 * @returns
 */
export function updateMedicationEntry(entry) {
    return fetchHelper.callPut(CREATE_UPDATE_MEDICATION_ENTRY, entry);
}
import React, {Component} from 'react'
import ReactDOM from 'react-dom';
import {connect} from 'react-redux'
import {$$} from '../../helpers/localization';
import NotesTabListItem from './NotesTabListItem'
import CenteredModal from '../shared/CenteredModal';
import AppointmentDetails from '../appointments/AppointmentDetails';
import NoteSpeechToText from './NoteSpeechToText';
import no_data from '../../resources/images/no_data.png'
import PropTypes from "prop-types";


export class NotesTab extends Component {
    state = {
        events: [],
        selectedNoteId: '',
        selectedNote: null,
        createUpdateModalOpened: false,
        deleteModalOpened: false,
        isCreateMode: false,
        note: '',
        formclass: '',
        errors: {},
    }

    constructor(props) {
        super(props);
    }

    /**
     * Creates the rows of the table
     *
     * @param {object} note - the note object
     * @param {number} idx - index value
     * @returns {Array} the table rows created
     */
    getRow = (note, idx) => {
        return <NotesTabListItem key={idx}
                                 index={idx}
                                 selectedAppointment={this.props.selectedAppointment}
                                 userId={this.props.userId}
                                 i18n={this.props.i18n}
                                 note={note}
                                 selectedUser={this.props.selectedUser}
                                 selectedNoteId={this.state.selectedNoteId}
                                 selectedNote={this.state.selectedNote}
                                 loggedUser={this.props.loggedUser}
                                 onNoteChange={this.onNoteChange}
                                 updateNote={this.updateNote}
                                 openDeleteModal={this.openDeleteModal}/>;
    }


    /**
     * The function we call in case we have no data
     */
    getNoData = (label) => {
        return <div style={{display: 'inline-flex', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
            <div><img style={{width: "9rem", height: " 9rem"}} src={no_data} alt={"No Data"}/></div>
            <div><span className="no-data-sharing-label">{label}</span></div>
        </div>
    }

    /**
     * Creates the full body of the table
     *
     * @returns {Array} the table rows
     */
    getBody = () => {
        let allRows = [];
        let notes = this.props.notes;
        if (!(notes && notes.length > 0)) {
            return this.getNoData($$("no_appointment_notes"));
        }
        for (let i = notes.length - 1; i >= 0 ; i--) {
            allRows.push(this.getRow(notes[i], i));
        }
        return [].concat.apply([], allRows);
    }

    /**
     * An event handler triggered when a new note is choosen
     * from the list of notes
     *
     * @param {object} note - the note object
     * @param {number} idx - index value
     */
        // eslint-disable-next-line no-unused-vars
    onNoteChange = (note, idx) => {
        this.setState({
            selectedNoteId: note.id,
            selectedNote: note,
            isCreateMode: false
        });
    }

    onNoteSpeechChange = (text) => {
        this.setState({note: text});
    };

    /**
     * Open delete modal.
     */
    openDeleteModal = () => {
        this.setState({
            deleteModalOpened: true
        });
    }

    /**
     * Hide delete modal.
     */
    onHideDeleteModal = () => {
        this.setState({
            deleteModalOpened: false
        });
    }

    /**
     * Update selected note.
     */
    updateNote = (note) => {
        this.props.updateNote(this.props.selectedUserId, note, this.props.selectedAppointment);
    }

    /**
     * Delete selected note.
     */
    deleteNote = () => {
        this.setState({
            deleteModalOpened: false
        });
        this.props.deleteNote(this.state.selectedNote.id, this.props.selectedAppointment);
    }

    /**
     * Close create new note
     */
    closeCreateNewNote = () => {
        this.setState({
            isCreateMode: false,
        });
    }

    /**
     * Open create new note
     */
    openCreateNewNote = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.setState({
            isCreateMode: true,
            selectedNoteId: '',
            selectedNote: null,
            note: '',
            formclass: '',
            errors: {}
        });
    }

    /**
     * Form submit handler, validate data and set error in state if any. Call create logbook entry action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        this.setState({errors: ''});
        if (!this.state.note) {
            errors.note = 'note_required_message';
        }
        this.setState({errors});
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }
        return Object.keys(errors).length === 0;

    }

    /**
     * Create new note for selected user
     */
    saveNewNote = () => {
        if (this.validate()) {
            let note = {};
            note.value = this.state.note;
            note.owner_id = this.props.loggedUser.id;
            note.subject_id = this.props.selectedUserId;
            note.note_type = "TEXT";
            note.visibility = "ALL";
            this.props.createNote(this.props.selectedAppointment, note);
            this.closeCreateNewNote();
        }
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        if (evt.target) {
            fields[evt.target.name] = evt.target.value;
        } else {
            fields[evt.name] = evt.value;
        }
        this.setState(fields);
    }

    canCreate = () => {
        const appointment = this.props.selectedAppointment;
        return appointment.status === 'ACCEPTED' || (appointment.status === 'COMPLETED' && this.inTimeRange())
    }

    inTimeRange = () => {
        let app = this.props.selectedAppointment;
        return app.completion_date + app.appointment_price.opened_communication_duration_days * 86400000 > new Date().getTime()
    }

    render() {

        let appointment = this.props.selectedAppointment;
        let domNode = document.getElementById('new-symptom-btn');
        return (
            <div style={{"marginTop": "-20px"}}>
                <AppointmentDetails
                    appointment={appointment}
                    selectedAppointment={appointment}
                    selectedUserId={this.props.selectedUserId}
                    selectedUser={this.props.selectedUser}
                />
                <br/>

                <div className="high-shadow-container">
                    <h3>{$$("appointment_notes_title")}</h3>
                    <br/>
                    {
                        <div className='row' style={{'marginLeft': '0px', 'marginRight': '0px'}}>
                            <div className='col-xs-12 col-md-12'>
                                <div className='row'>
                                    <div className='w-100 appointments-tab'>
                                        {this.state.isCreateMode &&
                                        <div>
                                            <div id='appointment' className="appointment-containter appointment-active"
                                                 onClick={this.onNoteClick}>
                                                <div className="row" style={{
                                                    "width": "100%",
                                                    "marginLeft": "20px",
                                                    "marginRight": "20px"
                                                }}>
                                                    <div style={{"width": "93%"}}>
                                                        <div style={{"textAlign": "right", "marginTop": "5px"}}>
                                                            <div className="note-title"
                                                                 style={{"display": "inline-block", "float": "left"}}>
                                                                {$$("new_note_label")}
                                                            </div>
                                                            <br/>
                                                        </div>
                                                        <div style={{"marginTop": "-25px", "marginBottom": "20px"}}>
                                                            <form id="logbookForm" onSubmit={this.onSubmit}
                                                                  className={this.state.formclass} noValidate={true}>
                                                                <div className="form-group">
                                                                    <div>
                                                                        <NoteSpeechToText
                                                                            onInputChange={this.onInputChange}
                                                                            note={this.state.note}
                                                                            onNoteSpeechChange={this.onNoteSpeechChange}
                                                                            i18n={this.props.i18n}
                                                                        />
                                                                    </div>
                                                                    <div className="invalid-feedback">
                                                                        {$$('note_required_message')}
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row" style={{
                                                                    "float": "right",
                                                                    "marginRight": "10px",
                                                                    "marginTop": "-5px",
                                                                    "zIndex": "100"
                                                                }}>
                                                                    <div>
                                                                        <button type="button"
                                                                                className="btn btn-secondary btn-block"
                                                                                onClick={this.closeCreateNewNote}>
                                                                            {$$("cancel_btn")}
                                                                        </button>
                                                                    </div>
                                                                    <div style={{"marginLeft": "7px"}}>
                                                                        <button type="button"
                                                                                className="btn btn-success btn-block"
                                                                                onClick={this.saveNewNote}>
                                                                            {$$("save_btn_label")}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                        </div>}
                                        {this.getBody()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {this.canCreate() && ReactDOM.createPortal(
                    <div className="blue-button-container" onClick={this.openCreateNewNote}>
                        <i className="add-new-button fa fa-plus" aria-hidden="true"
                           style={{"display": "table-cell", "verticalAlign": "middle"}}/>
                    </div>,
                    domNode
                )}
                <CenteredModal title={$$('delete_note_modal_header')}
                               show={this.state.deleteModalOpened}
                               onHide={this.onHideDeleteModal}
                               onConfirm={this.deleteNote}>
                    {$$('delete_note_modal_text')}
                </CenteredModal>
            </div>
        )
    }
}

NotesTab.propTypes = {
    createNote: PropTypes.func,
    deleteNote: PropTypes.func,
    i18n: PropTypes.object,
    notes: PropTypes.array,
    selectedAppointment: PropTypes.object,
    selectedUser: PropTypes.object,
    selectedUserId: PropTypes.string,
    updateNote: PropTypes.func,
    userId: PropTypes.string
};


const mapStateToProps = (state) => ({
    i18n: state.language.selected,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(NotesTab)

import React from 'react';
import FormCheck from "../../input/FormCheck";
import Modal from "react-bootstrap/Modal";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AnswerType from "../../../models/answer-type/AnswerType";
import BaseAnswer from "../BaseAnswer";
import {$$} from "../../../../helpers/localization";

class NumberAnswer extends BaseAnswer {
    render() {
        const answer = this.props.answer;

        return (
            <span>
                <div className={`custom-control custom-${answer.selectionType}`}>
                    <FormCheck ref={this.inputRef} answer={answer} handleChange={this.onInputChange} />
                    <label onClick={() => this.inputRef.current.click()}
                           className={'cursor-pointer mr-2 custom-control-label'}>
                        {`${answer.label[this.language]} ${this.getSuffix(answer)}`}
                    </label>
                </div>

                <Modal
                    show={this.state.open}
                    centered={true}
                    onHide={() => this.onCancelButtonPressed()}
                    dialogClassName="modal-20w picker-modal">
                    <Modal.Header>{answer.label[this.language]}</Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Control
                                value={this.state.result}
                                onChange={this.onChange}
                                onKeyPress={e => e.key === 'Enter' ? e.preventDefault() : null}
                                isInvalid={this.state.errors?.number}
                                type={'number'}
                                as={'input'}/>
                                <Form.Control.Feedback type="invalid">
                                    {this.state.errors?.number}
                                </Form.Control.Feedback>
                        </Form>
                        <div dir={'rtl'} className={'d-flex mt-3'}>
                            <Button onClick={() => this.onCancelButtonPressed()}>{$$("cancel_btn")}</Button>
                            <Button className={'mr-2'}
                                    disabled={!this.state.result}
                                    onClick={this.onOkButtonPressed}>{$$("OK")}</Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </span>
        )
    }

    onChange = (event) => {
        const result = event.target.value;
        const errors = this.validateForm(result);
        this.setState({result, errors})
    };

    onOkButtonPressed = () => {
        if (this.state.errors?.number) {
            return;
        }
        this.props.answer.result = this.state.result;
        this.selectAnswer();
        this.setState({open: false});
    };

    validateForm = (currentNumber) => {
        const range = this.props.answer.range;
        const errors = {};
        if (currentNumber < range?.min) {
            errors.number = `Value must be above ${range.min}`;
        }
        if (currentNumber > range?.max) {
            errors.number = `Value must be below ${range.max}`;
        }
        return errors;
    }
}

class NumberAnswerFactory {
    static get type() {
        return AnswerType.NUMBER;
    }

    static create(answer, updateAnswer, language) {
        return <NumberAnswer answer={answer} updateAnswer={updateAnswer} language={language}/>
    }
}

export default NumberAnswerFactory

import {generalPractitionerService} from '../service/generalPractitionerService'
import {
    CLEAR_GENERAL_PRACTITIONER,
    CREATE_GENERAL_PRACTITIONER_ERROR,
    CREATE_GENERAL_PRACTITIONER_SUCCESS,
    DELETE_GENERAL_PRACTITIONER_ERROR,
    DELETE_GENERAL_PRACTITIONER_SUCCESS,
    DUMMY,
    FETCH_SELECTED_USER_GENERAL_PRACTITIONER_ERROR,
    FETCH_SELECTED_USER_GENERAL_PRACTITIONER_SUCCESS,
    GET_CAPTURE_ERROR,
    GET_CAPTURE_SUCCESS,
    GP,
    IMPORT_GP_ERROR,
    IMPORT_GP_SUCCESS,
    SET_FORM_TO_DISABLED,
    SET_FORM_TO_ENABLED,
    UPDATE_GENERAL_PRACTITIONER_ERROR,
    UPDATE_GENERAL_PRACTITIONER_SUCCESS
} from './actions';

/**
 * Fetch selected users general practitioner
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchSelectedUserGeneralPractitioner(userId, params) {
    return (dispatch, getState) => {
        return generalPractitionerService.fetchSelectedUserGeneralPractitioner(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_SELECTED_USER_GENERAL_PRACTITIONER_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_USER_GENERAL_PRACTITIONER_ERROR, result: err});
        })
    }
}

export function clearGeneralPractitioner(){
    return(dispatch) => {
        dispatch({type: CLEAR_GENERAL_PRACTITIONER})
    }
}

export function createGeneralPractitioner(form) {
    return (dispatch, getState) => {
        dispatch({type: SET_FORM_TO_DISABLED, formName: GP});
        return generalPractitionerService.createUserGeneralPractitioner(form).then((res) => {
            // eslint-disable-next-line no-undef
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: SET_FORM_TO_ENABLED});
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: SET_FORM_TO_ENABLED});
                dispatch({type: CREATE_GENERAL_PRACTITIONER_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: SET_FORM_TO_ENABLED});
            dispatch({type: CREATE_GENERAL_PRACTITIONER_ERROR, result: err});
        })
    }
}

export function updateGeneralPractitioner(form) {
    return (dispatch, getState) => {
        dispatch({type: SET_FORM_TO_DISABLED, formName: GP});
        return generalPractitionerService.updateUserGeneralPractitioner(form).then((res) => {
            // eslint-disable-next-line no-undef
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: SET_FORM_TO_ENABLED});
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: SET_FORM_TO_ENABLED});
                dispatch({type: UPDATE_GENERAL_PRACTITIONER_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: SET_FORM_TO_ENABLED});
            dispatch({type: UPDATE_GENERAL_PRACTITIONER_ERROR, result: err});
        })
    }
}

export function deleteGeneralPractitioner() {
    return (dispatch, getState) => {
        return generalPractitionerService.deleteUserGeneralPractitioner().then((res) => {
            // eslint-disable-next-line no-undef
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: DELETE_GENERAL_PRACTITIONER_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: DELETE_GENERAL_PRACTITIONER_ERROR, result: err});
        })
    }
}


export function getImportGPCapture() {
    return (dispatch) => {
        return generalPractitionerService.getGPImportCapture().then((res) => {
            if (res) {
                dispatch({type: GET_CAPTURE_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: GET_CAPTURE_ERROR, result: err});
        })
    }
}

export function importGP(form) {
    return (dispatch) => {
        return generalPractitionerService.importGeneralPractitioner(form).then((res) => {
            if (res) {
                dispatch({type: IMPORT_GP_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: IMPORT_GP_ERROR, result: err});
        })
    }
}
import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import * as PropTypes from "prop-types";
import moment from 'moment';
import {updateUserMedicalProfile} from '../../actions/users_actions'
import {dateTimeUtils} from '../../utils/dateTimeUtils'
import CenteredModal from '../shared/CenteredModal';
import {connect} from 'react-redux'
import classnames from 'classnames'
import {
    createHealthIssue,
    deleteHealthIssue,
    fetchSelectedUserHealthIssues,
    updateHealthIssue
} from '../../actions/health-issues_actions'
import {
    clearIcd,
    searchIcd
} from '../../actions/icd_actions'
import CustomMultiSelect from '../shared/CustomMultiSelect';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {infoUtils} from "../../utils/infoUtils";
import no_data from "../../resources/images/no_data.png";
import {CHRONIC_CONDITIONS} from "../../actions/actions";
import FormWithLoading from "../shared/FormWithLoading";
import bg from 'date-fns/locale/bg';
import sq from 'date-fns/locale/sq';
import enGB from 'date-fns/locale/en-GB';


export class ChronicConditions extends Component {
    state = {
        createChronicCondition: false,
        conditionName: "",
        diagnosedDatetime: null,
        description: "",
        errors: {},
        selectedCode: "",
        codeArray: null,
    }

    constructor(props) {
        super(props);

    }

    clearState() {
        this.setState({
            createChronicCondition: false,
            conditionName: "",
            diagnosedDatetime: null,
            description: "",
            errors: {},
            selectedCode: "",
            codeArray: null,
        })
        this.props.clearIcd();
    }

    getLocale = () => {
        switch(this.props.i18n.selected.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            case "sq":
                return sq
            default:
                return enGB
        }
    }

    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        if (evt.target) {
            fields[evt.target.name] = evt.target.value;
        } else {
            fields[evt.name] = evt.value;
        }
        this.setState(fields);
    }

    /**
     * Set the state to the latest selected date.
     *
     * @param {object} date - The event handler argument
     */
    onDateChange = (date) => {
        this.setState({diagnosedDatetime: moment(date).valueOf()})
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.selectedCondition != null && prevProps.selectedCondition !== this.props.selectedCondition) {
            let codeArray = null
            if (this.props.selectedCondition.disease_code)
                codeArray = [{
                    value: this.props.selectedCondition.disease_code,
                    text: this.props.selectedCondition.name
                }]
            this.setState({
                conditionName: this.props.selectedCondition.name ? this.props.selectedCondition.name : "",
                diagnosedDatetime: this.props.selectedCondition.date_diagnosed ? this.props.selectedCondition.date_diagnosed : null,
                description: this.props.selectedCondition.description ? this.props.selectedCondition.description : "",
                selectedCode: this.props.selectedCondition.disease_code ? this.props.selectedCondition.disease_code : "",
                codeArray: codeArray
            })
        }
    }

    onConditionCreate = (state) => {
        this.setState({
            createChronicCondition: state,
        })

    };


    getIcdOptions(options) {
        return options && options.map(o => {
            if (o.label) {
                let n = o.label.search(/[A-Z][0-9]{2}[.]/);
                let name = o.label.substring(0, n)
                let text = o.label.substring(n)
                return {value: o.id, text: text, name: name}
            } else return {value: o.id, text: o.label ? o.label : "", name: o.label ? o.label : ""}
        });
    }

    validate = () => {
        let d = this.state.diagnosedDatetime ? new Date(this.state.diagnosedDatetime): null;
        const errors = {};
        this.setState({errors: ''});
        if (d && d > moment().valueOf()) {
            errors.datetime = 'datetime_max_value_message';
        }
        if (!this.state.conditionName && !this.state.selectedCode) {
            errors.conditionName = 'chronic_condition_name_required_message';
        }
        this.setState({errors});
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }
        return Object.keys(errors).length === 0;

    }

    onSave = async () => {
        if (this.validate()) {
            this.onConditionCreate(false)

            let conditions = this.props.medicalProfile.data.chronic_conditions
            let filteredConditions = conditions;

            let condition = {
                date_created: dateTimeUtils.toOffsetDateTime(moment().utc()),
                date_diagnosed: this.state.diagnosedDatetime ? moment(this.state.diagnosedDatetime).valueOf(): null,
                date_modified: dateTimeUtils.toOffsetDateTime(moment().utc()),
                description: this.state.description,
                name: this.state.conditionName,
                disease_code: this.state.selectedCode ? this.state.selectedCode : "",

            }
            if (this.props.selectedCondition == null) {
                filteredConditions.push(condition)
            } else {
                condition.id = this.props.selectedCondition.id
                filteredConditions = conditions.filter((condition) => {
                    return condition.id !== this.props.selectedCondition.id
                })
                filteredConditions.push(condition)
            }

            let form = {
                ...this.props.medicalProfile.data,
                chronic_conditions: filteredConditions
            }
            let keepSelectedCondition = this.props.selectedCondition
            await this.props.updateUserMedicalProfile(this.props.selectedUser.id, form)
            let conditionFromResponse = this.props.createConditionResponse && this.props.createConditionResponse.chronic_conditions && this.props.createConditionResponse.chronic_conditions[this.props.createConditionResponse.chronic_conditions.length - 1]
            if (keepSelectedCondition == null) {
                let healthIssue = {
                    chronic_condition_id: conditionFromResponse.id,
                    chronic_condition: conditionFromResponse,
                    user_id: this.props.selectedUser.id,
                    date_started: conditionFromResponse.date_diagnosed ? dateTimeUtils.toOffsetDateTime(moment(conditionFromResponse.date_diagnosed).utc()) : "",
                    date_ended: "",
                    notes: "",
                    deleted: false,
                    date_created: dateTimeUtils.toOffsetDateTime(new Date()),
                    date_modified: dateTimeUtils.toOffsetDateTime(new Date()),
                    name: this.state.conditionName,
                }
                this.props.createHealthIssue(healthIssue, this.props.selectedUser.id);

            } else {
                let healthIssues = this.props.healthIssues
                let healthIssueToBeEdited = null
                await healthIssues.map((h) => {
                    if (h.chronic_condition_id === keepSelectedCondition.id) {
                        healthIssueToBeEdited = h
                        healthIssueToBeEdited.date_started = this.state.diagnosedDatetime ? dateTimeUtils.toOffsetDateTime(moment(this.state.diagnosedDatetime).utc()) : ""
                        healthIssueToBeEdited.date_modified = dateTimeUtils.toOffsetDateTime(new Date())
                        healthIssueToBeEdited.name = this.state.conditionName
                    }
                })
                if (healthIssueToBeEdited) this.props.updateHealthIssue(healthIssueToBeEdited, this.props.selectedUser.id)

            }

            this.props.clear()
            this.clearState()
        }

    }


    /**
     * Confirm deletion and remove entry from list
     */
    onDeleteConfirmModal = async () => {
        let deletedEntry = this.props.selectedEntryToDelete;
        let filteredConditions = this.props.medicalProfile.data.chronic_conditions.filter((condition) => {
            return condition.id !== deletedEntry.id
        })
        this.setState({
            selectedEntryToDelete: null,
        });
        let form = {
            ...this.props.medicalProfile.data,
            chronic_conditions: filteredConditions
        }
        this.props.updateUserMedicalProfile(this.props.selectedUser.id, form);
        let healthIssues = this.props.healthIssues
        let healthIssueToBeDeleted = null
        healthIssues.map((h) => {
            if (h.chronic_condition_id === deletedEntry.id) {
                healthIssueToBeDeleted = h
            }
        })
        if (healthIssueToBeDeleted) this.props.deleteHealthIssue(healthIssueToBeDeleted.id, this.props.selectedUser.id);
        this.props.clear()
        this.clearState()
    }

    onIcdChange = async (e) => {
        await (this.props.selectedUser ? this.props.searchIcd(this.props.i18n.selected.lang, e) : null)
    }

    onSelectIcd = (selectedItem) => {
        this.setState(
            {
                selectedCode: selectedItem.value,
                conditionName: selectedItem.text
            })
    }

    onRemoveIcd = () => {
        this.setState({selectedCode: ""})
        this.props.clearIcd()
    }


    render() {
        let noChronicConditions = {
            imgClass: 'no-entry-image-medical-record',
            objClass: 'no-data-object-landing',
            primaryLabelClass: 'no-entry-medical-record-label',
            src: no_data,
            primaryLabel: $$('no_chronic_conditions'),
            nobreak: true
        }

        classnames('my-1 mr-sm-2', {
            'custom-error form-control': this.state.errors.datetime,
            'form-control': !this.state.errors.datetime,
        });
        return <div>
            <div className="new-header row space-between">
                <span className="add-new-condition">{$$("chronic_conditions")}</span>
                <div className="pointer" onClick={() => this.onConditionCreate(true)}>
                    <span className="fa fa-plus add-condition"/>
                    <span className="add-new-condition">{$$("add_entry")}</span>
                </div>
            </div>
            <div className="d-flex condition-container">
                {(this.props.medicalProfile.data.chronic_conditions && this.props.medicalProfile.data.chronic_conditions.length === 0) &&
                <div className="low-shadow-container flex-center no-data-count">
                    <div className="card-body light-green flex-center">
                        {infoUtils.noData(noChronicConditions)}
                    </div>
                </div>}
                {(this.props.medicalProfile.data.chronic_conditions) ?
                    this.props.medicalProfile.data.chronic_conditions.map(this.props.condition) : ""
                }
            </div>
            {(this.state.createChronicCondition || this.props.selectedCondition != null) &&
            <CenteredModal title={this.props.selectedCondition ? $$('edit_condition') : $$('add_condition')}
                           show={this.state.createChronicCondition || this.props.selectedCondition != null}
                           size={"Default"}
                           onHide={() => {
                               this.props.selectedCondition ? this.props.onChronicConditionEdit(false) : null
                               this.clearState()
                               this.props.clear()
                           }}
                           onConfirm={() => {
                               this.onSave()
                           }}>
                <FormWithLoading
                    formDisabled={this.props.formDisabled}
                    currentForm={CHRONIC_CONDITIONS}
                    marginTop="10%"
                    marginLeft="calc(50% - 70px)"
                >
                    <br/>
                    <div className="form-group pre-wrap">
                        {this.state.selectedCode && <label>{$$("disease")}</label>}
                        <CustomMultiSelect
                            options={this.getIcdOptions(this.props.icdEntries)}
                            onSearch={this.onIcdChange}
                            selectedValues={this.state.codeArray ? this.state.codeArray : []}
                            displayValue="text"
                            placeholder={$$("disease")}
                            groupBy="name"
                            emptyRecordMsg={$$("no_results_found")}
                            avoidHighlightFirstOption={true}
                            loading={!this.props.icdEntries}
                            loadingMessage={$$("loading_data_label")}
                            selectionLimit={1}
                            onSelect={this.onSelectIcd}
                            onRemove={this.onRemoveIcd}
                            hidePlaceholder={this.state.selectedCode !== ""}
                        />
                    </div>
                    {!this.state.selectedCode && <div className="form-group">
                        {this.state.conditionName && <label>{$$("condition_name")}</label>}
                        <input type="text" className="form-control"
                               value={(this.state.conditionName === "undefined") ? '' : this.state.conditionName}
                               placeholder={$$('condition_name')} name="conditionName" onChange={this.onInputChange}
                               required/>
                        <div
                            className={this.state.errors.conditionName ? "custom-invalid-feedback last-center-text" : "invalid-feedback last-center-text"}>
                            {this.state.errors.conditionName ? $$(this.state.errors.conditionName) : $$("chronic_condition_name_required_message")}
                        </div>
                    </div>}
                    <div className="form-group">
                        <div className="">
                            <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    format={this.props.settings.dateFormat}
                                    margin="normal"
                                    id="date-picker-inline"
                                    label={$$("date_diagnosed_label")}
                                    value={this.state.diagnosedDatetime}
                                    onChange={this.onDateChange}
                                    KeyboardButtonProps={{'aria-label': 'change date',}}
                                    invalidDateMessage={$$('invalid_date_format')}

                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={this.state.errors.datetime ? "custom-invalid-feedback last-center-text" : "invalid-feedback last-center-text"}>
                            {this.state.errors.datetime ? $$(this.state.errors.datetime) : ""}
                        </div>
                    </div>
                    <div className="form-group">
                        {this.state.description && <label>{$$("description")}</label>}
                        <input type="text" className="form-control" value={this.state.description || ''}
                               placeholder={$$('description')} name="description" onChange={this.onInputChange}/>
                    </div>
                </FormWithLoading>
            </CenteredModal>}
            {this.props.selectedEntryToDelete &&
            <CenteredModal title={$$('delete_log_entry_modal_header')}
                           dialogClassName='doc-delete-modal'
                           show={this.props.selectedEntryToDelete != null}
                           onHide={this.props.clear}
                           cancelBtnLabel={$$('cancel_btn')}
                           onConfirm={this.onDeleteConfirmModal}
                           id="delete-modal-title"
                           className="center-delete-modal"
                           idFooter="footer-delete-modal"
                           confirmBtnLabel={$$('delete_label')}
                           confirmBtnClass="danger"
                           idBtnPrimary="btn-danger"
                           idBtnSecondary="btn-secondary">
                {$$('mark_condition_entry_deleted_modal_title')}
                <div className="bin-align">
                    <i className="fas fa-trash-alt fa-3x"/>
                </div>
            </CenteredModal>}
        </div>;
    }
}

ChronicConditions.propTypes = {
    clear: PropTypes.func,
    clearIcd: PropTypes.func,
    selectedCondition: PropTypes.any,
    selectedEntryToDelete: PropTypes.any,
    condition: PropTypes.func,
    createConditionResponse: PropTypes.object,
    createHealthIssue: PropTypes.func,
    deleteHealthIssue: PropTypes.func,
    enableUpdateButton: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    formDisabled: PropTypes.object,
    healthIssues: PropTypes.array,
    i18n: PropTypes.any,
    icdEntries: PropTypes.array,
    medicalProfile: PropTypes.object,
    onChronicConditionEdit: PropTypes.func,
    searchIcd: PropTypes.func,
    selectedUser: PropTypes.object,
    updateHealthIssue: PropTypes.func,
    settings: PropTypes.any,
    updateUserMedicalProfile: PropTypes.func
}


const mapStateToProps = (state) => ({
    healthIssues: state.healthIssues.entries,
    createConditionResponse: state.medicalProfile.data,
    icdEntries: state.icd.entries,
    settings: state.settings.data,
    formDisabled: state.formInteractions

})

const mapDispatchToProps = {
    updateUserMedicalProfile,
    fetchSelectedUserHealthIssues,
    createHealthIssue,
    updateHealthIssue,
    deleteHealthIssue,
    searchIcd,
    clearIcd
}


export default connect(mapStateToProps, mapDispatchToProps)(ChronicConditions)

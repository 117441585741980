export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export const LOGIN_REQUEST_SENT = "LOGIN_REQUEST_SENT";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_ERROR = "LOGIN_REQUEST_ERROR";
export const TOKEN_REFRESH_REQUEST_SENT = "TOKEN_REFRESH_REQUEST_SENT";
export const TOKEN_REFRESH_REQUEST_SUCCESS = "TOKEN_REFRESH_REQUEST_SUCCESS";
export const TOKEN_REFRESH_REQUEST_ERROR = "TOKEN_REFRESH_REQUEST_ERROR";
export const LOGOUT = "LOGOUT";
export const AUTO_LOGOUT = "AUTO_LOGOUT";

export const REQUEST_SENT = "REQUEST_SENT";
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const REQUEST_ERROR = "REQUEST_ERROR";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_CLEAR = "REGISTER_CLEAR";


export const GET_CAPTURE_SUCCESS = "GET_CAPTURE_SUCCESS";
export const GET_CAPTURE_ERROR = "GET_CAPTURE_ERROR";
export const IMPORT_GP_SUCCESS = "IMPORT_GP_SUCCESS";
export const IMPORT_GP_ERROR = "IMPORT_GP_ERROR";


export const SELECT_USER = "SELECT_USER";
export const CLEAR_ENTRIES = "CLEAR_ENTRIES";
export const CLEAR_SYMPTOMS_LOG = "CLEAR_SYMPTOMS_LOG";
export const FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT = "FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT";
export const FETCH_SELECTED_USER_ENTRIES_ERROR = "FETCH_SELECTED_USER_ENTRIES_ERROR";
export const FETCH_SELECTED_USER_ENTRIES_SUCCESS = "FETCH_SELECTED_USER_ENTRIES_SUCCESS";
export const RESET_SELECTED_USER_ENTRIES_SUCCESS = "RESET_SELECTED_USER_ENTRIES_SUCCESS";
export const CREATE_LOGBOOK_ENTRY_REQUEST_SENT = "CREATE_LOGBOOK_ENTRY_REQUEST_SENT";
export const CREATE_LOGBOOK_ENTRY_SUCCESS = "CREATE_LOGBOOK_ENTRY_SUCCESS";
export const CREATE_LOGBOOK_ENTRY_ERROR = "CREATE_LOGBOOK_ENTRY_ERROR";
export const UPDATE_LOGBOOK_ENTRY_REQUEST_SENT = "UPDATE_LOGBOOK_ENTRY_REQUEST_SENT";
export const UPDATE_LOGBOOK_ENTRY_SUCCESS = "UPDATE_LOGBOOK_ENTRY_SUCCESS";
export const GET_MENSTRUATION_STATS_SENT = "GET_MENSTRUATION_STATS_SENT";
export const GET_MENSTRUATION_STATS_ERROR = "GET_MENSTRUATION_STATS_ERROR";
export const UPDATE_LOGBOOK_ENTRY_ERROR = "UPDATE_LOGBOOK_ENTRY_ERROR";
export const NO_MORE_ENTRIES = "NO_MORE_ENTRIES";
export const CLEAR_SELECTED_USER = "CLEAR_SELECTED_USER";

export const UPDATE_CALL_DATA = "UPDATE_CALL_DATA";
export const UPDATE_CAMERA_DEVICES = "UPDATE_CAMERA_DEVICES";

export const FETCH_CHARTS_DATA_REQUEST_SENT = "FETCH_CHARTS_DATA_REQUEST_SENT";
export const FETCH_CHARTS_DATA_SUCCESS = "FETCH_CHARTS_DATA_SUCCESS";
export const FETCH_CHARTS_DATA_ERROR = "FETCH_CHARTS_DATA_ERROR";
export const CHANGE_CHARTS_FILTERS = "CHANGE_CHARTS_FILTERS";
export const CLEAR_CHARTS = "CLEAR_CHARTS";
export const FETCH_DASHBOARD_CHARTS_DATA_SUCCESS = "FETCH_DASHBOARD_CHARTS_DATA_SUCCESS";
export const FETCH_DASHBOARD_CHARTS_DATA_ERROR = "FETCH_DASHBOARD_CHARTS_DATA_ERROR";
export const FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS = "FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS";
export const FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR = "FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR";
export const CLEAR_LATEST_MEASUREMENTS = "CLEAR_LATEST_MEASUREMENTS";


export const FETCH_HEALTH_ISSUES_DATA_REQUEST_SENT = "FETCH_HEALTH_ISSUES_DATA_REQUEST_SENT";
export const FETCH_HEALTH_ISSUES_DATA_SUCCESS = "FETCH_HEALTH_ISSUES_DATA_SUCCESS";
export const FETCH_HEALTH_ISSUES_DATA_ERROR = "FETCH_HEALTH_ISSUES_DATA_ERROR";
export const RESET_HEALTH_ISSUES_DATA_SUCCESS = "RESET_HEALTH_ISSUES_DATA_SUCCESS";
export const CREATE_HEALTH_ISSUE_ENTRY_REQUEST_SENT = "CREATE_HEALTH_ISSUE_ENTRY_REQUEST_SENT";
export const CREATE_HEALTH_ISSUE_ENTRY_SUCCESS = "CREATE_HEALTH_ISSUE_ENTRY_SUCCESS";
export const CREATE_HEALTH_ISSUE_ENTRY_ERROR = "CREATE_HEALTH_ISSUE_ENTRY_ERROR";
export const UPDATE_HEALTH_ISSUE_DATA_REQUEST_SENT = "UPDATE_HEALTH_ISSUE_DATA_REQUEST_SENT";
export const UPDATE_HEALTH_ISSUE_DATA_ERROR = "UPDATE_HEALTH_ISSUE_DATA_ERROR";
export const UPDATE_HEALTH_ISSUE_DATA_SUCCESS = "UPDATE_HEALTH_ISSUE_DATA_SUCCESS";
export const DELETE_HEALTH_ISSUE_DATA_REQUEST_SENT = "DELETE_HEALTH_ISSUE_DATA_REQUEST_SENT";
export const DELETE_HEALTH_ISSUE_DATA_ERROR = "DELETE_HEALTH_ISSUE_DATA_ERROR";
export const DELETE_HEALTH_ISSUE_DATA_SUCCESS = "DELETE_HEALTH_ISSUE_DATA_SUCCESS";
export const CLEAR_HEALTH_ISSUES = "CLEAR_HEALTH_ISSUES";

export const GET_USER_SETTINGS = "GET_USER_SETTINGS";
export const GET_USER_SETTINGS_ERROR = "GET_USER_SETTINGS_ERROR";
export const CHANGE_USER_SETTINGS_SUCCESS = "CHANGE_USER_SETTINGS_SUCCESS";
export const CHANGE_USER_SETTINGS_ERROR = "CHANGE_USER_SETTINGS_ERROR";

export const GET_NOMENCLATURE_REQUEST_SENT = "GET_NOMENCLATURE_REQUEST_SENT";
export const GET_NOMENCLATURE_SUCCESS = "GET_NOMENCLATURE_SUCCESS";
export const GET_NOMENCLATURE_ERROR = "GET_NOMENCLATURE_ERROR";

export const FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT = "FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT";
export const FETCH_SELECTED_USER_SYMPTOMS_ERROR = "FETCH_SELECTED_USER_SYMPTOMS_ERROR";
export const FETCH_SELECTED_USER_SYMPTOMS_SUCCESS = "FETCH_SELECTED_USER_SYMPTOMS_SUCCESS";
export const NO_MORE_SYMPTOMS = "NO_MORE_SYMPTOMS";
export const LOG_SELECTED_USER_SYMPTOMS_REQUEST_SENT = "LOG_SELECTED_USER_SYMPTOMS_REQUEST_SENT";
export const LOG_SELECTED_USER_SYMPTOMS_ERROR = "LOG_SELECTED_USER_SYMPTOMS_ERROR";
export const LOG_SELECTED_USER_SYMPTOMS_SUCCESS = "LOG_SELECTED_USER_SYMPTOMS_SUCCESS";
export const RESET_SELECTED_USER_SYMPTOMPS_SUCCESS = "RESET_SELECTED_USER_SYMPTOMPS_SUCCESS";
export const FETCH_LATEST_SYMPTOMS_TYPES_SUCCESS = "FETCH_LATEST_SYMPTOMS_TYPES_SUCCESS";
export const FETCH_LATEST_SYMPTOMS_TYPES_ERROR = "FETCH_LATEST_SYMPTOMS_TYPES_ERROR";

export const DUMMY = "DUMMY";

export const AUTHORIZING_PERMISSIONS_REQUEST_SENT = "AUTHORIZING_PERMISSIONS_REQUEST_SENT";
export const AUTHORIZING_PERMISSIONS_REQUEST_SUCCESS = "AUTHORIZING_PERMISSIONS_REQUEST_SUCCESS";
export const AUTHORIZING_PERMISSIONS_REQUEST_ERROR = "AUTHORIZING_PERMISSIONS_REQUEST_ERROR";
export const ACCESS_LINK_REQUEST_SENT = "ACCESS_LINK_REQUEST_SENT";
export const ACCESS_LINK_REQUEST_ERROR = "ACCESS_LINK_REQUEST_ERROR";
export const PERMISSION_DENY_REQUEST_SUCCESS = "PERMISSION_DENY_REQUEST_SUCCESS";
export const PERMISSION_APPROVE_REQUEST_SUCCESS = "PERMISSION_APPROVE_REQUEST_SUCCESS";
export const PERMISSION_DENY_REQUEST_ERROR = "PERMISSION_DENY_REQUEST_ERROR";
export const PERMISSION_APPROVE_REQUEST_ERROR = "PERMISSION_APPROVE_REQUEST_ERROR";
export const SHARE_DATA_SUCCESS = "SHARE_DATA_SUCCESS";
export const SHARE_DATA_ERROR = "SHARE_DATA_ERROR";

export const UPGRADE_CHILD_REQUEST_SENT = "UPGRADE_CHILD_REQUEST_SENT";
export const UPGRADE_CHILD_SUCCESS = "UPGRADE_CHILD_SUCCESS";
export const UPGRADE_CHILD_ERROR = "UPGRADE_CHILD_ERROR";

export const CLEAR_PERMISSIONS = "CLEAR_PERMISSIONS";

export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_ERROR = "GET_USER_PROFILE_ERROR";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const CLEAR_UPDATE_USER_PROFILE_REQUEST = "CLEAR_UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const UPDATE_USER_PASSWORD_ERROR = "UPDATE_USER_PASSWORD_ERROR";
export const CLEAR_USER_PASSWORD = "CLEAR_USER_PASSWORD";

export const FETCH_SELECTED_USER_DOCUMENTS_REQUEST_SENT = "FETCH_SELECTED_USER_DOCUMENTS_REQUEST_SENT";
export const FETCH_SELECTED_USER_DOCUMENTS_SUCCESS = "FETCH_SELECTED_USER_DOCUMENTS_SUCCESS";
export const FETCH_SELECTED_USER_DOCUMENTS_ERROR = "FETCH_SELECTED_USER_DOCUMENTS_ERROR";
export const NO_MORE_SELECTED_USER_DOCUMENTS = "NO_MORE_SELECTED_USER_DOCUMENTS";
export const CHANGE_DOCUMENTS_FILTERS = "CHANGE_DOCUMENTS_FILTERS";
export const RESET_SELECTED_USER_DOCUMENTS_SUCCESS = "RESET_SELECTED_USER_DOCUMENTS_SUCCESS";
export const FETCH_LOGGED_USER_DOCUMENTS_REQUEST_SENT = "FETCH_LOGGED_USER_DOCUMENTS_REQUEST_SENT";
export const FETCH_LOGGED_USER_DOCUMENTS_SUCCESS = "FETCH_LOGGED_USER_DOCUMENTS_SUCCESS";
export const FETCH_LOGGED_USER_DOCUMENTS_ERROR = "FETCH_LOGGED_USER_DOCUMENTS_ERROR";
export const NO_MORE_LOGGED_USER_DOCUMENTS = "NO_MORE_LOGGED_USER_DOCUMENTS";
export const RESET_LOGGED_USER_DOCUMENTS_SUCCESS = "RESET_LOGGED_USER_DOCUMENTS_SUCCESS";
export const CREATE_DOCUMENT_ENTRY_REQUEST_SENT = "CREATE_DOCUMENT_ENTRY_REQUEST_SENT";
export const CREATE_DOCUMENT_ENTRY_SUCCESS = "CREATE_DOCUMENT_ENTRY_SUCCESS";
export const CREATE_DOCUMENT_ENTRY_ERROR = "CREATE_DOCUMENT_ENTRY_ERROR";
export const UPDATE_DOCUMENT_ENTRY_REQUEST_SENT = "UPDATE_DOCUMENT_ENTRY_REQUEST_SENT";
export const UPDATE_DOCUMENT_ENTRY_SUCCESS = "UPDATE_DOCUMENT_ENTRY_SUCCESS";
export const UPDATE_DOCUMENT_ENTRY_ERROR = "UPDATE_DOCUMENT_ENTRY_ERROR";
export const DELETE_DOCUMENT_ENTRY_REQUEST_SENT = "DELETE_DOCUMENT_ENTRY_REQUEST_SENT";
export const DELETE_DOCUMENT_ENTRY_SUCCESS = "DELETE_DOCUMENT_ENTRY_SUCCESS";
export const DELETE_DOCUMENT_ENTRY_ERROR = "DELETE_DOCUMENT_ENTRY_ERROR";
export const CLEAR_DOC = "CLEAR_DOC";
export const CLEAR_USER_DOCUMENTS = "CLEAR_USER_DOCUMENTS";


export const ALL_USERS_REQUEST_SENT = "ALL_USERS_REQUEST_SENT";
export const ALL_USERS_SUCCESS = "ALL_USERS_SUCCESS";
export const ALL_USERS_ERROR = "ALL_USERS_ERROR";
export const CLEAR_USERS = "CLEAR_USERS";
export const ALL_USERS_FILTER_CHANGE = "ALL_USERS_FILTER_CHANGE";

export const GET_CHILDREN = "GET_CHILDREN";
export const GET_CHILDREN_ERROR = "GET_CHILDREN_ERROR";
export const CREATE_CHILD_SUCCESS = "CREATE_CHILD_SUCCESS";
export const CREATE_CHILD_ERROR = "CREATE_CHILD_ERROR";
export const GET_PARENT_LIST = "GET_PARENT_LIST";
export const GET_PARENT_COUNT = "GET_PARENT_COUNT";
export const CLEAR_PARENT_COUNT = "CLEAR_PARENT_COUNT";
export const GET_PARENT_COUNT_ERROR = "GET_PARENT_COUNT_ERROR";
export const ACCEPT_CHILD = "ACCEPT_CHILD";
export const ACCEPT_CHILD_ERROR = "ACCEPT_CHILD_ERROR";
export const ADD_PARENT = "ADD_PARENT";
export const ADD_PARENT_ERROR = "ADD_PARENT_ERROR";
export const DENY_CHILD = "DENY_CHILD";
export const DENY_CHILD_ERROR = "DENY_CHILD_ERROR";
export const DELETE_CHILD = "DELETE_CHILD";
export const DELETE_CHILD_ERROR = "DELETE_CHILD_ERROR";
export const DELETE_PARENT = "DELETE_PARENT";
export const DELETE_PARENT_ERROR = "DELETE_PARENT_ERROR";
export const GET_PARENT_LIST_ERROR = "GET_PARENT_LIST_ERROR";
export const CLEAR_CONNECTED_PROFILE_RESPONSE = "CLEAR_CONNECTED_PROFILE_RESPONSE";

export const FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS = "FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS";
export const FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR = "FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR";
export const UPDATE_MEDICAL_PROFILE_SUCCESS = "UPDATE_MEDICAL_PROFILE_SUCCESS";
export const UPDATE_MEDICAL_PROFILE_ERROR = "UPDATE_MEDICAL_PROFILE_ERROR";
export const CLEAR_MEDICAL_PROFILE = "CLEAR_MEDICAL_PROFILE";

export const FETCH_CHAT_MESSAGES_REQUEST_SENT = "FETCH_CHAT_MESSAGES_REQUEST_SENT";
export const FETCH_CHAT_MESSAGES_REQUEST_SUCCESS = "FETCH_CHAT_MESSAGES_REQUEST_SUCCESS";
export const FETCH_CHAT_MESSAGES_REQUEST_ERROR = "FETCH_CHAT_MESSAGES_REQUEST_ERROR";
export const SEND_MESSAGE_TO_USER = "SEND_MESSAGE_TO_USER"
export const NO_MORE_MESSAGES = "NO_MORE_MESSAGES";
export const CLEAR_CHAT = "CLEAR_CHAT";
export const FETCH_CHAT_UNREAD_MESSAGES_COUNT_SUCCESS = "FETCH_CHAT_UNREAD_MESSAGES_COUNT_SUCCESS";
export const FETCH_CHAT_UNREAD_MESSAGES_COUNT_ERROR = "FETCH_CHAT_UNREAD_MESSAGES_COUNT_ERROR";
export const SET_MESSAGE_AS_SEEN = "SET_MESSAGE_AS_SEEN";
export const NEW_MESSAGE = "NEW_MESSAGE";
export const NEW_MESSAGE_ARRIVED = "NEW_MESSAGE_ARRIVED";

export const FETCH_SELECTED_USER_MEDICATIONS_SUCCESS = "FETCH_SELECTED_USER_MEDICATIONS_SUCCESS";
export const FETCH_SELECTED_USER_MEDICATIONS_ERROR = "FETCH_SELECTED_USER_MEDICATIONS_ERROR";
export const DELETE_MEDICATION_PLAN_REQUEST_SENT = "DELETE_MEDICATION_PLAN_REQUEST_SENT";
export const DELETE_MEDICATION_PLAN_SUCCESS = "DELETE_MEDICATION_PLAN_SUCCESS";
export const DELETE_MEDICATION_PLAN_ERROR = "DELETE_MEDICATION_PLAN_ERROR";
export const UPDATE_MEDICATION_DATA_REQUEST_SENT = "UPDATE_MEDICATION_DATA_REQUEST_SENT";
export const UPDATE_MEDICATION_DATA_SUCCESS = "UPDATE_MEDICATION_DATA_SUCCESS";
export const UPDATE_MEDICATION_DATA_ERROR = "UPDATE_MEDICATION_DATA_ERROR";
export const CREATE_MEDICATION_DATA_REQUEST_SENT = "CREATE_MEDICATION_DATA_REQUEST_SENT";
export const CREATE_MEDICATION_DATA_SUCCESS = "CREATE_MEDICATION_DATA_SUCCESS";
export const CREATE_MEDICATION_DATA_ERROR = "CREATE_MEDICATION_DATA_ERROR";
export const GET_SELECTED_MEDICAL_PLAN_STATS = "GET_SELECTED_MEDICAL_PLAN_STATS";
export const CLEAR_MEDICATION_PLAN = "CLEAR_MEDICATION_PLAN";

export const FETCH_SELECTED_USER_GENERAL_PRACTITIONER_SUCCESS = "FETCH_SELECTED_USER_GENERAL_PRACTITIONER_SUCCESS";
export const FETCH_SELECTED_USER_GENERAL_PRACTITIONER_ERROR = "FETCH_SELECTED_USER_GENERAL_PRACTITIONER_ERROR";
export const CREATE_GENERAL_PRACTITIONER_SUCCESS = "CREATE_GENERAL_PRACTITIONER_SUCCESS";
export const CREATE_GENERAL_PRACTITIONER_ERROR = "CREATE_GENERAL_PRACTITIONER_ERROR";
export const UPDATE_GENERAL_PRACTITIONER_SUCCESS = "EDIT_GENERAL_PRACTITIONER_SUCCESS";
export const UPDATE_GENERAL_PRACTITIONER_ERROR = "EDIT_GENERAL_PRACTITIONER_ERROR";
export const DELETE_GENERAL_PRACTITIONER_SUCCESS = "DELETE_GENERAL_PRACTITIONER_SUCCESS";
export const DELETE_GENERAL_PRACTITIONER_ERROR = "DELETE_GENERAL_PRACTITIONER_ERROR";
export const CLEAR_GENERAL_PRACTITIONER = "CLEAR_GENERAL_PRACTITIONER";

export const FETCH_SELECTED_USER_LAB_RESULTS_REQUEST_SENT = "FETCH_SELECTED_USER_LAB_RESULTS_REQUEST_SENT";
export const FETCH_SELECTED_USER_LAB_RESULTS_SUCCESS = "FETCH_SELECTED_USER_LAB_RESULTS_SUCCESS";
export const FETCH_SELECTED_USER_LAB_RESULTS_ERROR = "FETCH_SELECTED_USER_LAB_RESULTS_ERROR";
export const NO_MORE_SELECTED_USER_LAB_RESULTS = "NO_MORE_SELECTED_USER_LAB_RESULTS";
export const CHANGE_LAB_RESULTS_FILTERS = "CHANGE_LAB_RESULTS_FILTERS";
export const RESET_SELECTED_USER_LAB_RESULTS_SUCCESS = "RESET_SELECTED_USER_LAB_RESULTS_SUCCESS";
export const FETCH_LABORATORIES_REQUEST_SENT = "FETCH_LABORATORIES_REQUEST_SENT";
export const FETCH_LABORATORIES_SUCCESS = "FETCH_LABORATORIES_SUCCESS";
export const FETCH_LABORATORIES_ERROR = "FETCH_LABORATORIES_ERROR";
export const FETCH_LAB_DATES_REQUEST_SENT = "FETCH_LAB_DATES_REQUEST_SENT";
export const FETCH_LAB_DATES_SUCCESS = "FETCH_LAB_DATES_SUCCESS";
export const FETCH_LAB_DATES_ERROR = "FETCH_LAB_DATES_ERROR";
export const CLEAR_LAB_DATES = "CLEAR_LAB_DATES";
export const FETCH_LAB_RESULTS_REQUEST_SENT = "FETCH_LAB_RESULTS_REQUEST_SENT";
export const FETCH_LAB_RESULTS_SUCCESS = "FETCH_LAB_RESULTS_SUCCESS";
export const FETCH_LAB_RESULTS_ERROR = "FETCH_LAB_RESULTS_ERROR";
export const CLEAR_LABS = "CLEAR_LABS";
export const UPDATE_LAB_RESULTS_REQUEST_SENT = "UPDATE_LAB_RESULTS_REQUEST_SENT";
export const UPDATE_LAB_RESULTS_ERROR = "UPDATE_LAB_RESULTS_ERROR";
export const UPDATE_LAB_RESULTS_SUCCESS = "UPDATE_LAB_RESULTS_SUCCESS";
export const CREATE_LAB_RESULTS_DOC_REQUEST_SENT = "CREATE_LAB_RESULTS_DOC_REQUEST_SENT";
export const CREATE_LAB_RESULTS_DOC_SUCCESS = "CREATE_LAB_RESULTS_DOC_SUCCESS";
export const CREATE_LAB_RESULTS_DOC_ERROR = "CREATE_LAB_RESULTS_DOC_ERROR";
export const CLEAR_LAB_RESULTS = "CLEAR_LAB_RESULTS";
export const CLEAR_LAB = "CLEAR_LAB";
export const DELETE_LAB_RESULT_REQUEST_SENT = "DELETE_LAB_RESULT_REQUEST_SENT";
export const DELETE_LAB_RESULT_SUCCESS = "DELETE_LAB_RESULT_SUCCESS";
export const DELETE_LAB_RESULT_ERROR = "DELETE_LAB_RESULT_ERROR";

export const FETCH_HOSPITALS_REQUEST_SENT = "FETCH_HOSPITALS_REQUEST_SENT"
export const FETCH_HOSPITALS_SUCCESS = "FETCH_HOSPITALS_SUCCESS";
export const FETCH_HOSPITALS_ERROR = "FETCH_HOSPITALS_ERROR";

export const FETCH_PROVIDERS_REQUEST_SENT = "FETCH_PROVIDERS_REQUEST_SENT";
export const FETCH_PROVIDERS_SUCCESS = "FETCH_PROVIDERS_SUCCESS";
export const FETCH_PROVIDERS_ERROR = "FETCH_PROVIDERS_ERROR";
export const FETCH_PROVIDER_REQUEST_SENT = "FETCH_PROVIDERS_REQUEST_SENT";
export const FETCH_PROVIDER_SUCCESS = "FETCH_PROVIDER_SUCCESS";
export const FETCH_PROVIDER_ERROR = "FETCH_PROVIDER_ERROR";
export const FETCH_FIRST_AVAILABLE_REQUEST_SENT = "FETCH_FIRST_AVAILABLE_REQUEST_SENT";
export const FETCH_FIRST_AVAILABLE_SUCCESS = "FETCH_FIRST_AVAILABLE_SUCCESS";
export const FETCH_FIRST_AVAILABLE_ERROR = "FETCH_FIRST_AVAILABLE_ERROR";
export const FETCH_PROVIDER_TIMETABLE_REQUEST_SENT = "FETCH_PROVIDER_TIMETABLE_REQUEST_SENT";
export const FETCH_PROVIDER_TIMETABLE_SUCCESS = "FETCH_PROVIDER_TIMETABLE_SUCCESS";
export const FETCH_PROVIDER_TIMETABLE_ERROR = "FETCH_PROVIDER_TIMETABLE_ERROR";
export const FETCH_SPECIALTIES_REQUEST_SENT = "FETCH_SPECIALTIES_REQUEST_SENT";
export const FETCH_SPECIALTIES_SUCCESS = "FETCH_SPECIALTIES_SUCCESS";
export const FETCH_SPECIALTIES_ERROR = "FETCH_SPECIALTIES_ERROR";
export const FETCH_SPECIALTIES_COUNT_REQUEST_SENT = "FETCH_SPECIALTIES_COUNT_REQUEST_SENT";
export const FETCH_SPECIALTIES_COUNT_SUCCESS = "FETCH_SPECIALTIES_COUNT_SUCCESS";
export const FETCH_SPECIALTIES_COUNT_ERROR = "FETCH_SPECIALTIES_COUNT_ERROR";
export const CLEAR_PROVIDERS = "CLEAR_PROVIDERS";


export const RESET_APPOINTMENTS_SUCCESS = "RESET_APPOINTMENTS_SUCCESS";

export const UPDATE_APPOINTMENT_STATUS_REQUEST_SENT = "UPDATE_APPOINTMENT_STATUS_REQUEST_SENT";
export const UPDATE_APPOINTMENT_STATUS_REQUEST_ERROR = "UPDATE_APPOINTMENT_STATUS_REQUEST_ERROR";
export const CHANGE_APPOINTMENT_IN_APPOINTMENTS_LIST = "CHANGE_APPOINTMENT_IN_APPOINTMENTS_LIST";

export const SELECT_APPOINTMENT = "SELECT_APPOINTMENT";
export const SELECT_APPOINTMENT_IN_PROGRESS = "SELECT_APPOINTMENT_IN_PROGRESS";
export const FETCH_APPOINTMENTS_REQUEST_SENT = "FETCH_APPOINTMENTS_REQUEST_SENT";
export const FETCH_APPOINTMENTS_SUCCESS = "FETCH_APPOINTMENTS_SUCCESS";
export const FETCH_APPOINTMENTS_ERROR = "FETCH_APPOINTMENTS_ERROR";
export const FETCH_APPOINTMENT_REQUEST_SENT = "FETCH_APPOINTMENT_REQUEST_SENT";
export const FETCH_APPOINTMENT_SUCCESS = "FETCH_APPOINTMENT_SUCCESS";
export const FETCH_APPOINTMENT_ERROR = "FETCH_APPOINTMENT_ERROR";

export const FETCH_APPOINTMENT_USER_REQUEST_SENT = "FETCH_APPOINTMENT_USER_REQUEST_SENT";
export const FETCH_APPOINTMENT_USER_SUCCESS = "FETCH_APPOINTMENT_USER_SUCCESS";
export const FETCH_APPOINTMENT_USER_ERROR = "FETCH_APPOINTMENT_USER_ERROR";

export const CREATE_APPOINTMENT_REQUEST_SENT = "CREATE_APPOINTMENT_REQUEST_SENT";
export const CREATE_APPOINTMENT_SUCCESS = "CREATE_APPOINTMENT_SUCCESS";
export const CREATE_APPOINTMENT_ERROR = "CREATE_APPOINTMENT_ERROR";

export const UPDATE_APPOINTMENT_REQUEST_SENT = "UPDATE_APPOINTMENT_REQUEST_SENT";
export const UPDATE_APPOINTMENT_SUCCESS = "UPDATE_APPOINTMENT_SUCCESS";
export const UPDATE_APPOINTMENT_ERROR = "UPDATE_APPOINTMENT_ERROR";

export const SEARCH_ICD_REQUEST_SENT = "SEARCH_ICD_REQUEST_SENT";
export const SEARCH_ICD_SUCCESS = "SEARCH_ICD_SUCCESS";
export const SEARCH_ICD_ERROR = "SEARCH_ICD_ERROR";
export const CLEAR_ICD = "CLEAR_ICD";

export const UPLOAD_PROFILE_IMAGE = "UPLOAD_PROFILE_IMAGE";
export const REMOVE_PROFILE_IMAGE = "REMOVE_PROFILE_IMAGE";

export const FETCH_SELECTED_CHILD_MEDICATIONS_SUCCESS = "FETCH_SELECTED_CHILD_MEDICATIONS_SUCCESS";
export const FETCH_SELECTED_CHILD_MEDICATIONS_ERROR = "FETCH_SELECTED_CHILD_MEDICATIONS_ERROR";
export const GET_MEDICATION_EVENTS_SUCCESS = "GET_MEDICATION_EVENTS_SUCCESS";
export const GET_MEDICATION_EVENTS_ERROR = "GET_MEDICATION_EVENTS_ERROR";
export const GET_CHILD_MEDICATION_EVENTS_SUCCESS = "GET_CHILD_MEDICATION_EVENTS_SUCCESS";
export const GET_CHILD_MEDICATION_EVENTS_ERROR = "GET_CHILD_MEDICATION_EVENTS_ERROR";
export const FETCH_MED_ENTRIES_FOR_MED_EVENTS_SUCCESS = "FETCH_MED_ENTRIES_FOR_MED_EVENTS_SUCCESS";
export const FETCH_MED_ENTRIES_FOR_MED_EVENTS_ERROR = "FETCH_MED_ENTRIES_FOR_MED_EVENTS_ERROR";
export const FETCH_CHILD_MED_ENTRIES_FOR_MED_EVENTS_SUCCESS = "FETCH_CHILD_MED_ENTRIES_FOR_MED_EVENTS_SUCCESS";
export const FETCH_CHILD_MED_ENTRIES_FOR_MED_EVENTS_ERROR = "FETCH_CHILD_MED_ENTRIES_FOR_MED_EVENTS_ERROR";
export const CLEAR_MED = "CLEAR_MED";
export const CLEAR_EVENTS = "CLEAR_EVENTS";
export const DELETE_MEDICATION_EVENTS_REQUEST_SENT = "DELETE_MEDICATION_EVENTS_REQUEST_SENT";
export const DELETE_MEDICATION_EVENTS_SUCCESS = "DELETE_MEDICATION_EVENTS_SUCCESS";
export const DELETE_MEDICATION_EVENTS_ERROR = "DELETE_MEDICATION_EVENTS_ERROR";
export const DELETE_MED_EVENTS_ENTRIES_REQUEST_SENT = "DELETE_MED_EVENTS_ENTRIES_REQUEST_SENT";
export const DELETE_MED_EVENTS_ENTRIES_SUCCESS = "DELETE_MED_EVENTS_ENTRIES_SUCCESS";
export const DELETE_MED_EVENTS_ENTRIES_ERROR = "DELETE_MED_EVENTS_ENTRIES_ERROR";
export const CREATE_MEDICATION_EVENT_REQUEST_SENT = "CREATE_MEDICATION_EVENT_REQUEST_SENT";
export const CREATE_MEDICATION_EVENT_SUCCESS = "CREATE_MEDICATION_EVENT_SUCCESS";
export const CREATE_MEDICATION_EVENT_ERROR = "CREATE_MEDICATION_EVENT_ERROR";
export const UPDATE_MEDICATION_EVENT_REQUEST_SENT = "UPDATE_MEDICATION_EVENT_REQUEST_SENT";
export const UPDATE_MEDICATION_EVENT_SUCCESS = "UPDATE_MEDICATION_EVENT_SUCCESS";
export const UPDATE_MEDICATION_EVENT_ERROR = "UPDATE_MEDICATION_EVENT_ERROR";
export const UPDATE_MEDICATION_ENTRY_REQUEST_SENT = "UPDATE_MEDICATION_ENTRY_REQUEST_SENT";
export const UPDATE_MEDICATION_ENTRY_SUCCESS = "UPDATE_MEDICATION_ENTRY_SUCCESS";
export const UPDATE_MEDICATION_ENTRY_ERROR = "UPDATE_MEDICATION_ENTRY_ERROR";


export const SET_FORM_TO_DISABLED = "SET_FORM_TO_DISABLED";
export const CLEAR_APPOINTMENT_CREATION_STATUS = "CLEAR_APPOINTMENT_CREATION_STATUS";
export const SET_PRESELECTED_APPOINTMENT = "SET_PRESELECTED_APPOINTMENT";
export const RESET_PRESELECTED_APPOINTMENT = "RESET_PRESELECTED_APPOINTMENT";
export const SET_FORM_TO_ENABLED = "SET_FORM_TO_ENABLED";
export const HEALTH_ISSUE = "HEALTH_ISSUE";
export const APPOINTMENTS = "APPOINTMENTS";
export const CHILD = "CHILD";
export const DOCUMENT = "DOCUMENT";
export const CHRONIC_CONDITIONS = "CHRONIC_CONDITIONS";
export const GP = "GP";
export const LOGBOOK = "LOGBOOK";
export const PROFILE = "PROFILE";
export const MEDICATIONS = "MEDICATIONS";
export const SYMPTOMS = "SYMPTOMS";
export const SETTINGS = "SETTINGS";

export const FETCH_LATEST_VIDEO_MESSAGE_SUCCESS="FETCH_LATEST_VIDEO_MESSAGE_SUCCESS"
import React, { useCallback, useRef } from 'react';

import VideoOffIcon from './icons/VideoOffIcon';
import VideoOnIcon from './icons/VideoOnIcon';

import useDevices from './hooks/useDevices/useDevices';
import useLocalVideoToggle from './hooks/useLocalVideoToggle/useLocalVideoToggle';
import {Button} from "react-bootstrap";
import {$$} from "../../helpers/localization";

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <Button
      className={props.className}
      onClick={toggleVideo}
      disabled={!hasVideoInputDevices || props.disabled}
    >
      <span className="text-white mr-2">{isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}</span>
      {$$(!hasVideoInputDevices ? 'no_video' : isVideoEnabled ? 'stop_video' : 'start_video')}
    </Button>
  );
}

import React, {Component} from "react";
import PropTypes from "prop-types";
import {getPrescriptionDetailsString} from "../../utils/medicationUtils";


const sortByProp = (prop) => {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return -1;
        }
        if (a[prop] > b[prop]) {
            return 1;
        }
        return 0;
    }
}

export default class CheckboxList extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return <div className="symptom-form-box" style={{width:"100%"}}>
            <div className="d-flex">
                <div className="d-flex flex-column medication-checkbox-container">
                    <input onChange={() => {this.props.onCheckBoxChange(this.props.item)}} checked={this.props.selected} id={this.props.index}
                           className="symptom-checkbox" type="checkbox"/>
                    <label className="checkbox-label" htmlFor={this.props.index}>
                        <i className="fa fa-check wht" aria-hidden="true"/>
                    </label>
                </div>
                <div className="d-flex flex-column ml-2">
                    <div className="symptom-item-name"> {this.props.itemLabel}</div>
                    <div className="medrec-grey-2"> {getPrescriptionDetailsString(this.props.item)}</div>
                    {this.props.other_intakes.sort(sortByProp("nhis_prescription_dosage_instruction_sequence")).map(o => {
                        return <div key={o.id} className="medrec-grey-2">{getPrescriptionDetailsString(o)}</div>
                    })}
                </div>
            </div>
        </div>
    }
}

CheckboxList.propTypes = {
    onCheckBoxChange:PropTypes.func,
    item:PropTypes.any,
    itemLabel:PropTypes.any,
    other_intakes:PropTypes.array,
    index:PropTypes.any,
    selected:PropTypes.bool,
}




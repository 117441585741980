import React, {Component} from 'react'
import {Scatter} from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import 'chartjs-plugin-datalabels';
import $ from 'jquery';
import PropTypes from "prop-types";


export class BloodPressureClassification extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * Get array of box annotations for the background rectangles.
     *
     * @returns {array} array of box annotation objects
     */
    getBoxAnnotations = () => {
        const annotations = [];
        this.props.chartData.ranges.forEach((r, i) => {
            const rectIndex = i === 0 || i === 1 ? i : i * 2;
            if (i === 0) {
                annotations.push(this.getSingleRectangle(r, rectIndex));
            } else {
                annotations.push(...this.splitRectangle(r, rectIndex));
            }
        });
        annotations.reverse();
        annotations.forEach((r,i) =>{
            r.id = 'a-box-' + i;
        });
        return annotations;
    }

    /**
     * Splits the range for the classification boxes into two rectangles
     *
     * @param {object} range object with coordinates to draw
     * @param {number} index of the range, used to set the box id
     * @returns {array} array of box annotation objects
     */
    splitRectangle(range, index) {
        const rect1 = {
            x1: 0,
            y1: range.y1,
            x2: range.x2,
            y2: range.y2,
            color: range.color
        };
        const rect2 = {
            x1: range.x1,
            y1: 0,
            x2: range.x2,
            y2: range.y1,
            color: range.color
        };
        return [this.getSingleRectangle(rect1, index), this.getSingleRectangle(rect2, index + 1)];
    }

    /**
     * Creates a box annotation object for the rectangle to draw
     *
     * @param {object} range object with coordinates to draw
     * @param {number} index of the range, used to set the box id
     * @returns {array} array of box annotation objects
     */
    getSingleRectangle(range, index) {
        return {
            type: 'box',
            drawTime: 'beforeDatasetsDraw',
            id: 'a-box-' + index,
            xScaleID: 'x-axis-1',
            yScaleID: 'y-axis-1',
            xMin: range.x1,
            yMin: range.y1,
            xMax: range.x2 + 1,
            yMax: range.y2 + 1,
            border: 0,
            borderColor: range.color,
            backgroundColor: range.color,
        };
    }

    /**
     * Get array for the y-axis properties.
     *
     * @returns {array} array of y-axis properties
     */
    getYAxesOptions = () => {
        return this.props.chartData.yAxis.map(a => {
            return {
                afterTickToLabelConversion: this.hideFirstAndLastAxisValue.bind(this),
                ticks: a.ticks,
                scaleLabel: {
                    display: true,
                    labelString: a.label
                }
            }
        });
    }

    /**
     * Get array for the x-axis properties.
     *
     * @returns {array} array of x-axis properties
     */
    getXAxesOptions = () => {
        return this.props.chartData.xAxis.map(a => {
            return {
                afterTickToLabelConversion: this.hideFirstAndLastAxisValue.bind(this),
                ticks: a.ticks,
                scaleLabel: {
                    display: true,
                    labelString: a.label
                }
            }
        });
    }

    /**
     * Hide the first and last values for the x and y axis.
     *
     * @param {object} scaleInstance the chart scale object
     */
    hideFirstAndLastAxisValue = (scaleInstance) => {
        scaleInstance.ticks[0] = '';
        scaleInstance.ticks[scaleInstance.ticks.length - 1] = '';
        scaleInstance.ticksAsNumbers[0] = '';
        scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] = '';
    }

    render() {
        if (!this.props.chartData.data) {
            return '';
        }

        const options = {
            scales: {
                yAxes: this.getYAxesOptions(),
                xAxes: this.getXAxesOptions()
            },
            legend: {
                display: false
            },
            responsive: true,
            maintainAspectRatio: false,
            shouldDrawRectangleLabels: true,
            plugins: {
                datalabels: {
                    display: false,
                },
            },
            annotation: {
                annotations: this.getBoxAnnotations()
            }
        };

        const chartData = $.extend(true, {}, this.props.chartData.data);

        return (
            <div className="card-body chart-card">
                <div className="card-title chart-title">
                    {this.props.chartData.label}
                </div>
                <div className="chart-container">
                    <Scatter data={chartData} options={options}/>
                </div>
            </div>
        )
    }
}

BloodPressureClassification.propTypes = {
    chartData: PropTypes.object,
    settings: PropTypes.object,
}

export default BloodPressureClassification

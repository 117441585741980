import React from "react";
import objectPath from "object-path";
import { Link } from "react-router-dom";
import MenuItemText from "./MenuItemText";
import MenuSubmenu from "./MenuSubmenu";
import clsx from "clsx";
import {$$} from "../../../helpers/localization";
import {Modal} from "react-bootstrap";
import {toAbsoluteUrl} from "../..";
import AptechkoPillIcon from "../../../../public/media/icons/aptechko_icon.png"
import {isBulgarian} from "../../../utils/userUtils";

export default class MenuItem extends React.Component {
  asideLeftLIRef = React.createRef();
  isDropdown =  document.body.classList.contains("kt-aside-menu--dropdown");

  submenuToggle =
    this.props.item.toggle === "click"
      ? "click"
      : objectPath.get(this.props.item, "submenu.type") === "tabs"
      ? "tabs"
      : "hover";

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param event Event
   */
  mouseEnter = event => {
    if (!this.isDropdown) {
      return;
    }

    if (this.props.item.submenu) {
      this.asideLeftLIRef.current.classList.add("kt-menu__item--hover");
      this.asideLeftLIRef.current.setAttribute(
        "data-ktmenu-submenu-toggle",
        this.submenuToggle
      );
    }
  };

  /**
   * Mouse Leave event
   * @param event: MouseEvent
   */
  mouseLeave = event => {
    if (!this.isDropdown) {
      return;
    }

    if (this.props.item.submenu && this.submenuToggle === "hover") {
      this.asideLeftLIRef.current.classList.remove("kt-menu__item--hover");
      this.asideLeftLIRef.current.removeAttribute("data-ktmenu-submenu-toggle");
    }
  };

  isMenuItemIsActive = item => {
    if (item.submenu) {
      return this.isMenuRootItemIsActive(item);
    }

    if (!item.page) {
      return false;
    }

    return this.props.currentUrl.indexOf(item.page) !== -1;
  };

  isMenuRootItemIsActive = item => {
    for (const subItem of item.submenu) {
      if (this.isMenuItemIsActive(subItem)) {
        return true;
      }
    }

    return false;
  };

  onMenuItemClick = (item, parentItem) => {
    if(parentItem && parentItem.title === "Children") {
        this.props.selectUser(item);
    }
  }

  isChildSelected = (item, parentItem, selectedUser) => {
    if(parentItem && selectedUser) {
        return item.id === selectedUser.id;
    }
    return false;
  }

  render() {
    const { item, currentUrl, parentItem, layoutConfig, selectedUser, selectUser, unreadChatMessages } = this.props;
    const isActive = this.isMenuItemIsActive(item);
    const isChildSelected = this.isChildSelected(item, parentItem, selectedUser);
    const unreadMessagesCount = unreadChatMessages ? unreadChatMessages.reduce((sum, m) => sum + m.count, 0) : 0;
    const isCountryBulgaria = isBulgarian(selectedUser.country);

    if (window.aptechko && item.aptechko) {
      return null;
    }

    return (
      <li
        ref={this.asideLeftLIRef}
        aria-haspopup="true"
        data-placement="right"
        data-ktmenu-submenu-mode={item.mode}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
        className={clsx(
          "kt-menu__item",
          {
            "kt-menu__item--submenu": item.submenu,
            "kt-menu__item--open kt-menu__item--here": isActive && item.submenu,
            "kt-menu__item--active kt-menu__item--here":
              (isActive && !item.submenu) || isChildSelected,
            "kt-menu__item--icon-only": item["icon-only"]
          },
          item["custom-class"]
        )}
        data-ktmenu-dropdown-toggle-class={item["dropdown-toggle-class"]}
      >

        {item.aptechko && isCountryBulgaria && <AptechkoSearchMedicine />}

        {(!item.submenu && !item.hidden && !item.aptechko) && (
          <Link onClick={() => this.onMenuItemClick(item, parentItem)} to={item.page !== null && item.page !== undefined ? `/${item.page}` : '#'}
                title={item.translate ? $$(item.translate) : item.title}
                className="kt-menu__link kt-menu__toggle">
            <MenuItemText item={item} parentItem={parentItem} unreadMessagesCount={unreadMessagesCount}/>
          </Link>
        )}

        {(item.submenu && !item.hidden) && (
          <a className="kt-menu__link kt-menu__toggle">
            <MenuItemText item={item} parentItem={parentItem} />
          </a>
        )}

        {(item.submenu && !item.hidden) && (
          <div className="kt-menu__submenu">
            <span className="kt-menu__arrow" />

            {item["custom-class"] === "kt-menu__item--submenu-fullheight" && (
              <div className="kt-menu__wrapper">
                <MenuSubmenu
                  item={item}
                  parentItem={item}
                  currentUrl={currentUrl}
                />
              </div>
            )}

            {item["custom-class"] !== "kt-menu__item--submenu-fullheight" && (
              <MenuSubmenu
                item={item}
                parentItem={item}
                currentUrl={currentUrl}
                layoutConfig={layoutConfig}
                selectUser={selectUser}
                selectedUser={selectedUser}
              />
            )}
          </div>
        )}
      </li>
    );
  }
}



class AptechkoSearchMedicine extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {open:false}
  }

  render() {
    const allowIframe = `geolocation ${process.env.APTECHKO_SEARCH_URL}`;
    return (
        <>
          <div className="w-100 pt-2 pb-2 pl-0 pr-3">
          <div className="d-flex w-100 space-between aptechko-link-item p-2 pl-3 align-items-center pointer" onClick={(e) => {
            e.preventDefault(); e.stopPropagation();
            if (!window.aptechko) {
              this.setState({open: true})
            }
          }}>
            <div className="aptechko-menu-texts" style={{fontSize:"1rem"}} title={`${$$("search_medicine_with")} Аптечко`}>
              {$$("search_medicine_with")}&nbsp;<img style={{height:"1rem", verticalAlign:"sub"}} src="https://aptechko.bg/static/images/logo.png" alt="Aptechko"/>
            </div>
            <div className="flex-grow-0 flex-shrink-0">
                <img style={{width:"2.5rem"}} src={AptechkoPillIcon} alt={"Aptechko"} title={`${$$("search_medicine_with")} Аптечко`}/>
            </div>
          </div>
          </div>
          {this.state.open && <Modal onHide={(e)=>{
            this.setState({open:false})
          }} show={this.state.open} size={"xl"}>
            <Modal.Header closeButton />
            <Modal.Body>
              {<iframe src={`${process.env.APTECHKO_SEARCH_URL}`} width="100%" style={{minHeight:"70vh"}} frameBorder="0" allow={allowIframe}/>}
            </Modal.Body>
          </Modal>}
        </>
    )
  }

}

import React, {Component} from "react";
import PropTypes from "prop-types";

export default class DoubleImageSolidPill extends Component {


    render() {
        return (<div className={`${this.props.selected ? "pill-picker-selected" : ""}`}
                     style={{position: "relative", height: "75px", display: "flex", justifyContent: "center"}}>
            <img src={this.props.MedF} style={{
                position: "absolute",
                top: "10px",
                height: "50px",
                width: "50px",
                filter: "drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5))"
            }} alt={"Capsule Pill Front"}/>
            <img src={this.props.MedM} style={{
                position: "absolute",
                top: "10px",
                height: "50px",
                width: "50px",
                filter: "drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5))"
            }} alt={"Capsule Pill Middle"}/>

            <div/>
            <div style={{
                marginTop: "10px",
                backgroundImage: `url(${this.props.MedM})`,
                mask: `url(${this.props.MedMMask})`,
                WebkitMask: `url(${this.props.MedMMask})`,
                WebkitMaskSize: "contain",
                maskSize: "contain",
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                backgroundColor: this.props.secondaryColour,
                backgroundBlendMode: "multiply",
                position: "absolute",
                top: 0,
                height: "50px",
                width: "50px"
            }}/>
            <div style={{
                marginTop: "10px",
                backgroundImage: `url(${this.props.MedF})`,
                mask: `url(${this.props.MedFMask})`,
                WebkitMask: `url(${this.props.MedFMask})`,
                WebkitMaskSize: "contain",
                maskSize: "contain",
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                backgroundColor: this.props.primaryColour,
                backgroundBlendMode: "multiply",
                position: "absolute",
                top: 0,
                height: "50px",
                width: "50px"
            }}/>
        </div>)
    }
}

DoubleImageSolidPill.propTypes = {
    MedB:  PropTypes.string,
    MedBMask:  PropTypes.string,
    MedF:  PropTypes.string,
    MedFMask: PropTypes.string,
    MedM:  PropTypes.string,
    MedMMask:  PropTypes.string,
    primaryColour:  PropTypes.string,
    secondaryColour:  PropTypes.string,
    selected:  PropTypes.bool
}
import {
    REMOVE_PROFILE_IMAGE,
    UPLOAD_PROFILE_IMAGE
} from './actions'

export const uploadImageToStore = (imageUrl) => {

    return (dispatch) => {
        dispatch({
            type: UPLOAD_PROFILE_IMAGE,
            payload: imageUrl
        })
    }
}

export function removeImageFromStore() {
    return (dispatch) => {
        dispatch({type: REMOVE_PROFILE_IMAGE})
    }
}
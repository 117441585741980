import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import AnswerFactory from "../answer/AnswerFactory";
import SelectionType from "../../models/selection-type/SelectionType";
import ReactMarkdown from "react-markdown";

class Question extends Component {
    language;
    constructor(props) {
        super(props);
        this.state = {answers: props.question.answers};
        this.language = props.language;
    }

    render() {
        const question = this.props.question;
        const answers = this.state.answers;


        return (
            <div className="d-flex flex-column">
                <h1 className="mb-3">{question.question && question.question[this.language]}</h1>
                <h4>
                    {question.text && <ReactMarkdown>
                        {question.text[this.language]}
                    </ReactMarkdown>
                    }
                </h4>
               {/* <h4 dangerouslySetInnerHTML={{__html: question.text && question.text[this.language]}}/>*/}
                <Form>
                    {answers?.map((answer, index) => {
                        return (
                            <div key={index} className={"p-1"}>
                                {answer.topic && <div className={"answer-topic"}>{answer.topic[this.language]}</div>}
                                <AnswerFactory answer={answer} question={question} language={this.language}
                                               updateAnswer={this.updateAnswer}/>
                            </div>
                        )
                    })}
                </Form>
            </div>
        );
    };

    updateAnswer = (updatedAnswer) => {
        const answers = this.props.question.answers;
        const isSingleSelect = this.props.question.selectionType === SelectionType.SINGLE;

        if (isSingleSelect) {
            this.clearAnswers(updatedAnswer);
        }
        this.setState({answers});
        this.props.updateAnswer();
    };

    clearAnswers(updatedAnswer) {
        this.props.question.answers.forEach(answer => {
            if (updatedAnswer.id !== answer.id) {
                answer.checked = false;
                answer.result = '';
            }
        });
    }
}

export default Question;

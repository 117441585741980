import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
import KTToggle from "../../_assets/js/toggle";
import {$$} from "../../../helpers/localization";
import {CALLING, IDLE, ONGOING} from "../../../constants/call_status";
import classNames from "classnames";
import {VideoControlsOverlay} from "../../../components/videocall/VideoControlsOverlay";
import ExposeContextToVideoControls from "./ExposeContextToVideoControls";
import {VideoManager} from "../../../components/videocall/video_manager";
import KTUtil from "../../_assets/js/util";

class HeaderMobile extends React.Component {
  toggleButtonRef = React.createRef();

  componentDidMount() {
    new KTToggle(this.toggleButtonRef.current, this.props.toggleOptions);
    if (window.aptechko) {
      let newVar = KTUtil.get(this.props.toggleOptions.target);
      if (!KTUtil.hasClass(newVar, this.props.toggleOptions.targetState)) {
        KTUtil.addClass(newVar, this.props.toggleOptions.targetState + " aptechko")
      }
    }
  }

  render() {
    const {
      headerLogo,
      asideDisplay,
      headerMenuSelfDisplay,
      headerMobileCssClasses,
      headerMobileAttributes
    } = this.props;
    let videoControlsClass = classNames("video-controls-wrap", {"d-none": !this.props.showVideoControls});
    return (
      <div
        id="kt_header_mobile"
        className={`kt-header-mobile ${headerMobileCssClasses} header-topbar-mobile`}
        {...headerMobileAttributes}
      >
        <div className="kt-header-mobile__logo">
          {/*<Link to="/">
            <img alt="logo" src={headerLogo} />
          </Link>*/}
          <Link to="">
            <h4>{$$(window.aptechko ? 'main_page_label': 'title')}</h4>
            {/* <img alt="logo" src={this.props.headerLogo} /> */}
          </Link>
        </div>

        <div className="kt-header-mobile__toolbar">
          {!window.aptechko && <div className={videoControlsClass}>
            {/* {this.props.userToChatFullName ? <div className="video-controls-username">{this.props.userToChatFullName}</div> : null} */}
            {!this.props.modalShow && <ExposeContextToVideoControls topbar={false} modalShow={this.props.modalShow} onHangup={()=>{
              VideoManager.setState({callStatus: IDLE})
            }}/>}
            {/* <audio id="endCallSignal" preload="auto" ref={this.endAudio}>
                        <source src={end_of_call_mp3} type="audio/mp3" />
                    </audio>

                    <audio id="callingSignal" loop preload="auto" ref={this.callAudio} >
                        <source src={calling_mp3} type="audio/mp3" />
                    </audio> */}
          </div>}

          {asideDisplay && (
            <button
              className="kt-header-mobile__toggler kt-header-mobile__toggler--left"
              id="kt_aside_mobile_toggler"
            >
              <span />
            </button>
          )}

          {window.aptechko && <div className={videoControlsClass}>
            {!this.props.modalShow && <ExposeContextToVideoControls topbar={false} modalShow={this.props.modalShow} onHangup={()=>{
              VideoManager.setState({callStatus: IDLE})
            }}/>}
          </div>}

          {/*{headerMenuSelfDisplay && (
            <button
              className="kt-header-mobile__toggler"
              id="kt_header_mobile_toggler"
            >
              <span />
            </button>
          )}*/}
          {!window.aptechko &&
          <button
              ref={this.toggleButtonRef}
              className="kt-header-mobile__topbar-toggler"
              id="kt_header_mobile_topbar_toggler"
          >
            <i className="flaticon-more"/>
          </button>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  headerLogo: builder.selectors.getStickyLogo(store),
  asideDisplay: objectPath.get(
    store.builder.layoutConfig,
    "aside.self.display"
  ),
  headerMenuSelfDisplay:
    objectPath.get(store.builder.layoutConfig, "header.menu.self.display") ===
    true,
  toggleOptions: {
    target: "body",
    targetState: "kt-header__topbar--mobile-on",
    togglerState: "kt-header-mobile__toolbar-topbar-toggler--active"
  },
  headerMobileCssClasses: builder.selectors.getClasses(store, {
    path: "header_mobile",
    toString: true
  }),
  headerMobileAttributes: builder.selectors.getAttributes(store, {
    path: "aside_menu"
  }),
  showVideoControls: store.video.data.callStatus === 'PRE_JOIN',
  videoMuted: store.video.data.videoMuted,
  audioMuted: store.video.data.audioMuted,
  modalShow: store.video.data.modalShow
});

export default connect(mapStateToProps)(HeaderMobile);

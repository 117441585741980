import {useEffect, useRef, useState} from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useIsRoomForGroupEvent() {
    const { room } = useVideoContext();
    const [state, setState] = useState<boolean | undefined>(room?.name.indexOf("event") > 1);
    const ref = useRef(false)
    useEffect(() => {
        ref.current = true;
        if (room) {
            const setRoomName = () => {
                if (ref.current) {
                    setState(room?.name.indexOf("event") > 1);
                }
            }
            setRoomName();
        }
    }, [room]);

    return state;
}

import {
    UPDATE_CALL_DATA,
    UPDATE_CAMERA_DEVICES
} from "../../actions/actions";
import {chatService} from "../../service/chat_service";
import store from "../../store";
import {
    CALLING,
    IDLE,
    ONGOING,
    RINGING
} from "../../constants/call_status";
import {
    COMPLETED,
    MISSED
} from "../../constants/video_message_status";
import moment from 'moment'

/* eslint-disable */

export const VideoManager = {
    appId: process.env.REACT_APP_QUICK_BLOX_APP_ID,
    authKey: process.env.REACT_APP_QUICK_BLOX_AUTH_KEY,
    authSecret: process.env.REACT_APP_QUICK_BLOX_AUTH_SECRET,

    config: {
        debug: true,
        webrtc: {
            answerTimeInterval: 60,
            dialingTimeInterval: 5,
            disconnectTimeInterval: 10,
            statsReportTimeInterval: 5,
            incomingLimit: 1
        },
        streamManagement: {
            enable: true
        }
    },

    state: {
        callStatus: IDLE
    },


    setState(obj) {
        this.state = {...this.state, ...obj}
        this.updateVideoData(this.state);
    },

    updateVideoData: function (data) {
        store.dispatch({type: UPDATE_CALL_DATA, result: data})
        /*window && window.QB && window.QB.webrtc && window.QB.webrtc.getMediaDevices("videoinput").then(function (devices) {
            if (devices.length) {
                store.dispatch({type: UPDATE_CAMERA_DEVICES, result: devices})
            }
        });*/
    },

    getVideoData: function () {
        return store.getState().video.data;
    },

    setSession(session) {
        this.session = session;
    },

    clearSession() {
        this.session = undefined;
    },

    createQBSession(res, userToChatFullName) {
        !window.QB.webrtc && window.QB.init(Number(this.appId), this.authKey, this.authSecret, this.config);
        window.QB.createSession((session) => {
            this.updateVideoData({videoSession: res, userToChatFullName: userToChatFullName})
            this.addQBListeners();
            this.connectToQBChat();
        });
    },

    getVideoChatSession(userToChatId, userToChatFullName) {
        chatService.getVideoCallSession(userToChatId).then((res) => {
            if (res) {
                this.createQBSession(res, userToChatFullName);
            }
        })
    },

    stopVideoChatSession(videoSession) {
        const params = {
            callerId: videoSession.callerUserId,
            destinationId: videoSession.destinationUserId
        }
        if (!videoSession.callerUserId) {
            return;
        }
        chatService.stopVideoCallSession(params);
    },

    hangup(callback) {
        let start = null;
        if (this.session) {
            start = this.session.startCallTime;
            this.session.stop({});
            this.clearSession()
        }
        let videoData = this.getVideoData();
        const videoSession = videoData.videoSession;
        if (videoSession) {
            this.stopVideoChatSession(videoSession);
            if (videoData.videoMessageId && start) {
                let duration = moment(new Date()).diff(moment(start));
                chatService.updateVideoCallDurationAndStatus(videoData.videoMessageId, duration, COMPLETED);
            }
        }
        if (window.QB.chat && !window.QB.chat._isLogout) {
            window.QB.chat.disconnect();
        }
        if (window.QB.Auth) {
            window.QB.destroySession(() => null);
        }
        this.updateVideoData({
            videoSession: {},
            currentUserQBId: null,
            callerQBId: null,
            recipientQBId: null,
            initiate_call: false,
            userToChatFullName: null,
            videoMuted: false,
            audioMuted: false,
            modalShow: false
        });
        if (callback) {
            callback();
        }
    },

    connectToQBChat(callback) {
        if (window.QB.chat) {
            //this.setSession({callStatus: STARTING_CALL});
            let videoData = this.getVideoData();
            let params = {
                jid: window.QB.chat.helpers.getUserJid(videoData.videoSession.destinationQBId, this.appId),
                password: videoData.videoSession.destinationQBSessionToken
            };
            window.QB.chat.connect(params, function (err, res) {
                if (err) {
                    if (this.session) {
                        this.session.stop({});
                        this.clearSession();
                    }
                } else {
                    //if (callback) {
                    //    callback(videoData.recipientQBId)
                    //}
                }
            }.bind(this));
        }
    },

    onAcceptCall() {
        //check for audio call
        const isAudio = false
        let mediaParams = {
            'audio': {echoCancellation: true, noiseSuppression: true},
            'video': {width: {min: 320, ideal: 720, max: 1920}},
            'options': {
                'muted': true,
                'mirror': false
            },
            'elemId': 'localVideo',
            'facingMode': 'user'
        };

        /** Hangup */
        if (this.state.callStatus === ONGOING || this.state.callStatus === CALLING) {
            if (this.session) {
                this.session.stop({});
                this.clearSession();
                return false;
            }
        } else {
            /** Check internet connection */
            if (!window.navigator.onLine) {
                return false;
            }
            let videoData = this.getVideoData();

            if (!videoData.videoSession && !videoData.videoSession.callerQBId) {
                return false;
            }

            if (isAudio) {
                mediaParams = {
                    audio: true,
                    video: false,
                    facingMode: 'user'
                };
            }
            const currentSession = this.session;
            currentSession.getUserMedia(mediaParams, function (err, stream) {
                if (err || !stream.getAudioTracks().length || (isAudio ? false : !stream.getVideoTracks().length)) {
                    currentSession.stop({});
                    this.clearSession();
                } else {
                    var callParameters = {};
                    if (isAudio) {
                        callParameters.callType = 2;
                    }
                    // Call to users
                    currentSession.accept({});
                }
            }.bind(this));
        }
    },

    onCallUser() {
        //check for audio call
        const isAudio = false
        let mediaParams = {
            'audio': {echoCancellation: true, noiseSuppression: true},
            'video': {width: {min: 320, ideal: 720, max: 1920}},
            'options': {
                'muted': true,
                'mirror': false
            },
            'elemId': 'localVideo',
            'facingMode': 'user'
        };

        /** Hangup */
        if (this.state.callStatus === ONGOING || this.state.callStatus === CALLING) {
            if (this.session) {
                this.session.stop({});
                this.clearSession();
                return false;
            }
        } else {
            /** Check internet connection */
            if (!window.navigator.onLine) {
                return false;
            }
            let videoData = this.getVideoData();

            if (!videoData.recipientQBId) {
                return false;
            }

            const currentSession = window.QB.webrtc.createNewSession([videoData.recipientQBId], isAudio ? window.QB.webrtc.CallType.AUDIO : window.QB.webrtc.CallType.VIDEO, null, null);
            this.setSession(currentSession);

            if (isAudio) {
                mediaParams = {
                    audio: true,
                    video: false,
                    facingMode: 'user'
                };
            }

            currentSession.getUserMedia(mediaParams, function (err, stream) {
                if (err || !stream.getAudioTracks().length || (isAudio ? false : !stream.getVideoTracks().length)) {
                    var errorMsg = '';
                    currentSession.stop({});
                    this.clearSession();
                } else {
                    var callParameters = {};
                    if (isAudio) {
                        callParameters.callType = 2;
                    }
                    // Call to users
                    currentSession.call({}, function () {
                        if (!window.navigator.onLine) {
                            currentSession.stop({});
                            this.clearSession();
                        } else {
                            this.setState({callStatus: CALLING});
                        }
                    }.bind(this));
                }
            }.bind(this));
        }
    },

    muteAudio() {
        if (this.session) {
            this.session.mute("audio");
        }
        this.updateVideoData({audioMuted: true});
    },

    unmuteAudio() {
        if (this.session) {
            this.session.unmute("audio");
        }
        this.updateVideoData({audioMuted: false});
    },

    getVideoCameras(cameraNo) {
        const currentSession = this.session;
        var deviceId;
        var deviceLabel;
        var audioDeviceId;
        let videoData = this.getVideoData();
        window && window.QB && window.QB.webrtc && window.QB.webrtc.getMediaDevices("videoinput").then(function (devices) {
            if (devices.length) {
                store.dispatch({type: UPDATE_CAMERA_DEVICES, result: devices})
                // here is a list of all available cameras
                if (devices.length == 2) {
                    var deviceInfo = devices[cameraNo];
                    deviceId = deviceInfo.deviceId;
                    deviceLabel = deviceInfo.label;
                    audioDeviceId = deviceInfo.audioDeviceId;
                }
                var constraints = {
                    audio: audioDeviceId || undefined,
                    video: {exact: deviceId}
                };
                if (currentSession) {
                    currentSession.switchMediaTracks(constraints, function (error, stream) {
                        stream.getAudioTracks()[0].enabled = videoData.audioMuted ? false : true
                    }.bind(this));
                }
            }
        });
    },

    onSwitchVideoCamera(cameraNo, devices) {
        const currentSession = this.session;
        var deviceId;
        var deviceLabel;
        var audioDeviceId;
        let videoData = this.getVideoData();
        if (devices.length) {
            // here is a list of all available cameras
            for (var i = 0; i < devices.length; i++) {
                if (cameraNo == i) {
                    var deviceInfo = devices[i];
                    deviceId = deviceInfo.deviceId;
                    deviceLabel = deviceInfo.label;
                    audioDeviceId = deviceInfo.audioDeviceId;
                }
            }
        }
        var constraints = {
            audio: audioDeviceId || undefined,
            video: {exact: deviceId}
        };
        if (currentSession) {
            currentSession.switchMediaTracks(constraints, function (error, stream) {
                stream.getAudioTracks()[0].enabled = videoData.audioMuted ? false : true
            }.bind(this));
        }
    },

    muteVideo() {
        if (this.session) {
            this.session.mute("video");
        }
        this.updateVideoData({videoMuted: true});
    },

    unmuteVideo() {
        if (this.session) {
            this.session.unmute("video");
        }
        this.updateVideoData({videoMuted: false});
    },

    addQBListeners() {
        //Listener called when the session is closed. Set the call status to IDLE
        window.QB.webrtc.onSessionCloseListener = function onSessionCloseListener(session) {
            this.session && this.session.detachMediaStream('main_video');
            this.session && this.session.detachMediaStream('localVideo');

            this.setState({callStatus: IDLE});
            if (this.session) {
                this.clearSession();
            }
            this.hangup();
        }.bind(this);

        //Listener called when there is no answer in the given interval
        window.QB.webrtc.onUserNotAnswerListener = function onUserNotAnswerListener(session, userId) {
            chatService.updateVideoCallDurationAndStatus(this.getVideoData().videoMessageId, 0, MISSED);
            this.session.stop({});
            this.clearSession();
        }.bind(this);

        //Listener called when there is a call incoming. Set the call status to RINGING
        window.QB.webrtc.onCallListener = function onCallListener(session, extension) {
            if (this.session && this.session.ID != session.ID) {
                this.session.stop({});
                this.clearSession();
            }
            this.setSession(session);
            this.setState({callStatus: RINGING});
        }.bind(this);

        //Listener called when the call is rejected
        window.QB.webrtc.onRejectCallListener = function onRejectCallListener(session, userId, extension) {
            if (this.session.ID !== session.ID) {
                return;
            }
            this.session.stop({});
            this.clearSession();
        }.bind(this);

        //Listener called when the call is accepted. Set the call status to ONGOING.
        window.QB.webrtc.onAcceptCallListener = function onAcceptCallListener(session, userId, extension) {
            if (this.state.callStatus === ONGOING) {
                if (this.session && this.session.ID != session.ID) {
                    this.session.stop({});
                    this.clearSession();
                    return false;
                }
            } else {
                this.setState({callStatus: ONGOING});
            }
            //this.callAudio.current.pause();
            //this.callStartTime = new Date();
            //this.startDurationTimer();
        }.bind(this);

        //Listener called when there is a remote stream incoming
        window.QB.webrtc.onRemoteStreamListener = function onRemoteStreamListener(session, userId, stream) {
            let rtcSession = this.session;
            var state = rtcSession.connectionStateForUser(userId),
                peerConnList = window.QB.webrtc.PeerConnectionState;

            if (state === peerConnList.DISCONNECTED || state === peerConnList.FAILED || state === peerConnList.CLOSED) {
                return false;
            }

            rtcSession.peerConnections[userId].stream = stream;

            rtcSession.attachMediaStream('main_video', rtcSession.peerConnections[userId].stream);
            this.setState({callStatus: ONGOING});
            this.updateVideoData({modalShow: true})
        }.bind(this);

        //Listener called when the session state is changed
        window.QB.webrtc.onSessionConnectionStateChangedListener = function onSessionConnectionStateChangedListener(session, userId, connectionState) {

            if (connectionState === window.QB.webrtc.SessionConnectionState.CLOSED) {
                let currentSession = this.session;
                if (currentSession) {
                    if (Object.keys(currentSession.peerConnections).length === 1 || userId === currentSession.initiatorID) {
                        //this.onHideModal();
                        //this.ringtoneAudio.current.pause();
                    }
                }
            }

        }.bind(this);
    }
}

/* eslint-enable */

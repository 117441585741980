import {fetchHelper} from '../helpers/request_helper';
import {
    APPLE_LOGIN_URL,
    FACEBOOK_LOGIN_URL,
    GOOGLE_LOGIN_URL,
    LOGIN_URL,
    REFRESH_TOKEN_URL,
    REGISTER_URL,
    LOGOUT_URL,
    CREATE_OR_UPDATE_USER_DEVICE, REGISTER_WITH_CONNECTED_URL
} from '../constants/api_paths';
import {v4} from "uuid";
import {version, internal_version} from "../../package"

export const authService = {
    login,
    loginWithGoogle,
    loginWithFacebook,
    loginWithApple,
    register,
    refreshToken,
    logout,
    autoLogoutServer,
    createDeviceInfo,
    registerWithConnectedProfile
};

const app = "Medrec-M";


/**
 * Perform the login request
 *
 * @param {string} email the user email
 * @param {string} password the user password
 * @returns {object} promise object
 */
export function login(email, password) {
    const body = {
        username: email,
        password,
        app: app,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        device_hash: getDeviceHash()
    };
    return fetchHelper.callPost(LOGIN_URL, body);
}

function getDeviceHash() {
    let hash = localStorage.getItem("mdrcma")
    if (!hash) {
        hash = "WA-" + v4();
        localStorage.setItem("mdrcma", hash);
    }
    return hash;
    //return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

/**
 * Perform the Google login request
 *
 * @param {string} id_token
 * @returns {object} promise object
 */
export function loginWithGoogle(id_token) {
    const body = {
        id_token: id_token,
        app: app,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        device_hash: getDeviceHash()
    };
    return fetchHelper.callPost(GOOGLE_LOGIN_URL, body);
}

/**
 * Perform the Apple login request
 *
 * @param {string} id_token
 * @param {string} client_id
 * @param {object} user - optional user object
 * @returns {object} promise object
 */
export function loginWithApple(id_token, client_id, user) {
    const body = {
        id_token: id_token,
        app: app,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        device_hash: getDeviceHash(),
        apple_client_id:client_id
    };
    if (user) {
        body.user = user;
    }
    return fetchHelper.callPost(APPLE_LOGIN_URL, body);
}

/**
 * Perform the Facebook login request
 *
 * @param {string} accessToken access token from facebook
 * @returns {object} promise object
 */
export function loginWithFacebook(accessToken) {
    const body = {
        id_token: accessToken,
        app: app,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        device_hash: getDeviceHash()
    };
    return fetchHelper.callPost(FACEBOOK_LOGIN_URL, body);
}


/**
 * Perform the login request
 *
 * @param {string} refreshToken the user refresh token
 * @returns {object} promise object
 */
export function refreshToken(refreshToken) {
    const body = {
        refreshToken: refreshToken,
    };
    return fetchHelper.callPost(REFRESH_TOKEN_URL, body);
}

/**
 * Perform the register request
 *
 * @param {object} user the user to create
 * @returns {object} promise object
 */
export function register(user) {
    return fetchHelper.callPost(REGISTER_URL, user);
}

/**
 * Perform the register request when sending with connected profile
 *
 * @param {object} user the user and connected profile to create
 * @returns {object} promise object
 */
export function registerWithConnectedProfile(user) {
    return fetchHelper.callPost(REGISTER_WITH_CONNECTED_URL, user);
}

export function logout() {
    //log out
    fetchHelper.callPost(LOGOUT_URL, {}).catch(reason => {
        console.log(reason)
    });
}


export function autoLogoutServer() {
    //log out
    return fetchHelper.callPost(LOGOUT_URL, {}).catch(reason => {
        console.log(reason)
    });
}

export function createDeviceInfo() {
    let appVersion = `${version} (${internal_version})`;
    let info = {
        userAgent: navigator.userAgent,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        language: navigator.language,
        appVersion: appVersion,
        mainVersion: version,
        build: internal_version
    }


    let deviceInfo = {
        platform: "web",
        app_version: appVersion,
        info: JSON.stringify(info)
    }

    return fetchHelper.callPost(CREATE_OR_UPDATE_USER_DEVICE, deviceInfo);
}
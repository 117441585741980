import React, {Component} from 'react'
import PropTypes from "prop-types";
import CenteredModal from "../shared/CenteredModal";
import {$$} from "../../helpers/localization";
import {appointmentUtils} from "../../utils/appointmentUtils";
import {formatUtils} from "../../utils/formatUtils";
import ClinicianName from "./ClinicianName";

class InfoModal extends Component {

    constructor(props) {
        super(props)
    }

    getOrganizationName(organizationId, nameOnly) {
        let organizationName = "";
        if (organizationId == null) return organizationName;
        let clinician = this.props.clinician
        if (clinician)
            clinician.organizations.forEach((organization) => {
                if (organization.id === organizationId) {
                    organizationName = organization.name;
                }
            })
        if (organizationName !== "") {
            return nameOnly ? organizationName : ", " + organizationName;
        }
        return organizationName;
    }

    getTitle = () => {
        const orgId = this.props.examination.organization_id ? this.props.examination.organization_id : null

        return <div>
            <div>
                {appointmentUtils.appointmentType(this.props.examination)}
            </div>
            <div className="price-hospital-make-appointment">
                <span>{formatUtils.currencyFormat(this.props.examination.price_cents, this.props.examination.currency)},&nbsp;</span>
                <ClinicianName
                    fromCreateAppointment={true}
                    clinician={this.props.clinician}
                    i18n={this.props.i18n}
                />,&nbsp;
                <span>{this.props.examination.encounter_price_type !== "TEXT_ONLY" && $$(this.props.examination.location_type.toLowerCase())}
                    {this.getOrganizationName(orgId, this.props.examination.encounter_price_type === "TEXT_ONLY")}</span>
            </div>
        </div>
    }

    render() {
        return (
            <>
                <CenteredModal show={this.props.showModal}
                               primary={true}
                               title={this.getTitle()}
                               className={''}
                               onHide={this.props.closeModal}>

                    <div className={" white-space-pre-line"}>
                        {this.props.examination.description && <>
                            <h4>{$$("description")}</h4>
                            {this.props.examination.description}

                        </>}
                        <br/>
                        <br/>
                        {this.props.examination.disclaimer && <>
                            <h4>{$$("disclaimer")}</h4>
                            {this.props.examination.disclaimer}
                        </>}
                    </div>
                </CenteredModal>
            </>
        )
    }
}

InfoModal.propTypes = {
    i18n: PropTypes.object,
    examination: PropTypes.object,
    clinician: PropTypes.object,
    showModal: PropTypes.bool,
    closeModal: PropTypes.func,
}

export default InfoModal;

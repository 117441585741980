import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import CenteredModal from '../shared/CenteredModal';
import Select from '../shared/Select'
import {connect} from 'react-redux'
import {
    clearLab,
    createLabResultsDoc,
    fetchLabResults,
    fetchLabResultsDates,
    updateLabResults
} from '../../actions/lab-results_actions';
import {
    createDocument
} from '../../actions/documents_actions';
import LabResultEntry from "./LabResultEntry";
import CustomMultiselect from '../shared/CustomMultiSelect'
import {Modal} from "react-bootstrap"
import {getHealthIssueOptions} from '../../constants/select_options'
import SelectLaboratoryForm from './SelectLaboratoryForm'
import PropTypes from "prop-types";
import {dateTimeUtils} from "../../utils/dateTimeUtils";


export class LabResultsForm extends Component {

    state = {
        requestSent: false,
        showProccessingModal: false,
        laboratory: $$('laboratory_label'),
        beforeDateTime: this.props.filters.before_date_time,
        afterDateTime: this.props.filters.after_date_time,
        isLabSelected: false,
        labEntry: {},
        username: '',
        password: '',
        selectedHealthIssues: [],
        health_issues_ids: [],
        areDatesAvailable: false,
        areLabTestResults: false,
        showLabModal: true,
        showDatesModal: false,
        showTestResults: false,
        showDocInputs: false,
        showDocModal: false,
        isDate: false,
        title: '',
        category: '',
        notes: '',
        labResult: '',
        labResultId: '',
        dates: [],
        date: '',
        formclass: {},
        message: '',
        errors: {}
    }

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this)
        this.labImportModal = this.labImportModal.bind(this)
        this.selectResultsModal = this.selectResultsModal.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps && prevProps.labDates !== this.props.labDates && !this.state.requestSent) {
            this.setState({
                requestSent: true,
                labEntry: {},
                username: '',
                password: '',
                areDatesAvailable: false,
                areLabTestResults: false,
                showLabModal: true,
                showDatesModal: false,
                showTestResults: false,
                formclass: {},
                date: '',
                isDate: false,
                dates: [],
                title: '',
                category: '',
                notes: '',
                errors: {}
            });
        }

        if (this.props.selectedEntry) {
            this.setState({
                health_issues_ids: this.props.selectedEntry && this.props.selectedEntry.health_issues_ids ? this.props.selectedEntry.health_issues_ids : []
            });
        }
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        if (evt.target) {
            fields[evt.target.name] = evt.target.value;
        } else {
            fields[evt.name] = evt.value;
        }
        this.setState(fields);
    }

    onUsernameChange = (evt) => {
        this.setState({username: evt.target.value})
    }
    onPasswordChange = (evt) => {
        this.setState({password: evt.target.value})
    }

    /**
     * Set the state to the latest laboratory selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;

        this.setState(fields, function () {
            this.props.clearLabDates();
        });

        if (value !== "") {
            this.setState({
                isLabSelected: true,
                formclass: '',
                errors: {},
                username: '',
                password: ''
            });
        }
    };

    /**
     * Set the state to the latest date selected option.
     *
     * @param {object} e - The event handler argument
     */
    onSelectChange2 = (e) => {
        if (e.value !== '') {
            this.setState({
                date: e.value,
                isDate: true
            });
        }
    }

    /**
     * Hide the login modal
     */
    onHideImportModal = async () => {
        this.setState({
            isLabSelected: false,
            laboratory: $$('laboratory_label'),
            areDatesAvailable: false,
            formclass: '',
            errors: {},
            username: '',
            password: ''
        });
        await this.props.clearLab();
        this.props.onHide();
    }

    /**
     * Hide the lab results modal
     */
    onHideResultsModal = () => {
        this.props.onHide();
        this.setState({
            showDatesModal: false,
            showLabModal: true,
            laboratory: $$('laboratory_label'),
            areDatesAvailable: false,
            isLabSelected: false,
            areLabTestResults: false,
            date: $$('select_date_label'),
            isDate: false,
            username: '',
            password: '',
            formclass: '',
            errors: {},
        });
        this.props.clearLab();
    }

    /**
     * Hide the save modal
     */
    onHideSaveModal = () => {
        this.setState({
            showTestResults: false,
            laboratory: $$('laboratory_label'),
            areLabTestResults: false,
            areDatesAvailable: false,
            isLabSelected: false,
            date: $$('select_date_label'),
            isDate: false,
            username: '',
            password: '',
            formclass: '',
            errors: {},
            showDocInputs: false,
            showDocModal: false
        });
        this.props.clearLab();
        this.props.onHide();
    }

    /**
     * Hide document modals
     */
    onHideDocumentModal = () => {
        this.setState({
            showDocModal: false,
            showDocInputs: false,
            showTestResults: false,
            laboratory: $$('laboratory_label'),
            areLabTestResults: false,
            areDatesAvailable: false,
            isLabSelected: false,
            isDate: false,
            date: $$('select_date_label'),
            username: '',
            password: '',
            formclass: '',
            errors: {},
            title: '',
            category: '',
            notes: ''
        });
        this.props.clearLab();
        this.props.onHide();
    }

    /**
     * Returns the laboratory name , to be put in the CenteredModal title
     */
    returnLabName = () => {
        let labName;
        // eslint-disable-next-line no-unused-vars
        this.props.labs.map((h, idx) => {
            if (h.lab === this.state.laboratory) {
                labName = h.name;
            }
        });
        return labName;
    }

    /**
     * Sets the dates array
     */
    getDates = () => {
        let datesArray = [];
        let dates = this.props.labDates.dates[0];
        let date = {
            text: $$('select_date_label'),
            value: ''
        }
        datesArray.push(date);
        date = {
            text: '',
            value: ''
        }
        // eslint-disable-next-line no-unused-vars
        dates.map((h, idx) => {
            date.text = h;
            date.value = h;
            datesArray.push(date);
            date = {
                text: '',
                value: ''
            }
        });
        this.setState({dates: datesArray});

    }

    /**
     * Called after we select a laboratory. To fetch the dates results.
     */
    labImportModal = async () => {
        let params;
        let multipleDt = false;
        this.setState({
            selectedHealthIssues: [],
            labResultId: ""
        })
        if (this.validateLabAuth()) {
            if (this.props.labs) {
                // eslint-disable-next-line no-unused-vars
                this.props.labs.map((h, idx) => {
                    if (h.lab === this.state.laboratory) {
                        this.setState({labEntry: h});
                        if (h.multipleDates === true) {
                            multipleDt = true;
                            params = {
                                lab: this.state.laboratory,
                                password: this.state.password,
                                username: this.state.username
                            }

                        } else {
                            this.selectResultsModal()
                        }
                    }
                });
            }
            if (multipleDt) {
                await this.props.fetchLabResultsDates(params);
                if (this.props.selectedUser && (this.props.labDates.request.finished === undefined || this.props.labDates.request.finished)) {
                    if (this.props.labDates.dates && this.props.labDates.dates.length > 0 && this.state.isLabSelected === true) {
                        this.setState({
                            areDatesAvailable: true,
                            showDatesModal: true,
                            showLabModal: false,
                            isLabSelected: false
                        });
                        this.getDates();
                    } else {
                        const errors = {}
                        errors.message = 'login_form_invalid_credentials_message';
                        this.setState({errors});
                        this.setState({
                            isLabSelected: true
                        });
                    }
                } else {
                    const errors = {}
                    errors.message = 'login_form_invalid_credentials_message';
                    this.setState({errors});
                    this.setState({
                        isLabSelected: true
                    });
                }
            }
        }
    }


    /**
     * Called after we select a date. To fetch the laboratory test results.
     */
    selectResultsModal = async () => {
        let params;
        if (this.state.isDate) {

            this.props.onHide();
            this.setState({
                showLabModal: true,
            });
            params = {
                date: this.state.date,
                lab: this.state.laboratory,
                password: this.state.password,
                username: this.state.username
            }
            await this.props.fetchLabResults(params);
            if (this.props.selectedUser && (this.props.labTestResults.request.finished === undefined || this.props.labTestResults.request.finished)) {
                if (this.props.labTestResults.data && (this.props.labTestResults.data.length > 0 || this.props.labTestResults.data.length === undefined)) {
                    this.setState({
                        areLabTestResults: true,
                        showTestResults: true,
                        areDatesAvailable: false,
                        showDatesModal: false,
                    });
                } else {
                    const errors = {};
                    errors.message = 'user_password_update_error_message';
                    this.setState({errors});
                }
            } else {
                const errors = {};
                errors.message = 'user_password_update_error_message';
                this.setState({errors});
            }

        } else {
            params = {
                lab: this.state.laboratory,
                password: this.state.password,
                username: this.state.username
            }
            await this.props.fetchLabResults(params);
            if (this.props.selectedUser && (this.props.labTestResults.request.finished === undefined || this.props.labTestResults.request.finished)) {
                if (this.props.labTestResults.data && (this.props.labTestResults.data.length > 0 || this.props.labTestResults.data.length === undefined) && this.state.isLabSelected === true) {

                    this.setState({
                        areLabTestResults: true,
                        showTestResults: true,
                        areDatesAvailable: false,
                        showDatesModal: false,
                    });
                } else {
                    const errors = {};
                    errors.message = 'login_form_invalid_credentials_message';
                    this.setState({errors});
                    this.setState({
                        isLabSelected: true
                    });
                }
            } else {
                const errors = {}
                errors.message = 'login_form_invalid_credentials_message';
                this.setState({errors});
                this.setState({
                    isLabSelected: true
                });
            }
        }
    }

    /**
     * Called after we select a laboratory test result
     */
    onSaveModal = async () => {
        let labResult = {
            ...this.props.labTestResults.data,
            health_issues_ids: this.state.health_issues_ids,
        };
        let params = {
            laboratory: this.state.laboratory,
            userId: this.props.selectedUser.id
        }


        let labResultId =  await this.props.updateLabResults(labResult, params, this.props.selectedUser.id)

        this.props.onHide();
        this.setState({
            areLabTestResults: false,
            isLabSelected: false,
            areDatesAvailable: false,
            showDocModal: true,
            showTestResults: false,
            labResultId: labResultId,
            labResult: ''
        });

    }

    showDocumentCreation = () => {
        if (!this.state.showDocInputs) {
            this.setState({
                showDocInputs: true
            });
        }
    }

    createDocumentForLabResult = async () => {
        let notes = ""

        for(let i in this.props.labTestResults.data.tests){
            for(let j in this.props.labTestResults.data.tests[i].groups){
                notes += this.props.labTestResults.data.tests[i].groups[j].label + ":"
                for(let k in this.props.labTestResults.data.tests[i].groups[j].tests){
                    notes += this.props.labTestResults.data.tests[i].groups[j].tests[k].label
                    if(k < this.props.labTestResults.data.tests[i].groups[j].tests.length -1){
                        notes += ","
                    }
                }
                notes  += " "
            }
        }

        await this.props.clearLab();
        let entry;
        if (this.validateDocument()) {
            entry = {
                category: this.state.category,
                date: this.state.date,
                lab: this.state.laboratory,
                laboratoryResultId: this.state.labResultId,
                notes: notes,
                password: this.state.password,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                title: this.state.labEntry.name,
                username: this.state.username
            }

            this.setState({
                showDocModal: false,
                showProccessingModal: true
            });
            await this.props.createLabResultsDoc(entry, this.props.selectedUser.id, this.state.health_issues_ids)
            this.setState({
                showDocInputs: false,
                showProccessingModal: false,
                title: '',
                category: '',
                notes: '',
                username: '',
                password: '',
                formclass: '',
                health_issues_ids: [],
                selectedHealthIssues:[],
                errors: {},
                laboratory: $$('laboratory_label'),
                date: $$('select_date_label'),
                isDate: false
            });
            this.props.fetchSelectedUserDocuments(this.props.selectedUser.id, null, true)
        }
    }


    /**
     * Validates the form
     */
    validateLabAuth = () => {
        const errors = {};
        this.setState({errors: ''});
        if (!this.state.username) {
            errors.username = 'username_required_label';
        } else if (!this.state.password) {
            errors.password = 'password_required_message';
        }
        this.setState({errors});
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }
        return Object.keys(errors).length === 0;
    }

    validateDocument = () => {
        const errors = {};
        this.setState({errors});
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }
        return Object.keys(errors).length === 0;
    }

    /**
     * Form submit handler, validate password , username and set error in state if any.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();

    }

    onSelect = (selectedItem) => {
        let selectedIssuesArray = this.state.selectedHealthIssues;
        let selectedIssuesArrayIds = this.state.health_issues_ids;
        selectedIssuesArray.push(selectedItem.value);
        selectedIssuesArrayIds.push(selectedItem.value.id);
        this.setState({
            selectedHealthIssues: selectedIssuesArray,
            health_issues_ids: selectedIssuesArrayIds
        })
    }

    onRemove = (removedItem) => {
        let selectedIssuesArray = this.state.selectedHealthIssues;
        let selectedIssuesArrayIds = this.state.health_issues_ids;
        selectedIssuesArray.pop(removedItem);
        selectedIssuesArrayIds.pop(removedItem.id);
        this.setState({
            selectedHealthIssues: selectedIssuesArray,
            health_issues_ids: selectedIssuesArrayIds
        })
    }


    render() {
        return (
            <div>
                <CenteredModal title={this.state.isLabSelected ? this.returnLabName() : $$('lab_result')}
                               show={this.props.show && this.state.showLabModal}
                               onHide={this.onHideImportModal}
                               onConfirm={this.labImportModal}
                               confirmBtnLabel={$$('continue')}>
                    <SelectLaboratoryForm onSubmit={this.onSubmit}
                                          formclass={this.state.formclass}
                                          isLabSelected={this.state.isLabSelected}
                                          laboratoryOptions={this.props.laboratoryOptions}
                                          laboratory={this.state.laboratory}
                                          onSelectChange={this.onSelectChange}
                                          errors={this.state.errors}
                                          username={this.state.username}
                                          onUsernameChange={this.onUsernameChange}
                                          password={this.state.password}
                                          onPasswordChange={this.onPasswordChange}
                    />
                </CenteredModal>
                <CenteredModal title={$$('lab_result')}
                               show={this.state.showDatesModal}
                               onHide={this.onHideResultsModal}
                               onConfirm={this.selectResultsModal}
                               confirmBtnLabel={$$('continue')}
                >
                    {this.state.areDatesAvailable &&
                        <Select name="date"
                                options={this.state.dates}
                                value={this.state.date}
                                placeHolder={$$('select_date_label')}
                                onChange={this.onSelectChange2}

                        />}
                </CenteredModal>
                <CenteredModal title={$$('lab_result')}
                               show={this.state.showTestResults}
                               onHide={this.onHideSaveModal}
                               onConfirm={this.onSaveModal}
                               confirmBtnLabel={$$('save_btn_label')}
                >
                    {this.state.areLabTestResults &&
                        <div className="form-group row">
                            <div className="card-header">
                                {dateTimeUtils.getFormattedDateWithDay(this.props.labTestResults.data.date)}
                            </div>
                            <div style={{
                                'position': 'relative',
                                'width': '100%',
                                'marginBottom': '10px',
                                'marginTop': '10px'
                            }}>
                                <CustomMultiselect
                                    options={getHealthIssueOptions(this.props.healthIssues.entries)}
                                    selectedValues={this.state.selectedHealthIssues}
                                    onSelect={this.onSelect}
                                    onRemove={this.onRemove}
                                    displayValue="text"
                                    placeholder={$$('select_health_issue_label')}
                                    closeIcon='cancel'
                                    avoidHighlightFirstOption={true}
                                    formClass={this.state.formclass}/>
                            </div>
                            <div className="card-body" style={{"padding": "0"}}>
                                <LabResultEntry
                                    labs={this.props.labs}
                                    laboratory={this.state.laboratory}
                                    entry={{
                                        "result": JSON.stringify(this.props.labTestResults.data),
                                        "laboratory": this.state.laboratory
                                    }}
                                    filters={this.props.filters}
                                    healthIssues={this.props.healthIssues}
                                    noControls = {true}
                                />
                            </div>
                        </div>}
                </CenteredModal>
                <CenteredModal title={$$('create_document')}
                               show={this.state.showDocModal}
                               onHide={this.onHideDocumentModal}
                               onConfirm={this.createDocumentForLabResult}
                               confirmBtnLabel={$$('create_document')}
                               cancelBtnLabel={$$('skip_label')}
                >
                    <div>{$$('create_lab_document')}</div>
                </CenteredModal>
                <Modal show={this.state.showProccessingModal}>
                    <Modal.Body style={{"textAlign": "center"}}>
                        <div style={{
                            "paddingTop": "30px",
                            "paddingBottom": "30px",
                            "paddingRight": "0px",
                            "paddingLeft": "0px"
                        }}>
                            {$$('processing_label') + "..."}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

LabResultsForm.propTypes = {
    changeLabResultsFilters: PropTypes.func,
    clearLab: PropTypes.func,
    clearLabDates: PropTypes.func,
    createLabResultsDoc: PropTypes.func,
    fetchLabResults: PropTypes.func,
    fetchLabResultsDates: PropTypes.func,
    selectedEntry: PropTypes.any,
    fetchSelectedUserDocuments: PropTypes.func,
    filters: PropTypes.object,
    getHealthIssueList: PropTypes.func,
    healthIssues: PropTypes.object,
    i18n: PropTypes.object,
    labDates: PropTypes.object,
    labResults: PropTypes.object,
    labTestResults: PropTypes.object,
    laboratoryOptions: PropTypes.array,
    labs: PropTypes.array,
    onHide: PropTypes.func,
    selectedUser: PropTypes.object,
    show: PropTypes.bool,
    updateLabResults: PropTypes.func
}

function mapStateToProps(state) {
    return {
        labDates: state.labDates,
        labTestResults: state.labTestResults,
        labResults: state.labResults.selectedUser
    }
}

const mapDispatchToProps = {
    fetchLabResultsDates,
    fetchLabResults,
    clearLab,
    createDocument,
    updateLabResults,
    createLabResultsDoc
}

export default connect(mapStateToProps, mapDispatchToProps)(LabResultsForm)
import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import Select from '../shared/Select'
import PropTypes from "prop-types";

export class SelectLaboratoryForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <form onSubmit={this.props.onSubmit} className={this.props.formclass}>
                    {!this.props.isLabSelected && <div>
                        <Select name="laboratory"
                                options={this.props.laboratoryOptions}
                                value={this.props.laboratory}
                                placeHolder={$$('select_laboratory_label')}
                                onChange={this.props.onSelectChange}
                        />
                        {this.props.errors.message && <div className="invalid-feedback">
                            {$$('user_password_update_error_message')}
                        </div>}
                    </div>}
                    {this.props.isLabSelected &&
                    <div className="form-group">
                        <div>{$$('credentials_message')}</div>
                        <input type="text"
                               placeholder={this.props.laboratory !== '' ? $$(this.props.laboratory.toLowerCase()) : $$("user_id")}
                               className="form-control" style={{'marginTop': '10px'}}
                               value={this.props.username} name="username" onChange={this.props.onUsernameChange}
                               required/><br/>
                        {this.props.errors.username && <div
                            className={this.props.errors.username ? "custom-invalid-feedback" : "invalid-feedback"}>
                            {$$('username_required_label')}
                        </div>
                        }
                        <input type="password" placeholder={$$("password_label")} className="form-control"
                               style={{'marginTop': '10px'}} value={this.props.password} name="password"
                               onChange={this.props.onPasswordChange} required/>
                        {this.props.errors.username && <div
                            className={this.props.errors.password ? "custom-invalid-feedback" : "invalid-feedback"}>
                            {$$('password_required_message')}
                        </div>}
                        <div style={{
                            'fontSize': '20px',
                            'color': 'red',
                            'marginTop': '20px'
                        }}>{this.props.errors.message ? $$('login_form_invalid_credentials_message') : ''}</div>
                    </div>}
                </form>
            </div>
        )
    }
}

SelectLaboratoryForm.propTypes = {
    errors: PropTypes.object,
    formclass: PropTypes.any,
    isLabSelected: PropTypes.bool,
    laboratory: PropTypes.string,
    laboratoryOptions: PropTypes.array,
    onPasswordChange: PropTypes.func,
    onSelectChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onUsernameChange: PropTypes.func,
    password: PropTypes.string,
    username: PropTypes.string
}

export default SelectLaboratoryForm;
import React from 'react';
import FormCheck from "../../input/FormCheck";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import AnswerType from "../../../models/answer-type/AnswerType";
import BaseAnswer from "../BaseAnswer";
import {$$} from "../../../../helpers/localization";

class ChooserAnswer extends BaseAnswer {
    render() {
        const answer = this.props.answer;
        const list = answer.range.list;
        return (
            <span>
                <div className={`custom-control custom-${answer.selectionType}`}>
                    <FormCheck ref={this.inputRef} answer={answer} handleChange={this.onInputChange}/>
                    <label className={'mr-2 cursor-pointer custom-control-label'} onClick={() => this.inputRef.current.click()}>
                        {`${answer.label[this.language]} ${this.getSuffix(answer)}`}
                    </label>
                </div>
                <Modal
                    show={this.state.open}
                    centered={true}
                    onHide={() => this.onCancelButtonPressed()}
                    dialogClassName="modal-20w picker-modal">
                    <Modal.Header>{answer.label[this.language]}</Modal.Header>
                    <Modal.Body>
                        <div className={'d-flex flex-column pb-2'}>
                            {list?.map(element => {
                                return <button key={element.value}
                                               className={'btn btn-secondary border-radius-0'}
                                               onClick={() => this.onClick(element)}>
                                    {element[this.language]}
                                </button>
                            })}
                        </div>
                        <Button onClick={() => this.onCancelButtonPressed()}>{$$("cancel_btn")}</Button>
                    </Modal.Body>
                </Modal>
            </span>
        );
    }

    onClick = (element) => {
        this.props.answer.result = element[this.language];
        this.props.answer.tag = element.value;
        this.selectAnswer();
        this.setState({open: false})
    }
}

class ChooserAnswerFactory {
    static get type() {
        return AnswerType.CHOOSER;
    }

    static create(answer, updateAnswer, language) {
        return <ChooserAnswer answer={answer} updateAnswer={updateAnswer} language={language}/>
    }
}

export default ChooserAnswerFactory

import React, {createRef} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

class SearchForSymptoms extends React.Component {
    inputRef = createRef();
    state = {open: false, data: null, searchValue: "", filtered: []};
    handleSearchChange = event => {
        this.setState({data: null, searchValue: event.target.value}, function () {
        });
        let treeItems = [];

        this.props.tree.filter(symptom => symptom.nomenclature.indexOf('.') === -1).map((symptom) => {

            let children = this.props.tree.filter(s => s.nomenclature !== symptom.nomenclature &&
                s.nomenclature.split('.')[0] === symptom.nomenclature.split('.')[0] &&
                s.nomenclature.split('.').length === 2);

            children.map((ch) => {
                this.props.tree.filter(s =>
                    s.nomenclature !== ch.nomenclature &&
                    s.nomenclature.split('.')[0] === ch.nomenclature.split('.')[0] &&
                    s.nomenclature.split('.')[1] === ch.nomenclature.split('.')[1] &&
                    s.nomenclature.split('.').length === 3).map((s) => {
                    treeItems.push(s);
                });

            })
        });
        this.setState({open: !this.state.open});

        let filtered = treeItems.filter(u => {
            if (event.target.value === "") {
                return false;
            }
            let startsWith = false;
            let words = u.description.toLowerCase().split(' ');
            let searchWords = event.target.value.toLowerCase().split(' ');

            for (let i = 0; i < words.length; i++) {
                if (u.description.toLowerCase().indexOf(event.target.value.toLowerCase()) === 0 ||
                    u.nomenclature.toLowerCase().indexOf(event.target.value.toLowerCase()) === 0 ||
                    words[i].indexOf(event.target.value.toLowerCase()) === 0
                    || searchWords.every(val => words.includes(val))) {
                    startsWith = true
                    break;
                }
            }

            return startsWith;
        })

        this.setState({filtered: filtered})

        if (event.target.value === "") {
            this.setState({filter: []})
        }

    };

    clear = () => {
        this.setState({searchValue: ""}, function () {
            this.props.filterUsers(this.state.searchValue);
        });
        this.setState({open: !this.state.open});
    };

    getWords = (sentence) => {
        let words = sentence.split(' ');
        return words.map((word, index) => {
            let isUnderlined = word.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) === 0 || this.state.searchValue.toLowerCase().indexOf(word.toLowerCase()) !== -1
            return <span key={index}><span
                className={isUnderlined ? "highlight-search" : null}>{word.trim()}</span>&nbsp;</span>
        })
    }

    getResults = (filtered) => {
        filtered.length >= 0
        let results = this.state.filtered.map((symptom, index) => {
            return <div key={index} onClick={() => {
                this.resetResults()
                this.props.onClick(symptom)
            }
            } className="search-text">
                {this.getWords(symptom.description)}
            </div>
        })
        return <div className="high-shadow-container-search-results">{results}</div>

    }
    resetResults = () => {
        this.setState({filtered: []})
    }

    render() {
        // eslint-disable-next-line no-unused-vars
        const {open, data, searchValue} = this.state;

        return (
            <div className="">
                <div
                    id="kt_quick_search_default1"
                    className={clsx("col-12 kt-quick-search1 zero-padding", {
                        "kt-quick-search--has-result1": data && data.length
                    })}
                >
                    <div className="kt-quick-search__form1">
                        <div className="input-group symptom-search-input-group">
                            <input
                                type="text"
                                ref={this.inputRef}
                                placeholder={this.props.placeholder}
                                value={searchValue}
                                onChange={this.handleSearchChange}
                                className="form-control kt-quick-search__input1"
                            />
                            <div className="input-group-append search-icon-append">
                                <span className="input-group-text search-icon-append auto-height">
                                    <i className="flaticon2-search-1 wht"/>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.filtered.length >= 0 ? this.getResults(this.state.filtered) : ""}
            </div>
        );
    }
}

SearchForSymptoms.propTypes = {
    description: PropTypes.string,
    placeholder: PropTypes.string,
    i18n: PropTypes.object,
    index: PropTypes.number,
    onChange: PropTypes.func,
    filterUsers: PropTypes.func,
    onClick: PropTypes.func,
    resetSelection: PropTypes.bool,
    selected: PropTypes.bool,
    shouldResetSelection: PropTypes.bool,
    strength: PropTypes.number,
    tree: PropTypes.any,
    type: PropTypes.string,
};

export default SearchForSymptoms;

import React, {useCallback, useEffect, useRef, useState} from 'react'
import {$$} from '../../helpers/localization'
import {Button, Modal} from "react-bootstrap";
import moment from "moment";
import useVideoContext from "../video/hooks/useVideoContext/useVideoContext";
import {useSelector} from "react-redux";
import {appointmentsService} from "../../service/appointments_service";
import useRoomState from "../video/hooks/useRoomState/useRoomState";

const MINUTES_TO_END = 5;

export default function EndOfAppointmentWarning() {
    const {room, videoMessage} = useVideoContext();
    const roomState = useRoomState();
    const [showModal, setShowModal] = useState(false);
    const appointments = useSelector((state) => state.userAppointments.entries);
    const timeoutRef = useRef();

    const clearTimeoutFunction = useCallback(() => {
        {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
            }
        }
    }, []);

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeoutFunction();
            }
        }
    }, [])

    useEffect(() => {
        if (roomState !== 'connected' || roomState !== 'reconnecting' || roomState !== 'reconnected') {
            clearTimeoutFunction();
        }
    }, [roomState])

    useEffect(() => {
        async function initialize() {
            if (!showModal && room && (roomState === 'connected' || roomState === 'reconnecting' || roomState === 'reconnected') && videoMessage) {
                if (!room.appointment) {
                    let appointment = appointments.find((a) => a.id === videoMessage.appointment_id);
                    if (!appointment) {
                        try {
                            appointment = await appointmentsService.fetchAppointment(videoMessage.appointment_id);
                        } catch (e) {
                            clearTimeoutFunction();
                            return;
                        }

                        if (!appointment) {
                            clearTimeoutFunction();
                            return;
                        }
                    }

                    if (room.appointment !== appointment) {
                        room.appointment = appointment;
                    }
                }

                let price = room.appointment.appointment_price;

                const getTimeToEnd = () => {
                    let shouldEndAt = moment(videoMessage.date_time + (price.duration_mins - MINUTES_TO_END) * 60 * 1000);
                    let now = moment();
                    let delta = shouldEndAt.diff(now);
                    let inMins = Math.round(delta / (60 * 1000));
                    return inMins;
                }

                let timeout = getTimeToEnd();

                clearTimeoutFunction();
                if (timeout > 0) {
                    let appId = videoMessage.appointment_id;
                    let msgId = videoMessage.id;
                    timeoutRef.current = setTimeout(() => {
                        if (room && (roomState === 'connected' || roomState === 'reconnecting' || roomState === 'reconnected')
                            && videoMessage && videoMessage.id === msgId && videoMessage.appointment_id === appId) {
                            setShowModal(true);
                        }
                        clearTimeoutFunction();
                    }, timeout * 1000 * 60);
                }
            }

            if (!room) {
                clearTimeoutFunction();
            }
        }
        initialize();
    }, [appointments, room, roomState, videoMessage, clearTimeoutFunction])

    return (
        <Modal scrollable={true} show={showModal} backdrop={"static"} backdropClassName={"confirm-over-other-modal"}
               style={{zIndex: 10000}}>
            <Modal.Header>
                <Modal.Title className="call-modal-title">{$$('warning')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{$$("call_ending_soon_body")}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={() => {
                        setShowModal(false);
                    }}>
                    <div className="flex-center">
                        {$$("close_btn_label")}
                    </div>
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
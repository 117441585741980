import React, {useEffect, useState} from 'react'
import {
    addDays,
    addWeeks,
    format,
    isBefore,
    isSameDay,
    setDay,
    subWeeks
} from 'date-fns'
import {
    bg,
    enGB
} from 'date-fns/locale/index'
import _ from 'underscore'

/* eslint-disable react/prop-types */
export default React.memo(function ReactHorizontalDatePicker({
                                                                 enableDays,
                                                                 enableScroll,
                                                                 selectedDay,
                                                                 i18n,
                                                                 chosenDate,
                                                                 previousDays,
                                                                 triggeredFromBtn,
                                                                 pickedCentralDate,
                                                                 weekChanged
                                                             }) {
    /* eslint-enable react/prop-types */
    let centralDate = pickedCentralDate ? pickedCentralDate : new Date();
    let [selectedDate, setSelectedDate] = useState(centralDate);
    let [currentWeek, setCurrentWeek] = useState(setDay(centralDate, _.contains(['en'], i18n) ? 0 : 1));
    let [currentDate] = useState(centralDate);
    let [week, setWeek] = useState(setDay(centralDate, _.contains(['en'], i18n) ? 0 : 1));
    const [headingDate, setHeadingDate] = useState(centralDate);
    enableScroll = enableScroll || false;
    enableDays = enableScroll === true ? enableDays || 90 : 7;

    useEffect(() => {
        let firstDayCurrentWeek = setDay(centralDate, _.contains(['en'], i18n) ? 0 : 1);
        let firstDayChosenWeek = setDay(week, _.contains(['en'], i18n) ? 0 : 1);

        if (!isSameDay(currentWeek, firstDayCurrentWeek)) {
            setCurrentWeek(firstDayCurrentWeek);
        }
        if (!isSameDay(week, firstDayChosenWeek)) {
            setWeek(firstDayChosenWeek);
        }
    }, [i18n]);


    useEffect(() => {
        if (!isSameDay(selectedDate, chosenDate) || triggeredFromBtn && !isSameDay(headingDate, centralDate)) {
            onDateClick(new Date(chosenDate), true);
        }
    }, [chosenDate]);


    const applyStyles = day => {
        const classes = [];
        if (isSameDay(day, selectedDate)) {
            classes.push(' date-day-item-selected');
        }

        if (isBefore(day, currentDate) && !previousDays) {
            classes.push(' date-day-item-disabled');
        }
        return classes.join(' ');
    };

    let _verticalList = () => {
        const _dayFormat = 'E';
        const _dateFormat = 'dd';
        const _verticalListItems = [];
        const _startDay = currentWeek;

        const backwardsOffset = 0
        let j = 0;

        for (let i = 0; i < enableDays + backwardsOffset; i++) {

            let _day = format(addDays(_startDay, i - backwardsOffset), _dayFormat, {locale: _.contains(['en', 'sq'], i18n) ? enGB : bg});
            let _date = format(addDays(_startDay, i - backwardsOffset), _dateFormat);

            if (j < backwardsOffset) {
                let offset = 0 - backwardsOffset + j
                _day = format(addDays(_startDay, offset), _dayFormat, {locale: _.contains(['en', 'sq'], i18n) ? enGB : bg});
                _date = format(addDays(_startDay, offset), _dateFormat);
                j = j + 1;
            }

            _verticalListItems.push(
                <div key={i} id={format(addDays(_startDay, i - backwardsOffset), 'MMMM dd')} className='wrapper'
                     style={{'marginRight': '0.3125rem', 'marginLeft': i === 0 ? '-0.3125rem' : '0rem'}}>
                    <div
                        className={`datepicker-date-day-item wrapper ${applyStyles(
                            addDays(_startDay, i - backwardsOffset)
                        )}`}
                        onClick={() => onDateClick(addDays(_startDay, i - backwardsOffset))}
                    >
                        <div className='datepicker-date-label'>{_date}</div>
                        <div className='datepicker-day-label '>{_day.toUpperCase()}</div>

                    </div>
                </div>
            );
        }

        return (
            <div
                id='container'
                className={
                    enableScroll === true
                        ? ' datepicker-datelist-scrollable datepicker-v-two'
                        : ' datepicker-dateList'
                }
            >
                {_verticalListItems}
            </div>
        );
    };

    const onDateClick = (day, shouldChangeWeek) => {
        setSelectedDate(day);
        selectedDay(day);

        if (shouldChangeWeek) {
            setWeek(setDay(day, _.contains(['en'], i18n) ? 0 : 1));
            setCurrentWeek(setDay(day, _.contains(['en'], i18n) ? 0 : 1));
            weekChanged(day);
            setHeadingDate(day);
        }

    };

    const nextScroll = () => {
        let followingWeek = addWeeks(week, 1);
        setWeek(setDay(followingWeek, _.contains(['en'], i18n) ? 0 : 1));
        setCurrentWeek(setDay(new Date(followingWeek), _.contains(['en'], i18n) ? 0 : 1));
        weekChanged(followingWeek);
        setHeadingDate(followingWeek);
    };

    const prevScroll = () => {
        let previousWeek = subWeeks(week, 1);
        setWeek(setDay(previousWeek, _.contains(['en'], i18n) ? 0 : 1));
        setCurrentWeek(setDay(new Date(previousWeek), _.contains(['en'], i18n) ? 0 : 1));
        weekChanged(previousWeek);
        setHeadingDate(previousWeek);
    };


    return (
        <div className='datepicker-strip'>
            <div className='datepicker'>
                <div className='wrapper'>
                    <div id='prev' className='button-previous btn-prev'>
                        {' '}
                        <button className='datepicker-button-previous' onClick={prevScroll}>
                            <i className='kt-menu__link-icon fas fa-angle-right'/>
                        </button>
                    </div>
                </div>
                {_verticalList()}
                <div className='wrapper'>
                    <div/>
                    <div id='next' className='button-previous btn-nxt'>
                        {' '}
                        <button className='datepicker-button-next' onClick={nextScroll}>
                            <i className='kt-menu__link-icon fas fa-angle-right'/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});
import React, {Component} from 'react';
import './StepProgressBar.css'
import classNames from "classnames";

class StepProgressBar extends Component {
    steps;
    constructor(props) {
        super(props);
        this.steps = Array.from({length: this.props.totalSteps}, (v, i) => i);
    }

    render() {
        return (
            <div className="w-75 d-flex flex-row step-progress-bar">
                { this.steps.map((step, index) => {
                    const tailClass = this.getTailClassName(index);
                    const headClass = this.getHeadClassName(index);
                    const stepClass = this.getStepClassName(index);

                    return (
                        <div key={step} className={stepClass}>
                            <span className={tailClass}/><span className={headClass}/>
                        </div>
                    )
                })}
            </div>
        );
    }

    getTailClassName = (index) => {
        const completedClass = this.getCompletedClassName(index);
        return classNames({
            tail: index > 0,
            [completedClass]: true
        });
    };

    getHeadClassName = (index) => {
        const completedClass = this.getCompletedClassName(index);
        return classNames({
            head: true,
            [completedClass]: true
        });
    };

    getStepClassName = (index) => {
        return classNames({
            'd-flex': true,
            'align-items-center': true,
            'w-100': index > 0
        })
    };

    getCompletedClassName = (index) => {
        return index <= this.props.currentStep ? 'step-complete': 'step-incomplete';
    }
}

export default StepProgressBar;

import React, {Component} from 'react'
import Select from '../shared/Select'
import {
    DOCUMENTS_FILTER,
    getResolvedOptions
} from '../../constants/select_options'
import {$$} from '../../helpers/localization'
import Search from '../shared/Search';
import PropTypes from "prop-types";


export class DocumentsFilters extends Component {

    state = {
        type: this.props.filters.type,
        category: this.props.filters.category,
        beforeDateTime: this.props.filters.before_date_time,
        afterDateTime: this.props.filters.after_date_time
    }

    constructor(props) {
        super(props);
    }

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields, function () {
            this.props.changeDocumentsFilters(this.state);
        });
    };

    render() {
        return (
            <div className="form-inline" style={{'backgroundColor': 'white', 'paddingTop': '10px'}}>
                {!this.props.updateModal &&
                <div style={{'display': 'flex', 'flexWrap': 'wrap', 'width': '100%'}}>
                    <h1 style={{'marginRight': 'auto'}}>
                        {$$('documents')}
                    </h1>
                    <div className="" style={{'display': 'flex', 'flexWrap': 'wrap'}}>
                        <div className="" style={{'display': 'flex', 'marginRight': '2rem'}}>
                            <Select
                                name="issues-type"
                                customClass="document-filters"
                                options={this.props.documentsOptions}
                                value={this.props.pickedOption}
                                onChange={this.props.onSelectChange}
                                placeHolder={$$('all_issues')}/>
                        </div>
                        <div className="" style={{'display': 'flex', 'marginRight': '2rem', 'marginBottom': '1.5rem'}}>
                            <Select
                                name="type"
                                customClass="document-filters"
                                options={getResolvedOptions(DOCUMENTS_FILTER.TYPE)}
                                value={this.state.type}
                                onChange={this.onSelectChange}
                                placeHolder={$$('all_document_types_label')}/>
                        </div>
                        <div className='search-box2' style={{'marginRight': '2rem'}}>
                            <Search
                                filterUsers={this.props.onSearchChange}
                                placeholder={$$('search_placeholder_label')}/>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}

DocumentsFilters.propTypes = {
    categoryOptions: PropTypes.array,
    changeDocumentsFilters: PropTypes.func,
    documentsOptions: PropTypes.array,
    filters: PropTypes.object,
    healthIssues: PropTypes.object,
    i18n: PropTypes.object,
    onSearchChange: PropTypes.func,
    onSelectChange:PropTypes.func,
    pickedOption: PropTypes.any,
    updateModal: PropTypes.bool
}

export default DocumentsFilters

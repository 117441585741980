import React, {Component} from 'react'
import {$$} from '../../../helpers/localization'
import {CONVERTER} from '../../../utils/converter'
import PropTypes from "prop-types";

export default class CholesterolForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.total_cholesterol ? $$('total_cholesterol_label') : null}<br/>
                <div className="form-group">
                    <div className="input-group mb-3">
                        <input type="number" step="0.01"
                               className={this.props.errors.total_cholesterol ? "custom-error form-control" : "form-control"}
                               value={this.props.total_cholesterol}
                               placeholder={$$('total_cholesterol_label')} name="total_cholesterol"
                               onChange={this.props.onInputChange}/>
                        <div className="input-group-append">
                            <span className="input-group-text" style={{"height": "auto"}}
                                  id="basic-addon2">{CONVERTER.getFormattedCholesterolUnit()}</span>
                        </div>
                    </div>
                    <div
                        className={this.props.errors.total_cholesterol ? "custom-invalid-feedback" : "invalid-feedback"}>
                        {this.props.errors.total_cholesterol ? $$(this.props.errors.total_cholesterol) : null}
                    </div>
                </div>
                {this.props.ldl ? $$('ldl_label') : null}<br/>
                <div className="form-group">
                    <input type="number" step="0.01"
                           className={this.props.errors.ldl ? "custom-error form-control" : "form-control"}
                           value={this.props.ldl} placeholder={$$('ldl_label')} name="ldl"
                           onChange={this.props.onInputChange}/>
                    <div
                        className={this.props.errors.ldl ? "custom-invalid-feedback" : "invalid-feedback"}>
                        {this.props.errors.ldl ? $$(this.props.errors.ldl) : null}
                    </div>
                </div>
                {this.props.hdl ? $$('hdl_label') : null}<br/>
                <div className="form-group">
                    <input type="number" step="0.01"
                           className={this.props.errors.hdl ? "custom-error form-control" : "form-control"}
                           value={this.props.hdl} placeholder={$$('hdl_label')} name="hdl"
                           onChange={this.props.onInputChange}/>
                    <div
                        className={this.props.errors.hdl ? "custom-invalid-feedback" : "invalid-feedback"}>
                        {this.props.errors.hdl ? $$(this.props.errors.hdl) : null}
                    </div>
                </div>
                {this.props.triglycerides ? $$('triglycerides_label') : null}<br/>
                <div className="form-group">
                    <input type="number" step="0.01"
                           className={this.props.errors.triglycerides ? "custom-error form-control" : "form-control"}
                           value={this.props.triglycerides} placeholder={$$('triglycerides_label')}
                           name="triglycerides" onChange={this.props.onInputChange}/>
                    <div
                        className={this.props.errors.triglycerides ? "custom-invalid-feedback" : "invalid-feedback"}>
                        {this.props.errors.triglycerides ? $$(this.props.errors.triglycerides) : null}
                    </div>
                    <div
                        className={this.props.errors.cholesterol ? "custom-invalid-feedback" : "invalid-feedback"}>
                        <br/>
                        {this.props.errors.cholesterol ? $$(this.props.errors.cholesterol) : $$('cholesterol_required_message')}
                    </div>
                </div>
            </div>
        )
    }
}

CholesterolForm.propTypes = {
    clearSelectedEntry: PropTypes.func,
    createEntry: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    formDisabled: PropTypes.object,
    getHealthIssueList: PropTypes.func,
    healthIssues: PropTypes.object,
    logbookEntries: PropTypes.array,
    medicalProfile: PropTypes.object,
    onHide: PropTypes.func,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    show: PropTypes.bool,
    type: PropTypes.string,
    errors: PropTypes.any,
    triglycerides: PropTypes.any,
    hdl: PropTypes.any,
    total_cholesterol: PropTypes.any,
    ldl: PropTypes.any,
    onInputChange: PropTypes.func,
    updateEntry: PropTypes.func,
    updateUserMedicalProfile: PropTypes.func
}
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as builder from "../../ducks/builder";
import { $$ } from '../../../helpers/localization';
import PropTypes from "prop-types";


class Brand extends React.Component {
  render() {
    return (
      <div
        className={`kt-aside__brand kt-grid__item ${this.props.brandClasses}`}
        id="kt_aside_brand"
      >
        <div className="kt-aside__brand-logo">
          <Link to="">
          <div className="main-logo-label" >{$$(window.aptechko ? "main_page_label" : "title")}</div>
            <div className="sub-logo-label">{$$(window.aptechko ? "title" : "app")}</div>
          </Link>
        </div>
      </div>
    );
  }
}

Brand.propTypes = {
  brandClasses:  PropTypes.any,
  i18n: PropTypes.object
}

const mapStateToProps = store => {
  return {
    brandClasses: builder.selectors.getClasses(store, {
      path: "brand",
      toString: true
    }),
    headerLogo: builder.selectors.getLogo(store),
    headerStickyLogo: builder.selectors.getStickyLogo(store),
    i18n: store.language
  };
};

export default connect(mapStateToProps)(Brand);

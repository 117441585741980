import * as React from "react";
import Modal from "react-bootstrap/Modal";
import ReactMarkdown from "react-markdown";

export default class ClinicInfoModal extends React.Component {
    render() {
        return <Modal show={this.props.show} onHide={this.props.onHide}>
            <Modal.Body>
                <ReactMarkdown>
                    {this.props.hospital.details}
                </ReactMarkdown>
            </Modal.Body>
        </Modal>
    }
}
import React, {Component} from 'react'
import {VideoManager} from "./video_manager";
import classnames from 'classnames'
import {faCog} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";


export class CameraDevices extends Component {

    state = {
        deviceId: this.props.deviceId && this.props.deviceId.exact
    }

    selectCamera = (cameraNo, e) => {
        VideoManager.onSwitchVideoCamera(cameraNo, this.props.cameraDevices);
        this.setState({deviceId: this.props.cameraDevices[cameraNo].deviceId})
        e.preventDefault();
        e.stopPropagation();
    }


    render() {

        return (
            <div style={{"alignSelf": "center"}}
                 className={classnames("dropup", "kt-header__topbar-item", "kt-header__topbar-item--langs")}>
                <button
                    className={classnames("cog-btn", "dropdown-toggle", "nav-link", "video-control-btn", this.props.modal ? "video-control-btn touch-btn" : "video-control-btn1 touch-btn")}
                    href='#'
                    onClick={e => e.preventDefault()} data-toggle="dropdown" data-flip="false" aria-expanded="false">
                    <FontAwesomeIcon
                        className={this.props.modal ? "touch-safari-video-btn" : "touch-safari-video-btn-heading"}
                        icon={faCog}/>
                </button>
                <div className={classnames("dropdown-menu-fit", "dropdown-menu-right", "dropdown-menu-anim", "dropdown-menu-top-unround", "dropdown-menu", "camera-menu", "touch-btn")}>
                    {this.props.cameraDevices && this.props.cameraDevices.map(function (camera, idx) {
                        return (

                            <a key={idx}
                               className={this.state.deviceId && this.state.deviceId === camera.deviceId ? "camera-selected dropdown-item touch-btn" : "dropdown-item touch-btn"}
                               href="#" onClick={(e) => this.selectCamera(idx, e)}>
                                <span>&nbsp;{camera.label}</span>
                            </a>

                        )
                    }.bind(this))}
                </div>
            </div>
        )
    }
}

CameraDevices.propTypes = {
    cameraDevices : PropTypes.array,
    deviceId : PropTypes.object,
    modal : PropTypes.bool
};
import React, {useRef, useState} from "react";
import CenteredModal from "../shared/CenteredModal";
import {$$, _$$} from "../../helpers/localization";
import PhoneInput, {isPossiblePhoneNumber} from "react-phone-number-input";
import bg from '../../constants/phone_input_bg'
import en from 'react-phone-number-input/locale/en'
import 'react-phone-number-input/style.css'

export function AppointmentUserDetailsForm({user, onUpdate, onCancel, i18n}) {
    const [formClass, setFormClass] = useState("");
    const [phone, setPhone] = useState(user.phone);
    const [name, setName] = useState(user.fullname === user.email ? '' : user.fullname);

    const formRef = useRef();

    return <CenteredModal
        show={true}
        onHide={onCancel}
        onConfirm={e => formRef.current.dispatchEvent(
            new Event("submit", { cancelable: true, bubble:true })
        )}
        secondary={true}
        title={$$("info_required")}
    >
        <p>{_$$('info_required_phone_explanation', user.fullname)}</p>

        <form id="user_details_form" ref={formRef} noValidate={true} className={formClass} onSubmit={(e)=>{
            e.preventDefault();
            let checkValidity = e.target.checkValidity();

            if (checkValidity !== true || !isPossiblePhoneNumber(phone || "")) {
                setFormClass("was-validated");
                if (!isPossiblePhoneNumber(phone || "")) {
                    document.getElementById("loggedUserPhoneNumber").setCustomValidity("invalid")
                } else {
                    document.getElementById("loggedUserPhoneNumber").setCustomValidity("")
                }
                return;
            }
            onUpdate({fullname:name.trim(), phone: phone})
        }}>

            <div className="form-group">
                <label>{$$("fullname_label")}</label>
                <input type={"text"} className={"form-control"} value={name} onChange={(e)=>{setName(e.target.value)}} required={true} />
            </div>
            <div className="form-group">
                <label>{$$('phone_label')}</label>
                <PhoneInput
                    name="loggedUserPhoneNumber"
                    id="loggedUserPhoneNumber"
                    defaultCountry={user.country ? user.country.toUpperCase() : i18n.selected.key.toUpperCase()}
                    placeholder={$$('phone_label')}
                    value={phone}
                    onChange={(p) => {
                        setPhone(p);
                        if (!isPossiblePhoneNumber(p || "")) {
                            document.getElementById("loggedUserPhoneNumber").setCustomValidity("invalid")
                        } else {
                            document.getElementById("loggedUserPhoneNumber").setCustomValidity("")
                        }
                    }}
                    numberInputProps={{className:`form-control`, required:true}}
                    labels={i18n.selected.lang === "bg" ? bg : en}
                />
                {formClass === "was-validated" && <div className="invalid-feedback d-block">{phone ? (isPossiblePhoneNumber(phone) ? undefined : $$('valid_phone_required')) : $$('valid_phone_required')}</div>}
            </div>
        </form>
    </CenteredModal>
}
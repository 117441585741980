import store from '../store'
import {fetchProvider} from '../actions/provider_actions'
import history from './history'
import {Routes} from '../constants/routes';

export const cliniciansProfileHelper = {
    prepareData,
    prepareDataFromId,

};


export async function prepareData(client) {
    await store.dispatch(fetchProvider(client.id));
    history.push(Routes.CLINICIAN)
}

export async function prepareDataFromId(clientId, selectedAppointment) {
    await store.dispatch(fetchProvider(clientId));
    history.push(
        {
            pathname: Routes.APPOINTMENT,
            state: {
                selectedAppointment: selectedAppointment,
            }
        }
    );
}

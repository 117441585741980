class AnswerType {
    static SELECTION = 'SELECTION';
    static DATE = 'DATE';
    static TEXT = 'TEXT';
    static COUNTRY = 'COUNTRY';
    static CHOOSER = 'CHOOSER';
    static NUMBER = 'NUMBER';
}

export default AnswerType

import {
        applyMiddleware,
        compose,
        createStore
} from 'redux';
import rootReducer from './reducers/composed_reducer';

import persistState, {mergePersistedState} from 'redux-localstorage';
import adapter from 'redux-localstorage/lib/adapters/sessionStorage';
import filter from 'redux-localstorage-filter';
import thunk from 'redux-thunk';
import {autoLogout} from "./utils/autoLogout";

const reducer = compose(
    mergePersistedState()
)(rootReducer);

const storage = compose(
    filter([
        'builder',
        'language',
        'authentication',
        'settings',
        'selectedUser',
        'logBook',
        'charts',
        'nomenclature',
        'symptoms',
        'logSymptoms',
        'permissions',
        'dashboardCharts',
        'latestUserMeasurements',
        'medicalProfile',
        'userInfo',
        'userPassword',
        'documents',
        'providers',
        'hospitals',
        'provider',
        'firstAvailable',
        'providerTimetable',
        'chat',
        'users',
        'medication',
        'practitioner',
        'healthIssues',
        'userAppointments',
        'userAppointment',
        'children_profiles',
        'icd',
        'labDates',
        'labTestResults'
    ])
)(adapter(window.sessionStorage));

const enhancer = compose(
    applyMiddleware(thunk, autoLogout()),
    persistState(storage, 'my-storage-key')
);

const store = createStore(reducer, {}, enhancer);

export default store;

import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {
    Button,
    Modal
} from "react-bootstrap";
import ClinicianName from '../clinicians/ClinicianName';
import UserImage from '../shared/UserImage'
import PropTypes from "prop-types";

export class VideoPickUpModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <Modal scrollable={true} show={this.props.show} backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="call-modal-title">
                        {$$('incoming_call_label')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="body-call-modal">
                    <div className="caller-image-name">
                        <div className='clinician-img-new-container'>
                            <UserImage
                                userID={this.props.clinician.id}
                                classnames={"caller-image"}/>
                        </div>
                        <div className="ml-1">
                            <ClinicianName
                                clinician={this.props.clinician}
                                i18n={this.props.i18n}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="call-modal-footer">
                    <Button
                        variant="primary"
                        onClick={() => {
                            this.props.onPickUp()
                        }}>
                        <div className="flex-center">
                            {$$("pickup_call_button_label")}
                            <i className="ml-1 fa fa-phone"/>
                        </div>
                    </Button>
                    <Button variant="secondary"
                            onClick={() => {
                                this.props.onDecline()
                            }}>
                        <div className="flex-center">
                            {$$("hang_up_button_label")}
                            <i className="ml-1 fa fa-ban"/>
                        </div>
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

VideoPickUpModal.propTypes = {
    clinician: PropTypes.object,
    onDecline: PropTypes.func,
    onPickUp: PropTypes.func,
    i18n: PropTypes.any,
    show: PropTypes.bool
};



export default VideoPickUpModal;
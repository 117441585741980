import {
    CYCLE,
    DAILY, DURATION_FOR_X_DAYS, DURATION_ONGOING, DURATION_UNTIL_DATE,
    EVERY_X_DAYS, SPECIFIC_DAYS_OF_WEEK,
    TAKE_EVERY_X_HOURS,
    TAKE_X_TIMES,
    WHEN_NEEDED
} from "../constants/medications_constants";
import {$$, _$$} from "../helpers/localization";
import {formatDate, getUnitValuefromKey} from "./converter";

/**
 * This checks a Prescription object to determine whether it is for a non-medicinal product and has to be handled separately since it has no name or other properties
 * @param prescription Prescription
 * @return {boolean|boolean}
 */
export function isNonMedicinalProduct(prescription) {
    return !prescription.name && prescription.nhis_prescription_lrn && !prescription.nhis_prescription_dosage_instruction_sequence
}

/**
 * True if it is a prescription for NHIS medication
 * @param prescription
 */
export function isNhisMedication(prescription) {
    return !isNonMedicinalProduct(prescription) && prescription.nhis_prescription_lrn
}

/**
 * Determines how often to take the medication
 * @param {object} prescription
 */
export function getPrescriptionDetailsString (prescription) {
    let instr = getPrescriptionInstructions(prescription)
    let s = `${getPrescriptionFrequency(prescription)}, ${getPrescriptionScheduleString(prescription).toLowerCase()}`;
    if (instr) {
        s = s + ", " + instr.toLowerCase();
    }
    s = s + `, ${getPrescriptionDurationString(prescription).toLowerCase()}`
    return s;
}

function getPrescriptionFrequency(prescription) {
    if (prescription.scheduleType === WHEN_NEEDED) return `${prescription.dose} ${getUnitValuefromKey(prescription.unit, prescription.dose)}`
    switch (prescription.howToTake) {
        case TAKE_X_TIMES:
            return _$$(prescription.scheduleTimesPerDay > 1 ? "times_per_day_plural" : "times_per_day", prescription.dose, getUnitValuefromKey(prescription.unit, prescription.dose), prescription.scheduleTimesPerDay);
        case TAKE_EVERY_X_HOURS:
            return _$$(prescription.scheduleHours > 1 ? "every_x_hours_plural" : "every_x_hours", prescription.dose, getUnitValuefromKey(prescription.unit, prescription.dose), prescription.scheduleHours);
        default:
            return '';
    }
}

function getPrescriptionScheduleString(medication) {
    switch (medication.scheduleType) {
        case WHEN_NEEDED:
            return $$('when_needed');
        case DAILY:
            return $$('daily');
        case EVERY_X_DAYS:
            return _$$(medication.scheduleDaysInactive > 0 ? 'every_days_plural' : 'every_days', medication.scheduleDaysInactive + 1);
        case CYCLE:
            let key;
            if (medication.scheduleDaysActive > 1 &&  medication.scheduleDaysInactive > 1) {
                key = 'take_rest_xy_plural'
            } else if(medication.scheduleDaysActive == 1 &&  medication.scheduleDaysInactive > 1) {
                key = 'take_rest_y_plural'
            } else if(medication.scheduleDaysActive > 1 &&  medication.scheduleDaysInactive == 1) {
                key = 'take_rest_x_plural'
            } else {
                key = 'take_rest'
            }


            return _$$(key, medication.scheduleDaysActive, medication.scheduleDaysInactive);
        default:
            return '';
    }
}

/**
 * Get the formatted prescription duration string
 *
 * @returns {string} medication plan duration
 */
function getPrescriptionDurationString(medication) {
    switch (medication.durationType) {
        case DURATION_ONGOING:
            return $$('ongoing');
        case DURATION_FOR_X_DAYS:
            return _$$(medication.durationInDays > 1 ? 'for_days_until_plural' : 'for_days_until', medication.durationInDays);
        default:
            return '';
    }
}

export function getPrescriptionInstructions(med) {
    if (med.instructions) {
        return med.instructions.split(",").map(instr => $$(instr)).join(", ");
    }
    return null;
}

function getMedFrequencyString(medication) {
    if (medication.scheduleType === WHEN_NEEDED) {
        return `${medication.dose} ${getUnitValuefromKey(medication.unit, medication.dose)}`
    }
    switch (medication.howToTake) {
        case TAKE_X_TIMES:
            return medication.scheduleTimesPerDay > 1 ?
                _$$('times_per_day_plural', medication.dose, getUnitValuefromKey(medication.unit, medication.dose), medication.scheduleTimesPerDay) :
                _$$('times_per_day', medication.dose, getUnitValuefromKey(medication.unit, medication.dose), medication.scheduleTimesPerDay);
        case TAKE_EVERY_X_HOURS:
            return medication.scheduleHours > 1 ?
                _$$('every_x_hours_plural', medication.dose, getUnitValuefromKey(medication.unit, medication.dose), medication.scheduleHours) :
                _$$('every_x_hours', medication.dose, getUnitValuefromKey(medication.unit, medication.dose), medication.scheduleHours);
        default:
            return '';
    }
}

function getMedScheduleString(medication) {
    switch (medication.scheduleType) {
        case WHEN_NEEDED:
            return $$('when_needed');
        case DAILY:
            return $$('daily');
        case EVERY_X_DAYS:
            return _$$(medication.scheduleDaysInactive > 0 ? 'every_days_plural' : 'every_days', medication.scheduleDaysInactive + 1);
        case CYCLE:
            let key;
            if (medication.scheduleDaysActive > 1 &&  medication.scheduleDaysInactive > 1) {
                key = 'take_rest_xy_plural'
            } else if(medication.scheduleDaysActive == 1 &&  medication.scheduleDaysInactive > 1) {
                key = 'take_rest_y_plural'
            } else if(medication.scheduleDaysActive > 1 &&  medication.scheduleDaysInactive == 1) {
                key = 'take_rest_x_plural'
            } else {
                key = 'take_rest'
            }


            return _$$(key, medication.scheduleDaysActive, medication.scheduleDaysInactive);
        case SPECIFIC_DAYS_OF_WEEK:
            return $$("specific_days_of_week");
        default:
            return '';
    }
}

function getMedDurationString(medication) {
    switch (medication.durationType) {
        case DURATION_ONGOING:
            return $$('ongoing');
        case DURATION_FOR_X_DAYS:
            return _$$(medication.durationInDays > 1 ? 'for_days_until_plural' : 'for_days_until', medication.durationInDays);
        case DURATION_UNTIL_DATE:
            return `${$$("until_date")} ${formatDate(medication.ends, true)}`;
        default:
            return '';
    }
}


export function getMedString(medication) {
    return `${getMedFrequencyString(medication)}, ${getMedScheduleString(medication).toLowerCase()}, ${getMedDurationString(medication).toLowerCase()}`;
}
import React, {Component} from 'react'
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';
import {$$} from '../../helpers/localization'
import GroupedSymptoms from './GroupedSymptoms'
import _ from 'underscore'
import moment from 'moment'
import SymptomsLogForm from './SymptomsLogForm';
import {connect} from 'react-redux'
import SymptomsHeader from './SymptomsHeader';
import no_data from '../../resources/images/no_data.png'
import {getHealthIssueOptionsNoLowerCase} from '../../constants/select_options'
import {infoUtils} from '../../utils/infoUtils'
import PropTypes from "prop-types";

export class SymptomsLog extends Component {
    state = {
        selectedDateTime: (this.props.symptoms.entries && this.props.symptoms.entries.length > 0) ? this.props.symptoms.entries[0].date_time : null,
        searchValue: "",
        pickedSelectOption: this.props.selectedHealthIssue ? this.props.selectedHealthIssue.id : 0,
        showSymptomLogForm: false,
        symptomEntry: {
            created_timestamp: 0,
            id: '',
            date_time: 0,
            notes: '',
            lat: 0,
            location_timestamp: 0,
            lon: 0,
            timezone: '',
            selectedSymptoms: [],
            health_issues_ids: []
        },
        startDate : 0,
        endDate : moment(new Date()).valueOf() + 3155760000000,
        hasContent: true,
        loadComplete: false
    }

    constructor(props) {
        super(props);
    }

    /**
     * Fetch selected user symptoms
     */
    onLoadMore = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let params;
        if (this.props.userId && (this.props.symptoms.request.finished === undefined || this.props.symptoms.request.finished)) {
            if (this.props.symptoms.entries && this.props.symptoms.entries.length > 0) {
                const entriesLength = this.props.symptoms.entries.length;
                params = {
                    before_date_time: this.props.symptoms.entries[entriesLength - 1].date_time,
                }
            }
            this.props.fetchEntries(this.props.userId, params, false);
        }
    }

    changeDateRange = async (dates) => {
        this.setState({
                startDate : dates.startDate ?moment(dates.startDate).valueOf() : 0,
                endDate : dates.endDate ? moment(dates.endDate).valueOf() :  moment(new Date()).valueOf() + 3155760000000
            }
        )
    }

    componentDidMount() {
            this.props.fetchEntries(this.props.userId, null, true).then(
                () => {
                    this.setState({
                            loadComplete: true
                        }
                    )
                });
    }

    componentWillUnmount() {
        this.props.clearSymptomsLog();
    }

    onDateTimeChange = (dateTime) => {
        this.setState({
            selectedDateTime: dateTime
        });
    }

    onHasContent = (hasContent) => {
        this.setState({hasContent: hasContent})
    }

    /**
     * Get the symptoms grouped by date time
     *
     * @returns {Array} of grouped symptoms
     */

    getNoDataObject = (label) => {
        return {
            imgClass: 'no-entry-image',
            objClass: 'no-data-object-documents',
            primaryLabelClass: 'no-hospitals-primary-label',
            src: no_data,
            primaryLabel: label,
        }
    }

    getGroupedSymptoms = () => {
        let noSymptoms = this.getNoDataObject($$('symptoms_no_data_label'))
        if (this.props.symptoms.entries && this.props.symptoms.entries.length > 0) {
            const groupedByDate = _.groupBy(this.props.symptoms.entries, (result) => moment(result.date_time).format('YYYY-MM-DD'));
            const keys = Object.keys(groupedByDate);
            if (keys.length > 0) {
                return keys.map((dateTime, idx) => {
                    return <GroupedSymptoms
                        pickedSelectOption={this.state.pickedSelectOption}
                        searchValue={this.state.searchValue}
                        selectedDateTime={moment(this.state.selectedDateTime).format("YYYY-MM-DD")}
                        onDateTimeChange={this.onDateTimeChange}
                        key={idx}
                        indexOfGroup={idx}
                        date_time={dateTime}
                        startDate ={this.state.startDate}
                        endDate = {this.state.endDate}
                        allEntries = {this.props.symptoms.entries}
                        symptoms={groupedByDate[dateTime]}
                        settings={this.props.settings}
                        i18n={this.props.i18n}
                        nomenclature={this.props.nomenclature}
                        showSymptomsList={this.shouldShowSymptomLogForm}
                        fetchSelectedUserHealthIssues={this.props.fetchSelectedUserHealthIssues}
                        healthIssues={this.props.healthIssues}
                        onHasContent={this.onHasContent}
                        hasContent={this.state.hasContent}
                    />
                });
            }
        } else if (this.state.loadComplete) {
            return infoUtils.noData(noSymptoms)
        }
    }
    /**
     * Creates an array with only the health issues that the entry is part of.
     * @param {*} entry - symptoms entry
     */
    getHealthIssueList = (entry) => {
        let healthIss = [];
        if (entry.health_issues_ids) {
            if (this.props.healthIssues && this.props.healthIssues.entries && this.props.healthIssues.entries.length > 0) {
                entry.health_issues_ids.map((ids) => {
                    this.props.healthIssues.entries.map((h) => {
                        if (ids === h.id) {
                            healthIss.push(h)
                        }
                    });
                });
                return healthIss;
            }
        }
    }

    /**
     * Handles the visibility of the symptoms create form by toggling the state variable
     *
     * @param {object} e the event
     * @param {object} entry the event
     */
    shouldShowSymptomLogForm = (e, entry) => {
        const shouldShowSymptomLogForm = !this.state.showSymptomLogForm;
        if (entry) {
            this.setState({
                showSymptomLogForm: shouldShowSymptomLogForm,
                symptomEntry: {
                    ...this.state.entry,
                    created_timestamp: entry.created_timestamp,
                    id: entry.id,
                    date_time: entry.date_time,
                    health_issues_ids: entry.health_issues_ids,
                    notes: entry.notes !== null ? entry.notes : '',
                    lat: entry.lat,
                    location_timestamp: entry.location_timestamp,
                    lon: entry.lon,
                    timezone: entry.timezone,
                    selectedSymptoms: entry.symptoms
                }
            });
        } else {
            this.setState({
                showSymptomLogForm: shouldShowSymptomLogForm,
                symptomEntry: {
                    ...this.state.entry,
                    created_timestamp: 0,
                    id: '',
                    date_time: 0,
                    health_issues_ids: [],
                    notes: '',
                    lat: 0,
                    location_timestamp: 0,
                    lon: 0,
                    timezone: '',
                    selectedSymptoms: []
                }
            });
        }
    }

    onSelectChange = (evt) => {
        if (parseInt(evt.value) === 0) {
            this.setState({
                pickedSelectOption: 0,
                hasContent: true
            })
        } else {
            this.setState({
                pickedSelectOption: evt.value
            })
            if (this.props.symptoms.entries && this.props.symptoms.entries.length === 0) {
                this.setState({
                    hasContent: false
                })
            }
        }
        this.props.clearPreSelectedHealthIssue();
    }

    onSearchChange = (evt) => {
        this.setState({
            searchValue: evt,
            hasContent: true
        })
    }


    getOptions(options) {
        const optionArray = getHealthIssueOptionsNoLowerCase(options);
        optionArray.unshift({value: "0", text: $$("all_issues")})
        return optionArray;
    }


    render() {
        let domNode = document.getElementById('new-symptom-btn');
        const hasMore = !this.props.symptoms.isLastPage && (this.props.symptoms.request.finished === undefined || this.props.symptoms.request.finished) && !this.props.symptoms.request.error;
        let noSymptomsForFilters = this.getNoDataObject($$("no_symptoms_after_filters"))
        return (
            <div>
                {!this.state.showSymptomLogForm && <SymptomsHeader
                    symptomsOptions={this.getOptions(this.props.healthIssues.entries)}
                    pickedOption={this.state.pickedSelectOption}
                    onSelectChange={this.onSelectChange}
                    i18n = {this.props.lang}
                    onRangeSelected={this.changeDateRange}
                    onSearchChange={this.onSearchChange}
                />}

                {!this.state.loadComplete && <div className={"med-search-loader"} />}
                {!this.state.showSymptomLogForm &&
                <div className="documents-inner-card symptoms-body">
                    <div ref={(ref) => this.listRef = ref}>
                        {this.state.hasContent &&
                        <div>
                            <div className="list-group" style={{marginTop: '2.2rem'}}>
                                {this.getGroupedSymptoms()}
                            </div>
                            {hasMore && <div className="text-center" style={{"marginTop": "10px"}}>
                                <a href="#" onClick={this.onLoadMore}>{$$('load_more')}</a>
                            </div>}
                        </div>
                        }
                        {!this.state.hasContent &&
                        <div className="list-group" style={{marginTop: '2.2rem'}}>
                            {infoUtils.noData(noSymptomsForFilters)}
                        </div>
                        }
                    </div>
                </div>
                }
                {!this.state.showSymptomLogForm && ReactDOM.createPortal(
                    <div className="blue-button-container" onClick={this.shouldShowSymptomLogForm}>
                        <i className="add-new-button fa fa-plus" aria-hidden="true"
                           style={{"display": "table-cell", "verticalAlign": "middle"}}/>
                    </div>,
                    domNode
                )}
                {this.state.showSymptomLogForm &&
                <SymptomsLogForm
                    nomenclature={this.props.nomenclature}
                    showSymptomsList={this.shouldShowSymptomLogForm}
                    created_timestamp={this.state.symptomEntry.created_timestamp}
                    id={this.state.symptomEntry.id}
                    date_time={this.state.symptomEntry.date_time}
                    notes={this.state.symptomEntry.notes}
                    selectedEntry={this.state.symptomEntry}
                    lat={this.state.symptomEntry.lat}
                    health_issues_ids={this.state.symptomEntry.health_issues_ids}
                    location_timestamp={this.state.symptomEntry.location_timestamp}
                    lon={this.state.symptomEntry.lon}
                    timezone={this.state.symptomEntry.timezone}
                    selectedSymptoms={this.state.symptomEntry.selectedSymptoms}
                    userId={this.props.userId}
                    i18n={this.props.i18n}
                    fetchSelectedUserHealthIssues={this.props.fetchSelectedUserHealthIssues}
                    healthIssues={this.props.healthIssues}
                    getHealthIssueList={this.getHealthIssueList}/>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    i18n: state.language.selected,
    lang: state.language
})

SymptomsLog.propTypes = {
    dispatch: PropTypes.func,
    fetchEntries:  PropTypes.func,
    fetchSelectedUserHealthIssues:  PropTypes.func,
    healthIssues:  PropTypes.object,
    i18n:  PropTypes.object,
    lang:  PropTypes.object,
    nomenclature:  PropTypes.object,
    selectedHealthIssue:  PropTypes.object,
    settings:  PropTypes.object,
    symptoms:  PropTypes.object,
    tabIndex:  PropTypes.number,
    userId:  PropTypes.string,
    clearSymptomsLog : PropTypes.func
};

export default connect(mapStateToProps)(SymptomsLog)

import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {CONVERTER, getUnitValuefromKey} from '../../utils/converter'
import {
    BLOOD_SUGAR,
    HBA1C,
    HEIGHT,
    HYDRATION,
    KETONES,
    MEDICATION,
    RESPIRATORY_RATE,
    SATURATION,
    TEMPERATURE,
    URINE_PH,
    WEIGHT
} from '../../constants/entry_types'
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";
import PropTypes from "prop-types";

export class LogbookSimpleEntry extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
    }

    getColumn = (name, value, unit, entry_type) => {
        return <div className="vitals-column-sm-logbook" style={{display: "flex", flexDirection: "column",}}>
            <div className="d-flex">
                <div className="medrec-grey-2 hide-on-big">
                    <i className="fa fa-clock"/>
                    <span
                        className="time-for-symptoms medrec-blue-1"> {CONVERTER.getEntryDateTimeFormat(this.props.entry.date_time)}</span>
                </div>
            </div>
            <div className="d-flex logbook-entry-title"
                 style={{whiteSpace: "no-wrap"}}>{entry_type && entry_type === 'MEDICATION' ? name : $$(name.toLowerCase())}</div>
            <div className="d-flex" style={{alignItems: "baseline"}}>
                <span className="vitals-value">{value}</span>
                <span className="vitals-unit">{unit}</span>
            </div>
        </div>
    }

    getEntryData = (measurement) => {
        switch (measurement.entry_type) {
            case TEMPERATURE:
                return this.getColumn("entry_type_temperature", CONVERTER.convertByUnit(measurement.entry_type, measurement.temperature), CONVERTER.getUnitByType(measurement.entry_type))
            case BLOOD_SUGAR:
                return this.getColumn("entry_type_blood_sugar", CONVERTER.bloodSugarPerUnit(measurement.blood_sugar), CONVERTER.getFormattedBloodSugarUnit())
            case HBA1C:
                return this.getColumn("entry_type_hba1c", CONVERTER.hba1cPerUnit(measurement.hba1c), CONVERTER.getFormattedHBA1CUnit())
            case KETONES:
                return this.getColumn("entry_type_ketones", CONVERTER.ketonesPerUnit(measurement.ketones), CONVERTER.getFormattedKetonesUnit())
            case URINE_PH:
                return this.getColumn("entry_type_urine_ph", CONVERTER.convertByUnit(measurement.entry_type, measurement.urine_ph))
            case WEIGHT:
                return this.getColumn("entry_type_weight", CONVERTER.convertByUnit(measurement.entry_type, measurement.weight), CONVERTER.getUnitByType(measurement.entry_type))
            case HEIGHT:
                return this.getColumn("entry_type_height", CONVERTER.convertByUnit(measurement.entry_type, measurement.height), CONVERTER.getUnitByType(measurement.entry_type))
            case MEDICATION:
                return this.getColumn(measurement.name, measurement.dose, getUnitValuefromKey(measurement.unit, measurement.dose), 'MEDICATION')
            case SATURATION:
                return this.getColumn("entry_type_oxygen_saturation", CONVERTER.convertByUnit(measurement.entry_type, measurement.oxygen_saturation), "%")
            case RESPIRATORY_RATE:
                return this.getColumn("entry_type_respiratory_rate", CONVERTER.convertByUnit(measurement.entry_type, measurement.respiratory_rate), CONVERTER.getFormatedRespiratoryRate())
            case HYDRATION:
                return this.getColumn("Hydration", CONVERTER.convertByUnit(measurement.entry_type, measurement.hydration), CONVERTER.getUnitByType(measurement.entry_type))
        }
    }


    render() {

        return (
            <div className="logbook-entry low-shadow-container condition-card">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        {this.getEntryData(this.props.entry)}
                        {this.props.entry.notes && !this.props.entry.hideNotes ?
                            <div className="mt-2" style={{wordBreak: "break-word"}}>{$$("notes")}: {this.props.entry.notes}</div> : ""}
                    </div>
                    <div className="col-md-6 col-sm-12">{this.props.getHealthIssueList(this.props.entry)}</div>
                </div>
                <div className="row" style={{justifyContent: "flex-end", paddingRight: "10px"}}>
                    <DownloadEditDeleteButtons
                        handleEditClick={() => this.props.onShowEditModal(this.props.entry)}
                        handleDeleteClick={() => this.props.onShowModal(this.props.entry)}
                    />
                </div>
            </div>

        )
    }
}

LogbookSimpleEntry.propTypes = {
    entry: PropTypes.object,
    getHealthIssueList: PropTypes.func,
    onShowEditModal: PropTypes.func,
    onShowModal: PropTypes.func,
    unit: PropTypes.string
};

export default LogbookSimpleEntry

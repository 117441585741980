import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    $$,
    _$$
} from '../../helpers/localization'
import {
    CONVERTER,
    getUnitValuefromKey
} from '../../utils/converter';
import ReactDOM from 'react-dom';
import moment from 'moment'
import {
    TAKE_EVERY_X_HOURS,
    TAKE_X_TIMES,
    WHEN_NEEDED
} from '../../constants/medications_constants';
import {
    createMedication,
    deleteMedEventsEntries,
    deleteMedicationEvents,
    deleteMedicationPlan,
    fetchSelectedChildMedicationPlan,
    fetchSelectedUserMedicationPlan,
    getPlanStats,
    updateMedication
} from '../../actions/medications_actions';
import MedicationForm from './MedicationForm'
import no_data from '../../resources/images/no_data.png'
import HealthIssueTag from '../health/HealthIssueTag';
import CenteredModal from '../shared/CenteredModal';
import {infoUtils} from '../../utils/infoUtils'
import SelectedPill from "./SelectedPill";
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";
import Select from '../shared/Select'
import {getHealthIssueOptionsNoLowerCase} from '../../constants/select_options'
import PropTypes from "prop-types";
import {dateTimeUtils} from "../../utils/dateTimeUtils";
import MakeInactiveButton from "./MakeInactiveButton";
import {AptechkoSearch} from "./MedicationsPanel";
import {isBulgarian} from "../../utils/userUtils";
import {getMedString} from "../../utils/medicationUtils";

class MedicationsPlansPage extends Component {

    state = {
        deletedPlanModal: {
            show: false
        },
        deletedMedHistoryModal: {
            show: false
        },
        selectedEntryToDelete: null,
        addUpdateModal: {
            show: false
        },
        selectedEntry: null,
        filteredMedicationPlan: this.props.medicationPlan,
        filterStatus: 'active',
        needsUpdate: false,
        pickedSelectOption: this.props.selectedHealthIssue ? this.props.selectedHealthIssue.id : "0",
        filteredByHealthIssue: this.props.medicationPlan,
        loaded: false
    }

    constructor(props) {
        super(props);
        this.index = 0;
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    componentDidMount() {
        this._ismounted = true;
        let params = {
            size: 1000,
            before_date_time: moment().valueOf()
        }
        this.props.fetchSelectedUserMedicationPlan(this.props.selectedUser.id, params)
            .then(() => {
                this.setState({loaded : true})
                if (this.props.selectedUser.id === this.props.loggedUser.id) {
                    this.props.childrenProfiles.entries.filter(cp => cp.child_accepted).map(childProfile => {
                        this.props.fetchSelectedChildMedicationPlan(childProfile.id, params);
                    })
                }
            });
    }

    filterMedication(status) {
        let filteredMedications = [];
        switch (status) {
            case "inactive":
                filteredMedications = this.props.medicationPlan.filter((medPlan) => {
                    return new Date(medPlan.ends) < new Date() && medPlan.ends !== 0
                })
                break;
            case "active":
                filteredMedications = this.props.medicationPlan.filter((medPlan) => {
                    return new Date(medPlan.ends) > new Date() || medPlan.ends === 0
                })
                break;
            case "all":
                filteredMedications = this.props.medicationPlan;
        }
        this.setState({filteredMedicationPlan: filteredMedications})
        return filteredMedications;
    }

    componentDidUpdate(prevProps) {
        if (this.state.needsUpdate) {
            let params = {
                size: 1000,
                before_date_time: moment().valueOf()
            }
            this.setState({loaded : false})
            this.props.fetchSelectedUserMedicationPlan(this.props.selectedUser.id, params)
                .then(() => {
                    this.setState({loaded : true})
                    if (this.props.selectedUser.id === this.props.loggedUser.id) {
                        this.props.childrenProfiles.entries.filter(cp => cp.child_accepted).map(childProfile => {
                            this.props.fetchSelectedChildMedicationPlan(childProfile.id, params);
                        })
                    }
                });

            /*
                There is a bug in mix blend mode if we just switch the shape to have a
                new image on the same component the colours wont display.
                This sets a timeout so that react doesn't tey to be clever and actually
                does redraw the component
            */
            this.setState({
                refreshPill: true,
            }, () => setTimeout(() => {
                if (this.state.refreshPill && this._ismounted) {
                    this.setState({
                        refreshPill: false,
                    })
                }
            }, 200))

            this.setState({needsUpdate: false})
        } else {
            if (this.props.medicationPlan !== prevProps.medicationPlan) {
                this.filterMedicationByHealthIssue(this.state.pickedSelectOption, this.state.filterStatus);
            }
        }
    }

    setUpdate = () => {
        this.setState({needsUpdate: true})
    }

    /**
     * Confirm deletion of the plan and pass to the deletion of the medication history
     */
    onDeletePlanConfirmModal = () => {

        this.setState({
            deletedMedHistoryModal: {show: true},
            deletedPlanModal: {show: false},
        });


    }

    /**
     * Confirm deletion of the medication history: medication event and medication entries (optionally)
     */
    onDeleteMedHistoryConfirmModal = async (evt) => {
        evt.persist();
        let params = {
            size: 1000,
            before_date_time: moment().valueOf()
        }
        let deletedEntry = this.state.selectedEntryToDelete;
        deletedEntry.deleted = true;
        deletedEntry.updated_timestamp = moment().valueOf();
        await this.props.deleteMedicationPlan(deletedEntry.id, deletedEntry.user_id);
        await this.props.deleteMedicationEvents(deletedEntry.id, deletedEntry.user_id);

        if (evt.target && evt.target.id === 'btn-primary1') {
            await this.props.deleteMedEventsEntries(deletedEntry.id, deletedEntry.user_id);
        }

        this.setState({loaded : false})
        await this.props.fetchSelectedUserMedicationPlan(deletedEntry.user_id, params).then(() =>{
            this.setState({loaded : true})
        });
        this.filterMedication(this.state.filterStatus);


        this.setState({
            deletedMedHistoryModal: {show: false},
            selectedEntryToDelete: '',
        });
    }

    /**
     * Show confirmation modal when trying to delete an entry
     *
     * @param {entry} key The entry to delete
     */
    showDeletedConfirmationModal = (key) => {
        this.setState({
            deletedPlanModal: {show: true},
            selectedEntryToDelete: key
        })
    }


    /**
     * Hide the modal
     */
    onHideAddUpdateModal = () => {
        this.setState({
            addUpdateModal: {
                show: false
            },
            selectedEntry: null,
            needsUpdate: true,
            refreshPill: true,
        });
    }

    /**
     * Show add/update modal
     *
     * @param {entry} entry - The entry to modify or null
     */
    showAddUpdateModal = (entry) => {
        if (entry) {
            this.setState({
                selectedEntry: entry,
                addUpdateModal: {show: true}
            })
        } else {
            this.setState({
                selectedEntry: null,
                addUpdateModal: {show: true}
            })
        }
    }

    /**
     * Clear selected entry from state
     */
    clearSelectedEntry = () => {
        this.setState({
            selectedEntry: null,
            needsUpdate: true,
        })
    }

    /**
     * Determines the specifics of taking the medication
     * @param {object} medication
     */
    getMedicationData = (medication) => {
        let alarms = medication.schedule && medication.scheduleType !== WHEN_NEEDED && medication.schedule.times.map((time, index) => {
            let timeStr = CONVERTER.getEntryDateTimeFormat(moment(time, 'HH:mm'), false);

            return <div key={index} className="d-flex mr-4 ml-1">
                <i className="fa fa-bell mt-1 medication-alarm"/>
                <div className="medication-time ml-1">{timeStr}</div>
            </div>
        })

        return <div key={++this.index} className="medication-row ml-2 flex-1">
            <div className="medications-plans-name d-flex flex-wrap">
                <div className="d-flex">{medication.name}</div>
                <div className="d-flex flex-wrap">{alarms}</div>
            </div>
            {(medication.remaining_meds <= medication.refillAlarmThreshold && medication.remaining_meds !== -1) &&
                <div
                    className="d-flex medication-reminder">{medication.remaining_meds} {getUnitValuefromKey(medication.unit)} left!
                </div>
            }
            <div className="medication-notes mt-1">{getMedString(medication)}</div>
            {medication.notes && <div className="medication-plan-notes">
                {medication.notes}
            </div>}
            {isBulgarian(this.props.selectedUser.country) && <div style={{marginLeft: "-10px",}} className="mt-1">
                    <AptechkoSearch name={medication.name}/>
                </div>
            }
        </div>;
    }

    /**
     * Returns the list of health issues where the medication is present
     * @param {object} medication
     */
    getHealthIssues = (medication) => {
        if (medication.health_issues_ids) {
            let healthIssues = medication.health_issues_ids.map((h, idx) => {
                let healthIssueName;
                this.props.healthIssues.map((healthIssue) => {
                    if (healthIssue.id === h) {
                        healthIssueName = healthIssue.name
                    }
                })
                if (healthIssueName) {
                    return <HealthIssueTag
                        key={idx}
                        className='patient-health-issue-list2'
                        healthIssue={healthIssueName ? healthIssueName : ""}/>
                }
            });

            return <div key={++this.index} className='medication-issues-sm-sc text-center clinicians-list-cell'>
                <div className="flex-wrap">{healthIssues}</div>
            </div>;
        }
    }

    /*
        There is a bug in mix blend mode if we just switch the shape to have a
        new image on the same component the colours wont display.
        This acts as an swap so that we always get a new component
     */
    getPillShape = (shapeIndex) => {
        if (this.state.refreshPill) {
            return 9001;
        } else {
            return shapeIndex;
        }
    }

    isMedPlanDisabled = (medPlan) => {
        return !!(medPlan.inactive || (new Date(medPlan.ends) < new Date() && medPlan.ends !== 0));
    }

    /**
     * Returns the edit and delete buttons
     * @param {object} medication
     */
    getButtons = (medication) => {
        return <div key={++this.index} className="text-right med-btn">
            <div className="">
                {(medication.inactive || new Date(medication.ends) > new Date() || medication.ends === 0) &&
                    <MakeInactiveButton
                        updateMedication={this.props.updateMedication}
                        medPlan={medication}
                        selectedUser={this.props.selectedUser}
                        i18n={this.props.i18n}
                        makeActive={this.isMedPlanDisabled(medication)}/>}

                <DownloadEditDeleteButtons
                    handleEditClick={!medication.inactive ? () => this.showAddUpdateModal(medication) : undefined}
                    handleDeleteClick={() => this.showDeletedConfirmationModal(medication)}
                />
            </div>
            <div className='small font-italic'>
                <span>{$$('med_start_date')}</span>
                {dateTimeUtils.getFormattedDate(medication.starts)}
                {medication.ends > 0 &&
                    <span>&nbsp;{$$('until_label')}&nbsp;
                        {dateTimeUtils.getFormattedDate(medication.ends)}
                    </span>
                }
            </div>
        </div>
    }


    /**
     * Creates the row each row of the medication
     * @param {*} medication
     * @param {*} idx
     */
    getRow = (medication, idx) => {
        let cells = [];

        cells.push(this.getHealthIssues(medication))
        cells.push(this.getButtons(medication))
        return <div key={idx}>

            <div className="d-flex flex-space-between flex-wrap flex-wrap med-plan-row">
                <div className="d-flex flex-wrap">
                    <SelectedPill primaryColour={CONVERTER.decimalHexTwosComplement(medication.shapePrimaryColor)}
                                  secondaryColour={CONVERTER.decimalHexTwosComplement(medication.shapeSecondaryColor)}
                                  pillSelection={this.getPillShape(medication.shapeIndex)}/>
                    {this.getMedicationData(medication)}
                </div>
                {cells}
            </div>
            <hr className="ruler-plans"/>
        </div>;
    }

    getBody = () => {
        let sortedMedications = this.state.filteredMedicationPlan.filter(medPlan => medPlan.user_id === this.props.selectedUser.id).sort((a, b) => (a.created_datetime < b.created_datetime) ? 1 : -1)
        let allRows = [];

        for (let i = 0; (i < sortedMedications.length); ++i) {
            allRows.push(this.getRow(sortedMedications[i], i));
        }

        return [].concat.apply([], allRows);
    }

    onChangeValue = event => {
        this.setState({filterStatus: event.target.value})
        this.filterMedicationByHealthIssue(this.state.pickedSelectOption, event.target.value)
    }

    getOptions(options) {
        const optionArray = getHealthIssueOptionsNoLowerCase(options);
        optionArray.unshift({value: 0, text: $$("all_issues")})
        return optionArray;
    }


    onSelectChange = (evt) => {
        this.setState({
                pickedSelectOption: evt.value
            },
            () => this.filterMedicationByHealthIssue(evt.value, this.state.filterStatus)
        );
    }

    filterMedicationByHealthIssue = (optionSelected, activeInactiveAll) => {
        this.setState({
            filteredMedicationPlan: this.filterBySelection(optionSelected, activeInactiveAll),
        })
    }

    /**
     *
     * @returns Filters the medication entries by health issues
     */
    filterBySelection = (optionSelected, activeInactiveAll) => {
        let filtered = this.filterMedication(activeInactiveAll);
        if (optionSelected === "0") {
            return filtered
        } else {
            return filtered.filter((entry) => {
                let hasHealthIssue = false;
                if (entry.health_issues_ids != null) {
                    entry.health_issues_ids.map((h) => {
                        if (h === optionSelected) {
                            hasHealthIssue = true;
                        }
                    })
                }
                return hasHealthIssue;
            })
        }
    }


    render() {
        let noMedications = {
            imgClass: 'no-entry-image',
            objClass: 'no-data-object',
            primaryLabelClass: 'no-clinicians-primary-label',
            src: no_data,
            primaryLabel: $$('no_medications'),
        }
        let domNode = document.getElementById('new-symptom-btn');
        return (
            <>
                <div className="d-flex flex-space-between mb-2 flex-wrap">
                    <div className="my-4 radio-container-medications">
                        <label className="medication-radiobutton custom-control custom-radio">
                            <input className="custom-control-input" type="radio" value="active" name="type"
                                   defaultChecked
                                   onChange={this.onChangeValue}/>
                            <span className="ml-1 custom-control-label">{$$("radio_active_label")}</span>
                        </label>
                        <label className="medication-radiobutton custom-control custom-radio ml-2">
                            <input className="custom-control-input" type="radio" value="inactive" name="type"
                                   onChange={this.onChangeValue}/>
                            <span className="ml-1  custom-control-label">{$$("radio_inactive_label")}</span>
                        </label>
                        <label className="medication-radiobutton custom-control custom-radio  ml-2">
                            <input className="custom-control-input" type="radio" value="all" name="type"
                                   onChange={this.onChangeValue}/>
                            <span className="ml-1 custom-control-label">{$$("all_label")}</span>
                        </label>
                    </div>
                    <div className="" style={{'display': 'flex', 'marginRight': '2rem'}}>
                        <Select
                            name="issues-type"
                            options={this.getOptions(this.props.healthIssues)}
                            value={this.state.pickedSelectOption}
                            onChange={this.onSelectChange}
                            customClass="med-plan-filter"
                            style={{
                                'minWidth': '-10.188rem',
                                'minHeight': '2.938rem',
                                'maxWidth': '18.75rem',
                                'zIndex': '1',
                                'opacity': '70%'
                            }}
                            placeHolder={$$('health_issues_label')}/>
                    </div>
                </div>
                {!this.state.loaded &&  <div className={"med-search-loader"} />}
                {this.state.loaded && (this.getBody().length > 0
                    ? <div className="medication-container">{this.getBody()}</div>
                    : infoUtils.noData(noMedications))}
                {ReactDOM.createPortal(
                    <div onClick={() => {
                        this.showAddUpdateModal(null)
                    }} className="blue-button-container">
                        <i className="add-new-button fa fa-plus add-medication-plan"/>
                    </div>,
                    domNode)}
                {this.state.addUpdateModal.show &&
                    <MedicationForm
                        show={this.state.addUpdateModal.show}
                        onHide={this.onHideAddUpdateModal}
                        medication={this.props.medication}
                        selectedUser={this.props.selectedUser}
                        selectedEntry={this.state.selectedEntry}
                        clearSelectedEntry={this.clearSelectedEntry}
                        healthIssues={this.props.healthIssues}
                        updateMedication={this.props.updateMedication}
                        createMedication={this.props.createMedication}
                        getPlanStats={this.props.getPlanStats}
                        formDisabled={this.props.formDisabled}
                        i18n={this.props.i18n}
                        settings={this.props.settings.data}
                        needsUpdate={this.setUpdate}
                        fetchSelectedUserMedicationPlan={this.props.fetchSelectedUserMedicationPlan}/>}
                <CenteredModal
                    title={$$('delete_plan')}
                    body={$$('mark_medication_entry_deleted_modal_message')}
                    dialogClassName='doc-delete-modal'
                    show={this.state.deletedPlanModal.show}
                    onHide={() => this.setState({deletedPlanModal: {show: false}})}
                    cancelBtnLabel={$$('cancel_btn')}
                    onConfirm={this.onDeletePlanConfirmModal}
                    id="delete-modal-title"
                    className="center-delete-modal"
                    idFooter="footer-delete-modal"
                    confirmBtnLabel={$$('delete_label')}
                    confirmBtnClass="danger"
                    idBtnPrimary="btn-danger"
                    idBtnSecondary="btn-secondary">
                    {this.state.selectedEntryToDelete ? this.state.selectedEntryToDelete.name : ''}<br/>
                    <div className="bin-align">
                        <i className="fas fa-trash-alt fa-3x"/>
                    </div>
                </CenteredModal>
                <CenteredModal
                    title={$$('delete_med_history')}
                    body={$$('mark_med_history_deleted_modal_message')}
                    dialogClassName='doc-delete-modal'
                    show={this.state.deletedMedHistoryModal.show}
                    onHide={() => this.setState({deletedMedHistoryModal: {show: false}})}
                    cancelBtnLabel={$$('cancel_btn')}
                    onConfirm={this.onDeleteMedHistoryConfirmModal}
                    id="delete-modal-title"
                    className="center-delete-modal"
                    idFooter="footer-delete-modal"
                    confirmBtnLabel={$$('delete_label')}
                    confirmBtnClass="danger"
                    idBtnPrimary="btn-danger"
                    idBtnSecondary="btn-secondary1"
                    extraBtnLabel={$$('no_label')}
                    idBtnExtra="btn-extra">
                    {this.state.selectedEntryToDelete ? this.state.selectedEntryToDelete.name : ''}<br/>
                    <div className="bin-align">
                        <i className="fas fa-trash-alt fa-3x"/>
                    </div>
                </CenteredModal>
            </>
        )
    }
}

MedicationsPlansPage.propTypes = {
    childrenProfiles: PropTypes.object,
    createMedication: PropTypes.func,
    deleteMedEventsEntries: PropTypes.func,
    deleteMedicationEvents: PropTypes.func,
    deleteMedicationPlan: PropTypes.func,
    fetchSelectedChildMedicationPlan: PropTypes.func,
    fetchSelectedUserMedicationPlan: PropTypes.func,
    formDisabled: PropTypes.object,
    getPlanStats: PropTypes.func,
    healthIssues: PropTypes.array,
    i18n: PropTypes.object,
    medication: PropTypes.object,
    medicationPlan: PropTypes.array,
    selectedHealthIssue: PropTypes.string,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    updateMedication: PropTypes.func
}


function mapStateToProps(state) {
    return {
        medicationPlan: state.medication.medicationPlan.entries,
        medication: state.medication,
        healthIssues: state.healthIssues.entries,
        selectedUser: state.selectedUser.data,
        i18n: state.language,
        settings: state.settings,
        childrenProfiles: state.children_profiles,
        formDisabled: state.formInteractions,
        loggedUser: state.userInfo.data,
    }
}

const mapDispatchToProps = {
    deleteMedEventsEntries,
    deleteMedicationEvents,
    deleteMedicationPlan,
    updateMedication,
    createMedication,
    getPlanStats,
    fetchSelectedUserMedicationPlan,
    fetchSelectedChildMedicationPlan
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicationsPlansPage)
import React, {createRef} from 'react';
import * as countries from 'i18n-iso-countries'
import BaseAnswer from "../BaseAnswer";
import AnswerType from "../../../models/answer-type/AnswerType";
import FormCheck from "../../input/FormCheck";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {Form} from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import './CountryAnswer.css';
import {$$} from "../../../../helpers/localization";

class CountryAnswer extends BaseAnswer {
    countries;
    searchRef;

    constructor(props) {
        super(props);
        this.searchRef = createRef();
        this.countries = countries.getNames(this.language, {select: 'official'});
        this.state = {countries: this.countries}
    }

    render() {
        const answer = this.props.answer;

        return (
            <span>
                <div className={`custom-control custom-${answer.selectionType}`}>
                    <FormCheck ref={this.inputRef} answer={answer} handleChange={this.onInputChange}/>
                    <label onClick={() => this.inputRef.current.click()}
                          className={'cursor-pointer mr-2 custom-control-label'}>
                        {`${answer.label[this.language]} ${this.getSuffix(answer)}`}
                    </label>
                </div>
                <Modal
                    show={this.state.open}
                    centered={true}
                    onHide={() => this.onCancelButtonPressed()}
                    className={'modal-dialog-scrollable'}
                    dialogClassName="modal-10w picker-modal">
                    <Modal.Header>
                        <Form className={'w-100'}>
                            <InputGroup>
                                <Form.Control
                                    ref={this.searchRef}
                                    onChange={this.filterCountries}
                                    as={'input'}
                                    type={'text'}
                                    onKeyPress={e => e.key === 'Enter' ? e.preventDefault() : null}
                                    placeholder={'Search Country'}/><InputGroup.Append>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </InputGroup.Text>
                            </InputGroup.Append>
                            </InputGroup>
                        </Form>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={'d-flex flex-column'}>
                            {Object.keys(this.state.countries).map(countryKey => {
                                return <button key={countryKey}
                                               className={'btn'}
                                               onClick={() => this.onCountrySelected(countryKey)}>
                                    {this.state.countries[countryKey]}
                                </button>
                            })}
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.onCancelButtonPressed()}>{$$("cancel_btn")}</Button>
                    </Modal.Footer>
                </Modal>
            </span>
        );
    };

    onCountrySelected(countryKey) {
        this.props.answer.result = this.countries[countryKey];
        this.selectAnswer();
        this.setState({open: false, countries: this.countries});
    };

    filterCountries = (event) => {
        const filterText = event.target.value;
        if (!filterText) {
            this.setState({countries: this.countries});
            return;
        }
        const filteredCountries = Object.keys(this.countries)
            .filter(countryKey => this.countries[countryKey].toLowerCase().includes(filterText.toLowerCase()))
            .reduce((acc, countryKey) => {
                acc[countryKey] = this.countries[countryKey];
                return acc;
            }, {});
        this.setState({countries: filteredCountries});
    };
}

class CountryAnswerFactory {
    static get type() {
        return AnswerType.COUNTRY;
    }

    static create(answer, updateAnswer, language) {
        return <CountryAnswer answer={answer} updateAnswer={updateAnswer} language={language}/>
    }
}

export default CountryAnswerFactory

import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createChild} from '../../actions/children_actions'
import {CreateChildForm} from './CreateChildForm'
import PropTypes from "prop-types";

class CreateChild extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                <CreateChildForm addNewChild={this.addNewChild} userInfo={this.props.userInfo}
                                 formDisabled={this.props.formDisabled}
                                 i18n = {this.props.i18n}
                                 settings = {this.props.settings}
                                 createChild={this.props.createChild} selectedUser={this.props.selectedUser}
                                 childrenProfiles={this.props.childrenProfiles}/>
            </div>
        )
    }
}

CreateChild.propTypes = {
    childrenProfiles: PropTypes.object,
    createChild: PropTypes.func,
    formDisabled: PropTypes.object,
    history: PropTypes.object,
    i18n: PropTypes.object,
    location:PropTypes.object,
    match: PropTypes.object,
    selectedUser: PropTypes.object,
    settings: PropTypes.any,
    userInfo:PropTypes.object
}

const mapStateToProps = (state) => ({
    selectedUser: state.selectedUser.data,
    userInfo: state.userInfo.data,
    i18n: state.language.selected,
    childrenProfiles: state.children_profiles,
    formDisabled: state.formInteractions,
    settings: state.settings.data

})


export default connect(mapStateToProps, {createChild})(CreateChild)
import React, {Component} from 'react';
import SelectionType from "../../models/selection-type/SelectionType";
import AnswerType from "../../models/answer-type/AnswerType";
import SelectionAnswerFactory from "./check-answer/SelectionAnswer";
import TextAnswerFactory from "./text-answer/TextAnswer";
import DateAnswerFactory from "./date-answer/DateAnswer";
import CountryAnswerFactory from "./country-answer/CountryAnswer";
import ChooserAnswerFactory from "./select-answer/ChooserAnswer";
import NumberAnswerFactory from "./number-answer/NumberAnswer";
import classNames from "classnames";

const RADIO = 'radio';
const CHECKBOX = 'checkbox';

class AnswerFactory extends Component {
    constructor(props) {
        super(props);
        this.props.answer.selectionType = this.props.question.selectionType === SelectionType.SINGLE ? RADIO : CHECKBOX;
    }

    static concreteFactories = {
        [AnswerType.SELECTION]: SelectionAnswerFactory,
        [AnswerType.TEXT]: TextAnswerFactory,
        [AnswerType.DATE]: DateAnswerFactory,
        [AnswerType.COUNTRY]: CountryAnswerFactory,
        [AnswerType.CHOOSER]: ChooserAnswerFactory,
        [AnswerType.NUMBER]: NumberAnswerFactory
    };

    render() {
        const answer = this.props.answer;
        const answerClassName = this.getAnswerClassName();

        return (
            <div className={answerClassName}>
                {AnswerFactory.concreteFactories[answer.type].create(answer, this.updateAnswer, this.props.language)}
            </div>
        );
    }

    updateAnswer = (updatedAnswer) => {
        this.props.updateAnswer(updatedAnswer);
    };

    getAnswerClassName() {
        return classNames({
            'ml-4': this.props.question?.answers?.some(answer => answer.topic != null)
        });
    };
}

export default AnswerFactory

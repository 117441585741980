import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    approvePermission,
    createTemporaryAccessLink,
    denyPermission,
    getAuthorizingPermissions,
    shareData
} from '../../actions/permissions_actions'
import PermissionList from './PermissionList'
import _ from 'underscore'
import {$$} from '../../helpers/localization'
import ShareData from './ShareData'
import {Alert} from 'react-bootstrap'
import {URL_UTILS} from '../../utils/urlUtils'
import {Collapse} from 'react-collapse'
import no_data from '../../resources/images/no_data.png'
import PropTypes from "prop-types";

class SharingPage extends Component {
    state = {
        collapse: true,
        collapse2: true,
        collapse3: true
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getAuthorizingPermissions();
    }


    /**
     * Sort temporary access links in descending order based on created date property
     * Set isFirst property for the first access link, so it can be highlighted
     *
     * @param {Array} tempAccessLinks - the list of temporary access links
     * @returns {Array} the sorted links
     */
    sortAndSetProperty = (tempAccessLinks) => {
        tempAccessLinks.sort(function (a, b) {
            return new Date(b.dateCreated) - new Date(a.dateCreated);
        });

        if (tempAccessLinks.length > 0) {
            tempAccessLinks[0].isFirst = true;
        }
        return tempAccessLinks;
    }

    /**
     * Create temporary access link for the Medrec-M monitor which can be shared with another user.
     *
     */
    createTemporaryAccessLink = () => {
        // eslint-disable-next-line no-undef
        const duration = parseInt(process.env.REACT_APP_ACCESS_LINK_DURATION);
        this.props.createTemporaryAccessLink(duration);
    }

    /**
     * Get error message based on error code
     *
     * @returns {string} error message to display
     */
    getErrorMessage = () => {
        const error = this.props.permissions.permissionOperationError;
        if (error) {
            return error.message;
        }
    }

    /**
     * We call this function to display an image and label in case there are no results
     * @param {*} label
     * @returns
     */
    getNoData = (label) => {
        return <div style={{display: 'inline-flex', alignItems: 'center', width: '100%'}}>
            <div style={{marginLeft: '16%'}}><img style={{width: "9rem", height: " 9rem"}} src={no_data}
                                                  alt={"No Data"}/></div>
            <div><span className="no-data-sharing-label">{label}</span></div>
        </div>
    }


    toggleCollapse = () => {
        this.setState({collapse: !this.state.collapse})
    }

    toggleCollapse2 = () => {
        this.setState({collapse2: !this.state.collapse2})
    }
    toggleCollapse3 = () => {
        this.setState({collapse3: !this.state.collapse3})
    }

    doesUserShare = (approvedPermissions) => {
        if (approvedPermissions.length === 0) {
            return true;
        } else return approvedPermissions.length === 1 &&
            approvedPermissions[0].authorizedUser === this.props.userInfo.id;
    }

    render() {
        const allPermissions = _.partition(this.props.permissions.entries, (p) => !!URL_UTILS.isUrl(p.authorizedUserFullName));
        const tempAccessLinks = this.sortAndSetProperty(allPermissions[0]);
        const permissions = _.partition(allPermissions[1], (p) => !!p.userApproved);
        const pendingPermissions = permissions[1];
        const approved = _.partition(permissions[0], (p) => !!p.authApproved);
        const approvedPermissions = approved[0];
        const pendingForAuthApproval = approved[1];

        const permissionOperationError = this.props.permissions.permissionOperationError ?
            (<div style={{"marginTop": "20px"}}>
                    <div className="">
                        <Alert variant="danger">
                            {this.getErrorMessage()}
                        </Alert>
                    </div>
                </div>
            )
            : '';
        return (
            <div className="wrapping-div">
                <h2>{$$("sharing_label")}</h2>

                <div className="sharing-container">
                    <ShareData
                        error={this.props.permissions.shareDataError}
                        createTemporaryAccessLink={this.props.createTemporaryAccessLink}
                        shareData={this.props.shareData}
                        success={this.props.permissions.shareDataSuccess}
                        pending={pendingForAuthApproval}
                        onDeny={this.props.denyPermission}/>
                    {permissionOperationError}


                    <div style={{"marginTop": "20px"}}>
                        <div id="accordion1" className="">
                            <div className="">
                                <div id="headingOne">
                                    <h5 className="mb-0 flex-space-between pointer" onClick={() => {
                                        this.toggleCollapse()
                                    }}>
                                        {$$('access_links_label')}
                                        <span className="mr-3">
                                            {!this.state.collapse &&
                                            <i className="fas fa-chevron-down" style={{"marginTop": "10px"}}/>}
                                            {this.state.collapse &&
                                            <i className="fas fa-chevron-up" style={{"marginTop": "10px"}}/>}
                                        </span>
                                    </h5>

                                </div>
                                <div aria-labelledby="headingOne" data-parent="#accordion1">
                                    {<Collapse isOpened={this.state.collapse}>
                                        {tempAccessLinks.length !== 0 && <div className="high-shadow-container">
                                            <h5 className=" temp-links-header flex-end">
                                                <button className="btn btn-primary"
                                                        onClick={this.createTemporaryAccessLink}>
                                                    {$$('new_link_label')}
                                                </button>
                                            </h5>
                                            <PermissionList permissions={tempAccessLinks}
                                                            onDeny={this.props.denyPermission}/>
                                        </div>}
                                    </Collapse>}
                                    {<Collapse isOpened={this.state.collapse}>
                                        {tempAccessLinks.length === 0 && <div className="high-shadow-container">
                                            <h5 className=" temp-links-header flex-end">
                                                <button className="btn btn-primary"
                                                        onClick={this.createTemporaryAccessLink}>
                                                    {$$('new_link_label')}
                                                </button>
                                            </h5>
                                            {this.getNoData($$("no_temporary_links_label_label"))}</div>}
                                    </Collapse>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{"marginTop": "20px"}}>
                        <div id="accordion2" className="">
                            <div className="">
                                <h5 className="mb-0 flex-space-between pointer" onClick={() => {
                                    this.toggleCollapse2()
                                }}>
                                    {$$('waiting_for_approval_list_label')}
                                    <span className="mr-3">
                                            {!this.state.collapse2 &&
                                            <i className="fas fa-chevron-down" style={{"marginTop": "10px"}}/>}
                                        {this.state.collapse2 &&
                                        <i className="fas fa-chevron-up" style={{"marginTop": "10px"}}/>}
                                        </span>
                                </h5>
                                {<Collapse isOpened={this.state.collapse2}>
                                    <div className="high-shadow-container">
                                        {pendingPermissions.length === 0 && this.getNoData($$("no_one_waiting_for_your_approval_label"))}
                                        <PermissionList permissions={pendingPermissions}
                                                        onDeny={this.props.denyPermission}
                                                        onApprove={this.props.approvePermission}/>
                                    </div>
                                </Collapse>}
                            </div>
                        </div>
                    </div>
                    <div style={{"marginTop": "20px"}}>
                        <div id="accordion3" className="">
                            <div className="">
                                <div className="" id="headingThree" data-toggle="collapse" data-target="#collapseThree"
                                     aria-expanded="true" aria-controls="collapseThree">
                                    <h5 className="mb-0 flex-space-between pointer" onClick={() => {
                                        this.toggleCollapse3()
                                    }}>
                                        {$$('shared_with_list_label')}
                                        <span className="mr-3">
                                            {!this.state.collapse3 &&
                                            <i className="fas fa-chevron-down" style={{"marginTop": "10px"}}/>}
                                            {this.state.collapse3 &&
                                            <i className="fas fa-chevron-up" style={{"marginTop": "10px"}}/>}
                                        </span>
                                    </h5>
                                </div>
                                {this.state.collapse3 && <div className="high-shadow-container">
                                    {this.doesUserShare(approvedPermissions) && this.getNoData($$("not_sharing_data_with_anyone_label"))}
                                    <PermissionList permissions={approvedPermissions} onDeny={this.props.denyPermission}
                                                    onApprove={this.props.approvePermission}/>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.userInfo.data,
        permissions: state.permissions
    }
}

SharingPage.propTypes = {
    approvePermission: PropTypes.func,
    createTemporaryAccessLink: PropTypes.func,
    denyPermission: PropTypes.func,
    getAuthorizingPermissions: PropTypes.func,
    history: PropTypes.object,
    location:  PropTypes.object,
    match: PropTypes.object,
    permissions: PropTypes.object,
    shareData: PropTypes.func,
    userInfo:  PropTypes.object
};

const mapDispatchToProps = {
    getAuthorizingPermissions,
    createTemporaryAccessLink,
    approvePermission,
    denyPermission,
    shareData
}

export default connect(mapStateToProps, mapDispatchToProps)(SharingPage)

import React, {Component, useEffect, useRef, useState} from 'react'
import moment from "moment";
import useVideoContext from "./hooks/useVideoContext/useVideoContext";
import {$$} from "../../helpers/localization";
import {useSelector} from "react-redux";
import {appointmentsService} from "../../service/appointments_service";

class Stopwatch extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {mins: 0, secs: 0}
    }

    componentDidMount() {
        this.setState({d: this.elapsedTime()});
        this.interval = this.getInterval();
    }

    getInterval() {
        return setInterval(this.getHandler(), 1000);
    }

    getHandler = () => {
        return () => {
            let elapsedTime = this.elapsedTime();
            this.setState({d: elapsedTime})
        };
    }

    elapsedTime = () => {
        let a = moment(new Date());
        let b = moment(this.props.start);
        let inp = a.diff(b);
        let diff = moment.utc(inp);
        let elapsedTime = diff > 1000 * 60 * 60 ? diff.format("H:mm:ss") : diff.format("mm:ss");
        return elapsedTime;
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        return <div className={"d-flex space-between"}>
            <div className="stopwatch">{this.state.d}</div>
            <RemainingTime room={this.props.room} videoMessage={this.props.message}/>
        </div>
    }

}

export default function StopwatchWrap() {
    const {room, videoMessage} = useVideoContext();

    return <Stopwatch start={room.started} message={videoMessage} room={room}/>
}

function RemainingTime({room, videoMessage}) {
    const appointments = useSelector((state) => state.userAppointments.entries);
    const [appointmentId, setAppointmentId] = useState();
    const [displayTime, setDisplayTime] = useState("");
    const [elapsedTime, setElapsedTime] = useState(0);

    const intervalRef = useRef();

    useEffect(() => {
        async function initialize() {
            if (room && videoMessage && videoMessage.appointment_id !== appointmentId) {
                if (!room.appointment) {
                    let appointment = appointments.find((a) => a.id === videoMessage.appointment_id);
                    if (!appointment) {
                        appointment = await appointmentsService.fetchAppointment(videoMessage.appointment_id);
                        if (!appointment) {
                            return;
                        }
                    }
                    if (room.appointment !== appointment) {
                        room.appointment = appointment;
                    }
                }
                let price = room.appointment.appointment_price;
                setAppointmentId(videoMessage.appointment_id);
                const updateTime = () => {
                    let shouldEndAt = moment(videoMessage.date_time + price.duration_mins * 60 * 1000);
                    let now = moment();
                    let delta = shouldEndAt.diff(now);
                    let absDelta = Math.abs(delta);
                    let diff = moment.utc(absDelta);
                    setElapsedTime(delta);
                    setDisplayTime(absDelta > 1000 * 60 * 60 ? diff.format("H:mm:ss") : diff.format("mm:ss"));
                }
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
                updateTime();
                intervalRef.current = setInterval(() => {
                    updateTime();
                }, 1000);
            }
        }

        initialize();

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
    }, [appointments, videoMessage]);

    if (!displayTime) {
        return <div className="time-remaining"></div>
    }

    let timeLeft = elapsedTime > 0 ? $$("time_remaining").replace('{time}', displayTime) : $$("time_over").replace('{time}', displayTime);
    return <div className="time-remaining">{timeLeft}</div>
}
import {combineReducers} from 'redux'
import {authentication} from './auth_reducers'
import {register} from './register_reducers'
import {language} from './language_reducers'
import {
    appointmentEncounter,
    charts,
    childToStandalone,
    dashboardCharts,
    latestUserMeasurements,
    medicalProfile,
    selectedUser,
    userInfo,
    userPassword,
    users
} from './users_reducers'
import {settings} from './settings_reducer'
import {
    latestSymptomsTypes,
    logSymptoms,
    nomenclature,
    symptoms
} from './symptoms_reducers'
import {logBook} from './logbook_reducers'
import {permissions} from './permissions_reducers'
import {metronic} from "../_metronic";
import {documents} from './documents_reducers'
import {chat} from './chat_reducers'
import {
    medication,
    medication_entries,
    medication_entry,
    medication_events
} from './medications_reducers'
import {importedPractitioner, practitioner, practitionerCapture} from './general_practitioner_reducer'
import {
    labDates,
    laboratories,
    labResults,
    labTestResults
} from './lab-results_reducers'
import {
    firstAvailable,
    hospitals,
    provider,
    providers,
    providerTimetable,
    specialties
} from './provider_reducers'
import {icd} from './icd_reducers'
import {
    userAppointment,
    userAppointments,
    preselectedAppointment
} from './appointments_reducers'
import {healthIssues} from "./health-issues_reducers";
import {children_profiles} from './children_profile_reducers'
import {manageProfileImage} from './profile_image_reducers'
import {
    cameraDevices,
    video
} from './video_reducer'
import {formInteractions} from "./form_interactions_reducer";
import {video_message} from "./video_message";

const reducer = combineReducers({
    builder: metronic.builder.reducer,
    authentication,
    register,
    language,
    selectedUser,
    logBook,
    charts,
    dashboardCharts,
    settings,
    nomenclature,
    symptoms,
    logSymptoms,
    latestSymptomsTypes,
    permissions,
    latestUserMeasurements,
    userInfo,
    userPassword,
    documents,
    medicalProfile,
    chat,
    users,
    medication,
    medication_events,
    medication_entries,
    medication_entry,
    practitioner,
    laboratories,
    labResults,
    hospitals,
    providers,
    provider,
    firstAvailable,
    providerTimetable,
    specialties,
    healthIssues,
    userAppointments,
    userAppointment,
    children_profiles,
    appointmentEncounter,
    icd,
    labDates,
    labTestResults,
    manageProfileImage,
    video,
    cameraDevices,
    childToStandalone,
    formInteractions,
    practitionerCapture,
    importedPractitioner,
    video_message,
    preselectedAppointment
});

export default reducer;

import {usersService} from "../service/users_service"
import {
    ALL_USERS_ERROR,
    ALL_USERS_FILTER_CHANGE,
    ALL_USERS_REQUEST_SENT,
    ALL_USERS_SUCCESS,
    CHANGE_CHARTS_FILTERS,
    CHRONIC_CONDITIONS,
    CLEAR_CHARTS,
    CLEAR_LATEST_MEASUREMENTS,
    CLEAR_MEDICAL_PROFILE,
    CLEAR_SELECTED_USER,
    CLEAR_UPDATE_USER_PROFILE_REQUEST,
    CLEAR_USER_PASSWORD,
    CLEAR_USERS,
    DUMMY,
    FETCH_APPOINTMENT_USER_ERROR,
    FETCH_APPOINTMENT_USER_REQUEST_SENT,
    FETCH_APPOINTMENT_USER_SUCCESS,
    FETCH_CHARTS_DATA_ERROR,
    FETCH_CHARTS_DATA_SUCCESS,
    FETCH_DASHBOARD_CHARTS_DATA_ERROR,
    FETCH_DASHBOARD_CHARTS_DATA_SUCCESS,
    FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR,
    FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS,
    FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR,
    FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS,
    GET_USER_PROFILE,
    GET_USER_PROFILE_ERROR,
    LOGIN_REQUEST_SUCCESS,
    PROFILE,
    REQUEST_SENT,
    SELECT_USER,
    SET_FORM_TO_DISABLED,
    SET_FORM_TO_ENABLED,
    UPDATE_MEDICAL_PROFILE_ERROR,
    UPDATE_MEDICAL_PROFILE_SUCCESS,
    UPDATE_USER_PASSWORD,
    UPDATE_USER_PASSWORD_ERROR,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_ERROR,
    UPGRADE_CHILD_ERROR,
    UPGRADE_CHILD_REQUEST_SENT,
    UPGRADE_CHILD_SUCCESS
} from "./actions"
import history from '../helpers/history'
import {Routes} from "../constants/routes";
import {fetchSelectedUserAppointments} from './appointments_actions'
import {getUserSettings} from "./settings_actions";
import {getChildren} from "./children_actions";
import {dateTimeUtils} from "../utils/dateTimeUtils";


/**
 * Get all chat users action, call the service and dispatch the appropriate reducer method
 *
 * @returns {function} dispatch function
 */
export function getAllUsers() {
    return (dispatch) => {
        dispatch({type: ALL_USERS_REQUEST_SENT});
        usersService.getAllUsers()
            .then((res) => {
                //remove duplicates
                let seen = {};
                res = res.filter(function (item) {
                    let id = item.id;
                    // eslint-disable-next-line no-prototype-builtins
                    return seen.hasOwnProperty(id) ? false : (seen[id] = true);
                });
                dispatch({type: ALL_USERS_SUCCESS, result: res});
            })
            .catch((err) => {
                dispatch({type: ALL_USERS_ERROR, result: err});
            })
    }
}

/**
 * Clear the user list action.
 *
 * @returns {function} dispatch function
 */
export function clearUsers() {
    return (dispatch) => {
        dispatch({type: CLEAR_USERS});
    }
}


/**
 * Change user filter action
 *
 * @param {string} filter - the given filter
 * @returns {function} dispatch function
 */
export function filterUsers(filter) {
    return (dispatch) => {
        dispatch({type: ALL_USERS_FILTER_CHANGE, filter: filter});
    }
}

/**
 * Get user info action.
 *
 * @returns {function} dispatch function
 */
export function getUserInfo(shouldSelectUser) {
    return (dispatch) => {
        usersService.getUserInfo()
            .then((res) => {
                dispatch(getUserSettings(res));
                if (res) {
                    dispatch({type: GET_USER_PROFILE, result: res});
                    if (shouldSelectUser) {
                        dispatch(selectUser(res, true));
                    }
                }
            })
            .catch((err) => {
                dispatch({type: GET_USER_PROFILE_ERROR, result: err});
            })
    }
}

/**
 * Upgrade a child user to a full profile
 *
 * @param {object} childInfo to upgrade
 * @param {object} parent the logged in user to change to on upgrade
 * @returns {function} dispatch function
 */

export function upgradeChildToFullUser(childInfo) {
    return (dispatch) => {
        dispatch({type: UPGRADE_CHILD_REQUEST_SENT});
        usersService.upgradeChildToFullUser(childInfo)
            .then((res) => {
                if (res) {
                    dispatch({type: UPGRADE_CHILD_SUCCESS, result: res});
                    dispatch(getChildren());
                }
            })
            .catch((err) => {
                dispatch({type: UPGRADE_CHILD_ERROR, result: err});
            })
    }

}


/**
 * Update user info action
 *
 * @param data {object} {phone, fullname} data object
 * @returns {function} dispatch function
 */
export function updateUserPhoneAndNames(data) {
    return (dispatch, getState) => {
        usersService.updateUserPhoneAndNames(data)
            .then((res) => {
                if (res) {
                    dispatch({type: UPDATE_USER_PROFILE, result: res});
                    const selectedUser = getState().selectedUser.data;
                    if (selectedUser && selectedUser.id === res.id) {
                        dispatch(selectUser(res, false));
                    }
                }
            })
            .catch((err) => {
                dispatch({type: UPDATE_USER_PROFILE_ERROR, result: err});
            })
    }
}


/**
 * Update user info action
 *
 * @param {object} userInfo the new user info data
 * @returns {function} dispatch function
 */
export function updateUserInfo(userInfo, parentId, stopRedirect) {
    return (dispatch, getState) => {
        dispatch({type: SET_FORM_TO_DISABLED, formName: PROFILE});
        usersService.updateUserInfo(userInfo)
            .then((res) => {
                if (res) {
                    if (res.id === parentId) {
                        dispatch({type: SET_FORM_TO_ENABLED});
                        dispatch({type: UPDATE_USER_PROFILE, result: res});
                    } else {
                        dispatch(getChildren());
                    }
                    const selectedUser = getState().selectedUser.data;
                    if (selectedUser && selectedUser.id === res.id) {
                        dispatch(selectUser(res, !stopRedirect));
                    }
                }
            })
            .catch((err) => {
                dispatch({type: SET_FORM_TO_ENABLED});
                dispatch({type: UPDATE_USER_PROFILE_ERROR, result: err});
            })
    }
}

export function clearUpdateRequest() {
    return (dispatch) => {
        dispatch({type: CLEAR_UPDATE_USER_PROFILE_REQUEST})
    }
}

/**
 * Update user password action
 *
 * @param {object} userPassword the new user password
 * @returns {function} dispatch function
 */
export function updateUserPassword(userPassword) {
    return (dispatch) => {
        dispatch({type: REQUEST_SENT});
        usersService.updateUserPassword(userPassword)
            .then((res) => {
                if (res) {
                    if (res.access_token && res.refresh_token) {
                        dispatch({type: LOGIN_REQUEST_SUCCESS, response: res});
                        dispatch({type: UPDATE_USER_PASSWORD, result: res});
                    }
                }
            })
            .catch((err) => {
                dispatch({type: UPDATE_USER_PASSWORD_ERROR, result: err});
            })
    }
}

/**
 * Clear the user password action.
 *
 * @returns {function} dispatch function
 */
export function clearUserPassword() {
    return (dispatch) => {
        dispatch({type: CLEAR_USER_PASSWORD});
    }
}

/**
 * Select user action, call the service and dispatch the appropiate reducer method
 *
 * @param {object} user - the selected user object
 * @returns {function} dispatch function
 */
export function selectUser(user, redirect) {
    return async (dispatch) => {
        dispatch({type: SELECT_USER, user: user});
        dispatch(fetchSelectedUserMedicalProfile(user.id));
        dispatch(getUserSettings(user));
        await dispatch(fetchSelectedUserAppointments(user.id, 0, (Date.now() + dateTimeUtils.YEAR_IN_MS)));
        //Redirect to medical record page
        if (redirect) {
            history.push(Routes.DASHBOARD);
        }
    }
}

/**
 * Clear the seleced user data action
 *
 * @returns {function} dispatch function
 */
export function clearSelectedUserData() {
    return (dispatch) => {
        dispatch({type: CLEAR_SELECTED_USER})
    }
}

/**
 * Fetch the medical profile for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @returns {function} dispatch function
 */
export function fetchSelectedUserMedicalProfile(userId) {
    return (dispatch, getState) => {
        return usersService.fetchSelectedUserMedicalProfile(userId).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR, result: err});
        })
    }
}

export function clearMedicalProfile() {
    return (dispatch) => {
        dispatch({type: CLEAR_MEDICAL_PROFILE});
    }
}

/**
 * Update the medical profile for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @returns {function} dispatch function
 */
export function updateUserMedicalProfile(userId, body) {
    return (dispatch, getState) => {
        dispatch({type: SET_FORM_TO_DISABLED, formName: CHRONIC_CONDITIONS});
        return usersService.updateUserMedicalProfile(userId, body).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: SET_FORM_TO_ENABLED});
                dispatch({type: UPDATE_MEDICAL_PROFILE_SUCCESS, result: res});
                dispatch(fetchSelectedUserMedicalProfile(userId));
            }
        }).catch((err) => {
            dispatch({type: SET_FORM_TO_ENABLED});
            dispatch({type: UPDATE_MEDICAL_PROFILE_ERROR, result: err});
        });
    }
}

/**
 * Fetch latest logged data for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @returns {function} dispatch function
 */
export function fetchSelectedUserLatestMeasurements(userId) {
    return (dispatch, getState) => {
        return usersService.fetchSelectedUserLatestMeasurements(userId).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res && res.length > 0) {
                dispatch({type: FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR, result: err});
        })
    }
}

export function clearLatestMeasurements() {
    return (dispatch) => {
        dispatch({type: CLEAR_LATEST_MEASUREMENTS});
    }
}

/**
 * Fetch the charts data for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @returns {function} dispatch function
 */
export function fetchChartsData(userId) {
    return (dispatch, getState) => {
        const state = getState();
        let params = {
            language: state.language.selected.lang,
            period: state.charts.filters.period
        };
        if (state.charts.filters.before_date_time) {
            params.before_date_time = state.charts.filters.before_date_time;
        }
        if (state.charts.filters.after_date_time) {
            params.after_date_time = state.charts.filters.after_date_time;
        }
        return usersService.fetchSelectedUserChartsData(userId, params).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_CHARTS_DATA_SUCCESS, result: res, userId: userId})
            }
        }).catch((err) => {
            dispatch({type: FETCH_CHARTS_DATA_ERROR, result: err});
        });
    }
}

export function clearChartsData() {
    return (dispatch) => {
        dispatch({type: CLEAR_CHARTS});
    }
}

/**
 * Fetch the latest charts data for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @returns {function} dispatch function
 */
export function fetchDashboardChartsData(userId) {
    return (dispatch, getState) => {
        const state = getState();
        let params = {
            language: state.language.selected.lang,
            period: 'WEEK'
        };
        return usersService.fetchSelectedUserChartsData(userId, params).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_DASHBOARD_CHARTS_DATA_SUCCESS, result: res, userId: userId})
            }
        }).catch((err) => {
            dispatch({type: FETCH_DASHBOARD_CHARTS_DATA_ERROR, result: err});
        });
    }
}

/**
 * Change the filters to apply to chart requests.
 *
 * @param {object} filters - the new set of filters
 * @returns {function} dispatch function
 */
export function changeChartsFilters(filters) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_CHARTS_FILTERS,
            period: filters.period,
            beforeDateTime: filters.beforeDateTime,
            afterDateTime: filters.afterDateTime
        });
    }
}

/**
 * Fetch the appointments for the user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchAppointmentEncounter(encounterId) {
    return (dispatch, getState) => {
        let params = {};
        dispatch({type: FETCH_APPOINTMENT_USER_REQUEST_SENT});
        return usersService.fetchAppointmentEncounter(encounterId, params).then((res) => {
            // eslint-disable-next-line no-undef
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_APPOINTMENT_USER_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_APPOINTMENT_USER_ERROR, result: err});
        });
    }
}

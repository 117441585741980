import React, {useState} from 'react';
import {Grid, Hidden, makeStyles, Theme, Typography} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import {Steps} from '../PreJoinScreens';
import ToggleAudioButton from '../../ToggleAudioButton';
import ToggleVideoButton from '../../ToggleVideoButton';

import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import {SERVER_URL} from "../../../../constants/api_paths";
import DeviceSelectionDialog from "../../DeviceSelectionDialog/DeviceSelectionDialog";
import SettingsIcon from "../../icons/SettingsIcon";
import {Button} from "react-bootstrap";
import {$$} from "../../../../helpers/localization";
import {chatService} from "../../../../service/chat_service";

const useStyles = makeStyles((theme: Theme) => ({
  settingsButton: {
    margin: '1.8em 0 0',
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      marginTop:'0',
      '& button': {
        margin: '0.5em 0',
      },
    },
    marginTop:'1rem'
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
    marginTop:"1rem"
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

function useAppState() {
  const [isFetching, setIsFetching] = useState(false);

  const getToken = async (name, roomName) => {
    return await f(roomName);
  }

  const f = async (appointmentId) => {
      const response = await chatService.joinAppointmentVideoRoom(appointmentId);
      return {token: response.token, messageId: response.message_id}
  }

  return {
    getToken, isFetching
  }
}

export default function DeviceSelectionScreen({ name, roomName, displayName, onCancel }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting, setMessageId } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
  const { removeLocalAudioTrack, removeLocalVideoTrack } = useVideoContext();

  const handleJoin = async () => {
    await getToken(name, roomName).then(({ token, messageId }) => {
      setMessageId(messageId);
      videoConnect(token);
    });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container alignItems="center" direction="column" style={{ height: '100%', justifyContent:"center" }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            {$$("entering_examination_room")}
          </Typography>
        </div>
      </Grid>
    );
  }

  if (deviceSettingsOpen) {
    return (
        <DeviceSelectionDialog open={true} onClose={()=>setDeviceSettingsOpen(false)}/>
    )
  }

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        {$$("enter_examination_room")}
        <span className="d-none">{roomName}</span>
      </Typography>

      <Grid container style={{justifyContent:"center"}}>
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={displayName} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton className="btn-secondary" disabled={disableButtons} />
              <ToggleVideoButton className="btn-secondary" disabled={disableButtons} />
            </Hidden>
            <Button
                onClick={() => setDeviceSettingsOpen(true)}
                className="btn-secondary"
            >
              <span className="mr-2"><SettingsIcon /></span> {$$("settings_label")}
            </Button>
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid container direction="column" style={{ height: '100%', justifyContent:"space-between" }}>
            <div>
              <Hidden smDown>
                <ToggleAudioButton className="w-100 btn-secondary mb-2" disabled={disableButtons} />
                <ToggleVideoButton className="w-100 btn-secondary mb-2" disabled={disableButtons} />
              </Hidden>
            </div>
            <div className={classes.joinButtons}>
              <Button variant={"secondary"} onClick={() => {
                    removeLocalAudioTrack();
                    removeLocalVideoTrack();
                    onCancel(); }}>
                {$$("cancel_btn")}
              </Button>
              <Button
                variant={"success"}
                onClick={handleJoin}
                disabled={disableButtons}
              >
                {$$("join_now")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

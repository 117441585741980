import React, {Component} from "react";
import ReactHorizontalDatePicker from "../shared/ReactHorizontalDatePickerV2";
import {calculateMedIntake} from '../../utils/medicationsUtils';
import {$$} from '../../helpers/localization'
import DatePicker from "react-datepicker";
import {bg, enGB} from 'date-fns/locale/index'
import {format} from 'date-fns'
import _ from 'underscore'
import {
    clearMed,
    clearMedEvents,
    createMedicationEvent,
    fetchSelectedUserMedicationPlan,
    getMedEntriesForMedEvents,
    getMedEntriesForMedEventsChild,
    getMedicationEvents,
    getMedicationEventsForChildren,
    updateMedicationEvent
} from '../../actions/medications_actions';
import {createLogbookEntry, updateLogbookEntry} from "../../actions/logbook_actions";
import moment from 'moment'
import {connect} from 'react-redux'
import no_data from '../../resources/images/no_data.png'
import MedicationEntry from "./MedicationEntry";
import TakeRescheduleModal from '../medications/TakeRescheduleModal';
import TakeMedication from './TakeMedication'
import {infoUtils} from '../../utils/infoUtils'
import {v4 as uuid} from 'uuid'
import PropTypes from "prop-types";
import {dateTimeUtils} from "../../utils/dateTimeUtils";


class MedicationsPage extends Component {

    constructor(props) {
        super(props);
        this.index = 0;
        this.state = {
            chosenDate: new Date(),
            scroll: true,
            medicationsIntakeSchedule: [],
            selectedOption: 'my-medications',
            datePicked: new Date(),
            week: new Date(),
            showTimePickerModal: false,
            takeMedicationModal: {
                show: false,
            },
            medIntakeHour: null,
            planName: '',
            dateTimePlanned: '',
            medPlan: null,
            takingOrRescheduling: null,
            showTakeMed: true,
            status: '',
            medicationEvent: null,
            medicationEntry: null,
            medicationToTake: null,
            whenNeeded: false,
            triggeredFromBtn: false,
            loaded: false
        };

        this.onChangeValue = this.onChangeValue.bind(this);
    }

    componentDidMount() {
        /*this.props.fetchSelectedUserMedicationPlan(this.props.selectedUser.id).then(() => {
            this.setState({loaded: true})
        });*/
        this.fetchMedicationEvents().then(() => {
            this.setState({loaded: true})
        });
    }

    componentWillUnmount() {
        this.props.clearMed();
        this.props.clearMedEvents();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.medication_events.entries !== this.props.medication_events.entries || prevState.chosenDate !== this.state.chosenDate || this.props.medicationPlan !== prevProps.medicationPlan) {
            let medicationPlans = this.getMedicationPlans()
            let medScheduleCalculated = calculateMedIntake(new Date(this.state.chosenDate), medicationPlans, this.props.medication_events.entries);
            this.setState({medicationsIntakeSchedule: medScheduleCalculated});
        }
    }

    refetchMedicationEventsAndEntries = () => {
        this.setState({
            showTimePickerModal: false,
            takeMedicationModal: {
                show: false,
            },
            medIntakeHour: null,
            planName: '',
            dateTimePlanned: '',
            medPlan: null,
            takingOrRescheduling: null,
            showTakeMed: true,
            status: '',
            medicationEvent: null,
            medicationEntry: null,
            medicationToTake: null,
            whenNeeded: false,
        })
        this.props.clearMed();
        this.props.clearMedEvents();
        this.setState({loaded: false})
        this.fetchMedicationEvents().then(() => {
            this.setState({loaded: true})
        });
    }

    takeMedication = (medicationToTake) => {
        this.setState({
            takingOrRescheduling: 'TAKE',
            takeMedicationModal: {show: false},
            showTimePickerModal: true,
            medicationToTake: medicationToTake
        })
    }
    rescheduleMedication = (medicationToTake) => {
        this.setState({
            takingOrRescheduling: 'RESCHEDULE',
            takeMedicationModal: {show: false},
            showTimePickerModal: true,
            medicationToTake: medicationToTake
        })
    }

    showTakeMedication = () => {
        this.setState({
            showTakeMed: true
        })
    }

    hideTakeMedication = () => {
        this.setState({
            showTakeMed: false
        })
    }

    /**
     * Use takingType and dateTime to create events/entries
     */
    timePickedForTakeAndReschedule = async (takingType, dateTime, isWhenNeeded) => {
        let id = uuid();
        let user_id = this.props.medicationPlan.find(p => p.id === this.state.medicationToTake.id).user_id;
        let form = {
            id: this.state.medicationToTake.selectedEvent != null ? this.state.medicationToTake.selectedEvent.id : id,
            created_timestamp: this.state.medicationToTake.selectedEvent != null ? this.state.medicationToTake.selectedEvent.created_timestamp : moment().valueOf(),
            medicationPlanId: this.state.medicationToTake.id,
            notes: this.state.medicationToTake.notes,
            planName: this.state.medicationToTake.name,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            updated_timestamp: moment().valueOf(),
            user_id: user_id,
            dateTimePlanned: this.state.medicationToTake.selectedEvent != null ? this.state.medicationToTake.selectedEvent.dateTimePlanned : (isWhenNeeded ? dateTime : this.state.medicationToTake.dateTimePlanned),
        }

        if (takingType !== "RESCHEDULE") {
            form = {
                ...form,
                status: "CONFIRMED",
                dateTimeScheduled: this.state.medicationToTake.selectedEvent != null ? this.state.medicationToTake.selectedEvent.dateTimeScheduled : 0,
            }

            await this.state.medicationToTake.selectedEvent != null ? this.props.updateMedicationEvent(form, user_id) : this.props.createMedicationEvent(form, user_id);

            let medicationEntryForm = {
                id: this.state.medicationToTake.selectedEntry != null ? this.state.medicationToTake.selectedEntry.id : null,
                entry_type: "MEDICATION",
                name: this.state.medicationToTake.name,
                unit: this.state.medicationToTake.unit,
                dose: this.state.medicationToTake.dose,
                notes: this.state.medicationToTake.notes,
                user_id: user_id,
                deleted: false,
                date_time: dateTime,
                health_issues_ids: this.state.medicationToTake.health_issues_ids,
                created_timestamp: this.state.medicationToTake.selectedEntry != null ? this.state.medicationToTake.selectedEntry.created_timestamp : moment().valueOf(),
                updated_timestamp: moment().valueOf(),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                medicationEventId: this.state.medicationToTake.selectedEvent != null ? this.state.medicationToTake.selectedEvent.id : id,
            }

            await this.state.medicationToTake.selectedEntry != null ? this.props.updateLogbookEntry(medicationEntryForm, user_id, 'MEDICATION') : this.props.createLogbookEntry(medicationEntryForm, user_id, 'MEDICATION')
            this.refetchMedicationEventsAndEntries();

        } else if (form.dateTimePlanned !== dateTime) {
            form = {
                ...form,
                status: "UNKNOWN",
                dateTimeScheduled: dateTime,
            }

            await this.state.medicationToTake.selectedEvent != null ? this.props.updateMedicationEvent(form, user_id) : this.props.createMedicationEvent(form, user_id);
            this.refetchMedicationEventsAndEntries();
        }

        this.setState({showTimePickerModal: false, medicationToTake: null, medicationEvent: null})
    }


    setMedIntakeHour = (hour) => {
        this.setState({medIntakeHour: hour})
    }

    hideTakeMedicationModal = () => {
        this.setState({
            takeMedicationModal: {
                show: false,
            }
        });
    }

    /**
     * Show add/update modal
     *
     * @param {entry} object - The entry to modify or null
     * @param {boolean} whenNeeded
     */
    showTakeMedicationModal = (object, whenNeeded) => {
        this.setState({
            takeMedicationModal: {
                show: true,
            },
            planName: object.planName,
            dateTimePlanned: object.dateTimePlanned,
            medicationEvent: object.medicationEvent,
            medicationEntry: object.medicationEntry,
            whenNeeded: whenNeeded
        });
    }

    setMedicationPlan = (medicationPlan) => {
        this.setState({
            medPlan: medicationPlan
        })
    }

    getMedicationPlans() {
        return this.state.selectedOption === 'my-medications'
            ? this.props.medicationPlan.filter(medPlan => medPlan.user_id === this.props.selectedUser.id)
            : this.props.medicationPlan;
    }

    /**
     * Called when we select one of the radio buttons. It changes the medications view (all/my medications)
     * @param {*} event
     */
    onChangeValue(event) {
        this.setState({
                selectedOption: event.target.value,
                loaded: false
            },
            () => this.fetchMedicationEvents().then(() => {
                    this.setState({loaded: true})
                }
            )
        );
    }

    onSelectDate = async (date, triggeredFromBtn) => {
        this.setState({
            chosenDate: date,
            triggeredFromBtn
        });
        await this.props.clearMedEvents();
        this.props.clearMed();
        this.fetchMedicationEvents()
    };

    setHoursMinutesSecondsToZero(date) {
        date.setHours('00');
        date.setMinutes('00');
        date.setSeconds('00');
        date.setMilliseconds('00');
        return date;
    }

    onDateClick = (date) => {
        this.setState({
            datePicked: date,
            chosenDate: date,
            triggeredFromBtn: true
        })
    }

    fetchMedicationEvents = async () => {
        let today = new Date(this.state.chosenDate);
        today = this.setHoursMinutesSecondsToZero(today)
        let tomorrow = new Date(this.state.chosenDate);
        tomorrow.setDate(today.getDate() + 1);
        tomorrow = this.setHoursMinutesSecondsToZero(tomorrow)
        let params = {
            size: 100,
            before_date_time: moment(tomorrow).valueOf(),
            after_date_time: moment(today).valueOf()
        }
        await this.props.getMedicationEvents(this.props.selectedUser.id, params);

        let body;
        let eventIdss = [];
        this.props.medication_events.entries.map(event => {
            if (this.props.selectedUser.id === event.user_id) {
                eventIdss.push(event.id);
            }
        })
        body = {
            eventIds: eventIdss
        };
        await this.props.getMedEntriesForMedEvents(this.props.selectedUser.id, body)
        if (this.state.selectedOption === 'all-medications') {
            if (this.props.selectedUser.id === this.props.loggedUser.id) {
                this.props.childrenProfiles.entries.map(childProfile => {
                    if (childProfile.child_accepted === true) {
                        this.getMedEntriesForEvents(childProfile, params);
                    }
                })
            }
        }
    }

    /**
     * Fetches the medication entries for medication events for the connected profile
     * @param {*} childProfile
     * @param {*} params
     */
    getMedEntriesForEvents = async (childProfile, params) => {
        let body;
        let eventIdss = [];

        await this.props.getMedicationEventsForChildren(childProfile.id, params);
        this.props.medication_events.entries.map(event => {
            if (childProfile.id === event.user_id) {
                eventIdss.push(event.id);
            }
        })
        body = {
            eventIds: eventIdss
        }
        this.props.getMedEntriesForMedEventsChild(childProfile.id, body)
    }

    /**
     * This function makes posible to stop the scroll once selected
     */
    onLoadComplete = () => {
        this.setState({scroll: false});
    }

    dateChanged = (date) => {
        this.setState({
            chosenDate: date
        })
    }

    weekChangedScroller = (newWeek) => {
        this.setState({
            week: newWeek,
        })
    }

    getMedicationEntriesForPlan = (medPlanId, entryHour) => {
        let medEventsForPlan = this.props.medication_events.entries.filter((event) => {
            return event.medicationPlanId === medPlanId && (entryHour === moment(event.dateTimePlanned).format("HH:mm") || (entryHour === moment(event.dateTimeScheduled).format("HH:mm")))
        })

        let medEntriesForPlans = [];
        this.props.medication_entries.entries.map((entry) => {
            let takeThisEntry = false;
            medEventsForPlan.map((medEvent) => {
                if (medEvent.id === entry.medicationEventId)
                    takeThisEntry = true;
            })

            if (takeThisEntry) {
                medEntriesForPlans.push(entry)
            }
        })
        return medEntriesForPlans;
    }

    getEventsForPlan = (medPlanId, entryHour) => {
        return this.props.medication_events.entries.filter((event) => {
            return event.medicationPlanId === medPlanId && (entryHour === moment(event.dateTimePlanned).format("HH:mm") || (entryHour === moment(event.dateTimeScheduled).format("HH:mm")))
        })
    }

    /**
     * This function finds all the med plans whose ids are in the entry array of medPlanIds. Then returns the medication name, the amount to be taken, and plan notes for each of the plans.
     * @param {*} entry - one entry from medicationsIntakeSchedule array which has 2 properties : {hour:'', medPlanIds:[]}
     * @returns
     */
    getOtherMedicationPlans = (entry) => {
        let count = 0
        let medPlan = entry.medPlanIds[0];
        let samePlanCount = -1;
        let medPlansLength = entry.medPlanIds.length;
        let medPlansNames = entry.medPlanIds.map(medPlanId => {
            if (medPlanId === medPlan) {
                samePlanCount++;
            } else {
                medPlan = medPlanId;
                samePlanCount = -1;
            }
            return this.props.medicationPlan.map(medicationPlan => {
                if (medicationPlan.id === medPlanId) {
                    count++;
                    return <MedicationEntry
                        chosenDate={new Date(this.state.chosenDate)}
                        hour={entry.hour}
                        children_profiles={this.props.children_profiles}
                        loggedUser = {this.props.loggedUser}
                        key={++this.index}
                        planCount={samePlanCount}
                        medicationPlan={medicationPlan}
                        medicationEntriesForEvents={this.getMedicationEntriesForPlan(medPlanId, entry.hour)}
                        medicationEvents={this.getEventsForPlan(medPlanId, entry.hour)}
                        isLast={medPlansLength === count}
                        showTakeMedicationModal={this.showTakeMedicationModal}
                        setMedicationPlan={this.setMedicationPlan}
                        setMedIntakeHour={this.setMedIntakeHour}
                        showTakeMedication={this.showTakeMedication}
                    />
                }
            })
        })
        return <div>
            {medPlansNames}
        </div>
    }

    dateAndHourToTimeStamp = (date, time) => {
        let dateToReturn = new Date(date);
        let hour = time ? time.split(':')[0] : moment().hour();
        let minute = time ? time.split(':')[1] : moment().minutes();
        dateToReturn.setHours(hour);
        dateToReturn.setMinutes(minute);
        return moment(dateToReturn).valueOf();
    }

    /**
     * This function returns the medication name, the amount to be taken, and plan notes for 'WHEN_NEEDED' medication plans.
     */
    getWhenNeededMedPlans = () => {
        let dateMillisec = moment(this.setHoursMinutesSecondsToZero(new Date(this.state.chosenDate))).valueOf();
        let count = 0;
        let whenNeededPlansLength = 0;
        let medicationPlans = this.getMedicationPlans()

        medicationPlans.map(medicationPlan => {
            let starts = moment(this.setHoursMinutesSecondsToZero(new Date(medicationPlan.starts))).valueOf();

            if (medicationPlan.scheduleType === 'WHEN_NEEDED' && dateMillisec >= starts && !medicationPlan.inactive) {
                whenNeededPlansLength++;
            }
        })

        let whenNeededMeds = medicationPlans.map(medicationPlan => {
            let starts = moment(this.setHoursMinutesSecondsToZero(new Date(medicationPlan.starts))).valueOf();

            if (medicationPlan.scheduleType === 'WHEN_NEEDED' && dateMillisec >= starts && !medicationPlan.inactive) {
                count++;
                return <MedicationEntry
                    chosenDate={this.state.chosenDate}
                    key={++this.index}
                    medicationPlan={medicationPlan}
                    medicationEntriesForEvents={this.props.medication_events}
                    isLast={whenNeededPlansLength === count}
                    isWhenNeeded={true}
                    children_profiles={this.props.children_profiles}
                    loggedUser = {this.props.loggedUser}
                    setMedicationPlan={this.setMedicationPlan}
                    showTakeMedicationModal={this.showTakeMedicationModal}
                    setMedIntakeHour={this.setMedIntakeHour}
                    showTakeMedication={this.showTakeMedication}
                />
            }
        })
        return whenNeededPlansLength !== 0 ? whenNeededMeds : null;
    }


    /**
     * This function returns the hours and the specific medications to be taken in those hours and the 'WHEN_NEEDED' medications
     * @returns
     */
    getMedicationsSchedule = () => {
        let noMedications = {
            imgClass: 'no-entry-image',
            objClass: 'no-data-object-documents',
            primaryLabelClass: 'no-clinicians-primary-label',
            src: no_data,
            primaryLabel: $$('no_medications'),
        }
        let whenNeededMeds = this.getWhenNeededMedPlans();
        let schedule = this.state.medicationsIntakeSchedule && this.state.medicationsIntakeSchedule.sort((a, b) => (a.hour > b.hour) ? 1 : -1).map(entry => {
            return <div key={++this.index} className="medication-row medication-schedule-container">
                <div className="med-intake-hour">
                    <i className="far fa-clock mr-1"/>
                    {entry.hour}
                </div>
                {this.getOtherMedicationPlans(entry)}
            </div>
        })
        return <>{(whenNeededMeds ? schedule.length + whenNeededMeds.length !== 0 : schedule.length !== 0) ?
            <div className="medication-row">
                {schedule}
                {whenNeededMeds != null && <div className="medication-row medication-schedule-container">
                    <div className="med-intake-hour">{$$('when_needed')}</div>
                    <div>
                        {whenNeededMeds}
                    </div>
                </div>}
            </div>
            :
            infoUtils.noData(noMedications)
        }</>
    }


    render() {
        return (
            <>
                {!this.state.loaded && <div className={"med-search-loader"}/>}
                {this.state.loaded && <>
                    <div className='my-2' style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div className="flex-space-between" style={{alignItems: "center", width: "28.5rem"}}>
                            <label className="scroll-head btn btn-secondary">
                                <div style={{position: "relative"}}>
                                    <DatePicker
                                        selected={this.state.datePicked}
                                        onChange={this.onDateClick}
                                        className="date-picker-medications"
                                        dateFormat="MMMM yyyy"
                                    />
                                </div>
                                <i className="fa fa-calendar-alt medication-calendar-icon"/>
                            </label>
                            <div>{format(new Date(this.state.week), 'MMMM yyyy', {locale: _.contains(['en', 'sq'], this.props.i18n.selected.lang) ? enGB : bg})}</div>
                            <div className='today-btn'>
                                <button className='btn btn-secondary' onClick={() => this.onDateClick(new Date())}>
                                    {$$('select_today_label')}
                                </button>
                            </div>
                        </div>
                        <ReactHorizontalDatePicker
                            selectedDay={this.onSelectDate}
                            enableScroll={true}
                            enableDays={7}
                            previousDays={true}
                            i18n={this.props.i18n.selected.lang}
                            chosenDate={this.state.chosenDate}
                            dateChanged={this.dateChanged}
                            onLoadComplete={this.onLoadComplete}
                            triggeredFromBtn={this.state.triggeredFromBtn}
                            pickedCentralDate={this.state.datePicked}
                            weekChanged={this.weekChangedScroller}
                        />
                        <div
                            className="current-date-medications">{format(new Date(this.state.chosenDate), 'dd MMMM yyyy', {locale: _.contains(['en', 'sq'], this.props.i18n.selected.lang) ? enGB : bg})}</div>
                    </div>
                    {this.props.selectedUser.id === this.props.loggedUser.id &&
                        <div className='my-4 d-flex radio-container-medications-intake'>
                            <label className='medication-radiobutton custom-control custom-radio mr-3'>
                                <input type="radio" className="custom-control-input" style={{display: "none"}}
                                       value="my-medications" name="medication-filter"
                                       checked={this.state.selectedOption === 'my-medications'}
                                       onChange={this.onChangeValue}/><span
                                className="ml-1 custom-control-label">{$$('my_medications')}</span>
                            </label>
                            <label className='medication-radiobutton custom-control custom-radio'>
                                <input type="radio" className="custom-control-input" value="all-medications"
                                       name="medication-filter"
                                       checked={this.state.selectedOption === 'all-medications'}
                                       onChange={this.onChangeValue}/><span
                                className="ml-1 custom-control-label">{$$('all_medications')}</span>
                            </label>
                        </div>}
                    <div className="text-center" style={{borderRadius: '0.5rem'}}>
                        <div>
                            {this.getMedicationsSchedule()}
                        </div>
                    </div>
                    <TakeRescheduleModal
                        type={this.state.takingOrRescheduling}
                        settings={this.props.settings}
                        show={this.state.showTimePickerModal}
                        i18n={this.props.i18n}
                        date={this.state.whenNeeded ? dateTimeUtils.currentTimestampWithoutSeconds() : this.state.chosenDate}
                        timePicked={this.timePickedForTakeAndReschedule}
                        time={(this.state.medIntakeHour != undefined) ? this.dateAndHourToTimeStamp(this.state.chosenDate, this.state.medIntakeHour) : dateTimeUtils.currentTimestampWithoutSeconds()}
                        whenNeeded={this.state.whenNeeded}
                        onHide={() => {
                            this.setState({
                                showTimePickerModal: false, takeMedicationModal: {
                                    show: true
                                }
                            })
                        }}
                    />
                    {this.state.takeMedicationModal.show &&
                        <TakeMedication
                            takeMedication={this.takeMedication}
                            rescheduleMedication={this.rescheduleMedication}
                            show={this.state.takeMedicationModal.show}
                            onHide={this.hideTakeMedicationModal}
                            time={this.dateAndHourToTimeStamp(this.state.chosenDate, this.state.medIntakeHour)}
                            selectedUser={this.props.selectedUser}
                            planName={this.state.planName}
                            dateTimePlanned={this.state.dateTimePlanned}
                            medicationPlan={this.state.medPlan}
                            showTakeMedication={this.showTakeMedication}
                            hideTakeMedication={this.hideTakeMedication}
                            showTakeMed={this.state.showTakeMed}
                            refetchMedicationEvents={this.refetchMedicationEventsAndEntries}
                            medicationEvent={this.state.medicationEvent}
                            medicationEntry={this.state.medicationEntry}
                            whenNeeded={this.state.whenNeeded}
                        />}
                </>}
            </>
        )
    }
}

MedicationsPage.propTypes = {
    childrenProfiles: PropTypes.object,
    clearMed: PropTypes.func,
    clearMedEvents: PropTypes.func,
    medication_events: PropTypes.any,
    loggedUser: PropTypes.any,
    selectedUser: PropTypes.any,
    medication_entries: PropTypes.any,
    children_profiles: PropTypes.any,
    medicationPlan: PropTypes.any,
    i18n: PropTypes.object,
    settings: PropTypes.any,
    createLogbookEntry: PropTypes.func,
    getMedEntriesForMedEventsChild: PropTypes.func,
    getMedicationEvents: PropTypes.func,
    updateMedicationEvent: PropTypes.func,
    updateLogbookEntry: PropTypes.func,
    getMedicationEventsForChildren: PropTypes.func,
    createMedicationEvent: PropTypes.func,
    getMedEntriesForMedEvents: PropTypes.func,
    fetchSelectedUserMedicationPlan: PropTypes.func,
}

function mapStateToProps(state) {
    return {
        children_profiles: state.children_profiles.entries,
        selectedUser: state.selectedUser.data,
        i18n: state.language,
        medication_events: state.medication_events.medicationEvent,
        childrenProfiles: state.children_profiles,
        medication_entries: state.medication_entries.medicationEntry,
        settings: state.settings,
        loggedUser: state.userInfo.data
    }
}

const mapDispatchToProps = {
    getMedicationEvents,
    getMedicationEventsForChildren,
    getMedEntriesForMedEvents,
    getMedEntriesForMedEventsChild,
    clearMed,
    clearMedEvents,
    createMedicationEvent,
    updateMedicationEvent,
    createLogbookEntry,
    updateLogbookEntry,
    fetchSelectedUserMedicationPlan
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicationsPage)
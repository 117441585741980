import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {CONVERTER} from '../../utils/converter'
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";
import PropTypes from "prop-types";


export class LogbookPeakFlow extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
    }

    render() {

        return <div className="logbook-entry low-shadow-container condition-card">
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <div className="d-flex">
                        <div className="medrec-grey-2 hide-on-big">
                            <i className="fa fa-clock"/>
                            <span
                                className="time-for-symptoms medrec-blue-1"> {CONVERTER.getEntryDateTimeFormat(this.props.entry.date_time)}</span>
                        </div>
                    </div>
                    <div className="logbook-entry-title">
                        {$$(this.props.entry.entry_type.toLowerCase())}
                    </div>
                    <div style={{alignItems: "baseline"}}>
                        <span className="vitals-value">{this.props.entry.peak_flow}</span>
                        <span className="vitals-unit">{$$("liters_per_minute")}
                            {this.props.entry.peak_flow_symptoms && <span className="lmp-with-symptoms font-size-1rem">{$$("peak_flow_with_symptoms")}</span>}
                        </span>
                    </div>
                    {this.props.entry.notes && !this.props.entry.hideNotes ?
                        <div className="mt-2">{$$("notes")}: {this.props.entry.notes}</div> : ""}
                </div>
                <div className="col-md-6 col-sm-12">{this.props.getHealthIssueList(this.props.entry)}</div>
            </div>

            <div className="row" style={{justifyContent: "flex-end", paddingRight: "10px"}}>
                <DownloadEditDeleteButtons
                    handleEditClick={() => this.props.onShowEditModal(this.props.entry)}
                    handleDeleteClick={() => this.props.onShowModal(this.props.entry)}
                />
            </div>
        </div>
    }
}

LogbookPeakFlow.propTypes = {
    entry: PropTypes.object,
    getHealthIssueList: PropTypes.func,
    onShowEditModal: PropTypes.func,
    onShowModal: PropTypes.func,
    unit: PropTypes.string
};

export default LogbookPeakFlow;

import React from "react";
import {Route, Switch} from "react-router-dom";
import "../../_metronic/_assets/sass/pages/login/login-1.scss";
import RegisterPage from "./../register/RegisterPage";
import LoginPage from './../login/LoginPage'
import LanguageSelector from "../layout/LanguageSelector";
import {connect} from "react-redux";
import {changeLanguage} from "../../actions/language_actions";
import {$$} from "../../helpers/localization";
import {version, internal_version} from "../../../package"
import ProfilePhotoUpload from '../register/ProfilePhotoUpload'
import PropTypes from "prop-types";
import {localiseURL} from "../../utils/urlUtils";
import {PRIVACY_POLICY_URL, TERMS_OF_USE_URL} from "../../constants/api_paths";
import ClinicianPage from "../clinicians/ClinicianPage";
import classNames from "classnames"
import {Routes} from "../../constants/routes";
import CliniciansPage from "../clinicians/CliniciansPage";
import PublicHospitalPage from "../clinicians/PublicHospitalPage";

class AuthPage extends React.Component {

    state =
        {
            showSuccessPage: true,
            currentYear: new Date().getFullYear()
        }

    render() {

        let url = localiseURL(PRIVACY_POLICY_URL)
        let urlTermsAndConditions = localiseURL(TERMS_OF_USE_URL);

        return (
            <>
                <div className="full-height-login kt-grid kt-grid--ver kt-grid--root">
                    <div
                        id="kt_login"
                        className="full-height-login  kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">

                        <div
                            className="full-height-login kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">

                            {(this.props.location.pathname === "/register") ?

                                <div
                                    className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-register__success__aside full-screen-reg">
                                    <ProfilePhotoUpload
                                        i18n={this.props.i18n}
                                    />
                                    <div className="main-logo-label">{$$("title")}</div>
                                    <span style={{
                                        position: "absolute",
                                        "right": "21px",
                                        "top": "36px"
                                    }}><LanguageSelector className="show language-home hide-min-1024"
                                                         changeLanguage={this.props.changeLanguage}/></span>
                                </div>
                                :
                                <div
                                    className='full-height-login kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside'>
                                    <div className="kt-grid__item">
                                        <div className="main-logo-label">{$$("title")}</div>
                                        <span style={{
                                            position: "absolute",
                                            "right": "21px",
                                            "top": "36px"
                                        }}><LanguageSelector className="show language-home hide-min-1024"
                                                             changeLanguage={this.props.changeLanguage}/></span>
                                    </div>
                                </div>
                            }
                            <div
                                className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 login-form-size  kt-login__wrapper">
                                <div className="kt-login__head">
                                    <div className="flex-container flex-row-reverse">
                                        <LanguageSelector className={classNames("show language-home hide-max-1024")}
                                                          changeLanguage={this.props.changeLanguage}/>
                                    </div>
                                </div>
                                <Switch>
                                    <Route path={Routes.LOGIN} component={LoginPage}/>
                                    <Route path={Routes.REGISTER} component={RegisterPage}/>
                                    <Route path={Routes.CLINICIAN_NOT_LOGGED_IN} component={ClinicianPage}/>
                                    <Route path={Routes.CLINIC_NOT_LOGGED_IN} component={PublicHospitalPage}/>

                                </Switch>
                                <div className="text-center">
                                    <div>
                                        <span className="p-2"><a target="_blank"
                                                                 rel="noopener noreferrer"
                                                                 className="kt-footer__menu-link kt-link"
                                                                 href={urlTermsAndConditions}>{$$('terms_and_conditions')}</a></span>
                                        <span className="p-2"><a target="_blank"
                                                                 rel="noopener noreferrer"
                                                                 className="kt-footer__menu-link kt-link"
                                                                 href={url}>{$$('privacy_policy')}</a></span>
                                    </div>
                                    <div className="pt-3">
                                        <h6>
                                            © Sirma 1992-{this.state.currentYear}
                                        </h6>
                                        <div className="kt-login__copyright">
                                            <div className="small">{$$("version")} {version} ({internal_version})</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

AuthPage.propTypes = {
    changeLanguage: PropTypes.func,
    history: PropTypes.object,
    i18n: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object
}

function mapStateToProps(state) {
    return {
        providerImage: state.providerImageVisibility,
        i18n: state.language
    }
}

const mapDispatchToProps = {
    changeLanguage
}


export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);

import React, {Component} from 'react'
import DisplayProfileImage from './DisplayProfileImage'
import EditProfileImage from './EditProfileImage'
import {
    deleteImage,
    getUserImage,
    uploadImage
} from '../../../service/users_service'
import PropTypes from "prop-types";


export class ProfileImageComponent extends Component {

    constructor(props) {
        super(props)

        this.state =
            {
                imageUrl: "",
                src: undefined,
                crop: null,
                croppedImageUrl: null,
                showMe: "none"
            }

    }

    componentDidUpdate(prevProps) {
        if (this.props.needsRefresh !== prevProps.needsRefresh) {

            this.fetchUserImage(this.props.selectedUser.id);
            this.props.refreshComplete();
        }


    }

    componentDidMount() {
        this.fetchUserImage(this.props.selectedUser.id)
    }

    fetchUserImage = (userId) => {
        try {
            getUserImage(userId).then((res) => {

                this.setState({imageUrl: res})

            });
        } catch (e) {
            console.log(e);
        }

    }

    toggleShowMe = () => {
        this.setState({showMe: "none"})
    }

    showModal = () => {
        this.setState({showModal: true});
    }

    onSelectFile = (e) => {

        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                    this.setState({src: reader.result});
                    this.setState({showMe: "block"});
                }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    addOrChangeProfilePicture = () => {

        const myInput = document.getElementById('picture');
        myInput.click();
    }

    onImageLoaded = (image) => {

        this.imageRef = image;

        let ratio = image.naturalWidth / image.naturalHeight;
        let heightInPct = 50 * ratio;
        let yInPct = (100 - (heightInPct)) / 2;
        let xInPct = 25;
        this.setState({
            crop: {
                unit: '%',
                width: 50,
                height: heightInPct,
                x: xInPct,
                y: yInPct,
                aspect: 1
            }
        });

        const targetX = image.width * xInPct / 100;
        const targetY = image.height * yInPct / 100;
        const targetWidth = image.width * 50 / 100;
        const targetHeight = image.height * heightInPct / 100;

        let cropForInitialPreview = {
            width: targetWidth,
            height: targetHeight,
            x: targetX,
            y: targetY

        };
        this.makeClientCrop(cropForInitialPreview);
        return false;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {

        this.setState({crop: percentCrop});
    };

    makeClientCrop = (crop) => {

        if (this.imageRef && crop.width && crop.height) {
            this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg',
                (e) => {
                    this.setState({croppedImageUrl: e})
                }
            );
        }
    }


    getCroppedImg = (image, crop, fileName, resolve) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        //fixed dest size - 512x512
        canvas.width = 512;
        canvas.height = 512;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height
        );

        canvas.toBlob(blob => {
            if (!blob) {
                return;
            }
            blob.name = fileName;

            window.URL.revokeObjectURL(this.state.croppedImageUrl);
            let a = window.URL.createObjectURL(blob);
            resolve(a, blob);
        }, 'image/jpeg');
    }

    onConfirmModal = () => {
        this.removeProfilePicture()
        this.setState({imageUrl: ""})
        this.setState({showModal: false});

    }

    removeProfilePicture = async () => {
        deleteImage(this.props.selectedUser, this.props.parentId)
            .then(() => {
                this.props.updateNeedsRefresh();
            })


    }

    onComplete = (croppedUrl) => {
        this.createFile(croppedUrl)

    }

    createFile = async (croppedUrl) => {

        const response = await fetch(croppedUrl)
            .then("SUCCESS")
            .catch(() => {
            });

        const data = await response.blob();

        const metadata = {
            type: 'image/jpeg'
        };
        const file = new File([data], "test.jpg", metadata);

        uploadImage(file, this.props.selectedUser, this.props.parentId)
            .then(() => {
                this.setState({showMe: "none"})
                this.props.updateNeedsRefresh()
            })

    }

    setShowModal = () => {
        this.setState({showModal: false})
    }

    render() {

        return (
            <div>
                <div>
                    <div>
                        <div className="flex-center flx-wrap">
                            <DisplayProfileImage
                                userId={this.props.userId}
                                addOrChangeProfilePicture={this.addOrChangeProfilePicture}
                                showModal={this.showModal}
                                imageUrl={this.state.imageUrl}
                            />
                            <EditProfileImage
                                onConfirmModal={this.onConfirmModal}
                                showModal={this.state.showModal}
                                onImageLoaded={this.onImageLoaded}
                                onCropComplete={this.onCropComplete}
                                onCropChange={this.onCropChange}
                                onSelectFile={this.onSelectFile}
                                src={this.state.src}
                                crop={this.state.crop}
                                croppedImageUrl={this.state.croppedImageUrl}
                                showMe={this.state.showMe}
                                toggleShowMe={this.toggleShowMe}
                                onComplete={this.onComplete}
                                setShowModal={this.setShowModal}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ProfileImageComponent.propTypes = {
    needsRefresh: PropTypes.bool,
    parentId: PropTypes.string,
    userId: PropTypes.string,
    refreshComplete: PropTypes.func,
    selectedUser: PropTypes.shape({
        id : PropTypes.string
    }),
    updateNeedsRefresh: PropTypes.func
};

export default ProfileImageComponent;
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    clearRegistrationData,
    register, registerWithConnectedProfile
} from '../../actions/register_actions'
import {RegisterForm} from './RegisterForm'
import {removeImageFromStore} from "../../actions/profile_image_actions";
import PropTypes from "prop-types";


class RegisterPage extends Component {
    state = {
        showModal: false
    }

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                <RegisterForm register={this.props.register}
                              registerWithConnectedProfile={this.props.registerWithConnectedProfile}
                              userWasntLoggedIn={this.props.userWasntLoggedIn}
                              clearRegistrationData={this.props.clearRegistrationData}
                              registration={this.props.registration}
                              i18n={this.props.i18n}
                              removeImageFromStore={this.props.removeImageFromStore}
                              history={this.props.history}/>
            </div>
        )
    }
}

RegisterPage.propTypes = {
    clearRegistrationData: PropTypes.func,
    history: PropTypes.object,
    i18n: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    register: PropTypes.func,
    registration: PropTypes.object,
    removeImageFromStore: PropTypes.func,
};


const mapStateToProps = (state) => (
    {
        registration: state.register,
        i18n: state.language,
        userWasntLoggedIn: state.preselectedAppointment.userWasntLoggedIn
    })

const mapDispatchToProps = {
    register,
    registerWithConnectedProfile,
    clearRegistrationData,
    removeImageFromStore
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage)

import {fetchHelper} from '../helpers/request_helper';
import {
    CREATE_UPDATE_DOCUMENT,
    DELETE_DOCUMENT,
    DOWNLOAD_DOCUMENT_URL,
    GET_USER_DOCUMENTS_URL,
    EXPORT_LOGBOOK_URL, DOWNLOAD_EXAMINATION_RESULTS_URL
} from '../constants/api_paths';

export const documentsService = {
    fetchSelectedUserDocuments,
    downloadFile,
    createDocument,
    updateDocument,
    deleteDocument,
    downloadExamResult,
    exportLogbook
};

/**
 * Fetch the selected user uploaded documents
 *
 * @param {string} userId id of the user to fetch documents for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserDocuments(userId, params) {
    return fetchHelper.callGet(GET_USER_DOCUMENTS_URL.replace('{userId}', userId), params);
}

/**
 * Download file with the given id.
 *
 * @param {number} documentId id of the document to download
 * @returns {object} promise object
 */
export function downloadFile(documentId) {
    return fetchHelper.getBlob(DOWNLOAD_DOCUMENT_URL.replace('{documentId}', documentId));
}

/**
 * Creates entry
 * @param {formData} entry The entry to be created
 */
export function createDocument(entry) {
    return fetchHelper.callPost(CREATE_UPDATE_DOCUMENT, entry, true);
}

/**
 * Updates entry
 * @param {formData} entry The entry to be updated
 */
export function updateDocument(entry) {
    return fetchHelper.callPut(CREATE_UPDATE_DOCUMENT, entry, null, true);
}

/**
 * Deletes document with the given id.
 *
 * @param {number} documentId id of the document to delete
 * @returns {object} promise object
 */
export function deleteDocument(documentId) {
    return fetchHelper.callDelete(DELETE_DOCUMENT.replace('{documentId}', documentId));
}

export function exportLogbook(userId, userDetails) {
    return fetchHelper.getBlobWithPut(EXPORT_LOGBOOK_URL.replace("{userId}", userId), userDetails);
}

export function downloadExamResult(lang, examDetails) {
    let data = {...examDetails}
    data.addSignatures = false;
    return fetchHelper.getBlobWithPost(DOWNLOAD_EXAMINATION_RESULTS_URL.replace("{reportType}", "EncounterOutcome").replace("{lang}", lang), data)
}

import {fetchHelper} from '../helpers/request_helper';
import {
    GET_FIRST_AVAILABLE,
    GET_HOSPITALS,
    GET_PROVIDER,
    GET_PROVIDER_PRICE,
    GET_PROVIDER_TIMETABLE,
    GET_PROVIDERS,
    GET_SPECIALTIES_COUNT,
    GET_SPECIALTIES_URL
} from '../constants/api_paths';

export const providerService = {
    fetchProviders,
    fetchSpecialties,
    fetchSpecialtiesCount,
    fetchProvider,
    fetchFirstHour,
    fetchTimetable,
    fetchHospitals,
    fetchPrice
};

export function fetchHospitals() {
    return fetchHelper.callGet(GET_HOSPITALS)
}


/**
 * Fetch the providers
 *
 */
export function fetchProviders(specialty, country) {
    const replacement = specialty ? '&specialty=' + specialty + '' : ''
    return fetchHelper.callGet(GET_PROVIDERS.replace('{country}', country).replace('{specialty}', replacement));
}


/**
 * Fetch all the available specialties
 *
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSpecialties(params) {
    return fetchHelper.callGet(GET_SPECIALTIES_URL, params);
}

export function fetchSpecialtiesCount(params) {
    return fetchHelper.callGet(GET_SPECIALTIES_COUNT, params);
}


export function fetchProvider(providerId) {
    return fetchHelper.callGet(GET_PROVIDER.replace('{providerId}', providerId));
}

export function fetchFirstHour(providerId, startTimestamp, endTimestamp, timezone, location, organizationId, priceId) {
    let locationUrl = location ? "&location=" + location : "";
    let organizationIdUrl = organizationId ? "&organizationId=" + organizationId : ""
    return fetchHelper.callGet(GET_FIRST_AVAILABLE
        .replace('{providerId}', providerId)
        .replace('{startTimestamp}', startTimestamp)
        .replace('{endTimestamp}', endTimestamp)
        .replace('{timezone}', timezone)
        .replace('{location}', locationUrl)
        .replace('{organizationId}', organizationIdUrl)
        .replace('{priceId}', priceId)
    )
}


export function fetchTimetable(providerId, startTimestamp, endTimestamp, timezone, location, organizationId, priceId) {
    let locationUrl = location ? "&location=" + location : "";
    let organizationIdUrl = organizationId ? "&organizationId=" + organizationId : ""
    return fetchHelper.callGet(GET_PROVIDER_TIMETABLE
        .replace('{providerId}', providerId)
        .replace('{startTimestamp}', startTimestamp)
        .replace('{endTimestamp}', endTimestamp)
        .replace('{timezone}', timezone)
        .replace('{location}', locationUrl)
        .replace('{organizationId}', organizationIdUrl)
        .replace('{priceId}', priceId)
    )
}

export function fetchPrice(providerId, priceId) {
    return fetchHelper.callGet(GET_PROVIDER_PRICE.replace('{providerId}', providerId).replace('{priceId}', priceId));
}

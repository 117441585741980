import {
    ALL_USERS_ERROR,
    ALL_USERS_FILTER_CHANGE,
    ALL_USERS_REQUEST_SENT,
    ALL_USERS_SUCCESS,
    CHANGE_CHARTS_FILTERS, CLEAR_CHARTS, CLEAR_MEDICAL_PROFILE,
    CLEAR_SELECTED_USER,
    CLEAR_USER_PASSWORD,
    CLEAR_USERS,
    FETCH_APPOINTMENT_USER_ERROR,
    FETCH_APPOINTMENT_USER_REQUEST_SENT,
    FETCH_APPOINTMENT_USER_SUCCESS,
    FETCH_CHARTS_DATA_ERROR,
    FETCH_CHARTS_DATA_SUCCESS,
    FETCH_DASHBOARD_CHARTS_DATA_ERROR,
    FETCH_DASHBOARD_CHARTS_DATA_SUCCESS,
    FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR,
    FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS,
    FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR,
    FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS,
    GET_USER_PROFILE,
    GET_USER_PROFILE_ERROR,
    LOGOUT,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    SELECT_USER,
    UPDATE_MEDICAL_PROFILE_ERROR,
    UPDATE_MEDICAL_PROFILE_SUCCESS,
    UPDATE_USER_PASSWORD,
    UPDATE_USER_PASSWORD_ERROR,
    UPDATE_USER_PROFILE,
    CLEAR_UPDATE_USER_PROFILE_REQUEST,
    UPDATE_USER_PROFILE_ERROR,
    UPGRADE_CHILD_ERROR,
    UPGRADE_CHILD_REQUEST_SENT,
    UPGRADE_CHILD_SUCCESS
} from "../actions/actions";
import {requestStatus} from './requests_reducers';


const initialState = {list: [], filter: null, request: requestStatus(undefined, {})}

/**
 * Users reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the users
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
export function users(state = initialState, action) {
    switch (action.type) {
        case ALL_USERS_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case ALL_USERS_SUCCESS: {
            // eslint-disable-next-line no-unused-vars
            const {error, ...rest} = state;
            return {
                ...rest,
                list: action.result,
                request: requestStatus(rest.request, {type: REQUEST_SUCCESS})
            }
        }
        case ALL_USERS_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {list, ...rest} = state;
            return {
                ...rest,
                error: action.result,
                request: requestStatus(rest.request, {type: REQUEST_ERROR, response: action.result})
            };
        }
        case ALL_USERS_FILTER_CHANGE: {
            return {...state, filter: action.filter};
        }
        case UPDATE_USER_PROFILE: {
            const usersList = state.list.map(u => {
                if (u.id === action.result.id) {
                    return action.result;
                }
                return u;
            });
            return {...state, list: usersList};
        }
        case CLEAR_USERS:
        case LOGOUT: {
            return {...initialState};
        }
        default: {
            return state;
        }
    }
}


/**
 * Child Upgrade reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the users
 * @param {action} action the action to execute on the state
 * @returns the new state
 */

const childUpdateInitialState = {data: {}, request: requestStatus(undefined, {}), error: {}}

export function childToStandalone(state = childUpdateInitialState, action) {
    switch (action.type) {
        case UPGRADE_CHILD_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case UPGRADE_CHILD_SUCCESS: {
            // eslint-disable-next-line no-unused-vars
            const {error, ...rest} = state;
            return {
                ...rest,
                request: requestStatus(rest.request, {type: REQUEST_SUCCESS})
            }
        }
        case UPGRADE_CHILD_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {error, ...rest} = state;
            return {
                ...rest,
                error: action.result,
                request: requestStatus(rest.request, {type: REQUEST_ERROR, response: action.result})
            };
        }
        case CLEAR_USERS:
        case LOGOUT: {
            return {...childUpdateInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Appointment encounter reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const appointmentEncounterInitialState = {data: {}, request: requestStatus(undefined, {})}

export function appointmentEncounter(state = appointmentEncounterInitialState, action) {
    switch (action.type) {
        case FETCH_APPOINTMENT_USER_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case FETCH_APPOINTMENT_USER_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_APPOINTMENT_USER_ERROR: {
            return {
                ...state, ...initialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case LOGOUT: {
            return {...appointmentEncounterInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * User profile info reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const userInfoInitialState = {data: {}, request: requestStatus(undefined, {})}

export function userInfo(state = userInfoInitialState, action) {
    switch (action.type) {
        case GET_USER_PROFILE:
        case UPDATE_USER_PROFILE: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})};
        }
        case GET_USER_PROFILE_ERROR: {
            return {
                ...state, ...userInfoInitialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.response})
            };
        }
        case UPDATE_USER_PROFILE_ERROR: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.response})};
        }
        case CLEAR_UPDATE_USER_PROFILE_REQUEST : {
            return {...state, request: userInfoInitialState.request};
        }
        case LOGOUT: {
            return {...state, ...userInfoInitialState};
        }
        default: {
            return state
        }
    }
}

/**
 * User password reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const userPasswordInitialState = {request: requestStatus(undefined, {}),}

export function userPassword(state = userPasswordInitialState, action) {
    switch (action.type) {
        case REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})};
        }
        case UPDATE_USER_PASSWORD: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SUCCESS})};
        }
        case UPDATE_USER_PASSWORD_ERROR: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})};
        }
        case CLEAR_USER_PASSWORD:
        case LOGOUT: {
            return {...state, ...userPasswordInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Selected user reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const selectedUserInitialState = {data: {}}

export function selectedUser(state = selectedUserInitialState, action) {
    switch (action.type) {
        case SELECT_USER: {
            // eslint-disable-next-line no-unused-vars
            const {data, ...rest} = state;
            return {...rest, data: action.user}
        }
        case CLEAR_SELECTED_USER:
        case LOGOUT: {
            return {...selectedUserInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 *Latest user logs reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const latestUserMeasurementsInitialState = {entries: [], request: requestStatus(undefined, {})}

export function latestUserMeasurements(state = latestUserMeasurementsInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS: {
            return {entries: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, ...rest} = state;
            return {...rest, entries:[], request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case SELECT_USER:
        case LOGOUT: {
            return {...latestUserMeasurementsInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Medical profile reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const medicalProfileInitialState = {data: {}, request: requestStatus(undefined, {})}

export function medicalProfile(state = medicalProfileInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR: {
            return {
                ...state, ...medicalProfileInitialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case UPDATE_MEDICAL_PROFILE_SUCCESS: {
            return {
                ...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case UPDATE_MEDICAL_PROFILE_ERROR: {
            return {
                ...state, ...medicalProfileInitialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case CLEAR_MEDICAL_PROFILE:
        case SELECT_USER:
        case LOGOUT: {
            return {...medicalProfileInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Chart data reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const chartsInitialState = {
    userId: null,
    filters: {
        period: "WEEK",
        before_date_time: null,
        after_date_time: null
    },
    chartsData: {
        chartsDataClassificationChart: {},
        chartsDataAveragesPerDayInterval: {},
        chartsDataAveragesPerDayOfWeek: {},
        chartsDataAveragesPerHour: {},
        chartsDataBPTimeline: {},
        chartsDataWeightTimeline: {},
        chartsDataCholesterolTimeline: {},
        chartsDataTemperatureTimeline: {},
        chartsDataHydrationTimeline: {},
        chartsDataSaturationTimeline: {},
        chartsDataRespiratoryRateTimeline: {},
        chartsDataHydrationAveragesPerHour: {}
    }, request: requestStatus(undefined, {})
}

export function charts(state = chartsInitialState, action) {
    switch (action.type) {
        case FETCH_CHARTS_DATA_SUCCESS: {
            addPropertiesToChartDatasets(action.result);
            return {
                ...state,
                userId: action.userId,
                chartsData: action.result,
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case FETCH_CHARTS_DATA_ERROR: {
            const rest = {...state, chartsData: chartsInitialState.chartsData};
            return {...rest, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case CHANGE_CHARTS_FILTERS: {
            return {
                ...state,
                filters: {
                    period: action.period,
                    before_date_time: action.beforeDateTime,
                    after_date_time: action.afterDateTime
                }
            }
        }
        case CLEAR_CHARTS:
        case SELECT_USER:
        case LOGOUT: {
            return {...chartsInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Dashboard chart data reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const dashboardChartsInitialState = {
    userId: null,
    chartsData: {
        chartsDataClassificationChart: {},
        chartsDataAveragesPerDayInterval: {},
        chartsDataAveragesPerDayOfWeek: {},
        chartsDataAveragesPerHour: {},
        chartsDataBPTimeline: {},
        chartsDataWeightTimeline: {},
        chartsDataCholesterolTimeline: {},
        chartsDataTemperatureTimeline: {},
        chartsDataHydrationTimeline: {},
        chartsDataSaturationTimeline: {},
        chartsDataRespiratoryRateTimeline: {},
        chartsDataHydrationAveragesPerHour: {}
    }, request: requestStatus(undefined, {})
}

export function dashboardCharts(state = dashboardChartsInitialState, action) {
    switch (action.type) {
        case FETCH_DASHBOARD_CHARTS_DATA_SUCCESS: {
            addPropertiesToChartDatasets(action.result);
            return {
                ...state,
                userId: action.userId,
                chartsData: action.result,
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case FETCH_DASHBOARD_CHARTS_DATA_ERROR: {
            const rest = {...state, chartsData: dashboardChartsInitialState.chartsData};
            return {...rest, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case SELECT_USER:
        case LOGOUT: {
            return {...dashboardChartsInitialState};
        }
        default: {
            return state;
        }
    }
}


/**
 * Add properties to chart datasets
 *
 * @param {object} chartsData object containing all the charts data
 */
function addPropertiesToChartDatasets(chartsData) {
    chartsData.chartsDataAveragesPerDayInterval.data.datasets.forEach(d => {
        setBarProperties(d);
    });

    chartsData.chartsDataAveragesPerDayOfWeek.data.datasets.forEach(d => {
        setBarProperties(d);
    });

    chartsData.chartsDataAveragesPerHour.data.datasets.forEach(d => {
        setBarProperties(d);
    });

    chartsData.chartsDataHydrationAveragesPerHour.data.datasets.forEach(d => {
        setBarProperties(d);
    });

    chartsData.chartsDataBPTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
        d.spanGaps = true
    });

    chartsData.chartsDataWeightTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    chartsData.chartsDataCholesterolTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    chartsData.chartsDataTemperatureTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    chartsData.chartsDataHydrationTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    chartsData.chartsDataSaturationTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    chartsData.chartsDataRespiratoryRateTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    function setLineProperties(dataset) {
        dataset.borderDash = [5, 5];
        dataset.borderWidth = 2;
        dataset.pointRadius = 3;
        dataset.pointHoverRadius = 3;
    }

    function setBarProperties(dataset) {
        dataset.barThickness = 15;
        dataset.maxBarThickness = 25;
    }
}
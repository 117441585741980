import {logbookService} from '../service/logbook_service'
import {
    CLEAR_ENTRIES,
    CREATE_LOGBOOK_ENTRY_ERROR,
    CREATE_LOGBOOK_ENTRY_REQUEST_SENT,
    DUMMY,
    FETCH_SELECTED_USER_ENTRIES_ERROR,
    FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT,
    FETCH_SELECTED_USER_ENTRIES_SUCCESS,
    NO_MORE_ENTRIES,
    RESET_SELECTED_USER_ENTRIES_SUCCESS,
    UPDATE_LOGBOOK_ENTRY_ERROR,
    UPDATE_LOGBOOK_ENTRY_REQUEST_SENT
} from './actions';
import moment from "moment";
import store from "../store";

/**
 * Fetch the logbook entries for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchSelectedUserLogbookEntries(userId, params, resetList, afterChange) {
    return (dispatch, getState) => {
        if (!params) {
            params = {
                before_date_time: moment().valueOf()
            };
        }
        params.size = 20;
        dispatch({type: FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT});
        return logbookService.fetchSelectedUserLogbookEntries(userId, params).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res && (res.length > 0 || afterChange)) {
                if (resetList) {
                    dispatch({type: RESET_SELECTED_USER_ENTRIES_SUCCESS, result: res});
                } else {
                    dispatch({type: FETCH_SELECTED_USER_ENTRIES_SUCCESS, result: res});
                }
            }
            if (res && (res.length < params.size)) {
                dispatch({type: NO_MORE_ENTRIES})
            }
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_USER_ENTRIES_ERROR, result: err});
        })
    }
}

export function clearLogbook() {
    return (dispatch) => {
        dispatch({type: CLEAR_ENTRIES});
    }
}

/**
 * Create a logbook entry
 *
 * @param {object} entry The entry to be created
 */
export function createLogbookEntry(entry, userId, type) {
    let params = {
        before_date_time: moment(new Date()).valueOf()
    };

    if (type !== "") {
        params.filterByType = type
    }
    return (dispatch, getState) => {
        dispatch({type: CREATE_LOGBOOK_ENTRY_REQUEST_SENT});
        return logbookService.createLogbookEntry(entry).then(() => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch(fetchSelectedUserLogbookEntries(userId, params, true, true));
        }).catch((err) => {
            dispatch({type: CREATE_LOGBOOK_ENTRY_ERROR, result: err});
        });
    }
}

/**
 * Update a logbook entry
 *
 * @param {object} entry The entry to be updated
 */
export function updateLogbookEntry(entry, userId, type) {
    let params = {
        before_date_time: moment(new Date()).valueOf()
    };

    if (type !== "") {
        params.filterByType = type
    }
    return (dispatch, getState) => {
        dispatch({type: UPDATE_LOGBOOK_ENTRY_REQUEST_SENT});
        return logbookService.updateLogbookEntry(entry).then(() => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch(fetchSelectedUserLogbookEntries(userId, params, true, true));
        }).catch((err) => {
            dispatch({type: UPDATE_LOGBOOK_ENTRY_ERROR, result: err});
        });
    }
}

export function dummyActionDirectDispatch() {
    store.dispatch({type:DUMMY})
}
import {healthIssuesService} from '../service/health-issues_service'
import {
    CLEAR_HEALTH_ISSUES,
    CREATE_HEALTH_ISSUE_ENTRY_ERROR,
    CREATE_HEALTH_ISSUE_ENTRY_REQUEST_SENT,
    CREATE_HEALTH_ISSUE_ENTRY_SUCCESS,
    DELETE_HEALTH_ISSUE_DATA_ERROR,
    DELETE_HEALTH_ISSUE_DATA_REQUEST_SENT,
    DELETE_HEALTH_ISSUE_DATA_SUCCESS,
    DUMMY,
    FETCH_HEALTH_ISSUES_DATA_ERROR,
    FETCH_HEALTH_ISSUES_DATA_REQUEST_SENT,
    HEALTH_ISSUE,
    RESET_HEALTH_ISSUES_DATA_SUCCESS,
    SET_FORM_TO_DISABLED,
    SET_FORM_TO_ENABLED,
    UPDATE_HEALTH_ISSUE_DATA_ERROR,
    UPDATE_HEALTH_ISSUE_DATA_REQUEST_SENT,
    UPDATE_HEALTH_ISSUE_DATA_SUCCESS
} from './actions';
import {fetchSelectedUserMedicalProfile} from "../service/users_service";

/**
 * Fetch the health issues for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchSelectedUserHealthIssues(userId, params) {
    return (dispatch, getState) => {
        if (!params) {
            params = {
                userId: userId
            };
        }
        dispatch({type: FETCH_HEALTH_ISSUES_DATA_REQUEST_SENT});
        return healthIssuesService.fetchSelectedUserHealthIssues(userId, params).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
        
            dispatch({type: RESET_HEALTH_ISSUES_DATA_SUCCESS, result: res});
           
        }).catch((err) => {
            dispatch({type: FETCH_HEALTH_ISSUES_DATA_ERROR, result: err});
        })
    }
}

export function clearHealthIssues() {
    return(dispatch) => {
        dispatch({type: CLEAR_HEALTH_ISSUES});
    }
}


/**
 *  Create a health issue entry
 *
 * @param {object} entry The entry to be created
 */
export function createHealthIssue(entry, userId) {
    return (dispatch, getState) => {
        dispatch({ type: SET_FORM_TO_DISABLED , formName : HEALTH_ISSUE });
        dispatch({type: CREATE_HEALTH_ISSUE_ENTRY_REQUEST_SENT});
        return healthIssuesService.createHealthIssue(entry).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: SET_FORM_TO_ENABLED});
            dispatch({type: CREATE_HEALTH_ISSUE_ENTRY_SUCCESS, result: res});
            dispatch(fetchSelectedUserHealthIssues(userId, null, true));
        }).catch((err) => {
            dispatch({ type: SET_FORM_TO_ENABLED });
            dispatch({type: CREATE_HEALTH_ISSUE_ENTRY_ERROR, result: err});
        });
    }
}


/**
 * Update a health issue
 *
 * @param {object} entry The entry to be updated
 */
export function updateHealthIssue(entry, userId) {
    return (dispatch, getState) => {
        dispatch({ type: SET_FORM_TO_DISABLED , formName : HEALTH_ISSUE });
        dispatch({type: UPDATE_HEALTH_ISSUE_DATA_REQUEST_SENT});
        return healthIssuesService.updateHealthIssue(entry).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({ type: SET_FORM_TO_ENABLED });
            dispatch({type: UPDATE_HEALTH_ISSUE_DATA_SUCCESS, result: res});
            dispatch(fetchSelectedUserHealthIssues(userId, null, true));
            dispatch(fetchSelectedUserMedicalProfile(userId));

        }).catch((err) => {
            dispatch({ type: SET_FORM_TO_ENABLED });
            dispatch({type: UPDATE_HEALTH_ISSUE_DATA_ERROR, result: err});
        });
    }
}

/**
 *  Delete a health issue entry
 *
 * @param {number} healthIssueId The entry to be deleted
 */
export function deleteHealthIssue(healthIssueId, userId) {
    return (dispatch, getState) => {
        dispatch({type: DELETE_HEALTH_ISSUE_DATA_REQUEST_SENT});
        return healthIssuesService.deleteHealthIssue(healthIssueId).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: DELETE_HEALTH_ISSUE_DATA_SUCCESS, result: res});
            dispatch(fetchSelectedUserHealthIssues(userId, null, true));
        }).catch((err) => {
            dispatch({type: DELETE_HEALTH_ISSUE_DATA_ERROR, result: err});
        });
    }
}

// eslint-disable-next-line no-undef
export const SERVER_URL = process.env.REACT_APP_SERVER_API_URL;
export const APP_URL = process.env.REACT_APP_SERVER_URL;

export const LOGIN_URL = `${SERVER_URL}/auth/token`;
export const GOOGLE_LOGIN_URL = `${SERVER_URL}/auth/token/google`;
export const FACEBOOK_LOGIN_URL = `${SERVER_URL}/auth/token/facebook`;
export const APPLE_LOGIN_URL = `${SERVER_URL}/auth/token/apple`;
export const REGISTER_URL = `${SERVER_URL}/public/user`;
export const REGISTER_WITH_CONNECTED_URL = `${SERVER_URL}/public/user/with_connected`;
export const REFRESH_TOKEN_URL = `${SERVER_URL}/auth/token/refresh`;
export const FORGOT_PASSWORD_URL = `${SERVER_URL}/public/password/forgotPassword?lang={lang}`;
export const PRIVACY_POLICY_URL = `https://medrec-m.com/privacy-policy/{lang}`;
export const TERMS_OF_USE_URL = `https://medrec-m.com/terms-and-conditions/{lang}`;


export const IMPORT_GP_CAPTURE_URL = `${SERVER_URL}/public/gp/captcha`;
export const IMPORT_GP_URL = `${SERVER_URL}/public/gp`;

export const ALL_USERS_URL = `${SERVER_URL}/user/chat/users`;

export const ALL_LOGBOOK_ENTIRES_FOR_USER = `${SERVER_URL}/logbook-api/{userId}?app=Medrec-M`;
export const CREATE_UPDATE_LOGBOOK_ENTRY = `${SERVER_URL}/logbook-api/entry`;
export const USER_CHART_DATA_URL = `${SERVER_URL}/charts/{userId}`;
export const LATEST_MEASUREMENTS_FOR_USER = `${SERVER_URL}/logbook-api/{userId}/latest?app=Medrec-M`;
export const USER_PICTURE_URL = `${SERVER_URL}/user/photo/downloadFile/{userId}`;
export const CLINICIAN_PUBLIC_PHOTO_URL = `${SERVER_URL}/public/provider/photo/{userId}`;

export const ALL_HEALTH_ISSUES_FOR_USER = `${SERVER_URL}/user/health_issues/{userId}?app=Medrec-M`;
export const CREATE_APPOINTMENT = `${SERVER_URL}/user/appointment?userId={userId}`;
export const UPDATE_APPOINTMENT = `${SERVER_URL}/user/appointment`;

export const CREATE_UPDATE_HEALTH_ISSUE = `${SERVER_URL}/user/health-issue`;
export const DELETE_HEALTH_ISSUE = `${SERVER_URL}/user/health-issue/{healthIssueId}`;
export const GET_USER_SETTINGS_URL = `${SERVER_URL}/medrec-m/settings/{targetUserId}`;
export const UPDATE_USER_SETTINGS_URL = `${SERVER_URL}/medrec-m/settings/{targetUserId}`;

export const GET_NOMENCLATURE_URL = `${SERVER_URL}/logbook-api/symptoms/nomenclature`;
export const GET_USER_SYMPTOMS_LOG_URL = `${SERVER_URL}/logbook-api/symptoms-log/{userId}?app=Medrec-M`;
export const LOG_USER_SYMPTOMs_URL = `${SERVER_URL}/logbook-api/symptoms-log/{userId}?app=Medrec-M&modified_after={modified_after}`;
export const GET_LATEST_SYMPTOMS_TYPES = `${SERVER_URL}/logbook-api/symptoms-log/latest_symptoms_types/{userId}?app=Medrec-M`;

export const GET_ALL_PERMISSIONS_URL = `${SERVER_URL}/permission/authorized?app=Medrec-M`;
export const GET_ALL_AUTHORIZING_PERMISSIONS_URL = `${SERVER_URL}/permission/authorizing?app=Medrec-M`;
export const APPROVE_PERMISSION_URL = `${SERVER_URL}/permission/{permissionId}/approve`;
export const DENY_PERMISSION_URL = `${SERVER_URL}/permission/{permissionId}/deny`;
export const SHARE_DATA_URL = `${SERVER_URL}/user/code/{email}/link?app=Medrec-M&types=READ`;
export const USER_ACCESS_LINK_URL = `${SERVER_URL}/user/access/link?app=Medrec-M&duration={duration}`;

export const GET_USER_INFO_URL = `${SERVER_URL}/user`;
export const UPDATE_USER_INFO_URL = `${SERVER_URL}/user`;
export const UPDATE_USER_PHONE_AND_NAMES_URL = `${SERVER_URL}/user/details`;
export const UPDATE_USER_PASSWORD_URL = `${SERVER_URL}/user/password`;
export const UPLOAD_USER_IMAGE_URL = `${SERVER_URL}/user/photo/uploadFile`;
export const DELETE_USER_IMAGE_URL = `${SERVER_URL}/user/photo/delete`;
export const UPLOAD_CHILD_IMAGE_URL = `${SERVER_URL}/user/photo/uploadFile/{childId}`;
export const DELETE_CHILD_IMAGE_URL = `${SERVER_URL}/user/photo/delete/{childId}`;
export const GET_PARENT_LIST = `${SERVER_URL}/user/parent/{childId}`;
export const UPGRADE_CHILD_TO_STANDALONE = `${SERVER_URL}/user/child_to_standalone`;
export const APPROVE_CHILD_WITH_PIN = `${SERVER_URL}/user/child/{childId}/approve/{pin}`;
export const DENY_CHILD = `${SERVER_URL}/user/child/{childId}/deny`;
export const DELETE_CHILD = `${SERVER_URL}/user/child/{childId}`;
export const DELETE_PARENT = `${SERVER_URL}/user/parent/{childId}/{parentId}/`;
export const ADD_PARENT_BY_EMAIL = `${SERVER_URL}/user/parent/{childId}`;


export const GET_USER_DOCUMENTS_URL = `${SERVER_URL}/logbook-api/documents/{userId}?app=Medrec-M`;
export const DOWNLOAD_DOCUMENT_URL = `${SERVER_URL}/logbook-api/document/attachment/{documentId}?app=Medrec-M`;
export const DOWNLOAD_EXAMINATION_RESULTS_URL = `${SERVER_URL}/reports/pdf?reportType={reportType}&lang={lang}`;
export const UPDATE_DOCUMENT_ENTRIES = `${SERVER_URL}/logbook-api/documents?modified_after={modified_after}`;
export const CREATE_UPDATE_DOCUMENT = `${SERVER_URL}/logbook-api/document`;
export const DELETE_DOCUMENT = `${SERVER_URL}/logbook-api/document/{documentId}?app=Medrec-M`;

export const ALL_APPOINTMENTS_FOR_USER = `${SERVER_URL}/user/appointment?app=Medrec-M`;
export const UPDATE_APPOINTMENT_STATUS_URL = `${SERVER_URL}/user/appointment/{appointmentId}/{status}?userId={userId}`;
export const GET_APPOINTMENT = `${SERVER_URL}/user/appointment/{appointmentId}`;
export const GET_NEXT_APPOINTMENT = `${SERVER_URL}/user/appointment/next`;
export const GET_ACTIVE_CONSULTATIONS_APPOINTMENT = `${SERVER_URL}/user/appointment/consultations/active`;
export const GET_HAS_ACTIVE_FUTURE_APPOINTMENT = `${SERVER_URL}/user/appointment/has_active_appointment`


export const GET_CHILDREN_URL = `${SERVER_URL}/user/children`;
export const CREATE_CHILD = `${SERVER_URL}/user/child`;

export const MEDICAL_PROFILE_FOR_USER = `${SERVER_URL}/user/medical_profile/{userId}?app=Medrec-M`;


export const GET_CHAT_WITH_USER = `${SERVER_URL}/user/chat/{userId}`;
export const GET_LATEST_MESSAGES_PER_CHAT = `${SERVER_URL}/user/chat/latest`;
export const SEND_MESSAGE_TO_USER = `${SERVER_URL}/user/chat/message`;
export const MARK_MESSAGE_AS_READ_URL = `${SERVER_URL}/user/chat/message/read/{messageId}`;
export const GET_UNREAD_MESSAGES = `${SERVER_URL}/user/chat/unread/count`;
export const START_VIDEO_CALL_SESSION_UTR = `${SERVER_URL}/user/chat/video/session/{userId}`;
export const GET_VIDEO_CALL_SESSION_URL = `${SERVER_URL}/user/chat/video/session/{userId}`;
export const STOP_VIDEO_CALL_SESSION_URL = `${SERVER_URL}/user/chat/video/session`
export const UPDATE_VIDEO_DURATION_AND_STATUS_URL = `${SERVER_URL}/user/chat/message/video/{messageId}`;
export const CHAT_WEBSOCKET_URL = `${SERVER_URL}/public/user/ws`;
export const CHAT_CAN_SEND_MESSAGE_TO = `${SERVER_URL}/user/chat/can_send_message_to/{recipientId}`;

export const CREATE_UPDATE_MEDICATION = `${SERVER_URL}/medication/plan`;
export const DELETE_MEDICATION_PLAN = `${SERVER_URL}/medication/plan/{planId}?app=Medrec-M`;
export const USER_MEDICATIONS_URL = `${SERVER_URL}/medication/plans/{userId}`;
export const SEARCH_FOR_MEDICATIONS_URL = `${SERVER_URL}/medication/search`;
export const USER_GENERAL_PRACTITIONERS_URL = `${SERVER_URL}/user/general-practitioner/{userId}?app=Medrec-M`;
export const USER_GENERAL_PRACTITIONERS_CREATE_URL = `${SERVER_URL}/user/general-practitioner?app=Medrec-M`;
export const GET_USER_LAB_RESULTS_URL = `${SERVER_URL}/logbook-api/laboratory-results/{userId}?app=Medrec-M`;
export const GET_LABORATORIES_URL = `${SERVER_URL}/public/logbook-api/laboratories?app=Medrec-M`;
//export const GET_PROVIDERS = `${SERVER_URL}/public/provider/providers?country={country}{specialty}`;
export const GET_PROVIDERS = `${SERVER_URL}/public/provider/providers?{specialty}`;
export const GET_PROVIDER = `${SERVER_URL}/public/provider/{providerId}`;
export const GET_PROVIDER_PRICE = `${SERVER_URL}/public/provider/appointment/{providerId}/price/{priceId}`;
export const GET_LAB_RESULTS_DATES = `${SERVER_URL}/public/logbook-api/laboratory/results/dates?app=Medrec-M`;
export const GET_LAB_RESULTS = `${SERVER_URL}/public/logbook-api/laboratory/result?app=Medrec-M`;
export const CREATE_UPDATE_LAB_RESULT = `${SERVER_URL}/logbook-api/laboratory-result?app=Medrec-M`;
export const SAVE_LAB_RESULT_DOCUMENT = `${SERVER_URL}/logbook-api/laboratory-result/document?app=Medrec-M`;
export const DELETE_LAB_RESULT = `${SERVER_URL}/logbook-api/laboratory-result/{lab-result-id}?app=Medrec-M`;
export const GET_HOSPITALS = `${SERVER_URL}/public/provider/hospitals`;
export const GET_PLAN_STATS = `${SERVER_URL}/medication/events/{userId}/per_plan/{planId}`;

export const GET_MENSTRUATION_STATS = `${SERVER_URL}/logbook-api/summary/menstruationDiary/{userId}`;
export const DOWNLOAD_REPORT_URL = `${SERVER_URL}/reports/pdf?reportType={reportType}&lang={lang}`;

export const GET_SPECIALTIES_URL = `${SERVER_URL}/public/provider/specialties/all`;
export const GET_SPECIALTIES_COUNT = `${SERVER_URL}/public/provider/specialties`;

export const GET_PROVIDER_TIMETABLE = `${SERVER_URL}/public/provider/appointment/{providerId}/timetables
?start_timestamp={startTimestamp}
&end_timestamp={endTimestamp}
&timezone={timezone}
&priceId={priceId}
{location}
{organizationId}`;

export const GET_FIRST_AVAILABLE = `${SERVER_URL}/public/provider/appointment/{providerId}/first_available
?start_timestamp={startTimestamp}
&end_timestamp={endTimestamp}
&timezone={timezone}
&priceId={priceId}
{location}
{organizationId}`;

export const GET_ENCOUNTER_OF_THE_APPOINTMENT = `${SERVER_URL}/user/encounter/{encounterId}`;

export const ICD_SEARCH = `${SERVER_URL}/public/provider/icd10/{country}/search?q={query}`;
export const GET_MEDICATION_EVENTS = `${SERVER_URL}/medication/events/{userId}`;
export const GET_MED_ENTRIES_FOR_MED_EVENTS = `${SERVER_URL}/medications/{userId}/by_medical_events`;
export const DELETE_MEDICATION_EVENTS = `${SERVER_URL}/medication/events/plan/{planId}`;
export const DELETE_MEDICATION_EVENTS_ENTRIES = `${SERVER_URL}/medications/plan/{planId}`;
export const CREATE_UPDATE_MEDICATION_EVENT = `${SERVER_URL}/medication/event?app=Medrec-M`;
export const CREATE_UPDATE_MEDICATION_ENTRY = `${SERVER_URL}/medication`;
export const CREATE_PAYMENT = `${SERVER_URL}/public/user/payment_page/submit`;

//export const API_APPLE_REDIRECT_URI =  `${SERVER_URL}/auth/token/apple/redirect`;
export const API_APPLE_REDIRECT_URI = `${APP_URL}`;

export const LOGOUT_URL = `${SERVER_URL}/user/logout`

export const DELETE_USER_URL = `${SERVER_URL}/user`

export const GET_VIDEO_ROOM_CREDENTIALS = `${SERVER_URL}/user/chat/video/room/{roomId}`;

export const GET_LATEST_ACTIVE_VIDEO_MESSAGE_URL = `${SERVER_URL}/user/chat/message/video/last`;

export const UPDATE_ENCOUNTER_DOCUMENTS_URL = `${SERVER_URL}/user/encounter/{encounterId}`;
export const GET_QUESTIONNAIRE_BY_ENCOUNTER_ID_URL = `${SERVER_URL}/user/questionnaire/encounter_price/{encounterPriceId}`;

export const EXPORT_LOGBOOK_URL = `${SERVER_URL}/logbook-api/export/download/{userId}`;

export const CREATE_OR_UPDATE_USER_DEVICE = `${SERVER_URL}/user/device`;
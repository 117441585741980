import React, {Component} from "react";
import {CONVERTER, getUnitValuefromKey} from '../../utils/converter';
import {$$} from '../../helpers/localization'
import moment from "moment";
import isSameDay from "date-fns/isSameDay";
import SelectedPill from "./SelectedPill";
import PropTypes from "prop-types";
import UserImage from "../shared/UserImage";

class MedicationEntry extends Component {

    constructor(props) {
        super(props);

    }



    getUsername = (userId) => {
        for(let i in this.props.children_profiles){
            if(this.props.children_profiles[i].id === userId){
                return this.props.children_profiles[i].fullname
            }
        }
        return this.props.loggedUser.fullname
    }

    /**
     * When clicking a medication, the TakeMedication modal is opened
     */
    onMedicationClick = () => {
        let date = new Date(this.props.chosenDate);
        let hour = this.props.hour && this.props.hour.split(':')[0];
        let minutes = this.props.hour && this.props.hour.split(':')[1];
        let medicationEntry = this.props.medicationEntriesForEvents ? this.props.medicationEntriesForEvents[0] : null
        let medicationEvent = this.props.medicationEvents
            ? (this.props.medicationEvents.length > 1
                ? this.props.medicationEvents.sort((a, b) => (a.updated_timestamp < b.updated_timestamp) ? 1 : -1)[this.props.planCount]
                : this.props.medicationEvents[0])
            : null
        date.setHours(hour);
        date.setMinutes(minutes, 0, 0);
        let object = {
            planName: this.props.medicationPlan.name,
            dateTimePlanned: date,
            medicationEvent: medicationEvent,
            medicationEntry: medicationEntry,
        }

        this.props.showTakeMedicationModal(object, this.props.medicationPlan.scheduleType === "WHEN_NEEDED");
        this.props.setMedicationPlan(this.props.medicationPlan);
        this.props.setMedIntakeHour(this.props.hour);
        this.props.showTakeMedication();


    }

    render() {
        let medicationEntry = this.props.medicationEntriesForEvents ? this.props.medicationEntriesForEvents[0] : null
        let medicationEvent = this.props.medicationEvents
            ? (this.props.medicationEvents.length > 1
                ? this.props.medicationEvents.sort((a, b) => (a.updated_timestamp < b.updated_timestamp) ? 1 : -1).find((f)=> f.status==='CONFIRMED')//sort((a, b) => (a.updated_timestamp < b.updated_timestamp) ? 1 : -1)[this.props.planCount]
                : this.props.medicationEvents[0])
            : null
        return (
            <>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: 'start',
                    cursor: 'pointer'
                }} onClick={this.onMedicationClick}>
                    <div className="pill-picker-component"
                         style={{marginLeft: "30px", minWidth: "100px", maxWidth: "100px"}}>
                        <SelectedPill
                            primaryColour={CONVERTER.decimalHexTwosComplement(this.props.medicationPlan.shapePrimaryColor)}
                            secondaryColour={CONVERTER.decimalHexTwosComplement(this.props.medicationPlan.shapeSecondaryColor)}
                            pillSelection={this.props.medicationPlan.shapeIndex}/>
                    </div>
                    <div className="med-plan-props">
                        <div className="medications-panel-name d-flex" style={{fontSize: '1.4rem', cursor: 'pointer'}}>
                            {this.props.medicationPlan.name}
                        </div>
                        {(!medicationEvent || (medicationEvent && medicationEvent.status !== "CONFIRMED" && medicationEvent.status !== "SKIPPED")) &&
                            <div className="medication-planned d-flex">
                                {$$('take')} {this.props.medicationPlan.dose} {getUnitValuefromKey(this.props.medicationPlan.unit, this.props.medicationPlan.dose)}
                            </div>}
                        {medicationEvent && moment(medicationEvent.dateTimeScheduled).format("HH:mm") === this.props.hour && (!isSameDay(new Date(this.props.chosenDate), medicationEvent.dateTimePlanned) || (isSameDay(new Date(this.props.chosenDate), medicationEvent.dateTimePlanned) && this.props.hour !== moment(medicationEvent.dateTimePlanned).format("HH:mm"))) &&
                            <div className="medication-planned-for">
                                {$$('planned_for_information')}, {moment(medicationEvent.dateTimePlanned).format("MMM DD HH:mm")}
                            </div>}
                        {medicationEntry && medicationEvent && medicationEvent.status === "CONFIRMED" &&
                            <>
                                <div className="medication-taken d-flex">
                                    {$$('taken_on_information').replace('{amount}', medicationEntry.dose).replace('{unit}', getUnitValuefromKey(medicationEntry.unit)).replace('{dateTime}', moment(medicationEntry.date_time).format("YYYY, MMM DD"))}
                                </div>
                                <div className="medication-taken">
                                    {moment(medicationEntry.date_time).format("HH:mm")}
                                </div>
                            </>}
                        {!medicationEntry && medicationEvent && medicationEvent.status === "CONFIRMED" &&
                            <>
                                <div className="medication-taken d-flex">
                                    {$$('taken_on_information').replace('{amount}', this.props.medicationPlan.dose).replace('{unit}', getUnitValuefromKey(this.props.medicationPlan.unit)).replace('{dateTime}', moment(medicationEvent.dateTimePlanned).format("YYYY, MMM DD"))}
                                </div>
                                <div className="medication-taken">
                                    {moment(medicationEvent.dateTimePlanned).format("HH:mm")}
                                </div>
                            </>}
                        {medicationEntry && medicationEvent && medicationEvent.status === "SKIPPED" &&
                            <div className="medication-planned">
                                {$$('skipped_information').replace('{amount}', medicationEntry.dose).replace('{unit}', getUnitValuefromKey(medicationEntry.unit))}
                            </div>}
                        {!medicationEntry && medicationEvent && medicationEvent.status === "SKIPPED" &&
                            <div className="medication-planned">
                                {$$('skipped_information').replace('{amount}', this.props.medicationPlan.dose).replace('{unit}', getUnitValuefromKey(this.props.medicationPlan.unit))}
                            </div>}
                        {medicationEvent && medicationEvent.skipReason && medicationEvent.status === "SKIPPED" &&
                            <div className="medication-reminder d-flex">
                                {$$('skip_reason_' + medicationEvent.skipReason.toLowerCase())}
                            </div>}
                        {hasNotes(medicationEntry) &&
                            <div className="medication-notes medication-notes-medication-page">
                                {medicationEntry.notes}
                            </div>
                        }
                        {!hasNotes(medicationEntry) && hasNotes(medicationEvent) &&
                            <div className="medication-notes medication-notes-medication-page">
                                {medicationEvent.notes}
                            </div>
                        }
                        {!hasNotes(medicationEntry) && !hasNotes(medicationEvent) && hasNotes(this.props.medicationPlan) &&
                            <div className="medication-notes medication-notes-medication-page">
                                {this.props.medicationPlan.notes}
                            </div>}
                        {this.props.medicationPlan.inactive &&
                            <div className="medication-reminder">
                                {$$('inactive_plan')}
                            </div>}
                        {this.props.medicationPlan.refills <= this.props.medicationPlan.remaining_meds &&
                            <div className="medication-reminder">
                                {$$('inventory')}: {this.props.medicationPlan.remaining_meds}
                            </div>}

                    </div>
                    <div className="pill-picker-component medication-user-image"
                         style={{ minWidth: "100px", maxWidth: "100px"}}>
                            <UserImage
                                userID={this.props.medicationPlan.user_id}
                                classnames={"small-prof-img"}/>
                            <div>
                                {this.getUsername(this.props.medicationPlan.user_id)}
                            </div>
                    </div>
                </div>
                {!this.props.isLast && <hr className='ruler'/>}
            </>
        )
    }
}

MedicationEntry.propTypes = {
    chosenDate: PropTypes.object,
    isLast: PropTypes.bool,
    isWhenNeeded: PropTypes.bool,
    medicationEvents: PropTypes.any,
    children_profiles: PropTypes.any,
    loggedUser: PropTypes.any,
    hour: PropTypes.any,
    planCount: PropTypes.any,
    medicationEntriesForEvents: PropTypes.any,
    medicationPlan: PropTypes.object,
    setMedIntakeHour: PropTypes.func,
    setMedicationPlan: PropTypes.func,
    showTakeMedication: PropTypes.func,
    showTakeMedicationModal: PropTypes.func
}


export default MedicationEntry


function isNoObjectOrEmptyNotes(o) {
    return !(o && o.notes);
}

function hasNotes(o) {
    return o && o.notes;
}
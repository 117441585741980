import {FETCH_LATEST_VIDEO_MESSAGE_SUCCESS, LOGOUT} from "../actions/actions";

export function video_message(state = {}, action) {
    switch (action.type) {
        case FETCH_LATEST_VIDEO_MESSAGE_SUCCESS:
            return action.result
        case LOGOUT: {
            return {};
        }
        default: {
            return state;
        }
    }
}
import React from 'react'
import {authHelper} from '../../helpers/auth_helper';
import {Routes} from '../../constants/routes';
import {
    Redirect,
    Route,
    useLocation
} from 'react-router-dom'
import {DefaultContainer} from '../layout/DefaultContainer';
import Layout from "../../_metronic/layout/Layout";
import {logout} from '../../actions/auth_actions'
import {connect} from 'react-redux'
import PropTypes from "prop-types";
import {resetPreselectedAppointment, setPreselectedAppointment} from "../../actions/appointments_actions";
import history from "../../helpers/history";
import {preselectedAppointment} from "../../reducers/appointments_reducers";
import store from "../../store";
import {fetchProvider} from "../../actions/provider_actions";

/* eslint-disable react/prop-types */
export function RouteWrapper({
                                 component: Component,
                                 isPrivate,
                                 ...rest
                             }) {
    /* eslint-enable react/prop-types */

    const isLoggedIn = authHelper.isLoggedIn();
    let loc = useLocation();
    let query = new URLSearchParams(loc.search);

    /**
     * If a location state contains price and we're opening the Appointment page, store the location state containing the appointment info in the redux store.
     */
    if (loc.state?.selectedPrice && !isLoggedIn && !rest.preselectedAppointment.selectedPrice && rest.path === Routes.APPOINTMENT) {
        rest.setPreselectedAppointment(loc.state)
    }

    /**
     * Redirect user to Log in page if he tries to access a private route except if the path is to the public clinician page
     * without authentication.
     */
    if (isPrivate && !isLoggedIn && !(rest.path === Routes.CLINICIAN_NOT_LOGGED_IN)) {
        return <Redirect to={Routes.LOGIN}/>;
    }

    /**
     * If the target url is the login page, and the request comes from a link with username and password, logout current user
     */
    if (!isPrivate && isLoggedIn && rest.path === Routes.LOGIN && query.get("username") && query.get("password")) {
        rest.logout(query.get("username"), query.get("password"))
        return '';
    }

    /**
     * After login if we have preselected appointment -> open directly Appointment page to create appointment
     */
    if (rest.path === Routes.DASHBOARD && isLoggedIn && rest.preselectedAppointment.selectedPrice) {
        store.dispatch(fetchProvider(rest.preselectedAppointment.clinician.id))
        return <Redirect to={{
            pathname: Routes.APPOINTMENT,
            state: {
                ...rest.preselectedAppointment
            }}} />
    }

    /**
     * Redirect user to Main page if he tries to access a non private route
     * after being authenticated.
     */
    if (!isPrivate && isLoggedIn) {
        //if you are logged in and coming with a direct link to clinician -> redirect to the clinician page.
        if (query.has("clinician") && rest.path === Routes.CLINICIAN_NOT_LOGGED_IN) {
            return <Redirect to={ { pathname: Routes.CLINICIAN, search: loc.search} } />
        }

        //if you are logged in and coming with a direct link to clinic -> redirect to the clinic page.
        if (query.has("org") && rest.path === Routes.CLINIC_NOT_LOGGED_IN) {
            return <Redirect to={ { pathname: Routes.CLINICIANS, search: loc.search} } />
        }

        return <Redirect to={Routes.MAIN_PAGE}/>;
    }

    const Wrapper = isLoggedIn ? Layout : DefaultContainer;

    /**
     * If not included on both previous cases, redirect user to the desired route.
     */
    return (
        <Route
            {...rest}
            render={props => (
                <Wrapper>
                    <Component {...props} />
                </Wrapper>
            )}
        />
    );
}



const mapDispatchToProps = {
    logout, setPreselectedAppointment, resetPreselectedAppointment
}

function mapStateToProps(state) {
    return {
        preselectedAppointment:state.preselectedAppointment
    }
}

RouteWrapper.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    staticContext: PropTypes.object,
    preselectedAppointment: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteWrapper)

import {appointmentsService} from '../service/appointments_service'
import {
    APPOINTMENTS,
    CHANGE_APPOINTMENT_IN_APPOINTMENTS_LIST, CLEAR_APPOINTMENT_CREATION_STATUS,
    CREATE_APPOINTMENT_ERROR,
    CREATE_APPOINTMENT_REQUEST_SENT,
    CREATE_APPOINTMENT_SUCCESS,
    DUMMY,
    FETCH_APPOINTMENT_ERROR,
    FETCH_APPOINTMENT_REQUEST_SENT,
    FETCH_APPOINTMENT_SUCCESS,
    FETCH_APPOINTMENTS_ERROR,
    FETCH_APPOINTMENTS_REQUEST_SENT,
    FETCH_APPOINTMENTS_SUCCESS,
    SET_FORM_TO_DISABLED,
    SET_FORM_TO_ENABLED,
    UPDATE_APPOINTMENT_ERROR,
    UPDATE_APPOINTMENT_REQUEST_SENT,
    UPDATE_APPOINTMENT_STATUS_REQUEST_ERROR,
    UPDATE_APPOINTMENT_STATUS_REQUEST_SENT,
    UPDATE_APPOINTMENT_SUCCESS,
    SET_PRESELECTED_APPOINTMENT,
    RESET_PRESELECTED_APPOINTMENT
} from './actions';


/**
 * Fetch the appointments for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchSelectedUserAppointments(userId, intervalStart, intervalEnd) {
    return (dispatch, getState) => {
        let params = {
            after: intervalStart,
            before: intervalEnd,
            userId: userId
        };
        dispatch({type: FETCH_APPOINTMENTS_REQUEST_SENT});
        return appointmentsService.fetchSelectedUserAppointments(userId, params).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_APPOINTMENTS_SUCCESS, result: res});
            }
            return res;
        }).catch((err) => {
            dispatch({type: FETCH_APPOINTMENTS_ERROR, result: err});
        });
    }
}


export function fetchAppointment(appointmentId) {
    return (dispatch, getState) => {
        const userId = userId || getState().userInfo && getState().userInfo.data ? getState().userInfo.data.id : '';
        dispatch({type: FETCH_APPOINTMENT_REQUEST_SENT});
        return appointmentsService.fetchAppointment(appointmentId).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_APPOINTMENT_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_APPOINTMENT_ERROR, result: err});
        });
    }
}


/**
 * Update status for selected appointment, dispatch the appropriate action.
 *
 * @param {object} appointmentId - id of the selected appointment
 * @param {object} status - new status for selected appointment
 * @param {object} note - note to be sent with the appointment
 * @returns {function} dispatch function
 */
export function updateAppointmentStatus(appointmentId, status, note, userId) {
    return (dispatch) => {
        dispatch({type: UPDATE_APPOINTMENT_STATUS_REQUEST_SENT});
        return appointmentsService.updateAppointmentStatus(appointmentId, status, note, userId).then((res) => {
            if (res) {
                dispatch({type: CHANGE_APPOINTMENT_IN_APPOINTMENTS_LIST, result: res});
            }
        }).catch((err) => {
            dispatch({type: UPDATE_APPOINTMENT_STATUS_REQUEST_ERROR, result: err});
        });
    }
}


export function updateAppointmentInList(appointment) {
    return (dispatch) => {
        dispatch({type: CHANGE_APPOINTMENT_IN_APPOINTMENTS_LIST, result: appointment});
    }
}

/**
 *  Create an appointment
 *
 * @param {object} appointment The entry to be created
 */
export function createAppointment(appointment, userId) {
    return (dispatch, getState) => {
        dispatch({ type: SET_FORM_TO_DISABLED, formName : APPOINTMENTS });
        dispatch({type: CREATE_APPOINTMENT_REQUEST_SENT});
        return appointmentsService.createAppointment(appointment, userId).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: SET_FORM_TO_ENABLED});
            dispatch({type: CREATE_APPOINTMENT_SUCCESS, result: res});
        }).catch((err) => {
            dispatch({ type: SET_FORM_TO_ENABLED });
            dispatch({type: CREATE_APPOINTMENT_ERROR, result: err});
        });
    }
}


/**
 *  Update an appointment
 *
 * @param {object} appointment The entry to be updated
 */
export function updateAppointment(appointment, userId) {
    return (dispatch, getState) => {
        dispatch({ type: SET_FORM_TO_DISABLED, formName : APPOINTMENTS });
        dispatch({type: CREATE_APPOINTMENT_REQUEST_SENT});
        return appointmentsService.updateAppointment(appointment, userId).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({ type: SET_FORM_TO_ENABLED });
            dispatch({type: CREATE_APPOINTMENT_SUCCESS, result: res});
        }).catch((err) => {
            dispatch({ type: SET_FORM_TO_ENABLED });
            dispatch({type: CREATE_APPOINTMENT_ERROR, result: err});
        });
    }
}

export function clearAppointmentCreationStatus() {
    return (dispatch) => {
        dispatch({ type: CLEAR_APPOINTMENT_CREATION_STATUS});
    }
}

export function createPayment(body) {
    return (dispatch, getState) => {
        return appointmentsService.createPayment(body)
    }
}

/**
 * Create action to reset the preselected appointment in the store
 * @return {function(...[*]=)}
 */
export function resetPreselectedAppointment() {
    return (dispatch) => {
        dispatch({ type: RESET_PRESELECTED_APPOINTMENT});
    }
}

/**
 * Create action to set the object into the preselected appointment in the store
 * @param state
 * @return {function(...[*]=)}
 */
export function setPreselectedAppointment(state) {
    return (dispatch) => {
        dispatch({ type: SET_PRESELECTED_APPOINTMENT, state: state});
    }
}

import React, {Component} from 'react'
import {
    deleteImage, getClinicianPhoto,
    getUserImage,
    uploadImage
} from '../../service/users_service'
import '../../_metronic'
import noImage from '../../../public/media/profile/no_image.png'
import user_image from '../../resources/images/user_image.png'
import * as classnames from "classnames"
import history from '../../helpers/history'
import PropTypes from "prop-types";

export class UserImage extends Component {

    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
    }

    componentDidUpdate(prevProps) {

        if (this.props.needsRefresh) {
            this.fetchUserImage();
            this.props.refreshComplete();
        }

        if ((!prevProps.userID && this.props.userID) ||
            prevProps.userID && this.props.userID && prevProps.userID !== this.props.userID) {
            this.fetchUserImage();
        }
        if (this.imgRef.current && this.imgRef.current.data && prevProps.userID !== this.props.userID) {
            this.imgRef.current.src = "";
        }
    }

    componentDidMount() {
        if (this.imgRef.current && this.imgRef.current.data) {
            this.imgRef.current.src = "";
        }
        if (this.props.isLoggedUserProfile) {

            if (this.props.userID === this.props.userInfo.id) {

                this.fetchUserImage();
            }
        } else if (this.props.userID) {

            this.fetchUserImage(this.props.userID);
        }
    }

    componentWillUnmount() {
        URL.revokeObjectURL(this.imgRef.current.src);
    }

    /**
     * Fetch selected user image, if there is not found, display placeholder
     */
    fetchUserImage = (userID) => {
        if (history.location.pathname !== '/register') {
            try {
                let userImage = this.props.clinician ? getClinicianPhoto(this.props.userID) : getUserImage(this.props.userID);
                userImage.then((res) => {


                    if (this.imgRef.current) {
                        if (!userID || (userID && this.props.userID === userID)) {
                            if (this.props.setProfilePictureState) {
                                this.props.setProfilePictureState(true);
                            }
                            this.imgRef.current.type = "image/jpg"
                            this.imgRef.current.src = res;
                        }
                    }
                }).catch(() => {

                    if (this.props.setProfilePictureState) {
                        this.props.setProfilePictureState(false);
                    }
                });
            } finally {
                if (this.imgRef.current) {
                    this.imgRef.current.type = "image/png"
                    this.imgRef.current.src = history.location.pathname === '/register' ? user_image : noImage;
                }
            }
        } else if (history.location.pathname === '/register') {
            if (this.props.blob !== null) {
                if (this.imgRef.current) {
                    if (this.props.setProfilePictureState) {
                        this.props.setProfilePictureState(true);
                    }
                    this.imgRef.current.type = "image/jpg"
                    this.imgRef.current.src = this.props.blob;
                }
            } else {
                this.imgRef.current.type = "image/png"
                this.imgRef.current.src = user_image;
                this.setState({hasImage: false});
            }
        }
    }

    /**
     * On select image handler. Upload new image. If successful show the image.
     * Check the image type for unsupported media, if so display error message.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectImage = (evt) => {


        const file = evt.target.files[0];

        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/png') {
                uploadImage(file, this.props.selectedUser, this.props.loggedInUserId).then(() => {
                    this.imgRef.current.src = URL.createObjectURL(file);
                    this.setState({
                        errors: {...this.state.errors, unsupprotedImage: false},
                        hasImage: true
                    });
                });
            } else {
                this.setState({
                    errors: {...this.state.errors, unsupprotedImage: true}
                });
            }
        }
    };


    /**
     * Delete user image, if request is successful show default placeholder
     */
    onDeleteImage = () => {
        deleteImage(this.props.selectedUser, this.props.loggedInUserId).then(() => {
            this.imgRef.current.type = "image/png"
            this.imgRef.current.src = noImage;
            this.setState({hasImage: false});
        });
    };


    render() {
        return (
            <img
                className={classnames(history.location.pathname === "/register" ? "upload-img" : "user-info-image", this.props.classnames)}
                ref={this.imgRef} alt="User Image"/>
        )
    }
}

UserImage.propTypes = {
    classnames: PropTypes.string,
    loggedInUserId: PropTypes.any,
    blob: PropTypes.any,
    selectedUser: PropTypes.object,
    userInfo: PropTypes.object,
    needsRefresh: PropTypes.bool,
    isLoggedUserProfile: PropTypes.bool,
    refreshComplete: PropTypes.func,
    setProfilePictureState: PropTypes.func,
    userID: PropTypes.string,
};

export default UserImage

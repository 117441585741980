import {
    AUTO_LOGOUT,
    DUMMY, FETCH_CHAT_MESSAGES_REQUEST_SUCCESS,
    FETCH_CHAT_UNREAD_MESSAGES_COUNT_SUCCESS,
    LOGOUT,
    NEW_MESSAGE_ARRIVED
} from "../actions/actions";
import history from "../helpers/history";
import {Routes} from "../constants/routes";
import {authService} from "../service/auth_service";
import "core-js/stable";
// Needed for the generator functions which are transpiled from async await keywords
import "regenerator-runtime/runtime";

const MS_TO_MINUTE = 60 * 1000; //1 min

export function autoLogout(minutes = 30) {
    let timeoutInMins = minutes * MS_TO_MINUTE;
    let logoutFunction = async (dispatch, logged) => {
        if (!logged) {
            return;
        }

        let twillio = window.twilioRoom;
        if (!twillio || twillio?.state === "disconnected") {
            try {
                await authService.autoLogoutServer()
            } catch (e) {
                console.log(e)
            } finally {
                dispatch({type: LOGOUT});
                dispatch({type: AUTO_LOGOUT, message: "AUTO_LOGOUT"});
                history.push(Routes.LOGIN);
            }
        }
    };
    return function (_ref) {
        let getState = _ref.getState;
        let dispatch = _ref.dispatch;

        let timeout = setTimeout(logoutFunction, timeoutInMins, dispatch, getState().userInfo.data?.id);
        return function (next) {
            return function (action) {
                let actionsToIgnore = [
                    NEW_MESSAGE_ARRIVED,
                    FETCH_CHAT_UNREAD_MESSAGES_COUNT_SUCCESS,
                    DUMMY,
                    FETCH_CHAT_MESSAGES_REQUEST_SUCCESS
                ]

                if (!(actionsToIgnore.includes(action.type))) {
                    clearTimeout(timeout);
                    timeout = setTimeout(logoutFunction, timeoutInMins, dispatch, getState().userInfo.data?.id);
                }
                return next(action);
            }
        }
    }
}
import React, {Component} from "react";
import {$$} from "../../helpers/localization";
import PropTypes from "prop-types";

export default class MedicationsTabs extends Component {
    render() {
        return (
            <div>
                <div className="d-flex mt-2 mb-2" style={{fontSize: "1.4rem", minHeight: "2.5rem"}}>
                            <span onClick={this.props.medicationsClick}
                                  className={this.props.showMedicationPlans ? 'clinician-tabs-active' : 'clinicians-tab'}>{$$('Medication_plans')}</span>
                    <span onClick={this.props.medicationsPlansClick}
                          className={!this.props.showMedicationPlans ? 'clinician-tabs-active ml-4' : 'clinicians-tab ml-4'}>{$$('Medications')}</span>
                </div>
            </div>
        )
    }
}

MedicationsTabs.propTypes = {
    medicationsClick: PropTypes.func,
    medicationsPlansClick: PropTypes.func,
    showMedicationPlans: PropTypes.bool,
    showMedications: PropTypes.bool
}

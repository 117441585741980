import React, {Component} from 'react'
import {getTreeViewSymptoms} from '../../utils/symptomUtils';
import {$$} from '../../helpers/localization';
import PropTypes from "prop-types";

class FormBox extends Component {
    state = {
        strength: this.props.strength,
        firstRadioBtnClass: this.props.strength >= 1 ? 'symptom-strength-cell-full symptom-strength-cell-low' : "symptom-strength-cell-empty",
        secondRadioBtnClass: this.props.strength >= 2 ? 'symptom-strength-cell-full symptom-strength-cell-medium' : "symptom-strength-cell-empty",
        thirdRadioBtnClass: this.props.strength >= 3 ? 'symptom-strength-cell-full symptom-strength-cell-high' : "symptom-strength-cell-empty",
        check: false,
        checkNoSymptoms: false,
        error: false,
    }

    constructor(props) {
        super(props);
    }

    onRadioChange = (evt) => {
        const strength = evt.target.value;
        this.resetSelection(strength.substring(0, 2));
    };

    onCheckBoxChange = () => {
        if (this.props.selected) {
            this.props.onChange({description: this.props.description, type: this.props.type, strength: 0})
        } else {
            this.setState({
                strength: 1,
                firstRadioBtnClass: 'symptom-strength-cell-full symptom-strength-cell-low',
                secondRadioBtnClass: 'symptom-strength-cell-empty ',
                thirdRadioBtnClass: 'symptom-strength-cell-empty',
                check: true
            }, function () {
                this.props.onChange({
                    description: this.props.description,
                    type: this.props.type,
                    strength: this.state.strength
                })
            });
        }
    }

    resetSelection = (strength) => {

        if (strength === "R1") {
            this.setState({
                strength: strength[1],
                firstRadioBtnClass: 'symptom-strength-cell-full symptom-strength-cell-low',
                secondRadioBtnClass: 'symptom-strength-cell-empty',
                thirdRadioBtnClass: 'symptom-strength-cell-empty',
                check: true
            }, function () {
                this.props.onChange({
                    description: this.props.description,
                    type: this.props.type,
                    strength: this.state.strength
                });
            });
        } else if (strength === "R2") {
            this.setState({
                strength: strength[1],
                firstRadioBtnClass: 'symptom-strength-cell-full symptom-strength-cell-low',
                secondRadioBtnClass: 'symptom-strength-cell-full symptom-strength-cell-medium',
                thirdRadioBtnClass: 'symptom-strength-cell-empty',
                check: true
            }, function () {
                this.props.onChange({
                    description: this.props.description,
                    type: this.props.type,
                    strength: this.state.strength
                });
            });
        } else if (strength === "R3") {
            this.setState({
                strength: strength[1],
                firstRadioBtnClass: 'symptom-strength-cell-full symptom-strength-cell-low',
                secondRadioBtnClass: 'symptom-strength-cell-full symptom-strength-cell-medium',
                thirdRadioBtnClass: 'symptom-strength-cell-full symptom-strength-cell-high',
                check: true
            }, function () {
                this.props.onChange({
                    description: this.props.description,
                    type: this.props.type,
                    strength: this.state.strength
                });
            });
        }
    }

    getStrength = () => {
        return <>
            <div className="strength-cell-container">
                <input className="strength-radio" type='radio' id={"R1" + this.props.index} name='strength'
                       value={"R1" + this.props.index} onChange={this.onRadioChange}/>
                <label className="pb-2 pointer" htmlFor={"R1" + this.props.index}>
                    <div className={this.state.firstRadioBtnClass}/>
                </label>
            </div>
            <div className="strength-cell-container">
                <input className="strength-radio" type='radio' id={"R2" + this.props.index} name='strength'
                       value={"R2" + this.props.index} onChange={this.onRadioChange}/>
                <label className="pb-2 pointer" htmlFor={"R2" + this.props.index}>
                    <div className={this.state.secondRadioBtnClass}/>
                </label>

            </div>
            <div className="strength-cell-container">
                <input className="strength-radio" type='radio' id={"R3" + this.props.index} name='strength'
                       value={"R3" + this.props.index} onChange={this.onRadioChange}/>
                <label className="pb-2 pointer" htmlFor={"R3" + this.props.index}>
                    <div className={this.state.thirdRadioBtnClass}/>
                </label>
            </div>
        </>
    }

    render() {
        let symptomsTree = getTreeViewSymptoms(this.props.i18n.lang);
        let nomenclature = "";
        symptomsTree.map((symptom) => {
            if (this.props.description && this.props.description.toLowerCase() === symptom.description.substring(0, this.props.description.length).toLowerCase()) {
                nomenclature = symptom.nomenclature
            }
        })

        let type = "UNDEFINED"
        symptomsTree.map((symptom) => {
            if (symptom.nomenclature === (nomenclature[0])) {
                type = symptom.description
            }
        })
        return (
            <>
                {!(this.props.type === 'NO_SYMPTOMS') && <div className="symptom-form-box ">
                    <div className="row">
                        <div className="col-1 checkbox-container">
                            <input onChange={this.onCheckBoxChange} checked={this.props.selected} id={this.props.index}
                                   className="symptom-checkbox" type="checkbox"/>
                            <label className="checkbox-label" htmlFor={this.props.index}>
                                <i className="fa fa-check wht" aria-hidden="true"/>
                            </label>
                        </div>
                        <div className="col-5 ml-2">
                            <div className="row symptom-item-type">
                                {type === "" ? '\u00A0' : type.replace("_", " ").replace("_", " ")}
                            </div>
                            <div className="row symptom-item-name"> {this.props.description}</div>
                        </div>
                        <div className="col-4 flex-space-between">
                            {this.getStrength()}
                        </div>
                    </div>
                </div>
                }
                {(this.props.type === 'NO_SYMPTOMS') && <div className="symptom-form-box ">
                    <div className="row">
                        <div className="col-1 checkbox-container">
                            <input onChange={this.onCheckBoxChange} checked={this.props.selected} id={this.props.index}
                                   className="symptom-checkbox" type="checkbox"/>
                            <label className="checkbox-label" htmlFor={this.props.index}>
                                <i className="fa fa-check wht" aria-hidden="true"/>
                            </label>
                        </div>
                        <div className="col-9 ml-2">
                            <div className="row symptom-item-type">
                                {'\u00A0'}
                            </div>
                            <div className="row symptom-item-name"> {$$('no_symptoms_label')}</div>
                        </div>
                    </div>
                </div>
                }
            </>
        )
    }
}

FormBox.propTypes = {
    description: PropTypes.string,
    i18n: PropTypes.object,
    index: PropTypes.any,
    onChange: PropTypes.func,
    selected: PropTypes.bool,
    strength: PropTypes.any,
    type: PropTypes.string
};

export default FormBox

import React from 'react';
import store from "../../store";

class QuestionnaireUtil {
    static resolveLanguage = (availableLanguages) => {
        const appLanguage = this.getAppLanguage();
        const languageSupported = availableLanguages.some(language => language === appLanguage);

        if (languageSupported) {
            return appLanguage;
        }
        return availableLanguages[0];
    };

    static getAppLanguage = () => {
        return store.getState().language.selected.lang;
    };
}


export default QuestionnaireUtil;

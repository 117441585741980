import React, {Component} from 'react'
import {ChatMessages} from './ChatMessages';
import ChatInput from './ChatInput';
import moment from 'moment';
import noImage from '../../../../public/media/profile/no_image.png'
import {getUserImage} from '../../../service/users_service';
import {fetchChatMessages} from '../../../actions/chat_actions';
import PropTypes from "prop-types";
import {canChatWithUser} from "../../../service/chat_service";
import {$$, _$$} from "../../../helpers/localization";
import Search from "../../shared/Search";
import {formatDate} from "../../../utils/converter";


export class ChatWithMessages extends Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
        this._ismounted = true;
        this.state = {
            canSendMessages: true,
            selectedMessages: [],
            index: 0,
            showSearch: false

        }
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    /**
     * Fetch the user image if the user changes.
     *
     * @param {object} prevProps - the previous component properties object
     */
    componentDidUpdate(prevProps) {
        if (this.imgRef.current && this.imgRef.current.data && prevProps.selectedUserId !== this.props.selectedUserId) {
            this.imgRef.current.src = "";
        }
        fetchChatMessages();
        if (prevProps.selectedUserId !== this.props.selectedUserId) {
            this.fetchUserImage();
            this.checkIfCanSendMessage();
        }
    }

    /**
     * Fetch the user image when the component mounts.
     */
    componentDidMount() {
        if (this.imgRef.current && this.imgRef.current.data) {
            this.imgRef.current.src = "";
        }

        this.checkIfCanSendMessage();
        this.fetchUserImage();
    }

    checkIfCanSendMessage = () => {
        if(this.props.selectedUserId) {
            canChatWithUser(this.props.selectedUserId).then((res) => {
                if (this._ismounted) {
                    this.setState({canSendMessages: res.canSendMessage, unblockDateTime: res.unblockDateTime})
                }
            }).catch((e) => {
                console.log(e)
            });
        }
    }

    /**
     * Fetch selected user image, if there is not found, display placeholder
     */
    fetchUserImage = () => {
        try {
            if(this.props.selectedUserId) {
                getUserImage(this.props.selectedUserId).then((res) => {
                    this.imgRef.current.type = "image/jpg"
                    this.imgRef.current.src = res;
                }).catch(() => {
                });
            }
        } finally {
            if (this.imgRef && this.imgRef.current) {
                this.imgRef.current.type = "image/png"
                this.imgRef.current.src = noImage;
            }
        }
    }

    /**
     * Send message to other user in chat
     *
     * @param {object} msg - the message to send
     */
    onSendMessage = (msg) => {
        const message = {
            message: msg,
            from_user_id: this.props.loggedUser.id,
            to_user_id: this.props.selectedUserId,
            dateTime: moment().valueOf()
        };
        canChatWithUser(this.props.selectedUserId).then((res) => {
            this.setState({canSendMessages: res.canSendMessage, unblockDateTime:res.unblockDateTime})
            if (res.canSendMessage) {
                this.props.sendMessage(message);
            }
        }).catch(() => {
        });

    }

    scrollSearchIntoView = (searchTerm) => {
        let element = document.getElementById(searchTerm);
        element.scrollIntoView();
    }

    goToNext = () => {
        if (this.state.selectedMessages.length > 0) {
            if (this.state.index < this.state.selectedMessages.length) {
                this.scrollSearchIntoView(this.state.selectedMessages[this.state.index].id)
                this.setState({index: this.state.index + 1})
            } else {
                this.scrollSearchIntoView(this.state.selectedMessages[0].id)
                this.setState({index: 1})
            }
        }
    }

    goToPrevious = () => {
        if (this.state.selectedMessages.length > 0) {
            if (this.state.index > 1) {
                this.scrollSearchIntoView(this.state.selectedMessages[this.state.index - 2].id)
                this.setState({index: this.state.index - 1})
            } else {
                this.scrollSearchIntoView(this.state.selectedMessages[this.state.selectedMessages.length + -1].id)
                this.setState({index: this.state.selectedMessages.length})
            }
        }
    }

    onSearchChange = (searchTerm) => {
        this.setState({
            searchTerm: searchTerm,
            index: 0
        })

        if (searchTerm !== "") {
            this.searchMessagesForTerm(searchTerm)
        } else {
            this.setState({
                selectedMessages: [],
                index: 0,
            })
        }
    }

    searchMessagesForTerm = (term) => {

        let MessagesThatContainTerm = []

        for (let i in this.props.messages) {
            if (this.props.messages[i].message?.includes(term)) {
                MessagesThatContainTerm.push(this.props.messages[i])
            }
        }

        this.setState({selectedMessages: MessagesThatContainTerm})
    }

    render() {
        if (!this.props.selectedUserId || this.props.selectedUserId === this.props.loggedUser.id) {
            return '';
        }
        const unreadMessagesFromUser = this.props.unreadMessages.find(m => m.sender === this.props.selectedUserId);
        return (
            <div className={`card chat-card chat-safari `}
                 style={{ "width": this.props.smallMessages || this.props.messagesSmall ? "100%" : "calc(100% - 335px)"}}>
                <div className="" style={{"background": "white"}}>
                    <div className="chat-card-title">
                        <div className={"dropdown-link"}>
                            {this.props.smallMessages &&
                                <button className="btn btn-outline-primary btn-icon btn-icon-sm btn-circle btn-sm"
                                        style={{marginBottom: "0.25rem"}}
                                        onClick={() => {
                                            this.setState(this.props.deSelectUser)
                                        }}>
                                    <i className='fas fa-arrow-left centered-text'/>
                                </button>}
                            <div className='row'
                                 style={{
                                     "marginLeft": "0",
                                     "marginRight": "0",
                                     "paddingRight": this.props.messagesSmall ? "0px" : "10px"
                                 }}>
                                <div className='d-flex justify-content-center appointment-img-container'
                                     style={{"height": "41.6px"}}>
                                    <img className="user-info-image patient-img"
                                         ref={this.imgRef}
                                         style={{"marginLeft": "0", "zIndex": "9001"}}
                                         alt="User chat image"/>
                                </div>
                                <div className='row name-and-email space-between-justify'
                                     style={{'marginLeft': '5px'}}>
                                    <div className={"dropdown-link"}>
                                        <div style={{'fontSize': '1.5rem'}}>{this.props.selectedUserFullName}</div>
                                        <div
                                            className='lighter-font'>{this.props.selectedUserEmail.includes("@") ? this.props.selectedUserEmail : ""}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="med-history-button">
                            <button
                                className={this.state.showSearch ? "btn btn-outline-primary btn-icon btn-icon-sm btn-circle btn-sm btn-secondary" : "btn btn-outline-primary btn-icon btn-icon-sm btn-circle btn-sm"}
                                style={{marginLeft: "0.5rem", marginBottom: "0.25rem"}}
                                onClick={() => {
                                    this.setState({showSearch: !this.state.showSearch})
                                }}>
                                <i className='fas fa-search centered-text'/>
                            </button>
                        </div>
                    </div>
                </div>
                <div id="chat-card-safari" className="card-body chat-card-body">
                    {this.state.showSearch && <div style={{marginTop: "1rem"}}>

                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "baseline"
                        }}>
                            <div style={{width: "60%"}}>
                                <Search handleSearchChange={this.onSearchChange}
                                        searchValue={this.state.searchTerm}
                                        placeholder={$$('search_within_selected_messages_label')}/>
                            </div>
                            <div style={{width: "20%"}}>
                                <div className="btn-group align-self-center" role="group" aria-label="Basic example">
                                    <button onClick={this.goToPrevious} className='btn btn-secondary'><i
                                        className="fa fa-angle-left"/></button>
                                    <button onClick={this.goToNext} className='btn btn-secondary'><i
                                        className="fa fa-angle-right"/></button>
                                </div>
                            </div>
                            <div style={{width: "8%"}}>
                                {this.state.index} {$$("of")} {this.state.selectedMessages.length} &nbsp;
                            </div>
                        </div>
                    </div>}
                    <div className="conversation" style={{height: this.state.showSearch ? "85%" : "100%"}}>
                        <ChatMessages messages={this.props.messages} loggedUser={this.props.loggedUser}
                                      selectedMessages={this.state.selectedMessages}
                                      unreadMessagesFromUser={unreadMessagesFromUser}/>
                    </div>
                </div>
                {this.state.canSendMessages && <ChatInput sendMessage={this.onSendMessage}/>}
                {!this.state.canSendMessages && <div className={"text-danger chat-error-txt"}>{this.state.unblockDateTime ? _$$("chat_disabled_message_with_unlock_time", formatDate(this.state.unblockDateTime)) : $$("chat_disabled_message")}</div>}
            </div>
        )
    }
}

ChatWithMessages.propTypes = {
    loggedUser: PropTypes.object,
    messages: PropTypes.array,
    selectedUserEmail: PropTypes.string,
    selectedUserFullName: PropTypes.string,
    selectedUserId: PropTypes.string,
    sendMessage: PropTypes.func,
    canChatWithUser: PropTypes.func,
    unreadMessages: PropTypes.array,
    users: PropTypes.array
}


export default ChatWithMessages

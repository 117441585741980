import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import Search from '../shared/Search';
import Select from '../shared/Select'
import PropTypes from "prop-types";
import {ClinicianFilters} from "./ClinicianFilters";

class CliniciansHeader extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div className="form-inline" style={{'backgroundColor': 'white'}}>
                <div className='flex-space-between flex-wrap w-100'>
                    <h1>{this.props.showFilters ? $$('search_for_clinicians_label') : $$('look_for_hospitals_label')}</h1>
                    <div className="flex-wrap">


                        <ClinicianFilters
                            specialtiesOptions = {this.props.specialtiesOptions}
                            specialties = {this.props.specialties}
                            onSelectChange = {this.props.onSelectChange}
                            filterCliniciansByName = {this.props.filterCliniciansByName}
                            i18n = {this.props.i18n}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

CliniciansHeader.propTypes = {
    filterCliniciansByName: PropTypes.func,
    onSelectChange: PropTypes.func,
    showFilters:  PropTypes.bool,
    specialties:  PropTypes.any,
    specialtiesOptions: PropTypes.array,
    i18n: PropTypes.array
}

export default CliniciansHeader

import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import Select from "../shared/Select";
import Search from "../shared/Search";
import PropTypes from "prop-types";


export class ClinicianFilters extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <div className="" style={{'display': 'flex', 'marginRight': '2rem'}}>
                    <Select
                        name="specialties"
                        options={this.props.specialtiesOptions}
                        value={this.props.specialties ? this.props.specialties : "UNDEFINED"}
                        onChange={this.props.onSelectChange}
                        placeHolder={$$('specialties')}
                        style={{
                            'minWidth': '-10.188rem',
                            'minHeight': '2.938rem',
                            'maxWidth': '18.75rem',
                            'zIndex': '1',
                            'opacity': '70%'
                        }}
                    />
                </div>
                <div className='search-box2 search-box-clinicians' style={{'marginRight': '2rem', marginTop: this.props.marginTop ?? 0}}>
                    <Search
                        filterUsers={this.props.filterCliniciansByName}
                        placeholder={$$('search_for_specialties_or_clinicians_label')}
                        style={{'width': '17.5rem'}}/>
                </div>
            </>
        )
    }
}


ClinicianFilters.propTypes = {
    specialtiesOptions: PropTypes.any,
    specialties: PropTypes.any,
    onSelectChange: PropTypes.func,
    filterCliniciansByName: PropTypes.func,
    i18n: PropTypes.object
}
import React, {Component} from 'react'
import {connect} from 'react-redux'
import UserProfile from "../profile/UserProfile";
import {$$} from "../../helpers/localization";
import {
    createLogbookEntry,
    fetchSelectedUserLogbookEntries,
    updateLogbookEntry,
    clearLogbook
} from "../../actions/logbook_actions";
import {
    changeChartsFilters,
    clearSelectedUserData,
    fetchChartsData,
    clearChartsData,
    fetchDashboardChartsData
} from "../../actions/users_actions";
import {fetchSelectedUserSymptomsLog, clearSymptomsLog} from "../../actions/symptoms_actions";
import {
    changeDocumentsFilters,
    fetchSelectedUserDocuments,
    clearUserDocuments
} from "../../actions/documents_actions";
import {
    changeLabResultsFilters,
    clearLabDates,
    fetchLaboratories,
    fetchSelectedUserLabResults,
    clearUserLabResults
} from "../../actions/lab-results_actions";
import {fetchSelectedUserHealthIssues} from "../../actions/health-issues_actions";
import Tabs from "./Tabs";
import Tab from "./Tab";
import Logbook from "../loogbok/Logbook";
import SymptomsLog from "../symptoms/SymptomsLog";
import ChartFilters from "./ChartFilters";
import Charts from "../charts/Charts";
import DocumentsFilters from "../documents/DocumentsFilters";
import Documents from "../documents/Documents";
import LabResultsFilters from "../lab-results/LabResultsFilters";
import LabResults from "../lab-results/LabResults";
import {getHealthIssueOptionsNoLowerCase} from '../../constants/select_options'
import PropTypes from "prop-types";

class ChildMedicalRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUserId: props.selectedUser ? props.selectedUser.id : null,
            showFilters: true,
            selectedIndex: this.props.location.state && this.props.location.state.index ? this.props.location.state.index : 0,
            selectedHealthIssue: this.props.location.state && this.props.location.state.selectedHealthIssue ? this.props.location.state.selectedHealthIssue : null,
            requestSent: false,
            searchValue: "",
            pickedSelectOption: this.props.location.state && this.props.location.state.selectedHealthIssue ? this.props.location.state.selectedHealthIssue.id : 0,
            updateModal: false,
            hasContent: !!(this.props.location.state && this.props.location.state.selectedHealthIssue),
        }
    }

    clearPreSelectedHealthIssue = () => {
        this.setState({selectedHealthIssue:null});
        if (this.props.location.state && this.props.location.state.selectedHealthIssue) {
            const {selectedHealthIssue, ...rest} = this.props.location.state;
            this.props.history.replace({state:rest});
        }
    }

    onHasContent = (hasContent) => {
        this.setState({hasContent: hasContent})
    }


    onHideUpdateModal = () => {
        this.setState({
            updateModal: false

        })
    }

    showUpdateModal = () => {
        this.setState({
            updateModal: true
        })
    }

    componentDidMount() {
        this.props.fetchLaboratories();
        if (this.props.selectedUser.id) {
            this.props.fetchSelectedUserHealthIssues(this.props.selectedUser.id, null);
        }
    }


    hideFilters = () => {
        this.setState({
            showFilters: false,
        })
    }
    showFilters = () => {
        this.setState({
            showFilters: true,
        })
    }
    componentWillUnmount() {
        this.props.clearChartsData();
    }


    /**
     * Fetch the charts data if the filters or language has changed
     */
    componentDidUpdate(prevProps) {
        if (prevProps.selectedUser.id && this.props.selectedUser.id) {
            if ((prevProps.chartFilters.period !== this.props.chartFilters.period) ||
                (prevProps.chartFilters.before_date_time !== this.props.chartFilters.before_date_time) ||
                (prevProps.chartFilters.after_date_time !== this.props.chartFilters.after_date_time) ||
                (prevProps.i18n.selected.lang !== this.props.i18n.selected.lang)) {
                this.props.fetchChartsData(this.props.selectedUser.id);
                this.props.fetchDashboardChartsData(this.props.selectedUser.id);
            }
            if (prevProps.documents.filters.before_date_time !== this.props.documents.filters.before_date_time) {
                this.props.fetchSelectedUserDocuments(this.props.selectedUser.id, null, true);
            }
            if (prevProps.labResults.filters.before_date_time !== this.props.labResults.filters.before_date_time) {
                this.props.fetchSelectedUserLabResults(this.props.selectedUser.id, null, true);
            }
            if (prevProps && prevProps.healthIssues.entries !== this.props.healthIssues.entries && !this.state.requestSent) {
                this.setState({
                    requestSent: true
                });
            }

        }
    }

    /**
     * Get the options for the documents category filter
     *
     * @returns {array} options for the documents category filter
     */
    getDocumentsCategoryOptions = () => {
        const categories = [{text: $$('select_category'), value: ''}];
        if (this.props.documents &&
            this.props.documents.selectedUser &&
            this.props.documents.selectedUser.entries &&
            this.props.documents.selectedUser.entries.length > 0) {
            for (let doc of this.props.documents.selectedUser.entries) {
                if (categories.length === 11) {
                    break;
                }
                if (doc.category && !categories.some(c => c.value === doc.category)) {
                    categories.push({text: doc.category, value: doc.category});
                }
            }
        }
        return categories;
    }
    /**
     * Get the options for the lab results laboratory filter
     *
     * @returns {array} options for the lab results laboratory filter
     */
    getLabResultsLaboratoryOptions = () => {
        const laboratories = [{text: $$('all_laboratory_label'), value: ''}];
        if (this.props.labs && this.props.labs.length > 0) {
            for (let lab of this.props.labs) {
                if (laboratories.length === 11) {
                    break;
                }
                if (lab.name && !laboratories.some(l => l.value === lab.name)) {
                    laboratories.push({text: lab.name, value: lab.lab});
                }
            }
        }
        return laboratories;
    }


    getOptions(options) {
        const optionArray = getHealthIssueOptionsNoLowerCase(options);
        optionArray && optionArray.unshift({value: "0", text: $$("all_issues")})
        return optionArray;
    }

    onSelectChange = (evt) => {
        this.setState({
            pickedSelectOption: evt.value,
            selectedHealthIssue: null,
            hasContent: false
        })
        this.clearPreSelectedHealthIssue();
    }

    onSearchChange = (evt) => {
        this.setState({
            searchValue: evt,
            hasContent: false
        })
    }

    onClick(idx) {
        this.setState({selectedIndex: idx})
    }

    render() {
        return (
            <div className="wrapping-div">
                <Tabs defaultActiveTabIndex={this.state.selectedIndex ? this.state.selectedIndex : 0}>
                    <Tab tabName={$$('dashboard')} tabNumber={1}>
                        <UserProfile/>
                    </Tab>
                    <Tab tabName={$$('Logbook')} tabNumber={2}>
                        <Logbook userId={this.props.selectedUser.id}
                                 logbook={this.props.logBook}
                                 isLastPage={this.props.logBook.isLastPage}
                                 isFinished={this.props.logBook.request.finished}
                                 fetchEntries={this.props.fetchSelectedUserLogbookEntries}
                                 clearLogbook={this.props.clearLogbook}
                                 createEntry={this.props.createLogbookEntry}
                                 updateEntry={this.props.updateLogbookEntry}
                                 settings={this.props.settings}
                                 selectedUser={this.props.selectedUser}
                                 i18n={this.props.i18n.selected}
                                 fetchSelectedUserHealthIssues={this.props.fetchSelectedUserHealthIssues}
                                 healthIssues={this.props.healthIssues}
                                 clearPreSelectedHealthIssue={this.clearPreSelectedHealthIssue}
                                 selectedHealthIssue={this.state.selectedHealthIssue}/>
                    </Tab>
                    <Tab tabName={$$('symptoms')} tabNumber={3}>
                        <SymptomsLog userId={this.props.selectedUser.id}
                                     symptoms={this.props.symptoms}
                                     clearSymptomsLog={this.props.clearSymptomsLog}
                                     nomenclature={this.props.nomenclature}
                                     fetchEntries={this.props.fetchSelectedUserSymptomsLog}
                                     settings={this.props.settings}
                                     i18n={this.props.i18n.selected}
                                     fetchSelectedUserHealthIssues={this.props.fetchSelectedUserHealthIssues}
                                     healthIssues={this.props.healthIssues}
                                     clearPreSelectedHealthIssue={this.clearPreSelectedHealthIssue}
                                     selectedHealthIssue={this.state.selectedHealthIssue}/>
                    </Tab>
                    <Tab tabName={$$('Charts')} tabNumber={4}>
                        <ChartFilters
                            i18n={this.props.i18n}
                            selectedUser={this.props.selectedUser}
                            filters={this.props.chartFilters}
                            changeChartsFilters={this.props.changeChartsFilters}
                        />
                        <Charts
                            userId={this.props.selectedUser.id}
                            fetchChartsData={this.props.fetchChartsData}
                            clearChartsData={this.props.clearChartsData}
                        />
                    </Tab>
                    <Tab tabName={$$('documents')} tabNumber={5}>
                        {this.state.showFilters &&
                        <DocumentsFilters
                            documentsOptions={this.getOptions(this.props.healthIssues.entries)}
                            pickedOption={this.state.pickedSelectOption}
                            onSelectChange={this.onSelectChange}
                            onSearchChange={this.onSearchChange}
                            i18n={this.props.i18n}
                            filters={this.props.documents.filters}
                            categoryOptions={this.getDocumentsCategoryOptions()}
                            changeDocumentsFilters={this.props.changeDocumentsFilters}
                            healthIssues={this.props.healthIssues}
                            updateModal={this.state.updateModal}
                        />
                        }
                        <Documents selectedUser={this.props.selectedUser}
                                   clearUserDocuments = {this.props.clearUserDocuments}
                                   searchValue={this.state.searchValue}
                                   pickedSelectOption={this.state.pickedSelectOption}
                                   filters={this.props.documents.filters}
                                   documents={this.props.documents.selectedUser}
                                   fetchEntries={this.props.fetchSelectedUserDocuments}
                                   i18n={this.props.i18n.selected}
                                   hideFilters={this.hideFilters}
                                   showFilters={this.showFilters}
                                   fetchSelectedUserHealthIssues={this.props.fetchSelectedUserHealthIssues}
                                   healthIssues={this.props.healthIssues}
                                   onHideUpdateModal={this.onHideUpdateModal}
                                   showUpdateModal={this.showUpdateModal}
                                   settings={this.props.settings}
                                   onHasContent={this.onHasContent}
                                   hasContent={this.state.hasContent}
                                   selectedHealthIssue={this.state.selectedHealthIssue}

                        />
                    </Tab>
                    <Tab tabName={$$('Lab_Results')} tabNumber={6}>
                        <LabResultsFilters
                            i18n={this.props.i18n}
                            filters={this.props.labResults.filters}
                            laboratoryOptions={this.getLabResultsLaboratoryOptions()}
                            changeLabResultsFilters={this.props.changeLabResultsFilters}
                            labResults={this.props.labResults.selectedUser}
                        />
                        <LabResults selectedUser={this.props.selectedUser}
                                    labResults={this.props.labResults.selectedUser}
                                    labs={this.props.labs}
                                    clearUserLabResults ={this.props.clearUserLabResults}
                                    fetchEntries={this.props.fetchSelectedUserLabResults}
                                    i18n={this.props.i18n.selected}
                                    fetchSelectedUserHealthIssues={this.props.fetchSelectedUserHealthIssues}
                                    healthIssues={this.props.healthIssues}
                                    filters={this.props.labResults.filters}
                                    laboratoryOptions={this.getLabResultsLaboratoryOptions()}
                                    changeLabResultsFilters={this.props.changeLabResultsFilters}
                                    labDates={this.props.labDates}
                                    clearLabDates={this.props.clearLabDates}
                                    fetchSelectedUserDocuments={this.props.fetchSelectedUserDocuments}/>
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        i18n: state.language,
        selectedUser: state.selectedUser.data,
        logBook: state.logBook,
        symptoms: state.symptoms,
        nomenclature: state.nomenclature,
        settings: state.settings,
        chartFilters: state.charts.filters,
        documents: state.documents,
        labs: state.laboratories.labs,
        labResults: state.labResults,
        auth: state.authentication,
        healthIssues: state.healthIssues,
        labDates: state.labDates
    }
}

const mapDispatchToProps = {
    fetchSelectedUserLogbookEntries,
    createLogbookEntry,
    updateLogbookEntry,
    clearSelectedUserData,
    changeChartsFilters,
    fetchChartsData,
    fetchDashboardChartsData,
    fetchSelectedUserSymptomsLog,
    fetchSelectedUserDocuments,
    changeDocumentsFilters,
    fetchLaboratories,
    fetchSelectedUserLabResults,
    changeLabResultsFilters,
    fetchSelectedUserHealthIssues,
    clearLabDates,
    clearLogbook,
    clearSymptomsLog,
    clearUserDocuments,
    clearChartsData,
    clearUserLabResults
}

ChildMedicalRecord.propTypes = {
    auth: PropTypes.object,
    changeChartsFilters: PropTypes.func,
    changeDocumentsFilters: PropTypes.func,
    changeLabResultsFilters: PropTypes.func,
    chartFilters: PropTypes.object,
    clearLabDates: PropTypes.func,
    clearSelectedUserData: PropTypes.func,
    createLogbookEntry: PropTypes.func,
    documents: PropTypes.object,
    fetchChartsData: PropTypes.func,
    fetchDashboardChartsData: PropTypes.func,
    fetchLaboratories: PropTypes.func,
    fetchSelectedUserDocuments: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    fetchSelectedUserLabResults: PropTypes.func,
    fetchSelectedUserLogbookEntries: PropTypes.func,
    fetchSelectedUserSymptomsLog: PropTypes.func,
    healthIssues: PropTypes.object,
    history: PropTypes.object,
    i18n: PropTypes.object,
    labDates: PropTypes.object,
    labResults: PropTypes.object,
    labs: PropTypes.array,
    location: PropTypes.object,
    logBook: PropTypes.object,
    match: PropTypes.object,
    clearLogbook: PropTypes.func,
    nomenclature: PropTypes.object,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    symptoms: PropTypes.object,
    updateLogbookEntry: PropTypes.func,
    clearSymptomsLog : PropTypes.func,
    clearUserDocuments : PropTypes.func,
    clearChartsData: PropTypes.func,
    clearUserLabResults : PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildMedicalRecord);

import React, {Component} from 'react';

class QuestionnaireSummary extends Component {
    render() {
        const questionResults = this.props.summary.results;
        return (
            <div className={'d-flex flex-column'}>
                {questionResults.map(question => {
                    return (
                        <div key={question.question_id}>
                            {`${question.question_text}`}
                            <ul>
                                {question.answers_results.map(answer => {
                                    let answerListItem = `${answer.answer_text}`;
                                    if (answer.answer_text !== answer.result_text) {
                                        answerListItem += ` -> ${answer.display ? answer.display.replace("{}", answer.result_text) : answer.result_text}`
                                    }
                                    return (
                                        <React.Fragment key={answer.answer_id}>
                                            {answer.topic && <div className={"summary-answer-topic"}>{answer.topic}</div>}
                                            <li>{answerListItem}</li>
                                        </React.Fragment>
                                    )})}
                            </ul>
                        </div>
                    )
                })}
            </div>
        );
    }
}

export default QuestionnaireSummary;

import React, {Component} from 'react'
import {$$} from '../../../helpers/localization'
import {CONVERTER} from '../../../utils/converter'
import PropTypes from "prop-types";

export default class KetonesForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.ketones ? $$('ketones_label') : null}<br/>
                <div className="form-group">
                    <div className="input-group mb-3">
                        <input type="number" step="0.01"
                               className={this.props.errors.ketones ? "custom-error form-control" : "form-control"}
                               value={this.props.ketones} placeholder={$$('ketones_label')}
                               name="ketones" onChange={this.props.onInputChange} required/>
                        <div className="input-group-append">
                            <span className="input-group-text" style={{"height": "auto"}}
                                  id="basic-addon2">{CONVERTER.getFormattedKetonesUnit()}</span>
                        </div>
                    </div>
                    <div
                        className={this.props.errors.ketones ? "custom-invalid-feedback" : "invalid-feedback"}>
                        {this.props.errors.ketones ? $$(this.props.errors.ketones) : $$('ketones_required_message')}
                    </div>
                </div>
            </div>
        )
    }
}

KetonesForm.propTypes = {
    clearSelectedEntry: PropTypes.func,
    createEntry: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    formDisabled: PropTypes.object,
    getHealthIssueList: PropTypes.func,
    healthIssues: PropTypes.object,
    logbookEntries: PropTypes.array,
    medicalProfile: PropTypes.object,
    onHide: PropTypes.func,
    onInputChange: PropTypes.func,
    errors: PropTypes.any,
    ketones: PropTypes.any,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    show: PropTypes.bool,
    type: PropTypes.string,
    updateEntry: PropTypes.func,
    updateUserMedicalProfile: PropTypes.func
}
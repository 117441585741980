import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {CONVERTER} from '../../utils/converter'
import {ReactComponent as ThermometerIcon} from '../../../public/media/icons/termometer.svg'
import {ReactComponent as BloodPressureIcon} from '../../../public/media/icons/blood pressure.svg'
import {ReactComponent as BloodSugarIcon} from '../../../public/media/icons/bloodsugar.svg'
import {ReactComponent as HbA1cIcon} from '../../../public/media/icons/hba1c.svg'
import {ReactComponent as KetonesIcon} from '../../../public/media/icons/ketones.svg'
import {ReactComponent as UrinepH} from '../../../public/media/icons/urine.svg'
import {ReactComponent as WeightIcon} from '../../../public/media/icons/weight.svg'
import {ReactComponent as HeightIcon} from '../../../public/media/icons/height.svg'
import {ReactComponent as CholesterolIcon} from '../../../public/media/icons/cholesterol.svg'
import {ReactComponent as OxygenSaturationIcon} from '../../../public/media/icons/heart-1.svg'
import {ReactComponent as RespiratoryRateIcon} from '../../../public/media/icons/respiratory.svg'
import {ReactComponent as HydrationIcon} from '../../../public/media/icons/water.svg'
import {ReactComponent as MenstruationIcon} from '../../../public/media/icons/menstruation.svg'
import {MENSTRUATION} from '../../constants/select_options'
import {infoUtils} from '../../utils/infoUtils'
import no_data from '../../resources/images/no_data.png'
import PropTypes from "prop-types";
import {dateTimeUtils} from "../../utils/dateTimeUtils";
import {PeakFlowIcon} from "../loogbok/PeakFlowIcon";
import {PEAK_FLOW} from "../../constants/entry_types";



export class LatestMeasurementsPanel extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
    }

    getColumn = (name, value, unit) => {
        return <div className="vitals-column-sm">
            <div className="vitals-name vitals-name-sm">{$$(name).replace("_", " ").toUpperCase()}</div>
            <div className="d-flex">
                <div className="vitals-value">{value}</div>
                <div className="vitals-unit">{unit}</div>
            </div>
        </div>
    }

    getDateTime = (entry) => {
        return <div className='date-for-vitals'>
            {dateTimeUtils.getFormattedDate(entry.date_time)}
        </div>
    }

    getCholesterol = (entry) => {
        return <div className="vitals-column">
            <div className="vitals-name">{$$("Cholesterol").replace("_", " ").toUpperCase()}</div>
            <div className="flex-space-between flex-wrap cholesterol">
                {this.getSmallColumn("total_label", entry.total_cholesterol)}
                {this.getSmallColumn("ldl_label", entry.ldl)}
                {this.getSmallColumn("hdl_label", entry.hdl)}
                {this.getSmallColumn("triglycerides", entry.triglycerides)}
            </div>
        </div>
    }

    getMenstruation = (entry) => {
        return <div className="vitals-column">
            <div className="vitals-name">{$$("entry_type_menstruation").toUpperCase()}</div>
            <div className="flex-space-between flex-wrap">
                {this.getSmallColumn("flow_label", $$(MENSTRUATION.FLOW[entry.menstruationFlow].text))}
                {this.getSmallColumn("color_label", $$(MENSTRUATION.COLOR[entry.menstruationColor].text), MENSTRUATION.COLOR[entry.menstruationColor].color)}
                {this.getSmallColumn("consistency_label", $$(MENSTRUATION.CONSISTENCY[entry.menstruationConsistency].text))}
            </div>
        </div>
    }


    getBloodPressure = (entry) => {
        return <div className="vitals-column">
            <div className="vitals-name">{$$(entry.entry_type.toLowerCase()).replace("_", " ").toUpperCase()}</div>
            <div className="flex-space-between flex-wrap">
                {this.getSmallColumn("systolic_label", entry.systolic)}
                {this.getSmallColumn("diastolic_label", entry.diastolic)}
                {this.getSmallColumn("pulse_label", entry.pulse)}
            </div>
            {entry.arrhythmia && <div className="medrec-red-2 pt-1">{$$("arrhythmia_detected_label")}</div>}
        </div>
    }

    getSmallColumn = (name, value, color) => {

        return <div className="blood-pressure-column-lv">
            <div className="vitals-mini-name">{name === "pulse_label" && value === 0 ? "" : $$(name).replace("_", " ").toUpperCase()}</div>
            <div className="d-flex">
                {name === "pulse_label" && value > 0 && <i className="fa fa-heart pulse-icon"/>}
                <div className="vitals-value" style={color ? {"color": color} : null}>{ name === "pulse_label" && value
                === 0 ? "": value}</div>
            </div>
        </div>
    }
    getIconType = (measurement) => {
        let icon;
        switch (measurement.entry_type) {
            case "TEMPERATURE":
                icon = <ThermometerIcon className="svg-icon"/>
                break
            case "BLOOD_PRESSURE":
                icon = <BloodPressureIcon className="svg-icon"/>
                break
            case "BLOOD_SUGAR":
                icon = <BloodSugarIcon className="svg-icon"/>
                break;
            case "HBA1C":
                icon = <HbA1cIcon className="svg-icon" height="32px" width="32px"/>;
                break;
            case "KETONES":
                icon = <KetonesIcon className="svg-icon" height="32px" width="32px"/>;
                break;
            case "URINE_PH":
                icon = <UrinepH className="svg-icon" height="40px" width="40px"/>;
                break;
            case "WEIGHT":
                icon = <WeightIcon className="svg-icon"/>;
                break;
            case "HEIGHT":
                icon = <HeightIcon className="svg-icon"/>;
                break;
            case "CHOLESTEROL":
                icon = <CholesterolIcon className="svg-icon"/>;
                break;
            case "SATURATION":
                icon = <OxygenSaturationIcon className="svg-icon"/>;
                break;
            case "RESPIRATORY_RATE":
                icon = <RespiratoryRateIcon className="svg-icon"/>;
                break;
            case "HYDRATION":
                icon = <HydrationIcon className="svg-icon"/>;
                break;
            case "MENSTRUATION":
                icon = <MenstruationIcon className="svg-icon" height="32px" width="32px"/>;
                break;
            case "PEAK_FLOW":
                icon = <PeakFlowIcon height="32px" width="32px"/>;
                break;
            default:
                icon = <div/>;
        }
        return icon;
    }

    getTheRest = (measurement) => {
        switch (measurement.entry_type) {
            case "TEMPERATURE":
                return this.getColumn("entry_type_temperature", CONVERTER.convertByUnit(measurement.entry_type, measurement.temperature), CONVERTER.getUnitByType(measurement.entry_type))
            case "BLOOD_PRESSURE":
                return this.getBloodPressure(measurement)
            case "BLOOD_SUGAR":
                return this.getColumn("entry_type_blood_sugar", CONVERTER.bloodSugarPerUnit(measurement.blood_sugar), CONVERTER.getFormattedBloodSugarUnit())
            case "HBA1C":
                return this.getColumn("entry_type_hba1c", CONVERTER.hba1cPerUnit(measurement.hba1c), CONVERTER.getFormattedHBA1CUnit())
            case "KETONES":
                return this.getColumn("entry_type_ketones", CONVERTER.ketonesPerUnit(measurement.ketones), CONVERTER.getFormattedKetonesUnit())
            case "URINE_PH":
                return this.getColumn("entry_type_urine_ph", CONVERTER.convertByUnit(measurement.entry_type, measurement.urine_ph))
            case "WEIGHT":
                return this.getColumn("entry_type_weight", CONVERTER.convertByUnit(measurement.entry_type, measurement.weight), CONVERTER.getUnitByType(measurement.entry_type))
            case "HEIGHT":
                return this.getColumn("entry_type_height", CONVERTER.convertByUnit(measurement.entry_type, measurement.height), CONVERTER.getUnitByType(measurement.entry_type))
            case "CHOLESTEROL":
                return this.getCholesterol(measurement)
            case "SATURATION":
                return this.getColumn("entry_type_oxygen_saturation", CONVERTER.convertByUnit(measurement.entry_type, measurement.oxygen_saturation), "%")
            case "RESPIRATORY_RATE":
                return this.getColumn("entry_type_respiratory_rate", CONVERTER.convertByUnit(measurement.entry_type, measurement.respiratory_rate), CONVERTER.getFormatedRespiratoryRate())
            case "HYDRATION":
                return this.getColumn("Hydration", CONVERTER.convertByUnit(measurement.entry_type, measurement.hydration), CONVERTER.getUnitByType(measurement.entry_type))
            case "MENSTRUATION":
                return this.getMenstruation(measurement)
            case "PEAK_FLOW":
                return this.getColumn("entry_type_peak_flow", measurement.peak_flow, $$("liters_per_minute"))
        }
    }

    getIconColumn = (measurement) => {

        return <div className="measurement-icons-container">
            <div className="measurement-icon">
                {this.getIconType(measurement)}
            </div>
        </div>
    }

    render() {
        let noMedications = {
            imgClass: 'no-entry-image-landing',
            objClass: 'no-data-object-landing',
            primaryLabelClass: 'no-medications-landing-label',
            src: no_data,
            primaryLabel: $$('logbook_no_data_label'),
            nobreak: true
        }
        let lastThreeMeasurements = this.props.latestUserMeasurements.sort((a, b) => (a.date_time < b.date_time) ? 1 : -1).filter((measurement) => {
            return measurement.entry_type !== "MEDICATION"
        }).slice(0, 3)
        let body = lastThreeMeasurements.map((measurement, idx) => {
            return <div key={idx} className="high-shadow-container-issues landing-container ">
                <div className="vitals-container">
                    {this.getIconColumn(measurement)}
                    {this.getTheRest(measurement)}
                    {measurement.entry_type === PEAK_FLOW && measurement.peak_flow_symptoms && <div className={"lmp-with-symptoms"}>{$$("peak_flow_with_symptoms")}</div>}
                </div>
                {this.getDateTime(measurement)}
            </div>
        });
        return (
            <div>
                {body}
                {lastThreeMeasurements.length === 0 &&
                <div className="py-3 high-shadow-container landing-container"> {infoUtils.noData(noMedications)} </div>}
            </div>
        )
    }
}

LatestMeasurementsPanel.propTypes = {
    dashboardCharts: PropTypes.object,
    fetchSelectedUserLatestMeasurements: PropTypes.func,
    healthIssues: PropTypes.array,
    history: PropTypes.object,
    i18n: PropTypes.object,
    latestUserMeasurements: PropTypes.array,
    location: PropTypes.object,
    match: PropTypes.object,
    medicationPlan: PropTypes.array,
    providers: PropTypes.array,
    selectedUser: PropTypes.object,
    userAppointments: PropTypes.array,
    userInfo: PropTypes.object
}

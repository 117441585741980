import React, {useEffect, useState} from 'react'
import {$$} from '../../helpers/localization';
import {convertDocumentTypeToText} from "../../utils/documentUtils";
import {ReactComponent as FileIcon} from "../../../public/media/icons/svg/Design/File.svg";
import {formatUtils} from "../../utils/formatUtils";
import {dateTimeUtils} from "../../utils/dateTimeUtils";
import TestsResultDisplay from "./TestsResultDisplay";
import PropTypes from "prop-types";
import {documentsService} from "../../service/docments_service";
import {downloadUtils} from "../../utils/downloadUtils";
import CenteredModal from "../shared/CenteredModal";
import {ListGroup} from "react-bootstrap";
import {EReferralList, Ereferrals} from "./Ereferrals";

export default class EncounterDetails extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {}
    }

    /**
     * Get concomitant diseases for the encounter
     */
    getConcomitantDiseases = () => {
        let concomitantDiseases = "";
        for (let i = 0; i < this.props.encounter.concomitant_diseases.length; i++) {
            if (i === 0) {
                concomitantDiseases += this.props.encounter.concomitant_diseases[i];
            } else {
                concomitantDiseases += "    |    " + this.props.encounter.concomitant_diseases[i];
            }
        }

        return concomitantDiseases;
    }

    /**
     * Get tests for the encounter
     */
    getTests = () => {
        let tests = "";
        for (let i = 0; i < this.props.encounter.tests.length; i++) {
            if (i === 0) {
                tests += this.props.encounter.tests[i];
            } else {
                tests += "\n" + this.props.encounter.tests[i];
            }
        }
        return tests;
    }

    /**
     * On confirm modal, download the file.
     */
    onConfirmDownloadModal = () => {
        const fileName = this.state.documentName;
        documentsService.downloadFile(this.state.documentEntryId).then((file) => {
            downloadUtils.download(file, fileName);
        });
        this.setState({showDownloadModal: false, documentEntryId: null, documentName: null, providerNames: null});
    }

    /**
     * Set state for the selected document entry when showing the modal
     */
    showDownloadModal = (documentEntryId, documentName, providerNames) => {
        this.setState({showDownloadModal: true, documentEntryId: documentEntryId, documentName: documentName, providerNames:providerNames});
    }

    hideDownloadModal = () => {
        this.setState({showDownloadModal: false, documentEntryId: null, documentName: null, providerNames: null});
    }

    getDocuments = () => {
        if (this.props.encounter && this.props.all_user_documents && this.props.encounter.document_ids?.length > 0) {
            return <div>
                {this.props.all_user_documents.filter(doc => this.props.encounter.document_ids.indexOf(doc.id) > -1).map(doc => {
                    return <div key={doc.id}>
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-4" style={{'marginTop': '3%', 'display': 'flex'}}>
                                <div style={{'paddingTop': '5px', 'marginRight': '15px'}}>
                                    <FileIcon className="svg-icon" height="21px" width="29px"
                                              style={{'fill': '#B4C5D0'}}/>
                                </div>
                                <div className="menu-tab2">
                                    {doc.title}
                                    <div style={{
                                        'fontSize': '13px',
                                        'color': '#9BABB6',
                                        'paddingTop': '3%'
                                    }}>{doc.filename}</div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4" style={{'marginTop': '3%'}}>
                                <div className="menu-tab2">
                                    {convertDocumentTypeToText(doc)}
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-2" style={{'marginTop': '3%'}}>
                                <div className="menu-tab2">{formatUtils.bytesToSize(doc.size)}</div>
                            </div>
                            <div className="col-sm-12 col-md-2" style={{'marginTop': '3%'}}>
                                <div className="menu-tab2">{dateTimeUtils.getFormattedDate(doc.date_time)}</div>
                            </div>
                        </div>
                        <hr/>
                    </div>
                })}
            </div>
        }
    }

    getProviderDocuments = () => {
        if (this.props.encounter && this.props.all_user_documents && this.props.encounter.provider_document_ids?.length > 0) {
            return <div>
                {this.props.all_user_documents.filter(doc => this.props.encounter.provider_document_ids.indexOf(doc.id) > -1).map(doc => {
                    return <div key={doc.id}>
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-4" style={{'marginTop': '3%', 'display': 'flex'}}>
                                <div style={{'paddingTop': '5px', 'marginRight': '15px'}}>
                                    <FileIcon className="svg-icon" height="21px" width="29px"
                                              style={{'fill': '#B4C5D0'}}/>
                                </div>
                                <div className="menu-tab2">
                                    <div>{doc.title}</div>
                                    <div><a style={{
                                        'fontSize': '13px',
                                        'color': "#30357C",
                                        'paddingTop': '3%'
                                    }} onClick={()=>{
                                        this.showDownloadModal(doc.id, doc.filename, doc.provider_names)
                                    }}>{doc.filename}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4" style={{'marginTop': '3%'}}>
                                <div className="menu-tab2">
                                    {convertDocumentTypeToText(doc)}
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-2" style={{'marginTop': '3%'}}>
                                <div className="menu-tab2">{formatUtils.bytesToSize(doc.size)}</div>
                            </div>
                            <div className="col-sm-12 col-md-2" style={{'marginTop': '3%'}}>
                                <div className="menu-tab2">{dateTimeUtils.getFormattedDate(doc.date_time)}</div>
                            </div>
                        </div>
                        <hr/>
                    </div>
                })}
                <CenteredModal title={$$('document_download_dialog_title')}
                               show={this.state.showDownloadModal}
                               onHide={this.hideDownloadModal}
                               onConfirm={this.onConfirmDownloadModal}
                               confirmBtnLabel={$$('download_btn')}
                               cancelBtnLabel={$$('cancel_btn')}
                >
                    {$$('confirmation_dialog_message').replace('{fileName}', this.state.documentName).replace('{owner}', this.props.selectedUser.fullname)}
                </CenteredModal>
            </div>
        }
    }

    getAdditionalResults = () => {
        return this.props.encounter.tests_results.map((entry, idx) => {
            return <React.Fragment key={idx}>
                {idx > 0 && <span className="ml-3 mr-3 medrec-grey-2">|</span>}
                <span key={idx} className="card-title pointer btn-link h6" onClick={() => {
                    this.setState({displayTestResult: entry})
                }}>{entry.name}</span>
            </React.Fragment>
        })
    }

    render() {
        if (this.state.displayTestResult) {
            return <div className={"p-5"}><TestsResultDisplay result={this.state.displayTestResult} onCancel={() => {
                this.setState({displayTestResult: undefined})
            }}/></div>
        }

        return (
            <div>
                <hr className="hr-text" data-content={$$(this.props.text_only ? "consultation_results_data_separator":"eccounter_data_separator")}/>
                {!this.props.isEncounterEmpty && <div className="card-body">
                    {this.props.encounter.main_diagnosis && <>
                        <div className="row full-width">
                            <div className='medrec-grey-2 appointment-details'>{$$("main_diagnosis_label")}</div>
                            <div className="appointment-details2">
                                {this.props.encounter.main_diagnosis}
                            </div>
                        </div>
                        <hr/>
                    </>}
                    {this.getConcomitantDiseases() !== "" && <>
                        <div className="row full-width">
                            <div className='medrec-grey-2 appointment-details'>{$$("concomitant_diseases_label")}</div>
                            <div className="appointment-details2 pre-wrap">
                                {this.getConcomitantDiseases()}
                            </div>
                        </div>
                        <hr/>
                    </>}
                    {this.props.encounter.preliminary_diagnosis && <>
                        <div className="row full-width">
                            <div className='medrec-grey-2 appointment-details'>{$$("preliminary_diagnosis")}</div>
                            <div className="appointment-details2">
                                <input className="big-checkbox" type="checkbox"
                                       name="is_preliminary_diagnosis"
                                       checked={this.props.encounter.preliminary_diagnosis}
                                       readOnly={true}
                                />
                            </div>
                        </div>
                        <hr/>
                    </>}
                    {this.props.encounter.subjective_data && <>
                        <div className="row full-width ">
                            <div className='medrec-grey-2 appointment-details'>{$$("subjective_data_label")}</div>
                            <div className="appointment-details2">
                                {this.props.encounter.subjective_data}
                            </div>
                        </div>
                        <hr/>
                    </>}
                    {this.props.encounter.objective_data && <>
                        <div className="row full-width">
                            <div className='medrec-grey-2 appointment-details'>{$$("objective_data_label")}</div>
                            <div className="appointment-details2">
                                {this.props.encounter.objective_data}
                            </div>
                        </div>
                        <hr/>
                    </>}
                    {this.getTests() !== "" && <>
                        <div className="row full-width">
                            <div className='medrec-grey-2 appointment-details'>{$$("tests_label")}</div>
                            <div className="appointment-details2 pre-wrap">
                                {this.getTests()}
                            </div>
                        </div>
                        <hr/>
                    </>}
                    {this.props.encounter.therapy && <>
                        <div className="row full-width">
                            <div className='medrec-grey-2 appointment-details'>{$$("therapy_label")}</div>
                            <div className="appointment-details2">
                                {this.props.encounter.therapy}
                            </div>
                        </div>
                        <hr/>
                    </>}
                    {this.props.encounter.additional_info && <>
                        <div className="row full-width">
                            <div className='medrec-grey-2 appointment-details'>{$$("additional_info")}</div>
                            <div className="appointment-details2">
                                {this.props.encounter.additional_info}
                            </div>
                        </div>
                        <hr/>
                    </>}
                    {this.props.encounter.conclusion && <>
                        <div className="row full-width">
                            <div className='medrec-grey-2 appointment-details'>{$$("conclusion")}</div>
                            <div className="appointment-details2">
                                {this.props.encounter.conclusion}
                            </div>
                        </div>
                        <hr/>
                    </>}
                    {this.getDocuments() &&  <div className="row full-width">
                        <div className='medrec-grey-2 appointment-details'>{$$("results_documents")}</div>
                        <div className="appointment-details2 pre-wrap">
                            {this.getDocuments()}
                        </div>
                    </div>}
                    {this.getProviderDocuments() &&  <div className="row full-width">
                        <div className='medrec-grey-2 appointment-details'>{$$("results_provider_documents")}</div>
                        <div className="appointment-details2 pre-wrap">
                            {this.getProviderDocuments()}
                        </div>
                    </div>}
                    {(this.getDocuments() || this.getProviderDocuments()) &&
                        <hr/>
                    }
                    {this.getAdditionalResults().length > 0 && <>
                        <div className="row full-width">
                            <div className='medrec-grey-2 appointment-details'>{$$("additional_results")}</div>
                            <div className="appointment-details2 pre-wrap">
                                {this.getAdditionalResults()}
                            </div>
                        </div>
                        <hr/>
                    </>}
                    {this.props.encounter.nhis_medical_notice_jsons && <>
                    <div className="row full-width">
                        <div className='medrec-grey-2 appointment-details'>{$$("medical_notices")}</div>
                        <div className="appointment-details2 pre-wrap">
                            <MedicalNotices notices={this.props.encounter.nhis_medical_notice_jsons} />
                        </div>
                    </div><hr/>
                    </>}
                    {this.props.encounter.ereferrals && this.props.encounter.ereferrals.length > 0 &&
                        <>
                            <div className="row full-width">
                                <div className='medrec-grey-2 appointment-details'>{$$("nhis.ereferral.ereferrals")}</div>
                                <div className="appointment-details2 pre-wrap">
                                    <EReferralList encounter={this.props.encounter} />
                                </div>
                            </div>
                        </>
                    }
                </div>}
            </div>
        )
    }
}

EncounterDetails.propTypes = {
    children: PropTypes.any,
    encounter: PropTypes.any,
    isEncounterEmpty: PropTypes.bool,
    all_user_documents: PropTypes.any
}

function MedicalNotices({notices, lang}) {
    const [medicalNotices, setMedicalNotices] = useState();

    useEffect(() => {
        if (notices) {
            setMedicalNotices(notices.map(n => {
                return JSON.parse(n).viewModel;
            }))
        }
    }, [notices]);


    if (!medicalNotices) {
        return null;
    }

    return <ListGroup className={"medical-notice-list"}>
        {medicalNotices.map(n => {
            return <MedicalNoticeListItem key={n.nrnMedicalNotice} notice={n} />
        })}
    </ListGroup>
}


function MedicalNoticeListItem({notice}) {
    return <div className="list-group-item">
        <ItemBody notice={notice} />
        <hr />
    </div>
}

function localizedDate(date) {
    if (!date) {
        return ""
    }
    return dateTimeUtils.getFormattedDate(new Date(date));
}

function ItemBody({notice}) {
    return <table className="table table-sm table-borderless base-font-color">
            <tbody>
            <TableRow labelKey={"medical_notice_nrn_number"} value={notice.nrnMedicalNotice}/>
            <TableRow labelKey={"medical_notice_issued_on_label"} value={localizedDate(notice.authoredOn)}/>
            <TableRow labelKey={"medical_notice_reason_label"} value={notice.reason}/>
            <TableRow labelKey={"medical_notice_location_view_label"} value={notice.location}/>
            {!notice.toDate && <TableRow labelKey={"medical_notice_from"} value={`${localizedDate(notice.fromDate)}`}/>}
            {notice.toDate && <TableRow labelKey={"medical_notice_for_period"} value={`${localizedDate(notice.fromDate)} - ${localizedDate(notice.toDate)}`}/>}
            <TableRow labelKey={"medical_notice_institution_label"} value={notice.institution}/>
            <TableRow labelKey={"medical_notice_note_label"} value={notice.note}/>
            </tbody>
        </table>
}

function TableRow({labelKey, value}) {
    if (value) {
        return <tr><td className="medrec-grey-2 text-right medical-notice-item-label">{$$(labelKey)}:</td><td className="whitespace-pre-line w-100 align-bottom">{value}</td></tr>
    }
    return null;
}
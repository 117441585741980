import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from "prop-types";
import CenteredModal from "../shared/CenteredModal";
import {$$} from "../../helpers/localization";
import {
    approveChildWithPin,
    clearConnectedProfileResponse,
    denyChild,
    getChildren
} from "../../actions/children_actions";


class ApproveChild extends Component {
    state = {
        errors: {},
        submitted: false,
        submittedReject: false,
        submissionError: false,
        submissionRejectionError: false,
        pin: ""
    }

    constructor(props) {
        super(props)
    }

    componentWillUnmount() {
        this.props.clearConnectedProfileResponse();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.response !== prevProps.response) {
            if (this.state.submitted) {
                if (this.props.response === true) {
                    if (this.props.switchUserAfterApproval) {
                        this.props.selectChild(this.props.child, true)
                    } else {
                        this.props.getChildren()
                    }
                    this.onHidePopup()
                } else {
                    this.setState({submissionError: true})
                }
            }
            if (this.state.submittedReject) {
                if (this.props.response === true) {
                    this.onHidePopup()
                } else {
                    this.setState({submissionRejectionError: true})
                }

            }
        }
    }

    onHidePopup = () => {
        this.setState({
            errors: {},
            submitted: false,
            submittedReject: false,
            submissionError: false,
            submissionRejectionError: false,
            pin: ""
        })
        this.props.clearConnectedProfileResponse();
        this.props.closePopup()
    }

    validate = () => {
        const errors = {};

        if (this.state.pin === "") {
            errors.pin = 'pin_required';
        }

        return errors;
    }

    approveChild = () => {
        let formErrors = this.validate();
        this.setState({errors: formErrors});

        if (Object.keys(formErrors).length === 0) {
            this.props.approveChildWithPin(this.props.child.id, this.state.pin)
            this.setState({submitted: true, submissionError: false})
        }
    }

    denyChild = () => {
        this.props.denyChild(this.props.child.id)
        this.setState({submittedReject: true, submissionRejectionError: false})
    }

    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    render() {
        return (
            <div>

                <CenteredModal show={this.props.showAcceptChild}
                               title={$$('approve_child')}
                               size={"Default"}
                               idBtnExtra={"deny-btn"}
                               classBtnExtra={"btn btn-danger"}
                               extraBtnLabel={$$('deny')}
                               confirmBtnLabel={$$('approve')}
                               onHide={this.onHidePopup}
                               onConfirm={this.approveChild}
                               extraBtnClick={this.denyChild}
                >
                    {this.state.submissionError && <div className={"server-error"}>{$$("submission_error")}</div>}
                    {this.state.submissionRejectionError &&
                        <div className={"server-error"}>{$$("rejection_error")}</div>}
                    <div className="form-group register-control">
                        <label>{$$('patient_uin')}:</label>
                        <input type="text"
                               className={this.state.errors.pin ? "custom-error form-control" : "form-control"}
                               value={this.state.pin}
                               placeholder={$$('patient_uin')}
                               name="pin"
                               onChange={this.onInputChange}
                               required/>
                        <div
                            className={this.state.errors.pin ? "custom-invalid-feedback" : "invalid-feedback"}>
                            {$$('pin_required')}
                        </div>
                    </div>
                </CenteredModal>
            </div>
        )
    }
}

ApproveChild.propTypes = {
    i18n: PropTypes.object,
    child: PropTypes.object,
    response: PropTypes.any,
    state: PropTypes.any,
    selectedChildToApprove: PropTypes.object,
    closePopup: PropTypes.func,
    selectChild: PropTypes.func,
    switchUserAfterApproval: PropTypes.bool,
    approveChildWithPin: PropTypes.func,
    clearConnectedProfileResponse: PropTypes.func,
    denyChild: PropTypes.func,
    getChildren: PropTypes.func,
    showAcceptChild: PropTypes.bool,
};

function mapStateToProps(state) {
    return {
        response: state.children_profiles.connectProfileResponse
    }
}

const mapDispatchToProps = {
    approveChildWithPin,
    denyChild,
    clearConnectedProfileResponse,
    getChildren
}

export default connect(mapStateToProps, mapDispatchToProps)(ApproveChild)

import CenteredModal from "../shared/CenteredModal";
import {$$} from "../../helpers/localization";
import React, {Component} from "react";
import {HexColorPicker} from "react-colorful";
import SelectedPill from "./SelectedPill";
import {ReactComponent as ResetIcon} from "../../../public/media/icons/svg/Design/Reset.svg";

import SingleImagePill from "./pills/SingleImagePill";
import DoubleImageSolidPill from "./pills/DoubleImageSolidPill";
import DoubleImageSemiPill from "./pills/DoubleImageSemiPill";
import DoubleImageTranPill from "./pills/DoubleImageTranPill";
import GelPill from "./pills/GelPill";
import MedCapsuleHSolidF from "../../resources/images/pill_shapes/pngs/med_capsule_H_solid_01_f.png";
import MedCapsuleHSolidFMask from "../../resources/images/pill_shapes/masks/med_capsule_H_solid_01_f_mask.png";
import MedCapsuleHSolidM from "../../resources/images/pill_shapes/pngs/med_capsule_H_solid_01_m.png";
import MedCapsuleHSolidMMask from "../../resources/images/pill_shapes/masks/med_capsule_H_solid_01_m_mask.png";
import MedCapsuleVSolidF from "../../resources/images/pill_shapes/pngs/med_capsule_V_solid_01_f.png";
import MedCapsuleVSemiF from "../../resources/images/pill_shapes/pngs/med_capsule_V_solid_01_f.png";
import MedCapsuleVSolidFMask from "../../resources/images/pill_shapes/masks/med_capsule_V_solid_01_f_mask.png";
import MedCapsuleVSemiFMask from "../../resources/images/pill_shapes/masks/med_capsule_V_solid_01_f_mask.png";
import MedCapsuleVSolidM from "../../resources/images/pill_shapes/pngs/med_capsule_V_solid_01_m.png";
import MedCapsuleVSolidMMask from "../../resources/images/pill_shapes/masks/med_capsule_V_solid_01_m_mask.png";
import MedCapsuleVSemiM from "../../resources/images/pill_shapes/pngs/med_capsule_V_semi_01_m.png";
import MedCapsuleVSemiMMask from "../../resources/images/pill_shapes/masks/med_capsule_V_semi_01_m_mask.png";
import MedCapsuleVSemiB from "../../resources/images/pill_shapes/pngs/med_capsule_V_semi_01_b.png";
import MedCapsuleVSemiBMask from "../../resources/images/pill_shapes/masks/med_capsule_V_semi_01_b_mask.png";
import MedCapsuleVTranF from "../../resources/images/pill_shapes/pngs/med_capsule_V_tran_01_f.png";
import MedCapsuleVTranFMask from "../../resources/images/pill_shapes/masks/med_capsule_V_tran_01_f_mask.png";
import MedCapsuleVTranM from "../../resources/images/pill_shapes/pngs/med_capsule_V_tran_01_m.png";
import MedCapsuleVTranMMask from "../../resources/images/pill_shapes/masks/med_capsule_V_tran_01_m_mask.png";
import MedCapsuleVTranB from "../../resources/images/pill_shapes/pngs/med_capsule_V_tran_01_b.png";
import MedCapsuleVTranBMask from "../../resources/images/pill_shapes/masks/med_capsule_V_tran_01_b_mask.png";
import MedGelSolidOneF from "../../resources/images/pill_shapes/pngs/med_gel_solid_01_f.png";
import MedGelSolidOneFMask from "../../resources/images/pill_shapes/masks/med_gel_solid_01_f_mask.png";
import MedGelSolidOneB from "../../resources/images/pill_shapes/pngs/med_gel_solid_01_b.png";
import MedGelSolidOneBMask from "../../resources/images/pill_shapes/masks/med_gel_solid_01_b_mask.png";
import MedGelSolidTwoF from "../../resources/images/pill_shapes/pngs/med_gel_solid_02_f.png";
import MedGelSolidTwoFMask from "../../resources/images/pill_shapes/masks/med_gel_solid_02_f_mask.png";
import MedGelSolidTwoB from "../../resources/images/pill_shapes/pngs/med_gel_solid_02_b.png";
import MedGelSolidTwoBMask from "../../resources/images/pill_shapes/masks/med_gel_solid_02_b_mask.png";
import MedGelSolidThreeF from "../../resources/images/pill_shapes/pngs/med_gel_solid_03_f.png";
import MedGelSolidThreeFMask from "../../resources/images/pill_shapes/masks/med_gel_solid_03_f_mask.png";
import MedGelSolidThreeB from "../../resources/images/pill_shapes/pngs/med_gel_solid_03_b.png";
import MedGelSolidThreeBMask from "../../resources/images/pill_shapes/masks/med_gel_solid_03_b_mask.png";
import MedGelSolidFourF from "../../resources/images/pill_shapes/pngs/med_gel_solid_04_f.png";
import MedGelSolidFourFMask from "../../resources/images/pill_shapes/masks/med_gel_solid_04_f_mask.png";
import MedGelSolidFourB from "../../resources/images/pill_shapes/pngs/med_gel_solid_04_b.png";
import MedGelSolidFourBMask from "../../resources/images/pill_shapes/masks/med_gel_solid_04_b_mask.png";
import MedSphericSolidOneF from "../../resources/images/pill_shapes/pngs/med_spheric_solid_01.png";
import MedSphericSolidOneFMask from "../../resources/images/pill_shapes/masks/med_spheric_solid_01_mask.png";
import MedSphericSolidTwoF from "../../resources/images/pill_shapes/pngs/med_spheric_solid_02.png";
import MedSphericSolidTwoFMask from "../../resources/images/pill_shapes/masks/med_spheric_solid_02_mask.png";
import MedSphericSolidThreeF from "../../resources/images/pill_shapes/pngs/med_spheric_solid_03.png";
import MedSphericSolidThreeFMask from "../../resources/images/pill_shapes/masks/med_spheric_solid_03_mask.png";
import MedPillRoundFlatLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_flat_L0_solid_x_01_f.png";
import MedPillRoundFlatLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_flat_L0_solid_x_01_f_mask.png";
import MedPillRoundFlatLZeroSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_flat_L0_solid_l_01_f.png";
import MedPillRoundFlatLZeroSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_flat_L0_solid_l_01_f_mask.png";
import MedPillRoundFlatLZeroSolidMF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_flat_L0_solid_m_01_f.png";
import MedPillRoundFlatLZeroSolidMFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_flat_L0_solid_m_01_f_mask.png";
import MedPillRoundFlatLZeroSolidSF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_flat_L0_solid_s_01_f.png";
import MedPillRoundFlatLZeroSolidSFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_flat_L0_solid_s_01_f_mask.png";
import MedPillRoundFlatLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_flat_L1_solid_x_01_f.png";
import MedPillRoundFlatLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_flat_L1_solid_x_01_f_mask.png";
import MedPillRoundFlatLOneSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_flat_L1_solid_l_01_f.png";
import MedPillRoundFlatLOneSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_flat_L1_solid_l_01_f_mask.png";
import MedPillRoundFlatLTwoSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_flat_L2_solid_x_01_f.png";
import MedPillRoundFlatLTwoSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_flat_L2_solid_x_01_f_mask.png";
import MedPillRoundFlatLTwoSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_flat_L2_solid_l_01_f.png";
import MedPillRoundFlatLTwoSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_flat_L2_solid_l_01_f_mask.png";
import MedPillRoundInLZeroSolidXF from "../../resources/images/pill_shapes/pngs/med_pill_round_in_L0_solid_x_01_f.png";
import MedPillRoundInLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_in_L0_solid_x_01_f_mask.png";
import MedPillRoundInLZeroSolidLF from "../../resources/images/pill_shapes/pngs/med_pill_round_in_L0_solid_l_01_f.png";
import MedPillRoundInLZeroSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_in_L0_solid_l_01_f_mask.png";
import MedPillRoundInLZeroSolidMF from "../../resources/images/pill_shapes/pngs/med_pill_round_in_L0_solid_m_01_f.png";
import MedPillRoundInLZeroSolidMFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_in_L0_solid_m_01_f_mask.png";
import MedPillRoundInLZeroSolidSF from "../../resources/images/pill_shapes/pngs/med_pill_round_in_L0_solid_s_01_f.png";
import MedPillRoundInLZeroSolidSFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_in_L0_solid_s_01_f_mask.png";
import MedPillRoundInLOneSolidXF from "../../resources/images/pill_shapes/pngs/med_pill_round_in_L1_solid_x_01_f.png";
import MedPillRoundInLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_in_L1_solid_x_01_f_mask.png";
import MedPillRoundInLOneSolidLF from "../../resources/images/pill_shapes/pngs/med_pill_round_in_L1_solid_l_01_f.png";
import MedPillRoundInLOneSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_in_L1_solid_l_01_f_mask.png";
import MedPillRoundInLTwoSolidXF from "../../resources/images/pill_shapes/pngs/med_pill_round_in_L2_solid_x_01_f.png";
import MedPillRoundInLTwoSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_in_L2_solid_x_01_f_mask.png";
import MedPillRoundInLTwoSolidLF from "../../resources/images/pill_shapes/pngs/med_pill_round_in_L2_solid_l_01_f.png";
import MedPillRoundInLTwoSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_in_L2_solid_l_01_f_mask.png";
import MedPillRoundOutLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_out_L0_solid_x_01_f.png";
import MedPillRoundOutLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_out_L0_solid_x_01_f_mask.png";
import MedPillRoundOutLZeroSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_out_L0_solid_l_01_f.png";
import MedPillRoundOutLZeroSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_out_L0_solid_l_01_f_mask.png";
import MedPillRoundOutLZeroSolidMF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_out_L0_solid_m_01_f.png";
import MedPillRoundOutLZeroSolidMFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_out_L0_solid_m_01_f_mask.png";
import MedPillRoundOutLZeroSolidSF
    from "../../resources/images/pill_shapes/pngs/med_pill_round_out_L0_solid_s_01_f.png";
import MedPillRoundOutLZeroSolidSFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_out_L0_solid_s_01_f_mask.png";
import MedPillRoundOutLOneSolidXF from "../../resources/images/pill_shapes/pngs/med_pill_round_out_L1_solid_x_01_f.png";
import MedPillRoundOutLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_out_L1_solid_x_01_f_mask.png";
import MedPillRoundOutLOneSolidLF from "../../resources/images/pill_shapes/pngs/med_pill_round_out_L1_solid_l_01_f.png";
import MedPillRoundOutLOneSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_out_L1_solid_l_01_f_mask.png";
import MedPillRoundOutLTwoSolidXF from "../../resources/images/pill_shapes/pngs/med_pill_round_out_L2_solid_x_01_f.png";
import MedPillRoundOutLTwoSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_out_L2_solid_x_01_f_mask.png";
import MedPillRoundOutLTwoSolidLF from "../../resources/images/pill_shapes/pngs/med_pill_round_out_L2_solid_l_01_f.png";
import MedPillRoundOutLTwoSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_round_out_L2_solid_l_01_f_mask.png";
import MedPillElipseFlatLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_elipse_flat_L0_solid_x_01_f.png";
import MedPillElipseFlatLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_elipse_flat_L0_solid_x_01_f_mask.png";
import MedPillElipseFlatLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_elipse_flat_L1_solid_x_01_f.png";
import MedPillElipseFlatLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_elipse_flat_L1_solid_x_01_f_mask.png";
import MedPillElipseInLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_elipse_in_L0_solid_x_01_f.png";
import MedPillElipseInLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_elipse_in_L0_solid_x_01_f_mask.png";
import MedPillElipseInLOneSolidXF from "../../resources/images/pill_shapes/pngs/med_pill_elipse_in_L1_solid_x_01_f.png";
import MedPillElipseInLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_elipse_in_L1_solid_x_01_f_mask.png";
import MedPillElipseOutLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_elipse_out_L0_solid_x_01_f.png";
import MedPillElipseOutLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_elipse_out_L0_solid_x_01_f_mask.png";
import MedPillElipseOutLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_elipse_out_L1_solid_x_01_f.png";
import MedPillElipseOutLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_elipse_out_L1_solid_x_01_f_mask.png";
import MedPillRectangleFlatLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_rectangle_flat_L0_solid_x_01_f.png";
import MedPillRectangleFlatLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_rectangle_flat_L0_solid_x_01_f_mask.png";
import MedPillRectangleFlatLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_rectangle_flat_L1_solid_x_01_f.png";
import MedPillRectangleFlatLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_rectangle_flat_L1_solid_x_01_f_mask.png";
import MedPillRectangleInLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_rectangle_in_L0_solid_x_01_f.png";
import MedPillRectangleInLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_rectangle_in_L0_solid_x_01_f_mask.png";
import MedPillRectangleInLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_rectangle_in_L1_solid_x_01_f.png";
import MedPillRectangleInLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_rectangle_in_L1_solid_x_01_f_mask.png";
import MedPillRectangleOutLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_rectangle_out_L0_solid_x_01_f.png";
import MedPillRectangleOutLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_rectangle_out_L0_solid_x_01_f_mask.png";
import MedPillRectangleOutLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_rectangle_out_L1_solid_x_01_f.png";
import MedPillRectangleOutLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_rectangle_out_L1_solid_x_01_f_mask.png";
import MedPillSquareFlatLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_flat_L0_solid_x_01_f.png";
import MedPillSquareFlatLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_flat_L0_solid_x_01_f_mask.png";
import MedPillSquareFlatLZeroSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_flat_L0_solid_l_01_f.png";
import MedPillSquareFlatLZeroSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_flat_L0_solid_l_01_f_mask.png";
import MedPillSquareFlatLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_flat_L1_solid_x_01_f.png";
import MedPillSquareFlatLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_flat_L1_solid_x_01_f_mask.png";
import MedPillSquareFlatLOneSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_flat_L1_solid_l_01_f.png";
import MedPillSquareFlatLOneSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_flat_L1_solid_l_01_f_mask.png";
import MedPillSquareFlatLTwoSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_flat_L2_solid_x_01_f.png";
import MedPillSquareFlatLTwoSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_flat_L2_solid_x_01_f_mask.png";
import MedPillSquareFlatLTwoSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_flat_L2_solid_l_01_f.png";
import MedPillSquareFlatLTwoSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_flat_L2_solid_l_01_f_mask.png";
import MedPillSquareInLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_in_L0_solid_x_01_f.png";
import MedPillSquareInLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_in_L0_solid_x_01_f_mask.png";
import MedPillSquareInLZeroSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_in_L0_solid_l_01_f.png";
import MedPillSquareInLZeroSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_in_L0_solid_l_01_f_mask.png";
import MedPillSquareInLOneSolidXF from "../../resources/images/pill_shapes/pngs/med_pill_square_in_L1_solid_x_01_f.png";
import MedPillSquareInLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_in_L1_solid_x_01_f_mask.png";
import MedPillSquareInLOneSolidLF from "../../resources/images/pill_shapes/pngs/med_pill_square_in_L1_solid_l_01_f.png";
import MedPillSquareInLOneSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_in_L1_solid_l_01_f_mask.png";
import MedPillSquareInLTwoSolidXF from "../../resources/images/pill_shapes/pngs/med_pill_square_in_L2_solid_x_01_f.png";
import MedPillSquareInLTwoSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_in_L2_solid_x_01_f_mask.png";
import MedPillSquareInLTwoSolidLF from "../../resources/images/pill_shapes/pngs/med_pill_square_in_L2_solid_l_01_f.png";
import MedPillSquareInLTwoSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_in_L2_solid_l_01_f_mask.png";
import MedPillSquareOutLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_out_L0_solid_x_01_f.png";
import MedPillSquareOutLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_out_L0_solid_x_01_f_mask.png";
import MedPillSquareOutLZeroSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_out_L0_solid_l_01_f.png";
import MedPillSquareOutLZeroSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_out_L0_solid_l_01_f_mask.png";
import MedPillSquareOutLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_out_L1_solid_x_01_f.png";
import MedPillSquareOutLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_out_L1_solid_x_01_f_mask.png";
import MedPillSquareOutLOneSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_out_L1_solid_l_01_f.png";
import MedPillSquareOutLOneSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_out_L1_solid_l_01_f_mask.png";
import MedPillSquareOutLTwoSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_out_L2_solid_x_01_f.png";
import MedPillSquareOutLTwoSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_out_L2_solid_x_01_f_mask.png";
import MedPillSquareOutLTwoSolidLF
    from "../../resources/images/pill_shapes/pngs/med_pill_square_out_L2_solid_l_01_f.png";
import MedPillSquareOutLTwoSolidLFMask
    from "../../resources/images/pill_shapes/masks/med_pill_square_out_L2_solid_l_01_f_mask.png";
import MedPillTriangleFlatLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_triangle_flat_L0_solid_x_01_f.png";
import MedPillTriangleFlatLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_triangle_flat_L0_solid_x_01_f_mask.png";
import MedPillTriangleFlatLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_triangle_flat_L1_solid_x_01_f.png";
import MedPillTriangleFlatLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_triangle_flat_L1_solid_x_01_f_mask.png";
import MedPillTriangleInLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_triangle_in_L0_solid_x_01_f.png";
import MedPillTriangleInLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_triangle_in_L0_solid_x_01_f_mask.png";
import MedPillTriangleInLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_triangle_in_L1_solid_x_01_f.png";
import MedPillTriangleInLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_triangle_in_L1_solid_x_01_f_mask.png";
import MedPillTriangleOutLZeroSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_triangle_out_L0_solid_x_01_f.png";
import MedPillTriangleOutLZeroSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_triangle_out_L0_solid_x_01_f_mask.png";
import MedPillTriangleOutLOneSolidXF
    from "../../resources/images/pill_shapes/pngs/med_pill_triangle_out_L1_solid_x_01_f.png";
import MedPillTriangleOutLOneSolidXFMask
    from "../../resources/images/pill_shapes/masks/med_pill_triangle_out_L1_solid_x_01_f_mask.png";
import MedXAmpouleOneF from "../../resources/images/pill_shapes/pngs/med_x_ampoule_1.png";
import MedXAmpouleOneFMask from "../../resources/images/pill_shapes/masks/med_x_ampoule_1_mask.png";
import MedXAmpouleTwoF from "../../resources/images/pill_shapes/pngs/med_x_ampoule_2.png";
import MedXAmpouleTwoFMask from "../../resources/images/pill_shapes/masks/med_x_ampoule_2_mask.png";
import MedXVialF from "../../resources/images/pill_shapes/pngs/med_x_vial.png";
import MedXVialFMask from "../../resources/images/pill_shapes/masks/med_x_vial_mask.png";
import MedXSprayF from "../../resources/images/pill_shapes/pngs/med_x_spray.png";
import MedXSprayFMask from "../../resources/images/pill_shapes/masks/med_x_spray_mask.png";
import MedXSprayNasalF from "../../resources/images/pill_shapes/pngs/med_x_spray_nasal.png";
import MedXSprayNasalFMask from "../../resources/images/pill_shapes/masks/med_x_spray_nasal_mask.png";
import MedXSprayOralF from "../../resources/images/pill_shapes/pngs/med_x_spray_oral.png";
import MedXSprayOralFMask from "../../resources/images/pill_shapes/masks/med_x_spray_oral_mask.png";
import MedXSyringeF from "../../resources/images/pill_shapes/pngs/med_x_syringe.png";
import MedXSyringeFMask from "../../resources/images/pill_shapes/masks/med_x_syringe_mask.png";
import MedXInsulinPenF from "../../resources/images/pill_shapes/pngs/med_x_insulin_pen.png";
import MedXInsulinPenFMask from "../../resources/images/pill_shapes/masks/med_x_insulin_pen_mask.png";
import MedXSpoonF from "../../resources/images/pill_shapes/pngs/med_x_spoon.png";
import MedXSpoonFMask from "../../resources/images/pill_shapes/masks/med_x_spoon_mask.png";
import MedXInhalerF from "../../resources/images/pill_shapes/pngs/med_x_inhaler.png";
import MedXInhalerFMask from "../../resources/images/pill_shapes/masks/med_x_inhaler_mask.png";
import MedXOintmentF from "../../resources/images/pill_shapes/pngs/med_x_ointment.png";
import MedXOintmentFMask from "../../resources/images/pill_shapes/masks/med_x_ointment_mask.png";
import MedXEyeDropsF from "../../resources/images/pill_shapes/pngs/med_x_eye_drops.png";
import MedXEyeDropsFMask from "../../resources/images/pill_shapes/masks/med_x_eye_drops_mask.png";
import MedXPipetteF from "../../resources/images/pill_shapes/pngs/med_x_pipette.png";
import MedXPipetteFMask from "../../resources/images/pill_shapes/masks/med_x_pipette_mask.png";
import MedXPowderOneF from "../../resources/images/pill_shapes/pngs/med_x_powder_1.png";
import MedXPowderOneFMask from "../../resources/images/pill_shapes/masks/med_x_powder_1_mask.png";
import MedXPowderTwoF from "../../resources/images/pill_shapes/pngs/med_x_powder_2.png";
import MedXPowderTwoFMask from "../../resources/images/pill_shapes/masks/med_x_powder_2_mask.png";
import NoPill from "./pills/NoPill";
import PropTypes from "prop-types";


export default class PillPicker extends Component {

    state = {
        previousPrimaryColour: "",
        PreviousSecondaryColour: "",
        showPrimaryModel: false,
        chosenShape: {
            "pillShape": this.props.pillSelection ? this.props.pillSelection : (this.props.selectedEntry && this.props.selectedEntry.shapeIndex ? this.props.selectedEntry.shapeIndex : 0),

        },
    }

    constructor(props) {
        super(props);
    }

    isPrimaryColourDisabled = () => {
        return this.props.pillSelection === 0
    }

    isSecondaryColourDisabled = () => {
        return this.props.pillSelection === 0 || this.props.pillSelection > 4;
    }

    onSavePillPicker = () => {


        let selection = {
            "pillShape": this.props.pillSelection,
            "primaryColour": this.props.pillSelection === 0 ? "#FFFFFF" : this.props.primaryColour,
            "secondaryColour": this.props.pillSelection === 0 || this.props.pillSelection > 4 ? "#FFFFFF" : this.props.secondaryColour
        }
        this.setState({
            chosenShape: {
                "pillShape": this.props.pillSelection && this.props.pillSelection,
            }
        })
        this.props.setPreviousPrimaryColour(this.props.primaryColour)
        this.props.setPreviousSecondaryColour(this.props.secondaryColour)

        this.props.onHidePillPicker(selection, true)
    }


    primaryColourClick = () => {
        if (!this.isPrimaryColourDisabled()) {
            this.setState({
                showPrimaryModel: true,
                previousPrimaryColour: this.props.primaryColour
            })
        }
    }

    secondaryColourClick = () => {
        if (!this.isSecondaryColourDisabled()) {
            this.setState({
                showSecondaryModel: true,
                previousSecondaryColour: this.props.secondaryColour
            })
        }
    }

    onHidePrimaryPicker = () => {
        this.setState({
                showPrimaryModel: false,
            }
        )
        this.props.setPrimaryColour(this.state.previousPrimaryColour)

    }

    onConfirmPrimaryPicker = () => {
        this.setState({showPrimaryModel: false})
    }

    onConfirmSecondaryPicker = () => {
        this.setState({showSecondaryModel: false})
    }

    onHideSecondaryPicker = () => {
        this.setState({
                showSecondaryModel: false
            }
        )
        this.props.setSecondaryColour(this.state.previousSecondaryColour)
    }

    render() {
        return (
            <div>
                <CenteredModal
                    title={$$('select_medication_style')}
                    dialogClassName='add-doc-modal'
                    show={this.props.showPillPicker}
                    onHide={() => this.props.onHidePillPicker(this.state.chosenShape)}
                    confirmBtnLabel={$$('select_label')}
                    cancelBtnLabel={$$('cancel_btn')}
                    onConfirm={this.onSavePillPicker}
                >
                    <div className="container">

                        <div className={"selection-component"}>
                            <div className="pill-picker-component">

                                <div className={`dot ${this.isPrimaryColourDisabled() ? "disabled-circle" : ""}`}
                                     style={{
                                         border: this.props.primaryColour && this.props.primaryColour.toLowerCase() === "#ffffff" ? "1px solid #B5B5B5" : "0px",
                                         backgroundColor: this.isPrimaryColourDisabled() ? "#FFFFFF" : this.props.primaryColour
                                     }} onClick={this.primaryColourClick}/>

                                <br/>
                                <CenteredModal
                                    show={this.state.showPrimaryModel}
                                    modelClassName="small-model"
                                    onHide={this.onHidePrimaryPicker}
                                    confirmBtnLabel={$$('select_label')}
                                    cancelBtnLabel={$$('cancel_btn')}
                                    onConfirm={this.onConfirmPrimaryPicker}
                                >
                                    <HexColorPicker style={{marginLeft: "auto", marginRight: "auto"}}
                                                    color={this.props.primaryColour}
                                                    onChange={this.props.setPrimaryColour}/>
                                </CenteredModal>
                                <p style={{textAlign: "center"}}>{$$('primary_colour')}</p>
                            </div>
                            <div className="pill-picker-component"
                                 style={{marginLeft: "30px", minWidth: "100px", maxWidth: "100px"}}>
                                <SelectedPill primaryColour={this.props.primaryColour}
                                              secondaryColour={this.props.secondaryColour}
                                              pillSelection={this.props.pillSelection}/>
                            </div>
                            <div className={"reset-icon"} onClick={this.props.resetPillToDefault}><ResetIcon/></div>
                            <div className="pill-picker-component ">
                                <div className={`dot ${this.isSecondaryColourDisabled() ? "disabled-circle" : ""}`}
                                     style={{
                                         border: this.props.secondaryColour && this.props.secondaryColour.toLowerCase() === "#ffffff" ? "1px solid #B5B5B5" : "0px",
                                         backgroundColor: this.isSecondaryColourDisabled() ? "#FFFFFF" : this.props.secondaryColour
                                     }} onClick={this.secondaryColourClick}/>
                                <br/>
                                <CenteredModal
                                    show={this.state.showSecondaryModel}
                                    modelClassName="small-model"
                                    onHide={this.onHideSecondaryPicker}
                                    confirmBtnLabel={$$('select_label')}
                                    cancelBtnLabel={$$('cancel_btn')}
                                    onConfirm={this.onConfirmSecondaryPicker}
                                >
                                    <HexColorPicker style={{marginLeft: "auto", marginRight: "auto"}}
                                                    color={this.props.secondaryColour}
                                                    onChange={this.props.setSecondaryColour}/>
                                </CenteredModal>
                                <p style={{textAlign: "center"}}>{$$('secondary_colour')}</p>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(0)}>
                            <NoPill selected={this.props.pillSelection === 0}/>
                        </div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(1)}>
                            <DoubleImageSolidPill MedF={MedCapsuleHSolidF} MedFMask={MedCapsuleHSolidFMask}
                                                  MedM={MedCapsuleHSolidM} MedMMask={MedCapsuleHSolidMMask}
                                                  selected={this.props.pillSelection === 1}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(2)}>
                            <DoubleImageSolidPill MedF={MedCapsuleVSolidF} MedFMask={MedCapsuleVSolidFMask}
                                                  MedM={MedCapsuleVSolidM} MedMMask={MedCapsuleVSolidMMask}
                                                  selected={this.props.pillSelection === 2}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(3)}>
                            <DoubleImageSemiPill MedF={MedCapsuleVSemiF} MedFMask={MedCapsuleVSemiFMask}
                                                 MedM={MedCapsuleVSemiM} MedMMask={MedCapsuleVSemiMMask}
                                                 MedB={MedCapsuleVSemiB} MedBMask={MedCapsuleVSemiBMask}
                                                 selected={this.props.pillSelection === 3}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(4)}>
                            <DoubleImageTranPill MedF={MedCapsuleVTranF} MedFMask={MedCapsuleVTranFMask}
                                                 MedM={MedCapsuleVTranM} MedMMask={MedCapsuleVTranMMask}
                                                 MedB={MedCapsuleVTranB} MedBMask={MedCapsuleVTranBMask}
                                                 selected={this.props.pillSelection === 4}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(5)}>
                            <GelPill MedF={MedGelSolidOneF} MedFMask={MedGelSolidOneFMask} MedB={MedGelSolidOneB}
                                     MedBMask={MedGelSolidOneBMask} selected={this.props.pillSelection === 5}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(6)}>
                            <GelPill MedF={MedGelSolidTwoF} MedFMask={MedGelSolidTwoFMask} MedB={MedGelSolidTwoB}
                                     MedBMask={MedGelSolidTwoBMask} selected={this.props.pillSelection === 6}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(7)}>
                            <GelPill MedF={MedGelSolidThreeF} MedFMask={MedGelSolidThreeFMask} MedB={MedGelSolidThreeB}
                                     MedBMask={MedGelSolidThreeBMask} selected={this.props.pillSelection === 7}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(8)}>
                            <GelPill MedF={MedGelSolidFourF} MedFMask={MedGelSolidFourFMask} MedB={MedGelSolidFourB}
                                     MedBMask={MedGelSolidFourBMask} selected={this.props.pillSelection === 8}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(9)}>
                            <SingleImagePill MedF={MedSphericSolidOneF} MedFMask={MedSphericSolidOneFMask}
                                             selected={this.props.pillSelection === 9}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(10)}>
                            <SingleImagePill MedF={MedSphericSolidTwoF} MedFMask={MedSphericSolidTwoFMask}
                                             selected={this.props.pillSelection === 10}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(11)}>
                            <SingleImagePill MedF={MedSphericSolidThreeF} MedFMask={MedSphericSolidThreeFMask}
                                             selected={this.props.pillSelection === 11}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(12)}>
                            <SingleImagePill MedF={MedPillRoundFlatLZeroSolidXF}
                                             MedFMask={MedPillRoundFlatLZeroSolidXFMask}
                                             selected={this.props.pillSelection === 12}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(13)}>
                            <SingleImagePill MedF={MedPillRoundFlatLZeroSolidLF}
                                             MedFMask={MedPillRoundFlatLZeroSolidLFMask}
                                             selected={this.props.pillSelection === 13}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(14)}>
                            <SingleImagePill MedF={MedPillRoundFlatLZeroSolidMF}
                                             MedFMask={MedPillRoundFlatLZeroSolidMFMask}
                                             selected={this.props.pillSelection === 14}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(15)}>
                            <SingleImagePill MedF={MedPillRoundFlatLZeroSolidSF}
                                             MedFMask={MedPillRoundFlatLZeroSolidSFMask}
                                             selected={this.props.pillSelection === 15}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(16)}>
                            <SingleImagePill MedF={MedPillRoundFlatLOneSolidXF}
                                             MedFMask={MedPillRoundFlatLOneSolidXFMask}
                                             selected={this.props.pillSelection === 16}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(17)}>
                            <SingleImagePill MedF={MedPillRoundFlatLOneSolidLF}
                                             MedFMask={MedPillRoundFlatLOneSolidLFMask}
                                             selected={this.props.pillSelection === 17}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(18)}>
                            <SingleImagePill MedF={MedPillRoundFlatLTwoSolidXF}
                                             MedFMask={MedPillRoundFlatLTwoSolidXFMask}
                                             selected={this.props.pillSelection === 18}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(19)}>
                            <SingleImagePill MedF={MedPillRoundFlatLTwoSolidLF}
                                             MedFMask={MedPillRoundFlatLTwoSolidLFMask}
                                             selected={this.props.pillSelection === 19}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(20)}>
                            <SingleImagePill MedF={MedPillRoundInLZeroSolidXF} MedFMask={MedPillRoundInLZeroSolidXFMask}
                                             selected={this.props.pillSelection === 20}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(21)}>
                            <SingleImagePill MedF={MedPillRoundInLZeroSolidLF} MedFMask={MedPillRoundInLZeroSolidLFMask}
                                             selected={this.props.pillSelection === 21}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(22)}>
                            <SingleImagePill MedF={MedPillRoundInLZeroSolidMF} MedFMask={MedPillRoundInLZeroSolidMFMask}
                                             selected={this.props.pillSelection === 22}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(23)}>
                            <SingleImagePill MedF={MedPillRoundInLZeroSolidSF} MedFMask={MedPillRoundInLZeroSolidSFMask}
                                             selected={this.props.pillSelection === 23}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(24)}>
                            <SingleImagePill MedF={MedPillRoundInLOneSolidXF} MedFMask={MedPillRoundInLOneSolidXFMask}
                                             selected={this.props.pillSelection === 24}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(25)}>
                            <SingleImagePill MedF={MedPillRoundInLOneSolidLF} MedFMask={MedPillRoundInLOneSolidLFMask}
                                             selected={this.props.pillSelection === 25}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(26)}>
                            <SingleImagePill MedF={MedPillRoundInLTwoSolidXF} MedFMask={MedPillRoundInLTwoSolidXFMask}
                                             selected={this.props.pillSelection === 26}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(27)}>
                            <SingleImagePill MedF={MedPillRoundInLTwoSolidLF} MedFMask={MedPillRoundInLTwoSolidLFMask}
                                             selected={this.props.pillSelection === 27}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(28)}>
                            <SingleImagePill MedF={MedPillRoundOutLZeroSolidXF}
                                             MedFMask={MedPillRoundOutLZeroSolidXFMask}
                                             selected={this.props.pillSelection === 28}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(29)}>
                            <SingleImagePill MedF={MedPillRoundOutLZeroSolidLF}
                                             MedFMask={MedPillRoundOutLZeroSolidLFMask}
                                             selected={this.props.pillSelection === 29}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(30)}>
                            <SingleImagePill MedF={MedPillRoundOutLZeroSolidMF}
                                             MedFMask={MedPillRoundOutLZeroSolidMFMask}
                                             selected={this.props.pillSelection === 30}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(31)}>
                            <SingleImagePill MedF={MedPillRoundOutLZeroSolidSF}
                                             MedFMask={MedPillRoundOutLZeroSolidSFMask}
                                             selected={this.props.pillSelection === 31}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(32)}>
                            <SingleImagePill MedF={MedPillRoundOutLOneSolidXF} MedFMask={MedPillRoundOutLOneSolidXFMask}
                                             selected={this.props.pillSelection === 32}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(33)}>
                            <SingleImagePill MedF={MedPillRoundOutLOneSolidLF} MedFMask={MedPillRoundOutLOneSolidLFMask}
                                             selected={this.props.pillSelection === 33}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(34)}>
                            <SingleImagePill MedF={MedPillRoundOutLTwoSolidXF} MedFMask={MedPillRoundOutLTwoSolidXFMask}
                                             selected={this.props.pillSelection === 34}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(35)}>
                            <SingleImagePill MedF={MedPillRoundOutLTwoSolidLF} MedFMask={MedPillRoundOutLTwoSolidLFMask}
                                             selected={this.props.pillSelection === 35}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(36)}>
                            <SingleImagePill MedF={MedPillElipseFlatLZeroSolidXF}
                                             MedFMask={MedPillElipseFlatLZeroSolidXFMask}
                                             selected={this.props.pillSelection === 36}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(37)}>
                            <SingleImagePill MedF={MedPillElipseFlatLOneSolidXF}
                                             MedFMask={MedPillElipseFlatLOneSolidXFMask}
                                             selected={this.props.pillSelection === 37}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(38)}>
                            <SingleImagePill MedF={MedPillElipseInLZeroSolidXF}
                                             MedFMask={MedPillElipseInLZeroSolidXFMask}
                                             selected={this.props.pillSelection === 38}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(39)}>
                            <SingleImagePill MedF={MedPillElipseInLOneSolidXF} MedFMask={MedPillElipseInLOneSolidXFMask}
                                             selected={this.props.pillSelection === 39}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(40)}>
                            <SingleImagePill MedF={MedPillElipseOutLZeroSolidXF}
                                             MedFMask={MedPillElipseOutLZeroSolidXFMask}
                                             selected={this.props.pillSelection === 40}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(41)}>
                            <SingleImagePill MedF={MedPillElipseOutLOneSolidXF}
                                             MedFMask={MedPillElipseOutLOneSolidXFMask}
                                             selected={this.props.pillSelection === 41}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(42)}>
                            <SingleImagePill MedF={MedPillRectangleFlatLZeroSolidXF}
                                             MedFMask={MedPillRectangleFlatLZeroSolidXFMask}
                                             selected={this.props.pillSelection === 42}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(43)}>
                            <SingleImagePill MedF={MedPillRectangleFlatLOneSolidXF}
                                             MedFMask={MedPillRectangleFlatLOneSolidXFMask}
                                             selected={this.props.pillSelection === 43}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(44)}>
                            <SingleImagePill MedF={MedPillRectangleInLZeroSolidXF}
                                             MedFMask={MedPillRectangleInLZeroSolidXFMask}
                                             selected={this.props.pillSelection === 44}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(45)}>
                            <SingleImagePill MedF={MedPillRectangleInLOneSolidXF}
                                             MedFMask={MedPillRectangleInLOneSolidXFMask}
                                             selected={this.props.pillSelection === 45}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(46)}>
                            <SingleImagePill MedF={MedPillRectangleOutLZeroSolidXF}
                                             MedFMask={MedPillRectangleOutLZeroSolidXFMask}
                                             selected={this.props.pillSelection === 46}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(47)}>
                            <SingleImagePill MedF={MedPillRectangleOutLOneSolidXF}
                                             MedFMask={MedPillRectangleOutLOneSolidXFMask}
                                             selected={this.props.pillSelection === 47}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(48)}>
                            <SingleImagePill MedF={MedPillSquareFlatLZeroSolidXF}
                                             MedFMask={MedPillSquareFlatLZeroSolidXFMask}
                                             selected={this.props.pillSelection === 48}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(49)}>
                            <SingleImagePill MedF={MedPillSquareFlatLZeroSolidLF}
                                             MedFMask={MedPillSquareFlatLZeroSolidLFMask}
                                             selected={this.props.pillSelection === 49}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(50)}>
                            <SingleImagePill MedF={MedPillSquareFlatLOneSolidXF}
                                             MedFMask={MedPillSquareFlatLOneSolidXFMask}
                                             selected={this.props.pillSelection === 50}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(51)}>
                            <SingleImagePill MedF={MedPillSquareFlatLOneSolidLF}
                                             MedFMask={MedPillSquareFlatLOneSolidLFMask}
                                             selected={this.props.pillSelection === 51}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(52)}>
                            <SingleImagePill MedF={MedPillSquareFlatLTwoSolidXF}
                                             MedFMask={MedPillSquareFlatLTwoSolidXFMask}
                                             selected={this.props.pillSelection === 52}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(53)}>
                            <SingleImagePill MedF={MedPillSquareFlatLTwoSolidLF}
                                             MedFMask={MedPillSquareFlatLTwoSolidLFMask}
                                             selected={this.props.pillSelection === 53}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(54)}>
                            <SingleImagePill MedF={MedPillSquareInLZeroSolidXF}
                                             MedFMask={MedPillSquareInLZeroSolidXFMask}
                                             selected={this.props.pillSelection === 54}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(55)}>
                            <SingleImagePill MedF={MedPillSquareInLZeroSolidLF}
                                             MedFMask={MedPillSquareInLZeroSolidLFMask}
                                             selected={this.props.pillSelection === 55}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(56)}>
                            <SingleImagePill MedF={MedPillSquareInLOneSolidXF} MedFMask={MedPillSquareInLOneSolidXFMask}
                                             selected={this.props.pillSelection === 56}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(57)}>
                            <SingleImagePill MedF={MedPillSquareInLOneSolidLF} MedFMask={MedPillSquareInLOneSolidLFMask}
                                             selected={this.props.pillSelection === 57}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(58)}>
                            <SingleImagePill MedF={MedPillSquareInLTwoSolidXF} MedFMask={MedPillSquareInLTwoSolidXFMask}
                                             selected={this.props.pillSelection === 58}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(59)}>
                            <SingleImagePill MedF={MedPillSquareInLTwoSolidLF} MedFMask={MedPillSquareInLTwoSolidLFMask}
                                             selected={this.props.pillSelection === 59}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(60)}>
                            <SingleImagePill MedF={MedPillSquareOutLZeroSolidXF}
                                             MedFMask={MedPillSquareOutLZeroSolidXFMask}
                                             selected={this.props.pillSelection === 60}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(61)}>
                            <SingleImagePill MedF={MedPillSquareOutLZeroSolidLF}
                                             MedFMask={MedPillSquareOutLZeroSolidLFMask}
                                             selected={this.props.pillSelection === 61}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(62)}>
                            <SingleImagePill MedF={MedPillSquareOutLOneSolidXF}
                                             MedFMask={MedPillSquareOutLOneSolidXFMask}
                                             selected={this.props.pillSelection === 62}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(63)}>
                            <SingleImagePill MedF={MedPillSquareOutLOneSolidLF}
                                             MedFMask={MedPillSquareOutLOneSolidLFMask}
                                             selected={this.props.pillSelection === 63}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(64)}>
                            <SingleImagePill MedF={MedPillSquareOutLTwoSolidXF}
                                             MedFMask={MedPillSquareOutLTwoSolidXFMask}
                                             selected={this.props.pillSelection === 64}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(65)}>
                            <SingleImagePill MedF={MedPillSquareOutLTwoSolidLF}
                                             MedFMask={MedPillSquareOutLTwoSolidLFMask}
                                             selected={this.props.pillSelection === 65}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(66)}>
                            <SingleImagePill MedF={MedPillTriangleFlatLZeroSolidXF}
                                             MedFMask={MedPillTriangleFlatLZeroSolidXFMask}
                                             selected={this.props.pillSelection === 66}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(67)}>
                            <SingleImagePill MedF={MedPillTriangleFlatLOneSolidXF}
                                             MedFMask={MedPillTriangleFlatLOneSolidXFMask}
                                             selected={this.props.pillSelection === 67}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(68)}>
                            <SingleImagePill MedF={MedPillTriangleInLZeroSolidXF}
                                             MedFMask={MedPillTriangleInLZeroSolidXFMask}
                                             selected={this.props.pillSelection === 68}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(69)}>
                            <SingleImagePill MedF={MedPillTriangleInLOneSolidXF}
                                             MedFMask={MedPillTriangleInLOneSolidXFMask}
                                             selected={this.props.pillSelection === 69}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(70)}>
                            <SingleImagePill MedF={MedPillTriangleOutLZeroSolidXF}
                                             MedFMask={MedPillTriangleOutLZeroSolidXFMask}
                                             selected={this.props.pillSelection === 70}/></div>
                        <div className="pill-picker-pill-component " onClick={() => this.props.setPillSelection(71)}>
                            <SingleImagePill MedF={MedPillTriangleOutLOneSolidXF}
                                             MedFMask={MedPillTriangleOutLOneSolidXFMask}
                                             selected={this.props.pillSelection === 71}/></div>
                        <div className="pill-picker-pill-component "
                             onClick={() => this.props.setPillSelection(100)}><SingleImagePill MedF={MedXAmpouleOneF}
                                                                                               MedFMask={MedXAmpouleOneFMask}
                                                                                               selected={this.props.pillSelection === 100}/>
                        </div>
                        <div className="pill-picker-pill-component "
                             onClick={() => this.props.setPillSelection(101)}><SingleImagePill MedF={MedXAmpouleTwoF}
                                                                                               MedFMask={MedXAmpouleTwoFMask}
                                                                                               selected={this.props.pillSelection === 101}/>
                        </div>
                        <div className="pill-picker-pill-component "
                             onClick={() => this.props.setPillSelection(102)}><SingleImagePill MedF={MedXVialF}
                                                                                               MedFMask={MedXVialFMask}
                                                                                               selected={this.props.pillSelection === 102}/>
                        </div>
                        <div className="pill-picker-pill-component "
                             onClick={() => this.props.setPillSelection(103)}><SingleImagePill MedF={MedXSprayF}
                                                                                               MedFMask={MedXSprayFMask}
                                                                                               selected={this.props.pillSelection === 103}/>
                        </div>
                        <div className="pill-picker-pill-component "
                             onClick={() => this.props.setPillSelection(104)}><SingleImagePill MedF={MedXSprayNasalF}
                                                                                               MedFMask={MedXSprayNasalFMask}
                                                                                               selected={this.props.pillSelection === 104}/>
                        </div>
                        <div className="pill-picker-pill-component "
                             onClick={() => this.props.setPillSelection(105)}><SingleImagePill MedF={MedXSprayOralF}
                                                                                               MedFMask={MedXSprayOralFMask}
                                                                                               selected={this.props.pillSelection === 105}/>
                        </div>
                        <div className="pill-picker-pill-component "
                             onClick={() => this.props.setPillSelection(106)}><SingleImagePill MedF={MedXSyringeF}
                                                                                               MedFMask={MedXSyringeFMask}
                                                                                               selected={this.props.pillSelection === 106}/>
                        </div>
                        <div className="pill-picker-pill-component "
                             onClick={() => this.props.setPillSelection(107)}><SingleImagePill MedF={MedXInsulinPenF}
                                                                                               MedFMask={MedXInsulinPenFMask}
                                                                                               selected={this.props.pillSelection === 107}/>
                        </div>
                        <div className="pill-picker-pill-component "
                             onClick={() => this.props.setPillSelection(108)}><SingleImagePill MedF={MedXSpoonF}
                                                                                               MedFMask={MedXSpoonFMask}
                                                                                               selected={this.props.pillSelection === 108}/>
                        </div>
                        <div className="pill-picker-pill-component "
                             onClick={() => this.props.setPillSelection(109)}><SingleImagePill MedF={MedXInhalerF}
                                                                                               MedFMask={MedXInhalerFMask}
                                                                                               selected={this.props.pillSelection === 109}/>
                        </div>
                        <div className="pill-picker-pill-component "
                             onClick={() => this.props.setPillSelection(110)}><SingleImagePill MedF={MedXOintmentF}
                                                                                               MedFMask={MedXOintmentFMask}
                                                                                               selected={this.props.pillSelection === 110}/>
                        </div>
                        <div className="pill-picker-pill-component "
                             onClick={() => this.props.setPillSelection(111)}><SingleImagePill MedF={MedXEyeDropsF}
                                                                                               MedFMask={MedXEyeDropsFMask}
                                                                                               selected={this.props.pillSelection === 111}/>
                        </div>
                        <div className="pill-picker-pill-component "
                             onClick={() => this.props.setPillSelection(112)}><SingleImagePill MedF={MedXPipetteF}
                                                                                               MedFMask={MedXPipetteFMask}
                                                                                               selected={this.props.pillSelection === 112}/>
                        </div>
                        <div className="pill-picker-pill-component "
                             onClick={() => this.props.setPillSelection(113)}><SingleImagePill MedF={MedXPowderOneF}
                                                                                               MedFMask={MedXPowderOneFMask}
                                                                                               selected={this.props.pillSelection === 113}/>
                        </div>
                        <div className="pill-picker-pill-component "
                             onClick={() => this.props.setPillSelection(114)}><SingleImagePill MedF={MedXPowderTwoF}
                                                                                               MedFMask={MedXPowderTwoFMask}
                                                                                               selected={this.props.pillSelection === 114}/>
                        </div>
                    </div>

                </CenteredModal>
            </div>
        )
    }
}

PillPicker.propTypes = {
    onHidePillPicker: PropTypes.func,
    pillSelection: PropTypes.number,
    primaryColour: PropTypes.string,
    resetPillToDefault: PropTypes.func,
    secondaryColour: PropTypes.string,
    selectedEntry: PropTypes.object,
    setPillSelection: PropTypes.func,
    setPreviousPrimaryColour: PropTypes.func,
    setPreviousSecondaryColour: PropTypes.func,
    setPrimaryColour: PropTypes.func,
    setSecondaryColour: PropTypes.func,
    showPillPicker: PropTypes.bool
}
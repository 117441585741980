import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import PropTypes from "prop-types";

class PasswordValidity extends Component {

    isEightChars = () => {
        if(this.props.password.length === 0){
            return ""
        }
        else if(this.props.password.length >= 8){
            return "criteria-met"
        }
        else if(this.props.password.length < 8){
            return "criteria-not-met"
        }

    }

    ContainsUpperCase = () => {
        if(this.props.password.length === 0){
            return ""
        }

        else if( /[A-Z]/.test(this.props.password)){
            return "criteria-met"
        } else {
            return "criteria-not-met"
        }
    }

    ContainsLowerCase = () => {
        if(this.props.password.length === 0){
            return ""
        }

        else if( /[a-z]/.test(this.props.password)){
            return "criteria-met"
        } else {
            return "criteria-not-met"
        }
    }

    ContainsNumber = () => {
        if(this.props.password.length === 0){
            return ""
        }

        else if( /\d/.test(this.props.password)){
            return "criteria-met"
        } else {
            return "criteria-not-met"
        }
    }

    ContainsSpecial = () => {

        const specialChars = /(?=.*[!@#&()\-[{}\]_%\\:;|"'`,?/*~$^+=<>.])/;

        if(this.props.password.length === 0){
            return ""
        }

        else if( specialChars.test(this.props.password)){
            return "criteria-met"
        } else {
            return "criteria-not-met"
        }
    }

    render() {
        return (
            <div className={"password-creation small whitespace-pre-line"}>
                {$$("password_creation_title")}
                <div className={this.isEightChars()}> &#8226; { $$("at_Lest_eight_characters")}</div>
                <div className={this.ContainsUpperCase()}> &#8226; {$$("at_Lest_one_uppercase_characters")}</div>
                <div className={this.ContainsLowerCase()}> &#8226; {$$("at_Lest_one_lowercase_characters")}</div>
                <div className={this.ContainsNumber()}> &#8226; {$$("at_Lest_one_number")}</div>
                <div className={this.ContainsSpecial()}> &#8226; {$$("at_Lest_one_special")}</div>
            </div>
        )
    }
}

PasswordValidity.propTypes = {
    password: PropTypes.any,
};

export default PasswordValidity

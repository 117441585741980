import React, {Component} from 'react'
import classNames from 'classnames'
import {$$, _$$} from '../../helpers/localization';
import {usersService} from "../../service/users_service"
import {Collapse} from 'react-collapse'
import moment from 'moment'
import {APPOINTMENTS_FILTER, getColorForOption} from '../../constants/select_options'
import UserImage from '../shared/UserImage'
import {formatUtils} from '../../utils/formatUtils'
import {COMPLETED, IN_PROGRESS, INITIAL, REJECTED} from '../../constants/appointment_payment_status'
import {appointmentUtils} from '../../utils/appointmentUtils'
import CenteredModal from '../shared/CenteredModal';
import {createPayment, fetchSelectedUserAppointments, updateAppointmentStatus} from '../../actions/appointments_actions'
import {connect} from 'react-redux'
import {cliniciansProfileHelper} from '../../helpers/clinician_profile_helper'
import HealthIssueTag from '../health/HealthIssueTag'
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import {getClinicianNameWithTitle} from "../../utils/getClinicianNameWithTitle";
import {CONVERTER} from "../../utils/converter";
import {dateTimeUtils} from "../../utils/dateTimeUtils";
import {formatDate} from "../../utils/converter";
import {getPrescriptionDetailsString, isNhisMedication, isNonMedicinalProduct} from "../../utils/medicationUtils";
import {createMedication} from "../../actions/medications_actions";
import history from "../../helpers/history";
import {Routes} from "../../constants/routes";
import EncounterDetails from "./EncounterDetails";
import {documentsService} from "../../service/docments_service";
import DocumentPicker from "./DocumentPicker";
import {downloadUtils} from "../../utils/downloadUtils";
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";
import {SERVER_URL, TERMS_OF_USE_URL} from "../../constants/api_paths";
import {localiseURL} from "../../utils/urlUtils";
import PrescriptionList from "./PrescriptionList";
import ImportMedications from "./ImportMedications";
import DocumentForm from "../documents/DocumentForm";
import {createDocument, deleteDocument, updateDocument,clearUserDocuments} from "../../actions/documents_actions";
import {fetchSelectedUserHealthIssues} from "../../actions/health-issues_actions";


class AppointmentsListItem extends Component {
    state = {
        cancelAppointmentModalOpened: false,
        acceptAppointmentModalOpened: false,
        appointmentToCancel: null,
        appointmentToAccept: null,
        descriptionOpened: false,
        isEditMode: false,
        appointment: '',
        changingAppointment: false,
        encounter: null,
        importPrescriptionsModal: false,
    }

    constructor(props) {
        super(props);
    }

    prepareClinicianData = async clinician => {
        await cliniciansProfileHelper.prepareDataFromId(clinician, this.props.appointment);

    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        if (this.props.selectedAppointment?.id !== this.props.appointment.id && this.state.descriptionOpened) {
            this.setState({
                descriptionOpened: false
            });
        }
        if (this.props.selectedAppointment?.id !== this.props.appointment.id && this.state.encounter != null) {
            this.setState({
                encounter: null,
                descriptionOpened: false
            });
        }
        if (this.props.selectedAppointment?.id !== this.props.appointment.id && this.state.encounter == null && this.state.descriptionOpened !== false) {
            this.setState({descriptionOpened: false});
        }
        if (this.props.selectedAppointment?.id !== this.props.appointment.id && this.state.isEditMode) {
            this.setState({
                isEditMode: false
            });
        }

        if (snapshot) {
            this.scrollElement(snapshot)
        }
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        let justSelected = this.props.selectedAppointment && this.props.selectedAppointment.id === this.props.appointment.id && prevProps.selectedAppointment?.id !== this.props.appointment.id;
        if (justSelected) {
            return {offsetTop: this.ref.offsetTop, scrollTop: document.scrollingElement.scrollTop}
        }
        return null;
    }

    componentWillUnmount() {
        //this.props.clearUserDocuments();
    }

    scrollElement = (o) => {
        // Store a 'this' ref, and
        const _this = this;
        // wait for a paint before running scrollHeight dependent code.
        window.requestAnimationFrame(function () {
            try {
                let node = _this.ref;
                if (node !== undefined && document.scrollingElement) {
                    let offsetTop = node.offsetTop;
                    let style = node.currentStyle || window.getComputedStyle(node);
                    const marginTopNumber = parseInt(style.marginTop);
                    offsetTop = offsetTop - marginTopNumber;
                    if (Math.round(offsetTop) !== Math.round(o.offsetTop)) {
                        let diff = offsetTop - o.offsetTop;
                        let top = Math.max(o.scrollTop + diff, 0);
                        document.scrollingElement.scrollTo({top: top, behavior: "instant"});
                    }
                }
            } catch (e) {
                console.log(e)
            }
        });
    }

    convertRemToPixels = (rem) => {
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    }

    /**
     * An event handler triggered when an appointment is clicked
     */
    onAppointmentClick = () => {
        this.props.onAppointmentChange(this.props.appointment);
        if (this.props.appointment.encounter_id && this.state.encounter == null) {
            this.retrieveEncounterAndUserDocuments();
        }
    }

    retrieveEncounterAndUserDocuments() {
        return usersService.fetchAppointmentEncounter(this.props.appointment.encounter_id, {userId: this.props.selectedUserId}).then(async (encounter) => {
            if (encounter) {
                this.setState({encounter});
                await documentsService.fetchSelectedUserDocuments(this.props.selectedUserId, {
                    before_date_time: moment().valueOf(),
                    size: 500
                }).then((r) => {
                    this.setState({all_user_documents: r})
                })
            }
        });
    }

    /**
     * Creates the time cell of the table
     *
     * @param {object} appointment - the appointment object
     * @returns {ReactElement} the created table cell
     */
    getTimeCell = (appointment) => {
        return <div className='d-flex'>
            <div className="text-right">
                <span className='font-weight-bold'>
                    {dateTimeUtils.getLongFormattedDate(appointment.starts_at)}
                </span>
                <br/>
                <span className="">
                    <i className='flaticon2-time medrec-grey-2'/>
                    &nbsp; {CONVERTER.getEntryDateTimeFormat(appointment.starts_at)}
                </span>
            </div>
        </div>
    }

    canAnswer = (app) => {
        return app.completion_date + app.appointment_price.opened_communication_duration_days * 86400000 > new Date().getTime()
    }

    consultationTimes = (appointment) => {
        if (appointment.text_only) {
            if (appointment.status === 'ACCEPTED' && appointment.first_response_time === 0 && appointment.payment_status === 'COMPLETED') {
                const time = CONVERTER.formatDate(appointment.ends_at)
                return <span>
                <br/>
                <span className="text-danger">{_$$("consultation_deadline_time", time)}</span>
                </span>
            }
            if (appointment.status === 'COMPLETED' && this.canAnswer(appointment)) {
                const time = CONVERTER.formatDate(appointment.completion_date + appointment.appointment_price.opened_communication_duration_days * 86400000)
                return <span>
                <br/>
                <span className="">{_$$("consultation_follow_up_deadline_time", time)}</span>
                </span>
            }
        }
    }

    /**
     * Creates the name cell of the table
     *
     * @param {object} appointment - the appointment object
     * @returns {ReactElement} the created table cell
     */
    getNameCell = (appointment) => {
        let doctorImageClass = 'patient-img';
        let doctor;
        for (let i = 0; i < appointment.participants.length; i++) {
            if (appointment.participants[i].participant_role === "PROVIDER") {
                doctor = appointment.participants[i];
            }
        }

        for (let i in this.props.clinicians) {
            if (this.props.clinicians[i].id === doctor.user_id) {
                doctor = this.props.clinicians[i]
            }
        }


        return <div className="d-flex">
            <div className='justify-content-center patient-img-container-list'>
                <UserImage userID={doctor.id}
                           classnames={doctorImageClass}/>
            </div>
            <div style={{"alignSelf": "left"}}>
                <span className='patient-fullname'> {getClinicianNameWithTitle(doctor)}</span>
                <br/>
                <span className="medrec-grey-2">
                    <span
                        className="medrec-grey-2 patient-age">{appointmentUtils.appointmentType(appointment.appointment_price)}, <span
                        className="text-lowercase">{appointment.text_only ? $$("text_consultation") : appointmentUtils.displayLocation(appointment)}</span></span>
                </span>
                {this.consultationTimes(appointment)}
                {this.isOnSite(appointment) && appointmentUtils.notOverridenLocation(appointment) && <br/>}
                {this.isOnSite(appointment) && appointmentUtils.notOverridenLocation(appointment) &&
                <span className="text-thin">
                    <i className="fa fa-map-marker-alt mr-1"/>
                    {this.getPracticeDetails(appointment.appointment_price)}
                </span>}
            </div>
        </div>
    }

    getPracticeDetails(price) {
        let text = [price.org_name, price.org_address, price.org_city, price.org_phone_numbers, price.org_website].filter(Boolean).join(", ");
        return text;
    }

    isOnSite(appointment) {
        return appointment.appointment_price.location_type === 'ON_SITE';
    }

    /**
     * Creates the price cell of the table
     *
     * @returns {ReactElement} the created table cell
     */
    getPriceCell = (appointment) => {

        let s = appointment.text_only ? $$('free_label_consultation') : $$('free_label');
        return <div className="payment-item align-self-start">
            {appointment.appointment_price.price_cents === 0 ? s : formatUtils.currencyFormat(appointment.appointment_price.price_cents, appointment.appointment_price.currency)}
        </div>;
    }

    /**
     * Creates the payment status cell of the table
     *
     * @returns {ReactElement} the created table cell
     */
    getPaymentStatus = (appointment) => {
        const consultation_key_suffix = appointment.text_only ? "_consultation" : "";
        let label = "";
        if (appointment.appointment_price.price_cents === 0) {
            label = "";
        } else if (appointment.payment_status === COMPLETED) {
            label = $$('payment_completed' + consultation_key_suffix);
        } else if (appointment.payment_status === INITIAL) {
            label = $$('payment_initial' + consultation_key_suffix);
        } else if (appointment.payment_status === IN_PROGRESS) {
            label = $$('payment_in_progress' + consultation_key_suffix);
        } else if (appointment.payment_status === REJECTED) {
            label = $$('payment_rejected' + consultation_key_suffix);
        }


        let overdue = appointmentUtils.isOverdue(appointment) &&
            (appointment.appointment_price.location_type !== 'ON_SITE' || (appointment.appointment_price.enable_payments_for_on_site_appointments && appointment.appointment_price.billing_type == 'ONLINE'));
        let missedPayment = overdue && appointment.payment_status !== COMPLETED && appointment.payment_status !== IN_PROGRESS && appointment.appointment_price.price_cents > 0;
        label = missedPayment ? $$('missed_payment' + consultation_key_suffix) : label;

        let canPay = ((appointment.payment_status === INITIAL || appointment.payment_status === REJECTED)
                && (appointment.status === 'COMPLETED' || appointment.status === 'ACCEPTED') && appointment.appointment_price.price_cents > 0
                && appointment.appointment_price.currency === 'BGN')
            && !overdue;

        return <div className='d-flex align-items-start'>
            <div className="payment-item">
                <span className={missedPayment ? "text-danger" : ""}>{label} {missedPayment &&
                    <p className="small">({formatDate(appointmentUtils.getAdjustedPayBefore(appointment), false)})</p>}</span>
                {canPay && (appointment.appointment_price.location_type !== 'ON_SITE'
                        || (appointment.appointment_price.enable_payments_for_on_site_appointments && appointment.appointment_price.billing_type == 'ONLINE')) &&
                    <p className="small">{$$("pay_before")}: {formatDate(appointmentUtils.getAdjustedPayBefore(appointment), false)}</p>}
                {canPay && appointment.appointment_price.location_type === 'ON_SITE' && appointment.appointment_price.billing_type === 'ONLINE_AND_ONSITE' &&
                <p className="small" style={{maxWidth: "220px"}}>({$$("pay_online_or_onsite")})</p>
                }
            </div>
            {canPay && (appointment.appointment_price.location_type !== 'ON_SITE'
                    || (appointment.appointment_price.enable_payments_for_on_site_appointments && appointment.appointment_price.billing_type !== 'ONSITE')) &&
                <div className="ml-3">
                    <button onClick={() => {
                        this.setState({showPaymentModal: true})
                    }} className="btn btn-pay btn-sm btn-danger ">
                        {$$("pay_now")}
                    </button>
                </div>
            }
        </div>
    }

    /**
     * Creates the health issues list cell of the table
     *
     * @param {object} appointment - the appointment object
     * @returns {ReactElement} the created table cell
     */
    getHealthIssueListCell = (appointment) => {
        if (appointment.health_issues && appointment.health_issues.length > 0) {
            let healthIssueList = appointment.health_issues.map((h, idx) => {
                return <HealthIssueTag
                    key={idx}
                    className='patient-health-issue-list health-issue-cell-appointments'
                    styles={{height: "fit-content", maxWidth: "100%"}}
                    healthIssue={h.name}
                />
            });

            return <div className="d-flex flex-wrap flex-fill flex-basis-full-width pt-2">
                <div className="d-flex flex-wrap">{healthIssueList}</div>
            </div>
        }
        return null;
    }


    /**
     * Creates the status cell of the table
     *
     * @param {object} appointment - the appointment object
     * @returns {ReactElement} the created table cell
     */
    getStatusCell = (appointment) => {
        return <div className="status-cell-appointment align-items-start">
            <span className="patient-age"
                  style={{"color": getColorForOption(APPOINTMENTS_FILTER.COLOR, appointment.status)}}>
                {this.getStatusString(appointment)}
            </span>
        </div>
    }

    getStatusString = (appointment) => {
        return appointment.text_only ? $$(`${appointment.status.toLowerCase()}_consultation`) : $$(appointment.status.toLowerCase())
    }

    /**
     * Cancel appointment on confirmation
     */
    cancelAppointment = async () => {
        await this.props.updateAppointmentStatus(this.state.appointmentToCancel.id, "CANCELED_BY_PATIENT", undefined, this.props.selectedUser.id);
        this.setState({cancelAppointmentModalOpened: false});
        this.props.fetchSelectedUserAppointments(this.props.selectedUser.id, 0, (Date.now() + dateTimeUtils.YEAR_IN_MS));
    }

    /**
     * Approve appointment on confirmation
     */
    confirmAppointment = async () => {
        await this.props.updateAppointmentStatus(this.state.appointmentToAccept.id, "ACCEPTED", undefined, this.props.selectedUser.id);
        this.setState({acceptAppointmentModalOpened: false});
        this.props.fetchSelectedUserAppointments(this.props.selectedUser.id, 0, (Date.now() + dateTimeUtils.YEAR_IN_MS));
    }

    executePayment = (method) => {
        let appId = this.props.appointment.id;
        let lang = this.props.i18n.selected.lang;

        let payment_method = method === "paylogin" ? "epay" : "epay_cc";

        this.props.createPayment({
            appointmentId: appId, payment_method: payment_method
        }).then(res => {
            if (!res.endpoint) {
                //handle empty response -> the server returns empty response when the appointment payment status is COMPLETED e.g. we're working with stale data.
                //todo: hide payment dialog, show warning that it's already paid and refresh the appointment.
                return;
            }
            let form = document.createElement("form");
            form.setAttribute("action", res.endpoint);
            form.setAttribute("method", "POST");
            form.appendChild(createTextInput("PAGE", method));
            form.appendChild(createTextInput("LANG", lang.toUpperCase()));
            form.appendChild(createTextInput("ENCODED", res.encoded));
            form.appendChild(createTextInput("CHECKSUM", res.checksum));
            let urlOk = window.aptechko ? `${SERVER_URL}/public/user/payment_page/ok?lang=${lang === 'bg' ? "bg" : "en"}` : window.location.href + "?status=OK&id=" + appId;
            form.appendChild(createTextInput("URL_OK", urlOk));
            let urlCancel = window.aptechko ? `${SERVER_URL}/public/user/payment_page/error?lang=${lang === 'bg' ? "bg" : "en"}` : window.location.href + "?status=CANCELED&id=" + appId;
            form.appendChild(createTextInput("URL_CANCEL", urlCancel));
            document.body.appendChild(form);
            if (window.aptechko) {
                form.target = "_blank";
            }
            form.submit();
            if (window.aptechko) {
                this.setState({showPaymentModal: false, agreement_checked: false})
                history.push(Routes.APPOINTMENTS + "?status=WAITING&id=" + appId);
            }
        });
    }

    showDocumentPicker = async () => {
        await this.retrieveEncounterAndUserDocuments().then(() => {
            this.setState({showDocumentPicker: true})
        })

    }

    /**
     * Returns the cancel appointment button
     */
    getButtons = (appointment) => {
        let clinicianId = null;
        this.props.appointment.participants.forEach((participant) => {
            if (participant.participant_role === "PROVIDER") {
                clinicianId = participant.user_id
            }
        })
        return <div className='buttons-appointments flex-fill'>
            <button type="button" className='btn btn-pay btn-sm btn-primary' onClick={(e) => {
                e.preventDefault();
                this.props.showNotes(this.props.appointment)
            }}>
                <i className='fa fa-sticky-note'/><span>{$$('notes_label')}</span>
            </button>

            {(appointment.status === 'WAITING_FOR_DOCTOR_APPROVAL' ||
                    appointment.status === 'WAITING_FOR_PATIENT_APPROVAL' ||
                    appointment.status === 'NEED_MORE_INFO' ||
                    appointment.status === 'ACCEPTED') &&
                <button type="button" className='btn btn-pay btn-sm btn-primary' onClick={() => {
                    this.prepareClinicianData(clinicianId)
                }}>
                    <i className='fa fa-pencil-alt'/><span>{$$('edit_label')}</span>
                </button>}

            {appointment.status === 'WAITING_FOR_PATIENT_APPROVAL' &&
                <button type="button" className='btn btn-pay btn-sm btn-primary'
                        onClick={() => {
                            this.setState({acceptAppointmentModalOpened: true, appointmentToAccept: appointment});
                        }}>
                    <i className='fa flaticon2-check-mark'/><span>{$$('confirm_appointment_btn')}</span>
                </button>}

            {(appointment.status === 'WAITING_FOR_DOCTOR_APPROVAL' ||
                    appointment.status === 'WAITING_FOR_PATIENT_APPROVAL' ||
                    appointment.status === 'NEED_MORE_INFO' ||
                    appointment.status === 'ACCEPTED') &&
                appointment.encounter_id == null &&
                (!appointment.text_only || (appointment.text_only && (!appointmentUtils.isPaid(appointment) || appointmentUtils.isFree(appointment)))) &&
                <button type="button" className='btn btn-pay btn-sm btn-primary' onClick={() => {

                    this.setState({cancelAppointmentModalOpened: true, appointmentToCancel: appointment})
                }}
            >
                <i className='fa fa-times'/><span>{$$(appointment.text_only ? 'cancel_consultation_label' : 'cancel_appointment_label')}</span>
            </button>}

            {appointment.encounter_id != null &&
                <button type="button" className='btn btn-pay btn-sm btn-primary' onClick={() => {
                    this.showDocumentPicker();
                }}
                >
                    <i className='fas fa-paperclip'/><span>{$$('results_documents_button')}</span>
                </button>}
        </div>
    }

    updateEncounterDocuments = (document_ids) => {
        usersService.updateEncounterDocuments(this.props.selectedUserId, this.state.encounter.id, document_ids).then((res) => {
            this.setState({encounter: res, showDocumentPicker: false});
        })
    }

    createMedication = async (form, userId) => {
        await this.props.createMedication(form, userId);
    }

    getPatientName = () => {
        for (let i in this.state.encounter.participants) {
            if (this.state.encounter.participants[i].participant_role === "PATIENT") {
                return this.state.encounter.participants[i].fullname
            }
        }
        return ""
    }

    getClinicianName = () => {
        for (let i in this.state.encounter.participants) {
            if (this.state.encounter.participants[i].participant_role === "PROVIDER") {
                return this.state.encounter.participants[i].fullname
            }
        }
        return ""
    }

    /*getFormattedPrescriptions = () => {
        let prescriptions = []

        for (const p of this.state.encounter.prescriptions) {
            let prescription;
            if (isNonMedicinalProduct(p)) {
                prescription = $$("nhis_supplement") + ": " + p.notes.replace(/(?:\r\n|\r|\n)/g, "; ");
            } else {
                prescription = p.name + ", " + getPrescriptionDetailsString(p)
            }
            prescriptions.push(prescription)
        }

        return prescriptions
    }*/

    getFormattedPrescriptions = () => {

        function sortPrescriptions(prescriptions) {
            let regular = [];
            let nonMedicinal = [];
            prescriptions.forEach(p => {
                if (isNonMedicinalProduct(p)) {
                    nonMedicinal.push(p);
                    return;
                }
                regular.push(p);
            });

            regular.sort((a, b) => {
                let result = compareByProp("name")(a,b);
                if (result === 0) {
                    result = compareByProp("nhis_prescription_nrn")(a,b);
                    if (result === 0) {
                        result = compareByProp("nhis_prescription_medication_sequence_id")(a,b);
                        if (result === 0) {
                            return compareByProp("nhis_prescription_dosage_instruction_sequence")(a,b);
                        }
                        return result;
                    }
                    return result;
                }
                return result;
            })
            nonMedicinal.sort(compareByProp("notes"))
            return [...regular, ...nonMedicinal];
        }

        const compareByProp = (prop) => {
            return function (a, b) {
                if (a[prop] == null && b[prop] != null) {
                    return 1
                }

                if (b[prop] == null && a[prop] != null) {
                    return -1
                }

                if (a[prop] < b[prop]) {
                    return -1;
                }
                if (a[prop] > b[prop]) {
                    return 1;
                }
                return 0;
            }
        }

        let res = [];

        let sorted = sortPrescriptions(this.state.encounter.prescriptions);

        for (let i in sorted) {
            let prescription = sorted[i];
            if (!isNonMedicinalProduct(prescription)) {
                let prescriptionText = prescription.name + ", " + (isNhisMedication(prescription) ? prescription.notes.replace(/(?:\n\r|\r\n|\r|\n)/g, "; ") : getPrescriptionDetailsString(prescription))
                res.push(prescriptionText)
            } else {
                let prescriptionText = $$("nhis.supplement") + ": " + prescription.notes.replace(/(?:\n\r|\r\n|\r|\n)/g, "; ");
                res.push(prescriptionText)
            }
        }
        return res
    }

    downloadAppointmentResult = () => {

        let examDetails = {
            reportTitle: $$("examination_outcome"),
            patientName: this.getPatientName(),
            appointmentDateTime: moment(this.state.encounter.server_created_timestamp).format(this.props.settings.data.dateFormat.toUpperCase()),
            clinicianName: this.getClinicianName(),
            datetime: moment(new Date()).format(this.props.settings.data.dateFormat.toUpperCase()),
            mainDiagnosis: this.state.encounter.main_diagnosis ? this.state.encounter.main_diagnosis : "",
            concomitantDiseases: this.state.encounter.concomitant_diseases ? this.state.encounter.concomitant_diseases : "",
            objectiveData: this.state.encounter.objective_data ? this.state.encounter.objective_data : "",
            subjectiveData: this.state.encounter.subjective_data ? this.state.encounter.subjective_data : "",
            therapy: this.state.encounter.therapy ? this.state.encounter.therapy : "",
            tests: this.state.encounter.tests ? this.state.encounter.tests : "",
            notes: this.state.encounter.additional_info ? this.state.encounter.additional_info : "",
            prescriptions: this.getFormattedPrescriptions(),
            tests_results: this.prepareTestResults(this.state.encounter.tests_results),
            isPreliminaryDiagnosis: this.state.encounter.preliminary_diagnosis || false
        }
        documentsService.downloadExamResult(this.props.i18n.selected.lang, examDetails).then((file) => {
            downloadUtils.download(file, "ExamReport_" + moment(this.state.encounter.server_created_timestamp).format("YYYYMMDD") + ".pdf");
        });
    }

    getValue = (field) => {
        let value = field.value;
        if (!value) {
            return "";
        }
        switch (field.type) {
            case 'calculate':
            case 'number':
                return value.f_value !== null ? value.f_value : "";
            case 'boolean':
                return value.b_value !== null ? value.b_value : "";
            case 'date':
                return value.date_value ? value.date_value : "";
            case 'string':
                return value.s_value ? value.s_value : "";
            default:
                return "";
        }
    }

    testHasValue = (test) => {
        return test.groups.find(g => {
            return this.groupHasValue(g)
        });
    }

    groupHasValue = (group) => {
        return group.rows.find(r => {
            return this.rowHasValue(r)
        });
    }

    rowHasValue(row) {
        return row.fields.find(f => {
            return this.getValue(f) !== ""
        });
    }

    prepareTestResults = (tests) => {
        if (!tests?.length > 0) {
            return null;
        }
        let result = [];
        for (let i = 0; i < tests.length; i++) {
            const test_result = _.cloneDeep(tests[i]);
            let res = [];
            for (let m = 0; m < test_result.tests.length; m++) {
                const test = test_result.tests[m]
                if (this.testHasValue(test)) {
                    res.push(test);
                    let groups = [];
                    for (let j = 0; j < test.groups.length; j++) {
                        const group = test.groups[j];
                        if (this.groupHasValue(group)) {
                            groups.push(group);
                            let rows = [];
                            for (let k = 0; k < group.rows.length; k++) {
                                const row = group.rows[k];
                                if (this.rowHasValue(row)) {
                                    rows.push(row)
                                }
                            }
                            group.rows = rows;
                        }
                    }
                    test.groups = groups;
                }
            }
            test_result.tests = res;
            result.push(test_result)
        }
        return result;
    }

    closeImportPrescriptions = () => {
        this.setState({
            importPrescriptionsModal: false
        })
    }

    isEncounterEmpty = () => {
        return !this.state.encounter.main_diagnosis &&
            !this.state.encounter.objective_data &&
            !this.state.encounter.subjective_data &&
            !this.state.encounter.therapy &&
            this.state.encounter.concomitant_diseases.length === 0 &&
            this.state.encounter.tests.length === 0 &&
            this.state.encounter.tests_results.length === 0 &&
            this.state.encounter.document_ids.length === 0 &&
            this.state.encounter.provider_document_ids.length === 0 &&
            this.state.encounter.prescriptions.length === 0;
    }

    getMore = () => {
        let hasDisclaimer = this.props.selectedAppointment?.appointment_price.disclaimer;
        let hasDescription = this.props.selectedAppointment?.appointment_price.description;
        return <div>
            {(hasDisclaimer || hasDescription) &&
                <hr className="hr-text mt-0" data-content={$$("description")}/>}
            {hasDescription &&
                <div className={"pl-4"}>
                    <p className="white-space-pre-line">{this.props.selectedAppointment?.appointment_price.description}</p>
                </div>
            }
            {hasDisclaimer &&
                <div className={"pl-4"}>
                    <p className="white-space-pre-line">{this.props.selectedAppointment?.appointment_price.disclaimer}</p>
                </div>
            }
        </div>
    }

    openAddDocument = () => {
        this.setState({
            showDocumentPicker: false,
            showAddDoc: true
        })
    }

    onHideAddUpdateModal = () => {
        this.retrieveEncounterAndUserDocuments();
        this.setState({
            showDocumentPicker: true,
            showAddDoc: false
        })
    }

    clearSelectedEntry = () => {
        this.setState({
            selectedEntry: null
        })
    }

    addNewDoc = (form, id) => {
        return this.props.createDocument(form, id).then(
            this.retrieveEncounterAndUserDocuments()
        )
    }

    render() {
        const activeClass = classNames('appointment-containter', {
            'appointment-active': (this.props.selectedAppointment && this.props.selectedAppointment.id === this.props.appointment.id)
        });
        classNames('appointments-tab-column-label medrec-blue-1', {
            'note-hidden': (this.props.selectedAppointment && this.props.selectedAppointment.id !== this.props.appointment.id)
        });
        return (
            <div className={activeClass} onClick={this.onAppointmentClick} ref={(ref) => {
                this.ref = ref
            }}>
                <div className='low-shadow-container'>
                    <div className="d-flex flex-wrap flex-column">
                        <div className="d-flex justify-content-between">
                            {this.getNameCell(this.props.appointment)}
                            {this.getTimeCell(this.props.appointment)}
                        </div>
                        {this.getHealthIssueListCell(this.props.appointment)}
                        <div className="d-flex pt-3 mt-3 border-top-light">
                            {this.getStatusCell(this.props.appointment)}
                            {this.getPriceCell(this.props.appointment)}
                            {this.getPaymentStatus(this.props.appointment)}
                        </div>
                        <div className="pt-3 mt-3 border-top-light show-more">
                            {this.getButtons(this.props.appointment)}
                        </div>
                    </div>
                    <div className="text-center p-3 show-more">
                        <a onClick={() => {
                            this.setState({descriptionOpened: !this.state.descriptionOpened})
                        }}>
                            {!this.state.descriptionOpened &&
                                <span className="mr-2">{$$("more")}</span>
                            }
                            {!this.state.descriptionOpened &&
                                <i className="kt-nav__link-icon fas fa-chevron-down medrec-blue-1 icon-thin"
                                />
                            }
                            {this.state.descriptionOpened &&
                                <span className="mr-2">{$$("less")}</span>
                            }
                            {this.state.descriptionOpened &&
                                <i className="kt-nav__link-icon fas fa-chevron-up medrec-blue-1 icon-thin"
                                />
                            }

                        </a>
                    </div>
                </div>
                {this.state.showDocumentPicker && this.state.encounter
                    && <DocumentPicker
                        provider_document_ids={this.state.encounter.provider_document_ids}
                        document_ids={this.state.encounter.document_ids}
                        openAddDocument={this.openAddDocument}
                        all_docs={this.state.all_user_documents || []}
                        onHide={() => {
                            this.setState({showDocumentPicker: false})
                        }}
                        updateDocuments={this.updateEncounterDocuments}
                    />}
                {this.state.showAddDoc &&
                    <DocumentForm
                        settings={this.props.settings}
                        show={this.state.showAddDoc}
                        onHide={this.onHideAddUpdateModal}
                        i18n={this.props.i18n}
                        selectedUser={this.props.selectedUser}
                        selectedEntry={this.state.selectedEntry}
                        createEntry={this.addNewDoc}
                        clearSelectedEntry={this.clearSelectedEntry}
                        formDisabled={this.props.formDisabled}
                        fetchSelectedUserHealthIssues={this.props.fetchSelectedUserHealthIssues}
                        healthIssues={this.props.healthIssues}
                    />

                }
                {this.state.encounter != null && this.props.selectedAppointment.encounter_id
                && this.props.selectedAppointment && this.props.selectedAppointment.id === this.props.appointment.id && this.props.selectedAppointment.status === 'COMPLETED' &&
                <Collapse isOpened={this.state.descriptionOpened}>
                    {this.getMore()}
                    {!this.isEncounterEmpty() && <>
                        <DownloadEditDeleteButtons handleDownloadClick={this.downloadAppointmentResult}
                        />
                        <span className="pointer"
                              onClick={this.downloadAppointmentResult}>{$$(this.props.appointment.text_only ? "download_as_pdf_consultation" : "download_as_pdf")} </span>
                        </>}
                        <EncounterDetails encounter={this.state.encounter}
                                          isEncounterEmpty={this.isEncounterEmpty()}
                                          text_only={this.props.appointment.text_only}
                                          all_user_documents={this.state.all_user_documents}
                                          selectedUser={this.props.selectedUser}
                        />
                        {this.state.encounter.prescriptions.length > 0 && <div>
                            <hr/>
                            <div className="row full-width">
                                <div className='medrec-grey-2' style={{
                                    "width": "20%",
                                    "textAlign": "right",
                                    "padding": "20px"
                                }}>{$$("prescriptions")}</div>
                                <div style={{
                                    "width": "80%",
                                    "textAlign": "left",
                                    "whiteSpace": "pre-wrap",
                                    "padding": "20px",
                                    "paddingLeft": "40px"
                                }}>
                                <PrescriptionList prescriptions={this.state.encounter.prescriptions} />
                                <div className="cursor-pointer mt-2" onClick={() => {
                                    this.setState({importPrescriptionsModal: true})
                                }}>
                                    <span className="fa fa-plus add-condition"/>
                                    {$$('import')}
                                </div>
                            </div>
                        </div>
                    </div>}
                    {this.isEncounterEmpty() &&
                    <>
                        <br/>
                        <div className='medrec-grey-2 center-text'>{$$("no_additional_exam_results")}</div>
                        <br/>
                    </>}
                </Collapse>}
                {(this.props.selectedAppointment && this.props.selectedAppointment.id === this.props.appointment.id &&
                        (this.state.encounter == null || this.props.selectedAppointment.status !== 'COMPLETED')) &&
                    <Collapse isOpened={this.state.descriptionOpened}>
                        {this.getMore()}

                    <hr className="hr-text"
                        data-content={$$(this.props.appointment.text_only ? "consultation_results_data_separator" : "eccounter_data_separator")}/>
                        <br/>
                    <div
                        className='medrec-grey-2 center-text'>{$$(this.props.appointment.text_only ? "no_encounter_found_label_consultation" : "no_encounter_found_label")}</div>
                        <br/>
                    </Collapse>}
                {this.state.appointmentToCancel &&
                <CenteredModal
                    title={$$(this.state.appointmentToCancel.text_only ? 'cancel_consultation_confirmation_header' : 'cancel_appointment_confirmation_header')
                        .replace('{fullname}', this.state.appointmentToCancel.participants.filter(a => a.participant_role === 'PROVIDER')[0].fullname)
                        .replace('{date}', moment(this.state.appointmentToCancel.text_only ? this.state.appointmentToCancel.server_created_timestamp : this.state.appointmentToCancel.starts_at).locale(this.props.i18n.selected.lang).format('D MMMM HH:mm'))}
                    show={this.state.cancelAppointmentModalOpened}
                    onHide={() => {
                        this.setState({cancelAppointmentModalOpened: false})
                    }}
                    onConfirm={this.cancelAppointment}
                    confirmBtnLabel={$$(this.state.appointmentToCancel.text_only ? 'cancel_consultation_label' : 'cancel_appointment_label')}
                >
                    {$$(this.state.appointmentToCancel.text_only ? 'cancel_consultation_confirmation_body' : 'cancel_appointment_confirmation_body')}
                </CenteredModal>
                }
                {this.state.appointmentToAccept &&
                    <CenteredModal title={$$('accept_appointment_confirmation_header')
                        .replace('{fullname}', this.state.appointmentToAccept.participants.filter(a => a.participant_role === 'PROVIDER')[0].fullname)
                        .replace('{date}', moment(this.state.appointmentToAccept.starts_at).locale(this.props.i18n.selected.lang).format('D MMMM HH:mm'))}
                                   show={this.state.acceptAppointmentModalOpened}
                                   onHide={() => {
                                       this.setState({acceptAppointmentModalOpened: false})
                                   }}
                                   onConfirm={this.confirmAppointment}
                                   confirmBtnLabel={$$('accept_appointment_label')}
                    >
                        {$$('accept_appointment_confirmation_body')}
                    </CenteredModal>
                }
                {
                    this.state.showPaymentModal && <CenteredModal
                        show={true}
                        onHide={() => {
                            this.setState({showPaymentModal: false, agreement_checked: false})
                        }}
                        primary={true}>
                        <p className="h4">
                            {$$(this.props.appointment.text_only ? "payment_option_consultation_modal_body" : "payment_option_modal_body")
                                .replace('{fullname}', this.props.appointment.participants.filter(a => a.participant_role === 'PROVIDER')[0].fullname)
                                .replace('{date}', moment(this.props.appointment.starts_at).locale(this.props.i18n.selected.lang).format('D MMMM HH:mm'))
                            }, {$$("total_sum")}&nbsp;{formatUtils.currencyFormat(this.props.appointment.appointment_price.price_cents, this.props.appointment.appointment_price.currency)}
                        </p>

                        <div className="mt-3 mb-3">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" id="agreement_input" name="agreement_input"
                                       className="custom-control-input" onChange={() => {
                                    this.setState({agreement_checked: !this.state.agreement_checked})
                                }}/>
                                <label className="custom-control-label" htmlFor="agreement_input">
                               <span className="ml-2 font-weight-normal">
                                    {$$("cancellation_policy_agreement_label_first_part")}&nbsp; <a target="_blank"
                                                                                                    href={localiseURL(TERMS_OF_USE_URL)}
                                                                                                    className="text-decoration-underline"
                                                                                                    rel="noreferrer">{$$("cancellation_policy")}</a>
                                </span>
                                </label>
                            </div>
                        </div>
                        <div className="d-table">
                            <div className="d-table-row">
                                <Button className="d-table-cell" style={{minWidth: "140px"}}
                                        disabled={!(this.state.agreement_checked)} onClick={() => {
                                    this.executePayment("paylogin")
                                }}>{$$("pay_with_epay_button")}</Button>
                                <span className="d-table-cell p-3">{$$("epay_payment_option_descr")}</span>
                            </div>
                            <div className="d-table-row">
                                <Button className="d-table-cell" style={{minWidth: "140px"}}
                                        disabled={!(this.state.agreement_checked)} onClick={() => {
                                    this.executePayment("credit_paydirect")
                                }}>{$$("pay_with_card_button")}</Button>
                                <span className="d-table-cell p-3">{$$("cc_payment_option_descr")}</span>
                            </div>
                        </div>
                    </CenteredModal>
                }
                {
                    this.props.paymentStatus && this.props.paymentAppId === this.props.appointment.id &&
                    <Modal show={true} onHide={this.props.clearPaymentStatus}
                           backdrop={this.props.paymentStatus === 'OK' || this.props.paymentStatus === 'WAITING' ? 'static' : true}
                    >

                        <Modal.Header closeButton={this.props.paymentStatus !== 'OK'}/>
                        <Modal.Body>
                            <div>
                                {(this.props.paymentStatus === 'OK' || this.props.paymentStatus === 'WAITING') && this.props.appointment.payment_status !== 'COMPLETED' &&
                                    <div>
                                        <Spinner animation={"border"}/> <span
                                        className="ml-2">{$$("payment_payed_waiting_body_text")}</span>
                                    </div>}
                                {(this.props.paymentStatus === 'OK' || this.props.paymentStatus === 'WAITING') && this.props.appointment.payment_status === 'COMPLETED' &&
                                    <div>
                                        <span className="">{$$("payment_completed_body_text")}</span>
                                    </div>}
                                {this.props.paymentStatus === 'CANCELED' &&
                                    <div>{$$("payment_canceled_body_text")}</div>}
                            </div>
                            {(this.props.paymentStatus === 'CANCELED' || this.props.appointment.payment_status === 'COMPLETED' || this.props.paymentStatus === 'WAITING') &&
                                <Modal.Footer>
                                    <Button variant='secondary'
                                            onClick={this.props.clearPaymentStatus}>
                                        {$$('close_btn_label')}
                                    </Button>
                                </Modal.Footer>}
                        </Modal.Body>
                    </Modal>
                }
                {this.state.encounter && <ImportMedications
                    prescriptions={this.state.encounter.prescriptions}
                    show={this.state.importPrescriptionsModal}
                    i18n={this.props.i18n}
                    settings={this.props.settings.data}
                    formDisabled={this.props.formDisabled}
                    selectedUser={this.props.user}
                    encounter={this.state.encounter}
                    onClose={this.closeImportPrescriptions}
                    openMedPage={()=>{history.push(Routes.MEDICATIONS)}}
                    createMedication={this.createMedication}/>
                }
            </div>
        )
    }

}

function createTextInput(name, value) {
    let input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", name);
    input.setAttribute("value", value);
    input.setAttribute("autocomplete", "off");
    return input;
}

AppointmentsListItem.propTypes = {
    appointment: PropTypes.object,
    clinician: PropTypes.object,
    fetchSelectedUserAppointments: PropTypes.func,
    paymentStatus: PropTypes.any,
    paymentAppId: PropTypes.any,
    user: PropTypes.any,
    createMedication: PropTypes.func,
    i18n: PropTypes.object,
    onAppointmentChange: PropTypes.func,
    clearPaymentStatus: PropTypes.func,
    createPayment: PropTypes.func,
    selectedAppointment: PropTypes.object,
    healthIssues: PropTypes.object,
    selectedUser: PropTypes.object,
    clinicians: PropTypes.array,
    selectedUserId: PropTypes.string,
    showNotes: PropTypes.func,
    createDocument: PropTypes.func,
    clearUserDocuments: PropTypes.func,
    updateDocument: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    deleteDocument: PropTypes.func,
    settings: PropTypes.any,
    formDisabled: PropTypes.any,
    updateAppointmentStatus: PropTypes.func
}

function mapStateToProps(state) {
    return {
        user: state.selectedUser.data,
        clinicians: state.providers.entries,
        clinician: state.provider.data,
        settings: state.settings,
        healthIssues: state.healthIssues,
        formDisabled: state.formInteractions

    }
}

const mapDispatchToProps = {
    fetchSelectedUserAppointments,
    updateAppointmentStatus,
    createPayment,
    createMedication,
    createDocument,
    clearUserDocuments,
    updateDocument,
    fetchSelectedUserHealthIssues,
    deleteDocument
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsListItem);
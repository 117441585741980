import React, {Component} from 'react'
import PropTypes from "prop-types";


class ResponseTimeButton extends Component {
    state = {}

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <button
                onClick={
                    () => {
                        this.props.onClick(this.props.responseTime)
                    }}
                className="btn response-time-item"
            >
                <div className="d-flex">
                    <span>
                        <i className="far fa-clock" style={{color: "#BBCDD9"}}/>
                    </span>
                    <span>
                        {this.props.label}
                    </span>
                </div>
            </button>
        )
    }
}

ResponseTimeButton.propTypes = {
    label: PropTypes.string,
    responseTime: PropTypes.number,
    onClick: PropTypes.func
}

export default ResponseTimeButton;

import React, {Component} from 'react'
import {$$} from '../../helpers/localization';
import CenteredModal from "../shared/CenteredModal";
import ListGroup from "react-bootstrap/ListGroup";
import {ListGroupItem} from "react-bootstrap";
import {ReactComponent as FileIcon} from "../../../public/media/icons/svg/Design/File.svg";
import {convertDocumentTypeToText} from "../../utils/documentUtils";
import {formatUtils} from "../../utils/formatUtils";
import {dateTimeUtils} from "../../utils/dateTimeUtils";
import no_data from "../../resources/images/no_data.png";
import {infoUtils} from "../../utils/infoUtils";
import PropTypes from "prop-types";

export default class DocumentPicker extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            document_ids: [...props.document_ids],
            all_docs: this.sort(this.filter(props.all_docs, this.props.provider_document_ids), props.document_ids)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.all_docs !== this.props.all_docs) {
            this.setState({
                all_docs: this.sort(this.filter(this.props.all_docs, this.props.provider_document_ids), this.props.document_ids)
            })
        }
    }

    getNoDataObject = (label) => {
        return {
            imgClass: 'no-docs-img',
            objClass: 'no-data-object-documents',
            primaryLabelClass: 'no-hospitals-primary-label',
            src: no_data,
            primaryLabel: label,
        }
    }


    filter(all, exclude) {
        return all.filter(d => exclude.indexOf(d.id) === -1);
    }

    sort(all, selected) {
        let selectedAvailable = [];
        let rest = [];
        all.forEach(e => {
            if (selected.indexOf(e.id) > -1) {
                selectedAvailable.push(e);
            } else {
                rest.push(e)
            }
        })
        selectedAvailable.push(...rest)
        return selectedAvailable;
    }

    updateEncounterDocuments = () => {
        this.props.updateDocuments(this.state.document_ids)
    }

    modify = (id) => {
        let indexOf = this.state.document_ids.indexOf(id);
        if (indexOf > -1) {
            this.state.document_ids.splice(indexOf, 1);
            this.setState({document_ids: [...this.state.document_ids]})
        } else {
            this.setState({document_ids: [...this.state.document_ids, id]})
        }
    }

    render() {
        return (
            <CenteredModal
                show={true}
                onHide={this.props.onHide}
                onConfirm={this.updateEncounterDocuments}
                header={<div>{$$("attach_documents_to_appointment_title")}
                    <div
                        className="pt-3 font-size-1rem text-danger">{$$("attach_documents_to_appointment_explanation")}</div>
                </div>}
                confirmBtnLabel={this.state.document_ids.length === 0 && this.props.document_ids.length > 0 ? $$("remove_all_documents_from_appointment_btn") : $$("attach_documents_to_appointment_title")}
                extraBtnClick={this.props.openAddDocument}
                idBtnExtra={"deny-btn"}
                disablePrimary={this.state.document_ids.length === 0 && this.props.document_ids.length === 0}
                extraBtnLabel={$$('new_document')}
                classBtnExtra={"mr-auto"}
                headerClassName={"h5 height-auto"}
                className="pt-0"
            >
                <div>
                    <ListGroup variant={"flush"}>
                        {
                            this.state.all_docs.length > 0 ? this.state.all_docs.map(doc => {
                                return <ListGroupItem className="cursor-pointer hover-list-item" variant={"light"}
                                                      key={doc.id} onClick={() => this.modify(doc.id)}>
                                    <div key={doc.id} className={"row"}>
                                        <div className="col-sm-12 col-md-1">
                                            <input checked={this.state.document_ids.indexOf(doc.id) > -1}
                                                   id={`chk_${doc.id}`} readOnly={true}
                                                   className="symptom-checkbox" type="checkbox"/>
                                            <div className="checkbox-label">
                                                <i className={this.state.document_ids.indexOf(doc.id) > -1 ? "fa fa-check wht" : "fa fa-check no-display"}
                                                   aria-hidden="true"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-4"
                                             style={{'marginTop': '3%', 'display': 'flex'}}>
                                            <div style={{'paddingTop': '5px', 'marginRight': '15px'}}>
                                                <FileIcon className="svg-icon" height="21px" width="29px"
                                                          style={{'fill': '#B4C5D0'}}/>
                                            </div>
                                            <div className="menu-tab2" style={{overflowWrap: "anywhere"}}>
                                                {doc.title}
                                                <div style={{
                                                    'fontSize': '13px',
                                                    'color': '#9BABB6',
                                                    'paddingTop': '3%'
                                                }}>{doc.filename}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-3" style={{'marginTop': '3%'}}>
                                            <div className="menu-tab2">
                                                {convertDocumentTypeToText(doc)}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-2" style={{'marginTop': '3%'}}>
                                            <div className="menu-tab2">{formatUtils.bytesToSize(doc.size)}</div>
                                        </div>
                                        <div className="col-sm-12 col-md-2" style={{'marginTop': '3%'}}>
                                            <div
                                                className="menu-tab2">{dateTimeUtils.getFormattedDate(doc.date_time)}</div>
                                        </div>
                                    </div>
                                </ListGroupItem>
                            }) : infoUtils.noData(this.getNoDataObject($$('no_documents_label')))
                        }
                    </ListGroup>
                </div>
            </CenteredModal>
        );
    }
}

DocumentPicker.propTypes = {
    openAddDocument: PropTypes.func,
    onHide: PropTypes.func,
    document_ids: PropTypes.array,
    provider_document_ids: PropTypes.array,
    all_docs: PropTypes.array,

}
export const
    sq = {
        text: {
            back_to_hospitals: "Kthehu te Spitalet",
            morning_time: "Orari i mëngjesit",
            evening_time: "Orari i mbrëmjes",
            noon_time: "Orari i mesditës",
            bed_time: "Orari para gjumit",
            back_label: "Kthehu",
            add_profile_photo: "Shto foto",
            congrats_message: "Urime",
            continue_medrec: "Vazhdoni te Medrec-M",
            registration_success_message: "Profili juaj u krijua me sukses",
            title: "MEDREC : M",
            app: "app",
            password_creation_title:"Fjalëkalimi duhet të përmbajë:",
            at_Lest_eight_characters:"Të paktën 8 karaktere",
            at_Lest_one_uppercase_characters:"Të paktën një karakter me shkronja të mëdha (A-Z)",
            at_Lest_one_lowercase_characters:"Të paktën një karakter të vogël (A-Z)",
            at_Lest_one_number:"Të paktën një numër (0-9)",
            at_Lest_one_special:"Të paktën një personazh special (~!@#$%^&*_-+=`|\\(){}[]:;\"'<>,.?/)",
            password_is_not_valid:"Fjalëkalimi nuk është i vlefshëm (Shih Kërkesat e Fjalëkalimit)",
            main_page_label: "Rekord Mjekësor",
            select_language_label: "Zgjidhni gjuhën",
            wizard_provider_image_label: "Ngarko fotografinë tënde",
            login_label: "Kyçuni",
            social_media_login_separator: "Ose",
            logout_label: "Dilni",
            save_btn_label: "Ruaj",
            username_label: "Username",
            email_label: "E-mail",
            password_label: "Fjalëkalimi",
            new_password_label: "Fjalëkalimi i ri",
            repeat_password_label: "Përsërit fjalëkalimin",
            change_password_label: "Ndrysho fjalëkalimin",
            user_password_updated_message: "Fjalëkalimi u përditësua me sukses",
            user_password_update_error_message: 'Gabim në server',
            email_required_message: "E-mail është i detyrueshëm",
            invalid_date_format: "Format i Gabuar i Datës",
            invalid_time_format: "Format i Gabuar i Orës",
            password_required_message: "Fjalëkalimi është i detyrueshëm",
            placeholder_email_message: "Shkruani adresën tuaj të emailit",
            placeholder_password_message: "Shkruani fjalëkalimin tuaj",
            forgotten_password: "Keni harruar fjalëkalimin?",
            agreements_message: "Duke klikuar këtu ju jeni dakord me",
            agreements_message_ca: "Marrëveshjet e Klienteve.",
            login_form_invalid_credentials_message: "Kredenciale të pa sakta",
            register_label: "Regjistrohu",
            fullname_label: "Emri i plotë",
            male_label: "Mashkull",
            female_label: "Femër",
            register_form_password_confirm_label: "Konfirmo fjalëkalimin",
            register_form_email_exists_message: "E-mail është ekzistues",
            register_form_email_not_correct_message: "E-mail nuk është i saktë",
            register_form_passwords_not_match_message: "Fjalëkalimet nuk përputhen",
            register_form_password_length_message: "Fjalëkalimi duhet të ketë minimumi 8 karaktere",
            register_form_password_whitespace_message: "Fjalëkalimi nuk duhet të fillojë ose të mbarojë me hapësira",
            register_form_full_name_required: "Emri i plotë është i detyrueshëm",
            register_form_password_confirm_required_message: "Konfirmimi i fjalëkalimit është i detyrueshëm",
            register_form_successful_message: "Regjistrimi u përfundua me sukses",
            confirm_btn_label: "Konfirmo",
            close_btn_label: "Mbyll",
            Charts: "Grafikët",
            Logbook: "Ditari",
            logbook_entries_header: " ",
            delete_log_entry_modal_header: "Konfirmoni veprimin",
            delete_log_entry_modal_text: "Jeni i sigurt që doni ta fshini këtë rekord?",
            select_entry_type_error: "Ju lutem zgjidhni një lloj rekordi më parë",
            glucose_units: "Njësia e glukozës",
            ketones_units: "Njësia e ketoneve",
            hba1c_units: "Njësia e HbA1c",
            Systolic: "Sistolik",
            Diastolic: "Diastolik",
            Pulse: "Pulsi",
            notes: "Shënime",
            total_cholesterol: "Kolesteroli total",
            triglycerides: "Trigliceridet",
            Cholesterol: "Kolesteroli",
            Weight: "Pesha",
            Height: "Gjatësia",
            weight_and_height: "Pesha dhe gjatësia",
            chronic_conditions: "Gjëndje kronike",
            allergies: "Alergji",
            blood_group: "Grupi i gjakut",
            details: "Detaje",
            blood_pressure: "Presioni i gjakut",
            settings_label: "Konfigurime",
            unit_of_measurement_label: "Njësia e matjes",
            cholesterol_units_label: "Njësia e kolesterolit",
            classification_method_label: "Metoda e klasifikimit",
            date_format_label: "Formati i datës",
            time_24hour_label: "Koha format 24 orë",
            settings_updated_message: "Konfigurimet u ruajtën me sukses",
            US: "US (lbs, oz, fl oz, inch)",
            metric: "Metrikë (kg, gram, ml, cm)",
            kg: "Kg",
            pound: "Pound",
            inches: "inch",
            cm: "cm",
            None: "Asnjë",
            diagnosed_in: "Diagnostikuar në",
            mild: "E lehtë",
            mild_to_moderate: "E lehtë në të moderuar",
            moderate: "E moderuar",
            moderate_to_severe: "E moderuar në të rëndë",
            severe: "E rëndë",
            life_threatening: "Rrezik për jetën",
            drug_allergy: "Alergji ndaj barnave",
            dust_allergy: "Alergji ndaj pluhurit",
            food_allergy: "Alergji ndaj ushqimit",
            insect_allergy: "Alergji ndaj insekteve",
            latex_allergy: "Alergji ndaj laktozës",
            mold_allergy: "Alergji ndaj mykut",
            pet_allergy: "Alergji ndaj kafshëve shtëpiake",
            percent: "%",
            millimol_per_mol: "mmol/mol",
            pollen_allergy: "Alergji ndaj polenit",
            other_allergy: "Alergji të tjera",
            Severity: "Ashpërsia",
            Medications: "Mjekime",
            Medication_plans: "Plani i mjekimit",
            medication_name_label: "Emri i mjekimit",
            unit_label: "Njësia",
            dose_label: "Sasia",
            days_between_intake: "Një herë në sa ditë",
            days: "ditë",
            number_times_per_day: "Sa herë në ditë",
            number_of_days: "Sa ditë",
            millimol_per_litre: "mmol/L",
            milligram_per_decilitre: "mg/dL",
            millimetre_of_mercury: "mmHg",
            week_label: "7 ditë",
            fortnight_label: "14 ditë",
            month_label: "30 ditë",
            quarter_label: "90 ditë",
            custom_label: "Personalizuar",
            symptoms: "Simptoma",
            Temperature: "Temperatura",
            Hydration: "Hidratimi",
            Respiratory_rate: "Shkalla e frymëmarrjes",
            Saturation: "Saturimi i oksigjenit",
            celsius: "°C",
            ml: "ml",
            bpm: "bpm",
            fahrenheit: "°F",
            fl_oz: "fl oz",
            refresh_token_expired_message: 'Sesioni përfundoi',
            sharing_label: "Lejet e aksesit",
            clinicians_label: "Klinikët",
            no_clinicians_primary_label: "Nuk u gjendën klinikë",
            no_clinicians_secondary_label: "Nuk u gjendën klinikë sipas përzgjedhjeve tuaja",
            all_clinicians: "Gjithë klinikët",
            waiting_for_approval_list_label: "Në pritje të aprovimit tuaj",
            access_links_label: "Linket e përkohshme të aksesit",
            shared_with_list_label: "Ndarë me",
            deny_permission_label: "Moho",
            approve_permission_label: "Aprovo",
            share_data_label: "Ndaj të dhënat",
            new_link_label: "Gjenero link të ri",
            share_label: "Ndaj",
            valid_to_label: "E vlefshme deri më",
            requested_user_does_not_exist_message: "Përdoruesi me këtë e-mail nuk ekziston",
            share_data_success_message: "Kërkesa për leje u realizua me sukses",
            forgot_password: "Keni harruar fjalëkalimin?",
            privacy_policy: "Politikat e privatësisë",
            gender_label: "Gjinia",
            country_label: "Shteti",
            male: "Mashkull",
            female: "Femër",
            city_label: "Qyteti",
            birthday_label: "Datëlindja",
            dashboard: "Faqja kryesore",
            user_info_label: "Të dhënat e përdoruesit",
            latest_measurements_label: "Matjet e fundit",
            last_week_data: "Grafikët e javës",
            no_recent_measurements: "Nuk ka matje të bëra kohët të fundit",
            loading_data_label: "Duke ngarkuar të dhënat...",
            logbook_no_data_label: "Ky përdorues nuk ka shënuar akoma të dhëna.",
            symptoms_no_data_label: "Ky përdorues nuk ka shënuar akoma simptoma.",
            search_placeholder_label: "Kërko...",
            latest_symptoms_label: "Simptomat e 24 orëve të fundit",
            no_latest_symptoms_label: "Nuk ka simptoma të shënuara 24 orët e fundit",
            profile_label: 'Profili',
            profile_tab: 'Profili kryesor',
            edit_profile_label: 'Edito profilin',
            update_btn: 'Modifiko',
            upload_image_label: 'Ngarko foto profili',
            user_info_updated_message: 'Profili u përditësua me sukses',
            unsupported_image_format: 'Formati i imazhit të zgjedhur nuk është i suportuar',
            choose_file_label: 'Zgjidh',
            documents: "Dokumente",
            category_label: "Kategori",
            document_type_label: "Tipi i dokumentit",
            type_description_label: "Përshkrim i tipit",
            document_download_dialog_title: "Shkarko dokumentin",
            confirmation_dialog_message: "Dokumenti ({fileName}) të cilin ju doni të shkarkoni i përket {owner} dhe përmban të dhëna sensitive. Ruajeni në një vend të sigurtë ose fshijeni pasi të mbaroni punë.",
            documents_no_data_label: "Ky përdorues nuk ka ngarkuar akoma dokumente.",
            download_btn: "Shkarko",
            cancel_btn: "Anulo",
            document_type_none: "Asnjë",
            lab_name: "Emri i Laboratorit",
            document_type_epicrisis: "Epikrizë",
            document_type_prescription: "Recetë",
            document_type_lab_results: "Rezultatet laboratorike",
            document_type_ambulance_sheet: "Raport mjekësor",
            document_type_referral: "Rekomandim",
            document_type_other: "Të tjera",
            document_type_diagnostic_imaging: "Imazhe Diagnostike",
            all_label: "Të gjitha",
            select_category: "Zgjidhni kategorinë",
            documents_no_data_for_filters_label: "Nuk ka dokumente të cilat korrespondojnë me filtrat e zgjedhur.",
            pending_for_auth_approval_label: "Përdoruesit të cilët duhet të pranojnë ofertën tuaj për të parë të dhënat",
            upgrade_child: "Kthe në llogari të thjeshtë",
            upgrade: "Kthe",
            to_regular: "në llogari të thjeshtë",
            confirm_password_label: "Konfirmo fjalëkalimin",
            passwords_dont_match: "Fjalëkalimet nuk përputhen",
            password_not_long_enough: "Fjalëkalimi duhet të ketë minimumi 8 karaktere",
            password_whitespace_message: "Fjalëkalimi nuk duhet të fillojë ose të mbarojë me hapësira",
            confirm_upgrade: "Jeni duke kthyer llogarinë në llogari të thjeshtë",
            upgrade_advisory: "Kur veprimi të përfundojë, do të ridrejtoheni në faqen kryesore dhe nuk do të keni më mundësi të menaxhoni",
            upgrade_continue: "Jeni të sigurt që doni të vazhdoni?",
            start_date: "Datë nga",
            end_date: "Datë tek",
            add_entry: "Shto rekord",
            update_entry: "Ndrysho rekordin",
            entry_types: "Tipet e rekordeve",
            entry_type_temperature: "Temperatura",
            entry_type_blood_pressure: "Presioni i gjakut",
            entry_type_blood_sugar: "Sheqeri në gjak",
            entry_type_hba1c: "HBA1C",
            hba1c: "HBA1C",
            entry_type_ketones: "Ketonet",
            entry_type_urine_ph: "Urina PH",
            entry_type_weight: "Pesha",
            entry_type_height: "Gjatësia",
            entry_type_cholesterol: "Kolesteroli",
            cholesterol: "Kolesteroli",
            entry_type_oxygen_saturation: "Saturimi i oksigjenit",
            entry_type_respiratory_rate: "Shkalla e frymëmarrjes",
            respiratory_rate: "Shkalla e frymëmarrjes",
            entry_type_hydration: "Hidratimi",
            hydration: "Hidratimi",
            entry_type_menstruation: "Menstruacione",
            menstruation: "Menstruacionet",
            entry_type_medication: "Mjekimet",
            position_type_seated: "Ulur",
            position_type_lying: "Shtrirë",
            position_type_standing: "Në këmbë",
            measuring_site_type_left_arm: "Krahu i majtë",
            measuring_site_type_right_arm: "Krahu i djathtë",
            measuring_site_type_left_wrist: "Kyçi i majtë",
            measuring_site_type_right_wrist: "Kyçi i djathtë",
            measuring_site_type_left_thigh: "Kofsha e majtë",
            measuring_site_type_right_thigh: "Kofsha e djathtë",
            measuring_site_type_left_calf: "Pulpa e majtë",
            measuring_site_type_right_calf: "Pulpa e djathtë",
            measuring_site_type_left_ankle: "Kyçi i këmbës së majtë",
            measuring_site_type_right_ankle: "Kyçi i këmbës së djathtë",
            temperature_label: "Temperatura",
            temperature: "Temperatura",
            systolic_label: "Sistolik",
            diastolic_label: "Diastolik",
            pulse_label: "Pulsi",
            position_label: "Pozicioni",
            measuring_site_label: "Vendi i matjes",
            arrhythmia_detected_label: "Shenja aritmie",
            blood_sugar_label: "Sheqeri në gjak",
            hba1c_label: "HbA1c",
            ketones: "Ketonet",
            urine_ph_label: "Urina pH",
            urine_ph: "Urina pH",
            weight_label: "Pesha",
            weight: "Pesha",
            height_label: "Gjatësia",
            height: "Gjatësia",
            total_cholesterol_label: "Kolesteroli total",
            total_label: "Totali",
            ldl_label: "LDL",
            hdl_label: "HDL",
            triglycerides_label: "Trigliceridet",
            oxygen_saturation_label: "Saturimi i oksigjenit",
            saturation: "Saturimi i oksigjenit",
            respiratory_rate_label: "Shkalla e frymëmarrjes",
            hydration_label: "Hidratimi",
            flow_label: "Rrjedha",
            color_label: "Ngjyra",
            primary_colour: "Ngjyra Primare",
            secondary_colour: "Ngjyra Sekondare",
            select_medication_style: "Zgjidhni Stilin e Medikamentit",
            consistency_label: "Dendësia",
            notes_label: "Shënime",
            datetime_required_message: "Data dhe ora janë të detyrueshme",
            date_required_message: "Data është e detyrueshme",
            datetime_max_value_message: "Data dhe ora nuk mund të vendosen në të ardhmen",
            end_date_before_start_date: "Data e përfundimit nuk mund te vendoset përpara datës së fillimit",
            temperature_required_message: "Temperatura është e detyrueshme",
            temperature_wrong_format_message: "Temperatura është në format të gabuar",
            temperature_out_of_range_message: "Temperatura është jashtë vlerave të lejuara",
            blood_pressure_required_message: "Fushat e presionit të gjakut ose pulsi janë të detyrueshme",
            blood_pressure_wrong_format_message: "Fushat e presionit të gjakut janë në format të gabuar",
            blood_pressure_out_of_range_message: "Fushat e presionit të gjakut janë jashtë vlerave të lejuara",
            blood_sugar_required_message: "Sheqeri në gjak është i detyrueshëm",
            blood_sugar_wrong_format_message: "Sheqeri në gjak është në format të gabuar",
            blood_sugar_out_of_range_message: "Sheqeri në gjak është jashtë vlerave të lejuara",
            hba1c_required_message: "Hemoglobina A1C është e detyrueshme",
            hba1c_wrong_format_message: "Hemoglobina A1C është në format të gabuar",
            hba1c_out_of_range_message: "Hemoglobina A1C është jashtë vlerave të lejuara",
            ketones_required_message: "Ketonet janë të detyrueshme",
            ketones_wrong_format_message: "Ketonet janë në format të gabuar",
            ketones_out_of_range_message: "Ketonet janë jashtë vlerave të lejuara",
            urine_ph_required_message: "Urina pH është e detyrueshme",
            urine_ph_wrong_format_message: "Urina pH është në format të gabuar",
            urine_ph_out_of_range_message: "Urina pH është jashtë vlerave të lejuara",
            weight_required_message: "Pesha është e detyrueshme",
            weight_wrong_format_message: "Pesha është në format të gabuar",
            weight_out_of_range_message: "Pesha është jashtë vlerave të lejuara",
            height_required_message: "Gjatësia është e detyrueshme",
            height_wrong_format_message: "Gjatësia është në format të gabuar",
            height_out_of_range_message: "Gjatësia është jashtë vlerave të lejuara",
            cholesterol_required_message: "Të paktën njëra nga fushat për kolesterolin duhet të plotësohet",
            total_cholesterol_wrong_format_message: "Kolesteroli total është në format të gabuar",
            total_cholesterol_out_of_range_message: "Kolesteroli total është jashtë vlerave të lejuara",
            ldl_wrong_format_message: "LDL është në format të gabuar",
            ldl_out_of_range_message: "LDL është jashtë vlerave të lejuara",
            hdl_wrong_format_message: "Gjatësia është në format të gabuar",
            hdl_out_of_range_message: "Gjatësia është jashtë vlerave të lejuara",
            triglycerides_wrong_format_message: "Trigliceridet janë në format të gabuar",
            triglycerides_out_of_range_message: "Trigliceridet janë jashtë vlerave të lejuara",
            oxygen_saturation_required_message: "Saturimi i oksigjenit është i detyrueshëm",
            oxygen_saturation_wrong_format_message: "Saturimi i oksigjenit është në format të gabuar",
            oxygen_saturation_out_of_range_message: "Saturimi i oksigjenit është jashtë vlerave të lejuara",
            respiratory_rate_required_message: "Shkalla e frymëmarrjes është e detyrueshme",
            respiratory_rate_wrong_format_message: "Shkalla e frymëmarrjes është në format të gabuar",
            respiratory_rate_out_of_range_message: "Shkalla e frymëmarrjes është jashtë vlerave të lejuara",
            hydration_required_message: "Hidratimi është i detyrueshëm",
            hydration_wrong_format_message: "Hidratimi është në format të gabuar",
            hydration_out_of_range_message: "Hidratimi është jashtë vlerave të lejuara",
            medication_name_required_message: "Emri i mjekimit është i detyrueshëm",
            unit_required_message: "Njësia është e detyrueshme",
            dose_required_message: "Sasia është e detyrueshme",
            dose_wrong_format_message: "Sasia është në format të gabuar",
            dose_out_of_range_message: "Sasia është jashtë vlerave të lejuara",
            load_more: "Shiko më shumë",
            afternoon: "Drekë",
            morning: "Mëngjes",
            evening: "Mbrëmje",
            select_gender: "Zgjidhni gjininë",
            my_documents_label: "Dokumentat e mia",
            my_children_label: "Fëmijët e mi",
            blood_sugar: "Sheqeri në gjak",
            HBA1C: "HbA1c",
            Ketones: "Ketone",
            Flow: "Rrjedhja",
            Color: "Ngjyra",
            Consistency: "Konsistenca",
            Urine_pH: "pH i urinës",
            no_flow: "Pa rrjedhje",
            light: "Lehtë",
            medium: "Mesatar",
            heavy: "Rëndë",
            unexpected: "Pa pritur",
            none: "Asnjë",
            black: "E zezë",
            brown: "Kafe",
            dark_red: "E kuqe e errët",
            bright_red: "E kuqe e hapur",
            pink: "Rozë",
            orange: "Portokalli",
            gray: "Gri",
            watery: "E lëngshme",
            clots: "Mpiksur",
            mmol_mol: "mmol/mol",
            no_chronic_conditions: "Pa gjendje kronike",
            no_allergies: "Pa alergji",
            Communication: "Komunikim",
            my_communication_label: "Komunikimet e mia",
            new_chat: "Chat i ri",
            recent_chats_label: "Komunikimet e fundit",
            available_users_label: "Përdoruesit",
            back: "Mbrapa",
            type_message: "Shkruaj një mesazh...",
            call: "Thirrje hyrëse",
            incoming_call_message: "Thirrje hyrëse nga %1",
            incoming_call_another_user_message: "Thirrje hyrëse nga një përdorues tjetër",
            accept: "Prano",
            no_user_selected_message: 'Asnjë përdorues i zgjedhur për të thirrur',
            ok: 'ok',
            no_answer: "Pa përgjigje",
            no_answer_message: "Asnjë përgjigje nga %1",
            you_wrote: "Ti ke shkruar",
            missed_call_label: "Thirrje e humbur",
            rejected_call_label: "Thirrje e refuzuar",
            in_progress_call_label: "Thirrje në progres",
            completed_call_label: "Thirrje e përfunduar",
            ongoing_call: "Videocall në progres",
            medication_plan: "Plani i mjekimit",
            general_practitioner: "Mjek i Përgjithshëm (Mjeku i Familjes)",
            practice_name: "Emri i Klinikës",
            practice_address: "Adresa e Klinikës",
            practice_contact: "Numri i kontaktit",
            practice_centre_code: "Kodi i Klinikës",
            practice_practitioner_code: "Kodi i Mjekut",
            na: "Jo e disponueshme",
            times_per_day: "%1 %2 x %3 herë në ditë",
            times_per_day_plural: "%1 %2 x %3 herë në ditë",
            every_x_hours: "%1 %2 x çdo %3 orë",
            every_x_hours_plural: "%1 %2 x çdo %3 orë",
            daily: 'Përditë',
            every_x_days: "Çdo X ditë",
            take_x_rest_x: "Merr X ditë dhe pusho Y ditë",
            when_needed: "Kur duhet",
            ongoing: "Në vazhdimësi",
            until_date: 'Deri në datën ',//%1',
            for_x_days_until: "Për X ditë",// deri më %2",
            specific_days_of_week: "Ditë specifike të javës",
            effective_from: "Efektive nga %1",
            medical_record_label: "Rekordet mjekësore",
            Lab_Results: "Rezultatet laboratorike",
            select_laboratory_label: "Zgjidhni laboratorin",
            lab_results_open_results_label: "Hap rezultatet",
            lab_results_close_results_label: "Mbyll rezultatet",
            lab_results_no_data_for_filters_label: "Nuk ka rezultate nga laboratorë të cilat korrespondojnë me filtrat e zgjedhur.",
            lab_results_no_data_label: "Ky përdorues nuk ka importuar akoma rezultate nga laboratorë.",
            lab_test_col_name_name: "Група показател",
            lab_test_col_name_result: "Резултат",
            lab_test_col_name_units: "Мерни единици",
            lab_test_col_name_flag: "Флаг",
            lab_test_col_name_range: "Референтни стойности",
            lab_microbiology_col_name_antibiogram: "Антибиограма",
            select: 'Zgjidh',
            preview_image: 'Shiko',
            OK: 'OK',
            delete_image_modal_tittle: 'Hiqni imazhin',
            delete_image_modal_message: 'Jeni të sigurt që doni të hiqni imazhin tuaj?',
            add_symptom_label: "Shto simptomë",
            log_symptoms_label: "Shto simptoma",
            log_symptoms_date_label: "Data",
            log_symptoms_time_label: "Ora",
            log_symptoms_notes_label: "Shënime",
            log_symptoms_form_date_time_not_correct_message: "Data e përzgjedhur nuk mund të jetë më e madhe se data e tanishme",
            log_symptoms_form_date_time_required_message: "Data është e detyrueshme",
            mark_symptom_entry_deleted_modal_title: "Shenjo simptomen si të fshirë",
            mark_symptom_entry_deleted_modal_message: "Jeni të sigurt që doni të shenjoni këtë simptomë si të fshirë?",
            mark_document_entry_deleted_modal_title: "Shenjo dokumentin si të fshirë",
            mark_document_entry_deleted_modal_message: "Jeni të sigurt që doni të fshini këtë dokument?",
            unit_type_ampule: "ampulë(a)",
            unit_type_application: "aplikim(e)",
            unit_type_capsule: "kapsulë(a)",
            unit_type_drop: "pikë(a)",
            unit_type_gram: "g",
            unit_type_injection: "injeksion(e)",
            unit_type_mg: "mg",
            unit_type_ml: "ml",
            unit_type_packet: "paketë(a)",
            reset_btn_label: "Rikthe",
            unit_type_patch: "pjesë",
            unit_type_piece: "copë(a)",
            unit_type_tablet: "tabletë(a)",
            unit_type_puff: "thithje",
            unit_type_spray: "spraj(e)",
            unit_type_suppository: "suposto",
            unit_type_tbsp: "lugë gjelle",
            unit_type_tsp: "lugë çaji",
            unit_type_unit: "njësi",
            create_document: "Krijo dokument",
            document_title: "Titulli i Dokumentit",
            document_title_required_message: "Titulli i detyruar",
            type_description_required_message: "Përshkrimi i tipit i detyruar",
            document_category: "Kategoria e Dokumentit",
            shared_label: "I ndarë",
            upload_document: "Ngarko Dokumentin",
            no_file_chosen: "pa përzgjedhur",
            file_required_message: "Skedari i detyruar",
            obstetrics_gynecology: 'Obstetrikë dhe Gjinekologji',
            allergology: 'Alergologji',
            angiology: 'Angiologji',
            venereology: 'Venerologji',
            internal_medicine: 'Mjekësi e Brendshme',
            gastroenterology: 'Gastroenterologji',
            thoracic_surgery: 'Kirurgji torakale',
            dental_medicine: 'Mjekësi Dentare',
            dermatology: 'Dermatologji',
            pediatric_gastroenterology: 'Gastroenterologji për fëmijë',
            pediatric_endocrinology: 'Endokrinologji për fëmijë',
            pediatric_neurology: 'Neurologji për fëmijë',
            pediatric_nephrology: 'Nefrologji për fëmijë',
            child_psychiatry: 'Psikiatria për fëmijët',
            pediatric_pulmonology: 'Pulmonologji për fëmijë',
            pediatric_rheumatology: 'Reumatologji për fëmijë',
            endocrinology: 'Endokrinologji',
            immunology: 'Immunologji',
            cardiology: 'Kardiologji',
            cardiac_surgery: 'Kirurgji kardiake',
            clinical_genetics: 'Gjenetika klinike',
            speech_therapy: 'Logopedi',
            mammology: 'Mamologji',
            microbiology: 'Mikrobiologji',
            neurology: 'Neurologji',
            neurological_surgery: 'Kirurgji Neurologjike',
            neonatology: 'Neonatologji',
            nephrology: 'Nefrologji',
            nuclear_medicine: 'Mjekësi nukleare',
            imaging_diagnostics: 'Imazheri',
            general_medicine: 'Mjekësi e përgjithshme',
            oncology: 'Onkologji',
            orthodontics: 'Ortodonci',
            orthopedics_traumatology: 'Ortopedi dhe Traumatologji',
            otoneurology: 'Otoneurologji',
            ophthalmology: 'Oftalmologji',
            pediatrics: 'Pediatri',
            psychiatry: 'Psikiatri',
            psychology: 'Psikologji',
            psychotherapy: 'Psikoterapi',
            pulmonology: 'Pulmonologji',
            rheumatology: 'Reumatologji',
            vascular_surgery: 'Kirurgji Vaskulare',
            ent: 'ORL',
            urology: 'Urologji',
            physical_medicine_rehabilitation: 'Mjekësi fizike dhe Rehabilitim',
            hematology: 'Hematologji',
            surgery: 'Kirurgji',
            homeopathy: 'Homeopati',
            specialties: "Specialitetet",
            health_issues_label: "Problemet e Shëndetit",
            health_issues_menu_label: "Shqetësimet",
            health_issues_no_data_label: "Nuk janë shënuar akoma probleme shëndetësore",
            health_issues_no_data_primary_label: "Nuk ka probleme shëndetësore",
            processing_label: "Duke procesuar",
            my_appointments_label: "Vizita",
            appointments_no_data_primary_label: "Nuk keni vizita",
            no_appointments_primary_label: "Nuk keni vizita",
            waiting_for_doctor_approval: "Duke pritur për konfirmim",
            waiting_for_patient_approval: "Në pritje të konfirmimit tuaj",
            need_more_info: "Kërkohet më shumë informacion",
            accepted: "Konfirmuar",
            completed: "Përfunduar",
            rejected_by_patient: "Refuzuar",
            canceled_by_patient: "Anulluar",
            rejected_by_provider: "Refuzuar",
            canceled_by_provider: "Anulluar",
            cancel_appointment_confirmation_header: "Jeni i sigurt që doni ta anuloni vizitën me {fullname} për datën {date}?",
            cancel_appointment_label: "Anulo vizitën",
            cancel_appointment_confirmation_body: "Kjo vizitë do të anulohet në mënyrë të menjëhershme. Ju nuk mund ta ktheni këtë veprim.",
            edit_label: "Ndrysho",
            start_date_label: "Data e fillimit",
            end_date_label: "Data e përfundimit",
            delete_label: "Fshi",
            primary_exam: 'Ekzaminimi kryesor',
            follow_up_exam: 'Ekzaminim pasues',
            other: 'Tjetër',
            online: 'Online',
            on_site: 'On Site',
            first_available_hour_label: "Orari i parë i lirë",
            book_another_hour_label: "Rezervo një orar tjetër",
            select_appointment_type_message: "Të lutem, vendos llojin e takimit që të shohësh oraret e lira.",
            make_an_appointment_label: "Lër një orar",
            contacts_label: "Kontaktet",
            phone_contact_label: "Telefono: ",
            email_contact_label: "Dërgo e-mail tek: ",
            no_number_label: "Nuk ka numër celulari",
            no_email_label: "Nuk ka e-mail",
            select_today_label: 'Sot',
            eccounter_data_separator: "Më shumë informacion",
            main_diagnosis_label: "Diagnoza kryesore",
            objective_data_label: "Të dhëna objektive",
            subjective_data_label: "Të dhëna subjektive",
            therapy_label: "Terapia",
            concomitant_diseases_label: "Sëmundjet shoqëruese",
            tests_label: "Testet",
            no_encounter_found_label: "Nuk u gjet asnjë vizitë",
            payment_completed: "E paguar",
            payment_initial: "Jo e paguar",
            payment_in_progress: "Pagesa në proces",
            payment_rejected: "Jo e paguar",
            create_appointment_health_issue_message: "Ju lutem, specifikoni më poshtë problemet tuaja shëndetësore që janë të lidhura me takimin. Në këtë mënyrë, kliniku do të ketë akses tek gjithë rekordet dhe dokumentet që kanë të bëjnë me problemet shëndetësore të përfshira.",
            create_appointment_notes_message: "Shto informacione të tjera për arsyet e vizitës. Ju lutem, mundësoni sa më shumë informacion.",
            select_health_issue_label: "Zgjidh problemet shëndetësore",
            create_appointment: "Rezervo",
            create_health_issue: "Problem i Ri Shëndeti",
            health_issue_title: "Emri i problemit shëndetsor",
            health_issue_description: "Përshkrimi",
            health_issue_title_required_message: "Kërkohet emri i problemit shëndetsor",
            related_label: "Elementët Bashkëngjitur",
            date_label: "Shto datë (Opsionale)",
            mark_health_issue_entry_deleted_modal_title: "Shenjo problemin shëndetsor si të fshirë",
            mark_health_issue_entry_deleted_modal_message: "Jeni të sigurt që doni të shenjoni këtë problem shëndetsor si të fshirë?",
            child_label: "Krijo profil të ri të lidhur",
            messages_label: "Mesazhet",
            recent_messages_title: "Mesazhet e fundit",
            no_messages_to_show: "Nuk ka mesazhe të tjera",
            delete_connected_profile:"Fshi profilin e lidhur",
            delete_connected_profile_text:"Are you sure you want to revoke permission for {name} to manage this profiles data. This action cannot be undone",
            delete_connected_profile_text_parent:"Are you sure you want to revoke your permissions to manage {name}? This action cannot be undone",
            check_email:"A user with this email cannot be found.",
            add_connected_profile:"Add a connected Profile",
            add_connected_profile_info:"To add a connected profiles, type the users registered email address into the input field below",
            add_connected_profile_info_warn:"Important: All connected Profiles will have full control over this profile ",
            delete_connected_profile_text_final_parent:"You are the final connected profile for this account. If you continue this account will be deleted.",
            connected_profiles:"Profilet e lidhura",
            approve_child:"Mirato profilin e lidhur",
            approve:"Mirato",
            deny:"Moho",
            pin_required:"Kërkohet PIN",
            submission_error:"Profili nuk është i lidhur. Kontrollo PIN",
            rejection_error:"Gabim në server",
            messages_will_appear_here: "Mesazhet do të shfaqen këtu",
            search_in_messages_label: "Kërko",
            add_allergy: "Shto Alergji",
            allergen_name_label: "Emri i alergjisë",
            date_diagnosed_label: "Data e diagnostikuar",
            description: "Përshkrimi",
            medications: "Medikamentet",
            allergy_severity_label: "Shkalla e alergjisë",
            allergy_type_label: "Lloji i alergjisë",
            edit_allergy: "Modifiko alergjinë",
            add_condition: "Shto konditë",
            edit_condition: "Modifiko konditën",
            allergen_name_required_message: "Emri i alergjisë i detyruar",
            chronic_condition_name_required_message: "Emri i konditës i detyruar",
            create: 'Krijo',
            condition_name: "Emri i konditës",
            mark_allergy_entry_deleted_modal_title: "Jeni i sigurt që doni të fshini alergjinë?",
            mark_condition_entry_deleted_modal_title: "Jeni i sigurt që doni të fshini konditën?",
            disease_code: "Kodi i Sëmundjes",
            disease: "Sëmundja",
            phone_label:"Numrat e telefonit",
            no_results_found: "Nuk u gjendën rezultate",
            good_morning: "Mirëmëngjes",
            good_afternoon: "Mirëdita",
            good_evening: "Mirëmbrëma",
            charts: "Grafikët",
            last_month_vitals: "Matjet e Kaluara",
            your_doctors: "Doktorët e tu",
            recent_health_issues: "Probleme të shëndetit të kohëve të fundit",
            see_all_label: "Shiko të gjitha",
            see_vitals_history_label: "Shiko historikun e matjeve",
            lab_result: "Rezultatet e Laboratoreve",
            select_label: "Zgjidh",
            user_id: "ID Përdoruesi",
            credentials_message: "Ju lutem, futni kredencialet që moret nga laboratori. Ne nuk i mbajmë këto kredenciale dhe ato përdoren vetëm për të marrë informacion nga faqja e laboratorit.",
            cibalab: "ID Përdoruesi",
            bodimed: "ID No.",
            ramus: "Numër ID",
            status: "ID Përdoruesi",
            pisanec: "ID Përdoruesi",
            acibadem_sofia: "Numër Përdoruesi",
            acibadem_varna: "Numër Përdoruesi",
            acibadem_sofia_cityclinic: "Numër Përdoruesi",
            continue: "Vazhdoni",
            username_required_label: "Ju lutem, shkruani kodin hyrës",
            select_date_label: "Zgjidhni nje datë",
            this_week: "Këtë javë",
            current_issues: "Probleme të kohës",
            past_issues: "Probleme të kaluara",
            all_issues: "Të gjitha problemet",
            chronic_condition: "Sëmundje Kronike",
            allergy_condition: "Alergji",
            vitals: "Matje",
            labs: "Analiza",
            add_health_issue_label: "Shto shqetësim shëndetësor",
            edit_health_issue_label: "Modifiko shqetësimin shëndetësor",
            create_lab_document: "Dëshironi të krijoni një dokument me rezultatet origjinale në seksionin e Dokumenteve?",
            skip_label: "Kalo",
            symptoms_history: "Historiku i simptomave",
            more: "më shumë",
            search_by_symptom: "Kërko sipas simptomës",
            mark_lab_result_entry_deleted_modal_title: "Fshi rezultatin e laboratorit",
            mark_lab_result_entry_deleted_modal_message: "Jeni të sigurt që doni të fshini këtë rezultat laboratori?",
            your_recent_symptoms: "Simptomat e zgjedhura",
            choose_symptom: "Zgjidh Simptomën",
            search_for_symptom: "Kërko Simptomën",
            no_symptoms_label: "Pa Simptoma",
            search_for_clinicians_label: "Kërko për doktorë",
            hospitals_label: "Spitalet",
            search_for_specialties_or_clinicians_label: "Kërko klinikun ose specialitetin...",
            back_to_clinicians_label: "Kthehu tek klinikët",
            examinations_label: "Ekzaminimet",
            biography_label: "Biografia",
            document_name_label: "Emri i dokumentit",
            size_label: "Madhësia",
            date_added_label: "Data e shtuar",
            document_label: "Shto Dokument",
            update_document: "Përditeso Dokument",
            book_label: "Rezervo",
            create_appointment_successful_message: "Ke rezervuar një takim me sukses.",
            mark_medication_entry_deleted_modal_message: "Jeni të sigurt që doni të fshini këtë mjekim?",
            med_start_date: "Efektive që prej ",
            no_one_waiting_for_your_approval_label: "Askush nuk është duke pritur aprovimin tuaj",
            not_sharing_data_with_anyone_label: "Nuk jeni duke ndarë të dhëna me askënd",
            not_waiting_for_anyones_approval_label: "Nuk ka përdorues që nuk kanë pranuar ende ofertën tuaj",
            title_label: "Titulli",
            update_note: "Përditëso shënimin",
            create_note: "Krijo shënim",
            note_required_message: "Shënimi është i detyruar",
            delete_note_modal_header: "Konfirmo Veprimin",
            delete_note_modal_text: "Je i/e sigurt që do të fshish veprimin?",
            general_note_label: "Shënim i përgjithshëm",
            new_note_label: "Shënim i Ri",
            back_to_appointments_label: "Kthehu tek takimet",
            note_label: "Shënim",
            pay_label: "Paguaj",
            schedule: "Programi",
            duration: "Kohëzgjatja",
            frequency: "Frekuenca",
            frequency_times: "Merr një vlerë",
            frequency_hours: "Numri i orëve",
            frequency_times_day: "X herë në ditë",
            frequency_every_hours: "Çdo X orë",
            take: "Merre",
            rest: "Pusho",
            cycle_day: "Dita e ciklit ",
            change_time: "Ndrysho orën",
            weekend_schedule: "Përdor orar tjetër për fundjavat",
            no_medications: "Nuk janë shtuar ende medikamente",
            add_medication: "Shto Mjekimin",
            update_medication: "Ndrysho Mjekimin",
            no_symptoms_after_filters: "Nuk u gjendën simptoma pas filtrimit.",
            no_logs_after_filters: "Nuk keni matje për filtrat e vendosur",
            add_notes: "Shtoni shënime",
            pay_now: "Paguaj tani",
            patient_uin: "PIN",
            no_appointment_notes: "Nuk ka shënime në këtë vizitë",
            agreement_selected_required: "Duhet të pranoni termat dhe kushtet përpara se të vazhdoni",
            look_for_hospitals_label: "Kontrollo për spitale",
            logbook_type_label: "Tipi i ditarit",
            radio_active_label: "Aktive",
            radio_inactive_label: "Inaktive",
            my_medications: "Medikamentet e mia",
            all_medications: "Të gjitha medikamentet",
            pickup_call_button_label: "Prano",
            hang_up_button_label: "Refuzo",
            incoming_call_label: "Thirrje hyrëse",
            inactive_plan: "Plan inaktiv",
            taken_on_information: "Marrë {amount} {unit} më {dateTime}",
            inventory: "Inventari",
            skip_reason_none: "Pa arsye",
            skip_reason_forgot_to_take: "Harrova ta marr",
            skip_reason_med_not_near_me: "Nuk i kisha medikamentet afër",
            skip_reason_asleep: "Isha përgjumësh",
            skip_reason_too_busy: "Isha tepër i zënë",
            skip_reason_out_of_med: "Më kishin mbaruar medikamentet",
            skip_reason_side_effects: "Pata efekte anësore",
            skip_reason_other: "Arsye të tjera",
            skip_reason_expensive: "Është shumë e shtrenjtë",
            skip_reason_dose_not_needed: "Nuk më duhet ajo dozë",
            planned_for_information: "Planifikuar për në",
            skipped_information: "Anashkalove {amount} {unit}",
            delete_plan: "Fshi planin",
            delete_med_history: "Fshi historikun e mjekimit",
            mark_med_history_deleted_modal_message: "Dëshironi të fshini të gjitha rekordet e kaluara për këtë plan mjekimi nga ditari?",
            no_label: "Jo",
            pick_date_and_time: "Zgjidh datën dhe orën",
            take_as_planned_label: "Merre kur e ke planifikuar, në {time}",
            take_now_label: "Merre tani, në {time}",
            take_at_selected_time_label: "Merre në kohën e përzgjedhur",
            reschedule_label: "Riskedulo",
            skipping_reason: "Ju lutem, specifikoni arsyen pse nuk po merrni dozën?",
            reason_notes: "Shënime mbi arsyen",
            rescheduled_for_label: "Riskeduluar për ",
            reset_medication_msg: "Jeni të sigurtë që dëshironi të riktheni mjekimin dhe të fshini rekordin nga ditari?",
            reset_medication_label: "Rikthe mjekimin",
            monday: "E hënë",
            tuesday: "E martë",
            wednesday: "E mërkurë",
            thursday: "E enjte",
            friday: "E premte",
            saturday: "E shtunë",
            sunday: "E diel",
            Captcha: "Captcha",
            EGN: "EGN",
            import: "importit",
            no_hours_available_primary_label: "Jo orë të lira",
            no_temporary_links_label_label: "Ju nuk keni ndonjë link aksesi të përkohshëm",
            selected_symptoms: "Simptomat e Selektuara",
            version:"Version",
            accept_appointment_confirmation_header: "Jeni i sigurt që dëshironi të miratoni vizitën në {date} me {fullname}?",
            accept_appointment_confirmation_body: "Kjo vizitë do të aprovohet menjëherë.",
            accept_appointment_label: "Aprovo",
            payment_option_modal_body:"Zgjidh një mënyrë pagese për vizitën tënde me {fullname} në {date}",
            pay_with_card_button:"Paguani me kartë",
            pay_with_epay:"Paguani me ePay",
            epay_payment_option_descr: "Përdorni këtë opsion nëse jeni regjistruar në ePay",
            cc_payment_option_descr: "Përdor këtë opsion për të paguar me kartën tuaj të debitit ose kreditit",
            payment_payed_waiting_body_text:"Ju lutemi prisni derisa të marrim konfirmimin e pagesës tuaj",
            payment_canceled_body_text: "Pagesa u anulua!",
            payment_completed_body_text: "Pagesa ka përfunduar!",
            total_sum: "Shuma",
            cancellation_policy:"Politika e anulimit",
            cancellation_policy_agreement_label_first_part: "Kontrollo këtu për të treguar se ke lexuar dhe pranuar",
            terms_and_conditions: "Kushtet e kontratës",
            login_form_temp_locked_down_message: "Llogaria juaj është bllokuar përkohësisht për shkak të përpjekjeve të përsëritura të dështuara për hyrje. Ju lutemi prisni disa minuta përpara se të provoni të identifikoheni përsëri.",
            login_form_must_reset_password_message: "Llogaria juaj është e bllokuar për shkak të përpjekjeve të përsëritura të dështuara për hyrje. Mund ta zhbllokoni duke rivendosur fjalëkalimin tuaj duke përdorur funksionin Harrove fjalëkalimin.",
            pay_before: "Paguani më parë",
            missed_payment:"E papaguar para afatit",
            missed_payment_consultation:"E papaguar para afatit",
            start_video:"Fillo videon",
            stop_video:"Ndalo videon",
            no_video:"Nuk ka video",
            no_audio:"Pa audio",
            mute:"Çaktivizo zërin",
            unmute:"Aktivizo zërin",
            join_now: "Bashkohu tani",
            enter_examination_room: "Hyni në dhomën e provimit",
            entering_examination_room: "Hyrja në dhomën e provimit",
            audio: "Audio",
            audio_input:"Hyrja audio",
            video: "Video",
            video_input:"Hyrja video",
            audio_and_video_settings: "Cilësimet audio dhe video",
            no_local_audio: "Nuk ka audio lokale",
            done_btn:"Gati",
            no_local_video:"Nuk ka video lokale",
            You:"Ju",
            current_video_call:"Sesioni aktual i videos",
            medication: {
                unit: {
                    label: {
                        ampule: {
                            display_long: 'ampulë',
                            display_long_plural: 'ampula',
                            display_short: '',
                            display_short_plural: ''
                        },
                        application: {
                            display_long: 'aplikim',
                            display_long_plural: 'aplikime',
                            display_short: '',
                            display_short_plural: ''
                        },
                        capsule: {
                            display_long: 'kapsulë',
                            display_long_plural: 'kapsula',
                            display_short: '',
                            display_short_plural: ''
                        },
                        drop: {
                            display_long: 'pikë',
                            display_long_plural: 'pika',
                            display_short: '',
                            display_short_plural: ''
                        },
                        gram: {
                            display_long: 'gram',
                            display_long_plural: 'gram',
                            display_short: 'g',
                            display_short_plural: 'g'
                        },
                        injection: {
                            display_long: 'injeksion',
                            display_long_plural: 'injeksione',
                            display_short: '',
                            display_short_plural: ''
                        },
                        mg: {
                            display_long: 'miligram',
                            display_long_plural: 'miligram',
                            display_short: 'mg',
                            display_short_plural: 'mg'
                        },
                        ml: {
                            display_long: 'mililitër',
                            display_long_plural: 'mililitra',
                            display_short: 'ml',
                            display_short_plural: 'ml'
                        },
                        packet: {
                            display_long: 'paketë',
                            display_long_plural: 'paketa',
                            display_short: '',
                            display_short_plural: ''
                        },
                        patch: {
                            display_long: 'pjesë',
                            display_long_plural: 'pjesë',
                            display_short: '',
                            display_short_plural: ''
                        },

                        piece: {
                            display_long: 'copë',
                            display_long_plural: 'copë',
                            display_short: '',
                            display_short_plural: ''
                        },
                        tablet: {
                            display_long: 'tabletë',
                            display_long_plural: 'tableta',
                            display_short: '',
                            display_short_plural: ''
                        },
                        puff: {
                            display_long: 'duhmë',
                            display_long_plural: 'duhma',
                            display_short: '',
                            display_short_plural: ''
                        },
                        spray: {
                            display_long: 'sprucim',
                            display_long_plural: 'sprucime',
                            display_short: '',
                            display_short_plural: ''
                        },
                        suppository: {
                            display_long: 'supost',
                            display_long_plural: 'suposte',
                            display_short: '',
                            display_short_plural: ''
                        },
                        tbsp: {
                            display_long: 'lugë gjelle',
                            display_long_plural: 'lugë gjelle',
                            display_short: '',
                            display_short_plural: ''
                        },
                        tsp: {
                            display_long: 'lugë çaji',
                            display_long_plural: 'lugë çaji',
                            display_short: '',
                            display_short_plural: ''
                        },
                        unit: {
                            display_long: 'njësi',
                            display_long_plural: 'njësi',
                            display_short: '',
                            display_short_plural: ''
                        }
                    }
                }
            },
            logentry_how_to_do_it: "Si të bëni matjet",
            logentry_blood_pressure_instructions1: "Shmangni pijet me kafeinë ose alkoolike 30 minuta më parë.",
            logentry_blood_pressure_instructions2: "Uluni të qetë për pesë minuta me shpinën tuaj të mbështetur dhe këmbët tuaja të pakryqëzuara.",
            logentry_blood_pressure_instructions3: "Mbështetni krahun tuaj në mënyrë që bërryli juaj të jetë në nivelin e zemrës ose afër tij.",
            logentry_blood_pressure_instructions4: "Mbështilleni pajisjen mbi lekurë të zhveshur.",
            logentry_blood_pressure_instructions5: "Mos flisni gjatë matjes.",
            logentry_blood_pressure_instructions6: "Lëreni pajisjen e shfryrë në vend, prisni një minutë, pastaj bëni një lexim të dytë. Nëse leximet janë afër bëni mesataren e tyre. Nëse jo, përsërisni matjen dhe bëni mesataren e tre leximeve.",
            logentry_respiratory_rate_instructions0: "Ju duhet të kërkoni një person tjetër që të masë shkallën tuaj të frymëmarrjes.",
            logentry_respiratory_rate_instructions1: "Uluni drejt.",
            logentry_respiratory_rate_instructions2: "Lëreni që personi tjetër të përpiqet të llogarisë frymëmarrjet tuaja pa e ditur. Nëse e dini, mund të përpiqeni të kontrolloni frymëmarrjen tuaj. Kjo mund të japë një shkallë të gabuar të frymëmarrjes.",
            logentry_respiratory_rate_instructions3: "Lëreni që personi tjetër të përdorë një orë me dorën tjetër dhe të numërojë frymëmarrjen tuaj për 60 sekonda. Ai mund të përdorë ndonjë nga metodat e mëposhtme për të llogaritur:\n - Shikon ngritjen dhe rënien e gjoksit tuaj. Një ngritje dhe një rënie llogariten si 1 frymëmarrje.\n - Dëgjon frymëmarrjen tuaj.\n - Vendos dorën e tij në gjoksin tuaj për të ndier ngritjen dhe rënien.",
            logentry_oxygen_saturation_instructions1: "Oksimetrat e përdorimit në shtëpi janë në dispozicion për shitje në internet dhe në farmaci. Këto mund të përdoren për të matur nivelet e oksigjenit në gjakun tuaj.",
            logentry_oxygen_saturation_instructions2: "Para se ta provoni në shtëpi, bisedoni me mjekun tuaj.",
            logentry_oxygen_saturation_instructions3: "Ekrani i oksimetrit tregon përqindjen e oksigjenit në gjakun tuaj. Për dikë që është i shëndetshëm, niveli normal i ngopjes së oksigjenit në gjak do të jetë rreth 95-100%.",
            logentry_oxygen_saturation_instructions0: "Oksigjeni bartet në qelizat e kuqe të gjakut nga një molekulë e quajtur hemoglobinë. Oksimetria e pulsit mat sa oksigjen ka hemoglobina në gjakun tuaj. Kjo quhet ngopja e oksigjenit dhe është përqindje (deri në 100).",
            logentry_temperature_instructions_title1: "Përdorimi i një termometri dixhital oral",
            logentry_temperature_instructions11: "Lani duart tuaja me sapun dhe ujë të ngrohtë.",
            logentry_temperature_instructions12: "Përdorni një termometër të pastër, që është larë në ujë të ftohtë, është pastruar duke e fërkuar me alkool dhe më pas është shpëlarë për të hequr alkoolin.",
            logentry_temperature_instructions13: "Mos hani ose pini asgjë për të paktën pesë minuta para se të masni temperaturën tuaj, sepse temperatura e ushqimit ose pijeve mund ta bëjë leximin të pasaktë. Ju duhet ta mbani gojën të mbyllur gjatë kësaj kohe.",
            logentry_temperature_instructions14: "Vendoseni majën e termometrit nën gjuhë.",
            logentry_temperature_instructions15: "Mbajeni termometrin në të njëjtin vend për rreth 40 sekonda.",
            logentry_temperature_instructions16: "Leximet do të vazhdojnë të rriten dhe simboli F (ose C) do të ndizet gjatë matjes.",
            logentry_temperature_instructions17: "Zakonisht termometri do të bëjë një zhurmë të fortë kur të bëhet leximi përfundimtar (zakonisht pas 30 sekondash). Nëse jeni duke ndjekur, regjistroni temperaturën dhe kohën.",
            logentry_temperature_instructions18: "Shpëlajeni termometrin në ujë të ftohtë, pastrojeni me alkool dhe shpëlajeni përsëri.",
            logentry_temperature_instructions_title2: "Përdorimi i një termometri dixhital për matjet nën sqetull",
            logentry_temperature_instructions21: "Hiqni këmishën dhe vendosni majën e termometrit në sqetull. Sigurohuni që sqetulla juaj është e thatë për të marrë leximin më të saktë.",
            logentry_temperature_instructions22: "Mbajeni termometrin nën sqetull në vend duke e palosur krahun në gjoks.",
            logentry_temperature_instructions23: "Termometri do të fryhet kur të bëhet leximi (kjo metodë mund të zgjasë më shumë se 30 sekonda).",
            logentry_temperature_instructions24: "Hiqni termometrin dhe regjistroni temperaturën dhe kohën.",
            logentry_temperature_instructions25: "Pastroni termometrin me sapun dhe ujë ose me alkool, duke e shpëlarë gjithmonë si një hap të fundit.",
            logentry_temperature_instructions_title3: "Përdorimi i një termometri për matje në vesh.",
            logentry_temperature_instructions31: "Tërhiqeni butësisht pas në majë të veshit për të hapur kanalin e veshit.",
            logentry_temperature_instructions32: "Vendosni mbulesën mbrojtëse në majën e termometrit.",
            logentry_temperature_instructions33: "Vendosni butësisht termometrin derisa kanali i veshit të mbyllet plotësisht.",
            logentry_temperature_instructions34: "Shtypni dhe mbani shtypur butonin për 1-2 sekonda derisa të dëgjoni një zhurmë (ndiqni udhëzimet e prodhuesit).",
            logentry_temperature_instructions35: "Hiqni termometrin, hidhni kapakun dhe regjistroni temperaturën dhe kohën.",
            logentry_temperature_instructions_note26: "Temperaturat e rektumit konsiderohen si treguesit më të saktë të temperaturës së trupit. Leximet e temperaturës orale dhe aksilare janë rreth to ½° deri 1°F (.3°C deri .6°C) poshtë rektumit. Shtojini këta numra në leximet e temperaturës orale dhe nën sqetull për leximin më të saktë.",
            logentry_hydration_instructions_title: "Sfida e Hidratimit (nga Fondacioni Britanik i Zemrës)",
            logentry_hydration_instructions_title1: "Fillimi - Java 1",
            logentry_hydration_instructions11: "Regjistroni sa ujë pini gjatë kohës që kaloni në punë. Mbani shënime se si jeni ndjerë gjatë ditës.",
            logentry_hydration_instructions12: "Në fund të javës, llogaritni konsumin tuaj mesatar ditor të ujit për javën e parë. Ju duhet të keni për qëllim të pini 4-6 gota (600ml-900ml) në ditë për kohën që kaloni në punë.",
            logentry_hydration_instructions_title2: "Javët 2–4",
            logentry_hydration_instructions21: "Në javët pasuese, synoni të rritni marrjen e ujit gradualisht, duke punuar drejt objektivit të 4-6 gotave (600ml-900ml) në ditë për kohën që kaloni në punë.",
            logentry_hydration_instructions22: "Vazhdoni të regjistroni sa gota (1 gotë është afërsisht 150 ml) ujë pini në punë çdo ditë. Në fund të çdo dite, regjistroni se si jeni ndjerë. A ka pasur ndonjë ndryshim në nivelin tuaj të përqendrimit, ndjenjën e lodhjes ose nervozizmin, për shembull?",
            logentry_hydration_instructions23: "Mendoni për arsyet pse nuk pini më shumë ujë. Diskutoni me kolegët tuaj të punës zgjidhje rreth këtyre problemeve.",
            logentry_hydration_instructions_title3: "Faktorët që mund t'ju pengojnë të pini më shumë lëngje gjatë punës, mund të përfshijnë:",
            logentry_hydration_instructions31: "Vendndodhja e largët e objekteve ujore, duke i bërë ato të paarritshme.",
            logentry_hydration_instructions32: "Mundësi të kufizuara për të pushuar për një pije.",
            logentry_hydration_instructions33: "Mungesa e inkurajimit për të ndaluar për të pirë ujë.",
            logentry_hydration_instructions34: "Duke mos dashur të pini shumë për shkak të sikletit për të përdorur ambiente të përbashkëta tualeti ose tualete të mirëmbajtura keq.",
            logentry_weight_instructions0: "Merrini vetes një shkallë të mirë trupore.",
            logentry_weight_instructions1: "Peshoni veten të paktën dy herë në ditë: kur zgjoheni, para se të shkoni në shtrat, dhe në mes të ditës (nëse është e mundur).",
            logentry_weight_instructions2: "Idealisht, peshoni veten të zhveshur. Veshja mund të shtojë peshë shtesë, dhe kjo sasi ndryshon, kështu që duhet ta shmangni nëse është e mundur.",
            logentry_weight_instructions3: "Shanset janë që ju nuk mund të peshoni veten nudo në mes të ditës,  kështu që peshoni veten para dhe pasi të visheni disa herë për të zbuluar se sa (përafërsisht) ndryshon pesha juaj kur visheni, kështu që ju mund të zbrisni atë masë nëse duhet të peshoni veten të veshur.",
            logentry_height_instructions0: "Së pari, gjeni një pjesë të sheshtë, pa tapet të dyshemesë dhe një pjesë të sheshtë muri.",
            logentry_height_instructions1: "Hiqni këpucët. Hiqni gërshetat, shiritat e kokës ose ndonjë gjë tjetër në kokën tuaj që të mund të merrni në një matje të saktë.",
            logentry_height_instructions2: "Hiqni çdo veshje të rëndë që mund ta bëjë të vështirë të qëndronin drejt me murit.",
            logentry_height_instructions3: "Qëndroni me këmbët tuaja të puthitura në dysheme thembrën kundër këndit ku takohen muri dhe dyshemeja. Sigurohuni që koka, shpatullat dhe të pasmet po prekin murin.",
            logentry_height_instructions4: "Qëndroni drejt me sytë tuaj që shikojnë përpara. Linja juaj e shikimit dhe mjekrës duhet të jetë paralele me dyshemenë.",
            logentry_height_instructions5: "Merrni dikë që të vendosë një objekt të sheshtë (si një vizore ose libër me kopertinë të fortë) kundër murit në një kënd të drejtë. Atëherë, lërini që ta ulin derisa të mbështetet butësisht në majë të kokës tuaj, duke e mbajtur atë në një kënd të duhur me murin.",
            logentry_height_instructions6: "Shënoni lehtë murin me laps në pikën kur vizorja ose libri (ose sendi tjetër i sheshtë) takon kokën tuaj.",
            logentry_height_instructions7: "Përdorni një kasetë mase - në mënyrë ideale një metalike që do të mbetet drejt - për të matur distancën nga dyshemeja deri në shenjën në mur.",
            logentry_height_instructions8: "Merrni shënim të matjes në 1/8-tën më të afërt të një inç ose 0.1 centimetër.",
            logentry_glucose_instructions0: "Lani dhe thani duart mirë.",
            logentry_glucose_instructions1: "Vendosni një shirit provë në matësin tuaj.",
            logentry_glucose_instructions2: "Shponi anën e gishtit tuaj me bisturinë që ndodhet në pajisjen tuaj të testimit.",
            logentry_glucose_instructions3: "Butësisht shtrydhni ose masazhoni gishtin tuaj derisa të formohet një pikë gjaku.",
            logentry_glucose_instructions4: "Prekni dhe mbajeni anën e shiritit të provës deri në marrjen e pikës së gjakut.",
            logentry_glucose_instructions5: "Matësi do të shfaq nivelin e glukozës në gjak në një ekran pas disa sekondash.",
            logentry_ketones_instructions0: "Lani duart.",
            logentry_ketones_instructions1: "Vendosni bisturinë me gjilpërë, duke ndjekur udhëzimet e dhëna.",
            logentry_ketones_instructions2: "Vendosni një rrip ketoni gjaku në matësin e ketonit.",
            logentry_ketones_instructions3: "Shponi gishtin tuaj për të nxjerrë një pikë të vogël gjaku duke përdorur bisturinë.",
            logentry_ketones_instructions4: "Lëreni shiritin të vijë në kontakt me pikën e gjakut dhe të kontrolloni rezultatet.",
            logentry_ketones_instructions5: "Hidhni rripin dhe shiritin siç sugjerohet në udhëzime.",
            logentry_urine_ph_instructions0: "Merrni shiritin e provës së pH. Ky shirit mat gjendjen acid-alkaline të çdo lëngu. Leximet në pikën e ulët të shkallës tregojnë një gjendje acid, dhe ato në pikën më të lartë një gjendje më alkaline. Udhëzimet e plota se si t'i përdorni ato vijnë me shiritat, por këto janë hapat e thjeshtë për tu ndjekur për të provuar ekuilibrin e pH të trupit tuaj në shtëpi.",
            logentry_urine_ph_instructions1: "Provoni në mëngjes. Gjëja e parë në mëngjes, nëse është e mundur pas 6 orësh gjumë pa u ngritur të urinoni, merrni një shirit provë. Ose urinoni drejtpërdrejt në shirit ose mblidhni urinën në një filxhan dhe zhytni shiritin në filxhanin me urinë.",
            logentry_urine_ph_instructions2: "Ju lutemi vini re se urina e parë e mëngjesit është leximi më i vlefshëm i pH. Nëse nuk mund të shkoni 6 orë pa u ngritur për të urinuar, atëherë thjesht provoni urinën e parë në mëngjes kur të zgjoheni për ditën.",
            logentry_urine_ph_instructions3: "Lexoni ngjyrën e rezultatit. Ndërsa shiriti i provës është lagur, do të marrë një ngjyrë. Ngjyra ka të bëjë me acidin ose gjendjen alkaline të urinës tuaj dhe shkon nga e verdha në blu të errët. Përputhni ngjyrën e shiritit tuaj të provës me grafikun e dhënë në pjesën e prapme të pajisjes tuaj të testimit.\n - Një numër nën 6.5 do të thotë që urina juaj është në anën acide. Sa më i ulët të jetë numri, aq më shumë acide është gjendja.\n - Leximi ideal i urinës duhet të jetë midis 6.5 deri 7.5.",
            logentry_menstruation_instructions0: "Një sasi normale e humbjes së lëngut menstrual për periodë është midis 5 ml deri në 80 ml.",
            logentry_menstruation_instructions1: "Metoda të ndryshme të kontrollit të lindjes mund të ndikojnë në intensitetin e periodës suaj.",
            logentry_menstruation_instructions2: "Humbja e mbi 80 ml lëngut menstrual për një periodë konsiderohet gjakderdhje e rëndë menstruale.",
            logentry_menstruation_instructions_title1: "Vlerësimi i vëllimit të periodës",
            logentry_menstruation_instructions3: "Nëse përdorni një kupe menstruale, ato shpesh kanë njësi vëllimi (p.sh. 10 ml, 15 ml, 25 ml) të treguara në anë të kupës së menstruacioneve për t'ju ndihmuar të vlerësoni sa lëng keni humbur.",
            logentry_menstruation_instructions4: "Në përgjithësi, sasia e lëngut të humbur mund të vlerësohet kur përdorni peceta higjenike ose tampona, në varësi të madhësisë dhe sasisë së lagies së produktit menstrual. Një tampon i lehtë plotësisht i ngopur mund të mbajë deri në 3 ml lëng, ndërsa një super tampon plotësisht i ngopur mund të mbajë deri në 12 ml (3,8).",
            logentry_menstruation_instructions5: "Në një ditë normale një pecetë higjenike plotësisht e lagur mund të mbajë rreth 5 ml lëng, dhe peceta higjenike e njomur plotësisht brenda natës mund të mbajë 10-15 ml.",
            logentry_menstruation_instructions6: "Nëse përsërisni vazhdimisht përmes një tamponi ose pecete higjenike çdo dy orë, kjo konsiderohet gjakderdhje e rëndë menstruale dhe duhet të sillet në vëmendjen e mjekut tuaj.",
            about: 'Rreth nesh',
            contact: 'Kontakt'
        }
    }

import React, {Component} from 'react'
import PropTypes from "prop-types";
import {downloadMenstruationReport, getMenstruationStats} from "../../service/logbook_service";
import {$$} from "../../helpers/localization";
import {TimeSpanPicker} from "../shared/TimeSpanPicker";
import moment from "moment";
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";
import {downloadUtils} from "../../utils/downloadUtils";
import {CONVERTER} from "../../utils/converter";
import addDays from "date-fns/addDays";
import {differenceInDays} from "date-fns";
import {infoUtils} from "../../utils/infoUtils";
import no_data from '../../resources/images/no_data.png'


// eslint-disable-next-line react/prop-types
function Accented({children}) {
    return <span className="font-weight-normal">{children}</span>
}

function suffBg(number) {
    let i = 0;
    for (; i < 6; i++) {
        let rule = rules[i];
        for (const subRule of rule) {
            if (("" + number).endsWith(subRule)) {
                return suffixesBg[i]
            }
        }
    }
    return suffixesBg[i];
}

function toDays(v) {
    if (v === 0) {
        return <Accented>&mdash;</Accented>
    }

    const unit = v > 1 ? "days" : "day";

    return <Accented>
        {Math.round(v)} {$$(unit).toLowerCase()}
    </Accented>
}


const english_ordinal_rules = new Intl.PluralRules("en", {type: "ordinal"});
const suffixesEn = {
    one: "st",
    two: "nd",
    few: "rd",
    other: "th"
};

const suffixesBg = ["ен", "тен", "и", "ви", "ри", "ти", "и"];

const rules = [
    [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000], // -> "ен"
    [100, 400, 500, 600, 700, 700, 800, 900], // -> "тен"
    [11, 12, 13, 14, 200, 300], // -> "и"
    [1], // -> "ви"
    [2], // -> "ри"
    [3, 4] // -> "ти"
];

//everything else -> "и"

function Periods({data}) {
    return <div>
        <h4>{$$("history")}</h4>
        <hr/>
        {
            data.periodStarts.map(a=>a).sort((a, b) => {
                return new Date(b).getTime() - new Date(a).getTime()
            }).map((key) => {
                return <div key={key} className="mb-2">
                    <div>{CONVERTER.formatDate(key, true)}</div>
                    <Bar key={key} idx={key} data={data}/>
                </div>
            })
        }
    </div>
}

function Bar({idx, data}) {
    const periodLength = data.periodLengths[idx];
    const cycleLength = data.cycleLengths[idx];
    let normalizedCycleWidth = cycleLength > 50 ? 50 : (cycleLength === 0 ? calcDays(idx, periodLength) : cycleLength - periodLength);
    let cyclePercentWidth = Math.round(normalizedCycleWidth / 50 * 100);
    let periodLengthPercentWidth = Math.round(periodLength * 2);

    return <div className="position-relative d-flex pr-2">
        <div style={{width: `${periodLengthPercentWidth}%`}}
             className="text-right p-1 pr-2 bg-danger text-light border-top-left-round border-bottom-left-round">{periodLength}</div>
        {normalizedCycleWidth > 0 && <div style={{width: `${cyclePercentWidth}%`}}
                                          className="text-right p-1 pr-2 medrec-green-2-bg border-top-right-round border-bottom-right-round position-relative">
            {normalizedCycleWidth + periodLength > 50 && <Spacer/>}
            {cycleLength > 0 ? cycleLength : "?"}
        </div>}
        {normalizedCycleWidth === 0 && <div className="p-1">?</div>}
    </div>
}

function calcDays(startDate, periodDays) {
    let res = addDays(new Date(startDate), periodDays - 1)
    let number = differenceInDays(new Date(), res);
    return number < 0 ? 0 : number;
}

function Spacer() {
    return <span className="position-absolute" style={{
        width: "1.2rem",
        backgroundColor: "#fff",
        display: "inline-block",
        top: "0",
        bottom: "0",
        right: "3rem",
        textAlign: "center",
        lineHeight: "2rem"
    }}>{"..."}</span>
}

export class MenstruationStats extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        selectedStart: new Date(),
        selectedEnd: new Date()
    }

    downloadReport = () => {
        let reportData = {}
        reportData.reportTitle = $$("menstruation_report");
        reportData.patientName = this.props.selectedUser.fullname;
        let format = this.props.settings.data.dateFormat.toUpperCase();
        reportData.fromDate = moment(this.state.selectedStart).format(format);
        reportData.toDate = moment(this.state.selectedEnd).format(format);
        reportData.datetime = moment(new Date()).format(format);
        reportData.data = this.state.menstruationStats;
        downloadMenstruationReport(this.props.selectedLanguage, reportData).then((file) => {
            downloadUtils.download(file, "Menstruation Report  - " +
                this.props.selectedUser.fullname +
                " - " +
                reportData.fromDate +
                " to " +
                reportData.toDate +
                ".pdf");
        });
    }
    menstruationStats = async (fromDate, toDate) => {
        let healthIssueIds = []

        for (let i in this.props.healthIssues.entries) {
            healthIssueIds.push(this.props.healthIssues.entries[i].id)
        }

        let params = {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            fromDate: fromDate ? fromDate : moment().subtract(1, "years").toDate().getTime(),
            toDate: toDate ? toDate : moment().toDate().getTime(),
            healthIssueIds: healthIssueIds
        }

        await getMenstruationStats(this.props.userId, params).then((res) => {
            this.setState({menstruationStats: res, fromDate: fromDate, toDate: toDate})
        })

    }

    ordinalSuffix = (number) => {
        if (this.props.selectedLanguage === 'en') {
            const category = english_ordinal_rules.select(number);
            const suffix = suffixesEn[category];
            return suffix;
        }
        return suffBg(number);
    }

    getDateOrdinal = (number) => {
        if (number === 0) {
            return <Accented>&mdash;</Accented>
        }
        return <Accented>{number}<sup>{this.ordinalSuffix(number)}</sup></Accented>
    }


    onFilter = async (dateRange) => {
        if (dateRange.start && dateRange.end) {
            let start = parseInt((dateRange.start.getTime()).toFixed(0));
            let end = parseInt((dateRange.end.getTime()).toFixed(0));

            await this.menstruationStats(start, end)

        }
    }

    render() {
        if (this.props.selectedUser.gender === 'MALE') {
            return null;
        }


        return (
            <div className={"card-body chart-card"}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div className="card-title chart-title">{$$("menstruation_stats")}</div>
                    <TimeSpanPicker onFilter={this.onFilter}
                                    settings={this.props.settings}
                    />
                </div>

                {this.state.menstruationStats?.entries?.length > 0 && <div className="pb-2 pl-2 pr-2 mb-2 font-weight-normal">
                    <DownloadEditDeleteButtons handleDownloadClick={this.downloadReport}/>
                    <span className={"user-profile-link cursor-pointer"}
                          onClick={this.downloadReport}>{$$("download_menstruation_report")}
                </span>
                </div>}

                {this.state.menstruationStats?.entries?.length > 0 && <div className="low-shadow-container">
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>{$$("cycle_day")}:</div>
                        <div>{this.getDateOrdinal(this.state.menstruationStats.cycleDay)}</div>
                    </div>
                    <br/>
                    <br/>

                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>{$$("cycle")}</div>
                            <div>{$$("length")}</div>
                        </div>
                        <hr/>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>{$$("last_cycle")}</div>
                            <div>{toDays(this.state.menstruationStats.lastCycleLength)}</div>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>{$$("cycle_average")}</div>
                            <div>{toDays(this.state.menstruationStats.averageCycleLength)}</div>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>{$$("cycle_shortest")}</div>
                            <div>{toDays(this.state.menstruationStats.shortestCycleLength)}</div>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>{$$("cycle_longest")}</div>
                            <div>{toDays(this.state.menstruationStats.longestCycleLength)}</div>
                        </div>
                    <br/>
                    <br/>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>{$$("period")}</div>
                            <div>{$$("length")}</div>
                        </div>
                        <hr/>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>{$$("last_period_length")}</div>
                            <div>{toDays(this.state.menstruationStats.lastPeriodLength)}</div>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>{$$("period_average")}</div>
                            <div>{toDays(this.state.menstruationStats.averagePeriodLength)}</div>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>{$$("period_shortest")}</div>
                            <div>{toDays(this.state.menstruationStats.shortestPeriodLength)}</div>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>{$$("period_longest")}</div>
                            <div>{toDays(this.state.menstruationStats.longestPeriodLength)}</div>
                        </div>
                        <br/>
                        <br/>
                        <Periods data={this.state.menstruationStats}/>
                </div>}
                {this.state.menstruationStats?.entries?.length === 0 && <NoData />}
            </div>
        )
    }
}

function NoData() {
    let emptyResultObj = {
        imgClass: 'no-lab-results-img',
        primaryLabelClass: 'no-lab-results-primary-label',
        secondaryLabelClass: 'no-lab-results-secondary-label',
        src: no_data,
        primaryLabel: $$('no_results_found'),
        secondaryLabel: ''
    }
    return infoUtils.noData(emptyResultObj);
}

MenstruationStats.propTypes = {
    userId: PropTypes.string,
    healthIssues: PropTypes.object,
    selectedUser: PropTypes.object,
    selectedLanguage: PropTypes.any,
    settings: PropTypes.any,
}

export default MenstruationStats

import React from "react";
import LanguageSelector from "../../../components/layout/LanguageSelector"
import {connect} from 'react-redux'
import {logout} from '../../../actions/auth_actions'
import {changeLanguage} from '../../../actions/language_actions'
import {getUserImage} from '../../../service/users_service'
import noImage from '../../../../public/media/profile/no_image.png'
import history from "../../../helpers/history";
import {Routes} from '../../../constants/routes'
import {Link} from 'react-router-dom'
import {$$} from '../../../helpers/localization'
import UserImage from "../../../components/shared/UserImage";
import {selectUser} from "../../../actions/users_actions";
import classNames from "classnames";
import {CHAT_VIEWPOSITION} from "../../../constants/enums";
import VideoMessageCallListener from "../../../components/videocall/VideoMessageCallListener";
import ApproveChild from "../../../components/profile/ApproveChild";
import {IDLE} from "../../../constants/call_status";
import {VideoManager} from "../../../components/videocall/video_manager";
import ExposeContextToVideoControls from "./ExposeContextToVideoControls";
import PropTypes from "prop-types";
import MessageNotifications from "./MessageNotifications";
import EndOfAppointmentWarning from "../../../components/videocall/EndOfAppointmentWarning";

class Topbar extends React.Component {


    constructor(props, context) {
        super(props, context);
        this.callAudio = React.createRef();
        this.endAudio = React.createRef();
        this.state = {
            chatViewPosition: CHAT_VIEWPOSITION.INITIAL,
            showNotification: false
        }
        this.imgRef = React.createRef();
    }

    /*logoutFromFacebook() {
        var tthis = this;
        FB.getLoginStatus(function (response) {
            if (response && response.status === 'connected') {
                FB.logout(() => {
                    tthis.props.logout();
                });
            } else {
                tthis.props.logout();
            }
        });
    } */

    /**
     * Set the ongoing call flag to show label in subheader
     *
     * @param {boolean} value - true if call is ongoing
     */
    setOngoingCallFlag = (value) => {
        this.setState({onGoingCall: value});
    }

    /**
     * Set flag to indicate the chat view expansion
     */
    expandView = () => {
        this.setState({chatViewPosition: CHAT_VIEWPOSITION.FULL});
    }

    /**
     * Set flag to indicate the chat view shrinking
     */
    shrinkView = () => {
        this.setState({chatViewPosition: CHAT_VIEWPOSITION.ONLY_CHAT});
    }

    onSelectChild = (p) => {
        if (!p.child_accepted) {
            this.setState({approveChildPopup: true, selectedChildToApprove: p})
        } else {
            this.props.selectUser(p, true)
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.chat.new_message
            && this.props.chat.new_message.type === "TEXT"
            && this.props.chat.new_message.from_user_id !== this.props.user.data.id
            && this.props.chat.new_message.id !== prevProps.chat.new_message?.id
            && !this.props.chat.new_message.seen
            && !this.state.showNotification
            && history.location.pathname !== Routes.MESSAGES
        ) {
            this.fetchUserImage(this.props.chat.new_message);
            this.setState({
                showNotification: true
            })
        }
    }

    /**
     * Fetch selected user image, if there is not found, display placeholder
     */
    fetchUserImage = (message) => {
        try {
            getUserImage(message.from_user_id).then((res) => {
                this.imgRef.current.type = "image/jpg"
                this.imgRef.current.src = res;
            }).catch(() => {
            });
        } finally {
            if (this.imgRef && this.imgRef.current && message && message.from_user_id !== this.props.chat.new_message.from_user_id) {
                this.imgRef.current.type = "image/png"
                this.imgRef.current.src = noImage;
            }
        }
    }

    render() {
        let videoControlsClass = classNames("video-controls-wrap", {"d-none": !this.props.showVideoControls}); //!this.props.showVideoControls

        return (
            <div className="kt-header__topbar header-topbar-mobile">

                {this.props.userInfo.id && <EndOfAppointmentWarning/>}

                <div className={videoControlsClass}>
                    {/* {this.props.userToChatFullName ? <div className="video-controls-username">{this.props.userToChatFullName}</div> : null} */}
                    {!this.props.modalShow &&
                        <ExposeContextToVideoControls topbar={true} modalShow={this.props.modalShow} onHangup={() => {
                            VideoManager.setState({callStatus: IDLE})
                        }}/>}
                </div>
                {this.props.userInfo.id && <VideoMessageCallListener/>}

                <div className="dropdown" style={{"alignSelf": "center"}}>
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
                       aria-haspopup="true" aria-expanded="false">
                        <UserImage classnames="profile-image" userID={this.props.selectedUser.id}/>
                        {this.props.selectedUser.fullname}
                    </a>

                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">

                        {
                            this.props.selectedUser.id === this.props.userInfo.id ? null :
                                <a className="dropdown-item" href="#" onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.props.selectUser(this.props.userInfo, true)
                                }}>
                                    <UserImage classnames="profile-image kt-nav__link-icon"
                                               userID={this.props.userInfo.id}/>

                                    <span
                                        className="kt-nav__link-text dropdown-link">{this.props.userInfo.fullname}
                                    </span>
                                </a>
                        }

                        {
                            this.props.children_profiles.entries.filter(p => p.id !== this.props.selectedUser.id).map((p, i) => {
                                return <a className="dropdown-item" href="#" key={i}
                                          onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              this.onSelectChild(p);
                                          }}>
                                    <UserImage classnames="profile-image kt-nav__link-icon" userID={p.id}/>
                                    <span className="kt-nav__link-text dropdown-link">{p.fullname}</span>
                                    {!p.child_accepted && <span
                                        className={"kt-nav__link-text dropdown-link text-danger ml-3 flex-grow-1 text-right"}>{$$("pin_required")}</span>}
                                </a>
                            })
                        }

                        {
                            this.props.children_profiles.entries.length > 0 ? <hr/> : null
                        }
                        <Link className="dropdown-item" to={Routes.CHILD_RECORD}><i
                            className="kt-nav__link-icon flaticon-user"/> <span
                            className="kt-nav__link-text dropdown-link">{$$('child_label')}</span></Link>
                        <Link className="dropdown-item" to={Routes.CHANGE_PASSWORD}><i
                            className="kt-nav__link-icon flaticon-lock"/> <span
                            className="kt-nav__link-text dropdown-link">{$$('change_password_label')}</span></Link>
                        <Link className="dropdown-item" to={Routes.LOGIN} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            window.google.accounts.id.disableAutoSelect()
                            this.props.logout()
                            //this.logoutFromFacebook();

                        }}><i className="kt-nav__link-icon flaticon-logout"/> <span
                            className="kt-nav__link-text dropdown-link">{$$('logout_label')}</span></Link>
                    </div>
                </div>

                <LanguageSelector className="show" changeLanguage={this.props.changeLanguage}/>

                <ApproveChild
                    showAcceptChild={this.state.approveChildPopup}
                    child={this.state.selectedChildToApprove}
                    selectChild={this.props.selectUser}
                    switchUserAfterApproval={true}
                    closePopup={() => {
                        this.setState({approveChildPopup: false})
                    }}
                />

                {this.state.showNotification &&
                    <div
                        aria-live="polite"
                        aria-atomic="true"
                        style={{
                            position: 'relative',
                            minHeight: '300px',
                        }}
                    >

                        <MessageNotifications message={this.props.chat.new_message}
                                              imgRef={this.imgRef}
                                              i18n={this.props.i18n}
                                              desktopNotifications={'Notification' in window && Notification.permission === "granted"}
                                              showNotification={this.state.showNotification}
                                              closeNotification={() => this.setState({showNotification: false})}
                        />


                    </div>
                }

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedUser: state.selectedUser.data,
        auth_data: state.authentication.data,
        userInfo: state.userInfo.data,
        children_profiles: state.children_profiles,
        i18n: state.language,
        showVideoControls: state.video.data.callStatus === 'PRE_JOIN',
        videoMuted: state.video.data.videoMuted,
        audioMuted: state.video.data.audioMuted,
        modalShow: state.video.data.modalShow,
        user: state.userInfo,
        appointmentId: state.video_message.appointment_id,
        videoStartTime: state.video_message.date_time,
        userAppointments: state.userAppointments.entries,
        chat: state.chat
    }
}

Topbar.propTypes = {
    selectUser: PropTypes.func,
    logout: PropTypes.func,
    changeLanguage: PropTypes.func,
    showVideoControls: PropTypes.any,
    modalShow: PropTypes.any,
    userInfo: PropTypes.any,
    selectedUser: PropTypes.any,
    children_profiles: PropTypes.any,
    i18n: PropTypes.any,
    user: PropTypes.any,
    chat: PropTypes.any
}

export default connect(mapStateToProps, {logout, changeLanguage, selectUser})(Topbar);



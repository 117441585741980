import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import moment from 'moment';
import SymptomStrengthPairs from './SymptomStrengthPairs'
import SearchForSymptoms from './SearchForSymptoms'
import SymptomsTreeView from './SymptomsTreeView'
import {SYMPTOMS} from '../../constants/symptoms'
import CenteredModal from '../shared/CenteredModal'
import {v4 as uuid} from 'uuid'
import {getLatestSymptomsTypes, logSelectedUserSymptoms} from '../../actions/symptoms_actions'
import {connect} from 'react-redux'
import CustomMultiselect from '../shared/CustomMultiSelect'
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {getHealthIssueOptions} from '../../constants/select_options'
import {getLanguage, getTreeViewSymptoms} from '../../utils/symptomUtils';
import FormWithLoading from "../shared/FormWithLoading";
import PropTypes from "prop-types";
import bg from 'date-fns/locale/bg';
import sq from 'date-fns/locale/sq';
import enGB from 'date-fns/locale/en-GB';


export class SymptomsLogForm extends Component {

    state = {
        created_timestamp: this.props.created_timestamp,
        deleted: false,
        id: this.props.id,
        notes: this.props.notes,
        symptoms: [],
        timezone: this.props.timezone,
        updated_timestamp: 0,
        user_id: this.props.userId,
        health_issues_ids: this.props.selectedEntry && this.props.health_issues_ids ? this.props.health_issues_ids : [],
        selectedHealthIssues: this.props.selectedEntry && this.props.health_issues_ids ? getHealthIssueOptions(this.props.getHealthIssueList(this.props.selectedEntry)) : [],
        formclass: '',
        showModal: false,
        errors: {},
        date: this.props.selectedEntry && this.props.date_time ? this.props.date_time : moment().valueOf(),
        time: this.props.selectedEntry && this.props.date_time ? this.props.date_time : moment().valueOf(),
    }

    constructor(props) {
        super(props);
    }


    getDateTime = () => {
        let dateTime = new Date(this.state.date);
        let t = new Date(this.state.time);
        dateTime.setHours(t.getHours());
        dateTime.setMinutes(t.getMinutes());
        return dateTime
    }

    getSymptomOptions() {
        let symptoms = this.getDefaultSymptoms();
        return symptoms.map((symptom) => {
            return {value: JSON.stringify(symptom), text: symptom.description}
        });
    }


    componentDidMount() {
        this.augmentAllSymptoms();
        let symptoms = [];
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.setState({timezone, symptoms: symptoms}, function () {
            this.displaySymptomsToUpdate();
        });
    }


    /**
     * Checks if the selected language has changed
     * If so, the labels of the symptoms are translated to the appropriate language
     *
     * @param {object} prevProps - previous props to be compared to the current ones
     */
    componentDidUpdate(prevProps) {
        if (prevProps.i18n.lang !== this.props.i18n.lang) {
            let translatedSymptoms = [];
            for (let i = 0; i < this.state.symptoms.length; ++i) {
                let symptom = {...this.state.symptoms[i]};
                symptom.description = this.getSymptomDescription(symptom);
                translatedSymptoms.push(symptom);
            }

            this.setState({symptoms: translatedSymptoms});
        }
    }


    onSelect = (selectedItem) => {
        let selectedIssuesArray = this.state.selectedHealthIssues;
        let selectedIssuesArrayIds = this.state.health_issues_ids;
        selectedIssuesArray.push(selectedItem.value);
        selectedIssuesArrayIds.push(selectedItem.value.id);
        this.setState({
            selectedHealthIssues: selectedIssuesArray,
            health_issues_ids: selectedIssuesArrayIds
        })
    }

    onRemove = (removedItem) => {
        let selectedIssuesArray = this.state.selectedHealthIssues;
        let selectedIssuesArrayIds = this.state.health_issues_ids;
        selectedIssuesArray.pop(removedItem);
        selectedIssuesArrayIds.pop(removedItem.id);
        this.setState({
            selectedHealthIssues: selectedIssuesArray,
            health_issues_ids: selectedIssuesArrayIds
        })
    }


    /**
     * Augment the main symptoms list with the full list of symptoms based on the language property
     */
    augmentAllSymptoms = () => {
        this.allSymptoms = {};
        this.allSymptoms['en'] = this.props.nomenclature.data.nomenclature['en'].all.concat(SYMPTOMS['en'].All);
        this.allSymptoms['bg'] = this.props.nomenclature.data.nomenclature['bg'].all.concat(SYMPTOMS['bg'].All);
    }

    /**
     * Prepares the default symptoms in the selected language. Adds 'No symptoms' to the
     * first element of the list.
     *
     * @returns {Array} - the list of default symptoms in the appropriate language
     */
    getDefaultSymptoms = () => {
        let lang = getLanguage(this.props.nomenclature.data.nomenclature, this.props.i18n.lang);
        let nomenclature = this.props.nomenclature.data.nomenclature[lang].all;
        return [{...nomenclature[nomenclature.length - 1]}].concat(nomenclature.filter(s => s.type !== 'NO_SYMPTOMS'));
    }

    /**
     * Handles the translation of the symptom description based on the language selected by the user
     *
     * @param {object} symptom - the symptom whose description might need to be translated in the selected language
     * @returns {string} - the appropriate symptom description
     */
    getSymptomDescription(symptom) {
        let lang = getLanguage(this.allSymptoms, this.props.i18n.lang);

        let s = this.allSymptoms[lang].find(s => s.type === symptom.type);
        if (s) {
            return s.description;
        } else {
            if (symptom.description) {
                return symptom.description;
            }
            return 'N/A';
        }
    }

    /**
     * Set the state to the latest selected date.
     *
     * @param {object} evt - The event handler argument
     */
    onDateChange = (date) => {
        if (date instanceof Date && !isNaN(date)) {
            const fields = Object.assign({}, this.state);
            let d = new Date(date);
            let t = new Date(this.state.time);
            d.setHours(t.getHours());
            d.setMinutes(t.getMinutes());
            fields["date"] = moment(d).valueOf();
            this.setState(fields);

            this.setState({
                errors: {datetime: null, date: null},
                dateTimeMissing: false,
                dateMissing: false
            });

        } else {
            this.setState({
                errors: {date: 'invalid_date_format'},
                dateTimeMissing: true,
                dateMissing: true
            });
        }
    };


    /**
     * Set the state to the latest selected date.
     *
     * @param {object} evt - The event handler argument
     */
    onTimeChange = (time) => {
        if (time instanceof Date && !isNaN(time)) {
            const fields = Object.assign({}, this.state);
            let d = new Date(this.state.date);
            d.setHours(time.getHours());
            d.setMinutes(time.getMinutes(), 0, 0);
            fields["time"] = moment(d).valueOf();
            this.setState(fields);
            this.setState({
                errors: {datetime: null, time: null},
                dateTimeMissing: false,
                timeMissing: false

            });


        } else {
            this.setState({
                errors: {time: 'invalid_time_format'},
                dateTimeMissing: true,
                timeMissing: true
            });
        }
    };


    /**
     *
     * Set the state to the latest change in the textarea value.
     *
     * @param {object} evt - The event handler argument
     */
    onNotesChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    /**
     * Set the showModal property to false and close the modal
     */
    onConfirmModal = () => {
        this.setState({showModal: false});
    }

    /**
     * Set the showModal property to true and display the modal
     */
    showModal = (evt) => {
        evt.preventDefault();
        this.setState({showModal: true});
    }

    /**
     * When a symptom is selected to be updated, it is added to the main symptoms list and displayed
     * along with its strength
     */
    displaySymptomsToUpdate = async () => {
        let notPresentSymptoms = this.props.selectedSymptoms
            .filter(selectedSymptom => !this.state.symptoms.find(s => s.type === selectedSymptom.type))
            .map(notPresentSymptom => {
                return {
                    ...notPresentSymptom,
                    strength: notPresentSymptom.strength,
                    selected: true,
                    shouldResetSelection: true
                };
            });
        let symptoms = this.state.symptoms.map(s => {
            let selectedSymptom = this.props.selectedSymptoms.find(selectedSymptom => selectedSymptom.type === s.type);
            if (selectedSymptom) {
                return {...s, strength: selectedSymptom.strength, selected: true, shouldResetSelection: true};
            }
            return s;
        });

        let allSymptoms = getTreeViewSymptoms(this.props.i18n.lang);
        let params = {
            size: 10
        }
        await this.props.getLatestSymptomsTypes(this.props.userId, params);
        let latestSymptoms = [];
        this.props.latestSymptomsTypes.entries && this.props.latestSymptomsTypes.entries.map((symptomType) => {
            let latest = allSymptoms && allSymptoms.find((symptom) => symptomType === symptom.type);
            latestSymptoms.push(latest);
        })

        let recentSymptoms = latestSymptoms && latestSymptoms.filter(latestSymptom => this.props.selectedSymptoms && !this.props.selectedSymptoms.find(s => s.type === latestSymptom.type))
            .map((symptom) => {
                return {...symptom, strength: 0, selected: false, shouldResetSelection: true};

            })
        this.setState({symptoms: notPresentSymptoms.concat(symptoms.filter(s => s.type !== 'NO_SYMPTOMS')).concat(recentSymptoms.filter(s => s.type !== 'NO_SYMPTOMS'))});
    }

    /**
     * When a symptom is selected from the TreeView component, it is added to the main symptoms list
     * along with the default strength
     *
     * @param {object} symptom - the selected symptom from the TreeView component
     */
    onSymptomSelected = (symptom) => {
        let notPresentSymptom = !this.state.symptoms.find(s => s.type === symptom.type);
        let notPresentSymptomEntries = [];
        if (notPresentSymptom) {
            notPresentSymptomEntries = [{...symptom, strength: 1, shouldResetSelection: true, selected: true}];
        }

        let symptoms = this.state.symptoms.map(s => {
            if (s.type === symptom.type) {
                return {...s, strength: 1, selected: true, shouldResetSelection: true};
            }
            return s;
        });

        this.setState({
            showModal: false,
            symptoms: notPresentSymptomEntries.concat(symptoms.filter(s => s.type !== 'NO_SYMPTOMS'))
        });
    }

    addSymptom = (symptom) => {
        let notPresentSymptom = !this.state.symptoms.find(s => (s.type === symptom.type && s.type != "NO_SYMPTOMS")) && (symptom.type != "NO_SYMPTOMS");
        let notPresentSymptomEntries = [];
        if (notPresentSymptom) {
            notPresentSymptomEntries = [{...symptom, strength: 1, shouldResetSelection: true, selected: true}];
        }
        let symptoms = this.state.symptoms.map(s => {
            if (s.type === symptom.type) {
                return {...s, strength: 1, selected: true, shouldResetSelection: true};
            }
            return s;
        });
        this.setState({
            showModal: false,
            symptoms: notPresentSymptomEntries.concat(symptoms.filter(s => s.type !== 'NO_SYMPTOMS'))
        });
    }

    onSearchClick = (symptom) => {
        this.addSymptom(symptom)
    }


    /**
     * A handler for each change that occurrs in the child components, including checking/unchecking the checkboxes,
     * selecting/deselecting the radio buttons etc
     *
     * @param {object} symptom - the symptoms whose values for strength, selected and shouldResetSelection must change
     */
    onChange = (symptom) => {
        let s;
        /* If the 'No symptoms' checkbox is checked we iterate all the symptoms and
        for each selected one we reset the flags and the strength value. If the strength of the symptom isn't equal to 0,
        this means that the symptom is selected and if found in the list we only update the strength of the choosen one.
        Also, a check is done to see if 'No symptoms' were previously selected. If this is the case, we deselect it */

        s = this.state.symptoms.map(s => {
            if (s.type === symptom.type) {
                s = {...s, strength: symptom.strength, selected: symptom.strength !== 0, shouldResetSelection: false};
            } else if ((s.type === 'NO_SYMPTOMS' && s.selected) || (symptom.type === 'NO_SYMPTOMS' && s.selected)) {
                s = {...s, strength: 0, selected: false, shouldResetSelection: false};
            }
            return s;
        });
        this.setState({symptoms: s});
    }

    /**
     * Form submit handler, validate data and set error in state if any. Call the appropriate action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        const formErrors = this.validate();
        this.setState({errors: formErrors});
        evt.preventDefault();
        if (this.state.formclass !== 'was-validated') {
            this.setState({formclass: 'was-validated'});
        }

        if (Object.keys(formErrors).length) {
            this.scrollTo();
            return;
        }

        if (evt.target.checkValidity() === true) {
            this.logSymptoms(evt);
        }
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        if (isNaN(this.state.date) || isNaN(this.state.time)) {
            this.scrollTo();
        }

        let d = new Date(this.getDateTime());


        if (!this.state.date || this.state.dateMissing) {
            errors.date = 'invalid_date_format';
        }

        if (!this.state.time || this.state.timeMissing) {
            errors.time = 'invalid_time_format';
        }

        if (d && d > moment().valueOf()) {
            errors.date_time = 'log_symptoms_form_date_time_not_correct_message';
        }

        if (this.state.dateTimeMissing) {
            errors.date_time = 'datetime_required_message';
        }

        return errors;
    }

    /**
     * Scrolls to top when an error happened in the form inputs
     */
    scrollTo = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    /**
     * Prepares the main symptom object and the symptoms array to be sent to the server
     *
     * @param {object} evt - the event that triggered the symptoms log
     */
    logSymptoms = (evt) => {
        evt.persist();
        let id = this.state.id ? this.state.id : uuid();

        let selectedSymptoms = this.prepareSelectedSymptoms();
        let createdTimestamp = this.state.created_timestamp !== 0 ? this.state.created_timestamp : moment().valueOf();

        this.setState({
            created_timestamp: createdTimestamp,
            updated_timestamp: moment().valueOf(),
            id: id,
            date_time: moment(this.getDateTime()).valueOf(),
            symptoms: selectedSymptoms
        }, function () {
            const body = [];
            // eslint-disable-next-line no-unused-vars
            const {formclass, showModal, errors, selectedHealthIssues, ...data} = this.state;
            body.push(data);
            this.props.logSelectedUserSymptoms(this.props.userId, body);
            this.props.showSymptomsList(evt);
        });
    }

    /**
     * Iterates the list of symptoms and for each selected one prepares the symptom object with the appropriate properties
     *
     * @returns {Array} the list of symptoms objects
     */
    prepareSelectedSymptoms = () => {
        let s = this.state.symptoms.filter(s => s.selected === true);

        if (s.length === 0) {
            s.push({
                id: uuid(),
                strength: 1,
                symptom_entry_id: uuid(),
                type: 'NO_SYMPTOMS'
            });
        } else {
            s = s.map(s => {
                return {
                    ...s,
                    description: s.description,
                    id: uuid(),
                    strength: s.strength,
                    symptom_entry_id: uuid(),
                    type: s.type
                }
            });
        }
        return s;
    }

    getLocale = () => {
        switch (this.props.i18n.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            case "sq":
                return sq
            default:
                return enGB
        }
    }


    render() {
        this.getHeader = () => {
            return <div className="row header-with-search">
                <span className="col-md-6 col-sm-12">{$$('log_symptoms_label')}</span>
            </div>
        }
        return (
            <CenteredModal
                title={this.getHeader()}
                show={true}
                onHide={this.props.showSymptomsList}
                onConfirm={this.onSubmit}
                size="lg"
                dialogClassName={"symptom-dialog"}
                confirmBtnLabel={$$('save_btn_label')}>
                <div className="w-100 log-form-padding">
                    <div className='row'>
                        <div className='col-xs-12 col-md-12'>
                            <FormWithLoading
                                formDisabled={this.props.formDisabled}
                                currentForm={SYMPTOMS}
                                marginTop="30%"
                                marginLeft="40%"
                                onSubmit={this.onSubmit}
                                className={this.state.formclass}
                                noValidate={true}>

                                <CenteredModal title={$$('add_symptom_label')}
                                               show={this.state.showModal}
                                               onHide={() => this.setState({showModal: false})}
                                               onConfirm={this.onConfirmModal}
                                               confirmBtnLabel={$$('OK')}
                                               cancelBtnLabel={$$('cancel_btn')}
                                               modalBodyStyle='modal-body-style'>
                                    <SymptomsTreeView
                                        symptoms={getTreeViewSymptoms(this.props.i18n.lang)}
                                        onSymptomSelected={this.onSymptomSelected}/>
                                </CenteredModal>
                                <br/>
                                <label>{$$("choose_symptom")}</label>
                                <SearchForSymptoms
                                    filterUsers={this.props.filterIssuesByName}
                                    placeholder={$$('search_for_symptom')}
                                    tree={getTreeViewSymptoms(this.props.i18n.lang)}
                                    onClick={this.onSearchClick}
                                />
                                <br/>
                                <label>{$$("selected_symptoms")}</label>
                                <div className='row register-control'>
                                    <div className='col-sm-12'>
                                        <SymptomStrengthPairs
                                            i18n={this.props.i18n}
                                            symptoms={this.state.symptoms}
                                            nomenclature={this.props.nomenclature.data.nomenclature}
                                            onChange={this.onChange}
                                            selectedSymptoms={this.props.selectedSymptoms}/>

                                    </div>
                                </div>
                                <div className='row register-control'>
                                    <div className='col-sm-6'>
                                        <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                variant="inline"
                                                format={this.props.settings.dateFormat}
                                                margin="normal"
                                                id="date-picker-inline"
                                                label={$$("log_symptoms_date_label")}
                                                value={this.state.date}
                                                onChange={this.onDateChange}
                                                KeyboardButtonProps={{'aria-label': 'change date',}}
                                                required
                                            />
                                        </MuiPickersUtilsProvider>
                                        <div
                                            className={this.state.errors.date ? 'custom-invalid-feedback' : 'invalid-feedback'}
                                            style={{'textAlignLast': 'center'}}>
                                            {this.state.errors.date ? $$(this.state.errors.date) : $$('log_symptoms_form_date_time_required_message')}
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                ampm={!this.props.settings.time24hour}
                                                margin="normal"
                                                id="time-picker"
                                                okLabel={$$("OK")}
                                                cancelLabel={$$("cancel_btn")}
                                                label={$$("log_symptoms_time_label")}
                                                value={this.state.time}
                                                onChange={this.onTimeChange}
                                                KeyboardButtonProps={{'aria-label': 'change time',}}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <div
                                            className={this.state.errors.time ? 'custom-invalid-feedback' : 'invalid-feedback'}
                                            style={{'textAlignLast': 'center'}}>
                                            {this.state.errors.time ? $$(this.state.errors.time) : $$('log_symptoms_form_date_time_required_message')}
                                        </div>
                                    </div>
                                    <div
                                        className={this.state.errors.date_time ? 'custom-invalid-feedback last-center-text' : 'invalid-feedback last-center-text'}
                                    >
                                        {this.state.errors.date_time ? $$(this.state.errors.date_time) : $$('log_symptoms_form_date_time_required_message')}
                                    </div>
                                </div>
                                <br/>
                                <label>{$$("health_issues_label")}</label>
                                <CustomMultiselect
                                    options={getHealthIssueOptions(this.props.healthIssues.entries)}
                                    selectedValues={this.state.selectedHealthIssues}
                                    onSelect={this.onSelect}
                                    onRemove={this.onRemove}
                                    displayValue="text"
                                    placeholder={$$('select_health_issue_label')}
                                    closeIcon='cancel'
                                    avoidHighlightFirstOption={true}
                                    isFieldValid={this.state.validLanguagesField}
                                    formClass={this.state.formclass}/>
                                <br/>
                                <div className='row register-control log-notes-count log-notes-count'>
                                    <div className='col-sm-12'>
                                        <label>{$$('log_symptoms_notes_label')}</label>
                                        <textarea className='form-control  my-1 mr-sm-2'
                                                  value={this.state.notes}
                                                  maxLength="500"
                                                  placeholder={$$("add_notes")}
                                                  onChange={this.onNotesChange}
                                                  name='notes'></textarea>
                                    </div>
                                </div>
                            </FormWithLoading>
                        </div>
                    </div>
                </div>
            </CenteredModal>
        )
    }
}

SymptomsLogForm.propTypes = {
    created_timestamp: PropTypes.number,
    date_time: PropTypes.number,
    fetchSelectedUserHealthIssues: PropTypes.func,
    formDisabled: PropTypes.object,
    getHealthIssueList: PropTypes.func,
    getLatestSymptomsTypes: PropTypes.func,
    healthIssues: PropTypes.object,
    health_issues_ids: PropTypes.array,
    i18n: PropTypes.object,
    id: PropTypes.string,
    latestSymptomsTypes: PropTypes.object,
    logSelectedUserSymptoms: PropTypes.func,
    nomenclature: PropTypes.object,
    notes: PropTypes.string,
    selectedEntry: PropTypes.object,
    selectedSymptoms: PropTypes.array,
    showSymptomsList: PropTypes.func,
    filterIssuesByName: PropTypes.func,
    timezone: PropTypes.string,
    userId: PropTypes.string,
    settings: PropTypes.any
};

const mapStateToProps = (state) => ({
    i18n: state.language.selected,
    latestSymptomsTypes: state.latestSymptomsTypes,
    formDisabled: state.formInteractions,
    settings: state.settings.data

})

export default connect(mapStateToProps, {logSelectedUserSymptoms, getLatestSymptomsTypes})(SymptomsLogForm)

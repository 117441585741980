import React, {Component, createRef} from 'react';

class BaseAnswer extends Component {
    inputRef;

    constructor(props) {
        super(props);
        this.inputRef = createRef();
        this.language = props.language;
        this.state = {
            open: false,
            result: props.answer.result || '',
        };
    }

    deselectAnswer() {
        const answer = this.props.answer;
        answer.checked = false;
        answer.result = null;
        this.props.updateAnswer(answer);
    }

    selectAnswer() {
        const answer = this.props.answer;
        answer.checked = true;
        this.props.updateAnswer(answer);
    }

    onCancelButtonPressed = () => {
        const currentResult = this.props.answer.result;
        if (!currentResult) {
            this.setState({open: false});
            return;
        }
        const newResult = this.state.result;
        if (newResult !== currentResult) {
            this.setState({result: currentResult});
        }
        this.setState({open: false});
    };

    onInputChange = (event) => {
        const open = event.target.checked;
        if (open) {
            this.setState({open});
            return;
        }
        this.deselectAnswer();
        this.setState({result: ''});
    };

    getSuffix(answer) {
        if (answer.result) {
            if (answer.display) {
                return answer.display.replace("{}", `- ${answer.result}`);
            }
            return `- ${answer.result}`;
        }

        if (answer.hint) {
            return `- ${answer.hint[this.language]}`;
        }

        return ''

        //return answer.result ? `- ${answer.result}` : '';
    };
}

export default BaseAnswer;

import {
    ACCEPT_CHILD,
    ACCEPT_CHILD_ERROR, ADD_PARENT, ADD_PARENT_ERROR,
    CHILD, CLEAR_CONNECTED_PROFILE_RESPONSE,
    CLEAR_PARENT_COUNT,
    CREATE_CHILD_ERROR,
    CREATE_CHILD_SUCCESS,
    DELETE_CHILD,
    DELETE_CHILD_ERROR,
    DELETE_PARENT,
    DELETE_PARENT_ERROR,
    DENY_CHILD,
    DENY_CHILD_ERROR, DUMMY,
    GET_CHILDREN,
    GET_CHILDREN_ERROR,
    GET_PARENT_COUNT,
    GET_PARENT_COUNT_ERROR,
    GET_PARENT_LIST,
    GET_PARENT_LIST_ERROR,
    SET_FORM_TO_DISABLED,
    SET_FORM_TO_ENABLED
} from "./actions";
import {childrenService} from "../service/children_service";
import {getUserInfo, selectUser} from './users_actions'

/**
 * Get children of the logged user
 *
 * @returns {function} dispatch function
 */
export function getChildren() {
    return (dispatch) => {
        childrenService.getChildren()
            .then((res) => {
                if (res) {
                    dispatch({ type: GET_CHILDREN, payload: res });}
            })
            .catch((err) => {
                dispatch({ type: GET_CHILDREN_ERROR, result: err });
            })
    }
}

/**
 * Get Parents for the given child ID
 *
 * @returns {function} dispatch function
 */
export function getParentList(childId) {
    return(dispatch) => {
        childrenService.getParentList(childId)
            .then((res) => {
                if (res){
                    dispatch({type: GET_PARENT_LIST, payload: res})
                }
            })
            .catch((err) => {
                dispatch({ type: GET_PARENT_LIST_ERROR, result: err });
            })
    }
}

/**
 * Get Parents count
 *
 * @returns {function} dispatch function
 */
export function getParentCount(childId) {
    return(dispatch) => {
        childrenService.getParentList(childId)
            .then((res) => {
                if (res){
                    dispatch({type: GET_PARENT_COUNT, childId:childId, payload: res})
                }
            })
            .catch((err) => {
                dispatch({ type: GET_PARENT_COUNT_ERROR, result: err });
            })
    }
}

/**
 * Clear the parent count
 *
 * @returns {function} dispatch function
 */
export function clearParentCount() {
    return(dispatch) => {
        dispatch({type: CLEAR_PARENT_COUNT})
    }
}

/**
 * Approve a child by passing a child ID and corresponding pin
 *
 * @returns {function} dispatch function
 */
export function approveChildWithPin(childId, pin) {
    return(dispatch) => {
        childrenService.approveChildWithPin(childId, pin)
            .then((res) => {
                if (res){
                    dispatch({type: ACCEPT_CHILD, response: true})
                    dispatch(getChildren());
                }
            })
            .catch((err) => {
                dispatch({ type: ACCEPT_CHILD_ERROR, response: false });
            })
    }
}

/**
 * Approve a child by passing a child ID and corresponding pin
 *
 * @returns {function} dispatch function
 */
export function addParentByEmail(childId, body) {
    return(dispatch) => {
        childrenService.addParentByEmail(childId, body)
            .then((res) => {
                if (res){
                    dispatch({type: ADD_PARENT, action: {status:200}})
                    dispatch(getParentList(childId));
                }
            })
            .catch((err) => {
                dispatch({ type: ADD_PARENT_ERROR, action: err });
            })
    }
}

export function clearConnectedProfileResponse(){
    return(dispatch) => {
        dispatch({type: CLEAR_CONNECTED_PROFILE_RESPONSE})
    }
}

/**
 * Deny an unaccepted child
 *
 * @returns {function} dispatch function
 */
export function denyChild(childId) {
    return(dispatch) => {
        childrenService.denyChild(childId)
            .then((res) => {
                dispatch({type: DENY_CHILD, response: true})
                dispatch(getChildren());
            })
            .catch((err) => {
                dispatch({ type: DENY_CHILD_ERROR, response: false });
            })
    }
}

/**
 * Remove a connected Child
 *
 * @returns {function} dispatch function
 */
export function deleteChild(childId) {
    return(dispatch) => {
        childrenService.deleteChild(childId)
            .then((res) => {
               dispatch({type: DELETE_CHILD, response: res})
               dispatch(getChildren());
            })
            .catch((err) => {
                dispatch({ type: DELETE_CHILD_ERROR, response: err });
            })
    }
}

/**
 * Remove a connected Parent
 *
 * @returns {function} dispatch function
 */
export function deleteParent(childId, parentID) {
    return(dispatch, getState) => {
        childrenService.deleteParent(childId, parentID)
            .then((res) => {
                dispatch({type: DELETE_PARENT, response: res})
                dispatch(getParentList(childId))
                dispatch(getChildren());

                if (getState().userInfo.data.id === parentID) {
                    dispatch(getUserInfo(true))
                }

            })
            .catch((err) => {
                dispatch({ type: DELETE_PARENT_ERROR, response: err });
            })
    }
}

/**
 * Create a new child, call the service and dispatch the appropiate reducer method 
 *
 * @param {object} user - The user data to persist
 * @returns {function} dispatch function
 */
export function createChild(user) {
    return (dispatch) => {
        dispatch({ type: SET_FORM_TO_DISABLED, formName : CHILD });
        childrenService.createChild(user)
            .then(res => {
                if (res.id) {
                    dispatch({type: SET_FORM_TO_ENABLED});
                    dispatch({ type: CREATE_CHILD_SUCCESS, response: res });
                    dispatch(selectUser(res, true));
                }
                dispatch(getChildren());
            })
            .catch((error) => {
                dispatch({ type: SET_FORM_TO_ENABLED });
                dispatch({ type: CREATE_CHILD_ERROR, response: error });
            });
    }
}
import {
    CLEAR_ICD,
    LOGOUT,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    SEARCH_ICD_ERROR,
    SEARCH_ICD_REQUEST_SENT,
    SEARCH_ICD_SUCCESS,
    SELECT_USER,
} from '../actions/actions'
import {requestStatus} from './requests_reducers';

/**
 * ICD search reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const icdInitialState = {entries: [], isLastPage: false, request: requestStatus(undefined, {}), data: {}}

export function icd(state = icdInitialState, action) {
    switch (action.type) {
        case SEARCH_ICD_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case SEARCH_ICD_SUCCESS: {
            return {
                ...state,
                entries: [...getFormattedIcds(action.result)],
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case SEARCH_ICD_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case CLEAR_ICD: {
            return {...state, ...icdInitialState}
        }
        case SELECT_USER:
        case LOGOUT: {
            return {...state, ...icdInitialState};
        }
        default: {
            return state;
        }
    }
}

function getFormattedIcds(actionResult) {
    let formattedList = [];
    let parentLabel = "";
    actionResult.map(
        (entry) => {
            let result = getLeaf(parentLabel, entry)
            if (result && result !== [])
                formattedList.push(result)
        }
    )

    let merged = [].concat.apply([], formattedList);
    let remerged = [].concat.apply([], merged);
    return remerged;
}

function getLeaf(parentLabel, entry) {
    if (parentLabel.includes("-")) {
        parentLabel = ""
    }

    if (entry.isLeaf) {
        return {
            "id": entry.id,
            "label": parentLabel + entry.id + " " + entry.label
        };
    } else {
        let miniList = []
        parentLabel += entry.id + " " + entry.label + "\n"
        entry.children.map((entry) => {
            let miniListResult = getLeaf(parentLabel, entry)
            if (miniListResult !== null)
                miniList.push(miniListResult)
        })
        return miniList;
    }
}

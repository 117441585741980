import React, {Component} from 'react'
import {connect} from 'react-redux'
import BarChart from './BarChart'
import {Chart} from 'react-chartjs-2'
import {PLUGINS} from '../../utils/chartsPlugins'
import LineChart from "./LineChart";
import {CONVERTER} from "../../utils/converter";
import PropTypes from "prop-types";

class LandingPageCharts extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * Register charts plugins
     */
    componentDidMount() {
        this.props.fetchChartsData(this.props.userId)
        //Used to clear the previous labels before the chart is drawn
        this.clearClassificationLabels = {
            id: "clearClassificationLabels",
            // eslint-disable-next-line no-unused-vars
            beforeDraw: function (chart, easing) {
                let ctx = chart.chart.ctx;
                let width = chart.chart.width;
                let height = chart.chart.height;
                ctx.restore();
                if (this.props.chartsDataClassificationChart.ranges && chart.config.options.shouldDrawRectangleLabels) {
                    ctx.clearRect(0, 0, width, height);
                }
                ctx.save();
            }.bind(this)
        }

        //Used to register plugin for drawing the labels for the classification rectangles
        this.classificationLabelsPlugin = {
            id: "classificationLabelsPlugin",
            // eslint-disable-next-line no-unused-vars
            afterDraw: function (chart, easing) {
                if (this.props.chartsDataClassificationChart.ranges && chart.config.options.shouldDrawRectangleLabels) {
                    let ctx = chart.chart.ctx;
                    let width = chart.chart.width;
                    let xScale = chart.scales['x-axis-1'];
                    let yScale = chart.scales['y-axis-1'];
                    const labelStartX = width > 1000 ? 5 : 5;
                    ctx.restore();
                    ctx.textAlign = "left";
                    ctx.textBaseline = "middle";
                    this.props.chartsDataClassificationChart.ranges.forEach(r => {
                        ctx.fillText(r.label, xScale.getPixelForValue(labelStartX), yScale.getPixelForValue(r.y2 + 5));
                    });
                    ctx.save();
                }
            }.bind(this)
        }

        this.resizeChartFont = PLUGINS.resizeChartFont();

        //Used to register plugin for drawing the labels on top of the bar charts.
        this.barChartBoundaryLabelsPlugin = PLUGINS.barChartBoundaryLabelsPlugin();

        Chart.pluginService.register(this.clearClassificationLabels);
        Chart.pluginService.register(this.resizeChartFont);
        Chart.pluginService.register(this.classificationLabelsPlugin);
        Chart.pluginService.register(this.barChartBoundaryLabelsPlugin);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.userId !== prevProps.userId){
            this.props.fetchChartsData(this.props.userId)
        }
    }

    componentWillUnmount() {
        Chart.pluginService.unregister(this.clearClassificationLabels);
        Chart.pluginService.unregister(this.classificationLabelsPlugin);
        Chart.pluginService.unregister(this.resizeChartFont);
        Chart.pluginService.unregister(this.barChartBoundaryLabelsPlugin);
        this.props.clearChartsData();
    }

    render() {
        return (
            <div className="landing-page-charts-container">
                <div className="high-shadow-container landing-page-charts-item landing-container ">
                    <BarChart small={true} chartData={this.props.chartsDataAveragesPerDayInterval}
                              settings={this.props.settings.data}/>
                </div>
                <div className="high-shadow-container landing-page-charts-item landing-container ">
                    <BarChart small={true} chartData={this.props.chartsDataAveragesPerHour}
                              settings={this.props.settings.data}/>
                </div>
                <div className="high-shadow-container landing-page-charts-item landing-container ">
                    <BarChart small={true} chartData={this.props.chartsDataAveragesPerDayOfWeek}
                              settings={this.props.settings.data}/>
                </div>
                <div className="high-shadow-container landing-page-charts-item landing-container ">
                    <LineChart small={true} chartData={this.props.chartsDataBPTimeline}
                               settings={this.props.settings.data}/>
                </div>
                <div className="high-shadow-container landing-page-charts-item landing-container ">
                    <LineChart small={true} chartData={this.props.chartsDataTemperatureTimeline}
                               yAxesRange={CONVERTER.getFormatedTemperatureYAxes()}
                               settings={this.props.settings.data}/>
                </div>
                <div className="high-shadow-container landing-page-charts-item landing-container ">
                    <BarChart small={true} chartData={this.props.chartsDataHydrationAveragesPerHour}
                              yAxesRange={CONVERTER.getFormatedHydrationYAxes()} settings={this.props.settings.data}
                              hideLowDataLabel={true}/>
                </div>
                <div className="high-shadow-container landing-page-charts-item landing-container ">
                    <LineChart small={true} chartData={this.props.chartsDataHydrationTimeline}
                               yAxesRange={CONVERTER.getFormatedHydrationYAxes()} settings={this.props.settings.data}/>
                </div>
                <div className="high-shadow-container landing-page-charts-item landing-container ">
                    <LineChart small={true} chartData={this.props.chartsDataSaturationTimeline}
                               yAxesRange={CONVERTER.getFormatedSaturationYAxes()} settings={this.props.settings.data}/>
                </div>
                <div className="high-shadow-container landing-page-charts-item landing-container">
                    <LineChart small={true} chartData={this.props.chartsDataRespiratoryRateTimeline}
                               yAxesRange={CONVERTER.getFormatedRespiratoryRateYAxes()}
                               settings={this.props.settings.data}/>
                </div>
            </div>
        )
    }
}

LandingPageCharts.propTypes = {
    chartsDataAveragesPerDayInterval: PropTypes.object,
    chartsDataAveragesPerDayOfWeek: PropTypes.object,
    chartsDataAveragesPerHour: PropTypes.object,
    chartsDataBPTimeline: PropTypes.object,
    chartsDataCholesterolTimeline: PropTypes.object,
    chartsDataClassificationChart: PropTypes.object,
    chartsDataHydrationAveragesPerHour: PropTypes.object,
    chartsDataHydrationTimeline: PropTypes.object,
    chartsDataRespiratoryRateTimeline: PropTypes.object,
    chartsDataSaturationTimeline: PropTypes.object,
    chartsDataTemperatureTimeline: PropTypes.object,
    chartsDataWeightTimeline: PropTypes.object,
    dispatch: PropTypes.func,
    settings: PropTypes.object,
    userId: PropTypes.string,
    fetchChartsData: PropTypes.func,
    clearChartsData: PropTypes.func
}

const mapStateToProps = (state) => ({
    chartsDataClassificationChart: state.charts.chartsData.chartsDataClassificationChart,
    chartsDataAveragesPerDayInterval: state.charts.chartsData.chartsDataAveragesPerDayInterval,
    chartsDataAveragesPerHour: state.charts.chartsData.chartsDataAveragesPerHour,
    chartsDataAveragesPerDayOfWeek: state.charts.chartsData.chartsDataAveragesPerDayOfWeek,
    chartsDataBPTimeline: state.charts.chartsData.chartsDataBPTimeline,
    chartsDataWeightTimeline: state.charts.chartsData.chartsDataWeightTimeline,
    chartsDataCholesterolTimeline: state.charts.chartsData.chartsDataCholesterolTimeline,
    chartsDataHydrationAveragesPerHour: state.charts.chartsData.chartsDataHydrationAveragesPerHour,
    chartsDataTemperatureTimeline: state.charts.chartsData.chartsDataTemperatureTimeline,
    chartsDataHydrationTimeline: state.charts.chartsData.chartsDataHydrationTimeline,
    chartsDataSaturationTimeline: state.charts.chartsData.chartsDataSaturationTimeline,
    chartsDataRespiratoryRateTimeline: state.charts.chartsData.chartsDataRespiratoryRateTimeline,
    settings: state.settings
})

export default connect(mapStateToProps)(LandingPageCharts)

import {
    CLEAR_PROVIDERS,
    FETCH_FIRST_AVAILABLE_ERROR,
    FETCH_FIRST_AVAILABLE_REQUEST_SENT,
    FETCH_FIRST_AVAILABLE_SUCCESS,
    FETCH_HOSPITALS_ERROR,
    FETCH_HOSPITALS_REQUEST_SENT,
    FETCH_HOSPITALS_SUCCESS,
    FETCH_PROVIDER_ERROR,
    FETCH_PROVIDER_REQUEST_SENT,
    FETCH_PROVIDER_SUCCESS,
    FETCH_PROVIDER_TIMETABLE_ERROR,
    FETCH_PROVIDER_TIMETABLE_REQUEST_SENT,
    FETCH_PROVIDER_TIMETABLE_SUCCESS,
    FETCH_PROVIDERS_ERROR,
    FETCH_PROVIDERS_REQUEST_SENT,
    FETCH_PROVIDERS_SUCCESS,
    FETCH_SPECIALTIES_COUNT_ERROR,
    FETCH_SPECIALTIES_COUNT_REQUEST_SENT,
    FETCH_SPECIALTIES_COUNT_SUCCESS,
    FETCH_SPECIALTIES_ERROR,
    FETCH_SPECIALTIES_REQUEST_SENT,
    FETCH_SPECIALTIES_SUCCESS,
    LOGOUT,
    NO_MORE_ENTRIES,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    SELECT_USER
} from '../actions/actions'
import {requestStatus} from './requests_reducers';

/**
 * Health issues reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const providersInitialState = {entries: [], isLastPage: false, request: requestStatus(undefined, {}), data: {}}

export function providers(state = providersInitialState, action) {
    switch (action.type) {
        case FETCH_PROVIDERS_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case FETCH_PROVIDERS_SUCCESS: {
            return {
                ...state,
                entries: [...action.result],
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case FETCH_PROVIDERS_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, isLastPage, ...rest} = state;
            return {...rest, entries:[], request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case NO_MORE_ENTRIES: {
            return {...state, isLastPage: true}
        }
        case CLEAR_PROVIDERS:
        case LOGOUT: {
            return {...state, ...providersInitialState};
        }
        default: {
            return state;
        }
    }
}

const hospitalsInitialState =
    {
        hospitals: [],
        request: requestStatus(undefined, {})
    }

export function hospitals(state = hospitalsInitialState, action) {

    switch (action.type) {
        case FETCH_HOSPITALS_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case FETCH_HOSPITALS_SUCCESS: {
            return {...state, hospitals: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_HOSPITALS_ERROR: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        default: {
            return state;
        }
    }
}


/**
 * Specialties reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the specialties
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const specialtiesInitialState = {
    specialties: [],
    request: requestStatus(undefined, {}),
}

export function specialties(state = specialtiesInitialState, action) {
    switch (action.type) {
        case FETCH_SPECIALTIES_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case FETCH_SPECIALTIES_SUCCESS: {
            return {
                ...state,
                specialties: action.result,
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case FETCH_SPECIALTIES_ERROR: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case FETCH_SPECIALTIES_COUNT_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case FETCH_SPECIALTIES_COUNT_SUCCESS: {
            return {
                ...state,
                specialties: action.result,
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case FETCH_SPECIALTIES_COUNT_ERROR: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case LOGOUT: {
            return {...state, ...specialtiesInitialState};
        }
        default: {
            return state;
        }
    }
}

const providerInitialState = {isLastPage: false, request: requestStatus(undefined, {}), data: {}}

export function provider(state = providerInitialState, action) {
    switch (action.type) {
        case FETCH_PROVIDER_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case FETCH_PROVIDER_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_PROVIDER_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case LOGOUT: {
            return {...state, ...providerInitialState};
        }
        default: {
            return state;
        }
    }
}

const firstHourInitialState = {isLastPage: false, request: requestStatus(undefined, {}), data: {}}

export function firstAvailable(state = firstHourInitialState, action) {
    switch (action.type) {
        case FETCH_FIRST_AVAILABLE_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, data, ...rest} = state;
            return {...rest, data: {}, request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case FETCH_FIRST_AVAILABLE_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_FIRST_AVAILABLE_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, data: {}, isLastPage, ...rest} = state;
            return {...rest, data: {}, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case SELECT_USER:
        case LOGOUT: {
            return {...state, ...firstHourInitialState};
        }
        default: {
            return state;
        }
    }
}

const providerTimetableInitialState = {isLastPage: false, request: requestStatus(undefined, {}), entries: []}

export function providerTimetable(state = providerTimetableInitialState, action) {
    switch (action.type) {
        case FETCH_PROVIDER_TIMETABLE_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, entries, ...rest} = state;
            return {...rest, entries: [], request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case FETCH_PROVIDER_TIMETABLE_SUCCESS: {
            return {...state, entries: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_PROVIDER_TIMETABLE_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, isLastPage, ...rest} = state;
            return {...rest, entries: [], request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case SELECT_USER:
        case LOGOUT: {
            return {...state, ...providerTimetableInitialState};
        }
        default: {
            return state;
        }
    }
}
import React, {Component} from 'react'
import {DocumentEntry} from './DocumentEntry'
import {$$} from '../../helpers/localization'
import {infoUtils} from '../../utils/infoUtils'
import no_appointments from '../../resources/images/no_appointments.png'
import PropTypes from "prop-types";
import {dateTimeUtils} from "../../utils/dateTimeUtils";

export class GroupedDocuments extends Component {
    state = {
        selectedDocument: null,
        selectedId: 1,
        filteredDocuments: this.props.documents,

    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.filterDocuments();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.searchValue !== this.props.searchValue) || (prevProps.pickedSelectOption !== this.props.pickedSelectOption)) {
            this.filterDocuments();
        }
        if (prevProps.documents !== this.props.documents) {
            this.filterDocuments();
        }
    }


    filterBySelection = () => {
        return this.props.documents.filter(entry => {
            let hasHealthIssue = false;
            if (this.props.pickedSelectOption === undefined || this.props.pickedSelectOption === 0 || this.props.pickedSelectOption === '0')
                return true;
            if (entry.health_issues_ids != null) {
                entry.health_issues_ids.map((h) => {
                    if (this.props.pickedSelectOption === undefined)
                        hasHealthIssue = true;
                    if (h === this.props.pickedSelectOption) {
                        hasHealthIssue = true;
                    }
                })
            }

            return hasHealthIssue;
        })
    }


    filterByDocument = (arrayToFilter) => {

        return arrayToFilter.filter(u => {
            if (this.props.searchValue === undefined) {
                return true;
            }
            let hasDocument = false;

            if (!u.title) hasDocument = false
            else if (u.title.toLowerCase().indexOf(this.props.searchValue.toLowerCase()) !== -1) {
                hasDocument = true;
            }

            if (u.filename.toLowerCase().indexOf(this.props.searchValue.toLowerCase()) !== -1) {
                hasDocument = true;
            }

            return hasDocument;
        })
    }


    filterDocuments = () => {
        let filteredBySelection = this.filterBySelection();
        let filteredByDocument = this.filterByDocument(filteredBySelection);

        this.setState({
            filteredDocuments: filteredByDocument
        })
        if (filteredByDocument.length > 0) {
            if (this.props.hasContent !== true)
                this.props.onHasContent(true);
        }
    }

    /**
     * Selects the first document just not to be null , when compared with the other documents
     * @param {object} document
     */
    selectFirst = (document) => {
        if (this.state.selectedId === 1) {
            this.setState({
                selectedDocument : document,
                selectedId : 2
            })
        }
    }

    /**
     * An event handler triggered when a new document is chosen
     * from the list of documents
     *
     * @param {object} document - the document object
     */
    onDocumentChange = (document) => {
        this.setState({
            selectedDocument: document
        });
    }

    getGroupedDocuments = () => {
        let noDocuments = {
            imgClass: 'no-entry-image',
            objClass: 'no-data-object-documents',
            primaryLabelClass: 'no-hospitals-primary-label',
            secondaryLabelClass: '',
            src: no_appointments,
            primaryLabel: $$('documents_no_data_for_filters_label'),
        }
        if (this.props.documents && this.props.documents.length > 0 && this.state.filteredDocuments.length > 0) {
            return this.state.filteredDocuments.map((entry, idx) => {
                this.selectFirst(entry);
                return <DocumentEntry key={idx}
                                      index={idx}
                                      entry={entry}
                                      showModal={this.props.showModal}
                                      onShowEditModal={this.props.showAddUpdateModal}
                                      onShowDeletedModal={this.props.showDeletedConfirmationModal}
                                      selectedUser={this.props.selectedUser}
                                      fetchSelectedUserHealthIssues={this.props.fetchSelectedUserHealthIssues}
                                      healthIssues={this.props.healthIssues}
                                      onDocumentChange={this.onDocumentChange}
                                      selectedDocument={this.state.selectedDocument}
                                      dateTime={dateTimeUtils.getFormattedDate(entry.date_time)}/>
            });
        } else {
            return infoUtils.noData(noDocuments)
        }
    }

    render() {
        return (
            <div className="card-body" style={{"padding": "0"}}>
                {this.getGroupedDocuments()}
            </div>
        )
    }
}

GroupedDocuments.propTypes = {
    documents: PropTypes.array,
    fetchSelectedUserHealthIssues: PropTypes.func,
    hasContent: PropTypes.bool,
    healthIssues: PropTypes.object,
    i18n: PropTypes.object,
    onHasContent: PropTypes.func,
    pickedSelectOption: PropTypes.any,
    searchValue: PropTypes.string,
    selectedUser: PropTypes.object,
    showAddUpdateModal: PropTypes.func,
    showDeletedConfirmationModal: PropTypes.func,
    showModal:PropTypes.func
}

export default GroupedDocuments

export const MEDICATION = 'MEDICATION';
export const BLOOD_PRESSURE = 'BLOOD_PRESSURE';
export const CHOLESTEROL = 'CHOLESTEROL';
export const WEIGHT = 'WEIGHT';
export const TEMPERATURE = 'TEMPERATURE';
export const HYDRATION = 'HYDRATION';
export const RESPIRATORY_RATE = 'RESPIRATORY_RATE';
export const SATURATION = 'SATURATION';
export const HEIGHT = 'HEIGHT';
export const MENSTRUATION = 'MENSTRUATION';
export const URINE_PH = 'URINE_PH';
export const KETONES = 'KETONES';
export const BLOOD_SUGAR = 'BLOOD_SUGAR';
export const HBA1C = 'HBA1C';
export const PEAK_FLOW = 'PEAK_FLOW';
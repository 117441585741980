export const PERIOD = {
    WEEK: 'WEEK',
    FORTNIGHT: 'FORTNIGHT',
    MONTH: 'MONTH',
    QUARTER: 'QUARTER'
};

export const CHAT_VIEWPOSITION = {
    INITIAL: 'INITIAL',
    ONLY_CHAT: 'ONLY_CHAT',
    FULL: 'FULL'
}
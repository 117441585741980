import moment from 'moment'
import store from '../store'
import { $$ } from '../helpers/localization'
import { WEIGHT, HYDRATION, TEMPERATURE, HEIGHT } from '../constants/entry_types';

export const CONVERTER = {
    cholesterolPerUnit,
    convertByUnit,
    convertToMetric,
    formatDate,
    getSelectedDateTimeFormat,
    getEntryDateTimeFormat,
    getDayPeriodFromTime,
    getFormatedCholesterolYAxes,
    getFormatedWeightYAxes,
    getFormatedHydrationYAxes,
    getFormatedTemperatureYAxes,
    getFormatedRespiratoryRateYAxes,
    getFormatedSaturationYAxes,
    getFormattedCholesterolUnit,
    getUnitByType,
    getBloodGroupByType,
    getSeverityForDisplay,
    getAllergyNameForDisplay,
    ketonesPerUnit,
    getFormatedRespiratoryRate,
    getFormattedKetonesUnit,
    bloodSugarPerUnit,
    getFormattedBloodSugarUnit,
    hba1cPerUnit,
    getFormattedHBA1CUnit,
    cholesterolInDefault,
    ketonesInDefault,
    bloodSugarInDefault,
    hba1cToDefault,
    getUnitValuefromKey,
    decimalHexTwosComplement,
    hexTwosComplementDecimal,
    getDateFNSDateTimeFormat,
    formatTime
};

/**
 * Convert cholesterol value to unit selected in settings
 * 
 * @param {number} value the value to convert
 * @returns {number} the converted value
 */
export function cholesterolPerUnit(value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.cholesterolUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return roundToTwo(value);
        } else if (selectedUnit === 'MG_DL') {
            return roundToTwo(value * 38.67);
        }
    }
}

/**
 * Convert ketones value to unit selected in settings
 * 
 * @param {number} value the value to convert
 * @returns {number} the converted value
 */
export function ketonesPerUnit(value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.ketonesUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return roundToTwo(value);
        } else if (selectedUnit === 'MG_DL') {
            return roundToTwo(value * 5.808);
        }
    }
}

/**
 * Convert blood sugar value to unit selected in settings
 * 
 * @param {number} value the value to convert
 * @returns {number} the converted value
 */
export function bloodSugarPerUnit(value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.glucoseUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return roundToTwo(value);
        } else if (selectedUnit === 'MG_DL') {
            return roundToTwo(value * 18);
        }
    }
}

/**
 * Convert hba1c value to unit selected in settings
 * 
 * @param {number} value the value to convert
 * @returns {number} the converted value
 */
export function hba1cPerUnit(value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.hba1cUnits;
        if (!selectedUnit || selectedUnit === 'PERCENT') {
            return roundToTwo(value);
        } else if (selectedUnit === 'MMOL_MOL') {
            return roundToTwo((value - 2.15) / 0.0915);
        }
    }
}

/**
 * Convert the given value based on the entry type and the selected unit
 * 
 * @param {string} entryType the type of the entry to convert
 * @param {number} value the value to convert
 * @returns {number} the converted value
 */
export function convertByUnit(entryType, value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.unitsOfMeasurement;
        if (!selectedUnit || selectedUnit === 'METRIC') {
            return roundToTwo(value);
        } else if (selectedUnit === 'US') {
            switch (entryType) {
                case WEIGHT: {
                    return roundToTwo(value * 2.2046);
                }
                case HYDRATION: {
                    return roundToTwo(value * 0.03381402);
                }
                case TEMPERATURE: {
                    return roundToTwo((value * 1.8) + 32);
                }
                case HEIGHT: {
                    return roundToTwo(value / 2.54);
                }
                default: {
                    return value;
                }
            }
        }
    }
}

export function convertToMetric(entryType, value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.unitsOfMeasurement;
        if (!selectedUnit || selectedUnit === 'METRIC') {
            return value;
        } else if (selectedUnit === 'US') {
            switch (entryType) {
                case WEIGHT: {
                    return value / 2.2046;
                }
                case HEIGHT: {
                    return value * 2.54;
                }
                case TEMPERATURE: {
                    return (value - 32) * 5 / 9;
                }
                case HYDRATION: {
                    return (value * 29.574);
                }
                default: {
                    return value;
                }
            }
        }
    }
}

export function cholesterolInDefault(value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.cholesterolUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return value;
        } else if (selectedUnit === 'MG_DL') {
            return value / 38.67;
        }
    }
}

export function ketonesInDefault(value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.ketonesUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return value;
        } else if (selectedUnit === 'MG_DL') {
            return value / 5.808;
        }
    }
}


export function bloodSugarInDefault(value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.glucoseUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return value;
        } else if (selectedUnit === 'MG_DL') {
            return value / 18;
        }
    }
}

export function hba1cToDefault(value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.hba1cUnits;
        if (!selectedUnit || selectedUnit === 'PERCENT') {
            return value;
        } else if (selectedUnit === 'MMOL_MOL') {
            return 0.0915 * value + 2.15;
        }
    }
}

/**
 * Get the formated cholesterol unit
 * 
 * @returns {string} formated unit
 */
export function getFormattedCholesterolUnit() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.cholesterolUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return $$('millimol_per_litre');
        } else if (selectedUnit === 'MG_DL') {
            return $$('milligram_per_decilitre');
        }
    }
}

/**
 * Get the formated ketones unit
 * 
 * @returns {string} formated unit
 */
export function getFormattedKetonesUnit() {
    //return $$('millimol_per_litre');
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.ketonesUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return $$('millimol_per_litre');
        } else if (selectedUnit === 'MG_DL') {
            return $$('milligram_per_decilitre');
        }
    }
}

/**
 * Get the formated respiratory rate unit
 * 
 * @returns {string} formated unit
 */
export function getFormatedRespiratoryRate() {
    return $$('bpm');
}

/**
 * Get the formated blood sugar unit
 * 
 * @returns {string} formated unit
 */
export function getFormattedBloodSugarUnit() {

    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.glucoseUnits
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return $$('millimol_per_litre');
        } else if (selectedUnit === 'MG_DL') {
            return $$('milligram_per_decilitre');
        }
    }
}

/**
 * Get the formated hba1c unit
 * 
 * @returns {string} formated unit
 */
export function getFormattedHBA1CUnit() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.hba1cUnits;
        if (!selectedUnit || selectedUnit === 'PERCENT') {
            return $$('percent');
        } else if (selectedUnit === 'MMOL_MOL') {
            return $$('millimol_per_mol');
        }
    }
}

/**
 * Get the formated unit for the entry
 * 
 * @param {string} entryType the entry type
 * @returns {string} formated unit
 */
export function getUnitByType(entryType) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.unitsOfMeasurement;
        if (!selectedUnit || selectedUnit === 'METRIC') {
            switch (entryType) {
                case WEIGHT: {
                    return $$('kg');
                }
                case HYDRATION: {
                    return $$('ml');
                }
                case TEMPERATURE: {
                    return $$('celsius');
                }
                case HEIGHT: {
                    return $$('cm');
                }
                default: {
                    return selectedUnit;
                }
            }
        } else if (selectedUnit === 'US') {
            switch (entryType) {
                case WEIGHT: {
                    return $$('pound');
                }
                case HYDRATION: {
                    return $$('fl_oz');
                }
                case TEMPERATURE: {
                    return $$('fahrenheit');
                }
                case HEIGHT: {
                    return $$('inches');
                }
                default: {
                    return selectedUnit;
                }
            }
        }
    }
}

/**
 * Format date with the format selected in settings
 * 
 * @param {moment.MomentInput} date to format
 * @param {boolean} hideTime flag used to hide time
 * @returns {string} formatted date
 */
export function formatDate(date, hideTime) {
    if (store.getState().settings.data) {
        return moment(date).format(getSelectedDateTimeFormat(hideTime));
    }
}

/**
 * Return time portion formatted according to user settings.
 * @param date
 * @return {string}
 */
export function formatTime(date) {
    return moment(date).format(store.getState().settings.data.time24hour ? "HH:mm" : "h:mm a");
}

/**
 * Get the format of date time from the settings for use with date-fns /MUI components/
 *
 * @param {boolean} hideTime flag used to hide time
 * @returns {string} the format to use
 */
export function getDateFNSDateTimeFormat(hideTime) {
    const dateFormat = store.getState().settings.data.dateFormat;
    if (dateFormat) {
        if (hideTime) {
            return dateFormat;
        } else {
            const timeFormat = store.getState().settings.data.time24hour ? "HH:mm" : "h:mm a";
            return dateFormat + ", " + timeFormat;
        }
    }
}

/**
 * Get the format of date time from the settings
 * 
 * @param {boolean} hideTime flag used to hide time
 * @returns {string} the format to use
 */
export function getSelectedDateTimeFormat(hideTime) {
    const dateFormat = store.getState().settings.data.dateFormat;
    if (dateFormat) {
        if (hideTime) {
            return dateFormat.toUpperCase();
        } else {
            const timeFormat = store.getState().settings.data.time24hour ? "HH:mm" : "h:mm a";
            return dateFormat.toUpperCase() + ", " + timeFormat;
        }
    }
}

/**
 * Format the date time for the user entries
 *
 * @param {Date} dateTime date time of the entries to format
 * @param showDayOfTheWeek boolean if set - display also the day of the week before the time.
 * @returns {string} the formated date time
 */
export function getEntryDateTimeFormat(dateTime, showDayOfTheWeek) {
    if (dateTime) {

        let timeFormat =  store.getState().language.selected.lang === "bg" ? "HH:mm" : "h:mm a";

        if(store.getState().settings.data.user_id){
            timeFormat = store.getState().settings.data.time24hour ? "HH:mm" : "h:mm a";
        }

        const format = showDayOfTheWeek ? `dddd, ${timeFormat}` : timeFormat;
        return moment(dateTime).locale(store.getState().language.selected.lang).format(format);
    }
}

/**
 * Get the day period based on the time
 * 
 * @param {Date} dateTime date time of the entries to format
 * @returns {string} day period, morning, afternoon, evening
 */
export function getDayPeriodFromTime(dateTime) {
    const hour = moment(dateTime).format('HH');
    if (!hour) {
        return moment(dateTime).locale(store.getState().language.selected.lang).format('dddd');
    }
    const splitAfternoon = 12;
    const splitEvening = 17;
    if (hour >= splitAfternoon && hour <= splitEvening) {
        return $$('afternoon');
    } else if (hour >= splitEvening) {
        return $$('evening');
    }
    return $$('morning');
}

/**
 * Rounds the given number to with precision of two decimals
 * 
 * @param {number} num the number to round 
 * @returns {number} the rounded number
 */
function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

/**
 * Gets the y axes for the cholesterol charts, depending on the settings chosen
 * 
 * @returns {object} the boundaries for the axes
 */
export function getFormatedCholesterolYAxes() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.cholesterolUnits;
        if (selectedUnit && selectedUnit === 'MMOL_L') {
            return { min: 0, max: 18, step: 1 };
        } else if (selectedUnit === 'MG_DL') {
            return { min: 30, max: 240, step: 30 };
        }
    }
}

/**
 * Gets the y axes for the weight charts, depending on the settings chosen
 * 
 * @returns {object} the boundaries for the axes
 */
export function getFormatedWeightYAxes() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.unitsOfMeasurement;
        if (selectedUnit && selectedUnit === 'METRIC') {
            return { min: 0, max: 180, step: 30 };
        } else if (selectedUnit === 'US') {
            return { min: 60, max: 360, step: 60 };
        }
    }
}

/**
 * Gets the y axes for the hydration charts, depending on the settings chosen
 * 
 * @returns {object} the boundaries for the axes
 */
export function getFormatedHydrationYAxes() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.unitsOfMeasurement;
        if (selectedUnit && selectedUnit === 'METRIC') {
            return { min: 0, max: 2100, step: 300 };
        } else if (selectedUnit === 'US') {
            return { min: 0, max: 70, step: 10 };
        }
    }
}

/**
 * Gets the y axes for the temperature charts, depending on the settings chosen
 * 
 * @returns {object} the boundaries for the axes
 */
export function getFormatedTemperatureYAxes() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.unitsOfMeasurement;
        if (selectedUnit && selectedUnit === 'METRIC') {
            return { min: 35, max: 42, step: 0.5 };
        } else if (selectedUnit === 'US') {
            return { min: 95, max: 110, step: 5 };
        }
    }
}

/**
 * Gets the y axes for the respiratory chart
 * 
 * @returns {object} the boundaries for the axes
 */
export function getFormatedRespiratoryRateYAxes() {
    return { min: 0, max: 100, step: 5 };
}

/**
 * Gets the y axes for the saturation chart
 * 
 * @returns {object} the boundaries for the axes
 */
export function getFormatedSaturationYAxes() {
    return { min: 70, max: 110, step: 5 };
}

/**
 * Return blood group e.g. (A+, A-, AB+...) as string from Medical Profile blood_type value
 * @param bloodType as it comes from the medical profile
 */
export function getBloodGroupByType(bloodType) {
    switch (bloodType) {
        case "A_RhD_P": return "A+";
        case "A_RhD_N": return "A-";
        case "B_RhD_P": return "B+";
        case "B_RhD_N": return "B-";
        case "O_RhD_P": return "0+";
        case "O_RhD_N": return "0-";
        case "AB_RhD_P": return "AB+";
        case "AB_RhD_N": return "AB-";
        default: return $$("None");
    }
}

/**
 * Return severity for display from the allergy severity int field
 * @param severity as it comes from the allergy
 */
export function getSeverityForDisplay(severity) {
    switch (severity) {
        case 0: return $$("mild");
        case 1: return $$("mild_to_moderate");
        case 2: return $$("moderate");
        case 3: return $$("moderate_to_severe");
        case 4: return $$("severe");
        case 5: return $$("life_threatening");
        default: "";
    }
}

/**
 * Return localized allergy name for display from the allergy type field
 * @param type as it comes from the allergy
 */
export function getAllergyNameForDisplay(type) {
    switch (type) {
        case "DRUG_ALLERGY": return $$("drug_allergy");
        case "DUST_ALLERGY": return $$("dust_allergy");
        case "FOOD_ALLERGY": return $$("food_allergy");
        case "INSECT_ALLERGY": return $$("insect_allergy");
        case "LATEX_ALLERGY": return $$("latex_allergy");
        case "MOLD_ALLERGY": return $$("mold_allergy");
        case "PET_ALLERGY": return $$("pet_allergy");
        case "POLLEN_ALLERGY": return $$("pollen_allergy");
        case "OTHER_ALLERGY": return $$("other_allergy");

        default: $$("None");
    }
}

/**
 * Return display value for given unit
 * @param unit medication unit
 * @param quantity medication dosage
 */
export function getUnitValuefromKey(unit, quantity) {
    let medicationUnit = unit === undefined || unit === null ? "" : unit ;
    let medicationQuantity = quantity === undefined || quantity === null ? 0 : quantity ;
    switch (medicationUnit.toUpperCase()) {
        case "AMPULE": return $$("medication.unit.label.ampule.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.ampule.display_short_plural")
                : $$("medication.unit.label.ampule.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.ampule.display_long_plural")
                : $$("medication.unit.label.ampule.display_long");
        case "APPLICATION": return $$("medication.unit.label.application.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.application.display_short_plural")
                : $$("medication.unit.label.application.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.application.display_long_plural")
                : $$("medication.unit.label.application.display_long");
        case "CAPSULE": return $$("medication.unit.label.capsule.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.capsule.display_short_plural")
                : $$("medication.unit.label.capsule.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.capsule.display_long_plural")
                : $$("medication.unit.label.capsule.display_long");
        case "DROP": return $$("medication.unit.label.drop.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.drop.display_short_plural")
                : $$("medication.unit.label.drop.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.drop.display_long_plural")
                : $$("medication.unit.label.drop.display_long");
        case "GRAM": return $$("medication.unit.label.gram.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.gram.display_short_plural")
                : $$("medication.unit.label.gram.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.gram.display_long_plural")
                : $$("medication.unit.label.gram.display_long");
        case "INJECTION": return $$("medication.unit.label.injection.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.injection.display_short_plural")
                : $$("medication.unit.label.injection.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.injection.display_long_plural")
                : $$("medication.unit.label.injection.display_long");
        case "MG": return $$("medication.unit.label.mg.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.mg.display_short_plural")
                : $$("medication.unit.label.mg.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.mg.display_long_plural")
                : $$("medication.unit.label.mg.display_long");
        case "ML": return $$("medication.unit.label.ml.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.ml.display_short_plural")
                : $$("medication.unit.label.ml.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.ml.display_long_plural")
                : $$("medication.unit.label.ml.display_long");
        case "PACKET": return $$("medication.unit.label.packet.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.packet.display_short_plural")
                : $$("medication.unit.label.packet.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.packet.display_long_plural")
                : $$("medication.unit.label.packet.display_long");
        case "PATCH": return $$("medication.unit.label.patch.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.patch.display_short_plural")
                : $$("medication.unit.label.patch.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.patch.display_long_plural")
                : $$("medication.unit.label.patch.display_long");
        case "PIECE": return $$("medication.unit.label.piece.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.piece.display_short_plural")
                : $$("medication.unit.label.piece.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.piece.display_long_plural")
                : $$("medication.unit.label.piece.display_long");
        case "TABLET": return $$("medication.unit.label.tablet.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.tablet.display_short_plural")
                : $$("medication.unit.label.tablet.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.tablet.display_long_plural")
                : $$("medication.unit.label.tablet.display_long");
        case "PUFF": return $$("medication.unit.label.puff.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.puff.display_short_plural")
                : $$("medication.unit.puff.tablet.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.puff.display_long_plural")
                : $$("medication.unit.label.puff.display_long");
        case "SPRAY": return $$("medication.unit.label.spray.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.spray.display_short_plural")
                : $$("medication.unit.spray.tablet.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.spray.display_long_plural")
                : $$("medication.unit.label.spray.display_long");
        case "SUPPOSITORY": return $$("medication.unit.label.suppository.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.suppository.display_short_plural")
                : $$("medication.unit.suppository.tablet.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.suppository.display_long_plural")
                : $$("medication.unit.label.suppository.display_long");
        case "TBSP": return $$("medication.unit.label.tbsp.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.tbsp.display_short_plural")
                : $$("medication.unit.label.tbsp.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.tbsp.display_long_plural")
                : $$("medication.unit.label.tbsp.display_long");
        case "TSP": return $$("medication.unit.label.tsp.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.tsp.display_short_plural")
                : $$("medication.unit.label.tsp.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.tsp.display_long_plural")
                : $$("medication.unit.label.tsp.display_long");
        case "UNIT": return $$("medication.unit.label.unit.display_short").length ?
            medicationQuantity === 0 || medicationQuantity > 1 ?
                    $$("medication.unit.label.unit.display_short_plural")
                    : $$("medication.unit.label.unit.display_short")
            : medicationQuantity === 0 || medicationQuantity > 1 ?
                $$("medication.unit.label.unit.display_long_plural")
                : $$("medication.unit.label.unit.display_long");

        default: $$("None");
    }
}


export function decimalHexTwosComplement(decimal){
    let hexadecimal;
    const size = 8;
    if (decimal === 0) {
        return "#ffffff"
    }
    if (decimal >= 0) {
        hexadecimal = decimal.toString();
        while ((hexadecimal.length % size) !== 0) {
            hexadecimal = "" + 0 + hexadecimal;
        }
        return hexadecimal;
    } else {
        hexadecimal = Math.abs(decimal).toString(16);
        while ((hexadecimal.length % size) !== 0) {
            hexadecimal = "" + 0 + hexadecimal;
        }
        let output = '';
        for (let i = 0; i < hexadecimal.length; i++) {
            output += (0x0F - parseInt(hexadecimal[i], 16)).toString(16);
        }
        output = (0x01 + parseInt(output, 16)).toString(16);
        output = "#" + output.substring(2);
        return output;
    }
}
export function hexTwosComplementDecimal(hex) {

    if (hex.toLowerCase() === "#ffffff") {
        return 0
    }

    hex = "FF" + hex.substring(1);
    if (hex.length % 2 !== 0) {
        hex = "0" + hex;
    }
    let num = parseInt(hex, 16);
    const maxVal = Math.pow(2, hex.length / 2 * 8);
    if (num > maxVal / 2 - 1) {
        num = num - maxVal
    }
    return num;
}


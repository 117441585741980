import {$$} from '../helpers/localization'
import moment from "moment";
import {INITIAL, REJECTED} from "../constants/appointment_payment_status";

export const appointmentUtils = {
    markClass,
    appointmentType,
    isOverdue,
    getAdjustedPayBefore,
    isAppointmentCanceled,
    isPaid,
    isFree,
    canPay,
    notOverridenLocation,
    displayLocation,
    shouldCheckWithServerForExistingAppointment
}

/**
 * Returns the class name of the mark
 *
 * @returns {string} the classname
 */
function markClass() {
    return 'dot';
}

/**
 * Returns the appointment type
 *
 * @returns {object} the appointment
 */
function appointmentType(appointment) {
    return appointment.name ? appointment.name : $$(appointment.encounter_type.toLowerCase())
}


function isAppointmentCanceled(appointmentStatus) {
    switch (appointmentStatus) {
        case 'CANCELED_RESPONSE_OVERDUE':
        case 'CANCELED_NOT_PAID':
        case 'CANCELED_BY_PATIENT':
        case 'CANCELED_BY_PROVIDER':
            return true;
        default:
            return false;
    }
}
/**
 * Checks whether the appointment is overdue
 * @param appointment
 * @return {boolean}
 */
function isOverdue(appointment) {
    let p = getAdjustedPayBefore(appointment)
    return moment(p).isBefore(new Date(), 'minute')
}

/**
 * Return either the pay_before_timestamp or the 30 mins before starts_at if pay_before_timestamp is 0 or missing
 * @param appointment
 * @return {moment.Moment}
 */
function getAdjustedPayBefore(appointment) {
    return !appointment.pay_before_timestamp ? moment(appointment.starts_at).add(-30, 'minute') : moment(appointment.pay_before_timestamp);
}

function isPaid(appointment) {
    return appointment.payment_status === 'COMPLETED'
}

function isFree(appointment) {
    return appointment.appointment_price.price_cents === 0;
}

function canPay(appointment) {
    let overdue = isOverdue(appointment) && appointment.appointment_price.location_type !== 'ON_SITE';
    return ((appointment.payment_status === INITIAL || appointment.payment_status === REJECTED)
        && (appointment.status === 'COMPLETED' || appointment.status === 'ACCEPTED') && appointment.appointment_price.price_cents > 0
        && appointment.appointment_price.currency === 'BGN')
    && !overdue
    && (appointment.appointment_price.location_type !== 'ON_SITE' || appointment.appointment_price.enable_payments_for_on_site_appointments)
}

function notOverridenLocation(appointment) {
    return !appointment.override_location_to_online;
}

function displayLocation(appointment) {
    if (appointment.override_location_to_online) {
        return $$("online");
    }
    return $$(appointment.appointment_price.location_type.toLowerCase())
}

/**
 * Returns true if the price is for regular appointment (not group and not text consultation)
 * @param appointmentPrice - price object
 */
function shouldCheckWithServerForExistingAppointment(appointmentPrice) {
    return appointmentPrice.encounter_price_type === 'REGULAR' && !appointmentPrice.private_price
}
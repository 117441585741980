import React, {Component} from 'react'
import {$$} from '../../helpers/localization';
import {SpeechButton} from './SpeechButton';
import PropTypes from "prop-types";


export class NoteSpeechToText extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notes: this.props.note,
            recording: false,
            preNote: this.props.note,
            text: "",
            edited: false,
            lang: this.getLanguage(),
            supported: false
        };
        this.notes = React.createRef();
        this.recognition = {};

        if (window.SpeechRecognition) {
            // eslint-disable-next-line no-undef
            this.recognition = new SpeechRecognition();
            this.state.supported = true;
        } else if (window.webkitSpeechRecognition) {
            // eslint-disable-next-line no-undef
            this.recognition = new webkitSpeechRecognition();
            this.state.supported = true;
        }
        this.recognition.continuous = true;
        this.recognition.interimResults = true;

        this.recognition.onresult = function (event) {
            if (event.results.length > 0) {
                var text = "";
                for (var i = 0; i < event.results.length; i++) {
                    text += event.results[i][0].transcript
                }
                this.props.onNoteSpeechChange(this.state.preNote + " " + text);
                this.setState({text: ""});
            }
        }.bind(this);

        this.recognition.onsoundstart = function () {
            this.setState({preNote: this.props.note});
        }.bind(this);
    }

    getLanguage() {
        return this.props.i18n.lang + "-" + this.props.i18n.key.toUpperCase();
    }

    componentDidMount() {
        if (this.state.supported) {
            this.recognition.abort();
        }
        if (this.state.recording) {
            this.state({recording: false});
        }
    }

    componentWillUnmount() {
        if (this.state.supported) {
            this.recognition.abort();
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-10">
                    <textarea
                        className="form-control"
                        rows="5"
                        value={this.props.note}
                        placeholder={$$('appointment_note_title')} name="note"
                        onChange={this.props.onInputChange} required
                        readOnly={this.state.recording}
                    />
                </div>
                &nbsp;
                {this.state.supported && <div className="col">
                    <SpeechButton title={this.state.recording ? "Stop Recording" : "Record"}
                                  recording={this.state.recording}
                                  onClick={() => {
                                      if (this.state.supported) {
                                          if (this.state.recording) {
                                              this.recognition.stop();
                                          } else {
                                              this.recognition.lang = this.getLanguage();
                                              this.recognition.start();
                                          }
                                          this.setState({recording: !this.state.recording});
                                      }
                                  }}/>
                </div>}

            </div>

        );
    }
}

NoteSpeechToText.propTypes = {
    i18n:  PropTypes.object,
    note: PropTypes.string,
    onInputChange: PropTypes.func,
    onNoteSpeechChange: PropTypes.func
};

export default NoteSpeechToText;
import {fetchHelper} from '../helpers/request_helper';
import {
    ADD_PARENT_BY_EMAIL,
    APPROVE_CHILD_WITH_PIN,
    CREATE_CHILD, DELETE_CHILD, DELETE_PARENT,
    DENY_CHILD,
    GET_CHILDREN_URL,
    GET_PARENT_LIST
} from '../constants/api_paths';

export const childrenService = {
    getChildren,
    getParentList,
    approveChildWithPin,
    denyChild,
    createChild,
    deleteChild,
    deleteParent,
    addParentByEmail
};

/**
 * Get the children of the logged user
 *
 * @returns {object} promise object
 */
export function getChildren() {
    return fetchHelper.callGet(GET_CHILDREN_URL);
}


/**
 * Approve a child by passing a child ID and corresponding pin
 *
 * @returns {object} promise object
 */
export function  approveChildWithPin(childId, pin) {
    return fetchHelper.callPost(APPROVE_CHILD_WITH_PIN.replace('{childId}', childId).replace('{pin}', pin),)
}

/**
 * Deny an unaccepted child
 *
 * @returns {object} promise object
 */
export function  denyChild(childId) {
    return fetchHelper.callPost(DENY_CHILD.replace('{childId}', childId),)
}

/**
 * Delete a child
 *
 * @returns {object} promise object
 */
export function  deleteChild(childId) {
    return fetchHelper.callDelete(DELETE_CHILD.replace('{childId}', childId),)
}

/**
 * Delete a parent
 *
 * @returns {object} promise object
 */
export function  deleteParent(childId, parentID) {
    return fetchHelper.callDelete(DELETE_PARENT.replace('{childId}', childId).replace('{parentId}', parentID),)
}

/**
 * Add a parent by email
 *
 * @returns {object} promise object
 */
export function  addParentByEmail(childId, body) {
    return fetchHelper.callPost(ADD_PARENT_BY_EMAIL.replace('{childId}', childId),body)
}

/**
 * Get the parents for the given childId
 *
 * @returns {object} promise object
 */
export function  getParentList(childId) {
    return  fetchHelper.callGet(GET_PARENT_LIST.replace('{childId}', childId),)
}

/**
 * Create a new child of the logged user
 *
 * @param {object} user the user to create
 * @returns {object} promise object
 */
export function createChild(user) {
    return fetchHelper.callPost(CREATE_CHILD, user);
}
import React, {Component} from 'react'
import {connect} from "react-redux";
import PropTypes from "prop-types";
import ChatUserListItem from "./ChatUserListItem";
import _ from "underscore";
import phoneHand from "../../../../public/media/chat/phone_hand.png";
import {$$} from "../../../helpers/localization";

class UsersList extends Component {

    state = {
        usersList: null,
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.getUsersList()
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.chat.new_message !== prevProps.chat.new_message) {
            this.props.updateUserListOnNewMessage(this.props.loggedInUser.id, this.props.chat.new_message);
            this.getUsersList()
        } else if (this.props.usersList !== prevProps.usersList || this.props.userToChatId !== prevProps.userToChatId) {
            this.getUsersList()
        } else if (this.props.smallMessages !== prevProps.smallMessages) {
            this.getUsersList()
        } else if (this.props.onlyNewMessages !== prevProps.onlyNewMessages) {
            this.getUsersList()
        } else if (this.props.i18n !== prevProps.i18n) {
            this.getUsersList()
        }

        if (this.props.usersList !== prevProps.usersList && this.props.userToChatId !== "") {
            let selectedUserInList = false;

            for (let i in this.props.usersList) {
                if (this.props.usersList[i].from_user_id === this.props.userToChatId || this.props.usersList[i].to_user_id === this.props.userToChatId) {
                    selectedUserInList = true
                }
            }

            if (!selectedUserInList) {
                const selectNewUser = _.throttle(this.selectFirstUserInList, 100);
                selectNewUser();
            }
        }
    }

    onItemClick = (user) => {
        let selectedUserId = user.from_user_id !== this.props.loggedInUser.id ? user.from_user_id : user.to_user_id
        let selectedUserName = user.from !== this.props.loggedInUser.fullname ? user.from : user.to
        let selectedUserEmail = user.fromEmail !== this.props.loggedInUser.email ? user.fromEmail : user.toEmail

        let userToChatWith = {
            email: selectedUserEmail,
            fullname: selectedUserName,
            id: selectedUserId
        }

        this.props.selectNewUser(userToChatWith);
    }

    selectFirstUserInList = () => {
        if (this.props.usersList[0]?.id && !this.props.smallMessages) {
            let selectedUserId = this.props.usersList[0].from_user_id !== this.props.loggedInUser.id ? this.props.usersList[0].from_user_id : this.props.usersList[0].to_user_id
            let selectedUserName = this.props.usersList[0].from !== this.props.loggedInUser.fullname ? this.props.usersList[0].from : this.props.usersList[0].to
            let selectedUserEmail = this.props.usersList[0].fromEmail !== this.props.loggedInUser.email ? this.props.usersList[0].fromEmail : this.props.usersList[0].toEmail

            let userToChatWith = {
                email: selectedUserEmail,
                fullname: selectedUserName,
                id: selectedUserId
            }

            this.props.selectNewUser(userToChatWith);
        }
    }

    isUserSelected = (tempUser) => {
        if (this.props.userToChatId == null || "") {
            this.selectFirstUserInList()
        }
        return tempUser === this.props.userToChatId
    }

    getUsersList = () => {
        let users = this.props.usersList?.map((m, idx) => {
            const tempUser = m.from_user_id === this.props.loggedInUser.id ? m.to_user_id : m.from_user_id;
            const isSent = m.from_user_id === this.props.loggedInUser.id;
            const userToChatId = isSent ? m.to_user_id : m.from_user_id;
            const unreadMessages = this.props.chat.unreadMessages.list.find(n => n.sender === userToChatId);

            return <ChatUserListItem userId={userToChatId} isActive={this.isUserSelected(tempUser)}
                                     isSent={isSent} message={m} key={idx} onItemClick={this.onItemClick}
                                     unreadMessages={unreadMessages}
                                     lang={this.props.i18n.lang}
            />
        })
        this.setState({usersList: users})
    }

    render() {
        return (
            <div className="scrollable-users">

                {this.state.usersList?.length === 0 && !this.props.onlyNewMessages &&
                    <div className={"centered-text"}>
                        <img height={"150px"} width={"150px"} src={phoneHand} alt="phone"/>
                        <br/>
                        <h4>{$$('no_users_after_filtering')}</h4>
                    </div>}

                {this.state.usersList?.length === 0 && this.props.onlyNewMessages &&
                    <div className={"centered-text"}>
                        <img height={"150px"} width={"150px"} src={phoneHand} alt="phone"/>
                        <h4>{$$('no_unread_messages')}</h4>
                    </div>}

                {this.state.usersList}
            </div>)
    }
}

UsersList.propTypes = {
    usersList: PropTypes.array,
    chat: PropTypes.object,
    userToChatId: PropTypes.string,
    smallMessages: PropTypes.bool,
    onlyNewMessages: PropTypes.bool,
    selectNewUser: PropTypes.func,
    updateUserListOnNewMessage: PropTypes.func,
    loggedInUser: PropTypes.object,
};

const mapStateToProps = (state) => ({
    i18n: state.language.selected,
    loggedInUser: state.userInfo.data,
    chat: state.chat
})

export default connect(mapStateToProps, null)(UsersList)

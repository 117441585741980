import React, {Component} from 'react'
import {Line} from 'react-chartjs-2'
import 'chartjs-plugin-annotation'
import 'chartjs-plugin-datalabels'
import $ from 'jquery';
import {CONVERTER} from '../../utils/converter';
import PropTypes from "prop-types";

export class LineChart extends Component {

    constructor(props) {
        super(props);
    }

    /**
     * Get array of box annotations for the background rectangles.
     *
     * @returns {array} array of box annotation objects
     */
    getBoxAnnotations = () => {
        return this.props.chartData.regions.map((r, i) => {
            return {
                type: 'box',
                drawTime: 'beforeDatasetsDraw',
                id: 'a-box-' + i,
                xScaleID: 'x-axis-0',
                yScaleID: 'y-axis-0',
                yMax: r.ymax,
                yMin: r.ymin,
                borderWidth: 0,
                borderColor: 'rgba(0, 0, 0, 0)',
                backgroundColor: r.color,
            }
        });
    }

    /**
     * Get array for the x-axis properties.
     *
     * @returns {array} array of x-axis properties
     */
    getXAxesOptions = () => {
        return this.props.chartData.xAxis.map(a => {
            return {
                type: 'time',
                offset: true,
                time: {
                    unit: a.time.unit,
                    tooltipFormat: CONVERTER.getSelectedDateTimeFormat(),
                    displayFormats: {
                        day: this.props.settings.dateFormat.toUpperCase(),
                    }
                },
                ticks: a.ticks,
                scaleLabel: {
                    display: true,
                    labelString: a.label
                },
            }
        });
    }

    /**
     * Get array for the y-axis properties.
     *
     * @returns {array} array of y-axis properties
     */
    getYAxesOptions = () => {
        let axes = this.props.chartData.yAxis.map(a => {
            return {
                ticks: {
                    max: this.props.yAxesRange ? this.props.yAxesRange.max : 210,
                    min: this.props.yAxesRange ? this.props.yAxesRange.min : 0,
                    stepSize: this.props.yAxesRange ? this.props.yAxesRange.step : 30
                },
                scaleLabel: {
                    display: true,
                    labelString: a.label
                },
                gridLines: {
                    borderDash: [8, 5]
                }
            }
        });

        if (axes.length === 2) {
            axes[0].position = 'left';
            axes[1].position = 'right';
        }
        return axes;
    }

    render() {
        if (!this.props.chartData.data) {
            return '';
        }

        const options = {
            legend: {
                display: false
            },
            scales: {
                yAxes: this.getYAxesOptions(),
                xAxes: this.getXAxesOptions()
            },
            responsive: true,
            maintainAspectRatio: false,
            elements: {
                line: {
                    fill: false,
                    tension: 0
                }
            },
            plugins: {
                datalabels: {
                    display: false,
                },
            },
            annotation: {
                annotations: this.getBoxAnnotations()
            }
        };

        const chartData = $.extend(true, {}, this.props.chartData.data);

        return (
            <div className={this.props.small ? "card-body-small chart-card " : "card-body chart-card"}>
                <div className="card-title chart-title">
                    {this.props.chartData.label}
                </div>
                <div className={this.props.small ? "chart-container-small" : "chart-container"}>
                    <Line data={chartData} options={options}/>
                </div>
            </div>
        )
    }
}

LineChart.propTypes = {
    chartData: PropTypes.object,
    hideLowDataLabel: PropTypes.bool,
    settings: PropTypes.object,
    small: PropTypes.bool,
    yAxesRange: PropTypes.object
}

export default LineChart

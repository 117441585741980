import React from "react";
import { connect } from "react-redux";
import * as builder from "../../ducks/builder";
import Brand from "../brand/Brand";
import PerfectScrollbar from "react-perfect-scrollbar";
import Menu from "./Menu";
import KTOffcanvas from "../../_assets/js/offcanvas";
import { selectUser } from '../../../actions/users_actions'
import _ from "underscore";
import objectPath from "object-path";
import KTToggle from "../../_assets/js/toggle";


class AsideLeft extends React.Component {
    asideOffCanvasRef = React.createRef();
    ktToggleRef = React.createRef();

    componentDidMount() {
        // eslint-disable-next-line

        const ktoffConvas = new KTOffcanvas(
            this.asideOffCanvasRef.current,
            this.props.menuCanvasOptions
        );
        new KTToggle(this.ktToggleRef.current, this.props.toggleOptions);
    }

    render() {
        return (
            <>
                <button className="kt-aside-close" id="kt_aside_close_btn">
                    <i className="la la-close" />
                </button>
                <div
                    id="kt_aside"
                    ref={this.asideOffCanvasRef}
                    className={`kt-aside ${this.props.asideClassesFromConfig} kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop`}
                >
                   <div
                        id="kt_aside_menu_wrapper"
                        className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
                    >
                        {this.props.asideSelfMinimizeToggle && (
                            <div className="kt-aside__brand-tools">
                                <button
                                    ref={this.ktToggleRef}
                                    className="kt-aside__brand-aside-toggler"
                                    id="kt_aside_toggler"
                                >
                                    <i className="fa fa-bars" />
                                </button>
                            </div>
                        )}

                        <Menu htmlClassService={this.props.htmlClassService} />

                    </div>
                    {/* <div style={{"verticalAlign": "sub"}}>
                        <Menu htmlClassService={this.props.htmlClassService} fixedButtons={true} />
                    </div> */}
                </div>
            </>
        );
    }
}

const mapStateToProps = store => ({
    disableAsideSelfDisplay:
        builder.selectors.getConfig(store, "aside.self.display") === false,
    disableScroll:
        builder.selectors.getConfig(store, "aside.menu.dropdown") === "true" ||
        false,
    asideClassesFromConfig: builder.selectors.getClasses(store, {
        path: "aside",
        toString: true
    }),
    menuCanvasOptions: {
        baseClass: "kt-aside",
        overlay: true,
        closeBy: "kt_aside_close_btn",
        toggleBy: {
            target: "kt_aside_mobile_toggler",
            state: "kt-header-mobile__toolbar-toggler--active"
        }
    },
    asideSelfMinimizeToggle: objectPath.get(
        store.builder.layoutConfig,
        "aside.self.minimize.toggle"
    ),
    toggleOptions: {
        target: "body",
        targetState: "kt-aside--minimize",
        togglerState: "kt-aside__brand-aside-toggler--active"
    },
    //userInfo: store.userInfo.data,
    selectedUser: store.selectedUser.data,
    i18n: store.language
});

export default connect(mapStateToProps/*, { selectUser }*/)(AsideLeft);

import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import moment from 'moment'
import ReactDOM from 'react-dom';
import CenteredModal from '../shared/CenteredModal';
import {documentsService} from '../../service/docments_service';
import {
    createDocument,
    deleteDocument,
    updateDocument
} from '../../actions/documents_actions';
import {downloadUtils} from '../../utils/downloadUtils';
import _ from 'underscore'
import GroupedDocuments from './GroupedDocuments';
import {connect} from 'react-redux'
import DocumentForm from './DocumentForm';
import no_data from '../../resources/images/no_data.png'
import {infoUtils} from '../../utils/infoUtils'
import PropTypes from "prop-types";


class Documents extends Component {
    state = {
        showModal: false,
        documentEntryId: null,
        documentName: '',
        confirmationModal: {
            show: false
        },
        deletedConfirmationModal: {
            show: false
        },
        addUpdateModal: {
            show: false
        },

        selectedEntry: null,
        selectedEntryToDelete: null,
        loadComplete: false

    }

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        this.props.fetchEntries(this.props.selectedUser.id).then(
            () => {
                this.setState({
                        loadComplete: true
                    }
                )
            });
    }


    componentWillUnmount() {
        this.props.clearUserDocuments();
    }


    /**
     * Confirm deletion and remove entry from list
     */
    onDeleteConfirmModal = () => {
        let deletedEntry = this.state.selectedEntryToDelete;
        deletedEntry.deleted = true;
        deletedEntry.updated_timestamp = moment().valueOf();
        this.props.deleteDocument(deletedEntry.id, this.props.selectedUser.id);

        this.setState({
            deletedConfirmationModal: {show: false},
            selectedEntryToDelete: '',
        });

    }


    /**
     * Hide the modal
     */
    onHideDeleteConfirmationModal = () => {
        this.setState({
            deletedConfirmationModal: {
                show: false
            },
            selectedEntryToDelete: ''
        });
    }

    /**
     * Hide the modal
     */
    onHideAddUpdateModal = () => {
        this.props.onHideUpdateModal();
        this.setState({
            addUpdateModal: {
                show: false
            },
            selectedEntry: null,
        });
    }

    /**
     * Show confirmation modal when trying to delete an entry
     *
     * @param {entry} key The entry to delete
     */
    showDeletedConfirmationModal = (key) => {
        this.setState({
            deletedConfirmationModal: {show: true},
            selectedEntryToDelete: key
        })
    }

    /**
     * Show add/update modal
     *
     * @param {entry} entry The entry to modify or null
     */
    showAddUpdateModal = (entry = null) => {
        if (entry) {
            this.setState({
                selectedEntry: entry,
                addUpdateModal: {show: true}
            })
            this.props.showUpdateModal();
        } else {
            this.setState({
                selectedEntry: null,
                addUpdateModal: {show: true}
            })
            this.props.showUpdateModal();
        }
    }

    /**
     * Clear selected entry from state
     */
    clearSelectedEntry = () => {
        this.setState({
            selectedEntry: null
        })
    }

    /**
     * On confirm modal, download the file.
     */
    onConfirmModal = () => {
        const fileName = this.state.documentName;
        documentsService.downloadFile(this.state.documentEntryId).then((file) => {
            downloadUtils.download(file, fileName);
        });
        this.setState({showModal: false, documentEntryId: null});
    }

    /**
     * Set state for the selected document entry when showing the modal
     */
    showModal = (documentEntryId, documentName) => {
        this.setState({showModal: true, documentEntryId: documentEntryId, documentName: documentName});
    }

    /**
     * Fetch selected user documents
     */
    onLoadMore = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let params = {
            before_date_time: moment().valueOf(),
            size: 500
        };
        if (this.props.selectedUser.id && (this.props.documents.request.finished === undefined || this.props.documents.request.finished)) {
            if (this.props.documents.entries && this.props.documents.entries.length > 0) {
                const entiresLength = this.props.documents.entries.length;
                params.before_date_time = this.props.documents.entries[entiresLength - 1].date_time;
            }
            this.props.fetchEntries(this.props.selectedUser.id, params);
        }
    }
    getNoDataObject = (label) => {
        return {
            imgClass: 'no-entry-image',
            objClass: 'no-data-object-documents',
            primaryLabelClass: 'no-hospitals-primary-label',
            src: no_data,
            primaryLabel: label,
        }
    }

    /**
     * Get the documents to show. Filter the documents based on the given input.
     *
     * @returns {HTMLElement} array of GroupedDocuments
     */
    getDocuments = () => {
        let noDocumentsForFilters = this.getNoDataObject($$('documents_no_data_for_filters_label'))
        let noDocuments = this.getNoDataObject($$('documents_no_data_label'))
        const filters = this.props.filters;
        if (this.props.documents && this.props.documents.entries && this.props.documents.entries.length > 0) {
            const filteredDocuments = this.props.documents.entries.filter(doc => {
                return (!filters.type || filters.type === doc.type) &&
                    (!filters.category || (doc.category && filters.category.toLowerCase() === doc.category.toLowerCase())) &&
                    (!filters.after_date_time || doc.date_time > filters.after_date_time)
            });
            const groupedByDate = _.groupBy(filteredDocuments, (result) => moment(result.date_time).format('YYYY-MM-DD'));
            const keys = Object.keys(groupedByDate);
            if (keys.length > 0) {
                return <GroupedDocuments
                    documents={filteredDocuments}
                    showModal={this.showModal}
                    showAddUpdateModal={this.showAddUpdateModal}
                    showDeletedConfirmationModal={this.showDeletedConfirmationModal}
                    i18n={this.props.i18n}
                    selectedUser={this.props.selectedUser}
                    fetchSelectedUserHealthIssues={this.props.fetchSelectedUserHealthIssues}
                    healthIssues={this.props.healthIssues}
                    searchValue={this.props.searchValue}
                    pickedSelectOption={this.props.pickedSelectOption}
                    hasFilteredDocs={this.hasFilteredDocs}
                    onHasContent={this.props.onHasContent}
                    hasContent={this.props.hasContent}
                />
            } else {
                return infoUtils.noData(noDocumentsForFilters)
            }
        } else if (this.state.loadComplete) {
            return infoUtils.noData(noDocuments)
        }
    }

    /**
     * If any of the documents has a type corresponding to the type filter , then we will display 'show more'
     * @returns Boolean value
     */
    hasDocResults = () => {
        let hasDocs = false;
        this.props.documents.entries.map(entry => {

            if (entry.type === this.props.filters.type || this.props.filters.type === '' || (entry.type !== this.props.filters.type && this.props.filters.type === 'OTHER')) {
                hasDocs = true;
            }
        });
        return hasDocs;
    }

    render() {
        let domNode = document.getElementById('new-symptom-btn');
        const hasMore = !this.props.documents.isLastPage && (this.props.documents.request.finished === undefined || this.props.documents.request.finished) && !this.props.documents.request.error;
        return (
            <div className="card documents-inner-card">
                {!this.state.loadComplete && <div className={"med-search-loader"}/>}
                {this.state.loadComplete && !this.state.addUpdateModal.show && <div className="card-body inner-card-body">
                    <div ref={(ref) => this.listRef = ref}>
                        <div className="list-group">
                            {this.getDocuments()}
                        </div>
                        {hasMore && this.hasDocResults() && this.props.hasContent &&
                        <div className="text-center" style={{"marginTop": "10px"}}>
                            <a href="#" onClick={this.onLoadMore}>{$$('load_more')}</a>
                        </div>
                        }
                    </div>
                </div>}
                {ReactDOM.createPortal(
                    <div>
                        <div className="blue-button-container" onClick={() => {
                            this.showAddUpdateModal(null)
                        }}>
                            <i className='flaticon2-plus'/>
                        </div>
                    </div>,
                    domNode
                )}
                <CenteredModal title={$$('document_download_dialog_title')}
                               show={this.state.showModal}
                               onHide={() => this.setState({showModal: false, documentEntryId: null})}
                               onConfirm={this.onConfirmModal}
                               confirmBtnLabel={$$('download_btn')}
                               cancelBtnLabel={$$('cancel_btn')}
                >
                    {$$('confirmation_dialog_message').replace('{fileName}', this.state.documentName).replace('{owner}', this.props.selectedUser.fullname)}
                </CenteredModal>
                <CenteredModal title={$$('mark_document_entry_deleted_modal_message')}
                               dialogClassName='doc-delete-modal'
                               show={this.state.deletedConfirmationModal.show}
                               onHide={() => this.setState({deletedConfirmationModal: {show: false}})}
                               onConfirm={this.onDeleteConfirmModal}
                               cancelBtnLabel={$$('cancel_btn')}
                               id="delete-modal-title"
                               className="center-delete-modal"
                               idFooter="footer-delete-modal"
                               confirmBtnLabel={$$('delete_label')}
                               confirmBtnClass="danger"
                               idBtnPrimary="btn-danger"
                               idBtnSecondary="btn-secondary"
                >
                    {this.state.selectedEntryToDelete ? this.state.selectedEntryToDelete.title : ''}<br/>
                    <div className="bin-align">
                        <i className="fas fa-trash-alt fa-3x"/>
                    </div>
                </CenteredModal>
                {this.state.addUpdateModal.show &&
                <DocumentForm
                    settings={this.props.settings}
                    show={this.state.addUpdateModal.show}
                    onHide={this.onHideAddUpdateModal}
                    i18n ={this.props.i18n}
                    selectedUser={this.props.selectedUser}
                    selectedEntry={this.state.selectedEntry}
                    createEntry={this.props.createDocument}
                    updateEntry={this.props.updateDocument}
                    clearSelectedEntry={this.clearSelectedEntry}
                    formDisabled={this.props.formDisabled}
                    fetchSelectedUserHealthIssues={this.props.fetchSelectedUserHealthIssues}
                    healthIssues={this.props.healthIssues}
                />}
            </div>
        )
    }
}

Documents.propTypes = {
    createDocument: PropTypes.func,
    deleteDocument: PropTypes.func,
    documents: PropTypes.object,
    fetchEntries: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    filters: PropTypes.object,
    formDisabled: PropTypes.object,
    hasContent: PropTypes.bool,
    healthIssues: PropTypes.object,
    hideFilters: PropTypes.func,
    i18n:PropTypes.object,
    onHasContent: PropTypes.func,
    onHideUpdateModal:  PropTypes.func,
    pickedSelectOption:  PropTypes.any,
    searchValue:  PropTypes.string,
    selectedHealthIssue:  PropTypes.object,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    showFilters:  PropTypes.func,
    showUpdateModal: PropTypes.func,
    clearUserDocuments: PropTypes.func,
    updateDocument:  PropTypes.func
}


function mapStateToProps(state) {
    return {
        formDisabled: state.formInteractions
    }
}

const mapDispatchToProps = {
    createDocument,
    updateDocument,
    deleteDocument
}

export default connect(mapStateToProps, mapDispatchToProps)(Documents)

import React, {useEffect, useState} from 'react'
import {Waypoint} from 'react-waypoint'
import {$$} from '../../helpers/localization'
import {
    addDays,
    addWeeks,
    format,
    isBefore,
    isSameDay,
    setDay,
    subDays,
    subWeeks,
    endOfWeek,
    startOfWeek
} from 'date-fns'
import {
    bg,
    enGB
} from 'date-fns/locale/index'
import {appointmentUtils} from '../../utils/appointmentUtils'
import _ from 'underscore'

const ID_FORMAT = 'yyyy MMMM dd'

let prevWidth;

/* eslint-disable react/prop-types */
export default React.memo(function ReactHorizontalDatePicker({
                                                                 enableDays,
                                                                 enableScroll,
                                                                 selectedDay,
                                                                 i18n,
                                                                 chosenDate,
                                                                 triggeredFromBtn,
                                                                 appointments,
                                                                 selectToday
                                                             }) {
    /* eslint-enable react/prop-types */
    let now = new Date();
    const [selectedDate, setSelectedDate] = useState(now);
    const [headingDate, setHeadingDate] = useState(now);
    const [currentWeek, setCurrentWeek] = useState(setDay(now, _.contains(['en'], i18n) ? 0 : 1));
    const [currentDate] = useState(now);
    const [week, setWeek] = useState(setDay(now, _.contains(['en'], i18n) ? 0 : 1));
    const [width, setWidth] = useState(window.innerWidth);

    prevWidth = width;

    enableScroll = enableScroll || false;
    enableDays = enableScroll === true ? enableDays || 90 : 7;

    useEffect(()=>{
        const scrollIntoView = () => {
            let element;
            if (prevWidth <= 1024 && window.innerWidth > 1024) { //means that the screen is getting wider
                let lastDayOfWeek = addDays(setDay(week, _.contains(['en'], i18n) ? 0 : 1), 6);
                let id = format(lastDayOfWeek, ID_FORMAT);
                element = document.getElementById(id);
            } else if (window.innerWidth <= 1024 && prevWidth > 1024) {
                let id = format(setDay(week, _.contains(['en'], i18n) ? 0 : 1), ID_FORMAT);
                element = document.getElementById(id);
            }
            setWidth(window.innerWidth);
            if (element) {
                element.scrollIntoView({behavior: 'instant', block: 'nearest', inline: 'nearest'});
            }
        }
        const updateWindowDimensions = () => {
            setTimeout(()=>{
                if (window.innerWidth !== prevWidth) {
                    scrollIntoView();
                }
            }, 300)
        }
        window.addEventListener('resize', updateWindowDimensions);
        scrollIntoView();
        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, [week, setWidth])

    useEffect(() => {
        if (!isSameDay(selectedDate, chosenDate) || triggeredFromBtn && !isSameDay(headingDate, now)) {
            onDateClick(new Date(chosenDate));
        }
    }, [chosenDate]);

    useEffect(() => {
        let firstDayCurrentWeek = setDay(now, _.contains(['en'], i18n) ? 0 : 1);
        let firstDayChosenWeek = setDay(week, _.contains(['en'], i18n) ? 0 : 1);

        if (!isSameDay(currentWeek, firstDayCurrentWeek)) {
            setCurrentWeek(firstDayCurrentWeek);
        }
        if (!isSameDay(week, firstDayChosenWeek)) {
            setWeek(firstDayChosenWeek);
        }
    }, [i18n]);

    const applyStyles = day => {
        const classes = [];
        if (isSameDay(day, selectedDate)) {
            classes.push(' date-day-item-selected');
        }

        if (isBefore(day, currentDate)) {
            classes.push(' date-day-item-disabled');
        }
        return classes.join(' ');
    };

    const _handlePosition = (pos, date) => {
        let currentPosition = pos.currentPosition;
        let previousPosition = pos.previousPosition;

        if (previousPosition === 'inside' && currentPosition === 'above') {
            setHeadingDate(date);
        }
        if (previousPosition === 'above' && currentPosition === 'inside') {
            setHeadingDate(addDays(date, -1));
        }
    };

    /**
     * Filters the appointments for each day, in order to put a mark on the days
     *
     * @param {Date} _formattedDate - the formatted date
     * @returns {Array} the list of filtered appointments
     */
    const getFilteredAppointments = (_formattedDate) => {
        return appointments &&
        // eslint-disable-next-line react/prop-types
        appointments.length > 0
            // eslint-disable-next-line react/prop-types
            ? appointments.filter(a => format(new Date(a.starts_at), 'yyyy-MM-dd') === _formattedDate)
            : [];
    }

    const _verticalList = () => {
        const _dayFormat = 'E';
        const _dateFormat = 'dd';
        const _verticalListItems = [];
        const _startDay = currentWeek;

        let shouldDisplay = false;

        for (let i = 0; i < enableDays; i++) {
            let _day = format(addDays(_startDay, i), _dayFormat, {locale: _.contains(['en', 'sq'], i18n) ? enGB : bg});
            let _date = format(addDays(_startDay, i), _dateFormat);

            let _formattedDate = format(new Date(addDays(_startDay, i)), 'yyyy-MM-dd');
            let appointmentsPerDate = getFilteredAppointments(_formattedDate);
            shouldDisplay = appointmentsPerDate.length > 0;

            _verticalListItems.push(
                <Waypoint
                    key={i}
                    horizontal={true}
                    onPositionChange={pos =>
                        parseInt(_date) === 1 ? _handlePosition(pos, addDays(_startDay, i)) : ''
                    }
                >
                    <div id={format(addDays(_startDay, i), ID_FORMAT)} className='wrapper'
                         style={{'marginRight': '0.3125rem', 'marginLeft': i === 0 ? '-0.3125rem' : '0rem'}}>
                        <div
                            className={`datepicker-date-day-item wrapper ${applyStyles(
                                addDays(_startDay, i)
                            )}`}
                            onClick={() => onDateClick(addDays(_startDay, i))}
                        >
                            <div className='datepicker-date-label  '>{_date}</div>
                            <div className='datepicker-day-label '>{_day.toUpperCase()}</div>

                        </div>
                        {shouldDisplay &&
                        <div className={appointmentUtils.markClass()}/>
                        }
                    </div>
                </Waypoint>
            );
        }

        return (
            <div
                id='container'
                className={
                    enableScroll === true
                        ? ' datepicker-datelist-scrollable datepicker-v-two'
                        : ' datepicker-dateList datepicker-v-two'
                }
            >
                {_verticalListItems}
            </div>
        );
    };

    const onDateClick = day => {
        if (isSameDay(day, new Date()) &&
            document.getElementById('container').scrollLeft !== 0 &&
            triggeredFromBtn) {
            document.getElementById('container').scrollLeft = 0;
            setWeek(setDay(now, _.contains(['en'], i18n) ? 0 : 1));
        }
        setSelectedDate(day);
        selectedDay(day);

        let week = endOfWeek(day, {weekStartsOn: _.contains(['en'], i18n) ? 0 : 1});
        let startWeek = startOfWeek(day, {weekStartsOn: _.contains(['en'], i18n) ? 0 : 1});
        let id = format(week, ID_FORMAT);
        let element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'auto', block: 'nearest', inline: 'nearest'});
            document.getElementById(format(subDays(week, 1), ID_FORMAT)).style.marginRight = '0rem';
            setWeek(startWeek);
            setHeadingDate(startWeek);
        }
    };

    const nextScroll = () => {
        let followingWeek = addWeeks(week, 1);
        let lastDayOfFollowingWeek = addDays(followingWeek, 6);
        let id = format(lastDayOfFollowingWeek, ID_FORMAT);
        let element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'});
            document.getElementById(format(subDays(followingWeek, 1), ID_FORMAT)).style.marginRight = '0rem';
            setWeek(followingWeek);
            setHeadingDate(followingWeek);
        }
    };

    const prevScroll = () => {
        let previousWeek = subWeeks(week, 1);
        let id = format(previousWeek, ID_FORMAT);
        let element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'});
            if (document.getElementById(format(subDays(previousWeek, 1), ID_FORMAT))) {
                document.getElementById(format(subDays(previousWeek, 1), ID_FORMAT)).style.marginRight = '0.3125rem';
            }
            document.getElementById(format(addDays(previousWeek, 6), ID_FORMAT)).style.marginRight = '0.3125rem';
            setWeek(previousWeek);
            setHeadingDate(previousWeek);
        }
    };

    return (
        <div className='datepicker-strip'>
            <div className='row space-between'>
                <div
                    className='scroll-head'>{format(week, 'MMMM yyyy', {locale: _.contains(['en', 'sq'], i18n) ? enGB : bg})}</div>
                <div className='today-btn'>
                    <button className='btn btn-secondary' onClick={selectToday}>
                        {$$('select_today_label')}
                    </button>
                </div>
            </div>
            <div className='datepicker'>
                <div className='wrapper'>
                    <div id='prev' className='button-previous btn-prev'>
                        {' '}
                        <button className='datepicker-button-previous' onClick={prevScroll}>
                            <i className='kt-menu__link-icon fas fa-angle-right'/>
                        </button>
                    </div>
                </div>
                {_verticalList()}
                <div className='wrapper'>
                    <div/>
                    <div id='next' className='button-previous btn-nxt'>
                        {' '}
                        <button className='datepicker-button-next' onClick={nextScroll}>
                            <i className='kt-menu__link-icon fas fa-angle-right'/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});
import React from 'react'
import {
    BLOOD_PRESSURE,
    BLOOD_SUGAR,
    CHOLESTEROL,
    HBA1C,
    HEIGHT,
    HYDRATION,
    KETONES,
    MEDICATION,
    MENSTRUATION, PEAK_FLOW,
    RESPIRATORY_RATE,
    SATURATION,
    TEMPERATURE,
    URINE_PH,
    WEIGHT
} from '../../constants/entry_types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faHamburger,
    faHeartbeat,
    faTemperatureLow,
    faTint,
    faWeight,
    faWind
} from '@fortawesome/free-solid-svg-icons'
import {ReactComponent as BloodSugarIcon} from '../../../public/media/icons/bloodsugar.svg'
import {ReactComponent as UrinepH} from '../../../public/media/icons/urine.svg'
import {ReactComponent as HeightIcon} from '../../../public/media/icons/height.svg'
import {ReactComponent as HBA1CIcon} from '../../../public/media/icons/hba1c.svg'
import {ReactComponent as KetonesIcon} from '../../../public/media/icons/ketones.svg'
import {ReactComponent as MenstrautionIcon} from '../../../public/media/icons/menstruation.svg'
import PropTypes from "prop-types";
import {PeakFlowIcon} from "./PeakFlowIcon";


export default function LogbookLogo(props) {
    let icon = "";
    switch (props.entry.entry_type) {
        case WEIGHT:
            icon = faWeight;
            break;
        case HYDRATION:
            icon = faTint;
            break;
        case TEMPERATURE:
            icon = faTemperatureLow;
            break;
        case BLOOD_SUGAR:
            return <div className="logbook-entry-logo">
                <BloodSugarIcon fill="#646c9a" className="logbook-entry-icon-v2 blood-sugar-icon" height="40px"
                                width="40px"/>
            </div>
        case URINE_PH:
            return <div className="logbook-entry-logo">
                <span className="logbook-entry-custom-icon logbook-entry-icon-v2"><UrinepH className="svg-icon"
                                                                                           fill="#646c9a"/></span>
            </div>
        case SATURATION:
            return <div className="logbook-entry-logo">
                <span className="logbook-entry-icon-o2 logbook-entry-icon-v2 "><b
                    style={{whiteSpace: "nowrap"}}>O<sub>2</sub></b></span>
            </div>
        case RESPIRATORY_RATE:
            icon = faWind;
            break;
        case HEIGHT:
            return <div className="logbook-entry-logo">
                <HeightIcon fill="#646c9a"/>
            </div>
        case KETONES:
            return <div className="logbook-entry-logo">
                <KetonesIcon fill="#646c9a" className="logbook-entry-icon-v2"/>
            </div>;
        case HBA1C:
            return <div className="logbook-entry-logo">
                <HBA1CIcon fill="#646c9a" className="logbook-entry-icon-v2"/>
            </div>;
        case MENSTRUATION:
            return <div className="logbook-entry-logo">
                <MenstrautionIcon fill="#646c9a" className="logbook-entry-icon-v2"/>
            </div>
        case CHOLESTEROL:
            return <div className="logbook-entry-logo">
                <FontAwesomeIcon icon={faHamburger} className="logbook-entry-icon-v2"/>
            </div>;
        case MEDICATION:
            return <div className="logbook-entry-logo">
                <i className="logbook-entry-icon-v2 flaticon2-contrast"/>
            </div>;
        case PEAK_FLOW:
            return <div className="logbook-entry-logo">
                <PeakFlowIcon className="logbook-entry-icon-v2"/>
            </div>;
        case BLOOD_PRESSURE:
            icon = faHeartbeat;
            break;
    }
    return <div className="logbook-entry-logo">
        <FontAwesomeIcon icon={icon} className="logbook-entry-icon-v2"/>
    </div>

}

LogbookLogo.propTypes = {
    entry: PropTypes.object
}
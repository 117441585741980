import {
    CHANGE_DOCUMENTS_FILTERS,
    CLEAR_DOC, CLEAR_USER_DOCUMENTS,
    CREATE_DOCUMENT_ENTRY_ERROR,
    CREATE_DOCUMENT_ENTRY_REQUEST_SENT,
    FETCH_LOGGED_USER_DOCUMENTS_ERROR,
    FETCH_LOGGED_USER_DOCUMENTS_REQUEST_SENT,
    FETCH_LOGGED_USER_DOCUMENTS_SUCCESS,
    FETCH_SELECTED_USER_DOCUMENTS_ERROR,
    FETCH_SELECTED_USER_DOCUMENTS_REQUEST_SENT,
    FETCH_SELECTED_USER_DOCUMENTS_SUCCESS,
    LOGOUT,
    NO_MORE_LOGGED_USER_DOCUMENTS,
    NO_MORE_SELECTED_USER_DOCUMENTS,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    RESET_LOGGED_USER_DOCUMENTS_SUCCESS,
    RESET_SELECTED_USER_DOCUMENTS_SUCCESS,
    SELECT_USER,
    UPDATE_DOCUMENT_ENTRY_ERROR,
    UPDATE_DOCUMENT_ENTRY_REQUEST_SENT
} from "../actions/actions";
import {requestStatus} from './requests_reducers';

/**
 * Documents entries reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the symptoms logs
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const documentsInitialState = {
    filters: {
        type: '',
        category: '',
        before_date_time: null,
        after_date_time: null,
    },
    selectedUser: {
        entries: [],
        isLastPage: false,
        request: requestStatus(undefined, {}),
    },
    loggedUser: {
        entries: [],
        isLastPage: false,
        request: requestStatus(undefined, {}),
    }
}

export function documents(state = documentsInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_DOCUMENTS_REQUEST_SENT: {
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    isLastPage: false,
                    request: requestStatus(state.request, {type: REQUEST_SENT})
                }
            }
        }
        case FETCH_SELECTED_USER_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                selectedUser: {
                    entries: [...state.selectedUser.entries, ...action.result],
                    request: requestStatus(state.request, {type: REQUEST_SUCCESS})
                }
            }
        }
        case FETCH_SELECTED_USER_DOCUMENTS_ERROR: {
            return {
                ...state,
                selectedUser: {
                    entries: documentsInitialState.selectedUser.entries,
                    isLastPage: documentsInitialState.selectedUser.isLastPage,
                    request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
                }
            }
        }
        case CREATE_DOCUMENT_ENTRY_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT}), type: "CREATE"}
        }
        case CREATE_DOCUMENT_ENTRY_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, isLastPage, ...rest} = state;
            return {
                ...rest,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result}),
                type: "CREATE"
            }
        }
        case UPDATE_DOCUMENT_ENTRY_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT}), type: "UPDATE"}
        }
        case UPDATE_DOCUMENT_ENTRY_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, isLastPage, ...rest} = state;
            return {
                ...rest,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result}),
                type: "UPDATE"
            }
        }
        case RESET_SELECTED_USER_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                selectedUser: {entries: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
            }
        }
        case CHANGE_DOCUMENTS_FILTERS: {
            return {
                ...state,
                filters: {
                    type: action.documentType,
                    category: action.category,
                    before_date_time: action.beforeDateTime,
                    after_date_time: action.afterDateTime
                }
            }
        }
        case NO_MORE_SELECTED_USER_DOCUMENTS: {
            return {...state, selectedUser: {...state.selectedUser, isLastPage: true}}
        }
        case FETCH_LOGGED_USER_DOCUMENTS_REQUEST_SENT: {
            return {
                ...state,
                loggedUser: {
                    ...state.loggedUser,
                    isLastPage: false,
                    request: requestStatus(state.request, {type: REQUEST_SENT})
                }
            }
        }
        case FETCH_LOGGED_USER_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                loggedUser: {
                    entries: [...state.loggedUser.entries, ...action.result],
                    request: requestStatus(state.request, {type: REQUEST_SUCCESS})
                }
            }
        }
        case FETCH_LOGGED_USER_DOCUMENTS_ERROR: {
            return {
                ...state,
                loggedUser: {
                    entries: documentsInitialState.loggedUser.entries,
                    isLastPage: documentsInitialState.loggedUser.isLastPage,
                    request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
                }
            }
        }
        case NO_MORE_LOGGED_USER_DOCUMENTS: {
            return {...state, loggedUser: {...state.loggedUser, isLastPage: true}}
        }
        case RESET_LOGGED_USER_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                loggedUser: {entries: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
            }
        }
        case SELECT_USER: {
            return {
                ...state,
                selectedUser: documentsInitialState.selectedUser,
                loggedUser: documentsInitialState.loggedUser
            };
        }
        case CLEAR_DOC: {
            return {...state, ...documentsInitialState};
        }
        case CLEAR_USER_DOCUMENTS:
        case LOGOUT: {
            return {...state, ...documentsInitialState};
        }
        default: {
            return state;
        }
    }
}
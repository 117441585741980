import React, {Component} from 'react'
import classnames from 'classnames'
import {$$} from '../../helpers/localization'
import {validators} from '../../helpers/validators'
import PermissionList from './PermissionList';
import no_data from '../../resources/images/no_data.png'
import PropTypes from "prop-types";


export class ShareData extends Component {
    state = {
        collapse: true,
        email: '',
        formclassName: '',
        errors: {}
    }

    constructor(props) {
        super(props);
    }


    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    /**
     * Form submit handler, validate data and set error in state if any. Call request permission action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        const formErrors = this.validate();
        this.setState({errors: formErrors});
        evt.preventDefault();
        if (this.state.formclassName !== "was-validated") {
            this.setState({formclassName: "was-validated"});
        }

        if (Object.keys(formErrors).length) {
            return;
        }

        if (evt.target.checkValidity() === true) {
            this.props.shareData(this.state.email);
        }
    }

    /**
     * Validate enetered form data..
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        if (this.state.email && !validators.validateEmail(this.state.email)) {
            errors.email = $$('register_form_email_not_correct_message');
        }
        return errors;
    }
    getNoData = (label) => {
        return <div style={{display: 'inline-flex', alignItems: 'center', width: '100%'}}>
            <div style={{marginLeft: '16%'}}><img style={{width: "9rem", height: " 9rem"}} src={no_data}
                                                  alt={"No Data"}/></div>
            <div><span className="no-data-sharing-label">{label}</span></div>
        </div>
    }

    toggleCollapse = () => {
        this.setState({collapse: !this.state.collapse})
    }

    render() {
        const emailTxtClasses = classnames({
            "custom-error": !!this.state.errors.email,
            "form-control": true
        });
        const formClass = classnames({
            "flex-space-between": true,
            "form-inline": true,
            "was-validated": !!this.state.formclassName
        });

        let alert = '';
        if (this.props.error !== null && this.props.error !== undefined) {
            alert = (
                <div className="alert alert-danger">
                    {$$('requested_user_does_not_exist_message')}
                </div>
            );
        } else if (this.props.success) {
            alert = (
                <div className="alert alert-success">
                    {$$('share_data_success_message')}
                </div>
            );
        }

        return (
            <div className="">
                <div style={{"marginTop": "20px"}}>
                    <div id="accordion" className="">
                        <div className="">
                            <div className="" id="headingThree" data-toggle="collapse" data-target="#collapseThree"
                                 aria-expanded="true" aria-controls="collapseThree">
                                <h5 className="mb-0 flex-space-between pointer" onClick={() => {
                                    this.toggleCollapse()
                                }}>
                                    {$$('share_label')}
                                    <span className="mr-3">
                                            {!this.state.collapse &&
                                            <i className="fas fa-chevron-down" style={{"marginTop": "10px"}}/>}
                                        {this.state.collapse &&
                                        <i className="fas fa-chevron-up" style={{"marginTop": "10px"}}/>}
                                        </span>
                                </h5>
                            </div>

                            {this.state.collapse && <div className="high-shadow-container">
                                {alert}
                                <div>
                                    <form onSubmit={this.onSubmit} className={formClass + " full-width form-inline"}
                                          noValidate={true}>
                                        <div className="form-group" style={{width: "80%"}}>
                                            <input type="email"
                                                   className={emailTxtClasses + " full-width"}
                                                   value={this.state.email}
                                                   placeholder={$$('email_label')}
                                                   name="email"
                                                   onChange={this.onInputChange}
                                                   required/>
                                            <div
                                                className={this.state.errors.email ? "custom-invalid-feedback" : "invalid-feedback"}>
                                                {this.state.errors.email ? this.state.errors.email : $$('email_required_message')}
                                            </div>
                                        </div>
                                        <div className="share-data-btn">
                                            <button type="submit"
                                                    className="btn btn-primary sharing-btn share-data-btn share-data-btn">{$$('share_data_label')}</button>
                                        </div>
                                    </form>
                                </div>
                                <div>
                                    <div style={{
                                        "marginLeft": "15px",
                                        "marginTop": "10px",
                                        "marginBottom": "5px"
                                    }}>
                                        {this.props.pending.length > 0 && $$('pending_for_auth_approval_label')}</div>
                                    {this.props.pending.length === 0 && this.getNoData($$("not_waiting_for_anyones_approval_label"))}
                                    <PermissionList permissions={this.props.pending} onDeny={this.props.onDeny}
                                                    cancelBtnLabel={$$('cancel_btn')}/>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ShareData.propTypes = {
    createTemporaryAccessLink: PropTypes.func,
    onDeny: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
    pending: PropTypes.array,
    shareData: PropTypes.func
};

export default ShareData

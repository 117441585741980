import React, {Component} from 'react'
import {$$} from '../../../helpers/localization'
import {ReactComponent as DeleteIcon} from "../../../../public/media/icons/svg/Design/Delete.svg";
import {ReactComponent as CameraIcon} from "../../../../public/media/icons/svg/Devices/Camera.svg";
import PropTypes from "prop-types";

export class DisplayProfileImage extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                {this.props.imageUrl === "" ?
                    <div className="upload-img text-center empty-img">
                        <button
                            onClick={this.props.addOrChangeProfilePicture}
                            className={"profile-btn"}
                        >
                            <i className="kt-nav__link-icon flaticon2-plus-1 plus-icn"/>
                            <br/>
                            <h4 className="upload-img-label">
                                {$$('add_profile_photo')}
                            </h4>
                        </button>
                    </div>
                    :
                    <div>
                        <div className="text-center">
                            <img
                                className="profile-photo"
                                src={this.props.imageUrl}
                                alt={"Profile Image"}/>
                        </div>
                        <div className="row centered-image-buttons">
                            <button className='delete-photo-icon btn zero-padding' onClick={this.props.showModal}>
                                <DeleteIcon className="svg-icon delete-btn" height="20px" width="20px"/>
                            </button>
                            <button className='add-photo-icon btn zero-padding' onClick={this.props.addOrChangeProfilePicture}>
                                <CameraIcon className="svg-icon icn-fill-white" height="25px" width="25px"/>
                            </button>
                        </div>
                    </div>}
            </div>
        )
    }
}

DisplayProfileImage.propTypes = {
    addOrChangeProfilePicture: PropTypes.func,
    imageUrl: PropTypes.string,
    showModal:  PropTypes.func
};

export default DisplayProfileImage;
import {
    AUTO_LOGOUT,
    LOGIN_REQUEST_ERROR,
    LOGIN_REQUEST_SENT,
    LOGIN_REQUEST_SUCCESS,
    LOGOUT,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    TOKEN_REFRESH_REQUEST_ERROR,
    TOKEN_REFRESH_REQUEST_SENT,
    TOKEN_REFRESH_REQUEST_SUCCESS
} from "../actions/actions";
import moment from 'moment'
import {requestStatus} from './requests_reducers';

const initialState = {data: {}, request: requestStatus(undefined, {}), refreshRequest: requestStatus(undefined, {})}

/**
 * Authentication reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the authentication
 * @param {action} action the action to execute on the state
 */
export function authentication(state = initialState, action) {
    switch (action.type) {
        case LOGIN_REQUEST_SENT: {
            return {
                ...state,
                request: requestStatus(state.request, {type: REQUEST_SENT}),
                refreshRequest: initialState.refreshRequest
            }
        }
        case LOGIN_REQUEST_SUCCESS: {
            action.response.authenticated_time = moment().valueOf();
            return {...state, data: action.response, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case LOGIN_REQUEST_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {data, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_ERROR, response: action.response})}
        }
        case TOKEN_REFRESH_REQUEST_SENT: {
            return {...state, refreshRequest: requestStatus(state.refreshRequest, {type: REQUEST_SENT})}
        }
        case TOKEN_REFRESH_REQUEST_SUCCESS: {
            action.response.authenticated_time = moment().valueOf();
            return {
                ...state,
                data: action.response,
                refreshRequest: requestStatus(state.refreshRequest, {type: REQUEST_SUCCESS})
            }
        }
        case TOKEN_REFRESH_REQUEST_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {request, ...rest} = state;
            return {
                ...rest,
                data: {},
                refreshRequest: requestStatus(rest.refreshRequest, {type: REQUEST_ERROR, response: action.response})
            }
        }
        case AUTO_LOGOUT: {
            // eslint-disable-next-line no-unused-vars
            const {request, ...rest} = state;
            return {
                ...rest,
                data: {},
                refreshRequest: {...rest, finished: true, success: false, error: {message: action.message}}
            }
        }
        case LOGOUT: {
            return {...state, data: initialState.data, request: initialState.request}
        }
        default: {
            return state;
        }
    }
}

import React, {Component} from 'react'
import {$$} from '../../helpers/localization';
import classNames from 'classnames'
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";
import CenteredModal from "../shared/CenteredModal";
import FormWithLoading from "../shared/FormWithLoading";
import {GP} from "../../actions/actions";
import ImportGP from "../profile/importGP/ImportGP";
import PropTypes from "prop-types";

export class GeneralPractitioner extends Component {

    state = {
        formClass: '',
        errors: {},
        importOpen: false,
        createEditModalOpen: false,
        deleteModalOpen: false,
        name: typeof this.props.generalPractitioner !== "undefined" ? this.props.generalPractitioner.name : "",
        practiceName: typeof this.props.generalPractitioner !== "undefined" ? this.props.generalPractitioner.practiceName : "",
        medicalPracticeAddress: typeof this.props.generalPractitioner !== "undefined" ? this.props.generalPractitioner.medicalPracticeAddress : "",
        practicePhoneNumber: typeof this.props.generalPractitioner !== "undefined" ? this.props.generalPractitioner.practicePhoneNumber : "",
        regionalHealthCenterCode: typeof this.props.generalPractitioner !== "undefined" ? this.props.generalPractitioner.regionalHealthCenterCode : "",
        uin: typeof this.props.generalPractitioner !== "undefined" ? this.props.generalPractitioner.uin : "",
    }

    componentDidMount() {
        this.props.fetchSelectedUserGeneralPractitioner(this.props.selectedUser.id)
    }

    componentWillUnmount() {
        this.props.clearGeneralPractitioner();
    }

    constructor(props) {
        super(props);
    }

    /**
     * Get Infomration of GP.
     *
     * @returns GP infomrmation
     */
    getGeneralPractitioner = () => {
        return <GeneralPractitionerItem
            toggleEditGPModal={this.toggleEditGPModal}
            hasPractitioner={typeof this.props.generalPractitioner !== "undefined"}
            practitioner={
                typeof this.props.generalPractitioner !== "undefined" ? this.props.generalPractitioner : ({
                    name: "",
                    practiceName: "",
                    medicalPracticeAddress: "",
                    practicePhoneNumber: "",
                    regionalHealthCenterCode: "",
                    uin: ""
                })
            } i18n={this.props.i18n}/>
    }

    toggleEditGPModal = () => {
        this.setState({
            createEditModalOpen: !this.state.createEditModalOpen,
            name: typeof this.props.generalPractitioner !== "undefined" ? this.props.generalPractitioner.name : "",
            practiceName: typeof this.props.generalPractitioner !== "undefined" ? this.props.generalPractitioner.practiceName : "",
            medicalPracticeAddress: typeof this.props.generalPractitioner !== "undefined" ? this.props.generalPractitioner.medicalPracticeAddress : "",
            practicePhoneNumber: typeof this.props.generalPractitioner !== "undefined" ? this.props.generalPractitioner.practicePhoneNumber : "",
            regionalHealthCenterCode: typeof this.props.generalPractitioner !== "undefined" ? this.props.generalPractitioner.regionalHealthCenterCode : ""
        })
    }

    toggleDeleteGPModal = () => {
        this.setState({deleteModalOpen: !this.state.deleteModalOpen})
    }

    onDeleteConfirmModal = () => {
        this.props.deleteUserGeneralPractitioner().then(() => {
            this.props.fetchSelectedUserGeneralPractitioner(this.props.selectedUser.id)
        })
        this.toggleDeleteGPModal()
    }

    setFormUsingImportedGP = (GP) => {
        this.setState({
            name: GP.name,
            practiceName: GP.practiceName,
            medicalPracticeAddress: GP.medicalPracticeAddress,
            practicePhoneNumber: GP.practicePhoneNumber,
            regionalHealthCenterCode: GP.regionalHealthCenterCode,
            uin: GP.uin
        })
    }

    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    isFormValid = () => {
        const {
            name,
            practiceName,
            medicalPracticeAddress,
            practicePhoneNumber,
            regionalHealthCenterCode,
            uin
        } = this.state

        return name && name !== "" &&
            practiceName && practiceName !== "" &&
            medicalPracticeAddress && medicalPracticeAddress !== "" &&
            practicePhoneNumber && practicePhoneNumber !== "" &&
            regionalHealthCenterCode && regionalHealthCenterCode !== "" &&
            uin && uin !== ""
    }

    onSubmit = (evt) => {
        evt.preventDefault();
        if (this.state.formClass !== "was-validated") {
            this.setState({formClass: "was-validated"});
        }

        if (this.isFormValid()) {

            let form = {
                "medicalPracticeAddress": this.state.medicalPracticeAddress,
                "name": this.state.name,
                "practiceName": this.state.practiceName,
                "practicePhoneNumber": this.state.practicePhoneNumber,
                "regionalHealthCenterCode": this.state.regionalHealthCenterCode,
                "uin": this.state.uin,
            }

            if (typeof this.props.generalPractitioner !== "undefined") {
                this.props.updateUserGeneralPractitioner(form).then(() => {
                    this.props.fetchSelectedUserGeneralPractitioner(this.props.selectedUser.id)
                })


            } else {
                this.props.createUserGeneralPractitioner(form).then(() => {
                    this.props.fetchSelectedUserGeneralPractitioner(this.props.selectedUser.id)
                })
            }

            if (this.state.formClass === "was-validated") {
                this.setState({formClass: ""});
            }

            this.toggleEditGPModal()
        }
    }

    render() {
        return (
            <div className="row"><><span
                className="wizard-title"> {$$('general_practitioner')} {typeof this.props.generalPractitioner !== "undefined" &&
                <DownloadEditDeleteButtons
                    handleDeleteClick={this.toggleDeleteGPModal}
                    handleEditClick={this.toggleEditGPModal}
                />}  </span> <br/> {this.getGeneralPractitioner()}</>
                <CenteredModal title={$$("general_practitioner")}
                               show={this.state.createEditModalOpen}
                               size={"Default"}
                               disableBackdrop={this.state.importOpen}
                               dialogClassName={this.state.importOpen ? "custom-dialog temp-hidden" : 'custom-dialog'}
                               onHide={this.toggleEditGPModal}
                               onConfirm={this.onSubmit}
                               confirmBtnLabel={$$('save_btn_label')}
                               cancelBtnLabel={$$('cancel_btn')}
                               leftBtn={this.props.selectedUser.country?.toLowerCase() === 'bg' ? <ImportGP
                                   getGPImportCapture={this.props.getGPImportCapture}
                                   generalPractitionerCapture={this.props.generalPractitionerCapture}
                                   importGP={this.props.importGP}
                                   setFormUsingImportedGP={this.setFormUsingImportedGP}
                                   importedPractitioner={this.props.importedPractitioner}
                                   toggleEditGPModal={() => {
                                       this.setState({importOpen: !this.state.importOpen})
                                   }}
                               /> : undefined}

                >
                    <FormWithLoading
                        formDisabled={this.props.formDisabled}
                        currentForm={GP}
                        marginTop="10%"
                        marginLeft="calc(50% - 70px)"
                        onSubmit={this.onSubmit}
                        className={this.state.formClass}
                        noValidate={true}>

                        <div className="form-group register-control">
                            <label>{$$('fullname_label')}</label>
                            <input type="text"
                                   className="form-control"
                                   value={this.state.name}
                                   placeholder={$$('fullname_label')}
                                   name="name"
                                   onChange={this.onInputChange}
                                   required/>
                            <div className="invalid-feedback">
                                {$$('name_field_required')}
                            </div>
                        </div>
                        <div className="form-group register-control">
                            <label>{$$('practice_name')}</label>
                            <input type="text"
                                   className="form-control"
                                   value={this.state.practiceName}
                                   placeholder={$$('practice_name')}
                                   name="practiceName"
                                   onChange={this.onInputChange}
                                   required/>
                            <div className="invalid-feedback">
                                {$$('practice_name_required')}
                            </div>
                        </div>
                        <div className="form-group register-control">
                            <label>{$$('practice_address')}</label>
                            <input type="text"
                                   className="form-control"
                                   value={this.state.medicalPracticeAddress}
                                   placeholder={$$('practice_address')}
                                   name="medicalPracticeAddress"
                                   onChange={this.onInputChange}
                                   required/>
                            <div className="invalid-feedback">
                                {$$('practice_address_required')}
                            </div>
                        </div>
                        <div className="form-group register-control">
                            <label>{$$('practice_contact')}</label>
                            <input type="text"
                                   className="form-control"
                                   value={this.state.practicePhoneNumber}
                                   placeholder={$$('practice_contact')}
                                   name="practicePhoneNumber"
                                   onChange={this.onInputChange}
                                   required/>
                            <div className="invalid-feedback">
                                {$$('practice_contact_required')}
                            </div>
                        </div>
                        <div className="form-group register-control">
                            <label>{$$('practice_centre_code')}</label>
                            <input type="text"
                                   className="form-control"
                                   value={this.state.regionalHealthCenterCode}
                                   placeholder={$$('practice_centre_code')}
                                   name="regionalHealthCenterCode"
                                   onChange={this.onInputChange}
                                   required/>
                            <div className="invalid-feedback">
                                {$$('practice_centre_code_required')}
                            </div>
                        </div>
                        <div className="form-group register-control">
                            <label>{$$('practice_practitioner_code')}</label>
                            <input type="text"
                                   className="form-control"
                                   value={this.state.uin}
                                   placeholder={$$('practice_practitioner_code')}
                                   name="uin"
                                   onChange={this.onInputChange}
                                   required/>
                            <div className="invalid-feedback">
                                {$$('practice_practitioner_code_required')}
                            </div>
                        </div>
                    </FormWithLoading>
                </CenteredModal>

                <CenteredModal title={$$('delete_log_entry_modal_header')}
                               dialogClassName='doc-delete-modal'
                               show={this.state.deleteModalOpen}
                               onHide={this.toggleDeleteGPModal}
                               cancelBtnLabel={$$('cancel_btn')}
                               onConfirm={this.onDeleteConfirmModal}
                               id="delete-modal-title"
                               className="center-delete-modal"
                               idFooter="footer-delete-modal"
                               confirmBtnLabel={$$('delete_label')}
                               confirmBtnClass="danger"
                               idBtnPrimary="btn-danger"
                               idBtnSecondary="btn-secondary">
                    {$$('upgrade_continue')}
                    <div className="bin-align">
                        <i className="fas fa-trash-alt fa-3x"/>
                    </div>
                </CenteredModal>

            </div>
        )
    }
}

const GeneralPractitionerItem = (props) => {
    // eslint-disable-next-line react/prop-types,no-unused-vars
    const {practitioner, hasPractitioner, i18n, toggleEditGPModal} = props;
    const [isCollapsed, setIsCollapsed] = React.useState(false);

    const collapseClick = React.useCallback(
        () => setIsCollapsed(!isCollapsed),
        [isCollapsed, setIsCollapsed],
    );


    const collapseClass = classNames('medication-plan-info',
        {
            "hide-medicaiton-info": !isCollapsed,
            "show-medication-info": isCollapsed
        });

    if (!hasPractitioner) {
        return (
            <div className="" style={{"display": "flex", "justifyContent": "space-between"}}>
                <div className="pointer" onClick={toggleEditGPModal}>
                    <span className="fa fa-plus add-condition add-gp"/>
                </div>
            </div>)
    }


    return (
        <span onClick={collapseClick} className="">
            {/* eslint-disable react/prop-types */}
            <div style={{"display": "flex", "justifyContent": "space-between"}}>
                <div>
                    {practitioner.name}
                </div>
                <div>
                    {!isCollapsed &&
                        <i className="kt-nav__link-icon flaticon2-down"/>
                    }
                    {isCollapsed &&
                        <i className="kt-nav__link-icon flaticon2-up"/>
                    }
                </div>
            </div>
                        <div className={collapseClass}>
                <br/>
                <div className={"bold"}> {$$('practice_name') + ":"}  </div>
                <div> {practitioner.practiceName}</div>
                <br/>
                    <div className={"bold"}> {$$('practice_address') + ":"}  </div>
                    <div> {practitioner.medicalPracticeAddress}</div>
                <br/>
                    <div className={"bold"}> {$$('practice_contact') + ":"}  </div>
                    <div> {practitioner.practicePhoneNumber}</div>
                <br/>
                    <div className={"bold"}> {$$('practice_centre_code') + ":"}  </div>
                    <div> {practitioner.regionalHealthCenterCode}</div>
                <br/>
                    <div className={"bold"}> {$$('practice_practitioner_code') + ":"}  </div>
                    <div> {practitioner.uin}</div>
                <br/>
            </div>
            {/* eslint-enable */}
        </span>
    )
}

GeneralPractitioner.propTypes = {
    createUserGeneralPractitioner: PropTypes.func,
    generalPractitionerCapture: PropTypes.any,
    importedPractitioner: PropTypes.any,
    deleteUserGeneralPractitioner: PropTypes.func,
    fetchSelectedUserGeneralPractitioner: PropTypes.func,
    formDisabled: PropTypes.object,
    generalPractitioner: PropTypes.object,
    getGPImportCapture: PropTypes.func,
    i18n: PropTypes.object,
    importGP: PropTypes.func,
    selectedUser: PropTypes.object,
    updateUserGeneralPractitioner: PropTypes.func,
    clearGeneralPractitioner: PropTypes.func
}

export default GeneralPractitioner

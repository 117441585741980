import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import UserImage from '../shared/UserImage'
import ClinicianName from './ClinicianName'
import {infoUtils} from '../../utils/infoUtils'
import no_appointments from '../../resources/images/no_appointments.png'
import PropTypes from "prop-types";

export class CliniciansPanel extends Component {
    constructor(props) {
        super(props);
    }

    getRow = (clinician) => {
        let cells = [];

        let clinicianFromProviders = this.props.clinicians.filter((clinicianFromProviders) => {
            return clinicianFromProviders.id === clinician.user_id
        })
        if (clinicianFromProviders) cells.push(this.getClinician(clinicianFromProviders[0]))

        return <tr key={clinician.id}>{cells}</tr>;
    }

    getSpecialtiesForPanel = (clinician) => {
        return clinician.specialties.map((specialty, index) => {
            if (index === 0) return $$(specialty.toLowerCase()) ? $$(specialty.toLowerCase()) : specialty.replace("_", " ").toLowerCase()
            else return ", " + ($$(specialty.toLowerCase()) ? $$(specialty.toLowerCase()) : specialty.replace("_", " ").toLowerCase())
        })
    }

    getOrganizationsForPanel = (clinician) => {
        return clinician.organizations.map((organization, index) => {
            if (index === 0) return organization.name;
            else return ", " + organization.name;
        })
    }

    getClinician = (clinician) => {
        if (clinician) {
            return <td key={clinician.id} className='row pb-2 pt-2'>
                <div className='clinician-img-new-container' style={{"cursor": "pointer"}} onClick={() => {
                    this.props.onClinicianClick(clinician)
                }}>
                    <UserImage
                        userID={clinician.id}
                        classnames={"clinician-img-new"}/>
                </div>
                <div className="pl-2 clinician-panel-data-container">
                    <ClinicianName
                        className={"clinician-panel-name"}
                        clinician={clinician}
                        i18n={this.props.i18n}
                        onNameClick={() => {
                            this.props.onClinicianClick(clinician)
                        }}
                    />
                    <div className="clinician-panel-specialty">{this.getSpecialtiesForPanel(clinician)}</div>
                    <div className="clinician-panel-hospital">{this.getOrganizationsForPanel(clinician)}</div>
                </div>
            </td>
        }
    }

    filterClinician(appointment) {
        if (appointment) {
            return appointment.participants.filter((participant) => {
                return participant.participant_role === "PROVIDER";
            })[0]
        }
        return []
    }

    getBody = () => {
        if (this.props.userAppointments.length > 0) {
            let threeLastClinicians = [];
            let lastAppointment = this.props.userAppointments.sort((a, b) => (a.starts_at < b.starts_at) ? 1 : -1).slice(0, 1)
            let lastClinician = this.filterClinician(lastAppointment[0])
            threeLastClinicians.push(lastClinician)
            lastAppointment = this.props.userAppointments.filter((appointment) => {
                let clinician = this.filterClinician(appointment)
                return clinician.fullname !== lastClinician.fullname
            }).sort((a, b) => (a.starts_at < b.starts_at) ? 1 : -1).slice(0, 1)
            let secondToLastClinician = this.filterClinician(lastAppointment[0])
            threeLastClinicians.push(secondToLastClinician)
            lastAppointment = this.props.userAppointments.filter((appointment) => {
                let clinician = this.filterClinician(appointment)
                return clinician.fullname !== lastClinician.fullname && clinician.fullname !== secondToLastClinician.fullname
            }).sort((a, b) => (a.starts_at < b.starts_at) ? 1 : -1).slice(0, 1)
            let thirdToLastClinician = this.filterClinician(lastAppointment[0])
            threeLastClinicians.push(thirdToLastClinician)
            let allRows = [];

            for (let i = 0; i < threeLastClinicians.length; ++i) {
                allRows.push(this.getRow(threeLastClinicians[i]));
            }
            return [].concat.apply([], allRows);
        }
    }

    areThereClinicians = () => {
        let lastAppointment = this.props.userAppointments.sort((a, b) => (a.starts_at < b.starts_at) ? 1 : -1).slice(0, 1)
        let lastClinician = this.filterClinician(lastAppointment[0])
        if (lastClinician.length === 0){
            return true;
        }
    }

    render() {
        let noClinicians = {
            imgClass: 'no-entry-image-landing',
            objClass: 'no-data-object-landing',
            primaryLabelClass: 'no-medications-landing-label',
            src: no_appointments,
            primaryLabel: $$('no_clinicians_primary_label'),
            nobreak: true
        }
        return (
            <div className="text-center">
                <div className='row' style={{display: "flex"}}>
                    <div className='col-xs-12 col-md-12'>
                        <div className='row' style={{'marginLeft': '1.563rem', 'marginRight': '2.25rem'}}>
                            <table id="issues" style={{'width': '100%'}}>
                                <tbody>
                                {this.getBody()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {this.areThereClinicians() && infoUtils.noData(noClinicians)}
            </div>
        )
    }
}

CliniciansPanel.propTypes = {
    clinicians: PropTypes.array,
    dashboardCharts: PropTypes.object,
    fetchSelectedUserLatestMeasurements: PropTypes.func,
    healthIssues: PropTypes.array,
    history:  PropTypes.object,
    i18n:  PropTypes.object,
    latestUserMeasurements:  PropTypes.array,
    location:  PropTypes.object,
    match:  PropTypes.object,
    medicationPlan: PropTypes.array,
    onClinicianClick:PropTypes.func,
    providers: PropTypes.array,
    selectedUser: PropTypes.object,
    userAppointments: PropTypes.array,
    userInfo:  PropTypes.object
}

import React from "react";
import AnswerType from "../../../models/answer-type/AnswerType";
import BaseAnswer from "../BaseAnswer";
import FormCheck from "../../input/FormCheck";
import '../Answer.css'

class SelectionAnswer extends BaseAnswer {
    translatedAnswer;

    constructor(props) {
        super(props);
        this.translatedAnswer = this.props.answer.label[this.language];

    }

    getHint = () => {
        if (!this.props.answer.checked && this.props.answer.hint) {
            return `- ${this.props.answer.hint[this.language]}`;
        }
        return ''
    }

    render() {

        const answer = this.props.answer;
        return (
            <span>
                <div className={`custom-control custom-${answer.selectionType}`}>
                    <FormCheck ref={this.inputRef}
                               onClick = {this.handleClick}
                               answer={answer}
                               handleChange={this.handleChange}/>
                    <label onClick={() => this.inputRef.current.click()} className={'cursor-pointer custom-control-label'}>{this.translatedAnswer} {this.getHint()}</label>
                </div>
            </span>
        )
    };

    handleClick = () => {
        if(this.props.answer.checked && this.props.answer.selectionType === "radio"){
            this.deselectAnswer();
        }
    }

    handleChange = (event) => {
        const checked = event.target.checked;
        if (checked) {
            this.props.answer.result = this.translatedAnswer;
            this.selectAnswer();
            return;
        }
        this.deselectAnswer();
    }
}

class SelectionAnswerFactory {
    static get type() {
        return AnswerType.SELECTION;
    }

    static create(answer, updateAnswer, language) {
        return <SelectionAnswer answer={answer} updateAnswer={updateAnswer} language={language}/>
    }
}

export default SelectionAnswerFactory

import store from "../store";

export function getClinicianNameWithTitle(clinician) {
    let language = store.getState().language.selected
    if (clinician) {
        if (clinician.title) {
            switch (language.lang) {
                case "sq":
                    return clinician.title + " " + clinician.fullname;
                case "bg":
                    return clinician.title + " " + clinician.fullname;
                case "en":
                    return clinician.fullname + ", " + clinician.title;
                default:
                    return clinician.fullname + " (" + clinician.title + ")";
            }
        } else return clinician.fullname;
    }
}
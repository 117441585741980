/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import { withRouter, Link } from "react-router-dom";
import { QuickActions } from "./components/QuickActions";
import { LayoutContextConsumer } from "../LayoutContext";
import { ReactComponent as SortNum1Icon } from "../../../_metronic/layout/assets/layout-svg-icons/SortNum1.svg";
import * as builder from "../../ducks/builder";
import { $$ } from '../../../helpers/localization'
import { Routes } from "../../../constants/routes";
import { CHAT_VIEWPOSITION } from "../../../constants/enums";

class SubHeader extends React.Component {

    state = {
        chatViewPosition: CHAT_VIEWPOSITION.INITIAL,
        onGoingCall: false
    }

    /**
     * On chat icon click. Expand or shrink the chat view based on the given state
     * 
     * @param {object} e - the event object
     */
    onClickChatIcon = (e) => {
        if (this.state.chatViewPosition === CHAT_VIEWPOSITION.INITIAL) {
            this.setState({ chatViewPosition: CHAT_VIEWPOSITION.ONLY_CHAT });
        } else if (this.state.chatViewPosition === CHAT_VIEWPOSITION.ONLY_CHAT || this.state.chatViewPosition === CHAT_VIEWPOSITION.FULL) {
            this.setState({ chatViewPosition: CHAT_VIEWPOSITION.INITIAL });
        }
    }

    /**
     * Set the ongoing call flag to show label in subheader
     * 
     * @param {boolean} value - true if call is ongoing
     */
    setOngoingCallFlag = (value) => {
        this.setState({onGoingCall: value});
    }

    /**
     * Set flag to indicate the chat view expansion
     */
    expandView = () => {
        this.setState({ chatViewPosition: CHAT_VIEWPOSITION.FULL });
    }

    /**
     * Set flag to indicate the chat view shrinking
     */
    shrinkView = () => {
        this.setState({ chatViewPosition: CHAT_VIEWPOSITION.ONLY_CHAT });
    }

    render() {
        const {
            subheaderCssClasses,
            subheaderContainerCssClasses,
            subheaderMobileToggle,
            unreadMessages
        } = this.props;

        const unreadMessagesCount = unreadMessages && unreadMessages.list ? unreadMessages.list.reduce((sum, m) => sum + m.count, 0) : 0;
        return (
            <div>
                <div
                    id="kt_subheader"
                    className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
                >
                    <div className={`kt-container ${subheaderContainerCssClasses}`}>
                        <div className="kt-subheader__main">
                            {subheaderMobileToggle && (
                                <button
                                    className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                                    id="kt_subheader_mobile_toggle"
                                >
                                    <span />
                                </button>
                            )}

                            <LayoutContextConsumer>
                                {/*{({ subheader: { title, breadcrumb } }) => (*/}

                                {({ subheader: { title, translate, page } }) => (
                                    <>
                                        {translate &&
                                            <><h3 className="kt-subheader__title">{$$(translate)}</h3>
                                                <span className="kt-subheader__separator kt-subheader__separator--v" /></>
                                        }
                                        {/*<BreadCrumbs items={breadcrumb} />*/}
                                        <span className="kt-subheader__desc">
                                            {`/${page}` === Routes.MAIN_PAGE || `/${page}` === Routes.CHILD_MEDICAL_RECORD ? this.props.selectedUser.fullname : this.props.userInfo.fullname}
                                        </span>
                                        {this.props.selectedUser.email && this.props.selectedUser.email.includes('@') && 
                                            <span className="kt-subheader__separator kt-subheader__separator--v" />
                                        }
                                        <a href={`mailto:${this.getEmail(page)}`}>
                                            {this.getEmail(page)}
                                        </a>
                                    </>
                                )}
                            </LayoutContextConsumer>

                        </div>
                        {/* Unread messages icon */}
                        <div className="kt-subheader__toolbar">
                            {this.state.onGoingCall && <div className="ongoing-cal-text"><b>{$$('ongoing_call')}</b></div>}
                            <div className="kt-subheader__wrapper">
                                <div style={{ "marginRight": "10px", "position": "relative" }}>
                                    <a className="flaticon2-chat-2" style={{ "fontSize": "1.5em" }} onClick={this.onClickChatIcon}></a>
                                    {unreadMessagesCount > 0 &&
                                        <span className="undread-messages-pill chat-icon-unread-messages"></span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getEmail(page) {
        let selectedUserEmail = this.props.selectedUser.email && this.props.selectedUser.email.includes('@') ? this.props.selectedUser.email : '';
        return `/${page}` === Routes.MAIN_PAGE || `/${page}` === Routes.CHILD_MEDICAL_RECORD ? selectedUserEmail : this.props.userInfo.email;
    }
}

const mapStateToProps = store => ({
    config: store.builder.layoutConfig,
    menuConfig: store.builder.menuConfig,
    subheaderMobileToggle: objectPath.get(
        store.builder.layoutConfig,
        "subheader.mobile-toggle"
    ),
    subheaderCssClasses: builder.selectors.getClasses(store, {
        path: "subheader",
        toString: true
    }),
    subheaderContainerCssClasses: builder.selectors.getClasses(store, {
        path: "subheader_container",
        toString: true
    }),
    selectedUser: store.selectedUser.data,
    userInfo: store.userInfo.data,
    unreadMessages: store.chat.unreadMessages
});

export default withRouter(connect(mapStateToProps)(SubHeader));

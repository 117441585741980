import React, {Component, useEffect, useRef, useState} from 'react'
import {$$, _$$} from '../../helpers/localization'
import {connect} from 'react-redux'
import moment from 'moment';
import history from '../../helpers/history'
import {Routes} from '../../constants/routes';
import {formatUtils} from '../../utils/formatUtils'
import CustomMultiselect from '../shared/CustomMultiSelect'
import CenteredModal from '../shared/CenteredModal'
import appointment_successful from '../../resources/images/appointment_successful.png'
import {ReactComponent as CalendarIcon} from '../../../public/media/icons/calendar.svg'
import {
    clearAppointmentCreationStatus,
    createAppointment,
    createPayment,
    updateAppointment
} from '../../actions/appointments_actions';
import ClinicianName from '../clinicians/ClinicianName';
import {getHealthIssueOptions} from '../../constants/select_options'
import {APPOINTMENTS} from "../../actions/actions";
import FormWithLoading from "../shared/FormWithLoading";
import PropTypes from "prop-types";
import {appointmentUtils} from "../../utils/appointmentUtils";
import {CONVERTER} from "../../utils/converter";
import Form from "react-bootstrap/Form";
import {appointmentsService, fetchQuestionnaireIdFromPrice} from "../../service/appointments_service";
import Questionnaire from "../../questionnaire/Questionnaire";
import _ from "underscore"
import {localiseURL} from "../../utils/urlUtils";
import {SERVER_URL, TERMS_OF_USE_URL} from "../../constants/api_paths";
import {Button} from "react-bootstrap";
import {selectUser, updateUserPhoneAndNames} from "../../actions/users_actions";
import {isPossiblePhoneNumber} from 'react-phone-number-input'
import {AppointmentUserPicker} from "./AppointmentUserPicker";
import {AppointmentUserDetailsForm} from "./AppointmentUserDetailsForm";

class CreateAppointment extends Component {

    constructor(props) {
        super(props);
        let noteValue = ""
        if (this.props.location.state.selectedAppointment) {
            let firstNote = findUserLastNote(this.props.location.state.selectedAppointment.notes, this.props.selectedUser.id)
            if (firstNote) {
                noteValue = firstNote.value;
            }
        }

        let selectedIssuesArray = this.props.location.state.selectedAppointment ? getHealthIssueOptions(this.props.location.state.selectedAppointment.health_issues) : [];
        this.state = {
            selectedExamination: this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.appointment_price : this.props.location.state.selectedPrice,
            selectedHealthIssues: this.props.location.state.selectedAppointment ? selectedIssuesArray : [],
            notes: this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.notes : [],
            healthIssueIds: this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.health_issue_ids : [],
            createOrUpdateClicked: false,
            noteValue: noteValue,
            createAppointmentSuccess: false,
            override_location_to_online: this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.override_location_to_online : false,
            errors: {},
            formclass: "",
            setPhoneNumberModal: false,
            share_all_data: this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.share_all_data : false,
            questionnaire_answer: this.props.location.state.selectedAppointment?.questionnaire_answer ? this.props.location.state.selectedAppointment.questionnaire_answer : null,
            requireDisclaimer: this.props.location.state.selectedAppointment ? !!this.props.location.state.selectedAppointment.appointment_price.disclaimer : !!this.props.location.state.selectedPrice.disclaimer,
            requirePayNowOrLater: this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.text_only
                && appointmentUtils.canPay(this.props.location.state.selectedAppointment)
                : this.props.location.state.selectedPrice.price_cents > 0 && this.props.location.state.selectedPrice.encounter_price_type === "TEXT_ONLY",
            wait: true
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.appointmentCreationStatus.creationSuccess && !this.state.showPayNowOrLater && !this.state.showPaymentModal) {
            history.push(Routes.APPOINTMENTS);
        }
    }

    componentWillUnmount() {
        this.props.clearAppointmentCreationStatus();
    }

    async componentDidMount() {

        if (!isPossiblePhoneNumber(this.props.loggedInUser.phone || "") || this.props.loggedInUser.fullname === this.props.loggedInUser.email) {
            this.openSetPhoneNumberModal()
        } else {

            let showFamilySelector = this.props.children_profiles.entries.length > 0 && !this.props.location.state.selectedAppointment;

            this.setState({showFamilySelector: showFamilySelector});

            if (!showFamilySelector && this.props.location.state.selectedPrice && appointmentUtils.shouldCheckWithServerForExistingAppointment(this.props.location.state.selectedPrice)) {
                //check for already existing future appointments. if such exist - redirect to clinician page
                let res = await appointmentsService.hasFutureAppointment(this.props.selectedUser.id, this.props.location.state.clinician.id);
                if (res.appointmentId) { //redirect to clinician page to show the already existing popup
                    history.push(`${Routes.CLINICIAN}?pid=${this.props.location.state.selectedPrice.id}&clinician=${this.props.location.state.clinician.id}`,
                        {futureAppointment: res})
                    return;
                }
            }

            if (this.state.wait) {
                this.setState({wait: false})
            }


            if (this.state.selectedExamination.questionnaire_id) {
                fetchQuestionnaireIdFromPrice(this.state.selectedExamination.id).then(r => {
                    if (this.props.location.state.selectedAppointment) {
                        this.initializeQuestionnaire(r)
                    }
                    this.setState({questionnaire: r})
                }).catch(() => {
                })
            }
        }
    }

    getOrganizationName(organizationId, nameOnly) {
        let organizationName = "";
        if (organizationId == null) return organizationName;
        let clinician = this.props.location.state.selectedAppointment ? this.props.clinician : this.props.location.state.clinician
        if (clinician)
            clinician.organizations.forEach((organization) => {
                if (organization.id === organizationId) {
                    organizationName = organization.name;
                }
            })
        if (organizationName !== "") {
            return nameOnly ? organizationName : ", " + organizationName;
        }
        return organizationName;
    }

    notesChanged = (event) => {
        this.setState({
            noteValue: event.target.value
        })
    }

    onSelect = (selectedItem) => {
        this.setState(prevState => ({
            selectedHealthIssues: [...prevState.selectedHealthIssues, selectedItem],
            healthIssueIds: [...prevState.healthIssueIds, selectedItem.value.id]
        }))
    }

    onRemove = (removedItem) => {
        let selectedIssuesArray = this.state.selectedHealthIssues;
        let selectedIssuesArrayIds = this.state.healthIssueIds;

        let indexHealthIssue = selectedIssuesArray.indexOf(removedItem)
        let indexHealthIssueId = selectedIssuesArrayIds.indexOf(removedItem.value.id)

        selectedIssuesArray.splice(indexHealthIssue, 1)
        selectedIssuesArrayIds.splice(indexHealthIssueId, 1)

        this.setState({
            selectedHealthIssues: selectedIssuesArray,
            healthIssueIds: selectedIssuesArrayIds
        })
    }

    validate = () => {
        const errors = {};
        this.setState({errors: ''});

        if (this.state.selectedExamination.encounter_price_type === "TEXT_ONLY") {
            if (this.state.noteValue === "") {
                errors.notes = $$('notes_required_message');
            }
        }
        this.setState({errors});
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }
        return Object.keys(errors).length === 0;
    }

    onSubmit = (evt) => {
        evt.preventDefault();
    }

    onQuestionnaireComplete = (summary) => {
        let entity = {
            questionnaire_type: this.state.questionnaire.type,
            user_id: this.props.selectedUser.id,
            questionnaire_answers: {
                ...summary
            }
        }
        this.setState({showQuestionnaire: false, questionnaire_answer: entity});
    }

    /**
     * Create new Appointment
     */
    onSave = async () => {
        let clinician = this.props.location.state.selectedAppointment ? this.props.clinician : this.props.location.state.clinician
        let notes = [];
        if (this.validate() && clinician.id) {

            let form = {};

            if (this.props.location.state.selectedAppointment) {
                notes = this.props.location.state.selectedAppointment.notes;
                form = {...this.props.location.state.selectedAppointment}

                let note = findUserLastNote(notes, this.props.selectedUser.id);
                if (this.state.noteValue === '') {
                    if (note) {
                        notes.pop(note);
                    }
                } else {
                    if (note) {
                        note.value = this.state.noteValue;
                    } else {
                        notes.push({
                            value: this.state.noteValue,
                            visibility: "ALL",
                            note_type: "TEXT",
                            owner_id: this.props.selectedUser.id,
                            subject_id: this.props.selectedUser.id
                        });
                    }
                }
            } else {
                if (this.state.noteValue !== "")
                    notes = [{
                        value: this.state.noteValue,
                        visibility: "ALL",
                        note_type: "TEXT",
                        subject_id: this.props.selectedUser.id
                    }]
            }

            form = {
                ...form,
                user_id: this.props.selectedUser.id,
                notes: notes,
                consultation_patient_deadline_hours: this.props.location.state.consultationPatientDeadlineHours ? this.props.location.state.consultationPatientDeadlineHours : 0,
                text_only: this.props.location.state.text_only,
                appointment_price: this.state.selectedExamination,
                payment_status: this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.payment_status : "INITIAL",
                health_issue_ids: this.state.healthIssueIds,
                status: this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.status : "WAITING_FOR_DOCTOR_APPROVAL",
                starts_at: this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.starts_at : this.props.location.state.selectedSlot.start,
                ends_at: this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.ends_at : this.props.location.state.selectedSlot.start + 60000 * this.state.selectedExamination.duration_mins,
                encounter_type: this.state.selectedExamination.encounter_type,
                location_type: this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.location_type : this.state.selectedExamination.location_type,
                organization_id: this.state.selectedExamination.organization_id ? this.state.selectedExamination.organization_id : "",
                location: this.props.selectedUser.country,
                participants: [{user_id: clinician.id, participant_role: "PROVIDER"}],
                health_issues: this.state.selectedHealthIssues,
                pay_before_timestamp: this.getPayBeforeTimestamp(),
                share_all_data: this.state.share_all_data,
                questionnaire_answer: this.state.questionnaire_answer ? this.state.questionnaire_answer : null,
                questionnaire_answers_id: this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.questionnaire_answers_id : null,
                override_location_to_online: this.state.override_location_to_online
            };

            if (this.props.location.state.text_only && !this.props.location.state.selectedAppointment) {
                form.starts_at = this.props.location.state.starts;
                form.ends_at = this.props.location.state.ends;
            }

            this.props.location.state.selectedAppointment ? this.props.updateAppointment(form, this.props.selectedUser.id) : this.props.createAppointment(form, this.props.selectedUser.id)
            // catch show error
            ;
        }
    }

    getPayBeforeTimestamp = () => {
        return this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.pay_before_timestamp : calculatePayBeforeTimestamp(this.props.location.state.selectedSlot.start, this.state.selectedExamination.price_cents, this.props.location.state);
    }

    onQuestionnaireCancel = () => {
        fetchQuestionnaireIdFromPrice(this.state.selectedExamination.id).then(r => {
            this.initializeQuestionnaire(r);
            this.setState({questionnaire: r, showQuestionnaire: false})
        });
    }

    initializeQuestionnaire = (r) => {
        let results;
        if (this.state.questionnaire_answer?.questionnaire_answers) {
            results = this.state.questionnaire_answer.questionnaire_answers.results;
        } else {
            results = this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.questionnaire_answer?.questionnaire_answers?.results : undefined;
        }

        if (results) {
            results.forEach(result => {
                let questions = r.questionnaire.questions;
                for (let i = 0; i < questions.length; i++) {
                    let question = questions[i];
                    let id = question.id || i;
                    if (result.question_id == id) {
                        result.answers_results.forEach(answer_result => {
                            for (let j = 0; j < question.answers.length; j++) {
                                let answer = question.answers[j];
                                let answer_id = answer.id || j;
                                if (answer_result.answer_id == answer_id) {
                                    answer.checked = true;
                                    answer.result = answer_result.result_text ? answer_result.result_text : null;
                                    answer.tag = answer_result.tag ? answer_result.tag : null;
                                }
                            }
                        })
                    }
                }
            })
        }
    }

    onQuestionnaireReset = () => {
        this.setState({questionnaire_answer: null}, () => {
            fetchQuestionnaireIdFromPrice(this.state.selectedExamination.id).then(r => {
                this.setState({questionnaire: r});
            })
        })
    }


    getTitle = () => {
        let isThisYear = this.props.location.state.selectedAppointment ? moment(this.props.location.state.selectedAppointment.starts_at).format("YYYY") === moment().format("YYYY") : moment(this.props.location.state.selectedSlot.start).format("YYYY") == moment().format("YYYY");
        const orgId = this.state.selectedExamination.organization_id ? this.state.selectedExamination.organization_id : null

        return <div style={{marginLeft: "2rem"}} className="flex-space-between">
            <div>
                <h3 style={{fontWeight: "600"}}>
                    {appointmentUtils.appointmentType(this.state.selectedExamination)}
                </h3>
                <h3 className="price-hospital-make-appointment">
                    <span>{formatUtils.currencyFormat(this.state.selectedExamination.price_cents, this.state.selectedExamination.currency)},&nbsp;</span>
                    <ClinicianName
                        fromCreateAppointment={true}
                        clinician={this.props.clinician}
                        i18n={this.props.i18n}
                    />,&nbsp;
                    <span>{this.state.selectedExamination.encounter_price_type !== "TEXT_ONLY" && $$(this.state.selectedExamination.location_type.toLowerCase())}
                        {this.getOrganizationName(orgId, this.state.selectedExamination.encounter_price_type === "TEXT_ONLY")}</span>
                </h3>
                {this.state.selectedExamination.encounter_price_type == "TEXT_ONLY" &&
                    <h3 className="price-hospital-make-appointment">
                            <span>
                                <i className="far fa-clock" style={{color: "#BBCDD9"}}/>
                            </span>
                        <span className="ml-2">
                                {this.responseTime()}
                            </span>
                    </h3>
                }
                <h3 className={"price-hospital-make-appointment mt-3"}>
                    {$$("patient_label")}: {this.props.selectedUser.fullname}
                </h3>
            </div>
            {this.state.selectedExamination.encounter_price_type !== "TEXT_ONLY"
                && <div className="early-appointment time-make-appointment flex-grow-0 flex-shrink-0">
                    <div className="">
                        <div className="">
                            {isThisYear && moment(this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.starts_at : this.props.location.state.selectedSlot.start).locale(this.props.i18n.selected.lang).format("ddd, D MMM")}
                            {!isThisYear && moment(this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.starts_at : this.props.location.state.selectedSlot.start).locale(this.props.i18n.selected.lang).format("ddd, D MMM YYYY")}
                        </div>
                        <div className="d-flex appointment-time">
                        <span>
                            <i className="far fa-clock" style={{color: "#BBCDD9"}}/>
                        </span>
                            <span className="ml-2">
                            {CONVERTER.getEntryDateTimeFormat(moment(this.props.location.state.selectedAppointment ? this.props.location.state.selectedAppointment.starts_at : this.props.location.state.selectedSlot.start), false)}
                        </span>
                        </div>
                    </div>
                    <div className="early-appointment-create-appointment ml-3">
                        <CalendarIcon width="32px" height="35px" className="svg-icon"/>
                    </div>
                </div>}
        </div>
    }

    responseTime = () => {
        let state = this.props.location.state;
        const hours = state.selectedAppointment ? state.selectedAppointment.consultation_patient_deadline_hours : state.consultationPatientDeadlineHours;
        switch (hours) {
            case 16:
                return $$("response_in_16")
            case 24:
                return $$("response_in_24")
            case 48:
                return $$("response_in_48")
            case 720:
            case 0:
                return $$("response_no_restriction")
            default:
                return _$$("response_in_X_days", Math.round(hours/24))
        }
    }

    handleTransition = () => {
        if (!this.validate()) return;

        let state = {}

        if (this.state.requireDisclaimer && !this.state.disclaimerAccepted) {
            if (!this.state.showDisclaimer) {
                state.showDisclaimer = true;
            } else {
                state.disclaimerAccepted = true;
                state.showDisclaimer = false;
            }
        }

        if (this.state.requirePayNowOrLater && !state.showDisclaimer) {
            if (!this.state.showPayNowOrLater) {
                state.showPayNowOrLater = true;
            } else {
                state.showPayNowOrLater = false;
                state.showPaymentModal = true;
            }
        }

        if (!_.isEmpty(state)) {
            this.setState(state);
        }

        if (!state.showDisclaimer && !this.props.appointmentCreationStatus.creationSuccess) {
            this.onSave();
        }
    }

    getConfirmButtonLabel = () => {
        if (this.state.selectedExamination.encounter_price_type === "TEXT_ONLY") {
            if ((this.state.showDisclaimer || !this.state.requireDisclaimer) && !this.state.showPayNowOrLater) {
                return $$('save_btn_label');
            }

            if (this.state.showPayNowOrLater) {
                return $$("pay_now");
            }

            return $$("continue");
        } else {
            if (this.state.showDisclaimer || !this.state.requireDisclaimer) {
                return this.props.location.state.selectedAppointment ? $$('save_btn_label') : $$('book_label');
            }
            return $$("continue");
        }
    }

    getCancelButtonLabel = () => {
        if (this.state.selectedExamination.encounter_price_type === "TEXT_ONLY") {
            if (this.state.showPayNowOrLater) {
                if (this.state.showPayLaterWarning) {
                    return $$("close_btn_label")
                }
                return $$("pay_later_btn")
            } else {
                return $$("cancel_btn");
            }
        } else {
            return $$("cancel_btn");
        }
    }

    canOverrideLocation = () => {
        if (this.props.location.state.selectedAppointment && this.state.selectedExamination.location_type === "ON_SITE") {
            let timeToLockLocation = this.props.location.state.selectedAppointment.starts_at - this.state.selectedExamination.lock_location_in_hours * 3600 * 1000;
            let isBeforeLock = this.props.location.state.selectedAppointment ? (Date.now() < timeToLockLocation) : false;
            return isBeforeLock && this.state.selectedExamination.allow_video_for_on_site && this.state.selectedExamination.can_enable_video_for_on_site_appointments;
        }
        return false;
    }

    confirmUser = async (user) => {
        if (this.props.location.state.selectedPrice && appointmentUtils.shouldCheckWithServerForExistingAppointment(this.props.location.state.selectedPrice)) {
            //check for already existing future appointments. if such exist - redirect to clinician page
            let res = await appointmentsService.hasFutureAppointment(user.id, this.props.location.state.clinician.id);
            if (res.appointmentId) { //redirect to clinician page to show the already existing popup
                history.push(`${Routes.CLINICIAN}?pid=${this.props.location.state.selectedPrice.id}&clinician=${this.props.location.state.clinician.id}`,
                    {futureAppointment: res})
                return;
            } else {
                if (user.id !== this.props.selectedUser.id) {
                    this.props.selectUser(user)
                }

                this.setState({
                    showFamilySelector: false
                })
            }
        } else {
            if (user.id !== this.props.selectedUser.id) {
                this.props.selectUser(user)
            }
            this.setState({
                showFamilySelector: false
            })
        }
    }

    openSetPhoneNumberModal = () => {
        this.setState({
            setPhoneNumberModal: true,
            wait: false
        })
    }

    updatePhoneNumber = async (obj) => {
        await this.props.updateUserPhoneAndNames(obj);
        this.setState({
            setPhoneNumberModal: false,
        });

        let showFamilySelector = this.props.children_profiles.entries.length > 0 && !this.props.location.state.selectedAppointment;

        if (!showFamilySelector && this.props.location.state.selectedPrice && appointmentUtils.shouldCheckWithServerForExistingAppointment(this.props.location.state.selectedPrice)) {
            //check for already existing future appointments. if such exist - redirect to clinician page
            let res = await appointmentsService.hasFutureAppointment(this.props.selectedUser.id, this.props.location.state.clinician.id);
            if (res.appointmentId) { //redirect to clinician page to show the already existing popup
                history.push(`${Routes.CLINICIAN}?pid=${this.props.location.state.selectedPrice.id}&clinician=${this.props.location.state.clinician.id}`,
                    {futureAppointment: res})
                return;
            }
        }

        this.setState({showFamilySelector: showFamilySelector});

        if (this.state.wait) {
            this.setState({wait: false})
        }

        if (this.state.selectedExamination.questionnaire_id) {
            fetchQuestionnaireIdFromPrice(this.state.selectedExamination.id).then(r => {
                if (this.props.location.state.selectedAppointment) {
                    this.initializeQuestionnaire(r)
                }
                this.setState({questionnaire: r})
            }).catch(() => {
            })
        }

    }

    getNewRender = () => {
        return <div>
            <CenteredModal
                show={this.state.createAppointmentSuccess}
                onHide={() => {
                    history.push(Routes.APPOINTMENTS);
                }}
                onConfirm={() => {
                    history.push(Routes.APPOINTMENTS);
                }}
                size="lg"
                dialogClassName={"symptom-dialog-success"}
            >
                <div className="successful-appointment-container">
                    <div className="successful-appointment-text">
                        {$$("create_appointment_successful_message")}
                    </div>
                    <div>
                        <img className="img-style-appointment" src={appointment_successful} alt={"appointment"}/>
                    </div>
                </div>

            </CenteredModal>
            <QuestionnaireModal show={this.state.showQuestionnaire}
                                onCancel={this.onQuestionnaireCancel}
                                onComplete={this.onQuestionnaireComplete}
                                questionnaire={this.state.questionnaire}
            />

            {this.state.showFamilySelector && <AppointmentUserPicker
                loggedInUser={this.props.loggedInUser}
                onCancel={()=>{
                    history.push(`${Routes.CLINICIAN}?pid=${this.props.location.state.selectedPrice.id}&clinician=${this.props.location.state.clinician.id}`);
                }}
                selected={this.props.selectedUser}
                users={this.props.children_profiles.entries}
                onSelected={this.confirmUser}
            />}


            {this.state.setPhoneNumberModal && <AppointmentUserDetailsForm
                onCancel={() => {
                    this.props.location.state.selectedAppointment ? history.push(Routes.APPOINTMENTS) : history.push(`${Routes.CLINICIAN}?pid=${this.props.location.state.selectedPrice.id}&clinician=${this.props.location.state.clinician.id}`);
                }}
                i18n={this.props.i18n}
                onUpdate={this.updatePhoneNumber}
                user={this.props.loggedInUser}
            /> }

            <CenteredModal
                dialogClassName={"custom-dialog custom-dialog-appointment"}
                title={this.getTitle()}
                show={!this.state.createAppointmentSuccess && !this.state.showQuestionnaire && !this.state.showFamilySelector && !this.state.setPhoneNumberModal}
                onHide={() => {
                    if (this.state.showPayNowOrLater && !this.state.showPayLaterWarning) {
                        this.setState({showPayLaterWarning: true})
                        return;
                    }
                    this.props.location.state.selectedAppointment || this.props.appointmentCreationStatus.creationSuccess ? history.push(Routes.APPOINTMENTS) : history.push(Routes.CLINICIAN);
                }}
                onConfirm={() => {
                    this.handleTransition()
                }}
                size="lg"
                confirmBtnLabel={this.getConfirmButtonLabel()}
                cancelBtnLabel={this.getCancelButtonLabel()}
                primary={this.state.showPayLaterWarning}
            >
                {this.state.showDisclaimer && <div className={"low-shadow-container mr-4"} style={{marginLeft: "2rem"}}>
                    <p className="white-space-pre-line">{this.state.selectedExamination.disclaimer}</p>
                </div>}
                {this.state.showPayNowOrLater &&
                    <div className={"low-shadow-container mr-4"} style={{marginLeft: "2rem"}}>
                        {this.state.showPayLaterWarning ?
                            <p className="white-space-pre-line">{$$("text_only_pay_later_warning")}</p>
                            :
                            <p className="white-space-pre-line">{_$$("text_only_pre_payment_text", CONVERTER.formatDate(this.getPayBeforeTimestamp(), false))}</p>
                        }
                    </div>}
                {!this.state.showDisclaimer && !this.state.showPayNowOrLater &&
                    <div style={{marginLeft: "2rem", marginRight: "2rem", marginTop: "1rem"}}>
                        <FormWithLoading
                            formDisabled={this.props.formDisabled}
                            currentForm={APPOINTMENTS}
                            marginTop="10%"
                            className={this.state.formclass}
                            marginLeft="calc(50% - 70px)"
                            onSubmit={this.onSubmit}>
                            {this.state.selectedExamination.description && <div>
                                <p className="white-space-pre-line">{this.state.selectedExamination.description}</p>
                            </div>}

                            {this.canOverrideLocation() && <div style={{
                                "marginBottom": "15px",
                                fontSize: "13px"
                            }} className="form-group">
                                <div className={"text-danger"} style={{
                                    fontSize: "13px"
                                }}>
                                    {$$("override_location_to_online_explanation")}
                                </div>
                                <div style={{
                                    "marginBottom": "15px",
                                    fontSize: "13px"
                                }}>
                                    <Form.Check id={`override_location_to_online`}
                                                type="checkbox"
                                                label={$$("override_location_to_online")}
                                                custom
                                                checked={this.state.override_location_to_online}
                                                value={false}
                                                className={this.state.override_location_to_online ? "danger" : ""}
                                                onChange={() => {
                                                    this.setState({override_location_to_online: !this.state.override_location_to_online})
                                                }
                                                }/>
                                </div>
                            </div>}


                            <div style={{
                                "marginBottom": "15px",
                                fontSize: "13px"
                            }} className="form-group">
                                <div className={"text-danger"} style={{
                                    "marginBottom": "15px",
                                    fontSize: "13px"
                                }}>
                                    {this.state.selectedExamination.encounter_price_type !== "TEXT_ONLY" ?
                                        $$("share_all_data_explanation") :
                                        $$("share_all_data_consultation_explanation")}
                                </div>
                                <div style={{
                                    "marginBottom": "15px",
                                    fontSize: "13px"
                                }}>
                                    <Form.Check id={`share_all_data_checkbox`}
                                                type="checkbox"
                                                label={$$("share_all_data_label")}
                                                custom
                                                checked={this.state.share_all_data}
                                                value={false}
                                                className={this.state.share_all_data ? "danger" : ""}
                                                onChange={() => {
                                                    this.setState({share_all_data: !this.state.share_all_data})
                                                }
                                                }/>
                                </div>
                            </div>

                            <div className={this.state.share_all_data ? "hidden" : "form-group"}>
                                <div style={{
                                    "marginBottom": "15px",
                                    fontSize: "13px"
                                }}>  {this.state.selectedExamination.encounter_price_type !== "TEXT_ONLY" ?
                                    $$('create_appointment_health_issue_message') :
                                    $$('create_consultation_health_issue_message')}</div>
                                <label>{$$("health_issues_label")}</label>
                                <CustomMultiselect
                                    options={getHealthIssueOptions(this.props.healthIssues)}
                                    selectedValues={this.state.selectedHealthIssues}
                                    onSelect={this.onSelect}
                                    onRemove={this.onRemove}
                                    displayValue="text"
                                    placeholder={$$('select_health_issue_label')}
                                    closeIcon='cancel'
                                    avoidHighlightFirstOption={true}
                                    isFieldValid={this.state.validLanguagesField}
                                    formClass={this.state.formclass}/>
                            </div>
                            {this.state.questionnaire && <div className="form-group">
                                <label>{$$("fill_in_the_questionnaire")}</label>
                                <div className="d-flex justify-content-around flex-wrap">
                                    <button className="btn btn-primary btn-small" onClick={() => {
                                        this.setState({showQuestionnaire: true})
                                    }}>
                                        {$$(this.state.questionnaire_answer ? "edit_questionnaire" : "to_the_questionnaire")}</button>
                                    {
                                        this.state.questionnaire_answer &&

                                        <button className="btn btn-danger btn-small"
                                                onClick={this.onQuestionnaireReset}>
                                            {$$("reset_questionnaire_answers")}</button>

                                    }
                                </div>
                            </div>
                            }
                            <div style={{
                                fontSize: "13px"
                            }}
                                 className="form-group">{this.state.selectedExamination.encounter_price_type !== "TEXT_ONLY" ?
                                $$('create_appointment_notes_message') :
                                $$('create_consultation_notes_message')}</div>
                            <label>{$$("appointment_note_title")}</label>
                            <textarea value={this.state.noteValue}
                                      onChange={this.notesChanged}
                                      required={this.state.selectedExamination.encounter_price_type === "TEXT_ONLY"}
                                      readOnly={this.props.location.state.selectedAppointment && !isMyNoteLast(this.props.location.state.selectedAppointment.notes, this.props.selectedUser.id)}
                                      className="form-control w-100"/>
                            <div className="server-error mt-1">{this.state.errors.notes} </div>

                            {this.props.appointmentCreationStatus.creationSuccess !== null && this.props.appointmentCreationStatus.creationSuccess !== true &&
                                <div className="server-error mt-1">{$$("user_password_update_error_message")}</div>}
                        </FormWithLoading>
                    </div>}
            </CenteredModal>
        </div>
    }

    executePayment = (method) => {
        let appId = this.props.appointment.id;
        let lang = this.props.i18n.selected.lang;

        let payment_method = method === "paylogin" ? "epay" : "epay_cc";

        this.props.createPayment({
            appointmentId: appId, payment_method: payment_method
        }).then(res => {
            if (!res.endpoint) {
                //handle empty response -> the server returns empty response when the appointment payment status is COMPLETED e.g. we're working with stale data.
                //todo: hide payment dialog, show warning that it's already paid and refresh the appointment.
                return;
            }
            let form = document.createElement("form");
            form.setAttribute("action", res.endpoint);
            form.setAttribute("method", "POST");
            form.appendChild(createTextInput("PAGE", method));
            form.appendChild(createTextInput("LANG", lang.toUpperCase()));
            form.appendChild(createTextInput("ENCODED", res.encoded));
            form.appendChild(createTextInput("CHECKSUM", res.checksum));
            let urlOk = window.aptechko ? `${SERVER_URL}/public/user/payment_page/ok?lang=${this.props.i18n.selected.lang === 'bg' ? "bg" : "en"}` : window.location.origin + "/appointments?status=OK&id=" + appId;
            form.appendChild(createTextInput("URL_OK", urlOk));
            let urlCancel = window.aptechko ? `${SERVER_URL}/public/user/payment_page/error?lang=${this.props.i18n.selected.lang === 'bg' ? "bg" : "en"}` : window.location.origin + "/appointments?status=CANCELED&id=" + appId;
            form.appendChild(createTextInput("URL_CANCEL", urlCancel));
            document.body.appendChild(form);
            this.props.clearAppointmentCreationStatus();
            if (window.aptechko) {
                form.target = "_blank";
            }
            form.submit();
            if (window.aptechko) {
                this.setState({showPaymentModal: false, agreement_checked: false})
                history.push(Routes.APPOINTMENTS + "?status=WAITING&id=" + appId);
            }
        });
    }

    render() {
        moment.locale(this.props.i18n.selected.lang)
        if (this.state.wait) {
            return null;
        }

        return (
            <>
                {!this.state.showPaymentModal && this.getNewRender()}

                {this.state.showPaymentModal && this.props.appointment.id && <CenteredModal
                    show={true}
                    onHide={() => {
                        history.push(Routes.APPOINTMENTS)
                    }}
                    primary={true}>
                    <p className="h4">
                        {$$(this.props.appointment.text_only ? "payment_option_consultation_modal_body" : "payment_option_modal_body")
                            .replace('{fullname}', this.props.appointment.participants.filter(a => a.participant_role === 'PROVIDER')[0].fullname)
                            .replace('{date}', moment(this.props.appointment.starts_at).locale(this.props.i18n.selected.lang).format('D MMMM HH:mm'))
                        }, {$$("total_sum")}&nbsp;{formatUtils.currencyFormat(this.props.appointment.appointment_price.price_cents, this.props.appointment.appointment_price.currency)}
                    </p>

                    <div className="mt-3 mb-3">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" id="agreement_input" name="agreement_input"
                                   className="custom-control-input" onChange={() => {
                                this.setState({agreement_checked: !this.state.agreement_checked})
                            }}/>
                            <label className="custom-control-label" htmlFor="agreement_input">
                               <span className="ml-2 font-weight-normal">
                                    {$$("cancellation_policy_agreement_label_first_part")}&nbsp; <a target="_blank"
                                                                                                    href={localiseURL(TERMS_OF_USE_URL)}
                                                                                                    className="text-decoration-underline"
                                                                                                    rel="noreferrer">{$$("cancellation_policy")}</a>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="d-table">
                        <div className="d-table-row">
                            <Button className="d-table-cell" style={{minWidth: "140px"}}
                                    disabled={!(this.state.agreement_checked)} onClick={() => {
                                this.executePayment("paylogin")
                            }}>{$$("pay_with_epay_button")}</Button>
                            <span className="d-table-cell p-3">{$$("epay_payment_option_descr")}</span>
                        </div>
                        <div className="d-table-row">
                            <Button className="d-table-cell" style={{minWidth: "140px"}}
                                    disabled={!(this.state.agreement_checked)} onClick={() => {
                                this.executePayment("credit_paydirect")
                            }}>{$$("pay_with_card_button")}</Button>
                            <span className="d-table-cell p-3">{$$("cc_payment_option_descr")}</span>
                        </div>
                    </div>
                </CenteredModal>}
            </>
        )
    }
}

CreateAppointment.propTypes = {
    clinician: PropTypes.object,
    loggedInUser: PropTypes.any,
    appointment: PropTypes.object,
    children_profiles: PropTypes.object,
    createAppointment: PropTypes.func,
    createPayment: PropTypes.func,
    selectUser: PropTypes.func,
    updateUserPhoneAndNames: PropTypes.func,
    clearAppointmentCreationStatus: PropTypes.func,
    formDisabled: PropTypes.object,
    healthIssues: PropTypes.array,
    history: PropTypes.object,
    i18n: PropTypes.object,
    appointmentCreationStatus: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    selectedUser: PropTypes.object,
    updateAppointment: PropTypes.func
}

function mapStateToProps(state) {
    return {
        i18n: state.language,
        appointmentCreationStatus: state.userAppointment.appointmentCreation,
        healthIssues: state.healthIssues.entries,
        selectedUser: state.selectedUser.data,
        clinician: state.provider.data,
        loggedInUser: state.userInfo.data,
        children_profiles: state.children_profiles,
        formDisabled: state.formInteractions,
        appointment: state.userAppointment.entry
    }
}

const mapDispatchToProps = {
    createAppointment,
    updateAppointment,
    clearAppointmentCreationStatus,
    selectUser,
    updateUserPhoneAndNames,
    createPayment
}


export default connect(mapStateToProps, mapDispatchToProps)(CreateAppointment)

function createTextInput(name, value) {
    let input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", name);
    input.setAttribute("value", value);
    input.setAttribute("autocomplete", "off");
    return input;
}

function calculatePayBeforeTimestamp(start, priceCents, locationState) {
    if (priceCents == 0) return 0;
    if (locationState.text_only) {
        if (locationState.consultationPatientDeadlineHours > 48) {
            return locationState.starts + 48 * 3600 * 1000;
        }
        return locationState.ends;
    }
    let startsAt = moment(start);
    let dayBefore = startsAt.add(-1, "day");
    let dayBeforeMinus30 = moment(dayBefore).add(-30, "minute");
    return dayBeforeMinus30.isAfter(new Date(), "minute") ? dayBefore.valueOf() : moment().add(30, "minute").valueOf();
}

class QuestionnaireModal extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {}
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.show !== prevProps.show && this.state.summary) {
            this.setState({summary: undefined})
        }
    }

    render() {
        if (!this.props.questionnaire) {
            return null;
        }
        return <CenteredModal
            show={this.props.show}
            onHide={() => {
                this.props.onCancel()
            }}
            onConfirm={() => {
                this.props.onComplete(this.state.summary);
            }}
            size="lg"
            dialogClassName={"custom-dialog custom-dialog-appointment questionnaire-modal"}
            primary={!this.state.summary}
            backdrop={"static"}
            cancelBtnLabel={$$("cancel_btn")}
        >
            <Questionnaire
                questionnaire={this.props.questionnaire.questionnaire}
                onComplete={this.props.onComplete}
                onSummaryReady={(summary) => this.setState({summary})}/>
        </CenteredModal>
    }
}


function findUserLastNote(notes, userId) {
    let latest = null;
    notes.find((note) => {
        if (note.modified_by === userId && note.owner_id === userId) {
            if (!latest || latest > note.server_created_timestamp) {
                latest = note
            }
        }
    })
    return latest;
}

function isMyNoteLast(notes, userId) {
    let myLatest = findUserLastNote(notes, userId);
    let latest = null;
    notes.find((note) => {
        if (!latest || latest > note.server_created_timestamp) {
            latest = note
        }
    })
    return myLatest === latest
}

QuestionnaireModal.propTypes = {
    onComplete: PropTypes.func,
    show: PropTypes.func,
    questionnaire: PropTypes.object,
    onCancel: PropTypes.func,
}
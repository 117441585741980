import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {validators} from '../../helpers/validators'
import {$$} from '../../helpers/localization'
import Select from "../shared/Select";
import {countryOptions} from "../../constants/countries";
import moment from "moment";
import {
    GENDER,
    getResolvedOptions
} from '../../constants/select_options';
import RegisterPageSuccess from './RegisterPageSuccess';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {localiseURL} from "../../utils/urlUtils";
import {TERMS_OF_USE_URL} from '../../constants/api_paths'
import bg from 'date-fns/locale/bg';
import sq from 'date-fns/locale/sq';
import enGB from 'date-fns/locale/en-GB';
import {isPasswordValid} from "../../utils/validatePassword";
import PasswordValidity from "../shared/PasswordValidity";
import PhoneInput, {isPossiblePhoneNumber} from "react-phone-number-input";
import en from "react-phone-number-input/locale/en";
import BG from '../../constants/phone_input_bg'


export class RegisterForm extends Component {

    static propTypes = {
        register: PropTypes.func
    }
    state = {
        imageUrl: '',
        hasImage: false,
        fullname: '',
        parentFullname: '',
        email: '',
        city: '',
        country: 'bg',
        birthday: 0,
        gender: '',
        password: '',
        rePassword: '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        formclass: '',
        phone: '',
        errors: {},
        user_flags: {"MARKETING_EMAILS": false},
        showSuccessPage: false,
        agreementSelected: false,
        needToSelectFormType: this.props.userWasntLoggedIn,
        createConnectedProfile: false
    }

    constructor(props) {
        super(props)
    }

    static getDerivedStateFromProps(props, state) {
        //Check if there has been an error returned from the register submition and if so se the error state
        if (props.registration.response && props.registration.response.status === 403) {
            props.clearRegistrationData();
            return {
                errors: {email: 'register_form_email_exists_message'}
            }
        } else if (props.registration.response && props.registration.response.status) {
            props.clearRegistrationData();
            return {
                errors: {serverError: 'error_occurred'}
            }
        } else {
            if (props.registration.response) {
                state.showSuccessPage = true
            }
        }
        return state;
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    getLocale = () => {
        switch (this.props.i18n.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            case "sq":
                return sq
            default:
                return enGB
        }
    }

    /**
     * Open new tab with the terms of use url
     */
    onTermsClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(localiseURL(TERMS_OF_USE_URL), "_blank");
    }

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields);
    };

    /**
     * Set the state to the latest selected date.
     *
     * @param {object} date - The event handler argument
     */
    onDateChange = (date) => {
        const fields = Object.assign({}, this.state);
        fields["birthday"] = moment(date).valueOf();
        this.setState(fields);
    };


    /**
     * Form submit handler, validate data and set error in state if any. Call register action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        const formErrors = this.validate();
        this.setState({errors: formErrors});
        evt.preventDefault();
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }

        if (Object.keys(formErrors).length) {
            return;
        }

        if (evt.target.checkValidity()) {
            // eslint-disable-next-line no-unused-vars
            const {errors, rePassword, formclass, ...data} = this.state;
            if (this.state.createConnectedProfile) {
                this.props.registerWithConnectedProfile(data)
            } else {
                this.props.register(data);
            }
        }
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        if (this.state.email && !validators.validateEmail(this.state.email) && this.state.email !== "") {
            errors.email = 'register_form_email_not_correct_message';
        }
        if (this.state.password !== this.state.rePassword) {
            errors.password = 'register_form_passwords_not_match_message';
        }
        if (this.state.password && !isPasswordValid(this.state.password)) {
            errors.password = 'password_is_not_valid';
        }
        if (!this.state.agreementSelected) {
            errors.agreementSelected = "agreement_required"
        }
        return errors;
    }


    onBackClick = () => {
        this.props.removeImageFromStore();
        this.props.clearRegistrationData();
        this.props.history.push("/login")
    }


    agreementChanged = () => {
        this.setState({agreementSelected: !this.state.agreementSelected})
    }

    marketingAgreementChanged = () => {
        let userFlags = {...this.state.user_flags};
        userFlags.MARKETING_EMAILS = !userFlags.MARKETING_EMAILS;
        this.setState({user_flags: userFlags})
    }

    hideSuccessForm = () => {
        this.setState({
            showSuccessPage: false
        })
    }

    render() {

        if (this.state.needToSelectFormType && this.props.userWasntLoggedIn) {
            return <div className="kt-login__body d-flex justify-content-center">
                <div className="kt-login__form">
                    <div className="kt-login__title">
                        <h2 className="text-center">{$$('register_label')}</h2>
                    </div>
                    <p className="text-justify" dangerouslySetInnerHTML={{__html: $$("adult_child_registration_explanation_text")}}/>
                    <div className="form-group">
                        <div className='row flex-space-between register-btn-back-container'>
                            <div className="col-xs-12 col-md-6 mx-auto mt-2">
                                <button type='button' onClick={() => {
                                    this.setState({createConnectedProfile: false, needToSelectFormType: false})
                                }}
                                        className='btn btn btn-primary register-btn-back'>
                                    {$$('adult_btn_label')}
                                </button>
                            </div>
                            <div className="col-xs-12 col-md-6 mx-auto mt-2" onClick={() => {
                                this.setState({createConnectedProfile: true, needToSelectFormType: false})
                            }}>
                                <button type='button' className='btn btn-primary full-width'>
                                    {$$('child_btn_label')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       }

        if (this.state.showSuccessPage) {
            return <div>
                <RegisterPageSuccess password={this.state.password}
                                     email={this.state.email}
                                     clearRegistrationData={this.props.clearRegistrationData}
                                     hideSuccessForm={this.hideSuccessForm}
                />
            </div>
        }

        if (this.state.createConnectedProfile) {
            return <div>
                <div className="kt-login__body d-flex justify-content-center">
                    <div className="kt-login__form">
                        <div className="kt-login__title">
                            <h2 className="text-center">{$$('register_label')}</h2>
                        </div>
                        <form onSubmit={this.onSubmit} className={this.state.formclass} noValidate={true}>
                            <h5>{$$("child_profile_section")}</h5>
                            <div className="form-group-set mb-3">
                                <div className="form-group">
                                    {this.state.fullname && <label>{$$('child_fullname_label')}</label>}
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.fullname}
                                        placeholder={$$('child_fullname_label')}
                                        name="fullname"
                                        onChange={this.onInputChange}
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        {$$('register_form_child_full_name_required')}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Select
                                            label={$$('gender_label')}
                                            name="gender"
                                            value={this.state.gender}
                                            options={getResolvedOptions(GENDER.OPTIONS)}
                                            onChange={this.onSelectChange}/>
                                    </div>
                                    <div className="col-sm-6">
                                        <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                variant="inline"
                                                format="yyyy/MM/dd"
                                                margin="normal"
                                                id="date-picker-inline"
                                                label={$$("birthday_label")}
                                                value={this.state.birthday}
                                                KeyboardButtonProps={{'aria-label': 'change date',}}
                                                onChange={this.onDateChange}
                                                required
                                                invalidDateMessage={$$('invalid_date_format')}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Select
                                            label={$$('country_label')}
                                            name="country"
                                            options={countryOptions(this.props.i18n.selected.lang)}
                                            value={this.state.country}
                                            onChange={this.onSelectChange}/>
                                    </div>
                                    <div className="col-sm-6">
                                        <label>{$$('city_label')}</label>
                                        <input
                                            type="text"
                                            className="form-control my-1 mr-sm-2"
                                            value={this.state.city}
                                            placeholder={$$('city_label')}
                                            name="city"
                                            onChange={this.onInputChange}/>
                                    </div>
                                </div>
                            </div>
                            <h5>{$$("account_section")}</h5>
                            <div className={"form-group-set"}>
                                <div className="form-group">
                                    {this.state.parentFullname && <label>{$$('fullname_label')}</label>}
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.parentFullname}
                                        placeholder={$$('fullname_label')}
                                        name="parentFullname"
                                        onChange={this.onInputChange}
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        {$$('register_form_full_name_required')}
                                    </div>
                                </div>
                                <div className="form-group">
                                    {this.state.email && <label>{$$('email_label')}</label>}
                                    <input
                                        type="email"
                                        className={this.state.errors.email ? "custom-error form-control" : "form-control"}
                                        value={this.state.email}
                                        placeholder={$$('email_label')}
                                        name="email"
                                        onChange={this.onInputChange}
                                        required
                                    />
                                    <div
                                        className={this.state.errors.email ? "custom-invalid-feedback" : "invalid-feedback"}>
                                        {this.state.errors.email ? $$(this.state.errors.email) : $$('email_required_message')}
                                    </div>
                                </div>
                                <div className="form-group">
                                    {this.state.phone && <label>{$$('phone_label')}</label>}
                                    <PhoneInput
                                        name="phone"
                                        id="phone"
                                        defaultCountry={this.props.i18n.selected.key.toUpperCase()}
                                        placeholder={$$('phone_label')}
                                        value={this.state.phone}
                                        onChange={(p) => {
                                            this.setState({phone: p})
                                            if (!isPossiblePhoneNumber(p || "")) {
                                                document.getElementById("phone").setCustomValidity("invalid")
                                            } else {
                                                document.getElementById("phone").setCustomValidity("")
                                            }
                                        }}
                                        numberInputProps={{className:`form-control`, required:true}}
                                        labels={this.props.i18n.selected.lang === "bg" ? BG : en}
                                    />
                                    {this.state.formClass === "was-validated" && <div className="invalid-feedback d-block">{this.state.phone ? (isPossiblePhoneNumber(this.state.phone) ? undefined : $$('valid_phone_required')) : $$('valid_phone_required')}</div>}


                                </div>
                                <div className="form-group">
                                    {this.state.password && <label>{$$('password_label')}</label>}
                                    <input
                                        type="password"
                                        className={this.state.errors.password ? "custom-error form-control" : "form-control"}
                                        value={this.state.password}
                                        placeholder={$$('password_label')}
                                        name="password"
                                        onChange={this.onInputChange}
                                        required
                                        minLength={8}
                                    />
                                    <div
                                        className={this.state.errors.password ? "custom-invalid-feedback" : "invalid-feedback"}>
                                        {this.state.errors.password ? $$(this.state.errors.password) : $$('password_required_message')}
                                    </div>
                                    <PasswordValidity password={this.state.password}/>

                                </div>
                                <div className="form-group">
                                    {this.state.rePassword &&
                                    <label>{$$('register_form_password_confirm_label')}</label>}
                                    <input
                                        type="password"
                                        className="form-control"
                                        value={this.state.rePassword}
                                        placeholder={$$('register_form_password_confirm_label')}
                                        name="rePassword"
                                        onChange={this.onInputChange}
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        {$$('register_form_password_confirm_required_message')}
                                    </div>
                                </div>
                                <div className="form-check custom-checkbox custom-control text-left mt-3">
                                    <input className="custom-control-input" type="checkbox" name="success-outlined"
                                           onChange={this.marketingAgreementChanged}
                                           checked={this.state.user_flags.MARKETING_EMAILS}
                                           id="success-outlined"/>
                                    <label className="custom-control-label ml-2" htmlFor="success-outlined">
                                        {$$('marketing_agreements_message')}
                                    </label>
                                </div>
                                <div className="form-check custom-checkbox custom-control text-left mt-3">
                                    <input className="custom-control-input" type="checkbox" name="agreement"
                                           required={true}
                                           onChange={this.agreementChanged} checked={this.state.agreementSelected}
                                           id="agreement"/>
                                    <label className="custom-control-label ml-2" htmlFor="agreement">
                                        {$$('agreements_message')} <a href={localiseURL(TERMS_OF_USE_URL)}
                                                                      onClick={this.onTermsClick}>{$$('agreements_message_ca')}</a>
                                    </label>
                                    <div
                                        className={this.state.errors.agreementSelected && !this.state.agreementSelected ? "custom-invalid-feedback" : "invalid-feedback"}>
                                        {$$('agreement_selected_required')}
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div
                                className={this.state.errors.serverError ? "chat-error-txt custom-invalid-feedback" : "chat-error-txt invalid-feedback"}>
                                {this.state.errors.serverError ? $$(this.state.errors.serverError) : $$('error_occurred')}
                            </div>
                            <div className="form-group">
                                <div className='row flex-space-between register-btn-back-container'>
                                    <div className="col-xs-12 col-md-6 mx-auto mt-2">
                                        <button type='button' onClick={this.onBackClick}
                                                className='btn btn-secondary register-btn-back'>
                                            {$$('back_label')}
                                        </button>
                                    </div>
                                    <div className="col-xs-12 col-md-6 mx-auto mt-2">
                                        <button type='submit' className='btn btn-primary full-width'>
                                            {$$('register_label')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        }

        return (
            <div>
                <div className="kt-login__body d-flex justify-content-center">
                    <div className="kt-login__form">
                        <div className="kt-login__title">
                            <h2 className="text-center">{$$('register_label')}</h2>
                        </div>
                        <form onSubmit={this.onSubmit} className={this.state.formclass} noValidate={true}>
                            <div className="form-group">
                                {this.state.fullname && <label>{$$('fullname_label')}</label>}
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.fullname}
                                    placeholder={$$('fullname_label')}
                                    name="fullname"
                                    onChange={this.onInputChange}
                                    required
                                />
                                <div className="invalid-feedback">
                                    {$$('register_form_full_name_required')}
                                </div>
                            </div>
                            <div className="form-group">
                                {this.state.email && <label>{$$('email_label')}</label>}
                                <input
                                    type="email"
                                    className={this.state.errors.email ? "custom-error form-control" : "form-control"}
                                    value={this.state.email}
                                    placeholder={$$('email_label')}
                                    name="email"
                                    onChange={this.onInputChange}
                                    required
                                />
                                <div
                                    className={this.state.errors.email ? "custom-invalid-feedback" : "invalid-feedback"}>
                                    {this.state.errors.email ? $$(this.state.errors.email) : $$('email_required_message')}
                                </div>
                            </div>
                            <div className="form-group">
                                {this.state.phone && <label>{$$('phone_label')}</label>}
                                <PhoneInput
                                    name="phone"
                                    id="phone"
                                    defaultCountry={this.props.i18n.selected.key.toUpperCase()}
                                    placeholder={$$('phone_label')}
                                    value={this.state.phone}
                                    onChange={(p) => {
                                        this.setState({phone: p})
                                        if (!isPossiblePhoneNumber(p || "")) {
                                            document.getElementById("phone").setCustomValidity("invalid")
                                        } else {
                                            document.getElementById("phone").setCustomValidity("")
                                        }
                                    }}
                                    numberInputProps={{className:`form-control`, required:false}}
                                    labels={this.props.i18n.selected.lang === "bg" ? BG : en}
                                />
                                {this.state.formClass === "was-validated" && <div className="invalid-feedback d-block">{this.state.phone ? (isPossiblePhoneNumber(this.state.phone) ? undefined : $$('valid_phone_required')) : $$('valid_phone_required')}</div>}

                            </div>
                            <div className="form-group">
                                {this.state.password && <label>{$$('password_label')}</label>}
                                <input
                                    type="password"
                                    className={this.state.errors.password ? "custom-error form-control" : "form-control"}
                                    value={this.state.password}
                                    placeholder={$$('password_label')}
                                    name="password"
                                    onChange={this.onInputChange}
                                    required
                                    minLength={8}
                                />
                                <div
                                    className={this.state.errors.password ? "custom-invalid-feedback" : "invalid-feedback"}>
                                    {this.state.errors.password ? $$(this.state.errors.password) : $$('password_required_message')}
                                </div>
                                <PasswordValidity password={this.state.password}/>

                            </div>
                            <div className="form-group">
                                {this.state.rePassword &&
                                <label>{$$('register_form_password_confirm_label')}</label>}
                                <input
                                    type="password"
                                    className="form-control"
                                    value={this.state.rePassword}
                                    placeholder={$$('register_form_password_confirm_label')}
                                    name="rePassword"
                                    onChange={this.onInputChange}
                                    required
                                />
                                <div className="invalid-feedback">
                                    {$$('register_form_password_confirm_required_message')}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Select
                                        label={$$('gender_label')}
                                        name="gender"
                                        value={this.state.gender}
                                        options={getResolvedOptions(GENDER.OPTIONS)}
                                        onChange={this.onSelectChange}/>
                                </div>
                                <div className="col-sm-6">
                                    <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            variant="inline"
                                            format="yyyy/MM/dd"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label={$$("birthday_label")}
                                            value={this.state.birthday}
                                            KeyboardButtonProps={{'aria-label': 'change date',}}
                                            onChange={this.onDateChange}
                                            required
                                            invalidDateMessage={$$('invalid_date_format')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Select
                                        label={$$('country_label')}
                                        name="country"
                                        options={countryOptions(this.props.i18n.selected.lang)}
                                        value={this.state.country}
                                        onChange={this.onSelectChange}/>
                                </div>
                                <div className="col-sm-6">
                                    <label>{$$('city_label')}</label>
                                    <input
                                        type="text"
                                        className="form-control my-1 mr-sm-2"
                                        value={this.state.city}
                                        placeholder={$$('city_label')}
                                        name="city"
                                        onChange={this.onInputChange}/>
                                </div>
                            </div>
                            <div className="form-check custom-checkbox custom-control text-left mt-3">
                                <input className="custom-control-input" type="checkbox" name="success-outlined"
                                       onChange={this.marketingAgreementChanged}
                                       checked={this.state.user_flags.MARKETING_EMAILS}
                                       id="success-outlined"/>
                                <label className="custom-control-label ml-2" htmlFor="success-outlined">
                                    {$$('marketing_agreements_message')}
                                </label>
                            </div>
                            <div className="form-check custom-checkbox custom-control text-left mt-3">
                                <input className="custom-control-input" type="checkbox" name="agreement"
                                       required={true}
                                       onChange={this.agreementChanged} checked={this.state.agreementSelected}
                                       id="agreement"/>
                                <label className="custom-control-label ml-2" htmlFor="agreement">
                                    {$$('agreements_message')} <a href={localiseURL(TERMS_OF_USE_URL)}
                                                                  onClick={this.onTermsClick}>{$$('agreements_message_ca')}</a>
                                </label>
                                <div
                                    className={this.state.errors.agreementSelected && !this.state.agreementSelected ? "custom-invalid-feedback" : "invalid-feedback"}>
                                    {$$('agreement_selected_required')}
                                </div>
                            </div>
                            <br/>
                            <div
                                className={this.state.errors.serverError ? "chat-error-txt custom-invalid-feedback" : "chat-error-txt invalid-feedback"}>
                                {this.state.errors.serverError ? $$(this.state.errors.serverError) : $$('error_occurred')}
                            </div>
                            <div className="form-group">
                                <div className='row flex-space-between register-btn-back-container'>
                                    <div className="col-xs-12 col-md-6 mx-auto mt-2">
                                        <button type='button' onClick={this.onBackClick}
                                                className='btn btn-secondary register-btn-back'>
                                            {$$('back_label')}
                                        </button>
                                    </div>
                                    <div className="col-xs-12 col-md-6 mx-auto mt-2">
                                        <button type='submit' className='btn btn-primary full-width'>
                                            {$$('register_label')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

RegisterForm.propTypes = {
    clearRegistrationData: PropTypes.func,
    history: PropTypes.object,
    i18n: PropTypes.object,
    register: PropTypes.func,
    registration: PropTypes.object,
    removeImageFromStore: PropTypes.func,
};


export default (RegisterForm)

import React from 'react'
import SettingsPage from './components/settings/SettingsPage'
import {
    Redirect,
    Route,
    Router,
    Switch
} from 'react-router-dom'
import {Routes} from './constants/routes';
import RouteWrapper from './components/wrapper/RouteWrapper'
import history from './helpers/history'
import {LayoutContextProvider} from "./_metronic";
import {connect} from 'react-redux'
import SharingPage from './components/permissions/SharingPage'
import CliniciansPage from './components/clinicians/CliniciansPage'
import ClinicianPage from './components/clinicians/ClinicianPage'
import MyDocuments from './components/documents/MyDocuments'
import ChildMedicalRecord from './components/main/ChildMedicalRecord'
import LandingPage from './components/main/LandingPage'
import Password from './components/password/Password';
import HealthIssues from './components/health/HealthIssues'
import Appointments from './components/appointments/Appointments'
import CreateAppointment from './components/appointments/CreateAppointment'
import CreateChild from './components/children/CreateChild'
import MessagesPage from './components/messages/MessagesPage';
import AuthPage from './components/login/AuthPage'
import Medications from "./components/medications/Medications";
import PropTypes from "prop-types";
import useConnectionOptions from "./components/video/utils/useConnectionOptions/useConnectionOptions";
import {VideoProvider} from "./components/video/VideoProvider";
import {IDLE} from "./constants/call_status";
import {VideoManager} from "./components/videocall/video_manager";

//resets the scroll position when the pages are changed
history.listen(() => {
    window.scrollTo(0, 0)
});

class App extends React.Component {
    constructor(props) {
        super(props);
        const queryParams = new URLSearchParams(window.location.search)
        if (queryParams.has('ref')) {
            let ref = queryParams.get('ref');
            if (ref === 'aptechko') {
                window.aptechko = true;
                window.sessionStorage.setItem("aptechko", "true")
            } else {
                window.aptechko = !!window.sessionStorage.getItem("aptechko")
            }
        } else {
            window.aptechko = !!window.sessionStorage.getItem("aptechko")
        }
    }


    render() {
        return (
            <Router history={history}>
                <LayoutContextProvider history={history} menuConfig={this.props.menuConfig} lang={this.props.lang}>
                    <VideoContextProvider>
                        <Switch>
                            <RouteWrapper isPrivate={false} path={Routes.LOGIN} component={AuthPage}/>
                            <RouteWrapper isPrivate={false} path={Routes.REGISTER} component={AuthPage}/>
                            <RouteWrapper isPrivate={false} path={Routes.CLINICIAN_NOT_LOGGED_IN} component={AuthPage}/>
                            <RouteWrapper isPrivate={false} path={Routes.CLINIC_NOT_LOGGED_IN} component={AuthPage}/>
                            <Route exact path='/' render={() => (
                                <Redirect
                                    to={Routes.DASHBOARD}
                                />
                            )}/>
                            <RouteWrapper isPrivate={true} path={Routes.DASHBOARD} component={LandingPage}/>
                            <RouteWrapper isPrivate={true} path={Routes.MAIN_PAGE} component={ChildMedicalRecord}/>
                            <RouteWrapper isPrivate={true} path={Routes.SETTINGS} component={SettingsPage}/>
                            <RouteWrapper isPrivate={true} path={Routes.SHARING} component={SharingPage}/>
                            <RouteWrapper isPrivate={true} path={Routes.CLINICIANS} component={CliniciansPage}/>
                            <RouteWrapper isPrivate={true} path={Routes.CLINICIAN} component={ClinicianPage}/>
                            <RouteWrapper isPrivate={true} path={Routes.MESSAGES} component={MessagesPage}/>
                            <RouteWrapper isPrivate={true} path={Routes.PROFILE} component={ChildMedicalRecord}/>
                            <RouteWrapper isPrivate={true} path={Routes.MY_DOCUMENTS} component={MyDocuments}/>
                            <RouteWrapper isPrivate={true} path={Routes.APPOINTMENTS} component={Appointments}/>
                            <RouteWrapper isPrivate={true} path={Routes.APPOINTMENT} component={CreateAppointment}/>
                            <RouteWrapper isPrivate={true} path={Routes.CHILD_MEDICAL_RECORD}
                                          component={ChildMedicalRecord}/>
                            <RouteWrapper isPrivate={true} path={Routes.CHILD_RECORD} component={CreateChild}/>
                            <RouteWrapper isPrivate={true} path={Routes.CHANGE_PASSWORD} component={Password}/>
                            <RouteWrapper isPrivate={true} path={Routes.HEALTH_ISSUES} component={HealthIssues}/>
                            <RouteWrapper isPrivate={true} path={Routes.MEDICATIONS} component={Medications}/>
                        </Switch>
                    </VideoContextProvider>
                </LayoutContextProvider>
            </Router>
        );
    }
}

App.propTypes = {
    menuConfig: PropTypes.any,
    lang: PropTypes.any
};


function mapStateToProps(state) {
    return {
        menuConfig: state.builder.menuConfig,
        lang: state.language.selected
    }
}

export default connect(mapStateToProps, null)(App);

function VideoContextProvider({children}) {
    let options = useConnectionOptions();
    return <VideoProvider options={options} onError={(e) => {
        console.log(e.code);
        if (e.code === 53118) {
            VideoManager.setState({error:53118})
        }
    }}>
        {children}
    </VideoProvider>
}
import React, {Component} from 'react';
import {$$} from '../../helpers/localization'
import ReactCrop from 'react-image-crop'
import CenteredModal from '../shared/CenteredModal'
import {authHelper} from '../../helpers/auth_helper'
import {removeImageFromStore, uploadImageToStore} from '../../actions/profile_image_actions'
import {connect} from 'react-redux'
import {ReactComponent as DeleteIcon} from "../../../public/media/icons/svg/Design/Delete.svg";
import {ReactComponent as CameraIcon} from "../../../public/media/icons/svg/Devices/Camera.svg";
import PropTypes from "prop-types";


class ProfilePhotoUpload extends Component {
    constructor(props) {
        super(props)
        this.state =
            {
                croppedImageUrl: null,
                showMe: "none",
                showModal: false,
                src: undefined,
                crop: null,
                hasProfilePicture: false,
            }
    }


    onComplete = (croppedUrl) => {
        this.props.uploadImageToStore(croppedUrl)
    }

    showModal = () => {
        this.setState({showModal: true});
    }


    onSelectFile = (e) => {

        if (e.target.files && e.target.files.length > 0) {


            const reader = new FileReader();
            reader.addEventListener('load', () => {

                    this.setState({src: reader.result});
                    this.setState({showMe: "block"});
                }
            );
            reader.readAsDataURL(e.target.files[0]);

        }
    };

    addOrChangeProfilePicture = () => {

        var myInput = document.getElementById('picture');
        myInput.click();
    }

    onImageLoaded = (image) => {

        this.imageRef = image;

        let ratio = image.naturalWidth / image.naturalHeight;
        let heightInPct = 50 * ratio;
        let yInPct = (100 - (heightInPct)) / 2;
        let xInPct = 25;
        this.setState({
            crop: {
                unit: '%',
                width: 50,
                height: heightInPct,
                x: xInPct,
                y: yInPct,
                aspect: 1
            }
        });
        const targetX = image.width * xInPct / 100;
        const targetY = image.height * yInPct / 100;
        const targetWidth = image.width * 50 / 100;
        const targetHeight = image.height * heightInPct / 100;

        let cropForInitialPreview = {
            width: targetWidth,
            height: targetHeight,
            x: targetX,
            y: targetY

        };

        this.makeClientCrop(cropForInitialPreview);
        return false;
    };

    onCropComplete = (crop) => {


        this.makeClientCrop(crop);
    };

    onCropChange = (percentCrop) => {
        this.setState({crop: percentCrop});
    };

    makeClientCrop = (crop) => {

        if (this.imageRef && crop.width && crop.height) {
            this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg',
                (e) => {
                    this.setState({croppedImageUrl: e})
                }
            );
        }
    }


    getCroppedImg = (image, crop, fileName, resolve) => {

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        //fixed dest size - 512x512
        canvas.width = 512;
        canvas.height = 512;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height
        );

        canvas.toBlob(blob => {
            if (!blob) {
                return;
            }
            blob.name = fileName;

            window.URL.revokeObjectURL(this.state.croppedImageUrl);
            let a = window.URL.createObjectURL(blob);

            resolve(a, blob);
        }, 'image/jpeg');
    }

    onConfirmModal = () => {
        this.removeProfilePicture();
        this.setState({showModal: false});
    }

    removeProfilePicture = () => {

        this.props.removeImageFromStore();
    }

    checkIfRegistered = () => {
        return !!(this.props.fullname && this.props.email && authHelper.isLoggedIn());
    }


    render() {
        const {imageUrl} = this.props;
        const {crop, croppedImageUrl} = this.state;

        return (
            <div className={"prof-img-upload"}>
                <div className="col-xs-4 col-md-4">
                    <div className="row">
                          <div style={{display: this.state.showMe !== "none" ? "none": ""}} className={"prof-img-container  auth-page-logo"}>
                            {
                                imageUrl === "" ?
                                    <div>
                                        <div className="upload-img-register text-center"
                                             onClick={this.addOrChangeProfilePicture}>
                                            <button
                                                className={"register-profile-btn"}
                                            >
                                                <i className="kt-nav__link-icon upload-img-icn "

                                                />
                                                <br/>
                                                <h4 className="upload-img-label-register">
                                                    {$$('add_profile_photo')}
                                                </h4>
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div>

                                        <div className="upload-img-box">
                                            <div>
                                                <img className="profile-photo-after-upload" src={imageUrl}
                                                     alt={"Profile Picture"}/>
                                            </div>
                                            <button className='delete-photo-icon-after-upload btn zero-padding'
                                                    onClick={this.showModal}>
                                                <DeleteIcon className="svg-icon icn-fill-white"
                                                            height="20px"
                                                            width="20px"
                                                />
                                            </button>

                                            <button className='add-photo-icon-after-upload btn zero-padding'
                                                    onClick={this.addOrChangeProfilePicture}>
                                                <CameraIcon className="svg-icon icn-fill-white"
                                                            height="25px"
                                                            width="25px"
                                                />
                                            </button>
                                        </div>
                                    </div>
                            }
                            {
                                this.checkIfRegistered() === true &&
                                <div className="row text-center show-email-fullname">
                                    <h3 className="text-white">{this.props.fullname}</h3>
                                    <h4 className="text-white">{this.props.email}</h4>
                                </div>
                            }
                            <div>
                                <input
                                    id="picture"
                                    type="file"
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                    className={"profile-input"}
                                    accept="image/jpeg,capture=camera"
                                    onChange={this.onSelectFile}
                                />
                            </div>

                            <CenteredModal title={$$('delete_image_modal_title')}
                                           dialogClassName='doc-delete-modal'
                                           show={this.state.showModal}
                                           onHide={() => this.setState({showModal: false})}
                                           cancelBtnLabel={$$('cancel_btn')}
                                           onConfirm={this.onConfirmModal}
                                           id="delete-modal-title"
                                           className="center-delete-modal"
                                           idFooter="footer-delete-modal"
                                           confirmBtnClass="danger"
                                           confirmBtnLabel={$$('OK')}
                                           idBtnPrimary="btn-danger"
                                           idBtnSecondary="btn-secondary">
                                {$$('delete_image_modal_message')}
                                <div className="bin-align">
                                    <i className="fas fa-trash-alt fa-3x"/>
                                </div>
                            </CenteredModal>
                        </div>
                    </div>
                </div>
                <div className={"image-crop-container"} style={{"display": this.state.showMe}}>
                    {this.state.src &&
                        (
                            <div className="p-2 w-50 d-table-cell">
                                <div
                                    className="p-2 text-center border-caption">{$$('select_label')}</div>
                                <ReactCrop
                                    src={this.state.src}
                                    crop={crop}
                                    ruleOfThirds
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                />
                            </div>
                        )}
                    <div className="p-2 w-50 d-table-cell">
                        <div className="p-2 text-center border-caption">
                            {$$('preview_image')}
                        </div>
                        {croppedImageUrl && (
                            <img className="preview" alt="Crop"
                                 src={croppedImageUrl}/>
                        )}
                    </div>
                    <div className="text-center pseudo-modal-footer" style={{"padding":"1rem"}}>
                        <div>
                            <button
                                className="btn btn-primary mr-2"
                                disabled={crop && crop.width < 10} onClick={() => {
                                this.onComplete(croppedImageUrl);
                                this.setState({showMe: "none"});
                            }}>{$$('save_btn_label')}</button>
                            <button className="btn btn-danger"
                                    onClick={() => this.setState({showMe: "none"})}>{$$('cancel_btn')}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let logged = authHelper.isLoggedIn();
    let fullname = (state.register.response && logged) ? state.register.response.fullname : "";
    let email = (state.register.response && logged) ? state.register.response.email : "";

    return {
        imageUrl: state.manageProfileImage.imageUrl,
        fullname: fullname,
        email: email
    }
}

ProfilePhotoUpload.propTypes = {
    email: PropTypes.string,
    i18n: PropTypes.any,
    fullname: PropTypes.string,
    imageUrl: PropTypes.string,
    removeImageFromStore: PropTypes.func,
    uploadImageToStore: PropTypes.func
};


const mapDispatchToProps = {

    uploadImageToStore,
    removeImageFromStore
}


export default connect(mapStateToProps, mapDispatchToProps)(ProfilePhotoUpload);

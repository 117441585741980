import React, {Component} from 'react'
import {$$} from '../../../helpers/localization'
import Select from '../../shared/Select'
import {
    getResolvedOptions,
    UNIT_TYPES
} from '../../../constants/select_options'
import PropTypes from "prop-types";

export default class MedicationForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="form-group">
                    <input type="text"
                           className={this.props.errors.name ? "custom-error form-control" : "form-control"}
                           value={this.props.name} placeholder={$$('medication_name_label')}
                           name="name" onChange={this.props.onInputChange} required/>
                    <div
                        className={this.props.errors.name ? "custom-invalid-feedback" : "invalid-feedback"}>
                        {this.props.errors.name ? $$(this.props.errors.name) : $$('medication_name_required_message')}
                    </div>
                </div>
                <div className="form-group">
                    <Select
                        name="unit"
                        options={getResolvedOptions(UNIT_TYPES.TYPE)}
                        value={this.props.unit}
                        onChange={this.props.onInputChange}
                        placeHolder={$$('unit_label')}/>
                </div>
                <div className="form-group">
                    <input type="number" step="0.00001"
                           className={this.props.errors.dose ? "custom-error form-control" : "form-control"}
                           value={this.props.dose} placeholder={$$('dose_label')} name="dose"
                           onChange={this.props.onInputChange} required/>
                    <div
                        className={this.props.errors.dose ? "custom-invalid-feedback" : "invalid-feedback"}>
                        {this.props.errors.dose ? $$(this.props.errors.dose) : $$('dose_required_message')}
                    </div>
                </div>
            </div>
        )
    }
}

MedicationForm.propTypes = {
    clearSelectedEntry: PropTypes.func,
    createEntry: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    formDisabled: PropTypes.object,
    getHealthIssueList: PropTypes.func,
    healthIssues: PropTypes.object,
    errors: PropTypes.any,
    dose: PropTypes.any,
    unit: PropTypes.any,
    name: PropTypes.any,
    logbookEntries: PropTypes.array,
    medicalProfile: PropTypes.object,
    onHide: PropTypes.func,
    onInputChange: PropTypes.func,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    show: PropTypes.bool,
    type: PropTypes.string,
    updateEntry: PropTypes.func,
    updateUserMedicalProfile: PropTypes.func
}
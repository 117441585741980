import React, {useEffect, useState} from "react";
import {getPrescriptionDetailsString, isNonMedicinalProduct} from "../../utils/medicationUtils";
import {$$} from "../../helpers/localization";
import {dateTimeUtils} from "../../utils/dateTimeUtils";

export default function PrescriptionList({prescriptions}) {
    const [nonNhisPrescriptions, setNonNhisPrescriptions] = useState(prescriptions.filter(p => !p.nhis_prescription_nrn));
    const [nhisPrescriptions, setNhisPrescriptions] = useState(prescriptions.filter(p => !!p.nhis_prescription_nrn));

    useEffect(() => {
        setNonNhisPrescriptions(prescriptions.filter(p => !p.nhis_prescription_nrn));
        setNhisPrescriptions(prescriptions.filter(p => !!p.nhis_prescription_nrn))
    }, [prescriptions])

    return <div>
        {nonNhisPrescriptions.map(p => <div key={p.id}>
            &bull; {p.name + " - " + getPrescriptionDetailsString(p)}
            {p.notes && <div className="pb-2 pl-2 pr-2 medrec-grey-2 white-space-pre-line">{p.notes}</div>}
        </div>)}
        <NhisPrescriptionList nhisPrescriptions={nhisPrescriptions}/>
    </div>
}

function NhisPrescriptionList({nhisPrescriptions}) {
    const [map, setMap] = useState({});
    const [nonMedicinalProducts, setNonMedicinalProducts] = useState({});

    useEffect(() => {
        const map = {}
        const nonMedicinalProducts = {}
        for (const p of nhisPrescriptions) {
            if (isNonMedicinalProduct(p)) {
                nonMedicinalProducts[p.nhis_prescription_nrn] = p;
                continue;
            }
            let groupCode = p.nhis_prescription_group_code ? p.nhis_prescription_group_code : "A";
            if (groupCode !== "A") {
                continue;
            }

            let nhisP = map[p.nhis_prescription_nrn];
            if (!nhisP) {
                nhisP = [];
                map[p.nhis_prescription_nrn] = nhisP;
            }
            let meds = nhisP[p.nhis_prescription_medication_sequence_id]
            if (!meds) {
                meds = [];
                nhisP[p.nhis_prescription_medication_sequence_id] = meds;
            }
            meds[p.nhis_prescription_dosage_instruction_sequence - 1] = p;
        }
        setMap(map);
        setNonMedicinalProducts(nonMedicinalProducts)
    }, [nhisPrescriptions]);

    return <div>{
        Object.keys(map).map(nrn => {
            return <div key={nrn} className="mt-4">
                <p className="bold mb-2">{
                    $$("nhis_prescription_label")}: {nrn}
                    <br/>
                    {$$("date_label")}: {dateTimeUtils.getFormattedDate(new Date(map[nrn][0][0].server_created_timestamp))}
                </p>
                {map[nrn].map((arr, i) => {
                    return <NhisMedications meds={arr} key={i} />
                })}
                <NhisNonMedicinalProduct p={nonMedicinalProducts[nrn]} />
            </div>
        })
    }</div>
}

function NhisMedications({meds}) {
    return (
        <div key={meds[0].id} className="ml-2">
            &bull; {meds[0].name}
            {meds.map(p => {
                return <div key={p.id}>
                    {p.notes && <div className="pl-2 pr-2 medrec-grey-2">{p.notes.replace(/(?:\n\r|\r\n|\r|\n)/g, "; ")}</div>}
                </div>
            })}
            <hr/>
        </div>
    )
}

function NhisNonMedicinalProduct({p}) {
    if (!p) {
        return null;
    }

    return <div key={p.id} className="ml-2">
        &bull; {$$("nhis_supplement")}
            <div>
                <div className="pl-2 pr-2 medrec-grey-2">{p.notes}</div>
            </div>
        <hr/>
    </div>
}
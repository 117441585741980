import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {LabResultEntry} from './LabResultEntry'
import CenteredModal from '../shared/CenteredModal'
import {deleteLabResult, updateLabResults} from '../../actions/lab-results_actions'
import {connect} from 'react-redux'
import PropTypes from "prop-types";
import {getHealthIssueOptions} from "../../constants/select_options";
import CustomMultiselect from "../shared/CustomMultiSelect";
import {dateTimeUtils} from "../../utils/dateTimeUtils";

export class GroupedLabResults extends Component {
    state = {
        deletedConfirmationModal: {
            show: false
        },
        selectedEntryToDelete: null,

    }

    constructor(props) {
        super(props);
    }

    /**
     * Show confirmation modal when trying to delete an entry
     *
     * @param {entry} key The entry to delete
     */
    showDeletedConfirmationModal = (key) => {
        this.setState({
            deletedConfirmationModal: {show: true},
            selectedEntryToDelete: key
        })
    }


    /**
     * Confirm deletion and remove entry from list
     */
    onDeleteConfirmModal = () => {
        let deletedEntry = this.state.selectedEntryToDelete;
        this.props.deleteLabResult(deletedEntry.id, this.props.selectedUser.id);

        this.setState({
            deletedConfirmationModal: {show: false},
            selectedEntryToDelete: null,
        });

    }

    showEditModal = (entry) => {
        let healthIssuesIds = entry.health_issues_ids;
        if (!healthIssuesIds) {
            healthIssuesIds = []
        }
        this.setState({selectedEntryToEdit: entry, showEditModal: true, entry_health_issues_ids: [...healthIssuesIds]})
    }

    onUpdate = () => {
        let labResult = {...JSON.parse(this.state.selectedEntryToEdit.result), health_issues_ids: [...this.state.entry_health_issues_ids]};
        this.props.updateLabResults(labResult, {userId:this.props.selectedUser.id, laboratory:this.state.selectedEntryToEdit.laboratory}, this.props.selectedUser.id)
            .then(()=>{
                this.setState({showEditModal: false, selectedEntryToEdit: undefined, entry_health_issues_ids:[]})
            })
    }

    getHealthIssueList = (entry) => {
        let healthIss = [];
        if (entry.health_issues_ids) {
            if (this.props.healthIssues && this.props.healthIssues.entries && this.props.healthIssues.entries.length > 0) {
                entry.health_issues_ids.map((ids) => {
                    this.props.healthIssues.entries.map((h) => {
                        if (ids === h.id) {
                            healthIss.push(h)
                        }
                    });
                });
                return healthIss;
            }
        }
    }

    getLaboratoryName = (entry) => {
        if (this.props.labs) {
            return this.props.labs.find(l => l.lab === entry.laboratory).name;
        }
    }

    render() {

        const labResults = this.props.labResults && this.props.labResults.length > 0 ? this.props.labResults.map((entry, idx) => {
            return <LabResultEntry
                key={idx}
                index={idx}
                labs={this.props.labs}
                entry={entry}
                filters={this.props.filters}
                fetchSelectedUserHealthIssues={this.props.fetchSelectedUserHealthIssues}
                onLabResultChange={this.props.onLabResultChange}
                isSelected={this.props.isSelected}
                selectedLabResult={this.props.selectedLabResult}
                healthIssues={this.props.healthIssues}
                showDeletedConfirmationModal={this.showDeletedConfirmationModal}
                showEditModal={this.showEditModal}
            />
            // eslint-disable-next-line no-undef
        }, this) : noData;

        return (

            <div className="card-body" style={{"marginLeft": "-0.5rem", "marginRight": "0rem"}}>
                {labResults[0]}
                <CenteredModal title={$$('mark_lab_result_entry_deleted_modal_title')}
                               dialogClassName='doc-delete-modal'
                               show={this.state.deletedConfirmationModal.show}
                               onHide={() => this.setState({deletedConfirmationModal: {show: false}})}
                               cancelBtnLabel={$$('cancel_btn')}
                               onConfirm={this.onDeleteConfirmModal}
                               id="delete-modal-title"
                               className="center-delete-modal"
                               idFooter="footer-delete-modal"
                               confirmBtnLabel={$$('delete_label')}
                               confirmBtnClass="danger"
                               idBtnPrimary="btn-danger"
                               idBtnSecondary="btn-secondary">
                    {$$('mark_lab_result_entry_deleted_modal_message')}
                    <div className="bin-align">
                        <i className="fas fa-trash-alt fa-3x"/>
                    </div>
                </CenteredModal>
                <CenteredModal
                    title={$$('edit_label')}
                    show={this.state.showEditModal}
                    onHide={() => this.setState({showEditModal: false, selectedEntryToEdit: undefined, entry_health_issues_ids:[]})}
                    onConfirm={this.onUpdate}
                    confirmBtnLabel={$$('update_btn')}
                >
                    {this.state.selectedEntryToEdit && <div style={{height: "300px"}} className={"p-3"}>
                        <div className={"mb-3"}>
                            {this.getLaboratoryName(this.state.selectedEntryToEdit)} - {dateTimeUtils.getFormattedDate(this.state.selectedEntryToEdit.result_date)}
                        </div>
                        <CustomMultiselect
                            options={getHealthIssueOptions(this.props.healthIssues.entries)}
                            selectedValues={getHealthIssueOptions(this.getHealthIssueList({health_issues_ids:this.state.entry_health_issues_ids}))}
                            onSelect={(item) => {
                                this.setState({entry_health_issues_ids: [...this.state.entry_health_issues_ids, item.value.id]});
                            }}
                            onRemove={(item) => {
                                this.setState({entry_health_issues_ids: [...this.state.entry_health_issues_ids.filter(i=>i !== item.value.id)]});
                            }}
                            displayValue="text"
                            placeholder={$$('select_health_issue_label')}
                            closeIcon='cancel'
                            avoidHighlightFirstOption={true}/>
                    </div>}
                </CenteredModal>
            </div>
        )
    }
}

GroupedLabResults.propTypes = {
    deleteLabResult: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    filters: PropTypes.object,
    healthIssues: PropTypes.object,
    i18n: PropTypes.object,
    isLast: PropTypes.bool,
    isSelected: PropTypes.func,
    labResults: PropTypes.array,
    selectedLabResult: PropTypes.any,
    labs: PropTypes.array,
    onLabResultChange: PropTypes.func,
    resultDate: PropTypes.string,
    selectedUser: PropTypes.object
}

export default connect(null, {
    deleteLabResult,
    updateLabResults
})(GroupedLabResults)

import React, {Component} from "react";
import FormLoading from "./FormLoading";
import PropTypes from "prop-types";

export default class FormWithLoading extends Component {

    render() {
        return (
            <form onSubmit={ this.props.onSubmit}
                  className={this.props.className}
                  id = {this.props.id}
                  noValidate={this.props.noValidate}>
                {this.props.children}
                <FormLoading
                    formDisabled = {this.props.formDisabled}
                    currentForm = {this.props.currentForm}
                    marginTop = {this.props.marginTop}
                    marginLeft = {this.props.marginLeft}
                />
            </form>
        )
    }
}

FormWithLoading.propTypes = {
    children: PropTypes.any,
    id: PropTypes.any,
    className: PropTypes.string,
    currentForm: PropTypes.any,
    formDisabled: PropTypes.object,
    marginLeft: PropTypes.string,
    marginTop: PropTypes.string,
    noValidate: PropTypes.bool,
    onSubmit: PropTypes.func
};

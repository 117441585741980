import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {formatUtils} from '../../utils/formatUtils';
import LabResultTable from './LabResultTable';
import LabResultMicrobiologyTable from './LabResultMicrobiologyTable';
import CenteredModal from "../shared/CenteredModal";
import HealthIssueTag from '../health/HealthIssueTag';
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";
import {ReactComponent as FileIcon} from "../../../public/media/icons/svg/Design/File.svg";
import classNames from "classnames";
import PropTypes from "prop-types";
import {dateTimeUtils} from "../../utils/dateTimeUtils";


export class LabResultEntry extends Component {
    state = {
        expandTable: false,
        result: null,
        items: [],
        microbiologyTableWidth: null
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.updateItemsInState()
    }

    updateItemsInState = () => {
        let i;
        let item;
        const result = formatUtils.formatAsJSON(this.props.entry.result);
        let items = [];
        for (i = 0; i < result.tests.length; ++i) {
            for (let j = 0; j < result.tests[i].groups.length; ++j) {
                item = {};
                item.group = result.tests[i].groups[j].label;
                item.tests = result.tests[i].groups[j].tests.map(t => t.label);
                items.push(item);
            }
        }

        for (i = 0; i < result.microbiology.length; ++i) {
            item = {};
            item.group = result.microbiology[i].label;
            item.tests = [].concat(result.microbiology[i].test);
            items.push(item);
        }

        this.setState({items: items});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters.laboratory !== this.props.filters.laboratory
            || prevProps.filters.before_date_time !== this.props.filters.before_date_time
            || prevProps.filters.after_date_time !== this.props.filters.after_date_time) {
            this.setState({expandTable: false, result: null});
            this.updateItemsInState();
        }
    }

    /**
     * Handles click event on a laboratory result
     *
     * @param {object} e the event object
     */
    onLaboratoryResultClick = (e) => {
        e.preventDefault();
        const shouldExpandTable = !this.state.expandTable;
        if (shouldExpandTable) {
            this.setState({expandTable: shouldExpandTable, result: formatUtils.formatAsJSON(this.props.entry.result)});
        } else {
            this.setState({expandTable: shouldExpandTable, result: null});
        }
    }

    /**
     * Returns the laboratory name of the current lab result entry
     *
     * @returns {string} the laboratory name name of the current entry
     */
    getLaboratoryName = () => {
        if (this.props.labs) {
            return this.props.labs.find(l => l.lab === this.props.entry.laboratory)?.name;
        }
    }

    /**
     * Returns the DOM elements representing the group and the tests of the particular lab result entry
     *
     * @returns {Array} the created DOM elements based on the lab entry
     */
    getGroupsAndTests = () => {
        if (this.state.items) {
            return this.state.items.map((item, index) => {
                return <div key={index}>
                    <b>{formatUtils.format(item.group)}</b>
                    <br/>
                    {item.tests.map((t) => {
                        return <div key={t} className={"non-test-cells"}>
                            <span>{t}</span>
                            <br/>
                        </div>
                    })}
                </div>
            });
        }
    }

    /**
     * Creates the health issues list that entry is part of
     *
     * @param {object} entry - the lab result object
     * @returns {ReactElement}
     */
    getHealthIssueList = (entry) => {
        let healthIss = [];
        if (entry.health_issues_ids) {
            if (this.props.healthIssues && this.props.healthIssues.entries && this.props.healthIssues.entries.length > 0) {
                entry.health_issues_ids.map((ids) => {
                    this.props.healthIssues.entries.map((h) => {
                        if (ids === h.id) {
                            healthIss.push(h.name)
                        }
                    });
                });
            }
        }
        let healthIssueList2 = healthIss.map((p, idy) => {
            return <HealthIssueTag
                key={idy}
                className='patient-health-issue-list patient-health-issue-list-mobile'
                styles={{'marginLeft': '10%'}}
                healthIssue={p}
            />
        });
        return <div className='text-center appointment-health-issue-list-cell' style={{"paddingTop": "0"}}>
            <div style={{'justifyContent': 'left'}}>{healthIssueList2}</div>
        </div>;
    }

    /**
     * Sets the microbiology table width equal to the tests table's width
     *
     * @param {number} width the value of the width in the tests table
     */
    handleTestTableWidthChange = (width) => (
        this.setState({microbiologyTableWidth: width})
    )
    selectLabResult = () => {
        this.props.onLabResultChange(this.props.entry.id)
    }

    render() {

        let activeClass = classNames('appointment-containter', {
            'appointment-active': (this.props.selectedLabResult === this.props.entry.id)
        });

        return (<div className={activeClass}
                     onClick={this.selectLabResult}
                     style={{'marginBottom': '20px', 'border': 'none'}}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-12" style={{'margin': 'none'}}>
                        <div className="row" style={{'margin': 'none'}}>
                            <div className="col-sm-12 col-xl-2" style={{'marginTop': '20px', 'display': 'flex'}}>
                                {this.getLaboratoryName()}
                            </div>
                            <div className="col-sm-12 col-xl-4 results-lab-results">
                                {this.getGroupsAndTests()}
                            </div>
                            <div className="col-sm-12 col-xl-2 health-issues-lab-results ">
                                {this.getHealthIssueList(this.props.entry)}
                            </div>
                            <div className="col-sm-12 col-xl-2" style={{'marginTop': '20px', 'display': 'flex'}}>
                                {dateTimeUtils.getFormattedDate(this.props.entry.result_date)}
                            </div>
                            <div className="col-sm-12 col-xl-2" style={{'marginTop': '20px', 'display': 'flex'}}>
                                <div style={{'display': 'inline'}}>
                                    <FileIcon className="svg-icon"
                                              height="17px"
                                              width="17px"
                                              onClick={this.onLaboratoryResultClick}
                                              style={{'fill': '#02555B'}}/>
                                </div>
                                {!this.props.noControls && <DownloadEditDeleteButtons
                                    handleEditClick={() => {
                                        this.props.showEditModal(this.props.entry)
                                    }}
                                    handleDeleteClick={() => this.props.showDeletedConfirmationModal(this.props.entry)}

                                />
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                {(this.props.selectedLabResult && this.props.selectedLabResult === this.props.entry.id) && this.props.entry.notes &&
                                <div style={{
                                    'fontSize': '16px',
                                    'color': '#9BABB6',
                                    'paddingTop': '3%',
                                    'marginLeft': '3%'
                                }}>
                                    {this.props.entry.notes}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr style={{'borderTop': '0.2px solid #F2F2F2'}}/>
            <CenteredModal
                show={this.state.expandTable}
                size="md"
                onHide={() => this.setState({expandTable: false})}
                onConfirm={() => this.setState({expandTable: false})}
                confirmBtnLabel={$$('OK')}
                cancelBtnLabel={$$('cancel_btn')}
            >
                <LabResultTable resize={this.handleTestTableWidthChange} result={this.state.result}
                                laboratory={this.props.entry.laboratory}/>
                <LabResultMicrobiologyTable width={this.state.microbiologyTableWidth}
                                            result={this.state.result}/>
                <br/>
            </CenteredModal>
        </div>)
    }
}

LabResultEntry.propTypes = {
    entry: PropTypes.object,
    fetchSelectedUserHealthIssues: PropTypes.func,
    filters: PropTypes.object,
    healthIssues: PropTypes.object,
    selectedLabResult: PropTypes.any,
    index: PropTypes.number,
    isSelected: PropTypes.func,
    labs:  PropTypes.array,
    onLabResultChange: PropTypes.func,
    showDeletedConfirmationModal: PropTypes.func
}

export default LabResultEntry

import React, {Component} from 'react'
import {$$} from '../../../helpers/localization';
import noImage from '../../../../public/media/profile/no_image.png'
import {getUserImage} from '../../../service/users_service';
import {
    COMPLETED,
    IN_PROGRESS,
    INITIATED,
    MISSED,
    REJECTED
} from '../../../constants/video_message_status'
import moment from 'moment'
import classnames from 'classnames'
import PropTypes from "prop-types";

export class ChatUserListItem extends Component {

    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
    }

    /**
     * Fetch the user image if the user changes.
     *
     * @param {object} prevProps - the previous component properties object
     */
    componentDidUpdate(prevProps) {
        if (this.imgRef.current && this.imgRef.current.data && prevProps.userId !== this.props.userId) {
            this.imgRef.current.src = "";
        }
        if (prevProps.userId !== this.props.userId) {
            this.fetchUserImage();
        }
    }

    /**
     * Fetch the user image when the component mounts.
     */
    componentDidMount() {
        if (this.imgRef.current && this.imgRef.current.data) {
            this.imgRef.current.src = "";
        }
        if (this.props.userId) {
            this.fetchUserImage();
        }
    }

    /**
     * Fetch selected user image, if there is not found, display placeholder
     */
    fetchUserImage = () => {
        try {
            getUserImage(this.props.userId).then((res) => {
                this.imgRef.current.type = "image/jpg"
                this.imgRef.current.src = res;
            }).catch(() => {
            });
        } finally {
            this.imgRef.current.type = "image/png"
            this.imgRef.current.src = noImage;
        }
    }

    /**
     * Get the vide message text to display in the last message item
     *
     * @returns {string} text to display for the video call
     */
    getVideoMessageText = () => {
        if (this.props.message.type === "VIDEO") {
            switch (this.props.message.video_status) {
                case MISSED:
                    return <b>{$$('missed_call_label')}</b>;
                case REJECTED:
                    return <b>{$$('rejected_call_label')}</b>;
                case IN_PROGRESS:
                    return <b>{$$('in_progress_call_label')}</b>;
                case COMPLETED:
                    return <span><b>{$$('completed_call_label')}</b>: {moment.utc(this.props.message.video_duration).format('HH:mm:ss')}</span>;
            }
        }
        return '';
    }
    getTimeLabel = () => {
        if (this.props.message?.date_time > 0) {
            moment.locale(this.props.lang)
            return (moment(this.props.message.date_time).fromNow())
        }
        return null
    }

    render() {
        const activeClass = classnames('list-group-item list-group-item-action flex-column align-items-start', {
            'selected-message': this.props.isActive
        });

        return (
            <a href="./ChatUserListItem#" className={activeClass} onClick={(e) => {
                e.preventDefault();
                this.props.onItemClick(this.props.message);
            }}>
                <div className={"dropdown-link"}>
                    <div>
                        <div className="user-chat-info">
                            <img className="user-chat-image small-margin" ref={this.imgRef}
                                 style={{"width": "43px", "height": "43px"}} alt="USer chat image"/>
                            <div className={"chat-blurb"}>
                                <div><b>{this.props.isSent ? (
                                    this.props.message.to.length > 27 ?
                                        this.props.message.to.substr(0, 27) + "..." : this.props.message.to
                                ) : (
                                    this.props.message.from.length > 27 ?
                                        this.props.message.from.substr(0, 27) + "..." : this.props.message.from
                                )}</b></div>
                                {this.props.message.type === 'TEXT' &&
                                    <div className="mb-1 shortened-message">{this.props.message.message}</div>
                                }
                                {(this.props.message.type === "VIDEO" && this.props.message.video_status !== INITIATED) &&
                                    <div className="mb-1 shortened-message">{this.getVideoMessageText()}</div>
                                }
                            </div>
                            {(this.props.unreadMessages && this.props.unreadMessages.count > 0) &&
                                <span
                                    className="message-count-label chat-blurb chat-unread-messages">{this.props.unreadMessages.count}</span>
                            }
                        </div>
                    </div>
                    <div>
                        <div className=" chat-blurb message-time-from-now">
                            {this.getTimeLabel()}
                        </div>
                    </div>
                    <div className="message-item-line"/>
                </div>
            </a>
        )
    }
}

ChatUserListItem.propTypes = {
    isActive: PropTypes.bool,
    isSent: PropTypes.bool,
    message: PropTypes.object,
    unreadMessages: PropTypes.object,
    onItemClick: PropTypes.func,
    userId: PropTypes.string
}


export default ChatUserListItem

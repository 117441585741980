import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import moment from 'moment'
import _ from 'underscore'
import GroupedLabResults from './GroupedLabResults';
import LabResultsForm from './LabResultsForm'
import ReactDOM from "react-dom";
import no_labs from '../../resources/images/no_labs.png';
import {infoUtils} from '../../utils/infoUtils'
import PropTypes from "prop-types";

export class LabResults extends Component {
    state = {
        addUpdateModal: {
            show: false
        },
        selectedEntry: null,
        selectedLabResult: null,
        loadComplete: false
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let params = {
            before_date_time: moment().valueOf(),
            size: 500
        };
        this.props.fetchEntries(this.props.selectedUser.id, params).then(
            () => {
                this.setState({
                        loadComplete: true
                    }
                )
            });
    }

    componentWillUnmount() {
        this.props.clearUserLabResults();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.filterAndSelectFirst(prevProps)
    }


    selectFirst = (filteredResults) => {
        if (filteredResults.length > 0) {
            this.setState({
                selectedLabResult: filteredResults[0].id
            })
        }
    }

    /**
     * Show add/update modal
     *
     * @param {entry} entry The entry to modify or null
     */
    showAddUpdateModal = (entry = null) => {
        if (entry) {
            this.setState({
                selectedEntry: entry,
                addUpdateModal: {show: true}
            })
        } else {
            this.setState({
                selectedEntry: null,
                addUpdateModal: {show: true}
            })
        }
    }

    /**
     * Hide the add/update modal
     */
    onHideAddUpdateModal = () => {
        this.setState({
            addUpdateModal: {
                show: false
            },
            selectedEntry: ''
        });
    }
    /**
     * Fetch selected user lab results
     *
     * @param {object} e  - the event
     */
    onLoadMore = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let params = {
            before_date_time: moment().valueOf(),
            size: 500
        };
        if (this.props.selectedUser.id && (this.props.labResults.request.finished === undefined || this.props.labResults.request.finished)) {
            if (this.props.labResults.entries && this.props.labResults.entries.length > 0) {
                const entriesLength = this.props.labResults.entries.length;
                params.before_date_time = new Date(this.props.labResults.entries[entriesLength - 1].result_date).getTime();
            }
            this.props.fetchEntries(this.props.selectedUser.id, params);
        }
    }

    getNoDataObject = (label) => {
        return {
            imgClass: 'no-entry-image',
            objClass: 'no-data-object-documents',
            primaryLabelClass: 'no-hospitals-primary-label',
            src: no_labs,
            primaryLabel: label,
        }
    }

    /**
     * An event handler triggered when a new labResult is chosen
     * from the list of labResults
     *
     * @param {object} labResult - the labResult object
     */
    onLabResultChange = (labResult) => {
        this.setState({
            selectedLabResult: labResult
        });
    }


    isSelected = (id) => {
        return id === this.props.selectedLabResult;
    }

    filterAndSelectFirst = (prevProps) => {
        const filters = this.props.filters;
        if (this.props.labResults && this.props.labResults.entries && this.props.labResults.entries.length > 0 &&
            (this.props.labResults.entries !== prevProps.labResults.entries || filters !== prevProps.filters)) {
            const filteredLabResults = this.props.labResults.entries.filter(labResult => {
                return !!((!filters.laboratory || (labResult.laboratory && filters.laboratory.toLowerCase() === labResult.laboratory.toLowerCase())) &&
                    (!filters.after_date_time || moment(labResult.result_date).startOf('day').valueOf() >= moment(filters.after_date_time).startOf('day').valueOf()));
            });
            this.selectFirst(filteredLabResults);
        }

    }


    /**
     * Get the lab results to show. Filter the lab results based on the given input.
     *
     * @returns {any} array of GroupedLabResults
     */
    getLabResults = () => {
        let noLabsForFilters = this.getNoDataObject($$('lab_results_no_data_for_filters_label'))
        let noLabs = this.getNoDataObject($$('lab_results_no_data_label'))
        const filters = this.props.filters;
        if (this.props.labResults && this.props.labResults.entries && this.props.labResults.entries.length > 0) {
            const filteredLabResults = this.props.labResults.entries.filter(labResult => {
                return (!filters.laboratory || (labResult.laboratory && filters.laboratory.toLowerCase() === labResult.laboratory.toLowerCase())) &&
                    (!filters.after_date_time || moment(labResult.result_date).startOf('day').valueOf() >= moment(filters.after_date_time).startOf('day').valueOf());
            });

            const groupedByDate = _.groupBy(filteredLabResults, (result) => moment(result.result_date).format('YYYY-MM-DD'));
            const keys = Object.keys(groupedByDate);
            if (keys.length > 0) {
                return keys.map((resultDate, idx) => {
                    return <GroupedLabResults
                        resultDate={resultDate}
                        key={idx}
                        isLast={keys.length - 1 === idx}
                        labs={this.props.labs}
                        labResults={groupedByDate[resultDate]}
                        i18n={this.props.i18n}
                        onLabResultChange={this.onLabResultChange}
                        isSelected={this.isSelected}
                        selectedLabResult={this.state.selectedLabResult}
                        filters={this.props.filters}
                        fetchSelectedUserHealthIssues={this.props.fetchSelectedUserHealthIssues}
                        healthIssues={this.props.healthIssues}
                        selectedUser={this.props.selectedUser}/>
                });
            } else {
                return infoUtils.noData(noLabsForFilters)
            }
        } else if (this.state.loadComplete) {
            return infoUtils.noData(noLabs)
        }
    }

    /**
     * Creates an array with only the health issues that the entry is part of.
     * @param {*} entry - log entry
     */
    getHealthIssueList = (entry) => {
        let healthIss = [];
        if (entry.health_issues_ids) {
            if (this.props.healthIssues && this.props.healthIssues.entries && this.props.healthIssues.entries.length > 0) {
                entry.health_issues_ids.map((ids) => {
                    this.props.healthIssues.entries.map((h) => {
                        if (ids === h.id) {
                            healthIss.push(h)
                        }
                    });
                });
                return healthIss;
            }
        }
    }

    /**
     * If any of the lab results is part of any of the selected laboratories , then we will display the table header
     * @returns Boolean value
     */
    hasLabResults = () => {
        let hasLabs = false;
        this.props.labResults.entries.map(entry => {

            if (entry.laboratory === this.props.filters.laboratory || this.props.filters.laboratory === '') {
                hasLabs = true;
            }
        });
        return hasLabs;
    }

    render() {
        let domNode = document.getElementById('new-symptom-btn');
        const hasMore = !this.props.labResults.isLastPage && (this.props.labResults.request.finished === undefined || this.props.labResults.request.finished) && !this.props.labResults.request.error;
        return (
            <div className="card documents-inner-card">
                {!this.state.loadComplete && <div className={"med-search-loader"}/>}
                <div className="card-body inner-card-body">
                    <div className="text-right">
                        {!this.state.addUpdateModal.show && ReactDOM.createPortal(
                            <div>
                                <div className="blue-button-container" onClick={() => {
                                    this.showAddUpdateModal()
                                }}>
                                    <i className='flaticon2-plus' aria-hidden="true"
                                       style={{"display": "table-cell", "verticalAlign": "middle", 'border': 'none'}}/>
                                </div>
                            </div>,
                            domNode
                        )}
                    </div>
                    <br/>
                    <div ref={(ref) => this.listRef = ref}>
                        <div className="list-group">
                            <div className="lab-results-mobile">
                                {this.getLabResults()}
                            </div>
                        </div>
                        {hasMore && this.props.labResults.entries.length > 0 && this.hasLabResults() &&
                            <div className="text-center" style={{"marginTop": "10px"}}>
                                <a href="#" onClick={this.onLoadMore}>{$$('load_more')}</a>
                            </div>}
                    </div>
                </div>
                <LabResultsForm
                    selectedUser={this.props.selectedUser}
                    show={this.state.addUpdateModal.show}
                    onHide={this.onHideAddUpdateModal}
                    selectedEntry={this.state.selectedEntry}
                    filters={this.props.filters}
                    labs={this.props.labs}
                    laboratoryOptions={this.props.laboratoryOptions}
                    labDates={this.props.labDates}
                    changeLabResultsFilters={this.props.changeLabResultsFilters}
                    clearLabDates={this.props.clearLabDates}
                    getLabDates={this.props.getLabDates}
                    healthIssues={this.props.healthIssues}
                    i18n={this.props.i18n}
                    getHealthIssueList={this.getHealthIssueList}
                    fetchSelectedUserDocuments={this.props.fetchSelectedUserDocuments}
                />
            </div>
        )
    }
}

LabResults.propTypes = {
    changeLabResultsFilters: PropTypes.func,
    clearLabDates: PropTypes.func,
    fetchEntries: PropTypes.func,
    fetchSelectedUserDocuments: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    getLabDates: PropTypes.func,
    filters: PropTypes.object,
    healthIssues: PropTypes.object,
    i18n: PropTypes.object,
    selectedLabResult: PropTypes.any,
    labDates: PropTypes.object,
    labResults: PropTypes.object,
    laboratoryOptions: PropTypes.array,
    labs: PropTypes.array,
    selectedUser: PropTypes.object,
    clearUserLabResults: PropTypes.func
}

export default LabResults

import React, {Component} from "react";
import PropTypes from "prop-types";

class UserItem extends Component {
    constructor(props) {
        super(props);
    }

    selected = () => {
        if (this.props.selected) {
            return "selected-group-list-item-green"
        }
        return ""
    }

    render() {
        return (

            <a href="#"
               className={"list-group-item list-group-item-action flex-column align-items-start " + this.selected()}
               onClick={(e) => {
                   e.preventDefault();
                   this.props.onItemClick(this.props.id, this.props.name, this.props.user);
               }}>
                <div className="patient-fullname organisation-selection">
                    <b>{this.props.name}</b>
                </div>
            </a>
        )
    }
}

UserItem.propTypes = {
    onItemClick: PropTypes.func,
    id: PropTypes.string,
    user: PropTypes.object,
    name: PropTypes.string,
    selected: PropTypes.bool

}

export default UserItem;
import moment from 'moment'
import {
    DURATION_FOR_X_DAYS,
    DAILY,
    EVERY_X_DAYS,
    SPECIFIC_DAYS_OF_WEEK,
    CYCLE,
    DURATION_UNTIL_DATE
} from '../constants/medications_constants';
import {ONGOING} from '../constants/call_status';
import { compareAsc, isSameDay, isSameMinute, addDays, differenceInDays, isBefore } from 'date-fns';

const MS_IN_A_DAY = 1000 * 60 * 60 * 24;

export const medicationUtils = {
    getTimesArrayXTimesADay,
    getTimesArrayEveryXHours
}

/**
 * Calculates the end date based on the plan , to further look if our selected date from the calendar is within that limit
 * @param {object} medPlan 
 * @returns 
 */
 export function calculateMedDuration(medPlan){
    let endDate = 0
    if(medPlan.durationType == DURATION_FOR_X_DAYS) {
        endDate = medPlan.ends;
    } else if(medPlan.durationType == DURATION_UNTIL_DATE) {
        endDate = medPlan.ends; //endDate in milliseconds
    } else if(medPlan.durationType == DURATION_FOR_X_DAYS && medPlan.scheduleType == CYCLE){
        endDate = medPlan.durationInDays; //special case where as endDate I have duration in days number
    }
    return endDate;
}


/**
 * Returns the times array of the medication intake in the plan
 * @param {object} medPlan 
 * @returns 
 */
export function calculateMedHours(medPlan){
    let times = medPlan.schedule.times;//format in HH:mm
    return times;
}


/**
 * Returns the weekend times array of the medication intake
 * @param {*object} medPlan 
 * @returns 
 */
export function calculateMedHoursWeekend(medPlan){
    let times=[...medPlan.schedule.times];
    if (medPlan.schedule.weekends.length != 0 && medPlan.schedule.weekendTimes) {
        times = medPlan.schedule.weekendTimes;//format in HH:mm
    }
    return times;
}


/**
 * This function updates the medication intake schedule by adding new intake hours if they are not present, or by updating an existing
 * hour with only adding med_plan_ids
 * @param {*} times - intake times array , weekdays' or weekends' based on specific plan 
 * @param {*} medicationsIntakeSchedule - the times schedule we want to build for the selected date , if it rests within the plan; array of medicationIntake objects
 * @param {*} medPlan - the plan from where we gonna take the times and all the conditions
 * @param {*} medicationIntake - one object made of a time and a list of med_plan_ids
 * @returns 
 */
export function updateMedIntakeSchedule (times, medicationsIntakeSchedule, medPlan, medicationIntake, sameDate, selectedDate){
    times.map(time => {
        time = time.substring(0, time.lastIndexOf(":"));
        let isTimePresent = false;
        //the case if any of the hours is entered in medicationsIntakeSchedule and we need to add just the id of the new plan
        medicationsIntakeSchedule.map(entry => {
            if(entry.hour == time){
                isTimePresent = true;
                if(isInRange(time, selectedDate, medPlan)){
                    entry.medPlanIds.push(medPlan.id)
                }
            }
        });
        //the case when no such hours are entered before in medicationsIntakeSchedule, we enter all of them
        if(isTimePresent == false){
            medicationIntake = {
                hour: time,
                medPlanIds: [medPlan.id]
            }
            if(isInRange(time, selectedDate, medPlan)){
                medicationsIntakeSchedule.push(medicationIntake);
            }
        }
    });
    return medicationsIntakeSchedule;
}

function isInRange(time, selectedDate, medPlan) {
    let timeInstant = moment(selectedDate).set("hour", time.split(":")[0]).set("minute", time.split(":")[1]).valueOf();
    return (timeInstant >= medPlan.starts && (medPlan.ends === 0 || timeInstant <= medPlan.ends))
}


/**
 * Calculates the medication intake hours for plans with schedule type DAILY
 * @param {*} medPlan 
 * @param {*} dateMillisec 
 * @param {*} actualDay 
 * @param {*} oneWeekendDay 
 * @param {*} times 
 * @param {*} medicationIntake 
 * @param {*} medicationsIntakeSchedule 
 * @returns 
 */
export function scheduleTypeDaily(starts, medPlan, dateMillisec, actualDay, oneWeekendDay, times, medicationIntake, medicationsIntakeSchedule){
    if(medPlan.durationType == ONGOING){
        //case if our date is one of the checked weekend days
        if((medPlan.schedule.weekends!==undefined && medPlan.schedule.weekends.length == 1 && actualDay == oneWeekendDay) || (medPlan.schedule.weekends!==undefined && medPlan.schedule.weekends.length == 2 && (actualDay == 6 || actualDay == 0))){
            times = calculateMedHoursWeekend(medPlan);
        } else {
            times = calculateMedHours(medPlan); //the times array for each plan for week days
        }
        medicationsIntakeSchedule = updateMedIntakeSchedule(times,medicationsIntakeSchedule, medPlan, medicationIntake, isSameDay(dateMillisec, starts), dateMillisec);
    }
    //we get the endDate to see if the date is within the plan
    let endDate = calculateMedDuration(medPlan);
    if(dateMillisec < endDate){
        //case if our date is one of the checked weekend days
        if((medPlan.schedule.weekends!==undefined && medPlan.schedule.weekends.length == 1 && actualDay == oneWeekendDay) || (medPlan.schedule.weekends!==undefined && medPlan.schedule.weekends.length == 2 && (actualDay == 6 || actualDay == 0))){
            times = calculateMedHoursWeekend(medPlan);
        } else {
            times = calculateMedHours(medPlan); //the times array for each plan for week days
        }
        medicationsIntakeSchedule = updateMedIntakeSchedule(times,medicationsIntakeSchedule, medPlan, medicationIntake, isSameDay(dateMillisec, starts), dateMillisec);
    }

    return medicationsIntakeSchedule;
}


/**
 * Calculates the medication intake hours for plans with schedule type EVERY_X_DAYS
 * @param {*} date 
 * @param {*} medPlan 
 * @param {*} dateMillisec 
 * @param {*} actualDay 
 * @param {*} oneWeekendDay 
 * @param {*} times 
 * @param {*} medicationIntake 
 * @param {*} medicationsIntakeSchedule 
 * @returns 
 */
export function scheduleTypeEveryXdays(starts, date, medPlan, dateMillisec, actualDay, oneWeekendDay, times, medicationIntake, medicationsIntakeSchedule){
    let selectedDate = date;
    selectedDate.setHours('00');
    selectedDate.setMinutes('00');
    selectedDate.setSeconds('00');

    let lastDate;
    if(medPlan.durationType === DURATION_FOR_X_DAYS){
        lastDate = calculateMedDuration(medPlan);
    } else if(medPlan.durationType === DURATION_UNTIL_DATE){
        lastDate = medPlan.ends;
    } else {
        lastDate = medPlan.starts + 10000 * MS_IN_A_DAY;
    }
    let startDate = starts;

    if (isBefore(date, startDate) || isBefore(lastDate, date)) {
        return medicationsIntakeSchedule;
    }

    let days = differenceInDays(date, startDate);
    if (days % (medPlan.scheduleDaysInactive+1) === 0) { //we should take this day
        //case if our date is one of the checked weekend days
        if((medPlan.schedule.weekends!==undefined && medPlan.schedule.weekends.length == 1 && actualDay == oneWeekendDay)
            || (medPlan.schedule.weekends!==undefined && medPlan.schedule.weekends.length == 2 && (actualDay == 6 || actualDay == 0))) {
            times = calculateMedHoursWeekend(medPlan);
        } else {
            times = calculateMedHours(medPlan); //the times array for each plan for week days
        }
        medicationsIntakeSchedule = updateMedIntakeSchedule(times, medicationsIntakeSchedule, medPlan, medicationIntake, isSameDay(starts, date), dateMillisec);
    }
    return medicationsIntakeSchedule;
}


/**
 * Calculates the medication intake hours for plans with schedule type SPECIFIC_DAYS_OF_WEEK
 * @param {*} medPlan 
 * @param {*} dateMillisec 
 * @param {*} actualDay 
 * @param {*} oneWeekendDay 
 * @param {*} times 
 * @param {*} medicationIntake 
 * @param {*} medicationsIntakeSchedule 
 * @returns 
 */
export function scheduleTypeSpecificDays(starts, medPlan, dateMillisec, actualDay, oneWeekendDay, times, medicationIntake, medicationsIntakeSchedule){
    let endDate ;
    if(actualDay == 0){
        actualDay = 7;
    }
    if(medPlan.durationType == DURATION_FOR_X_DAYS || medPlan.durationType == DURATION_UNTIL_DATE) {
        endDate = calculateMedDuration(medPlan);
    } else if (medPlan.durationType == ONGOING){
        medPlan.schedule.weekdays.map(weekday => {
            if(weekday == actualDay){
                //case if our date is one of the checked weekend days
                if((medPlan.schedule.weekends!==undefined && medPlan.schedule.weekends.length == 1 && actualDay == oneWeekendDay) || (medPlan.schedule.weekends!==undefined && medPlan.schedule.weekends.length == 2 && (actualDay == 6 || actualDay == 0))){
                    times = calculateMedHoursWeekend(medPlan);
                } else {
                    times = calculateMedHours(medPlan); //the times array for each plan for week days
                }
                medicationsIntakeSchedule = updateMedIntakeSchedule(times,medicationsIntakeSchedule, medPlan, medicationIntake, isSameDay(starts, dateMillisec), dateMillisec);
            }
        });
    }
    if(dateMillisec < endDate){
        medPlan.schedule.weekdays.map(weekday => {
            if(weekday == actualDay){
                //case if our date is one of the checked weekend days
                if((medPlan.schedule.weekends!==undefined && medPlan.schedule.weekends.length == 1 && actualDay == oneWeekendDay) || (medPlan.schedule.weekends!==undefined && medPlan.schedule.weekends.length == 2 && (actualDay == 6 || actualDay == 0))){
                    times = calculateMedHoursWeekend(medPlan);
                } else {
                    times = calculateMedHours(medPlan); //the times array for each plan for week days
                }
                medicationsIntakeSchedule = updateMedIntakeSchedule(times,medicationsIntakeSchedule, medPlan, medicationIntake, isSameDay(starts, dateMillisec), dateMillisec);
            }
        });
    }
    return medicationsIntakeSchedule;
}


/**
 * Calculates the medication intake hours for plans with schedule type CYCLE
 * @param {*} medPlan 
 * @param {*} dateMillisec 
 * @param {*} times 
 * @param {*} medicationIntake 
 * @param {*} medicationsIntakeSchedule 
 * @returns 
 */
export function scheduleTypeCycle(starts, medPlan, dateMillisec, actualDay, oneWeekendDay, times, medicationIntake, medicationsIntakeSchedule){
    let nrOfDaysItLasts;
    let lastDate;
    if(medPlan.durationType === DURATION_FOR_X_DAYS){
        nrOfDaysItLasts = medPlan.durationInDays;
        lastDate = medPlan.ends;
    } else if(medPlan.durationType === DURATION_UNTIL_DATE){
        let durationMillisec = medPlan.ends - medPlan.starts;
        nrOfDaysItLasts = Math.ceil(durationMillisec/MS_IN_A_DAY);
        lastDate = medPlan.ends;
    } else {
        nrOfDaysItLasts = 365;//one year
        lastDate = medPlan.starts + 30 * MS_IN_A_DAY;
    }
    let startDate = starts;
    let onePeriod = (medPlan.scheduleDaysInactive + medPlan.scheduleDaysActive);
    let startCycleDays = medPlan.scheduleDaysCountStartsAt - 1;
    let endDate;
    //calculate initial values for startDate and endDate
    if (medPlan.scheduleDaysActive - startCycleDays <= 0) { //starting in resting day
        startDate = moment(startDate).add((onePeriod - startCycleDays), 'd').valueOf();
        endDate = moment(startDate).add(medPlan.scheduleDaysActive, 'd').valueOf();
    } else { //starting in normal take day.
        endDate = moment(startDate).add((medPlan.scheduleDaysActive - startCycleDays), 'd').valueOf();
    }
    for(let i = 0;i <= nrOfDaysItLasts;i = i + onePeriod){
        if(dateMillisec >= startDate && dateMillisec < endDate && dateMillisec <= lastDate){
            //case if our date is one of the checked weekend days
            // eslint-disable-next-line no-undef
            if((medPlan.schedule.weekends!==undefined && medPlan.schedule.weekends.length == 1 && actualDay == oneWeekendDay) || (medPlan.schedule.weekends!==undefined && medPlan.schedule.weekends.length == 2 && (actualDay == 6 || actualDay == 0))){
                times = calculateMedHoursWeekend(medPlan);
            } else {
                times = calculateMedHours(medPlan); //the times array for each plan for week days
            }

            medicationsIntakeSchedule = updateMedIntakeSchedule(times,medicationsIntakeSchedule, medPlan, medicationIntake, isSameDay(dateMillisec, starts), dateMillisec);
        }
        startDate = moment(endDate).add(medPlan.scheduleDaysInactive, 'd').valueOf();
        endDate = moment(startDate).add(medPlan.scheduleDaysActive, 'd').valueOf();
    }
    return medicationsIntakeSchedule;
}




/**
 * 
 * @param {*} date - the date we selected from the calendar , whose med intake times we want to determine
 * @param {*} medPlans - the list of the plans
 * @returns - an array of objects , made of times and ids of each plans having those times , ex : {'08:00' : [med_plan_id1, med_plan_id2] , '09:50' : [med_plan_id2] }
 */
export function calculateMedIntake(date, medPlans, medEvents) {
    //we compare the date in milliseconds
    date.setHours('00');
    date.setMinutes('00');
    date.setSeconds('00');
    date.setMilliseconds(0);
    let dateMillisec = moment(date).valueOf();
    let medicationsIntakeSchedule = [];
    let actualDay = date.getDay();
    medPlans.map(medPlan => {
        let oneWeekendDay =  medPlan.schedule.weekends ? medPlan.schedule.weekends[0] : 0;
        let medicationIntake = {
            hour: '',
            medPlanIds: []
        };
        let times = [];
        let starts = new Date(medPlan.starts);
        starts.setHours('00');
        starts.setMinutes('00');
        starts.setSeconds('00');
        starts.setMilliseconds('00');

        let createdTime = moment(medPlan.created_datetime).format("hh:mm:ss");
        let finalDoseTime = medPlan.schedule.times.reduce((prev, curr) => prev > curr ? prev : curr, createdTime);

        if(createdTime > finalDoseTime) {
            starts = moment(starts).add(1, 'days').valueOf()
        } else {
            starts = moment(starts).valueOf();
        }

        //ignore the case when medication plan is deleted

        if(medPlan.deleted == false && dateMillisec >= starts){
            //the case when schedule type is DAILY 
            if(medPlan.scheduleType == DAILY){
                medicationsIntakeSchedule = scheduleTypeDaily(starts, medPlan, dateMillisec, actualDay, oneWeekendDay, times, medicationIntake, medicationsIntakeSchedule);
            } else if(medPlan.scheduleType == EVERY_X_DAYS){
                medicationsIntakeSchedule = scheduleTypeEveryXdays(starts, date, medPlan, dateMillisec, actualDay, oneWeekendDay, times, medicationIntake, medicationsIntakeSchedule);
            } else if(medPlan.scheduleType == SPECIFIC_DAYS_OF_WEEK){
                medicationsIntakeSchedule = scheduleTypeSpecificDays(starts, medPlan, dateMillisec, actualDay, oneWeekendDay, times, medicationIntake, medicationsIntakeSchedule);
            } else if(medPlan.scheduleType == CYCLE){
                medicationsIntakeSchedule = scheduleTypeCycle(starts, medPlan, dateMillisec, actualDay, oneWeekendDay, times, medicationIntake, medicationsIntakeSchedule);
            }
        }
    });

    let reschedule = [];

    medEvents.map((medEvent)=>{
        let slotExistsForEvent = false;

        // Reschedules within the same day
        medicationsIntakeSchedule.map((medSlot)=>{
        date.setHours(medSlot.hour.split(":")[0])
        date.setMinutes(medSlot.hour.split(":")[1])
        
            if(isSameMinute(medEvent.dateTimePlanned,moment(date).valueOf())){
               slotExistsForEvent = true;
               if(medEvent.dateTimeScheduled != 0){
                medSlot.medPlanIds.map((id)=>{
                    if(medEvent.medicationPlanId==id){
                        if(isSameDay(medEvent.dateTimeScheduled, date))
                        {
                            reschedule.push({scheduledTime: medEvent.dateTimeScheduled, medPlanId: id})
                        }
                        if(medSlot.rescheduled){
                            medSlot.rescheduled.push({medPlanId:id, medEvent: medEvent});
                        }   
                        else{
                            medSlot.rescheduled = [{medPlanId:id, medEvent: medEvent}];
                        }
                    }
                })
               }
            }
        })
        // When you take a "When Needed" medication at a specific time and the intake schedule is empty
        if(medicationsIntakeSchedule.length==0 && isSameDay(medEvent.dateTimePlanned,date)){
            reschedule.push({medPlanId: medEvent.medicationPlanId, scheduledTime: medEvent.dateTimePlanned})
        }
        // Reschedules from another day
        else if(isSameDay(medEvent.dateTimeScheduled,date) && !isSameDay(medEvent.dateTimePlanned,date)){
            reschedule.push({medPlanId: medEvent.medicationPlanId, scheduledTime: medEvent.dateTimeScheduled})
    }
        // To take when needed when intake is not empty
        else if (!slotExistsForEvent){
            reschedule.push({medPlanId: medEvent.medicationPlanId, scheduledTime: medEvent.dateTimePlanned})
        }
    })


    // Clear out the rescheduled slot entries
    medicationsIntakeSchedule.map((medSlot)=>{
        if(medSlot.rescheduled){
            medSlot.rescheduled.map((reScheduled)=>{  
            let clearedMedSlot = medSlot.medPlanIds.filter((planId)=>{
                return planId != reScheduled.medPlanId;
            })
            medSlot.medPlanIds = clearedMedSlot;
            })
        }   
    })

    // reschedule entries to another time
    if(reschedule){
        reschedule.map((slot)=>{
            let timeSlotExists = false;
           medicationsIntakeSchedule.map((medIntake)=>{
               if(moment(slot.scheduledTime).format("HH:mm")==medIntake.hour){
               timeSlotExists = true;
               medIntake.medPlanIds.push(slot.medPlanId);
           }
        })
        
        if(!timeSlotExists){
            medicationsIntakeSchedule.push({hour: moment(slot.scheduledTime).format("HH:mm"), medPlanIds:[slot.medPlanId]})
        }
        })
    }
   
    // Clear out the slots with no leftover entries
     let clearedSchedule = medicationsIntakeSchedule.filter((medSlot)=>{
        return medSlot.medPlanIds.length!=0
    })

    return clearedSchedule;
}

export function getStatusForPlan(hour, medPlanId, medEvents){

    let status;
    medEvents.map((medEvent)=>{
        if(medEvent.medicationPlanId==medPlanId && (moment(medEvent.dateTimePlanned).format("HH:mm")==hour&&medEvent.dateTimeScheduled==0 || moment(medEvent.dateTimeScheduled).format("HH:mm")==hour)){
            status = medEvent.status;
        }
    })
    return status;
}

/**
 * This calculates and returns the times array, based on X times a day schedule
 * @param nrTimes
 * @param settings
 * @return {[]}
 */
function getTimesArrayXTimesADay(nrTimes, settings) {
    let times = [];
    if (+nrTimes !== parseInt(nrTimes) || +nrTimes <= 0) { //not a positive int
        return times
    }

    if (nrTimes > 4) {
        let timeToAdd = hh_mm_to_moment(settings.morningTime);
        let diff = diffInMins(settings.morningTime, settings.bedTime);
        let stepInMins = diff / (nrTimes - 1);
        for (let i = 0; i < nrTimes; i++) {
            times.push(timeToAdd.format("HH:mm"));
            timeToAdd.add(stepInMins, "minutes");
        }
        return times;
    }
    let predefinedTimes = [
        settings.morningTime,
        settings.noonTime,
        settings.eveningTime,
        settings.bedTime
    ]
    for (let i = 0; i < nrTimes; i++) {
        let predefinedTime = predefinedTimes[i];
        let asArray = predefinedTime.split(":");
        times.push(`${asArray[0]}:${asArray[1]}`);
    }
    return times;
}

/**
 * This calculates and returns the times array, based on every X hours schedule
 * @param nrHrs
 * @param settings
 * @return {[]}
 */
function getTimesArrayEveryXHours(nrHrs, settings) {
    if (+nrHrs !== parseInt(nrHrs) || +nrHrs <= 0) { //not a positive int
        return [];
    }
    let step = parseInt(nrHrs);
    if (step > 0) {
        let startTime = hh_mm_to_moment(settings.morningTime);
        let endTime = moment(startTime).add(1, 'day');
        let times = [];
        while (startTime.isBefore(endTime)) {
            times.push(startTime.format("HH:mm"));
            startTime.add(step, 'hour');
        }
        return times;
    }
    return [];
}

function diffInMins(time1, time2) {
    let time1Split = time1.split(":");
    let time2Split = time2.split(":");

    let milliseconds1 = time1Split[0]*60*60*1000 + time1Split[1]*60*1000;
    let time1AsMoment = moment.utc(milliseconds1);
    let milliseconds2 = time2Split[0]*60*60*1000 + time2Split[1]*60*1000;
    let time2AsMoment = moment.utc(milliseconds2);
    if (time2AsMoment.isBefore(time1AsMoment)) {
        time2AsMoment.add(1, 'day');
    }
    return time2AsMoment.diff(time1AsMoment, "minutes");
}

function hh_mm_to_moment(t) {
    let time1Split = t.split(":");
    return moment.utc(time1Split[0]*60*60*1000 + time1Split[1]*60*1000);
}
import {
    LOGOUT,
    REMOVE_PROFILE_IMAGE,
    UPLOAD_PROFILE_IMAGE
} from '../actions/actions'

const initialState =
    {
        imageUrl: ""
    }

export function manageProfileImage(state = initialState, action) {

    switch (action.type) {
        case UPLOAD_PROFILE_IMAGE: {
            let newImage = action.payload;
            return {...state, imageUrl: newImage}
        }
        case REMOVE_PROFILE_IMAGE: {
            return {...state, imageUrl: ""}
        }
        case LOGOUT: {
            return {...state, initialState}
        }
        default: {
            return state;
        }
    }
}


import React, {Component} from 'react'
import {Multiselect} from 'multiselect-react-dropdown'
import PropTypes from "prop-types";

export class CustomMultiSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: "cms_" + Math.floor(Math.random() * 1000000)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isFieldValid !== this.props.isFieldValid) {
            if (!this.props.isFieldValid) {
                this.removeSuccessClass();
                this.addErrorClass();
            } else {
                this.addSuccessClass();
            }
        }
        if (prevProps.formClass !== this.props.formClass && this.props.formClass !== '' && this.props.isFieldValid) {
            this.addSuccessClass();
        }
    }

    /**
     * Handles the logic of selecting an item selection from the list
     *
     * @param {Array} selectedList - the selected list
     * @param {object} selectedItem - the selected item object
     */
    onSelect = (selectedList, selectedItem) => {
        this.removeErrorClass();
        this.props.onSelect(selectedItem);
    }

    /**
     * Handles the logic of removing an item selection from the list
     *
     * @param {Array} selectedList - the selected list
     * @param {object} removedItem - the removed item object
     */
    onRemove = (selectedList, removedItem) => {
        this.props.onRemove(removedItem);
        if (!this.props.isFieldValid) {
            this.addErrorClass();
        }
    }

    /**
     * Add the error class to the search box class list
     */
    addErrorClass = () => {
        if (!this.searchWrapper) return;
        this.searchWrapper.classList.add('_2iA8p44d0WZ-WqRBGcAuEV-invalid');
    }

    /**
     * Removes the error class to the search box class list
     */
    removeErrorClass = () => {
        if (!this.searchWrapper) return;
        this.searchWrapper.classList.remove('_2iA8p44d0WZ-WqRBGcAuEV-invalid');
    }

    /**
     * Add the success class to the search box class list
     */
    addSuccessClass = () => {
        if (!this.searchWrapper) return;
        this.searchWrapper.classList.add('_2iA8p44d0WZ-WqRBGcAuEV-success');
    }

    /**
     * Removes the success class to the search box class list
     */
    removeSuccessClass = () => {
        if (!this.searchWrapper) return;
        this.searchWrapper.classList.remove('_2iA8p44d0WZ-WqRBGcAuEV-success');
    }

    render() {
        return (
            <Multiselect
                id={this.state.id}
                ref={(el) => {
                    el != null ? this.searchWrapper = el.searchWrapper.current : null
                }}
                {...this.props}
                onSelect={this.onSelect}
                onRemove={this.onRemove}
                style={{
                    searchBox: {
                        "display": "flex",
                        "flexWrap": "wrap",
                        "border": "1px solid #e2f8f4",
                        "borderRadius": "8px"
                    },
                    chips: {
                        "padding": "4px 10px",
                        "margin": "3px",
                        "borderRadius": "10px",
                        "display": "inline-block",
                        "alignItems": "center",
                        "fontSize": "13px",
                        "backgroundColor": "#FFE5E8",
                        "color": "#31757a",
                        "whiteSpace": "nowrap",
                        "textOverflow": "ellipsis",
                        "overflow": "hidden",
                        "maxWidth": "100%",
                        "position": "relative",
                        "paddingRight": "20px"
                    }
                }}
            />
        )
    }
}

CustomMultiSelect.propTypes = {
    avoidHighlightFirstOption: PropTypes.bool,
    closeIcon: PropTypes.string,
    displayValue: PropTypes.string,
    formClass: PropTypes.string,
    isFieldValid: PropTypes.bool,
    onRemove: PropTypes.func,
    onSelect: PropTypes.func,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    selectedValues: PropTypes.array
};

export default CustomMultiSelect
import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import moment from 'moment';
import GroupedLogBookEntries from './GroupedLogBookEntries';
import _ from 'underscore';
import CenteredModal from '../shared/CenteredModal';
import {LogbookForm} from './LogbookForm';
import ReactDOM from "react-dom";
import LogbookHeader from "./LogbookHeader";
import no_data from '../../resources/images/no_data.png'
import {fetchSelectedUserMedicalProfile, updateUserMedicalProfile} from '../../actions/users_actions';
import {connect} from 'react-redux'
import {getHealthIssueOptionsNoLowerCase} from '../../constants/select_options'
import {infoUtils} from '../../utils/infoUtils'
import PropTypes from "prop-types";


export class Logbook extends Component {
    state = {
        confirmationModal: {
            show: false
        },
        addUpdateModal: {
            show: false
        },
        selectedEntry: null,
        selectedEntryToDelete: null,
        groupedLogBookEntries: [],
        formclass: '',
        startDate: 0,
        endDate:  moment(new Date()).valueOf() + 3155760000000,
        errors: {},
        pickedSelectOption: this.props.selectedHealthIssue ? this.props.selectedHealthIssue.id : 0,
        descriptionOpened: false,
        type: "",
        loadComplete: false
    }

    constructor(props) {
        super(props);
    }

    /**
     * Fetch logbook entries function used for the infinite scroll
     */
    onLoadMore = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let params;
        if (this.props.userId && (this.props.logbook.request.finished === undefined || this.props.logbook.request.finished)) {
            if (this.props.logbook.entries && this.props.logbook.entries.length > 0) {
                const entiresLength = this.props.logbook.entries.length;
                params = {
                    before_date_time: this.props.logbook.entries[entiresLength - 1].date_time,
                    filterByType: this.state.type
                }
            }
            this.props.fetchEntries(this.props.userId, params, false);
        }
    }

    openAddModal = () => {
        this.setState({
            addUpdateModal: {
                show: true
            }
        })
    }

    onSelectChange = (evt) => {
        if (parseInt(evt.value) === 0) {
            this.setState({
                pickedSelectOption: 0,
            })
        } else {
            this.setState({
                pickedSelectOption: evt.value
            })
        }
        this.props.clearPreSelectedHealthIssue();
    }

    componentWillUnmount() {
        this.props.clearLogbook();
    }


    componentDidMount = async () => {
            let params = {
                before_date_time: this.state.endDate,
                after_date_time:  this.state.startDate,
                filterByType: ""
            };
            this.props.clearLogbook();
            this.props.fetchSelectedUserMedicalProfile(this.props.selectedUser.id)
            await this.props.fetchEntries(this.props.userId, params, true).then(
                () => {
                    this.setState({
                            loadComplete: true
                        }
                    )
                });
        }


    /**
     * This function fetches the entries based on type parameter
     * @param {*} type
     */
    onFilterByTypeChanged(type) {
        let params = {
            before_date_time: this.state.endDate,
            after_date_time:  this.state.startDate,
        };

        if (type !== "") {
            params.filterByType = type
        }

        this.props.fetchEntries(this.props.userId, params, true);
    }

    /**
     * This changes type based on selection
     * @param {*} evt
     */
    onSelectType = (evt) => {
        this.setState({
            type: evt.value
        })
        this.onFilterByTypeChanged(evt.value)
    }

    /**
     * Confirm deletion and remove entry from list
     */
    onConfirmModal = () => {
        let deletedEntry = this.state.selectedEntryToDelete;
        deletedEntry.deleted = true;
        deletedEntry.updated_timestamp = moment().valueOf();
        this.props.updateEntry(deletedEntry, this.props.selectedUser.id, this.state.type);
        this.setState({
            confirmationModal: {show: false},
            selectedEntryToDelete: '',
        });
    }


    /**
     * Hide the modal
     */
    onHideConfirmationModal = () => {
        this.setState({
            confirmationModal: {
                show: false,
                onHideModal: null,
                onConfirm: null
            },
            selectedEntryToDelete: ''
        });
    }

    /**
     * Hide the modal
     */
    onHideAddUpdateModal = () => {
        this.setState({
            addUpdateModal: {
                show: false
            },
            selectedEntry: '',
        });
        this.onFilterByTypeChanged(this.state.type)
    }

    /**
     * Show confirmation modal when trying to delete an entry
     *
     * @param {entry} key The entry to delete
     */
    showConfirmationModal = (key) => {
        this.setState({
            confirmationModal: {show: true},
            selectedEntryToDelete: key
        })
    }

    /**
     * Show add/update modal
     *
     * @param {entry} entry The entry to modify or null
     */
    showAddUpdateModal = (entry = null) => {
        if (entry) {
            this.setState({
                selectedEntry: entry,
                addUpdateModal: {show: true}
            })
        } else {
            this.setState({
                selectedEntry: '',
                addUpdateModal: {show: true}
            })
        }
    }

    /**
     * Clear selected entry from state
     */
    clearSelectedEntry = () => {
        this.setState({
            selectedEntry: ''
        })
    }

    groupEntries = (logbook) => {
        let groupedByDate = _.groupBy(logbook, (result) => moment(result.date_time).format('YYYY-MM-DD'));

        return Object.values(groupedByDate);
    }

    checkIfEmptyEntries = (logbook, selectedOption) => {

        let groupedLogbook = this.groupEntries(logbook)
        let hasHealthIssue = false;

        if (selectedOption === 0 || selectedOption === "0") {
            return true
        } else {
            groupedLogbook.filter((currentDate) => {
                currentDate.filter((entry) => {
                    if (selectedOption === 0)
                        return true;

                    if (entry.health_issues_ids != null) {

                        entry.health_issues_ids.map((h) => {
                            if (h === selectedOption) {
                                hasHealthIssue = true;
                                return true;
                            }
                        })
                    }

                })

            })
        }

        return hasHealthIssue;
    }

    getNoDataObject = (label) => {
        return {
            imgClass: 'no-entry-image',
            objClass: 'no-data-object-documents',
            primaryLabelClass: 'no-hospitals-primary-label',
            src: no_data,
            primaryLabel: label,
        }
    }

    /**
     * Get the logbook entries grouped by date time
     *
     * @returns {Array} of grouped logbook entries
     */
    getGroupedLogBookEntries = () => {
        let noLogbookEntries = this.getNoDataObject($$('logbook_no_data_label'))

        if ( (this.props.logbook.entries && this.props.logbook.entries.length > 0 && (this.state.type === "" || this.state.type === this.props.logbook.entries[0].entry_type))) {
            const groupedByDate = _.groupBy(this.props.logbook.entries, (result) => moment(result.date_time).format('YYYY-MM-DD'));
            const keys = Object.keys(groupedByDate);

            if (keys.length > 0) {
                return keys.map((dateTime, idx) => {
                    return <GroupedLogBookEntries
                        date_time={dateTime}
                        key={idx}
                        logBook={groupedByDate[dateTime]}
                        pickedSelectOption={this.state.pickedSelectOption}
                        settings={this.props.settings}
                        symptoms={this.getOptions(this.props.healthIssues.entries)}
                        i18n={this.props.i18n}
                        onDelete={this.onConfirmModal}
                        onShowModal={this.showConfirmationModal}
                        onShowEditModal={this.showAddUpdateModal}
                        fetchSelectedUserHealthIssues={this.props.fetchSelectedUserHealthIssues}
                        healthIssues={this.props.healthIssues}
                    />
                });
            }
        } else if (this.state.loadComplete) {
            return infoUtils.noData(noLogbookEntries)
        }
    }

    /**
     * Creates an array with only the health issues that the entry is part of.
     * @param {*} entry - log entry
     */
    getHealthIssueList = (entry) => {
        let healthIss = [];
        if (entry.health_issues_ids) {
            if (this.props.healthIssues && this.props.healthIssues.entries && this.props.healthIssues.entries.length > 0) {
                entry.health_issues_ids.map((ids) => {
                    this.props.healthIssues.entries.map((h) => {
                        if (ids === h.id) {
                            healthIss.push(h)
                        }
                    });
                });
                return healthIss;
            }
        }
    }

    changeDateRange = async (dates) => {
        this.setState({
                startDate : dates.startDate ?moment(dates.startDate).valueOf() : 0,
                endDate : dates.endDate ? moment(dates.endDate).valueOf() :  moment(new Date()).valueOf() + 3155760000000
            }
        )

        let params = {
            before_date_time: dates.endDate ? moment(dates.endDate).endOf("day").valueOf() :  moment(new Date()).valueOf() + 3155760000000,
            after_date_time:  dates.startDate ?moment(dates.startDate).valueOf() : 0,
            filterByType: this.state.type
        };
        this.props.clearLogbook();
        this.props.fetchSelectedUserMedicalProfile(this.props.selectedUser.id)
        await this.props.fetchEntries(this.props.userId, params, true).then(
            () => {
                this.setState({
                        loadComplete: true
                }
                )
            });
    }


    getOptions(options) {
        const optionArray = getHealthIssueOptionsNoLowerCase(options);
        optionArray.unshift({value: "0", text: $$("all_issues")})
        return optionArray;
    }

    render() {
        let hasEntries = this.checkIfEmptyEntries(this.props.logbook.entries, this.state.pickedSelectOption)
        let domNode = document.getElementById('new-symptom-btn');
        const hasMore = !this.props.logbook.isLastPage && (this.props.logbook.request.finished === undefined || this.props.logbook.request.finished) && !this.props.logbook.request.error;
        return (
            <div>
                <LogbookHeader
                    options={this.getOptions(this.props.healthIssues.entries)}
                    pickedOption={this.state.pickedSelectOption}
                    onSelectChange={this.onSelectChange}
                    onSelectType={this.onSelectType}
                    i18n = {this.props.lang}
                    onRangeSelected = {this.changeDateRange}
                    type={this.state.type}
                />
                {!this.state.addUpdateModal.show && ReactDOM.createPortal(
                    <div className="blue-button-container" onClick={this.openAddModal}>
                        <i className="add-new-button fa fa-plus" aria-hidden="true"
                           style={{"display": "table-cell", "verticalAlign": "middle"}}/>
                    </div>,
                    domNode
                )}
                {!this.state.loadComplete && <div className={"med-search-loader"} />}
                <div className="card documents-inner-card">
                    <div className="card-body inner-card-body">
                        <div ref={(ref) => this.listRef = ref}>
                            {
                                hasEntries === true ?
                                    <div>
                                        <div className="list-group">
                                            {this.getGroupedLogBookEntries()}
                                        </div>
                                        {hasMore &&
                                            <div className="text-center" style={{"marginTop": "10px"}}>
                                                <a href="#" onClick={this.onLoadMore}>{$$('load_more')}</a>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div className="text-center no-data-object-documents">
                                        <img src={no_data} className='no-entry-image' alt={"no data"}/>
                                        <br/>
                                        <br/>
                                        <span
                                            className="no-hospitals-primary-label">{$$("no_logs_after_filters")}</span>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <CenteredModal title={$$('delete_log_entry_modal_header')}
                               dialogClassName='doc-delete-modal'
                               show={this.state.confirmationModal.show}
                               onHide={this.onHideConfirmationModal}
                               cancelBtnLabel={$$('cancel_btn')}
                               onConfirm={this.onConfirmModal}
                               id="delete-modal-title"
                               className="center-delete-modal"
                               idFooter="footer-delete-modal"
                               confirmBtnLabel={$$('delete_label')}
                               confirmBtnClass="danger"
                               idBtnPrimary="btn-danger"
                               idBtnSecondary="btn-secondary">
                    {$$('delete_log_entry_modal_text')}
                    <div className="bin-align">
                        <i className="fas fa-trash-alt fa-3x"/>
                    </div>
                </CenteredModal>
                <LogbookForm
                    show={this.state.addUpdateModal.show}
                    onHide={this.onHideAddUpdateModal}
                    i18n={this.props.i18n}
                    selectedUser={this.props.selectedUser}
                    selectedEntry={this.state.selectedEntry}
                    medicalProfile={this.props.medicalProfile.data}
                    createEntry={this.props.createEntry}
                    updateEntry={this.props.updateEntry}
                    type={this.state.type}
                    clearSelectedEntry={this.clearSelectedEntry}
                    updateUserMedicalProfile={this.props.updateUserMedicalProfile}
                    settings={this.props.settings}
                    getHealthIssueList={this.getHealthIssueList}
                    fetchSelectedUserHealthIssues={this.props.fetchSelectedUserHealthIssues}
                    healthIssues={this.props.healthIssues}
                    formDisabled={this.props.formDisabled}
                    logbookEntries={this.props.logbook.entries}
                />
            </div>
        )
    }
}

Logbook.propTypes = {
    createEntry: PropTypes.func,
    fetchEntries: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    fetchSelectedUserMedicalProfile: PropTypes.func,
    formDisabled: PropTypes.object,
    healthIssues: PropTypes.object,
    i18n: PropTypes.object,
    lang: PropTypes.object,
    isFinished: PropTypes.bool,
    isLastPage: PropTypes.bool,
    logbook: PropTypes.object,
    medicalProfile: PropTypes.object,
    selectedHealthIssue: PropTypes.object,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    tabIndex: PropTypes.number,
    updateEntry: PropTypes.func,
    updateUserMedicalProfile: PropTypes.func,
    userId: PropTypes.string,
    clearLogbook: PropTypes.func
}

function mapStateToProps(state) {
    return {
        medicalProfile: state.medicalProfile,
        lang: state.language,
        formDisabled: state.formInteractions

    }
}

const mapDispatchToProps = {
    fetchSelectedUserMedicalProfile,
    updateUserMedicalProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(Logbook);

import {connect} from "react-redux";
import React, {Component} from "react";
import MedicationsTabs from "./MedicationsTabs";
import MedicationsPage from "./MedicationsPage";
import MedicationsPlansPage from "./MedicationsPlansPage";
import PropTypes from "prop-types";


class Medications extends Component {

    state = {
        showMedicationPlans: true,
        showMedications: false,
        selectedHealthIssue: this.props.location.state && this.props.location.state.selectedHealthIssue ? this.props.location.state.selectedHealthIssue : '',
    }

    constructor(props) {
        super(props);
    }

    medicationsClick = () => {
        this.setState({showMedicationPlans: true, showMedications: false});
    }

    medicationsPlansClick = () => {
        this.setState({showMedicationPlans: false, showMedications: true});
    }

    render() {
        return (
            <div className='wrapping-div'>
                <MedicationsTabs
                    showMedicationPlans={this.state.showMedicationPlans}
                    showMedications={this.state.showMedications}
                    medicationsClick={this.medicationsClick}
                    medicationsPlansClick={this.medicationsPlansClick}
                />

                {this.state.showMedicationPlans &&
                <MedicationsPlansPage selectedHealthIssue={this.state.selectedHealthIssue}/>}
                {this.state.showMedications &&
                <MedicationsPage
                    i18n={this.props.i18n}
                    medicationPlan={this.props.medicationPlan}
                />}
            </div>
        )
    }
}

Medications.propTypes = {
    history: PropTypes.object,
    i18n: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    medicationPlan: PropTypes.array
}

function mapStateToProps(state) {
    return {
        i18n: state.language,
        medicationPlan: state.medication.medicationPlan.entries,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Medications)
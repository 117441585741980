import React, {Component} from 'react'
import PropTypes from "prop-types";

export class Select extends Component {

    state = {
        value: this.props.value,
        error: false
    };

    static getDerivedStateFromProps(nextProps) {
        return {value: nextProps.value, error: nextProps.error}
    }

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onChange = (evt) => {
        const name = this.props.name;
        const value = evt.target.value;
        this.setState({value});
        this.props.onChange({name, value});
    };

    render() {
        let hasNoLabel = this.props.hasNoLabel || false;
        const options = this.props.options && this.props.options.map((o, i) => {
            return <option value={o.value} key={i} disabled={!!o.disabled} hidden={this.props.required && !!o.disabled}>{o.text}</option>
        });


        return (
            <div
                className={this.props.settingsPage && !this.props.connectedProfile ? "settings-dropdown " : (this.props.customClass ? this.props.customClass : "")}>
                {!hasNoLabel && <label className={this.props.labelClass}>{this.props.label}</label>}
                <div className={this.props.selectDivClass + " flex"}>
                    <select onChange={this.onChange} style={this.props.style} value={this.state.value}
                            className={this.props.settingsPage ? "settings-select mr-sm-2 pointer dropdown-arrow" : "custom-select mr-sm-2 pointer dropdown-arrow"} required={this.props.required}
                            disabled={this.props.selectDisabled ? true : null}>
                        {options}
                    </select>
                    <i className={this.props.className ? this.props.className : ''} style={this.props.style2}/>
                </div>
                <div className="invalid-feedback">{this.state.error}</div>
            </div>
        )
    }
}

Select.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    placeHolder:PropTypes.string,
    settingsPage: PropTypes.bool,
    connectedProfile: PropTypes.bool,
    hasNoLabel: PropTypes.bool,
    selectDisabled: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.any,
    customClass: PropTypes.any,
    className: PropTypes.any,
    style: PropTypes.any,
    selectDivClass: PropTypes.any,
    style2: PropTypes.any,
    labelClass: PropTypes.any,
    error: PropTypes.any
};

export default Select

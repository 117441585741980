import React, {Component} from 'react'
import {
    BLOOD_PRESSURE,
    BLOOD_SUGAR,
    CHOLESTEROL,
    HBA1C,
    HEIGHT,
    HYDRATION,
    KETONES,
    MEDICATION,
    MENSTRUATION, PEAK_FLOW,
    RESPIRATORY_RATE,
    SATURATION,
    TEMPERATURE,
    URINE_PH,
    WEIGHT
} from '../../constants/entry_types';
import {CONVERTER} from '../../utils/converter';
import moment from 'moment';
import LogbookSimpleEntry from './LogbookSimpleEntry';
import LogbookMenstruation from './LogbookMenstruation';
import LogbookLogo from './LogbookLogo';
import LogbookCholesterol from './LogbookCholesterol';
import LogbookBloodPressure from './LogbookBloodPressure';
import HealthIssueTag from '../health/HealthIssueTag';
import PropTypes from "prop-types";
import {dateTimeUtils} from "../../utils/dateTimeUtils";
import LogbookPeakFlow from "./LogbookPeakFlow";

export class GroupedLogBookEntries extends Component {
    state = {
        expand: false,
        filteredLogbook: this.props.logBook,
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.filterSymptoms();
    }

    componentDidUpdate(prevProps) {

        if ((prevProps.searchValue !== this.props.searchValue) || (prevProps.pickedSelectOption !== this.props.pickedSelectOption)) {
            this.filterSymptoms();
        }

        if (prevProps.symptoms !== this.props.symptoms) {
            this.filterSymptoms();
        }
    }

    filterSymptoms = () => {
        let filteredByHealthIssue = this.filterBySelection();
        this.setState({
            filteredLogbook: filteredByHealthIssue,
        })
    }

    /**
     *
     * @returns Filters the logbook entries by health issues
     */
    filterBySelection = () => {

        if (this.props.pickedSelectOption === "0") {
            return this.props.logBook
        } else {
            return this.props.logBook.filter((entry) => {
                let hasHealthIssue = false;
                if (this.props.pickedSelectOption === 0)
                    return true;
                if (entry.health_issues_ids != null) {
                    entry.health_issues_ids.map((h) => {
                        if (this.props.pickedSelectOption === 0)
                            hasHealthIssue = true;
                        if (h === this.props.pickedSelectOption) {
                            hasHealthIssue = true;
                        }
                    })
                }
                return hasHealthIssue;
            })
        }
    }


    /**
     * Creates the health issues list
     *
     * @param {object} entry - the logbook object
     * @returns {ReactElement}
     */
    getHealthIssueList = (entry) => {
        let healthIss = [];
        if (entry.health_issues_ids) {
            if (this.props.healthIssues && this.props.healthIssues.entries && this.props.healthIssues.entries.length > 0) {
                entry.health_issues_ids.map((ids) => {
                    this.props.healthIssues.entries.map((h) => {
                        if (ids === h.id) {

                            healthIss.push(h.name)
                        }
                    });
                });
            }
        }
        let healthIssueList2 = healthIss.map((p, idy) => {
            return <HealthIssueTag
                key={idy}
                className='patient-health-issue-list2 health-issue-logbook'
                styles={{width: "fit-content"}}
                healthIssue={p}
            />
        });
        return <div className='text-center flex-wrap flex-end'>
            {healthIssueList2}
        </div>;
    }

    /**
     * Get the logbook entries grouped by date time
     *
     * @returns {Array} of logbook entries
     */
    getLogBookEntries = () => {
        if (this.state.filteredLogbook && this.state.filteredLogbook.length > 0 && this.state.filteredLogbook.length <= 2) {
            return this.filteredList(this.state.filteredLogbook.length)
        } else if (this.state.filteredLogbook && this.state.filteredLogbook.length > 0 && this.state.filteredLogbook.length > 2) {
            if (!this.state.expand) {
                let result = this.filteredList(2);
                result.push(this.expandButton())
                return result
            } else {
                let result = this.filteredList(this.state.filteredLogbook.length);
                result.push(this.compressButton())
                return result
            }
        }
    }

    expandButton = () => {
        return <div key={Math.random()} className="text-left">
            <div className="d-flex">
                <div className="expand-compress-logbook">
                    <i className={"fa fa-chevron-down"} onClick={() => {
                        this.setState({
                            expand: true
                        })
                    }}/>
                </div>
                <div className="more-text pointer" onClick={() => {
                    this.setState({
                        expand: true
                    })
                }}>+{this.state.filteredLogbook.length - 2} more
                </div>
            </div>

        </div>
    }

    compressButton = () => {
        return <div key={Math.random()} className="text-left">
            <div className="d-flex">
                <div className="expand-compress-logbook">
                    <i className={"fa fa-chevron-up"} onClick={() => {
                        this.setState({
                            expand: false
                        })
                    }}/>
                </div>
            </div>
        </div>
    }

    filteredList = (length) => {
        return this.state.filteredLogbook.slice(0, length).map((entry, idx) => {
            return (
                <div key={idx} className="text-right" style={{'display': 'flex'}}>
                    <div className="medrec-grey-2 hide-on-sm-time">
                        <i className="fa fa-clock"/>
                        <span
                            className="time-for-symptoms medrec-blue-1"> {CONVERTER.getEntryDateTimeFormat(entry.date_time)}</span>
                    </div>
                    <div className="col-12 text-left d-flex w-100">
                        <LogbookLogo
                            {...this.props}
                            entry={entry}
                        />
                        {this.listItemByType(entry)}
                    </div>
                </div>
            )
        });
    }


    listItemByType = (entry) => {
        switch (entry.entry_type) {
            case WEIGHT:
            case BLOOD_SUGAR:
            case HYDRATION:
            case TEMPERATURE:
            case RESPIRATORY_RATE:
            case SATURATION:
            case HEIGHT:
            case URINE_PH:
            case KETONES:
            case HBA1C:
            case MEDICATION:
                return (
                    <LogbookSimpleEntry entry={entry}
                                        unit={this.props.settings.data.unitsOfMeasurement}
                                        getHealthIssueList={this.getHealthIssueList}
                                        onShowEditModal={this.props.onShowEditModal}
                                        onShowModal={this.props.onShowModal}
                    />
                )
            case CHOLESTEROL:
                return (
                    <LogbookCholesterol entry={entry}
                                        unit={this.props.settings.data.cholesterolUnit}
                                        getHealthIssueList={this.getHealthIssueList}
                                        onShowEditModal={this.props.onShowEditModal}
                                        onShowModal={this.props.onShowModal}
                    />
                )
            case BLOOD_PRESSURE:
                return (
                    <LogbookBloodPressure entry={entry}
                                          getHealthIssueList={this.getHealthIssueList}
                                          onShowEditModal={this.props.onShowEditModal}
                                          onShowModal={this.props.onShowModal}
                                          settings={this.props.settings}
                    />
                )
            case MENSTRUATION:
                return (
                    <LogbookMenstruation entry={entry}
                                         getHealthIssueList={this.getHealthIssueList}
                                         onShowEditModal={this.props.onShowEditModal}
                                         onShowModal={this.props.onShowModal}
                                         settings={this.props.settings}
                    />
                )
            case PEAK_FLOW:
                return (
                    <LogbookPeakFlow entry={entry}
                                         getHealthIssueList={this.getHealthIssueList}
                                         onShowEditModal={this.props.onShowEditModal}
                                         onShowModal={this.props.onShowModal}
                                         settings={this.props.settings}
                    />
                )
        }
    }

    render() {
        let isToday = moment(this.props.date_time).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD");

        return (

            <div className="margin-on-big">
                {this.state.filteredLogbook.length > 0 &&
                <ol key={Math.random()} className="logbook-ol">
                    <li key={Math.random()} className={isToday ? "logbook-li-active" : "logbook-li"}>
                            <span style={{marginLeft: "4.313rem"}}
                                  className={isToday ? "logbook-date-active" : "logbook-date"}>
                                {dateTimeUtils.getFormattedDayWithDate(this.props.date_time)}
                            </span>
                        <div key={Math.random()}>
                            {this.getLogBookEntries()}
                        </div>
                    </li>
                </ol>
                }
            </div>

        )
    }
}

GroupedLogBookEntries.propTypes = {
    clearSelectedEntry: PropTypes.func,
    createEntry: PropTypes.func,
    date_time: PropTypes.any,
    fetchSelectedUserHealthIssues: PropTypes.func,
    formDisabled: PropTypes.object,
    getHealthIssueList: PropTypes.func,
    healthIssues: PropTypes.object,
    logbookEntries: PropTypes.array,
    medicalProfile: PropTypes.object,
    onHide: PropTypes.func,
    onShowModal: PropTypes.func,
    pickedSelectOption: PropTypes.any,
    logBook: PropTypes.any,
    symptoms: PropTypes.any,
    searchValue: PropTypes.any,
    onShowEditModal: PropTypes.func,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    show: PropTypes.bool,
    type: PropTypes.string,
    i18n: PropTypes.any,
    updateEntry: PropTypes.func,
    updateUserMedicalProfile: PropTypes.func
}

export default GroupedLogBookEntries

import {
    CLEAR_EVENTS,
    CLEAR_MED, CLEAR_MEDICATION_PLAN,
    CREATE_MEDICATION_DATA_ERROR,
    CREATE_MEDICATION_DATA_REQUEST_SENT,
    CREATE_MEDICATION_EVENT_ERROR,
    CREATE_MEDICATION_EVENT_REQUEST_SENT,
    CREATE_MEDICATION_EVENT_SUCCESS,
    FETCH_CHILD_MED_ENTRIES_FOR_MED_EVENTS_ERROR,
    FETCH_CHILD_MED_ENTRIES_FOR_MED_EVENTS_SUCCESS,
    FETCH_MED_ENTRIES_FOR_MED_EVENTS_ERROR,
    FETCH_MED_ENTRIES_FOR_MED_EVENTS_SUCCESS,
    FETCH_SELECTED_CHILD_MEDICATIONS_ERROR,
    FETCH_SELECTED_CHILD_MEDICATIONS_SUCCESS,
    FETCH_SELECTED_USER_MEDICATIONS_ERROR,
    FETCH_SELECTED_USER_MEDICATIONS_SUCCESS,
    GET_CHILD_MEDICATION_EVENTS_ERROR,
    GET_CHILD_MEDICATION_EVENTS_SUCCESS,
    GET_MEDICATION_EVENTS_ERROR,
    GET_MEDICATION_EVENTS_SUCCESS,
    GET_SELECTED_MEDICAL_PLAN_STATS,
    LOGOUT,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    SELECT_USER,
    UPDATE_MEDICATION_DATA_ERROR,
    UPDATE_MEDICATION_DATA_REQUEST_SENT,
    UPDATE_MEDICATION_ENTRY_ERROR,
    UPDATE_MEDICATION_ENTRY_REQUEST_SENT,
    UPDATE_MEDICATION_EVENT_ERROR,
    UPDATE_MEDICATION_EVENT_REQUEST_SENT,
} from "../actions/actions";
import {requestStatus} from './requests_reducers';


/**
 * Medication reducer. Reduce state based on action type.
 *
 * @param {object} the state of the selected user
 * @param {action} action the action to be executed on the state
 * @returns the new state
 */
const medicationInitialState = {medicationPlan: {entries: [], request: requestStatus(undefined, {})}}

export function medication(state = medicationInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_CHILD_MEDICATIONS_SUCCESS:
            return {
                ...state,
                medicationPlan: {
                    entries: [...state.medicationPlan.entries, ...action.result],
                    request: requestStatus(state.request, {type: REQUEST_SUCCESS})
                }
            }
        case FETCH_SELECTED_USER_MEDICATIONS_SUCCESS: {
            return {
                ...state,
                medicationPlan: {entries: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
            }
        }
        case FETCH_SELECTED_CHILD_MEDICATIONS_ERROR:
        case FETCH_SELECTED_USER_MEDICATIONS_ERROR: {
            return {
                ...state,
                medicationPlan: {
                    entries: medicationInitialState.medicationPlan.entries,
                    request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.response})
                }
            }
        }
        case UPDATE_MEDICATION_DATA_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT}), type: "UPDATE"}
        }
        case UPDATE_MEDICATION_DATA_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, isLastPage, ...rest} = state;
            return {
                ...rest,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result}),
                type: "UPDATE"
            }
        }
        case CREATE_MEDICATION_DATA_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT}), type: "CREATE"}
        }
        case CREATE_MEDICATION_DATA_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, isLastPage, ...rest} = state;
            return {
                ...rest,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result}),
                type: "CREATE"
            }
        }
        case GET_SELECTED_MEDICAL_PLAN_STATS: {
            return {
                ...state,
                medicationPlanStats: {
                    statistics: action.result,
                    request: requestStatus(state.request, {type: REQUEST_SUCCESS})
                }
            }

        }
        case CLEAR_MEDICATION_PLAN:
        case SELECT_USER:
        case LOGOUT: {
            return {...state, ...medicationInitialState};
        }
        default: {
            return state;
        }
    }
}


/**
 * Medication events reducer. Changes based on the changing events (skip, reschedule, take) in the medication plans
 *
 * @param {object} the state of the selected user
 * @param {action} action the action to be executed on the state
 * @returns the new state
 */
const medicationEventsInitialState = {medicationEvent: {entries: [], request: requestStatus(undefined, {})}}

export function medication_events(state = medicationEventsInitialState, action) {
    switch (action.type) {
        case GET_CHILD_MEDICATION_EVENTS_SUCCESS:
            return {
                ...state,
                medicationEvent: {
                    entries: [...state.medicationEvent.entries, ...action.result],
                    request: requestStatus(state.request, {type: REQUEST_SUCCESS})
                }
            }
        case GET_MEDICATION_EVENTS_SUCCESS: {
            return {
                ...state,
                medicationEvent: {
                    entries: action.result,
                    request: requestStatus(state.request, {type: REQUEST_SUCCESS})
                }
            }
        }
        case GET_CHILD_MEDICATION_EVENTS_ERROR:
        case GET_MEDICATION_EVENTS_ERROR: {
            return {
                ...state,
                medicationEvent: {
                    entries: medicationEventsInitialState.medicationEvent.entries,
                    request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.response})
                }
            }
        }
        case CREATE_MEDICATION_EVENT_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT}), type: "CREATE"}
        }
        case CREATE_MEDICATION_EVENT_SUCCESS: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SUCCESS, response: action.result})}
        }
        case CREATE_MEDICATION_EVENT_ERROR: {
            const {...rest} = state;
            return {
                ...rest,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result}),
                type: "CREATE"
            }
        }
        case UPDATE_MEDICATION_EVENT_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT}), type: "UPDATE"}
        }
        case UPDATE_MEDICATION_EVENT_ERROR: {
            const {...rest} = state;
            return {
                ...rest,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result}),
                type: "UPDATE"
            }
        }
        case CLEAR_EVENTS:
        case SELECT_USER:
        case LOGOUT: {
            return {...state, ...medicationEventsInitialState};
        }
        default: {
            return state;
        }
    }
}


/**
 * Medication entries reducer. It fetches medication entries based on the medication events.
 *
 * @param {object} the state of the selected user
 * @param {action} action the action to be executed on the state
 * @returns the new state
 */
const medicationEntriesInitialState = {medicationEntry: {entries: [], request: requestStatus(undefined, {})}}

export function medication_entries(state = medicationEntriesInitialState, action) {
    switch (action.type) {
        case FETCH_CHILD_MED_ENTRIES_FOR_MED_EVENTS_SUCCESS:
            return {
                ...state,
                medicationEntry: {
                    entries: [...state.medicationEntry.entries, ...action.result],
                    request: requestStatus(state.request, {type: REQUEST_SUCCESS})
                }
            }
        case FETCH_MED_ENTRIES_FOR_MED_EVENTS_SUCCESS: {
            return {
                ...state,
                medicationEntry: {
                    entries: action.result,
                    request: requestStatus(state.request, {type: REQUEST_SUCCESS})
                }
            }
        }
        case FETCH_CHILD_MED_ENTRIES_FOR_MED_EVENTS_ERROR:
        case FETCH_MED_ENTRIES_FOR_MED_EVENTS_ERROR: {
            return {
                ...state,
                medicationEntry: {
                    entries: medicationEntriesInitialState.medicationEntry.entries,
                    request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.response})
                }
            }
        }
        case CLEAR_MED:
        case SELECT_USER:
        case LOGOUT: {
            return {...state, ...medicationEntriesInitialState};
        }
        default: {
            return state;
        }
    }
}


/**
 * Medication entry reducer. It updates medication entry based on the medication event.
 *
 * @param {object} the state of the selected user
 * @param {action} action the action to be executed on the state
 * @returns the new state
 */
const medicationEntryInitialState = {medicationEntry: {entries: [], request: requestStatus(undefined, {})}}

export function medication_entry(state = medicationEntryInitialState, action) {
    switch (action.type) {
        case UPDATE_MEDICATION_ENTRY_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT}), type: "UPDATE"}
        }
        case UPDATE_MEDICATION_ENTRY_ERROR: {
            const {...rest} = state;
            return {
                ...rest,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result}),
                type: "UPDATE"
            }
        }

        case SELECT_USER:
        case LOGOUT: {
            return {...state, ...medicationEntryInitialState};
        }
        default: {
            return state;
        }
    }
}
import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import moment from 'moment';
import {Modal} from "react-bootstrap"
import {dateTimeUtils} from '../../utils/dateTimeUtils'
import CenteredModal from '../shared/CenteredModal';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {HEALTH_ISSUE} from "../../actions/actions";
import FormWithLoading from "../shared/FormWithLoading";
import PropTypes from "prop-types";
import bg from 'date-fns/locale/bg';
import sq from 'date-fns/locale/sq';
import enGB from 'date-fns/locale/en-GB';
import {connect} from "react-redux";

export class HealthIssueForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showProcessingModal: false,
            dateStarted: this.props.selectedEntry && this.props.selectedEntry.date_started ? this.props.selectedEntry.date_started : new Date(),
            dateEnded: this.props.selectedEntry && this.props.selectedEntry.date_ended ? this.props.selectedEntry.date_ended : null,
            title: this.props.selectedEntry && this.props.selectedEntry.name ? this.props.selectedEntry.name : "",
            description: this.props.selectedEntry && this.props.selectedEntry.notes ? this.props.selectedEntry.notes : "",
            formclass: "",
            errors: {},
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.onSaveAddUpdateModal = this.onSaveAddUpdateModal.bind(this)

    }

    getLocale = () => {
        switch(this.props.i18n.selected.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            case "sq":
                return sq
            default:
                return enGB
        }
    }

    /**
     * Set the state to the latest selected date.
     *
     * @param {object} date - The event handler argument
     */
    onStartDateChange = (date) => {

        this.setState({dateStarted: date ? date : null})
    };

    /**
     * Set the state to the latest selected date.
     *
     * @param {object} date - The event handler argument
     */
    onEndDateChange = (date) => {
        this.setState({dateEnded: date ? date : null})
    };


    /**
     * Handle form creation or update based on the selected entry
     * @param {Object} form - request object to be sent to the server
     */
    handleCreateOrUpdate = async (form) => {
        this.setState({showProcessingModal: true})
        if (this.props.selectedEntry) {
            await this.props.updateEntry(form, this.props.selectedUser.id);
            this.props.clearSelectedEntry();
        } else {
            await this.props.createEntry(form, this.props.selectedUser.id);
        }
        this.setState({showProcessingModal: false})
        this.onHideAddUpdateModal();
    }


    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        if (evt.target) {
            fields[evt.target.name] = evt.target.value;
        } else {
            fields[evt.name] = evt.value;
        }
        this.setState(fields);
    }

    /**
     * Hide the modal
     */
    onHideAddUpdateModal = () => {

        this.props.onHide();
    }


    /**
     * Form submit handler, validate data and set error in state if any. Call create health issue entry action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {

        let startDate = dateTimeUtils.toOffsetDateTime(new Date(this.state.dateStarted)).split('T')[0];
        let endDate = dateTimeUtils.toOffsetDateTime(new Date(this.state.dateEnded && this.state.dateEnded)).split('T')[0];

        const errors = {};
        this.setState({errors: ''});

        if (!this.state.dateStarted) {
            errors.dateStarted = 'date_required_message';

        } else if (this.state.dateStarted > moment().valueOf()) {
            errors.dateStarted = 'datetime_max_value_message';
        }

        if (this.state.dateEnded > moment().valueOf()) {
            errors.dateEnded = 'datetime_max_value_message';
        } else if (Number.isNaN(this.state.dateEnded)) {
            errors.dateEnded = 'invalid_date_format';
        } else if (startDate > endDate && this.state.dateEnded != null) {

            errors.dateEnded = 'end_date_before_start_date';
        }

        if (!this.state.title) {
            errors.title = 'health_issue_title_required_message';
        }
        this.setState({errors});
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }
        return Object.keys(errors).length === 0;

    }


    /**
     * Create new entry or update existing entry from list
     */
    onSaveAddUpdateModal = () => {
        if (this.validate()) {
            let form = this.props.selectedEntry && {...this.props.selectedEntry};

            if (!this.props.selectedEntry) {
                form = Object.assign({},
                    {
                        ...form,
                        user_id: this.props.selectedUser.id,
                        date_started: dateTimeUtils.toOffsetDateTime(new Date(this.state.dateStarted)),
                        date_ended: this.state.dateEnded ? dateTimeUtils.toOffsetDateTime(new Date(this.state.dateEnded)) : null,
                        notes: this.state.description,
                        deleted: false,
                        date_created: dateTimeUtils.toOffsetDateTime(new Date()),
                        date_modified: dateTimeUtils.toOffsetDateTime(new Date()),
                        name: this.state.title,
                    }
                )
            } else {
                form = Object.assign({},
                    {
                        ...form,
                        user_id: this.props.selectedUser.id,
                        date_started: dateTimeUtils.toOffsetDateTime(new Date(this.state.dateStarted)),
                        date_ended: this.state.dateEnded ? dateTimeUtils.toOffsetDateTime(new Date(this.state.dateEnded)) : null,
                        notes: this.state.description,
                        deleted: false,
                        date_created: this.props.selectedEntry.date_created,
                        date_modified: dateTimeUtils.toOffsetDateTime(new Date()),
                        name: this.state.title,
                    }
                )
            }
            this.handleCreateOrUpdate(form)
        }
    }


    render() {
        let errorMsg = '';

        return (
            <div>
                <CenteredModal
                    title={this.props.selectedEntry ? $$('edit_health_issue_label') : $$('add_health_issue_label')}
                    show={this.props.show}
                    onHide={this.onHideAddUpdateModal}
                    onConfirm={this.onSaveAddUpdateModal}
                    confirmBtnLabel={$$('save_btn_label')}>
                    <div className='row mt-4'>
                        <div className='col-xs-12 col-md-12'>
                            <FormWithLoading
                                formDisabled={this.props.formDisabled}
                                currentForm={HEALTH_ISSUE}
                                marginTop="10%"
                                marginLeft="calc(50% - 70px)"
                                id="documentForm"
                                onSubmit={this.onSubmit}
                                className={this.state.formclass}>
                                {errorMsg}
                                <div className="form-group">
                                    {this.state.title &&
                                    <label className="form-labels">{$$("health_issue_title")}</label>}
                                    <input type="text" className="form-control" value={this.state.title || ''}
                                           placeholder={$$('health_issue_title')} name="title"
                                           onChange={this.onInputChange} required/>
                                    <div
                                        className={this.state.errors.title && !this.state.title ? "custom-invalid-feedback" : "invalid-feedback"}
                                        style={{"textAlignLast": "center"}}>
                                        {this.state.errors.title ? $$(this.state.errors.title) : $$("health_issue_title_required_message")}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 form-group">
                                        <div>
                                            <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    variant="inline"
                                                    format={this.props.settings.dateFormat}
                                                    margin="normal"
                                                    id="date-picker-inline"
                                                    label={$$("start_date_label")}
                                                    required
                                                    value={this.state.dateStarted}
                                                    onChange={this.onStartDateChange}
                                                    KeyboardButtonProps={{'aria-label': 'change date',}}
                                                    invalidDateMessage={$$('invalid_date_format')}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div
                                            className={this.state.errors.dateStarted ? "custom-invalid-feedback" : "invalid-feedback"}
                                            style={{"textAlignLast": "center"}}>
                                            {this.state.errors.dateStarted ? $$(this.state.errors.dateStarted) : $$("datetime_required_message")}
                                        </div>
                                    </div>
                                    <div className="col-6 form-group">
                                        <div>
                                            <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    variant="inline"
                                                    format={this.props.settings.dateFormat}
                                                    margin="normal"
                                                    id="date-picker-inline2"
                                                    label={$$("end_date_label")}
                                                    value={this.state.dateEnded}
                                                    onChange={this.onEndDateChange}
                                                    KeyboardButtonProps={{'aria-label': 'change date',}}
                                                    invalidDateMessage={$$('invalid_date_format')}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div
                                            className={this.state.errors.dateEnded ? "custom-invalid-feedback" : "invalid-feedback"}
                                            style={{"textAlignLast": "center"}}>
                                            {this.state.errors.dateEnded ? $$(this.state.errors.dateEnded) : $$("datetime_required_message")}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    {this.state.description && <label>{$$('health_issue_description')}</label>}
                                    <textarea className="form-control" value={this.state.description || ''}
                                              placeholder={$$('health_issue_description')} name="description"
                                              onChange={this.onInputChange}/>
                                </div>
                            </FormWithLoading>
                        </div>
                    </div>
                    {<Modal show={this.state.showProcessingModal}>
                        <Modal.Body style={{"textAlign": "center"}}>
                            <div style={{
                                "paddingTop": "30px",
                                "paddingBottom": "30px",
                                "paddingRight": "0px",
                                "paddingLeft": "0px"
                            }}>
                                {$$('processing_label') + "..."}
                            </div>
                        </Modal.Body>
                    </Modal>}
                </CenteredModal>
            </div>
        )
    }
}

HealthIssueForm.propTypes = {
    clearSelectedEntry: PropTypes.func,
    createEntry: PropTypes.func,
    formDisabled: PropTypes.object,
    i18n: PropTypes.any,
    onHide: PropTypes.func,
    selectedUser: PropTypes.object,
    selectedEntry: PropTypes.any,
    show: PropTypes.bool,
    settings: PropTypes.any,
    updateEntry: PropTypes.func
}

const mapStateToProps = (state) => ({
    settings: state.settings.data
})

export default connect(mapStateToProps, {})(HealthIssueForm)

import React, {Component} from "react";
import {Tooltip} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PropTypes from "prop-types";
import {$$} from "../../helpers/localization";
import CenteredModal from "../shared/CenteredModal";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {CONVERTER} from "../../utils/converter";
import moment from "moment";
import enGB from "date-fns/locale/en-GB";
import bg from "date-fns/locale/bg";
import sq from "date-fns/locale/sq";
import {DURATION_FOR_X_DAYS, DURATION_ONGOING, DURATION_UNTIL_DATE} from "../../constants/medications_constants";


class MakeInactiveButton extends Component {
    constructor(props, context) {
        super(props, context);
    }

    state = {
        medPlanToDisable: {},
        showDisableConfirmation: false,
        disableDateTime: moment().valueOf(),
        selectEndTimeModal: false,
        medPlanToEnable: {},
        enableDateTime: moment().valueOf(),
    }

    getLocale = () => {
        switch (this.props.i18n.selected.lang) {
            case "en":
                return enGB;
            case "bg":
                return bg;
            case "sq":
                return sq;
            default:
                return enGB;
        }
    }

    renderTooltip = props => (
        <Tooltip {...props} show={"true"}>{this.props.makeActive ? $$("make_active") : $$("make_inactive")}</Tooltip>
    );


    openModalOrEnablePlan = (medPlan, makeActive) => {
        if (makeActive) {
            switch (medPlan.durationType) {
                case DURATION_FOR_X_DAYS:
                    medPlan.ends = medPlan.starts + medPlan.durationInDays * 24 * 60 * 60 * 1000;
                    this.enableOrDisableMedPlan(medPlan, makeActive);
                    break;
                case DURATION_ONGOING:
                    medPlan.ends = 0;
                    this.enableOrDisableMedPlan(medPlan, makeActive);
                    break;
                case DURATION_UNTIL_DATE:
                    this.setState({
                        medPlanToEnable: medPlan,
                        selectEndTimeModal: true
                    })
                    break;
                default:
                    medPlan.ends = 0;
                    this.enableOrDisableMedPlan(medPlan, makeActive);
                    break;
            }
        } else {
            this.setState({medPlanToDisable: medPlan, showDisableConfirmation: !this.state.selectEndTimeModal})
        }

    }

    onDisableDateChange = (date) => {
        this.setState({disableDateTime: moment(date).valueOf()})
    };

    onEnableDateChange = (date) => {
        let medPlan = this.state.medPlanToEnable
        medPlan.ends = moment(date).valueOf()
        this.setState({
            enableDateTime: moment(date).valueOf(),
            medPlanToEnable: medPlan,
        })
    };

    enableOrDisableMedPlan = (medPlan, makeActive) => {
        medPlan.inactive = !makeActive;
        if (medPlan.inactive) {
            medPlan.ends = this.state.disableDateTime;
        } else {
            medPlan.ends = medPlan.durationType === DURATION_UNTIL_DATE ? this.state.enableDateTime : medPlan.ends;
        }

        this.setState({
            medPlanToDisable: {},
            showDisableConfirmation: false,
            disableDateTime: new Date(),
            selectEndTimeModal: false,
            medPlanToEnable: {},
            enableDateTime: new Date(),
        }, async () => {
            await this.props.updateMedication(medPlan, this.props.selectedUser.id);

        })
    }

    hideModals = () => {
        this.setState({
            medPlanToDisable: {},
            showDisableConfirmation: false,
            disableDateTime: moment().valueOf(),
            selectEndTimeModal: false,
            medPlanToEnable: {},
            enableDateTime: moment().valueOf()})
    }

    render() {
        return <span>
            <OverlayTrigger
                placement={"top"}
                overlay={this.renderTooltip}
                popperConfig={{
                    modifiers: {
                        preventOverflow: {
                            enabled: false
                        },
                        hide: {
                            enabled: false
                        }
                    }
                }}
            >
                <span onClick={() => {
                    this.openModalOrEnablePlan(this.props.medPlan, this.props.makeActive)
                }}
                      className={this.props.makeActive ? "fa fa-check-circle enable-disable-btn" : "fa  fa-ban enable-disable-btn"}/>
            </OverlayTrigger>

            <CenteredModal
                title={$$('make_inactive')}
                show={this.state.showDisableConfirmation}
                onHide={() => {
                    this.hideModals();
                }}
                cancelBtnLabel={$$('cancel_btn')}
                onConfirm={() => {
                    this.enableOrDisableMedPlan(this.state.medPlanToDisable, false)
                }}
                confirmBtnLabel={$$('make_inactive')}
                confirmBtnClass="danger"
                idBtnPrimary="btn-danger"
                idBtnSecondary="btn-secondary1">
                <div className="kt-block-center">
                    <div>
                        {$$("end_date_label")}
                    </div>
                    <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                            variant="inline"
                            format={CONVERTER.getDateFNSDateTimeFormat(false)}
                            margin="normal"
                            id="date-picker-inline"
                            value={this.state.disableDateTime}
                            onChange={this.onDisableDateChange}
                            KeyboardButtonProps={{'aria-label': 'change date',}}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </CenteredModal>

            <CenteredModal
                title={$$('select_end_date')}
                disablePrimary={this.state.enableDateTime === 0}
                show={this.state.selectEndTimeModal}
                onHide={this.hideModals}
                cancelBtnLabel={$$('cancel_btn')}
                onConfirm={() => {
                    this.enableOrDisableMedPlan(this.state.medPlanToEnable, true)
                }}
                confirmBtnLabel={$$('confirm_btn_label')}
                confirmBtnClass="primary"
            >
                <div className="kt-block-center">
                    <div>
                        {$$("end_date_label")}
                    </div>
                    <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                            variant="inline"
                            format={CONVERTER.getDateFNSDateTimeFormat(false)}
                            margin="normal"
                            id="date-picker-inline"
                            value={this.state.enableDateTime}
                            onChange={this.onEnableDateChange}
                            KeyboardButtonProps={{'aria-label': 'change date',}}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </CenteredModal>
        </span>
    }
}

MakeInactiveButton.propTypes = {
    updateMedication: PropTypes.func,
    i18n: PropTypes.any,
    selectedUser: PropTypes.any,
    makeActive: PropTypes.bool,
    medPlan: PropTypes.any,
    style: PropTypes.object,
    title: PropTypes.string
}

export default MakeInactiveButton

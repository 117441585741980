import React, {Component} from 'react'
import {$$} from '../../helpers/localization';
import moment from 'moment'
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";
import classNames from 'classnames'
import {Link} from 'react-router-dom'
import {Routes} from '../../constants/routes'
import PropTypes from "prop-types";
import {dateTimeUtils} from "../../utils/dateTimeUtils";


class HealthIssuesListItem extends Component {
    state = {
        isEditMode: false,
        value: '',
        healthIssue: '',
        formclass: '',
        errors: {},
        changingHealthIssue: false,
    }

    constructor(props) {
        super(props);
    }

    componentDidUpdate = (prevProps) => {

        if (this.props.selectedHealthIssue && ((prevProps.selectedHealthIssue !== this.props.selectedHealthIssue) ||
            (prevProps.selectedHealthIssue === this.props.selectedHealthIssue && (!this.state.value && this.props.selectedHealthIssue.value)))) {
            this.setState({
                value: this.props.selectedHealthIssue.value,
                healthIssue: this.props.selectedHealthIssue.value
            });
        }
        if (this.props.selectedHealthIssue.id !== this.props.healthIssue.id && this.state.isEditMode) {
            this.setState({
                isEditMode: false
            });
        }
    }

    /**
     * An event handler triggered when a health issue is clicked
     */
    onHealthIssueClick = () => {
        this.props.onHealthIssueChange(this.props.healthIssue);
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    /**
     *
     * @returns the left part of each health issue - name, date, delete and edit icons
     */
    getLeft = () => {
        return <div className="">
            <div className="d-flex">
                <div className="health-issue-name">
                    {this.props.healthIssue.name}
                    {(!this.props.healthIssue.allergy_condition_id || !this.props.healthIssue.allergyCondition) && (!this.props.healthIssue.chronic_condition_id || !this.props.healthIssue.chronicCondition) && <>
                        <DownloadEditDeleteButtons
                            handleEditClick={() => this.props.showAddUpdateModal(this.props.healthIssue)}
                            handleDeleteClick={() => this.props.showDeletedConfirmationModal(this.props.healthIssue)}
                        />
                    </>}
                </div>
            </div>
            <div className="mt-2">
                {(this.props.healthIssue.date_started || this.props.healthIssue.date_ended) &&
                <div className="issue-date">
                    <i className="flaticon-calendar-with-a-clock-time-tools" style={{'marginRight': '6px'}}/>
                    {this.props.healthIssue.date_started && <div className="mr-2">
                        {dateTimeUtils.getFormattedDateWithLongMonth(this.props.healthIssue.date_started)}
                    </div>}
                    {this.props.healthIssue.date_started && this.props.healthIssue.date_ended &&
                    "-"}
                    {this.props.healthIssue.date_ended && <div className="" style={{'marginLeft': '6px'}}>
                        {dateTimeUtils.getFormattedDateWithLongMonth(this.props.healthIssue.date_ended)}
                    </div>}
                </div>}
                {this.props.healthIssue.allergy_condition_id && <div className="issue-allergy-condition-cells">
                    {$$("allergy_condition")}
                </div>}
                {this.props.healthIssue.chronic_condition_id && <div className="issue-allergy-condition-cells">
                    {$$("chronic_condition")}
                </div>}
            </div>
        </div>
    }

    /**
     *
     * @returns the right part of each health issue
     */
    getRight = () => {
        return <div className="row justify-content-end">
            {this.getRectangle("symptoms", 0, Routes.CHILD_MEDICAL_RECORD, this.props.healthIssue, 2)}
            {this.getRectangle("vitals", 13, Routes.CHILD_MEDICAL_RECORD, this.props.healthIssue, 1)}
            {this.getRectangle("medications", 1, Routes.MEDICATIONS, this.props.healthIssue)}
            {this.getRectangle("documents", 3, Routes.CHILD_MEDICAL_RECORD, this.props.healthIssue, 4)}
            {this.getRectangle("labs", 0, Routes.CHILD_MEDICAL_RECORD, this.props.healthIssue, 5)}

        </div>
    }

    getRectangle = (name, number, route, selectedHealthIssue, selectedIndex) => {
        return <div className="health-issue-groups-container" onClick={this.onHealthIssueClick}>
            <Link style={{textDecoration: 'none'}} className="" to={{
                pathname: route,
                state: {
                    selectedHealthIssue: selectedHealthIssue,
                    index: selectedIndex ? selectedIndex : 0
                }
            }}>{$$(name)}</Link>
            <div className="number-container" style={number === 0 ? {"background": "#BBCDD9"} : {}}>{number}</div>
        </div>
    }

    render() {
        const activeClass = classNames('appointment-containter mt-2', {
            'appointment-active mt-2': ( this.props.selectedHealthIssue && this.props.selectedHealthIssue.id === this.props.healthIssue.id)
        });
        return (
            <div className={activeClass} onClick={this.onHealthIssueClick}
                 style={{'marginBottom': '3px', 'border': 'none'}}>
                <div className="health-issue-container">
                    {this.getLeft()}
                    {this.getRight(this.props.selectedHealthIssue)}
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        {(this.props.selectedHealthIssue &&  this.props.selectedHealthIssue.id === this.props.healthIssue.id) && this.props.healthIssue && this.props.healthIssue.notes &&
                        <div className="health-issue-notes">
                            {this.props.healthIssue.notes}
                        </div>}
                    </div>
                </div>
                <hr style={{'borderTop': '0.2px solid #F2F2F2'}}/>
            </div>
        )
    }
}

HealthIssuesListItem.propTypes = {
    healthIssue: PropTypes.object,
    i18n: PropTypes.object,
    onHealthIssueChange: PropTypes.func,
    selectedHealthIssue:  PropTypes.object,
    selectedUserId: PropTypes.string,
    showAddUpdateModal: PropTypes.func,
    showDeletedConfirmationModal: PropTypes.func
}

export default HealthIssuesListItem;

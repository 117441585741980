import React, {Component} from 'react'
import classnames from 'classnames';
import PropTypes from "prop-types";

export default class Tab extends Component {

    constructor(props) {
        super(props);
    }

    /**
     * On click handler for the tab button
     *
     * @param {object} e the event object
     */
    onClick = (e) => {
        e.preventDefault();
        this.props.onClick(this.props.tabIndex);
    }

    render() {
        var tabClass = classnames({
            'nav-link': true,
            'active': this.props.isActive
        });

        return (
            <li className="nav-item">
                <div className={tabClass} onClick={(e) => this.onClick(e)}>
                    {this.props.tabNumber === 1 ?
                        <i className="tab-icons flaticon-imac"/> : this.props.tabNumber === 2 ?
                            <i className="tab-icons fas fa-clipboard-list"/> : this.props.tabNumber === 3 ?
                                <i className="tab-icons fas fa-history"/> : this.props.tabNumber === 4 ?
                                    <i className="tab-icons fas fa-chart-pie"/> :
                                    this.props.tabNumber === 5 ?
                                        <i className="tab-icons far fa-folder"/> : this.props.tabNumber === 6 ?
                                            <i className="tab-icons fas fa-microscope"/> : ""}
                    <a className="tab-name">{this.props.tabName}</a>
                </div>
            </li>)
    }
}

Tab.propTypes = {
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    tabIndex: PropTypes.number,
    tabName: PropTypes.string,
    tabNumber: PropTypes.number
}
import {$$} from "../helpers/localization";

/**
 * Convert the type enum to text
 *
 * @param {string} type the type of the document
 * @param {string} type_description the type description
 * @returns {string} the text for the corresponding type
 */
export function convertDocumentTypeToText(document) {
    let type = document.type
    switch (type) {
        case "NONE":
            return $$("document_type_none");
        case "EPICRISIS":
            return $$("document_type_epicrisis");
        case "DIAGNOSTIC_IMAGING":
            return $$("document_type_diagnostic_imaging");
        case "PRESCRIPTION":
            return $$("document_type_prescription");
        case "LAB_RESULTS":
            return $$("document_type_lab_results");
        case "AMBULANCE_SHEET":
            return $$("document_type_ambulance_sheet");
        case "REFERRAL":
            return $$("document_type_referral");
        case "OTHER":
            return document.typeDescription; //use the type description field which is provided from the user for OTHER /custom/ types
        default:
            ""; //unknown
    }
}
import React, {Component} from 'react'
import {$$} from '../../../helpers/localization'
import PropTypes from "prop-types";

export default class UrinePhForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.urine_ph ? $$('urine_ph_label') : null}<br/>
                <div className="form-group">
                    <input type="number" min="0" max="14"
                           className={this.props.errors.urine_ph ? "custom-error form-control" : "form-control"}
                           value={this.props.urine_ph} placeholder={$$('urine_ph_label')}
                           name="urine_ph" onChange={this.props.onInputChange} required/>
                    <div
                        className={this.props.errors.urine_ph ? "custom-invalid-feedback" : "invalid-feedback"}>
                        {this.props.errors.urine_ph ? $$(this.props.errors.urine_ph) : $$('urine_ph_required_message')}
                    </div>
                </div>
            </div>
        )
    }
}

UrinePhForm.propTypes = {
    clearSelectedEntry: PropTypes.func,
    createEntry: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    formDisabled: PropTypes.object,
    errors: PropTypes.any,
    urine_ph: PropTypes.any,
    getHealthIssueList: PropTypes.func,
    onInputChange: PropTypes.func,
    healthIssues: PropTypes.object,
    logbookEntries: PropTypes.array,
    medicalProfile: PropTypes.object,
    onHide: PropTypes.func,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    show: PropTypes.bool,
    type: PropTypes.string,
    updateEntry: PropTypes.func,
    updateUserMedicalProfile: PropTypes.func
}
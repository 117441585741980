import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {
    Button,
    Modal
} from "react-bootstrap";
import PropTypes from "prop-types";

export default class CenteredModal extends Component {

    render() {
        let dialogClassName = this.props.dialogClassName ? this.props.dialogClassName : 'custom-dialog'
        let id = this.props.id ? this.props.id : ''
        let idFooter = this.props.idFooter ? this.props.idFooter : ''
        let className = this.props.className ? this.props.className : ''
        let modelClassName = this.props.modelClassName ? this.props.modelClassName : ''
        let idBtnPrimary = this.props.idBtnPrimary ? this.props.idBtnPrimary : ''
        let idBtnSecondary = this.props.idBtnSecondary ? this.props.idBtnSecondary : ''
        let showFooter = this.props.dialogClassName !== 'symptom-dialog-success' && this.props.dialogClassName !== 'medication-modal';
        if (!this.props.show) {
            return null;
        }
        let showExtraBtn = this.props.idBtnExtra;
        let idBtnExtra = this.props.idBtnExtra ? this.props.idBtnExtra : '';
        let titleClassName = this.props.titleClassName ? this.props.titleClassName : 'modal-title h4';
        let bodyClassName = this.props.bodyClassName ? this.props.bodyClassName : '';
        let headerClassName = this.props.headerClassName ? this.props.headerClassName : '';
        let primary = this.props.primary ? this.props.primary : false;
        let secondary = this.props.secondary ? this.props.secondary : false;

        let backdrop = this.props.backdrop || !this.props.disableBackdrop;
        return (
            <Modal className={modelClassName} backdrop={backdrop} scrollable={true} show={this.props.show} dialogClassName={dialogClassName}
                   onHide={this.props.onHide}>
                <Modal.Header closeButton className={headerClassName}>
                    {this.props.header}
                </Modal.Header>
                <Modal.Body className={className}>
                    <div className={bodyClassName}>
                        <Modal.Title id={id} className={titleClassName}>
                            {this.props.title}
                        </Modal.Title>
                        <h5>{this.props.body}</h5>
                    </div>
                    {this.props.children}
                </Modal.Body>
                {showFooter && <Modal.Footer id={idFooter}>

                    { typeof this.props.leftBtn !== undefined && this.props.leftBtn}

                    {showExtraBtn &&
                    <Button id={idBtnExtra}
                            disabled={this.props.disabledBtnExtra}
                            className={this.props.classBtnExtra ?? this.props.extraBtnLabel}
                            onClick={this.props.extraBtnClick ? this.props.extraBtnClick: this.props.onConfirm}>
                        {this.props.extraBtnLabel ? this.props.extraBtnLabel : $$('no_label')}
                    </Button>}
                    {!primary && <Button id={idBtnPrimary}
                                         disabled={this.props.disablePrimary}
                                         variant={this.props.confirmBtnClass ? this.props.confirmBtnClass : 'primary'}
                                         onClick={this.props.onConfirm}>
                        {this.props.confirmBtnLabel ? this.props.confirmBtnLabel : $$('confirm_btn_label')}
                    </Button>}
                    {!secondary && <Button id={idBtnSecondary} variant='secondary'
                                           onClick={this.props.onCancel ? this.props.onCancel : this.props.onHide}>
                        {this.props.cancelBtnLabel ? this.props.cancelBtnLabel : $$('close_btn_label')}
                    </Button>}
                </Modal.Footer>}
            </Modal>
        );
    }
}

CenteredModal.propTypes = {
    children: PropTypes.any,
    dialogClassName: PropTypes.any,
    id: PropTypes.any,
    idFooter: PropTypes.any,
    className: PropTypes.any,
    classBtnExtra: PropTypes.any,
    extraBtnClick: PropTypes.func,
    modelClassName: PropTypes.any,
    idBtnPrimary: PropTypes.any,
    idBtnSecondary: PropTypes.any,
    idBtnExtra: PropTypes.any,
    titleClassName: PropTypes.any,
    bodyClassName: PropTypes.any,
    disablePrimary: PropTypes.bool,
    disabledBtnExtra: PropTypes.bool,
    headerClassName: PropTypes.any,
    primary: PropTypes.any,
    secondary: PropTypes.any,
    header: PropTypes.any,
    body: PropTypes.any,
    confirmBtnLabel: PropTypes.any,
    leftBtn: PropTypes.any,
    extraBtnLabel: PropTypes.any,
    cancelBtnLabel: PropTypes.any,
    confirmBtnClass: PropTypes.any,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    onHide: PropTypes.func,
    show: PropTypes.bool,
    disableBackdrop: PropTypes.bool,
    size: PropTypes.string,
    title: PropTypes.any
};
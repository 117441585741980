import React, {Component} from 'react'
import {$$} from '../../../helpers/localization'
import CenteredModal from '../../shared/CenteredModal'
import ReactCrop from 'react-image-crop'
import PropTypes from "prop-types";


export class EditProfileImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
    }

    handleOnComplete = (croppedImageUrl) => {
        this.props.onComplete(croppedImageUrl)
    }

    handleSetShowModal = () => {
        this.props.setShowModal();
    }

    render() {
        let src = this.props.src;
        let crop = this.props.crop;
        let croppedImageUrl = this.props.croppedImageUrl

        return (
            <div>
                <div>
                    <input
                        id="picture"
                        type="file"
                        onClick={(event) => {
                            event.target.value = null
                        }}
                        accept="image/jpeg,capture=camera"
                        className={"no-display"}
                        onChange={this.props.onSelectFile}
                    />
                </div>
                <div className="text-center" style={{"display": this.props.showMe}}>
                    {src !== undefined ?
                        <div className="p-2 w-50 d-table-cell">
                            <div className="p-2 bg-light border-caption">{$$('select_label')}</div>
                            <ReactCrop
                                src={src}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={this.props.onImageLoaded}
                                onComplete={this.props.onCropComplete}
                                onChange={this.props.onCropChange}
                            />
                        </div>
                        :
                        <div>
                        </div>
                    }
                    <div className="p-2 w-50 d-table-cell">
                        <div className="p-2 bg-light border-caption">
                            {$$('preview_image')}
                        </div>
                        {croppedImageUrl && (
                            <img className="preview" alt="Crop" src={croppedImageUrl}/>
                        )}
                    </div>
                    <div className="text-right pseudo-modal-footer prof-edit-cont">
                        <div>
                            <button
                                className="btn btn-primary mr-2"
                                disabled={crop && crop.width < 10}
                                onClick={() => this.handleOnComplete(croppedImageUrl)}>
                                {$$('save_btn_label')}</button>
                            <button
                                className="btn btn-danger"
                                onClick={this.props.toggleShowMe}>
                                {$$('cancel_btn')}
                            </button>
                        </div>
                    </div>
                    <CenteredModal title={$$('delete_image_modal_title')}
                                   dialogClassName='doc-delete-modal'
                                   show={this.props.showModal}
                                   onHide={this.handleSetShowModal}
                                   cancelBtnLabel={$$('cancel_btn')}
                                   onConfirm={this.props.onConfirmModal}
                                   id="delete-modal-title"
                                   className="center-delete-modal"
                                   idFooter="footer-delete-modal"
                                   confirmBtnLabel={$$('OK')}
                                   confirmBtnClass="danger"
                                   idBtnPrimary="btn-danger"
                                   idBtnSecondary="btn-secondary">
                        {$$('delete_image_modal_message')}
                        <div className="bin-align">
                            <i className="fas fa-trash-alt fa-3x"/>
                        </div>
                    </CenteredModal>

                </div>
            </div>
        )
    }
}

EditProfileImage.propTypes = {
    crop: PropTypes.any,
    croppedImageUrl: PropTypes.string,
    onComplete: PropTypes.func,
    onConfirmModal: PropTypes.func,
    onCropChange: PropTypes.func,
    onCropComplete: PropTypes.func,
    onImageLoaded: PropTypes.func,
    onSelectFile: PropTypes.func,
    setShowModal: PropTypes.func,
    showMe: PropTypes.string,
    showModal: PropTypes.bool,
    src: PropTypes.string,
    toggleShowMe: PropTypes.func,
};

export default EditProfileImage;
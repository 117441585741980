import React, {Component} from 'react'
import {validators} from '../../helpers/validators'
import {$$, _$$} from '../../helpers/localization'
import Select from "../shared/Select"
import {countryOptions} from "../../constants/countries"
import moment from "moment";
import {GENDER, getResolvedOptions} from '../../constants/select_options'
import CenteredModal from "../shared/CenteredModal";
import {MedicalProfile} from "./MedicalProfile";
import {
    clearMedicalProfile,
    clearUpdateRequest,
    fetchSelectedUserMedicalProfile,
    selectUser,
    updateUserInfo,
    updateUserMedicalProfile
} from '../../actions/users_actions'
import {connect} from 'react-redux'
import ProfileImageComponent from './profileImage/ProfileImageComponent'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {dateTimeUtils} from '../../utils/dateTimeUtils'
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";
import {PROFILE} from "../../actions/actions";
import FormWithLoading from "../shared/FormWithLoading";
import GeneralPractitioner from "../dashboard/GeneralPractitioner";
import {
    clearGeneralPractitioner,
    createGeneralPractitioner,
    deleteGeneralPractitioner,
    fetchSelectedUserGeneralPractitioner,
    getImportGPCapture,
    importGP,
    updateGeneralPractitioner
} from "../../actions/generalPractitioner_actions";
import bg from 'date-fns/locale/bg';
import sq from 'date-fns/locale/sq';
import enGB from 'date-fns/locale/en-GB';

import PropTypes from "prop-types";
import {
    addParentByEmail,
    clearConnectedProfileResponse,
    clearParentCount,
    deleteChild,
    deleteParent,
    denyChild,
    getChildren,
    getParentCount,
    getParentList
} from "../../actions/children_actions";
import ApproveChild from "./ApproveChild";
import _ from "lodash";
import {usersService} from "../../service/users_service";
import {logout} from "../../actions/auth_actions";
import {documentsService} from "../../service/docments_service";
import {downloadUtils} from "../../utils/downloadUtils";
import PhoneInput, {isPossiblePhoneNumber} from "react-phone-number-input";
import BG from "../../constants/phone_input_bg.json";
import en from "react-phone-number-input/locale/en";

export class UserProfileForm extends Component {

    state = {
        id: this.props.userInfo.id,
        fullname: this.props.userInfo.fullname,
        email: this.props.userInfo.email,
        city: this.props.userInfo.city || '',
        country: this.props.userInfo.country || '',
        birthday: this.props.userInfo.birthday || dateTimeUtils.getUtcOffset(0).valueOf(),
        gender: this.props.userInfo.gender || '',
        phone: this.props.userInfo.phone || '',
        editUserInfoData: _.cloneDeep(this.props.userInfo),
        formclass: '',
        hasImage: false,
        hasProfilePicture: true,
        approveChildPopup: false,
        removeParentPopup: false,
        removeChildPopup: false,
        addParentPopup: false,
        selectedChildToApprove: null,
        selectedProfileToRemove: null,
        parentEmail: "",
        errors: {},
        upgradeOpen: false,
        confirmOpen: false,
        editOpen: false,
        childEmail: "",
        childPassword: "",
        confirmChildPassword: "",
        weight: this.props.medicalProfile.data.weight,
        height: this.props.medicalProfile.data.height,
        blood: this.props.medicalProfile.data.blood_type,
        peak_flow_norm: this.props.medicalProfile.data.peak_flow_norm,
        needsRefresh: false,
        updateDisabled: true,
        isEnabled: true,
        needsUpdate: false
    }

    constructor(props) {
        super(props);
    }

    getLocale = () => {
        switch (this.props.i18n.selected.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            case "sq":
                return sq
            default:
                return enGB
        }
    }

    componentDidMount() {
        this.props.getChildren();
        if (this.props.userInfo.id !== this.props.parent.id) {
            this.props.getParentList(this.props.userInfo.id);
        } else {
            for (let i in this.props.children) {
                if (this.props.children[i].child_accepted) {
                    this.props.getParentCount(this.props.children[i].id);
                }
            }
        }
    }

    componentWillUnmount() {
        this.props.clearParentCount();
        this.props.clearUpdateRequest();
        this.props.clearConnectedProfileResponse();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.updateDisabled && this.state.isEnabled && (this.state.weight !== this.props.medicalProfile.data.weight ||
            this.state.height !== this.props.medicalProfile.data.height ||
            this.state.peak_flow_norm !== this.props.medicalProfile.data.peak_flow_norm ||
            this.state.blood !== this.props.medicalProfile.data.blood_type)
        ) {
            this.setState({updateDisabled: false})
        }
        if (this.props.connectProfileResponse !== prevProps.connectProfileResponse) {
            if (this.props.connectProfileResponse === 404) {
                this.setState({
                    errors: {parentEmail: 'check_email'}
                });
            }
            if (this.props.connectProfileResponse === 200) {
                this.setState({addParentPopup: false, parentEmail: ""})
                this.props.clearConnectedProfileResponse();
            }
        }

        if (this.props.profileUpdateResponse === false &&
            this.state.editOpen &&
            this.props.profileUpdateResponse !== prevProps.profileUpdateResponse) {
            this.setState({
                errors: {email: 'register_form_email_exists_message'}
            });
            this.props.clearUpdateRequest();
        }
        if (!this.state.id) {
            this.setState({
                id: this.props.userInfo.id,
                fullname: this.props.userInfo.fullname,
                email: this.props.userInfo.email,
                city: this.props.userInfo.city || '',
                country: this.props.userInfo.country || '',
                birthday: this.props.userInfo.birthday || dateTimeUtils.getUtcOffset(0).valueOf(),
                gender: this.props.userInfo.gender || 'FEMALE',
            });
        }

        if (this.state.needsUpdate && this.props.upgrade.request.finished !== false) {
            if (this.props.upgrade.error && this.props.upgrade.error.status === 403) {
                this.setState({
                    errors: {childEmail: 'register_form_email_exists_message'},
                    upgradeOpen: true,
                    confirmOpen: false,
                });
            } else {
                this.closePopup();
                this.props.selectUser(this.props.parent, true)
            }
            this.setState({needsUpdate: false})
        }

    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onEditInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields.editUserInfoData[evt.target.name] = evt.target.value;
        this.setState(fields);
    }

    marketingAgreementChanged = (evt) => {
        const fields = Object.assign({}, this.state);
        if (!fields.editUserInfoData.user_flags) {
            fields.editUserInfoData.user_flags = {MARKETING_EMAILS: false}
        }
        fields.editUserInfoData.user_flags["MARKETING_EMAILS"] = !fields.editUserInfoData.user_flags?.["MARKETING_EMAILS"];
        this.setState(fields);
    }

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields.editUserInfoData[name] = value;
        this.setState(fields);
    };


    enableUpdateButton = () => {
        this.setState({isEnabled: true})
    }


    refreshComplete = () => {
        this.setState({needsRefresh: false})
    }

    /**
     * Set the state to the latest selected date.
     *
     * @param {object} date - The event handler argument
     */
    onDateChange = (date) => {
        if (date == null) {
            this.setState({editUserInfoData: {...this.state.editUserInfoData, birthday: "INVALID DATE"}})
        } else {
            const fields = Object.assign({}, this.state);
            fields.editUserInfoData["birthday"] = moment(date).valueOf();
            this.setState(fields);
        }
    };

    /**
     * Form submit handler, validate data and set error in state if any. Call updateUserInfo action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        let formErrors = this.props.parentId === this.props.userInfo.id ? this.validate() : this.validateChildUpdate();
        this.setState({errors: formErrors});
        evt.preventDefault();
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }

        // if a phone number is set but is not a valid number do not submit the form
        if (this.state.editUserInfoData.phone &&
            this.state.editUserInfoData.phone !== ""
            ){
            if(!isPossiblePhoneNumber(this.state.editUserInfoData.phone || "")) {
                return;
            }
        }

            if (Object.keys(formErrors).length) {
            return;
        }

        if (evt.target.checkValidity() === true) {
            this.props.updateUserInfo(this.state.editUserInfoData, this.props.parentId);
        }
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};

        if (!this.state.editUserInfoData.fullname) {
            errors.fullname = 'register_form_full_name_required';
        }

        if (this.state.editUserInfoData.email && !validators.validateEmail(this.state.editUserInfoData.email)) {
            errors.email = 'register_form_email_not_correct_message';
        } else if (!this.state.editUserInfoData.email) {
            errors.email = "email_required_message"
        }
        if (this.state.editUserInfoData.birthday === "INVALID DATE") {
            errors.birthday = "date_invalid"
        }
        return errors;
    }

    validateChildUpdate = () => {
        const errors = {};

        if (!this.state.editUserInfoData.fullname) {
            errors.fullname = 'register_form_full_name_required';
        }
        if (this.state.editUserInfoData.birthday.toString() === "INVALID DATE") {
            errors.birthday = "date_invalid"
        }
        return errors;
    }


    /**
     * Validate  form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validateUpgrade = () => {
        const errors = {};
        if (this.state.childEmail === "" || !validators.validateEmail(this.state.childEmail)) {
            errors.childEmail = 'register_form_email_not_correct_message';
        }

        if (this.state.childPassword.length < 8) {
            errors.childPassword = 'password_not_long_enough';
        }

        if (this.state.childPassword.startsWith(" ") || this.state.childPassword.endsWith(" ")) {
            errors.childPassword = 'password_whitespace_message';
        }

        if (this.state.childPassword !== this.state.confirmChildPassword) {
            errors.childPassword = 'passwords_dont_match';
        }

        return errors;
    }

    /**
     * Get error message for the image uploading
     *
     * @returns {string} the error to display
     */
    getErrorMessage = () => {
        if (this.state.errors.unsupprotedImage) {
            return $$('unsupported_image_format');
        }
    }

    updateNeedsRefresh = () => {
        this.setState({needsRefresh: true})

    }

    lookUpCountry = () => {
        let index = countryOptions(this.props.i18n.selected.lang)

        for (let i in index) {
            if (index[i].value === this.state.country?.toLowerCase()) {
                return index[i].text
            }
        }
    }

    closePopup = () => {
        this.setState({
            upgradeOpen: false,
            confirmOpen: false,
            editOpen: false,
            childEmail: "",
            childPassword: "",
            confirmChildPassword: "",
            formclass: "",
            errors: {}
        });
    }


    upgradeChild = () => {
        this.setState({upgradeOpen: true});
    }

    openUpgradeConfirmModal = () => {

        let formErrors = this.validateUpgrade();

        this.setState({errors: formErrors});
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }

        if (Object.keys(formErrors).length) {
            return;
        }

        this.setState({
            upgradeOpen: false,
            confirmOpen: true,
        });
    }

    onUpgradeConfirmModal = async () => {
        let upgradeObject = {
            childId: this.state.id,
            email: this.state.childEmail,
            password: this.state.childPassword
        }
        await this.props.upgradeChildToFullUser(upgradeObject)
        this.setState({needsUpdate: true})
    }

    editProfile = () => {
        this.setState({editOpen: true});

    }

    changeWeightHeightAndBlood = (form) => {
        this.setState({
            weight: form.weight,
            height: form.height,
            blood: form.blood_type
        })
    }

    onPeakFlowNormChanged = (peakFlowNorm) => {
        this.setState({peak_flow_norm: peakFlowNorm, isEnabled: true});
    }

    handleUpdate = async (form) => {
        await this.props.updateUserMedicalProfile(this.props.userInfo.id, form);
        this.setState({updateDisabled: true, isEnabled: false})
    }

    update = () => {
        let form = {
            ...this.props.medicalProfile.data,
            weight: this.state.weight,
            height: this.state.height,
            blood_type: this.state.blood,
            peak_flow_norm: this.state.peak_flow_norm
        }
        this.handleUpdate(form)
    }

    closeEditPopup = () => {
        this.closePopup();

        this.setState({
            editUserInfoData: _.cloneDeep(this.props.userInfo),
            errors: {}
        })
    }

    removeSelectedProfile = () => {
        if (this.state.removeChildPopup) {
            if (this.getNumberOfParentsForChild(this.state.selectedProfileToRemove.id) === 1) {
                this.props.deleteChild(this.state.selectedProfileToRemove.id)
            } else {
                this.props.denyChild(this.state.selectedProfileToRemove.id)
            }
        }

        if (this.state.removeParentPopup) {
            this.props.deleteParent(this.props.userInfo.id, this.state.selectedProfileToRemove.id)
        }

        this.setState({removeChildPopup: false, removeParentPopup: false})


    }

    getChildParentCount = (childID) => {
        for (let i in this.props.childParentCount) {
            if (this.props.childParentCount[i].childID === childID) {
                if (this.props.childParentCount[i].count > 0) {
                    return true
                }
            }
        }
        return false;
    }

    getNumberOfParentsForChild = (childID) => {
        for (let i in this.props.childParentCount) {
            if (this.props.childParentCount[i].childID === childID) {
                return this.props.childParentCount[i].count
            }
        }
        return 0;
    }

    isLastAcceptedParent(profile) {
        return this.props.parents.length === 1 || (this.props.parents.filter(p => p.child_accepted).length === 1 && profile.child_accepted)
    }

    getConnectedProfiles = (profile, idx) => {
        let body = <div key={idx}>
            <div
                className={profile.child_accepted ? "low-shadow-container  connected-profile" : "low-shadow-container connected-profile-show-pin"}>
                <div className="card-body light-green">
                    {this.props.userInfo.id === this.props.parent.id && <>
                        {profile.child_accepted &&
                            <div className="" style={{display: "flex", justifyContent: "space-between"}}>
                                {profile.fullname}
                                <div style={{marginRight: "1rem"}}>
                                    <DownloadEditDeleteButtons
                                        handleDeleteClick={() => {
                                            this.setState({
                                                removeChildPopup: true,
                                                selectedProfileToRemove: profile
                                            })
                                        }}
                                    /></div>
                            </div>}
                        {!profile.child_accepted &&
                            <div className="" style={{display: "flex", justifyContent: "space-between"}}>
                                <div>{profile.fullname}
                                    <div className={"text-danger"}>{$$("pin_required")}</div>
                                </div>

                                <div className="pointer" onClick={() => {
                                    this.setState({approveChildPopup: true, selectedChildToApprove: profile})
                                }}>
                                    <span className="fa fa-plus add-condition add-gp"/>
                                </div>
                            </div>}
                    </>}
                    {this.props.userInfo.id !== this.props.parent.id && <>
                        <div className="" style={{display: "flex", justifyContent: "space-between"}}>
                            {profile.fullname}
                            {!this.isLastAcceptedParent(profile) && <div style={{marginRight: "1rem"}}>
                                <DownloadEditDeleteButtons
                                    handleDeleteClick={() => {
                                        this.setState({removeParentPopup: true, selectedProfileToRemove: profile})
                                    }}
                                />
                            </div>
                            }
                        </div>
                        <span className="wizard-title">{!profile.child_accepted && `${$$("patient_uin")}: `}
                        </span> {!profile.child_accepted && profile.pin}
                    </>}
                </div>
            </div>
            <br/>
        </div>

        return body
    }

    addParent = () => {
        if (this.state.parentEmail !== "") {
            this.setState({
                errors: {parentEmail: ""}
            }, () => {
                let form = {email: this.state.parentEmail}
                this.props.addParentByEmail(this.props.userInfo.id, form)
            });
        } else {
            this.setState({
                errors: {parentEmail: 'email_required_message'}
            });
        }
    }

    submitForm = (evt) => {
        evt.preventDefault();
    }

    deleteProfile = () => {
        this.setState({deleteOpen: true})
    }

    onDeleteProfile = () => {
        this.setState({deleteError: undefined});
        usersService.deleteUser().then(() => {
            this.props.logout()
        }).catch((e) => {
            this.setState({deleteError: e})
        });
    }

    exportData = () => {
        let userDetails = {
            id: this.props.userInfo.id,
            fullname: this.props.userInfo.fullname,
            email: this.props.userInfo.email,
            gender: this.props.userInfo.gender,
            city: this.props.userInfo.city,
            country: this.props.userInfo.country,
            timezone: this.props.userInfo.timezone,
            medicalProfile: this.props.medicalProfile.data,
            healthIssues: this.props.healthIssues.entries?.map(entry => {
                const {medicalProfile, ...rest} = entry;
                return rest;
            }),
            medications: this.props.medications
        }
        documentsService.exportLogbook(this.props.userInfo.id, userDetails).then((file) => {
            downloadUtils.download(file, "data.zip");
        });
    }

    render() {
        let alert = '';
        if (this.props.showSuccessfulAlert) {
            alert = (
                <div className="alert alert-success alert-dismissible fade show"
                     role="alert">
                    {$$('user_info_updated_message')}
                </div>
            );
        }

        if (this.state.errors.unsupprotedImage) {
            alert = (
                <div className="alert alert-danger alert-dismissible fade show"
                     role="alert">
                    {this.getErrorMessage()}
                </div>
            );
        }

        const gender = GENDER.OPTIONS.find(g => g.value === this.state.gender);
        return (
            <div className=" d-flex profile-container">
                <div className="user-info-card">
                    <div className="user-image-name">
                        <div style={{marginTop: '2rem'}}>
                            <div>
                                <ProfileImageComponent
                                    selectedUser={this.props.userInfo}
                                    parentId={this.props.parentId}
                                    refreshComplete={this.refreshComplete}
                                    needsRefresh={this.state.needsRefresh}
                                    updateNeedsRefresh={this.updateNeedsRefresh}
                                />
                            </div>
                        </div>
                        <div className="centered-text">
                            <h4>{this.state.fullname}</h4>
                        </div>
                        {this.props.userInfo && <div className="centered-text">
                            <div>
                                <div className="medrec-grey-2 font-size-09rem">
                                    {$$('patient_uin')} : {this.props.userInfo.user_uin}
                                </div>
                            </div>
                        </div>}
                        <br/>
                        <div className="d-flex">
                            <p className="pointer" onClick={this.editProfile}>{$$('edit_profile_label')} </p>
                            <div style={{marginLeft: "-10px"}}>
                                <DownloadEditDeleteButtons
                                    handleEditClick={this.editProfile}
                                />
                            </div>
                        </div>
                        <div className="d-flex">
                            <p className="pointer" onClick={this.exportData}>{$$('export_medical_profile')} </p>
                            <div style={{marginLeft: "-10px"}}>
                                <DownloadEditDeleteButtons
                                    handleDownloadClick={this.exportData}
                                />
                            </div>
                        </div>
                        {this.props.parentId === this.props.userInfo.id &&
                            <div className="d-flex">
                                <p className="pointer" onClick={this.deleteProfile}>{$$('delete_label')} </p>
                                <div style={{marginLeft: "-10px"}}>
                                    <DownloadEditDeleteButtons
                                        handleDeleteClick={this.deleteProfile}
                                    />
                                </div>
                                <CenteredModal title={$$('delete_label') + " " + this.state.fullname}
                                               show={this.state.deleteOpen}
                                               size={"Default"}
                                               onHide={() => this.setState({deleteOpen: false, deleteInput: ""})}
                                               onConfirm={this.onDeleteProfile}
                                               confirmBtnLabel={$$('delete_label')}
                                               confirmBtnClass={"danger"}
                                               cancelBtnLabel={$$('cancel_btn')}
                                               primary={(this.state.deleteInput || "") !== 'DELETE'}
                                >
                                    <div className="mt-3 mb-3">
                                        <h5 className={"bold"}> {$$('confirm_delete')}</h5>
                                        <p className={"text-danger mt-3"}> {$$('confirm_delete_advisory')}</p>

                                        {this.state.deleteError &&
                                            <p className={"text-danger"}>{$$("error_occurred")}</p>}
                                    </div>
                                    <div className={"form-group"}>
                                        <label>{$$("delete_word_confirm_label")}</label>
                                        <input type={"text"} autoFocus={true} className={"form-control"}
                                               value={this.state.deleteInput || ""}
                                               onChange={(e) => this.setState({deleteInput: e.target.value})}/>
                                    </div>
                                </CenteredModal>
                            </div>
                        }
                        <div>
                            <hr/>
                            <span
                                className="wizard-title">{this.props.userInfo.id === this.props.parent.id ? $$("connected_profiles") : $$("controlled_by")} </span>
                            <br/>
                            <br/>
                            {this.props.userInfo.id === this.props.parent.id && this.props.children.map(this.getConnectedProfiles)}

                            {this.props.userInfo.id !== this.props.parent.id && this.props.parents && this.props.parents.map(this.getConnectedProfiles)}
                            {this.props.userInfo.id !== this.props.parent.id &&
                                <div style={{display: "flex", justifyContent: "flex-end"}}>
                                    {$$("add_connected_profile")}
                                    <span onClick={() => {
                                        this.setState({addParentPopup: true})
                                    }} className=" pointer fa fa-plus add-condition add-gp"/>
                                </div>}
                        </div>
                        <hr className="hr-hidden"/>
                    </div>

                    <div className="user-details">

                        {this.props.userInfo.gender && <div className="row">
                            <p><span className="wizard-title"> {$$('gender_label')} </span> <br/> {$$(gender.text)}</p>
                        </div>}

                        {this.props.userInfo.birthday !== 0 && <div className="row">
                            <p>
                                <span className="wizard-title"> {$$('birthday_label')} </span>
                                <br/>
                                {dateTimeUtils.getFormattedDate(this.state.birthday)}
                            </p>
                        </div>}

                        {this.props.userInfo.city && <div className="row">
                            <p><span className="wizard-title"> {$$('city_label')} </span> <br/>{this.state.city}</p>
                        </div>}

                        {this.props.userInfo.country && <div className="row">
                            <p><span className="wizard-title"> {$$('country_label')} </span> <br/>{this.lookUpCountry()}
                            </p>
                        </div>}

                        {this.props.userInfo.phone && <div className="row">
                            <p><span className="wizard-title"> {$$('phone_label')} </span> <br/>{this.state.phone}</p>
                        </div>}

                        {this.props.userInfo.email && this.props.userInfo.email.includes("@") && <div className="row">
                            <p><span className="wizard-title"> {$$('email_label')} </span> <br/>{this.state.email}</p>
                        </div>}

                        <GeneralPractitioner generalPractitioner={this.props.generalPractitioner}
                                             formDisabled={this.props.formDisabled}
                                             i18n={this.props.i18n}
                                             selectedUser={this.props.userInfo}
                                             createUserGeneralPractitioner={this.props.createGeneralPractitioner}
                                             deleteUserGeneralPractitioner={this.props.deleteGeneralPractitioner}
                                             updateUserGeneralPractitioner={this.props.updateGeneralPractitioner}
                                             fetchSelectedUserGeneralPractitioner={this.props.fetchSelectedUserGeneralPractitioner}
                                             getGPImportCapture={this.props.getImportGPCapture}
                                             importGP={this.props.importGP}
                                             clearGeneralPractitioner={this.props.clearGeneralPractitioner}
                                             importedPractitioner={this.props.importedPractitioner}
                                             generalPractitionerCapture={this.props.generalPractitionerCapture}
                        />

                    </div>
                </div>
                <div className="health-stats-card">

                    <MedicalProfile changeWeightHeightAndBlood={this.changeWeightHeightAndBlood}
                                    medicalProfile={this.props.medicalProfile}
                                    i18n={this.props.i18n}
                                    clearMedicalProfile={this.props.clearMedicalProfile}
                                    fetchSelectedUserMedicalProfile={this.props.fetchSelectedUserMedicalProfile}
                                    selectedUser={this.props.userInfo}
                                    enableUpdateButton={this.enableUpdateButton}
                                    onPeakFlowNormChanged={this.onPeakFlowNormChanged}
                    />

                    <br/>
                    {this.props.userInfo.email.includes("@") === false &&
                        <div className='row form-group register-control upgrade-child-button'>
                            <div className='col-xs-5 col-md-5 mx-auto'>
                                <button type="button"
                                        className='btn btn-primary btn-block'
                                        onClick={this.upgradeChild}>
                                    {$$('upgrade_child')}
                                </button>
                            </div>
                            <div className='col-xs-5 col-md-5 mx-auto'>
                                <button type="button"
                                        onClick={this.update}
                                        disabled={this.state.updateDisabled}
                                        className="btn btn-primary btn-block">
                                    {$$('update_btn')}
                                </button>
                            </div>
                        </div>}

                    {this.props.userInfo.email.includes("@") &&
                        <div className="form-group register-control" style={{"marginTop": "10px"}}>
                            <div className="col-xs-7 col-md-7 mx-auto">
                                <button type="button"
                                        onClick={this.update}
                                        disabled={this.state.updateDisabled}
                                        className="btn btn-primary btn-block">
                                    {$$('update_btn')}
                                </button>
                            </div>
                        </div>}
                </div>


                <CenteredModal title={$$('upgrade') + " " + this.state.fullname + " " + $$('to_regular')}
                               show={this.state.upgradeOpen}
                               size={"Default"}
                               onHide={this.closePopup}
                               onConfirm={this.openUpgradeConfirmModal}
                               confirmBtnLabel={$$('upgrade_child')}
                               cancelBtnLabel={$$('cancel_btn')}
                >
                    <div>
                        <div className="form-group register-control">
                            <label>{$$('email_label')}</label>
                            <input type="email"
                                   className={this.state.errors.childEmail ? "custom-error form-control" : "form-control"}
                                   value={this.state.childEmail} placeholder={$$('email_label')}
                                   name="childEmail"
                                   onChange={this.onInputChange} required/>
                            <div
                                className={this.state.errors.childEmail ? "custom-invalid-feedback" : "invalid-feedback"}>
                                {this.state.errors.childEmail ? $$(this.state.errors.childEmail) : $$('email_required_message')}
                            </div>
                        </div>

                        <div className="form-group register-control">
                            <label>{$$('password_label')}</label>
                            <input type="password"
                                   className={this.state.errors.childPassword ? "custom-error form-control" : "form-control"}
                                   value={this.state.childPassword} placeholder={$$('password_label')}
                                   name="childPassword"
                                   onChange={this.onInputChange} required/>
                        </div>

                        <div className="form-group register-control">
                            <label>{$$('confirm_password_label')}</label>
                            <input type="password"
                                   className={this.state.errors.childPassword ? "custom-error form-control" : "form-control"}
                                   value={this.state.confirmChildPassword} placeholder={$$('confirm_password_label')}
                                   name="confirmChildPassword"
                                   onChange={this.onInputChange} required/>

                            <div
                                className={this.state.errors.childPassword ? "custom-invalid-feedback" : "invalid-feedback"}>
                                {this.state.errors.childPassword ? $$(this.state.errors.childPassword) : $$('password_required_message')}
                            </div>
                        </div>
                    </div>
                </CenteredModal>

                <CenteredModal title={$$('upgrade') + " " + this.state.fullname + " " + $$('to_regular')}
                               show={this.state.confirmOpen}
                               size={"Default"}
                               onHide={this.closePopup}
                               onConfirm={this.onUpgradeConfirmModal}
                               confirmBtnLabel={$$('upgrade_child')}
                               cancelBtnLabel={$$('cancel_btn')}
                >
                    <div>
                        <p> {$$('confirm_upgrade')}</p>
                        <p> {$$('upgrade_advisory')} {this.state.fullname}</p>
                        <p> {$$('upgrade_continue')}</p>
                    </div>
                </CenteredModal>

                <CenteredModal show={this.state.editOpen}
                               title={$$('edit_profile_label')}
                               size={"Default"}
                               onHide={this.closeEditPopup}
                               onConfirm={this.onSubmit}
                >
                    <br/>
                    <FormWithLoading
                        formDisabled={this.props.formDisabled}
                        currentForm={PROFILE}
                        marginTop="30%"
                        marginLeft="40%"
                        onSubmit={this.submitForm}
                        className={this.state.formclass}>
                        {alert}
                        <div className="form-group register-control">
                            <label>{$$('fullname_label')}</label>
                            <input
                                type="text"
                                className="form-control"
                                value={this.state.editUserInfoData.fullname}
                                placeholder={$$('fullname_label')}
                                name="fullname"
                                onChange={this.onEditInputChange}
                                required
                            />
                            <div className="invalid-feedback">
                                {$$('register_form_full_name_required')}
                            </div>
                        </div>

                        <div className="form-group register-control">
                            <label>{$$('phone_label')} </label>
                            <PhoneInput
                                name="phone"
                                id="phone"
                                defaultCountry={this.props.userInfo.country ? this.props.userInfo.country.toUpperCase() : this.props.i18n.selected.key.toUpperCase()}
                                placeholder={$$('phone_label')}
                                value={this.state.editUserInfoData.phone ? this.state.editUserInfoData.phone : ""}
                                onChange={(p) => {
                                    let user = this.state.editUserInfoData
                                    user.phone = p ? p : "";
                                    if (p && p !== "") {
                                        this.setState({editUserInfoData: user})
                                    }
                                    if (!p || p === ""){
                                        document.getElementById("phone").setCustomValidity("")
                                    } else if (!isPossiblePhoneNumber(p || "")) {
                                        document.getElementById("phone").setCustomValidity("invalid")
                                    } else {
                                        document.getElementById("phone").setCustomValidity("")
                                    }
                                }}
                                numberInputProps={{className:`form-control`, required:false}}
                                labels={this.props.i18n.selected.lang === "bg" ? BG : en}
                            />
                            {this.state.formClass === "was-validated" && <div className="invalid-feedback d-block">{this.state.phone ? (isPossiblePhoneNumber(this.state.phone) ? undefined : $$('valid_phone_required')) : $$('valid_phone_required')}</div>}

                        </div>

                        {this.props.userInfo.email.includes("@") &&
                            <div className="form-group register-control">
                                <label>{$$('email_label')}</label>
                                <input type="email"
                                       className={this.state.errors.email || (this.state.email && !validators.validateEmail(this.state.email) || !this.state.email) ? "custom-error form-control" : "form-control"}
                                       value={this.state.editUserInfoData.email}
                                       placeholder={$$('email_label')}
                                       name="email"
                                       onChange={this.onEditInputChange}
                                       required
                                />
                                <div
                                    className={this.state.errors.email || (this.state.email && !validators.validateEmail(this.state.email) || !this.state.email) ? "custom-invalid-feedback" : "invalid-feedback"}>
                                    {this.state.errors.email && (!this.state.email ? $$("email_required_message") : $$(this.state.errors.email))}
                                </div>
                            </div>
                        }

                        <div className="row register-control">
                            <div className="col-sm-6">
                                <Select
                                    label={$$('gender_label')}
                                    name="gender"
                                    value={this.state.editUserInfoData.gender}
                                    options={getResolvedOptions(GENDER.OPTIONS)}
                                    onChange={this.onSelectChange}
                                    style={{
                                        'minWidth': '100%',
                                        'minHeight': '47px',
                                        'maxWidth': '100%',
                                        'zIndex': '1',
                                        'opacity': '70%'
                                    }}/>
                            </div>
                            <div className="col-sm-6">
                                <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        variant="inline"
                                        format={this.props.settings.dateFormat}
                                        margin="normal"
                                        id="date-picker-inline"
                                        label={$$("birthday_label")}
                                        value={this.state.editUserInfoData.birthday}
                                        onChange={this.onDateChange}
                                        KeyboardButtonProps={{'aria-label': 'change date'}}
                                        required
                                        invalidDateMessage={$$('invalid_date_format')}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="row" style={{"marginTop": "5px"}}>
                            <div className="col-sm-6">
                                <Select
                                    label={$$('country_label')}
                                    name="country"
                                    options={countryOptions(this.props.i18n.selected.lang)}
                                    value={this.state.editUserInfoData.country?.toLowerCase()}
                                    onChange={this.onSelectChange}
                                    style={{
                                        'minWidth': '100%',
                                        'minHeight': '47px',
                                        'maxWidth': '100%',
                                        'zIndex': '1',
                                        'opacity': '70%'
                                    }}/>
                            </div>
                            <div className="col-sm-6">
                                <label>{$$('city_label')}</label>
                                <input type="text"
                                       className="form-control"
                                       value={this.state.editUserInfoData.city}
                                       placeholder={$$('city_label')}
                                       name="city"
                                       style={{height: '47px'}}
                                       onChange={this.onEditInputChange}/>
                            </div>
                        </div>
                        {this.props.userInfo.email.includes("@") && <div>
                            <div className="form-check custom-checkbox custom-control text-left mt-3">
                                <input className="custom-control-input" type="checkbox" name="success-outlined"
                                       onChange={this.marketingAgreementChanged}
                                       checked={this.state.editUserInfoData.user_flags?.MARKETING_EMAILS}
                                       id="success-outlined"/>
                                <label className="custom-control-label ml-2" htmlFor="success-outlined">
                                    {$$('marketing_agreements_message')}
                                </label>
                            </div>
                        </div>}
                    </FormWithLoading>
                </CenteredModal>
                <ApproveChild
                    showAcceptChild={this.state.approveChildPopup}
                    formDisabled={this.props.formDisabled}
                    child={this.state.selectedChildToApprove}
                    closePopup={() => {
                        this.setState({approveChildPopup: false})
                    }}
                />
                <CenteredModal show={this.state.removeChildPopup || this.state.removeParentPopup}
                               title={$$('delete_connected_profile')}
                               size={"Default"}
                               confirmBtnClass={"btn btn-danger"}
                               confirmBtnLabel={$$('delete_label')}
                               onHide={() => {
                                   this.setState({removeChildPopup: false, removeParentPopup: false})
                               }}
                               onConfirm={this.removeSelectedProfile}
                >
                    <div className="form-group register-control">

                        {this.props.userInfo.id !== this.props.parent.id && this.state.selectedProfileToRemove && _$$("delete_connected_profile_text", this.state.selectedProfileToRemove.fullname, this.props.userInfo.fullname)}
                        {this.props.userInfo.id === this.props.parent.id && this.state.selectedProfileToRemove && _$$("delete_connected_profile_text_parent", this.state.selectedProfileToRemove.fullname)}
                        <br/>
                        <br/>
                        {this.state.selectedProfileToRemove && this.getNumberOfParentsForChild(this.state.selectedProfileToRemove.id) === 1 &&
                            <span
                                className={"text-danger"}>{_$$("delete_connected_profile_text_final_parent", this.state.selectedProfileToRemove.fullname)} </span>}

                    </div>
                </CenteredModal>
                <CenteredModal show={this.state.addParentPopup}
                               title={$$('add_connected_profile')}
                               size={"Default"}
                               onHide={() => {
                                   this.setState({addParentPopup: false})
                               }}
                               onConfirm={this.addParent}
                >
                    <div className="form-group register-control">
                        <span>{$$("add_connected_profile_info")}</span>
                        <br/>
                        <br/>
                        <span className={"text-danger"}>{$$("add_connected_profile_info_warn")}</span>
                        <br/>
                        <br/>
                        <div className="form-group register-control">
                            <label>{$$('email_label')}</label>
                            <input type="email"
                                   className={this.state.errors.parentEmail ? "custom-error form-control" : "form-control"}
                                   value={this.state.parentEmail}
                                   placeholder={$$('email_label')}
                                   name="parentEmail"
                                   onChange={this.onInputChange}
                                   required/>
                            <div
                                className={this.state.errors.parentEmail ? "custom-invalid-feedback" : "invalid-feedback"}>
                                {this.state.errors.parentEmail ? $$(this.state.errors.parentEmail) : $$('email_required_message')}
                            </div>
                        </div>
                    </div>
                </CenteredModal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        connectProfileResponse: state.children_profiles.connectProfileResponse,
        profileUpdateResponse: state.userInfo.request.success,
        children: state.children_profiles.entries,
        parents: state.children_profiles.parents,
        childParentCount: state.children_profiles.childParentCount,
        formDisabled: state.formInteractions,
        generalPractitioner: state.practitioner.generalPractitioner.value,
        generalPractitionerCapture: state.practitionerCapture.value,
        importedPractitioner: state.importedPractitioner.value,
        settings: state.settings.data,
        healthIssues: state.healthIssues,
        medications: state.medication.medicationPlan.entries
    }
}

UserProfileForm.propTypes = {
    showSuccessfulAlert: PropTypes.any,
    children: PropTypes.any,
    parents: PropTypes.any,
    profileUpdateResponse: PropTypes.bool,
    clearUpdateRequest: PropTypes.func,
    childParentCount: PropTypes.any,
    formDisabled: PropTypes.any,
    connectProfileResponse: PropTypes.any,
    generalPractitioner: PropTypes.object,
    createGeneralPractitioner: PropTypes.func,
    getImportGPCapture: PropTypes.func,
    importedPractitioner: PropTypes.any,
    updateUserMedicalProfile: PropTypes.func,
    state: PropTypes.any,
    importGP: PropTypes.func,
    generalPractitionerCapture: PropTypes.object,
    selectUser: PropTypes.func,
    updateGeneralPractitioner: PropTypes.func,
    fetchSelectedUserGeneralPractitioner: PropTypes.func,
    deleteGeneralPractitioner: PropTypes.func,
    userInfo: PropTypes.shape({
        fullname: PropTypes.string,
        user_uin: PropTypes.string,
        id: PropTypes.string,
        response: PropTypes.any,
        sex: PropTypes.string,
        gender: PropTypes.string,
        birthday: PropTypes.number,
        city: PropTypes.string,
        phone: PropTypes.string,
        country: PropTypes.string,
        email: PropTypes.string
    }),
    parentId: PropTypes.string,
    parent: PropTypes.object,
    selectedUser: PropTypes.func,
    updateUserInfo: PropTypes.func,
    upgradeChildToFullUser: PropTypes.func,
    medicalProfile: PropTypes.shape({
        data: PropTypes.shape({
            weight: PropTypes.number,
            height: PropTypes.number,
            blood_type: PropTypes.any,
            blood: PropTypes.string
        })
    }),
    i18n: PropTypes.object,
    upgrade: PropTypes.object,
    clearGeneralPractitioner: PropTypes.func,
    fetchSelectedUserMedicalProfile: PropTypes.func,
    getParentList: PropTypes.func,
    getParentCount: PropTypes.func,
    clearParentCount: PropTypes.func,
    deleteChild: PropTypes.func,
    denyChild: PropTypes.func,
    getChildren: PropTypes.func,
    deleteParent: PropTypes.func,
    addParentByEmail: PropTypes.func,
    clearConnectedProfileResponse: PropTypes.func,
    settings: PropTypes.any,
    clearMedicalProfile: PropTypes.func
};

const mapDispatchToProps = {
    updateUserMedicalProfile,
    updateUserInfo,
    selectUser,
    getParentList,
    getParentCount,
    createGeneralPractitioner,
    updateGeneralPractitioner,
    fetchSelectedUserGeneralPractitioner,
    deleteGeneralPractitioner,
    getImportGPCapture,
    importGP,
    clearGeneralPractitioner,
    fetchSelectedUserMedicalProfile,
    clearMedicalProfile,
    clearParentCount,
    deleteChild,
    denyChild,
    clearUpdateRequest,
    deleteParent,
    getChildren,
    addParentByEmail,
    clearConnectedProfileResponse,
    logout
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileForm)


import {fetchHelper} from '../helpers/request_helper';
import {
    GET_LATEST_SYMPTOMS_TYPES,
    GET_NOMENCLATURE_URL,
    GET_USER_SYMPTOMS_LOG_URL,
    LOG_USER_SYMPTOMs_URL
} from '../constants/api_paths';

export const symptomsService = {
    getNomencalture,
    fetchSelectedUserSymptoms,
    logSelectedUserSymptoms,
    getLatestSymptomsTypes
};

/**
 * Get nomencalture from the service
 *
 * @returns {object} promise object
 */
export function getNomencalture() {
    return fetchHelper.callGet(GET_NOMENCLATURE_URL);
}

/**
 * Fetch the selected user symptoms
 *
 * @param {string} userId id of the user to fetch symptoms for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserSymptoms(userId, params) {
    return fetchHelper.callGet(GET_USER_SYMPTOMS_LOG_URL.replace('{userId}', userId), params);
}

/**
 * Log the selected user symptoms
 *
 * @param {string} userId id of the user to log symptoms for
 * @param {Array} body the body of the post request
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function logSelectedUserSymptoms(userId, body, params) {
    return fetchHelper.callPost(LOG_USER_SYMPTOMs_URL.replace('{userId}', userId).replace('{modified_after}', params.modified_after), body);
}

/**
 * It will return the latest used symptoms types for a given user
 * @param {*} userId
 * @param {*} params
 * @returns
 */
export function getLatestSymptomsTypes(userId, params) {
    return fetchHelper.callGet(GET_LATEST_SYMPTOMS_TYPES.replace('{userId}', userId), params);
}
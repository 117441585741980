import React, {Component} from 'react';
import {$$} from '../../helpers/localization'
import {connect} from 'react-redux'
import {login} from '../../actions/auth_actions'
import PropTypes from "prop-types";


class RegisterPageSuccess extends Component {

    constructor(props) {
        super(props)

        this.state =
            {
                password: "",
                email: ""
            }
    }

    componentDidMount() {
        this.setState({password: this.props.password})
        this.setState({email: this.props.email})
    }

    onLogin = async (e) => {
        e.preventDefault()

        const email = this.state.email;
        const password = this.state.password;

        if (email !== "" && password !== "") {
            await this.props.login(email, password);

        }
        this.props.hideSuccessForm()
        this.props.clearRegistrationData();

    }

    render() {
        return (
            <div className="register-success-container">
                <h2>{$$('congrats_message')}</h2>
                <h4>{$$('registration_success_message')}</h4>
                <div className="register-success-image"/>
                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                    <button type="button" className="btn btn-success btn-block" onClick={this.onLogin}>
                        {$$('continue_medrec')}
                    </button>
                </div>
                <br/>
            </div>
        );
    }
}

RegisterPageSuccess.propTypes = {
    clearRegistrationData: PropTypes.func,
    email: PropTypes.string,
    hideSuccessForm: PropTypes.func,
    login: PropTypes.func,
    password: PropTypes.string,
};


const mapDispatchToProps = {
    login
}

export default connect(null, mapDispatchToProps)(RegisterPageSuccess);
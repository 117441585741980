import useVideoContext from "../../../components/video/hooks/useVideoContext/useVideoContext";
import useIsTrackEnabled from "../../../components/video/hooks/useIsTrackEnabled/useIsTrackEnabled";
import useIsTrackSwitchedOff from "../../../components/video/hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import {VideoControlsOverlay} from "../../../components/videocall/VideoControlsOverlay";
import React from "react";

export default function ExposeContextToVideoControls({modalShow, onHangup, topbar}) {
    const context = useVideoContext();
    let audioTrack = context.localTracks.find(track => track.kind === 'audio');
    let isAudioTrackEnabled = useIsTrackEnabled(audioTrack);
    let isAudioTrackSwitchedOff = useIsTrackSwitchedOff(audioTrack);
    let audioMuted = !isAudioTrackEnabled || isAudioTrackSwitchedOff;
    let videoTrack = context.localTracks.find(track => track.kind === 'video');
    let isVideoTrackEnabled = useIsTrackEnabled(videoTrack);
    let isVideoTrackSwitchedOff = useIsTrackSwitchedOff(videoTrack);
    let videoMuted = !isVideoTrackEnabled || isVideoTrackSwitchedOff;
    return <VideoControlsOverlay topbar={topbar} modal={false} videoMuted={videoMuted} audioMuted={audioMuted} modalShow={modalShow} onHangup={onHangup}/>
}
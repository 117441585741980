import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import CliniciansListView from './CliniciansListView'
import Hospital from './Hospital'
import {connect} from 'react-redux'
import PropTypes from "prop-types";
import {SERVER_URL} from "../../constants/api_paths";
import {isLoggedIn} from "../../helpers/auth_helper";
import Select from "../shared/Select";
import Search from "../shared/Search";

class HospitalCliniciansView extends Component {
    constructor(props) {
        super(props)
        this.state =
            {
                hospital: "",
            }
    }

    componentDidMount() {
        let hospital = this.props.hospital
        this.setState({hospital: hospital, clinicians: hospital.clinicians})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.hospital !== prevProps.hospital) {
            let hospital = this.props.hospital
            this.setState({hospital: hospital, clinicians: hospital.clinicians})
        }
    }

    handleHideClinicians = () => {
        this.props.hideClinicians()
    }

    render() {
        const {hospital, clinicians} = this.state
        return (
            <div>
                {isLoggedIn() && <div className="mt-1 back-to-clinicians pointer"
                                      style={{"marginLeft": "2rem", "paddingTop": "2rem"}}
                                      onClick={this.handleHideClinicians}>
                    <span><i className="fa fa-arrow-left"/></span>
                    <span className="ml-2">{$$('back_to_hospitals')}</span>
                </div>}

                <div className={isLoggedIn() ? "high-shadow-container d-flex" : "d-flex mb-4"} style={{
                    "textAlign": "left",
                    "marginTop": "2rem",
                    "marginLeft": "0.5rem",
                    "marginRight": "0.5rem"
                }}>
                    {
                        hospital !== "" && !this.state.show_placeholder &&
                        <div className="p-2">
                            <img style={{width: "60px"}}
                                 src={`${SERVER_URL}/public/provider/hospital/logo/${hospital.hospital.id}`}
                                 onError={() => {
                                     this.setState({show_placeholder: true})
                                 }}/>
                        </div>
                    }
                    {this.state.show_placeholder && <div className="p-2"><i className="flaticon2-hospital"/></div>}
                    {hospital !== "" &&
                        <Hospital
                            i18n={this.props.i18n}
                            specialtiesOptions={this.props.specialtiesOptions}
                            selectedSpecialtyFilter={this.props.selectedSpecialtyFilter}
                            specialties={this.props.specialties}
                            filterCliniciansByName={this.props.filterCliniciansByName}
                            onSelectChange={this.props.onSelectChange}
                            hospital={this.state.hospital}
                        />}

                </div>
                {
                    clinicians &&
                    <CliniciansListView
                        location={this.props.location}
                        providers={clinicians}
                        i18n={this.props.i18n}
                        onClinicianClick={this.props.onClinicianClick}
                    />
                }
            </div>
        )
    }
}

HospitalCliniciansView.propTypes = {
    dispatch: PropTypes.func,
    specialtiesOptions: PropTypes.array,
    filterCliniciansByName: PropTypes.func,
    onSelectChange: PropTypes.func,
    location: PropTypes.object,
    hideClinicians: PropTypes.func,
    hospital: PropTypes.object,
    i18n: PropTypes.object,
    onClinicianClick: PropTypes.func,
    providers: PropTypes.array,
    settings: PropTypes.object,
    specialties: PropTypes.array,
    selectedSpecialtyFilter: PropTypes.any,
    userInfo: PropTypes.object
}

function mapStateToProps(state) {
    return {
        userInfo: state.userInfo.data,
        settings: state.settings,
        providers: state.providers.entries,
        i18n: state.language,
        specialties: state.specialties.specialties
    }
}

export default connect(mapStateToProps)(HospitalCliniciansView);
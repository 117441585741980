export const
    en = {
        text: {
            morning_time: "Morning Time",
            evening_time: "Evening Time",
            noon_time: "Noon Time",
            bed_time: "Bed Time",
            back_label: "Back",
            add_profile_photo: "Add photo",
            congrats_message: "Congratulations",
            continue_medrec: "Continue to Medrec-M",
            registration_success_message: "Your profile was successfully created",
            wizard_provider_image_label: "Upload your photo",
            title: "MEDREC : M",
            app: "app",
            password_creation_title:"Password must contain:",
            at_Lest_eight_characters:"At least 8 characters",
            at_Lest_one_uppercase_characters:"At least one Uppercase character (A-Z)",
            at_Lest_one_lowercase_characters:"At least one Lowercase character (a-z)",
            at_Lest_one_number:"At least one number (0-9)",
            at_Lest_one_special:"At least one Special character (~!@#$%^&*_-+=`|\\(){}[]:;\"'<>,.?/)",
            password_is_not_valid:"Password is not Valid (See Password Requirements)",
            main_page_label: "Medical record",
            select_language_label: "Select language",
            login_label: "Log in",
            login_form_title: "Log in",
            social_media_login_separator: "Or",
            logout_label: "Log out",
            save_btn_label: "Save",
            username_label: "Username",
            email_label: "E-mail",
            password_label: "Password",
            new_password_label: "New Password",
            repeat_password_label: "Confirm New Password",
            change_password_label: "Change password",
            user_password_updated_message: "Password updated successfully",
            user_password_update_error_message: 'Server error',
            email_required_message: "E-mail is required",
            invalid_date_format: "Invalid Date Format",
            invalid_time_format: "Invalid Time Format",
            password_required_message: "Password is required",
            placeholder_email_message: "Type your email address",
            placeholder_password_message: "Type your password",
            forgotten_password: "Forgot your password?",
            agreements_message: "By clicking here you agree with our",
            agreements_message_ca: "Terms Of Use.",
            login_form_invalid_credentials_message: "Invalid credentials",
            register_label: "Register",
            fullname_label: "Full Name",
            male_label: "Male",
            female_label: "Female",
            register_form_password_confirm_label: "Confirm password",
            register_form_email_exists_message: "E-mail already exists",
            register_form_email_not_correct_message: "E-mail is not correct",
            register_form_passwords_not_match_message: "Passwords do not match",
            register_form_password_length_message: "Password should have minimum 8 characters",
            register_form_password_whitespace_message: "Password cannot start or end with whitespace character",
            register_form_full_name_required: "Full Name is required",
            register_form_password_confirm_required_message: "Password confirmation is required",
            register_form_successful_message: "User successfully created",
            confirm_btn_label: "Confirm",
            close_btn_label: "Close",
            close_search: "Close",
            Charts: "Charts",
            Logbook: "Logbook",
            logbook_entries_header: " ",
            delete_log_entry_modal_header: "Confirm your action",
            delete_log_entry_modal_text: "Are you sure you want to delete this entry?",
            select_entry_type_error: "Please select an entry type first",
            glucose_units: "Glucose Unit",
            ketones_units: "Ketones Unit",
            hba1c_units: "HbA1c Unit",
            Systolic: "Systolic",
            Diastolic: "Diastolic",
            Pulse: "Pulse",
            notes: "Notes",
            appointment_notes_title:"Messages",
            appointment_note_title:"Message",
            total_cholesterol: "Total Cholesterol",
            total_label: "Total",
            triglycerides: "Triglycerides",
            Cholesterol: "Cholesterol",
            Weight: "Weight",
            Height: "Height",
            weight_and_height: "Weight and Height",
            chronic_conditions: "Chronic Conditions",
            allergies: "Allergies",
            blood_group: "Blood Group",
            details: "Details",
            blood_pressure: "Blood Pressure",
            settings_label: "Settings",
            unit_of_measurement_label: "Unit of Measurement",
            cholesterol_units_label: "Cholesterol Unit",
            classification_method_label: "Classification Method",
            date_format_label: "Date Format",
            time_24hour_label: "Use 24 Hour Clock",
            settings_updated_message: "Settings saved successfully",
            US: "US (lbs, oz, fl oz, inch)",
            metric: "Metric (kg, gram, ml, cm)",
            kg: "kg",
            pound: "lbs",
            inches: "inches",
            cm: "cm",
            None: "None",
            diagnosed_in: "Diagnosed in",
            mild: "Mild",
            mild_to_moderate: "Mild to moderate",
            moderate: "Moderate",
            moderate_to_severe: "Moderate to severe",
            severe: "Severe",
            life_threatening: "Life threatening",
            drug_allergy: "Drug Allergy",
            dust_allergy: "Dust Allergy",
            food_allergy: "Food Allergy",
            insect_allergy: "Insect Allergy",
            latex_allergy: "Latex Allergy",
            mold_allergy: "Mold Allergy",
            pet_allergy: "Pet Allergy",
            percent: "%",
            millimol_per_mol: "mmol/mol",
            pollen_allergy: "Pollen Allergy",
            other_allergy: "Other Allergy",
            Severity: "Severity",
            Medications: "Medications",
            Medication_plans: "Medication Plans",
            millimol_per_litre: "mmol/L",
            milligram_per_decilitre: "mg/dl",
            millimetre_of_mercury: "mmHg",
            days_between_intake: "Days between intake",
            days: "Days",
            day: "Day",
            every: "Every",
            number_times_per_day: "Number of times per day",
            chat_disabled_message: "Chat messages with this clinician are currently disabled!",
            chat_disabled_message_with_unlock_time: "Chat messages with this clinician will be available on %1!",
            number_of_days: "Number of Days",
            no_unread_messages: "No Unread Chat Messages",
            search_within_selected_messages_label: "Search Chat Messages",
            of: "of",
            no_users_after_filtering:"No Search Results",
            sort_by_name:"Sort By Name",
            sort_by_most_recent:"Sort By Most Recent",
            new_messages_only:"Unread Chat Messages Only",
            today: "Today",
            yesterday: "Yesterday",
            week_label: "7 days",
            fortnight_label: "14 days",
            month_label: "30 days",
            quarter_label: "90 days",
            custom_label: "Custom",
            symptoms: "Symptoms",
            Temperature: "Temperature",
            Hydration: "Hydration",
            Respiratory_rate: "Respiratory rate",
            Saturation: "Oxygen saturation",
            celsius: "°C",
            ml: "ml",
            bpm: "bpm",
            fahrenheit: "°F",
            fl_oz: "fl oz",
            refresh_token_expired_message: 'Session timeout',
            sharing_label: "Sharing",
            clinicians_label: "Clinicians",
            no_clinicians_primary_label: "No Clinicians Found",
            no_clinicians_secondary_label: "We could not find any clinicians matching the query",
            all_clinicians: "All Clinicians",
            waiting_for_approval_list_label: "Waiting for your approval",
            access_links_label: "Temporary access links",
            shared_with_list_label: "Shared with",
            deny_permission_label: "Deny",
            approve_permission_label: "Approve",
            share_data_label: "Share data",
            new_link_label: "New link",
            share_label: "Share",
            valid_to_label: "Valid to",
            requested_user_does_not_exist_message: "User with requested e-mail does not exist",
            share_data_success_message: "Permission successfully requested",
            forgot_password: "Forgot password",
            privacy_policy: "Privacy Policy",
            gender_label: "Sex",
            country_label: "Country",
            valid_phone_required: "A valid phone number is required",
            male: "Male",
            female: "Female",
            city_label: "City",
            birthday_label: "Date of Birth",
            dashboard: "Dashboard",
            user_info_label: "User info",
            latest_measurements_label: "Latest measurements",
            last_week_data: "Weekly charts",
            no_recent_measurements: "There are no measurements for this user.",
            loading_data_label: "Loading data...",
            logbook_no_data_label: "This user has not logged any entries yet.",
            symptoms_no_data_label: "This user has not logged any symptoms yet.",
            search_placeholder_label: "Search...",
            latest_symptoms_label: "Last 24 hours symptoms log",
            no_latest_symptoms_label: "No Symptoms Logged In The Last 24 Hours.",
            profile_label: 'Profile',
            profile_tab: 'Main Profile',
            edit_profile_label: 'Edit Profile',
            info_required:"Required information",
            info_required_phone_explanation:"Please, enter your name and phone number, so that the clinic or the clinician can contact you if necessary. This information will be stored under 'Name' and 'Phone' in %1's Medrec:M user profile.",
            update_btn: 'Update',
            upload_image_label: 'Upload profile picture',
            user_info_updated_message: 'User profile updated successfully',
            unsupported_image_format: 'The selected file format is not supported',
            choose_file_label: 'Choose image',
            documents: "Documents",
            category_label: "Category",
            document_type_label: "Document type",
            all_document_types_label: "All documents",
            type_description_label: "Type description",
            document_download_dialog_title: "Download document",
            confirmation_dialog_message: "The document ({fileName}) you want to download belongs to {owner} and can contain sensitive data. Make sure you keep it in a safe location or delete it after use.",
            documents_no_data_label: "This user has not uploaded any documents yet.",
            no_documents_label:"You do not have any created documents.",
            download_btn: "Download",
            auto_logout:"Your session has expired",
            make_inactive:"Deactivate Plan",
            make_active:"Activate Plan",
            make_plan_inactive: "Are you sure you want to deactivate this plan?",
            download_as_pdf: "Download the Examination report",
            download_as_pdf_consultation: "Download the Examination report",
            cancel_btn: "Cancel",
            document_type_none: "None",
            document_type_epicrisis: "Epicrisis",
            document_type_prescription: "Prescription",
            lab_name: "Laboratory Name",
            document_type_lab_results: "Laboratory results",
            document_type_ambulance_sheet: "Medical report",
            document_type_referral: "Referral",
            document_type_other: "Other",
            document_type_diagnostic_imaging: "Diagnostic Imaging",
            all_label: "All",
            select_category: "Select category",
            select_end_date: "Select End Date",
            documents_no_data_for_filters_label: "There are no documents corresponding to the selected filters.",
            pending_for_auth_approval_label: "Users who have not yet accepted your sharing request",
            start_date: "From Date",
            end_date: "To Date",
            add_entry: "Add new entry",
            upgrade_child: "Promote to regular account",
            upgrade: "Promote",
            to_regular: "to a regular account",
            confirm_password_label: "Confirm password",
            passwords_dont_match: "Passwords do not match",
            password_not_long_enough: "Password should have minimum 8 characters",
            password_whitespace_message: "Password cannot start or end with whitespace character",
            confirm_upgrade: "You are about to upgrade this profile to a regular account.",
            upgrade_advisory: "Once this action is complete you will be returned to the dashboard and you will no longer be able to manage",
            upgrade_continue: "Are you sure you want to continue?",
            update_entry: "Update entry",
            entry_types: "All Entries",
            entry_type_temperature: "Temperature",
            entry_type_blood_pressure: "Blood pressure",
            entry_type_blood_sugar: "Blood sugar",
            entry_type_hba1c: "HBA1C",
            hba1c: "HBA1C",
            entry_type_ketones: "Ketones",
            entry_type_urine_ph: "Urine PH",
            entry_type_weight: "Weight",
            entry_type_height: "Height",
            entry_type_cholesterol: "Cholesterol",
            cholesterol: "Cholesterol",
            entry_type_oxygen_saturation: "Oxygen saturation",
            entry_type_respiratory_rate: "Respiratory rate",
            respiratory_rate: "Respiratory rate",
            entry_type_hydration: "Hydration",
            hydration: "Hydration",
            entry_type_menstruation: "Menstruation",
            menstruation: "Menstruation",
            entry_type_medication: "Medication",
            position_type_seated: "Seated",
            position_type_lying: "Lying",
            position_type_standing: "Standing",
            measuring_site_type_left_arm: "Left arm",
            measuring_site_type_right_arm: "Right arm",
            measuring_site_type_left_wrist: "Left wrist",
            measuring_site_type_right_wrist: "Right wrist",
            measuring_site_type_left_thigh: "Left thigh",
            measuring_site_type_right_thigh: "Right thigh",
            measuring_site_type_left_calf: "Left calf",
            measuring_site_type_right_calf: "Right calf",
            measuring_site_type_left_ankle: "Left ankle",
            measuring_site_type_right_ankle: "Right ankle",
            temperature_label: "Temperature",
            temperature: "Temperature",
            systolic_label: "Systolic",
            diastolic_label: "Diastolic",
            pulse_label: "Pulse",
            position_label: "Position",
            measuring_site_label: "Measuring site",
            arrhythmia_detected_label: "Arrhythmia detected",
            blood_sugar_label: "Blood sugar",
            blood_sugar: "Blood sugar",
            hba1c_label: "HbA1c",
            ketones_label: "Ketones",
            ketones: "Ketones",
            urine_ph_label: "Urine pH",
            urine_ph: "Urine pH",
            weight_label: "Weight",
            weight: "Weight",
            height_label: "Height",
            height: "Height",
            total_cholesterol_label: "Total Cholesterol",
            ldl_label: "LDL",
            hdl_label: "HDL",
            triglycerides_label: "Triglycerides",
            oxygen_saturation_label: "Oxygen saturation",
            saturation: "Oxygen saturation",
            respiratory_rate_label: "Respiratory rate",
            hydration_label: "Hydration",
            flow_label: "Flow",
            color_label: "Colour",
            primary_colour: "Primary Colour",
            secondary_colour: "Secondary Colour",
            select_medication_style: "Select Medication Style",
            consistency_label: "Consistency",
            no_medications: "No Medications Have Been Added",
            medication_name_label: "Medication name",
            unit_label: "Unit",
            dose_label: "Dose",
            notes_label: "Communication",
            datetime_required_message: "Date and time are required",
            date_required_message: "Date is required",
            datetime_max_value_message: "Date and time cannot be set in the future",
            end_date_before_start_date: "End date can not be set before start date",
            temperature_required_message: "Temperature is required",
            temperature_wrong_format_message: "Temperature has wrong format",
            temperature_out_of_range_message: "Temperature value is out of range",
            blood_pressure_required_message: "Either blood pressure or pulse fields are required",
            blood_pressure_wrong_format_message: "Blood pressure fields have wrong format",
            blood_pressure_out_of_range_message: "Blood pressure fields are out of range",
            blood_sugar_required_message: "Blood sugar is required",
            blood_sugar_wrong_format_message: "Blood sugar has wrong format",
            blood_sugar_out_of_range_message: "Blood sugar value is out of range",
            hba1c_required_message: "Hemoglobin A1C is required",
            hba1c_wrong_format_message: "Hemoglobin A1C has wrong format",
            hba1c_out_of_range_message: "Hemoglobin A1C value is out of range",
            ketones_required_message: "Ketones are required",
            ketones_wrong_format_message: "Ketones have wrong format",
            ketones_out_of_range_message: "Ketones value is out of range",
            urine_ph_required_message: "Urine pH is required",
            urine_ph_wrong_format_message: "Urine pH has wrong format",
            urine_ph_out_of_range_message: "Urine pH value is out of range",
            weight_required_message: "Weight is required",
            weight_wrong_format_message: "Weight has wrong format",
            weight_out_of_range_message: "Weight value is out of range",
            height_required_message: "Height is required",
            height_wrong_format_message: "Height has wrong format",
            height_out_of_range_message: "Height value is out of range",
            cholesterol_required_message: "At least one of the cholesterol fields must be filled",
            total_cholesterol_wrong_format_message: "Total cholesterol has wrong format",
            total_cholesterol_out_of_range_message: "Total cholesterol value is out of range",
            ldl_wrong_format_message: "LDL has wrong format",
            ldl_out_of_range_message: "LDL value is out of range",
            hdl_wrong_format_message: "HDL has wrong format",
            hdl_out_of_range_message: "HDL value is out of range",
            triglycerides_wrong_format_message: "Triglycerides have wrong format",
            triglycerides_out_of_range_message: "Triglycerides are out of range",
            oxygen_saturation_required_message: "Oxygen saturation is required",
            oxygen_saturation_wrong_format_message: "Oxygen saturation has wrong format",
            oxygen_saturation_out_of_range_message: "Oxygen saturation value is out of range",
            respiratory_rate_required_message: "Respiratory rate is required",
            respiratory_rate_wrong_format_message: "Respiratory rate has wrong format",
            respiratory_rate_out_of_range_message: "Respiratory rate value is out of range",
            hydration_required_message: "Hydration is required",
            hydration_wrong_format_message: "Hydration has wrong format",
            hydration_out_of_range_message: "Hydration value is out of range",
            medication_name_required_message: "Medication name is required",
            unit_required_message: "Unit is required",
            dose_required_message: "Dose is required",
            dose_wrong_format_message: "Dose has wrong format",
            dose_out_of_range_message: "Dose value is out of range",
            load_more: "Show next",
            afternoon: "Afternoon",
            morning: "Morning",
            evening: "Evening",
            select_gender: "Select Sex",
            my_documents_label: "My documents",
            my_children_label: "My children",
            HBA1C: "HbA1c",
            Ketones: "Ketones",
            Flow: "Flow",
            Color: "Color",
            Consistency: "Consistency",
            Urine_pH: "Urine pH",
            not_entered: "Not entered",
            menstruation_stats:"Menstruation Statistics",
            duration_range_picker_label:"Reporting period",
            year: "Year",
            years:"Years",
            light: "Light",
            medium: "Medium",
            heavy: "Heavy",
            unexpected: "Unexpected",
            none: "None",
            black: "Black",
            brown: "Brown",
            dark_red: "Dark red",
            bright_red: "Bright red",
            pink: "Pink",
            orange: "Orange",
            gray: "Gray",
            watery: "Watery",
            clots: "Clots",
            mmol_mol: "mmol/mol",
            no_chronic_conditions: "No Chronic Conditions",
            no_allergies: "No Allergies",
            Communication: "Communication",
            my_communication_label: "My Communications",
            new_chat: "New Chat",
            recent_chats_label: "Recent Chats",
            available_users_label: "Available Users",
            back: "Back",
            back_to_hospitals: "Back to Clinics",
            back_to_hospital: "Back to Clinic",
            back_to_hospital_with_name:"Back to %1",
            type_message: "Type message...",
            call: "Incoming Call",
            incoming_call_message: "Incoming call from %1",
            incoming_call_another_user_message: "Incoming call from another user",
            accept: "Accept",
            no_user_selected_message: 'No User Selected To Call',
            warning: "Warning",
            call_ending_soon: "Call Ending Soon",
            call_ending_soon_body: "The end of the allotted time for the current appointment is approaching!",
            no_answer: "No Answer",
            no_answer_message: "No Answer From %1",
            you_wrote: "You wrote",
            missed_call_label: "Missed call",
            rejected_call_label: "Rejected call",
            in_progress_call_label: "Call in progress",
            completed_call_label: "Call ended",
            ongoing_call: "Call ongoing",
            medication_plan: "Medication plan",
            general_practitioner: "General Practitioner",
            practice_name: "Practice Name",
            practice_name_required: "Practice Name is Required",
            practice_address: "Practice Address",
            practice_address_required: "Practice Address is Required",
            practice_contact: "Contact number",
            practice_contact_required: "Contact number is Required",
            practice_centre_code: "Centre code",
            practice_centre_code_required: "Centre code is Required",
            practice_practitioner_code: "Practitioner code",
            practice_practitioner_code_required: "Practitioner code is Required",
            na: "N/A",
            times_per_day: "%1 %2, %3 time a day",
            examination_outcome:"Examination outcome",
            times_per_day_plural: "%1 %2, %3 times a day",
            every_x_hours: "%1 %2, every %3 hour",
            every_x_hours_plural: "%1 %2, every %3 hours",
            daily: 'Daily',
            every_x_days: "Every X day(s)",
            every_days: "Every day",
            every_days_plural: "Every %1 days",
            take_x_rest_x: "Take X day(s) and rest Y day(s)",
            take_rest_x_plural: "Take %1 days and rest one day",
            take_rest_y_plural: "Take one day and rest %2 days",
            take_rest_xy_plural: "Take %1 days and rest %2 days",
            take_rest: "Take one day and rest one day",
            when_needed: "When needed",
            ongoing: "Ongoing",
            until_date: 'Until date ',//%1',
            for_x_days_until: "For X day(s)", //until %2"
            for_days_until: "for %1 day",
            for_days_until_plural: "for %1 days",
            specific_days_of_week: "Specific days of week",
            effective_from: "Effective from %1",
            medical_record_label: "Medical record",
            Lab_Results: "Lab Results",
            select_laboratory_label: "Select Laboratory",
            all_laboratory_label: "All Laboratories",
            lab_results_open_results_label: "Open results",
            lab_results_close_results_label: "Close results",
            lab_results_no_data_for_filters_label: "There are no laboratories results corresponding to the selected filters.",
            lab_results_no_data_label: "This user has not imported any laboratories results yet.",
            lab_test_col_name_name: "Name",
            lab_test_col_name_result: "Result",
            lab_test_col_name_units: "Units",
            lab_test_col_name_flag: "Flag",
            lab_test_col_name_range: "Expected range",
            lab_microbiology_col_name_antibiogram: "Antibiogram",
            select: "Select",
            preview_image: 'Preview',
            OK: 'OK',
            delete_image_modal_title: 'Remove image',
            delete_image_modal_message: 'Are you sure you want to remove your image?',
            add_symptom_label: "Add symptom",
            log_symptoms_label: "Log symptoms",
            log_symptoms_date_label: "Date",
            log_symptoms_time_label: "Time",
            log_symptoms_notes_label: "Notes",
            log_symptoms_form_date_time_not_correct_message: "Selected date cannot be greater than current date",
            log_symptoms_form_date_time_required_message: "Date is required",
            mark_symptom_entry_deleted_modal_title: "Mark symptom entry as deleted",
            mark_symptom_entry_deleted_modal_message: "Are you sure you want to mark this symptom entry as deleted?",
            mark_document_entry_deleted_modal_title: "Mark document entry as deleted",
            mark_document_entry_deleted_modal_message: "Are you sure you want to delete this document?",
            unit_type_ampule: "ampule(s)",
            unit_type_application: "application(s)",
            unit_type_capsule: "capsule(s)",
            unit_type_drop: "drop(s)",
            unit_type_gram: "g(s)",
            unit_type_injection: "injection(s)",
            unit_type_mg: "mg(s)",
            unit_type_ml: "ml(s)",
            unit_type_packet: "sachet(s)",
            reset_btn_label: "Reset",
            unit_type_patch: "patch(es)",
            unit_type_piece: "piece(s)",
            unit_type_tablet: "tablet(s)",
            unit_type_puff: "puff(s)",
            unit_type_spray: "spray(s)",
            unit_type_suppository: "suppository(ies)",
            unit_type_tbsp: "tbsp(s)",
            unit_type_tsp: "tsp(s)",
            unit_type_unit: "unit(s)",
            create_document: "Create Document",
            appointment_filter_all: "All",
            appointment_filter_completed: "Completed appointments",
            appointment_filter_pending: "Pending appointments",
            appointment_filter_active_consults: "Active consultations",
            document_title: "Document Title",
            document_title_required_message: "Document Title Required",
            type_description_required_message: "Type Description Required",
            document_category: "Document Category",
            shared_label: "Shared",
            upload_document: "Upload Document",
            new_document: "New Document",
            no_file_chosen: "No File Chosen",
            file_required_message: "File Required",
            obstetrics_gynecology: 'Obstetrics and Gynecology',
            allergology: 'Allergology',
            angiology: 'Angiology',
            venereology: 'Venereology',
            internal_medicine: 'Internal Medicine',
            gastroenterology: 'Gastroenterology',
            thoracic_surgery: 'Thoracic surgery',
            dental_medicine: 'Dental Medicine',
            dermatology: 'Dermatology',
            pediatric_gastroenterology: 'Pediatric gastroenterology',
            pediatric_endocrinology: 'Pediatric endocrinology',
            pediatric_neurology: 'Pediatric neurology',
            pediatric_nephrology: 'Pediatric nephrology',
            child_psychiatry: 'Child psychiatry',
            pediatric_pulmonology: 'Pediatric pulmonology',
            pediatric_rheumatology: 'Pediatric rheumatology',
            endocrinology: 'Endocrinology',
            immunology: 'Immunology',
            cardiology: 'Cardiology',
            cardiac_surgery: 'Cardiac surgery',
            clinical_genetics: 'Clinical genetics',
            speech_therapy: 'Speech therapy',
            mammology: 'Mammology',
            microbiology: 'Microbiology',
            neurology: 'Neurology',
            neurological_surgery: 'Neurological Surgery',
            neonatology: 'Neonatology',
            nephrology: 'Nephrology',
            nuclear_medicine: 'Nuclear medicine',
            imaging_diagnostics: 'Imaging diagnostics',
            general_medicine: 'General medicine',
            oncology: 'Oncology',
            orthodontics: 'Orthodontics',
            orthopedics_traumatology: 'Orthopedics and Traumatology',
            otoneurology: 'Otoneurology',
            ophthalmology: 'Ophthalmology',
            pediatrics: 'Pediatrics',
            psychiatry: 'Psychiatry',
            psychology: 'Psychology',
            psychotherapy: 'Psychotherapy',
            pulmonology: 'Pulmonology',
            rheumatology: 'Rheumatology',
            vascular_surgery: 'Vascular Surgery',
            ent: 'ENT',
            urology: 'Urology',
            physical_medicine_rehabilitation: 'Physical Medicine and Rehabilitation',
            hematology: 'Hematology',
            surgery: 'Surgery',
            homeopathy: 'Homeopathy',
            specialties: "Specialties",
            health_issues_label: "Health Issues",
            health_issues_menu_label: "Health Issues",
            health_issues_no_data_label: "No Health Issues have Been Created Yet",
            health_issues_no_data_primary_label: "No Health Issues",
            processing_label: "Processing",
            my_appointments_label: "Appointments",
            appointments_no_data_primary_label: "No Pending Appointments",
            no_appointments_primary_label: "No Pending Appointments",
            waiting_for_doctor_approval: "Waiting For Confirmation",
            waiting_for_doctor_approval_consultation: "Waiting For Confirmation",
            waiting_for_patient_approval: "Waiting For Your Confirmation",
            waiting_for_patient_approval_consultation: "Waiting For Your Confirmation",
            need_more_info: "Need more information",
            need_more_info_consultation: "Need more information",
            accepted: "Confirmed",
            accepted_consultation: "Confirmed",
            completed: "Completed",
            completed_consultation: "Completed",
            rejected_by_patient: "Rejected",
            rejected_by_patient_consultation: "Rejected",
            canceled_by_patient: "Canceled",
            canceled_by_patient_consultation: "Canceled",
            rejected_by_provider: "Rejected",
            rejected_by_provider_consultation: "Rejected",
            canceled_response_overdue:"Canceled (Response Overdue)",
            canceled_response_overdue_consultation:"Canceled (Response Overdue)",
            canceled_not_paid:"Canceled (Payment Overdue)",
            canceled_not_paid_consultation:"Canceled (Payment Overdue)",
            canceled_by_provider: "Canceled",
            canceled_by_provider_consultation: "Canceled",
            cancel_appointment_confirmation_header: "Are you sure you want to cancel your appointment with {fullname} for {date}?",
            cancel_consultation_confirmation_header: "Are you sure you want to cancel your text consultation with {fullname} created on {date}?",
            cancel_appointment_label: "Cancel appointment",
            cancel_consultation_label: "Cancel consultation",
            cancel_appointment_confirmation_body: "This appointment will be cancelled immediately. You cannot undo this action.",
            cancel_consultation_confirmation_body: "This consultation will be cancelled immediately. You cannot undo this action.",
            consultation_deadline_time: "The deadline for the clinician to respond is %1",
            consultation_follow_up_deadline_time: "You can send messages until %1",
            edit_label: "Edit",
            start_date_label: "Start date",
            end_date_label: "End date",
            delete_label: "Delete",
            primary_exam: "Primary Exam",
            follow_up_exam: "Follow Up Exam",
            other: "Other",
            online: 'Online',
            on_site: 'On Site',
            first_available_hour_label: "Earliest Appointment",
            book_another_hour_label: "Book Another Hour",
            select_appointment_type_message: "Please, select a consultation or an examination in order to see the available hours",
            make_an_appointment_label: "Make an Appointment",
            disclaimer: "Additional conditions",
            text_only_response_explanation: "Please select a time for the clinician to respond. The response time is not guaranteed but if there is no response from the clinician in that time, you will be refunded.",
            hours: "hours ",
            clinician_responds_in: "This clinician responds in ",
            response_in_16: "Response in 16 hours",
            response_in_24: "Response in 24 hours",
            response_in_48: "Response in 48 hours",
            response_in_X_days: "Response in %1 days",
            response_no_restriction: "Response in 30 days",
            make_a_text_consultation_label: "Make a Text Consultation",
            contacts_label: "Contacts",
            phone_contact_label: "Call: ",
            phone_label:"Phone",
            email_contact_label: "Email to: ",
            no_number_label: "No Number Provided",
            no_email_label: "No Email Provided",
            select_today_label: 'Today',
            eccounter_data_separator: "Examination Result",
            consultation_results_data_separator: "Consultation Result",
            prescriptions: "Medications",
            main_diagnosis_label: "Main Diagnosis",
            objective_data_label: "Objective Data",
            subjective_data_label: "Subjective Data",
            therapy_label: "Therapy",
            concomitant_diseases_label: "Concomitant diseases and complications",
            conclusion:"Conclusion",
            preliminary_diagnosis:"Preliminary diagnosis",
            tests_label: "Tests",
            no_encounter_found_label: "This appointment is not yet completed.",
            no_encounter_found_label_consultation: "This consultation is not yet completed.",
            payment_completed: "Paid",
            payment_completed_consultation: "Paid",
            payment_initial: "Not Paid",
            payment_initial_consultation: "Not Paid",
            payment_in_progress: "Payment in Progress",
            payment_in_progress_consultation: "Payment in Progress",
            payment_rejected: "Not Paid",
            payment_rejected_consultation: "Not Paid",
            create_appointment_health_issue_message: "or specify below only the health issues that are related to this appointment. This way the clinician will have access only to the records and documents associated to the attached health issues.",
            create_consultation_health_issue_message: "or specify below only the health issues that are related to this consultation. This way the clinician will have access only to the records and documents associated to the attached health issues.",
            create_appointment_notes_message: "Add additional information about the reasons for your appointment. Provide as much as possible information.",
            create_consultation_notes_message: "Add additional information about the reasons for your consultation. Provide as much as possible information.",
            notes_required_message: "A message is required to create a text consultation",
            select_health_issue_label: "Select Health Issue",
            create_appointment: "Create Appointment",
            create_health_issue: "New Health Issue",
            health_issue_title: "Health issue title",
            health_issue_description: "Description",
            health_issue_title_required_message: "Health issue title required",
            related_label: "Related Items",
            date_label: "Date",
            mark_health_issue_entry_deleted_modal_title: "Mark health issue entry as deleted",
            mark_health_issue_entry_deleted_modal_message: "Are you sure you want to mark this health issue entry as deleted?",
            child_label: "Create a new connected profile",
            delete_connected_profile:"Delete Connected Profile",
            connected_profiles:"Connected Profiles",
            main_user: "Main Profile",
            approve_child:"Approve Connected Profile",
            approve:"Approve",
            deny:"Deny",
            delete_connected_profile_text:"Are you sure you want to revoke the permission given to %1 to manage the profile of %2? This action cannot be undone.",
            delete_connected_profile_text_parent:"Are you sure you want to revoke your permission to manage %1? This action cannot be undone.",
            check_email:"A user with this email cannot be found.",
            add_connected_profile:"Add a connected Profile",
            add_connected_profile_info:"To add a connected profiles, type the users registered email address into the input field below.",
            add_connected_profile_info_warn:"Important: All connected Profiles will have full control over this profile.",
            delete_connected_profile_text_final_parent:"You are the final connected profile for %1. If you continue %1's profile and all of its data will be deleted.",
            pin_required:"PIN Required",
            select_user_for_appointment:"Select User",
            submission_error:"Profile not connected. Check PIN",
            rejection_error:"Server error",
            messages_label: "Chat",
            recent_messages_title: "Recent Chat Messages",
            no_messages_to_show: "No Chat Messages To Show",
            messages_will_appear_here: "Chat messages will appear here",
            search_in_messages_label: "Search",
            search_for_medications: "Search",
            medications_found: "Medications Found",
            needs_prescription:"Needs Prescription",
            doesnt_need_prescription:"No Prescription Required",
            add_allergy: "Add Allergy",
            show_info_modal: "Additional Info",
            allergen_name_label: "Allergen Name",
            date_diagnosed_label: "Date Diagnosed",
            description: "Description",
            medications: "Medications",
            allergy_severity_label: "Allergy Severity",
            allergy_type_label: "Allergy Type",
            edit_allergy: "Edit Allergy",
            add_condition: "Add Condition",
            edit_condition: "Edit Condition",
            allergen_name_required_message: "Allergen Name Required",
            chronic_condition_name_required_message: "Chronic Condition Name Required",
            create: 'Create',
            condition_name: "Condition Name",
            mark_allergy_entry_deleted_modal_title: "Are you sure you want to delete allergy?",
            mark_condition_entry_deleted_modal_title: "Are you sure you want to delete condition?",
            disease_code: "Disease Code",
            disease: "Disease",
            no_results_found: "No Results Found",
            good_morning: "Good Morning",
            good_afternoon: "Good Afternoon",
            good_evening: "Good Evening",
            charts: "Charts",
            last_month_vitals: "Latest Vitals",
            your_doctors: "Your Doctors",
            recent_health_issues: "Recent Health Issues",
            see_all_label: "See All",
            see_vitals_history_label: "See Vitals History",
            lab_result: "Import Lab Result",
            select_label: "Select",
            user_id: "User ID",
            credentials_message: "Please, enter the credentials that you received from the laboratory. We don't keep these credentials and they are used only to retrieve the information from laboratory's site.",
            cibalab: "User ID",
            bodimed: "ID No.",
            ramus: "ID number",
            status: "User ID",
            pisanec: "User ID",
            acibadem_sofia: "User number",
            acibadem_varna: "User number",
            acibadem_sofia_cityclinic: "User number",
            continue: "Continue",
            Captcha: "Captcha",
            EGN: "EGN",
            import: "Import",
            username_required_label: "Please, enter a username",
            select_date_label: "Select date",
            symptoms_history: "Symptoms History",
            more: "More",
            less: "Less",
            this_week: "This Week",
            current_issues: "Current Issues",
            past_issues: "Past Issues",
            chronic_condition: "Chronic Condition",
            allergy_condition: "Allergy",
            vitals: "Vitals",
            labs: "Labs",
            add_health_issue_label: "Add Health Issue",
            edit_health_issue_label: "Update Health Issue",
            create_lab_document: "Do you want to also create a document with the original results in the Documents section?",
            skip_label: "Skip",
            all_issues: "All Health Issues",
            search_by_symptom: "Search symptom",
            mark_lab_result_entry_deleted_modal_title: "Delete laboratory result",
            mark_lab_result_entry_deleted_modal_message: "Are you sure you want to delete this laboratory result?",
            your_recent_symptoms: "Your Recent Symptoms",
            choose_symptom: "Choose Symptom",
            search_for_symptom: "Search For Symptom",
            no_symptoms_label: "No Symptoms",
            search_for_clinicians_label: "Search For Clinicians",
            hospitals_label: "Clinics",
            search_for_specialties_or_clinicians_label: "Search for specialties or clinicians...",
            back_to_clinicians_label: "Back to clinicians",
            examinations_label: "Examinations And Consultations",
            biography_label: "Biography",
            document_name_label: "Document name",
            size_label: "Size",
            date_added_label: "Date added",
            document_label: "Add Document",
            update_document: "Update Document",
            book_label: "Book",
            create_appointment_successful_message: "You have successfully booked an appointment",
            mark_medication_entry_deleted_modal_message: "Are you sure you want to delete this medication plan?",
            med_start_date: "Effective from ",
            no_one_waiting_for_your_approval_label: "You do not have anyone waiting for approval",
            not_sharing_data_with_anyone_label: "You are not sharing data with anyone",
            not_waiting_for_anyones_approval_label: "There are no users who have not yet accepted your sharing request",
            title_label: "Title",
            update_note: "Update note",
            create_note: "Create note",
            note_required_message: "Notes are required",
            delete_note_modal_header: "Confirm your action",
            delete_note_modal_text: "Are you sure you want to delete this note?",
            general_note_label: "General note",
            new_note_label: "New Message",
            back_to_appointments_label: "Back to appointments",
            note_label: "Note",
            pay_label: "Pay",
            schedule: "Schedule",
            duration: "Duration",
            frequency: "Frequency",
            frequency_times: "Intake number",
            frequency_hours: "Number of hours",
            frequency_times_day: "X times a day",
            frequency_every_hours: "Every X hours",
            free_label:"Free",
            free_label_consultation:"Free",
            take: "Take",
            rest: "Rest",
            cycle_day: "Cycle Day",
            cycle: "Cycle",
            last_cycle:"Last Cycle",
            cycle_average:"Average",
            cycle_shortest:"Shortest",
            cycle_longest:"Longest",
            period_average:"Average",
            period_shortest:"Shortest",
            period_longest:"Longest",
            length: "Duration",
            download_menstruation_report:"Download Menstruation Report",
            menstruation_report: "Menstruation Report",
            history:"History",
            period:"Period",
            last_period_length: "Last Period Length",
            change_time: "Change time",
            weekend_schedule: "Use different schedule for weekends",
            add_medication: "Add Medication",
            import_all: "Import all",
            import_selected: "Import Selected",
            update_medication: "Update Medication",
            no_symptoms_after_filters: "No Symptom Logs Were Found After Filtering",
            no_logs_after_filters: "No Logs Were Found After Filtering",
            add_notes: "Add Notes",
            pay_now: "Pay Now",
            patient_uin: "PIN",
            no_appointment_notes: "No Messages For This Appointment",
            agreement_selected_required: "You need to agree to our Terms of Use in order to continue",
            look_for_hospitals_label: "Look for Clinics",
            logbook_type_label: "Logbook type",
            radio_active_label: "Active",
            radio_inactive_label: "Inactive",
            my_medications: "My Medications",
            all_medications: "All Medications",
            pickup_call_button_label: "Pick Up",
            hang_up_button_label: "Hang Up",
            incoming_call_label: "Incoming Call",
            inactive_plan: "Inactive plan",
            taken_on_information: "Taken {amount} {unit} on {dateTime}",
            time_remaining: "Expected time remaining: {time}",
            time_over: "{time} over expected end time",
            inventory: "Inventory",
            skip_reason_none: "No Reason",
            skip_reason_forgot_to_take: "Forgot to take",
            skip_reason_med_not_near_me: "Medicine is not near me",
            skip_reason_asleep: "I was asleep",
            skip_reason_too_busy: "I was too busy",
            skip_reason_out_of_med: "I was out of medicine",
            skip_reason_side_effects: "I experienced side effects",
            skip_reason_other: "Other reason",
            skip_reason_expensive: "It is too expensive",
            skip_reason_dose_not_needed: "I don't need that dose",
            planned_for_information: "Planned for",
            skipped_information: "Skipped {amount} {unit}",
            delete_plan: "Delete plan",
            delete_med_history: "Delete medication history",
            mark_med_history_deleted_modal_message: "Would you like to delete all past records for this medication plan from the logbook?",
            no_label: "No",
            pick_date_and_time: "Pick date and time",
            reschedule_label: "Change time",
            take_as_planned_label: "Take as planned, at {time}",
            take_now_label: "Take now, at {time}",
            take_at_selected_time_label: "Take at the selected time",
            skipping_reason: "Please, specify the reason for skipping the dose?",
            reason_notes: "Reason notes",
            rescheduled_for_label: "Rescheduled for ",
            reset_medication_msg: "Are you sure you want to reset the medication and delete the entry from the logbook?",
            reset_medication_label: "Reset medication",
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday: "Sunday",
            no_hours_available_primary_label: "No Hours Available",
            no_temporary_links_label_label: "You do not have any temporary access links",
            selected_symptoms: "Selected Symptoms",
            version: "Version",
            accept_appointment_confirmation_header: "Are you sure you want to approve the appointment with {fullname} on {date}?",
            accept_appointment_confirmation_body: "This appointment will be approved immediately.",
            accept_appointment_label: "Approve",
            payment_option_modal_body:"Select a payment method for your appointment with {fullname} on {date}",
            payment_option_consultation_modal_body: "Select a payment method for your text consultation with {fullname} created on {date}",
            pay_with_card_button:"Pay with a card",
            pay_with_epay_button:"Pay with ePay",
            epay_payment_option_descr:"Use this option if you have a registration with ePay",
            cc_payment_option_descr:"Use this option to pay with your debit or credit card",
            payment_payed_waiting_body_text:"Please, wait until we receive confirmation for your payment",
            payment_canceled_body_text: "The payment is canceled!",
            payment_completed_body_text: "The payment is completed!",
            total_sum: "total",
            cancellation_policy:"Cancellation Policy",
            cancellation_policy_agreement_label_first_part:"Check here to indicate that you have read and agree to the ",
            terms_and_conditions:"Terms Of Use",
            login_form_temp_locked_down_message: "Your account has been temporarily locked due to repeated failed log in attempts. Please wait for several minutes before trying to log in again.",
            login_form_must_reset_password_message: "Your account is locked due to repeated failed log in attempts. You can unlock it by resetting your password using the Forgot password function.",
            pay_before: "Pay before",
            missed_payment:"Unpaid before the deadline",
            missed_payment_consultation:"Unpaid before the deadline",
            start_video:"Start Video",
            stop_video:"Stop Video",
            no_video:"No Video",
            no_audio:"No Audio",
            mute:"Mute",
            unmute:"Unmute",
            join_now: "Join Now",
            enter_examination_room: "Enter Examination Room",
            entering_examination_room: "Entering Examination Room",
            audio:"Audio",
            audio_input:"Audio Input",
            video:"Video",
            video_input:"Video Input",
            audio_and_video_settings: "Audio and Video Settings",
            no_local_audio: "No Local Audio",
            done_btn:"Done",
            no_local_video:"No Local Video",
            You:"You",
            current_video_call:"Current Video Session",
            camera_permissions_denied_heading:"Unable to Access Media:",
            camera_permissions_denied_message:"The user has denied permission to use video. Please grant permission to the browser to access the camera.",
            microphone_permissions_denied_heading:"Unable to Access Media:",
            microphone_permissions_denied_message:'The user has denied permission to use audio. Please grant permission to the browser to access the microphone.',
            not_allowed_error_heading:'Unable to Access Media:',
            not_allowed_error_message:'The operating system has blocked the browser from accessing the microphone or camera. Please check your operating system settings.',
            not_allowed_error_message_alt:'The user has denied permission to use audio and video. Please grant permission to the browser to access the microphone and camera.',
            not_found_error_heading:'Cannot Find Microphone or Camera:',
            not_found_error_message:'The browser cannot access the microphone or camera. Please make sure all input devices are connected and enabled.',
            connection_error_heading:'Connection Error:',
            connection_error_message:'Media connection failed or Media activity ceased. Please, rejoin the room and wait for the other participant to join.',
            error_acquiring_media_heading:'Error Acquiring Media:',
            no_camera_or_microphone_detected_heading:'No Camera or Microphone Detected:',
            no_camera_or_microphone_detected_message:'Other participants in the room will be unable to see and hear you.',
            no_camera_detected_heading:'No Camera Detected:',
            no_camera_detected_message:'Other participants in the room will be unable to see you.',
            no_microphone_detected_heading:'No Microphone Detected:',
            no_microphone_detected_message:'Other participants in the room will be unable to hear you.',
            error_occurred:"Error occurred! Please contact our support at support@medrec-m.com.",
            confirm_delete:"Are you sure you want to close your account with Medrec-M?",
            confirm_delete_advisory:"Closing your account will delete all of your data and all your connected profiles which don't have other profiles connected to them.",
            delete_word_confirm_label:"Please, type in the word DELETE in the box below",
            share_all_data_explanation: "Important! Shared data will be available to the clinician until the appointment is canceled or 14 days after the scheduled appointment's time!",
            share_all_data_consultation_explanation: "Important! Shared data will be available to the clinician until the consultation is canceled or 14 days after the creation of this consultation!",
            share_all_data_label:"Share all data with the clinician",
            controlled_by:"Profiles with access",
            attach_documents_to_appointment_title:"Attach Documents",
            attach_documents_to_appointment_explanation:"The attached documents will be available to the clinician until you detach them!",
            results_documents:"My Attached Documents",
            results_documents_button:"Attach Documents",
            appointment_complete:"This appointment is completed.",
            get_for_ios:"Get for iOS",
            get_for_android:"Get for Android",
            choose_mobile_app_text:"For the best experience on mobile, download our app",
            liters_per_minute:"L/min",
            peak_flow:"Peak Flow",
            entry_type_peak_flow:"Peak Flow",
            peak_flow_label:"Peak Flow",
            peak_flow_required_message:"Peak Flow is required",
            peak_flow_wrong_format_message:"Peak Flow is in the wrong format",
            peak_flow_out_of_range_message:"Peak Flow is out of range",
            peak_flow_with_symptoms:"With Symptoms",
            peak_flow_symptoms_label:"If you experience any of these warning symptoms check the box.",
            peak_flow_inhaler_label: "Used reliever inhaler",
            peak_flow_asthma_symptoms_label: "Had asthma symptoms such as shortness of breath, tight chest, coughing or wheezing",
            peak_flow_night_asthma_symptoms_label: "Waking at night with asthma symptoms",
            peak_flow_daily_activity_label: "Feeling like you can’t keep up with your normal day-to-day activities",
            fill_in_the_questionnaire:"For this appointment the clinician requires to fill a questionnaire.",
            to_the_questionnaire:"Proceed to the Questionnaire",
            edit_questionnaire:"Edit the Questionnaire",
            reset_questionnaire_answers:"Clear the answers",
            next_question_btn:"Next Question",
            new_message_from: "From",
            previous_question_btn:"Previous Question",
            export_medical_profile:"Download Medical History",
            marketing_agreements_message:"Receive timely updates with the latest medical news and healthcare tips from us.",
            until_label: "until",
            additional_results:"Additional Tests and Procedures",
            peak_flow_norm:"Peak Flow Norm (L/min)",
            additional_info:"Additional Information",
            book_the_appointment_btn:'Book the appointment',
            confirm_appointment_btn:"Confirm the appointment",
            no_additional_exam_results:"The examination result is empty.",
            no_additional_exam_results_consultation:"The consultation result is empty.",
            next_appointment:"Next appointment",
            active_text_consultations:"Active text consultations",
            last_message: "%1 wrote",
            text_consultation: "Text consultation",
            text_only_payment_explanation:"Attention! In order to start text consultation with this clinician, the full price for the consultation must be paid! You can pay later, but the clinician will see your message after the payment is processed.",
            text_only_pre_payment_text: "You successfully started online consultation.\n\nAttention! The clinician will see your message when the full price of the consultation is paid.\n\nYour payment end date is %1. After that time the consultation will be automatically canceled.",
            text_only_pay_later_warning: "Attention! The clinician will see your message and the response time countdown will start after the consultation is paid.",
            pay_later_btn: "Pay Later",
            search_with: "Search with ",
            search_medicine_with: "Search medicine with ",
            results_provider_documents:"Clinician's attached documents",
            override_location_to_online:"Request this appointment to be a video consultation",
            override_location_to_online_explanation:"If you choose the option below the clinician will make an online video consultation at the selected time instead of an in place examination",
            MORNING:"Morning",
            EARLY_MORNING:"Early Morning",
            LATE_MORNING:"Late Morning",
            NOON:"Noon",
            AFTERNOON:"Afternoon",
            EARLY_AFTERNOON:"Early Afternoon",
            LATE_AFTERNOON:"Late Afternoon",
            EVENING:"Evening",
            EARLY_EVENING:"Early Evening",
            LATE_EVENING:"Late Evening",
            NIGHT:"Night",
            AFTER_SLEEP:"After Sleep",
            BEFORE_SLEEP:"Before Sleep",
            UPON_WAKING:"Upon Waking",
            WITH_MEAL:"With Meal",
            WITH_BREAKFAST:"With Breakfast",
            WITH_LUNCH:"With Lunch",
            WITH_DINNER:"With Dinner",
            BEFORE_MEAL:"Before Meal",
            BEFORE_BREAKFAST:"Before Breakfast",
            BEFORE_LUNCH:"Before Lunch",
            BEFORE_DINNER:"Before Dinner",
            AFTER_MEAL:"After Meal",
            AFTER_BREAKFAST:"After Breakfast",
            AFTER_LUNCH:"After Lunch",
            AFTER_DINNER:"After Dinner",
            intake_instructions_note_label: "Intake instructions",
            already_existing_appointment_body: "You already have an appointment with this clinician at %1.",
            pay_online_or_onsite:"This appointment can be payed online or during the visit on site",
            select_user_for_appointment_modal_body:"Select the user for whom you want to book an appointment or text consultation.",
            patient_label: "Patient",
            adult_btn_label: "Adult",
            child_btn_label: "Child",
            child_profile_section: "Child Profile",
            account_section: "Parent/Guardian Account Registration",
            child_fullname_label:"Child's Name",
            register_form_child_full_name_required:"Child's Name is required",
            adult_child_registration_explanation_text: "Registration of a <strong>Child (a person under 18 years old)</strong> " +
                "always requires filling in the details of a parent/guardian, creating a primary parental profile that manages the child's profile." +
                "<br/><br/>" +
                "After registering as an <strong>Adult (a person over 18 years of age)</strong>, " +
                "you can add a related family profile of your relative, child or other.",
            adult_child_registration_explanation_ps:"After successful registration, you will be redirected to the Medrec:M platform to finalize the appointment booking.",
            name_field_required: "Name is Required",
            nhis_prescription_label: "E-prescription",
            nhis_supplement: "Non-medicinal products",
            medical_notices: "Medical Notices",
            medical_notice_nrn_number:"NRN number",
            medical_notice_issued_on_label:"Issued On",
            medical_notice_reason_label:"Reason",
            medical_notice_location_view_label:"Treatment location",
            medical_notice_from:"From",
            medical_notice_for_period:"Period",
            medical_notice_institution_label:"To serve before",
            medical_notice_note_label:"Clarifying notes",
            medical_notice_diagnosis: "Disease",
            remove_all_documents_from_appointment_btn:"Detach all documents",
            nhis: {
                ereferral: {
                    ereferrals: "Referrals",
                    nrn_number: "NRN",
                    authoredOn: "Date of issuance",
                    category: "Referral category",
                    activity: "Assigned medical diagnostic activities",
                    qualificationTarget: "Medical specialty the patient is referred to",
                    qualificationTargets: "Medical specialties the patient is referred to",
                    admissionType: "Type of admission",
                    directedBy: "Referred by",
                    specializedActivityCode: "Specialized activities or manipulations, or procedures for physical and rehabilitation medicine",
                    examType: "Type of examination under NHIF for which the referral for medical expertise is issued",
                    workIncapacityReason: "Reason for issuing the referral",
                    clinicalPathway: "NHIF clinical pathway",
                    outpatientProcedure: "NHIF outpatient procedures the patient is sent for",
                }
            },
            medication: {
                unit: {
                    label: {
                        ampule: {
                            display_long: 'ampule',
                            display_long_plural: 'ampules',
                            display_short: '',
                            display_short_plural: ''
                        },
                        application: {
                            display_long: 'application',
                            display_long_plural: 'applications',
                            display_short: '',
                            display_short_plural: ''
                        },
                        capsule: {
                            display_long: 'capsule',
                            display_long_plural: 'capsules',
                            display_short: '',
                            display_short_plural: ''
                        },
                        drop: {
                            display_long: 'drop',
                            display_long_plural: 'drops',
                            display_short: '',
                            display_short_plural: ''
                        },
                        gram: {
                            display_long: 'gram',
                            display_long_plural: 'grams',
                            display_short: 'g',
                            display_short_plural: 'gs'
                        },
                        injection: {
                            display_long: 'injection',
                            display_long_plural: 'injections',
                            display_short: '',
                            display_short_plural: ''
                        },
                        mg: {
                            display_long: 'milligram',
                            display_long_plural: 'milligrams',
                            display_short: 'mg',
                            display_short_plural: 'mgs'
                        },
                        ml: {
                            display_long: 'milliliter',
                            display_long_plural: 'milliliters',
                            display_short: 'ml',
                            display_short_plural: 'mls'
                        },
                        packet: {
                            display_long: 'sachet',
                            display_long_plural: 'sachets',
                            display_short: '',
                            display_short_plural: ''
                        },
                        patch: {
                            display_long: 'patch',
                            display_long_plural: 'patches',
                            display_short: '',
                            display_short_plural: ''
                        },
                        piece: {
                            display_long: 'piece',
                            display_long_plural: 'pieces',
                            display_short: '',
                            display_short_plural: ''
                        },
                        tablet: {
                            display_long: 'tablet',
                            display_long_plural: 'tablets',
                            display_short: '',
                            display_short_plural: ''
                        },
                        puff: {
                            display_long: 'puff',
                            display_long_plural: 'puffs',
                            display_short: '',
                            display_short_plural: ''
                        },
                        spray: {
                            display_long: 'spray',
                            display_long_plural: 'sprays',
                            display_short: '',
                            display_short_plural: ''
                        },
                        suppository: {
                            display_long: 'suppository',
                            display_long_plural: 'suppositories',
                            display_short: '',
                            display_short_plural: ''
                        },
                        tbsp: {
                            display_long: 'tablespoon',
                            display_long_plural: 'tablespoons',
                            display_short: 'tbsp',
                            display_short_plural: 'tbsps'
                        },
                        tsp: {
                            display_long: 'teaspoon',
                            display_long_plural: 'teaspoons',
                            display_short: 'tsp',
                            display_short_plural: 'tsps'
                        },
                        unit: {
                            display_long: 'unit',
                            display_long_plural: 'units',
                            display_short: '',
                            display_short_plural: ''
                        }
                    }
                }
            },
            logentry_how_to_do_it: "How to do it",
            logentry_instructions_title: "How to measure",
            logentry_blood_pressure_instructions1: "Avoid caffeinated or alcoholic beverages 30 minutes beforehand.",
            logentry_blood_pressure_instructions2: "Sit quietly for five minutes with your back supported and your legs uncrossed.",
            logentry_blood_pressure_instructions3: "Support your arm so your elbow is at or near heart level.",
            logentry_blood_pressure_instructions4: "Wrap the cuff over bare skin.",
            logentry_blood_pressure_instructions5: "Don’t talk during the measurement.",
            logentry_blood_pressure_instructions6: "Leave the deflated cuff in place, wait a minute, then take a second reading. If the readings are close, average them. If not, repeat again and average the three readings.",
            logentry_respiratory_rate_instructions0: "You must ask another person to measure your respiratory rate.",
            logentry_respiratory_rate_instructions1: "Sit upright.",
            logentry_respiratory_rate_instructions2: "Let the other person tries to count your respirations without you knowing. If you know, you may try to control your breathing. This can give a false respiratory rate.",
            logentry_respiratory_rate_instructions3: "Let the other person use a watch with a second hand and count your breaths for 60 seconds. He may use any of the following methods to count:\n  - Looks at your chest rise and fall. One rise and one fall are counted as 1 breath.\n  - Listens to your breaths.\n  - Places his hand on your chest to feel the rise and fall.",
            logentry_oxygen_saturation_instructions1: "Home use oximeters are available for sale online and in chemists. These can be used to measure the levels of oxygen in your blood.",
            logentry_oxygen_saturation_instructions2: "Before testing at home, talk to your health care professional.",
            logentry_oxygen_saturation_instructions3: "The oximeter display shows the percentage of oxygen in your blood. For someone who’s healthy, the normal blood oxygen saturation level will be around 95–100%.",
            logentry_oxygen_saturation_instructions0: "Oxygen is carried around in your red blood cells by a molecule called haemoglobin. Pulse oximetry measures how much oxygen the haemoglobin in your blood is carrying. This is called the oxygen saturation and is a percentage (scored out of 100).",
            logentry_temperature_instructions_title1: "Using a digital oral thermometer",
            logentry_temperature_instructions11: "Wash your hands with soap and warm water.",
            logentry_temperature_instructions12: "Use a clean thermometer, one that has been washed in cold water, cleaned with rubbing alcohol, and then rinsed to remove the alcohol.",
            logentry_temperature_instructions13: "Do not eat or drink anything for at least five minutes before you take your temperature because the temperature of the food or beverage could make the reading inaccurate. You should keep your mouth closed during this time.",
            logentry_temperature_instructions14: "Place the thermometer tip under the tongue.",
            logentry_temperature_instructions15: "Hold the thermometer in the same spot for about 40 seconds.",
            logentry_temperature_instructions16: "Readings will continue to increase and the F (or C) symbol will flash during measurement.",
            logentry_temperature_instructions17: "Usually, the thermometer will make a beeping noise when the final reading is done (usually about 30 seconds). If you are keeping track, record the temperature and the time.",
            logentry_temperature_instructions18: "Rinse thermometer in cold water, clean it with alcohol and rinse again.",
            logentry_temperature_instructions_title2: "Using a digital axillary thermometer",
            logentry_temperature_instructions21: "Remove the shirt and place the thermometer tip into your armpit. Make sure your armpit is dry to get the most accurate reading.",
            logentry_temperature_instructions22: "Keep the axillary thermometer in place by folding your arm across your chest.",
            logentry_temperature_instructions23: "The thermometer will beep when reading is done (this method may take longer than 30 seconds).",
            logentry_temperature_instructions24: "Remove and record temperature and time.",
            logentry_temperature_instructions25: "Clean the thermometer with soap and water or with alcohol, always rinsing as a last step.",
            logentry_temperature_instructions_title3: "Using a tympanic thermometer",
            logentry_temperature_instructions31: "Pull gently back on the top of the ear to open the ear canal.",
            logentry_temperature_instructions32: "Place the protective cover on the tip of the thermometer.",
            logentry_temperature_instructions33: "Gently insert the thermometer until the ear canal is fully sealed off.",
            logentry_temperature_instructions34: "Press and hold down the button for 1–2 seconds until you hear a beep (follow the manufacturer’s instructions).",
            logentry_temperature_instructions35: "Remove the thermometer, discard the cover, and record temperature and time.",
            logentry_temperature_instructions_note26: "Rectal temperatures are considered most accurate indication of the body’s temperature. Oral and axillary temperature readings are about ½° to 1°F (.3°C to .6°C) below rectal. Add these numbers to oral and axillary temperature readings for the most accurate reading.",
            logentry_hydration_instructions_title: "Hydration Challenge (by British Heart Foundation)",
            logentry_hydration_instructions_title1: "Getting started – Week 1",
            logentry_hydration_instructions11: "Record how much water you drink during the time spent at work. Note how you have been feeling during the day.",
            logentry_hydration_instructions12: "At the end of the week, calculate your average daily water consumption for the  first week. You should be aiming to drink 4–6 glasses (600ml-900ml) per day for the time you spend at work.",
            logentry_hydration_instructions_title2: "Weeks 2–4",
            logentry_hydration_instructions21: "In subsequent weeks, aim to increase your intake of water gradually, working towards the target of 4–6 glasses (600ml-900ml) per day for the time you spend at work.",
            logentry_hydration_instructions22: "Continue to record how many glasses (1 glass is a approximately 150ml) of water you drink at work each day. At the end of each day, record how you have been feeling. Have there been any changes in your concentration level, feelings of fatigue, or irritability, for example?",
            logentry_hydration_instructions23: "Think about the reasons why you did not drink more water. Discuss with your work colleagues ways around these problems.",
            logentry_hydration_instructions_title3: "Factors that might prevent you from drinking more fluids while at work can include:",
            logentry_hydration_instructions31: "Poor location of water facilities, making them inaccessible.",
            logentry_hydration_instructions32: "Limited opportunities to break for a drink.",
            logentry_hydration_instructions33: "Lack of encouragement to stop for a drink of water.",
            logentry_hydration_instructions34: "Not wanting to drink too much because of embarrassment over using shared toilet facilities or poorly maintained toilets.",
            logentry_weight_instructions0: "Get yourself a good body scale.",
            logentry_weight_instructions1: "Weigh yourself at least twice a day: when you wake up, before you go to bed, and in the middle of the day (if possible).",
            logentry_weight_instructions2: "Ideally, weigh yourself naked. Clothing can add extra weight, and that amount varies, so you should avoid it if possible.",
            logentry_weight_instructions3: "Chances are you can’t weigh yourself in the nude in the middle of the day, however, so weigh yourself before and after you get dressed a few times to find out how much (approximately) your weight changes when dressed so you can subtract that amount if you need to weigh yourself clothed.",
            logentry_height_instructions0: "First, find a flat, uncarpeted section of floor and a flat section of wall.",
            logentry_height_instructions1: "Take off your shoes. Remove braids, headbands, or anything else on your head that may get in the way of an accurate measurement.",
            logentry_height_instructions2: "Remove any bulky clothing that may make it difficult to stand flat against the wall.",
            logentry_height_instructions3: "Stand with your feet flat on the floor with your heels against the corner where the wall and floor meet. Make sure your head, shoulders, and buttocks are touching the wall.",
            logentry_height_instructions4: "Stand up straight with your eyes looking straight ahead. Your line of sight and chin should be parallel to the floor.",
            logentry_height_instructions5: "Have someone place a flat object (like a ruler or hardcover book) against the wall at a right angle. Then have them lower it until it rests gently on top of your head, keeping it at a right angle to the wall.",
            logentry_height_instructions6: "Lightly mark the wall with a pencil at the point where the ruler or book (or other flat object) meets your head.",
            logentry_height_instructions7: "Use a tape measure — ideally a metal one that will remain straight — to measure the distance from the floor to the mark on the wall.",
            logentry_height_instructions8: "Take note of the measurement to the nearest 1/8th of an inch or 0.1 centimeter.",
            logentry_glucose_instructions0: "Wash and dry your hands well.",
            logentry_glucose_instructions1: "Place a test strip into your meter.",
            logentry_glucose_instructions2: "Prick the side of your fingertip with the lancet provided with your test kit.",
            logentry_glucose_instructions3: "Gently squeeze or massage your finger until a drop of blood forms.",
            logentry_glucose_instructions4: "Touch and hold the edge of the test strip to the drop of blood.",
            logentry_glucose_instructions5: "The meter will display your blood glucose level on a screen after a few seconds.",
            logentry_ketones_instructions0: "Wash your hands.",
            logentry_ketones_instructions1: "Load the lancet with the needle, following the directions provided.",
            logentry_ketones_instructions2: "Place a blood ketone strip into the ketone meter.",
            logentry_ketones_instructions3: "Prick your finger to draw a small drop of blood using the lancet.",
            logentry_ketones_instructions4: "Let the strip come in contact with the drop of blood and check the results.",
            logentry_ketones_instructions5: "Dispose of the strip and lancet as suggested in the directions.",
            logentry_urine_ph_instructions0: "Obtain pH test strip. This strip measures the acid-alkaline state of any liquid. Readings at the low end of the scale indicate an acidic state, and those on the higher end a more alkaline state. Complete instructions on how to use them come with the strips, but these are the simple steps to follow to test your body pH balance at home.",
            logentry_urine_ph_instructions1: "Test in the morning . First thing in the morning, if possible after 6 hours of sleep without getting up to urinate, get a test strip. Either urinate directly on the strip or collect urine in a cup and dip the strip into the urine in the cup.",
            logentry_urine_ph_instructions2: "Please note that first-morning urine is the most valuable pH reading. If you can’t go 6 hours without getting up to urinate, then just test the first urine in the morning when you get up for the day.",
            logentry_urine_ph_instructions3: "Read the result color. As the test strip is moistened, it will take on a color. The color relates to the acid or alkaline state of your urine and ranges from yellow to dark blue. Match the color of your test strip with the chart provided on the back of your test kit.\n - A number below 6.5 means that your urine is on the acid side. The lower the number, the more acidic the condition.\n - The ideal urine reading should be between 6.5 to 7.5.",
            logentry_menstruation_instructions0: "A normal amount of menstrual fluid loss per period is between 5 mL to 80 mL.",
            logentry_menstruation_instructions1: "Different birth control methods may affect the heaviness of your period.",
            logentry_menstruation_instructions2: "Losing over 80 mL of menstrual fluid per period is considered heavy menstrual bleeding.",
            logentry_menstruation_instructions_title1: "Estimating the volume of the period",
            logentry_menstruation_instructions3: "If you use a menstrual cup, these often have volume measurements (ex. 10 mL, 15 mL, 25 mL) indicated on the side of the menstrual cup to help you estimate how much fluid you have lost.",
            logentry_menstruation_instructions4: "In general, the amount of fluid lost can be estimated when using pads or tampons, depending on the size and soaking amount of the menstrual product. A fully saturated light tampon can hold up to 3 mL of fluid, while a fully saturated super tampon may hold up to 12 mL (3,8).",
            logentry_menstruation_instructions5: "A regular daytime fully soaked pad may hold around 5 ml of fluid, and fully soaked overnight pad may hold 10–15 mL.",
            logentry_menstruation_instructions6: "If you are repeatedly soaking through a tampon or pad every two hours, this is considered heavy menstrual bleeding and should be brought to the healthcare provider’s attention.",
            logentry_peak_flow_instructions0: 'Before each use, make sure the sliding marker or arrow on the Peak Flow Meter is at the bottom of the numbered scale (zero or the lowest number on the scale).',
            logentry_peak_flow_instructions1: 'Stand up straight. Remove gum or any food from your mouth. Take a deep breath (as deep as you can). Put the mouthpiece of the peak flow meter into your mouth. Close your lips tightly around the mouthpiece. Be sure to keep your tongue away from the mouthpiece. In one breath, blow out as hard and as quickly as possible (like you are blowing out candles on a birthday cake). Blow a "fast hard blast" rather than "slowly blowing" until you have emptied out nearly all the air from your lungs.',
            logentry_peak_flow_instructions2: 'The force of the air coming out of your lungs causes the marker to move along the numbered scale. Note the number on a piece of paper.',
            logentry_peak_flow_instructions3: 'Repeat the entire routine three times. (You know you have done the routine correctly when the numbers from all three tries are very close together.)',
            logentry_peak_flow_instructions4: 'IMPORTANT: Enter the HIGHEST of the three ratings in the logbook. Do not calculate an average.',
            logentry_peak_flow_instructions5: 'Measure your peak flow rate close to the same time each day. You and your healthcare provider can determine the best times. One suggestion is to measure your peak flow rate twice daily between 7 and 9 a.m. and between 6 and 8 p.m. You may want to measure your peak flow rate before or after using your medicine. Some people measure peak flow both before and after taking medication. Try to do it the same way each time.',
            about: 'About',
            contact: 'Contact'
        }
    }